import { TemplatesDisplay } from "./TemplatesDisplay";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import TemplateDrawer from "screens/designStudio/templates/TemplateDrawer";
import { MAX_DISPLAY, ROUTES } from "./utils/constants";
import { Spin } from "antd";
import { useFetchTemplates } from "shared/hooks/designStudio/useFetchTemplates";
import { Template } from "shared/types/salesEnablement";

const getExpiringTemplates = (templates: Template[]) => {
  const currentDate = new Date();

  return templates
    .filter((template: Template) => {
      const expirationDate = template.expirationDate
        ? new Date(template.expirationDate)
        : undefined;
      return expirationDate && expirationDate >= currentDate;
    })
    .sort(
      (a: Template, b: Template) =>
        +new Date(a.expirationDate!) - +new Date(b.expirationDate!),
    )
    .slice(0, MAX_DISPLAY);
};

export const ExpiringTemplates = () => {
  const navigate = useNavigate();
  const { templates, isTemplateLoading, isError } = useFetchTemplates();
  const expiringTemplates = getExpiringTemplates(templates);

  const handleClickExpiring = () => {
    const today = moment().startOf("day").valueOf();
    const sixtyDaysAhead = moment().add(60, "days").endOf("day").valueOf();
    navigate(`${ROUTES.templates}?expirationDate=${today}+${sixtyDaysAhead}`);
  };

  return (
    <Spin spinning={isTemplateLoading}>
      <TemplatesDisplay
        isError={isError}
        title="Templates Expiring Soon"
        templates={expiringTemplates}
        filterFunction={templates => getExpiringTemplates(templates)}
        handleClick={handleClickExpiring}
        showExpirationDate
      />
      <TemplateDrawer drawerMode="edit" toHomepage />
    </Spin>
  );
};
