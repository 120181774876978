import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
} from "@ant-design/icons";
import { Divider, Slider } from "antd";
import { useCallback, useState } from "react";

import { ExtendedObjectType, TTextAlign } from "shared/types/designStudio";

import * as utils from "./ManageText.utils";

import "./ManageText.scss";
import FontProperty from "./manageText/FontProperty";
import { TCanvasAction } from "../../canvasContainer/Canvas";

type TObjectUpdateCustomActionType = "radius";
export type TShapePropertyKey =
  | keyof fabric.Rect
  | keyof fabric.Circle
  | keyof fabric.Triangle
  | TObjectUpdateCustomActionType;

type TObjectUpdateActionDataKey =
  | keyof fabric.Textbox
  | TShapePropertyKey
  | "reset";

export type TObjectUpdateAction = {
  target: fabric.Object;
  action: {
    type: ExtendedObjectType;
    data: {
      key: TObjectUpdateActionDataKey;
      value: string | number | undefined;
    };
  };
};
interface ManageTextProps {
  textbox?: fabric.Textbox; // canvasAction is set whenever user selects an object from the canvas
  canvasAction?: TCanvasAction;
}

interface ManageTextHandlers {
  setObjectUpdateAction: (action: TObjectUpdateAction) => void;
}

const ManageText: React.FC<ManageTextProps & ManageTextHandlers> = props => {
  const { fill } = props.textbox || {};

  const [presetColors, setPresetColors] = useState<string[]>([
    `${(fill as string) || "#FFFFFF"}`,
  ]);

  const { deltaY } = props.textbox || {};
  const returnSuperOrSubscriptButton = (
    type: "superscript" | "subscript",
    deltaYChange: number,
    onClick: (e: React.MouseEvent<HTMLDivElement>) => void,
  ) => (
    <div
      style={{
        background: deltaY === deltaYChange ? "rgba(0, 121, 255, 1)" : "",
      }}
      key={`${type}-base`}
      className="base"
      onClick={onClick}
    >
      A<div className={type}>A</div>
    </div>
  );

  const { textbox } = props;
  const onChange = useCallback(
    (key: keyof fabric.Textbox) => async (value: string | number) => {
      if (!textbox) return;

      const invalidTypeErrorMessage = 'The "value" type must be string.';

      switch (key) {
        case "fontFamily":
          if (typeof value !== "string")
            throw new Error(invalidTypeErrorMessage);

          break;

        case "fill":
          if (typeof value !== "string")
            throw new Error(invalidTypeErrorMessage);

          setPresetColors(prev => utils.getPresetColors(prev, value));
          break;
      }

      props.setObjectUpdateAction({
        target: textbox,
        action: {
          type: "text",
          data: {
            key,
            value,
          },
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [textbox],
  );

  const { setObjectUpdateAction } = props;
  const onScriptChange = useCallback(
    (script: "superscript" | "subscript" | "reset", textbox?: fabric.Textbox) =>
      (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();

        if (!textbox) return;

        setObjectUpdateAction({
          target: textbox,
          action: {
            type: "text",
            data: {
              key: script,
              value: JSON.stringify(textbox),
            },
          },
        });
      },
    [setObjectUpdateAction],
  );

  const { canvasAction } = props;

  return (
    <div className="property-container">
      <FontProperty
        textbox={props.textbox}
        presetColors={presetColors}
        canvasAction={canvasAction}
        onChange={onChange}
      />

      <Divider />

      <div className="property-with-title font-alignment-property">
        <div className="title">Text Alignment</div>
        <div className="alignments">
          {(["left", "center", "right"] as TTextAlign[]).map(alignment => (
            <span
              role="button"
              tabIndex={0}
              style={
                textbox?.textAlign === alignment
                  ? {
                      backgroundColor: "#1890ff",
                    }
                  : {}
              }
              onClick={() => onChange("textAlign")(alignment)}
              key={`alignment-${alignment}`}
            >
              {alignment === "left" && <AlignLeftOutlined />}
              {alignment === "center" && <AlignCenterOutlined />}
              {alignment === "right" && <AlignRightOutlined />}
            </span>
          ))}
        </div>
      </div>

      <Divider />

      <div className="property-with-title font-script-property">
        <div className="title">Script</div>

        <div className="script-container">
          {["superscript", "subscript", "reset"].map(type => {
            let icon = null;

            switch (type) {
              case "superscript":
                icon = returnSuperOrSubscriptButton(
                  type,
                  -14,
                  onScriptChange("superscript", textbox),
                );
                break;
              case "subscript":
                icon = returnSuperOrSubscriptButton(
                  type,
                  4.4,
                  onScriptChange("subscript", textbox),
                );
                break;
              case "reset":
                icon = (
                  <div
                    key={`${type}-base`}
                    className="base"
                    onClick={onScriptChange("reset", textbox)}
                  >
                    Reset
                  </div>
                );
                break;
              default:
                icon = <div key={`${type}-base`} />;
                break;
            }
            return icon;
          })}
        </div>
      </div>
      <Divider />
      <div className="property-with-title font-opacity-property">
        <div className="title">Opacity</div>
        <div className="opacity-slider-container">
          <Slider
            min={0}
            max={1}
            step={0.1}
            value={textbox?.opacity}
            onChange={onChange("opacity")}
          />
        </div>
      </div>
    </div>
  );
};

export default ManageText;
