import { Button } from "antd";
import classNames from "classnames";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { AiIcon } from "shared/icons/AiIcon";
import { AiResizeIcon } from "shared/icons/AiResizeIcon";
import styles from "./ToolPicker.module.scss";

const ToolPicker = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { sessionId } = useParams<{ sessionId: string }>();

  const selectedTab = location.pathname.includes("/ai-image-tools/generate")
    ? "generate"
    : "resize";

  const handleTabChange = (activeKey: "generate" | "resize") => {
    navigate(`/ai-image-tools/${activeKey}/${sessionId}`);
  };

  return (
    <div className={styles.toolPicker}>
      <Button
        type="ghost"
        onClick={() => handleTabChange("generate")}
        className={classNames(styles.toolPickerTab, {
          [styles.toolPickerTabActive]: selectedTab === "generate",
        })}
      >
        <AiIcon
          height={40}
          width={40}
          color={selectedTab === "generate" ? "#1890ff" : "black"}
        />
        Generate Image
      </Button>
      <Button
        type="ghost"
        onClick={() => handleTabChange("resize")}
        className={classNames(styles.toolPickerTab, {
          [styles.toolPickerTabActive]: selectedTab === "resize",
        })}
      >
        <AiResizeIcon
          height={40}
          width={40}
          color={selectedTab === "resize" ? "#1890ff" : "black"}
        />
        Resize Image
      </Button>
    </div>
  );
};

export default ToolPicker;
