import DataListURLTable, {
  ITableColumnURL,
} from "shared/components/dataListURL/DataListURLTable";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { ProductTable } from "shared/types/salesEnablement";
import { TableActionsContextProvider } from "shared/components/HoverActionsVirtualTable/TableActionsContext";
import { ContextTableActions } from "shared/components/tableActions/ContextTableActions";
import { InlineTableActions } from "shared/components/tableActions/InlineTableActions";
import { EditOutlined, SearchOutlined } from "@ant-design/icons";
import ToolbarTable, {
  ToolbarButton,
} from "shared/components/toolbarTable/ToolbarTable";
import { SearchFilterDropdown } from "shared/components/SearchFilterDropdown";
import { uniq } from "lodash";
import { fieldKeys } from "./fields";
import { DataListTagsURL } from "shared/components/dataListURL/DataListTagsURL";
import { useDataListURLData } from "shared/components/dataListURL/useDataListURLData";
import { useDataListURLGlobalFilter } from "shared/components/dataListURL/useDataListURLGlobalFilter";
import { useMemo, useState } from "react";
import Tags from "screens/designStudio/shared/Tags";
import styles from "./Products.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "./utils/constants";
import ProductFilterDrawer from "./ProductFilterDrawer";

type Props = {
  productsTable: ProductTable[];
  loading: boolean;
};

const Table = ({ productsTable, loading }: Props) => {
  const navigate = useNavigate();

  const { windowInnerHeight } = useWindowSize();

  const [openFilter, setOpenFilter] = useState(false);

  const {
    data: productsTableData,
    selectedItems: selectedProducts,
    originalData: allProducts,
  } = useDataListURLData<keyof ProductTable, ProductTable>({
    isError: false,
    isLoading: loading,
    data: productsTable,
  });

  const onEdit = (selectedProduct: ProductTable) => {
    navigate(ROUTES.update(selectedProduct.id));
  };

  const toolbarContents: ToolbarButton = {
    New: {
      extraInfo: { text: "New Product" },
      onClick: () => {
        navigate(ROUTES.create);
      },
    },
    Edit: {
      onClick: () => {
        onEdit(selectedProducts[0]);
      },
      disabled: selectedProducts.length !== 1,
    },
  };

  const { globalFilter, setGlobalFilter } = useDataListURLGlobalFilter();

  const columns: ITableColumnURL<keyof ProductTable, ProductTable>[] = useMemo(
    () => [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        filterDropdown: SearchFilterDropdown,
        filterIcon: <SearchOutlined />,
        render: (_, record: ProductTable) => (
          <Link to={ROUTES.update(record.id)}>{record.name}</Link>
        ),
      },
      {
        title: "Product Type",
        dataIndex: "productType",
        key: "productType",
        filters: uniq(productsTable.map(t => t.productType)).map(
          (type: string) => ({
            text: type,
            value: type,
          }),
        ),
      },
      {
        title: "Language",
        dataIndex: "language",
        key: "language",
        filters: [
          {
            key: "en",
            text: "English",
            value: "en",
          },
          {
            key: "es",
            text: "Spanish",
            value: "es",
          },
        ],
        render: language => (
          <Tags
            tags={language.map((key: string) => ({
              id: key,
              name: key === "en" ? "English" : "Spanish",
            }))}
          />
        ),
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        filterDropdown: SearchFilterDropdown,
        filterIcon: <SearchOutlined />,
      },
    ],
    [productsTable],
  );

  const generateToolbarContents: (
    record?: ProductTable,
  ) => ToolbarButton = record => {
    if (!record) return {};

    return {
      Edit: {
        icon: <EditOutlined />,
        onClick: () => {
          onEdit(record);
        },
        showInContextMenu: true,
        showInInlineMenu: true,
      },
    };
  };

  return (
    <div className={styles.container}>
      <ToolbarTable
        searchValue={globalFilter ?? undefined}
        toolbarContents={toolbarContents}
        searchPlaceholder="Input search text"
        titleTooltip="Search sessions by name"
        onSearch={value => setGlobalFilter(value)}
      />
      <DataListTagsURL<keyof ProductTable, ProductTable>
        data={productsTableData}
        originalData={allProducts}
        onAddFilterClick={() => setOpenFilter(true)}
      />
      <TableActionsContextProvider<ProductTable>
        renderContextActions={record => (
          <ContextTableActions
            getItemsToRender={generateToolbarContents}
            record={record}
          />
        )}
        renderHoverActions={record => (
          <InlineTableActions
            getItemsToRender={generateToolbarContents}
            record={record}
          />
        )}
      >
        <DataListURLTable<keyof ProductTable, ProductTable>
          loading={loading}
          dataSource={productsTableData}
          columns={columns}
          pagination={false}
          size="small"
          scroll={{ y: windowInnerHeight - 245 }}
          type="hoverActionsVirtualTable"
          fieldKeys={fieldKeys}
        />
      </TableActionsContextProvider>
      <ProductFilterDrawer
        open={openFilter}
        onClose={() => setOpenFilter(false)}
      />
    </div>
  );
};

export default Table;
