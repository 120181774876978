import { Button } from "antd";
import styles from "./PmaxDrawerFooter.module.scss";
import { PmaxCreateAssetGroupFooterProps } from "../PmaxLoadDrawer";

export const SelectStepFooter = ({
  onClose,
  onGoToAdLoadReview,
  previewDisabled,
}: PmaxCreateAssetGroupFooterProps) => (
  <div className={styles.buttonContainer}>
    <Button onClick={onClose}>Cancel</Button>
    <Button
      type="primary"
      onClick={onGoToAdLoadReview}
      disabled={previewDisabled}
    >
      Next
    </Button>
  </div>
);
