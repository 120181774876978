import { useDataListURLGlobalFilter } from "shared/components/dataListURL/useDataListURLGlobalFilter";
import ToolbarTable from "shared/components/toolbarTable/ToolbarTable";

const Toolbar = () => {
  const { globalFilter, setGlobalFilter } = useDataListURLGlobalFilter();

  return (
    <ToolbarTable
      searchValue={globalFilter ?? undefined}
      toolbarContents={{}}
      searchPlaceholder="Search"
      titleTooltip=""
      onSearch={setGlobalFilter}
    />
  );
};

export default Toolbar;
