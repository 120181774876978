import DataListURLTable from "shared/components/dataListURL/DataListURLTable";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { NormalizedDisclosure } from "shared/types/salesEnablement";
import { TableActionsContextProvider } from "shared/components/HoverActionsVirtualTable/TableActionsContext";
import { ContextTableActions } from "shared/components/tableActions/ContextTableActions";
import { InlineTableActions } from "shared/components/tableActions/InlineTableActions";
import { EditOutlined } from "@ant-design/icons";
import ToolbarTable, {
  ToolbarButton,
} from "shared/components/toolbarTable/ToolbarTable";
import { fieldKeys } from "./fields";
import { DataListTagsURL } from "shared/components/dataListURL/DataListTagsURL";
import { useDataListURLData } from "shared/components/dataListURL/useDataListURLData";
import { useDataListURLGlobalFilter } from "shared/components/dataListURL/useDataListURLGlobalFilter";
import { useNavigate } from "react-router-dom";
import { useNormalizedDisclosures } from "shared/hooks/admin/useDisclosures";
import { ROUTES } from "./utils/constants";
import { useIsSalesEnablementAdmin } from "shared/hooks/useIsAdmin";
import { useColumns } from "./table/useColumns";
import DisclosureFilterDrawer from "./DislcosureFilterDrawer";
import { useState } from "react";

const Table = () => {
  const navigate = useNavigate();

  const { windowInnerHeight } = useWindowSize();

  const isSalesEnablementAdmin = useIsSalesEnablementAdmin();

  const { normalizedDisclosures, isLoading } = useNormalizedDisclosures();

  const columns = useColumns(normalizedDisclosures ?? []);

  const [openFilter, setOpenFilter] = useState(false);

  const { data, selectedItems: selectedDisclosures } = useDataListURLData<
    keyof NormalizedDisclosure,
    NormalizedDisclosure
  >({
    isError: false,
    isLoading,
    data: normalizedDisclosures,
  });

  const onEdit = (selectedDisclosure: NormalizedDisclosure) => {
    navigate(`${ROUTES.update(selectedDisclosure.id)}${location.search}`);
  };

  const toolbarContents: ToolbarButton = {
    New: {
      extraInfo: {
        text: "New Disclosure",
      },
      onClick: () => {
        navigate(`${ROUTES.create}${location.search}`);
      },
      disabled: !isSalesEnablementAdmin,
    },
    Edit: {
      onClick: () => {
        onEdit(selectedDisclosures[0] ?? "");
      },
      disabled: selectedDisclosures.length !== 1 || !isSalesEnablementAdmin,
    },
  };

  const { globalFilter, setGlobalFilter } = useDataListURLGlobalFilter();

  const generateToolbarContents: (
    record?: NormalizedDisclosure,
  ) => ToolbarButton = record => {
    if (!record) return {};

    return {
      Edit: {
        icon: <EditOutlined />,
        onClick: () => {
          onEdit(record);
        },
        showInContextMenu: true,
        showInInlineMenu: true,
        disabled: !isSalesEnablementAdmin,
      },
    };
  };

  return (
    <>
      <ToolbarTable
        searchValue={globalFilter ?? undefined}
        toolbarContents={toolbarContents}
        searchPlaceholder="Search"
        titleTooltip="Search"
        onSearch={value => setGlobalFilter(value)}
      />
      <DataListTagsURL<keyof NormalizedDisclosure, NormalizedDisclosure>
        data={data}
        originalData={normalizedDisclosures || []}
        onAddFilterClick={() => setOpenFilter(true)}
      />
      <TableActionsContextProvider<NormalizedDisclosure>
        renderContextActions={record => (
          <ContextTableActions
            getItemsToRender={generateToolbarContents}
            record={record}
          />
        )}
        renderHoverActions={record => (
          <InlineTableActions
            getItemsToRender={generateToolbarContents}
            record={record}
          />
        )}
      >
        <DataListURLTable<keyof NormalizedDisclosure, NormalizedDisclosure>
          loading={isLoading}
          dataSource={data}
          columns={columns}
          pagination={false}
          size="small"
          scroll={{ y: windowInnerHeight - 245 }}
          type="hoverActionsVirtualTable"
          fieldKeys={fieldKeys}
        />
        <DisclosureFilterDrawer
          open={openFilter}
          onClose={() => setOpenFilter(false)}
        />
      </TableActionsContextProvider>
    </>
  );
};

export default Table;
