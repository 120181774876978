import { Upload, Space } from "antd";
import { useMemo } from "react";
import { MultiUploadSizing } from "shared/types/uploadManagement";
import { IUploadMediaUrls } from "utils/uploadMedia";
import { getFileList } from "./utils.file";
import { DropPlaceholder } from "./DropPlaceholder";
import { useUploadDragger } from "./useUploadDragger";
import { CAMUploadButton } from "./CAMUploadButton";

interface SingleFileUploadDraggerProps {
  fileUrls: IUploadMediaUrls;
  handleChangeFile(newFile: IUploadMediaUrls): void;
  size: MultiUploadSizing;
}

export const SingleFileUploadDragger = ({
  fileUrls,
  handleChangeFile,
  size,
}: SingleFileUploadDraggerProps) => {
  const fileList = useMemo(() => getFileList(fileUrls), [fileUrls]);

  const {
    uploadStatus,
    setUploadStatus,
    isUploading,
    handleFileSizeValidation,
    onUpload,
    onCAMClose,
  } = useUploadDragger(handleChangeFile, size);

  return (
    <Space align="end">
      <div>
        <Upload
          data-cy="ad-media-upload"
          multiple={false}
          accept="image/*, video/*"
          listType="picture-card"
          fileList={fileList}
          beforeUpload={handleFileSizeValidation}
          onRemove={() => {
            handleChangeFile({
              thumbnail: "",
              videoUrl: "",
              assetId: "",
            });
            setUploadStatus(undefined);
          }}
          customRequest={onUpload}
        >
          {!fileUrls?.thumbnail && !isUploading && <DropPlaceholder />}
        </Upload>
        <CAMUploadButton
          uploadStatus={uploadStatus}
          onClose={onCAMClose}
          isUploading={isUploading}
          filename={fileUrls?.filename}
        />
      </div>
    </Space>
  );
};
