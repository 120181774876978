import { IConfig } from "shared/types/configuration";
import HttpClient from "./httpClient";
import { IApiResponse } from "shared/types/shared";
import {
  Advertiser,
  BeeswaxCampaign,
  BeeswaxCampaignWithoutMetadata,
  BeeswaxCampaignsLoadingStatus,
  DeleteBeeswaxCampaignsBody,
  LoadCampaignsBody,
} from "screens/beeswaxCampaigns/types";

export default ({ config }: { config: IConfig }) => ({
  getCampaigns: (): Promise<IApiResponse<BeeswaxCampaign[]>> => {
    return HttpClient.get<IApiResponse<BeeswaxCampaign[]>>(
      config.services.beeswaxCampaigns.getBeeswaxCampaigns,
    );
  },
  getCampaign: (id: string): Promise<IApiResponse<BeeswaxCampaign>> => {
    return HttpClient.get<IApiResponse<BeeswaxCampaign>>(
      config.services.beeswaxCampaigns.getBeeswaxCampaign(id),
    );
  },
  createCampaign: (
    campaign: BeeswaxCampaignWithoutMetadata,
  ): Promise<IApiResponse<BeeswaxCampaign>> => {
    return HttpClient.post<IApiResponse<BeeswaxCampaign>>(
      config.services.beeswaxCampaigns.createBeeswaxCampaign,
      campaign,
    );
  },
  updateCampaign: (
    id: string,
    campaign: Partial<BeeswaxCampaignWithoutMetadata>,
  ): Promise<IApiResponse<BeeswaxCampaign>> => {
    return HttpClient.put<IApiResponse<BeeswaxCampaign>>(
      config.services.beeswaxCampaigns.updateBeeswaxCampaign(id),
      campaign,
    );
  },
  deleteCampaigns: (
    deleteCampaignsBody: DeleteBeeswaxCampaignsBody,
  ): Promise<IApiResponse> => {
    return HttpClient.delete<IApiResponse>(
      config.services.beeswaxCampaigns.deleteBeeswaxCampaigns,
      deleteCampaignsBody,
    );
  },
  getBeeswaxAdvertisers: (): Promise<IApiResponse<Advertiser[]>> => {
    return HttpClient.get<IApiResponse<Advertiser[]>>(
      config.services.beeswaxCampaigns.getBeeswaxAdvertisers,
    );
  },
  loadBeeswaxCampaigns: (
    body: LoadCampaignsBody,
  ): Promise<IApiResponse<string>> => {
    return HttpClient.post<IApiResponse<string>>(
      config.services.beeswaxCampaigns.loadBeeswaxCampaigns,
      body,
    );
  },
  getBeeswaxCampaignsLoadingStatus: (
    sessionId: string,
  ): Promise<IApiResponse<BeeswaxCampaignsLoadingStatus>> => {
    return HttpClient.get<IApiResponse<BeeswaxCampaignsLoadingStatus>>(
      config.services.beeswaxCampaigns.getBeeswaxCampaignsLoadingStatus(
        sessionId,
      ),
    );
  },
});
