import { message } from "antd";
import classnames from "classnames";
import { fabric } from "fabric";
import { keys } from "lodash";
import { useEffect, useState } from "react";
import { useCAMFonts } from "shared/components/media";
import { getBrandsByName } from "shared/hooks/brandsAccountsManagement/useFetchBrandsByName";
import { IDimension } from "shared/types/designStudio";
import { getUsedFontsFromLoadedJson } from "utils/fabric/helpers.text";
import { loadFont as loadFontFn } from "utils/helpers";
import {
  addPaddingAdjustment,
  fetchTemplateJson,
  getGroupedMappings,
  getHighlightBox,
  getVarsGroupById,
  highlightVariablesInCanvas,
  removeImageForImageVar,
  renderImageVars,
  scaleCanvas,
  setImageForVideoVar,
} from "../../assetBatchDrawer/dynamicText/utils.fabric";
import { setTextboxDataForCanvas } from "../../assetBatchDrawer/dynamicText/utils.variable";
import {
  renderTextVars,
  setTargetOpacity,
} from "../../shared/components/Preview/utils";
import { DEFAULT_FPS } from "../../shared/constants";
import { useAssetBatchesContext } from "../../shared/contexts/AssetBatchesContext";
import { useAssetBatchesRenderContext } from "../../shared/contexts/AssetBatchesRenderContext";
import { useAssetBatchesValueMappingContext } from "../../shared/contexts/AssetBatchesValueMappingContext";
import { useVideoStitchingContext } from "../../shared/contexts/VideoStitchingContext";
import { useVideoPlayer } from "../../shared/hooks/useVideoPlayer";
import { loadFontsFromTemplate } from "../../shared/utils";
import {
  isExtendedFabricObject,
  isFixedVideo,
  isImage,
  isTextbox,
  isVideo,
} from "../../shared/validators";
import styles from "./VideoStitching.module.scss";

interface Props {
  compositionId: string;
  fps: number;
  opacity?: number;
  playing: boolean;
  currentFrame: number;
}

export const VideoCanvasForPreview = ({ playing, currentFrame }: Props) => {
  const {
    useTemplateBackgroundEffect,
    setPreviewLoading,
    previewLoading,
    currentStep,
    currentCompositionId,
    backgroundMedias,
    compositions,
    preventAutoScroll,
    setHasFixedBackground,
  } = useAssetBatchesContext();

  const { setCanvasJsons, videoAudios, setBackgroundAudios, backgroundAudios } =
    useVideoStitchingContext();

  const {
    canvas,
    setCanvas,
    setCanvasContainerDim,
    setCurrentSwitchType,
    setTemplateHasFixedVideo,
    valueMappings,
    isMediaMaskOn,
    isTextMaskOn,
    currentSwitchType,
    template,
    editingComposition,
    canvasRef,
    canvasContainerRef,
    previewHovering,
  } = useAssetBatchesRenderContext();

  const {
    setChangedMappingKey,
    changedMappingKey,
    hoveredMappingKey,
    selectedRow,
    emptyValueHighlight,
    showVariablesOn,
  } = useAssetBatchesValueMappingContext();

  const { togglePlaying, updateVideoMuted, updateVideoVolume } = useVideoPlayer(
    {
      canvas,
      currentFrame,
    },
  );

  const compositionIndex = compositions.indexOf(editingComposition);
  const [backgroundEle, setBackgroundEle] = useState<HTMLVideoElement | null>(
    null,
  );

  const { fontFamilies, loadFont: loadCAMFont } = useCAMFonts();
  const [loadedCamFonts, setLoadedCamFonts] = useState<boolean>(false);

  useEffect(() => {
    const loadFonts = async () => {
      if (!template) return;
      const json = await fetchTemplateJson(template);
      const fonts = getUsedFontsFromLoadedJson(json);
      const promises = fonts.map(font => {
        if (!fontFamilies.includes(font)) return Promise.resolve();
        return loadCAMFont(font, loadFontFn);
      });
      await Promise.all(promises);
      setLoadedCamFonts(true);
    };
    loadFonts();
  }, [fontFamilies, loadCAMFont, loadedCamFonts, template]);

  useEffect(() => {
    if (!template || !loadedCamFonts) return;

    const newCanvas = new fabric.Canvas(
      `canvas_${editingComposition.compositionId}_${editingComposition.template}`,
    );
    newCanvas.setWidth(0);
    newCanvas.setHeight(0);
    setChangedMappingKey(undefined);

    const canvasContainerRect =
      canvasContainerRef.current?.getBoundingClientRect();
    if (!canvasContainerRect) return;

    const render = async () => {
      setPreviewLoading(true);
      const json = await fetchTemplateJson(template);
      setCanvasJsons((prev: Record<string, any>) => {
        if (prev[editingComposition.compositionId] !== undefined) return prev;
        return { ...prev, [editingComposition.compositionId]: json };
      });

      const brands = template.oems.length
        ? await getBrandsByName(template.oems)
        : [];
      const brandFonts = brands?.flatMap(brand => brand.fonts) ?? [];

      await loadFontsFromTemplate(json, brandFonts);

      setTemplateHasFixedVideo(false);
      newCanvas.loadFromJSON(json, () => {
        const backgroundImg = newCanvas.backgroundImage;
        if (isImage(backgroundImg) && backgroundImg.visible)
          setHasFixedBackground(true);
        fabric.util.clearFabricFontCache();
        newCanvas.getObjects().forEach(obj => {
          obj.set({ selectable: false, evented: false });
          if (isTextbox(obj)) setTextboxDataForCanvas(obj);
          if (isFixedVideo(obj)) setTemplateHasFixedVideo(true);
          if (isVideo(obj) || isFixedVideo(obj))
            setImageForVideoVar(
              newCanvas,
              obj,
              obj.customData.videoSrc,
              "fill",
            );
          addPaddingAdjustment(obj, newCanvas);
        });
        const { artboard } = template;
        const canvasDim: IDimension = {
          width: artboard.width,
          height: artboard.height,
        };
        const containerDim: IDimension = {
          width: canvasContainerRect.width,
          height: canvasContainerRect.height,
        };
        setCanvasContainerDim(containerDim);
        scaleCanvas(newCanvas, canvasDim, containerDim);
        setCanvas(newCanvas);
        setPreviewLoading(false);
      });
    };

    render().catch(() => {
      message.error("Failed to render template.");
      setPreviewLoading(false);
    });
  }, [
    template,
    setCanvas,
    setCanvasContainerDim,
    setPreviewLoading,
    setTemplateHasFixedVideo,
    setChangedMappingKey,
    setCanvasJsons,
    editingComposition.compositionId,
    editingComposition.template,
    compositionIndex,
    canvasRef,
    canvasContainerRef,
    loadedCamFonts,
    setHasFixedBackground,
  ]);

  useEffect(() => {
    if (!canvas || !selectedRow || previewHovering) return;

    highlightVariablesInCanvas(
      hoveredMappingKey,
      canvasRef,
      currentCompositionId,
      canvas,
      emptyValueHighlight,
      valueMappings,
      selectedRow,
    );
  }, [
    canvas,
    hoveredMappingKey,
    valueMappings,
    selectedRow,
    emptyValueHighlight,
    editingComposition,
    currentCompositionId,
    previewHovering,
    canvasRef,
  ]);

  // Effect to render variables in canvas on load and on change
  useEffect(() => {
    if (!canvas) return;

    const varsGroupById = getVarsGroupById(valueMappings);
    for (const element of keys(varsGroupById)) {
      const id = element;
      if (changedMappingKey && !changedMappingKey.includes(id)) continue;
      const { groupedMappings, mappings } = getGroupedMappings(
        isMediaMaskOn,
        showVariablesOn,
        varsGroupById,
        id,
        isTextMaskOn,
      );

      const target = canvas
        ?.getObjects()
        .filter(isExtendedFabricObject)
        .find(obj => obj.name === id);
      if (target) {
        renderTextVars(target, groupedMappings, selectedRow, showVariablesOn);
        if (currentSwitchType !== "text" && showVariablesOn)
          renderImageVars(
            target,
            mappings,
            currentStep,
            canvas,
            selectedRow,
            setBackgroundEle,
          );
        else {
          canvas.setBackgroundColor("lightgrey", () => {
            removeImageForImageVar(canvas, target);
            setTargetOpacity(target, 1);
          });
        }
      }
    }

    canvas.renderAll();
  }, [
    valueMappings,
    canvas,
    selectedRow,
    isTextMaskOn,
    isMediaMaskOn,
    currentSwitchType,
    setCurrentSwitchType,
    currentStep,
    changedMappingKey,
    showVariablesOn,
  ]);

  useTemplateBackgroundEffect(
    canvas,
    editingComposition,
    isMediaMaskOn,
    showVariablesOn,
    selectedRow,
    backgroundMedias,
    setBackgroundAudios,
    setBackgroundEle,
  );

  useEffect(() => {
    if (!currentCompositionId) return;

    togglePlaying(playing, editingComposition.duration ?? 2);
    updateVideoVolume(
      playing,
      currentCompositionId,
      backgroundAudios[currentCompositionId],
    );
    updateVideoMuted(
      playing,
      currentCompositionId,
      backgroundAudios[currentCompositionId],
    );
    if (playing && backgroundEle) {
      backgroundEle.play();
      if (
        videoAudios.some(
          vid =>
            vid.compositionId === currentCompositionId &&
            vid.variableIds?.some(variableId =>
              variableId.includes(backgroundEle.id),
            ),
        )
      )
        backgroundAudios[currentCompositionId]?.play();
    } else {
      backgroundEle?.pause();
      const audio = backgroundAudios[currentCompositionId];
      if (audio && audio.readyState > 0) {
        audio.pause();
        audio.currentTime = currentFrame / DEFAULT_FPS;
      }
    }
  }, [
    backgroundAudios,
    backgroundEle,
    currentCompositionId,
    currentFrame,
    editingComposition.duration,
    playing,
    togglePlaying,
    updateVideoMuted,
    updateVideoVolume,
    videoAudios,
  ]);

  // Effect to highlight empty values on hover and add them again when not hovering
  useEffect(() => {
    if (!canvas) return;

    canvas.getObjects().forEach(obj => {
      if ((obj as any).customType === "highlightBox") canvas.remove(obj);
    });
    canvas.renderAll();

    if (!previewHovering) {
      const varsGroupById = getVarsGroupById(valueMappings);
      keys(varsGroupById).forEach(id => {
        const { mappings } = getGroupedMappings(
          isMediaMaskOn,
          showVariablesOn,
          varsGroupById,
          id,
          isTextMaskOn,
        );

        const target = canvas
          ?.getObjects()
          .filter(isExtendedFabricObject)
          .find(obj => obj.name === id);
        if (!target) return;
        if (!mappings[0].value && !varsGroupById[id][0].value) {
          const highlightBox = getHighlightBox(target, false, false);
          if (highlightBox) {
            canvas?.add(highlightBox);
            canvas?.renderAll();
          }
        }
      });
    }
  }, [
    canvas,
    previewHovering,
    editingComposition,
    valueMappings,
    changedMappingKey,
    isMediaMaskOn,
    showVariablesOn,
    isTextMaskOn,
  ]);

  useEffect(() => {
    if (preventAutoScroll) return;
    if (!canvasRef.current?.id.includes(currentCompositionId ?? "")) return;
    canvasRef.current?.scrollIntoView();
  }, [currentCompositionId, canvasRef, preventAutoScroll]);

  return (
    <canvas
      id={`canvas_${editingComposition.compositionId}_${editingComposition.template}`}
      ref={canvasRef}
      className={
        previewLoading
          ? classnames([styles.canvasHidden, styles.canvas])
          : styles.canvas
      }
    />
  );
};
