import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useIsSalesEnablementAdmin } from "shared/hooks/useIsAdmin";
import { useSalesEnablementContext } from "screens/designStudio/hooks/SalesEnablementDataProvider";
import useTemplateEditing from "shared/hooks/salesEnablement/useTemplateEditing";
import { FileDrawerMode } from "shared/types/salesEnablement";
import PreviewDrawer from "./PreviewDrawer";
import FileDrawer from "./FileDrawer";
import useNavigateWithSearch from "shared/hooks/useNavigateWithSearch";

type Props = { drawerMode: FileDrawerMode; toHomepage?: boolean };

const TemplateDrawer = (props: Props) => {
  const [init, setInit] = useState(false);
  const { drawerMode, toHomepage = false } = props;
  const { templateId } = useParams<{
    templateId: string;
  }>();

  const navigate = useNavigateWithSearch();
  const isSalesEnablementAdmin = useIsSalesEnablementAdmin();

  const { setupDrawer } = useTemplateEditing();
  const {
    templates,
    setSelectedTemplate,
    setFiles,
    setFileDrawerMode,
    setDrawerDisabledMap,
    uploadForm,
    setIsFormDirty,
    setStatus,
    open,
    setOpen,
    componentsTabsFilter,
    setComponentsTabsFilter,
  } = useSalesEnablementContext();

  const template = useMemo(() => {
    return templates.find(t => t.id === templateId);
  }, [templateId, templates]);

  useEffect(() => {
    if (!init && (drawerMode === "new" || !!template)) {
      setInit(true);
      setupDrawer({ drawerMode, template });
    }
  }, [template, init, drawerMode, setupDrawer]);

  return isSalesEnablementAdmin ? (
    <FileDrawer
      componentsTabsFilter={componentsTabsFilter}
      setComponentsTabsFilter={setComponentsTabsFilter}
      onClose={() => {
        setIsFormDirty(false);
        uploadForm?.resetFields();
        setStatus("UNPUBLISHED");
        setFileDrawerMode(undefined);
        setDrawerDisabledMap(undefined);
        setSelectedTemplate(undefined);
        setFiles({});
        setOpen(false);
        setComponentsTabsFilter("all");
        if (!toHomepage) {
          navigate("..");
        }
      }}
      open={open}
    />
  ) : (
    <PreviewDrawer
      open={open}
      onClose={() => {
        setFiles({});
        setComponentsTabsFilter("all");
        setOpen(false);
        if (!toHomepage) {
          navigate("..");
        }
      }}
      componentsTabsFilter={componentsTabsFilter}
    />
  );
};

export default TemplateDrawer;
