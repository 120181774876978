type SpaceIconProps = {
  variant: "horizontal" | "vertical";
};

export const SpaceIcon = ({ variant }: SpaceIconProps) => {
  const rotation = variant === "horizontal" ? "rotate(90deg)" : "none";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      style={{ transform: rotation }}
    >
      <mask id="path-3-inside-1_1301_39" fill="white">
        <path d="M15.1 2C15.595 2 16 1.55 16 1C16 0.450001 15.595 -1.77031e-08 15.1 -3.93402e-08L0.9 -6.60042e-07C0.405001 -6.81679e-07 -1.96702e-08 0.45 -4.37114e-08 0.999999C-6.77526e-08 1.55 0.405001 2 0.9 2L15.1 2Z" />
        <path d="M15.1 20C15.595 20 16 19.55 16 19C16 18.45 15.595 18 15.1 18L0.899999 18C0.405 18 -8.06475e-07 18.45 -8.30516e-07 19C-8.54558e-07 19.55 0.405 20 0.899999 20L15.1 20Z" />
        <path d="M11 6C12.1046 6 13 6.89543 13 8L13 12C13 13.1046 12.1046 14 11 14L5 14C3.89543 14 3 13.1046 3 12L3 8C3 6.89543 3.89543 6 5 6L11 6Z" />
      </mask>
      <path
        d="M15.1 2C15.595 2 16 1.55 16 1C16 0.450001 15.595 -1.77031e-08 15.1 -3.93402e-08L0.9 -6.60042e-07C0.405001 -6.81679e-07 -1.96702e-08 0.45 -4.37114e-08 0.999999C-6.77526e-08 1.55 0.405001 2 0.9 2L15.1 2Z"
        stroke="black"
        strokeOpacity="0.56"
        strokeWidth="4"
        mask="url(#path-3-inside-1_1301_39)"
      />
      <path
        d="M15.1 20C15.595 20 16 19.55 16 19C16 18.45 15.595 18 15.1 18L0.899999 18C0.405 18 -8.06475e-07 18.45 -8.30516e-07 19C-8.54558e-07 19.55 0.405 20 0.899999 20L15.1 20Z"
        stroke="black"
        strokeOpacity="0.56"
        strokeWidth="4"
        mask="url(#path-3-inside-1_1301_39)"
      />
      <path
        d="M11 6C12.1046 6 13 6.89543 13 8L13 12C13 13.1046 12.1046 14 11 14L5 14C3.89543 14 3 13.1046 3 12L3 8C3 6.89543 3.89543 6 5 6L11 6Z"
        stroke="black"
        strokeOpacity="0.56"
        strokeWidth="4"
        mask="url(#path-3-inside-1_1301_39)"
      />
    </svg>
  );
};
