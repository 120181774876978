import { UploadFile } from "antd/lib/upload/interface";
import {
  InfiniteData,
  MutationOptions,
  QueryClient,
  useMutation,
  useQueryClient,
} from "react-query";
import API from "services";
import { IGetTemplatesResult, ITemplate } from "shared/types/designStudio";
import { OperationMode } from "shared/types/inputValues";

type TVariables = {
  template: ITemplate;
  htmlText?: string;
  zipFileId?: string;
  mode: OperationMode;
  fileList?: UploadFile[];
};

export type OnCompleteFunc = (
  variables: TVariables,
  updatedTemplate: ITemplate,
) => void;

const updateTemplate = async ({
  mode,
  template,
  htmlText,
  zipFileId,
  fileList,
}: {
  mode: OperationMode;
  template: ITemplate;
  htmlText?: string;
  zipFileId?: string;
  fileList?: UploadFile[];
}) => {
  const { result, error } =
    mode === "CREATE" || mode === "DUPLICATE"
      ? await API.services.designStudio.createTemplate(
          template,
          htmlText,
          zipFileId,
          mode === "DUPLICATE",
          fileList,
        )
      : await API.services.designStudio.updateTemplate(
          template,
          htmlText,
          zipFileId,
          fileList,
        );
  if (error) {
    throw Error(`${error}`);
  }

  return result?.template || null;
};

const updateTemplateQueryData = (
  variables: TVariables,
  updatedTemplate: ITemplate,
  queryClient: QueryClient,
): void => {
  const queries = queryClient
    .getQueryCache()
    .findAll({ queryKey: ["templates"] });
  if (queries.length === 0) return;

  const lastQuery = queries[queries.length - 1];
  const queryData = lastQuery.state.data as
    | InfiniteData<IGetTemplatesResult>
    | undefined;

  if (!queryData) return;

  const updateTemplates = (pages: IGetTemplatesResult[]) =>
    pages.map(page => ({
      ...page,
      templates: page.templates.map(template =>
        template.id === updatedTemplate.id ? updatedTemplate : template,
      ),
    }));

  switch (variables.mode) {
    case "CREATE":
    case "DUPLICATE":
      const lastPageIndex = queryData.pages.length - 1;
      const newPage = queryData.pages.map((page, index) =>
        index === lastPageIndex
          ? { ...page, templates: [...page.templates, updatedTemplate] }
          : page,
      );
      queryClient.setQueryData(lastQuery.queryKey, {
        ...queryData,
        pages: newPage,
      });
      break;
    case "UPDATE":
      queries.forEach(query => {
        const queryData = query.state.data as
          | InfiniteData<IGetTemplatesResult>
          | undefined;
        if (!queryData) return;
        const newPage = updateTemplates(queryData.pages);
        queryClient.setQueryData(query.queryKey, {
          ...queryData,
          pages: newPage,
        });
      });
      break;
  }
};

export const useMutateTemplate = (onComplete?: OnCompleteFunc) => {
  const queryClient = useQueryClient();

  const onSuccess: (
    onComplete?: OnCompleteFunc,
  ) => MutationOptions<ITemplate | null, Error, TVariables>["onSuccess"] =
    onComplete => (updatedTemplate, variables) => {
      if (!updatedTemplate) return;

      updateTemplateQueryData(variables, updatedTemplate, queryClient);

      if (updatedTemplate.type === "html") {
        queryClient.invalidateQueries(["template-versions"]);
      }
      onComplete?.(variables, updatedTemplate);
    };

  return useMutation<ITemplate | null, Error, TVariables>(
    "mutateTemplate",
    updateTemplate,
    {
      onSuccess: onSuccess(onComplete),
    },
  );
};
