import { UseMutationOptions, useMutation } from "react-query";
import API from "services";
import {
  beeswaxCampaignsQueryKey,
  updateBeeswaxCampaignMutationKey,
} from "../constants";
import { queryClient } from "queryClient";
import { notification } from "antd";
import { IDataTableError } from "shared/types/errors";
import { IApiResponse } from "shared/types/shared";
import { BeeswaxCampaign, BeeswaxCampaignWithoutMetadata } from "../types";

type AllOptions = UseMutationOptions<
  IApiResponse<BeeswaxCampaign, IDataTableError>,
  unknown,
  Partial<BeeswaxCampaignWithoutMetadata>,
  unknown
>;

export const useUpdateBeeswaxCampaign = (
  id: string | undefined,
  options?: Pick<AllOptions, "onSuccess">,
) => {
  return useMutation({
    mutationKey: updateBeeswaxCampaignMutationKey,
    mutationFn: campaign => {
      if (!id) throw new Error("No campaign id provided");
      return API.services.beeswaxCampaigns.updateCampaign(id, campaign);
    },
    onSettled: () => {
      queryClient.invalidateQueries([beeswaxCampaignsQueryKey]);
    },
    onError: () => {
      notification.error({
        message: "Error",
        description: "Something went wrong updating the campaign.",
      });
    },
    ...options,
  });
};
