export class ImportAdData {
  "Ad Name"?: string;
  "Ad Format"?: CsvAdFormat | string;
  "Primary Text"?: string;
  Headline?: string;
  Description?: string;
  CTA?: string;
  "Display URL"?: string;
  "Media Link URL"?: string;
  "Media Story Link URL"?: string;
  "Destination URL"?: string;
  "Model Year"?: string;
  OEM?: string;
  Brand?: string;
  "Model Name"?: string;
  Tags?: string;
  "Sales Event"?: string;
  "Ad Category"?: string;
  Client?: string;
  Package?: string;
  "Start Date"?: string;
  "End Date"?: string;
  "UTM"?: string;
  "Campaign Month"?: string;
  Account?: string;
  Industry?: string;
}

export const IMPORT_CSV_HEADERS = Object.keys(new ImportAdData());

export type CsvAdFormat =
  | "Instant Experience"
  | "Canvas"
  | "Collection"
  | "Carousel"
  | "Still"
  | "Motion"
  | "Video"
  | "AIA Carousel"
  | "AIA Still"
  | "DPA Carousel"
  | "DPA Still"
  | "FTA Carousel"
  | "FTA Still";
