import {
  IUploadImagesForm,
  IUploadImagesResponse,
} from "shared/types/uploadManagement";
import { getErrorMessage } from "utils/errorMessage";
import { UploadFile } from "antd/lib/upload/interface";
import { IFile } from "shared/types/uploadAsset";
import API from "services";

export const uploadImagesToS3 = async (
  images: UploadFile<IFile>[],
  destination: string,
) => {
  try {
    const imagesFromS3 = images?.filter(file => file.url) || [];
    const newImages = images?.filter(file => !file.url) || [];

    const { uploadImages } = API.services.uploadManagement;

    const uploadImagesForm: IUploadImagesForm = {
      files: newImages.map(image => ({
        filename: image.name,
        fileType: image.type,
        imageData: image.thumbUrl,
        featureName: destination,
      })),
    };

    const uploadImagesResponse = newImages.length
      ? await uploadImages<IUploadImagesResponse>(uploadImagesForm)
      : { result: { images: [] }, error: null };

    const { error, result: responseResult } = uploadImagesResponse;

    if (error) throw new Error(error.message || "Error uploading images");
    const imagesWithError = responseResult?.images?.filter(
      image => image["error"],
    );
    if (imagesWithError?.length) throw new Error("Error uploading images");

    if (responseResult?.images?.length) {
      imagesFromS3.push(
        ...responseResult.images.map((image, idx) => {
          const newImage = newImages[idx];
          return { ...newImage, url: image.imageUrl };
        }),
      );
    }

    return imagesFromS3;
  } catch (error) {
    const errMsg = getErrorMessage(error);
    throw new Error(errMsg);
  }
};
