import {
  DownloadOutlined,
  LinkOutlined,
  ZoomInOutlined,
} from "@ant-design/icons";
import { Button, Tooltip, Typography } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useGenerateImages from "shared/hooks/genAI/useGenerateImages";
import { AiIcon } from "shared/icons/AiIcon";
import { AiResizeIcon } from "shared/icons/AiResizeIcon";
import {
  getImageInfoOnLoad,
  handleDownload,
} from "../utils/functions/imageInfoUtils";
import CopyLinkModal from "./CopyLinkModal";
import styles from "./ImagePreview.module.scss";

const ImagePreview = ({
  index,
  image,
  openCarousel,
  prompt,
  ...rest
}: {
  index: number;
  image: string;
  openCarousel: (imageIndex: number) => void;
  prompt: string;
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [copyLinkModalVisible, setCopyLinkModalVisible] = useState(false);
  const [imageInfo, setImageInfo] = useState<{
    width: number;
    height: number;
  } | null>(null);
  const [blob, setBlob] = useState<Blob | null>(null);
  const navigate = useNavigate();
  const { sessionId } = useParams<{ sessionId: string }>();

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleImageLoad = async (
    e: React.SyntheticEvent<HTMLImageElement, Event>,
  ) => {
    const { width, height, blob } = await getImageInfoOnLoad(e, image);
    setImageInfo({ width, height });
    setBlob(blob);
  };

  const { generateImages } = useGenerateImages({
    sessionId: sessionId || "",
    prompt: prompt,
    height: 512,
    width: 512,
    nSamples: 4,
    baseImageUrl: image,
  });

  return (
    <>
      <div
        className={styles.previewImage}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...rest}
      >
        <img
          src={image}
          height={400}
          width={400}
          key={image}
          className={styles.image}
          onLoad={handleImageLoad}
        />
        {isHovering && (
          <div className={styles.overlay}>
            <div className={styles.buttonsRow}>
              <Tooltip title="Open in gallery">
                <Button
                  type="ghost"
                  icon={
                    <ZoomInOutlined
                      width={32}
                      height={32}
                      style={{ fontSize: "32px" }}
                    />
                  }
                  className={styles.button}
                  onClick={() => openCarousel(index)}
                />
              </Tooltip>
              <Tooltip title="Download">
                <Button
                  type="ghost"
                  icon={
                    <DownloadOutlined
                      width={32}
                      height={32}
                      style={{ fontSize: "32px" }}
                    />
                  }
                  onClick={() => handleDownload(blob)}
                  className={styles.button}
                />
              </Tooltip>
              <Tooltip title="Copy">
                <Button
                  type="ghost"
                  icon={
                    <LinkOutlined
                      width={32}
                      height={32}
                      style={{ fontSize: "32px" }}
                    />
                  }
                  onClick={() => setCopyLinkModalVisible(true)}
                  className={styles.button}
                />
              </Tooltip>
              <Tooltip title="Resize Image">
                <Button
                  data-cy="navigate-to-resize-button"
                  type="ghost"
                  icon={<AiResizeIcon width={32} height={32} color="#FFFFFF" />}
                  onClick={() =>
                    navigate(`/ai-image-tools/resize/${sessionId}`, {
                      state: { image },
                    })
                  }
                  className={styles.button}
                />
              </Tooltip>
              <Tooltip title="Generate variations">
                <Button
                  type="ghost"
                  icon={<AiIcon width={32} height={32} color="#FFFFFF" />}
                  onClick={() => generateImages()}
                  className={styles.button}
                />
              </Tooltip>
            </div>
            <div className={styles.infoRow}>
              {imageInfo && (
                <>
                  <Typography.Text className={styles.text}>
                    {`${imageInfo.width} x ${imageInfo.height} px`}
                  </Typography.Text>
                  <Typography.Text className={styles.text}>|</Typography.Text>
                </>
              )}
              <Typography.Text className={styles.text}>
                {image.split(".").pop()}
              </Typography.Text>
              <Typography.Text className={styles.text}>|</Typography.Text>
              {blob && (
                <Typography.Text className={styles.text}>
                  {`${(blob.size / 1024 / 1024).toFixed(2)} MB`}
                </Typography.Text>
              )}
            </div>
          </div>
        )}
      </div>
      <CopyLinkModal
        link={image}
        visible={copyLinkModalVisible}
        onClose={() => setCopyLinkModalVisible(false)}
      />
    </>
  );
};

export default ImagePreview;
