import { useFetchHtmlFromUrl } from "screens/designStudio/hooks/useFetchHtmlFromUrl";
import styles from "./HTMLPreview.module.scss";
import { useTemplateRenderContext } from "screens/designStudio/hooks/TemplateRenderDataProvider";
const HTMLPreview = () => {
  const { setHtmlIframeRef, file } = useTemplateRenderContext();
  const { data: selectedFile } = useFetchHtmlFromUrl(file);

  return (
    <iframe
      className={styles.iframe}
      ref={ref => setHtmlIframeRef(ref)}
      srcDoc={selectedFile?.html}
    ></iframe>
  );
};
export default HTMLPreview;
