import { Space, Radio, Typography, Divider, Spin } from "antd";
import { useFeedContext } from "../shared/contexts/FeedContext";
import styles from "./ExportDrawer.module.scss";
import { getHTMLTemplatesMap } from "./utils/getHTMLTemplatesMap";
import { ExportDestination } from "./types";
import { useFetchTemplates } from "./useFetchTemplates";
import { useAdLibraryExportContext } from "./AdLibraryExportContext";
import { isFeatureEnabled } from "utils/helpers";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { AssetBatchSelection } from "./AssetBatchSelection";

const isExportToOffPlatformEnabled = isFeatureEnabled(
  "ENABLE_EXPORT_TO_OFF_PLATFORM",
  false,
);

const isExportToAdLibraryEnabled = isFeatureEnabled(
  "ENABLE_AD_ENGINE_EXPORT_TO_AD_LIBRARY",
  false,
);

const isExportToPortalEnabled = isFeatureEnabled(
  "ENABLE_EXPORT_TO_PORTAL",
  true,
);

export const ExportSettings = () => {
  const {
    selectedAssetBatches,
    onSelectAssetBatches,
    assetBatchesForSelectedRows,
  } = useFeedContext();
  const { exportDestination, setExportDestination } =
    useAdLibraryExportContext();

  const { templates, isLoading: isFetchingTemplates } = useFetchTemplates(
    assetBatchesForSelectedRows,
  );
  const HTMLTemplatesMap = getHTMLTemplatesMap(
    assetBatchesForSelectedRows,
    templates,
  );

  const onExportDestinationChange = (value: ExportDestination) => {
    if (
      [
        ExportDestination.AdLibrary,
        ExportDestination.GoogleSpreadsheet,
      ].includes(value)
    ) {
      const validSelectedAssetBatches = selectedAssetBatches.filter(
        assetBatchId => !HTMLTemplatesMap[assetBatchId],
      );

      onSelectAssetBatches(validSelectedAssetBatches);
    }

    setExportDestination(value);
  };

  return (
    <Spin
      spinning={isFetchingTemplates}
      size="large"
      indicator={<Loading3QuartersOutlined spin />}
      wrapperClassName={styles.spinWrapper}
    >
      <div className={styles.exportSettingsWrapper}>
        <div className={styles.exportSettingsColumn}>
          <Typography.Text type="secondary">
            Asset batches to export
          </Typography.Text>
          <Divider className={styles.divider} />

          <AssetBatchSelection HTMLTemplatesMap={HTMLTemplatesMap} />
        </div>
        <div className={styles.exportSettingsColumn}>
          <Typography.Text type="secondary">Export for</Typography.Text>
          <Divider className={styles.divider} />
          <Radio.Group
            className={styles.radioGroup}
            onChange={event => onExportDestinationChange(event.target.value)}
            value={exportDestination}
          >
            <Space direction="vertical">
              {isExportToAdLibraryEnabled && (
                <Radio value={ExportDestination.AdLibrary}>Ad Library</Radio>
              )}
              <Radio value={ExportDestination.CSVDownload}>CSV download</Radio>
              {isExportToPortalEnabled && (
                <Radio value={ExportDestination.Portal}>Portal</Radio>
              )}
              {isExportToOffPlatformEnabled && (
                <Radio value={ExportDestination.GoogleSpreadsheet}>
                  Spreadsheet-Enabled Portal
                </Radio>
              )}
            </Space>
          </Radio.Group>
        </div>
      </div>
    </Spin>
  );
};
