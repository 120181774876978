import { Button, Divider, Drawer, Steps, notification, message } from "antd";
import { useMemo, useState } from "react";
import ImportCsvForm from "./adImportDrawer/ImportCsvForm";
import { getValidations } from "./adCompleteImportDrawer/adImportTable/adImportTableContainer/adImportTable.utils";
import styles from "./AdImportDrawer.module.scss";
import { LoadingOutlined } from "@ant-design/icons";
import { useBatchMutateAds } from "shared/hooks/adLibrary/useMutateAd";
import { pluralize } from "utils/terms";
import { useImportCSV } from "./adImportDrawer/useImportCSV";
import { adsImportCSVTemplateUrl } from "utils/constants";
import { AdImportDrawerTable } from "./adImportDrawer/AdImportDrawerTable";

const AdImportDrawer = ({ handleClose }: { handleClose: () => void }) => {
  // the data array from the csv file, the first row will have the header.
  const [selectedAdIds, setSelectedAdIds] = useState<string[]>([]);
  const [isSelectingAdsToImport, setIsSelectingAdsToImport] = useState(false);
  const { importData, isLoadingAds, adsLoadingMedia, csvRawData, ads, setAd } =
    useImportCSV(handleClose);

  const {
    mutateAsync: batchMutateAdsAsync,
    isLoading,
    isSuccess,
  } = useBatchMutateAds();

  const handleImport = async () => {
    const selectedAds = ads.filter(ad => selectedAdIds.includes(ad.id));
    try {
      await batchMutateAdsAsync(selectedAds);
      message.success({
        content: `${selectedAds.length} ${pluralize(
          "Ad",
          selectedAdIds.length,
        )} successfully imported to the library.`,
        className: styles.successMessage,
      });
      handleClose();
    } catch (e) {
      notification.error({
        message: "There was an error trying to complete the import.",
      });
    }
  };

  const importButtonText = `Import ${ads.length} ${pluralize(
    "ad",
    selectedAdIds.length,
  )}`;

  const validations = useMemo(() => getValidations(ads), [ads]);

  const importDisabled = useMemo(() => {
    return Boolean(
      isLoading ||
        !ads.length ||
        validations.adIdsWithErrors.length ||
        adsLoadingMedia.length,
    );
  }, [validations, ads, adsLoadingMedia, isLoading]);

  return (
    <Drawer
      title="Import"
      visible
      onClose={handleClose}
      width={"100%"}
      closable
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          {!isSelectingAdsToImport && (
            <Button onClick={handleClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
          )}

          {isSelectingAdsToImport ? (
            <Button
              onClick={handleImport}
              type="primary"
              data-cy="ad-import-btn"
              disabled={!selectedAdIds.length}
              loading={isLoading}
            >
              Complete Import
            </Button>
          ) : (
            <Button
              disabled={importDisabled}
              onClick={() => setIsSelectingAdsToImport(true)}
              type="primary"
              data-cy="ad-import-btn"
            >
              {importButtonText}
            </Button>
          )}
        </div>
      }
      bodyStyle={{ overflowX: "hidden" }}
    >
      <Steps current={ads.length > 0 ? 1 : 0} style={{ marginBottom: "20px" }}>
        <Steps.Step title="Upload file" />
        <Steps.Step
          title="Import Ads"
          status={isSuccess ? "finish" : "wait"}
          icon={isLoading ? <LoadingOutlined /> : null}
        />
        <Steps.Step title="" disabled style={{ visibility: "hidden" }} />
      </Steps>

      <Divider style={{ marginLeft: "-24px", width: "110%" }} />

      <ImportCsvForm
        csvJsonData={csvRawData}
        setCsvJsonData={importData}
        csvTemplateUrl={adsImportCSVTemplateUrl}
        disabled={isSelectingAdsToImport}
      />
      <div className={styles.resultTable}>
        {isLoadingAds && (
          <div className={styles.loading}>
            <LoadingOutlined />
            <h3>Loading Ads</h3>
          </div>
        )}
        {ads.length > 0 && !isLoadingAds && (
          <>
            <h4> Ads available to be loaded into Ad Library </h4>
            <AdImportDrawerTable
              selectEnabled={isSelectingAdsToImport}
              ads={ads}
              selectedRowKeys={selectedAdIds}
              setSelectedRowKeys={setSelectedAdIds}
              setAd={setAd}
              adValidations={validations.adValidations}
              adsLoadingMedia={adsLoadingMedia}
            />
          </>
        )}
      </div>
    </Drawer>
  );
};

export default AdImportDrawer;
