import {
  SmartDisplayRounded,
  TitleRounded,
  PhotoSizeSelectActualRounded,
  CropLandscapeRounded,
  SegmentRounded,
  SelectAllRounded,
} from "@mui/icons-material";
import {
  getVariableNames,
  splitIntoComponents,
} from "screens/designStudio/designStudioV2/editorDrawer/editorDrawerContext/helpers.variables";
import { LayerElement } from "screens/designStudio/designStudioV2/types";
import { TextVariableIcon } from "shared/icons/TextVariableIcon";
import { observer } from "mobx-react-lite";

type LayerIconProps = {
  layer: LayerElement;
};

const baseIconProps = { sx: { fontSize: "medium", color: "action.active" } };

export const LayerIcon = observer(({ layer }: LayerIconProps) => {
  switch (layer.type) {
    case "video":
      return <SmartDisplayRounded {...baseIconProps} />;
    case "image":
      return <PhotoSizeSelectActualRounded {...baseIconProps} />;
    case "svg":
    case "figure":
      return <CropLandscapeRounded {...baseIconProps} />;
    case "text":
      const variables = getVariableNames(layer.text);
      const components = splitIntoComponents(layer.text);
      if (components.length > 1) return <SegmentRounded {...baseIconProps} />;
      if (variables.length === 1)
        return <TextVariableIcon {...baseIconProps} />;
      return <TitleRounded {...baseIconProps} />;
    case "group":
      return <SelectAllRounded {...baseIconProps} />;
    default:
      return <TitleRounded {...baseIconProps} />;
  }
});
