/** @jsxImportSource @emotion/react */
import { Global, css } from "@emotion/react";
import { Theme } from "theme/salesEnablement";

export const GlobalStyles = ({ theme }: { theme: Theme }) => (
  <Global
    styles={css`
      :root {
        --primary-color: ${theme.primaryColor};
        --secondary-color: ${theme.secondaryColor};
        --primary-text-color: ${theme.primaryTextColor};
        --secondary-text-color: ${theme.secondaryTextColor};
        --green-color: ${theme.greenColor};
        --red-primary-color: ${theme.redPrimaryColor};
        --red-secondary-color: ${theme.redSecondaryColor};
      }

      // ? Primary Color Override for Ant Design
      a {
        color: var(--primary-color);
      }
      a:active {
        color: var(--primary-color);
      }
      ::-moz-selection {
        background: var(--primary-color);
      }
      ::selection {
        background: var(--primary-color);
      }
      html {
        --antd-wave-shadow-color: var(--primary-color);
      }
      .ant-click-animating-node {
        box-shadow: 0 0 0 0 var(--primary-color);
      }
      @-webkit-keyframes waveEffect {
        100% {
          box-shadow: 0 0 0 var(--primary-color);
        }
      }
      @keyframes waveEffect {
        100% {
          box-shadow: 0 0 0 var(--primary-color);
        }
      }
      .ant-alert-info .ant-alert-icon {
        color: var(--primary-color);
      }
      .ant-anchor-ink-ball {
        border: 2px solid var(--primary-color);
      }
      .ant-anchor-link-active > .ant-anchor-link-title {
        color: var(--primary-color);
      }
      .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
        .ant-select-item-option-state {
        color: var(--primary-color);
      }
      .ant-badge-status-processing {
        background-color: var(--primary-color);
      }
      .ant-badge-status-processing::after {
        border: 1px solid var(--primary-color);
      }
      .ant-badge-status-blue {
        background: var(--primary-color);
      }
      .ant-ribbon {
        background-color: var(--primary-color);
      }
      .ant-ribbon-color-blue {
        color: var(--primary-color);
        background: var(--primary-color);
      }
      .ant-menu-submenu-selected {
        color: var(--primary-color);
      }
      .ant-menu-item a:hover {
        color: var(--primary-color);
      }
      .ant-menu-item > .ant-badge a:hover {
        color: var(--primary-color);
      }
      .ant-menu-item:hover,
      .ant-menu-item-active,
      .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
      .ant-menu-submenu-active,
      .ant-menu-submenu-title:hover {
        color: var(--primary-color);
      }
      .ant-menu-item-selected {
        color: var(--primary-color);
      }
      .ant-menu-item-selected a,
      .ant-menu-item-selected a:hover {
        color: var(--primary-color);
      }
      .ant-menu-submenu:hover
        > .ant-menu-submenu-title
        > .ant-menu-submenu-expand-icon,
      .ant-menu-submenu:hover
        > .ant-menu-submenu-title
        > .ant-menu-submenu-arrow {
        color: var(--primary-color);
      }
      .ant-menu-vertical .ant-menu-submenu-selected,
      .ant-menu-vertical-left .ant-menu-submenu-selected,
      .ant-menu-vertical-right .ant-menu-submenu-selected {
        color: var(--primary-color);
      }
      .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
      .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
      .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
      .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
      .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
      .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
      .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
      .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
        color: var(--primary-color);
        border-bottom: 2px solid var(--primary-color);
      }
      .ant-menu-horizontal > .ant-menu-item a:hover {
        color: var(--primary-color);
      }
      .ant-menu-horizontal > .ant-menu-item-selected a {
        color: var(--primary-color);
      }
      .ant-menu-vertical .ant-menu-item::after,
      .ant-menu-vertical-left .ant-menu-item::after,
      .ant-menu-vertical-right .ant-menu-item::after,
      .ant-menu-inline .ant-menu-item::after {
        border-right: 3px solid var(--primary-color);
      }
      .ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
        background-color: var(--primary-color);
      }
      .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
        .ant-menu-item-selected {
        background-color: var(--primary-color);
      }
      .ant-menu.ant-menu-dark .ant-menu-item-selected,
      .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
        background-color: var(--primary-color);
      }
      .ant-tooltip-blue .ant-tooltip-inner {
        background-color: var(--primary-color);
      }
      .ant-tooltip-blue .ant-tooltip-arrow-content {
        background-color: var(--primary-color);
      }
      .ant-dropdown-menu-item-selected,
      .ant-dropdown-menu-submenu-title-selected,
      .ant-dropdown-menu-item-selected > a,
      .ant-dropdown-menu-submenu-title-selected > a {
        color: var(--primary-color);
      }
      .ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
        color: var(--primary-color);
      }
      .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
      .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
      .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
        background: var(--primary-color);
      }
      .ant-btn-primary {
        background: var(--primary-color);
        border-color: var(--primary-color);
      }
      .ant-btn-link {
        color: var(--primary-color);
      }
      .ant-btn-background-ghost.ant-btn-primary {
        color: var(--primary-color);
        border-color: var(--primary-color);
      }
      .ant-picker-calendar-full
        .ant-picker-panel
        .ant-picker-cell-selected
        .ant-picker-calendar-date
        .ant-picker-calendar-date-value,
      .ant-picker-calendar-full
        .ant-picker-panel
        .ant-picker-cell-selected:hover
        .ant-picker-calendar-date
        .ant-picker-calendar-date-value,
      .ant-picker-calendar-full
        .ant-picker-panel
        .ant-picker-cell-selected
        .ant-picker-calendar-date-today
        .ant-picker-calendar-date-value,
      .ant-picker-calendar-full
        .ant-picker-panel
        .ant-picker-cell-selected:hover
        .ant-picker-calendar-date-today
        .ant-picker-calendar-date-value {
        color: var(--primary-color);
      }
      .ant-picker-calendar-full
        .ant-picker-panel
        .ant-picker-calendar-date-today {
        border-color: var(--primary-color);
      }
      .ant-radio-wrapper:hover .ant-radio,
      .ant-radio:hover .ant-radio-inner,
      .ant-radio-input:focus + .ant-radio-inner {
        border-color: var(--primary-color);
      }
      .ant-radio-checked::after {
        border: 1px solid var(--primary-color);
      }
      .ant-radio-inner::after {
        background-color: var(--primary-color);
      }
      .ant-radio-checked .ant-radio-inner {
        border-color: var(--primary-color);
      }
      .ant-radio-button-wrapper:hover {
        color: var(--primary-color);
      }
      .ant-radio-button-wrapper-checked:not(
          .ant-radio-button-wrapper-disabled
        ) {
        color: var(--primary-color);
        border-color: var(--primary-color);
      }
      .ant-radio-button-wrapper-checked:not(
          .ant-radio-button-wrapper-disabled
        )::before {
        background-color: var(--primary-color);
      }
      .ant-radio-button-wrapper-checked:not(
          .ant-radio-button-wrapper-disabled
        ):first-child {
        border-color: var(--primary-color);
      }
      .ant-radio-group-solid
        .ant-radio-button-wrapper-checked:not(
          .ant-radio-button-wrapper-disabled
        ) {
        background: var(--primary-color);
        border-color: var(--primary-color);
      }
      .ant-picker-range .ant-picker-active-bar {
        background: var(--primary-color);
      }
      .ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
        color: var(--primary-color);
        background: #e6f7ff;
        border-color: #91d5ff;
      }
      .ant-picker-panel-focused {
        border-color: var(--primary-color);
      }
      .ant-picker-header-view button:hover {
        color: var(--primary-color);
      }
      .ant-picker-cell-in-view.ant-picker-cell-today
        .ant-picker-cell-inner::before {
        border: 1px solid var(--primary-color);
      }
      .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
      .ant-picker-cell-in-view.ant-picker-cell-range-start
        .ant-picker-cell-inner,
      .ant-picker-cell-in-view.ant-picker-cell-range-end
        .ant-picker-cell-inner {
        background: var(--primary-color);
      }
      .ant-picker-today-btn {
        color: var(--primary-color);
      }
      .ant-picker-week-panel-row-selected td,
      .ant-picker-week-panel-row-selected:hover td {
        background: var(--primary-color);
      }
      .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
        color: var(--primary-color);
      }
      .ant-tag-checkable-checked {
        background-color: var(--primary-color);
      }
      .ant-tag-blue-inverse {
        color: #fff;
        background: var(--primary-color);
        border-color: var(--primary-color);
      }
      .ant-tag-processing {
        color: var(--primary-color);
        background: #e6f7ff;
        border-color: #91d5ff;
      }
      .ant-card-actions > li > span:hover {
        color: var(--primary-color);
      }
      .ant-card-actions > li > span a:not(.ant-btn):hover,
      .ant-card-actions > li > span > .anticon:hover {
        color: var(--primary-color);
      }
      .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
        color: var(--primary-color);
      }
      .ant-tabs-ink-bar {
        background: var(--primary-color);
      }
      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: var(--primary-color);
      }
      .ant-input-group-addon .ant-select-open .ant-select-selector,
      .ant-input-group-addon .ant-select-focused .ant-select-selector {
        color: var(--primary-color);
      }
      .ant-checkbox-wrapper:hover .ant-checkbox-inner,
      .ant-checkbox:hover .ant-checkbox-inner,
      .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: var(--primary-color);
      }
      .ant-checkbox-checked::after {
        border: 1px solid var(--primary-color);
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: var(--primary-color);
        border-color: var(--primary-color);
      }
      .ant-checkbox-indeterminate .ant-checkbox-inner::after {
        background-color: var(--primary-color);
      }
      .ant-form-item-is-validating.ant-form-item-has-feedback
        .ant-form-item-children-icon {
        color: var(--primary-color);
      }
      .ant-list-item-meta-title > a:hover {
        color: var(--primary-color);
      }
      .ant-spin {
        color: var(--primary-color);
      }
      .ant-spin-dot-item {
        background-color: var(--primary-color);
      }
      .ant-pagination-item:focus-visible,
      .ant-pagination-item:hover {
        border-color: var(--primary-color);
      }
      .ant-pagination-item:focus-visible a,
      .ant-pagination-item:hover a {
        color: var(--primary-color);
      }
      .ant-pagination-item-active {
        border-color: var(--primary-color);
      }
      .ant-pagination-item-active a {
        color: var(--primary-color);
      }
      .ant-pagination-jump-prev
        .ant-pagination-item-container
        .ant-pagination-item-link-icon,
      .ant-pagination-jump-next
        .ant-pagination-item-container
        .ant-pagination-item-link-icon {
        color: var(--primary-color);
      }
      .ant-pagination-prev:focus-visible .ant-pagination-item-link,
      .ant-pagination-next:focus-visible .ant-pagination-item-link,
      .ant-pagination-prev:hover .ant-pagination-item-link,
      .ant-pagination-next:hover .ant-pagination-item-link {
        color: var(--primary-color);
        border-color: var(--primary-color);
      }
      .ant-pagination-simple .ant-pagination-simple-pager input:hover {
        border-color: var(--primary-color);
      }
      .ant-message-info .anticon,
      .ant-message-loading .anticon {
        color: var(--primary-color);
      }
      .ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
        color: var(--primary-color);
      }
      .anticon.ant-notification-notice-icon-info {
        color: var(--primary-color);
      }
      .ant-page-header-back-button {
        color: var(--primary-color);
      }
      .ant-progress-steps-item-active {
        background: var(--primary-color);
      }
      .ant-progress-inner:not(.ant-progress-circle-gradient)
        .ant-progress-circle-path {
        stroke: var(--primary-color);
      }
      .ant-progress-success-bg,
      .ant-progress-bg {
        background-color: var(--primary-color);
      }
      .ant-result-info .ant-result-icon > .anticon {
        color: var(--primary-color);
      }
      .ant-slider-handle.ant-tooltip-open {
        border-color: var(--primary-color);
      }
      .ant-steps-item-icon .ant-steps-icon {
        color: var(--primary-color);
      }
      .ant-steps-item-process .ant-steps-item-icon {
        border-color: var(--primary-color);
      }
      .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
        color: var(--primary-color);
      }
      .ant-steps-item-process
        .ant-steps-item-icon
        > .ant-steps-icon
        .ant-steps-icon-dot {
        background: var(--primary-color);
      }
      .ant-steps-item-process
        > .ant-steps-item-container
        > .ant-steps-item-icon {
        background: var(--primary-color);
      }
      .ant-steps-item-finish .ant-steps-item-icon {
        border-color: var(--primary-color);
      }
      .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
        color: var(--primary-color);
      }
      .ant-steps-item-finish
        .ant-steps-item-icon
        > .ant-steps-icon
        .ant-steps-icon-dot {
        background: var(--primary-color);
      }
      .ant-steps-item-finish
        > .ant-steps-item-container
        > .ant-steps-item-content
        > .ant-steps-item-title::after {
        background-color: var(--primary-color);
      }
      .ant-steps-item-finish
        > .ant-steps-item-container
        > .ant-steps-item-tail::after {
        background-color: var(--primary-color);
      }
      .ant-steps
        .ant-steps-item:not(.ant-steps-item-active)
        > .ant-steps-item-container[role="button"]:hover
        .ant-steps-item-title,
      .ant-steps
        .ant-steps-item:not(.ant-steps-item-active)
        > .ant-steps-item-container[role="button"]:hover
        .ant-steps-item-subtitle,
      .ant-steps
        .ant-steps-item:not(.ant-steps-item-active)
        > .ant-steps-item-container[role="button"]:hover
        .ant-steps-item-description {
        color: var(--primary-color);
      }
      .ant-steps
        .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
        > .ant-steps-item-container[role="button"]:hover
        .ant-steps-item-icon {
        border-color: var(--primary-color);
      }
      .ant-steps
        .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
        > .ant-steps-item-container[role="button"]:hover
        .ant-steps-item-icon
        .ant-steps-icon {
        color: var(--primary-color);
      }
      .ant-steps-item-custom.ant-steps-item-process
        .ant-steps-item-icon
        > .ant-steps-icon {
        color: var(--primary-color);
      }
      .ant-steps-navigation .ant-steps-item::before {
        background-color: var(--primary-color);
      }
      .ant-switch-checked {
        background-color: var(--primary-color);
      }
      .ant-switch-checked .ant-switch-loading-icon {
        color: var(--primary-color);
      }
      .ant-table-column-sorter-up.active,
      .ant-table-column-sorter-down.active {
        color: var(--primary-color);
      }
      .ant-table-filter-trigger.active {
        color: var(--primary-color);
      }
      .ant-table-row-expand-icon {
        color: var(--primary-color);
      }
      .ant-timeline-item-head-blue {
        color: var(--primary-color);
        border-color: var(--primary-color);
      }
      .ant-transfer-list-content-item-remove {
        color: var(--primary-color);
      }
      .ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
      .ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
      .ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
        border-color: var(--primary-color);
      }
      .ant-select-tree-checkbox-checked::after {
        border: 1px solid var(--primary-color);
      }
      .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
        background-color: var(--primary-color);
        border-color: var(--primary-color);
      }
      .ant-select-tree-checkbox-indeterminate
        .ant-select-tree-checkbox-inner::after {
        background-color: var(--primary-color);
      }
      .ant-select-tree-switcher-loading-icon {
        color: var(--primary-color);
      }
      .ant-select-tree-node-content-wrapper[draggable="true"]
        .ant-tree-drop-indicator {
        background-color: var(--primary-color);
      }
      .ant-select-tree-node-content-wrapper[draggable="true"]
        .ant-tree-drop-indicator::after {
        border: 2px solid var(--primary-color);
      }
      .ant-select-tree .ant-select-tree-treenode.drop-container > [draggable] {
        box-shadow: 0 0 0 2px var(--primary-color);
      }
      .ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
      .ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
        background: var(--primary-color);
      }
      .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
      .ant-tree-checkbox:hover .ant-tree-checkbox-inner,
      .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
        border-color: var(--primary-color);
      }
      .ant-tree-checkbox-checked::after {
        border: 1px solid var(--primary-color);
      }
      .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
        background-color: var(--primary-color);
        border-color: var(--primary-color);
      }
      .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
        background-color: var(--primary-color);
      }
      .ant-tree-switcher-loading-icon {
        color: var(--primary-color);
      }
      .ant-tree-node-content-wrapper[draggable="true"]
        .ant-tree-drop-indicator {
        background-color: var(--primary-color);
      }
      .ant-tree-node-content-wrapper[draggable="true"]
        .ant-tree-drop-indicator::after {
        border: 2px solid var(--primary-color);
      }
      .ant-tree .ant-tree-treenode.drop-container > [draggable] {
        box-shadow: 0 0 0 2px var(--primary-color);
      }
      a.ant-typography,
      .ant-typography a {
        color: var(--primary-color);
      }
      .ant-typography-expand,
      .ant-typography-edit,
      .ant-typography-copy {
        color: var(--primary-color);
      }
      .ant-upload.ant-upload-select-picture-card:hover {
        border-color: var(--primary-color);
      }
      .ant-upload-list-picture
        .ant-upload-list-item-error
        .ant-upload-list-item-thumbnail
        .anticon
        svg
        path[fill="var(--primary-color)"],
      .ant-upload-list-picture-card
        .ant-upload-list-item-error
        .ant-upload-list-item-thumbnail
        .anticon
        svg
        path[fill="var(--primary-color)"] {
        fill: #ff4d4f;
      }

      .ant-btn:hover,
      .ant-btn:focus {
        color: var(--secondary-color);
        border-color: var(--secondary-color);
      }

      .ant-btn:active {
        color: var(--primary-color);
        border-color: var(--primary-color);
      }
      .ant-btn-primary:active {
        color: var(--primary-color);
        border-color: var(--primary-color);
      }
      .ant-btn-link:active {
        color: var(--primary-color);
      }
      .ant-btn-ghost:active {
        color: var(--primary-color);
        border-color: var(--primary-color);
      }
      .ant-btn-dashed:active {
        color: var(--primary-color);
        border-color: var(--primary-color);
      }
      .ant-btn-text:active {
        color: var(--primary-color);
        border-color: var(--primary-color);
      }
      .ant-btn-background-ghost.ant-btn-primary:active {
        color: var(--primary-color);
        border-color: var(--primary-color);
      }
      .ant-radio-button-wrapper-checked:not(
          .ant-radio-button-wrapper-disabled
        ):active {
        color: var(--primary-color);
        border-color: var(--primary-color);
      }
      .ant-radio-button-wrapper-checked:not(
          .ant-radio-button-wrapper-disabled
        ):active::before {
        background-color: var(--primary-color);
      }
      .ant-radio-group-solid
        .ant-radio-button-wrapper-checked:not(
          .ant-radio-button-wrapper-disabled
        ):active {
        background: var(--primary-color);
        border-color: var(--primary-color);
      }
      .ant-picker-today-btn:active {
        color: var(--primary-color);
      }
      .ant-tag-checkable:active {
        background-color: var(--primary-color);
      }
      .ant-tag-blue {
        color: var(--primary-color);
      }
      .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:active,
      .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:active,
      .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:focus,
      .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:focus {
        color: var(--primary-color);
      }
      .ant-tabs-tab-btn:focus,
      .ant-tabs-tab-remove:focus,
      .ant-tabs-tab-btn:active,
      .ant-tabs-tab-remove:active {
        color: var(--primary-color);
      }
      .ant-page-header-back-button:active {
        color: var(--primary-color);
      }
      .ant-table-row-expand-icon:active {
        color: var(--primary-color);
      }
      .ant-transfer-list-content-item-remove:active {
        color: var(--primary-color);
      }
      a.ant-typography:active,
      .ant-typography a:active {
        color: var(--primary-color);
      }
      .ant-typography-expand:active,
      .ant-typography-edit:active,
      .ant-typography-copy:active {
        color: var(--primary-color);
      }
      .ant-upload.ant-upload-drag.ant-upload-drag-hover:not(
          .ant-upload-disabled
        ) {
        border-color: var(--primary-color);
      }

      // ? Secondary Color Override for Ant Design
      a:hover {
        color: var(--secondary-color);
      }
      .ant-select-focused:not(.ant-select-disabled).ant-select:not(
          .ant-select-customize-input
        )
        .ant-select-selector {
        border-color: var(--secondary-color);
      }
      .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: var(--secondary-color);
      }
      .ant-breadcrumb a:hover {
        color: var(--secondary-color);
      }
      .ant-btn-primary:hover,
      .ant-btn-primary:focus {
        color: #fff;
        background: var(--secondary-color);
        border-color: var(--secondary-color);
      }
      .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
        border-right-color: var(--secondary-color);
        border-left-color: var(--secondary-color);
      }
      .ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
        border-right-color: var(--secondary-color);
      }
      .ant-btn-group .ant-btn-primary:last-child:not(:first-child),
      .ant-btn-group .ant-btn-primary + .ant-btn-primary {
        border-left-color: var(--secondary-color);
      }
      .ant-btn-ghost:hover,
      .ant-btn-ghost:focus {
        color: var(--secondary-color);
        border-color: var(--secondary-color);
      }
      .ant-btn-dashed:hover,
      .ant-btn-dashed:focus {
        color: var(--secondary-color);
        border-color: var(--secondary-color);
      }
      .ant-btn-link:hover,
      .ant-btn-link:focus {
        color: var(--secondary-color);
        background: transparent;
        border-color: transparent;
      }
      .ant-btn-text:hover,
      .ant-btn-text:focus {
        color: var(--secondary-color);
        border-color: var(--secondary-color);
      }
      .ant-btn-background-ghost.ant-btn-primary:hover,
      .ant-btn-background-ghost.ant-btn-primary:focus {
        color: var(--secondary-color);
        border-color: var(--secondary-color);
      }
      .ant-btn-group-rtl.ant-btn-group
        .ant-btn-primary:last-child:not(:first-child),
      .ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary {
        border-right-color: var(--secondary-color);
        border-left-color: #d9d9d9;
      }
      .ant-btn-group-rtl.ant-btn-group
        .ant-btn-primary:last-child:not(:first-child)[disabled],
      .ant-btn-group-rtl.ant-btn-group
        .ant-btn-primary
        + .ant-btn-primary[disabled] {
        border-right-color: #d9d9d9;
        border-left-color: var(--secondary-color);
      }
      .ant-radio-button-wrapper-checked:not(
          .ant-radio-button-wrapper-disabled
        ):hover {
        color: var(--secondary-color);
        border-color: var(--secondary-color);
      }
      .ant-radio-button-wrapper-checked:not(
          .ant-radio-button-wrapper-disabled
        ):hover::before {
        background-color: var(--secondary-color);
      }
      .ant-radio-group-solid
        .ant-radio-button-wrapper-checked:not(
          .ant-radio-button-wrapper-disabled
        ):hover {
        background: var(--secondary-color);
        border-color: var(--secondary-color);
      }
      .ant-radio-button-wrapper-checked:not(
          [class*=" ant-radio-button-wrapper-disabled"]
        ).ant-radio-button-wrapper:first-child {
        border-right-color: var(--secondary-color);
      }
      .ant-picker:hover,
      .ant-picker-focused {
        border-color: var(--secondary-color);
      }
      .ant-picker-focused {
        border-color: var(--secondary-color);
      }
      .ant-picker-input > input:hover {
        border-color: var(--secondary-color);
      }
      .ant-picker-input > input:focus,
      .ant-picker-input > input-focused {
        border-color: var(--secondary-color);
      }
      .ant-picker-today-btn:hover {
        color: var(--secondary-color);
      }
      .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:hover,
      .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:hover {
        color: var(--secondary-color);
      }
      .ant-tabs-tab:hover {
        color: var(--secondary-color);
      }
      .ant-cascader-picker:focus .ant-cascader-input {
        border-color: var(--secondary-color);
      }
      .ant-cascader-picker-label:hover
        + .ant-cascader-input:not(
          .ant-cascader-picker-disabled
            .ant-cascader-picker-label:hover
            + .ant-cascader-input
        ) {
        border-color: var(--secondary-color);
      }
      .ant-input-affix-wrapper:hover {
        border-color: var(--secondary-color);
      }
      .ant-input-affix-wrapper:focus,
      .ant-input-affix-wrapper-focused {
        border-color: var(--secondary-color);
      }
      .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
        border-color: var(--secondary-color);
      }
      .ant-input:hover {
        border-color: var(--secondary-color);
      }
      .ant-input:focus,
      .ant-input-focused {
        border-color: var(--secondary-color);
      }
      .ant-input-search .ant-input:hover,
      .ant-input-search .ant-input:focus {
        border-color: var(--secondary-color);
      }
      .ant-input-search
        .ant-input:hover
        + .ant-input-group-addon
        .ant-input-search-button:not(.ant-btn-primary),
      .ant-input-search
        .ant-input:focus
        + .ant-input-group-addon
        .ant-input-search-button:not(.ant-btn-primary) {
        border-left-color: var(--secondary-color);
      }
      .ant-input-search-rtl
        .ant-input:hover
        + .ant-input-group-addon
        .ant-input-search-button:not(.ant-btn-primary),
      .ant-input-search-rtl
        .ant-input:focus
        + .ant-input-group-addon
        .ant-input-search-button:not(.ant-btn-primary) {
        border-right-color: var(--secondary-color);
        border-left-color: #d9d9d9;
      }
      .ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper:hover,
      .ant-input-search-rtl
        > .ant-input-group
        > .ant-input-affix-wrapper-focused {
        border-right-color: var(--secondary-color);
      }
      .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):hover {
        border-color: var(--secondary-color);
      }
      .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):focus {
        border-color: var(--secondary-color);
      }
      .ant-input-number:hover {
        border-color: var(--secondary-color);
      }
      .ant-input-number:focus,
      .ant-input-number-focused {
        border-color: var(--secondary-color);
      }
      .ant-input-number-handler:hover .ant-input-number-handler-up-inner,
      .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
        color: var(--secondary-color);
      }
      .ant-input-number:hover {
        border-color: var(--secondary-color);
      }
      .ant-input-number-focused {
        border-color: var(--secondary-color);
      }
      .ant-pagination-item-active:focus-visible,
      .ant-pagination-item-active:hover {
        border-color: var(--secondary-color);
      }
      .ant-pagination-item-active:focus-visible a,
      .ant-pagination-item-active:hover a {
        color: var(--secondary-color);
      }
      .ant-pagination-prev:hover button,
      .ant-pagination-next:hover button {
        border-color: var(--secondary-color);
      }
      .ant-pagination-options-quick-jumper input:hover {
        border-color: var(--secondary-color);
      }
      .ant-pagination-options-quick-jumper input:focus,
      .ant-pagination-options-quick-jumper input-focused {
        border-color: var(--secondary-color);
      }
      .ant-mentions:hover {
        border-color: var(--secondary-color);
      }
      .ant-mentions:focus,
      .ant-mentions-focused {
        border-color: var(--secondary-color);
      }
      .ant-mentions-focused {
        border-color: var(--secondary-color);
      }
      .ant-page-header-back-button:focus,
      .ant-page-header-back-button:hover {
        color: var(--secondary-color);
      }
      .ant-table-row-expand-icon:focus,
      .ant-table-row-expand-icon:hover {
        color: var(--secondary-color);
      }
      .ant-transfer-list-content-item-remove:focus,
      .ant-transfer-list-content-item-remove:hover {
        color: var(--secondary-color);
      }
      .ant-transfer-list-content-item-remove:hover {
        color: var(--secondary-color);
      }
      a.ant-typography:focus,
      .ant-typography a:focus,
      a.ant-typography:hover,
      .ant-typography a:hover {
        color: var(--secondary-color);
      }
      .ant-typography-expand:focus,
      .ant-typography-edit:focus,
      .ant-typography-copy:focus,
      .ant-typography-expand:hover,
      .ant-typography-edit:hover,
      .ant-typography-copy:hover {
        color: var(--secondary-color);
      }
      .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
        border-color: var(--secondary-color);
      }
      .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
        color: var(--secondary-color);
      }

      // ? Success Color Override for Ant Design
      .ant-badge-status-success {
        background: var(--green-color);
      }
      .ant-badge-status-green {
        background: var(--green-color);
      }

      // ? Placeholder Color Override for Ant Design

      .ant-select-single.ant-select-open .ant-select-selection-item {
        color: var(--secondary-text-color);
      }
      .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
        color: var(--secondary-text-color);
      }
      .ant-select-selection-placeholder {
        color: var(--secondary-text-color);
      }
      .ant-picker-input > input:-ms-input-placeholder {
        color: var(--secondary-text-color);
      }
      .ant-picker-input > input::placeholder {
        color: var(--secondary-text-color);
      }
      .ant-picker-input-placeholder > input {
        color: var(--secondary-text-color);
      }
      .ant-input-affix-wrapper:-ms-input-placeholder {
        color: var(--secondary-text-color);
      }
      .ant-input-affix-wrapper::placeholder {
        color: var(--secondary-text-color);
      }
      .ant-input:-ms-input-placeholder {
        color: var(--secondary-text-color);
      }
      .ant-input::placeholder {
        color: var(--secondary-text-color);
      }
      .ant-input-number:-ms-input-placeholder {
        color: var(--secondary-text-color);
      }
      .ant-input-number::placeholder {
        color: var(--secondary-text-color);
      }
      .ant-input-number-input:-ms-input-placeholder {
        color: var(--secondary-text-color);
      }
      .ant-input-number-input::placeholder {
        color: var(--secondary-text-color);
      }
      .ant-pagination-options-quick-jumper input:-ms-input-placeholder {
        color: var(--secondary-text-color);
      }
      .ant-pagination-options-quick-jumper input::placeholder {
        color: var(--secondary-text-color);
      }
      .ant-mentions:-ms-input-placeholder {
        color: var(--secondary-text-color);
      }
      .ant-mentions::placeholder {
        color: var(--secondary-text-color);
      }
      .ant-mentions > textarea:-ms-input-placeholder {
        color: var(--secondary-text-color);
      }
      .ant-mentions > textarea::placeholder {
        color: var(--secondary-text-color);
      }
      .ant-skeleton-element .ant-skeleton-image-path {
        fill: var(--secondary-text-color);
      }
      .ant-table-selection-extra .anticon {
        color: var(--secondary-text-color);
      }

      // ? Danger Color Override for Ant Design
      .ant-btn-dangerous,
      .ant-btn-dangerous:active,
      .ant-btn-dangerous.ant-btn-link:active,
      .ant-btn-dangerous.ant-btn-text:active {
        color: var(--red-primary-color);
        border-color: var(--red-primary-color);
      }

      .ant-btn-dangerous:hover,
      .ant-btn-dangerous:focus,
      .ant-btn-dangerous.ant-btn-link:hover,
      .ant-btn-dangerous.ant-btn-link:focus,
      .ant-btn-dangerous.ant-btn-text:hover,
      .ant-btn-dangerous.ant-btn-text:focus {
        color: var(--red-secondary-color);
        border-color: var(--red-secondary-color);
      }
    `}
  />
);
