import { UseQueryResult, useQueries } from "react-query";
import {
  convertDropboxUrl,
  getGoogleDriveFileExtension,
  getGoogleDriveFileUrl,
  isGDriveUrl,
} from "utils/helpers.adEngine";
import { getFileExtensionFromUrl } from "../../assetBatchDrawer/utils";
import { MediaUrlData } from "shared/types/assetExport";

const fetchMediaUrlData = async (
  mediaUrl?: string,
): Promise<MediaUrlData | undefined> => {
  if (!mediaUrl) return;

  const isGdriveSrc = isGDriveUrl(mediaUrl);
  const ext = isGdriveSrc
    ? await getGoogleDriveFileExtension(mediaUrl)
    : getFileExtensionFromUrl(mediaUrl);

  const src = isGdriveSrc
    ? await getGoogleDriveFileUrl(mediaUrl)
    : convertDropboxUrl(mediaUrl);

  return {
    originalUrl: mediaUrl,
    url: src,
    ext,
  };
};

export const useMediaUrlData = (
  mediaUrls: string[],
): UseQueryResult<MediaUrlData | undefined>[] => {
  return useQueries(
    mediaUrls?.map(url => ({
      queryKey: ["useMediaUrlData", url],
      queryFn: () => fetchMediaUrlData(url),
    })),
  );
};
