import ReactPlayer from "react-player";
import { Ellipsis } from "../Ellipsis";
import { getPreviewThumbnail } from "../getPreviewThumbnail";
import { mixedImage } from "../images/mixedImage";
import { handleImgError } from "../handleImgError";
import { getAssetDetails } from "./utils";

import styles from "./AssetDetail.module.scss";

interface Props {
  assets: WDAsset[];
}

export const AssetDetail = ({ assets }: Props) => {
  const [asset] = assets;
  const isMixed = assets.length > 1;
  const previewImg = getPreviewThumbnail(asset);
  const assetDetails = getAssetDetails(assets);

  return (
    <div className={styles.host}>
      <h4>Preview</h4>
      <div className={styles.preview}>
        {previewImg.isVideo && !isMixed ? (
          <div>
            <ReactPlayer
              controls
              height="430px"
              width="100%"
              playing={true}
              muted={true}
              loop={true}
              key={previewImg.url}
              url={previewImg.url}
            />
          </div>
        ) : (
          <img
            className={styles.img}
            onError={handleImgError}
            src={isMixed ? mixedImage : previewImg.url}
            data-mixed={isMixed}
            alt="Asset image"
          />
        )}
      </div>
      <div className={styles.details}>
        {assetDetails.map(([key, value]) => {
          return (
            <div key={key}>
              <strong>{key}</strong>
              <Ellipsis>{value}</Ellipsis>
            </div>
          );
        })}
      </div>
    </div>
  );
};
