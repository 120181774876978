import { useState } from "react";
import { StoreType } from "polotno/model/store";
import { observer } from "mobx-react-lite";
import { useMutation } from "react-query";
import { useDesignStudioEditorItems } from "screens/designStudio/designStudioLayout/designStudioTable/hooks/useDesignStudioEditorItems";
import { toPNGBlobInputs } from "screens/designStudio/designStudioV2/editorDrawer/editorDrawerContext/helpers.store";
import { Stack, Typography } from "@mui/material";
import { BlobInput } from "shared/components/media/CAMUpload/useUpload";
import CloseIcon from "@mui/icons-material/Close";
import { FolderPathSection } from "./export/FolderPathSection";
import { MetaFormSection } from "./export/MetaFormSection";
import { useCustomCAMUpload } from "./export/useCustomCAMUpload";

type ExportProps = {
  store: StoreType;
};

export const Export = observer(({ store }: ExportProps) => {
  const { activeItem } = useDesignStudioEditorItems();
  const [folderPath, setFolderPath] = useState("");
  const [changeFolder, setChangeFolder] = useState(false);

  const { mutate: getBlob, data: blobInputs } = useMutation<BlobInput[]>(() =>
    toPNGBlobInputs(store, activeItem?.asset.name ?? ""),
  );

  const {
    uploading,
    uploadFiles,
    setFolderId,
    setMeta,
    notification,
    handleNotificationClose,
  } = useCustomCAMUpload({
    files: blobInputs || [],
    folderPath: folderPath,
  });

  const handleExportClick = () => {
    if (blobInputs) {
      uploadFiles();
    }
  };

  const handleFolderSelection = (
    selectedId: string | number,
    selectedPath: string,
  ) => {
    setFolderId(String(selectedId));
    setFolderPath(selectedPath);
    setChangeFolder(false);
    if (selectedPath) {
      getBlob();
    }
  };

  return (
    <>
      <Stack sx={{ py: 2 }} spacing={2}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography>Select destination folder in CAM</Typography>
          {folderPath && (
            <CloseIcon
              onClick={() => setFolderPath("")}
              sx={{ cursor: "pointer", color: "gray" }}
            >
              X
            </CloseIcon>
          )}
        </Stack>
        <FolderPathSection
          folderPath={folderPath}
          onChangeFolderPath={() => setChangeFolder(true)}
          onSelect={handleFolderSelection}
          changeFolder={changeFolder}
        />
        {folderPath && (
          <MetaFormSection
            onChange={setMeta}
            onExportClick={handleExportClick}
            isUploading={uploading}
            notification={notification}
            handleNotificationClose={handleNotificationClose}
          />
        )}
      </Stack>
    </>
  );
});
