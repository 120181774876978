import { Drawer, Button, Space, Checkbox, Input, message } from "antd";
import { DateRangeFilter } from "shared/components/salesEnablement/filter/DateRangeFilter";
import { TextFilter } from "shared/components/salesEnablement/filter/TextFilter";
import { useFilterFields } from "./filterDrawer/useFilterFields";
import { SelectFilter } from "shared/components/salesEnablement/filter/SelectFilter";
import { useCallback, useMemo, useState } from "react";
import styles from "./FilterDrawer.module.scss";
import useFilterPresets, {
  FilterPreset,
} from "../DrawerContext/useFilterPresets";
import { DeleteOutlined } from "@ant-design/icons";
import classNames from "classnames";
import uuid from "uuid";

type FilterDrawerProps = {
  isOpen: boolean;
  closeDrawer: () => void;
};

export const FilterDrawer = ({
  isOpen,
  closeDrawer: closeDrawerProp,
}: FilterDrawerProps) => {
  const {
    filterFields,
    prefilteredItems,
    tempFilters,
    setTempFilters,
    hasUncommittedFilters,
    updateTempFilter,
    filters,
    setFilters,
    resetFilters,
  } = useFilterFields();

  const [saveFiltersChecked, setSaveFiltersChecked] = useState(false);
  const [presetName, setPresetName] = useState<string>();
  const {
    presets,
    loading,
    deletePreset,
    selectedPreset,
    setSelectedPreset,
    createPreset,
  } = useFilterPresets("design-studio");

  const closeDrawer = () => {
    setSaveFiltersChecked(false);
    setPresetName(undefined);
    closeDrawerProp();
  };

  const onPresetClear = useCallback(() => {
    setSelectedPreset(undefined);
    setTempFilters({});
  }, [setSelectedPreset, setTempFilters]);

  const options = useMemo(
    () =>
      presets.map(({ id, name, feature }) => {
        const isSelected = selectedPreset?.name === name;
        return {
          key: `preset-option-${name}`,
          value: name,
          label: (
            <div
              className={classNames(
                styles.savedFilterOption,
                `${isSelected ? "selected" : ""}`,
              )}
            >
              <span>{name}</span>
              <span className={styles.deleteButton}>
                <Button
                  type="text"
                  size="small"
                  icon={<DeleteOutlined />}
                  onClick={async e => {
                    e.preventDefault();
                    e.stopPropagation();

                    // delete and then de-select the preset to make sure the selection is cleared.
                    deletePreset({ id, feature });
                    onPresetClear();
                  }}
                />
              </span>
            </div>
          ),
        };
      }),
    [presets, selectedPreset, deletePreset, onPresetClear],
  );

  return (
    <Drawer
      className={styles.filterDrawer}
      title="Filter by:"
      width={500}
      placement="right"
      closable={true}
      destroyOnClose
      afterVisibleChange={() => setTempFilters(filters)}
      onClose={closeDrawer}
      visible={isOpen}
      footerStyle={{ display: "flex", justifyContent: "flex-end" }}
      footer={
        <Space className={styles.footer}>
          <Button
            type="ghost"
            onClick={() => {
              setTempFilters(filters);
              closeDrawer();
            }}
          >
            Cancel
          </Button>
          <Space>
            <Button
              danger
              onClick={() => {
                resetFilters();
                setSelectedPreset(undefined);
                closeDrawer();
              }}
            >
              Clear filters
            </Button>
            <Button
              type="primary"
              onClick={async () => {
                if (saveFiltersChecked) {
                  if (!presetName?.trim()) {
                    message.error("Please enter a name for the preset");

                    return;
                  }

                  message.loading("Saving filters...");

                  const preset: FilterPreset = {
                    name: presetName,
                    id: uuid.v4(),
                    filters: tempFilters,
                    feature: "design-studio",
                  };
                  createPreset(preset, {
                    onSuccess: () => {
                      message.destroy();
                    },
                  });
                }

                setFilters(tempFilters as typeof filters);
                closeDrawer();
              }}
            >
              {hasUncommittedFilters
                ? `Show ${prefilteredItems.length} results`
                : "Show results"}
            </Button>
          </Space>
        </Space>
      }
    >
      <div className={styles.savedFilters}>
        <SelectFilter<string>
          disabled={loading}
          loading={loading}
          value={selectedPreset?.name}
          key="saved-filters"
          title="Saved filters"
          onSelect={(value: string) => {
            const preset = presets.find(preset => preset.name === value);
            if (!preset) return;

            setSelectedPreset(preset);
            const { filters } = preset;
            if (!filters) return;

            setTempFilters(filters);
          }}
          onClear={onPresetClear}
          options={options}
        />
      </div>

      {filterFields.map(filter => {
        if (filter.type === "text") {
          return (
            <TextFilter
              key={filter.key}
              title={filter.title}
              value={filter.value}
              onChange={e => updateTempFilter("name", [e.target.value])}
            />
          );
        }

        if (filter.type === "date") {
          return (
            <DateRangeFilter
              key={filter.key}
              title={filter.title}
              value={filter.value}
              onChange={(value: string[]) =>
                updateTempFilter(filter.key, value)
              }
            />
          );
        }
        return (
          <SelectFilter
            mode="multiple"
            key={filter.key}
            title={filter.title}
            value={filter.value}
            options={filter.options}
            onChange={(value: string[]) => {
              updateTempFilter(filter.key, value);
            }}
          />
        );
      })}

      {!selectedPreset && (
        <div className={styles.saveFiltersCheckboxContainer}>
          <div className={styles.checkbox}>
            <Checkbox
              checked={saveFiltersChecked}
              onChange={e => setSaveFiltersChecked(e.target.checked)}
            >
              Save filters
            </Checkbox>
          </div>

          {saveFiltersChecked && (
            <>
              <div className={styles.divider} />

              <div className={styles.filtersNameInput}>
                <Input
                  placeholder="Choose a name"
                  value={presetName}
                  onChange={e => setPresetName(e.target.value)}
                />
              </div>
            </>
          )}
        </div>
      )}
    </Drawer>
  );
};
