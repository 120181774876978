import { notification } from "antd";
import { useCallback, useState } from "react";
import { useLayersWithData } from "screens/designStudio/hooks/useLayersWithData";
import { useFetchTemplates } from "shared/hooks/designStudio/useFetchTemplates";
import { MarketingMaterialTableItem } from "shared/types/marketingMaterials";
import { longAlert } from "utils/antd/longAlert/longAlert";
import { useMarketingMaterialActions } from "./useMarketingMaterialActions";
import {
  getDeliveriesFromMaterials,
  openNotificationProgress,
  pdfDownloadProgressKey,
} from "../utils/pdfDelivery.utils";
import moment from "moment-timezone";

export const usePdfDeliveryData = () => {
  const { templates } = useFetchTemplates();
  const { getLayersWithData } = useLayersWithData();
  const { deliverMaterials } = useMarketingMaterialActions();
  const [isLoading, setIsLoading] = useState(false);

  const getPdfDeliveryData = useCallback(
    async (selectedMarketingMaterials: MarketingMaterialTableItem[]) => {
      try {
        if (!selectedMarketingMaterials.length) return;
        const type = selectedMarketingMaterials.length == 1 ? "pdf" : "zip";
        setIsLoading(true);
        openNotificationProgress(0, type);

        const deliveries = getDeliveriesFromMaterials({
          materials: selectedMarketingMaterials,
          templates,
          getLayersWithData,
        });

        const { result } = await deliverMaterials({
          deliveries: deliveries.map(({ deliveryData }) => deliveryData),
        });

        downloadFileData({
          url: result,
          filename:
            deliveries.length > 1
              ? `Delivery ${moment().format("MM/DD/YYYY")}`
              : deliveries[0].material.name,
          type,
        });
        openNotificationProgress(100, type);
        longAlert({
          type: "success",
          header:
            "File(s) successfully downloaded to your device. To retrieve your downloaded material(s), go to your downloads folder.",
        });
        setIsLoading(false);
      } catch {
        notification.close(pdfDownloadProgressKey);
        notification.error({
          message: "Download has failed.",
          description: "Please try again in a few minutes",
        });
        setIsLoading(false);
      }
    },
    [templates, getLayersWithData, deliverMaterials],
  );

  const downloadFileData = ({
    url,
    filename,
    type,
  }: {
    url: string;
    filename: string;
    type: "pdf" | "zip";
  }) => {
    const link = document.createElement("a");
    link.download = filename ? `${filename}.${type}` : url;
    link.href = url;
    link.target = "_blank";
    link.click();
  };

  return {
    getPdfDeliveryData,
    isLoading,
  };
};
