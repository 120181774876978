import { MediaSubtype, TAudioFile } from "shared/types/assetExporter";

export const useVideoCompositionAudios = () => {
  const createAudioElement = (
    id: string | undefined,
    url: string,
  ): HTMLAudioElement => {
    let audioEle = id
      ? (document.getElementById(id) as HTMLAudioElement)
      : null;
    if (!audioEle) {
      audioEle = document.createElement("audio");
      audioEle.id = id ?? "";
    }
    audioEle.src = url;
    return audioEle;
  };

  const updateAudioFiles = (
    setAudioFiles: React.Dispatch<React.SetStateAction<TAudioFile[]>>,
    id: string | undefined,
    newAudioFile: TAudioFile,
  ) => {
    setAudioFiles(prev => {
      const index = prev.findIndex(file => file.id === id);
      if (index !== -1) {
        const newAudioFiles = [...prev];
        newAudioFiles[index] = newAudioFile;
        return newAudioFiles;
      }
      return [...prev, newAudioFile];
    });
  };

  const createNewAudioFile = (
    type: MediaSubtype,
    url: string,
    name: string,
    id: string | undefined,
    setAudioFiles: React.Dispatch<React.SetStateAction<TAudioFile[]>>,
    start?: number,
    end?: number,
    column?: string,
  ) => {
    const audioEle = createAudioElement(id, url);

    audioEle.onloadedmetadata = function () {
      const newAudioFile: TAudioFile = {
        id: id ?? "",
        name: name,
        src: audioEle?.src ?? url,
        start: start ?? 0,
        end: end ?? Math.floor((audioEle?.duration ?? 0) * 1000),
        column,
        file: audioEle,
        type,
      };
      updateAudioFiles(setAudioFiles, id, newAudioFile);
    };

    if (type === MediaSubtype.Feed && !url) {
      const newAudioFile = {
        id: id ?? "",
        name: "",
        start: 0,
        end: 0,
        file: undefined,
        type,
        src: "",
        column: undefined,
      };
      updateAudioFiles(setAudioFiles, id, newAudioFile);
    }
  };

  const processAudioFiles = (
    audioFiles: TAudioFile[],
    setAudioFiles: React.Dispatch<React.SetStateAction<TAudioFile[]>>,
  ) => {
    audioFiles.forEach(audioFile => {
      createNewAudioFile(
        audioFile.type ?? MediaSubtype.CAM,
        audioFile.src,
        audioFile.name,
        audioFile.id,
        setAudioFiles,
        audioFile.start,
        audioFile.end,
        audioFile.column,
      );
    });
  };

  return {
    processAudioFiles,
    createNewAudioFile,
  };
};
