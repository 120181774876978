import { AdType } from "screens/adLibrary/facebookUtils/types";
import { useCurrentSession } from "./useCurrentSession";
import { isValidUrl } from "utils/validators";
import { isTruthy, typedGroupBy } from "utils/helpers.array";
import { useMemo } from "react";

type IssueTarget = {
  adId: string;
  storeName: string;
  elementId?: string;
  cardIndex?: number;
};

export type AdLoadIssue = {
  target: IssueTarget;
  status: "error" | "warning";
  field: "destinationUrl" | "displayUrl" | "callToAction" | "productSetId";
  message: string;
};

export const useAdLoadIssues = () => {
  const { session } = useCurrentSession();
  const issues = useMemo(
    () =>
      session.data.adProtoDrafts
        .flatMap<AdLoadIssue | undefined>(adProto => {
          if (adProto.dests.length === 0) return [];

          const adProtoType = adProto.type;
          switch (adProtoType) {
            case AdType.Still:
            case AdType.DPA:
            case AdType.AIA:
            case AdType.Video: {
              const { destinationUrl, displayUrl, callToAction } = adProto;
              const target = {
                adId: adProto.id,
                storeName: adProto.storeName,
              };
              return [
                getDestinationUrlIssue(destinationUrl, target),
                getDisplayUrlIssue(displayUrl, target),
                getCallToActionIssue(callToAction, target),
              ];
            }
            case AdType.Carousel: {
              const { callToAction } = adProto;
              return [
                getCallToActionIssue(callToAction, {
                  adId: adProto.id,
                  storeName: adProto.storeName,
                }),
                ...adProto.cards.flatMap((card, index) => {
                  const { destinationUrl } = card;
                  return getDestinationUrlIssue(destinationUrl, {
                    adId: adProto.id,
                    storeName: adProto.storeName,
                    cardIndex: index,
                  });
                }),
              ];
            }
            case AdType.InstantExperience:
            case AdType.Collection: {
              if (adProto.convertToVideo) {
                const target = {
                  adId: adProto.id,
                  storeName: adProto.storeName,
                };
                return [
                  getDestinationUrlIssue(adProto.destinationUrl, target),
                  getDisplayUrlIssue(adProto.displayUrl, target),
                  getCallToActionIssue(adProto.callToAction, target),
                ];
              }
              return adProto.elementDests.flatMap(elementDest => {
                if (elementDest.destType === "url") {
                  return getDestinationUrlIssue(elementDest.destinationUrl, {
                    adId: adProto.id,
                    storeName: adProto.storeName,
                    elementId: elementDest.elementId,
                  });
                }

                return getProductSetIssue(
                  elementDest.productSetId,
                  {
                    adId: adProto.id,
                    storeName: adProto.storeName,
                    elementId: elementDest.elementId,
                  },
                  adProto.convertToVideo,
                );
              });
            }
            default: {
              const _exhaustiveCheck: never = adProtoType;
              throw new Error(`Unhandled ad proto type: ${_exhaustiveCheck}`);
            }
          }
        })
        .filter(isTruthy),
    [session.data.adProtoDrafts],
  );

  return {
    issues,
    hasIssues: issues.length > 0,
    issuesByField: typedGroupBy(issues, issue => issue.field),
  };
};

const getDestinationUrlIssue = (
  destinationUrl: string | undefined,
  target: IssueTarget,
): AdLoadIssue | undefined => {
  if (isValidUrl(destinationUrl ?? "")) return undefined;

  return {
    target,
    status: "error",
    field: "destinationUrl",
    message: "Destination URL is required",
  };
};

const getProductSetIssue = (
  productSetId: string | undefined,
  target: IssueTarget,
  convertToVideo: boolean,
): AdLoadIssue | undefined => {
  if (productSetId || convertToVideo) return undefined;

  return {
    target,
    status: "error",
    field: "productSetId",
    message: "Product set is required",
  };
};

const getDisplayUrlIssue = (
  displayUrl: string | undefined,
  target: IssueTarget,
): AdLoadIssue | undefined => {
  if (
    !displayUrl ||
    isValidUrl(displayUrl, {
      optionalProtocol: true,
    })
  )
    return undefined;

  return {
    target,
    status: "error",
    field: "displayUrl",
    message: "Display URL is not a valid URL",
  };
};

const getCallToActionIssue = (
  callToAction: string | undefined,
  target: IssueTarget,
): AdLoadIssue | undefined => {
  if (callToAction) return undefined;

  return {
    target,
    status: "error",
    field: "callToAction",
    message: "Call to action is required",
  };
};
