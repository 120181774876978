import { isFeatureEnabled } from "utils/helpers";
import { useIsAdmin } from "./useIsAdmin";
import { useIsBrochureAdmin, useIsBrochureUser } from "./useIsBrochureUser";
import { useHasSalesEnablementReadAccess } from "./useHasSalesEnablementReadAccess";

export const useHasBrochureAccess = () => {
  const isBrochureManagementEnabled = isFeatureEnabled(
    "ENABLE_BROCHURE_MANAGEMENT",
  );
  const isAdmin = useIsAdmin();
  const hasSalesEnablementReadAccess = useHasSalesEnablementReadAccess();
  const isBrochureAdmin = useIsBrochureAdmin();
  const isBrochureUser = useIsBrochureUser();

  const hasBrochureAccess =
    isBrochureManagementEnabled &&
    (isAdmin || isBrochureAdmin || isBrochureUser);

  const onlyBrochureAccess =
    hasBrochureAccess && !isAdmin && !hasSalesEnablementReadAccess;

  return { hasBrochureAccess, onlyBrochureAccess };
};
