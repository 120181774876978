import { Button, Checkbox, Input, Typography } from "antd";
import { useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";

import styles from "./DimensionsPicker.module.scss";

export type Dimension = {
  width: number;
  height: number;
  label: string;
  selected: boolean;
  id: number;
  transformedS3Url?: string;
};

type DimensionsPickerProps = {
  dimensions: Dimension[];
  customDimensions: Dimension[];
  setCustomDimensions: React.Dispatch<React.SetStateAction<Dimension[]>>;
  handleSelectDimension: (id: number) => void;
};

const DimensionsPicker = ({
  dimensions,
  customDimensions,
  setCustomDimensions,
  handleSelectDimension,
}: DimensionsPickerProps) => {
  const [displayCustomDimensions, setDisplayCustomDimensions] = useState(false);

  const handleEditCustomDimension = (
    id: number,
    width?: number,
    height?: number,
  ) => {
    setCustomDimensions(prev =>
      prev.map(dimension =>
        dimension.id === id
          ? {
              ...dimension,
              width: width || dimension.width,
              height: height || dimension.height,
            }
          : dimension,
      ),
    );
  };

  const handleAddCustomDimension = () => {
    setCustomDimensions(prev => [
      ...prev,
      {
        width: 0,
        height: 0,
        label: "Custom",
        id: prev.length + dimensions.length,
        selected: true,
      },
    ]);
  };

  const handleDeleteDimension = (id: number) => {
    setCustomDimensions(prev => prev.filter(dimension => dimension.id !== id));
  };

  return (
    <>
      <Checkbox.Group className={styles.dimensionList}>
        <Typography.Text className={styles.infoText}>
          Desired dimension(s)
        </Typography.Text>
        {dimensions.map(dimension => (
          <div key={dimension.label} className={styles.dimensionListItem}>
            <Checkbox
              value={dimension.id}
              checked={dimension.selected}
              onChange={() => handleSelectDimension(dimension.id)}
            >
              <Typography.Text>
                {dimension.label} - {dimension.width}x{dimension.height}
              </Typography.Text>
            </Checkbox>
          </div>
        ))}
        <Checkbox
          value="custom"
          checked={displayCustomDimensions}
          onChange={() => {
            setDisplayCustomDimensions(prev => !prev);
            setCustomDimensions(prev =>
              prev.length > 0
                ? prev
                : [
                    {
                      id: 4,
                      width: 0,
                      height: 0,
                      label: "Custom",
                      selected: true,
                    },
                  ],
            );
          }}
        >
          Custom
        </Checkbox>
      </Checkbox.Group>
      {displayCustomDimensions && (
        <div className={styles.customDimensionContainer}>
          {customDimensions.map(dimension => (
            <div key={dimension.id} className={styles.customDimensionInputRow}>
              <div className={styles.customDimensionInput}>
                <Typography.Text>W</Typography.Text>
                <Input
                  onChange={e =>
                    handleEditCustomDimension(
                      dimension.id,
                      parseInt(e.target.value),
                    )
                  }
                  value={dimension.width || 0}
                />
              </div>
              <div className={styles.customDimensionInput}>
                <Typography.Text>H</Typography.Text>
                <Input
                  onChange={e =>
                    handleEditCustomDimension(
                      dimension.id,
                      undefined,
                      parseInt(e.target.value),
                    )
                  }
                  value={dimension.height || 0}
                />
              </div>
              {customDimensions.length > 0 && (
                <Button
                  icon={<DeleteOutlined />}
                  type="ghost"
                  onClick={() => handleDeleteDimension(dimension.id)}
                  className={styles.deleteButton}
                />
              )}
            </div>
          ))}
          <Button
            onClick={handleAddCustomDimension}
            className={styles.addCustomDimension}
            type="link"
          >
            + Add custom dimension
          </Button>
        </div>
      )}
    </>
  );
};

export default DimensionsPicker;
