import { DeleteOutlined, EditOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Input, Tooltip } from "antd";
import { useCallback, useEffect, useState } from "react";
import { getSrcFromComposition } from "screens/assetExporter/feedConfigurationV2/assetBatchDrawer/utils";
import { MINIMUM_DURATION } from "screens/assetExporter/feedConfigurationV2/shared/constants";
import { useAssetBatchesContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesContext";
import { isTemplateComposition } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesContext.utils";
import { useAssetBatchesValueMappingContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesValueMappingContext";
import { useVideoStitchingContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/VideoStitchingContext";
import { useCompositionDuration } from "screens/assetExporter/feedConfigurationV2/shared/hooks/useCompositionDuration";
import { useUndoDeleteComposition } from "screens/assetExporter/feedConfigurationV2/shared/hooks/useUndoDeleteComposition";
import {
  TColumnValue,
  TObjValue,
} from "screens/assetExporter/feedConfigurationV2/shared/types";
import { getMaxDurationForComposition } from "screens/assetExporter/feedConfigurationV2/shared/utils";
import { successNotification } from "shared/components/customNotification/Notification";
import { TComposition, TMediaComposition } from "shared/types/assetExporter";
import { checkIfItIsVideoSrc } from "utils/helpers.adEngine";
import styles from "./VariableCollapseHeader.module.scss";
import {
  getCurrentTemplate,
  getVariableCollapseHeaderTitle,
  updateDurationWithCompositionId,
} from "./utils";

export type VariableCollapseHeaderProps = {
  composition: TComposition;
};

export const VariableCollapseHeader = ({
  composition,
}: VariableCollapseHeaderProps) => {
  const {
    templatesToUse: templates,
    replaceTemplateComposition,
    changeTemporalRemoved,
    setEditingTemplate,
    compositions,
    rows,
    setCompositions,
  } = useAssetBatchesContext();

  const { selectedRow } = useAssetBatchesValueMappingContext();

  const { manageDurationExceeded } = useCompositionDuration();

  const { setVideoAudios } = useVideoStitchingContext();

  const { undoMessage } = useUndoDeleteComposition(composition);

  const currentTemplate = getCurrentTemplate(composition, templates);
  const [inputValue, setInputValue] = useState(composition.duration.toString());
  const [tooltipTitle, setTooltipTitle] = useState("");

  const title = getVariableCollapseHeaderTitle(
    templates,
    composition.template,
    composition.type,
    (composition as TMediaComposition).subtype,
  );

  useEffect(() => {
    setInputValue(composition.duration.toString());
  }, [composition.duration]);

  const getTooltipTitile = useCallback(async () => {
    const maxDuration = await getMaxDurationForComposition(composition, rows);
    setTooltipTitle(
      maxDuration
        ? `The selected video features a duration of ${maxDuration}s`
        : "",
    );
  }, [composition, rows]);

  useEffect(() => {
    getTooltipTitile();
  }, [composition, getTooltipTitile, rows]);

  const handleEditTemplate = () => {
    if (!isTemplateComposition(composition)) return;

    setEditingTemplate(composition.template);
    const editorUrl = `/design-studio/editor/templates/${currentTemplate?.id}/editor-v2?from=ad-engine`;
    window.open(editorUrl, "_blank");
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rgx = /^[0-9]*\.?[0-9]*$/;
    if (!rgx.test(event.target.value)) return;

    setInputValue(event.target.value); // Update the input value state
  };

  const handleOnBlur = async () => {
    const isMedia = !composition.template;
    const src = isMedia
      ? getSrcFromComposition(composition as TMediaComposition, selectedRow)
      : "";
    const { isVideo } = await checkIfItIsVideoSrc(src);

    const checks = await Promise.all(
      Object.keys(composition.variables).map(async newVarKey => {
        const varValue = composition.variables[newVarKey].value;
        if (typeof varValue === "string") {
          const { isVideo: isSrcVideo } = await checkIfItIsVideoSrc(varValue);
          return isSrcVideo;
        }
        if ((varValue as TObjValue)?.type === "match_to_column") {
          const column = (varValue as TColumnValue).column;
          const rowChecks = await Promise.all(
            (rows as any[]).map(async row => {
              const { isVideo: isRowVideo } = await checkIfItIsVideoSrc(
                row?.[column],
              );
              return isRowVideo;
            }),
          );
          return rowChecks.some(isRowVideo => isRowVideo);
        }
        return false;
      }),
    );
    const cannotBe0s = checks.some(check => check);

    const allow0s = !isVideo && compositions?.length < 2 && !cannotBe0s;
    const minimumDuration = allow0s ? 0 : MINIMUM_DURATION;

    if (inputValue === "") setInputValue(`${minimumDuration}`);
    let duration = inputValue ? parseFloat(inputValue) : minimumDuration;
    const userInputDuration =
      inputValue === "" || isNaN(parseFloat(inputValue))
        ? 0
        : parseFloat(inputValue);
    if (duration < minimumDuration || isNaN(duration)) {
      duration = minimumDuration;
      setInputValue(`${minimumDuration}`);
      successNotification({
        messageLabel: `We adjusted your duration from ${userInputDuration}s to ${MINIMUM_DURATION}s since duration minimum is ${MINIMUM_DURATION}s`,
        key: `durationAlert-${composition.compositionId}`,
        duration: 5,
        size: "big",
      });
    }

    const compIncludesVideo = isVideo || cannotBe0s;
    if (compIncludesVideo) {
      const maxDuration = await getMaxDurationForComposition(composition, rows);
      if (duration > maxDuration) {
        duration = maxDuration;
        setInputValue(`${maxDuration}`);
      }
    }

    const newCompositions = updateDurationWithCompositionId(
      compositions,
      composition.compositionId,
      duration,
    );

    manageDurationExceeded(
      duration,
      composition,
      newCompositions,
      setInputValue,
    );
  };

  return (
    <div className={styles.headerContainer}>
      <div>{title}</div>
      <div className={styles.buttonContainer}>
        {isTemplateComposition(composition) && (
          <Button
            type="text"
            size="small"
            icon={
              <SyncOutlined
                onClick={() => replaceTemplateComposition(composition)}
              />
            }
          />
        )}
        {isTemplateComposition(composition) && (
          <Button
            type="text"
            size="small"
            icon={<EditOutlined onClick={() => handleEditTemplate()} />}
          />
        )}
        <Button
          type="text"
          size="small"
          icon={
            <DeleteOutlined
              onClick={() => {
                changeTemporalRemoved(composition, true);
                setVideoAudios(prev =>
                  prev.filter(
                    item => item.compositionId !== composition.compositionId,
                  ),
                );
                setCompositions(prev => {
                  return prev.map(comp => {
                    if (comp.compositionId === composition.compositionId) {
                      return {
                        ...comp,
                        audioSelVariableId: [],
                      };
                    }
                    return comp;
                  });
                });
                undoMessage();
              }}
            />
          }
        />
        <Tooltip title={tooltipTitle}>
          <Input
            size="small"
            suffix={<div style={{ color: "rgba(0,0,0,.45)" }}>s</div>}
            className={styles.mediaDurationInput}
            onClick={event => {
              event.stopPropagation();
            }}
            onChange={handleOnChange}
            onBlur={handleOnBlur} // Add onBlur handler
            maxLength={4}
            value={inputValue} // Use the input value state
            onMouseEnter={getTooltipTitile}
          />
        </Tooltip>
      </div>
    </div>
  );
};
