import { Rule } from "antd/lib/form";
import { SelectOption } from "./types";

export const ROUTES = {
  home: "/campaign-management/beeswax",
  create: "/campaign-management/beeswax/new",
  selectStep: "/campaign-management/beeswax/load/select",
  resultStep: "/campaign-management/beeswax/load/result",
  update: (id: string) => `/campaign-management/beeswax/${id}`,
  createLineItem: (campaignId: string | undefined) =>
    campaignId
      ? `/campaign-management/beeswax/${campaignId}/line-items/new`
      : `/campaign-management/beeswax/new/line-items/new`,
  editLineItem: (campaignId: string | undefined) =>
    campaignId
      ? `/campaign-management/beeswax/${campaignId}/line-items/edit`
      : `/campaign-management/beeswax/new/line-items/edit`,
};

export const beeswaxCampaignsQueryKey = "beeswaxCampaigns";
export const loadBeeswaxCampaignsStatusQueryKey = "loadBeeswaxCampaignsStatus";

export const createBeeswaxCampaignMutationKey = "createBeeswaxCampaign";
export const updateBeeswaxCampaignMutationKey = "updateBeeswaxCampaign";
export const loadBeeswaxCampaignsMutationKey = "loadBeeswaxCampaigns";

export const requiredRule: Rule = {
  required: true,
  message: "This field is required",
};

export const budgetRule: Rule = {
  message: "Enter a value greater than zero",
  type: "number",
  min: 0.01,
};

export const dealListOptions: SelectOption[] = [
  {
    label: "In Market Auto",
    value: "inMarketAuto",
  },
  {
    label: "Constellation Olympics Deal",
    value: "constellationOlympicsDeal",
  },
];

export const locationTargetOptions: SelectOption[] = [
  {
    label: "Zip Code List",
    value: "zipCodeList",
  },
  {
    label: "Radius",
    value: "radius",
  },
];

export const radiusUnitOptions: SelectOption[] = [
  {
    label: "Miles",
    value: "miles",
  },
  {
    label: "Kilometers",
    value: "kilometers",
  },
];

export const lineItemTypeOptions: SelectOption[] = [
  {
    label: "Video",
    value: "video",
  },
];

export const campaignFormId = "beeswax-campaign-form";
export const lineItemFormId = "line-item-form";

export const accountToken = "{Account Name}";

export const beeswaxDateFormat = "YYYY-MM-DD HH:mm:ss";
