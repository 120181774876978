import { useState, useEffect } from "react";

export const useDeviceDetection = () => {
  const [isSupportedDevice, setIsSupportedDevice] = useState<
    boolean | undefined
  >(undefined);

  useEffect(() => {
    const handleDeviceDetection = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      const isMobile =
        /iphone|ipad|ipod|android|blackberry|windows phone/g.test(userAgent);
      const isTablet =
        /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent);

      setIsSupportedDevice(!isMobile && !isTablet);
    };

    handleDeviceDetection();
    window.addEventListener("resize", handleDeviceDetection);

    return () => {
      window.removeEventListener("resize", handleDeviceDetection);
    };
  }, []);

  return { isSupportedDevice };
};
