import {
  TColumnValue,
  TMediaResizeType,
} from "screens/assetExporter/feedConfigurationV2/shared/types";
import { TVideoAudio } from "shared/types/assetExporter";
import { KeyboardEvent } from "react";

type HandleChangeParams = {
  val: string;
  option: any;
  mappingKey: string;
  rows: any[];
  setShowMediaAlert: React.Dispatch<React.SetStateAction<boolean>>;
  onValueChange: (mKey: string, emptyValue: boolean) => void;
  onSelectInputValueSelect: (
    mappingKey: string,
    rows: any[],
    value: TColumnValue,
  ) => Promise<void>;
  onSelectInputValue: (
    mappingKey: string,
    rows: any[],
    value?: string | undefined,
  ) => Promise<void>;
};

type HandleKeyDownParams = {
  e: KeyboardEvent<HTMLDivElement>;
  value: string | undefined;
  mappingKey: string;
  resizeType: TMediaResizeType;
  rows: any[];
  onSelectResizeType: (
    mappingKey: string,
    resizeType: TMediaResizeType,
  ) => void;
  onSelectInputValue: (
    mappingKey: string,
    rows: any[],
    value?: string | undefined,
  ) => Promise<void>;
  onSelectInputValueSelect: (
    mappingKey: string,
    rows: any[],
    value: TColumnValue,
  ) => Promise<void>;
};

type HanldeClearParams = {
  compositionId: string;
  mappingKey: string;
  rows: any[];
  onSelectInputValue: (
    mappingKey: string,
    rows: any[],
    value?: string | undefined,
  ) => void;
  setVideoAudios: React.Dispatch<React.SetStateAction<TVideoAudio[]>>;
};

export const useImageVariableInput = () => {
  const getLeftColSpan = (isVideo: boolean, templates: any) => {
    if (templates?.[0]?.type === "html" || !isVideo) {
      return 24;
    }
    return 16;
  };

  const handleChange = async ({
    val,
    option,
    mappingKey,
    rows,
    setShowMediaAlert,
    onValueChange,
    onSelectInputValue,
    onSelectInputValueSelect,
  }: HandleChangeParams): Promise<void> => {
    setShowMediaAlert(false);
    onValueChange(mappingKey, !val);
    if (val && "key" in option && typeof option.key === "string") {
      await onSelectInputValueSelect(mappingKey, rows, {
        column: option.key,
        type: "match_to_column",
      });
    } else {
      await onSelectInputValue(mappingKey, rows, val);
    }
  };

  const handleClear = ({
    compositionId,
    mappingKey,
    rows,
    onSelectInputValue,
    setVideoAudios,
  }: HanldeClearParams) => {
    onSelectInputValue(mappingKey, rows, undefined);
    setVideoAudios(prev =>
      prev.map(item => {
        if (item.compositionId === compositionId) {
          const filteredVariableIds = item.variableIds.filter(
            variableId => !mappingKey.includes(variableId),
          );
          return { ...item, variableIds: filteredVariableIds };
        }
        return item;
      }),
    );
  };

  const handleKeyDown = async ({
    e,
    value,
    mappingKey,
    resizeType,
    rows,
    onSelectResizeType,
    onSelectInputValue,
    onSelectInputValueSelect,
  }: HandleKeyDownParams) => {
    if (e.key === "Enter" && value) {
      await onSelectInputValueSelect(mappingKey, rows, {
        column: value,
        type: "match_to_column",
      });
      onSelectResizeType(mappingKey, resizeType);
    }
    if (e.key === "Esc" || e.key === "Escape") {
      await onSelectInputValue(mappingKey, rows, undefined);
    }
  };
  return {
    getLeftColSpan,
    handleChange,
    handleClear,
    handleKeyDown,
  };
};
