export const formatTime = (milliseconds: number): string => {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const secondsLeft = totalSeconds % 60;
  const millisecondsLeft = Math.floor((milliseconds % 1000) / 10); // Convert to hundredths of a second

  return `${minutes < 10 ? "0" : ""}${minutes}:${
    secondsLeft < 10 ? "0" : ""
  }${secondsLeft}:${millisecondsLeft < 10 ? "0" : ""}${millisecondsLeft}`;
};
