import { IInstantExperience } from "shared/types/adLibrary";
import {
  OperationType,
  IGetQCHistoryLogsParams,
  IGetQCHistoryLogsResult,
  IModifyQCHistoryLogResult,
  IGetAdsParams,
  IGetAdsResult,
  ICreateSharedAdReviewReqBody,
  ICreateSharedAdReviewResult,
  IGetSharedAdReviewResult,
  IBatchModifyQCHistoryLogResult,
  IGetSharedAdReviewParams,
  IGetFacebookIEParams,
  IFlipFacebookCampaignReqBody,
  FlipFacebookCampaignsResponse,
  GetFlipStatusResponse,
} from "shared/types/adReview";
import { IConfig } from "shared/types/configuration";

import { IApiResponse, IQCHistoryLog } from "shared/types/shared";
import HttpClient from "./httpClient";

export default ({ config }: { headers: any; config: IConfig }) => ({
  getAds: (params: IGetAdsParams): Promise<IApiResponse<IGetAdsResult>> => {
    return HttpClient.get(config.services.adLibrary.adReview.adsUrl(params));
  },
  getSharedAdReview: (
    sharedAdReviewId: string,
    params: IGetSharedAdReviewParams,
  ): Promise<IApiResponse<IGetSharedAdReviewResult>> => {
    return HttpClient.get(
      config.services.adLibrary.adReview.sharedAdReviewsUrl(
        sharedAdReviewId,
        params,
      ),
      {},
      false,
    );
  },
  createSharedAdReview: (
    body: ICreateSharedAdReviewReqBody,
  ): Promise<IApiResponse<ICreateSharedAdReviewResult>> => {
    return HttpClient.post(
      config.services.adLibrary.adReview.sharedAdReviewsUrl(),
      body,
    );
  },
  getQCHistoryLogs: (
    params: IGetQCHistoryLogsParams,
  ): Promise<IApiResponse<IGetQCHistoryLogsResult>> => {
    return HttpClient.get(config.services.qcHistoryLogsUrl(params));
  },
  batchCreateQCHistoryLogs: (
    qcHistoryLogs: IQCHistoryLog[],
  ): Promise<IApiResponse<IBatchModifyQCHistoryLogResult>> => {
    return HttpClient.post(config.services.batchQCHistoryLogs(), {
      qcHistoryLogs,
    });
  },
  modifyQCHistoryLog: (
    qcHistoryLog: IQCHistoryLog,
    operation: OperationType,
  ): Promise<IApiResponse<IModifyQCHistoryLogResult>> => {
    return HttpClient.post(config.services.qcHistoryLogsUrl(), {
      qcHistoryLog,
      operation,
    });
  },
  getFacebookInstantExperience: (
    adId: string,
    params?: IGetFacebookIEParams,
  ): Promise<IApiResponse<IInstantExperience[]>> => {
    return HttpClient.get(
      config.services.adLibrary.adReview.facebookInstantExperienceUrl(
        adId,
        params,
      ),
      undefined,
      !params?.sharedAdReviewId,
    );
  },
  flipFacebookCampaigns: (
    body: IFlipFacebookCampaignReqBody,
  ): Promise<IApiResponse<FlipFacebookCampaignsResponse>> => {
    return HttpClient.post(
      config.services.adLibrary.adReview.flipFacebookCampaignsUrl(),
      body,
    );
  },
  getFlipStatus: (executionId: string) => {
    return HttpClient.get<IApiResponse<GetFlipStatusResponse>>(
      config.services.adLibrary.adReview.getFlipStatusUrl(executionId),
    );
  },
});
