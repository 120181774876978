import { useQuery } from "react-query";
import API from "services";
import {
  convertDropboxUrl,
  getGoogleDriveFileExtension,
  getGoogleDriveFileUrl,
  isGDriveUrl,
} from "utils/helpers.adEngine";

const fetchAudioVideo = async (videoSrc: string) => {
  if (!videoSrc) return false;
  const { checkVideoAudio } = API.services.assetExporter;

  const isGdriveSrc = isGDriveUrl(videoSrc);
  const gDriveExt = isGdriveSrc
    ? await getGoogleDriveFileExtension(videoSrc)
    : "";

  if (!isGdriveSrc && !videoSrc.includes(".mp4")) return false;

  if (gDriveExt !== "" && gDriveExt !== "mp4") return false;

  const src = isGdriveSrc
    ? await getGoogleDriveFileUrl(videoSrc)
    : convertDropboxUrl(videoSrc);

  return (
    (
      await checkVideoAudio({
        videoSrc: src,
      })
    )?.hasAudioVideo ?? false
  );
};

export const useHasAudioVideo = (videoSrc: string) => {
  return useQuery(["hasAudioVideo", videoSrc], () => fetchAudioVideo(videoSrc));
};
