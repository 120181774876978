import { Button, Drawer, Form, Input } from "antd";
import styles from "./Options.module.scss";
import {
  DeleteOutlined,
  PlusOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import { Option, OptionGroupLayer } from "shared/types/salesEnablement";
import { OnLayerChangeFunc } from "../../Layers";
import { ReactComponent as Edit } from "statics/images/Edit_pen.svg";
import { useState } from "react";
import uuid from "uuid";

type Props = {
  layer: OptionGroupLayer;
  onChange?: OnLayerChangeFunc;
  disabled?: boolean;
};
const Options = (props: Props) => {
  const { layer, onChange, disabled } = props;
  const [form] = Form.useForm<{ options: Option[] }>();

  const [open, setOpen] = useState(false);
  return (
    <div>
      {(layer.options?.length ?? 0) > 0 && (
        <>
          <div className={styles.gray}>
            The agent will choose one of the options below:
          </div>
          <div>
            <ol>
              {layer.options?.map(opt => (
                <li key={`option-${opt.id}`}>{opt.value}</li>
              ))}
            </ol>
          </div>
        </>
      )}

      <Button
        className={styles.edit}
        type="link"
        icon={<Edit className={styles.editPen} />}
        disabled={disabled}
        onClick={() => {
          setOpen(true);
        }}
      >
        Add options
      </Button>
      <Drawer
        width={928}
        title="Edit Options"
        visible={open}
        onClose={() => setOpen(false)}
        closable={false}
        footer={
          <div className={styles.footer}>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button type="primary" onClick={() => form.submit()}>
              Save
            </Button>
          </div>
        }
      >
        <Form
          form={form}
          onFinish={values => {
            const { options } = values;
            onChange?.(layer, {
              type: "group_set_options",
              data: { options },
            });
            setOpen(false);
          }}
        >
          <div className={styles.layerName}>
            <ThunderboltOutlined />
            {layer.name}
          </div>
          <div className={styles.description}>
            {`Text added between {{}} will be editable by the agent upon creating
          marketing materials.`}
          </div>

          <div className={styles.optionList}>
            <Form.List name="options" initialValue={layer.options}>
              {(fields, { add, remove }) => (
                <>
                  {fields.length > 0 && (
                    <div className={styles.listContainer}>
                      {fields.map((field, index) => (
                        <Form.Item className={styles.formItem} key={field.key}>
                          <div className={styles.option}>
                            <Form.Item
                              {...field}
                              name={[field.name, "value"]}
                              noStyle
                              validateTrigger={["onChange", "onBlur"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Options must not be empty.",
                                },
                              ]}
                            >
                              <Input placeholder="Enter Option" />
                            </Form.Item>
                            {index !== 0 && (
                              <Button
                                type="text"
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                  remove(field.name);
                                }}
                              />
                            )}
                          </div>
                        </Form.Item>
                      ))}
                    </div>
                  )}

                  <Form.Item>
                    <Button
                      type="link"
                      icon={<PlusOutlined />}
                      onClick={() => {
                        add({ value: "", id: uuid.v4() });
                      }}
                    >
                      Add option
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </div>
        </Form>
      </Drawer>
    </div>
  );
};

export default Options;
