import useDeepEffect from "shared/hooks/useDeepEffect";
import { TObjectUpdateAction } from "../../propertySectionV2/propertyRow/ManageText";
import { fabric } from "fabric";
import { message } from "antd";
import {
  getStyles,
  isHighlighted,
  removeStyleAttr,
} from "../canvas.utils/Utils";
import {
  ICustomShapeData,
  IExtendedFabricObject,
  TShape,
} from "shared/types/designStudio";

const resetSubscriptSuperscript = (textbox: fabric.Textbox) => {
  const originalFontSize = getOriginalFontSize(textbox);
  textbox.setSelectionStyles({
    fontSize: originalFontSize,
    deltaY: undefined,
  });
};

const setSubSuperScript = (
  textbox: fabric.Textbox,
  key: "subscript" | "superscript",
) => {
  resetSubscriptSuperscript(textbox);
  const { selectionStart = 0, selectionEnd = 0 } = textbox;
  const originalFontSize = getOriginalFontSize(textbox);

  key === "superscript"
    ? textbox.setSuperscript(selectionStart, selectionEnd)
    : textbox.setSubscript(selectionStart, selectionEnd);

  textbox.setSelectionStyles({
    originalFontSize,
  });
};

const getOriginalFontSize = (textbox: fabric.Textbox) => {
  const styles = textbox.getSelectionStyles();

  const { originalFontSize, fontSize } = styles?.[0] ?? {};

  // Used originalFontSize if it is defined, otherwise use fontSize.
  // Fallback to textbox.fontSize if both are undefined.
  return originalFontSize ?? fontSize ?? textbox.fontSize;
};

const computeFontStyles = (
  key: TObjectUpdateAction["action"]["data"]["key"],
  value: string | number | undefined,
) => {
  if (key === "fontFamily" && typeof value === "string") {
    return {
      fontFamily: value,
      fontStyle: "normal",
      fontWeight: "normal",
    } as const;
  }

  const isUpdatingFontSize = key === "fontSize";
  const resetCachedStyles = isUpdatingFontSize
    ? { originalFontSize: undefined }
    : {};

  return {
    [key]: value,
    // Reset cached/injected styles if needed
    ...resetCachedStyles,
  };
};
export default (args: {
  objectUpdateAction?: TObjectUpdateAction;
  canvas?: fabric.Canvas;
  onComplete: (object: fabric.Object) => void;
}) => {
  useDeepEffect(() => {
    if (!args.objectUpdateAction) return;

    const {
      action: {
        type,
        data: { key, value },
      },
    } = args.objectUpdateAction;

    const activeObject =
      (args.canvas?.getActiveObject() as IExtendedFabricObject) || {}; // there must be active object otherwise, user wont see the property panel to interact with

    const { customType, customData } = activeObject || {};
    switch (type) {
      case "shape":
        if (customType !== "shape") {
          message.error("There was mismatch type error!");
          break;
        }

        const { shape } = customData as ICustomShapeData;
        const changingRadius =
          (["square", "squircle"] as TShape[]).includes(shape) &&
          key === "radius";

        if (changingRadius) {
          if (value ?? false) {
            const scaleX = (activeObject as fabric.Rect).scaleX ?? 1;
            const scaleY = (activeObject as fabric.Rect).scaleY ?? 1;
            const calculatedRx = (value as number) * scaleY;
            const calculatedRy = (value as number) * scaleX;
            (activeObject as fabric.Rect).set({
              rx: calculatedRx,
              ry: calculatedRy,
            });
            activeObject.set({
              customData: {
                ...(customData || {}),
                radius: value as number,
              },
            });
          }
        } else {
          activeObject.set({
            [key]: value,
          });
        }
        break;

      case "text":
        if (customType !== "text") {
          // Some textbox in old template might not have "customType" defined.
          // Check if it is textbox type and let it pass if it is.
          if (activeObject?.type !== "textbox") {
            message.error("There was mismatch type error!");
            return;
          }
        }

        const textbox = activeObject as unknown as fabric.Textbox;

        if (["superscript", "subscript", "reset"].includes(key)) {
          if (key === "reset") resetSubscriptSuperscript(textbox);
          if (key === "superscript") setSubSuperScript(textbox, key);
          if (key === "subscript") setSubSuperScript(textbox, key);
        } else {
          fabric.util.clearFabricFontCache(textbox.fontFamily);
          textbox.initDimensions();
          if (!isHighlighted(textbox)) {
            // this means, the textbox object is selected but not specific text has been highlighted.
            // If this is the case, we need to remove (un-style) the "key" from each style object,
            //  and then apply the global textbox property.
            removeStyleAttr(textbox, key);
            textbox.set({
              objectCaching: false,
              ...computeFontStyles(key, value),
            });
          } else if (key === "fontSize") {
            if (isHighlighted(textbox)) {
              const styles = getStyles(textbox, {
                [key]: value,
              });
              textbox.set({
                styles,
                objectCaching: false,
              });
            } else {
              textbox.set({
                [key]: value as number, // validated in FontProperty.tsx
              });
            }
          } else {
            const styles = getStyles(textbox, computeFontStyles(key, value));
            textbox.set({
              styles: {
                ...(textbox.styles || {}),
                ...styles,
              },
              objectCaching: false,
            });
          }
        }

        break;

      default:
        activeObject?.set({
          [key]: value,
        });
        break;
    }

    args.canvas?.requestRenderAll();
    args.onComplete(activeObject);
  }, [args.objectUpdateAction]);
};
