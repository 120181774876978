import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Drawer, Tabs } from "antd";
import useNavigateWithSearch from "shared/hooks/useNavigateWithSearch";
import styles from "./ArchiveTemplateFileDrawer.module.scss";
import Preview from "screens/adLibrary/marketingMaterialDrawer/Preview";
import TemplateRenderDataProvider from "screens/designStudio/hooks/TemplateRenderDataProvider";
import { useFetchArchives } from "shared/hooks/salesEnablement/useFetchArchives";
import { ArchiveItem } from "shared/types/salesEnablementArchive";
import { useIsSalesEnablementAdmin } from "shared/hooks/useIsAdmin";
import { Language } from "shared/types/salesEnablement";
import { ROUTES } from "screens/designStudio/archive/constants";
import { NotificationOutlined } from "@ant-design/icons";

const ArchiveTemplateFileDrawer = () => {
  const { data: archives } = useFetchArchives();
  const { archiveId } = useParams<{ archiveId: string }>();

  const navigate = useNavigateWithSearch();

  const selectedArchive = useMemo(
    () => archives?.find(archive => archive.id === archiveId),
    [archives, archiveId],
  );

  const onClose = () => {
    navigate("..");
  };
  return (
    <PreviewTemplate
      visible={!!selectedArchive}
      onClose={() => onClose()}
      archive={selectedArchive}
    />
  );
};

type PreviewTemplateProps = {
  visible: boolean;
  onClose: () => void;
  archive?: ArchiveItem;
};

const PreviewTemplate = ({
  visible,
  onClose,
  archive,
}: PreviewTemplateProps) => {
  const [currentLanguage, setCurrentLanguage] = useState<Language>(
    () => archive?.marketingMaterial.language ?? "en",
  );

  const isSalesEnablementAdmin = useIsSalesEnablementAdmin();
  const navigate = useNavigateWithSearch();

  const { activeFile } = useMemo(
    () => ({
      activeFile: archive?.templateData.files?.[currentLanguage],
    }),
    [currentLanguage, archive?.templateData.files],
  );

  const multiLanguage = (archive?.templateData.languages?.length ?? 0) > 1;

  return archive ? (
    <Drawer
      title="Preview Template"
      placement="right"
      onClose={onClose}
      visible={visible}
      closable={false}
      width={`min(calc(100% - 48px), 928px)`}
      footer={
        <div className={styles.footer}>
          <Button onClick={onClose}>Close</Button>
          <Button
            type="primary"
            icon={<NotificationOutlined />}
            onClick={() => {
              if (archive.templateData.id) {
                navigate(ROUTES.newMaterial(archive.templateData.id));
              }
            }}
          >
            Create Marketing Material
          </Button>
        </div>
      }
    >
      <TemplateRenderDataProvider file={activeFile} material={undefined}>
        <div
          className={
            isSalesEnablementAdmin ? styles.contentAdmin : styles.contentAgent
          }
        >
          <Tabs
            activeKey={currentLanguage}
            onChange={activeKey => setCurrentLanguage(activeKey as Language)}
            tabPosition={"top"}
            hideAdd
            className={styles.tabs}
            style={{ height: "100%" }}
            tabBarStyle={{
              visibility: multiLanguage ? "visible" : "hidden",
              marginBottom: "0",
              height: multiLanguage ? "46px" : "0px",
              marginLeft: "24px",
            }}
          >
            {(Object.keys(archive.templateData.files) as Language[])?.map(
              fileKey => (
                <Tabs.TabPane
                  tab={fileKey == "es" ? "Spanish" : "English"}
                  key={fileKey}
                  style={{ overflow: "auto" }}
                >
                  <Preview selectedFile={activeFile} />
                </Tabs.TabPane>
              ),
            )}
          </Tabs>
        </div>
      </TemplateRenderDataProvider>
    </Drawer>
  ) : null;
};

export default ArchiveTemplateFileDrawer;
