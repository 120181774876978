import { AudioCollapseHeader } from "./AudioCollapseHeader";
import styles from "./AudioCollapseSection.module.scss";
import { CollapseSection } from "./CollapseSection";

interface Props {
  activeKey: string[];
  idKey: string;
  handleOnChange: (key: string) => void;
  isActive: boolean;
  title: string;
  canDelete?: boolean;
  children?: React.ReactNode;
}

export const AudioCollapseSection = ({
  activeKey,
  idKey,
  handleOnChange,
  isActive,
  title,
  children,
  canDelete,
}: Props) => {
  return (
    <CollapseSection
      activeKey={activeKey}
      idKey={idKey}
      handleOnChange={() => handleOnChange(activeKey[0])}
      customStyle={styles.collapseContainer}
      header={
        <AudioCollapseHeader
          isActive={isActive}
          title={title}
          id={idKey}
          canDelete={canDelete}
        />
      }
    >
      {children}
    </CollapseSection>
  );
};
