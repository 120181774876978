import { Account, AccountLogo } from "shared/types/salesEnablement";
import { useMemo } from "react";
import { useLayoutPreference } from "shared/hooks/useLayoutPreference";
import DataListURLTable, {
  ITableColumnURL,
} from "shared/components/dataListURL/DataListURLTable";
import NameCardCell from "shared/components/NameCardCell";
import Tags from "screens/designStudio/shared/Tags";
import { useDataListURLGlobalFilter } from "shared/components/dataListURL/useDataListURLGlobalFilter";
import { useDataListURLSorter } from "shared/components/dataListURL/useDataListURLSorter";
import { useDataListURLData } from "shared/components/dataListURL/useDataListURLData";
import ToolbarTable from "shared/components/toolbarTable/ToolbarTable";
import Card from "shared/components/CardV2";
import { DataListTagsURL } from "shared/components/dataListURL/DataListTagsURL";
import { SearchFilterDropdown } from "shared/components/SearchFilterDropdown";
import { SearchOutlined } from "@ant-design/icons";
import styles from "./AccountLogosList.module.scss";
import { TimeAgo } from "shared/components/TimeAgo";

type ListLogo = AccountLogo & { id: string };

export const AccountLogosList = ({
  accounts,
  selectedAccountId,
  setSelectedLogo,
  selectedLogo,
}: {
  accounts: Account[];
  selectedAccountId: string;
  setSelectedLogo: (logo: AccountLogo | undefined) => void;
  selectedLogo: AccountLogo | undefined;
}) => {
  const [layout, setLayout] = useLayoutPreference("accountLogosLayout");

  const logos: ListLogo[] = useMemo(() => {
    const selectedAccount = accounts?.find(
      account => account.id === selectedAccountId,
    );

    if (!selectedAccount) return [];
    return selectedAccount.logos.map(({ uid, ...logo }) => ({
      ...logo,
      uid,
      id: uid,
    }));
  }, [accounts, selectedAccountId]);

  const { sortKey, sortOrder, sortItems } = useDataListURLSorter<
    keyof AccountLogo,
    AccountLogo
  >();
  const { globalFilter, setGlobalFilter } = useDataListURLGlobalFilter();

  const { data, originalData } = useDataListURLData<keyof ListLogo, ListLogo>({
    isError: false,
    isLoading: false,
    data: logos,
  });

  return (
    <>
      <div className={styles.searchContainer}>
        <ToolbarTable
          layout={layout}
          titleTooltip="Search"
          searchPlaceholder="Search"
          toolbarContents={{}}
          onSearch={setGlobalFilter}
          searchValue={globalFilter ?? undefined}
          disableSearch={false}
          onClickLayout={setLayout}
          previewFormat={false}
          sortingOrder={sortKey && sortOrder ? [sortKey, sortOrder] : undefined}
          onSortChange={([columnKey, order]) => {
            sortItems(columnKey, order);
          }}
          includeFilterOnTableLayout={true}
        />
      </div>
      <DataListTagsURL<keyof ListLogo, ListLogo>
        data={data}
        originalData={originalData}
        showSelectAll={false}
      />
      <div className={styles.listContainer}>
        {layout === "card" ? (
          <div className={styles.cardContainer}>
            {data?.map(logo => (
              <Card
                thumbnail={logo.url}
                key={logo.id}
                title={logo.name}
                tags={[
                  logo.type ?? "",
                  `${logo.dimensions?.width ?? 0}x${
                    logo.dimensions?.height ?? 0
                  }`,
                  `${getSizeInMb(logo.size)}mb`,
                ]}
                item={logo}
                checkboxType="radio"
                isSelected={selectedLogo?.uid === logo.id}
                onSelect={selected =>
                  selected ? setSelectedLogo(logo) : setSelectedLogo(undefined)
                }
              />
            ))}
          </div>
        ) : (
          <DataListURLTable<keyof ListLogo, ListLogo>
            fieldKeys={["name", "url", "type", "id"]}
            dataSource={data}
            type="normal"
            size="small"
            pagination={false}
            columns={accountLogosColumns}
            rowSelection={{
              type: "radio",
              onSelect: (record, selected) => {
                if (selected) {
                  setSelectedLogo(record);
                }
              },
              selectedRowKeys: selectedLogo?.uid ? [selectedLogo.uid] : [],
            }}
          />
        )}
      </div>
    </>
  );
};

const accountLogosColumns: ITableColumnURL<keyof ListLogo, ListLogo>[] = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 200,
    render: (_, record) => (
      <NameCardCell name={record.name} thumbnail={record.url} />
    ),
    filterDropdown: SearchFilterDropdown,
    filterIcon: (filtered: boolean) => (
      <SearchOutlined color={filtered ? "#1890ff" : undefined} />
    ),
  },
  {
    title: "Format",
    dataIndex: "type",
    key: "type",
    width: 200,
    render: (_, record) => (
      <Tags tags={[{ id: record.type ?? "", name: record.type ?? "" }]}></Tags>
    ),
    filterDropdown: SearchFilterDropdown,
    filterIcon: (filtered: boolean) => (
      <SearchOutlined color={filtered ? "#1890ff" : undefined} />
    ),
  },
  {
    title: "Dimensions",
    dataIndex: "dimensions",
    key: "dimensions",
    width: 200,
    render: (_, record) => (
      <div>
        {record.dimensions?.width ?? 0}x{record.dimensions?.height ?? 0}
      </div>
    ),
  },
  {
    title: "Size",
    dataIndex: "size",
    key: "size",
    width: 200,
    render: (_, record) => <div>{getSizeInMb(record.size)}Mb</div>,
  },
  {
    title: "Last Modified",
    dataIndex: "lastModified",
    key: "lastModified",
    width: 200,
    render: (_, record) => <TimeAgo date={record.lastModifiedDate ?? ""} />,
  },
];

function getSizeInMb(size?: number) {
  if (!size) return 0;
  return ((size ?? 1) / (1024 * 100)).toFixed(2);
}
