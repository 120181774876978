import { useNavigate } from "react-router-dom";
import { FolderOutlined } from "@ant-design/icons";
import { Account } from "shared/types/salesEnablement";
import { ROUTES } from "./utils/constants";
import { Typography } from "antd";
import styles from "./AccountItem.module.scss";

export const AccountItem = ({ account }: { account: Account }) => {
  const navigate = useNavigate();

  return (
    <div
      key={account.id}
      onClick={() => navigate(ROUTES.accountMarketingMaterials(account.name))}
      className={styles.account}
    >
      <div className={styles.icon}>
        <FolderOutlined className={styles.folderIcon} />
      </div>
      <Typography.Text className={styles.fontSize}>
        {account.name}
      </Typography.Text>
    </div>
  );
};
