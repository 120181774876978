import { Typography, Form, Row, InputNumber, Select } from "antd";
import styles from "./RadiusForm.module.scss";
import { radiusUnitOptions } from "../constants";
import { useLineItemDrawerContext } from "./LineItemFormProvider";

const { Text } = Typography;

const RadiusForm = () => {
  const { form } = useLineItemDrawerContext();

  const RadiusValue = () => (
    <Form.Item className={styles.formItem} name="radiusValue">
      <InputNumber className={styles.input} />
    </Form.Item>
  );

  const RadiusUnit = () => (
    <Form.Item className={styles.formItem} name="radiusUnit">
      <Select options={radiusUnitOptions} />
    </Form.Item>
  );
  return (
    <Form.Item
      noStyle
      className={styles.formItemGroup}
      dependencies={["locationTarget"]}
    >
      {() =>
        form.getFieldValue("locationTarget") === "radius" && (
          <Row gutter={16} className={styles.radiusRow}>
            <Form.Item
              className={styles.nestedFormItem}
              label={<Text>Radius</Text>}
              help={
                <Text type="secondary" className={styles.helperText}>
                  Radius from the account address as configured in Brands &
                  Accounts
                </Text>
              }
            >
              <div className={styles.fieldsContainer}>
                <RadiusValue />
                <RadiusUnit />
              </div>
            </Form.Item>
          </Row>
        )
      }
    </Form.Item>
  );
};

export default RadiusForm;
