import groupBy from "lodash/groupBy";
import { LoadStatus, PmaxAdReviewItem, PmaxLoadItem } from "../../shared/types";
import {
  GetPmaxUploadStatusResponse,
  AssetGroupUploadOutput,
} from "shared/types/adLibrary";

export const getNumberByStatus = (
  uploadStatus: Partial<GetPmaxUploadStatusResponse>,
  status: "Loaded" | "Failed",
) => {
  if (!uploadStatus?.responseJson?.output) return 0;

  if (status === "Loaded") {
    return uploadStatus.responseJson.output.filter(isSuccessOutput).length;
  }

  return uploadStatus.responseJson.output.filter(isErrorOutput).length;
};

export const isSuccessOutput = (output: AssetGroupUploadOutput) =>
  Boolean(output.result);

export const isErrorOutput = (output: AssetGroupUploadOutput) =>
  Boolean(output.error);

const getAssetGroupNameFromOutput = (output: AssetGroupUploadOutput) => {
  if (output.result?.[0]) {
    const [_, name] = output.result[0].id.split("_");
    return name;
  }

  return output.error?.assetGroupName;
};
const getReviewItemLoadStatus = (
  item: PmaxAdReviewItem,
  uploadStatus: Partial<GetPmaxUploadStatusResponse>,
): LoadStatus => {
  const output = uploadStatus?.responseJson?.output?.find(
    assetGroupUploadOutput =>
      getAssetGroupNameFromOutput(assetGroupUploadOutput) ===
      item.assetGroup.name,
  );

  if (output?.result) return "Loaded";
  if (output?.error) return "Failed";

  return "Loading";
};

export const parseLoadItems = (
  items: PmaxAdReviewItem[],
  uploadStatus: Partial<GetPmaxUploadStatusResponse>,
): PmaxLoadItem[] =>
  Object.entries(groupBy(items, item => item.campaign.name)).map(
    ([key, value]) => ({
      campaign: key,
      assetGroups: value.map(item => ({
        ...item,
        loadStatus: getReviewItemLoadStatus(item, uploadStatus),
      })),
    }),
  );

export const filterLoadItems = (items: PmaxLoadItem[], status: LoadStatus) => {
  const filteredItems = items.map(item => ({
    ...item,
    assetGroups: item.assetGroups.filter(
      assetGroup => assetGroup.loadStatus === status,
    ),
  }));
  return filteredItems.filter(item => item.assetGroups.length > 0);
};

export const getComputedCampaignLoadStatus = (item: PmaxLoadItem) => {
  const statuses = item.assetGroups.map(assetGroups => assetGroups.loadStatus);
  if (statuses.includes("Loading")) return "Loading";
  if (statuses.every(status => status === "Failed")) return "Failed";
  if (statuses.every(status => status === "Loaded")) return "Loaded";
  return "Mixed";
};

// We currently only support loading ads for a single account at a time
export const getAdAccountNameAndCID = (items: PmaxAdReviewItem[]) => ({
  name: items?.[0]?.adAccount?.name || "",
  cid: items?.[0]?.adAccount?.id || "",
});
