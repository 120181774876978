import moment from "moment-timezone";
import {
  DEFAULT_DURATION,
  DEFAULT_FPS,
} from "screens/assetExporter/feedConfigurationV2/shared/constants";
import { isTemplateComposition } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesContext.utils";
import {
  CompositionType,
  MediaSubtype,
  TComposition,
} from "shared/types/assetExporter";
import { ITemplate } from "shared/types/designStudio";

export const getVariableCollapseHeaderTitle = (
  templates: ITemplate[],
  templateId: string,
  compositionType: CompositionType,
  mediaSubType: MediaSubtype,
) => {
  if (compositionType === CompositionType.Template)
    return templates.find(compTemplate => compTemplate.id === templateId)?.name;
  if (mediaSubType === MediaSubtype.CAM) return "Media from CAM";
  return "Media from feed column or URL";
};

export const getDurationText = (composition: TComposition) => {
  if (
    isTemplateComposition(composition) ||
    composition.column ||
    composition.url
  )
    return `${composition.duration}`;
  return `2`;
};

export const getCurrentTemplate = (
  composition: TComposition,
  templates: ITemplate[],
) => {
  if (isTemplateComposition(composition))
    return templates.find(
      compTemplate => compTemplate.id === composition.template,
    );
};

export const updateDurationWithCompositionId = (
  compositions: TComposition[],
  compositionId: string,
  duration: number,
) => {
  const otherCompositionsShouldBeUpdated = compositions.length > 1;
  return compositions.map(comp => {
    if (comp.compositionId === compositionId) return { ...comp, duration };
    if (otherCompositionsShouldBeUpdated && comp.duration === 0)
      return { ...comp, duration: DEFAULT_DURATION };
    return comp;
  });
};

export const formatTime = (seconds: number) => {
  const duration = moment.duration(seconds, "seconds");

  const minutes = Math.floor(duration.asMinutes());
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
    .toString()
    .padStart(2, "0")}`;
};

export const calculateDurationInFrames = (
  start: number,
  end: number,
  fps: number = DEFAULT_FPS,
) => {
  let duration = Math.floor(end - start) * fps;
  if (duration <= 0) {
    duration = 5 * fps;
  }
  return duration;
};
