import { AutoComplete, Col, Row } from "antd";
import { TComposition, TMediaComposition } from "shared/types/assetExporter";
import { FieldWrapper } from "../../shared/components/FieldWrapper";
import { useAssetBatchesContext } from "../../shared/contexts/AssetBatchesContext";
import { useAssetBatchesValueMappingContext } from "../../shared/contexts/AssetBatchesValueMappingContext";
import { getDefaultDuration } from "../utils";
import styles from "./VariableInput.module.scss";
import { videoCompositionEnabled } from "shared/constants/assetExporter";
import { AudioCheckboxMedia } from "../../assetBatchDrawerV2/manageVariables/panelVariables/UploadAudio/AudioCheckboxMedia";
import {
  getMediaByType,
  isMediaCompositionFromFeed,
} from "../../shared/contexts/AssetBatchesContext.utils";
import { useEffect, useState } from "react";
import { useHasAudioVideo } from "../../shared/hooks/useHasAudioVideo";
import { nonNullable } from "utils/helpers.array";
import { useMediaDataForSelectedRow } from "../../shared/hooks/useMediaDataForSelectedRow";

type MediaFromFeedInputProps = {
  compositionId: string;
};

export const MediaFromFeedInput = ({
  compositionId,
}: MediaFromFeedInputProps) => {
  const [showAudioSelection, setShowAudioSelection] = useState(false);
  const { mediaColumns, compositions, setCompositions } =
    useAssetBatchesContext();
  const { selectedRow } = useAssetBatchesValueMappingContext();
  const composition = compositions.find(
    comp => comp.compositionId === compositionId,
  ) as TMediaComposition;
  const value = composition?.column ?? composition?.url;

  const { isLoading, data: selectedRowMediaUrls } = useMediaDataForSelectedRow(
    selectedRow,
    mediaColumns,
  );
  const filterColumns = getMediaByType(
    ["mp4", "png", "jpg", "jpeg"],
    selectedRow,
    isLoading ? [] : selectedRowMediaUrls.filter(nonNullable),
    mediaColumns,
  );

  const spanValue = videoCompositionEnabled ? 21 : 24;
  const itemAlign = isMediaCompositionFromFeed(composition)
    ? "flex-end"
    : "flex-start";
  const src = composition?.column
    ? selectedRow[composition?.column]
    : composition?.url;
  const { data: hasAudioVideo } = useHasAudioVideo(src);

  useEffect(() => {
    setShowAudioSelection((hasAudioVideo ?? false) && videoCompositionEnabled);
  }, [hasAudioVideo]);

  const editMediaInputValue = async (url: string, column?: string) => {
    const src = column ? selectedRow[column] : url;
    const duration = await getDefaultDuration(src, compositions?.length ?? 0);
    const newCompositions = compositions.map(comp => {
      if (comp.compositionId === compositionId) {
        return {
          ...comp,
          url,
          column,
          duration,
          audioEnabled: true,
        };
      }
      return comp;
    }) as TComposition[];
    setCompositions(newCompositions);
  };

  return (
    <Row className={styles.row} style={{ alignItems: `${itemAlign}` }}>
      <Col span={spanValue}>
        <FieldWrapper width={24}>
          <AutoComplete
            allowClear={true}
            options={filterColumns?.map(col => {
              return {
                key: col.columnName,
                value: col.columnName,
              };
            })}
            filterOption
            className={styles.input}
            bordered={true}
            value={value}
            onChange={(val, option) => {
              if (val && "key" in option && typeof option.key === "string")
                editMediaInputValue("", option.key);
              else editMediaInputValue(val);
            }}
            onKeyDown={e => {
              if (e.key === "Enter" && value) editMediaInputValue("", value);
              if (e.key === "Esc" || e.key === "Escape")
                editMediaInputValue("");
            }}
          />
        </FieldWrapper>
      </Col>
      {showAudioSelection && (
        <Col span={3}>
          <AudioCheckboxMedia composition={composition} />
        </Col>
      )}
    </Row>
  );
};
