import { Button, Checkbox, Tooltip } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { setSelectedAssetBuildInstance } from "redux/assetBuilder/assetBuilder.slice";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { useAppSelector } from "shared/hooks/useAppSelector";
import {
  IAssetBuildInstance,
  SelectedInstance,
} from "shared/types/assetBuilder";

import styles from "./SelectInstance.module.scss";

type Props = {
  order: number;
  instance: IAssetBuildInstance;
  instancesBySize: Record<string, IAssetBuildInstance[]>;
};

export const SelectInstance = ({ order, instance, instancesBySize }: Props) => {
  const dispatch = useAppDispatch();
  const selectedInstances = useAppSelector(
    state => state.assetBuilder.buildPage.selectedInstance,
  );
  const selectedIds = selectedInstances.map(i => i.instance.id);
  const tplId = instance.template?.id;

  if (!tplId) {
    return null;
  }

  const instances = Object.entries(instancesBySize)
    .flatMap(([, v]) => v)
    .filter(i => i.template?.id === tplId)
    .map(mapSelectedInstance);

  const allInstancesSelectedBySize =
    instances.length === selectedInstances.length;

  const enabled =
    !instance?.isCustomImage &&
    (!selectedInstances.length ||
      tplId === selectedInstances[0].instance.template?.id);

  const toggleInstance = (ev: CheckboxChangeEvent) => {
    // If the instance is a custom image (custom integration), then we want to avoid any editing
    if (instance.isCustomImage) return;
    if (ev.target.checked) {
      dispatch(
        setSelectedAssetBuildInstance([
          ...selectedInstances,
          mapSelectedInstance(instance, order),
        ]),
      );
    } else {
      dispatch(
        setSelectedAssetBuildInstance(
          selectedInstances.filter(i => i.instance.id !== instance.id),
        ),
      );
    }
  };

  const toggleSelectAllWithSameTemplate = () => {
    if (allInstancesSelectedBySize) {
      dispatch(setSelectedAssetBuildInstance([]));
    } else {
      dispatch(setSelectedAssetBuildInstance(instances));
    }
  };

  return (
    <div className={styles.host}>
      <Tooltip
        title={
          !enabled
            ? "You can only bulk edit instances that use the same template within the same size"
            : ""
        }
      >
        <Checkbox
          checked={selectedIds.includes(instance.id)}
          disabled={!enabled}
          onChange={toggleInstance}
        />
      </Tooltip>
      <Button
        disabled={instance.isCustomImage}
        size="small"
        onClick={toggleSelectAllWithSameTemplate}
      >
        {allInstancesSelectedBySize
          ? "Unselect all with this template"
          : "Select all with this template"}
      </Button>
    </div>
  );
};

const mapSelectedInstance = (
  instance: IAssetBuildInstance,
  order: number,
): SelectedInstance => {
  const template = instance.template!;

  return {
    instance,
    assetType: template.assetType.toLowerCase(),
    order,
    size: `${template.artboard.width} x ${template.artboard.height}`,
  };
};
