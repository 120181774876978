import { TableActionsContextProvider } from "shared/components/HoverActionsVirtualTable/TableActionsContext";
import { DataListTagsURL } from "shared/components/dataListURL/DataListTagsURL";
import DataListURLTable from "shared/components/dataListURL/DataListURLTable";
import { ContextTableActions } from "shared/components/tableActions/ContextTableActions";
import { InlineTableActions } from "shared/components/tableActions/InlineTableActions";
import { useIsBrochureAdmin } from "shared/hooks/useIsBrochureUser";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { getFiltersFromData } from "utils/helpers.table";
import { BrochureRequest } from "../../shared/types/brochureManagement";
import Toolbar from "./Toolbar";
import { columns } from "./columns";
import { fieldKeys } from "./fields";
import {
  useBrochureRequests,
  useTriggerBrochure,
} from "./hooks/useBrochureRequests";
import { generateToolbarContents } from "./toolbarContents";

const Table = () => {
  const { windowInnerHeight } = useWindowSize();
  const {
    data: brochureRequests,
    originalData: unfilteredBrochureRequests,
    isLoading,
  } = useBrochureRequests();
  const { mutate: regenFn } = useTriggerBrochure();

  const isBrochureAdmin = useIsBrochureAdmin();
  const getFilters = getFiltersFromData(brochureRequests);
  const cols = columns(getFilters);

  return (
    <div style={{ padding: "1em 0" }}>
      <Toolbar />
      <DataListTagsURL<keyof BrochureRequest, BrochureRequest>
        data={brochureRequests}
        originalData={unfilteredBrochureRequests}
        disableRowSelection
      />
      <TableActionsContextProvider<BrochureRequest>
        renderContextActions={record => (
          <ContextTableActions
            getItemsToRender={record =>
              generateToolbarContents(record, isBrochureAdmin, regenFn)
            }
            record={record}
          />
        )}
        renderHoverActions={record => (
          <InlineTableActions
            getItemsToRender={record =>
              generateToolbarContents(record, isBrochureAdmin, regenFn)
            }
            record={record}
          />
        )}
      >
        <DataListURLTable<keyof BrochureRequest, BrochureRequest>
          loading={isLoading}
          dataSource={brochureRequests}
          columns={cols}
          pagination={false}
          size="small"
          scroll={{ y: windowInnerHeight - 245 }}
          type="hoverActionsVirtualTable"
          fieldKeys={fieldKeys}
          disableRowSelection
        />
      </TableActionsContextProvider>
    </div>
  );
};

export default Table;
