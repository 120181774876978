import { useParams } from "react-router-dom";
import { useFetchDashboards } from "shared/hooks/dashboardManagement/useFetchDashboards";
import LookerDashboard from "./LookerDashboard";

export type DynamicDashboardDetailProps = {
  allowDownload?: boolean;
};

const DynamicDashboardDetail = ({
  allowDownload,
}: DynamicDashboardDetailProps) => {
  const { name } = useParams() as { name: string };
  const { dashboards, isLoading: loadingDashboard } = useFetchDashboards();

  const normalizeTitle = (title: string) =>
    title.toLowerCase().replace(/ /g, "-");

  const dashboard = dashboards?.find(
    dash => normalizeTitle(dash.title ?? "") === name,
  );

  return (
    <LookerDashboard
      dashboard={dashboard}
      loadingDashboard={loadingDashboard}
      allowDownload={allowDownload}
    />
  );
};

export default DynamicDashboardDetail;
