import { StoreType } from "screens/designStudio/designStudioV2/types";
import { useLayers } from "./layers/useLayers";
import { LayerItem } from "./layers/LayerItem";
import { Grid, Typography, Box } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { observer } from "mobx-react-lite";

type LayerProps = {
  store: StoreType;
};

export const Layers = observer(({ store }: LayerProps) => {
  const {
    layers,
    selectedLayerId,
    onDoubleClick,
    onClick,
    onKeyDown,
    onClickOutside,
    onDragEnd,
  } = useLayers(store);

  return (
    <Grid container gap={3} direction="column" flex={1} sx={{ pt: 1 }}>
      {!!layers.length && (
        <Typography>Elements on your active page:</Typography>
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="layers-droppable" type="layer">
          {provided => {
            return (
              <Grid
                container
                gap={1}
                direction="column"
                flex={1}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {layers.map((layer, idx) => (
                  <Draggable key={layer.id} draggableId={layer.id} index={idx}>
                    {(draggableProvided, snapshot) => {
                      return (
                        <Box
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          ref={draggableProvided.innerRef}
                          sx={{
                            ...draggableProvided.draggableProps.style,
                            boxShadow: theme =>
                              snapshot.isDragging
                                ? `0px 2px 0px 0px ${theme.palette.primary.main}`
                                : "none",
                            borderRadius: 1,
                          }}
                        >
                          <LayerItem
                            key={layer.id}
                            layer={layer}
                            selectedLayerId={selectedLayerId}
                            onDoubleClick={onDoubleClick}
                            onClick={onClick}
                            onClickOutside={onClickOutside}
                            onKeyDown={onKeyDown}
                          />
                        </Box>
                      );
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Grid>
            );
          }}
        </Droppable>
      </DragDropContext>
    </Grid>
  );
});
