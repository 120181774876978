import API from "services";

import { useMutation, useQueryClient, type InfiniteData } from "react-query";
import useSetAdsQueryData from "./useSetAdsQueryData";

import {
  IAd,
  ICreateOrUpdateAdResponseData,
  IGetAdsResponseData,
} from "shared/types/adLibrary";

export const useOptimisticallyMutateAdShell = () => {
  const queryClient = useQueryClient();
  const setAdsQueryData = useSetAdsQueryData();

  return useMutation<
    ICreateOrUpdateAdResponseData | null,
    Error,
    IAd,
    { previousAds?: InfiniteData<IGetAdsResponseData> }
  >(createOrUpdateAdShell, {
    // Optimistic update for mutate ad shell
    onMutate: async (ad: IAd) => {
      const previousAds = await setAdsQueryData({
        ads: [ad],
        operation: "mutate",
      });

      return { previousAds };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_err, _newAds, context) => {
      queryClient.setQueryData("ads", context?.previousAds);
    },
    onSettled: () => {
      queryClient.invalidateQueries("ads");
    },
  });
};

export const useBatchMutateAdShells = () => {
  const queryClient = useQueryClient();
  const setAdsQueryData = useSetAdsQueryData();

  return useMutation<
    ICreateOrUpdateAdResponseData[] | null,
    Error,
    IAd[],
    { previousAds?: InfiniteData<IGetAdsResponseData> }
  >(batchCreateOrUpdateAdShells, {
    // Optimistic update for mutate ad shell
    onMutate: async (ads: IAd[]) => {
      const previousAds = await setAdsQueryData({
        ads,
        operation: "mutate",
      });

      return { previousAds };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_err, _newAds, context) => {
      queryClient.setQueryData("ads", context?.previousAds);
    },
    onSettled: () => {
      queryClient.invalidateQueries("ads");
    },
  });
};

const createOrUpdateAdShell = async (ad: IAd) => {
  const { result, error } = await API.services.adLibrary.createOrUpdateAdShell(
    ad,
  );

  if (error) {
    throw Error(error.message);
  }

  return result;
};

const batchCreateOrUpdateAdShells = async (ads: IAd[]) => {
  const { result, error } =
    await API.services.adLibrary.batchCreateOrUpdateAdShells(ads);

  if (error) {
    throw Error(error.message);
  }

  return result;
};
