import { Typography, Stack } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

export const AutoSaveIndicator = () => (
  <Stack
    direction="row"
    spacing={1}
    alignItems="center"
    position="absolute"
    sx={{
      top: 155,
      right: 16,
      zIndex: 1000,
      "@keyframes save-bounce": {
        "0%": { transform: "translateY(0)" },
        "50%": { transform: "translateY(-4px)" },
        "100%": { transform: "translateY(0)" },
      },
    }}
  >
    <Typography
      sx={{
        fontSize: 13,
        color: "text.secondary",
      }}
    >
      Auto-saving
    </Typography>
    <CloudDownloadIcon
      fontSize="small"
      sx={{
        color: "action.active",
        animation: "save-bounce 1s ease infinite",
      }}
    />
  </Stack>
);
