import axios from "axios";
import { useQuery } from "react-query";
import { TemplateFile } from "shared/types/salesEnablement";

export function useFetchHtmlFromUrl(templateFile?: TemplateFile) {
  return useQuery<TemplateFile | undefined>(
    ["selected-file", templateFile?.id],
    async () => {
      if (!templateFile?.url) return;

      const file = await axios.get(templateFile.url, {
        responseType: "text",
      });

      return {
        ...templateFile,
        html: file.data,
      };
    },
    {
      enabled: !!templateFile,
    },
  );
}
