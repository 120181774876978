import { ImageList as MUIImageList } from "@mui/material";
import { StyledImageListItem } from "../StyledImageListItem";
import { useDragToCanvas } from "../useDragToCanvas";

export type ImageListItem = {
  src: string;
  width?: number;
  height?: number;
};

type ImageListProps = {
  images: ImageListItem[];
  onClick: (file: ImageListItem) => void;
  onRemove?: (file: ImageListItem) => void;
};

export const ImageList = ({ images, onClick, onRemove }: ImageListProps) => {
  const { getDragEndPosition } = useDragToCanvas();
  return (
    <MUIImageList variant="masonry" cols={2} gap={8}>
      {images?.map(img => (
        <StyledImageListItem key={img.src} onClick={() => onClick(img)}>
          <img
            srcSet={img.src}
            src={img.src}
            crossOrigin="anonymous"
            draggable
            loading="lazy"
            onError={() => onRemove?.(img)}
            onDragEnd={() =>
              onClick({
                ...img,
                ...getDragEndPosition(img),
              })
            }
          />
        </StyledImageListItem>
      ))}
    </MUIImageList>
  );
};
