import {
  SyncOutlined,
  InfoCircleFilled,
  CheckCircleFilled,
} from "@ant-design/icons";
import { Tooltip, Spin, Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { IAdValidations } from "../../adValidationUtils";
import styles from "./CheckboxCell.module.scss";

type CheckboxCellProps = {
  isLoading: boolean;
  isSelected: boolean;
  onChange: (e: CheckboxChangeEvent) => void;
  adValidation: IAdValidations | undefined;
  selectEnabled: boolean;
};

export const CheckboxCell = ({
  isLoading,
  isSelected,
  adValidation,
  onChange,
  selectEnabled,
}: CheckboxCellProps) => {
  if (isLoading)
    return (
      <Tooltip title="Loading Media">
        <Spin indicator={<SyncOutlined spin />} size="small" />
      </Tooltip>
    );
  if (adValidation?.hasErrors) {
    return <InfoCircleFilled className={styles.errorIcon} />;
  }

  if (!selectEnabled && !adValidation?.hasErrors) {
    return <CheckCircleFilled className={styles.successIcon} />;
  }

  return <Checkbox checked={isSelected} onChange={onChange} />;
};
