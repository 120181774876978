import { PlusCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import style from "./CreateNewSelectLabel.module.scss";

type Props = Readonly<{
  onCreateClick: () => void;
  label: string;
}>;

const CreateNewSelectLabel = ({ onCreateClick, label }: Props) => {
  return (
    <Button
      onClick={event => {
        event.preventDefault();
        onCreateClick();
      }}
      icon={<PlusCircleOutlined />}
      type="text"
      className={style.button}
    >
      {label}
    </Button>
  );
};

export default CreateNewSelectLabel;
