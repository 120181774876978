import { IConfig } from "shared/types/configuration";
import { IDashboardSetting } from "shared/types/dashboardManagement";
import HttpClient from "./httpClient";

export default ({ config }: { config: IConfig }) => ({
  getLookerThumbnail: <T>(dashboardId: string): Promise<T> => {
    const { services } = config;
    const getLookerThumbnail = `${services.getLookerThumbnailUrl}?dashboardId=${dashboardId}`;
    return HttpClient.get<any>(getLookerThumbnail);
  },
  generateLookerIframe: <T>(
    dashboardId: string,
    dashboardSettings?: IDashboardSetting[] | null,
    allowDownload?: boolean,
  ): Promise<T> => {
    const { services } = config;
    const generateLookerIframeUrl = services.generateLookerIframeUrl;
    return HttpClient.post<any>(
      generateLookerIframeUrl,
      { dashboardId, dashboardSettings, allowDownload },
      {},
      false,
    );
  },
  generatePublicLookerIframe: (publicDashboardId: string): Promise<string> => {
    const { services } = config;
    const generatePublicLookerIframeUrl =
      services.generatePublicLookerIframeUrl;
    return HttpClient.post<string>(
      generatePublicLookerIframeUrl,
      { publicDashboardId },
      {},
      false,
    );
  },
});
