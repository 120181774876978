import { useColumn } from "../useColumn";
import { transformBytes } from "../../utils.filesize";

export const useColSize = () => {
  return useColumn("filesize", {
    title: "Size",
    renderFn(text, { type }) {
      return <span>{type === "asset" ? transformBytes(text) : null}</span>;
    },
  });
};
