import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { TableActionsContextProvider } from "shared/components/HoverActionsVirtualTable/TableActionsContext";
import { ContextTableActions } from "shared/components/tableActions/ContextTableActions";
import { InlineTableActions } from "shared/components/tableActions/InlineTableActions";
import { ToolbarButton } from "shared/types/adLibrary";
import LineItemToolbar from "./LineItemToolbar";
import { BeeswaxCampaignURLParams, LineItem } from "../types";
import { useLineItems } from "./hooks/useLineItems";
import { useWindowSize } from "shared/hooks/useWindowSize";
import DataListURLTable from "shared/components/dataListURL/DataListURLTable";
import { fieldKeys } from "./lineItemFields";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../constants";
import { useBeeswaxCampaignsContext } from "../BeeswaxCampaignsProvider";
import { useLineItemActions } from "./hooks/useLineItemActions";
import { useLineItemColumns } from "./lineItemColumns";

const LineItemTable = () => {
  const { windowInnerHeight } = useWindowSize();
  const { data: lineItems, isLoading } = useLineItems();
  const navigate = useNavigate();
  const { campaignId } = useParams<BeeswaxCampaignURLParams>();
  const { selectLineItem } = useBeeswaxCampaignsContext();
  const { deleteLineItems } = useLineItemActions();
  const columns = useLineItemColumns();

  const generateToolbarContents: (
    record?: LineItem,
  ) => ToolbarButton = record => {
    if (!record) return {};

    return {
      Edit: {
        icon: <EditOutlined />,
        onClick: () => {
          selectLineItem(record.id);
          navigate(ROUTES.editLineItem(campaignId));
        },
        showInContextMenu: true,
        showInInlineMenu: true,
      },
      Delete: {
        icon: <DeleteOutlined />,
        onClick: () => {
          deleteLineItems([record.id]);
        },
        showInContextMenu: true,
        showInInlineMenu: true,
      },
    };
  };

  return (
    <div style={{ padding: "1em 0" }}>
      <LineItemToolbar />
      <TableActionsContextProvider<LineItem>
        renderContextActions={record => (
          <ContextTableActions
            getItemsToRender={generateToolbarContents}
            record={record}
          />
        )}
        renderHoverActions={record => (
          <InlineTableActions
            getItemsToRender={generateToolbarContents}
            record={record}
          />
        )}
      >
        <DataListURLTable<keyof LineItem, LineItem>
          loading={isLoading}
          dataSource={lineItems}
          columns={columns}
          size="small"
          scroll={{ y: windowInnerHeight - 245 }}
          type="hoverActionsVirtualTable"
          fieldKeys={fieldKeys}
        />
      </TableActionsContextProvider>
    </div>
  );
};

export default LineItemTable;
