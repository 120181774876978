import { Typography } from "antd";
import Link from "antd/lib/typography/Link";
import { useAssetBatchesContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesContext";
import styles from "./TemplateHeader.module.scss";

export const TemplateHeader = () => {
  const { setOpenFileManager, templatesToUse: templates } =
    useAssetBatchesContext();

  const handleEditTemplate = () => {
    const editorUrl = `/design-studio/editor/templates/${templates[0]?.id}/editor-v2?from=ad-engine`;
    window.open(editorUrl, "_blank");
  };

  return (
    <div className={styles.templateHeader}>
      <Typography.Title level={5}>{"Preview Template:"}</Typography.Title>
      {templates[0].type === "html" ? (
        <Typography.Text className={styles.templateName}>
          {`${templates[0]?.name || "-"}`}
        </Typography.Text>
      ) : (
        <Link className={styles.templateName} onClick={handleEditTemplate}>
          {`${templates[0]?.name || "-"}`}
        </Link>
      )}

      <Link
        onClick={() => {
          setOpenFileManager(true);
        }}
        className={styles.changeTemplate}
      >
        Change Template
      </Link>
    </div>
  );
};
