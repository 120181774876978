import { SelectChangeEvent } from "@mui/material";
import { StoreType } from "polotno/model/store";
import { useState } from "react";
import { useMutation } from "react-query";
import { useDesignStudioEditorItems } from "screens/designStudio/designStudioLayout/designStudioTable/hooks/useDesignStudioEditorItems";
import {
  DownloadFileType,
  download,
} from "screens/designStudio/designStudioV2/editorDrawer/editorDrawerContext/helpers.store";

export const useDownload = (store: StoreType) => {
  const { activeItem } = useDesignStudioEditorItems();
  const [downloadType, setDownloadType] = useState<DownloadFileType>("PNG");
  const [pixelRatio, setPixelRatio] = useState<number>(1);
  const [fps, setFps] = useState<number>(30);
  const [pageSizeModifier, setPageSizeModifier] = useState<number>(1);

  const {
    mutate: onDownload,
    isLoading: isDownloading,
    isError,
  } = useMutation(() =>
    download(store, {
      type: downloadType,
      fileName: activeItem?.asset?.name ?? "template",
      pixelRatio,
      fps,
      pageSizeModifier,
    }),
  );

  const onChange = (event: SelectChangeEvent<DownloadFileType>) => {
    const type = event.target.value as DownloadFileType;
    setDownloadType(type);
  };

  return {
    downloadType,
    onChange,
    onDownload,
    pixelRatio,
    setPixelRatio,
    fps,
    setFps,
    pageSizeModifier,
    setPageSizeModifier,
    isDownloading,
    isError,
  };
};
