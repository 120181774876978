import { CalendarOutlined, SearchOutlined } from "@ant-design/icons";
import { Badge } from "antd";
import { returnFilterOptions } from "screens/adLibrary/adLoad/adLoadDrawer/shared/components/adsToLoadTable/utils";
import { DateFilterDropdown } from "shared/components/DateFilterDropdown";
import { FilterDropdownWithFilterSearch } from "shared/components/FilterDropdownWithFilterSearch";
import { TimeAgo } from "shared/components/TimeAgo";
import { ITableColumnURL } from "shared/components/dataListURL/DataListURLTable";
import { KeysWithStringOrUndefinedValues } from "utils/utilityTypes";
import {
  BrochureRequest,
  BrochureRequestStatus,
} from "../../shared/types/brochureManagement";

const getCalendarIcon = (filtered: boolean) => (
  <CalendarOutlined color={filtered ? "#1890ff" : undefined} />
);

export const getVersionColor = (status?: BrochureRequestStatus) => {
  if (status === "failed") return "red";
  if (status === "completed") return "green";
  return "blue";
};

export const columns: (
  getFilters: (
    key: KeysWithStringOrUndefinedValues<BrochureRequest>,
  ) => { text: string; value: string }[],
) => ITableColumnURL<keyof BrochureRequest, BrochureRequest>[] = getFilters => [
  {
    key: "status",
    title: "Status",
    dataIndex: ["status"],
    filters: returnFilterOptions(["Completed", "Failed", "Processing"]),
    filterDropdown: FilterDropdownWithFilterSearch,
    filterIcon: <SearchOutlined />,
    render: (status: string) => {
      const badgeColor = getVersionColor(status as BrochureRequestStatus);
      return (
        <div>
          <Badge color={badgeColor} />
          {status.charAt(0).toUpperCase() + status.slice(1)}
        </div>
      );
    },
    width: 150,
  },
  {
    key: "planId",
    title: "Plan ID",
    dataIndex: ["planId"],
    filters: getFilters("planId"),
    filterDropdown: FilterDropdownWithFilterSearch,
    filterIcon: <SearchOutlined />,
  },
  {
    key: "productLine",
    title: "Product Line",
    dataIndex: ["productLine"],
    filters: getFilters("productLine"),
    filterDropdown: FilterDropdownWithFilterSearch,
    filterIcon: <SearchOutlined />,
  },
  {
    key: "companyName",
    title: "Company Name",
    dataIndex: ["companyName"],
    filters: getFilters("companyName"),
    filterDropdown: FilterDropdownWithFilterSearch,
    filterIcon: <SearchOutlined />,
  },
  {
    key: "createdAt",
    title: "Created",
    dataIndex: ["createdAt"],
    render: (date: Date) => <TimeAgo date={date} />,
    filterDropdown: DateFilterDropdown,
    filterIcon: getCalendarIcon,
  },
  {
    key: "createdBy",
    title: "Created By",
    dataIndex: ["createdBy"],
    filters: getFilters("createdBy"),
    filterDropdown: FilterDropdownWithFilterSearch,
    filterIcon: <SearchOutlined />,
  },
  {
    key: "state",
    title: "State",
    dataIndex: ["state"],
    filters: getFilters("state"),
    filterDropdown: FilterDropdownWithFilterSearch,
    filterIcon: <SearchOutlined />,
  },
  {
    key: "productSeries",
    title: "Product Series",
    dataIndex: ["productSeries"],
    filters: getFilters("productSeries"),
    filterDropdown: FilterDropdownWithFilterSearch,
    filterIcon: <SearchOutlined />,
  },
];
