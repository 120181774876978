import { useMemo } from "react";
import { TemplatesDisplay } from "./TemplatesDisplay";
import TemplateDrawer from "screens/designStudio/templates/TemplateDrawer";
import { Spin } from "antd";
import { useFetchTemplates } from "shared/hooks/designStudio/useFetchTemplates";
import { Template } from "shared/types/salesEnablement";
import { MAX_DISPLAY } from "./utils/constants";
import { useFetchRecentTemplates } from "shared/hooks/salesEnablement/useFetchRecentTemplates";

const sortTemplates = (templates: Template[]): Template[] =>
  [...templates]
    .sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    )
    .slice(0, MAX_DISPLAY);

type Props = {
  userId: string;
  onClick: () => void;
};
export const RecentlyUsedTemplates = ({ userId, onClick }: Props) => {
  const { data, isLoading, isError } = useFetchRecentTemplates(userId);
  const templates = useMemo(
    () => data?.result.filter(template => !!template) ?? [],
    [data],
  );
  return (
    <Spin spinning={isLoading && !isError}>
      <TemplatesDisplay
        isError={isError}
        title={"My Recently Used Templates"}
        description={
          "Aflac-provided templates for you to create your marketing materials"
        }
        templates={templates}
        handleClick={onClick}
      />
      <TemplateDrawer drawerMode="edit" toHomepage />
    </Spin>
  );
};

export const TemplatesAddedByMe = ({ userId, onClick }: Props) => {
  const { templates, isTemplateLoading, isError } = useFetchTemplates();
  const templatesAddedByMeSorted = useMemo(() => {
    const adminTemplates = templates.filter(
      template => template.createdBy === userId,
    );
    return sortTemplates(adminTemplates);
  }, [templates, userId]);

  return (
    <Spin spinning={isTemplateLoading}>
      <TemplatesDisplay
        isError={isError}
        title={"Templates Added by Me"}
        templates={templatesAddedByMeSorted}
        handleClick={onClick}
      />
      <TemplateDrawer drawerMode="edit" toHomepage />
    </Spin>
  );
};
