import { PlusOutlined } from "@ant-design/icons";
import { Col, Input, message, Modal, Row, Select, Form } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { User } from "redux/auth/auth.slice";

import FormDrawer from "shared/components/FormDrawer";
import { GenericLocationSelect } from "shared/components/select/GenericLocationSelect";
import { IAccount, IAccountRecord } from "shared/types/accountManagement";
import { IBrand, IBrandRecord } from "shared/types/brandManagement";
import {
  CreatingDataStatus,
  FetchingDataStatus,
  IDesignStudioState,
  INewStamp,
  IStamp,
  ITemplateTag,
  UpdatingDataStatus,
} from "shared/types/designStudio";
import { OperationMode } from "shared/types/inputValues";

import actions from "../../redux/rootActions";

import "./NewStampDrawer.scss";

const getInitialNewStamp = (user: User): INewStamp =>
  ({
    status: "UN-PUBLISHED",
    name: "",
    offerType: null,
    width: 0,
    height: 0,
    oem: "",
    oems: [],
    state: "",
    stores: [],
    tags: [],
    createdAt: Date.now(),
    updatedAt: Date.now(),
    createdBy: user.email,
    updatedBy: user.email,
    isDeleted: false,
  } as INewStamp);

interface INewStampDrawer {
  fetchingData: FetchingDataStatus;
  fetchData: (dataType: FetchingDataStatus, query?: string) => void;
  createTag: (tag: ITemplateTag) => void;
  createStamp: (stamp: INewStamp) => void;
  creatingData: CreatingDataStatus;
  tags: ITemplateTag[];
  oemRecords: IBrandRecord[];
  dealerRecords: IAccountRecord[];
  user: User;
  mode?: OperationMode;
  showNewStampDrawer: boolean;
  closeDrawer: () => void;
  stampToUpdate?: null | IStamp;
  removeStampToUpdate: () => void;
  updateStamp: (stamp: IStamp) => void;
  updatingData: UpdatingDataStatus;
  stamps: IStamp[];
  getDealers: (paginationToken?: string) => void;
  getOems: (paginationToken?: string) => void;
}

const NewStampDrawer: React.FC<INewStampDrawer> = ({
  fetchingData,
  fetchData,
  creatingData,
  createStamp,
  createTag,
  tags,
  oemRecords,
  dealerRecords,
  user,
  mode = "",
  showNewStampDrawer = false,
  closeDrawer,

  stampToUpdate,
  removeStampToUpdate,
  updateStamp,
  updatingData,
  stamps,
  getDealers,
  getOems,
}) => {
  useEffect(() => {
    if (
      creatingData === "complete_stamp" ||
      updatingData === "complete_stamp"
    ) {
      message.success(
        creatingData === "complete_stamp"
          ? "Stamp Created Successfully"
          : "Stamp updated successfully.",
      );
      setNewStamp(getInitialNewStamp(user));
      removeStampToUpdate();
      closeDrawer();
    }

    // eslint-disable-next-line
  }, [creatingData, updatingData]);

  const [newStamp, setNewStamp] = useState<INewStamp | IStamp>(
    getInitialNewStamp(user),
  );

  useEffect(() => {
    if (!stampToUpdate) {
      return;
    }

    setNewStamp({
      ...stampToUpdate,
      updatedBy: user.email, // updatedAt will be assigned with server timestamp
    } as IStamp);

    setSelectedBrandName(stampToUpdate.oem as string);

    if (stampToUpdate.oems) {
      setSelectedBrandNames(stampToUpdate.oems);
    } else {
      setSelectedBrandNames(
        stampToUpdate.oem ? [stampToUpdate.oem as string] : [],
      );
    }

    setSelectedAccountNames(
      (stampToUpdate.stores && (stampToUpdate.stores as string[])) || [],
    );
    setSelectedTagNames(stampToUpdate.tags);
    setSelectedState(stampToUpdate.state);

    // eslint-disable-next-line
  }, [stampToUpdate]);

  const filterData = (
    type: FetchingDataStatus | "brands",
    arr:
      | IBrand[]
      | IAccount[]
      | ITemplateTag[]
      | IBrandRecord[]
      | IAccountRecord[],
    searchby: string,
  ) => {
    if (searchBy.toLowerCase().trim() === "") {
      return arr;
    }

    switch (type) {
      case "brands":
        return (arr as IBrandRecord[]).filter(brand =>
          brand.oemName.toLowerCase().includes(searchby.toLowerCase()),
        );
      case "accounts":
        return (arr as IAccountRecord[]).filter(account =>
          account.dealerName.toLowerCase().includes(searchby.toLowerCase()),
        );
      case "tags":
        return (arr as ITemplateTag[]).filter(tag =>
          tag.name.toLowerCase().includes(searchby.toLowerCase()),
        );
      default:
        return arr;
    }
  };

  const [searchBy, setSearchBy] = useState<string>("");

  const filteredBrands = filterData(
    "brands",
    oemRecords,
    searchBy,
  ) as IBrandRecord[];
  const filteredAccounts = filterData(
    "accounts",
    dealerRecords,
    searchBy,
  ) as IAccountRecord[];
  const filteredTags = filterData("tags", tags, searchBy) as ITemplateTag[];
  const [selectedState, setSelectedState] = useState<string>("");
  const [selectedBrandName, setSelectedBrandName] = useState<string>("");
  const [selectedBrandNames, setSelectedBrandNames] = useState<string[]>([]);
  const [selectedAccountNames, setSelectedAccountNames] = useState<string[]>(
    [],
  );
  const [selectedTagNames, setSelectedTagNames] = useState<string[]>([]);

  let validStampName = false;
  const filteredStamps = stamps.filter(stamp => stamp.name === newStamp.name);
  if (mode === "CREATE") {
    validStampName = filteredStamps.length < 1;
  } else if (mode === "UPDATE") {
    validStampName =
      filteredStamps.length < 1 || newStamp.name === stampToUpdate?.name;
  }

  const loadMoreData = (dataType: "brands" | "accounts") => {
    if (dataType === "brands") {
      const { oemName: lastBrandName } = oemRecords[oemRecords.length - 1] || {
        oemName: "",
      };
      getOems(encodeURIComponent(lastBrandName));
    } else {
      const { dealerName: lastAccountName } = dealerRecords[
        dealerRecords.length - 1
      ] || {
        dealerName: "",
      };
      getDealers(encodeURIComponent(lastAccountName));
    }
    return;
  };

  const drawerForm = (
    <Form layout="vertical" hideRequiredMark={true}>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            label="Define Stamp Name"
            validateStatus={!validStampName ? "error" : ""}
            hasFeedback={!validStampName}
            help={!validStampName && "That name has already been used."}
          >
            <Input
              className="stamp-name-input"
              key="stamp-name-input"
              onChange={event => {
                setNewStamp({
                  ...newStamp,
                  name: event.target.value,
                });
              }}
              value={newStamp.name}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row key="Row-select-brands" gutter={16}>
        <Col span={24}>
          <Form.Item label="Assign Brand(s)">
            <Select
              showSearch={true}
              className="select-for-oems"
              // AV2-1754: remove the disable condition for now
              // disabled={
              //   selectViewObject.type !== "stores" &&
              //   stampToUpdate !== null &&
              //   updatingData !== "duplicating_stamp"
              // }
              key="new-stamp-brands-select"
              mode="multiple"
              filterOption={true}
              style={{ width: "100%" }}
              labelInValue={true}
              value={(selectedBrandNames as string[]).map(store => ({
                key: `store-option-key-${store}`,
                value: store,
                label: store,
              }))}
              onFocus={() => {
                if (oemRecords.length === 0) {
                  getOems();
                }
                setSearchBy(""); // reset the list
              }}
              loading={false}
              onSearch={value => {
                setSearchBy(value);
              }}
              onSelect={option => {
                setSearchBy("");
                const filter = option.key;
                if (filter === "load-more-button") {
                  loadMoreData("brands");
                  return;
                }

                if (!selectedBrandNames.includes(option.value)) {
                  setSelectedBrandNames([...selectedBrandNames, option.value]);
                }
              }}
              onDeselect={option => {
                setSelectedBrandNames(
                  selectedBrandNames.filter(
                    name => name !== (option.value as string),
                  ),
                );
              }}
            >
              {filteredBrands.map((brand: IBrandRecord) => (
                <Select.Option
                  value={brand.oemName}
                  key={`brand-option-key-${brand.oemName}`}
                >
                  {brand.oemName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row key="Row-select-accounts" gutter={16}>
        <Col span={24}>
          <Form.Item label="Assign Account(s) (optional)">
            <Select
              showSearch={true}
              className="select-for-stores"
              // AV2-1754: remove the disable condition for now
              // disabled={
              //   selectViewObject.type !== "stores" &&
              //   stampToUpdate !== null &&
              //   updatingData !== "duplicating_stamp"
              // }
              key="new-stamp-accounts-select"
              mode="multiple"
              filterOption={true}
              style={{ width: "100%" }}
              labelInValue={true}
              value={
                newStamp && newStamp.stores
                  ? (selectedAccountNames as string[]).map(account => ({
                      key: `account-option-key-${account}`,
                      value: account,
                      label: account,
                    }))
                  : []
              }
              onFocus={() => {
                if (dealerRecords.length === 0) {
                  getDealers();
                }

                setSearchBy(""); // reset the list
              }}
              loading={false}
              onSearch={value => {
                setSearchBy(value);
              }}
              onSelect={option => {
                setSearchBy("");
                const filter = option.key;

                if (filter === "load-more-button") {
                  loadMoreData("accounts");
                  return;
                }

                if (!selectedAccountNames.includes(option.value)) {
                  setSelectedAccountNames([
                    ...selectedAccountNames,
                    option.value,
                  ]);
                }
              }}
              onDeselect={option => {
                const removed = selectedAccountNames.filter(
                  name => name !== option.value,
                );
                setSelectedAccountNames(removed);
              }}
            >
              {filteredAccounts.map((account: IAccountRecord) => (
                <Select.Option
                  value={account.dealerName}
                  key={`account-option-key-${account.dealerName}`}
                >
                  {account.dealerName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={14}>
          <Form.Item label="Enter Stamp Size">
            <Row gutter={16}>
              <Col span={12}>
                <Input
                  className="width-input"
                  type="number"
                  disabled={
                    !!stampToUpdate && updatingData !== "duplicating_stamp"
                  }
                  min={0}
                  prefix="W"
                  suffix="px"
                  value={newStamp.width}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const { target } = event;
                    const { value } = target;
                    setNewStamp({
                      ...newStamp,
                      width: parseInt(value, 10),
                    });
                  }}
                />
              </Col>
              <Col span={12}>
                <Input
                  className="height-input"
                  type="number"
                  disabled={
                    !!stampToUpdate && updatingData !== "duplicating_stamp"
                  }
                  min={0}
                  prefix="H"
                  suffix="px"
                  value={newStamp.height}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const { target } = event;
                    const { value } = target;
                    setNewStamp({
                      ...newStamp,
                      height: parseInt(value, 10),
                    });
                  }}
                />
              </Col>
            </Row>
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item label="Select Location">
            <GenericLocationSelect
              className="select-for-state"
              defaultValue={selectedState}
              value={selectedState}
              onChange={(value: string) => {
                setSelectedState(value);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Assign Tag(s) (optional)">
            <Select
              className={`select-for-tags`}
              key="new-template-tags-select"
              mode="multiple"
              filterOption={false}
              loading={
                creatingData === "tag" ||
                (tags.length === 0 && fetchingData === "tags")
              }
              style={{ width: "100%" }}
              onFocus={() => {
                if (tags.length === 0 && fetchingData === null) {
                  fetchData("tags");
                }

                setSearchBy("");
              }}
              onSearch={(value: string) => {
                setSearchBy(value);
              }}
              onSelect={value => {
                setSelectedTagNames([...selectedTagNames, value]);

                setSearchBy("");
              }}
              onDeselect={value => {
                setSelectedTagNames(
                  selectedTagNames.filter(tagName => tagName !== value),
                );
              }}
              value={(selectedTagNames || []).filter(tagName => tagName)}
              dropdownRender={menu => {
                const menuItems = menu.props.options;
                if (menuItems.length === 0) {
                  return (
                    <div
                      className="add-tag-dropdown-container"
                      onClick={() => {
                        if (searchBy.trim() === "") {
                          Modal.warning({
                            title:
                              "The tag name is not valid. Please enter valid tag name.",
                          });

                          return;
                        }

                        Modal.confirm({
                          title: "Do you want to add new tag?",
                          content: (
                            <span className="add-template-tag-container-span">
                              Are you sure you want to add new tag,
                              <span>{searchBy}</span>
                              for this template?
                            </span>
                          ),
                          okText: "Add",
                          onOk: () => {
                            const tagObject: ITemplateTag = {
                              name: searchBy,
                              createdAt: new Date().getTime(),
                              createdBy: user.email,
                            };

                            setSelectedTagNames([
                              ...selectedTagNames,
                              tagObject.name,
                            ]);
                            createTag(tagObject);
                            setSearchBy("");
                          },
                        });
                      }}
                    >
                      <PlusOutlined /> Add Tag
                    </div>
                  );
                }

                return menu;
              }}
            >
              {filteredTags.map((tag: ITemplateTag) => (
                <Select.Option value={tag.name} key={`option-key-${tag.name}`}>
                  {tag.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      Assign Tag
    </Form>
  );

  const handleClose = () => {
    setNewStamp(getInitialNewStamp(user));

    removeStampToUpdate();
    closeDrawer();
    setSelectedBrandName("");
    setSelectedBrandNames([]);
    setSelectedAccountNames([]);
    setSelectedTagNames([]);
    setSelectedState("");
  };
  return (
    <>
      <FormDrawer
        mode={mode}
        drawerWidth={720}
        dataName={"Stamp"}
        processingData={creatingData === "stamp" || updatingData === "stamp"}
        showDrawer={showNewStampDrawer}
        validForm={validStampName}
        drawerForm={drawerForm}
        handleClose={handleClose}
        handleSubmit={() => {
          if (fetchingData) {
            message.warning(
              "The stamp cannot be sumitted due to some operations are in progress!",
            );

            return;
          }

          let stamp: INewStamp | IStamp;
          if (!stampToUpdate) {
            stamp = {
              ...newStamp,
              oem: selectedBrandName,
              oems: selectedBrandNames,
              state: selectedState,
              stores: selectedAccountNames,
              tags: selectedTagNames,
              createdAt: Date.now(),
              updatedAt: Date.now(),
            } as INewStamp;
          } else {
            stamp = {
              ...newStamp,
              oem: selectedBrandName,
              oems: selectedBrandNames,
              state: selectedState,
              stores: selectedAccountNames,
              tags: selectedTagNames,
              updatedAt: Date.now(),
            } as IStamp;

            // id must exist in this case
            if (!(stamp as IStamp).id) {
              message.error("The current stamp data is invalid.");

              return;
            }
          }

          if (
            stamp.name.trim() === "" ||
            selectedBrandNames.length <= 0 ||
            stamp.width <= 0 ||
            stamp.height <= 0
          ) {
            message.warning(
              "The stamp cannot be sumitted due to missing fields.",
            );

            return;
          }

          if (!stampToUpdate) {
            createStamp(stamp as INewStamp);
          } else {
            updateStamp(stamp as IStamp);
          }

          setSelectedBrandName("");
          setSelectedBrandNames([]);
          setSelectedAccountNames([]);
          setSelectedTagNames([]);
          setSelectedState("");
        }}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { auth, designStudio, oemManagement, dealerManagement } = state;
  const { user } = auth;

  const { oemRecords } = oemManagement;
  const { dealerRecords } = dealerManagement;

  const {
    fetchingData,
    creatingData,
    tags,
    paginationKey,
    stampToUpdate,
    updatingData,
    stamps,
  } = designStudio as IDesignStudioState;

  return {
    user,
    fetchingData,
    creatingData,
    tags,
    paginationKey,
    stampToUpdate,
    updatingData,
    stamps,
    oemRecords,
    dealerRecords,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchData: (dataType: FetchingDataStatus, query = "") => {
      dispatch(actions.designStudio.fetchData(dataType, query));
    },
    createTag: (tag: ITemplateTag) => {
      dispatch(actions.designStudio.createTag(tag));
    },
    createStamp: (stamp: INewStamp) => {
      dispatch(actions.designStudio.createStamp(stamp));
    },
    removeStampToUpdate: () => {
      dispatch(actions.designStudio.removeStampToUpdate());
    },
    updateStamp: (stamp: IStamp) => {
      dispatch(actions.designStudio.updateStamp(stamp));
    },
    getOems: (paginationToken?: string) => {
      dispatch(actions.oemManagement.getOems(paginationToken));
    },
    getDealers: (paginationToken?: string) => {
      dispatch(actions.dealerManagement.getDealers(paginationToken));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewStampDrawer);
