import {
  FilterOutlined,
  PlusOutlined,
  MinusOutlined,
  ShrinkOutlined,
} from "@ant-design/icons";
import { Button, Tabs } from "antd";
import { ReactNode, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import * as AssetBuilderActions from "../../redux/assetBuilder/assetBuilder.slice";

import { IContentTab } from "shared/types/tabContainer";
import "./TabContainer.scss";

interface ITabContainer {
  displaySearchView?: {
    displayNewOffer: boolean;
    displaySearchInput: boolean;
    displayPlusButton: boolean;
    customExtraButton?: JSX.Element;
  };
  displayFilterSection?: boolean;
  displayStatusSection?: boolean;
  displayStatusSelect?: ReactNode;
  searchQuery?: string | null;

  filterTab?: ReactNode;
  selectedTab?: string;
  contentTabs: IContentTab[];
  onChange?: (tab: string) => void;
  onSearchInputChange?: (input: string) => void;
  setBlankOfferData: () => void;
  searchBy?: string;
  addButton?: ReactNode | string;
  updateSelectedTabs?: (key: string) => void;
}

/**
 * @param displayFilterSection default true.
 * @param displaySearchView default true.
 */
const TabContainer: React.FC<ITabContainer> = ({
  displayFilterSection = true,
  displayStatusSection = false,
  displayStatusSelect,
  displaySearchView = {
    displayNewOffer: true,
    displaySearchInput: true,
    displayPlusButton: true,
  },
  searchQuery,
  filterTab,
  selectedTab,
  contentTabs,
  onChange,
  setBlankOfferData,
  onSearchInputChange,
  searchBy,
  addButton,
  updateSelectedTabs,
}) => {
  const [toggleFilter, setToggleFilter] = useState<boolean>(true); // true indicates that opening the filter panel, false indicates that shrinking the filter panel.

  const filterSectionProps = useSpring({
    width: toggleFilter ? "25em" : "4em",
    config: {
      duration: 200,
    },
  });

  const [toggleSearchInput, setToggleSearchInput] = useState<boolean>(false); // same filter toggle rule applies to this.

  const searchInputRef = useRef<HTMLSpanElement>(null);

  const searchPlusButtonRef = useRef<HTMLSpanElement>(null);

  const toggleSearchInputHandler = (event: any) => {
    if (!searchInputRef || !searchInputRef.current) {
      return;
    }

    if ((searchInputRef as any).current.contains(event.target)) {
      // if already opened, we need to return here.
      if (
        toggleSearchInput ||
        (searchPlusButtonRef as any).current.contains(event.target)
      ) {
        return;
      }

      return setToggleSearchInput(true);
    }

    if (toggleSearchInput) {
      return;
    }

    // outside
    setToggleSearchInput(false);
  };

  const [searchInputText, setSearchInputText] = useState<string>(
    searchQuery || "",
  );
  const [inputTimer, setInputTimer] = useState<NodeJS.Timeout | undefined>(
    undefined,
  );

  useEffect(() => {
    selectedTab && setActiveTabKey(selectedTab.toLowerCase());
  }, [selectedTab]);

  const { pathname } = useLocation();

  useEffect(() => {
    if (inputTimer) {
      clearTimeout(inputTimer);
    }

    const tmpTimer = setTimeout(() => {
      if (onSearchInputChange) {
        if (pathname.includes("/select")) {
          onSearchInputChange(searchBy || "");
        } else {
          onSearchInputChange(searchInputText);
        }
      }
    }, 1000);

    if (setInputTimer) {
      setInputTimer(tmpTimer);
    }

    // eslint-disable-next-line
  }, [searchInputText, setInputTimer, onSearchInputChange]);

  // mousedown event handler for search text field to expand/shrink
  useEffect(() => {
    document.addEventListener("mousedown", toggleSearchInputHandler);

    return () => {
      document.removeEventListener("mousedown", toggleSearchInputHandler);
    };

    // eslint-disable-next-line
  }, []);
  // TODO: remove activeTabKey state after adding deep link route on AssetBuilder, Legal Lingo & Admin
  const [activeTabKey, setActiveTabKey] = useState<string | undefined>(
    selectedTab
      ? selectedTab.toLowerCase()
      : contentTabs[0]?.title.toLowerCase(),
  );

  const displayOrderDetails = pathname.endsWith("/select");
  return (
    <div className="tab-container" data-cy={`${activeTabKey}-tab-container`}>
      {/* filter panels */}
      {displayFilterSection && (
        <animated.div
          className="filter-section"
          style={{
            width: filterSectionProps.width,
            flex: "initial",
          }}
        >
          <Tabs
            tabBarExtraContent={{
              right: (
                <>
                  {toggleFilter && (
                    <ShrinkOutlined
                      className="shrink-tab-btn"
                      onClick={() => setToggleFilter(false)}
                    />
                  )}
                </>
              ),
            }}
            className="filter-section-tabs"
            animated={false}
            activeKey={toggleFilter ? "filter-tab" : "none"}
            onTabClick={(key: string) => {
              // when the panel is collapsed, ONLY "filter-tab" icon will be visible.
              // so the fact that key === 'shrink-filter', it means that the panel was expanded and user just clicked on shrink button.
              // so the negate of this logic would be if key is not equal to "shrink-tab", user just clicked "filter-tab" to expand the filter section panel.
              const open = key !== "shrink-tab";

              setToggleFilter(open);
            }}
          >
            <Tabs.TabPane
              key="filter-tab"
              tab={
                <>
                  <FilterOutlined />
                  {displayOrderDetails && (
                    <span className="order-details-tab">Order Details</span>
                  )}
                </>
              }
            >
              {filterTab}
            </Tabs.TabPane>
          </Tabs>
        </animated.div>
      )}

      {/* offer list panel */}
      <div className="tab-container-content-section">
        <Tabs
          tabBarExtraContent={{
            right: (
              <>
                {addButton}
                {displayStatusSection ? (
                  displayStatusSelect
                ) : (
                  <SearchSection
                    displaySearchView={displaySearchView}
                    setBlankOfferData={setBlankOfferData}
                    searchInputRef={searchInputRef}
                    setSearchInputText={setSearchInputText}
                    searchInputText={searchInputText}
                    searchPlusButtonRef={searchPlusButtonRef}
                  />
                )}
              </>
            ),
          }}
          className="tab-container-content-tabs"
          activeKey={activeTabKey}
          onChange={activeKey => {
            if (activeKey === "tab-container-search-view") {
              return;
            }
            onChange && onChange(activeKey);
            setActiveTabKey(activeKey);
          }}
        >
          {contentTabs.map(tabObject => {
            const { title, component, hidden, tabButton } = tabObject;
            if (hidden) return;

            // NOTE: in order for the antd Tab to switch between each tab, it needs unique
            // key attribute to its Tabs.TabPane. For this key, the title will be lower cased and used as its key.

            return (
              <Tabs.TabPane
                key={title.toLowerCase()}
                tab={
                  tabButton ? (
                    tabButton
                  ) : !title.includes("Default") &&
                    pathname.includes("/select") ? (
                    <span>
                      {title}
                      <MinusOutlined
                        onClick={() => {
                          updateSelectedTabs && updateSelectedTabs(title);
                        }}
                        style={{ paddingLeft: "5px" }}
                      />
                    </span>
                  ) : (
                    title
                  )
                }
              >
                {component}
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};

interface SearchSectionProps {
  displaySearchView: ITabContainer["displaySearchView"];
  setBlankOfferData: () => void;
  searchInputRef: React.RefObject<HTMLSpanElement>;
  setSearchInputText: (value: string) => void;
  searchInputText: string;
  searchPlusButtonRef: React.RefObject<HTMLSpanElement>;
}

function SearchSection({
  displaySearchView,
  searchInputRef,
  setSearchInputText,
  searchInputText,
  searchPlusButtonRef,
}: SearchSectionProps) {
  const { pathname } = useLocation();
  const disableSearchPaths = [
    "/select",
    "/select-v2",
    "/review",
    "/asset-launcher",
    "/build",
  ];
  const displaySearch = !disableSearchPaths.some(path =>
    pathname.endsWith(path),
  );

  return (
    <div className="tab-container-search-button-container">
      {displaySearchView?.customExtraButton}

      {displaySearch && (
        <span ref={searchInputRef} className="search-container">
          {displaySearchView!.displaySearchInput && (
            <div>
              <input
                data-cy="search-input"
                onKeyDown={event => {
                  if (!event.key.includes("Arrow")) {
                    return;
                  }
                  event.stopPropagation();
                }}
                type="text"
                style={{
                  outline: "none",
                  backgroundColor: "transparent",
                  color: "#676b6c",
                  width: "210px",
                  border: "1px solid #d9d9d9",
                  borderRadius: "2px",
                  paddingLeft: "10px",
                }}
                placeholder="Search"
                onChange={event => {
                  if (!event || !event.target) {
                    return;
                  }

                  const { value } = event.target || { value: "" };

                  // After this function call, the logic will be handled within useEffect above.
                  setSearchInputText(value);
                }}
                value={searchInputText}
              />
            </div>
          )}

          <span ref={searchPlusButtonRef} className="search-plus-button">
            {displaySearchView!.displayPlusButton && (
              <Button
                onClick={event => {
                  event.stopPropagation();
                }}
                icon={<PlusOutlined />}
              />
            )}
          </span>
        </span>
      )}
    </div>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setBlankOfferData: () => {
      dispatch(AssetBuilderActions.setBlankOfferData());
    },
  };
};

export default connect(null, mapDispatchToProps)(TabContainer);
