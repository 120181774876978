import {
  Typography,
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  InputNumber,
  Select,
} from "antd";
import styles from "./LineItemForm.module.scss";
import { numberInputFormatter } from "screens/campaignManagement/googlePerformanceMax/pmaxCampaignsTable/newCampaignDrawer/newCampaignForm/helpers";
import {
  requiredRule,
  budgetRule,
  dealListOptions,
  locationTargetOptions,
  lineItemTypeOptions,
  ROUTES,
  lineItemFormId,
} from "../constants";
import { useLineItemDrawerContext } from "./LineItemFormProvider";
import RadiusForm from "./RadiusForm";
import {
  getEndDateRules,
  transformFormToLineItem,
  transformLineItemToForm,
} from "../helpers";
import { useBeeswaxCampaignsContext } from "../BeeswaxCampaignsProvider";
import { useLineItemActions } from "./hooks/useLineItemActions";
import FileUpload from "./FileUpload";
import LineItemBudget from "../LineItemBudget";
import { BeeswaxCampaignURLParams, LineItemFormType } from "../types";
import { useNavigate, useParams } from "react-router-dom";

const { Text } = Typography;

const LineItemForm = () => {
  const navigate = useNavigate();
  const { campaignId } = useParams<BeeswaxCampaignURLParams>();
  const { form } = useLineItemDrawerContext();
  const { getLineItem, createLineItem, editLineItem } = useLineItemActions();
  const {
    resetSelectedLineItem,
    lineItemId,
    form: campaignFormInstance,
  } = useBeeswaxCampaignsContext();
  const campaignForm = campaignFormInstance.getFieldsValue();

  const onSubmit = async (values: LineItemFormType) => {
    const draftLineItem = await transformFormToLineItem(values);

    lineItemId
      ? editLineItem(lineItemId, draftLineItem)
      : createLineItem(draftLineItem);

    resetSelectedLineItem();
    navigate(campaignId ? ROUTES.update(campaignId) : ROUTES.create);
  };

  const LineItemName = () => (
    <Form.Item
      label={<Text>Line Item Name</Text>}
      name="name"
      rules={[requiredRule]}
    >
      <Input />
    </Form.Item>
  );

  const StartDate = () => (
    <Form.Item label={<Text>Start Date</Text>} name="startDate" required>
      <DatePicker format="MM-DD-YYYY" className={styles.input} />
    </Form.Item>
  );

  const EndDate = () => (
    <Form.Item
      label={<Text>End Date</Text>}
      name="endDate"
      rules={getEndDateRules("startDate")}
      dependencies={["startDate"]}
      required
    >
      <DatePicker format="MM-DD-YYYY" className={styles.input} />
    </Form.Item>
  );

  const CPMBid = () => (
    <Form.Item
      label={<Text>CPM Bid</Text>}
      name="cpmBid"
      rules={[requiredRule, budgetRule]}
    >
      <InputNumber
        formatter={numberInputFormatter}
        className={styles.input}
        precision={2}
      />
    </Form.Item>
  );

  const DealList = () => (
    <Form.Item
      label={<Text>Deal List</Text>}
      required
      rules={[requiredRule]}
      name="dealListId"
    >
      <Select options={dealListOptions} />
    </Form.Item>
  );

  const LocationTarget = () => (
    <Form.Item
      name={"locationTarget"}
      label={<Text>Location Target</Text>}
      required
      rules={[requiredRule]}
    >
      <Select options={locationTargetOptions} />
    </Form.Item>
  );

  const LineItemType = () => (
    <Form.Item
      label={<Text>Line Item Type</Text>}
      required
      rules={[requiredRule]}
      name="lineItemType"
    >
      <Select options={lineItemTypeOptions} />
    </Form.Item>
  );

  const FixedValue = ({ title, value }: { title: string; value: string }) => (
    <div className={styles.fixedValue}>
      <Text className={styles.fixedValueHeader}>{title}</Text>
      <Text>{value}</Text>
    </div>
  );

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={transformLineItemToForm(
        getLineItem(lineItemId),
        campaignForm,
      )}
      onFinish={onSubmit}
      id={lineItemFormId}
    >
      <LineItemName />
      <Row gutter={16}>
        <Col span={12}>
          <StartDate />
        </Col>
        <Col span={12}>
          <EndDate />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <LineItemBudget />
        </Col>
        <Col span={12}>
          <CPMBid />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <DealList />
        </Col>
        <Col span={12}>
          <LocationTarget />
        </Col>
      </Row>
      <RadiusForm />
      <Row gutter={16}>
        <Col span={24}>
          <LineItemType />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label={<Text>Creatives</Text>} name={"creatives"}>
            <FileUpload />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <FixedValue title="Bidding Strategy" value="Flat CPM" />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <FixedValue title="Pacing" value="Lifetime" />
        </Col>
      </Row>
    </Form>
  );
};

export default LineItemForm;
