import { message, Modal } from "antd";

import API from "services";
import { useFetchStampsByOEM } from "shared/hooks/useFetchStampsByOEM";

import {
  AlignLayersActionType,
  isStamp,
  isStampArray,
  IStamp,
  IStampExceptionType,
  isTemplate,
  ITemplate,
  TabMenu,
} from "shared/types/designStudio";
import { ILegalLingoReduxState } from "shared/types/legalLingo";
import useCanvasHistory, {
  TCanvasHistory,
} from "./editor.hooks/useCanvasHistory";
import { TLayerAction } from "./editor.hooks/useLayers";
import { useEditorResources } from "./editor.hooks/useResource";
import { TCanvasAction } from "./editor/canvasContainer/Canvas";
import { TCopyAction } from "./editor/canvasContainer/canvas.hooks/useCopyPasteObject";
import { TImageInsertData } from "./editor/canvasContainer/canvas.hooks/useImageInsertData";
import { TObjectUpdateAction } from "./editor/propertySectionV2/propertyRow/ManageText";
import StampChangeConfirmFooter from "./editor/StampChangeConfirmFooter";

import * as utils from "./Editor.utils";
import { useRouteQuery } from "shared/hooks/useRouteQuery";
import { isEmpty } from "lodash";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
  useContext,
} from "react";
import { User } from "redux/auth/auth.slice";
import { flushSync } from "react-dom";
import { fabric } from "fabric";

export type TResource = IStamp | ITemplate;

export type EditorContext = EditorContextProps & EditorContextHandlers;

type EditorContextProps = {
  resource?: TResource;
  canvasJson?: any;
  loading: boolean;
  isFetchingStampsForInsert: boolean;
  stampsForInsert?: IStamp[];
  imageInsertData?: TImageInsertData;
  copyAction?: TCopyAction;
  canvasHistory: TCanvasHistory;
  updatedCanvasJson?: any; // This should the the canvas json that is up to date.
  layerAction?: TLayerAction;
  showStampListModalInToolbar: boolean;
  canvasAction?: TCanvasAction;
  align: AlignLayersActionType;
  showGrid: boolean;
  selectedObject?: fabric.Object;
  objectUpdateAction?: TObjectUpdateAction;
  loadingPrimaryMessage: boolean;
  messagingForStamp?: ILegalLingoReduxState["messagingForStamp"];
  user?: User;
  selectedStampException: {
    type: IStampExceptionType;
    value: string;
  };
  shouldBlockFromNavigateAway: boolean;
};

type EditorContextHandlers = {
  setResource: (resource: TResource) => void;
  fetchStampsByOEM: (options?: { force: boolean }) => void;
  setImageInsertData: (imageInsertData: TImageInsertData) => void;
  resetImageInsertData: () => void;
  fetchVariables: () => void;
  setCopyAction: (copyAction: TCopyAction) => void;
  onCanvasJsonUpdate: (json: any, replace?: boolean) => void;
  undo: () => void;
  redo: () => void;
  setLayerAction: (layerAction: TLayerAction) => void;
  toggleStampListModalInToolbar: (show: boolean) => void;
  setCanvasAction: (canvasAction: TCanvasAction) => void;
  setAlign: (align: AlignLayersActionType) => void;
  toggleGrid: (show: boolean) => void;
  setObjectUpdateAction: (action: TObjectUpdateAction) => void;
  fetchPrimaryMessage: (stamp: IStamp) => void;
  setStampByOfferType: (offerType: string) => void;
  setUser: (user: User) => void;
  setStampException: (type: IStampExceptionType, value: string) => void;
  resetCanvas: (url?: string) => void;
  updateStamp: (stamp: IStamp, shouldUpdateResource?: boolean) => void;
  resetAlign: () => void;
  onSaveComplete: () => void;
};

export const context = createContext<EditorContext | null>(null);

export type TTargetParams = {
  targetTab?: TabMenu;
  targetId?: string;
};

const Provider = ({
  targetTab,
  targetId,
  children,
}: PropsWithChildren<TTargetParams>) => {
  const [showStampListModalInToolbar, toggleStampListModalInToolbar] =
    useState<boolean>(false);
  const [canvasJson, setCanvasJson] = useState<any>();
  const [resource, setResource] = useState<TResource>();
  const [loading, setLoading] = useState<boolean>(false);
  const selectedOfferType = useRouteQuery("offerType");
  const {
    refetch,
    data: stampsForInsert,
    isFetching: isFetchingStampsForInsert,
  } = useFetchStampsByOEM(isTemplate(resource) ? resource.oems : [], {
    enabled: false,
  });
  const [shouldBlockFromNavigateAway, setShouldBlockFromNavigateAway] =
    useState<boolean>(false);

  const [copyAction, setCopyAction] = useState<TCopyAction>();

  const [canvasHistory, setCanvasHistory] = useState<TCanvasHistory>({
    history: undefined,
    historyIndex: -1,
    historyStatus: "idle",
  });

  const [stamps, setStamps] = useState<IStamp[]>();
  const [user, setUser] = useState<User>();

  const { isLoading: isLoadingResources } = useEditorResources({
    type: targetTab,
    id: targetId,
    selectedOfferType: selectedOfferType || undefined,
    onSuccess: data => {
      if (isStampArray(data.resource)) {
        setStamps(data.resource);

        const selectedStamp = data.resource.find(
          stmp => stmp.offerType === selectedOfferType,
        );
        const stamp = selectedStamp || data.resource[0];
        setResource(stamp);
      } else {
        setResource(data.resource);
      }

      const isValidJson = !!data?.json && !isEmpty(data?.json);

      if (isValidJson) {
        setCanvasJson(data.json);
        setCanvasHistory(prev => {
          return {
            ...prev,
            history: (prev.history || []).concat([data.json]),
          };
        });
      }

      setShouldBlockFromNavigateAway(false);
      setLoading(false);
    },
    onError: message.error,
  });

  // NOTE: This state will be used in Canvas.tsx
  //       This state is usually set in Toolbar.tsx. And whenever different state is set for imageInsertData,
  //       take a look at the Canvas.tsx where imageInsertData is being used.
  const [imageInsertData, setImageInsertData] = useState<TImageInsertData>();
  const [messagingForStamp, setMessagingForStamp] =
    useState<ILegalLingoReduxState["messagingForStamp"]>();

  const [updatedCanvasJson, setUpdatedCanvasJson] = useState<any>();
  useEffect(() => {
    if (!updatedCanvasJson) return;

    // Here, we need to check the historyStatus. If previsouly undoing, and then json was updated,
    //  we need to check if hitoryIndex is pointing at the last element.
    // If not, then we need to wipe out the future history elements and then re-write the histroy from the current updatedCanvasJson.

    // NOTE: the historyIndex will be adjusted in the useCanvasHistory.tsx
    setCanvasHistory(prev => {
      const { history, historyStatus, historyIndex } = prev;
      const shouldReWriteHistory =
        historyStatus === "undoing" &&
        history &&
        historyIndex < history.length - 1;

      if (shouldReWriteHistory) {
        const preHistory = history!.slice(0, historyIndex + 1); // cut the history array upto historyIndex
        const newHistory = [...preHistory, updatedCanvasJson];

        return {
          ...prev,
          history: newHistory,
          historyStatus: "idle",
        };
      }

      return { ...prev };
    });
  }, [updatedCanvasJson, canvasHistory.historyStatus]);

  useCanvasHistory({
    canvasHistory,
    setShouldBlockFromNavigateAway: block => {
      setShouldBlockFromNavigateAway(block);
    },
    onComplete: updatedCanvasHistory => {
      setCanvasHistory(updatedCanvasHistory);

      const { history, historyIndex } = updatedCanvasHistory;
      const currentCanvasJsonInHistory = history?.[historyIndex];

      switch (updatedCanvasHistory.historyStatus) {
        case "undoing":
        case "redoing":
          setLoading(true);
          setCanvasJson(null); // we first have to reset canvas json rendered in the Canvas.tsx

          // NOTE: We need to wait little because consecutive secCanvasJson calls were conflicting...
          setTimeout(() => {
            setCanvasJson(currentCanvasJsonInHistory);
            setLoading(false);

            setCanvasHistory(prev =>
              prev
                ? {
                    ...prev,
                    historyStatus: "idle",
                  }
                : prev,
            );
          }, 500);

          break;
        default:
          // Clear font cache before updating canvasJson to avoid cursor misplacement
          // This should only be required when custom fonts are used, but this is a safe guard
          fabric.util.clearFabricFontCache();
          setCanvasJson(currentCanvasJsonInHistory);
          break;
      }
    },
  });

  // This layer action will be fired from a ILayerObject.
  // Once an action fired, this will modify object in the canvas and layer items will be reset.
  const [layerAction, setLayerAction] = useState<TLayerAction>();
  const [canvasAction, setCanvasAction] = useState<TCanvasAction>();

  const [align, setAlign] = useState<AlignLayersActionType>(null);
  const [showGrid, toggleGrid] = useState<boolean>(false);
  const [selectedObject, setSelectedObject] = useState<fabric.Object>();
  const [objectUpdateAction, setObjectUpdateAction] =
    useState<TObjectUpdateAction>();

  const [loadingPrimaryMessage, setLoadingPrimaryMessage] =
    useState<boolean>(false);

  const [showStampChangeConfirmModal, toggleStampChangeConfirmModal] =
    useState<boolean>(false);

  const [switchingStampOfferType, setSwitchingStampOfferType] =
    useState<string>();
  const [selectedStampException, setSelectedStampException] = useState<{
    type: IStampExceptionType;
    value: string;
  }>({
    type: "state",
    value: "default",
  });

  const resetCanvas = useCallback(url => {
    if (!url) {
      setCanvasJson(undefined);
      return;
    }
    setLoading(true);
    fetch(`${url}?timestamp=${Date.now()}`)
      .then(response => response.json())
      .then(json => {
        setCanvasHistory(prev => {
          return {
            ...prev,
            history: (prev.history || []).concat([json]),
          };
        });
        setCanvasJson(json);
      })
      .then(() => {
        setLoading(false);
        setShouldBlockFromNavigateAway(false); // After canvas changed, we should let user to navigate away...
      })
      .catch(() => {
        message.error("There was an error while loading.");
        setLoading(false);
      });
  }, []);

  const switchStampOfferType = useCallback(
    (switchingOfferType?: string) => {
      const stamp = stamps?.find(stmp => stmp.offerType === switchingOfferType);
      if (!stamp) {
        message.error(
          `The stamp for selected offer type (${switchingOfferType}) cannot be found.`,
        );

        return;
      }

      // reset history
      setCanvasHistory({
        history: undefined,
        historyIndex: -1,
        historyStatus: "idle",
      });

      setResource(stamp);
      setSelectedStampException({
        type: "state",
        value: "default",
      });

      resetCanvas(stamp.stampJsonUrl);
    },
    [resetCanvas, stamps],
  );

  const switchStampException = useCallback(
    (type: IStampExceptionType, value: string) => {
      if (!isStamp(resource)) return;

      // reset history
      setCanvasHistory({
        history: undefined,
        historyIndex: -1,
        historyStatus: "idle",
      });

      let jsonUrl = resource.stampJsonUrl;
      const foundException = resource.exceptions?.find(
        exp => exp.type === type && exp.value === value,
      );
      if (foundException) jsonUrl = foundException.stampJsonUrl;

      resetCanvas(jsonUrl);
    },
    [resetCanvas, resource],
  );

  const state: EditorContext = {
    resource,
    setResource: resource => {
      setResource(resource);
    },
    canvasJson,
    loading: loading || isLoadingResources,

    isFetchingStampsForInsert,
    fetchStampsByOEM: async (options?: { force: boolean }) => {
      if (!stampsForInsert || options?.force) refetch();
    },
    stampsForInsert,

    setImageInsertData: (imageInsertData: TImageInsertData) => {
      setImageInsertData(imageInsertData);
    },
    imageInsertData,
    resetImageInsertData: () => {
      setImageInsertData(undefined);
    },

    fetchVariables: () => {
      // fill
    },

    copyAction,
    setCopyAction: (copyAction: TCopyAction) => {
      setCopyAction(copyAction);
    },

    canvasHistory,
    onCanvasJsonUpdate: (json: any, replace?: boolean) => {
      // At the time of writing below, if replace is true, we replace the last history json with this current one.
      if (replace ?? false) {
        setCanvasHistory(prevHistory => {
          const { history } = prevHistory;
          if (!history) {
            return {
              history: [json],
              historyIndex: 0,
              historyStatus: "idle",
            };
          }

          const current = [...history];
          current.pop();

          const updated = [...current, json];
          return {
            ...prevHistory,
            history: updated,
          };
        });
      } else {
        setCanvasHistory(prevHistory => {
          const { history, historyStatus, historyIndex } = prevHistory;
          if (historyStatus === "idle") {
            const shouldReWriteHistory =
              history && historyIndex < history.length - 1;

            // after undo, user modified the canvas again
            // It shoud rewrite history
            if (shouldReWriteHistory) {
              const preHistory = history!.slice(0, historyIndex + 1); // cut the history array upto historyIndex
              const newHistory = [...preHistory, json];
              return {
                ...prevHistory,
                history: newHistory,
                historyIndex: newHistory.length - 1,
              };
            } else {
              // object is added regulary
              return {
                ...prevHistory,
                history: (prevHistory.history || []).concat(json),
                historyIndex: prevHistory.historyIndex + 1,
              };
            }
          }
          return { ...prevHistory };
        });
      }

      // History adding rule is the following.
      // Add whenever canvas modified.
      // If undo, then move pointer (historyIndex) to previous possible postion.
      // If redo, then move pointer (historyIndex) to the next possible position.
      //
      // An exceptional case would be that after undo, user starts to modify the canvas again.
      // In this case, we wipe out the future history starting from the last position of the pointer.
      // So if we let [1,2,3*] be current history, and * indicates the current pointer.
      // After undo twice, the history would look something like [1*,2,3]. And then if user starts to modify,
      //  the history becomes something like [1,4*].
      // Wiping out the history will be done in one of the useEffect
      setUpdatedCanvasJson(json);
    },
    undo: () => {
      setCanvasHistory(prev => {
        if (prev.historyIndex === 0) return prev;

        return {
          ...prev,
          historyStatus: "undoing",
          historyIndex: prev.historyIndex - 1,
        };
      });
    },
    redo: () => {
      setCanvasHistory(prev => {
        const { historyIndex, history } = prev;
        if (!history || historyIndex === history.length - 1) return prev;

        return {
          ...prev,
          historyStatus: "redoing",
          historyIndex: prev.historyIndex + 1,
        };
      });
    },
    updatedCanvasJson,
    layerAction,
    setLayerAction: layerAction => {
      // This is a workaround to opt out of automatic batching for the layerAction state.
      // There are a lot of canvas effects that are triggered by changes in the layerAction state, and batching these causes some updates to be missed.
      flushSync(() => setLayerAction(layerAction));
    },

    showStampListModalInToolbar,
    toggleStampListModalInToolbar: (show: boolean) => {
      toggleStampListModalInToolbar(show);
    },
    canvasAction,
    setCanvasAction: (canvasAction: TCanvasAction) => {
      switch (canvasAction.action.type) {
        case "objectSelected":
          setSelectedObject(canvasAction.target);
          break;
        case "objectDeSelected":
          setSelectedObject(undefined);

          break;
      }
      setCanvasAction(canvasAction);
    },
    align,
    setAlign: align => {
      setAlign(align);
    },
    resetAlign: () => {
      setAlign(null);
    },
    showGrid,
    toggleGrid: show => {
      toggleGrid(show);
    },
    selectedObject,
    objectUpdateAction,
    setObjectUpdateAction,
    loadingPrimaryMessage,
    messagingForStamp,
    fetchPrimaryMessage: async stamp => {
      setLoadingPrimaryMessage(true);

      const { result } = await API.services.legalLingo.getMessageForStamp(
        stamp,
      );
      setMessagingForStamp({
        messagings: result || [],
        loading: false,
      });

      setLoadingPrimaryMessage(false);
    },
    user,
    setUser: user => {
      setUser(user);
    },

    setStampByOfferType: offerType => {
      if (utils.didStampChange(canvasHistory)) {
        setSwitchingStampOfferType(offerType); // This stamp offer type will be used after the user action (discard, cancel and save).
        toggleStampChangeConfirmModal(true); // The 3 actions (discard, cancel and save) will be done in the modal.
      } else {
        switchStampOfferType(offerType);
      }
    },

    selectedStampException,
    setStampException: (type: IStampExceptionType, value: string) => {
      setSelectedStampException({
        type: "state",
        value,
      });
      switchStampException(type, value);
    },
    resetCanvas,
    updateStamp: (stamp: IStamp, shouldUpdateResource?: boolean) => {
      if (shouldUpdateResource) setResource(stamp);

      setStamps(prevStamps =>
        prevStamps?.map(stmp => {
          if (stmp.offerType === stamp.offerType) {
            return stamp;
          }

          return stmp;
        }),
      );
    },
    onSaveComplete: () => {
      setShouldBlockFromNavigateAway(false);
    },
    shouldBlockFromNavigateAway,
  };

  return (
    <context.Provider value={state}>
      <>
        {children}
        <Modal
          className="canvas-stamp-save-modal"
          visible={showStampChangeConfirmModal}
          title="Do you want to save changes?"
          footer={
            <StampChangeConfirmFooter
              resource={resource}
              canvasHistory={canvasHistory}
              user={user}
              switchingStampOfferType={switchingStampOfferType}
              selectedStampException={selectedStampException}
              afterDiscardComplete={() => {
                if (switchingStampOfferType) {
                  toggleStampChangeConfirmModal(false);

                  switchStampOfferType(switchingStampOfferType);
                } else {
                  // NOTE: The "switchingStampOfferType" must be set before this action.
                  //       If this offer type was not set, it mean there was something wrong in the func after the select onSelect method.
                  message.error(
                    "There was unknown error. Please refresh the page and try to switch offer type.",
                  );
                }
              }}
              onCancel={() => {
                toggleStampChangeConfirmModal(false);
              }}
              afterSaveComplete={stamp => {
                const updatedStamps = stamps?.map(stmp => {
                  if (stmp.offerType === stamp?.offerType) {
                    return stamp;
                  }

                  return stmp;
                });

                setStamps(updatedStamps);
                switchStampOfferType(switchingStampOfferType);
                toggleStampChangeConfirmModal(false);
              }}
            />
          }
        >
          <span>
            Do you want to save current changes before switching to other offer
            type?
          </span>
        </Modal>
      </>
    </context.Provider>
  );
};

export default Provider;

export const useEditorContext = () => {
  const editorContext = useContext(context);

  if (!editorContext) {
    throw new Error("Context must be used within a ContextProvider");
  }

  return editorContext;
};
