import { observer } from "mobx-react-lite";
import { TextField } from "@mui/material";
import { StackContainer } from "../containers/StackContainer";
import { TextLayerElement } from "screens/designStudio/designStudioV2/types";
import { ColorPickerPopover } from "screens/designStudio/designStudioV2/editorDrawer/editorDrawerContent/polotnoEditor/editorTopbar/layerEffects/ColorPickerPopover";
import { ColorResult } from "react-color";
import LineWeightRoundedIcon from "@mui/icons-material/LineWeightRounded";

type TextStrokeControlProps = {
  elements: TextLayerElement[];
};

const TextStrokeControl = observer(({ elements }: TextStrokeControlProps) => {
  const [layer] = elements;

  const handleStrokeColorChange = (color: ColorResult) => {
    elements.forEach(element => {
      element.set({ stroke: color.hex });
    });
  };

  const handleStrokeWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const width = parseInt(e.target.value, 10);
    elements.forEach(element => {
      element.set({ strokeWidth: isNaN(width) ? 1 : width });
    });
  };

  return (
    <StackContainer>
      <ColorPickerPopover
        color={layer?.stroke || "#000000"}
        onChange={handleStrokeColorChange}
      />
      <TextField
        type="number"
        size="small"
        label="Weight"
        value={layer?.strokeWidth || 1}
        onChange={handleStrokeWidthChange}
        sx={{ width: 95 }}
        InputProps={{
          startAdornment: (
            <LineWeightRoundedIcon
              fontSize="small"
              sx={{
                color: "action.active",
                mr: 1,
              }}
            />
          ),
        }}
      />
    </StackContainer>
  );
});

export default TextStrokeControl;
