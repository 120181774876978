import { isEqual } from "lodash";
import { useState, useRef } from "react";

/**
 * A hook that will keep the state synced with the initial value.
 * Useful to sync the state with props without having to use useEffect.
 * More info: https://react.dev/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes
 * @param syncValue The initial value of the state.
 */
export const useSyncedState = <T>(
  syncValue: T,
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const originalValue = useRef(syncValue);
  const [value, setValue] = useState(syncValue);

  if (!isEqual(syncValue, originalValue.current)) {
    originalValue.current = syncValue;
    setValue(syncValue);
  }
  return [value, setValue];
};
