import styles from "./VideoStitching.module.scss";
import { AudioSlider } from "./AudioSlider";
import { MediaSubtype, TAudioFile } from "shared/types/assetExporter";
import { AudioPreviewCollapseSection } from "../manageVariables/panelVariables/UploadAudio/AudioPreviewCollapseSection";
import classNames from "classnames";

interface Props {
  duration: number;
  active: boolean;
  audioFiles: TAudioFile[];
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AudioFilePreview = ({
  duration,
  active,
  setActive,
  audioFiles,
}: Props) => {
  if (!audioFiles || audioFiles.length === 0) return <></>;

  const tooltipText = (type: string) => {
    return type === MediaSubtype.CAM
      ? "Audio from CAM"
      : "Audio from feed column or URL";
  };

  return (
    <div
      className={classNames(styles.audioVisualizer, {
        [styles.active]: active,
      })}
    >
      <AudioPreviewCollapseSection
        title="Audio Tracks"
        activeKey={active ? ["audioTrack"] : []}
        idKey="audioTrack"
        handleOnChange={() => setActive(!active)}
        isActive={active}
        duration={duration}
      >
        {audioFiles.map(audioFile =>
          !audioFile.src ? null : (
            <AudioSlider
              key={audioFile.id}
              audioFile={audioFile}
              durationMs={duration * 1000}
              tooltipText={tooltipText(audioFile.type)}
            />
          ),
        )}
      </AudioPreviewCollapseSection>
    </div>
  );
};
