import {
  LoadingOutlined,
  RedoOutlined,
  CheckOutlined,
  SyncOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { UploadFileStatus } from "antd/lib/upload/interface";

export const UploadStatusIcon = ({
  status,
}: {
  status: UploadFileStatus | "default" | undefined;
}) => {
  switch (status) {
    case "uploading":
      return <LoadingOutlined />;
    case "error":
      return <RedoOutlined />;
    case "success":
      return <CheckOutlined />;
    case "done":
      return <SyncOutlined />;
    default:
      return <UploadOutlined />;
  }
};
