import { MediaColumn } from "shared/types/assetExporter";
import { useMediaUrlData } from "./useMediaUrlData";

export const useMediaDataForSelectedRow = (
  selectedRow?: any,
  mediaColumns?: MediaColumn[],
) => {
  const urls =
    mediaColumns?.map(column => selectedRow[column.columnName] ?? "") ?? [];
  const mediaUrlData = useMediaUrlData(urls);
  const isLoading = mediaUrlData.some(d => d.isLoading);
  const data = mediaUrlData.map(u => u.data);

  return { data, isLoading };
};
