import { observer } from "mobx-react-lite";
import { Typography, Switch } from "@mui/material";
import useTextElementEffects from "screens/designStudio/designStudioV2/hooks/useTextElementEffects";
import BlurSlider from "../layerEffects/BlurSlider";
import TextStroke from "../layerEffects/TextStroke";
import Background from "../layerEffects/Background";
import Shadow from "../layerEffects/Shadow";
import { EffectsMenu } from "../containers/EffectsMenu";
import { StackContainer } from "screens/designStudio/designStudioV2/editorDrawer/editorDrawerContent/polotnoEditor/editorTopbar/containers/StackContainer";
import { TextLayerElement } from "screens/designStudio/designStudioV2/types";

type FontEffectsControlProps = {
  elements: TextLayerElement[];
};

const FontEffectControls = observer(({ elements }: FontEffectsControlProps) => {
  const [layer] = elements;
  const isBlurEnabled = Boolean(layer?.blurEnabled);
  const isTextStrokeEnabled = Boolean(layer?.strokeWidth);
  const isBackgroundEnabled = Boolean(layer?.backgroundEnabled);
  const isShadowEnabled = Boolean(layer?.shadowEnabled);

  const { toggleBlur, toggleTextStroke, toggleBackground, toggleShadow } =
    useTextElementEffects(elements);

  return (
    <EffectsMenu>
      <StackContainer>
        <Switch checked={!!isBlurEnabled} onChange={toggleBlur} />
        <Typography variant="caption">Blur</Typography>
      </StackContainer>
      {isBlurEnabled && <BlurSlider elements={elements} />}
      <StackContainer>
        <Switch checked={!!isTextStrokeEnabled} onChange={toggleTextStroke} />
        <Typography variant="caption">Text Stroke</Typography>
      </StackContainer>
      {isTextStrokeEnabled && <TextStroke elements={elements} />}
      <StackContainer>
        <Switch checked={!!isBackgroundEnabled} onChange={toggleBackground} />
        <Typography variant="caption">Background</Typography>
      </StackContainer>
      {isBackgroundEnabled && <Background elements={elements} />}
      <StackContainer>
        <Switch checked={!!isShadowEnabled} onChange={toggleShadow} />
        <Typography variant="caption">Shadow</Typography>
      </StackContainer>
      {isShadowEnabled && <Shadow elements={elements} />}
    </EffectsMenu>
  );
});

export default FontEffectControls;
