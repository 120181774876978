import { Checkbox } from "antd";
import { useSelected } from "../../../useSelected";

import styles from "./GridSelect.module.scss";

type Props = {
  resource: WDAsset;
  hover: boolean;
};
export const GridSelect = (props: Props) => {
  const { resource, hover } = props;
  const { toggleSelected, selectedIds } = useSelected();
  const dataVisible = hover || selectedIds.length > 0;

  return (
    <div className={styles.host} data-visible={dataVisible}>
      <Checkbox onClick={() => toggleSelected(resource)} />
    </div>
  );
};
