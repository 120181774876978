import { Input, Form } from "antd";
import { SimpleUpload } from "shared/components/uploadAsset/SimpleUpload";
import styles from "./FormColumn.module.scss";
import { LogoInstructionText } from "../../LogoInstructionText";
import { UrlInput } from "./formColumn/UrlInput";

type Props = Readonly<{
  style?: React.CSSProperties;
  disabled?: boolean;
}>;

const FormColumn = ({ style, disabled }: Props) => {
  return (
    <div style={style} className={styles.formColumn}>
      <Form.Item
        label="Account Name"
        name="name"
        rules={[{ required: true, message: "Please add account name" }]}
      >
        <Input disabled={disabled} />
      </Form.Item>
      <Form.Item
        name="url"
        label="URL"
        rules={[{ type: "url", message: "Invalid URL format" }]}
      >
        <UrlInput disabled={disabled} />
      </Form.Item>
      <Form.Item label="Logos">
        <LogoInstructionText />
        <Form.Item name="logos" valuePropName="logos" trigger="setLogos">
          <SimpleUpload disabled={disabled} />
        </Form.Item>
      </Form.Item>
    </div>
  );
};

export default FormColumn;
