import APIs from "services";
import {
  ErrorStatus,
  MarketingMaterial,
  MarketingMaterialTableItem,
} from "shared/types/marketingMaterials";
import {
  Account,
  DeliveryMethod,
  Language,
  Template,
} from "shared/types/salesEnablement";
import { QUERY_KEYS } from "../utils/constants";
import { useFetchTemplates } from "shared/hooks/designStudio/useFetchTemplates";
import { useIsSalesEnablementAdmin } from "shared/hooks/useIsAdmin";
import { useMemo } from "react";
import { useUser } from "shared/hooks/useUser";
import {
  useGetTemplateErrorStatusFn,
  type TemplateErrorStatus,
} from "screens/designStudio/templates/errorStatus.utils";
import { isTruthy } from "utils/helpers.array";
import { useMarketingMaterialErrorStatus } from "./useMarketingMaterialErrorStatus";
import { useFetchAccounts } from "shared/hooks/salesEnablement/useFetchAccounts";
import { useInfiniteFetchAll } from "shared/hooks/useInfiniteFetchAll";

export const useFetchMarketingMaterials = () => {
  const isAdmin = useIsSalesEnablementAdmin();
  const user = useUser();
  const {
    templates,
    isTemplateLoading,
    isError: isTemplateError,
  } = useFetchTemplates();

  const { data: accounts, isLoading: isLoadingAccounts } = useFetchAccounts();
  const {
    data,
    isLoading: materialsLoading,
    isError,
  } = useInfiniteFetchAll<{ items: MarketingMaterial[] }, MarketingMaterial>({
    queryKey: QUERY_KEYS.marketingMaterials,
    queryFn: ({ paginationKey }) =>
      APIs.services.salesEnablement.getMarketingMaterials(paginationKey),
    getDataFromResponse: response => response.items ?? [],
  });

  const originalMarketingMaterials = useMemo(() => {
    if (!isAdmin) return data;

    return data?.filter(mm => mm.createdBy === user.sub);
  }, [data, isAdmin, user]);

  const { getMarketingMaterialErrorStatus } = useMarketingMaterialErrorStatus();
  const getTemplateErrorStatus = useGetTemplateErrorStatusFn();

  const marketingMaterials = mergeMaterialsAndTemplates(
    originalMarketingMaterials ?? [],
    templates,
    getTemplateErrorStatus,
    getMarketingMaterialErrorStatus,
    accounts ?? [],
  );

  return {
    isLoading: isTemplateLoading || materialsLoading || isLoadingAccounts,
    marketingMaterials,
    isError: isError || isTemplateError,
  };
};

function mergeMaterialsAndTemplates(
  materials: MarketingMaterial[] | undefined,
  templates: Template[] | undefined,
  getTemplateErrorStatus: (
    template: Template,
  ) => TemplateErrorStatus | undefined,
  getMarketingMaterialErrorStatus: (
    template: Template,
    material: MarketingMaterial,
  ) => ErrorStatus | undefined,
  accounts: Account[],
) {
  if (!materials || !templates) return [];
  const templatesMap = templates.reduce<Record<string, Template>>(
    (acc, template) => {
      acc[template.id] = template;
      return acc;
    },
    {},
  );

  return materials
    .filter(
      material => !!material.templateId && !!templatesMap[material.templateId],
    )
    .map(material =>
      mergeTemplateInMarketingMaterial(
        material,
        templatesMap[material.templateId],
        getTemplateErrorStatus,
        getMarketingMaterialErrorStatus,
      ),
    )
    .map(material => ({ ...material, ...getFilterFields(material, accounts) }));
}

function mergeTemplateInMarketingMaterial(
  material: MarketingMaterial,
  template: Template,
  getTemplateErrorStatus: (
    template: Template,
  ) => TemplateErrorStatus | undefined,
  getMarketingMaterialErrorStatus: (
    template: Template,
    material: MarketingMaterial,
  ) => ErrorStatus | undefined,
): MarketingMaterialTableItem {
  const {
    id: templateId,
    name: templateName,
    description: templateDescription,
    createdAt: templateCreatedAt,
    lastUpdatedAt: templateUpdatedAt,
    audience: templateAudience,
    expirationDate: templateExpirationDate,
    tags: templateTags,
    status: templateStatus,
    locations: templateLocations,
    customizable: templateCustomizable,
    materialType: templateMaterialType,
  } = template;

  const templateThumbnail = Object.values(template.files)?.[0]?.thumbnail;
  const templateErrorStatus = getTemplateErrorStatus(template);

  const materialErrorStatus = getMarketingMaterialErrorStatus(
    template,
    material,
  );

  return {
    ...material,
    materialErrorStatus,
    templateId,
    templateName,
    templateDescription,
    templateCreatedAt,
    templateUpdatedAt,
    templateDeliveryMethods: getDeliveryMethods(template),
    templateAudience,
    templateExpirationDate,
    templateTags,
    templateStatus,
    templateLocations,
    templateThumbnail,
    templateErrorStatus,
    templateCustomizable,
    templateMaterialType,
  };
}

export function getDeliveryMethods(template: Template): DeliveryMethod[] {
  return Object.keys(template.files)
    .flatMap(key => {
      const file = template.files[key as Language];
      return file?.deliveryMethods;
    })
    .filter(isTruthy);
}

function getFilterFields(
  material: MarketingMaterialTableItem,
  accounts: Account[],
): {
  account?: string;
  products?: string[];
} {
  return Object.values(material.fields ?? {}).reduce<{
    account?: string;
    products?: string[];
  }>(
    (accum, field) => {
      if (!field || typeof field == "string" || !("type" in field))
        return accum;

      if (field?.type === "account") {
        accum.account = accounts.find(acc => acc.id === field?.value)?.name;
      }

      if (field?.type === "product") {
        accum.products = accum.products?.concat(
          field.productsData.map(data => data.name ?? ""),
        );
      }
      return accum;
    },
    {
      account: undefined,
      products: [],
    },
  );
}

export function getMaterialFromMaterialTableItem(
  material: MarketingMaterialTableItem,
): MarketingMaterial {
  return {
    createdAt: material.createdAt,
    createdBy: material.createdBy,
    updatedAt: material.updatedAt,
    id: material.id,
    name: material.name,
    templateId: material.templateId,
    language: material.language,
    templateThumbnail: material.templateThumbnail,
    locations: material.locations,
    fields: material.fields,
    productTypeOffer: material.productTypeOffer,
  };
}
