import { useState } from "react";
import { Badge, Button } from "antd";
import moment from "moment";
import { TemplateVersion } from "shared/types/designStudio";
import { downloadFile } from "../utils";
import { notification } from "antd";
import SelectedDetailRow from "./SelectedDetailRow";
import style from "./SelectedVersionDetails.module.scss";

type SelectedVersionDetailsProps = {
  selectedVersion: TemplateVersion;
  indexSelected: number;
  sortedTemplatesLength: number;
};

const SelectedVersionDetails = ({
  selectedVersion,
  indexSelected,
  sortedTemplatesLength,
}: SelectedVersionDetailsProps) => {
  const [loading, setLoading] = useState(false);
  const { fileUrl, fileName } = selectedVersion;

  const handleDownloadClick = async () => {
    setLoading(true);
    try {
      await downloadFile(fileUrl, fileName);
      notification.success({
        message: `Downloaded ${fileName}`,
        duration: 3,
        placement: "bottomRight",
      });
    } catch (error) {
      notification.error({
        message: `Download Failed ${fileName}`,
        duration: 3,
        placement: "bottomRight",
      });
    }
    setLoading(false);
  };
  return (
    <div className={style.body}>
      <SelectedDetailRow label="Version:">
        {sortedTemplatesLength - indexSelected}
      </SelectedDetailRow>
      <SelectedDetailRow label="Status:">
        <Badge
          status={selectedVersion.status === "done" ? "success" : "error"}
        />
        {selectedVersion.status === "done" ? "Uploaded" : "Failed"}
      </SelectedDetailRow>
      <SelectedDetailRow label="Uploaded on:">
        {moment(selectedVersion.uploadedAt).format("YYYY/MM/DD hh:mma")}
      </SelectedDetailRow>
      <SelectedDetailRow label="Uploaded by:">
        {selectedVersion.uploadedBy}
      </SelectedDetailRow>
      <SelectedDetailRow label="File:">
        {selectedVersion.fileName}
      </SelectedDetailRow>

      <div className={style.detailsRow}>
        <div className={style.button}>
          <Button
            data-cy="version-history-download-button"
            type="primary"
            onClick={handleDownloadClick}
            disabled={loading}
            style={{ width: "9em" }}
          >
            {loading ? "Downloading..." : "Download File"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SelectedVersionDetails;
