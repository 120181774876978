import { UseMutationOptions, useMutation } from "react-query";
import API from "services";
import {
  beeswaxCampaignsQueryKey,
  createBeeswaxCampaignMutationKey,
} from "../constants";
import { queryClient } from "queryClient";
import { notification } from "antd";
import { IDataTableError } from "shared/types/errors";
import { IApiResponse } from "shared/types/shared";
import { BeeswaxCampaign, BeeswaxCampaignWithoutMetadata } from "../types";

type AllOptions = UseMutationOptions<
  IApiResponse<BeeswaxCampaign, IDataTableError>,
  unknown,
  BeeswaxCampaignWithoutMetadata,
  unknown
>;

export const useCreateBeeswaxCampaign = (
  options?: Pick<AllOptions, "onSuccess">,
) => {
  return useMutation({
    mutationKey: createBeeswaxCampaignMutationKey,
    mutationFn: API.services.beeswaxCampaigns.createCampaign,
    onSettled: () => {
      queryClient.invalidateQueries([beeswaxCampaignsQueryKey]);
    },
    onError: () => {
      notification.error({
        message: "Error",
        description: "Something went wrong creating the campaign.",
      });
    },
    ...options,
  });
};
