import { RightOutlined } from "@ant-design/icons";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import styles from "./AudioCollapseHeader.module.scss";
import { useVideoStitchingContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/VideoStitchingContext";
import { renderPlayPauseButtonGrey } from "screens/assetExporter/feedConfigurationV2/shared/components/VideoPlayerControllers";
import { Button } from "antd";
import { DEFAULT_FPS } from "screens/assetExporter/feedConfigurationV2/shared/constants";
import { formatTime } from "../utils";

type Props = {
  isActive: boolean;
  title: string;
  duration: number;
  onPlayClicked: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

export const AudioPreviewHeader = ({
  isActive,
  title,
  duration,
  onPlayClicked,
}: Props) => {
  const { handlePlayVideo, isPlaying, currentFrame } =
    useVideoStitchingContext();

  const playVideo = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    onPlayClicked(e);
    handlePlayVideo();
  };

  const formattedTime = `${formatTime(
    currentFrame / DEFAULT_FPS,
  )} / ${formatTime(duration)}`;

  return (
    <div className={styles.headerContainer}>
      <RightOutlined
        rotate={isActive ? 90 : 0}
        className={styles.rightArrow}
        width="10px"
        height="10px"
      />
      <VolumeUpIcon className={styles.volumeIcon} />
      <span>{title}</span>
      <Button
        type="text"
        icon={renderPlayPauseButtonGrey({
          playing: isPlaying,
          isBuffering: false,
        })}
        onClick={e => playVideo(e)}
        shape="circle"
        className={styles.playButton}
      />
      <span className={styles.videoTime}>{formattedTime}</span>
    </div>
  );
};
