import { isEmpty } from "lodash";
import { IAd } from "shared/types/adLibrary";
import { AdType } from "../facebookUtils/types";

type IssueType = "error" | "warning";
type IssueValue = "empty" | "invalid";

interface IAdValidationIssue {
  type: IssueType;
  value: IssueValue;
}

export interface IAdValidations {
  adId: string;
  hasErrors: boolean;

  adFormat?: IAdValidationIssue;
  name?: IAdValidationIssue;
  postCopy?: IAdValidationIssue;
  headline?: IAdValidationIssue;
  thumbnail?: IAdValidationIssue;
  ctaButtonText?: IAdValidationIssue;
  headlineDescription?: IAdValidationIssue;
  utm?: IAdValidationIssue;
  primaryText?: IAdValidationIssue;
}

export const adValidationFieldsMapping = {
  adFormat: "Ad Format",
  name: "Ad Name",
  postCopy: "Primary Text",
  headline: "Headline",
  thumbnail: "Media Link URL",
  ctaButtonText: "CTA Button Text",
  headlineDescription: "Description",
  utm: "UTM",
  primaryText: "Primary Text",
};

const dynamicReqFields = ["name"];

const requiredFieldsByAdFormat: Partial<Record<AdType, string[]>> = {
  Carousel: ["name"],
  Still: ["name"],
  Video: ["name"],
  Collection: ["name"],
  InstantExperience: ["name"],
  DPA: dynamicReqFields,
  AIA: dynamicReqFields,
};

const validateEmpty = (
  value?: string,
  type: IssueType = "error",
): IAdValidationIssue | undefined => {
  if (isEmpty(value)) {
    return { type, value: "empty" };
  }
};

const validateAdFormat = (
  adFormat?: string,
): IAdValidationIssue | undefined => {
  if (!adFormat) {
    return { type: "error", value: "empty" };
  }
  if (!Object.keys(AdType).includes(adFormat)) {
    return { type: "error", value: "invalid" };
  }
};

export const getAdValidations = (ad: IAd): IAdValidations => {
  const validateEmptyIfRequired = (field: string, value?: string) => {
    return requiredFieldsByAdFormat[ad.type]?.includes(field)
      ? validateEmpty(value, "error")
      : undefined;
  };

  const adFormatValidation = validateAdFormat(ad.type);
  if (adFormatValidation) {
    return {
      adId: ad.id,
      hasErrors: true,
      adFormat: adFormatValidation,
      // check the other values that are required for all the ad formats
      name: validateEmpty(ad.inputParameters.name, "error"),
      headline: validateEmpty(ad.visuals.headline, "error"),
    };
  }

  // TODO: especial validation for carousel - headline and thumbnails
  const validations: IAdValidations = {
    adId: ad.id,
    hasErrors: false,
    name: validateEmptyIfRequired("name", ad.inputParameters.name),
    postCopy: validateEmptyIfRequired("postCopy", ad.inputParameters.name),
    ctaButtonText: validateEmptyIfRequired(
      "ctaButtonText",
      ad.visuals.ctaButtonText,
    ),
    primaryText: validateEmptyIfRequired("primaryText", ad.visuals.primaryText),
    utm: validateEmptyIfRequired("utm", ad.inputParameters.utm),
    headlineDescription: validateEmptyIfRequired(
      "headlineDescription",
      ad.visuals.headlineDescription,
    ),
    thumbnail: validateEmptyIfRequired("thumbnail", ad.visuals.thumbnail),
    headline: validateEmptyIfRequired("headline", ad.visuals.headline),
  };

  if (
    Object.values(validations).some(
      (value: IAdValidationIssue) => value?.type === "error",
    )
  ) {
    validations.hasErrors = true;
  }

  return validations;
};

export const getValidationForAdId = (
  adId: string,
  validationErrors: IAdValidations[],
) => validationErrors?.find(adValidation => adValidation.adId === adId);

export const hasErrors = (adId: string, validationErrors?: IAdValidations[]) =>
  !!getValidationForAdId(adId, validationErrors ?? [])?.hasErrors;
