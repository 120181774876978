import { PropsWithChildren } from "react";
import styles from "./FilterByInput.module.scss";
import { Form } from "antd";

type FilterByProps = {
  title: string;
};

export const FilterBy = ({
  title,
  children,
}: PropsWithChildren<FilterByProps>) => {
  return (
    <Form.Item
      label={title}
      labelCol={{ span: 24, className: styles.title }}
      colon={false}
      className={styles.filterBy}
      htmlFor={title}
    >
      {children}
    </Form.Item>
  );
};
