import { useMemo, useCallback, useEffect, useState } from "react";
import { createStore } from "./helpers.store";
import { DesignStudioTableTemplate } from "screens/designStudio/context/DesignStudioDataProvider";
import { useDesignStudioEditor } from "screens/designStudio/designStudioLayout/designStudioTable/hooks/useDesignStudioEditor";
import { useCanvasJson } from "../../hooks/useCanvasJson";
import { useQueryClient } from "react-query";
import { getCanvasJsonQueryKey } from "shared/hooks/useFetchCanvasJsonObjects";
import { useDesignStudioEditorItems } from "screens/designStudio/designStudioLayout/designStudioTable/hooks/useDesignStudioEditorItems";
import { useLoadFontsToStores } from "./useLoadFontsToStores";
import { useSaveStore } from "../editorDrawerContent/polotnoEditor/editorTopbar/useSaveStore";

export const useManageStores = () => {
  const { items, activeItem } = useDesignStudioEditorItems();
  const queryClient = useQueryClient();

  const { selectedId, setEditor } = useDesignStudioEditor();
  const canvasJsonQueries = useCanvasJson(items);
  const [storeData, setStoreData] = useState(() => items.map(createStore));

  useLoadFontsToStores(items, storeData, selectedId);

  useEffect(() => {
    canvasJsonQueries.map((query, index) => {
      if (query.isSuccess) {
        const loadableStore = storeData.find(store => {
          return store.assetId === items[index].asset.id;
        });

        if (loadableStore && loadableStore.store.pages.length === 0) {
          loadableStore.store.loadJSON(query.data);
        }
      }
    });
  }, [canvasJsonQueries, items, storeData]);

  const addStore = useCallback(
    (item: DesignStudioTableTemplate) => {
      setStoreData(stores => [...stores, createStore(item)]);
    },
    [setStoreData],
  );

  const activeStore = useMemo(() => {
    const loadableStore = storeData.find(
      store => store.assetId === selectedId,
    )!;

    if (!loadableStore) return null;

    const queryData = queryClient.getQueryData<any>(
      getCanvasJsonQueryKey(
        items.find(item => item.asset.id === selectedId)?.asset.canvasJsonUrl ??
          "",
      ),
    );
    if (!loadableStore.store.pages.length && queryData) {
      loadableStore.store.loadJSON(queryData);
    }

    return loadableStore.store;
  }, [selectedId, storeData, items, queryClient]);

  const removeStore = useCallback(
    (assetId: string) => {
      setStoreData(stores => {
        const newStores = stores.filter(store => store.assetId !== assetId);
        if (newStores.length === 0) {
          setEditor(null);
          return newStores;
        }

        const newActiveId =
          selectedId === assetId ? newStores[0].assetId : selectedId ?? "";

        setEditor({
          selectedId: newActiveId,
          assetIds: newStores.map(s => s.assetId),
        });
        return newStores;
      });
    },
    [selectedId, setEditor, setStoreData],
  );

  const changeActiveStore = useCallback(
    (assetId: string) => {
      setEditor({ selectedId: assetId });
    },
    [setEditor],
  );

  const { save, isAutoSaving, isSaving } = useSaveStore(
    activeStore,
    activeItem,
    true,
    60000,
  );

  return {
    stores: storeData,
    activeStore,
    addStore,
    removeStore,
    changeActiveStore,
    save,
    isSaving,
    isAutoSaving,
  };
};
