import { PrinterOutlined } from "@ant-design/icons";
import { Tooltip, Button, TooltipProps } from "antd";
import { ToolbarButtonValue } from "shared/types/toolbar";

type Props = Pick<ToolbarButtonValue, "disabled" | "onClick" | "extraInfo"> & {
  tooltipProps: Partial<TooltipProps>;
  buttonTextInline(text: string): string | null;
  shape: "circle" | undefined;
};

export const Print = ({
  extraInfo,
  disabled,
  tooltipProps,
  shape,
  onClick,
  buttonTextInline,
}: Props) => {
  return (
    <Tooltip
      title={extraInfo?.tooltipText ?? "Print"}
      overlayStyle={{ whiteSpace: "pre-line", maxWidth: "348px" }}
      placement={extraInfo?.tooltipPlacement ?? "top"}
      arrowPointAtCenter
      {...tooltipProps}
    >
      <Button
        key="print-toolbar-btn"
        disabled={disabled}
        onClick={onClick}
        className="action-btn-toolbar"
        icon={<PrinterOutlined />}
        shape={shape}
      >
        {buttonTextInline(extraInfo?.title ?? "Print")}
      </Button>
    </Tooltip>
  );
};
