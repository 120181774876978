import ToolbarTable, {
  ToolbarButton,
} from "shared/components/toolbarTable/ToolbarTable";
import styles from "./DesignStudioTableHeader.module.scss";
import {
  DesignStudioAssetType,
  useDesignStudioContext,
} from "../../context/DesignStudioDataProvider";
import { Menu } from "antd";
import { useDesignStudioTableActions } from "./hooks/useDesignStudioTableActions";
import {
  useDesignStudioGlobalFilter,
  useDesignStudioSorter,
} from "screens/adLibrary/shared/hooks/dataListHooks";
import { useIsAdmin } from "shared/hooks/useIsAdmin";
import { getEditUrl, isModifierKeyActive, openInNewTab } from "../shared/utils";

type DesignStudioTableHeaderProps = {
  openCreateDrawer: (type: DesignStudioAssetType) => void;
  openFilterDrawer: () => void;
};

export const DesignStudioTableHeader = ({
  openCreateDrawer,
  openFilterDrawer,
}: DesignStudioTableHeaderProps) => {
  const { selectedRows, setIsColumnPickerOpen, layout, setLayout } =
    useDesignStudioContext();
  const { globalFilter, setGlobalFilter } = useDesignStudioGlobalFilter();
  const isAdmin = useIsAdmin();

  const { editParams, showPreview, archive, edit, duplicate } =
    useDesignStudioTableActions();

  const isSingleRowSelected = selectedRows.length === 1;
  const { sortKey, sortOrder, sortItems } = useDesignStudioSorter();

  const handleEdit = (e: React.MouseEvent<HTMLElement>) => {
    if (selectedRows.length !== 1) return;
    if (!isModifierKeyActive(e)) return edit.handler([selectedRows[0]]);

    const url = getEditUrl(selectedRows[0]);
    openInNewTab(url);
  };
  const toolbarContents: ToolbarButton = {
    New: {
      disabled: !isAdmin,
      extraInfo: {
        dropdownMenu: (
          <Menu>
            <Menu.Item
              onClick={() => openCreateDrawer("template")}
              data-cy="new-template-header-button"
            >
              New Template
            </Menu.Item>
            <Menu.Item
              onClick={() => openCreateDrawer("artboard")}
              data-cy="new-artboard-header-button"
            >
              New Artboard
            </Menu.Item>
            <Menu.Item
              onClick={() => openCreateDrawer("stamp")}
              data-cy="new-stamp-header-button"
            >
              New Stamp
            </Menu.Item>
          </Menu>
        ),
      },
    },
    Edit: {
      disabled: edit.disabled(selectedRows),
      onClick: (e: React.MouseEvent<HTMLElement>) => handleEdit(e),
    },
    Params: {
      disabled: !isSingleRowSelected || editParams.disabled(selectedRows[0]),
      onClick: () => editParams.handler(selectedRows[0]),
    },
    Preview: {
      disabled: !isSingleRowSelected || showPreview.disabled(selectedRows[0]),
      onClick: () => showPreview.handler(selectedRows[0]),
      extraInfo: { tooltip: "Preview template" },
    },
    Archive: {
      disabled: archive.disabled(selectedRows),
      onClick: () => archive.handler(selectedRows),
    },
    Column: {
      onClick: () => setIsColumnPickerOpen(true),
      extraInfo: { iconOnly: true },
    },
    Duplicate: {
      disabled: !isSingleRowSelected || duplicate.disabled(selectedRows[0]),
      onClick: () => duplicate.handler(selectedRows[0]),
    },
    Filter: {
      onClick: openFilterDrawer,
    },
    SelectWithSort: {
      onClick: () => null,
      extraInfo: [
        {
          value: "name",
          label: "Name",
        },
        {
          value: "category",
          label: "Category",
        },
        {
          value: "status",
          label: "Status",
        },
        {
          value: "assetType",
          label: "Asset Type",
        },
        {
          value: "templateType",
          label: "Template Type",
        },
        {
          value: "mediaType",
          label: "Media Type",
        },
        {
          value: "dimensions",
          label: "Dimensions",
        },
        {
          value: "numberOfStamps",
          label: "Number of Stamps",
        },
        {
          value: "artboardName",
          label: "Artboard Name",
        },
        {
          value: "stores",
          label: "Accounts",
        },
        {
          value: "oems",
          label: "Brands",
        },
        {
          value: "created",
          label: "Created",
        },
        {
          value: "lastEdited",
          label: "Last Edited",
        },
        {
          value: "tags",
          label: "Tags",
        },
      ],
    },
  };

  return (
    <div className={styles.header}>
      <ToolbarTable
        layout={layout}
        onClickLayout={setLayout}
        previewFormat={false}
        toolbarContents={toolbarContents}
        searchPlaceholder="Search"
        searchValue={globalFilter ?? ""}
        onSearch={setGlobalFilter}
        titleTooltip="Search"
        sortingOrder={sortKey && sortOrder ? [sortKey, sortOrder] : undefined}
        includeFilterOnTableLayout={true}
        onSortChange={([columnKey, order]) => {
          sortItems(columnKey, order);
        }}
      />
    </div>
  );
};
