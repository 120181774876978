import { StoreType } from "polotno/model/store";
import { PropsWithChildren } from "react";
import { MenuItem, Menu, Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useWorkspaceContextMenu } from "./workspaceContainer/useWorkspaceContextMenu";

type WorkspaceContainerProps = PropsWithChildren<{ store: StoreType }>;

export const WorkspaceContainer = observer(
  ({ children, store }: WorkspaceContainerProps) => {
    const { handleContextMenu, handleClose, actions, contextMenu } =
      useWorkspaceContextMenu(store);

    return (
      <Box
        onContextMenu={handleContextMenu}
        sx={{
          width: 1,
          height: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {children}
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          {actions.map(action => (
            <MenuItem key={action.label} onClick={action.onClick}>
              {action.label}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    );
  },
);
