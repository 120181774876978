import { PlusOutlined } from "@ant-design/icons";
import { Button, Empty } from "antd";
import useNavigateWithSearch from "shared/hooks/useNavigateWithSearch";
import styles from "./EmptyMarketingMaterials.module.scss";
import { useFetchMarketingMaterials } from "./hooks/useFetchMarketingMaterials";

function EmptyMarketingMaterials() {
  const navigateWithSearch = useNavigateWithSearch();
  const { isLoading, marketingMaterials } = useFetchMarketingMaterials();
  if (isLoading) {
    return null;
  }
  return (
    <div className={styles.empty}>
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description=""
        className={styles.icon}
      />
      {marketingMaterials.length === 0 ? (
        <>
          <div className={styles.text}>No marketing materials yet</div>
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => {
              navigateWithSearch(`new`);
            }}
            style={{ borderRadius: "15px" }}
          >
            New Marketing Material
          </Button>
        </>
      ) : (
        <div className={styles.text}>No marketing materials found</div>
      )}
    </div>
  );
}

export default EmptyMarketingMaterials;
