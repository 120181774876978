import { Popover, IconButton, Typography, Stack } from "@mui/material";
import { SketchPicker, ColorResult } from "react-color";
import FormatColorFillRoundedIcon from "@mui/icons-material/FormatColorFillRounded";
import { useAnchorPopover } from "screens/designStudio/designStudioV2/hooks/useAnchorPopover";

type ColorPickerPopoverProps = {
  color: string;
  onChange: (color: ColorResult) => void;
  label?: string;
};

export const ColorPickerPopover = ({
  color,
  onChange,
  label,
}: ColorPickerPopoverProps) => {
  const { id, open, onClick, onClose, anchorEl } =
    useAnchorPopover("color-picker");

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent={label ? "space-between" : "flex-start"}
      width={label ? "100%" : "auto"}
    >
      {label && <Typography variant="caption">{label}</Typography>}
      <IconButton size="small" onClick={onClick}>
        <FormatColorFillRoundedIcon fontSize="small" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <SketchPicker
          color={color}
          onChange={onChange}
          styles={{
            default: {
              alpha: {
                display: "none",
              },
            },
          }}
        />
      </Popover>
    </Stack>
  );
};
