import { AbsoluteFill, Audio } from "remotion";
import styles from "./AudioVisualizer.module.scss";
import { DEFAULT_FPS } from "screens/assetExporter/feedConfigurationV2/shared/constants";

type Props = {
  src: string;
  start: number;
  end: number;
};

export const AudioVisualizer = ({ src, start, end }: Props) => {
  if (!src) return;
  return (
    <AbsoluteFill
      className={styles.audioVisualizer}
      style={{ backgroundColor: "white", backgroundImage: "unset" }}
    >
      <Audio
        src={src}
        startFrom={start * DEFAULT_FPS}
        endAt={end * DEFAULT_FPS}
      />
    </AbsoluteFill>
  );
};
