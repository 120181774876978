import DataListURLTable, {
  ITableColumnURL,
} from "shared/components/dataListURL/DataListURLTable";
import { ToolbarButton } from "shared/components/toolbarTable/ToolbarTable";
import { TableActionsContextProvider } from "shared/components/HoverActionsVirtualTable/TableActionsContext";
import { ContextTableActions } from "shared/components/tableActions/ContextTableActions";
import { InlineTableActions } from "shared/components/tableActions/InlineTableActions";
import { DataListURLProvider } from "shared/components/dataListURL/dataListURLContext";
import { ThumbnailCell } from "shared/components/ThumbnailCell";
import { filterBySearch } from "utils/helpers.toolbar";
import { useAccountsContext } from "shared/components/salesEnablement/context/AccountsContext";
import { Account } from "shared/types/salesEnablement";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { useFetchAccounts } from "shared/hooks/salesEnablement/useFetchAccounts";

const AccountList = () => {
  const { windowInnerHeight } = useWindowSize();
  const { data = [], isLoading } = useFetchAccounts();
  const { setEmployees, setSelectedAccounts, toggleDrawer, search } =
    useAccountsContext();

  const generateToolbarContents: (
    record?: Account,
  ) => ToolbarButton = record => {
    if (!record) return {};

    return {};
  };
  const onClickName = (item: Account) => {
    setEmployees(item.employees);
    setSelectedAccounts([item]);
    toggleDrawer("EDIT_ACCOUNT");
  };

  const columns: ITableColumnURL<keyof Account, Account>[] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name: string, item: Account) => (
        <ThumbnailCell
          name={name}
          image={item.logos[0]?.url || ""}
          onClick={() => onClickName(item)}
        />
      ),
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
    },
    {
      title: "Logos",
      dataIndex: "logos",
      key: "logos",
      render: logos => logos?.length || 0,
    },
  ];
  const fields = {};
  const dataSource = filterBySearch(data, search || "");

  return (
    <DataListURLProvider fields={fields}>
      <TableActionsContextProvider<Account>
        renderContextActions={record => (
          <ContextTableActions
            getItemsToRender={generateToolbarContents}
            record={record}
          />
        )}
        renderHoverActions={record => (
          <InlineTableActions
            getItemsToRender={generateToolbarContents}
            record={record}
          />
        )}
      >
        <DataListURLTable<keyof Account, Account>
          dataSource={dataSource}
          loading={isLoading}
          columns={columns}
          pagination={false}
          rowSelection={{
            type: "checkbox",
            onChange: (_, selectedRows) => {
              setSelectedAccounts(selectedRows);
            },
          }}
          size="small"
          scroll={{ y: windowInnerHeight - 245 }}
          type="hoverActionsVirtualTable"
          fieldKeys={["name", "url", "logos", "employees"]}
        />
      </TableActionsContextProvider>
    </DataListURLProvider>
  );
};

export default AccountList;
