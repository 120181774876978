import { useQuery } from "react-query";
import API from "services";

export const useGetPmaxUploadStatus = (executionId?: string) =>
  useQuery(
    ["pmaxUploadStatus", executionId],
    () => {
      if (!executionId) return Promise.reject("Execution ID is not defined");
      return API.services.adLibrary.getPmaxUploadStatus(executionId);
    },
    {
      enabled: !!executionId,
      refetchInterval: data => {
        if (data?.result?.status === "processing") return 2500;
        return false;
      },
    },
  );
