import { Checkbox, Form } from "antd";
import { useProducts } from "shared/hooks/admin/useProducts";
import { getProductTypeOfferDisclosureRule } from "../utils/helpers";

export const ProductTypeOfferInput = ({
  disabled,
  disclosureFieldId,
}: {
  disabled?: boolean;
  disclosureFieldId?: string;
}) => {
  const { productTypes } = useProducts();

  return (
    <Form.Item
      label="Product Type Offer"
      required
      name="productTypeOffer"
      rules={[
        { required: true, message: "This field is required." },
        getProductTypeOfferDisclosureRule(disclosureFieldId),
      ]}
      tooltip={{
        title:
          "At least one Product Type Offer is required for disclosure(s) to populate.",
        placement: "right",
      }}
    >
      <Checkbox.Group
        disabled={disabled}
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(100%, 1fr))",
        }}
        options={productTypes
          .filter(productType => !excludedProductTypes.includes(productType))
          .map(productType => ({
            label: productType,
            value: productType,
          }))}
      />
    </Form.Item>
  );
};

const excludedProductTypes = [
  "Aflac Dental and Vision",
  "Aflac Dental & Vision",
];
