import { IAd } from "shared/types/adLibrary";
import { AdType } from "screens/adLibrary/facebookUtils/types";
import { SearchOutlined } from "@ant-design/icons";
import { SearchFilterDropdown } from "shared/components/SearchFilterDropdown";
import { IAdValidations } from "screens/adLibrary/adImport/adValidationUtils";
import { getAdFormatTypes, getAdTypeLabel } from "screens/adLibrary/utils";

import { EditableCellType } from "shared/components/dataList/EditableCell";
import {
  isNotChildRecord,
  isChildRecord,
} from "../../adCompleteImportDrawer/adImportTable/adImportTableContainer/adImportTable.utils";
import { MediaCell } from "./MediaCell";
import { onFilterBy, compareStringBy } from "utils/helpers.table";
import {
  AdTableColumn,
  getCellProps,
} from "../../adCompleteImportDrawer/adImportTable/adImportTableContainer/columns";
import { MultipleIfHasChildren } from "./MultipleIfHasChildren";
import { AdImportEditableCell } from "./AdImportEditableCell";
import styles from "./AdImportEditableCell.module.scss";
import AutoEllipsisText from "shared/components/AutoEllipsisText";

type GetColumnsParams = {
  validationErrors?: IAdValidations[];
  onToggleExpand: (record: IAd) => void;
  expandedRowKeys: string[];
  setAd: (adId: string, ad: IAd) => void;
};

const getDescription = (record: IAd) => {
  if (record.type === AdType.Carousel) {
    return record.visuals?.cards?.[0]?.description;
  }
  return record.visuals?.headlineDescription;
};

const getDescriptionDataIndex = (record: IAd) => {
  if (record.type === AdType.Carousel) {
    return ["visuals", "description"];
  }
  return ["visuals", "headlineDescription"];
};

export const getColumns = ({
  validationErrors,
  onToggleExpand,
  expandedRowKeys,
  setAd,
}: GetColumnsParams): AdTableColumn[] => {
  const adFormatTypes = getAdFormatTypes();

  return [
    {
      name: "Name",
      title: "Name",
      dataIndex: ["inputParameters", "name"],
      fixed: "left",
      sorter: compareStringBy(a => a.inputParameters?.name),
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      onFilter: onFilterBy<IAd>(ad => ad.inputParameters?.name?.toString(), {
        caseInsensitive: true,
        matchMode: "includes",
      }),
      className: styles.cellWrapper,
      width: 250,
      render: (name, record) => {
        if (isChildRecord(record))
          return <AutoEllipsisText>{name}</AutoEllipsisText>;
        return (
          <AdImportEditableCell
            record={record}
            dataIndex={["inputParameters", "name"]}
            handleSave={(ad: IAd) => setAd(ad.id, ad)}
            type="input"
            isEditing={isNotChildRecord(record)}
            hasError={
              !!validationErrors?.find(vE => vE.adId === record.id)?.name
            }
          />
        );
      },
    },
    {
      name: "In Feed Media",
      title: "In Feed (1:1)",
      dataIndex: ["visuals", "thumbnail"],
      render: (_, ad: IAd) => {
        return <MediaCell thumbnail={ad.visuals?.thumbnail} />;
      },
      width: 100,
    },
    {
      name: "Story Media",
      title: "Story (9:16)",
      dataIndex: ["visuals", "additionalPlacements"],
      render: (_, ad: IAd) => {
        if (ad.type === AdType.Carousel) {
          return (
            <MediaCell
              thumbnail={
                ad.visuals?.cards?.[0]?.additionalPlacements?.[0]?.thumbnail
              }
            />
          );
        }
        return (
          <MediaCell
            thumbnail={ad.visuals?.additionalPlacements?.[0]?.thumbnail}
          />
        );
      },
      width: 100,
    },
    {
      name: "Ad Format",
      title: "Format",
      dataIndex: "type",
      sorter: compareStringBy("type"),
      filters: Object.values(AdType).map(adType => ({
        text: getAdTypeLabel(adType),
        value: adType,
      })),
      onFilter: onFilterBy("type"),
      getClassNameOnError: record =>
        getCellProps(record.id, "adFormat", validationErrors),
      width: 150,
      editableCellType: EditableCellType.Select,
      selectOptions: adFormatTypes,
      render: (format, record) => {
        if (isChildRecord(record)) {
          return <AutoEllipsisText>{format}</AutoEllipsisText>;
        }
        return (
          <AdImportEditableCell
            record={record}
            dataIndex={["type"]}
            handleSave={(ad: IAd) => setAd(ad.id, ad)}
            type="select"
            isEditing={true}
            selectOptions={adFormatTypes}
            hasError={
              !!validationErrors?.find(vE => vE.adId === record.id)?.adFormat
            }
          />
        );
      },
    },
    {
      name: "Copy",
      title: "Primary Text",
      dataIndex: ["visuals", "postCopy"],
      sorter: compareStringBy(a => a.visuals?.postCopy),
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      onFilter: onFilterBy<IAd>(ad => ad.visuals?.postCopy?.toString(), {
        caseInsensitive: true,
        matchMode: "includes",
      }),
      getClassNameOnError: record =>
        getCellProps(record.id, "postCopy", validationErrors),
      width: 200,
      render: (_, record) => {
        if (isChildRecord(record)) return null;
        return (
          <AdImportEditableCell
            record={record}
            dataIndex={["visuals", "postCopy"]}
            handleSave={(ad: IAd) => setAd(ad.id, ad)}
            type="input"
            isEditing={isNotChildRecord(record) ? true : false}
          />
        );
      },
    },
    {
      name: "Headline",
      title: "Headline",
      dataIndex: ["visuals", "headline"],
      sorter: compareStringBy(a => a.visuals?.headline),
      render: (text, record) => {
        if (record.type === AdType.Carousel) {
          return (
            <MultipleIfHasChildren
              text={text}
              record={record}
              onToggleExpand={onToggleExpand}
              numberOfItems={
                record.visuals?.cards?.filter(card => card.headline).length ?? 0
              }
              tooltip={
                expandedRowKeys.includes(record.id)
                  ? "Click to collapse Headlines"
                  : "Click to view all Headlines"
              }
            />
          );
        }

        return (
          <AdImportEditableCell
            record={record}
            dataIndex={["visuals", "headline"]}
            handleSave={(ad: IAd) => setAd(ad.id, ad)}
            type="input"
            isEditing={true}
          />
        );
      },
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      onFilter: onFilterBy<IAd>(ad => ad.visuals?.headline?.toString(), {
        caseInsensitive: true,
        matchMode: "includes",
      }),
      getClassNameOnError: record =>
        getCellProps(record.id, "headline", validationErrors),
      width: 150,
    },
    {
      name: "description",
      title: "Description",
      sorter: compareStringBy(a => getDescription(a)),
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      onFilter: onFilterBy<IAd>(ad => getDescription(ad)?.toString(), {
        caseInsensitive: true,
        matchMode: "includes",
      }),
      width: 200,
      render: (_, record) => {
        if (record.type === AdType.Carousel) {
          return (
            <MultipleIfHasChildren
              text={getDescription(record) ?? ""}
              record={record}
              onToggleExpand={onToggleExpand}
              numberOfItems={
                record.visuals?.cards?.filter(card => card.description)
                  .length ?? 0
              }
              tooltip={
                expandedRowKeys.includes(record.id)
                  ? "Click to collapse Descriptions"
                  : "Click to view all Descriptions"
              }
            />
          );
        }
        return (
          <AdImportEditableCell
            record={record}
            dataIndex={getDescriptionDataIndex(record)}
            handleSave={(ad: IAd) => setAd(ad.id, ad)}
            type="input"
            isEditing={true}
          />
        );
      },
    },
    {
      name: "utm",
      title: "UTM",
      dataIndex: ["inputParameters", "utm"],
      sorter: compareStringBy(a => a.inputParameters?.utm),
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      onFilter: onFilterBy<IAd>(ad => ad.inputParameters?.utm?.toString(), {
        caseInsensitive: true,
        matchMode: "includes",
      }),
      width: 150,
      render: (_, record) => {
        if (isChildRecord(record)) return null;
        return (
          <AdImportEditableCell
            record={record}
            dataIndex={["inputParameters", "utm"]}
            handleSave={(ad: IAd) => setAd(ad.id, ad)}
            type="input"
            isEditing={true}
          />
        );
      },
    },
  ];
};
