import { createContext, memo, ReactNode, useCallback, useContext } from "react";
import { TTemplateComposition } from "shared/types/assetExporter";
import { BrandLogo, CAMLogo } from "shared/types/brandsAccounts";
import { getVideoDurationForAdEngine } from "../../assetBatchDrawer/utils";
import {
  TColumnValue,
  TInputType,
  TMediaResizeType,
  TValueMappings,
} from "../types";
import { getMaxDurationForComposition } from "../utils";
import { isColumnValue } from "../validators";
import { useAssetBatchesContext } from "./AssetBatchesContext";
import {
  findVideoSrcFromVariables,
  getBackgroundMediaForUrl,
} from "./AssetBatchesContext.utils";
import { useAssetBatchesValueMappingContext } from "./AssetBatchesValueMappingContext";

interface ContextProps {
  editingComposition: TTemplateComposition;
  onLogoSelect: (mappingKey: string, logo: BrandLogo | CAMLogo) => void;
  onSelectInputType: (mappingKey: string, value: TInputType) => void;
  onSelectInputValue: (
    mappingKey: string,
    rows: any[],
    value?: string,
  ) => Promise<void>;
  onSelectInputValueSelect: (
    mappingKey: string,
    rows: any[],
    value: TColumnValue,
  ) => Promise<void>;
  onSelectResizeType: (
    mappingKey: string,
    resizeType: TMediaResizeType,
  ) => void;
}

type ContextProviderProps = {
  children: ReactNode;
  editingComposition: TTemplateComposition;
};

const Context = createContext<ContextProps>({} as ContextProps);

const ContextProvider = ({
  children,
  editingComposition,
}: ContextProviderProps) => {
  const {
    currentStep,
    backgroundResizeTypes,
    setBackgroundResizeTypes,
    updateMaxStep,
    setCompositions,
  } = useAssetBatchesContext();

  const {
    updateCompositionVariables,
    setChangedMappingKey,
    onBackgroundMediaChange,
    onBackgroundColumnChange,
  } = useAssetBatchesValueMappingContext();

  const onLogoSelect = useCallback(
    (mappingKey: string, logo: BrandLogo | CAMLogo) => {
      updateMaxStep(currentStep);
      const newMappingValues = (): TValueMappings => {
        const prevValueMapping = editingComposition.variables[mappingKey];
        return {
          ...editingComposition.variables,
          [mappingKey]: {
            ...prevValueMapping,
            value: {
              ...logo,
              id: logo.id,
              type: "logo",
            },
          },
        };
      };
      setChangedMappingKey(mappingKey);
      updateCompositionVariables(
        newMappingValues(),
        editingComposition.compositionId,
      );
    },
    [
      updateCompositionVariables,
      updateMaxStep,
      setChangedMappingKey,
      currentStep,
      editingComposition,
    ],
  );

  const onSelectInputType = useCallback(
    (mappingKey: string, value: TInputType) => {
      updateMaxStep(currentStep);
      setChangedMappingKey(mappingKey);
      const setValueMappings = () => {
        const prevValueMapping = editingComposition.variables[mappingKey];
        const shouldClearValue = value !== prevValueMapping.inputType;
        return {
          ...editingComposition.variables,
          [mappingKey]: {
            ...prevValueMapping,
            inputType: value,
            value: shouldClearValue ? undefined : prevValueMapping.value,
          },
        };
      };
      updateCompositionVariables(
        setValueMappings(),
        editingComposition.compositionId,
      );
    },
    [
      updateCompositionVariables,
      updateMaxStep,
      currentStep,
      editingComposition,
      setChangedMappingKey,
    ],
  );

  const updateDurationWithVideoSrc = useCallback(
    async (valueMappings: TValueMappings, rows: any[]) => {
      const videoSrc = findVideoSrcFromVariables(valueMappings, rows);
      const maxDuration = await getMaxDurationForComposition(
        editingComposition,
        rows,
      );
      const needToUpdateDuration =
        !!videoSrc &&
        (editingComposition?.duration === 0 ||
          maxDuration < editingComposition?.duration);
      if (!needToUpdateDuration) return;
      // Update duration of video compositions
      const duration = await getVideoDurationForAdEngine(videoSrc);
      setCompositions(prevCompositions =>
        prevCompositions.map(comp =>
          comp.compositionId === editingComposition.compositionId
            ? { ...comp, duration }
            : comp,
        ),
      );
    },
    [editingComposition, setCompositions],
  );

  /**
   * This function only handles string | TColumnValue except regex input.
   * @param mappingKey
   * @param value TColumnValue type except regex input
   */
  const onSelectInputValue = useCallback(
    async (mappingKey: string, rows: any[], value?: string) => {
      updateMaxStep(currentStep);
      setChangedMappingKey(mappingKey);
      const setValueMappings = () => {
        const prevValueMapping = editingComposition.variables[mappingKey];
        const val =
          isColumnValue(prevValueMapping.value) &&
          prevValueMapping.value.type === "regex"
            ? { ...prevValueMapping.value, regexPattern: value }
            : value;
        const inputType =
          typeof val === "string" ? "text" : prevValueMapping.inputType;
        return {
          ...editingComposition.variables,
          [mappingKey]: {
            ...prevValueMapping,
            value: val,
            inputType,
          },
        };
      };
      updateCompositionVariables(
        setValueMappings(),
        editingComposition.compositionId,
      );

      const valueMapping = editingComposition.variables[mappingKey];

      if (valueMapping.variable.variable === "Theme Background") {
        if (!value) {
          onBackgroundMediaChange(editingComposition.compositionId, undefined);
          return;
        }
        const media = await getBackgroundMediaForUrl(value);
        onBackgroundMediaChange(editingComposition.compositionId, media);
      }

      updateDurationWithVideoSrc(setValueMappings(), rows);
    },
    [
      updateMaxStep,
      currentStep,
      setChangedMappingKey,
      updateCompositionVariables,
      editingComposition.compositionId,
      editingComposition.variables,
      updateDurationWithVideoSrc,
      onBackgroundMediaChange,
    ],
  );

  const onSelectInputValueSelect = useCallback(
    async (mappingKey: string, rows: any[], value: TColumnValue) => {
      updateMaxStep(currentStep);
      setChangedMappingKey(mappingKey);
      const setValueMappings = (): TValueMappings => {
        const prevValueMapping = editingComposition.variables[mappingKey];
        return {
          ...editingComposition.variables,
          [mappingKey]: {
            ...prevValueMapping,
            inputType: "match_to_column",
            value,
          },
        };
      };

      updateCompositionVariables(
        setValueMappings(),
        editingComposition.compositionId,
      );

      const valueMapping = editingComposition.variables[mappingKey];
      if (valueMapping?.variable?.variable === "Theme Background") {
        onBackgroundColumnChange(
          editingComposition.compositionId,
          value.column,
        );
      }

      updateDurationWithVideoSrc(setValueMappings(), rows);
    },
    [
      updateMaxStep,
      currentStep,
      setChangedMappingKey,
      updateCompositionVariables,
      editingComposition.compositionId,
      editingComposition.variables,
      updateDurationWithVideoSrc,
      onBackgroundColumnChange,
    ],
  );

  const onSelectResizeType = useCallback(
    (mappingKey: string, resizeType: TMediaResizeType) => {
      updateMaxStep(currentStep);
      setChangedMappingKey(mappingKey);
      const setValueMappings = () => {
        const prevValueMapping = editingComposition.variables[mappingKey];
        return {
          ...editingComposition.variables,
          [mappingKey]: {
            ...prevValueMapping,
            resizeType,
          },
        };
      };
      updateCompositionVariables(
        setValueMappings(),
        editingComposition.compositionId,
      );
      if (
        editingComposition.variables[mappingKey].variable.variable ===
        "Theme Background"
      )
        setBackgroundResizeTypes({
          ...backgroundResizeTypes,
          [editingComposition.compositionId]: resizeType,
        });
    },
    [
      updateMaxStep,
      currentStep,
      setChangedMappingKey,
      updateCompositionVariables,
      editingComposition.compositionId,
      editingComposition.variables,
      setBackgroundResizeTypes,
      backgroundResizeTypes,
    ],
  );

  return (
    <Context.Provider
      value={{
        editingComposition,
        onLogoSelect,
        onSelectInputType,
        onSelectInputValue,
        onSelectInputValueSelect,
        onSelectResizeType,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const AssetBatchesTemplateCompositionProvider = memo(ContextProvider);

export const useAssetBatchesTemplateCompositionContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error("Context must be used within a ContextProvider");
  }

  return context;
};
