import { useQuery } from "react-query";
import API from "services";
import { getResultIfOk } from "services/adLibrary";

export const useFetchRecentTemplates = (userId: string) => {
  const queryKey = ["recent-templates", userId];
  const queryFn = async () =>
    getResultIfOk(
      await API.services.salesEnablement.getRecentTemplates(userId),
    );

  return useQuery(queryKey, queryFn);
};
