import { useEffect, useMemo } from "react";
import { useInfiniteQuery } from "react-query";
import API from "services";
import {
  ArtboardSizeType,
  ITemplate,
  TemplateStatusType,
} from "shared/types/designStudio";

type ReturnType = {
  templates: ITemplate[];
  isFetching: boolean;
  hasNextPage?: boolean;
};

type FetchTemplateParams = {
  status?: TemplateStatusType;
  isDeleted?: boolean;
  enabled?: boolean;
  artboardSize?: ArtboardSizeType;
};

export const useFetchTemplates = ({
  status,
  isDeleted,
  enabled,
  artboardSize,
}: FetchTemplateParams): ReturnType => {
  const { data, hasNextPage, fetchNextPage, isFetched, isFetchingNextPage } =
    useFetchTemplatesPaginated({ status, isDeleted, enabled, artboardSize });

  useEffect(() => {
    if (!hasNextPage || isFetchingNextPage) return;
    fetchNextPage();
  }, [hasNextPage, fetchNextPage, isFetchingNextPage]);

  const templates = useMemo(
    () => data?.pages.flatMap(page => page?.templates ?? []) ?? [],
    [data?.pages],
  );

  return {
    templates,
    isFetching: !isFetched,
    hasNextPage,
  };
};

const useFetchTemplatesPaginated = ({
  status,
  isDeleted,
  enabled,
  artboardSize,
}: FetchTemplateParams) => {
  return useInfiniteQuery({
    queryKey: [
      "templates",
      ...(isDeleted ? ["deleted"] : []),
      ...(!!status ? [status] : []),
      ...(!!artboardSize ? [artboardSize] : []),
    ],
    queryFn: getTemplatesPaginated({ status, isDeleted, artboardSize }),
    getNextPageParam: lastPage => {
      return lastPage?.paginationKey;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled,
  });
};

const getTemplatesPaginated =
  ({ status, isDeleted, artboardSize }: FetchTemplateParams) =>
  async ({ pageParam }: { pageParam?: string }) => {
    const { result, error } =
      await API.services.designStudio.getTemplatesPaginated({
        status,
        isDeleted,
        paginationKey: pageParam,
        artboardSize,
      });

    if (error) {
      throw Error(error.message);
    }

    return result;
  };
