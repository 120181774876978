import { observer } from "mobx-react-lite";
import { Typography, Switch } from "@mui/material";
import useMediaElementEffects from "screens/designStudio/designStudioV2/hooks/useMediaElementEffects";
import BlurSlider from "screens/designStudio/designStudioV2/editorDrawer/editorDrawerContent/polotnoEditor/editorTopbar/layerEffects/BlurSlider";
import Shadow from "screens/designStudio/designStudioV2/editorDrawer/editorDrawerContent/polotnoEditor/editorTopbar/layerEffects/Shadow";
import { StackContainer } from "screens/designStudio/designStudioV2/editorDrawer/editorDrawerContent/polotnoEditor/editorTopbar/containers/StackContainer";
import { ImageLayerElement } from "screens/designStudio/designStudioV2/types";
import { EffectsMenu } from "../containers/EffectsMenu";
import Brightness from "../layerEffects/Brightness";
import Border from "../layerEffects/Border";
import CornerRadius from "../layerEffects/CornerRadius";

type FontEffectsControlProps = {
  element: ImageLayerElement;
};

const ImageEffectControls = observer(({ element }: FontEffectsControlProps) => {
  const isBlurEnabled = Boolean(element?.blurEnabled);
  const isBrightnessEnabled = Boolean(element?.brightnessEnabled);
  const isBorderEnabled = Boolean(element?.borderSize);
  const isCornerRadiusEnabled = Boolean(element?.cornerRadius);
  const isGrayscaleEnabled = Boolean(element?.grayscaleEnabled);
  const isSepiaEnabled = Boolean(element?.sepiaEnabled);
  const isShadowEnabled = Boolean(element?.shadowEnabled);

  const {
    toggleBlur,
    toggleBrightness,
    toggleBorder,
    toggleCornerRadius,
    toggleGrayscale,
    toggleSepia,
    toggleShadow,
  } = useMediaElementEffects(element);

  return (
    <EffectsMenu>
      <StackContainer>
        <Switch checked={!!isBlurEnabled} onChange={toggleBlur} />
        <Typography variant="caption">Blur</Typography>
      </StackContainer>
      {isBlurEnabled && <BlurSlider elements={[element]} />}
      <StackContainer>
        <Switch checked={!!isBrightnessEnabled} onChange={toggleBrightness} />
        <Typography variant="caption">Brightness</Typography>
      </StackContainer>
      {isBrightnessEnabled && <Brightness element={element} />}
      <StackContainer>
        <Switch checked={!!isGrayscaleEnabled} onChange={toggleGrayscale} />
        <Typography variant="caption">Grayscale</Typography>
      </StackContainer>
      <StackContainer>
        <Switch checked={!!isSepiaEnabled} onChange={toggleSepia} />
        <Typography variant="caption">Sepia</Typography>
      </StackContainer>
      <StackContainer>
        <Switch checked={!!isBorderEnabled} onChange={toggleBorder} />
        <Typography variant="caption">Border</Typography>
      </StackContainer>
      {isBorderEnabled && <Border element={element} />}
      <StackContainer>
        <Switch
          checked={!!isCornerRadiusEnabled}
          onChange={toggleCornerRadius}
        />
        <Typography variant="caption">Corner Radius</Typography>
      </StackContainer>
      {isCornerRadiusEnabled && <CornerRadius element={element} />}
      <StackContainer>
        <Switch checked={!!isShadowEnabled} onChange={toggleShadow} />
        <Typography variant="caption">Shadow</Typography>
      </StackContainer>
      {isShadowEnabled && <Shadow elements={[element]} />}
    </EffectsMenu>
  );
});

export default ImageEffectControls;
