import classNames from "classnames";
import { memo, ReactText, useCallback, useState } from "react";
import {
  getValidationForAdId,
  IAdValidations,
} from "screens/adLibrary/adImport/adValidationUtils";
import ColumnsFilter from "screens/adLibrary/shared/components/ColumnsFilter";
import { useBatchMutateAds } from "shared/hooks/adLibrary/useMutateAd";
import useResizableTable from "shared/hooks/useResizableTable";
import useSelectedColumns from "shared/hooks/userPreferences/useSelectedColumns";
import { INotification } from "shared/types/notification";
import { useUser } from "shared/hooks/useUser";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { AdLibraryTableColumn, IAd, QcStatus } from "shared/types/adLibrary";
import { IndustryType } from "shared/types/shared";
import { AdPreviewModal } from "../../shared/AdPreviewModal";
import "./AdTableListContainer.scss";
import { useBatchCreateNotification } from "shared/hooks/notification/useMutateNotification";
import AdExportDrawer from "../AdExportDrawer";
import { useAdLibraryIds } from "screens/adLibrary/shared/hooks/dataListHooks";
import { adFieldKeys, IAdFields } from "screens/adLibrary/fields";
import DataListURLTable from "shared/components/dataListURL/DataListURLTable";
import { getColumns } from "./adTableListContainer/columns";
import { noop } from "lodash";
import styles from "./AdTableListContainer.module.scss";
import { shouldEnableNotifications } from "utils/constants";

interface Props<AdType extends IAd = IAd> {
  data: AdType[];
  selectedItems: AdType[];
  displayColumn: boolean;
  expand: boolean;
  defaultColumns?: string[];
  clientType: IndustryType;
  setClientType: (clientType: IndustryType) => void;
  setDisplayColumn: (displayColumn: boolean) => void;
  showExportDrawer?: boolean;
  setShowExportDrawer?: (show: boolean) => void;
  onEditClick?: (ad: IAd) => void;
  stickyColumns?: string[];
  validationErrors?: IAdValidations[];
  extraColumns?: AdLibraryTableColumn<AdType>[];
  disabledRows?: { [adId: string]: string };
}

const AdTableListContainer = <AdType extends IAd = IAd>({
  expand,
  displayColumn,
  defaultColumns = [
    "Name",
    "Ad Format",
    "Status",
    "Campaign Planners",
    "Created",
    "Last Edit",
    "Last Edit By",
    "Tags",
    "Ad URL",
    "Destination URL",
  ],
  clientType,
  setClientType,
  setDisplayColumn,
  showExportDrawer,
  setShowExportDrawer,
  stickyColumns,
  onEditClick,
  validationErrors,
  data,
  selectedItems,
  extraColumns,
  disabledRows = {},
}: Props<AdType>) => {
  const { selectedIds } = useAdLibraryIds();

  const { isColumnSelected, selectedColumns, setSelectedColumns } =
    useSelectedColumns(defaultColumns);

  const [previewAd, setPreviewAd] = useState<AdType | null>(null);
  const { windowInnerHeight } = useWindowSize();

  const getCellProps = (adId: string, field: keyof IAdValidations) => ({
    className: classNames({
      "cell-with-error": !!getValidationForAdId(adId, validationErrors ?? [])?.[
        field
      ],
    }),
  });

  const { mutate: batchCreateNotifications } = useBatchCreateNotification();
  const { mutate: batchMutateAds } = useBatchMutateAds();
  const user = useUser();
  const currentUser = user?.email as string;

  const onStatusChange = useCallback(
    (status: QcStatus, ad: IAd, selectedIds: ReactText[]) => {
      if (selectedIds.length > 1 && selectedIds.some(id => id === ad.id)) {
        batchMutateAds(
          selectedItems.map(ad => ({
            ...ad,
            qcStatus: status,
            updatedBy: currentUser,
            updatedAt: Date.now(),
          })),
        );
      }

      batchMutateAds([
        {
          ...ad,
          qcStatus: status,
          updatedBy: currentUser,
          updatedAt: Date.now(),
        },
      ]);

      if (shouldEnableNotifications && ad.userHistory?.length) {
        const notificationObj: INotification = {
          user: "",
          createdBy: `${user?.name}`,
          type: "updateStatus",
          module: "Ad Library",
          feature: `${ad.inputParameters.name}`,
          detail: ad.qcStatus,
          change: status,
          link: `/ad-library?id=${ad.id}`,
        };
        const notificationsObj = ad.userHistory
          .filter(pUser => pUser !== currentUser)
          .map(userToNotify => {
            return { ...notificationObj, user: userToNotify };
          });
        batchCreateNotifications(notificationsObj);
      }
    },

    [
      batchMutateAds,
      batchCreateNotifications,
      selectedItems,
      user,
      currentUser,
    ],
  );

  const columns = getColumns<AdType>({
    selectedIds,
    setPreviewAd,
    onEditClick,
    getCellProps,
    onStatusChange,
    extraColumns,
  });

  stickyColumns?.length &&
    columns.forEach((column: any) => {
      if (stickyColumns.includes(column.title)) {
        column.fixed = "left";
      }
    });

  const { components, resizableColumns } = useResizableTable<
    AdType,
    AdLibraryTableColumn<AdType> & { name: string }
  >(
    columns.map(column => ({ ...column, name: column.title })),
    disabledRows,
  );

  const filteredColumns: AdLibraryTableColumn<AdType>[] = (
    resizableColumns ?? []
  ).filter(
    c =>
      isColumnSelected(c.title) || extraColumns?.some(col => col.key === c.key),
  );

  const exportColumns = getColumns<IAd>({
    selectedIds,
    setPreviewAd: noop,
    onEditClick: noop,
    getCellProps,
    onStatusChange,
  });

  const isRowDisabled = (ad: AdType) => {
    return (
      getValidationForAdId(ad.id, validationErrors ?? [])?.hasErrors ||
      !!disabledRows[ad.id]
    );
  };
  return (
    <>
      <DataListURLTable<IAdFields, AdType>
        data-cy="ad-table-container"
        className="ad-table-container"
        scroll={{ y: windowInnerHeight - (expand ? 280 : 245) }}
        rowKey={record => record.id}
        pagination={false}
        rowClassName={ad => (isRowDisabled(ad) ? styles.disabledRow : "")}
        size={expand ? "large" : "small"}
        rowSelection={{
          getCheckboxProps: adRecord => ({
            disabled: isRowDisabled(adRecord),
          }),
        }}
        disabledRows={disabledRows}
        columns={filteredColumns}
        components={components}
        dataSource={data}
        fieldKeys={adFieldKeys}
        type="hoverActionsVirtualTable"
      />
      <ColumnsFilter
        displayColumn={displayColumn}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        setDisplayColumn={setDisplayColumn}
        columnNames={columns.map(column => column.title)}
        clientType={clientType}
        setClientType={setClientType}
      />
      {previewAd && <AdPreviewModal ad={previewAd} setAd={setPreviewAd} />}
      {showExportDrawer && setShowExportDrawer && (
        <AdExportDrawer
          showExportDrawer={showExportDrawer}
          setShowExportDrawer={setShowExportDrawer}
          colDefsForExport={exportColumns}
        />
      )}
    </>
  );
};

export default memo(AdTableListContainer) as typeof AdTableListContainer;
