import { Input } from "antd";
import { getNormalizedUrlValue } from "utils/helpers.salesEnablement";

type Props = {
  disabled?: boolean;
  value?: string;
  onChange?: (value: string) => void;
};

export const UrlInput = ({ disabled, value, onChange }: Props) => {
  const prefix = "https://";
  return (
    <Input
      addonBefore={prefix}
      id="url"
      disabled={disabled}
      value={value?.split(prefix)[1] || value}
      onChange={e => onChange?.(getNormalizedUrlValue(e.target.value))}
    />
  );
};
