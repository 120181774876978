import styles from "./ListSection.module.scss";
import { UserMarketingMaterials } from "./UserMarketingMaterials";
import { TemplatesAddedByMe, RecentlyUsedTemplates } from "./UserTemplates";
import { ExpiringTemplates } from "./ExpiringTemplates";
import { useNavigate } from "react-router-dom";
import { useUser } from "shared/hooks/useUser";
import { ROUTES } from "./utils/constants";

type SectionProps = {
  isAdmin: boolean;
};

export const ListSection = ({ isAdmin }: SectionProps) => {
  const navigate = useNavigate();
  const { sub: userId } = useUser();
  const onTemplateClick = () =>
    navigate(isAdmin ? ROUTES.userTemplates(userId) : ROUTES.newestTemplates);

  return (
    <div className={styles.container}>
      {isAdmin ? (
        <>
          <ExpiringTemplates />
          <TemplatesAddedByMe userId={userId} onClick={onTemplateClick} />
        </>
      ) : (
        <>
          <RecentlyUsedTemplates userId={userId} onClick={onTemplateClick} />
          <UserMarketingMaterials />
        </>
      )}
    </div>
  );
};
