import TemplateList from "./designStudio/TemplateList";
import { DataProvider } from "./designStudio/hooks/SalesEnablementDataProvider";
import { DataListURLProvider } from "shared/components/dataListURL/dataListURLContext";
import { fields } from "./designStudio/fields";

const Templates = () => {
  return (
    <DataProvider>
      <DataListURLProvider fields={fields}>
        <TemplateList showActions />
      </DataListURLProvider>
    </DataProvider>
  );
};

export default Templates;
