import { useLoadStep } from "./hooks/useLoadStep";
import SelectTable from "./selectStep/Table";
import SelectFooter from "./selectStep/Footer";
import { fields as selectFields } from "./selectStep/fields";
import ResultTable from "./resultStep/Table";
import ResultFooter from "./resultStep/Footer";
import { fields as resultFields } from "./resultStep/fields";
import { ReactNode, useMemo } from "react";
import { DataListURLProvider } from "shared/components/dataListURL/dataListURLContext";

type Response = {
  Table: () => JSX.Element;
  Footer: () => JSX.Element;
  DataListProvider: ({ children }: { children: ReactNode }) => JSX.Element;
  shouldDisableAccounts: boolean;
};

export const useStepData = (): Response => {
  const step = useLoadStep();

  const selectStepData = useMemo(
    () => ({
      Table: () => <SelectTable />,
      Footer: () => <SelectFooter />,
      DataListProvider: ({ children }: { children: ReactNode }) => (
        <DataListURLProvider fields={selectFields}>
          {children}
        </DataListURLProvider>
      ),
      shouldDisableAccounts: false,
    }),
    [],
  );

  const resultStepData = useMemo(
    () => ({
      Table: () => <ResultTable />,
      Footer: () => <ResultFooter />,
      DataListProvider: ({ children }: { children: ReactNode }) => (
        <DataListURLProvider fields={resultFields}>
          {children}
        </DataListURLProvider>
      ),
      shouldDisableAccounts: true,
    }),
    [],
  );

  if (step === "select") return selectStepData;
  if (step === "result") return resultStepData;

  throw new Error("Invalid step");
};
