import { useContext, createContext, ReactNode } from "react";
import { LineItemFormInstance, LineItemFormType } from "../types";
import { useForm } from "antd/lib/form/Form";

type LineItemDrawerContextType = {
  form: LineItemFormInstance;
};

const Context = createContext<LineItemDrawerContextType | null>(null);

export const useLineItemDrawerContext = () => {
  const ctx = useContext(Context);

  if (!ctx) {
    throw new Error(
      "useLineItemDrawerContext must be used within a LineItemDrawerContext",
    );
  }
  return ctx;
};

export const LineItemDrawerContext = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [form] = useForm<LineItemFormType>();

  return (
    <Context.Provider
      value={{
        form,
      }}
    >
      {children}
    </Context.Provider>
  );
};
