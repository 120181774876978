import { Drawer } from "antd";
import { useGetAgentProfiles } from "shared/hooks/salesEnablement/useAgentProfiles";
import AgentProfileDrawerForm from "./agentProfileDrawer/AgentProfileDrawerForm";
import Spinner from "screens/adLibrary/adLoadV2/shared/Spinner";
import { AgentProfile } from "screens/adLibrary/marketingMaterialDrawer/hooks/agentManagement";
import AgentProfileFooter from "./agentProfileDrawer/AgentProfileDrawerFooter";

type Props = {
  /**
   * The agent id, if it's truthy
   */
  agentId: string | undefined;
  onClose: () => void;
  onSuccess: (data: AgentProfile) => void;
  visible: boolean;
};

const AgentProfileDrawer = ({
  onClose,
  visible,
  onSuccess,
  agentId,
}: Props) => {
  const isEdit = !!agentId;
  const { data: agentProfiles, isLoading: isLoadingAgentProfiles } =
    useGetAgentProfiles();
  const agentProfileEdit = isEdit
    ? agentProfiles?.find(agentProfile => agentProfile.id === agentId)
    : undefined;

  return (
    <Drawer
      data-cy="agent-profile-drawer"
      visible={visible}
      title={isEdit ? "Edit Agent" : "New Agent"}
      placement="right"
      width={500}
      onClose={onClose}
      destroyOnClose
      closable={false}
      footerStyle={{
        display: "flex",
      }}
      footer={<AgentProfileFooter onClose={onClose} agentId={agentId} />}
    >
      <p
        style={{
          color: "rgba(0, 0, 0, 0.45)",
          fontSize: "14px",
        }}
      >
        This information will be used for filling in agent contact info in
        marketing materials.
      </p>
      {isLoadingAgentProfiles && <Spinner />}
      {!isLoadingAgentProfiles && (
        <AgentProfileDrawerForm
          onSuccess={onSuccess}
          agentProfile={agentProfileEdit}
        />
      )}
    </Drawer>
  );
};

export default AgentProfileDrawer;
