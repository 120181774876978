import { Input, Table } from "antd";
import {
  createDataListConfig,
  DataListProvider,
  useDataList,
} from "shared/components/dataList";
import { dataColumns } from "./audienceManager/dataColumns";
import { audienceIntegrationLogsQueryKey } from "./audienceManager/constants";
import { fetchAudienceIntegrationLogsWrapper } from "./audienceManager/hooks/useFetchAudienceIntegrationLogs";
import { useTableColumns } from "./audienceManager/hooks/useTableColumns";
import { AudienceIntegrationLog } from "./audienceManager/types";
import AudienceIntegrationLogDetails from "./audienceManager/AudienceIntegrationLogDetails";
import { useState } from "react";
const { Search } = Input;

const AudienceManager = () => {
  const [dataList, dataListActions] = useDataList<AudienceIntegrationLog>();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedLog, setSelectedLog] = useState<AudienceIntegrationLog>();

  const onClickOnAudienceName = (log: AudienceIntegrationLog) => {
    setIsDrawerOpen(true);
    setSelectedLog(log);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const columns = useTableColumns({ onClickOnAudienceName });

  return (
    <div>
      <Search
        allowClear={true}
        value={dataList.globalFilter}
        placeholder="Search"
        onChange={value => dataListActions.setGlobalFilter(value.target.value)}
        style={{ padding: 16 }}
      />
      <Table<AudienceIntegrationLog>
        loading={dataList.isLoading}
        rowKey={record => record.id}
        columns={columns}
        pagination={false}
        size={"small"}
        dataSource={dataList.rows}
        onChange={(_pagination, _filters, sorter, extra) => {
          if ((extra.action = "sort") && !Array.isArray(sorter)) {
            dataListActions.sortItems(sorter.columnKey, sorter.order);
          }
        }}
      />
      {selectedLog && isDrawerOpen && (
        <AudienceIntegrationLogDetails
          selectedLog={selectedLog}
          onClose={closeDrawer}
        />
      )}
    </div>
  );
};

const AudienceManagerContainer = () => {
  const config = createDataListConfig(dataColumns, {
    queryKey: audienceIntegrationLogsQueryKey,
    queryFn: fetchAudienceIntegrationLogsWrapper,
  });

  return (
    <DataListProvider config={config}>
      <AudienceManager />
    </DataListProvider>
  );
};

export default AudienceManagerContainer;
