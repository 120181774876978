import { useNavigate, useLocation, NavigateOptions } from "react-router-dom";

/**
 * Use this instead of `useNavigate` when you want to navigate to a given location preserving the current search query.
 * @returns A function that navigates to a given location preserving the current search query.
 */
const useNavigateWithSearch = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  return (to: string, options?: NavigateOptions) =>
    navigate(`${to}${search ?? ""}`, options);
};

export default useNavigateWithSearch;
