import { ChangeEvent, Dispatch, SetStateAction, useCallback } from "react";
import {
  IFacebookAccount,
  IFacebookObject,
} from "screens/adLibrary/facebookUtils/types";
import { onlyAllowNumbers } from "screens/adLibrary/shared/validationUtils";
import {
  AccountDetailsFacebook,
  IAccountRecord,
} from "shared/types/accountManagement";

interface IAdPlatformsAccount {
  accountToEdit: IAccountRecord;
  selectedAccount: IFacebookAccount | undefined;
  setAccountToEdit: (accountToEdit: IAccountRecord) => void;
  setSelectedGoogleAccountCID: Dispatch<SetStateAction<string | undefined>>;
  setSelectedBeeswaxAdvertiser: Dispatch<SetStateAction<string | undefined>>;
  setSelectedAccount: Dispatch<SetStateAction<IFacebookAccount | undefined>>;
  setShowPixelModal: Dispatch<SetStateAction<boolean>>;
  setPixelModalData: Dispatch<SetStateAction<IFacebookAccount | undefined>>;
}

export const useAdPlatformsAccount = ({
  accountToEdit,
  selectedAccount,
  setAccountToEdit,
  setSelectedGoogleAccountCID,
  setSelectedBeeswaxAdvertiser,
  setSelectedAccount,
  setShowPixelModal,
  setPixelModalData,
}: IAdPlatformsAccount) => {
  const updateFacebookAsset = useCallback(
    (name: keyof AccountDetailsFacebook, value: string) => {
      setAccountToEdit({
        ...accountToEdit,
        details: {
          ...accountToEdit.details,
          facebook: {
            ...accountToEdit.details.facebook,
            [name]: value,
          },
        },
        enabled: true,
      });
    },
    [accountToEdit, setAccountToEdit],
  );

  const handleFacebookAccountSelect = useCallback(
    (account: IFacebookAccount | undefined) => {
      setSelectedAccount(account);

      if (!!account) {
        setAccountToEdit({
          ...accountToEdit,
          details: {
            ...accountToEdit.details,
            facebook: {
              ...accountToEdit.details.facebook,
              fbAccountId: account.account_id,
              fbPageId: "",
              fbPixelId: "",
              fbCatalogId: "",
              fbInstagramId: "",
            },
          },
          enabled: true,
        });
      }
    },
    [setSelectedAccount, setAccountToEdit, accountToEdit],
  );

  const handlePageSelect = useCallback(
    (page: IFacebookObject | null) => {
      updateFacebookAsset("fbPageId", page?.id || "");
    },
    [updateFacebookAsset],
  );

  const handlePixelSelect = useCallback(
    (pixel: IFacebookObject | null) => {
      updateFacebookAsset("fbPixelId", pixel?.id || "");
    },
    [updateFacebookAsset],
  );

  const handleAccountIdChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      onlyAllowNumbers(value) && updateFacebookAsset("fbAccountId", value);
    },
    [updateFacebookAsset],
  );

  const handlePageIdChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      onlyAllowNumbers(value) && updateFacebookAsset("fbPageId", value);
    },
    [updateFacebookAsset],
  );

  const handleCreatePixel = (value: string) => {
    setShowPixelModal(true);
    setPixelModalData(selectedAccount);
    onlyAllowNumbers(value) && updateFacebookAsset("fbPixelId", value);
  };

  const handleCatalogSelect = useCallback(
    (catalog: IFacebookObject | null) => {
      updateFacebookAsset("fbCatalogId", catalog?.id || "");
    },
    [updateFacebookAsset],
  );

  const handleInstagramSelect = useCallback(
    (instagram: IFacebookObject | null) => {
      updateFacebookAsset("fbInstagramId", instagram?.id || "");
    },
    [updateFacebookAsset],
  );

  const handleCatalogIdChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      onlyAllowNumbers(value) && updateFacebookAsset("fbCatalogId", value);
    },
    [updateFacebookAsset],
  );

  const handleSelectGoogleAccount = useCallback(
    adAccountCID => {
      setSelectedGoogleAccountCID(adAccountCID);
      setAccountToEdit({
        ...accountToEdit,
        details: {
          ...accountToEdit.details,
          google: {
            ...accountToEdit.details.google,
            adAccountCID,
          },
        },
      });
    },
    [accountToEdit, setAccountToEdit, setSelectedGoogleAccountCID],
  );

  const handleClearGoogleAccount = useCallback(() => {
    setSelectedGoogleAccountCID(undefined);
    setAccountToEdit({
      ...accountToEdit,
      details: {
        ...accountToEdit.details,
        google: undefined,
      },
    });
  }, [accountToEdit, setAccountToEdit, setSelectedGoogleAccountCID]);

  const handleChangeBeeswaxAdvertiser = useCallback(
    (advertiserId: string | undefined) => {
      setSelectedBeeswaxAdvertiser(advertiserId);
      setAccountToEdit({
        ...accountToEdit,
        details: {
          ...accountToEdit.details,
          beeswax: {
            ...accountToEdit.details.beeswax,
            advertiserId,
          },
        },
      });
    },
    [accountToEdit, setAccountToEdit, setSelectedBeeswaxAdvertiser],
  );

  return {
    handleFacebookAccountSelect,
    handlePageSelect,
    handlePixelSelect,
    handleAccountIdChange,
    handlePageIdChange,
    handleCreatePixel,
    handleSelectGoogleAccount,
    handleChangeBeeswaxAdvertiser,
    handleClearGoogleAccount,
    handleCatalogSelect,
    handleInstagramSelect,
    handleCatalogIdChange,
  };
};
