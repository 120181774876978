import type { FormInstance } from "antd";
import { createContext, useContext } from "react";

const FormContext = createContext<FormInstance | undefined>(undefined);

/**
 * A hook like this is available in antd > 4.20, but we are using 4.15.6 so we need to create our own
 */
export function useFormInstance<Value = unknown>() {
  const form = useContext(FormContext);

  if (!form) {
    throw new Error("useFormInstance must be used within a FormProvider");
  }

  return form as FormInstance<Value>;
}

type FormProviderProps<Value> = {
  form: FormInstance<Value>;
  children: React.ReactNode;
};

/**
 * A provider to provide the form instance to the children
 * @example
 * const form = useForm();
 * return (
 *  <FormProvider value={form}>
 *    <Form form={form}>
 *     {children}
 *    </Form>
 *  </FormProvider>
 */
export function FormProvider<Value>({
  form,
  children,
}: FormProviderProps<Value>) {
  return <FormContext.Provider value={form}>{children}</FormContext.Provider>;
}
