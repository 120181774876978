import { Button, message } from "antd";
import styles from "./Notification.module.scss";
import { CloseButton } from "./CloseButton";

interface Props {
  messageLabel: React.ReactNode;
  duration?: number;
  dataCy?: string;
  linkText?: string;
  onClickLink?: () => void;
  size?: "small" | "big";
  key?: string;
  maxMsgQty?: number;
}

export const errorNotification = ({
  messageLabel,
  duration,
  dataCy,
  size = "small",
}: Props) => {
  return message.error({
    key: "errorMsg",
    className:
      size === "small"
        ? styles.errorMessage
        : `${styles.errorMessage} ${styles.bigNoticeDiv}`,
    duration: duration ?? 10,
    content: (
      <div
        className={size === "small" ? styles.container : styles.bigContainer}
        data-cy={dataCy ?? "error-message-global"}
      >
        <span>{messageLabel}</span>
        <CloseButton keyMessage="errorMsg" />
      </div>
    ),
  });
};

export const successNotification = ({
  messageLabel,
  duration,
  dataCy,
  linkText,
  onClickLink,
  maxMsgQty,
  size = "small",
  key = "successMsg",
}: Props) => {
  if (maxMsgQty) {
    message.config({ maxCount: maxMsgQty });
  }

  return message.success({
    key,
    className:
      size === "small"
        ? styles.successMessage
        : `${styles.successMessage} ${styles.bigNoticeDiv}`,
    duration: duration ?? 4,
    content: (
      <div
        className={size === "small" ? styles.container : styles.bigContainer}
        data-cy={dataCy ?? "success-message-global"}
      >
        <span>{messageLabel}</span>
        {linkText && (
          <Button
            type="link"
            data-cy="success-message-link"
            onClick={() => {
              message.destroy(key);
              onClickLink?.();
            }}
          >
            {linkText}
          </Button>
        )}
        <CloseButton keyMessage={key} />
      </div>
    ),
  });
};

export const warningNotification = ({
  messageLabel,
  duration,
  dataCy,
  linkText,
  onClickLink,
  size = "small",
  key = "warningMsg",
}: Props) => {
  return message.warning({
    key,
    className:
      size === "small"
        ? styles.warningMessage
        : `${styles.warningMessage} ${styles.bigNoticeDiv}`,
    duration: duration ?? 4,
    content: (
      <div
        className={size === "small" ? styles.container : styles.bigContainer}
        data-cy={dataCy ?? "warning-message-global"}
        key={key}
      >
        <span>{messageLabel}</span>
        {linkText && (
          <Button
            type="link"
            onClick={() => {
              message.destroy(key);
              onClickLink?.();
            }}
          >
            {linkText}
          </Button>
        )}
        <CloseButton keyMessage={key} />
      </div>
    ),
  });
};
