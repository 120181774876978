export interface Theme {
  primaryColor: string;
  secondaryColor: string;
  primaryTextColor: string;
  secondaryTextColor: string;
  greenColor: string;
  redPrimaryColor: string;
  redSecondaryColor: string;
}

const aflacTheme: Theme = {
  primaryColor: "#0070A9",
  secondaryColor: "#008CC5",
  primaryTextColor: "rgba(0, 0, 0, 0.85)",
  secondaryTextColor: "rgba(0, 0, 0, 0.55)",
  greenColor: "#389e0d",
  redPrimaryColor: "#ff4d4f",
  redSecondaryColor: "#ff7875",
};

// * Use the below to conditionally apply the different themes based on client
const getTheme = (): Theme | null => {
  if (process.env.REACT_APP_AV2_CLIENT === "aflac") {
    return aflacTheme;
  }
  return null;
};

export const salesEnablementTheme: Theme | null = getTheme();
