import { useEffect, useState } from "react";
import { Collapse, Spin } from "antd";
import { BeforeCapture, DropResult } from "react-beautiful-dnd";
import { DraggableList } from "shared/components/draggableList/DraggableList";
import { MediaSubtype, TComposition } from "shared/types/assetExporter";
import { HolderOutlined, RightOutlined } from "@ant-design/icons";
import { MediaFromFeedInput } from "../../assetBatchDrawer/panelVariables/MediaFromFeedInput";
import { useAssetBatchesContext } from "../../shared/contexts/AssetBatchesContext";
import {
  isMediaCompositionFromCAM,
  isMediaCompositionFromFeed,
  isTemplateComposition,
} from "../../shared/contexts/AssetBatchesContext.utils";
import { TVariable } from "../../shared/types";
import { MediaFromCamInput } from "../../assetBatchDrawer/panelVariables/MediaFromCamInput";
import styles from "./PanelVariables.module.scss";
import { AddMediaDropdown } from "./panelVariables/AddMediaDropdown";
import { VariableCollapseHeader } from "./panelVariables/VariableCollapseHeader";
import { TotalDuration } from "./panelVariables/TotalDuration";
import { useCompositionDuration } from "../../shared/hooks/useCompositionDuration";
import {
  MAX_VIDEO_DURATION,
  videoCompositionAudioFilesEnabled,
} from "shared/constants/assetExporter";
import { TemplateComposition } from "./panelVariables/TemplateComposition";
import { AudioCollapseSection } from "./panelVariables/UploadAudio/AudioCollapseSection";
import { AudioFromCam } from "../../assetBatchDrawer/panelVariables/AudioFromCam";
import { AudioFromFeed } from "../../assetBatchDrawer/panelVariables/AudioFromFeed";
import { useVideoStitchingContext } from "../../shared/contexts/VideoStitchingContext";

export const PanelVariables = () => {
  const [hoveredPanel, setHoveredPanel] = useState<string | undefined>();
  const [totalDuration, setTotalDuration] = useState(0);
  // Add a new state variable for sorted variables
  const [sortedVariables, setSortedVariables] = useState<TVariable[]>([]);
  const { audioFiles, isFetchingAudioFiles } = useAssetBatchesContext();
  const [currentAudioIndex, setCurrentAudioIndex] = useState("");
  const [prevAudioFilesLength, setPrevAudioFilesLength] = useState(0);
  const [activePanel, setActivePanel] = useState<string | null>(null);

  useEffect(() => {
    if (audioFiles.length > prevAudioFilesLength) {
      const lastAudioFile = audioFiles[audioFiles.length - 1];
      setCurrentAudioIndex(lastAudioFile.id);
    }
    setPrevAudioFilesLength(audioFiles.length);
  }, [audioFiles, prevAudioFilesLength]);

  const handleAudioOnChange = (id: string) => {
    if (currentAudioIndex === id) setCurrentAudioIndex("");
    else setCurrentAudioIndex(id);
  };

  const {
    compositions,
    isFetchingMediaCols,
    currentCompositionId,
    setCurrentCompositionId,
    setPreventAutoScroll,
    setCompositions,
  } = useAssetBatchesContext();

  const { setCurrentMediaId, currentMediaId } = useVideoStitchingContext();

  const { manageDurationExceeded, calculateTotalDuration } =
    useCompositionDuration();

  useEffect(() => {
    const newTotalDuration = calculateTotalDuration(compositions);
    setTotalDuration(newTotalDuration);

    if (newTotalDuration > MAX_VIDEO_DURATION) {
      const lastComposition = compositions[compositions.length - 1];
      manageDurationExceeded(
        lastComposition.duration,
        lastComposition,
        compositions,
      );
    }
  }, [compositions, calculateTotalDuration, manageDurationExceeded]);

  const customHeader = (composition: TComposition, isActive: boolean) => (
    <div
      className={styles.customPanelHeader}
      onMouseEnter={() => setHoveredPanel(composition.compositionId)}
      onMouseLeave={() => setHoveredPanel(undefined)}
    >
      {hoveredPanel === composition.compositionId && (
        <HolderOutlined className={styles.holderIcon} />
      )}
      <RightOutlined
        rotate={isActive ? 90 : 0}
        className={styles.rightArrow}
        width="10px"
        height="10px"
      />
      <VariableCollapseHeader composition={composition} />
    </div>
  );

  const handleOnChange = (compositionId: string) => {
    setPreventAutoScroll(false);
    if (currentMediaId !== compositionId) setCurrentMediaId(compositionId);

    if (activePanel === compositionId) {
      setActivePanel(null);
      return;
    }

    setActivePanel(compositionId);
  };

  const activeAudioKey = [currentAudioIndex ?? ""];
  const createDisplayVariables = (compositions: TComposition[]) => {
    return compositions.map(composition => {
      const activeKey = [
        activePanel === composition.compositionId
          ? composition.compositionId
          : "",
      ];
      if (isTemplateComposition(composition))
        return (
          <TemplateComposition
            key={composition.compositionId}
            composition={composition}
            activeKey={activeKey}
            sortedVariables={sortedVariables}
            setSortedVariables={setSortedVariables}
            handleOnChange={handleOnChange}
            customHeader={customHeader}
            isActive={activePanel === composition.compositionId}
          />
        );

      return (
        <Collapse
          key={composition.compositionId}
          activeKey={activeKey}
          onChange={() => handleOnChange(composition.compositionId)}
          bordered={false}
          className={styles.collapseContainer}
        >
          <Collapse.Panel
            key={composition.compositionId}
            header={customHeader(
              composition,
              activePanel === composition.compositionId,
            )}
            showArrow={false}
          >
            {isMediaCompositionFromFeed(composition) && (
              <MediaFromFeedInput
                key={`${composition.compositionId}-feed`}
                compositionId={composition.compositionId}
              />
            )}
            {isMediaCompositionFromCAM(composition) && (
              <MediaFromCamInput composition={composition} />
            )}
          </Collapse.Panel>
        </Collapse>
      );
    });
  };

  const handleOnBeforeCapture = (item: BeforeCapture) => {
    const { draggableId } = item;
    if (currentCompositionId !== draggableId) {
      setCurrentCompositionId(undefined);
    }
  };
  const handleDragEnd = (droppedItem: DropResult) => {
    setCurrentCompositionId(
      compositions[droppedItem?.destination?.index ?? 0].compositionId,
    );
  };

  return (
    <div className={styles.compVariablesContainer}>
      <div className={styles.dropdownContainer}>
        <AddMediaDropdown />
      </div>
      {(isFetchingAudioFiles || isFetchingMediaCols) && <Spin />}
      {!isFetchingAudioFiles &&
        videoCompositionAudioFilesEnabled &&
        audioFiles && (
          <div className={styles.audioContainer}>
            {audioFiles.map(audioFile => (
              <AudioCollapseSection
                key={audioFile.id + "_collapseSection"}
                idKey={audioFile.id}
                activeKey={activeAudioKey}
                handleOnChange={() => handleAudioOnChange(audioFile.id)}
                isActive={currentAudioIndex === audioFile.id}
                title={
                  audioFile.type === MediaSubtype.CAM
                    ? "Audio track from CAM"
                    : "Audio track from feed column or URL"
                }
              >
                {audioFile.type === MediaSubtype.CAM ? (
                  <AudioFromCam audioFile={audioFile} />
                ) : (
                  <AudioFromFeed audioFile={audioFile} />
                )}
              </AudioCollapseSection>
            ))}
          </div>
        )}
      <div>
        {!isFetchingMediaCols && (
          <DraggableList
            listItems={createDisplayVariables(compositions)}
            originalListItems={compositions}
            updateOriginalListItems={setCompositions}
            handleOnBeforeCapture={handleOnBeforeCapture}
            handleDragEnd={handleDragEnd}
          />
        )}
      </div>
      {compositions.length > 0 && <TotalDuration duration={totalDuration} />}
    </div>
  );
};
