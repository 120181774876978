import { observer } from "mobx-react-lite";
import { useCropHandler } from "screens/designStudio/designStudioV2/hooks/useCropHandler";
import { ButtonContainer } from "../containers/ButtonContainer";
import { StoreType } from "polotno/model/store";

type CropProps = {
  store: StoreType;
};

const Crop = observer(({ store }: CropProps) => {
  const { handleCrop } = useCropHandler(store);

  return (
    <>
      <ButtonContainer
        size="small"
        onClick={handleCrop}
        sx={{
          minWidth: 2,
        }}
      >
        Crop
      </ButtonContainer>
    </>
  );
});

export default Crop;
