import { DeleteOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import { ManualField } from "shared/types/marketingMaterials";
import { ManualGroupLayer } from "shared/types/salesEnablement";
import LayerInput from "./LayerInput";
import uuid from "uuid";
import { useMaterialFormInstance } from "screens/adLibrary/marketingMaterials/MarketingMaterialsProvider";
type Props = {
  layer: ManualGroupLayer;
  disabled?: boolean;
  value?: ManualField;
  onChange?: (field: ManualField) => void;
};

const ManualLayerInput = ({ layer, disabled, value, onChange }: Props) => {
  const { maxItems = 6 } = layer;
  const form = useMaterialFormInstance();
  if (!value || !onChange || !Array.isArray(value.fieldsData)) return null;

  const onDeleteFieldset = (id: string, fieldValue: ManualField) => {
    const newSets = fieldValue.fieldsData.filter(field => field.id !== id);
    onChange({
      ...fieldValue,
      fieldsData: newSets,
    });
    form.setFieldsValue({
      fields: { [layer.id]: { ...fieldValue, fieldsData: newSets } },
    });
  };

  const isImageManualLayer = layer.subLayers.some(subLayer => {
    return subLayer.type === "image";
  });

  return (
    <div>
      {value.fieldsData.map(({ id }, index) => (
        <div key={id} style={{ paddingTop: "1em" }}>
          <Typography.Text>
            {layer.name} {index + 1}{" "}
            {index > 0 && !disabled ? (
              <DeleteOutlined
                style={{ marginLeft: "1em" }}
                onClick={() => onDeleteFieldset(id, value)}
              />
            ) : null}
          </Typography.Text>
          {layer.subLayers.map(subLayer => (
            <ManualLayerRow
              subLayer={subLayer}
              key={`${id}-${subLayer.id}`}
              layerId={layer.id}
              index={index.toString()}
              disabled={disabled}
            />
          ))}
        </div>
      ))}

      {!isImageManualLayer && value.fieldsData.length < maxItems && (
        <Button
          type="link"
          onClick={() => {
            onChange({
              ...value,
              fieldsData: [...value.fieldsData, { id: uuid(), field: {} }],
            });
          }}
          disabled={disabled}
        >
          + Add Another
          {layer.name.replace("List", " ")}
        </Button>
      )}
    </div>
  );
};

const ManualLayerRow = ({
  subLayer,
  layerId,
  index,
  disabled,
}: {
  subLayer: ManualGroupLayer["subLayers"][number];
  layerId: string;
  index: string;
  disabled?: boolean;
}) => {
  return (
    <LayerInput
      layer={subLayer}
      subLayerId={[
        "fields",
        layerId,
        "fieldsData",
        index,
        "field",
        subLayer.id,
      ]}
      disabled={disabled}
      language="en"
      isSubLayer
    />
  );
};

export default ManualLayerInput;
