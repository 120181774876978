import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { intersection, keyBy } from "lodash";
import { OptionData } from "rc-select/lib/interface";
import { CAMConfig } from "shared/components/media";
import { LIST_MODE_FILE_TYPES } from "shared/components/media/constants";
import {
  handleMissingThumbnails,
  handleResponseWithErrors,
  mapKeywordToOptionValue,
  prepareHeaders,
  sanitizeExtension,
  withExtraFields,
  withSearchDefaults,
  withoutEmptyValues,
  withoutExtraFields,
} from "./utils";

const mediaApi = createApi({
  reducerPath: "mediaApi",
  baseQuery: fetchBaseQuery({
    baseUrl: CAMConfig.wdBaseUrl,
    prepareHeaders,
  }),
  tagTypes: [
    "currentUser",
    "assets",
    "foldersList",
    "keywords",
    "schema",
    "searchResults",
    "autoCompleteResults",
    "embedLinks",
  ],
  endpoints: builder => ({
    getCurrentUser: builder.query<WDUser, void>({
      query() {
        return `users/me`;
      },
      keepUnusedDataFor: Infinity,
      providesTags: ["currentUser"],
    }),
    getFolders: builder.query<any[], string | undefined>({
      query(id) {
        return id
          ? `folders/${id}/assets?limit=1000&sortby=datecreated&sortdir=desc&types=${LIST_MODE_FILE_TYPES}`
          : `folders`;
      },
      transformResponse(res: any) {
        if (Array.isArray(res)) {
          return res;
        }
        const { folders = [], items = [] } = res;

        return folders.concat(handleMissingThumbnails(items));
      },
      providesTags: ["assets"],
    }),
    getAssetsMeta: builder.query<WDMetaDataXMPRes, string[]>({
      query(ids) {
        return `assets/${ids}/metadatas/xmp`;
      },
      transformResponse(res: any, meta: any, [id]: string[]) {
        return withoutEmptyValues(
          withoutExtraFields("type" in res ? { [id]: res } : res),
        );
      },
      keepUnusedDataFor: 0,
    }),
    getMetaSchema: builder.query<MGSchemas, void>({
      query() {
        return "metadataschemas?full=1";
      },
      transformResponse(schemas: WDSchemas) {
        const xmpschema = schemas.xmpschema
          .filter(schema => schema.status === "active")
          .map(schema => {
            return {
              ...schema,
              field: schema.field.toLowerCase(),
            };
          });

        return {
          ...schemas,
          xmpschema,
          xmp: keyBy(xmpschema, "field"),
        };
      },
      providesTags: ["schema"],
    }),
    getAssetEmbedlinks: builder.query<WDEmbedLinksResponse, string>({
      query(assetId) {
        return `assets/${assetId}/embedlinks`;
      },
      providesTags: ["embedLinks"],
    }),
    getKeywords: builder.query<OptionData[], void>({
      query() {
        return CAMConfig.keywordsUrl;
      },
      transformResponse(data: (WDKeyword | ExternalKeyword)[]) {
        return data.map(mapKeywordToOptionValue);
      },
      keepUnusedDataFor: Infinity,
      providesTags: ["keywords"],
    }),
    getFoldersList: builder.query<{ [key: string]: WDFolder }, string[]>({
      query(ids) {
        return `folders/list?ids=${ids}`;
      },
      transformResponse(list: any[]) {
        return list.reduce((acc, item) => ({ ...acc, [item.id]: item }), {});
      },
      providesTags: ["foldersList"],
    }),
    searchAssets: builder.query<WDSearchResult, MGSearchPayload>({
      async queryFn({ withMeta, payload }, api, extra, baseQuery) {
        const res = (await baseQuery({
          url: "v2/search",
          method: "post",
          body: JSON.stringify(withSearchDefaults(payload)),
        })) as { data: WDSearchResult };

        if (withMeta && res.data.items.length) {
          const ids = res.data.items.map(asset => asset.id);
          const { data } = (await baseQuery(`assets/${ids}/metadatas/xmp`)) as {
            data: WDMetaDataXMPRes | WDMetaDataXMP;
          };
          // Normalize API response, which is different when only 1 item.
          const meta = "type" in data ? { [ids[0]]: data } : data;

          // Oh nooo, a mutation!
          // This mutation is contained, totally valid.
          res.data.items = res.data.items.map(asset => {
            return {
              ...asset,
              meta: meta[+asset.id],
            };
          });
        }

        return res;
      },
      keepUnusedDataFor: 0,
      providesTags: ["searchResults"],
    }),
    getAutocompleteOptions: builder.query<
      WDAutoCompleteResult,
      WDAutoCompletePayload
    >({
      query(payload) {
        return `v2/auto-complete?${new URLSearchParams(payload)}`;
      },
      providesTags: ["autoCompleteResults"],
    }),
    getAssetsList: builder.mutation<WDAsset[], string[]>({
      query(ids) {
        return {
          url: `assets/list?ids=${ids}`,
        };
      },
    }),
    getAssetPermissions: builder.query<string[], string>({
      query(ids) {
        return `folders/list?ids=${ids}`;
      },
      transformResponse(folders: WDFolder[]) {
        const permsByFolder = folders.map(f => f.permissions.assets);

        return intersection(...permsByFolder);
      },
      keepUnusedDataFor: Infinity,
    }),
    getOnlyFolders: builder.mutation<WDFolder[], number>({
      query(id) {
        return id ? `folders/${id}/assets` : `folders`;
      },
      transformResponse(res: any) {
        return Array.isArray(res) ? res : res.folders || [];
      },
    }),
    searchAssetsMutation: builder.mutation<WDSearchResult, MGSearchPayload>({
      query({ payload }) {
        return {
          url: "v2/search",
          method: "post",
          body: JSON.stringify(withSearchDefaults(payload)),
        };
      },
    }),
    createFolder: builder.mutation({
      query(body) {
        return {
          url: "folders",
          method: "post",
          body: JSON.stringify(body),
        };
      },
      invalidatesTags: ["assets"],
    }),
    deleteAssets: builder.mutation<null, string[]>({
      async queryFn(ids, api, extra, baseQuery) {
        const reqs = ids.map(id =>
          baseQuery({
            url: `assets/${id}`,
            method: "delete",
          }),
        );

        const res = await Promise.all(reqs);
        return handleResponseWithErrors(res, null);
      },
      invalidatesTags: ["assets"],
    }),
    deleteFolder: builder.mutation<null, string>({
      query(id) {
        return {
          url: `folders/${id}`,
          method: "delete",
        };
      },
      invalidatesTags: ["assets"],
    }),
    updateAssetMeta: builder.mutation<null, { payload: any[] }>({
      async queryFn({ payload }, api, extra, baseQuery) {
        const reqs = payload.map(({ id, ...payload }) =>
          baseQuery({
            url: `assets/${id}/metadatas/xmp`,
            method: "put",
            body: JSON.stringify(withExtraFields(payload)),
          }),
        );

        const res = await Promise.all(reqs);
        return handleResponseWithErrors(res, null);
      },
      invalidatesTags: ["assets"],
    }),
    updateFolderProps: builder.mutation<
      any,
      {
        props: MGUpdateFolderPayload;
      }
    >({
      query({ props }) {
        const { id, ...rest } = props;
        return {
          url: `folders/${id}`,
          method: "put",
          body: JSON.stringify(rest),
        };
      },
      invalidatesTags: ["foldersList", "assets"],
    }),
    updateAssetProps: builder.mutation<
      WDAsset[],
      {
        props: MGUpdateAssetPayload[];
      }
    >({
      async queryFn({ props }, api, extra, baseQuery) {
        const reqs = props.map(({ id, ...rest }) =>
          baseQuery({
            url: `assets/${id}`,
            method: "put",
            body: JSON.stringify(sanitizeExtension(rest)),
          }),
        );

        const res = await Promise.all(reqs);
        return handleResponseWithErrors<WDAsset[]>(
          res,
          res.map(({ data }) => data as WDAsset),
        );
      },
      invalidatesTags: ["assets"],
    }),
    setExpiration: builder.mutation<null, { payload: MGExpirationPayload[] }>({
      async queryFn({ payload }, api, extra, baseQuery) {
        const reqs = payload.map(({ id, timestamp }) => {
          return baseQuery({
            url: `assets/${id}/expiration`,
            method: "put",
            body: JSON.stringify({
              expire_unix_timestamp: timestamp,
              notes: null,
              notify: false,
            }),
          });
        });

        const res = await Promise.all(reqs);
        return handleResponseWithErrors(res, null);
      },
      invalidatesTags: ["assets", "searchResults"],
    }),
    removeExpiration: builder.mutation<
      null,
      { payload: MGExpirationPayload[] }
    >({
      async queryFn({ payload }, api, extra, baseQuery) {
        const reqs = payload.map(({ id }) => {
          return baseQuery({
            url: `assets/${id}/expiration`,
            method: "delete",
          });
        });

        const res = await Promise.all(reqs);
        return handleResponseWithErrors(res, null);
      },
      invalidatesTags: ["assets", "searchResults"],
    }),
    publishAssets: builder.mutation<null, string[]>({
      query(ids) {
        return {
          url: `brandconnect/brandportals/${CAMConfig.wdBrandPortalId}/assets/`,
          method: "put",
          body: JSON.stringify({
            asset_ids: ids,
          }),
        };
      },
      invalidatesTags: ["searchResults"],
    }),
    unpublishAssets: builder.mutation<null, string[]>({
      query(ids) {
        return {
          url: `brandconnect/brandportals/${CAMConfig.wdBrandPortalId}/assets/`,
          method: "delete",
          body: JSON.stringify({
            asset_ids: ids,
          }),
        };
      },
      invalidatesTags: ["searchResults"],
    }),
    downloadAsset: builder.mutation<BinaryType, string>({
      query(id) {
        return {
          url: `assets/${id}/download`,
          cache: "no-cache",
          responseHandler: async response => {
            if (!response.ok) {
              return await response.json();
            }
            return window.URL.createObjectURL(await response.blob());
          },
        };
      },
    }),
  }),
});

export default mediaApi;
export const {
  useGetCurrentUserQuery,
  useLazyGetCurrentUserQuery,
  useGetFoldersQuery,
  useGetFoldersListQuery,
  useGetAssetsMetaQuery,
  useCreateFolderMutation,
  useDeleteAssetsMutation,
  useDeleteFolderMutation,
  useUpdateAssetMetaMutation,
  useUpdateAssetPropsMutation,
  useUpdateFolderPropsMutation,
  useGetMetaSchemaQuery,
  useGetAssetsListMutation,
  useSearchAssetsQuery,
  useGetKeywordsQuery,
  useSearchAssetsMutationMutation,
  useGetAutocompleteOptionsQuery,
  useLazyGetAssetEmbedlinksQuery,
  useLazyGetAssetPermissionsQuery,
  useGetOnlyFoldersMutation,
  useSetExpirationMutation,
  useRemoveExpirationMutation,
  usePublishAssetsMutation,
  useUnpublishAssetsMutation,
  useDownloadAssetMutation,
  util,
} = mediaApi;
