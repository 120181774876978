import { Stack, Typography } from "@mui/material";
import CloudUploadRounded from "@mui/icons-material/CloudUploadRounded";
import { BigButton } from "./BigButton";
import { StoreType } from "polotno/model/store";
import { useLocalUploadFiles } from "./upload/useLocalUploadFiles";
import { useElements } from "../useElements";
import { StyledVisuallyHiddenInput } from "./upload/StyledVisuallyHiddenInput";
import { observer } from "mobx-react-lite";
import { RecentImagesList } from "./RecentImagesList";

type UploadPanelProps = {
  store: StoreType;
};

export const UploadPanel = observer(({ store }: UploadPanelProps) => {
  const { addImage } = useElements(store);
  const {
    onDrop,
    recentImages,
    onChange,
    removeRecentImage,
    RECENT_FILES_KEY,
  } = useLocalUploadFiles(addImage);

  return (
    <Stack spacing={2}>
      <BigButton
        component="label"
        onDrop={onDrop}
        onDragOver={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <CloudUploadRounded />
        <Typography
          variant="subtitle2"
          fontWeight="fontWeightBold"
          textTransform="uppercase"
        >
          Upload
        </Typography>
        <Typography variant="body2" sx={{ color: "text.primary" }}>
          or drag and drop the file(s) here
        </Typography>
        <StyledVisuallyHiddenInput type="file" multiple onChange={onChange} />
      </BigButton>
      <RecentImagesList
        files={recentImages}
        onClick={addImage}
        onRemove={image => removeRecentImage(RECENT_FILES_KEY, image)}
      />
    </Stack>
  );
});
