import { SaveRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEditorDrawerContext } from "../../../editorDrawerContext/useEditorDrawerContext";

export const Save = observer(() => {
  const { save } = useEditorDrawerContext();

  return (
    <IconButton size="small" onClick={() => save()}>
      <SaveRounded fontSize="small" />
    </IconButton>
  );
});
