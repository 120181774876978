import {
  Stack,
  ImageList,
  ImageListItem,
  TextField,
  Box,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { useStockMedia } from "./stock/useStockMedia";
import { StoreType } from "polotno/model/store";
import { observer } from "mobx-react-lite";
import { useElements } from "../useElements";
import { useInView } from "react-intersection-observer";
import { useDebounce } from "shared/hooks/useDebounce";
import { SearchRounded } from "@mui/icons-material";
import { AttributionLinks } from "./stock/AttributionLinks";
import { StockMediaImage } from "shared/types/stock";
import { useDragToCanvas } from "../useDragToCanvas";
import { ImageObjectParams } from "screens/designStudio/designStudioV2/editorDrawer/editorDrawerContext/helpers.store";

type StockPanelProps = {
  store: StoreType;
  search: string;
  setSearch: (search: string) => void;
};

export const StockPanel = observer(
  ({ store, search, setSearch }: StockPanelProps) => {
    const { addImage } = useElements(store);
    const debouncedSearch = useDebounce(search, 300);
    const {
      flatData,
      fetchNextPage,
      hasNextPage,
      isLoading,
      trackStockMediaDownload,
      refetch,
    } = useStockMedia(debouncedSearch);
    const { getDragEndPosition } = useDragToCanvas();

    const insertStockImage = (
      img: StockMediaImage,
      params?: Omit<ImageObjectParams, "src" | "width" | "height">,
    ) => {
      trackStockMediaDownload(img.links.download_location);
      addImage({
        src: img.urls.full,
        width: img.width,
        height: img.height,
        ...params,
      });
    };

    const { ref } = useInView({
      threshold: 0,
      onChange: inView => {
        if (inView && hasNextPage) fetchNextPage();
      },
    });

    return (
      <Stack alignItems="stretch" spacing={2}>
        <Box
          sx={{
            position: "sticky",
            top: 57,
            zIndex: 1,
            backgroundColor: "background.paper",
          }}
        >
          <TextField
            fullWidth
            size="small"
            label="Search"
            autoFocus={true}
            value={search}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => refetch()}>
                  <SearchRounded sx={{ color: "action.active" }} />
                </IconButton>
              ),
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSearch(event.target.value);
            }}
          />
        </Box>
        <ImageList variant="masonry" cols={2} gap={8}>
          {flatData?.map(stockImg => (
            <ImageListItem
              key={stockImg.id}
              onClick={() => insertStockImage(stockImg)}
              onDragEnd={() =>
                insertStockImage(stockImg, getDragEndPosition(stockImg))
              }
              sx={{
                cursor: "pointer",
                position: "relative",
                "& img": { borderRadius: 1 },
                "&:hover": {
                  backgroundColor: "action.hover",
                  "& .img": { filter: "brightness(0.2)" },
                  "& .imgAttribution": {
                    visibility: "visible",
                  },
                },
              }}
            >
              <Box
                component="img"
                className="img"
                srcSet={stockImg.urls.thumb}
                src={stockImg.urls.thumb}
                loading="lazy"
                sx={{
                  minHeight: 30,
                  width: 1,
                  "&:hover": { filter: "brightness(0.3)" },
                  transition: "filter 0.2s ease-in-out",
                }}
              />
              <AttributionLinks
                name={stockImg.user.name}
                imgLink={stockImg.links.html}
                userLink={stockImg.user.links.html}
              />
            </ImageListItem>
          ))}
        </ImageList>
        <span ref={ref} style={{ height: 50 }} />
        {isLoading && <CircularProgress sx={{ alignSelf: "center" }} />}
      </Stack>
    );
  },
);
