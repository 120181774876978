import { Typography, Table, Alert } from "antd";
import styles from "./ExportDrawer.module.scss";
import { useFeedContext } from "../shared/contexts/FeedContext";
import { useOffPlatformExportContext } from "./OffPlatformExportContext";
import {
  PortalOptionMatchTableData,
  HandleSelectPortalOptions,
  RowType,
} from "./types";
import { getColumns } from "./MapDataColumns";
import { getMapDataTableData } from "./utils/getMapDataTableData";

export const MapData = () => {
  const { columns: feedColumns } = useFeedContext();
  const { portalOptionMatches, setPortalOptionMatches } =
    useOffPlatformExportContext();

  const tableData = getMapDataTableData(feedColumns);

  const handleSelectPortalOption: HandleSelectPortalOptions = (
    portalOption,
    rowData,
  ) => {
    setPortalOptionMatches(previousPortalOptionMatches => ({
      ...previousPortalOptionMatches,
      [rowData.key]: { portalOption: portalOption || null },
    }));
  };

  const columns = getColumns(portalOptionMatches, handleSelectPortalOption);

  const getTableRowClassName = (record: PortalOptionMatchTableData) => {
    const portalOptionMatch = portalOptionMatches[record.key]?.portalOption;
    const isMediaRow = record.rowType === RowType.Media;

    if (portalOptionMatch == null && !isMediaRow) {
      return styles.highlightRow;
    }
    return "";
  };

  return (
    <div className={styles.matchColumnsWrapper}>
      <div className={styles.alertContainer}>
        <Typography.Text>
          Please set how the assets will appear in the portal using the
          selectboxes on the right.
        </Typography.Text>
        <Alert
          message="Content from unmapped columns will be added to the spreadsheet, but won't show in the portal."
          type="warning"
          showIcon
        />
      </div>
      <Table
        size="small"
        columns={columns}
        dataSource={tableData}
        pagination={false}
        bordered
        rowClassName={getTableRowClassName}
      />
    </div>
  );
};
