import CommonFilterDrawer from "shared/components/filterDrawer/FilterDrawer";
import { FilterFields } from "shared/components/filterDrawer/useFilterFields";
import { states } from "shared/constants/states";
import { languageOptions } from "utils/helpers.salesEnablement";
import { uniq, uniqBy } from "lodash";
import { useMemo } from "react";
import { useNormalizedDisclosures } from "shared/hooks/admin/useDisclosures";
import { useProducts } from "shared/hooks/admin/useProducts";
import { NormalizedDisclosure } from "shared/types/salesEnablement";

type Props = {
  onClose: () => void;
  open: boolean;
};

const DisclosureFilterDrawer = ({ open, onClose }: Props) => {
  const { normalizedDisclosures } = useNormalizedDisclosures();
  const { products } = useProducts();

  const productOptions = useMemo(
    () =>
      uniqBy(
        normalizedDisclosures.flatMap(({ products }) => products),
        "id",
      ).map(product => ({
        key: product.id,
        value: `${product.id}/${product.name}`,
        label: product.name,
      })),
    [normalizedDisclosures],
  );

  const productsTypes = useMemo(() => {
    const uniqProductsTypes = uniq(
      products.map(({ productType }) => productType),
    );

    return (
      uniqProductsTypes.map(productType => ({
        key: productType,
        value: productType,
        label: productType,
      })) ?? []
    );
  }, [products]);

  const locationOptions = useMemo(
    () =>
      uniq(normalizedDisclosures.flatMap(({ locations }) => locations)).map(
        location => ({
          key: location,
          value: location,
          label: `${location} - ${
            states[location as keyof typeof states].name
          }`,
        }),
      ),
    [normalizedDisclosures],
  );

  const filterFields: FilterFields<NormalizedDisclosure> = [
    {
      title: "Name",
      key: "name",
      type: "text",
    },
    {
      title: "Product",
      key: "products",
      type: "select",
      options: productOptions,
    },

    {
      title: "Product Type",
      key: "productTypes",
      type: "select",
      options: productsTypes,
    },
    {
      title: "Language",
      key: "availableLanguages",
      type: "select",
      options: languageOptions,
    },
    {
      title: "States",
      key: "locations",
      type: "select",
      options: locationOptions,
    },
  ];

  return (
    <CommonFilterDrawer
      data={normalizedDisclosures}
      filterKey="salesenablement-disclosures"
      fields={filterFields}
      open={open}
      onClose={onClose}
    />
  );
};

export default DisclosureFilterDrawer;
