import { DataListURLProvider } from "shared/components/dataListURL/dataListURLContext";
import LineItemTable from "./lineItems/LineItemTable";
import { fields } from "./lineItems/lineItemFields";
import { Outlet } from "react-router-dom";

const LineItems = () => {
  return (
    <DataListURLProvider fields={fields}>
      <LineItemTable />
      <Outlet />
    </DataListURLProvider>
  );
};

export default LineItems;
