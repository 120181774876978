import { useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import {
  LayerElement,
  StoreType,
} from "screens/designStudio/designStudioV2/types";
import { reorderLayers } from "./helpers";

export const useLayers = (store: StoreType) => {
  const [selectedLayerId, setSelectedLayerId] = useState<string | null>(null);
  const layers = store.activePage?.children.slice().reverse() ?? [];

  const onDoubleClick = (layerId: string) => {
    setSelectedLayerId(layerId);
  };

  const onClick = (layerId: string) => {
    store.selectElements([layerId]);
  };

  const onClickOutside = (layer: LayerElement) => {
    if (selectedLayerId !== layer.id) return;
    setSelectedLayerId(null);
  };

  const onKeyDown = (
    layer: LayerElement,
    cleanUp: () => void,
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (selectedLayerId !== layer.id) return;
    if (e.key === "Enter") {
      layer.set({ name: e.currentTarget.value });
      setSelectedLayerId(null);
    }

    if (e.key === "Escape") {
      cleanUp();
      setSelectedLayerId(layer.id);
    }
  };

  const onDragEnd = (e: DropResult) => {
    const { source, destination } = e;

    if (!source || !destination) return;

    const toIndex = destination.index;
    const fromIndex = source.index;

    if (e.type === "child-layer") {
      const parentLayer = store.getElementById(destination.droppableId);
      const childLayersCopy = parentLayer.children.slice().reverse();

      reorderLayers(parentLayer, childLayersCopy, fromIndex, toIndex);
    }

    if (e.type === "layer") {
      const layersCopy = [...layers];

      reorderLayers(store.activePage, layersCopy, fromIndex, toIndex);
    }
  };

  return {
    layers,
    selectedLayerId,
    onDoubleClick,
    onClick,
    onClickOutside,
    onKeyDown,
    onDragEnd,
  };
};
