import { uniq } from "lodash";
import { useLazyGetAssetPermissionsQuery } from "../../../redux/media/media.api";

export const useFetchPermissions = () => {
  const [getAssetPermissions] = useLazyGetAssetPermissionsQuery();

  return async (folderIds: string[], action: WDPermsAsset | WDPermsFolder) => {
    const ids = uniq(folderIds).join(",");
    const perms = await getAssetPermissions(ids, true).unwrap();

    return perms.includes(action);
  };
};
