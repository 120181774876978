import { Link } from "react-router-dom";
import { ROUTES } from "screens/designStudio/templates/constants";
import styles from "./FromTemplate.module.scss";
import { useGetTemplateErrorStatusFn } from "screens/designStudio/templates/errorStatus.utils";
import type { Template } from "shared/types/salesEnablement";
import { Tooltip } from "antd";

type Props = Readonly<{
  template: Template;
}>;

function FromTemplate({ template }: Props) {
  const errorStatus = useGetTemplateErrorStatusFn()(template);

  return (
    <div className={styles.fromTemplate}>
      <p className={styles.fromTemplateText}>From the template&nbsp;</p>
      <Tooltip
        visible={errorStatus ? undefined : false}
        title={errorStatus?.message}
      >
        {errorStatus ? (
          <strong>{template.name}</strong>
        ) : (
          <Link target="_blank" to={ROUTES.edit(template.id)}>
            {template.name}
          </Link>
        )}
      </Tooltip>
    </div>
  );
}

export default FromTemplate;
