import { CloseOutlined, SettingOutlined } from "@ant-design/icons";
import { Popover, Spin, Tabs, Button } from "antd";
import { useEffect, useState } from "react";
import OfferImagePopoverSearchContent from "./OfferImagePopoverSearchContent";
import OfferImagePopoverUploadContent from "./OfferImagePopoverUploadContent";
import {
  uploadJellybeanUpdate,
  getJellybeanImages,
} from "redux/assetBuilder/assetBuilder.slice";
import "./OfferImagePopover.scss";
import { useAppShallowEqualSelector } from "shared/hooks/useAppSelector";
import { AppState } from "redux/store";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { delay } from "utils/helpers";
import { CAMTableSelect, CAM_ENABLED } from "shared/components/media";
import { pick } from "lodash";

type Props = {
  enable?: boolean; // This prop will take preference over "editMode" from redux.
};

type Handlers = {
  setNewJellybean: (jellybean: string) => void;
  setEditOfferData?: () => void;
};
type IOfferImagePopover = Props & Handlers;

const OfferImagePopoverV2 = ({
  setNewJellybean,
  setEditOfferData,
  enable,
}: IOfferImagePopover) => {
  const dispatch = useAppDispatch();
  const { offerData, editMode } = useAppShallowEqualSelector(
    ({ assetBuilder }: AppState) => ({
      offerData: assetBuilder.offerData,
      editMode: assetBuilder.editMode,
    }),
  );
  const [openPopover, togglePopover] = useState(false);
  const [showUploadContent, toggleUploadContent] = useState(false);
  const [undoButtonActive, toggleUndoButtonActive] = useState(false);
  const [okButtonActive, toggleOkButtonActive] = useState(false);
  const [buttonActionString, setButtonActionString] = useState("");

  useEffect(() => {
    dispatch(
      uploadJellybeanUpdate({
        value: false,
        img: "",
      }),
    );
  }, [dispatch]);
  useEffect(() => {
    if (openPopover && getJellybeanImages) {
      dispatch(getJellybeanImages(offerData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openPopover]);

  const { TabPane } = Tabs;

  const enabled = enable ?? editMode;

  return (
    <div className="popOver" onClick={e => e.stopPropagation()}>
      <Popover
        trigger={"click"}
        placement="rightTop"
        visible={openPopover}
        autoAdjustOverflow={false}
        getPopupContainer={getTriggerNode}
        content={
          <div className="offer-image-popover">
            <Spin spinning={false} size="large">
              <div className="close-pop-over">
                <CloseOutlined
                  onClick={() => {
                    toggleUndoButtonActive(false);
                    toggleUploadContent(false);
                    togglePopover(!openPopover);
                  }}
                />
              </div>
              <Tabs
                size="small"
                onChange={() => {
                  toggleUndoButtonActive(false);
                  toggleOkButtonActive(false);
                }}
              >
                {CAM_ENABLED && (
                  <TabPane tab="Select From DAM" key="webDAMSelect">
                    <CAMTableSelect
                      initParams={pick(offerData, [
                        "year",
                        "make",
                        "model",
                        ...(offerData?.trim?.trim() ? ["trim"] : []),
                      ])}
                      onSelect={setNewJellybean}
                    />
                  </TabPane>
                )}
                <TabPane tab="Select From Gallery" key="JellybeanSelect">
                  <OfferImagePopoverSearchContent
                    setNewJellybean={setNewJellybean}
                    showUploadContent={showUploadContent}
                    toggleUploadContent={() => {
                      toggleUploadContent(!showUploadContent);
                    }}
                    buttonActionString={buttonActionString}
                    toggleOkButton={() => {
                      toggleOkButtonActive(true);
                    }}
                    toggleUndoButton={() => {
                      toggleUndoButtonActive(true);
                    }}
                  ></OfferImagePopoverSearchContent>
                </TabPane>
                <TabPane tab="Upload From Computer" key="JellybeanUploader">
                  <OfferImagePopoverUploadContent
                    setNewJellybean={setNewJellybean}
                    showUploadContent={showUploadContent}
                    toggleUploadContent={() => {
                      toggleUploadContent(!showUploadContent);
                    }}
                    togglePopover={() => {
                      togglePopover(!openPopover);
                    }}
                  ></OfferImagePopoverUploadContent>
                </TabPane>
              </Tabs>
            </Spin>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: "2em",
              }}
            >
              {undoButtonActive && (
                <Button
                  onClick={async () => {
                    setButtonActionString("UNDO");
                    togglePopover(!openPopover);
                    await delay(500);
                    setButtonActionString("");
                    toggleUndoButtonActive(false);
                    toggleUploadContent(false);
                  }}
                  style={{
                    marginRight: "5px",
                    visibility: undoButtonActive ? "visible" : "hidden",
                  }}
                  type="primary"
                  danger
                  ghost
                >
                  Undo
                </Button>
              )}

              {okButtonActive && (
                <Button
                  onClick={async () => {
                    setButtonActionString("OK");
                    togglePopover(!openPopover);
                    await delay(500);
                    setButtonActionString("");
                    toggleUndoButtonActive(false);
                    toggleUploadContent(false);
                  }}
                  style={{
                    color: "white",
                    visibility: okButtonActive ? "visible" : "hidden",
                  }}
                  type="primary"
                >
                  Ok
                </Button>
              )}
            </div>
          </div>
        }
      >
        <SettingOutlined
          style={{
            opacity: enabled ? 1 : 0,
          }}
          onClick={() => {
            if (enabled) {
              setEditOfferData?.();
              togglePopover(!openPopover);
            }
          }}
        />
      </Popover>
    </div>
  );
};

const getTriggerNode = (node: HTMLElement) => {
  return node.parentNode?.parentNode as HTMLElement;
};

export default OfferImagePopoverV2;
