import { Tag } from "shared/types/salesEnablement";
import { Tag as AntdTag } from "antd";

type Props = {
  tags: Tag[];
};
const Tags = (props: Props) => {
  const { tags } = props;
  const isEmpty = !tags || tags.length === 0;

  const [t1, t2, ...rest] = tags ?? [];
  return (
    <>
      {!isEmpty
        ? [t1, t2]
            .filter(t => t)
            .map(t => <AntdTag key={t.id}>{t.name}</AntdTag>)
        : null}
      {rest.length > 0 && <AntdTag>+{rest.length}</AntdTag>}
    </>
  );
};

export default Tags;
