import { useState, FC } from "react";

import { Button, Checkbox, message, Select, Tooltip } from "antd";
import { IDisclosure } from "shared/types/legalLingo";

import { useFetchDisclosures } from "shared/hooks/legalLingo/useFetchDisclosures";
import { IAssetBuildInstance, IAssetInstance } from "shared/types/assetBuilder";
import { lowerCase } from "lodash";

import { LoadingOutlined, ReloadOutlined } from "@ant-design/icons";
import { useRefreshDisclosures } from "./useRefreshDisclosures";

import styles from "./DisclosureSelection.module.scss";

interface DisclosureSelectionProps {
  onDisclosureChange: (disclosure: {
    allSizes?: IDisclosure;
    currentSize?: IDisclosure;
    selectedAsset?: IDisclosure;
  }) => void;
  assetBuildInstance?: IAssetBuildInstance;
  assetInstances: Record<string, Record<string, IAssetBuildInstance[]>>;
  updateAssetInstances: (
    instance: IAssetInstance | IAssetInstance[],
    assetType: string,
    size: string,
    order?: number,
  ) => void;
}

const defaultDisclosure = {
  id: 0,
  name: "",
  vin: true,
  condition: [],
  location: [],
  oem: [],
  store: [],
  disclosures: [],
};

export const DisclosureSelection: FC<DisclosureSelectionProps> = ({
  onDisclosureChange,
  assetBuildInstance,
  assetInstances,
  updateAssetInstances,
}) => {
  const { disclosure = {} } = assetBuildInstance || {};
  const { disclosures, refetch, isFetching } = useFetchDisclosures();
  const { refresh } = useRefreshDisclosures({ updateAssetInstances });
  const [disclosureKey, disclosureValue] =
    Object.entries(disclosure)?.[0] || [];
  const [selectedKey, setSelectedKey] = useState<string | null>(disclosureKey);
  const [selectedDisclosure, setSelectedDisclosure] = useState<IDisclosure>(
    disclosureValue || defaultDisclosure,
  );
  const [prevInstance, setPrevInstance] = useState(assetBuildInstance);

  if (prevInstance !== assetBuildInstance) {
    setSelectedKey(disclosureKey);
    setSelectedDisclosure(disclosureValue);
    setPrevInstance(assetBuildInstance);
  }
  const refreshDisclosures = async () => {
    try {
      const result = await refetch();
      refresh(assetInstances, result.data || []);
    } catch (_) {
      message.error("Failed to refresh disclosures");
    }
  };

  return (
    <div className={styles.host}>
      <section>
        <Select
          placeholder={"Select a Custom Disclosure"}
          value={selectedDisclosure?.name}
          showSearch
          onChange={disc => {
            const foundDisc = disclosures.find(
              fullDiscs => fullDiscs.name === disc,
            );
            if (foundDisc) {
              setSelectedDisclosure(foundDisc);
              setSelectedKey(null);
            }
          }}
        >
          {disclosures.map((disc: IDisclosure) => {
            return (
              <Select.Option key={disc.name} value={disc.name}>
                {disc.name}
              </Select.Option>
            );
          })}
        </Select>
        <Tooltip title="Refresh Disclosure">
          <Button onClick={refreshDisclosures}>
            {isFetching ? <LoadingOutlined /> : <ReloadOutlined />}
          </Button>
        </Tooltip>
      </section>

      {["allSizesInThisCategory", "currentSize", "selectedAsset"]
        .slice(0, 12)
        .map(con => (
          <Checkbox
            key={con}
            value={con}
            checked={selectedKey === con}
            disabled={!selectedDisclosure?.disclosures?.length}
            style={{
              paddingBottom: "1em",
              paddingRight: "2em",
              marginLeft: "0",
            }}
            onChange={discOption => {
              const target = discOption.target;
              const { value, checked } = target;
              const disclosureToSet = checked
                ? selectedDisclosure
                : defaultDisclosure;
              setSelectedKey(checked ? con : null);
              onDisclosureChange({ [value]: { ...disclosureToSet } });
            }}
          >
            {`Add to ${lowerCase(con)}`}
          </Checkbox>
        ))}
    </div>
  );
};
