import { CloseCircleFilled } from "@ant-design/icons";
import { Alert, Spin } from "antd";
import { orderBy } from "lodash";
import { useCallback } from "react";
import { TComposition, TTemplateComposition } from "shared/types/assetExporter";
import { AssetBatchesTemplateCompositionProvider } from "../../shared/contexts/AssetBatchesTemplateCompositionContext";
import { useAssetBatchesContext } from "../../shared/contexts/AssetBatchesContext";
import { mediaTypes } from "../../shared/types";
import { ImageVariableInput } from "../panelVariables/ImageVariableInput";
import { LogoVariableInput } from "../panelVariables/LogoVariableInput";
import styles from "../panelVariables/PanelVariables.module.scss";
import { getValueMappingKey } from "../utils";
import { isTemplateComposition } from "../../shared/contexts/AssetBatchesContext.utils";

const MatchSuccess = () => {
  return (
    <Alert
      className={styles.success}
      message="We selected the columns for you!"
      description="Please review the selected columns before clicking next."
      type="success"
      showIcon
      icon={<CloseCircleFilled />}
    />
  );
};

const MatchError = ({ conflictCount }: { conflictCount: number }) => {
  return (
    <Alert
      className={styles.error}
      message={`We couldnt find ${conflictCount} of the columns`}
      description="Please select them below"
      type="error"
      showIcon
      icon={<CloseCircleFilled />}
    />
  );
};

export const PanelMediaVariables = () => {
  const {
    mediaColumns,
    variables: allVariables,
    compositions,
    isFetchingMediaCols,
    showMediaAlert,
  } = useAssetBatchesContext();
  const getSortedVariables = useCallback(
    (composition: TComposition) => {
      const variables = allVariables.find(
        vars => vars.compositionId === composition.compositionId,
      )?.variables;
      if (!variables) return [];
      return [
        ...variables.filter(
          variable => variable.variable === "Theme Background",
        ),
        ...orderBy(
          variables,
          variable =>
            `${variable.type}-${variable.variable}-${variable.logoData?.logoType}`,
          ["asc"],
        ).filter(
          variable =>
            mediaTypes.includes(variable.type) &&
            variable.variable !== "Theme Background",
        ),
      ];
    },
    [allVariables],
  );

  const getVariablesWithoutMatch = (composition: TTemplateComposition) => {
    return Object.values(composition.variables).filter(
      mapping =>
        !mapping.value &&
        mediaTypes
          .filter(type => type !== "logo")
          .includes(mapping.variable.type),
    ).length;
  };

  if (!compositions[0])
    return <span>First select template to start editing elements</span>;

  return (
    <div className={styles.main}>
      {isFetchingMediaCols && <Spin />}
      {!isFetchingMediaCols && mediaColumns && (
        <>
          {compositions.map(
            composition =>
              isTemplateComposition(composition) && (
                <AssetBatchesTemplateCompositionProvider
                  key={composition.compositionId}
                  editingComposition={composition}
                >
                  {showMediaAlert &&
                    (getVariablesWithoutMatch(composition) > 0 ? (
                      <MatchError
                        conflictCount={getVariablesWithoutMatch(composition)}
                      />
                    ) : (
                      <MatchSuccess />
                    ))}
                  {getSortedVariables(composition).map(variable =>
                    variable.type === "logo" ? (
                      <LogoVariableInput
                        key={`${composition.compositionId}-${variable.id}-${variable.variable}`}
                        variable={variable}
                        valueMapping={
                          composition.variables[getValueMappingKey(variable)]
                        }
                        mappingKey={getValueMappingKey(variable)}
                      />
                    ) : (
                      <ImageVariableInput
                        key={`${composition.compositionId}-${variable.id}-${variable.variable}`}
                        compositionId={composition.compositionId}
                        variable={variable}
                        valueMapping={
                          composition.variables[getValueMappingKey(variable)]
                        }
                        mappingKey={getValueMappingKey(variable)}
                      />
                    ),
                  )}
                </AssetBatchesTemplateCompositionProvider>
              ),
          )}
        </>
      )}
    </div>
  );
};
