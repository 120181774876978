import { useState } from "react";
import {
  Stack,
  TextField,
  Box,
  FormHelperText,
  Typography,
} from "@mui/material";
import { useDebounce } from "shared/hooks/useDebounce";
import { StoreType } from "polotno/model/store";
import { observer } from "mobx-react-lite";
import useGenerateImages from "shared/hooks/genAI/useGenerateImages";
import { useDesignStudioEditor } from "screens/designStudio/designStudioLayout/designStudioTable/hooks/useDesignStudioEditor";
import { AutoAwesomeRounded } from "@mui/icons-material";
import useGetSession from "shared/hooks/genAI/useGetSession";
import { LoadingButton } from "@mui/lab";
import { useElements } from "./useElements";
import { ImageList } from "./media/ImageList";

type AiImagesPanelProps = {
  store: StoreType;
};

export const AiImages = observer(({ store }: AiImagesPanelProps) => {
  const [prompt, setPrompt] = useState("");
  const { sessionId } = useDesignStudioEditor();
  const debouncedPrompt = useDebounce(prompt, 300);
  const { addImage } = useElements(store);
  const sessionIdToUse = sessionId ?? "";
  const { generateImages } = useGenerateImages({
    sessionId: sessionIdToUse,
    prompt: debouncedPrompt,
    height: 512,
    width: 512,
    nSamples: 4,
  });

  const { session } = useGetSession({
    id: sessionIdToUse,
  });

  const images = session?.state?.images?.map(src => ({
    src,
    width: session?.state?.width,
    height: session?.state?.height,
  }));

  return (
    <Stack alignItems="stretch" spacing={2}>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          pt: 2,
          backgroundColor: "background.paper",
        }}
      >
        <TextField
          fullWidth
          size="small"
          multiline
          placeholder="Photograph of a city skyline, with skyscrapers, from ground level, extremely detailed"
          value={prompt}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setPrompt(event.target.value);
          }}
        />
        <FormHelperText sx={{ px: 1.75 }}>
          Describe the image you want to generate.
        </FormHelperText>
      </Box>
      <LoadingButton
        onClick={generateImages}
        size="small"
        startIcon={<AutoAwesomeRounded fontSize="small" />}
        variant="contained"
        sx={{
          width: 1,
          mt: 4,
        }}
        loading={session?.status === "pending"}
      >
        <Typography variant="inherit">Generate</Typography>
      </LoadingButton>
      {images && <ImageList images={images} onClick={addImage} />}
    </Stack>
  );
});
