import { getAdValidations } from "screens/adLibrary/adImport/adValidationUtils";
import { AdType } from "screens/adLibrary/facebookUtils/types";
import { IAd, ChildCard, IAdWithChildren, ICard } from "shared/types/adLibrary";

export const isChildRecord = (record: IAd) => (record as ChildCard).isChild;
export const isNotChildRecord = (record: IAd) => !isChildRecord(record);
export const adHasChildren = (ad: IAdWithChildren) => !!ad.children?.length;
export const adDoesNotHaveChildren = (ad: IAdWithChildren) =>
  !adHasChildren(ad);

export const updateAdCard = (ad: IAd, childAdCardUpdate: ChildCard): IAd => {
  return {
    ...ad,
    visuals: {
      ...ad.visuals,
      cards: ad.visuals.cards?.map((card, index) =>
        childAdCardUpdate.cardIndex === index
          ? mapAdImportTableRecordToAdCard(card, childAdCardUpdate)
          : card,
      ),
    },
  };
};

export const updateAd = (adUpdate: IAd, ads: IAd[]) => {
  const childAdCardUpdate = (adUpdate as ChildCard).isChild
    ? (adUpdate as ChildCard)
    : null;

  return ads.map(ad => {
    if (!!childAdCardUpdate && ad.id === childAdCardUpdate.parentAdId) {
      return updateAdCard(ad, childAdCardUpdate);
    }
    return ad.id === adUpdate.id ? adUpdate : ad;
  });
};

export const getValidations = (ads: IAd[]) => {
  const adValidations = ads.map(getAdValidations);
  const adIdsWithErrors = adValidations
    .filter(validation => validation.hasErrors)
    .map(validation => validation.adId);
  const adsWithoutErrors = ads
    .filter(ad => !adIdsWithErrors.includes(ad.id))
    .map(ad => ad.id);

  return {
    adIdsWithErrors,
    adsWithoutErrors,
    adValidations,
  };
};

const mapAdImportTableRecordToAdCard = (
  card: ICard,
  childAdCardUpdate: ChildCard,
): ICard => {
  const description = childAdCardUpdate.visuals?.headlineDescription ?? "";
  const destinationUrl = childAdCardUpdate.inputParameters?.destinationUrl;
  const headline = childAdCardUpdate.visuals?.headline ?? "";
  const ctaButtonText = childAdCardUpdate.visuals?.ctaButtonText;
  const thumbnail = childAdCardUpdate.visuals?.thumbnail ?? "";

  return {
    ...card,
    description,
    destinationUrl,
    headline,
    ctaButtonText,
    thumbnail,
    additionalPlacements: card.additionalPlacements?.map(placement => ({
      ...placement,
      description,
      destinationUrl,
      headline,
      ctaButtonText,
      thumbnail,
    })),
  };
};

export const mapAdCardToAdImportTableRecord = (
  ad: IAd,
  card: ICard,
  index: number,
): ChildCard => {
  const id = `${ad.id}_card_${index}`;
  return {
    isChild: true,
    parentAdId: ad.id,
    cardIndex: index,
    id,
    inputParameters: {
      id,
      name: `Card ${index + 1}`,
      destinationUrl: card.destinationUrl,
    },
    createdAt: ad.createdAt,
    updatedAt: ad.updatedAt,
    type: card.videoUrl ? AdType.Video : AdType.Still,
    visuals: {
      id,
      headline: card.headline,
      thumbnail: card.thumbnail,
      videoUrl: card.videoUrl,
      assetId: card.assetId,
      postCopy: "",
      ctaButtonText: card.ctaButtonText,
      headlineDescription: card.description,
      additionalPlacements: card.additionalPlacements,
    },
  };
};
