import { useQuery } from "react-query";
import APIs from "services";
import { Language, Template } from "shared/types/salesEnablement";
import { useAuthedAgentLicensedStates } from "../salesEnablement/useAgentLicensedStates";
import { getResultIfOk } from "services/adLibrary";

export const useFetchTemplates = () => {
  const { isLoadingLicensedStates } = useAuthedAgentLicensedStates();
  const {
    data: templates = [],
    isLoading,
    isError,
  } = useQuery<Template[]>("sales-enablement-templates", async () => {
    try {
      const { result } = getResultIfOk(
        await APIs.services.salesEnablement.getTemplates(),
      );

      return result
        .filter(template => !!template)
        .map(template => ({
          ...template,
          languages: Object.keys(template.files) as Language[],
        }));
    } catch (err) {
      throw err;
    }
  });

  return {
    templates,
    isTemplateLoading: isLoading || isLoadingLicensedStates,
    isError,
  };
};
