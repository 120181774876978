import { Tooltip, Select, Form } from "antd";
import styles from "../../ConditionalFields.module.scss";
import { FormListFieldData } from "antd/lib/form/FormList";

type Props = {
  cond: FormListFieldData;
  disabled: boolean;
  disabledInputTooltip: string | undefined;
};

export const IfInput = ({ cond, disabled, disabledInputTooltip }: Props) => {
  const options = [
    {
      value: "specificProduct",
      label: "Contains specific product",
    },
    {
      value: "productType",
      label: "Contains Product of type",
    },
    {
      value: "location",
      label: "Is set to Location",
    },
  ];
  return (
    <Tooltip title={disabledInputTooltip}>
      <Form.Item
        {...cond}
        className={styles.formItem}
        name={[cond.name, "if"]}
        rules={[
          {
            required: true,
            message: "Please select a condition",
          },
        ]}
      >
        <Select<string>
          placeholder="Please Select"
          disabled={disabled}
          options={options}
          showSearch
          optionFilterProp="label"
        />
      </Form.Item>
    </Tooltip>
  );
};
