import { UploadFile, UploadFileStatus } from "antd/lib/upload/interface";
import {
  TemplateFile,
  Template,
  Language,
  DeliveryMethod,
} from "shared/types/salesEnablement";
import { isWithinDays } from "utils/helpers.date";
import { spreadCSV } from "utils/helpers.array";
import { startCase, intersection, uniq, inRange } from "lodash";
import moment from "moment-timezone";
import { keys } from "shared/types/shared";

export const toUploadFile = (
  file?: TemplateFile,
  status?: UploadFileStatus,
): UploadFile | undefined =>
  file
    ? {
        uid: file.uid ?? "",
        name: file.name ?? "",
        status: (status || file.status) ?? "done",
        url: file.url,
        originFileObj: file.originFileObj,
      }
    : undefined;

export const toTemplateFile = (file: UploadFile): TemplateFile => ({
  uid: file.uid ?? "",
  name: file.name ?? "",
  fileName: file.fileName ?? "",
  url: file.url,
  originFileObj: file.originFileObj,
  status: file.status,
});

/**
 * The "value" can be either [string] or string[].
 * As user selects filters, the "value" comes as string[]. After the user click on "Show Results",
 * the "value" is converted to [string] and passed to the filter function.
 * Ex) { "salesCycle": ['prospecting', 'employeeEnrollment']} ---> { "salesCycle": ['prospecting,employeeEnrollment']}
 * This happens because we are normalizing filter values in "useDataListURLFilters.ts". Check "normalizeArray" function in return block.
 */
export const onFilterTemplate =
  (
    key: Extract<
      keyof Template,
      | "name"
      | "status"
      | "expirationDate"
      | "description"
      | "audience"
      | "locations"
      | "salesCycle"
      | "materialType"
      | "createdBy"
    >,
  ) =>
  (value: string[], record: Template) => {
    // We will convert normalized "value" to string[] and deal with string[] only.
    if (!Array.isArray(value))
      throw new Error(
        'The "value" in "onFilterTemplate" must be an array type.',
      );

    const val = containsQueryString(value) ? value[0].split(",") : value;
    const recVal = record[key];
    if (!recVal) return false;

    switch (key) {
      case "status":
        if (typeof recVal !== "string")
          throw new Error(
            `"status" must be a string type. Found ${typeof recVal}.`,
          );
        return val.some(v => {
          if (v.includes("new"))
            return isWithinDays(record.createdAt || Date.now(), 30);
          else return recVal.toLowerCase() === v.toLowerCase();
        });

      case "expirationDate":
        // For "expirationDate", only one value comes in "value" array and its format is '1711598400000 1714363200000'.
        // Also, expirationDate from "record" must be type of string. Ex) "2024-06-01T03:59:59.999Z"
        if (typeof recVal !== "string")
          throw new Error(
            `"expirationDate" must be a string type. Found ${typeof recVal}.`,
          );

        const [dateVal] = value;
        if (dateVal) {
          const expirationDate = +moment(recVal);
          const [startDate, endDate] = dateVal.split(" ");
          return inRange(expirationDate, +startDate, +endDate);
        }
        return false;

      case "name":
      case "description":
      case "createdBy":
        if (typeof recVal !== "string")
          throw new Error(
            `"${key}" must be a string type. Found ${typeof recVal}.`,
          );

        return val.some(v => {
          return recVal.toLowerCase().includes(v.toLowerCase());
        });

      case "audience":
      case "locations":
      case "salesCycle":
      case "materialType":
      default:
        return false;
    }
  };

/**
 * Same logic follows as "onFilterTemplate". Refer to that function.
 */
export const onDynamicFilterTemplate =
  (key: "languages" | "customizable" | "deliveryMethods") =>
  (value: string[], record: Template) => {
    if (!Array.isArray(value))
      throw new Error(
        'The "value" in "onFilterTemplate" must be an array type.',
      );

    const val = containsQueryString(value) ? value[0].split(",") : value;

    switch (key) {
      case "languages":
        const langs = keys<Partial<Record<Language, TemplateFile>>>(
          record.files,
        );
        return !!intersection(val, langs).length;
      case "customizable":
        const recVal = record.customizable
          ? ["customizable"]
          : ["nonCustomizable"];
        return val.some(v => {
          return recVal.some((r: string) =>
            r
              .replace(/\s/g, "")
              .toLowerCase()
              .includes(v.replace(/(\s|[^a-zA-Z ])/g, "").toLowerCase()),
          );
        });
      case "deliveryMethods":
        const methods = uniq(
          Object.values(record["files"])
            .map(file => file.deliveryMethods)
            .flat(),
        );
        return !!intersection(spreadCSV(value), methods).length;
      default:
        return false;
    }
  };

const containsQueryString = (value: string[]) =>
  value.length === 1 && value[0].split(",").length > 0;

export const capitalizeCSV = (value: string) => {
  return value
    .split(",")
    .map(v => startCase(v))
    .join(", ");
};

export const deliveryMethods: {
  label: string;
  value: DeliveryMethod;
  key: string;
}[] = [
  {
    label: "Download",
    value: "download",
    key: "download",
  },
  {
    label: "Send by Email",
    value: "email",
    key: "email",
  },
];

export const mapCSV = (value: string) => {
  return value.split(",").join(", ");
};

export const mapLabelToValue = (
  label: string,
  options: { key: string; value: string; label: string }[],
) => {
  return options.find(option => option.label === label)?.value || "";
};

export const mapValueToLabel = (
  value: string,
  options: { key: string; value: string; label: string }[],
) => {
  return options.find(option => option.value === value)?.label || "";
};

export function arrayToCsv(data: any[][]) {
  return data
    .map(
      row =>
        row
          .map(item =>
            JSON.stringify(item, (key: string, value: any) =>
              !value ? "" : value,
            ),
          ) // convert every value to String
          .map(v => v.replaceAll('"', '""')) // escape double quotes
          .map(v => `"${v}"`) // quote it
          .join(","), // comma-separated
    )
    .join("\r\n"); // rows starting on new lines
}

export function downloadBlob(
  content: string,
  filename: string,
  contentType: string,
) {
  // Create a blob
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);

  // Create a link to download it
  const pom = document.createElement("a");
  pom.href = url;
  pom.setAttribute("download", filename);
  pom.click();
}

export const downloadFile = async (fileUrl: string, fileName: string) => {
  const response = await fetch(fileUrl);
  const blob = await response.blob();
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  link.click();
  URL.revokeObjectURL(url);
};
