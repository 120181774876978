import { message } from "antd";
import { uniq } from "lodash";
import { interpolate } from "remotion";
import { loadFontFromBrand } from "screens/designStudio/editor/propertySectionV2/propertyRow/ManageText.utils";
import { COLUMN_ASSET_BATCHES } from "shared/constants/assetExporter";
import { customFonts } from "shared/constants/canvas";
import { CompositionType, TComposition } from "shared/types/assetExporter";
import { Font } from "shared/types/brandsAccounts";
import { IDimension, TExtendedTextbox } from "shared/types/designStudio";
import { loadFont } from "utils/helpers";
import { checkIfItIsVideoSrc } from "utils/helpers.adEngine";
import { getVideoDurationForAdEngine } from "../assetBatchDrawer/utils";
import { MINIMUM_DURATION } from "./constants";
import { isTColumnValue, isTextbox } from "./validators";

export const getColumnTitle = (key: string) => {
  if (key === "order_number") return "";
  if (key === COLUMN_ASSET_BATCHES) return "Asset batches applied";

  return key;
};

export const loadFontsFromTemplate = async (json: any, brandFonts: Font[]) => {
  if (!json) return;
  const { objects } = json;
  if (!objects || !Array.isArray(objects)) return;

  const textboxes = objects.filter(isTextbox);
  const fontFamilies = getFontFamiliesForCanvas(textboxes);

  const brandResults = await Promise.all(
    brandFonts.map(font => loadFontFromBrand(font.name, font.url)),
  );
  const results = await Promise.all(
    fontFamilies.map(fontFamily => loadFont(fontFamily)),
  );
  if ([...brandResults, ...results].some(result => !result)) {
    message.error("Failed to load all required fonts.");
  }
};

const getFontFamiliesForCanvas = (textboxes: TExtendedTextbox[]) =>
  textboxes
    .reduce<string[]>((acc, textbox) => {
      const { styles, fontFamily } = textbox;
      const fontFamilies = Object.values<any>(styles).reduce<string[]>(
        (styleAcc, charStyleObj) => {
          const fontFamiliesFromcharStyle = Object.values<any>(
            charStyleObj,
          ).map(style => style.fontFamily) as string[];
          return [...styleAcc, ...uniq(fontFamiliesFromcharStyle)];
        },
        [],
      );

      return [
        ...acc,
        ...(uniq(
          [...fontFamilies, fontFamily].filter(fontFam => !!fontFam),
        ) as string[]),
      ];
    }, [])
    .filter(fontFamily => customFonts.includes(fontFamily));

export const fadeOut = (
  frame: number,
  from: number,
  durationInFrame: number,
): number => {
  return interpolate(frame, [from, durationInFrame], [0.5, 0], {
    extrapolateRight: "clamp",
  });
};

export const fadeIn = (
  frame: number,
  from: number,
  durationInFrame: number,
): number => {
  return interpolate(frame, [from, durationInFrame], [0.3, 1], {
    extrapolateRight: "clamp",
  });
};

export const scaleHTMLCanvas = (
  canvas: HTMLCanvasElement | null,
  original: IDimension,
  target: IDimension,
) => {
  if (canvas) {
    // Set the canvas dimensions to the target dimensions
    canvas.width = target.width;
    canvas.height = target.height;

    // Get the context and scale it
    const ctx = canvas.getContext("2d");
    if (ctx) {
      ctx.scale(target.width / original.width, target.height / original.height);
    }
  }
};

export const calculateTotalDuration = (compositions: TComposition[]) => {
  return (
    compositions
      .flatMap(comp => comp.duration)
      .reduce((acc, curr) => acc + curr, 0) ?? 0
  );
};

export const getDefaultNamingRule = () => {
  const feedName = `<span class="mention" data-index="0" data-denotation-char="" data-value="Feed name"><span contenteditable="false"><span class="ql-mention-denotation-char"></span><span style="padding-left: 5px; padding-right: 5px;">Feed name</span></span></span>`;
  const assetBatchName = `<span class="mention" data-index="1" data-denotation-char="" data-value="Asset batch name"><span contenteditable="false"><span class="ql-mention-denotation-char"></span><span style="padding-left: 5px; padding-right: 5px;">Asset batch name</span></span></span>`;
  const numericalOrder = `<span class="mention" data-index="2" data-denotation-char="" data-value="Numerical order"><span contenteditable="false"><span class="ql-mention-denotation-char"></span><span style="padding-left: 5px; padding-right: 5px;">Numerical order</span></span></span>`;
  return `<p>${feedName}${assetBatchName}${numericalOrder}</p>`;
};

export const getNamingTextWithPattern = (pattern: string) => {
  const variables = pattern.split("{").map(part => {
    return part.split("}")[0];
  });

  let convertedHtml = pattern;
  variables.forEach(variable => {
    convertedHtml = convertedHtml.replace(
      new RegExp(`{${variable}}`, "g"),
      `<span class="mention" data-index="0" data-denotation-char="" data-value="${variable}"><span contenteditable="false"><span class="ql-mention-denotation-char"></span><span style="padding-left: 5px; padding-right: 5px;">${variable}</span></span></span>`,
    );
  });
  return convertedHtml;
};

export const removeSpecialCharacters = (text: string) => {
  // Specify the characters to be removed within the square brackets in the regular expression
  return text.replace(/[{}|""^~\[\].,`\\]/g, "");
};

const replaceWhitespaceWithDash = (text: string) => {
  if (!text) return "";
  return text.replace(/\s/g, "-");
};

export const getNameForRow = (
  row: any,
  pattern: string,
  feedName: string,
  assetBatchName: string,
  index: number,
  ext?: string,
) => {
  let finalText = pattern;
  const numericalOrder = `${("00" + (index + 1)).slice(-3)}`;
  Object.keys(row).forEach(key => {
    const value = row[key];
    const regex = new RegExp(`\{${key}\}`, "g");
    finalText = finalText.replace(regex, `_${value}_`);
  });
  finalText = finalText
    .replace(/\{Feed name\}/g, `_${replaceWhitespaceWithDash(feedName)}_`)
    .replace(
      /\{Asset batch name\}/g,
      `_${replaceWhitespaceWithDash(assetBatchName)}_`,
    )
    .replace(/\{Numerical order\}/g, `_${numericalOrder}_`)
    .replace(/@/g, "");
  if (finalText === "") return "";
  if (finalText.startsWith("-") || finalText.startsWith("_"))
    finalText = finalText.slice(1);
  finalText = replaceWhitespaceWithDash(finalText);
  finalText = finalText
    .replace(/__/g, "_")
    .replace(/-_/g, "_")
    .replace(/_-/g, "_");
  if (finalText.endsWith("-") || finalText.endsWith("_"))
    finalText = finalText.slice(0, -1);
  finalText = removeSpecialCharacters(finalText);

  if (!ext) return finalText;
  return `${finalText}.${ext}`;
};

export const getReplacedHoveredValue = (
  hoveredValue: string,
  row: any,
  feedName: string,
  assetBatchName: string,
  index: number,
) => {
  if (!hoveredValue) return "";
  if (hoveredValue === "Feed name") return replaceWhitespaceWithDash(feedName);
  if (hoveredValue === "Asset batch name")
    return replaceWhitespaceWithDash(assetBatchName);
  if (hoveredValue === "Numerical order")
    return `${("00" + (index + 1)).slice(-3)}`;
  return replaceWhitespaceWithDash(row[hoveredValue]);
};

export const replaceHtmlWithNamingPattern = (value: string) => {
  return value
    .replace(/[^\x00-\x7F]/g, "")
    .replace(/<p>/g, "")
    .replace(/<\/p>/g, "")
    .replace(/<br>/g, "")
    .replace(/<([^/<>]+)>/g, "{")
    .replace(/<\/([^/<>]+)>/g, "}")
    .replace(/{}/g, "")
    .replace(/\{\{/g, "")
    .replace(/\}\}/g, "");
};

export const getMaxDurationFromSrcAndColumns = async (
  url: string,
  column: string | undefined,
  rows: any[],
) => {
  let maxDuration = 0;
  let containsVideo = false;

  const { isVideo, src } = await checkIfItIsVideoSrc(url);

  if (isVideo) {
    containsVideo = true;
    const videoDuration = await getVideoDurationForAdEngine(src);
    if (maxDuration === 0 || (videoDuration < maxDuration && maxDuration !== 0))
      maxDuration = videoDuration;
  } else if (column) {
    await Promise.all(
      rows.map(async row => {
        const { isVideo: isRowVideo, src: rowSrc } = await checkIfItIsVideoSrc(
          row?.[column],
        );
        if (!isRowVideo) return;
        containsVideo = true;
        const colVideoDuration = await getVideoDurationForAdEngine(rowSrc);
        if (
          maxDuration === 0 ||
          (colVideoDuration < maxDuration && maxDuration !== 0)
        )
          maxDuration = colVideoDuration;
      }),
    );
  }

  return { maxDuration, containsVideo };
};

export const getMaxDurationForComposition = async (
  composition: TComposition,
  rows: any[],
) => {
  let maxDuration = 0;
  let containsVideo = false;
  if (composition.type === CompositionType.Template) {
    await Promise.all(
      Object.keys(composition.variables).map(async key => {
        const value = composition.variables[key].value;
        const varUrl = typeof value === "string" ? value : "";
        const varColumn = isTColumnValue(value) ? value.column : undefined;

        const { maxDuration: varMaxDuration, containsVideo: varContainsVideo } =
          await getMaxDurationFromSrcAndColumns(varUrl, varColumn, rows);

        if (
          maxDuration === 0 ||
          (varMaxDuration < maxDuration && maxDuration !== 0)
        )
          maxDuration = varMaxDuration;

        containsVideo = containsVideo || varContainsVideo;
      }),
    );
  }

  if (composition.type === CompositionType.Media) {
    const { url, column } = composition;
    const { maxDuration: mediaMaxDuration, containsVideo: mediaContainsVideo } =
      await getMaxDurationFromSrcAndColumns(url, column, rows);

    maxDuration = mediaMaxDuration;
    containsVideo = mediaContainsVideo;
  }

  if (!containsVideo) return 0;
  if (maxDuration < MINIMUM_DURATION) maxDuration = MINIMUM_DURATION;
  return maxDuration;
};
