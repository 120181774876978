import { Typography, TextField } from "@mui/material";
import { FolderAutocomplete } from "./FolderAutocomplete";

type FolderPathSectionProps = {
  folderPath: string;
  onChangeFolderPath: () => void;
  onSelect: (selectedId: string | number, selectedPath: string) => void;
  changeFolder: boolean;
};

export const FolderPathSection = ({
  folderPath,
  onChangeFolderPath,
  onSelect,
  changeFolder,
}: FolderPathSectionProps) => {
  if (!folderPath || changeFolder) {
    return <FolderAutocomplete onSelect={onSelect} />;
  }

  return (
    <TextField
      label="Selected Folder"
      value={folderPath}
      variant="outlined"
      fullWidth
      disabled
      InputProps={{
        endAdornment: (
          <Typography
            onClick={onChangeFolderPath}
            sx={{
              cursor: "pointer",
              color: theme => theme.palette.primary.main,
              textDecoration: "underline",
            }}
          >
            Change
          </Typography>
        ),
      }}
    />
  );
};
