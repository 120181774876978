import { Stack, StackProps } from "@mui/material";

export const StackContainer = (props: StackProps) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="flex-start"
      sx={{ width: "100%" }}
      {...props}
    >
      {props.children}
    </Stack>
  );
};
