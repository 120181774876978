import { Button, ButtonProps } from "@mui/material";

export const PositionButton = (props: ButtonProps) => {
  return (
    <Button
      variant="text"
      sx={{
        textTransform: "none",
        color: "text.primary",
        minWidth: "max-content",
      }}
      {...props}
    >
      {props.children}
    </Button>
  );
};
