import { useDeleteAssetsMutation } from "../../../../redux/media/media.api";
import { message, Modal } from "antd";
import { handleApiError } from "../handleApiError";

export const useDeleteAsset = (record: WDAsset) => {
  const [deleteAsset] = useDeleteAssetsMutation();

  return () =>
    Modal.confirm({
      title: `Delete Asset`,
      okText: "Delete",
      okButtonProps: {
        danger: true,
      },
      content: (
        <p>
          Are you sure you want to delete <strong>{record.name}</strong>?
        </p>
      ),
      async onOk() {
        try {
          await deleteAsset([record.id]).unwrap();
          message.success(`Asset was deleted.`);
        } catch (error) {
          handleApiError(error);
        }
      },
    });
};
