import { Col, Form, Input, Row } from "antd";
import InputTextField from "antd/lib/input/Input";
import { useRef, SetStateAction, Dispatch, useState } from "react";
import { IAccountRecord } from "shared/types/accountManagement";
import {
  IFormValidationAttributes,
  OperationMode,
} from "shared/types/inputValues";
import styles from "./DetailsTab.module.scss";
import { UploadLogos } from "screens/brandsAccountsManagement/common/UploadLogos";
import { ILogos } from "shared/types/brandsAccounts";
import { useDetailsAccountValidations } from "shared/hooks/brandsAccountsManagement/useDetailsAccountValidations";
import { CodeSnippet } from "./CodeSnippet";
import { Address } from "./Address";
import FormInput from "./FormInput";
import { FormInstance } from "antd/es/form/Form";
import Locations from "./Locations";

interface DetailsTabProps {
  accountToEdit: IAccountRecord;
  mode: OperationMode;
  form: FormInstance<IAccountRecord>;
  disabled: boolean;
  logos: ILogos;
  setLogos: Dispatch<SetStateAction<ILogos>>;
  setAccountToEdit: (accountToEdit: IAccountRecord) => void;
  onRemoveLogo?: (url: string, fieldKey: keyof ILogos) => void;
  setRequiredFields: Dispatch<SetStateAction<boolean>>;
}

export const DetailsTab = ({
  accountToEdit,
  logos,
  mode,
  form,
  disabled,
  setLogos,
  onRemoveLogo,
  setAccountToEdit,
  setRequiredFields,
}: DetailsTabProps) => {
  const accountNameFieldRef = useRef<InputTextField>(null);
  const accountCodeFieldRef = useRef<InputTextField>(null);
  const accountUrlFieldRef = useRef<InputTextField>(null);
  const accountBrandFieldRef = useRef<InputTextField>(null);

  const defaultValidationAttributes = {
    timeoutId: undefined,
    validationStatus: "success",
    hasFeedback: undefined,
    feedbackMessage: "",
    required: true,
  } satisfies IFormValidationAttributes;

  const [accountNameValidationAttributes, setAccountNameValidationAttributes] =
    useState<IFormValidationAttributes>(defaultValidationAttributes);

  const [accountCodeValidationAttributes, setAccountCodeValidationAttributes] =
    useState<IFormValidationAttributes>(defaultValidationAttributes);

  const [accountUrlValidationAttributes, setAccountUrlValidationAttributes] =
    useState<IFormValidationAttributes>(defaultValidationAttributes);
  const {
    handelClickAccountName,
    handleClickAccountCode,
    handelClickAccountUrl,
  } = useDetailsAccountValidations({
    accountToEdit,
    mode,
    accountNameValidationAttributes,
    accountCodeValidationAttributes,
    accountUrlValidationAttributes,
    form,
    setAccountCodeValidationAttributes,
    setAccountNameValidationAttributes,
    setAccountUrlValidationAttributes,
    setAccountToEdit,
    setRequiredFields,
  });

  return (
    <Form
      layout="vertical"
      requiredMark={false}
      className={styles.form}
      form={form}
    >
      <Row className={styles.rowContainer}>
        <Col span={12} className={styles.leftColContainer}>
          <Form.Item label={"Brand"}>
            <Input
              data-cy="account-brand-input"
              ref={accountBrandFieldRef}
              key="account-brand-input"
              disabled={true}
              value={accountToEdit.dealerOem}
            />
          </Form.Item>

          <FormInput
            name={mode === "UPDATE" ? "newDealerName" : "dealerName"}
            required={true}
            label={"Account name"}
            validateStatus={accountNameValidationAttributes.validationStatus}
            hasFeedback={accountNameValidationAttributes.hasFeedback}
            feedbackMessage={
              accountNameValidationAttributes.feedbackMessage || undefined
            }
            dataCy="account-name-input"
            ref={accountNameFieldRef}
            key="account-name-input"
            disabled={disabled}
            value={
              mode === "UPDATE"
                ? accountToEdit.newDealerName
                : accountToEdit.dealerName
            }
            onChange={handelClickAccountName}
          />
          <FormInput
            name={"dealerCode"}
            required={true}
            label={"Location name (2-3-3)"}
            validateStatus={accountCodeValidationAttributes.validationStatus}
            hasFeedback={accountCodeValidationAttributes.hasFeedback}
            feedbackMessage={
              accountCodeValidationAttributes.feedbackMessage || undefined
            }
            maxLength={8}
            dataCy="account-code-input"
            ref={accountCodeFieldRef}
            key="account-code-input"
            disabled={disabled}
            value={accountToEdit.dealerCode}
            onChange={handleClickAccountCode}
          />
          <FormInput
            name={"dealerUrl"}
            required={true}
            label="URL"
            validateStatus={accountUrlValidationAttributes.validationStatus}
            hasFeedback={accountUrlValidationAttributes.hasFeedback}
            feedbackMessage={
              accountUrlValidationAttributes.feedbackMessage || undefined
            }
            dataCy="account-url-input"
            ref={accountUrlFieldRef}
            key="account-url-input"
            disabled={disabled}
            value={accountToEdit.dealerUrl}
            onChange={handelClickAccountUrl}
          />

          <Address
            accountToEdit={accountToEdit}
            disabled={disabled}
            mode={mode}
            setAccountToEdit={setAccountToEdit}
          />
          <Locations
            accountToEdit={accountToEdit}
            disabled={disabled}
            setAccountToEdit={setAccountToEdit}
          />
        </Col>
        <Col span={12} className={styles.rightColContainer}>
          <UploadLogos
            logos={logos}
            setLogos={setLogos}
            onRemoveLogo={onRemoveLogo}
          />
          <CodeSnippet accountToEdit={accountToEdit} mode={mode} />
        </Col>
      </Row>
    </Form>
  );
};
