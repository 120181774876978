import { useQuery } from "react-query";
import API from "services";
import {
  CompositionType,
  QUERY_KEY_ASSET_BATCHES,
  TAssetBatch,
} from "shared/types/assetExporter";

const getAssetBatches = async (feedId: string) => {
  const assetBatches = await API.services.assetExporter.getAssetBatch(feedId);

  const mapped = assetBatches.map(assetBatch => {
    const compositions = assetBatch.compositions?.map(comp => ({
      ...comp,
      type: comp.type ?? CompositionType.Template,
    }));

    return { ...assetBatch, compositions } as TAssetBatch;
  });

  return mapped;
};

const useGetAssetBatches = (feedId: string, onSuccess?: () => void) => {
  return useQuery<TAssetBatch[], Error>(
    [QUERY_KEY_ASSET_BATCHES, feedId],
    () => getAssetBatches(feedId),
    {
      enabled: !!feedId,
      onSuccess,
    },
  );
};

export default useGetAssetBatches;
