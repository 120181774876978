import { observer } from "mobx-react-lite";
import { ImageLayerElement } from "screens/designStudio/designStudioV2/types";
import { Slider } from "@mui/material";

type BrightnessProps = {
  element: ImageLayerElement;
};
const Brightness = observer(({ element }: BrightnessProps) => {
  const handleBrightnessChange = (
    _event: Event,
    newValue: number | number[],
  ) => {
    const brightnessValue = Array.isArray(newValue) ? newValue[0] : newValue;
    element.set({ brightnessEnabled: true, brightness: brightnessValue });
  };

  return (
    <Slider
      value={element?.brightness || 0}
      onChange={handleBrightnessChange}
      valueLabelDisplay="auto"
      min={0}
      max={1}
      step={0.01}
      valueLabelFormat={value => (value * 100).toFixed(0)}
    />
  );
});

export default Brightness;
