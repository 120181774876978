import { DeleteOutlined, RightOutlined } from "@ant-design/icons";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import styles from "./AudioCollapseHeader.module.scss";
import { Button } from "antd";
import { useAssetBatchesContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesContext";

type Props = {
  isActive: boolean;
  title: string;
  id: string;
  canDelete?: boolean;
};

export const AudioCollapseHeader = ({
  isActive,
  title,
  id,
  canDelete = true,
}: Props) => {
  const { setAudioFiles } = useAssetBatchesContext();

  return (
    <>
      <RightOutlined
        rotate={isActive ? 90 : 0}
        className={styles.rightArrow}
        width="10px"
        height="10px"
      />
      <VolumeUpIcon className={styles.volumeIcon} />
      <span>{title}</span>
      {canDelete && (
        <div className={styles.buttonContainer}>
          <Button
            type="text"
            size="small"
            icon={<DeleteOutlined />}
            onClick={() => {
              const audioElement = document.getElementById(id);
              audioElement?.remove();
              setAudioFiles(prev =>
                prev.filter(audioFile => audioFile.id !== id),
              );
            }}
          />
        </div>
      )}
    </>
  );
};
