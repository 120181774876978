import { message } from "antd";
import { fabric } from "fabric";
import { keys } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { IDimension, IExtendedFabricObject } from "shared/types/designStudio";
import { checkIfItIsVideoSrc } from "utils/helpers.adEngine";
import { createVideoElement } from "utils/media/utils.input";
import {
  addPaddingAdjustment,
  getImageObjectWithResizeType,
  scaleCanvas,
  setImageForImageVar,
  setImageForVideoVar,
} from "../../assetBatchDrawer/dynamicText/utils.fabric";
import {
  groupMappingsByLineIdx,
  setTextboxDataForCanvas,
} from "../../assetBatchDrawer/dynamicText/utils.variable";
import { parseMappingKey } from "../../assetBatchDrawer/utils";
import {
  renderTextVars,
  setTargetOpacity,
} from "../../shared/components/Preview/utils";
import { useAssetBatchesContext } from "../../shared/contexts/AssetBatchesContext";
import { getImageMappingValue } from "../../shared/contexts/AssetBatchesContext.utils";
import { useAssetBatchesValueMappingContext } from "../../shared/contexts/AssetBatchesValueMappingContext";
import { useVideoStitchingContext } from "../../shared/contexts/VideoStitchingContext";
import { getDummyTarget, getVideoImage } from "../../shared/contexts/utils";
import { useVideoPlayer } from "../../shared/hooks/useVideoPlayer";
import { TValueMapping } from "../../shared/types";
import {
  isCarCut,
  isExtendedFabricObject,
  isFixedVideo,
  isImageRect,
  isLogo,
  isTextbox,
  isVideo,
} from "../../shared/validators";

interface Props {
  compositionId: string;
  width: number;
  height: number;
  fps: number;
  opacity?: number;
  playing: boolean;
  currentFrame: number;
}

export const VideoCanvas = ({
  compositionId,
  width,
  height,
  opacity,
  fps,
  playing,
  currentFrame,
}: Props) => {
  const { selectedRow } = useAssetBatchesValueMappingContext();
  const { backgroundMedias, backgroundResizeTypes, artboard, compositions } =
    useAssetBatchesContext();
  const [backgroundEle, setBackgroundEle] = useState<HTMLVideoElement | null>(
    null,
  );
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const {
    canvasJsons,
    videoAudios,
    backgroundAudios,
    setBackgroundAudios,
    isSeeked,
    setIsSeeked,
  } = useVideoStitchingContext();

  const [canvas, setCanvas] = useState<fabric.Canvas | undefined>();
  const { togglePlaying, updateVideoMuted, updateVideoVolume } = useVideoPlayer(
    {
      canvas,
      currentFrame,
    },
  );
  const currentComposition = compositions.find(
    comp => comp.compositionId === compositionId,
  );

  const currentIndex = compositions.findIndex(
    comp => comp.compositionId === compositionId,
  );

  const endFramesDuration = compositions.reduce((acc, composition, index) => {
    return index <= currentIndex ? acc + composition.duration * fps : acc;
  }, 0); // it is the last frame of the current composition

  const startFrameDuration = compositions.reduce((acc, composition, index) => {
    return index < currentIndex ? acc + composition.duration * fps : acc;
  }, 0); // it is the first frame of the current composition

  useEffect(() => {
    const newCanvas = new fabric.Canvas(`canvas_${compositionId}`);
    const render = async () => {
      newCanvas.loadFromJSON(canvasJsons[compositionId], () => {
        newCanvas.getObjects().forEach(obj => {
          obj.set({ selectable: false, evented: false });
          if (isTextbox(obj)) setTextboxDataForCanvas(obj);
          if (isVideo(obj) || isFixedVideo(obj))
            setImageForVideoVar(
              newCanvas,
              obj,
              obj.customData.videoSrc,
              "fill",
            );
          addPaddingAdjustment(obj, newCanvas);
        });
        const canvasOriginal: IDimension = {
          width: artboard?.width ?? 720,
          height: artboard?.height ?? 720,
        };
        const containerModify: IDimension = {
          width: width ?? 600,
          height: height,
        };

        scaleCanvas(newCanvas, canvasOriginal, containerModify);
        setCanvas(newCanvas);
      });
    };
    render().catch(() => {
      message.error("Failed to render template.");
    });
  }, [
    artboard?.height,
    artboard?.width,
    canvasJsons,
    compositionId,
    currentComposition?.duration,
    currentIndex,
    height,
    setCanvas,
    width,
  ]);

  const renderImageVars = useCallback(
    async (target: IExtendedFabricObject, mappings: TValueMapping[]) => {
      if (
        !canvas ||
        !(isCarCut(target) || isImageRect(target) || isLogo(target))
      )
        return;
      if (target.customType === "theme_background") {
        setTargetOpacity(target, 0);
        return;
      }
      // Image vars are always single mappings
      const mapping = mappings[0];
      const resizeType = mapping.resizeType ?? "fill";
      const rawSrc = getImageMappingValue(mapping, selectedRow);
      if (!rawSrc) return;
      const { isVideo: isVideoExt, src } = await checkIfItIsVideoSrc(rawSrc);
      if (isVideoExt) {
        setImageForVideoVar(canvas, target, src, resizeType);
      } else if (src) setImageForImageVar(canvas, target, src, resizeType);
      setTargetOpacity(target, 0);
    },
    [canvas, selectedRow],
  );

  useEffect(() => {
    if (!canvas) return;

    const valueMappings = currentComposition?.variables ?? {};
    const varsGroupById = keys(valueMappings).reduce<
      Record<string, TValueMapping[]>
    >((acc, mappingKey) => {
      const { variableId } = parseMappingKey(mappingKey);

      const mapping = valueMappings[mappingKey];

      return {
        ...acc,
        [variableId]: [...(acc[variableId] || []), mapping],
      };
    }, {});

    keys(varsGroupById).forEach(id => {
      const mappings = varsGroupById[id];

      const groupedMappings = groupMappingsByLineIdx(mappings);

      const target = canvas
        .getObjects()
        .filter(isExtendedFabricObject)
        .find(obj => obj.name === id);

      if (!target) return;
      renderTextVars(target, groupedMappings, selectedRow, true);
      renderImageVars(target, mappings);
    });

    canvas.renderAll();
  }, [
    canvas,
    compositionId,
    compositions,
    currentComposition?.variables,
    currentComposition?.duration,
    renderImageVars,
    selectedRow,
  ]);

  useEffect(() => {
    if (!backgroundMedias?.[compositionId]) return;

    const { type, src } = backgroundMedias[compositionId]!;
    // remove theme background layer if exists
    const themeBg = canvas
      ?.getObjects()
      .find(
        obj => (obj as IExtendedFabricObject).customType === "theme_background",
      );
    if (themeBg) canvas?.remove(themeBg);

    const dummyTarget = getDummyTarget(canvas);

    // Set video background
    if (["mp4"].includes(type)) {
      const addVideoBackground = async () => {
        const videoEle = await createVideoElement(src, "mp4", false, false);

        const videoImage = getVideoImage(videoEle);

        const resizedVideo = getImageObjectWithResizeType(
          videoImage,
          dummyTarget,
          backgroundResizeTypes?.[compositionId] ?? "fill",
        );
        (resizedVideo as any).contentType = "video";
        canvas?.setBackgroundImage(resizedVideo, () => {
          setBackgroundEle(videoEle);
        });
      };

      addVideoBackground();
      return;
    }

    // Set image background
    fabric.Image.fromURL(src, urlImg => {
      const img = urlImg.set({
        left: 0,
        top: 0,
        selectable: false,
      });

      const resizedImg = getImageObjectWithResizeType(
        img,
        dummyTarget,
        backgroundResizeTypes?.[compositionId] ?? "fill",
      );
      canvas?.setBackgroundImage(resizedImg, () => null);
    });
  }, [
    backgroundMedias,
    backgroundResizeTypes,
    canvas,
    compositionId,
    setBackgroundAudios,
    videoAudios,
  ]);

  useEffect(() => {
    const backgroundAudio = backgroundAudios[compositionId];

    if (
      currentFrame >= endFramesDuration - fps * 0.5 ||
      currentFrame < startFrameDuration
    ) {
      // 8 frames is the threshold to stop the video
      updateVideoMuted(false, compositionId, backgroundAudio);
      if (backgroundAudio) {
        backgroundAudio.pause();
        backgroundAudio.currentTime = 0;
      }
    } else if (
      currentFrame < startFrameDuration ||
      currentFrame > endFramesDuration
    ) {
      togglePlaying(false, 0);
    } else {
      togglePlaying(playing, endFramesDuration / fps); // duration in seconds for the video composition
      updateVideoMuted(playing, compositionId, backgroundAudio);
      updateVideoVolume(playing, compositionId, backgroundAudio);
      if (playing) {
        backgroundEle?.play();
        backgroundAudio?.play();
      } else {
        backgroundEle?.pause();
        if (backgroundAudio) {
          backgroundAudio.pause();
          backgroundAudio.currentTime = currentFrame / fps;
        }
      }
    }
  }, [
    endFramesDuration,
    backgroundEle,
    currentFrame,
    togglePlaying,
    updateVideoVolume,
    updateVideoMuted,
    playing,
    compositionId,
    fps,
    startFrameDuration,
    backgroundAudios,
    videoAudios,
    isSeeked,
    setIsSeeked,
  ]);

  return (
    <canvas
      id={`canvas_${compositionId}`}
      ref={canvasRef}
      style={{ opacity: opacity }}
    />
  );
};
