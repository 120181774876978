import { DragEventHandler, ChangeEventHandler, useMemo } from "react";
import { localFileToDataURL } from "../../../../utils";
import { useEditorDrawerContext } from "screens/designStudio/designStudioV2/editorDrawer/editorDrawerContext/useEditorDrawerContext";
import { ImageListItem } from "../ImageList";

const RECENT_FILES_KEY = "LOCAL";
export const useLocalUploadFiles = (
  onUpload: (files: ImageListItem) => void,
) => {
  const { addRecentImages, removeRecentImage, getRecentImages } =
    useEditorDrawerContext();
  const handleFiles = async (files: FileList) => {
    const newFiles = await Promise.all(
      [...files].map(async file => ({
        src: await localFileToDataURL(file),
      })),
    );

    newFiles.forEach(onUpload);
    newFiles.forEach(file => addRecentImages(RECENT_FILES_KEY, [file]));
  };

  const recentImages = useMemo(
    () => getRecentImages(RECENT_FILES_KEY),
    [getRecentImages],
  );

  const onDrop: DragEventHandler = async e => {
    e.preventDefault();
    if (!e.dataTransfer.files) return;
    handleFiles(e.dataTransfer.files);
  };

  const onChange: ChangeEventHandler<HTMLInputElement> = async e => {
    if (!e.target.files) return;
    handleFiles(e.target.files);
  };

  return {
    onDrop,
    onChange,
    recentImages,
    removeRecentImage,
    RECENT_FILES_KEY,
  };
};
