import {
  LayerElement,
  PolotnoPage,
  GroupLayerElement,
} from "screens/designStudio/designStudioV2/types";

export const toggleLayerVisibility = (layer: LayerElement) =>
  layer.set({ visible: !layer.visible });

export const toggleLayerLock = (layer: LayerElement) =>
  layer.set({
    selectable: !layer.selectable,
    draggable: !layer.draggable,
    contentEditable: !layer.contentEditable,
    styleEditable: !layer.styleEditable,
    resizable: !layer.resizable,
  });

export const deleteLayer = (layer: LayerElement) =>
  layer.store.deleteElements([layer.id]);

export const isLocked = (layer: LayerElement) => !layer.selectable;

export const reorderLayers = (
  obj: PolotnoPage | GroupLayerElement,
  layersCopy: LayerElement[],
  fromIndex: number,
  toIndex: number,
) => {
  const [moved] = layersCopy.splice(fromIndex, 1);
  const layersCopyWithoutMovedLayer = layersCopy.filter(
    layer => layer.id !== moved.id,
  );

  obj.set({
    children: [
      ...layersCopyWithoutMovedLayer.slice(0, toIndex),
      moved,
      ...layersCopyWithoutMovedLayer.slice(toIndex),
    ]
      .slice()
      .reverse(),
  });
};
