import { useFetchMarketingMaterials } from "../adLibrary/marketingMaterials/hooks/useFetchMarketingMaterials";
import { useNavigate } from "react-router-dom";
import { MarketingMaterialsDisplay } from "./MarketingMaterialsDisplay";
import { ROUTES } from "./utils/constants";
import MarketingMaterialDrawer from "screens/adLibrary/marketingMaterials/MarketingMaterialDrawer";
import { Spin } from "antd";
import { MarketingMaterial } from "shared/types/marketingMaterials";
import { useUser } from "shared/hooks/useUser";
import { MAX_DISPLAY } from "./utils/constants";

const sortMaterials = (materials: MarketingMaterial[]): MarketingMaterial[] =>
  [...materials]
    .sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    )
    .slice(0, MAX_DISPLAY);

export const UserMarketingMaterials = () => {
  const navigate = useNavigate();
  const { sub: userId } = useUser();
  const { marketingMaterials, isLoading, isError } =
    useFetchMarketingMaterials();
  const materials = marketingMaterials.filter(
    material => !!material && material.createdBy === userId,
  );

  return (
    <Spin spinning={isLoading}>
      <MarketingMaterialsDisplay
        isError={isError}
        title="My Recent Marketing Materials"
        description="Marketing materials created by you and ready to be delivered"
        materials={materials}
        sortFunction={materials => sortMaterials(materials)}
        handleClick={() => navigate(ROUTES.userMarketingMaterials)}
      />
      <MarketingMaterialDrawer type="edit" />
    </Spin>
  );
};
