import { Collapse, Spin } from "antd";
import React, { FC, useEffect, useMemo, useState } from "react";

import {
  IAssetBuild,
  IAssetBuildInstance,
  IAssetInstance,
  FeedTab,
  SelectedInstance,
} from "shared/types/assetBuilder";
import { assetBuildInstanceToAssetInstance } from "utils/helpers.asset";

import {
  ExtendedObjectType,
  ITemplate,
  LogoEventType,
} from "shared/types/designStudio";
import TemplateCard from "./buildAssetList/TemplateCard";
import ToggleForm from "./buildAssetList/ToggleForm";

import { INewOrder } from "shared/types/newOrders";
import { cloneDeep, isEmpty, isNumber } from "lodash";
import CollapseHeader from "./buildAssetList/CollapseHeader";
import { IConfigurationState } from "shared/types/configuration";
import uuid from "uuid";
import { useAppSelector } from "shared/hooks/useAppSelector";

import styles from "./BuildAssetList.module.scss";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { setSelectedAssetBuildInstance } from "redux/assetBuilder/assetBuilder.slice";

interface IBuildAssetListProps {
  assetType: string;
  config: IConfigurationState["config"];
  instancesBySize: Record<string, IAssetBuildInstance[]>; // { [assetType]: { [size]: IAssetBuildInstance } }
  offers: IAssetBuild["offers"];
  templatesBySize: Record<string, ITemplate[]>;
  selectedTemplateSizes: string[];
  currentOrder?: INewOrder;
  selectedAssetTypeTab: string;
  feedTabs?: FeedTab[];
}

interface IBuildAssetListHandlers {
  updateAssetInstances: (size: string, instances: IAssetInstance[]) => void;
  setSelectedAssetBuildInstance: (args: SelectedInstance[]) => void;
  setShouldUpdateThemeImage: (shouldUpdateThemeImage: boolean) => void;
  onNewAssetInstance: () => void;
}

const BuildAssetList: FC<IBuildAssetListProps & IBuildAssetListHandlers> = ({
  instancesBySize,
  offers,
  templatesBySize,
  updateAssetInstances,
  selectedTemplateSizes,
  currentOrder,
  selectedAssetTypeTab,
  setShouldUpdateThemeImage,
  ...props
}) => {
  const dispatch = useAppDispatch();
  const selectedInstances = useAppSelector(
    state => state.assetBuilder.buildPage.selectedInstance,
  );
  const [activeCollapseKeys, setActiveCollapseKeys] = useState<string[]>(
    Object.keys(templatesBySize).filter(key => {
      return key;
    }),
  );

  const templateSizeList = useMemo(
    () => Object.keys(instancesBySize),
    [instancesBySize],
  );

  type ShowHideObjectsType = {
    [key in ExtendedObjectType | LogoEventType]?: {
      toggle: boolean;
      action: "show" | "hide";
    };
  };

  const defaultValue: { toggle: boolean; action: "show" | "hide" } = {
    toggle: true,
    action: "show",
  };
  const [showHideObjects, setShowHideObjects] = useState<
    Record<string, ShowHideObjectsType>
  >(
    templateSizeList.reduce<Record<string, ShowHideObjectsType>>(
      (acc, size) => {
        acc[size] = {
          stamp: defaultValue,
          logo: defaultValue,
          SALES_EVENT_LOGO: defaultValue,
          BRAND_LOGO: defaultValue,
          ACCOUNT_LOGO: defaultValue,
        };

        return acc;
      },
      {},
    ),
  );

  const defaultHighlighted: ExtendedObjectType[] = useMemo(
    () => ["logo", "stamp", "disclosure"] as ExtendedObjectType[],
    [],
  );
  const highlightObjectTypes = useMemo(
    () => [...defaultHighlighted, "lifestyle"],
    [defaultHighlighted],
  );
  const [highlights, setHighlights] = useState<
    Record<
      string,
      {
        [key in ExtendedObjectType]?: boolean;
      }
    >
  >(
    templateSizeList.reduce((acc, size) => {
      acc[size] = (highlightObjectTypes as ExtendedObjectType[]).reduce(
        (innerAcc, type) => {
          innerAcc[type] = defaultHighlighted.includes(type);

          return innerAcc;
        },

        {} as { [key in ExtendedObjectType]?: boolean },
      );

      return acc;
    }, {} as Record<string, { [key in ExtendedObjectType]?: boolean }>),
  );

  useEffect(() => {
    const missingSizes = templateSizeList.reduce<string[]>(
      (acc, templateSize) => {
        const sizeMissingInHightlights = !Object.keys(highlights).some(
          highlightSize => highlightSize === templateSize,
        );

        if (sizeMissingInHightlights) {
          return acc.concat([templateSize]);
        }

        return acc;
      },
      [],
    );

    if (missingSizes.length === 0) return;

    // we need to add new sizes to highlights and showHideObjects
    const missingHighlights = missingSizes.reduce<
      Record<string, { [key in ExtendedObjectType]?: boolean }>
    >((acc, size) => {
      acc[size] = (highlightObjectTypes as ExtendedObjectType[]).reduce(
        (innerAcc, type) => {
          innerAcc[type] = defaultHighlighted.includes(type);

          return innerAcc;
        },

        {} as { [key in ExtendedObjectType]?: boolean },
      );

      return acc;
    }, {});

    setHighlights(prev => ({
      ...prev,
      ...missingHighlights,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateSizeList]);

  const handleNewInstance = (size: string, index?: number) => {
    const isClone = isNumber(index);
    const instancesFromSize = instancesBySize[size] || [];
    const assetInstances = instancesFromSize.flatMap(buildInstance => {
      const assetInstance = assetBuildInstanceToAssetInstance(buildInstance);
      return assetInstance && !isEmpty(assetInstance.offers)
        ? assetInstance
        : [];
    });
    const newInstance: IAssetInstance = {
      id: uuid.v4(),
      template: null,
      offers: {},
    };
    const order = isClone ? index + 1 : assetInstances.length;
    const clonedAssetInstances = [...assetInstances];
    clonedAssetInstances.splice(order, 0, newInstance);

    const newBuildInstance = () => {
      if (isClone) {
        const clonedBuildInstance = cloneDeep(instancesFromSize[index]);
        clonedBuildInstance.selectedOffer = undefined;
        clonedBuildInstance.id = newInstance.id;

        return clonedBuildInstance;
      } else {
        return { id: newInstance.id };
      }
    };
    updateAssetInstances(size, clonedAssetInstances);
    props.setSelectedAssetBuildInstance([
      {
        instance: newBuildInstance(),
        assetType: selectedAssetTypeTab,
        order,
        size,
      },
    ]);
    setShouldUpdateThemeImage(false);
    props.onNewAssetInstance();
  };

  return (
    <Spin tip="Loading..." spinning={!currentOrder}>
      <Collapse
        className="build-asset-list"
        expandIconPosition="left"
        activeKey={activeCollapseKeys}
      >
        {templateSizeList
          .filter(
            size =>
              !isEmpty(instancesBySize[size]) &&
              selectedTemplateSizes.some(selectedSize => selectedSize === size),
          )
          .map(size => {
            const disableToggles =
              !instancesBySize[size] ||
              instancesBySize[size].filter(
                instance => instance.template && instance.selectedOffer,
              ).length < 1;

            const panelKey = `build-collapse-for-size-${size.replace(
              /\s+/g,
              "",
            )}`;

            return (
              <Collapse.Panel
                key={size}
                className={panelKey}
                id={panelKey}
                header={
                  <CollapseHeader
                    collapseKey={panelKey}
                    size={size}
                    isCollapseExpanded={activeCollapseKeys.includes(size)}
                    assetCount={instancesBySize[size]?.length ?? 0}
                    onCollapseExpand={(shouldExpand, collapseSize) => {
                      setActiveCollapseKeys(
                        shouldExpand
                          ? activeCollapseKeys.concat([size])
                          : activeCollapseKeys.filter(
                              key => key !== collapseSize,
                            ),
                      );
                    }}
                  />
                }
              >
                <div className={styles.sizeCtn}>
                  <ToggleForm
                    initialHighlights={highlights[size]}
                    initialShowHideObjects={showHideObjects[size]}
                    size={size}
                    disableToggles={disableToggles}
                    updateAssetInstances={updateAssetInstances}
                    updateHighlights={(type, size, isHighlighted) => {
                      setHighlights(prevHeights => {
                        return {
                          ...(prevHeights || {}),
                          [size]: {
                            ...(prevHeights[size] || {}),
                            [type]: isHighlighted,
                          },
                        };
                      });
                    }}
                    updateShowHideObjects={(type, size, action, toggle) => {
                      setShowHideObjects({
                        ...(showHideObjects || {}),
                        [size]: {
                          ...(showHideObjects[size] || {}),
                          [type]: {
                            toggle,
                            action,
                          },
                        },
                      });
                    }}
                  />
                  {(instancesBySize[size] || []).map((instance, index) => {
                    return (
                      <div
                        key={instance.id}
                        data-cy="template-card-container"
                        onClick={() => {
                          if (Object.keys(instance).length > 0) {
                            setShouldUpdateThemeImage(false);
                          }
                        }}
                      >
                        <TemplateCard
                          order={index}
                          size={size}
                          assetType={selectedAssetTypeTab}
                          instance={instance}
                          offers={offers}
                          templates={templatesBySize[size]}
                          currentOrder={currentOrder}
                          highlights={highlights[size]}
                          instancesBySize={instancesBySize}
                          cloneAssetInstance={() => {
                            handleNewInstance(size, index);
                          }}
                          updateAssetInstance={newInstance => {
                            const instance =
                              assetBuildInstanceToAssetInstance(newInstance);
                            const assetInstances = instancesBySize[size].map(
                              assetBuildInstanceToAssetInstance,
                            );

                            updateAssetInstances(
                              size,
                              assetInstances.map((assetInstance, idx) =>
                                idx === index ? instance : assetInstance,
                              ),
                            );
                          }}
                          deleteAssetInstance={id => {
                            const assetInstances = instancesBySize[size].map(
                              assetBuildInstanceToAssetInstance,
                            );

                            updateAssetInstances(
                              size,
                              assetInstances.filter(i => i.id !== id),
                            );
                          }}
                          onChangeLogoSubstitution={logoSub => {
                            const ids = selectedInstances.map(
                              i => i.instance.id,
                            );
                            const assetInstances = instancesBySize[size].map(
                              assetBuildInstanceToAssetInstance,
                            );

                            updateAssetInstances(
                              size,
                              assetInstances.map(instance => {
                                if (ids.includes(instance.id)) {
                                  const { logoSubstitutions = [], ...rest } =
                                    instance;

                                  return {
                                    ...rest,
                                    logoSubstitutions: logoSubstitutions
                                      .filter(sub => sub.id !== logoSub.id)
                                      .concat(logoSub),
                                  };
                                }

                                return instance;
                              }),
                            );
                            const updatedInstances = selectedInstances.map(
                              i => {
                                const clonedInstance = structuredClone(i);
                                clonedInstance.instance.logoSubstitutions = [
                                  logoSub,
                                ];
                                return clonedInstance;
                              },
                            );
                            dispatch(
                              setSelectedAssetBuildInstance(updatedInstances),
                            );
                          }}
                          toggleHighlightStamps={(
                            on: boolean,
                            size: string,
                          ) => {
                            setHighlights({
                              ...(highlights || {}),
                              [size]: {
                                stamp: on,
                              },
                            });
                          }}
                          toggleHighlightLogos={(on: boolean, size: string) => {
                            setHighlights({
                              ...(highlights || {}),
                              [size]: {
                                logo: on,
                              },
                            });
                          }}
                          allObjectsShown={showHideObjects[size]}
                          feedTabs={props.feedTabs}
                        />
                      </div>
                    );
                  })}

                  <div
                    data-cy="add-new-instance"
                    className={styles.addNewInstance}
                    onClick={() => {
                      handleNewInstance(size);
                    }}
                  >
                    <span>Add New Instance</span>
                  </div>
                </div>
              </Collapse.Panel>
            );
          })}
      </Collapse>
    </Spin>
  );
};

export default BuildAssetList;
