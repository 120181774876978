import { useAuth0 } from "@auth0/auth0-react";
import { Modal } from "antd";
import { useCallback, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useIsAdmin } from "shared/hooks/useIsAdmin";
import { isEnvVarEquals } from "utils/helpers";

type ModalType = {
  destroy: () => void;
  update: (configUpdate: any) => void;
};

export const AutoLogout = () => {
  const THIRTY_MINS_MS = 1_800_000;
  const SIXTY_SECS_MS = 60_000;
  const ACTIVE_MSG = "trigger-active";
  const LOGOUT_MSG = "trigger-logout";

  const { logout } = useAuth0();
  const isAdmin = useIsAdmin();
  const isAflac = isEnvVarEquals("CLIENT", "aflac");
  const [modal, setModal] = useState<ModalType>();

  const onIdle = () => message(LOGOUT_MSG, true);
  const onPrompt = () => triggerModal();
  const onActive = () => message(ACTIVE_MSG, true);

  const onMessage = (data: unknown) => {
    if (typeof data !== "string" || ![ACTIVE_MSG, LOGOUT_MSG].includes(data))
      return;
    if (data === LOGOUT_MSG) {
      // Logout is done in a timeout because it clears the document object and prevents the
      // postmessage message from reaching the other tabs if run synchronously.
      setTimeout(() => {
        logout({ returnTo: window.location.origin });
      }, 200);
    }
    Modal.destroyAll();
    setModal(undefined);
    reset();
  };

  const { message, reset } = useIdleTimer({
    onIdle,
    onMessage,
    onPrompt,
    onActive,
    promptBeforeIdle: SIXTY_SECS_MS,
    timeout: THIRTY_MINS_MS,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
    disabled: isAdmin || !isAflac,
  });

  const triggerModal = useCallback(() => {
    if (modal) return;
    const infoModal = Modal.info({
      title: "Your session is about to expire",
      content: (
        <div>
          <p>
            You will be logged out due to inactivity in {SIXTY_SECS_MS / 1000}{" "}
            seconds. Confirm below if you wish to continue your session.
          </p>
        </div>
      ),
      okText: "Continue Session",
      onOk: () => message(ACTIVE_MSG, true),
      cancelText: "Log Out",
      onCancel: () => message(LOGOUT_MSG, true),
      okCancel: true,
    });
    setModal(infoModal);
  }, [message, modal]);

  return null;
};
