import { onConfirmPublish } from "../shared/components/publish";
import { useMutation, useQueryClient } from "react-query";
import { Template, TemplateStatus } from "shared/types/salesEnablement";
import API from "services";
import { successNotification } from "shared/components/customNotification/Notification";

export const useToggleTemplateStatus = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (form: Partial<Record<keyof Template, any>>) =>
      API.services.salesEnablement.saveTemplate(form, "edit"),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries("sales-enablement-templates");
      },
    },
  );

  const updateStatus = async ({
    status,
    record,
  }: {
    status: TemplateStatus;
    record: Template;
  }) => {
    await mutation.mutateAsync({
      ...record,
      status,
      statusHistory: [...record.statusHistory, status],
    });

    successNotification({
      messageLabel: `The template has been ${status.toLowerCase()}`,
      size: "big",
    });
  };

  const toggleTemplateStatus = async ({ record }: { record: Template }) => {
    if (!record) return;

    if (record.status === "UNPUBLISHED") {
      return onConfirmPublish({
        onOk: async () => updateStatus({ status: "PUBLISHED", record }),
      });
    }

    await updateStatus({ status: "UNPUBLISHED", record });
  };

  return toggleTemplateStatus;
};
