import { forwardRef, Ref } from "react";
import Alert from "@mui/material/Alert";
import { AlertTitle } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export const SaveAlert = forwardRef<HTMLDivElement>(
  (props, ref: Ref<HTMLDivElement>) => (
    <Alert
      ref={ref}
      icon={<CheckCircleOutlineIcon fontSize="inherit" />}
      severity="success"
      {...props}
    >
      <AlertTitle>Success</AlertTitle>
      Your changes have been successfully saved.
    </Alert>
  ),
);

SaveAlert.displayName = "SaveAlert";
