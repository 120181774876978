import { useState } from "react";
import { QuestionCircleOutlined, ExportOutlined } from "@ant-design/icons";
import { Button, Popover, Divider } from "antd";
import styles from "./HelpPopover.module.scss";
import { NavLink } from "react-router-dom";
import { isFeatureEnabled } from "utils/helpers";

const HelpPopover = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const salesEnablementEnabled = isFeatureEnabled("ENABLE_SALES_ENABLEMENT");
  const content = (
    <div className={styles.helpContent}>
      <div className={styles.helpTitle}>
        <span>How can we help?</span>
        <span onClick={() => setVisible(false)}>X</span>
      </div>
      <Divider
        type="horizontal"
        style={{ marginBottom: "12px", marginTop: "12px" }}
      />
      <div className={styles.helpButtons}>
        {salesEnablementEnabled && (
          <>
            <span style={{ fontSize: "13px" }}>
              Click below to access Sales Marketplace help resources and
              updates.
            </span>
            <Button>
              <ExportOutlined />
              <NavLink
                to="https://constellation-aflac.zendesk.com/hc/en-us/categories/24103472907156-Platform-Guides-Videos"
                target="_blank"
              >
                Guides & FAQs
              </NavLink>
            </Button>
            <Button>
              <ExportOutlined />
              <NavLink
                to="https://constellation-aflac.zendesk.com/hc/en-us/categories/24101855287060-Development-Updates"
                target="_blank"
              >
                Enhancements & Updates
              </NavLink>
            </Button>
          </>
        )}
        {!salesEnablementEnabled && (
          <>
            <Button>
              <NavLink to="/help/ad-library" target="_blank">
                FAQ
              </NavLink>
            </Button>
            <Button>
              <NavLink to="/help/step-0" target="_blank">
                Resources
              </NavLink>
            </Button>
          </>
        )}
      </div>
    </div>
  );

  return (
    <Popover
      trigger="click"
      placement="topRight"
      content={content}
      visible={visible}
      onVisibleChange={setVisible}
      overlayClassName={styles.popoverNoPadding}
    >
      <QuestionCircleOutlined
        style={{
          fontSize: "24px",
          marginRight: ".5rem",
        }}
      />
    </Popover>
  );
};

export default HelpPopover;
