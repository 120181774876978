import { styled } from "@mui/material/styles";
import { ImageListItem } from "@mui/material";

export const StyledImageListItem = styled(ImageListItem)(({ theme }) => ({
  cursor: "pointer",
  position: "relative",
  "& :hover": {
    transition: "filter 0.2s ease-in-out",
    filter: "brightness(0.2)",
  },
  "& img": {
    borderRadius: theme.shape.borderRadius,
  },
}));
