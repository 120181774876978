import { StoreType } from "screens/designStudio/designStudioV2/types";
import { ImageList, ImageListItem, Stack, Typography } from "@mui/material";
import { useElements } from "./useElements";
import { POLOTNO_FIGURES } from "screens/designStudio/designStudioV2/constants";
import { useDragToCanvas } from "./useDragToCanvas";

type ShapeProps = {
  store: StoreType;
};

export const Shapes = ({ store }: ShapeProps) => {
  const { addFigure } = useElements(store);
  const { getDragEndPosition } = useDragToCanvas();

  return (
    <Stack spacing={2} sx={{ pt: 1 }}>
      <Stack spacing={2}>
        <Typography variant="body2">Shapes</Typography>
        <ImageList variant="standard" cols={3} gap={32}>
          {POLOTNO_FIGURES?.map(figure => (
            <ImageListItem
              key={figure.url}
              onClick={() => {
                addFigure(figure);
              }}
            >
              <img
                srcSet={figure.url}
                src={figure.url}
                loading="lazy"
                draggable
                onDragEnd={() =>
                  addFigure({ ...figure, ...getDragEndPosition(figure) })
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Stack>
    </Stack>
  );
};
