import { CallbackListener, PlayerRef } from "@remotion/player";
import {
  createContext,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { TVideoAudio } from "shared/types/assetExporter";

interface ContextProps {
  canvasJsons: Record<string, any>;
  canvas: fabric.Canvas | undefined;
  isVideoPreviewOpen: boolean;
  isPlaying: boolean;
  currentFrame: number;
  playerRef: PlayerRef | null;
  volume: number;
  isMuted: boolean;
  videoAudios: TVideoAudio[];
  isSeeked: boolean;
  backgroundAudios: Record<string, HTMLAudioElement | undefined>;
  isLoading: boolean;
  setCanvasJsons: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  setCanvas: React.Dispatch<React.SetStateAction<fabric.Canvas | undefined>>;
  setIsVideoPreviewOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentFrame: React.Dispatch<React.SetStateAction<number>>;
  setPlayerRef: React.Dispatch<PlayerRef | null>;
  setVolume: React.Dispatch<React.SetStateAction<number>>;
  setIsMuted: React.Dispatch<React.SetStateAction<boolean>>;
  setVideoAudios: React.Dispatch<React.SetStateAction<TVideoAudio[]>>;
  setIsSeeked: React.Dispatch<React.SetStateAction<boolean>>;
  setBackgroundAudios: React.Dispatch<
    React.SetStateAction<Record<string, HTMLAudioElement | undefined>>
  >;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  handlePlayVideo: () => void;
  currentMediaId: string;
  setCurrentMediaId: React.Dispatch<React.SetStateAction<string>>;
}

type ContextProviderProps = {
  children: ReactNode;
};

const Context = createContext<ContextProps>({} as ContextProps);

const ContextProvider = ({ children }: ContextProviderProps) => {
  const [canvasJsons, setCanvasJsons] = useState<Record<string, any>>({});
  const [canvas, setCanvas] = useState<fabric.Canvas>();
  const [isVideoPreviewOpen, setIsVideoPreviewOpen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentFrame, setCurrentFrame] = useState(0);
  const [playerRef, setPlayerRef] = useState<PlayerRef | null>(null);
  const [volume, setVolume] = useState(1);
  const [isMuted, setIsMuted] = useState(false);
  const [videoAudios, setVideoAudios] = useState<TVideoAudio[]>([]);
  const [backgroundAudios, setBackgroundAudios] = useState<
    Record<string, HTMLAudioElement | undefined>
  >({});
  const [isSeeked, setIsSeeked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [currentMediaId, setCurrentMediaId] = useState("");

  const handlePlayVideo = useCallback(() => {
    playerRef?.toggle();
  }, [playerRef]);

  useEffect(() => {
    if (!playerRef) return;

    const onPlay: CallbackListener<"play"> = () => {
      setIsPlaying(true);
    };

    const onPause: CallbackListener<"pause"> = () => {
      setIsPlaying(false);
    };

    const onMuteChange: CallbackListener<"mutechange"> = e => {
      setIsMuted(e.detail.isMuted);
    };

    const onVolumeChange: CallbackListener<"volumechange"> = e => {
      setVolume(e.detail.volume);
    };

    const onSeeked: CallbackListener<"seeked"> = e => {
      setCurrentFrame(e.detail.frame);
      setIsSeeked(true);
    };

    playerRef.addEventListener("play", onPlay);
    playerRef.addEventListener("pause", onPause);

    playerRef.addEventListener("volumechange", onVolumeChange);
    playerRef.addEventListener("mutechange", onMuteChange);

    playerRef.addEventListener("seeked", onSeeked);

    return () => {
      // Make sure to clean up event listeners
      if (playerRef) {
        playerRef.removeEventListener("play", onPlay);
        playerRef.removeEventListener("pause", onPause);
        playerRef.removeEventListener("volumechange", onVolumeChange);
        playerRef.removeEventListener("mutechange", onMuteChange);
        playerRef.removeEventListener("seeked", onSeeked);
      }
    };
  }, [playerRef]);

  const contextProviderValue = useMemo(
    () => ({
      canvasJsons,
      canvas,
      setCanvas,
      isVideoPreviewOpen,
      setCanvasJsons,
      setIsVideoPreviewOpen,
      isPlaying,
      setIsPlaying,
      currentFrame,
      setCurrentFrame,
      playerRef,
      setPlayerRef,
      volume,
      setVolume,
      isMuted,
      setIsMuted,
      videoAudios,
      setVideoAudios,
      setIsSeeked,
      isSeeked,
      backgroundAudios,
      setBackgroundAudios,
      isLoading,
      setIsLoading,
      handlePlayVideo,
      currentMediaId,
      setCurrentMediaId,
    }),
    [
      canvasJsons,
      canvas,
      isVideoPreviewOpen,
      isPlaying,
      currentFrame,
      playerRef,
      volume,
      isMuted,
      videoAudios,
      isSeeked,
      backgroundAudios,
      isLoading,
      handlePlayVideo,
      currentMediaId,
    ],
  );

  return (
    <Context.Provider value={contextProviderValue}>{children}</Context.Provider>
  );
};

export const VideoStitchingProvider = memo(ContextProvider);

export const useVideoStitchingContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error("Context must be used within a VideoStitchingProvider");
  }

  return context;
};
