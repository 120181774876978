import { useTrackCurrentExec } from "screens/adLibrary/adLoadV2/hooks/useCurrentExec";
import { useCurrentSession } from "screens/adLibrary/adLoadV2/hooks/useCurrentSession";
import { useNormalizedCampaignsData } from "screens/adLibrary/adLoadV2/hooks/useNormalizedCampaignsData";
import {
  findAdProtoDraft,
  getCustomAdName,
} from "screens/adLibrary/facebookUtils/adProto";
import { LoadErrorPayload } from "screens/adLibrary/facebookUtils/adProto.types";
import { useAds } from "screens/adLibrary/shared/hooks/useAds";
import { getAdKeyFromDest } from "../../../campaignData.utils";
import { AdLoadProgressData } from "../AdLoadProgressTable";
import { AdType } from "screens/adLibrary/facebookUtils/types";

export const useDataSource = () => {
  const { execStatuses } = useTrackCurrentExec();
  const { session, adDestToLoad } = useCurrentSession();
  const { originalData: ads } = useAds();
  const { campaignData } = useNormalizedCampaignsData();

  return adDestToLoad.flatMap<AdLoadProgressData>(adDest => {
    const { storeName, campaignId, adsetId, adId } = adDest;
    const loadStatus = execStatuses.find(
      status => status.adId === adId && status.adsetId === adsetId,
    );
    const ad = ads?.find(adItem => adItem.id === adId);
    if (!ad) return [];

    const adProtoDraft = findAdProtoDraft({
      adProtoDrafts: session.data.adProtoDrafts,
      adId,
      storeName,
    });

    const campaign = campaignData.campaigns[campaignId];
    const adset = campaignData.adsets[adsetId];

    const adFormat =
      "convertToVideo" in adProtoDraft && adProtoDraft.convertToVideo
        ? AdType.Video
        : ad.type;

    const adName = getCustomAdName({
      adName: ad.inputParameters?.name ?? "",
      adFormat,
      adsetName: adset?.name ?? "",
    });

    return {
      id: getAdKeyFromDest({
        adId,
        adsetId,
        campaignId,
        storeName,
      }),
      status: loadStatus?.status || "loading",
      storeName,
      adName,
      format: adFormat,
      campaignName: campaign?.name ?? "",
      adsetName: adset?.name ?? "",
      errorMessage:
        loadStatus?.status === "error"
          ? getErrorMessage(loadStatus.error)
          : undefined,
      thumbnail: ad?.visuals.thumbnail25,
    };
  });
};

const errorMap: Record<string, string> = {
  "Too Many Ads": "Each Ad Set can contain maximum of 50 Ads",
  "Ad Can't Be Edited": "The Ad Set reached its end date",
  "Unsupported Promoted Object Used In Asset Customization":
    "Error in Asset Customization. The ad asset type is not compatible with the selected campaigns.",
};

const getErrorMessage = (error: LoadErrorPayload) => {
  const errorUserTitle = error.errorData?.error_user_title;
  const customErrorMessage = errorUserTitle && errorMap[errorUserTitle];

  return customErrorMessage ?? errorUserTitle ?? error.errorMessage;
};
