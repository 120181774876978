import { useUpdateAssetMetaMutation } from "redux/media/media.api";
import { finishUpload, getPresignedUrl, uploadToS3 } from "../utils.upload";

export type BlobInput = { blob: Blob; fileName: string };

export const useUpload = () => {
  const [updateMeta] = useUpdateAssetMetaMutation();

  return async (
    input: BlobInput | string,
    folderId: string,
    meta?: MGFormValues,
  ) => {
    try {
      const { blob, fileName } = await getBlobInputFromUrl(input);
      const file = new File([blob], fileName, {
        type: blob.type,
        lastModified: Date.now(),
      });

      // Get pre-signed url from AWS.
      const { presignedUrl, processId } = await getPresignedUrl(folderId, file);
      // Upload filt to S3.
      await uploadToS3(presignedUrl, file);
      // Notify to webdam.
      const { id } = await finishUpload(processId);
      // Update asset metadata.
      await updateMeta({ payload: [{ id, ...meta }] }).unwrap();
    } catch {
      throw new Error("Failed to upload asset");
    }
  };
};

const getBlobInputFromUrl = async (input: BlobInput | string) => {
  if (typeof input === "string") {
    const res = await fetch(input);
    const blob = await res.blob();
    const fileName = input.split("/").at(-1)!;

    return { blob, fileName };
  }

  return input;
};
