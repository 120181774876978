import {
  BrochureTriggerResponse,
  GetBrochureRequestsPaginatedParams,
  GetBrochureRequestsResponse,
} from "shared/types/brochureManagement";
import { IConfig } from "shared/types/configuration";
import HttpClient from "./httpClient";

export default ({ config }: { headers: any; config: IConfig }) => ({
  getBrochureRequests: async ({
    paginationKey,
  }: GetBrochureRequestsPaginatedParams) => {
    const { result, error } = await HttpClient.get<GetBrochureRequestsResponse>(
      `${config.services.brochure.brochureRequestsUrl}${
        paginationKey
          ? `?paginationKey=${btoa(JSON.stringify(paginationKey))}`
          : ""
      }`,
    );
    if (error || !result) {
      return {
        error: {
          message: "Brochures list cannot be retrieved at this moment.",
        },
      };
    }

    return {
      result,
    };
  },
  triggerBrochure: (request: Record<string, unknown>) => {
    return HttpClient.post<BrochureTriggerResponse>(
      config.services.brochure.triggerBrochureUrl,
      request,
    );
  },
});
