import { useDataListURLGlobalFilter } from "shared/components/dataListURL/useDataListURLGlobalFilter";
import ToolbarTable, {
  ToolbarButton,
} from "shared/components/toolbarTable/ToolbarTable";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "./constants";
import { useDeleteBeeswaxCampaigns } from "./hooks/useDeleteBeeswaxCampaigns";
import BeeswaxLogo from "shared/components/MediaLogos/BeeswaxLogo";
import { message } from "antd";
import { isEmpty } from "lodash";
import { useBeeswaxCampaignsDataSource } from "./hooks/useBeeswaxCampaignsDataSource";
import { useSelectedBeeswaxCampaigns } from "./hooks/useSelectedBeeswaxCampaigns";

const Toolbar = () => {
  const { globalFilter, setGlobalFilter } = useDataListURLGlobalFilter();
  const { selectedItems: selectedRecords } = useBeeswaxCampaignsDataSource();
  const { selectedCampaigns } = useSelectedBeeswaxCampaigns();
  const { mutate: deleteCampaigns } = useDeleteBeeswaxCampaigns();
  const navigate = useNavigate();

  const loadToBeeswax = () => {
    const campaignWithoutLineItem = selectedCampaigns.some(campaign =>
      isEmpty(campaign.lineItems),
    );

    if (campaignWithoutLineItem) {
      message.error("Some campaigns do not have line items");
      return;
    }

    navigate(ROUTES.selectStep);
  };

  const toolbarContents: ToolbarButton = {
    Load: {
      icon: <BeeswaxLogo />,
      extraInfo: {
        title: "Load to Beeswax",
        tooltip: "Load selected campaigns to Beeswax",
      },
      onClick: loadToBeeswax,
      disabled: selectedRecords.length < 1,
    },
    New: {
      extraInfo: {
        text: "New Campaign Template",
      },
      onClick: () => {
        navigate(ROUTES.create);
      },
    },
    Edit: {
      onClick: () => {
        const record = selectedRecords[0];
        if (!record) throw new Error("No record selected");

        navigate(ROUTES.update(record.id));
      },
      disabled: selectedRecords.length !== 1,
    },
    Delete: {
      onClick: () => {
        deleteCampaigns({ ids: selectedRecords.map(c => c.id) });
      },
      disabled: selectedRecords.length < 1,
    },
  };

  return (
    <ToolbarTable
      searchValue={globalFilter ?? undefined}
      toolbarContents={toolbarContents}
      searchPlaceholder="Input search text"
      titleTooltip="Search campaigns"
      onSearch={setGlobalFilter}
    />
  );
};

export default Toolbar;
