import {
  DesignStudioAssetType,
  DesignStudioTableArtboard,
  DesignStudioTableItem,
  DesignStudioTableStamp,
  DesignStudioTableTemplate,
} from "./context/DesignStudioDataProvider";
import moment from "moment";
import { formatDimensions } from "utils/helpers";
import { fullCountryStateOptions } from "shared/constants/dataManagement";
import { nonNullable } from "utils/helpers.array";
import { DesignStudioFields } from "./context/fields";
import {
  artboardsColumnsPreset,
  defaultColumnsPreset,
  stampsColumnsPreset,
  templatesColumnsPreset,
} from "./designStudioLayout/designStudioTable/columnFilters/columns";
import { TTemplateType } from "shared/types/designStudio";
import {
  DESIGN_STUDIO_V2_EDITOR_ENABLED,
  POLOTNO_EDITOR,
} from "./designStudioV2/constants";
import { Template } from "shared/types/salesEnablement";

const STAMP = "Stamp";
const ARTBOARD = "Artboard";
const TEMPLATE = "Template";
const IMAGE = "Image";
const VIDEO = "Video";

const createFilterFn = (item: string): { value: string; text: string } => ({
  value: item,
  text: item,
});

export const isStamp = (
  item?: DesignStudioTableItem,
): item is DesignStudioTableStamp => item?.itemType === "stamp";

export const isArtboard = (
  item?: DesignStudioTableItem,
): item is DesignStudioTableArtboard => item?.itemType === "artboard";

export const isTemplate = (
  item?: DesignStudioTableItem,
): item is DesignStudioTableTemplate => item?.itemType === "template";

export const getThumbnailUrl = (item: DesignStudioTableItem) => {
  if (isArtboard(item)) return null;
  return item.asset.thumbnailUrl;
};

export const isHTMLTemplate = (item: DesignStudioTableItem) =>
  isTemplate(item) && item.asset.type === "html";

export const isV3Template = (item: DesignStudioTableItem) =>
  isTemplate(item) && item.asset.type === POLOTNO_EDITOR;

export const getCreatedAt = (item: DesignStudioTableItem): number | null =>
  isArtboard(item) ? item.asset.created_at : item.asset.createdAt;

export const getUpdatedAt = (item: DesignStudioTableItem) => {
  if (isStamp(item)) return item.asset.updatedAt;
  if (isArtboard(item)) return item.asset.updated_at ?? 0;
  if (isTemplate(item)) return item.asset.lastUpdatedAt ?? 0;
  return 0;
};

export const sortByLastEditedDateFn = (
  a: DesignStudioTableItem,
  b: DesignStudioTableItem,
) => (getUpdatedAt(b) ?? 0) - (getUpdatedAt(a) ?? 0);

export const getCategoryName = (item: DesignStudioTableItem) => {
  if (isStamp(item)) return STAMP;
  if (isArtboard(item)) return ARTBOARD;
  if (isTemplate(item)) return TEMPLATE;
  return "";
};

export const getStatus = (item: DesignStudioTableItem) => {
  if (isStamp(item) || isTemplate(item)) {
    return item.asset.status === "PUBLISHED" ? "Published" : "Unpublished";
  }

  return "-";
};

export const isPublished = (item: DesignStudioTableItem) => {
  if (isArtboard(item)) return false;
  return item.asset.status === "PUBLISHED";
};

export const isArchived = (item: DesignStudioTableItem) => {
  if (isArtboard(item)) return false;
  return item.asset.isDeleted;
};

export const getAssetType = (item: DesignStudioTableItem) => {
  if (isTemplate(item)) return item.asset.assetType;
  if (isArtboard(item)) return item.asset.asset_type;
  return "";
};

const filterDuplicatesAndEmpty = <T extends string>(
  value: T,
  index: number,
  self: T[],
): value is T => {
  if (value === null || value === "") return false;
  return self.indexOf(value) === index;
};

export const getAssetTypeFilters = (items: DesignStudioTableItem[]) =>
  items.map(getAssetType).filter(filterDuplicatesAndEmpty).map(createFilterFn);

export const getDimensionFilters = (items: DesignStudioTableItem[]) =>
  getAllDimensions(items)
    .map(formatDimensions)
    .filter(filterDuplicatesAndEmpty)
    .map(createFilterFn);

export const getTemplateTypeFilters = (items: DesignStudioTableItem[]) =>
  items
    .map(getTemplateType)
    .filter(filterDuplicatesAndEmpty)
    .map(createFilterFn);

export const getTemplateType = (item: DesignStudioTableItem) => {
  if (isTemplate(item)) return item.asset.type || "";
  return "";
};

export const getMediaType = (item: DesignStudioTableItem) => {
  if (isStamp(item)) return IMAGE;
  if (isTemplate(item)) return item.asset.mediaType === "mp4" ? VIDEO : IMAGE;
  return "";
};

export const getDimensions = (item: DesignStudioTableItem) => {
  if (isArtboard(item)) return formatDimensions(item.asset);
  if (isTemplate(item)) return formatDimensions(item.asset.artboard);
  return "";
};

export const getDimensionsObject = (item: DesignStudioTableItem) => {
  if (isArtboard(item)) {
    return {
      width: item.asset.width,
      height: item.asset.height,
    };
  }
  if (isTemplate(item)) {
    return {
      width: item.asset.artboard.width,
      height: item.asset.artboard.height,
    };
  }
  if (isStamp(item)) {
    return {
      width: item.asset.width,
      height: item.asset.height,
    };
  }
  return null;
};

export const getAllDimensions = (items: DesignStudioTableItem[]) =>
  items.map(getDimensionsObject).filter(nonNullable);

export const getArtboardNameFilters = (items: DesignStudioTableItem[]) =>
  items
    .map(getArtboardName)
    .filter(filterDuplicatesAndEmpty)
    .map(createFilterFn);

export const getArtboardName = (item: DesignStudioTableItem) => {
  if (isTemplate(item)) return item.asset.artboard.name;
  return "";
};

export const getStoresFilters = (items: DesignStudioTableItem[]) =>
  items
    .flatMap(item => {
      if (isTemplate(item)) return item.asset.stores;
      return [];
    })
    .filter(filterDuplicatesAndEmpty)
    .map(createFilterFn);

export const getStores = (item: DesignStudioTableItem) => {
  if (isTemplate(item)) return item.asset.stores.join(", ");
  if (isStamp(item)) return item.asset.stores?.join(", ") ?? "";
  return "";
};

export const getStoresArray = (item: DesignStudioTableItem) => {
  if (isTemplate(item)) return item.asset.stores || [];
  return [];
};

export const getOemsArray = (item: DesignStudioTableItem) => {
  if (isTemplate(item) || isStamp(item)) return item.asset.oems || [];
  return [];
};

export const getTagsFilters = (items: DesignStudioTableItem[]) =>
  items.flatMap(getTags).filter(filterDuplicatesAndEmpty).map(createFilterFn);

export const getTags = (item: DesignStudioTableItem) => {
  if (isArtboard(item) || !item.asset.tags.length) return [];
  return item.asset.tags;
};

export const getLocation = (item: DesignStudioTableItem) => {
  if (isArtboard(item) || !item.asset.state) return "";
  return item.asset.state;
};

export const getOemsFilters = (items: DesignStudioTableItem[]) =>
  items
    .flatMap(item => {
      if (isTemplate(item) || isStamp(item)) return item.asset.oems;
      return [];
    })
    .filter(filterDuplicatesAndEmpty)
    .map(createFilterFn);

export const getOems = (item: DesignStudioTableItem) => {
  if (isTemplate(item) || isStamp(item))
    return item.asset.oems?.length ? item.asset.oems.join(", ") : "";
  return "";
};

export const getNumberOfStampsFilters = (items: DesignStudioTableItem[]) =>
  items
    .map(getNumberOfStamps)
    .filter(filterDuplicatesAndEmpty)
    .map(createFilterFn);

export const getNumberOfStamps = (item: DesignStudioTableItem) => {
  if (isTemplate(item)) return `${item.asset.numOfStamps}`;
  return "";
};

export const getCreatedAgo = (item: DesignStudioTableItem) =>
  moment(getCreatedAt(item)).fromNow();

export const getUpdatedAgo = (item: DesignStudioTableItem) => {
  const updatedAtDate = getUpdatedAt(item);
  return updatedAtDate ? moment(updatedAtDate).fromNow() : "";
};

export const getAllRowKeys = (items: DesignStudioTableItem[]) =>
  items.map(getRowKey);

export const getRowKey = (record: DesignStudioTableItem) => {
  const identifier = isArtboard(record) ? record.asset.name : record.asset.id;
  const createdAt = isArtboard(record)
    ? record.asset.created_at
    : record.asset.createdAt;

  return `${record.itemType}-${identifier}-${createdAt}`;
};

export const getItemIds = (items: DesignStudioTableItem[]) => {
  return items
    .map(item => {
      if (isArtboard(item)) return null;
      return item.asset.id;
    })
    .filter(nonNullable);
};

export const categoryFilters = [STAMP, ARTBOARD, TEMPLATE].map(createFilterFn);

export const mediaTypeFilters = [IMAGE, VIDEO].map(createFilterFn);

export const locationFilters = fullCountryStateOptions[0].states.map(state =>
  createFilterFn(state.abbreviation),
);

const columnNameMap: Record<DesignStudioFields, string> = {
  assetType: "Asset Type",
  templateType: "Template Type",
  mediaType: "Media Type",
  dimensions: "Dimensions",
  artboardName: "Artboard Name",
  stores: "Stores",
  tags: "Tags",
  location: "Location",
  oems: "OEMs",
  numberOfStamps: "Number of Stamps",
  created: "Created",
  lastEdited: "Updated",
  name: "Name",
  category: "Category",
  status: "Status",
};

const getDesignStudioItemPreset = (itemType: DesignStudioAssetType) => {
  if (itemType === "stamp") return stampsColumnsPreset;
  if (itemType === "artboard") return artboardsColumnsPreset;
  if (itemType === "template") return templatesColumnsPreset;
  return defaultColumnsPreset;
};

export const explainEmptyColumn = (
  itemType: DesignStudioAssetType,
  column: DesignStudioFields,
) => {
  const availableColumns = getDesignStudioItemPreset(itemType);

  if (!availableColumns?.includes(column))
    return `${columnNameMap[column]} does not apply to ${itemType}s`;

  return "Click edit to add this info";
};

export const statusFilters = ["Published", "Unpublished"].map(createFilterFn);

export const dateFilterToRange = (
  dateFilter: string[],
): [moment.Moment, moment.Moment] | undefined => {
  if (!dateFilter || !dateFilter[0]) return;
  const [dateFrom, dateTo] = dateFilter[0].split(" ");
  return [moment(parseInt(dateFrom)), moment(parseInt(dateTo))];
};

export const availableTemplateTypes: TTemplateType[] =
  DESIGN_STUDIO_V2_EDITOR_ENABLED
    ? ["carcut", "lifestyle", "html", POLOTNO_EDITOR]
    : ["carcut", "lifestyle", "html"];

export const isTemplateExpired = (exp?: string) =>
  !!exp ? moment.utc(exp).isBefore(moment.utc()) : false;

export const dateToEST = (d: moment.Moment) =>
  moment.utc(d).tz("America/New_York");

export const filterableMetrics = (templates: Template[]) => {
  const top25 = templates.sort((a, b) => b.metrics - a.metrics).slice(0, 25);

  return templates.map(template => ({
    ...template,
    metrics: top25.find(t => t.id === template.id) ? 1 : 0,
  }));
};
