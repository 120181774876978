import { ReactNode } from "react";

import styles from "./MenuContiner.module.scss";

type Props = {
  children: ReactNode;
};
export const MenuContainer = ({ children }: Props) => {
  return <ul className={styles.host}>{children}</ul>;
};
