import { FilterValue } from "antd/lib/table/interface";
import { ICanvasObject } from "shared/types/assetBuilder";
import {
  BrandLogo,
  CAMLogo,
  ImageType,
  LogoType,
} from "shared/types/brandsAccounts";
import { ITemplate } from "shared/types/designStudio";
export type TCanvasJSON = {
  version: string;
  objects: ICanvasObject[];
  height: number;
  width: number;
};
export type TMediaResizeType = "fill" | "fit";
export type LogoData = {
  logoType: LogoType;
  artboardName: ImageType;
};
export type TVariable = {
  id: string; // object.name
  raw: string; // raw string, ex) this string with {variable}
  variable: string; // variable string, ex) variable
  type:
    | "image"
    | "text"
    | "cssImage"
    | "cssInlineImage"
    | "video"
    | "logo"
    | "cssStyle"
    | "cssAttribute";
  lineIdx: number; // the index of a line that this variable was extracted from
  startIdx: number; // starting index
  cssClassName?: string; // css class name, for cssImage vars
  logoData?: LogoData;
};

export type TInputType = "match_to_column" | "text" | "regex" | "logo";
export type TColumnValue = {
  column: string;
  type?: Extract<TInputType, "match_to_column" | "regex">;
  regexPattern?: string; // only exist for regex type
  error?: string;
};
export type TLogoValue = {
  type: Extract<TInputType, "logo">;
} & (BrandLogo | CAMLogo);

export type TObjValue = TColumnValue | TLogoValue;
export type TValue = string | TObjValue;
export type TValueMapping = {
  variable: TVariable;
  inputType: TInputType;
  value?: TValue;
  resizeType?: TMediaResizeType;
};
export type TValueMappings = Record<
  string, // <variable id>-<variable>
  TValueMapping
>;

export type TMediaType = "mp4" | "png" | "jpg" | "jpeg";
export type TBackgroundMedia = {
  type: TMediaType;
  src: string;
  column?: string;
};

export type TemplateListColumn =
  | Extract<
      keyof ITemplate,
      | "name"
      | "assetType"
      | "tags"
      | "status"
      | "lastUpdatedAt"
      | "type"
      | "stores"
      | "oems"
      | "createdAt"
    >
  | "dimension"
  | "artboardName"
  | "stamps";

export type FiltersType = Record<TemplateListColumn, FilterValue | null>;

export const mediaTypes = [
  "image",
  "cssImage",
  "cssInlineImage",
  "video",
  "logo",
];
