import { ReactElement, PropsWithChildren, memo } from "react";
import { Tooltip, Typography } from "antd";

interface LabelOptions {
  style?: React.CSSProperties;
  label?: string;
  required?: boolean;
  tooltip?: string;
  className?: string;
}

type LabelProps = PropsWithChildren<LabelOptions>;

const LabelComponent = ({
  children,
  label,
  required,
  tooltip,
  style,
  className,
}: LabelProps): ReactElement => {
  return (
    <div
      className={className}
      style={{ width: "100%", paddingBottom: "8px", ...style }}
    >
      {required && <Typography.Text type="danger">*</Typography.Text>}
      <Typography.Text>{children ?? label ?? ""}</Typography.Text>
      {tooltip && <Tooltip title={tooltip}>{tooltip}</Tooltip>}
    </div>
  );
};

export const Label = memo(LabelComponent);
