import { DesignStudioTableItem } from "screens/designStudio/context/DesignStudioDataProvider";
import { isStamp, isV3Template } from "screens/designStudio/helpers";

export const isModifierKeyActive = (
  event: React.MouseEvent<HTMLElement, MouseEvent>,
) => event.ctrlKey || event.metaKey;

export const openInNewTab = (url: string) => {
  window.open(url, "_blank");
};

export const getEditUrl = (item: DesignStudioTableItem) => {
  if (isStamp(item)) {
    return `/design-studio/editor/stamps/${item.asset.id}/editor-v2?offerType=${item.asset.offerType}`;
  }
  return isV3Template(item)
    ? `/design-studio?&assetIds=${item.asset.id}&selectedId=${item.asset.id}`
    : `/design-studio/editor/templates/${item.asset.id}/editor-v2`;
};
