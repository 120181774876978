import { Stack, Divider, IconButton, Tooltip } from "@mui/material";
import { observer } from "mobx-react-lite";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LibraryAddRounded from "@mui/icons-material/LibraryAddRounded";
import DeleteForeverRounded from "@mui/icons-material/DeleteForeverRounded";
import AddBoxRounded from "@mui/icons-material/AddBoxRounded";
import { StoreType } from "polotno/model/store";

interface PageControlsProps {
  store: StoreType;
  page: StoreType["pages"][number];
  xPadding: number;
  yPadding: number;
}

const commonButtonStyle = {
  width: 28,
  height: 28,
  borderRadius: "0%",
};

const PageControls = observer(
  ({ store, page, xPadding, yPadding }: PageControlsProps) => {
    const hasManyPages = store.pages.length > 1;
    const index = store.pages.indexOf(page);

    return (
      <Stack
        divider={<Divider orientation="vertical" flexItem />}
        direction="row"
        position="absolute"
        top={yPadding - 45 + "px"}
        right={xPadding + "px"}
        border="1px solid #ccc"
        borderRadius={1}
      >
        {hasManyPages && (
          <Tooltip title="Move Page Up" disableHoverListener={index === 0}>
            <>
              <IconButton
                disabled={index === 0}
                onClick={() => {
                  page.setZIndex(index - 1);
                }}
                sx={commonButtonStyle}
              >
                <KeyboardArrowUpIcon fontSize="small" />
              </IconButton>
            </>
          </Tooltip>
        )}
        {hasManyPages && (
          <Tooltip
            title="Move Page Down"
            disableHoverListener={index === store.pages.length - 1}
          >
            <>
              <IconButton
                disabled={index === store.pages.length - 1}
                onClick={() => {
                  const index = store.pages.indexOf(page);
                  page.setZIndex(index + 1);
                }}
                sx={commonButtonStyle}
              >
                <KeyboardArrowDownIcon fontSize="small" />
              </IconButton>
            </>
          </Tooltip>
        )}
        <Tooltip title="Add Page">
          <IconButton onClick={() => store.addPage()} sx={commonButtonStyle}>
            <AddBoxRounded fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Duplicate Page">
          <IconButton
            onClick={() => store.activePage?.clone()}
            sx={commonButtonStyle}
          >
            <LibraryAddRounded fontSize="small" />
          </IconButton>
        </Tooltip>
        {hasManyPages && (
          <Tooltip title="Delete Page">
            <IconButton
              onClick={() => store.deletePages([store.activePage.id])}
              sx={commonButtonStyle}
            >
              <DeleteForeverRounded fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    );
  },
);

export default PageControls;
