import {
  Autocomplete,
  ClickAwayListener,
  Paper,
  Popover,
  TextField,
} from "@mui/material";
import { useRef, useState } from "react";
import { insertTextAtPosition } from "../../editorDrawerContext/helpers.text";
import { isTextElement } from "./utils";
import { observer } from "mobx-react-lite";
import { AnchorPosition } from "./useTextVariableInsert";
import {
  isTextVariable,
  variableNames,
} from "../../editorDrawerContext/helpers.variables";

type VariableInsertionPopoverProps = {
  anchorPosition: AnchorPosition;
  onClose: () => void;
};

export const VariableInsertionPopover = observer(
  ({ anchorPosition, onClose }: VariableInsertionPopoverProps) => {
    const autocompleteTextFieldRef = useRef<HTMLInputElement>(null);
    const { element, cursor } = anchorPosition ?? {};
    const [inputValue, setInputValue] = useState("{");

    const isValid =
      !element ||
      !isTextElement(element) ||
      !anchorPosition ||
      cursor === null ||
      cursor === undefined;

    if (isValid) return null;

    return (
      <Popover
        id={"id"}
        open={!!anchorPosition}
        sx={{ zIndex: 1000 }}
        anchorPosition={anchorPosition}
        anchorReference="anchorPosition"
        TransitionProps={{
          onEntered: () => {
            autocompleteTextFieldRef.current?.focus();
          },
        }}
      >
        <ClickAwayListener onClickAway={onClose}>
          <Paper>
            <Autocomplete
              options={variableNames}
              freeSolo
              selectOnFocus
              inputValue={inputValue}
              onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
              onChange={(_, value) => {
                if (!value || !isTextVariable(value)) return;
                insertTextAtPosition(element, value, cursor);
                setInputValue("{");
                onClose();
              }}
              sx={{ minWidth: 250 }}
              componentsProps={{ popper: { sx: { width: "fit-content" } } }}
              renderInput={params => (
                <TextField {...params} inputRef={autocompleteTextFieldRef} />
              )}
              forcePopupIcon
              includeInputInList
              openOnFocus
            />
          </Paper>
        </ClickAwayListener>
      </Popover>
    );
  },
);
