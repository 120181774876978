/**
 * Role Based Access Control (RBAC) configuration
  
 * Roles in Auth0
 * - V2 Admin
 * - User (originally implicit, now also explicit)
 * - Sales Enablement Admin
 * - Brochures Admin
 * - Brochures User
 * - SE Agent - Brochures Admin

 * Role Circumstances

 * - AV2 Site
 *   - V2 Admin (manage:v2admin permission)
 *     - All standard V2 modules
 * 
 *   - User role (explicit or implicit)
 *     - Only access to certain V2 modules

 * - ASM (Aflac) / Sales Enablement site

 *   - Sales Enablement Admin (manage:salesenablementadmin permission)
 *     - Sales Enablement Admin modules
 * 
 *   - [No Role] (Agent) (implicit or explicit via read:salesenablement permission)
 *     - Sales Enablement Agent modules
 * 
 *   - Brochures Admin (manage:brochures permission)
 *     - Brochures Managment module only, manager access
 * 
 *   - Brochures User (read:brochures permission)
 *     - Brochures Management module only, user access
 * 
 *   - SE Agent + Brochures Admin (read:salesenablement + manage:brochures permissions)
 *     - Sales Enablement Agent modules
 *     - Brochures Management module, manager access
 * 
 * NOTE: Currently the "read:salesenablement" permissions is only used for the 
 * "SE Agent + Brochures Admin" role since read permission is implicit for No Role users (Agents)
 */
const adminRoles = {
  manageAdmin: "manage:v2admin",
  manageSalesEnablementAdmin: "manage:salesenablementadmin",
  manageBrochures: "manage:brochures",
};

const moduleRoles = {
  readBrochures: "read:brochures",
  readSalesEnablement: "read:salesenablement",
};

export const rbac = {
  ...adminRoles,
  ...moduleRoles,
};
