import { Box, CircularProgress, Grid } from "@mui/material";
import { EditorHeader } from "./EditorHeader";
import { EditorTabs } from "./EditorTabs";
import { useEditorDrawerContext } from "../../editorDrawerContext/useEditorDrawerContext";

export const EditorLoader = () => {
  const { removeStore, changeActiveStore } = useEditorDrawerContext();
  return (
    <Box sx={{ width: 1, height: 1, display: "flex", flexDirection: "column" }}>
      <EditorHeader onRemove={removeStore} onChange={changeActiveStore} />
      <Grid container sx={{ width: 1, flex: 1 }}>
        <Grid item xs={3}>
          <EditorTabs />
        </Grid>
        <Grid item xs={9}>
          <Box
            sx={{
              backgroundColor: "#E7E7E7",
              width: 1,
              height: 1,
              display: "grid",
              placeItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
