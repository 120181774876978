import { Chip, Box } from "@mui/material";

type KeywordChipProps = {
  selected: string[];
  handleDelete: (value: string) => (event: React.MouseEvent) => void;
};

export const KeywordChip = ({ selected, handleDelete }: KeywordChipProps) => {
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.2 }}>
      {selected.map((value, index) => {
        if (index < 2) {
          return (
            <Chip
              key={value}
              label={value}
              onDelete={handleDelete(value)}
              onMouseDown={e => e.stopPropagation()}
            />
          );
        }
        if (index === 2) {
          return <Chip key="more" label={`+${selected.length - 2}`} />;
        }
        return null;
      })}
    </Box>
  );
};
