import { Button, Form, FormInstance, Tooltip } from "antd";
import { forwardRef, useImperativeHandle } from "react";
import { createPortal } from "react-dom";
import { ButtonGroup } from "../ButtonGroup";
import { Loading } from "../Loading";
import { MetaFormItems } from "../MetaFormItem";
import { useCAMFooter } from "../useCAMFooter";
import { useForm } from "./useForm";

import styles from "./AssetMetaForm.module.scss";

interface Props {
  drawerId: string;
  assets: WDAsset[];
}

type FormInstanceWithSubmit = Omit<FormInstance<MGFormValues>, "submit"> & {
  submit: (callback: () => Promise<void>) => Promise<void>;
};

export const AssetMetaForm = forwardRef<FormInstanceWithSubmit, Props>(
  function AssetMetaForm({ assets, drawerId }, formRef) {
    const {
      initialValues,
      isLoading,
      onSubmit,
      onClose,
      disabled,
      schema,
      form,
    } = useForm(assets);
    const footer = useCAMFooter(drawerId);
    const tooltip = disabled && "Requires edit permission";

    useImperativeHandle(
      formRef,
      () => ({
        ...form,
        submit: async callback => {
          await onSubmit(form.getFieldsValue());
          await callback();
        },
      }),
      [form, onSubmit],
    );

    if (schema && initialValues) {
      return (
        <Form
          form={form}
          id={drawerId}
          layout="vertical"
          initialValues={initialValues}
          onFinish={onSubmit}
          className={styles.form}
          data-disabled={disabled}
        >
          <h4>Meta Data</h4>
          <MetaFormItems schema={schema} />
          {footer &&
            createPortal(
              <ButtonGroup>
                <Button onClick={onClose}>Close</Button>
                <Tooltip title={tooltip} placement="topRight">
                  <Button
                    type="primary"
                    htmlType="submit"
                    form={drawerId}
                    loading={isLoading}
                    disabled={disabled}
                  >
                    Save
                  </Button>
                </Tooltip>
              </ButtonGroup>,
              footer,
            )}
        </Form>
      );
    }

    return <Loading />;
  },
);
