import { SearchOutlined } from "@ant-design/icons";
import { DateFilterDropdown } from "shared/components/DateFilterDropdown";
import { SearchFilterDropdown } from "shared/components/SearchFilterDropdown";
import { ITableColumnURL } from "shared/components/dataListURL/DataListURLTable";
import { Tag } from "antd";
import { ThumbnailCell } from "shared/components/ThumbnailCell";
import { LoadCampaignRecord } from "screens/beeswaxCampaigns/types";
import StatusIcon from "screens/adLibrary/adLoadV2/adLoadDrawer/loadStep/adLoadProgressTable/StatusIcon";
import { toUSD } from "utils/helpers.currency";
import { renderDate, getCalendarIcon } from "screens/beeswaxCampaigns/helpers";
import { useLoadCampaignsContext } from "../LoadProvider";
import { useBeeswaxCampaignsLoadingStatus } from "../hooks/useBeeswaxCampaignsLoadingStatus";

export const useColumns = () => {
  const { session } = useLoadCampaignsContext();
  const { data: statuses } = useBeeswaxCampaignsLoadingStatus(session);

  const columns: ITableColumnURL<
    keyof LoadCampaignRecord,
    LoadCampaignRecord
  >[] = [
    {
      title: "",
      key: "status",
      width: 70,
      dataIndex: "status",
      render: (_, record) => {
        if (record.type === "lineItem") return undefined;

        const status = statuses?.find(
          item => item.campaignId === record.id,
        )?.status;

        return <StatusIcon status={status ?? "loading"} />;
      },
    },
    {
      title: "Name",
      dataIndex: ["name"],
      key: "name",
      filterDropdown: SearchFilterDropdown,
      filterIcon: <SearchOutlined />,
      render: (_, record) => {
        if (record.type === "campaign") {
          return record.name;
        }

        return (
          <ThumbnailCell
            name={record.name}
            image={record.creatives?.[0]?.thumbnailUrl}
          />
        );
      },
    },
    {
      title: "Account",
      dataIndex: ["accountId"],
      key: "accountId",
      render: (_, record) => record.accountId && <Tag>{record.accountId}</Tag>,
    },
    {
      key: "startDate",
      title: "Start Date",
      dataIndex: ["startDate"],
      render: renderDate,
      filterDropdown: DateFilterDropdown,
      filterIcon: getCalendarIcon,
    },
    {
      key: "endDate",
      title: "End Date",
      dataIndex: ["endDate"],
      render: renderDate,
      filterDropdown: DateFilterDropdown,
      filterIcon: getCalendarIcon,
    },
    {
      key: "budget",
      title: "Budget",
      dataIndex: ["budget"],
      render: toUSD,
    },
    {
      key: "dailyBudget",
      title: "Daily Budget",
      dataIndex: ["dailyBudget"],
      render: (_, record) => record.dailyBudget && toUSD(record.dailyBudget),
    },
    {
      key: "cpmBid",
      title: "CPM Bid",
      dataIndex: ["cpmBid"],
      render: (_, record) => record.cpmBid && toUSD(record.cpmBid),
    },
    {
      key: "creatives",
      title: "Creatives",
      dataIndex: ["creatives"],
      render: (_, record) => record.creatives?.length,
    },
  ];

  return columns;
};
