import { ReactComponent as DashStrokeOne } from "statics/images/svg/icons/dash-stroke-one.svg";
import { ReactComponent as DashStrokeTwo } from "statics/images/svg/icons/dash-stroke-two.svg";
import { ReactComponent as DashStrokeThree } from "statics/images/svg/icons/dash-stroke-three.svg";
import { ReactComponent as DashStrokeFour } from "statics/images/svg/icons/dash-stroke-four.svg";
import { SvgIconProps } from "@mui/material";

type DashStrokeProps = SvgIconProps & {
  variant: "one" | "two" | "three" | "four";
};

const getIconByVariant = (variant: DashStrokeProps["variant"]) => {
  switch (variant) {
    case "one":
      return DashStrokeOne;
    case "two":
      return DashStrokeTwo;
    case "three":
      return DashStrokeThree;
    case "four":
      return DashStrokeFour;
  }
};

export const DashStrokeIcon = (props: DashStrokeProps) => {
  const { variant, ...svgIconProps } = props;
  const Icon = getIconByVariant(variant);

  return <Icon {...svgIconProps} />;
};
