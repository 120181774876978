import API from "services";
import { useQuery } from "react-query";
import { Advertiser } from "screens/beeswaxCampaigns/types";

const queryKey = "beeswaxAdvertisers";

const getBeeswaxAdvertisers = async (): Promise<Advertiser[]> => {
  const { result, error } =
    await API.services.beeswaxCampaigns.getBeeswaxAdvertisers();
  if (!result || error) {
    throw Error(error?.message || "something went wrong!");
  }
  return result;
};

export const useBeeswaxAdvertisers = () => {
  return useQuery([queryKey], () => getBeeswaxAdvertisers());
};
