import { Stack, Divider } from "@mui/material";
import { observer } from "mobx-react-lite";
import TextEditor from "./editorTopbar/textEditor/TextEditor";
import MediaEditor from "./editorTopbar/mediaEditor/MediaEditor";
import { LayerControls } from "./editorTopbar/LayerControls";
import {
  textElementSelected,
  mediaElementSelected,
  layerElementUnlocked,
  layerCropModeEnabled,
} from "./utils";
import { StoreType } from "polotno/model/store";
import { useCropHandler } from "screens/designStudio/designStudioV2/hooks/useCropHandler";
import { ButtonContainer } from "./editorTopbar/containers/ButtonContainer";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Download } from "./editorTopbar/Download";
import { Save } from "./editorTopbar/Save";
import { FigureEditor } from "./editorTopbar/FigureEditor";

interface EditorControlsProps {
  store: StoreType;
}

export const EditorTopBar = observer(({ store }: EditorControlsProps) => {
  const isTextElementSelected = textElementSelected(store.selectedElements);
  const isMediaElementSelected = mediaElementSelected(store.selectedElements);
  const isElementUnlocked = layerElementUnlocked(store);
  const isCropModeEnabled = layerCropModeEnabled(store);
  const { cropSave, cropCancel } = useCropHandler(store);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      p={1}
      borderBottom={1}
      borderColor="divider"
      alignItems="center"
    >
      {!isCropModeEnabled ? (
        <>
          <Stack
            direction="row"
            sx={{ flex: 1 }}
            alignItems="center"
            justifyContent="end"
            spacing={1}
            height={40}
          >
            <FigureEditor store={store} />
            {isTextElementSelected && isElementUnlocked && (
              <TextEditor store={store} />
            )}
            {isMediaElementSelected && isElementUnlocked && (
              <MediaEditor store={store} />
            )}
          </Stack>
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={1}
            pl={1}
          >
            <LayerControls store={store} />
            <Stack direction="row" spacing={1}>
              <Save />
              <Download store={store} />
            </Stack>
          </Stack>
        </>
      ) : (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={1}
          height={40}
        >
          <Stack direction="row" spacing={1}>
            <ButtonContainer
              startIcon={<CheckRoundedIcon fontSize="small" color="action" />}
              onClick={cropSave}
            >
              Done
            </ButtonContainer>
            <ButtonContainer
              startIcon={<CloseRoundedIcon fontSize="small" color="action" />}
              onClick={cropCancel}
            >
              Cancel
            </ButtonContainer>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
});
