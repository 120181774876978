export const LOCAL_STORAGE_KEY = "media";
export const REDIRECT_URI = `${location.origin}/media-callback`;
export const MIXED_VALUE = "Mixed";
export const WD_CDN_PATH = "https://cdn2.webdamdb.com";
export const UPLOAD_ACCEPT_FILETYPES =
  "image/png,image/jpeg,video/mp4,video/quicktime,application/zip,audio/mpeg,audio/wav,font/woff,application/font-woff,font/otf,font/ttf,image/vnd.adobe.photoshop,.psd,application/pdf,text/html";
export const SEARCH_MODE_FILE_TYPES =
  "png,jpg,jpeg,mp4,mov,zip,mp3,wav,woff,otf,ttf,pdf,html";
export const LIST_MODE_FILE_TYPES = "image,audiovideo,document,other";

export const QP_SEARCH = "media_search";
export const QP_BREADCRUMB = "media_breadcrumb";
export const QP_LAYOUT = "media_layout";

export const MM_DD_YYYY_FORMAT = `MM/DD/YYYY hh:mma`;
export const IMG_TYPES = ["png", "jpg", "jpeg", "eps"];
export const AUDIO_TYPES = ["mp3", "wav"];
export const VIDEO_TYPES = ["mp4", "mov"];
export const FONT_TYPES = ["otf", "ttf", "woff", "woff2"];
export const ZIP_TYPES = ["zip"];
export const PSD_TYPES = ["psd"];
export const PDF_TYPES = ["pdf"];
export const HTML_TYPES = ["html"];
