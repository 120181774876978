import { AuthProvider } from "contexts/AuthContext";
import { FrameDaemon } from "daemon/FrameDaemon";
import { createContext, PropsWithChildren } from "react";
import NewVersionAlert from "./app/NewVersionAlert";
import { NotificationsDaemon } from "daemon/NotificationsDaemon";
import { useApolloClient } from "shared/hooks/av3/useApolloClient";
import { ApolloProvider } from "@apollo/client";
import { AutoLogout } from "./AutoLogout";

export const AppContext = createContext({});

type AppProviderProps = PropsWithChildren<object>;

export const AppProvider = ({ children }: AppProviderProps) => {
  const { client } = useApolloClient();
  return client ? (
    <AppContext.Provider value={{}}>
      <ApolloProvider client={client}>
        <AuthProvider>
          <>
            <NewVersionAlert />
            {children}
            <FrameDaemon />
            <NotificationsDaemon />
            <AutoLogout />
          </>
        </AuthProvider>
      </ApolloProvider>
    </AppContext.Provider>
  ) : null;
};

export const AppConsumer = AppContext.Consumer;
