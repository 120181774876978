import AccountList from "./accounts/AccountList";
import AccountToolbar from "./accounts/AccountToolbar";
import { AccountDrawerContainer } from "shared/components/salesEnablement/accounts/AccountDrawer";
import { AccountsProvider } from "shared/components/salesEnablement/context/AccountsContext";
import styles from "./Accounts.module.scss";

const Accounts = () => {
  return (
    <div className={styles.container}>
      <AccountsProvider>
        <AccountToolbar />
        <AccountList />
        <AccountDrawerContainer />
      </AccountsProvider>
    </div>
  );
};

export default Accounts;
