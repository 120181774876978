import { Row, Col, Typography, Space, Button, Table, Tag, Tooltip } from "antd";
import {
  CheckCircleTwoTone,
  InfoCircleTwoTone,
  SyncOutlined,
} from "@ant-design/icons";
import styles from "../FlipperDrawer.module.scss";
import { useFlipStatus } from "../shared/hooks/useFlipStatus";
import {
  IFlippedFacebookCampaignRes,
  FlipDataNode,
} from "shared/types/adReview";
import { DataNode } from "antd/lib/tree";

const FlipStatus = ({
  selectedTreeData,
  flippedCampaignRes,
}: {
  selectedTreeData: DataNode[];
  flippedCampaignRes: IFlippedFacebookCampaignRes[];
}) => {
  const { totalCnt, loadingCnt, flippedCnt, failedCnt, dataSource } =
    useFlipStatus(selectedTreeData, flippedCampaignRes);

  const columns = [
    {
      title: "",
      dataIndex: "status",
      key: "status",
      width: "3%",
      render: (status: string, record: FlipDataNode) =>
        !status ? (
          <SyncOutlined className={styles.loadingIcon} spin />
        ) : status === "success" ? (
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        ) : status === "error" ? (
          <Tooltip title={record.message}>
            <InfoCircleTwoTone twoToneColor="#eb2f96" />
          </Tooltip>
        ) : null,
    },
    {
      title: <Typography.Text strong>Flipping progress</Typography.Text>,
      dataIndex: "title",
      key: "title",
      width: "97%",
      render: (title: string, record: FlipDataNode) => (
        <Tag className={!record.children ? styles.child : undefined}>
          {title}
        </Tag>
      ),
    },
  ];

  return (
    <Row className={styles.container}>
      <Col className={styles.column}>
        <Space direction="horizontal">
          <Typography.Text>{totalCnt} items</Typography.Text>
          <Button size="small">
            <SyncOutlined className={styles.loadingIcon} />
            <Typography.Text>{loadingCnt} Loading</Typography.Text>
          </Button>
          <Button size="small">
            <CheckCircleTwoTone twoToneColor="#52c41a" />
            <Typography.Text>{flippedCnt} Flipped</Typography.Text>
          </Button>
          <Button size="small">
            <InfoCircleTwoTone twoToneColor="#eb2f96" />
            <Typography.Text>{failedCnt} Failed</Typography.Text>
          </Button>
        </Space>
        <Table
          className={styles.table}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          expandable={{
            defaultExpandAllRows: true,
            expandIcon: () => null,
          }}
        />
      </Col>
    </Row>
  );
};

export default FlipStatus;
