import { useCallback } from "react";
import { AbsoluteFill, Series, Video, useCurrentFrame } from "remotion";
import { CompositionType, TComposition } from "shared/types/assetExporter";
import {
  endFramesDuration,
  isCompositionPlaying,
  startFrameDuration,
} from "../../shared/components/Preview/utils";
import { useAssetBatchesContext } from "../../shared/contexts/AssetBatchesContext";
import { useAssetBatchesMediaRenderContext } from "../../shared/contexts/AssetBatchesMediaRenderContext";
import { useAssetBatchesValueMappingContext } from "../../shared/contexts/AssetBatchesValueMappingContext";
import { useVideoStitchingContext } from "../../shared/contexts/VideoStitchingContext";
import { fadeIn } from "../../shared/utils";
import { VideoCanvas } from "./VideoCanvas";
import { VideoCanvasForPreview } from "./VideoCanvasForPreview";
import {
  DEFAULT_CANVAS_WIDTH,
  DEFAULT_CANVAS_HEIGHT,
} from "../../assetBatchDrawer/dynamicText/PanelTemplate";

interface Props {
  fps: number;
  playerWidth?: number;
  playerHeight?: number;
  playing: boolean;
  currentFrame: number;
}

export const useVideoCanvas = ({
  fps,
  playerWidth = DEFAULT_CANVAS_WIDTH,
  playerHeight = DEFAULT_CANVAS_HEIGHT,
  playing,
  currentFrame,
}: Props) => {
  const { videoRef } = useAssetBatchesMediaRenderContext();
  const { selectedRow } = useAssetBatchesValueMappingContext();
  const fadeDuration = Math.round(0.5 * fps);
  const frame = useCurrentFrame();
  const { compositions } = useAssetBatchesContext();
  const { backgroundAudios } = useVideoStitchingContext();

  const getSerieByCompositionType = useCallback(
    (
      composition: TComposition,
      index: number,
      actualDuration: number,
      isCompositionPreview: boolean,
      isVideo: boolean,
      convertedSrc: string,
    ) => {
      let duration = composition.duration * fps;
      let fromDuration = 0;
      if (index !== 0) {
        fromDuration = -fadeDuration;
        duration += fadeDuration;
      }

      let opacityIn = 1;
      if (!isCompositionPreview && index !== 0) {
        opacityIn = fadeIn(
          frame,
          actualDuration - composition.duration * fps - fadeDuration,
          actualDuration - composition.duration * fps,
        );
      }

      const isCompoPlaying = isCompositionPlaying(
        startFrameDuration(compositions, index),
        endFramesDuration(compositions, index),
        currentFrame,
      );

      if (backgroundAudios) {
        Object.entries(backgroundAudios).forEach(([key, audio]) => {
          if (
            isCompoPlaying &&
            !key.includes(composition.compositionId) &&
            audio &&
            !audio?.paused
          ) {
            audio.pause();
          }
        });
      }

      switch (composition.type) {
        case CompositionType.Template:
          return (
            <Series.Sequence
              durationInFrames={duration}
              offset={fromDuration}
              layout="none"
              key={composition.compositionId}
            >
              <AbsoluteFill>
                {isCompositionPreview ? (
                  <VideoCanvasForPreview
                    key={`videoCanvasForPreview-${selectedRow?.rowIdentifier}`}
                    compositionId={composition.compositionId}
                    opacity={opacityIn}
                    fps={fps}
                    playing={playing}
                    currentFrame={currentFrame}
                  />
                ) : (
                  <VideoCanvas
                    key={`videoCanvas-${selectedRow?.rowIdentifier}`}
                    compositionId={composition.compositionId}
                    width={playerWidth}
                    height={playerHeight}
                    opacity={opacityIn}
                    fps={fps}
                    playing={playing}
                    currentFrame={currentFrame}
                  />
                )}
              </AbsoluteFill>
            </Series.Sequence>
          );
        case CompositionType.Media:
          return (
            <Series.Sequence
              durationInFrames={duration}
              offset={fromDuration}
              key={composition.compositionId}
              layout="none"
            >
              <AbsoluteFill>
                {isVideo ? (
                  <Video
                    ref={videoRef}
                    src={convertedSrc}
                    style={{ opacity: opacityIn }}
                    muted={!composition.audioEnabled}
                  />
                ) : (
                  <img
                    src={convertedSrc}
                    style={{
                      opacity: opacityIn,
                      width: playerWidth,
                      height: playerHeight,
                    }}
                  />
                )}
              </AbsoluteFill>
            </Series.Sequence>
          );
        default:
          return <></>;
      }
    },
    [
      fps,
      frame,
      fadeDuration,
      compositions,
      backgroundAudios,
      currentFrame,
      selectedRow,
      playing,
      playerWidth,
      playerHeight,
      videoRef,
    ],
  );

  const getVideoCanvas = useCallback(
    (
      composition: TComposition,
      index: number,
      isCompositionPreview: boolean,
      isVideo: boolean,
      convertedSrc: string,
    ) => {
      if (composition.duration * fps <= 0) return <></>;
      const actualDuration = compositions
        .slice(0, index + 1)
        .reduce((acc, comp) => acc + (comp.duration ?? 5), 0);
      return getSerieByCompositionType(
        composition,
        index,
        actualDuration * fps,
        isCompositionPreview,
        isVideo,
        convertedSrc,
      );
    },
    [compositions, fps, getSerieByCompositionType],
  );

  return {
    getSerieByCompositionType,
    getVideoCanvas,
  };
};
