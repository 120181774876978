import { SearchOutlined } from "@ant-design/icons";
import { uniq, uniqBy } from "lodash";
import { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import Tags from "screens/designStudio/shared/Tags";
import { SearchFilterDropdown } from "shared/components/SearchFilterDropdown";
import { ITableColumnURL } from "shared/components/dataListURL/DataListURLTable";
import { NormalizedDisclosure } from "shared/types/salesEnablement";
import { ROUTES } from "../utils/constants";

export const useColumns = (
  disclosures: NormalizedDisclosure[],
): ITableColumnURL<keyof NormalizedDisclosure, NormalizedDisclosure>[] => {
  const getUniqOptions = useCallback(
    (key: keyof Pick<NormalizedDisclosure, "productTypes" | "locations">) => {
      return uniq(disclosures?.flatMap(t => t[key])).map((field: string) => ({
        text: field,
        value: field,
      }));
    },
    [disclosures],
  );

  return useMemo(
    () => [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        filterDropdown: SearchFilterDropdown,
        filterIcon: <SearchOutlined />,
        render: (_, record) => (
          <Link to={ROUTES.update(record.id)}>{record.name}</Link>
        ),
        width: 380,
      },
      {
        title: "Product",
        dataIndex: "products",
        key: "products",
        filters: uniqBy(
          disclosures?.flatMap(({ products }) => products),
          product => product.id,
        ).map(product => {
          return {
            text: product.name,
            value: `${product.id}/${product.name}`,
          };
        }),
        render: (_, record) => (
          <Tags tags={record.products.map(product => product)} />
        ),
      },
      {
        title: "Location",
        dataIndex: "locations",
        key: "locations",
        filters: getUniqOptions("locations"),
        width: 150,
        render: (_, record) =>
          record?.locations && (
            <Tags
              tags={record.locations?.map(location => ({
                id: location,
                name: location,
              }))}
            />
          ),
      },
      {
        title: "Product Type",
        dataIndex: "productTypes",
        key: "productTypes",
        filters: getUniqOptions("productTypes"),
        render: (_, record) => (
          <Tags
            tags={record.productTypes.map(productType => ({
              id: productType,
              name: productType,
            }))}
          />
        ),
        width: 200,
      },
      {
        title: "Language",
        dataIndex: "availableLanguages",
        key: "availableLanguages",
        width: 150,
        filters: uniq(disclosures?.flatMap(t => t.availableLanguages)).map(
          language => ({
            text: language,
            value: language,
          }),
        ),
        render: (_, record) => (
          <Tags
            tags={record.availableLanguages.map(language => ({
              id: language,
              name: language === "en" ? "English" : "Spanish",
            }))}
          />
        ),
      },
      {
        title: "Disclosure(s)",
        dataIndex: "disclosures",
        key: "disclosures",
        ellipsis: true,
        filterDropdown: SearchFilterDropdown,
        filterIcon: <SearchOutlined />,
        render: (_, record) => record.disclosures.join(","),
        width: 300,
      },
    ],
    [disclosures, getUniqOptions],
  );
};
