import { useParams, useNavigate } from "react-router-dom";
import { useUser } from "shared/hooks/useUser";
import { useBeeswaxCampaignsContext } from "../BeeswaxCampaignsProvider";
import {
  ROUTES,
  createBeeswaxCampaignMutationKey,
  updateBeeswaxCampaignMutationKey,
} from "../constants";
import {
  BeeswaxCampaignForm,
  BeeswaxCampaignURLParams,
  BeeswaxCampaignWithoutMetadata,
} from "../types";
import { useCreateBeeswaxCampaign } from "./useCreateBeeswaxCampaign";
import { useUpdateBeeswaxCampaign } from "./useUpdateBeeswaxCampaign";
import { useIsMutating } from "react-query";
import { message } from "antd";

export const useMutateBeeswaxCampaign = () => {
  const { draftLineItems } = useBeeswaxCampaignsContext();
  const { campaignId } = useParams<BeeswaxCampaignURLParams>();
  const navigate = useNavigate();
  const { email } = useUser();

  const onSuccess = () => {
    message.success("Successfully saved Campaign");
    navigate(ROUTES.home);
  };

  const { mutate: create } = useCreateBeeswaxCampaign({
    onSuccess: onSuccess,
  });
  const { mutate: update } = useUpdateBeeswaxCampaign(campaignId, {
    onSuccess: onSuccess,
  });

  const isCreating = useIsMutating(createBeeswaxCampaignMutationKey) > 0;
  const isUpdating = useIsMutating(updateBeeswaxCampaignMutationKey) > 0;

  const onSubmit = (values: BeeswaxCampaignForm) => {
    // Using the operator "!" because we are sure that the value is not null or undefined
    const campaign: BeeswaxCampaignWithoutMetadata = {
      name: values.name!,
      startDate: values.startDate!.startOf("day").utc().format(),
      endDate: values.endDate?.endOf("day").utc().format(),
      dailyBudget: values.dailyBudget,
      totalBudget: values.totalBudget!,
      updatedBy: email,
      lineItems: draftLineItems,
    };

    campaignId ? update(campaign) : create(campaign);
  };

  return {
    onSubmit,
    isSubmitting: campaignId ? isUpdating : isCreating,
  };
};
