import {
  StoreType,
  createStore as createPolotnoStore,
} from "polotno/model/store";
import { DesignStudioTableTemplate } from "screens/designStudio/context/DesignStudioDataProvider";
import {
  LayerElement,
  LayerType,
  PolotnoFigure,
  PolotnoImage,
  PolotnoJSON,
  PolotnoSVG,
  PolotnoText,
  TextLayerElement,
} from "../../types";
import { POLOTNO_API_KEY } from "../../constants";
import {
  positionHorizontalLeft,
  positionHorizontalRight,
  positionHorizontalCenter,
  positionVerticalBottom,
  positionVerticalCenter,
  positionVerticalTop,
  spaceHorizontally,
  spaceVertically,
} from "./helpers.positioning";
import {
  getSelectedElements,
  isGroupElement,
  isGroupTextLayer,
  isTextElement,
} from "../editorDrawerContent/polotnoEditor/utils";
import { splitIntoComponents } from "./helpers.variables";
import { capitalize } from "@mui/material";
import { AtLeast } from "utils/utilityTypes";

const storeConfig = POLOTNO_API_KEY
  ? { key: POLOTNO_API_KEY, showCredit: false }
  : undefined;

export const createStore = (item: DesignStudioTableTemplate) => {
  const store = createPolotnoStore(storeConfig);

  // Enable rulers
  store.toggleRulers(true);

  // Set default size to artboard size
  store.setSize(item.asset.artboard.width, item.asset.artboard.height);

  // Add an empty page for new templates
  if (!item.asset.canvasJsonUrl) store.addPage();

  return {
    store,
    assetId: item.asset.id ?? "",
  };
};

const getLayerTypeLength = (store: StoreType, type: LayerType) =>
  store.activePage?.children?.filter(el => {
    if (type === "group") return el.type === "group" || isGroupTextLayer(el);
    return el.type === type;
  }).length ?? 0;

const createBaseCustomParams = (store: StoreType, type: LayerType) => {
  const layerTypeNumber = getLayerTypeLength(store, type) + 1 ?? 1;
  return {
    custom: {
      tempName: `${capitalize(type)} ${layerTypeNumber}`,
    },
  };
};

export const centerElement = (
  store: StoreType,
  params: { width?: number; height?: number },
) => {
  const imageWidth = params.width ?? 0;
  const imageHeight = params.height ?? 0;

  return {
    x: (store.activePage.computedWidth - imageWidth) / 2,
    y: (store.activePage.computedHeight - imageHeight) / 2,
  };
};

const fontSizeMapping: { [fontSize: number]: number } = {
  34: 200,
  20: 150,
  16: 80,
};

export const getFontWidth = (fontSize: number) => {
  return fontSizeMapping[fontSize];
};

export type ImageObjectParams = AtLeast<PolotnoImage, "src">;
export const createImageObject = (
  store: StoreType,
  params: ImageObjectParams,
) => {
  store.activePage?.addElement({
    type: "image",
    ...createBaseCustomParams(store, "image"),
    ...centerElement(store, params),
    ...params,
  });
};

export type TextObjectParams = Partial<Omit<PolotnoText, "toggleEditMode">>;
export const createTextObject = (
  store: StoreType,
  params: Partial<PolotnoText>,
) => {
  const width = getFontWidth(Number(params.fontSize));

  const element = store.activePage?.addElement({
    type: "text",
    width,
    ...createBaseCustomParams(store, "text"),
    ...params,
    ...centerElement(store, {
      ...params,
      width,
    }),
  });
  element.toggleEditMode();
};

export type SVGObjectParams = Partial<PolotnoSVG>;
export const createSVGObject = (store: StoreType, params: SVGObjectParams) => {
  store.activePage?.addElement({
    type: "svg",
    keepRatio: false,
    ...createBaseCustomParams(store, "svg"),
    ...params,
    ...centerElement(store, params),
  });
};

export type FigureObjectParams = Partial<PolotnoFigure> &
  Pick<PolotnoFigure, "subType">;
export const createFigureObject = (
  store: StoreType,
  params: FigureObjectParams,
) => {
  store.activePage?.addElement({
    type: "figure",
    keepRatio: false,
    fill: "lightgray",
    ...createBaseCustomParams(store, "figure"),
    ...params,
    ...centerElement(store, params),
  });
};

export const getBase64Thumbnail = async (store: StoreType) => {
  const dataUrl = await store.toDataURL();
  return dataUrl.replace(/^data:image\/(jpg|jpeg|png);base64,/, "");
};

export const toJSON = (store: StoreType) => {
  // Polotno's types are not great yet, so we're casting to our own type for this MVP.
  return store.toJSON() as unknown as PolotnoJSON;
};

export const deleteSelectedElements = (store: StoreType) => {
  return store.deleteElements(store.selectedElements.map(el => el.id));
};

const getBaseLayerName = (layer: LayerElement) => {
  if (layer.name) return layer.name;
  if (layer?.custom?.tempName) return layer.custom.tempName;
  return layer.id;
};

const getTextLayerName = (layer: TextLayerElement) => {
  if (layer.name) return layer.name;
  const components = splitIntoComponents(layer.text);
  if (components.length === 1) return components[0];
  if (components.length > 1) {
    return `Group ${
      layer?.custom?.tempName ?? getLayerTypeLength(layer.store, "group") + 1
    }`;
  }
  return getBaseLayerName(layer);
};

export const getLayerName = (layer: LayerElement) => {
  if (isTextElement(layer)) return getTextLayerName(layer);
  return getBaseLayerName(layer);
};

const duplicateElement = (element: LayerElement) => {
  if (element.type === "group") {
    const groupClone = element.clone();
    groupClone.children.forEach(child => {
      child.set({
        x: child.x + 50,
        y: child.y + 50,
      });
    });
    return groupClone;
  }
  const clone = element.clone();
  clone.set({
    x: element.x + 50,
    y: element.y + 50,
  });
  return clone;
};

export const duplicateSelectedElements = (store: StoreType) => {
  const selectedElements = getSelectedElements(store);

  if (!selectedElements.length) return;

  const clonedIds = selectedElements.map((element: LayerElement) => {
    const duplicatedElement = duplicateElement(element);
    return duplicatedElement.id;
  });

  store.selectElements(clonedIds);
};

export type PositionType =
  | "horizontal-left"
  | "horizontal-right"
  | "horizontal-center"
  | "vertical-top"
  | "vertical-bottom"
  | "vertical-center";

export const positionSelectedElements = (
  store: StoreType,
  position: PositionType,
) => {
  const { selectedElements, activePage } = store;

  if (!selectedElements.length || !activePage) return;

  const { computedWidth: pageWidth, computedHeight: pageHeight } = activePage;
  const filteredElements = selectedElements.filter(el => el.type !== "group");

  switch (position) {
    case "horizontal-left":
      positionHorizontalLeft(filteredElements, pageWidth);
      break;
    case "horizontal-right":
      positionHorizontalRight(filteredElements, pageWidth);
      break;
    case "horizontal-center":
      positionHorizontalCenter(filteredElements, pageWidth);
      break;
    case "vertical-top":
      positionVerticalTop(filteredElements, pageHeight);
      break;
    case "vertical-bottom":
      positionVerticalBottom(filteredElements, pageHeight);
      break;
    case "vertical-center":
      positionVerticalCenter(filteredElements, pageHeight);
      break;
  }
};

export type LayeringAction = "moveUp" | "moveDown" | "toBottom" | "toForward";

export const moveSelectedElements = (
  store: StoreType,
  action: LayeringAction,
) => {
  const selectedElements = getSelectedElements(store);

  if (!selectedElements.length) return;

  switch (action) {
    case "moveUp":
      selectedElements.forEach(element => element.moveUp());
      break;
    case "moveDown":
      selectedElements.forEach(element => element.moveDown());
      break;
    case "toBottom":
      selectedElements.forEach(element => element.moveBottom());
      break;
    case "toForward":
      selectedElements.forEach(element => element.moveTop());
      break;
  }
};

export const toggleLockElements = (store: StoreType) => {
  const selectedElements = getSelectedElements(store);

  if (!selectedElements.length) return;

  selectedElements.forEach(element => {
    element.set({
      contentEditable: !element.contentEditable,
      draggable: !element.draggable,
      styleEditable: !element.styleEditable,
      resizable: !element.resizable,
      removable: !element.removable,
    });
  });
};

export const groupSelectedElements = (store: StoreType) => {
  const selectedElements = getSelectedElements(store);
  const containsGroupElements = selectedElements.some(isGroupElement);

  if (!selectedElements.length || containsGroupElements) return;

  return store.groupElements(selectedElements.map(el => el.id));
};

export const ungroupSelectedElements = (store: StoreType) => {
  const selectedElements = getSelectedElements(store);
  const containsGroupElements = selectedElements.some(isGroupElement);

  if (!selectedElements.length || !containsGroupElements) return;

  return store.ungroupElements(selectedElements.map(el => el.id));
};

export type SpacingType = "space-horizontal" | "space-vertical";

export const spaceSelectedElements = (
  store: StoreType,
  spacing: SpacingType,
) => {
  const { selectedElements, activePage } = store;

  if (!selectedElements.length || !activePage) return;

  const { computedWidth, computedHeight } = activePage;
  const filteredElements = selectedElements.filter(el => el.type !== "group");

  switch (spacing) {
    case "space-horizontal":
      spaceHorizontally(filteredElements, computedWidth);
      break;
    case "space-vertical":
      spaceVertically(filteredElements, computedHeight);
      break;
  }
};

export const DOWNLOAD_FILE_TYPES = [
  "PNG",
  "JPEG",
  "PDF",
  "GIF",
  "HTML",
] as const;

export type DownloadFileType = (typeof DOWNLOAD_FILE_TYPES)[number];

type DownloadParams = {
  fileName: string;
  type: DownloadFileType;
  pixelRatio: number;
  fps: number;
  pageSizeModifier: number;
};

const getDownloadMimeType = (type: DownloadFileType) => {
  if (type === "PNG") return "image/png";
  if (type === "JPEG") return "image/jpeg";
  return "image/png";
};

const downloadImages = async (store: StoreType, params: DownloadParams) => {
  const save = params.type === "GIF" ? store.saveAsGIF : store.saveAsImage;

  await Promise.allSettled(
    store.pages.map(({ id }) =>
      save({
        pageId: id,
        mimeType: getDownloadMimeType(params.type),
        ...params,
      }),
    ),
  );
};

type DownloadStrategy = (
  store: StoreType,
  params: DownloadParams,
) => Promise<void>;

const downloadStrategies: Record<DownloadFileType, DownloadStrategy> = {
  PNG: downloadImages,
  JPEG: downloadImages,
  GIF: downloadImages,
  PDF: (store, params) =>
    store.saveAsPDF({
      ...params,
      dpi: store.dpi / params.pageSizeModifier,
      pixelRatio: params.pixelRatio * 2,
    }),
  HTML: (store, params) => store.saveAsHTML(params),
};

export const download = (store: StoreType, params: DownloadParams) => {
  return downloadStrategies[params.type](store, params);
};

export const toPNGBlobInputs = (store: StoreType, fileName: string) => {
  return Promise.all(
    store.pages.map(async ({ id }, index) => ({
      blob: (await store.toBlob({
        pageId: id,
        mimeType: "image/png",
      })) as Blob, // Polotno's types are not great yet, so we're casting to our own type for this MVP.
      fileName: `${fileName}-${index}.png`,
    })),
  );
};
