import { Col, Row, Select } from "antd";
import {
  INPUT_TYPES,
  INPUT_TYPE_TITLE,
} from "screens/assetExporter/feedConfigurationV2/shared/constants";
import { useAssetBatchesContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesContext";
import { useAssetBatchesTemplateCompositionContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesTemplateCompositionContext";
import { TInputType } from "screens/assetExporter/feedConfigurationV2/shared/types";
import styles from "../PanelVariables.module.scss";

type SelectInputTypeProps = {
  inputType: TInputType;
  mappingKey: string;
  variableType: string;
};

export const SelectInputType = ({
  inputType,
  mappingKey,
  variableType,
}: SelectInputTypeProps) => {
  const { setShowTextAlert } = useAssetBatchesContext();
  const { onSelectInputType } = useAssetBatchesTemplateCompositionContext();
  const filteredInputTypes = INPUT_TYPES.filter(type =>
    ["cssStyle", "cssAttribute"].includes(variableType)
      ? type !== "regex"
      : true,
  );

  return (
    <Row>
      <Col span={24}>
        <Select<TInputType>
          value={inputType}
          className={styles.input}
          onSelect={val => {
            setShowTextAlert(false);
            onSelectInputType(mappingKey, val);
          }}
        >
          {filteredInputTypes.map(type => (
            <Select.Option key={type} value={type}>
              {INPUT_TYPE_TITLE[type]}
            </Select.Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
};
