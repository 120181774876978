import { observer } from "mobx-react-lite";
import { Stack, IconButton, Typography } from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { useStoreZoom } from "screens/designStudio/designStudioV2/hooks/useStoreZoom";
import { StoreType } from "screens/designStudio/designStudioV2/types";

interface ZoomControlsProps {
  store: StoreType;
}

const ZoomControls = observer(({ store }: ZoomControlsProps) => {
  const { zoomIn, zoomOut } = useStoreZoom(store);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{
        position: "fixed",
        bottom: 20,
        left: `calc(50% + ${130}px)`,
        backgroundColor: "action.hover",
        borderRadius: 1,
      }}
    >
      <IconButton onClick={zoomOut} sx={{ borderRadius: 1 }}>
        <ZoomOutIcon />
      </IconButton>
      <Typography component="div">{Math.round(store.scale * 100)}%</Typography>
      <IconButton onClick={zoomIn} sx={{ borderRadius: 1 }}>
        <ZoomInIcon />
      </IconButton>
    </Stack>
  );
});

export default ZoomControls;
