import { MediaPreview } from "../../../shared/components/MediaPreview";
import { isTemplateComposition } from "../../../shared/contexts/AssetBatchesContext.utils";
import { AssetBatchesMediaRenderProvider } from "../../../shared/contexts/AssetBatchesMediaRenderContext";
import { useAssetBatchesValueMappingContext } from "../../../shared/contexts/AssetBatchesValueMappingContext";
import { TComposition } from "shared/types/assetExporter";
import { DEFAULT_CANVAS_WIDTH } from "../PanelTemplate";
import { TemplatePreviewSection } from "../../../shared/components/TemplatePreviewSection";
import { useAssetBatchesContext } from "../../../shared/contexts/AssetBatchesContext";

interface Props {
  composition: TComposition;
  playerWidth?: number;
  zoomPercentage: number;
}

const CONTAINER_PADDING = 60;

export const CompositionPreview = ({
  composition,
  playerWidth,
  zoomPercentage,
}: Props) => {
  const { selectedRow } = useAssetBatchesValueMappingContext();
  const { templatesToUse: templates } = useAssetBatchesContext();
  if (!isTemplateComposition(composition)) {
    return (
      <AssetBatchesMediaRenderProvider key={composition.compositionId}>
        <MediaPreview
          row={selectedRow}
          editingComposition={composition}
          width={
            (playerWidth ? playerWidth : DEFAULT_CANVAS_WIDTH) -
            CONTAINER_PADDING
          }
          zoomLevel={zoomPercentage / 100}
        />
      </AssetBatchesMediaRenderProvider>
    );
  }

  const template = templates.find(
    compTemplate => compTemplate.id === composition.template,
  );

  return (
    <TemplatePreviewSection
      key={`template-preview-${composition.compositionId}-${selectedRow?.rowIdentifier}`}
      template={template}
      composition={composition}
      selectedRow={selectedRow}
    />
  );
};
