import { Row } from "antd";
import { FieldWrapper } from "screens/assetExporter/feedConfigurationV2/shared/components/FieldWrapper";
import {
  TInputType,
  TValueMapping,
  TVariable,
} from "screens/assetExporter/feedConfigurationV2/shared/types";
import { FieldTitle } from "../../shared/components/FieldTitle";
import styles from "./VariableInput.module.scss";
import { useHightlightVarEvents } from "./hooks/useHighlightVarEvents";
import InputValue from "./variableInput/InputValue";
import { SelectInputType } from "./variableInput/SelectInputType";

type VariableInputProps = {
  variable: TVariable;
  valueMapping?: TValueMapping;
  mappingKey: string;
};

const getInputValueTitle = (inputType: TInputType) => {
  if (inputType === "match_to_column") return "Column";
  if (inputType === "text") return "Text";
  return "";
};

export const TextVariableInput = ({
  variable,
  valueMapping,
  mappingKey,
}: VariableInputProps) => {
  const inputType = valueMapping?.inputType ?? "match_to_column";
  const { onMouseEnter, onMouseLeave, onFocus, onBlur, onValueChange } =
    useHightlightVarEvents(mappingKey);

  const inputValueProps = {
    inputType: inputType,
    value: valueMapping?.value,
    mappingKey: mappingKey,
    onFocus: onFocus,
    onBlur: onBlur,
    onValueChange: onValueChange,
  };
  return (
    <>
      <FieldTitle title={variable.variable} />
      <Row
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={styles.row}
        justify="space-around"
        align="top"
      >
        <FieldWrapper
          label="Type"
          required
          width={inputType === "regex" ? 5 : undefined}
        >
          <SelectInputType
            variableType={variable.type}
            inputType={inputType}
            mappingKey={mappingKey}
          />
        </FieldWrapper>
        {inputType !== "regex" && (
          <FieldWrapper label={getInputValueTitle(inputType)} required>
            <InputValue
              {...inputValueProps}
              inputFieldType={
                inputType === "match_to_column" ? "select" : "input"
              }
            />
          </FieldWrapper>
        )}

        {inputType === "regex" && (
          <>
            <FieldWrapper label="Refer column" required>
              <InputValue
                {...inputValueProps}
                inputFieldType="select"
                placeholder="select"
              />
            </FieldWrapper>

            <FieldWrapper label="Regex" required>
              <InputValue {...inputValueProps} inputFieldType="input" />
            </FieldWrapper>
          </>
        )}
      </Row>
    </>
  );
};
