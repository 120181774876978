import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useGetSession from "shared/hooks/genAI/useGetSession";
import uuid from "uuid";

export const useAiToolsSession = () => {
  const navigate = useNavigate();
  const { sessionId } = useParams<{ sessionId: string }>();
  const { session, isLoading } = useGetSession({
    id: sessionId,
  });

  useEffect(() => {
    if (!sessionId) {
      const newId = uuid.v4();
      navigate(`${newId}`);
    }
  }, [navigate, sessionId]);

  return {
    session,
    isLoadingSession: isLoading,
  };
};
