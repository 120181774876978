import { InfoCircleOutlined } from "@ant-design/icons";
import { Space, Tooltip, Typography, Upload } from "antd";
import { UploadFileStatus } from "antd/lib/upload/interface";
import produce from "immer";
import { memo, useMemo, useState } from "react";
import { AdType } from "screens/adLibrary/facebookUtils/types";
import { CAMConfig } from "shared/components/media";
import { AdMedia, MultiUploadSizing } from "shared/types/uploadManagement";
import { generateGuidFilename, getBase64DataUrl } from "utils/helpers";
import { uploadMediaV2, uploadVideoToFB } from "utils/uploadMedia";

import styles from "./MultiFileUploadDragger.module.scss";
import { getFileList, validateFileSize } from "./utils.file";
import { DropPlaceholder } from "./DropPlaceholder";
import { CAMUploadButton } from "./CAMUploadButton";

interface IProps {
  multiFileUrls: AdMedia[];
  setMultiFileUrls: React.Dispatch<React.SetStateAction<AdMedia[]>>;
  placementSizes: MultiUploadSizing[];
  adType: AdType;
}

const SizingInfo: Record<
  MultiUploadSizing,
  { label: string; tooltip: string }
> = {
  IN_FEED: { label: "In feed (1:1)", tooltip: "In-Feed media size: 1080x1080" },
  STORY: { label: "Story (9:16)", tooltip: "Story Media Size: 1080x1920" },
};

type Props = {
  file: AdMedia | { size: MultiUploadSizing };
  setFile(adMedia: AdMedia): void;
  removeFile(): void;
  uploadStatus: UploadFileStatus | "default";
  setUploadStatus(uploadStatus: UploadFileStatus): void;
  adType: AdType;
};

const FileUploadDragger = ({
  file,
  setFile,
  removeFile,
  uploadStatus,
  setUploadStatus,
  adType,
}: Props) => {
  const handleFileSizeValidation = (fileBlob: Blob) =>
    validateFileSize(fileBlob, () => setUploadStatus("error"));

  const isUploading = uploadStatus === "uploading";

  const convertWDtoUploadable = async (
    rows: WDAsset[],
    size: MultiUploadSizing,
  ) => {
    const [asset] = rows;
    if (["mov", "mp4"].includes(asset.filetype)) {
      const uploadedVideo = await uploadVideoToFB(asset.hiResURLRaw);
      return {
        assetId: uploadedVideo.assetId!,
        thumbnail: uploadedVideo.thumbnail,
        videoUrl: uploadedVideo.videoUrl,
        filetype: "video",
        size,
        filename: asset.name,
      } satisfies AdMedia;
    }
    return {
      thumbnail: asset.hiResURLRaw,
      filetype: "image",
      imageUrl: asset.hiResURLRaw,
      size,
      filename: asset.name,
    } satisfies AdMedia;
  };

  const fileList = useMemo(() => getFileList(file), [file]);

  return (
    <>
      <Upload
        fileList={fileList}
        className="ad-carousel-card-upload"
        data-cy="ad-media-upload"
        multiple={false}
        accept={adType === AdType.Still ? "image/*" : "video/*"}
        listType="picture-card"
        onRemove={removeFile}
        beforeUpload={handleFileSizeValidation}
        customRequest={async ({ file: resultFile, onSuccess, onError }) => {
          setUploadStatus("uploading");
          const assetFile = resultFile as File;
          const imageDataUrl = await getBase64DataUrl(assetFile);

          const mediaToUpload = {
            file: imageDataUrl,
            filename: generateGuidFilename(assetFile.name),
            size: assetFile.size,
            type: assetFile.type,
            mediaSizing: file.size,
          };

          try {
            const assetData = await uploadMediaV2(mediaToUpload, "card-asset");
            onSuccess?.(assetData, new XMLHttpRequest());
            setFile(assetData);

            setUploadStatus("done");
          } catch (error) {
            onError?.(error as Error);
            setUploadStatus("error");
          }
        }}
      >
        {!fileList && !isUploading && <DropPlaceholder />}
      </Upload>
      {CAMConfig.active && (
        <>
          <CAMUploadButton
            onClose={async (assets: WDAsset[]) => {
              setUploadStatus("uploading");
              setFile(await convertWDtoUploadable(assets, file.size));
              setUploadStatus("done");
            }}
            uploadStatus={uploadStatus}
            isUploading={isUploading}
            filename={"filename" in file ? file.filename : undefined}
          />
        </>
      )}
    </>
  );
};

const MultiFileUploadDragger = ({
  multiFileUrls,
  setMultiFileUrls,
  placementSizes,
  adType,
}: IProps) => {
  const [uploadStatus, setUploadStatus] = useState<
    (UploadFileStatus | "default")[]
  >(
    placementSizes.map((_, placementIndex) =>
      multiFileUrls[placementIndex] ? "done" : "default",
    ),
  );

  const getFile = (
    size: MultiUploadSizing,
  ): AdMedia | { size: MultiUploadSizing } => {
    return (
      multiFileUrls.find(file => file?.size === size) || {
        size,
      }
    );
  };

  return (
    <Space align="start">
      {placementSizes.map((size, placementIx) => {
        const file = getFile(size);

        return (
          <div key={size} className={styles.placementSizesContainer}>
            <Typography.Text className={styles.label}>
              {SizingInfo[size].label}
              <Tooltip title={SizingInfo[size].tooltip}>
                <InfoCircleOutlined className={styles.infoIcon} />
              </Tooltip>
            </Typography.Text>
            <FileUploadDragger
              key={size}
              file={file}
              adType={adType}
              removeFile={() =>
                setMultiFileUrls(
                  produce<AdMedia[]>(oldMedia => {
                    oldMedia.splice(placementIx, 1);
                  }),
                )
              }
              setFile={newFile => {
                setMultiFileUrls(
                  produce<AdMedia[]>(oldMedia => {
                    oldMedia[placementIx] = newFile;
                  }),
                );
              }}
              uploadStatus={uploadStatus[placementIx]}
              setUploadStatus={status => {
                setUploadStatus(
                  produce(oldStatus => {
                    oldStatus[placementIx] = status;
                  }),
                );
              }}
            />
          </div>
        );
      })}
    </Space>
  );
};

export default memo(MultiFileUploadDragger);
