import { GetMatchColumnsTableData, RowType } from "../types";
import { isArtboardRatioValid } from "./isArtboardRatioValid";
import { getThumbnailURL } from "./getThumbnailURL";
import { COLUMN_ASSET_BATCHES } from "shared/constants/assetExporter";

export const getMatchColumnsTableData: GetMatchColumnsTableData = ({
  assetBatches,
  selectedAssetBatches,
  feedColumns,
  artboards,
  selectedRows,
}) => {
  const selectedAssetBatchesData = assetBatches.filter(
    assetBatch =>
      assetBatch.assetBatchId &&
      selectedAssetBatches.includes(assetBatch.assetBatchId),
  );
  const rowIdentifier = selectedRows[0];
  const assetBatchMediaRows = selectedAssetBatchesData.map(
    (assetBatchData, index) => ({
      title: assetBatchData.name,
      key: "assetMedia" + index,
      assetBatchName: assetBatchData.name,
      assetBatchId: assetBatchData.assetBatchId,
      thumbnailURL: getThumbnailURL(
        assetBatchData.feedId,
        assetBatchData?.assetBatchId,
        assetBatchData.createdAt,
        rowIdentifier,
      ),
      isValidDimensions: isArtboardRatioValid(
        artboards,
        assetBatchData?.artboardName,
      ),
      rowType: RowType.Media,
    }),
  );

  const assetBatchNameRows = selectedAssetBatchesData.map(
    (assetBatchData, index) => ({
      title: "Asset Name",
      key: "assetName" + index,
      tooltipText: "Asset names created in the rename step",
      assetBatchName: assetBatchData.name,
      assetBatchId: assetBatchData.assetBatchId,
      rowType: RowType.AssetName,
    }),
  );

  const columnTitleRows = feedColumns
    .filter(
      feedColumn =>
        typeof feedColumn.title === "string" &&
        !!feedColumn.title &&
        !!feedColumn.key &&
        feedColumn.key !== COLUMN_ASSET_BATCHES,
    )
    .map(feedColumn => ({
      title: typeof feedColumn.title === "string" ? feedColumn.title : "",
      key: feedColumn.key,
      rowType: RowType.Feed,
    }));

  return [...assetBatchMediaRows, ...assetBatchNameRows, ...columnTitleRows];
};
