import { Typography, Form, InputNumber } from "antd";
import { numberInputFormatter } from "screens/campaignManagement/googlePerformanceMax/pmaxCampaignsTable/newCampaignDrawer/newCampaignForm/helpers";
import { budgetRule } from "./constants";
import { useBeeswaxCampaignsContext } from "./BeeswaxCampaignsProvider";
import { toUSD } from "utils/helpers.currency";
import { Rule } from "antd/lib/form";

const { Text } = Typography;

const LineItemBudget = () => {
  const { lineItemId, draftLineItems } = useBeeswaxCampaignsContext();
  const { form: campaignFormInstance } = useBeeswaxCampaignsContext();
  const campaignForm = campaignFormInstance.getFieldsValue();

  const getAvailableBudget = () => {
    const usedBudget = draftLineItems.reduce((acc, lineItem) => {
      if (lineItem.id === lineItemId) {
        return acc;
      }

      return acc + (lineItem.budget ?? 0);
    }, 0);

    return (campaignForm?.totalBudget ?? 0) - usedBudget;
  };

  const availableBudget = getAvailableBudget();

  const rules: Rule[] = [
    budgetRule,
    {
      validator: (_, value) => {
        if (value > availableBudget) {
          return Promise.reject(
            "Budget cannot exceed the total budget of the campaign",
          );
        }
        return Promise.resolve();
      },
    },
  ];

  return (
    <Form.Item
      label={<Text>Budget</Text>}
      name="budget"
      rules={rules}
      help={
        <Text type="secondary" style={{ fontSize: 14 }}>
          Available: {toUSD(availableBudget)}
        </Text>
      }
    >
      <InputNumber
        formatter={numberInputFormatter}
        style={{ width: "100%" }}
        precision={2}
      />
    </Form.Item>
  );
};

export default LineItemBudget;
