import { message } from "antd";
import { useQuery } from "react-query";
import API from "services";
import { IGenFeatureSessionState, ISessionResponse } from "shared/types/genAI";

const timeInterval = 5000;
const maxRetries = 14;

const useGetSession = (args: { id: string | undefined }) => {
  const { data, isLoading, error } = useQuery<
    ISessionResponse<IGenFeatureSessionState> | null,
    Error
  >(
    ["getSession", args.id],
    () =>
      args.id ? API.services.genAI.getSession(args.id) : Promise.resolve(null),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: (refetchedData, query) => {
        const shouldRefetch =
          query.state.dataUpdateCount < maxRetries &&
          refetchedData?.status === "pending" &&
          !error;

        if (shouldRefetch) {
          return timeInterval;
        }
        return false;
      },
      enabled: !!args.id,
      onError: error => {
        if (error) {
          message.info(`${error.message}. A new session will be created`, 10);
        } else {
          message.error("Something went wrong, please try again.", 10);
        }
      },
    },
  );

  return { session: data, isLoading, error };
};

export default useGetSession;
