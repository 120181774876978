import { keys } from "lodash";
import API from "services";
import { MediaUrlData } from "shared/types/assetExport";
import {
  CompositionType,
  ICondition,
  IMedia,
  MediaColumn,
  MediaInputError,
  MediaSubtype,
  TAssetBatch,
  TComposition,
  TTemplateComposition,
} from "shared/types/assetExporter";
import { ITemplate } from "shared/types/designStudio";
import {
  checkIfItIsVideoSrc,
  convertDropboxUrl,
  extractExtension,
  getGoogleDriveFileExtension,
  getGoogleDriveFileUrl,
  isGDriveUrl,
  isValidMediaExtension,
} from "utils/helpers.adEngine";
import {
  extractVariables,
  setTextboxDataForCanvas,
} from "../../assetBatchDrawer/dynamicText/utils.variable";
import { getValueMappingKey } from "../../assetBatchDrawer/utils";
import { DEFAULT_DURATION } from "../constants";
import {
  TBackgroundMedia,
  TColumnValue,
  TInputType,
  TMediaResizeType,
  TMediaType,
  TObjValue,
  TValue,
  TValueMapping,
  TValueMappings,
  TVariable,
  mediaTypes,
} from "../types";
import {
  isCarCut,
  isColumnValue,
  isImageRect,
  isLogo,
  isLogoValue,
  isTColumnValue,
  isTextbox,
} from "../validators";
import { TRuleCondition, getEmptyRuleCondition } from "./AssetBatchesContext";

export const parseConditions = (conditions: ICondition[]): TRuleCondition[] => {
  return conditions.map((condition, index) => {
    const { type, leftEq, op, rightEq } = condition;
    const operator =
      type === "if" ? "and" : (type.split("-")[0] as "and" | "or");
    return {
      index,
      operator,
      columnName: leftEq.value,
      comparisonOperator: op,
      value: rightEq,
    };
  });
};

export const parseCompositionToVariables = (
  compositions: TTemplateComposition[],
) => {
  return compositions.map(composition => ({
    compositionId: composition.compositionId,
    variables: parseValueMappings(composition.variables),
  }));
};

export const parseValueMappings = (valueMappings: TValueMappings) => {
  return keys(valueMappings).reduce<TVariable[]>((acc, key) => {
    return [...acc, valueMappings[key].variable];
  }, []);
};

export const getBackgroundValueMapping = (valueMappings: TValueMappings) => {
  const bgValueMappingKey = keys(valueMappings).find(key => {
    const valueMapping = valueMappings[key];
    if (valueMapping.variable.variable === "Theme Background") return key;
  });

  if (!bgValueMappingKey) return;

  return valueMappings[bgValueMappingKey];
};

export const getBackgroundMedia = (media: IMedia[], selectedRow: any) => {
  if (media.length === 0) return;
  if (media[0].column)
    return getBackgroundMediaForUrl(
      selectedRow[media[0].column],
      media[0].column,
    );
  if (media[0].src) return getBackgroundMediaForUrl(media[0].src);
};

export const getBackgroundMediaForComposition = async (
  composition: TComposition,
  selectedRow: any,
) => {
  if (!composition.variables) return;
  const backgroundColumnKey = Object.keys(composition.variables).find(key =>
    key.includes("Background"),
  );
  if (!backgroundColumnKey) return;

  const value = composition.variables[backgroundColumnKey]?.value;
  if (!isColumnValue(value) && typeof value === "string")
    return getBackgroundMediaForUrl(value);
  if (!isColumnValue(value)) return;

  return getBackgroundMediaForUrl(selectedRow[value.column], value.column);
};

export const getBackgroundResizeType = (
  valueMappings: TValueMappings,
): TMediaResizeType => {
  const bgValueMappingKey = keys(valueMappings).find(key => {
    const valueMapping = valueMappings[key];
    if (valueMapping.variable.variable === "Theme Background") return key;
  });

  if (!bgValueMappingKey) return "fill";

  return valueMappings[bgValueMappingKey].resizeType ?? "fill";
};

export const generateValueMappings = (variables: TVariable[], row: any) => {
  if (!row) return {};
  return variables.reduce<TValueMappings>((acc, variable) => {
    const key = getValueMappingKey(variable);
    const inputType: TInputType = "match_to_column";

    const keyExistInRow = variable.variable in row;
    const value: TColumnValue | undefined = keyExistInRow
      ? {
          column: variable.variable,
          type: "match_to_column",
        }
      : undefined;

    return {
      ...acc,
      [key]: {
        variable,
        inputType,
        value,
      },
    };
  }, {});
};

const getRegexValue = (mapping: TValueMapping, row: any) => {
  if (!isColumnValue(mapping.value) || !mapping.value.regexPattern) {
    return;
  }
  try {
    const regex = new RegExp(mapping.value.regexPattern);
    const rowVal = row[mapping.value.column];
    return rowVal.match(regex);
  } catch (e) {}
};

export const getValueForVarType = (
  variable: TVariable,
  rowValue: TValue | undefined,
  mediaMask: boolean = true,
  textMask: boolean = true,
) => {
  if (mediaTypes.includes(variable.type)) {
    return mediaMask ? rowValue : undefined;
  }
  if (variable.type === "text") {
    return textMask ? rowValue : undefined;
  }
  return rowValue;
};

export const updateValueMappingsForRow = (
  valueMappings: TValueMappings,
  row: any,
  mediaMask: boolean = true,
  textMask: boolean = true,
) => {
  return keys(valueMappings).reduce<TValueMappings>((acc, key) => {
    const mapping = valueMappings[key];
    let value;
    switch (mapping.inputType) {
      case "text":
        value = getValueForVarType(
          mapping.variable,
          mapping.value,
          mediaMask,
          textMask,
        );
        break;
      case "regex":
        if (!isColumnValue(mapping.value) || !mapping.value.regexPattern)
          // prev value must be TColumnValue type otherwise, there is nothing we can do. Error!
          return {
            ...acc,
            [key]: mapping,
          };
        value = getValueForVarType(
          mapping.variable,
          getRegexValue(mapping, row),
          mediaMask,
          textMask,
        );
        break;
      case "match_to_column":
        if (!isColumnValue(mapping.value))
          // prev value must be TColumnValue type otherwise, there is nothing we can do. Error!
          return {
            ...acc,
            [key]: mapping,
          };
        value = getValueForVarType(
          mapping.variable,
          row[mapping.value.column],
          mediaMask,
          textMask,
        );
        break;
    }
    return {
      ...acc,
      [key]: {
        ...mapping,
        value: {
          ...(mapping.value as TObjValue),
          value,
        },
      },
    };
  }, {});
};

export const getUpdatedRuleConditions = (
  filteredRuleCond: TRuleCondition[],
) => {
  if (!filteredRuleCond.length) return [getEmptyRuleCondition(0)];

  return filteredRuleCond;
};

export const getMediaArray = (
  backgroundMedia: TBackgroundMedia | undefined,
) => {
  if (!backgroundMedia) return [];
  return backgroundMedia.column
    ? [{ column: backgroundMedia.column }]
    : [{ src: backgroundMedia.src }];
};

export const visibleColumnsFilter = (columnsName: string) =>
  !["rowIdentifier", "lastUpdated", "Process", "build_assets"].includes(
    columnsName,
  );

export const validateUrl = (url: string) => {
  const cleanUrl = convertDropboxUrl(url);
  try {
    new URL(cleanUrl);
  } catch (e) {
    return false;
  }
  if (isGDriveUrl(url)) return true;
  const ext = extractExtension(cleanUrl);
  return isValidMediaExtension(ext);
};

export const getBackgroundMediaForUrl = async (
  url: string,
  col?: string,
): Promise<TBackgroundMedia | undefined> => {
  const cleanUrl = convertDropboxUrl(url);
  const ext = isGDriveUrl(url)
    ? await getGoogleDriveFileExtension(url)
    : extractExtension(cleanUrl);

  if (!isValidMediaExtension(ext)) {
    return;
  }

  const isGdriveSrc = isGDriveUrl(url);
  const src = isGdriveSrc
    ? await getGoogleDriveFileUrl(url)
    : convertDropboxUrl(url);

  return {
    src,
    column: col,
    type: ext as TMediaType,
  };
};

export const hasMediaInputError = (
  previewCount: number,
  value?: string,
  valueMapping?: TValueMapping,
  mediaColumns?: MediaColumn[],
): MediaInputError => {
  if (!value || value === "") return "EMPTY";
  else if (!isColumnValue(valueMapping?.value) && !validateUrl(value))
    return "INVALID";
  const mediaColumn = mediaColumns?.find(col => col.columnName === value);
  if (!!mediaColumn && mediaColumn.count !== previewCount)
    return "COLUMN_DISPARITY";
};

export const getMappedMediaVars = (
  previewCount: number,
  allMediaVars: string[],
  valueMappings: TValueMappings,
  mediaColumn?: MediaColumn[],
) => {
  return allMediaVars.filter(key => {
    const valueMapping = valueMappings[key];
    const value = getInputValueForMapping(valueMapping);
    const hasError = hasMediaInputError(
      previewCount,
      value,
      valueMapping,
      mediaColumn,
    );
    return !hasError || hasError === "COLUMN_DISPARITY";
  });
};

export const getInputValueForMapping = (valueMapping?: TValueMapping) => {
  if (isColumnValue(valueMapping?.value)) return valueMapping?.value.column;
  if (isLogoValue(valueMapping?.value)) return valueMapping?.value.logoUrl;
  return valueMapping?.value;
};

export const getImageMappingValue = (
  valueMapping: TValueMapping,
  selectedRow: any,
) => {
  const val = valueMapping?.value;
  if (isColumnValue(val)) return selectedRow[val.column];
  if (isLogoValue(val)) return val.logoUrl;
  return val;
};

export const replaceBackground = (
  prevVal: Record<string, any> | undefined,
  compositionToRemove: TComposition,
) => {
  if (!prevVal) return;

  const newVals = { ...prevVal };
  delete newVals[compositionToRemove.compositionId];

  return newVals;
};

export const removeItemByCompositionId = (
  items: any[],
  compositionToRemove: TComposition,
) =>
  items.filter(
    item => item.compositionId !== compositionToRemove.compositionId,
  );

export const isTemplateComposition = (
  comp: TComposition,
): comp is TTemplateComposition => comp.type === CompositionType.Template;

export const findVideoSrcFromVariables = (
  variables: TValueMappings,
  rows: any[],
) => {
  let videoSrc = "";
  Object.keys(variables).forEach(async key => {
    const val = variables[key].value;

    if (typeof val === "string") {
      const { isVideo } = await checkIfItIsVideoSrc(val);
      if (isVideo) videoSrc = val;
    }
    if (isTColumnValue(val)) {
      const column = val.column;
      const videoRow = rows.find(async row => {
        const { isVideo: isRowVideo } = await checkIfItIsVideoSrc(
          row?.[column],
        );
        return isRowVideo;
      });
      if (videoRow) videoSrc = videoRow?.[column];
    }
  });
  return videoSrc;
};

export const replaceCompositionVariable = (
  composition: TComposition,
  newVariables: TValueMappings,
  compositionId: string,
) => {
  if (composition.compositionId === compositionId) {
    return {
      ...composition,
      variables: { ...newVariables },
    };
  }

  return composition;
};

export const replaceComposition = (
  oldCompositions: TComposition[],
  composition: TComposition,
) =>
  oldCompositions.map(oldComposition => {
    if (oldComposition.compositionId === composition.compositionId)
      return composition;

    return oldComposition;
  });

export const getVarsFromCanvas = (canvas: fabric.Canvas) => {
  const objs = canvas.getObjects();
  const textboxes = objs.filter(isTextbox);
  textboxes.forEach(textbox => setTextboxDataForCanvas(textbox));
  const images = objs.filter(obj => isCarCut(obj) || isImageRect(obj));
  const logos = objs.filter(obj => isLogo(obj));
  return [...textboxes, ...images, ...logos].reduce<Array<TVariable>>(
    (acc, obj) => {
      return [...acc, ...extractVariables(obj)];
    },
    [],
  );
};

export const getMediaError = (
  previewCount: number,
  value?: string,
  valueMapping?: TValueMapping,
  mediaColumns?: MediaColumn[],
) => {
  const error = hasMediaInputError(
    previewCount,
    value,
    valueMapping,
    mediaColumns,
  );
  if (error === "EMPTY")
    return "select a column containing media or type a url";
  else if (error === "INVALID") return "enter a valid URL";

  const mediaColumn = mediaColumns?.find(col => col.columnName === value);
  if (error === "COLUMN_DISPARITY" && mediaColumn)
    return `${
      previewCount - mediaColumn.count
    } of ${previewCount} rows contain invalid media links`;
};

export const getTemplatesPromises = (editingAssetBatch: TAssetBatch) => {
  return editingAssetBatch.compositions
    .map(composition =>
      isTemplateComposition(composition)
        ? API.services.designStudio.getTemplateById(composition.template)
        : undefined,
    )
    .filter(Boolean);
};

export const getNewCompositionInstance = (
  feedId: string,
  newTemplate: ITemplate,
  compositionCount: number,
  currentComposition?: TComposition,
  editingAssetBatches?: TAssetBatch,
  existingVariables?: TValueMappings,
) => {
  const isSingleComposition =
    compositionCount === 0 || (compositionCount === 1 && currentComposition);
  return {
    type: CompositionType.Template,
    compositionId: currentComposition
      ? currentComposition.compositionId
      : `composition_${newTemplate.id}_${Date.now()}`,
    feedId,
    template: newTemplate.id,
    variables: existingVariables ?? {},
    ...(editingAssetBatches?.assetBatchId && {
      assetBatchId: editingAssetBatches.assetBatchId,
    }),
    duration: isSingleComposition ? 0 : DEFAULT_DURATION,
    audioSelVariableId: [],
  } as TTemplateComposition;
};

export const isMediaCompositionFromFeed = (comp: TComposition) =>
  comp?.type === CompositionType.Media && comp?.subtype === MediaSubtype.Feed;

export const isMediaCompositionFromCAM = (comp: TComposition) =>
  comp?.type === CompositionType.Media && comp?.subtype === MediaSubtype.CAM;

export const getMediaByType = (
  types: string[],
  selectedRow: any,
  mediaUrlData?: MediaUrlData[],
  mediaColumns?: MediaColumn[],
) => {
  if (!mediaUrlData) return [];
  const filerColumns = mediaColumns?.filter(col => {
    const mediaUrl = mediaUrlData.find(
      urlData => urlData.originalUrl === selectedRow[col.columnName],
    );
    return mediaUrl && types.includes(mediaUrl.ext);
  });
  return filerColumns || [];
};
