import { useQueryParams } from "use-query-params";
import { useDataListURLContext } from "./dataListURLContext";
import { getEntries, getEmptyFilterQueryParams } from "./utils";

export const useDataListURLReset = <FieldKey extends string, RecordType>() => {
  const { fields, setSelectedIds } = useDataListURLContext<
    FieldKey,
    RecordType
  >();

  const [defaultSortKey, defaultField] = getEntries(fields).find(
    ([, field]) => !!field?.defaultSortOrder,
  ) ?? [undefined, {}];

  const [, setQuery] = useQueryParams();

  const resetAll = () => {
    const emptyFilterQueryParams = getEmptyFilterQueryParams(fields);

    setQuery(() => ({
      ...emptyFilterQueryParams,
      sortKey: defaultSortKey ?? "",
      sortOrder: defaultField?.defaultSortOrder ?? "",
      globalFilter: undefined,
    }));

    setSelectedIds([]);
  };

  return {
    resetAll,
  };
};
