import { IconButton } from "@mui/material";

type TooltipIconButtonProps = {
  onClick: () => void;
  children: React.ReactNode;
};

export const TooltipIconButton = ({
  onClick,
  children,
}: TooltipIconButtonProps) => {
  return (
    <IconButton
      size="small"
      sx={{
        borderRadius: 0,
        backgroundColor: "background.paper",
        "&:hover": { backgroundColor: "grey.300" },
      }}
      onClick={onClick}
    >
      {children}
    </IconButton>
  );
};
