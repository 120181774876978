import {
  useQuery,
  useMutation,
  useQueryClient,
  useIsMutating,
} from "react-query";
import { AgentProfile } from "screens/adLibrary/marketingMaterialDrawer/hooks/agentManagement";
import { AgentProfileFormValues } from "screens/agentProfiles/agentProfileDrawer/AgentProfileDrawerForm";
import { AgentProfileKeys } from "screens/agentProfiles/fields";
import API from "services";
import { useDataListURLData } from "shared/components/dataListURL/useDataListURLData";

const agentProfileKey = "agent-profiles";

export const useGetAgentProfiles = () => {
  return useQuery<AgentProfile[], Error>({
    queryKey: [agentProfileKey],
    queryFn: API.privServices.salesEnablement.getAgentProfiles,
  });
};

export const useAgentProfiles = () => {
  const { data, isLoading, isError } = useGetAgentProfiles();
  return useDataListURLData<AgentProfileKeys, AgentProfile>({
    data,
    isLoading,
    isError,
  });
};

export const useCreateAgentProfile = () => {
  const queryClient = useQueryClient();
  return useMutation<AgentProfile, Error, AgentProfileFormValues>({
    mutationFn: API.privServices.salesEnablement.createAgentProfile,
    mutationKey: [agentProfileKey, "new"],
    onSuccess: () => {
      return queryClient.invalidateQueries([agentProfileKey]);
    },
  });
};

export const useIsMutatingAgentProfile = () => {
  return useIsMutating([agentProfileKey]);
};

export const useUpdateAgentProfile = () => {
  const queryClient = useQueryClient();
  return useMutation<AgentProfile, Error, AgentProfile>({
    mutationFn: API.privServices.salesEnablement.updateAgentProfile,
    mutationKey: [agentProfileKey, "edit"],
    onSuccess: () => {
      return queryClient.invalidateQueries([agentProfileKey]);
    },
  });
};

export const useDeleteAgentProfiles = () => {
  const queryClient = useQueryClient();
  return useMutation<string[], Error, string[]>({
    mutationFn: API.privServices.salesEnablement.deleteAgentProfiles,
    onSuccess: () => {
      return queryClient.invalidateQueries([agentProfileKey]);
    },
  });
};
