import { Checkbox, Col, Divider, Form, Input, Row, Select } from "antd";
import { FC, useCallback, useState } from "react";

import { useSelector } from "react-redux";
import { FacebookAccountDetails } from "screens/adLibrary/shared/FacebookAccountDetails";
import GoogleAdAccountSelect from "screens/adLibrary/shared/GoogleAdAccountSelect";
import { useIsAdmin } from "shared/hooks/useIsAdmin";
import { IAccountRecord } from "shared/types/accountManagement";
import { GoogleAdAccount, IAdLibraryState } from "shared/types/adLibrary";
import { industryType } from "utils/helpers";
import { IFacebookAccount } from "../../adLibrary/facebookUtils/types";

const googlePMaxEnabled = process.env.REACT_APP_AV2_ENABLE_PMAX === "true";

interface DealerAdvancedStoreDetailsProps {
  dealerToEditWithLogos: IAccountRecord;
}

interface DealerAdvancedStoreDetailsHandlers {
  setDealerToEditWithLogos: (dealerToEditWithLogos: IAccountRecord) => void;
}

const DealerDetails: FC<
  DealerAdvancedStoreDetailsProps & DealerAdvancedStoreDetailsHandlers
> = ({ dealerToEditWithLogos, setDealerToEditWithLogos }) => {
  const isAdmin = useIsAdmin();

  const [selectedGoogleAccountCID, setSelectedGoogleAccountCID] = useState<
    GoogleAdAccount["id"] | undefined
  >(dealerToEditWithLogos.details?.google?.adAccountCID);

  const selectedFacebookAccounts: IFacebookAccount[] =
    useSelector(
      (state: { adLibrary: IAdLibraryState }) =>
        state.adLibrary.selectedFacebookAccounts,
    ) || [];

  const [editCoop, setEditCoop] = useState<boolean>(false);
  const isDisabledCoop = !isAdmin || !editCoop;

  const resetDealerCoopDetailsEdits = useCallback(() => {
    setDealerToEditWithLogos({
      ...dealerToEditWithLogos,
      coopDetails: {
        emailOrPortal: "",
        coopSite: "",
        coopEmail: "",
        coopUsername: "",
        coopPassword: "",
        coopDealerCode: "",
        coopLoginLocked: false,
      },
    });
  }, [dealerToEditWithLogos, setDealerToEditWithLogos]);

  const handleSelectGoogleAccount = useCallback(
    adAccountCID => {
      setSelectedGoogleAccountCID(adAccountCID);
      setDealerToEditWithLogos({
        ...dealerToEditWithLogos,
        details: {
          ...dealerToEditWithLogos.details,
          google: {
            ...dealerToEditWithLogos.details.google,
            adAccountCID,
          },
        },
      });
    },
    [dealerToEditWithLogos, setDealerToEditWithLogos],
  );

  const handleClearGoogleAccount = useCallback(() => {
    setSelectedGoogleAccountCID(undefined);
    setDealerToEditWithLogos({
      ...dealerToEditWithLogos,
      details: {
        ...dealerToEditWithLogos.details,
        google: undefined,
      },
    });
  }, [dealerToEditWithLogos, setDealerToEditWithLogos]);

  return (
    <div>
      <Form layout="vertical" hideRequiredMark={true}>
        <Divider>Facebook</Divider>
        <FacebookAccountDetails
          dealerToEdit={dealerToEditWithLogos}
          setDealerToEdit={(dealer: IAccountRecord) =>
            setDealerToEditWithLogos(dealer)
          }
          selectedFacebookAccounts={selectedFacebookAccounts}
        />
        {googlePMaxEnabled && (
          <>
            <Divider>Google</Divider>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label={"Google Ad Account"}>
                  <GoogleAdAccountSelect
                    value={selectedGoogleAccountCID}
                    onSelect={handleSelectGoogleAccount}
                    onClear={handleClearGoogleAccount}
                    onDeselect={handleClearGoogleAccount}
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        {industryType("auto") && (
          <div>
            <Row gutter={24}>
              <Col span={12}>
                <Checkbox
                  onChange={() => {
                    setEditCoop(!editCoop);
                  }}
                >
                  Would you like to edit the Co-Op information?
                </Checkbox>
              </Col>
            </Row>
            <br />
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label={"Email or Portal Submission"}>
                  <Select
                    className="email-portal"
                    showSearch={true}
                    key="email-portal-option"
                    disabled={isDisabledCoop}
                    value={dealerToEditWithLogos.coopDetails?.emailOrPortal}
                    onChange={(value: string) => {
                      if (!dealerToEditWithLogos.coopDetails) {
                        resetDealerCoopDetailsEdits();
                        return;
                      }
                      setDealerToEditWithLogos({
                        ...dealerToEditWithLogos,
                        coopDetails: {
                          ...dealerToEditWithLogos.coopDetails,
                          emailOrPortal: value,
                        },
                      });
                    }}
                  >
                    {["Email", "Portal"].map((opt: string) => (
                      <Select.Option
                        key={`state-select-option-${opt}`}
                        value={opt}
                      >
                        {opt}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={"Coop Email"}>
                  <Input
                    key="coop-email"
                    disabled={isDisabledCoop}
                    value={dealerToEditWithLogos.coopDetails?.coopEmail}
                    onChange={e => {
                      if (!dealerToEditWithLogos.coopDetails) {
                        resetDealerCoopDetailsEdits();
                        return;
                      }
                      setDealerToEditWithLogos({
                        ...dealerToEditWithLogos,
                        coopDetails: {
                          ...dealerToEditWithLogos.coopDetails,
                          coopEmail: e.target.value,
                        },
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={"Dealer Code"}>
                  <Input
                    key="coop-dealer-code"
                    disabled={isDisabledCoop}
                    value={dealerToEditWithLogos.coopDetails?.coopDealerCode}
                    onChange={e => {
                      if (!dealerToEditWithLogos.coopDetails) {
                        resetDealerCoopDetailsEdits();
                        return;
                      }
                      setDealerToEditWithLogos({
                        ...dealerToEditWithLogos,
                        coopDetails: {
                          ...dealerToEditWithLogos.coopDetails,
                          coopDealerCode: e.target.value,
                          coopLoginLocked: false,
                        },
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label={"Coop Site"}>
                  <Input
                    key="coop-site"
                    disabled={isDisabledCoop}
                    value={dealerToEditWithLogos.coopDetails?.coopSite}
                    onChange={e => {
                      if (!dealerToEditWithLogos.coopDetails) {
                        resetDealerCoopDetailsEdits();
                        return;
                      }
                      setDealerToEditWithLogos({
                        ...dealerToEditWithLogos,
                        coopDetails: {
                          ...dealerToEditWithLogos.coopDetails,
                          coopSite: e.target.value,
                          coopLoginLocked: false,
                        },
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label={"Coop Username"}>
                  <Input
                    key="coop-UN"
                    disabled={isDisabledCoop}
                    value={dealerToEditWithLogos.coopDetails?.coopUsername}
                    onChange={e => {
                      if (!dealerToEditWithLogos.coopDetails) {
                        resetDealerCoopDetailsEdits();
                        return;
                      }
                      setDealerToEditWithLogos({
                        ...dealerToEditWithLogos,
                        coopDetails: {
                          ...dealerToEditWithLogos.coopDetails,
                          coopUsername: e.target.value,
                          coopLoginLocked: false,
                        },
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={"Coop Password"}>
                  <Input.Password
                    key="coop-PW"
                    disabled={isDisabledCoop}
                    value={dealerToEditWithLogos.coopDetails?.coopPassword}
                    onChange={e => {
                      if (!dealerToEditWithLogos.coopDetails) {
                        resetDealerCoopDetailsEdits();
                        return;
                      }
                      setDealerToEditWithLogos({
                        ...dealerToEditWithLogos,
                        coopDetails: {
                          ...dealerToEditWithLogos.coopDetails,
                          coopPassword: e.target.value,
                          coopLoginLocked: false,
                        },
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item label={"OfferLogix Source Code"}>
                  <Input
                    key="offerlogix-source-code"
                    value={dealerToEditWithLogos?.offerlogixSourceCode}
                    onChange={e => {
                      setDealerToEditWithLogos({
                        ...dealerToEditWithLogos,
                        offerlogixSourceCode: e.target.value,
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        )}
      </Form>
    </div>
  );
};

export default DealerDetails;
