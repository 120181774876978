import { Button, Form, FormItemProps, Input, Typography } from "antd";
import { useCallback, useMemo, useState } from "react";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  specialStateLicensePattern,
  specialStateMessage,
} from "shared/constants/salesEnablement";
import { isSpecialState } from "../hooks/agentManagement";
import { StateKey, states } from "shared/constants/states";
import { useMaterialFormInstance } from "screens/adLibrary/marketingMaterials/MarketingMaterialsProvider";
import { baseRules } from "../utils/helpers";

type Props = {
  fieldName: string[];
  label?: string;
  value?: string;
  onChange?: (value: string) => void;
};

export const LicenseField = ({ fieldName, label, onChange }: Props) => {
  const [isInputVisible, setIsInputVisible] = useState(true);
  const [isFieldValid, setIsFieldValid] = useState(true);
  const form = useMaterialFormInstance();
  const locations: StateKey[] = form.getFieldValue("locations") ?? [];
  const specialStates = locations.filter(isSpecialState);
  const selectedStatesMessage = specialStates
    ?.map(stateId => specialStateMessage[stateId])
    .join(" / ");

  const helpMessage = `Per ${specialStates.join(
    "/",
  )} DOI, please enter this full text before your number: ${selectedStatesMessage}`;

  const getPattern = useCallback(() => {
    if (specialStates.length === 1) {
      return new RegExp(`^${specialStateLicensePattern[specialStates[0]]}$`);
    } else if (specialStates.length > 1) {
      const arPattern = specialStateLicensePattern[states.AR.id];
      const caPattern = specialStateLicensePattern[states.CA.id];
      return new RegExp(
        `^(?:(${arPattern}) \\/ (${caPattern})$|(${caPattern}) \\/ (${arPattern})$)`,
      );
    }
  }, [specialStates]);

  const formProps: FormItemProps = useMemo(() => {
    if (specialStates.length) {
      const pattern = getPattern();
      return {
        label: specialStates
          .map(stateId => `${stateId} License No.`)
          .join(" / "),
        rules: [
          {
            required: true,
            pattern,
            validator: (_, value: string) => {
              if (pattern && value?.match(pattern)) {
                setIsFieldValid(true);
                return Promise.resolve();
              }
              setIsFieldValid(false);
              return Promise.reject(helpMessage);
            },
          },
        ],
      };
    }
    return {
      label,
      rules: baseRules("Please enter a License Number or remove this field."),
    };
  }, [getPattern, helpMessage, label, specialStates]);

  const isHelpMessageVisible = isFieldValid && !!specialStates?.length;

  if (!isInputVisible)
    return (
      <Button
        type="link"
        icon={<PlusOutlined />}
        onClick={() => setIsInputVisible(true)}
      >
        Add License Number
      </Button>
    );

  return (
    <>
      <Form.Item name={fieldName} {...formProps}>
        {specialStates?.length ? (
          <Input.TextArea
            placeholder={selectedStatesMessage}
            rows={specialStates.length}
          />
        ) : (
          <Input
            placeholder="License number"
            suffix={
              <DeleteOutlined
                onClick={() => {
                  setIsInputVisible(false);
                  onChange?.("");
                }}
              />
            }
          />
        )}
      </Form.Item>
      {isHelpMessageVisible && (
        <Typography.Text type="secondary">{helpMessage}</Typography.Text>
      )}
    </>
  );
};
