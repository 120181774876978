import { notification } from "antd";
import { chain, orderBy } from "lodash";
import { queryClient } from "queryClient";
import { useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import { QUERY_KEYS } from "screens/admin/disclosures/utils/constants";
import APIs from "services";
import {
  DisclosureFilter,
  Multilingual,
  NormalizedDisclosure,
} from "shared/types/salesEnablement";
import { useProducts } from "./useProducts";

const { fetchDisclosures } = QUERY_KEYS;

export const useFetchDisclosures = () => {
  return useQuery({
    queryKey: fetchDisclosures,
    queryFn: async () => {
      const { result } = await APIs.services.salesEnablement.getDisclosures();
      return orderBy(result, ["updatedAt"], "desc");
    },
  });
};

export const useCreateDisclosure = () => {
  return useMutation({
    mutationFn: APIs.services.salesEnablement.createDisclosure,
    onSettled: () => {
      queryClient.invalidateQueries([fetchDisclosures]);
    },
    onError: () => {
      notification.error({
        message: "Error",
        description: "Something went wrong creating the disclosure.",
      });
    },
  });
};

export const useDeleteDisclosure = () => {
  return useMutation({
    mutationFn: APIs.services.salesEnablement.deleteDisclosure,
    onSettled: () => {
      queryClient.invalidateQueries([fetchDisclosures]);
    },
    onSuccess: () => {
      notification.success({
        message: "Success",
        description: "Disclosure deleted successfully.",
      });
    },
    onError: () => {
      notification.error({
        message: "Error",
        description: "Something went wrong deleting the disclosure.",
      });
    },
  });
};

export const useUpdateDisclosure = () => {
  return useMutation({
    mutationFn: APIs.services.salesEnablement.updateDisclosure,
    onSettled: () => {
      queryClient.invalidateQueries([fetchDisclosures]);
    },
    onError: () => {
      notification.error({
        message: "Error",
        description: "Something went wrong updating the disclosure.",
      });
    },
  });
};

export const useFetchFilteredDisclosures = (
  disclosureFilter?: DisclosureFilter,
  onSuccess?: (disclosures: Multilingual<string>[]) => void,
) => {
  return useQuery({
    queryFn: async () => {
      if (!disclosureFilter) return [];
      const disclosureFilterRequest = {
        locations: disclosureFilter.locations?.join(","),
        productsIds: disclosureFilter.productsIds.join(","),
        productsTypes: disclosureFilter.productsTypes.join(","),
      };
      const { result } = await APIs.services.salesEnablement.filterDisclosures(
        disclosureFilterRequest,
      );
      return result.disclosures;
    },
    queryKey: ["disclosuresFilter", disclosureFilter?.key],
    onSuccess,
  });
};

export const useDisclosure = (disclosureId?: string) => {
  const { data, isLoading } = useFetchDisclosures();
  if (!disclosureId) return { disclosure: undefined, isLoading };
  const disclosure = data?.find(d => d.id === disclosureId);
  return { disclosure, isLoading };
};

export const useNormalizedDisclosures = () => {
  const { data: disclosures, isLoading: isDisclosuresLoading } =
    useFetchDisclosures();
  const { productsTable: products, isLoading: isProductsLoading } =
    useProducts();
  const normalizedDisclosures: NormalizedDisclosure[] = useMemo(() => {
    const productsNames = chain(products).keyBy("id").mapValues("name").value();
    return (
      disclosures?.map(disclosure => ({
        ...disclosure,
        products: disclosure.products.map(productId => ({
          id: productId,
          name: productsNames[productId] ?? "",
        })),
      })) ?? []
    );
  }, [disclosures, products]);

  return {
    normalizedDisclosures,
    isLoading: isDisclosuresLoading || isProductsLoading,
  };
};
