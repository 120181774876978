import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import { UploadFile, UploadProps } from "antd/lib/upload/interface";
import { useState } from "react";
import { CAMConfig, CAMSelect } from "shared/components/media";
import { IUploadImageFormInput } from "shared/types/uploadManagement";
import { generateGuidFilename, getBase64DataUrl } from "utils/helpers";
import { uploadMedia } from "utils/uploadMedia";
import uuid from "uuid";

interface Props {
  value?: UploadFile[];
  onChange?: (value: UploadFile[]) => void;
}

const FileUpload = ({ value = [], onChange }: Props) => {
  const [fileList, setFileList] = useState<UploadFile[]>(value);

  const updateFile = (id: string, data: Partial<UploadFile>) => {
    setFileList(prev => {
      const updatedFileList = prev.map(file =>
        file.uid === id ? { ...file, ...data } : file,
      );

      onChange?.(updatedFileList);
      return updatedFileList;
    });
  };

  const uploadCAMMedia = async (assets: WDAsset[]) => {
    const newFiles: UploadFile[] = assets.map(asset => ({
      uid: uuid(),
      name: asset.filename,
      status: "done",
      url: asset.hiResURLRaw,
      thumbUrl: asset.thumbnailurls?.[0]?.url,
    }));

    setFileList(prev => {
      const updatedFileList = [...prev, ...newFiles];

      onChange?.(updatedFileList);
      return updatedFileList;
    });
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    onChange?.(newFileList);
  };

  const uploadMediaHandler = async (file: File & { uid: string }) => {
    const mediaToUpload: IUploadImageFormInput = {
      file: await getBase64DataUrl(file),
      filename: generateGuidFilename(file.name),
      size: file.size,
      type: file.type,
    };

    try {
      const assetData = await uploadMedia(mediaToUpload, "card-asset");
      updateFile(file.uid, {
        url: assetData.videoUrl,
        thumbUrl: assetData.thumbnail,
        status: "done",
      });
    } catch (error) {
      updateFile(file.uid, { status: "error" });
    }
  };

  return (
    <>
      <Upload
        multiple
        accept="video/*"
        listType="picture-card"
        fileList={fileList}
        onChange={handleChange}
        customRequest={async ({ file }) => {
          uploadMediaHandler(file as File & { uid: string });
        }}
      >
        {fileList.length <= 8 && (
          <button style={{ border: 0, background: "none" }} type="button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </button>
        )}
      </Upload>
      {CAMConfig.active && (
        <CAMSelect
          maxSelected={1}
          fileTypes={["mp4", "mov"]}
          onClose={uploadCAMMedia}
        >
          <Button icon={<UploadOutlined />}>Select From CAM</Button>
        </CAMSelect>
      )}
    </>
  );
};

export default FileUpload;
