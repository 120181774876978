import { Button } from "antd";
import styles from "./EmailDrawerFooter.module.scss";

type Props = {
  onClose: () => void;
  onSend: () => void;
  isSaving: boolean;
};

export const EmailDrawerFooter = ({ onClose, onSend, isSaving }: Props) => {
  return (
    <div className={styles.footer}>
      <Button type="default" htmlType="button" onClick={onClose}>
        Close
      </Button>
      <Button
        type="primary"
        htmlType="button"
        onClick={onSend}
        loading={isSaving}
      >
        Send
      </Button>
    </div>
  );
};
