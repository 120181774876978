import { Form, Modal, Select, Spin } from "antd";
import { useFetchOrderTags } from "screens/assetBuilder/hooks/useFetchOrderTags";
import { Tag } from "screens/assetBuilder/models";
import { useMutateOrderTags } from "../../hooks/useMutateOrderTags";
import styles from "./TagSelectInput.module.scss";

type Props = {
  selectedTags: string[];
  handleTagsChange: (value: string[]) => void;
};

export const TagSelectInput = (props: Props) => {
  const { selectedTags, handleTagsChange } = props;
  const { createTag } = useMutateOrderTags();
  const { tags, isFetching } = useFetchOrderTags();
  const options = tags?.map((tag: Tag) => ({
    value: tag.name,
    label: tag.name,
  }));

  const onTagsSelect = (selectedValue: string) => {
    if (!tags?.some((t: Tag) => t.name === selectedValue)) {
      Modal.confirm({
        title: `Do you want to add ${selectedValue} as a new tag?`,
        onOk: () => createTag.mutate(selectedValue),
        onCancel: () => handleTagsChange(selectedTags),
      });
    }
  };

  return (
    <>
      <Form.Item
        className={styles.formItem}
        label={"Assign Tag(s) (Optional)"}
        initialValue={selectedTags}
      >
        <Select
          mode="tags"
          value={selectedTags}
          onChange={handleTagsChange}
          onSelect={onTagsSelect}
          onBlur={() => handleTagsChange(selectedTags)}
          tokenSeparators={[","]}
          options={options}
        />
      </Form.Item>
      <div className={styles.spinnerContainer}>
        <Spin className={styles.spinner} spinning={isFetching} />
      </div>
    </>
  );
};
