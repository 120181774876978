import { Col, message, Row, Select, Spin, Input, Form } from "antd";
import { useState, useEffect, FC, useMemo } from "react";
import uuid from "uuid/v4";
// components
import FormDrawer from "shared/components/FormDrawer";
import moment, { Moment } from "moment";
import {
  INewOrder,
  INewOrderRecord,
  IWFProjectNameRecord,
} from "shared/types/newOrders";
import API from "services";
import { IConfigurationState } from "shared/types/configuration";
import { useQueryClient } from "react-query";
import { User } from "redux/auth/auth.slice";
import { isPharma } from "utils/helpers";

import { DateRowPharma } from "./newOrderDrawer/DateRowPharma";
import { DateRowCommon } from "./newOrderDrawer/DateRowCommon";
import { useFetchDealers } from "shared/hooks/useFetchDealersV2";
import { IAccount } from "shared/types/accountManagement";
import { TagSelectInput } from "./TagSelectInput";

import HttpClient from "services/httpClient";

import styles from "./NewOrderDrawer.module.scss";

interface INewOrderDrawerProps {
  orderForUpdate?: INewOrderRecord | null;
  enableWorkfront: boolean;
  processingNewOrders: boolean;
  showAddNewOrderDrawer: boolean;
  wfProjectNames: IWFProjectNameRecord[];
  user?: User;
  config?: IConfigurationState["config"];
}
interface INewOrderDrawerHandlers {
  toggleAddNewOrderDrawer: (toggle: boolean) => void;
  resetOrderForUpdate: () => void;
  onOrderCreated: (order: INewOrder) => void;
  onOrderUpdated: (order: INewOrder) => void;
}

const LABEL_ACCOUNT = isPharma ? "Brand" : "Select Account";
const PLACEHOLDER_ACCOUNT = isPharma ? "Add brands" : "";

const NewOrderDrawer: FC<
  INewOrderDrawerProps & INewOrderDrawerHandlers
> = props => {
  const queryClient = useQueryClient();
  // TODO: rewrite first last name
  const initialOrder = useMemo<Partial<INewOrderRecord>>(
    () => ({
      expiresAt: moment().add(7, "days").valueOf(),
      creator_name: `${props.user?.name}`,
      is_archived: false,
      num_assets: 0,
      selectedInventory: 0,
      totalSelectedOffers: 0,
      totalUsedStamps: 0,
      selectedOrderOffers: "",
      integrationCount: 0,
      pushed_WF: false,
    }),
    [props.user?.name],
  );
  const [orderToSubmit, setOrderToSubmit] = useState<Partial<INewOrderRecord>>({
    ...initialOrder,
  });

  const canSubmitOrder =
    !!orderToSubmit?.dealer_name &&
    // One of the following is required (LADTech uses wfProjectNumber)
    (!!orderToSubmit?.wfProjectName || !!orderToSubmit?.wfProjectNumber);

  useEffect(() => {
    if (!props.orderForUpdate) return;

    setOrderToSubmit(props.orderForUpdate);
  }, [props.orderForUpdate]);

  const { dealers, isFetching: isFetchingAccounts } = useFetchDealers();
  // Creating options for the Selects

  const wfOptions = useMemo(
    () => props.wfProjectNames.map(wf => wf.name).sort(),
    [props.wfProjectNames],
  );

  const [drawerLoader, toggleDrawerLoader] = useState<boolean>(false);
  const [wfStoreName, setWfStoreName] = useState<string>();
  const [accountCode233, setAccountCode233] = useState<string>();
  useEffect(() => {
    if (wfStoreName && accountCode233) {
      const selectedDealer = dealers.find(
        (account: IAccount) => account.dealer_code === accountCode233,
      );

      if (!selectedDealer) {
        message.error(
          "No account was found for this project. Please manually select an account",
        );
        return;
      }

      const { dealer_name, dealer_code, dealer_oem } = selectedDealer;

      toggleDrawerLoader(false);
      setOrderToSubmit({
        ...orderToSubmit,
        dealer_name,
        dealer_code,
        dealer_oem,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wfStoreName, accountCode233]);

  const handleSubmit = async () => {
    const messageKey = `create-order`;
    message.loading({
      key: messageKey,
      content: "Creating order...",
    });

    const isUpdating = !!props.orderForUpdate;
    const url = `${props.config?.services.newOrder.newOrders}`;

    const order = {
      newOrder: {
        ...orderToSubmit,
        id: isUpdating ? orderToSubmit.id : uuid(),
        createdAt: isUpdating ? orderToSubmit.createdAt : Date.now(),
      },
    };

    type Response = {
      newOrder: INewOrder | null;
      error: { message: string } | null;
    };
    const response = isUpdating
      ? await HttpClient.put<Response>(url, order)
      : await HttpClient.post<Response>(url, order);

    if (response.error || !response?.newOrder) {
      message.error({
        key: messageKey,
        content: !!response.error?.message
          ? response.error.message
          : "There was an error.",
        duration: 3,
      });

      return;
    }

    // invalidate orders from react-query
    queryClient.invalidateQueries("orders");

    // In order to use message functions with onClose callback, we can't use it with messageKey as above.
    // So we first have to destroy the message used previsouly.
    message.destroy();

    const successMessage = isUpdating
      ? "Order updated successfully"
      : "Order created successfully!";
    message.success(successMessage, 1, () => {
      setOrderToSubmit({
        ...initialOrder,
      });

      props.toggleAddNewOrderDrawer(false);

      isUpdating
        ? props.onOrderUpdated(response!.newOrder!)
        : props.onOrderCreated(response!.newOrder!);
    });
  };

  const handleClose = () => {
    setOrderToSubmit({
      ...initialOrder,
    });
    props.resetOrderForUpdate();
    props.toggleAddNewOrderDrawer(false);
  };

  const dateRowProps = {
    orderToSubmit,
    onChangeDate: (value: Moment | null, date: string) => {
      setOrderToSubmit({
        ...orderToSubmit,
        expiresAt: Date.parse(date),
      });
    },
  };
  const handleTagsChange = (value: string[]) => {
    setOrderToSubmit({
      ...orderToSubmit,
      tags: value,
    });
  };

  const drawerForm = (
    <Form layout="vertical" hideRequiredMark={true}>
      <Spin
        className="new-order-drawer-spinner"
        spinning={drawerLoader}
        size="large"
      >
        <Row gutter={24}>
          {props.enableWorkfront && (
            <Col span={12}>
              <Form.Item label="Select Project #">
                <Select
                  className="select-for-project"
                  showSearch={true}
                  key="project-num-select"
                  value={orderToSubmit?.wfProjectNumber}
                  onChange={async (value: string) => {
                    toggleDrawerLoader(true);

                    const wfNameArr = value.split("_");
                    let wfID = "";
                    for (let i = 1; i < props.wfProjectNames.length; i++) {
                      if (props.wfProjectNames[i].name === value) {
                        wfID = props.wfProjectNames[i].ID;
                      }
                    }
                    let restOfTheName = "";
                    if (wfNameArr.length > 1) {
                      for (let i = 1; i < wfNameArr.length; i++) {
                        restOfTheName += ` ${wfNameArr[i]}`;
                      }
                    }

                    setOrderToSubmit(existing => ({
                      ...existing,
                      wfProjectName: restOfTheName ? restOfTheName : "",
                      wfProjectNumber:
                        wfNameArr.length !== 0 ? wfNameArr[0] : value,
                      wfID,
                      wfFullProjectName: value,
                    }));

                    const url = `${props.config?.services.workFrontUrl}`;
                    const request: RequestInfo = new Request(url, {
                      method: "POST",
                      body: JSON.stringify({
                        wfInfo: {
                          wfID,
                        },
                      }),
                    });

                    const response = await API.send<{
                      result: any;
                      error: { message: string };
                    }>(request);

                    if (response.error) {
                      message.error(response.error.message);

                      return;
                    }

                    const { dealerCode233, wfStoreName } = response.result || {
                      dealerCode233: "",
                      wfStoreName: "",
                    };
                    setAccountCode233(dealerCode233);
                    setWfStoreName(wfStoreName);

                    toggleDrawerLoader(false);
                  }}
                >
                  {wfOptions.map((prj: string) => (
                    <Select.Option
                      key={`state-select-option-${prj}`}
                      value={prj}
                    >
                      {prj}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {/* for non ladtech environments */}
          {!props.enableWorkfront && (
            <Col span={12}>
              <Form.Item label="Input Project Name">
                <Input
                  data-cy="project-name-input"
                  key="project-num-select"
                  value={orderToSubmit?.wfProjectNumber}
                  onChange={event => {
                    setOrderToSubmit({
                      ...orderToSubmit,
                      wfProjectName: event.target.value,
                      wfProjectNumber: event.target.value,
                      wfFullProjectName: event.target.value,
                    });
                  }}
                ></Input>
              </Form.Item>
            </Col>
          )}
          <Col span={12}>
            <Form.Item label={LABEL_ACCOUNT}>
              <Select
                loading={isFetchingAccounts}
                className="select-for-store"
                data-cy="select-account"
                key="account-state-select"
                placeholder={PLACEHOLDER_ACCOUNT}
                showSearch={true}
                value={orderToSubmit?.dealer_name}
                onChange={(value: string) => {
                  const account = dealers.find(d => d.dealer_name === value);

                  if (!account) {
                    message.error("Selected Account could not be found.");

                    return;
                  }

                  const { dealer_code, dealer_oem } = account;
                  setOrderToSubmit({
                    ...orderToSubmit,
                    dealer_name: value,
                    dealer_code,
                    dealer_oem,
                  });
                }}
              >
                {dealers.map(({ dealer_name: accountName }) => (
                  <Select.Option
                    key={`state-select-option-${accountName}`}
                    data-cy="select-option"
                    value={accountName}
                  >
                    {accountName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row className={styles["date-row"]} gutter={24}>
          {isPharma && (
            <DateRowPharma {...dateRowProps} onUpdateOrder={setOrderToSubmit} />
          )}
          {!isPharma && <DateRowCommon {...dateRowProps} />}
        </Row>
      </Spin>
      <Row gutter={16}>
        <Col className={styles.tagCol} span={13}>
          <TagSelectInput
            handleTagsChange={handleTagsChange}
            selectedTags={orderToSubmit?.tags || []}
          />
        </Col>
      </Row>
    </Form>
  );

  return (
    <FormDrawer
      mode={!!props.orderForUpdate ? "UPDATE" : ""}
      drawerWidth={720}
      dataName={"Order"}
      showDrawer={props.showAddNewOrderDrawer}
      validForm={canSubmitOrder}
      drawerForm={drawerForm}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      processingData={props.processingNewOrders}
    />
  );
};

export default NewOrderDrawer;
