import { DataListURLProvider } from "shared/components/dataListURL/dataListURLContext";
import { fields } from "./agentProfiles/fields";
import AgentProfilesTable from "./agentProfiles/AgentProfilesTable";
import AgentProfilesToolbar from "./agentProfiles/agentProfileDrawer/AgentProfilesToolbar";
import AgentProfileDrawer from "./agentProfiles/AgentProfileDrawer";
import { useParams } from "react-router-dom";
import useNavigateWithSearch from "shared/hooks/useNavigateWithSearch";

const AgentProfiles = () => {
  const navigate = useNavigateWithSearch();
  const { agentId } = useParams();
  const isNew = agentId === "new";
  const onDrawerClose = () =>
    navigate("..", {
      relative: "path",
    });

  return (
    <DataListURLProvider fields={fields}>
      <div
        style={{
          padding: "16px 0",
        }}
      >
        <AgentProfilesToolbar />
        <AgentProfilesTable />
        <AgentProfileDrawer
          agentId={isNew ? undefined : agentId}
          onClose={onDrawerClose}
          onSuccess={onDrawerClose}
          visible={!!agentId}
        />
      </div>
    </DataListURLProvider>
  );
};

export default AgentProfiles;
