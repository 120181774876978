import { Button } from "antd";
import styles from "./ExportDrawer.module.scss";
import { ExportDestination, RecommendedColumnTitle } from "./types";
import { useFeedContext } from "../shared/contexts/FeedContext";
import { getNextButtonTitle } from "./utils/getNextButtonTitle";
import { getNextButtonText } from "./utils/getNextButtonText";
import { useAssetBatchExportGeneration } from "shared/hooks/assetExporter/useAssetBatchExportGeneration";
import { useAdLibraryExportContext } from "./AdLibraryExportContext";
import { isFinalExportStep } from "./utils/isFinalExportStep";
import { useOffPlatformExportContext } from "./OffPlatformExportContext";
import { hasColumnInvalidData } from "./utils/validateRowData";

interface FooterProps {
  onClose: () => void;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}

export const Footer = ({
  onClose,
  currentStep,
  setCurrentStep,
}: FooterProps) => {
  const { selectedAssetBatches, feedId, selectedRows } = useFeedContext();
  const {
    exportDestination,
    adType,
    setAdType,
    columnMatches,
    setColumnMatches,
    adInfoUpdates,
    setAdInfoUpdates,
    validity,
  } = useAdLibraryExportContext();

  const { portalOptionMatches, setPortalOptionMatches } =
    useOffPlatformExportContext();

  const { handleStartExportAssetBatches, isStartingExport } =
    useAssetBatchExportGeneration({
      feedId,
      selectedRows,
      selectedAssetBatches: selectedAssetBatches ?? [],
      exportDestination: exportDestination ?? ExportDestination.CSVDownload,
      columnMatches,
      adInfoUpdates,
      adType,
      portalOptionMatches,
    });

  const onExport = () => {
    handleStartExportAssetBatches({
      onSettled: onClose,
    });
  };

  const handleNextButtonClick = () => {
    if (isFinalExportStep(currentStep, exportDestination)) onExport();
    else setCurrentStep(previousStep => previousStep + 1);

    if (currentStep === 0) {
      setAdType(null);
      setColumnMatches({});
      setPortalOptionMatches({});
    }
    if (currentStep === 1) setAdInfoUpdates({});
  };

  const handleBackButtonClick = () => {
    setCurrentStep(previousStep => previousStep - 1);
  };

  const isNextButtonDisabled = () => {
    switch (currentStep) {
      case 0:
        return selectedAssetBatches.length === 0 || !exportDestination;
      case 1:
        return exportDestination === ExportDestination.AdLibrary && !adType;
      case 2:
        return (
          exportDestination === ExportDestination.AdLibrary &&
          (hasColumnInvalidData(validity, RecommendedColumnTitle.CTAButton) ||
            hasColumnInvalidData(
              validity,
              RecommendedColumnTitle.DestinationURL,
            ))
        );
      default:
        return false;
    }
  };

  return (
    <div className={styles.footer}>
      <Button onClick={onClose}>Cancel</Button>
      <div className={styles.nextBackButtonsContainer}>
        {currentStep > 0 && (
          <Button onClick={handleBackButtonClick}>Back</Button>
        )}
        <Button
          onClick={handleNextButtonClick}
          type="primary"
          disabled={isNextButtonDisabled()}
          loading={isStartingExport}
          title={getNextButtonTitle(
            exportDestination,
            selectedAssetBatches,
            currentStep,
          )}
        >
          {getNextButtonText(exportDestination, currentStep)}
        </Button>
      </div>
    </div>
  );
};
