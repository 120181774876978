import { groupBy } from "lodash";
import { KeysWithStringOrUndefinedValues, NonFalsy } from "./utilityTypes";

export const removeItemOrDups = <T>(
  arr: T[],
  predicate: (item: T) => boolean,
): T[] => {
  let oneInstanceAlreadyFound = false;

  return arr.filter(item => {
    if (predicate(item) && !oneInstanceAlreadyFound) {
      oneInstanceAlreadyFound = true;
      return false;
    }
    return true;
  });
};

export const dedupe = <T>(data: T, index: number, arr: T[]) => {
  return arr.indexOf(data) === index;
};

export const dedupeBy =
  <T>(key: KeysWithStringOrUndefinedValues<T>) =>
  (data: T, index: number, arr: T[]) => {
    return arr.findIndex(item => item[key] === data[key]) === index;
  };

export const typedGroupBy = <
  K extends string | number | symbol,
  T extends Record<K, unknown>[],
>(
  collection: T,
  keyGetter: (item: T[number]) => K,
) => {
  return groupBy(collection, keyGetter) as Partial<Record<K, T>>;
};

/**
 * Normalizes an array of objects by a key.
 * If there are multiple objects with the same key, the first one is kept.
 * This is useful when you want to avoid looping through the array to find an object by a key.
 * @param collection
 * @param keyGetter
 * @returns An object with the key as the object key and the object with that key as the value.
 */
export const normalizeBy = <
  K extends string | number | symbol,
  T extends Record<K, unknown>[],
>(
  collection: T,
  keyGetter: (item: T[number]) => K,
) => {
  return collection.reduce((acc, item) => {
    const key = keyGetter(item);
    if (acc[key]) {
      return acc;
    }
    return { ...acc, [key]: item };
  }, {} as Partial<Record<K, T[number]>>);
};

export const isTruthy = <T>(value: T): value is NonFalsy<T> => !!value;

export const nonNullable = <T>(value: T): value is NonNullable<T> => {
  return value !== null && value !== undefined;
};

/**
 * Given a 2d array, a line number and item index, returns the item in the previous position.
 * If the item is the first in the line, returns the last item in the previous line.
 *
 * @param arr 2d array
 * @param line line number
 * @param index item index
 */
export const getPreviousValue2d = <T>(
  arr: T[][],
  line: number,
  index: number,
) => {
  if (line === 0 && index === 0) {
    return null;
  }
  // If it's the start of any row (but not the first row)
  if (index === 0) {
    const prevLine = arr[line - 1];
    return prevLine?.[prevLine.length - 1];
  }
  return arr[line]?.[index - 1];
};

/**
 * Joins an array of strings with a separator, ignoring null and undefined values.
 */
export const joinArray = (arr: unknown[], separator = " ") => {
  return arr.filter(nonNullable).join(separator);
};

// Spreads an array of single or multiple csv into a single array of strings
export const spreadCSV = (csv: string[] | undefined): string[] => {
  return csv?.map(v => v.split(",")).flat() || [];
};
