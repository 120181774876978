import { useEffect, useRef, useState } from "react";
import { TMediaComposition } from "shared/types/assetExporter";
import styles from "./MediaPreview.module.scss";
import { VideoPlayer } from "./VideoPlayer";
import { useCheckIfItIsVideoSrc } from "../hooks/useCheckIfItIsVideoSrc";
import { useVideoStitchingContext } from "../contexts/VideoStitchingContext";

type MediaPreviewProps = {
  row: Record<string, string>;
  editingComposition: TMediaComposition;
  width: number;
  zoomLevel: number;
};

export const MediaPreview = ({
  row,
  editingComposition,
  width,
}: MediaPreviewProps) => {
  const previewRef = useRef<HTMLDivElement>(null);
  const [isHovering, setIsHovering] = useState(false);
  const { currentMediaId } = useVideoStitchingContext();

  useEffect(() => {
    if (!previewRef.current?.id.includes(currentMediaId ?? "")) return;
    previewRef.current?.scrollIntoView();
  }, [currentMediaId]);

  const value = editingComposition.column
    ? row[editingComposition.column]
    : editingComposition?.url;

  const {
    data: { isVideo, src: convertedSrc } = { isVideo: false, src: value },
    isLoading,
  } = useCheckIfItIsVideoSrc(value);

  return (
    <div
      className={styles.preview}
      id={editingComposition.compositionId}
      ref={previewRef}
    >
      {!isLoading && value && (
        <div
          onMouseEnter={() => {
            setIsHovering(true);
          }}
          onMouseLeave={() => {
            setIsHovering(false);
          }}
        >
          {isVideo ? (
            <VideoPlayer
              editingComposition={editingComposition}
              compositionIndex={0}
              playerDimensions={{
                width: Math.round(width),
              }}
              showControls={isHovering}
            />
          ) : (
            <img
              src={convertedSrc}
              className={styles.media}
              alt={editingComposition.name}
            />
          )}
        </div>
      )}
    </div>
  );
};
