import { PlayCircleOutlined } from "@ant-design/icons";
import { Button, Col, Row, Tooltip } from "antd";
import { NavTemplateItems } from "screens/assetExporter/feedConfigurationV2/shared/components/Preview/NavTemplateItems";
import { useAssetBatchesContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesContext";
import { useVideoStitchingContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/VideoStitchingContext";
import styles from "../PanelTemplate.module.scss";

export const PanelTemplateHeader = () => {
  const { compositions, previewLoading, audioFiles } = useAssetBatchesContext();
  const { setIsVideoPreviewOpen } = useVideoStitchingContext();

  const disabledPlayButton = compositions.some(
    composition => composition.duration === 0,
  );

  const toolTipTitle = disabledPlayButton
    ? "Please add media duration for all media"
    : "";

  const showFullPreviewButton =
    compositions.length > 1 ||
    (compositions.length === 1 && audioFiles.length > 0);

  return (
    <Row>
      <Col span={24}>
        <div className={styles.header}>
          <div className={styles.previewHeader}>
            <span>Preview</span>
            <NavTemplateItems />
          </div>
          {showFullPreviewButton && (
            <Tooltip title={toolTipTitle}>
              <Button
                disabled={previewLoading || disabledPlayButton}
                className={styles.playButton}
                onClick={() => setIsVideoPreviewOpen(true)}
                icon={<PlayCircleOutlined />}
              >
                Preview and edit
              </Button>
            </Tooltip>
          )}
        </div>
      </Col>
    </Row>
  );
};
