import { ReloadOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import Link from "antd/lib/typography/Link";
import API from "services";
import { useAssetBatchesContext } from "../contexts/AssetBatchesContext";
import { useAssetBatchesRenderContext } from "../contexts/AssetBatchesRenderContext";
import { useAssetBatchesValueMappingContext } from "../contexts/AssetBatchesValueMappingContext";
import { useFeedContext } from "../contexts/FeedContext";
import styles from "./Preview.module.scss";

interface Props {
  canvasClientWidth?: number;
  canvasClientHeight?: number;
}

export const ReloadTemplate = ({
  canvasClientHeight,
  canvasClientWidth,
}: Props) => {
  const { setShowReload, setEditingTemplate, compositions } =
    useAssetBatchesContext();
  const { template, editingComposition } = useAssetBatchesRenderContext();
  const { editingAssetBatch } = useFeedContext();
  const { saveTemplate } = useAssetBatchesValueMappingContext();

  const handleReloadTemplate = () => {
    if (!template?.id) return;
    API.services.designStudio
      .getTemplateById(template.id)
      .then(({ result }) => {
        if (!result) return;
        const { template: selectedTemplate } = result;
        saveTemplate(
          selectedTemplate,
          compositions?.length ?? 0,
          editingAssetBatch,
          editingComposition,
        );
      });
    setShowReload(false);
    setEditingTemplate(undefined);
  };

  return (
    <div
      style={{
        width: canvasClientWidth,
        height: canvasClientHeight,
      }}
      className={styles.reloadTemplateLayer}
    >
      <div
        className={styles.reloadBackgroundLayer}
        style={{
          width: canvasClientWidth,
          height: canvasClientHeight,
        }}
      ></div>
      <div
        className={styles.reloadContentLayer}
        style={{
          width: canvasClientWidth,
          height: canvasClientHeight,
        }}
      >
        <div className={styles.reloadContentContainer}>
          <ReloadOutlined
            className={styles.reloadIcon}
            style={{
              left: (canvasClientWidth ?? 48) / 2 - 24,
            }}
          />
          <Typography.Title level={4}>
            This template has been updated.
          </Typography.Title>
          <Link className={styles.loadText} onClick={handleReloadTemplate}>
            Load latest version
          </Link>
        </div>
      </div>
    </div>
  );
};
