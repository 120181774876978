import {
  PhotoSizeSelectLargeRounded,
  AutoAwesomeRounded,
  CropLandscapeRounded,
  DashboardCustomizeRounded,
  LayersRounded,
  PhotoSizeSelectActualRounded,
  TitleRounded,
  UploadRounded,
  DownloadRounded,
  RssFeedRounded,
  CommentRounded,
  RocketLaunchRounded,
  PreviewRounded,
} from "@mui/icons-material";
import { useState } from "react";

export type EditorTab =
  | "templates"
  | "layers"
  | "text"
  | "media"
  | "shapes"
  | "upload"
  | "resize"
  | "aiImages"
  | "import"
  | "brandKit"
  | "preview"
  | "feed"
  | "review"
  | "export";

const tabs = [
  {
    value: "templates",
    label: "Templates",
    enabled: false,
    Icon: DashboardCustomizeRounded,
  },
  { value: "layers", label: "Layers", enabled: true, Icon: LayersRounded },
  { value: "text", label: "Text", enabled: true, Icon: TitleRounded },
  {
    value: "media",
    label: "Media",
    enabled: true,
    Icon: PhotoSizeSelectActualRounded,
  },
  {
    value: "shapes",
    label: "Shapes",
    enabled: true,
    Icon: CropLandscapeRounded,
  },
  { value: "upload", label: "Upload", enabled: false, Icon: UploadRounded },
  {
    value: "resize",
    label: "Resize",
    enabled: false,
    Icon: PhotoSizeSelectLargeRounded,
  },
  {
    value: "aiImages",
    label: "AI Images",
    enabled: true,
    Icon: AutoAwesomeRounded,
  },
  { value: "import", label: "Import", enabled: false, Icon: DownloadRounded },
  { value: "preview", label: "Preview", enabled: false, Icon: PreviewRounded },
  { value: "feed", label: "Feed", enabled: false, Icon: RssFeedRounded },
  { value: "review", label: "Review", enabled: false, Icon: CommentRounded },
  {
    value: "export",
    label: "Export",
    enabled: true,
    Icon: RocketLaunchRounded,
  },
] as const;

export const useEditorTabs = () => {
  const [currentTab, setTab] = useState<EditorTab | null>("layers");
  const enabledTabs = tabs.filter(tab => tab.enabled);
  return { tabs: enabledTabs, currentTab: currentTab ?? null, setTab };
};
