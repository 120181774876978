import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Spin, notification, message } from "antd";

import {
  ILifestyleImage,
  IAssetBuildInstance,
  SelectedInstance,
} from "shared/types/assetBuilder";

import GenericError from "shared/errors/GenericError";
import { IConfig } from "shared/types/configuration";
import { INewOrder } from "shared/types/newOrders";

import "./ImageSelectionList.scss";
import HttpClient from "services/httpClient";
import { TTemplateType } from "shared/types/designStudio";
import { CAM_ENABLED } from "shared/components/media";
import { isSameImage } from "../ImageSelection.utils";
import Thumbnail from "./imageSelectionList/Thumbnail";
import { MediaImageSelection } from "./MediaImageSelection";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { cloneDeep } from "lodash";
import { setSelectedAssetBuildInstance } from "../../../../../../redux/assetBuilder/assetBuilder.slice";

type ImgSelectionProps = {
  lifestyleImages: ILifestyleImage[];
  fetching: boolean;
  type: TTemplateType | "";
  currOrder: INewOrder | undefined;
  instance: IAssetBuildInstance | null;
  imageType: string;
  config?: IConfig;
  savedSelectImageSearch: string;
  shouldUpdateThemeImage: boolean;
  selectedInstances: SelectedInstance[];
};

type ImgSelectionHandlers = {
  insertLifestyleImage?: (imageUrl: string, imageName: string) => void;
  onLifestyleImageDeleteComplete?: (id: string) => void;
  saveSelectImageSearch: (searcyBy: string) => void;
  setShouldUpdateThemeImage: (shouldUpdateThemeImage: boolean) => void;
  setNumBackgroundImages: (numBackgroundImages: number) => void;
};

type ImageSelectionListProps = ImgSelectionProps & ImgSelectionHandlers;

const withTimestamp = (url: string) => {
  const finalUrl = new URL(url);
  finalUrl.search = Date.now().toString();

  return finalUrl.toString();
};

const ImageSelectionList = (props: ImageSelectionListProps) => {
  const {
    imageType,
    type,
    saveSelectImageSearch,
    savedSelectImageSearch,
    insertLifestyleImage,
    setNumBackgroundImages,
    shouldUpdateThemeImage,
    onLifestyleImageDeleteComplete,
    config,
    instance,
    lifestyleImages,
    fetching,
    selectedInstances,
  } = props;

  const dispatch = useAppDispatch();

  const transformedAssetImages = useMemo(() => {
    return to2DArray(lifestyleImages);
  }, [lifestyleImages]);

  const [hoveredImageId, setHoveredImageId] = useState("");
  const [selectedImgUrl, setSelectedImageUrl] = useState("");
  const [searchBy, setSearchBy] = useState(
    props?.currOrder?.selectImageCollapseSearchInput || "",
  );
  const [didUserSearchByImageName, setDidUserSearchByImageName] =
    useState(false);

  const imgRef = useRef<Map<string, HTMLLIElement>>(new Map());

  const scrollToId = useCallback((itemId: string) => {
    const map = imgRef.current;
    const node = map.get(itemId);
    if (node) {
      node.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, []);

  const findMatch = (lifestyleImage: ILifestyleImage) => {
    if (type === "carcut" || type === "") {
      return searchBy
        ? lifestyleImage?.name
            ?.toLocaleLowerCase()
            .includes(searchBy.toLocaleLowerCase())
        : true;
    }
    if (type === "lifestyle") {
      const imageName = `${lifestyleImage.year}_${lifestyleImage.make}_${lifestyleImage.model}_${lifestyleImage.trim}`;
      return searchBy
        ? imageName?.toLocaleLowerCase().includes(searchBy.toLocaleLowerCase())
        : true;
    }
  };

  const convertUrlToCDN = (url?: string) => {
    return url?.replace(/s3.*amazonaws.com/, "constellationagency.com") || "";
  };

  const [fetchingCustomImage, setFetchingCustomImage] = useState(false);
  const [customImageUrls, setCustomImageUrls] = useState<{
    [key: string]: string;
  }>({});

  const updateSelectedInstances = useCallback(
    (url: string, name: string) => {
      const clonedSelectedInstances = cloneDeep(selectedInstances);
      const updatedInstances = clonedSelectedInstances.map(instance => {
        instance.instance.lifestyleImageUrl = url;
        instance.instance.lifestyleImageName = name;
        instance.instance.lifestyleFabricImageJson = undefined;
        return instance;
      });
      dispatch(setSelectedAssetBuildInstance(updatedInstances));
    },
    [dispatch, selectedInstances],
  );

  const instancesHaveSameImages = useMemo(() => {
    const refImage =
      selectedInstances.length > 0
        ? selectedInstances[0].instance.lifestyleImageUrl
        : null;
    return selectedInstances.every(
      inst => inst.instance.lifestyleImageUrl === refImage,
    );
  }, [selectedInstances]);

  useEffect(() => {
    if (fetching) {
      return;
    }
    const imgUrl: string = instance?.lifestyleImageUrl?.split("?")[0] || "";
    setSelectedImageUrl(imgUrl);
    setTimeout(() => {
      scrollToId(imgUrl as string);
    }, 300);
  }, [fetching, instance, scrollToId]);

  useEffect(() => {
    // save number of background images

    let backgroundImagesCount = 0;
    const backgroundImages = transformedAssetImages.map(row => {
      return row.filter(findMatch).map(lifestyleImage => {
        backgroundImagesCount += 1;
        return lifestyleImage;
      });
    });

    if (
      backgroundImagesCount === 1 &&
      shouldUpdateThemeImage &&
      !didUserSearchByImageName // did user press add new instance && has the user NOT typed anything yet?
    ) {
      for (const innerArr of backgroundImages) {
        if (innerArr.length !== 1) continue;
        // only one of the inner arrays will have length one
        const { url, name } = innerArr[0];
        const cdnUrl = convertUrlToCDN(url);
        if (!url) {
          notification.open({
            message: "This asset image has an issue.",
          });

          continue;
        }

        insertLifestyleImage?.(`${cdnUrl}`, name || "");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transformedAssetImages, searchBy]);

  useEffect(() => {
    setSearchBy(savedSelectImageSearch ? savedSelectImageSearch : "");
  }, [savedSelectImageSearch]);

  useEffect(() => {
    if (
      props?.currOrder?.selectImageCollapseSearchInput &&
      !savedSelectImageSearch
    ) {
      setSearchBy(props?.currOrder?.selectImageCollapseSearchInput || "");
    }
  }, [
    props?.currOrder?.selectImageCollapseSearchInput,
    savedSelectImageSearch,
  ]);

  const images = useMemo(() => {
    const images: ILifestyleImage[] = [];

    for (let i = 0; i < transformedAssetImages.length; i++) {
      const row = transformedAssetImages[i];

      for (let j = 0; j < row.length; j++) {
        const lifestyleImage = row[j];

        const { make, year, model } = instance?.selectedOffer?.offerData || {
          make: "",
          year: "",
          model: "",
        };

        const { width, height } = instance?.template?.artboard || {
          width: 0,
          height: 0,
        };

        const lifestyleImageTrimSplit = lifestyleImage?.trim?.split("x");
        const lifestyleImageWidth = lifestyleImageTrimSplit
          ? lifestyleImageTrimSplit[0]
          : "";
        const lifestyleImageHeight = lifestyleImageTrimSplit
          ? lifestyleImageTrimSplit[1]
          : "";

        if (
          (type === "carcut" || type === "") &&
          (lifestyleImage.oems || []).length > 0
        ) {
          const intersection = (lifestyleImage.oems || []).filter(
            oem => oem.toLowerCase() === make.toLowerCase(),
          );
          if (intersection.length <= 0) continue;
        }

        if (
          process.env.REACT_APP_AV2_CLIENT === "internal" &&
          type === "lifestyle" &&
          (lifestyleImage?.make?.toLowerCase() !== make.toLowerCase() || // make check
            lifestyleImage?.year !== parseInt(year) || // year check
            lifestyleImage?.model !== model || // model check
            (lifestyleImageWidth && // size check
              lifestyleImageHeight &&
              (parseInt(lifestyleImageHeight) !== height ||
                parseInt(lifestyleImageWidth) !== width)))
        )
          continue;

        images.push(lifestyleImage);
      }
    }
    const filteredImages = images
      .filter(img => img.url !== "")
      .filter(findMatch);
    setNumBackgroundImages(filteredImages.length);
    return filteredImages;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transformedAssetImages, searchBy]);

  const imageSelection = async (selectedImg: ILifestyleImage) => {
    const { url, name = "" } = selectedImg;
    if (!url) {
      notification.open({
        message: "This asset image has an issue.",
      });
      return;
    }
    const imageUrl = customImageUrls[url] || url;
    setSelectedImageUrl(imageUrl);
    const imageType = url.match(/(jpe?g|png)$/gi);
    if (
      imageType &&
      !imageUrl.includes("constellationagency.com") &&
      !imageUrl.includes("webdam") &&
      config
    ) {
      setFetchingCustomImage(true);
      const { url: newUrl } =
        (await uploadImage(url, config, imageType ? imageType[0] : "jpg")) ||
        {};
      const newCdnUrl = convertUrlToCDN(newUrl);

      setCustomImageUrls({
        ...customImageUrls,
        [url]: newCdnUrl,
      });
      setFetchingCustomImage(false);
      insertLifestyleImage?.(`${newCdnUrl}`, name);
      updateSelectedInstances(`${newCdnUrl}`, name);
    } else {
      insertLifestyleImage?.(`${withTimestamp(imageUrl)}`, name);
      updateSelectedInstances(`${withTimestamp(imageUrl)}`, name);
    }
  };

  return (
    <>
      <Input
        placeholder="Search By Asset Name"
        className="theme-image-search-by-name"
        value={searchBy}
        prefix={<SearchOutlined rev={undefined} />}
        allowClear={true}
        onChange={e => {
          setSearchBy(e.target.value);
          saveSelectImageSearch(e.target.value);
          setDidUserSearchByImageName(true);
        }}
      />
      {CAM_ENABLED && (
        <MediaImageSelection
          type={type}
          instance={instance}
          imageSelection={imageSelection}
        />
      )}
      <Spin spinning={fetching || fetchingCustomImage} tip="Loading...">
        <ul className="image-selection-list">
          {!fetching && transformedAssetImages.length === 0 && (
            <li>No images</li>
          )}
          {images.map(lifestyleImage => {
            return (
              <li
                className={`${
                  instancesHaveSameImages &&
                  isSameImage(selectedImgUrl, lifestyleImage.url)
                    ? "selected"
                    : ""
                }`}
                key={lifestyleImage.url}
                //className={"selected"}
                ref={el => {
                  const map = imgRef.current;
                  if (el && lifestyleImage?.url) {
                    map.set(lifestyleImage?.url, el);
                  } else {
                    map.delete(lifestyleImage?.url as string);
                  }
                }}
              >
                <Thumbnail
                  selectedInstances={selectedInstances}
                  config={config}
                  type={type}
                  lifestyleImage={lifestyleImage}
                  hoveredImageId={hoveredImageId}
                  imageType={imageType}
                  selectedLifestyleImageUrl={
                    selectedImgUrl || instance?.lifestyleImageUrl
                  }
                  setHoveredImageId={id => {
                    setHoveredImageId(id || "");
                  }}
                  convertUrlToCDN={convertUrlToCDN}
                  onSelect={async () => {
                    await imageSelection(lifestyleImage);
                  }}
                  onDeleteComplete={id => {
                    onLifestyleImageDeleteComplete?.(id); // defined in ImageSElection.tsx
                  }}
                />
              </li>
            );
          })}
        </ul>
      </Spin>
    </>
  );
};

const uploadImage = async (
  url: string,
  config: IConfig,
  imageType?: string,
) => {
  const uploadCustomImageUrl = `${config.services.assetBuilder.uploadCustomImageUrl}`;
  const { result, error } = await HttpClient.post<{
    result: { lifestyleImage: ILifestyleImage };
    error: GenericError;
  }>(
    uploadCustomImageUrl,
    {
      url,
      imageType,
    },
    {
      cache: "no-cache",
    },
  );

  if (error) {
    message.error(error.message);

    return;
  }

  const { lifestyleImage: createdLifestyleImage } = result;

  return createdLifestyleImage;
};

const to2DArray = (lifestyleImages: ILifestyleImage[]) => {
  const result: ILifestyleImage[][] = [];
  let row: ILifestyleImage[] = [];
  for (const lifestyleImage of lifestyleImages) {
    row.push(lifestyleImage);

    // 3 images per row
    if (row.length === 3) {
      result.push(row);

      row = [];
    }
  }

  if (row.length > 0) {
    result.push(row);
  }

  return result;
};

export default ImageSelectionList;
