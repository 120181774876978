import { isEqual } from "lodash";
import { Dispatch, SetStateAction } from "react";
import { assetNamingEnabled } from "shared/constants/assetExporter";
import {
  FeedTblRow,
  STEP_CREATE_BATCH_V2,
  TAssetBatchRequest,
  TMediaComposition,
  TTemplateComposition,
} from "shared/types/assetExporter";
import { AssetBatchDrawerBase } from "./AssetBatchDrawerBase";
import styles from "./AssetBatchDrawerV2.module.scss";
import { ApplyCondition } from "./assetBatchDrawer/ApplyCondition";
import { ManageVariables } from "./assetBatchDrawerV2/ManageVariables";
import { RenameAssets } from "./assetBatchDrawerV2/RenameAssets";
import { useAssetBatchesContext } from "./shared/contexts/AssetBatchesContext";
import {
  isMediaCompositionFromCAM,
  isTemplateComposition,
  parseConditions,
} from "./shared/contexts/AssetBatchesContext.utils";
import { useFeedContext } from "./shared/contexts/FeedContext";

interface AssetBatchDrawerProps {
  onSubmit: (assetBatch: TAssetBatchRequest) => void;
  onClose: (showUndoMessage: boolean, batchName: string) => void;
  setFilteredRows: Dispatch<SetStateAction<FeedTblRow[]>>;
}

export const AssetBatchDrawerV2 = ({
  onSubmit,
  onClose,
  setFilteredRows,
}: AssetBatchDrawerProps) => {
  const {
    batchName,
    ruleConditions,
    compositions,
    isAllValid,
    isFirstStepValid,
    currentStep,
    namingRulePattern,
    audioFiles,
  } = useAssetBatchesContext();

  const { editingAssetBatch } = useFeedContext();

  const lastStep = assetNamingEnabled
    ? STEP_CREATE_BATCH_V2.THREE_RENAME
    : STEP_CREATE_BATCH_V2.TWO_VARIABLES;

  const isLastStep = () => currentStep === lastStep;

  const edited = () => {
    if (!editingAssetBatch) return false;

    const areSameNames = editingAssetBatch?.name === batchName;
    const areSameConditions = isEqual(
      parseConditions(editingAssetBatch.conditions),
      ruleConditions,
    );

    const areSameVariables = compositions.every(
      composition =>
        !isTemplateComposition(composition) ||
        isEqual(
          composition.variables,
          (
            editingAssetBatch?.compositions.find(
              com =>
                com.compositionId === composition.compositionId &&
                isTemplateComposition(com),
            ) as TTemplateComposition | undefined
          )?.variables,
        ),
    );

    const areSameDurations = compositions.every(composition =>
      isEqual(
        composition.duration,
        editingAssetBatch?.compositions.find(
          com => com.compositionId === composition.compositionId,
        )?.duration,
      ),
    );

    const areSameUrls = compositions.every(
      composition =>
        isTemplateComposition(composition) ||
        isEqual(
          composition.url,
          (
            editingAssetBatch?.compositions.find(
              com => com.compositionId === composition.compositionId,
            ) as TMediaComposition | undefined
          )?.url,
        ),
    );

    const areSameCompositionsLength =
      compositions.length === editingAssetBatch.compositions.length;

    const areSameCompositionsAudio = compositions.every(
      composition =>
        !isMediaCompositionFromCAM(composition) ||
        isEqual(
          (composition as TMediaComposition).audioEnabled,
          (
            editingAssetBatch?.compositions.find(
              com => com.compositionId === composition.compositionId,
            ) as TMediaComposition | undefined
          )?.audioEnabled,
        ),
    );

    const areSameAudioSelVariableId = compositions.every(composition => {
      if (!isTemplateComposition(composition)) return true;
      const orgAudioSelVariableId =
        typeof composition.audioSelVariableId === "string"
          ? [composition.audioSelVariableId]
          : composition.audioSelVariableId;

      const matchingComposition = editingAssetBatch?.compositions.find(
        com => com.compositionId === composition.compositionId,
      ) as TTemplateComposition | undefined;

      const editingAudioSelVariableId =
        typeof matchingComposition?.audioSelVariableId === "string"
          ? [matchingComposition.audioSelVariableId]
          : matchingComposition?.audioSelVariableId;

      if (
        !orgAudioSelVariableId ||
        !editingAudioSelVariableId ||
        orgAudioSelVariableId.length !== editingAudioSelVariableId.length
      ) {
        return false;
      }

      return orgAudioSelVariableId.every(id =>
        editingAudioSelVariableId.includes(id),
      );
    });

    const areSameNamingRulePattern =
      (editingAssetBatch.namingRulePattern ?? "") === namingRulePattern ||
      !assetNamingEnabled;

    const areSameAudioFiles =
      audioFiles &&
      editingAssetBatch.audioFiles &&
      audioFiles.length === editingAssetBatch.audioFiles.length &&
      audioFiles.every(
        (audioFile, index) =>
          editingAssetBatch?.audioFiles?.[index] &&
          audioFile.file?.src === editingAssetBatch.audioFiles[index]?.src,
      );

    if (
      areSameNames &&
      areSameConditions &&
      areSameVariables &&
      areSameDurations &&
      areSameUrls &&
      areSameCompositionsLength &&
      areSameAudioSelVariableId &&
      areSameNamingRulePattern &&
      areSameCompositionsAudio &&
      areSameAudioFiles
    ) {
      return false;
    }

    return true;
  };

  const disabledSaveForFirstStep = !isFirstStepValid || !edited();
  const disabledSaveForSecondStep = !isAllValid || !edited();

  const isFirstStep = currentStep === STEP_CREATE_BATCH_V2.ONE_CONDITION;
  const isSecondStep = currentStep === STEP_CREATE_BATCH_V2.TWO_VARIABLES;
  const isThirdStep = currentStep === STEP_CREATE_BATCH_V2.THREE_RENAME;

  return (
    <AssetBatchDrawerBase
      currentStepConst={STEP_CREATE_BATCH_V2.ONE_CONDITION}
      createDisabled={
        (isFirstStep && !isFirstStepValid) ||
        (isSecondStep && !isAllValid) ||
        (isThirdStep && !isAllValid)
      }
      editDisabled={
        (isFirstStep && !isFirstStepValid) ||
        (assetNamingEnabled && isSecondStep && !isAllValid)
      }
      editDisabledLastStep={
        (isFirstStep && disabledSaveForFirstStep) ||
        (assetNamingEnabled && isSecondStep && disabledSaveForSecondStep) ||
        (isLastStep() && disabledSaveForSecondStep)
      }
      steps={
        <div className={styles.stepsContainer}>
          {currentStep === STEP_CREATE_BATCH_V2.ONE_CONDITION && (
            <ApplyCondition setFilteredRows={setFilteredRows} />
          )}
          {currentStep === STEP_CREATE_BATCH_V2.TWO_VARIABLES && (
            <ManageVariables />
          )}
          {currentStep === STEP_CREATE_BATCH_V2.THREE_RENAME && (
            <RenameAssets />
          )}
        </div>
      }
      onSubmit={onSubmit}
      onClose={onClose}
      setFilteredRows={setFilteredRows}
      isLastStep={isLastStep}
    />
  );
};
