import { ExportBatchAssetsBody } from "shared/types/assetExporter";
import { User } from "redux/auth/auth.slice";
import {
  RecommendedColumnMatches,
  ExportDestination,
  AdInfoUpdates,
  AdType,
  PortalOptionMatches,
} from "screens/assetExporter/feedConfigurationV2/exportDrawer/types";
import { getMappingDataForExportPortal } from "./getMappingDataForExportPortal";

interface GetExportAssetBatchesBody {
  selectedRows: string[];
  selectedAssetBatches: string[];
  feedId: string;
  exportDestination: ExportDestination;
  user: User;
  columnMatches?: RecommendedColumnMatches;
  adInfoUpdates?: AdInfoUpdates;
  adType?: AdType | null;
  portalOptionMatches?: PortalOptionMatches;
}

export const getExportAssetBatchesBody = ({
  selectedRows,
  selectedAssetBatches,
  feedId,
  user,
  exportDestination,
  columnMatches,
  adInfoUpdates,
  adType,
  portalOptionMatches,
}: GetExportAssetBatchesBody): ExportBatchAssetsBody => {
  const baseExportBody: ExportBatchAssetsBody = {
    feedId,
    createdBy: user.email,
    selectedRows: selectedRows,
    assetBatchIds: selectedAssetBatches,
    exportType: exportDestination,
  };

  if (exportDestination === ExportDestination.AdLibrary && adType) {
    return {
      ...baseExportBody,
      columnMatches,
      adType,
      updates: adInfoUpdates,
    };
  }

  if (
    exportDestination === ExportDestination.GoogleSpreadsheet &&
    portalOptionMatches
  ) {
    const mappingDataForExportPortal =
      getMappingDataForExportPortal(portalOptionMatches);

    return {
      ...baseExportBody,
      mappingDataForExportPortal,
      instanceName: process.env.REACT_APP_AV2_CLIENT,
    };
  }

  return baseExportBody;
};
