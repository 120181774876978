import { useState } from "react";
import { notification } from "antd";
import { isRejected } from "../utils.promise";
import { BlobInput, useUpload } from "./useUpload";

type CAMUploadHookProps = {
  files: string[] | BlobInput[];
  onComplete?: () => void;
};

export const useCAMUpload = ({ files, onComplete }: CAMUploadHookProps) => {
  const [open, setOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [meta, setMeta] = useState<MGFormValues>();
  const [folderId, setFolderId] = useState<string>();
  const uploadFromUrl = useUpload();

  const openDrawer = () => {
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
    setMeta(undefined);
    setFolderId(undefined);
  };

  const handleClick = async () => {
    setUploading(true);
    const promises = files.map(file => uploadFromUrl(file, folderId!, meta));
    const res = await Promise.allSettled(promises);
    const failed = res.filter(isRejected);

    if (failed.length) {
      notification.error({
        message: "Upload files failed",
        description: failed[0].reason.message,
        duration: 0,
      });
    } else {
      notification.success({
        message: "Upload files",
        description: `Success uploading ${files.length} file(s)`,
        duration: 0,
      });
      onComplete?.();
      closeDrawer();
    }

    setUploading(false);
  };

  return {
    open,
    uploading,
    meta,
    folderId,
    setMeta,
    setFolderId,
    openDrawer,
    closeDrawer,
    handleClick,
  };
};
