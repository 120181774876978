import { SaveOutlined } from "@ant-design/icons";
import { Button, message, Modal } from "antd";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import actions from "../../redux/rootActions";

import {
  AssetInstanceRecord,
  HeaderMatchType,
  IAssetBuilderState,
  ISelectedOffer,
  OfferData,
  RawSelectedOffers,
  URLPattern,
} from "shared/types/assetBuilder";
import { IConfig, IConfigurationState } from "shared/types/configuration";
import { IHeader } from "shared/types/header";
import {
  INewOrderRecord,
  INewOrderState,
  StatusOptions,
} from "shared/types/newOrders";

import { isEqual } from "lodash";
import Header from "shared/components/Header";
import {
  getAssetCount,
  getSelectedVins,
  getStampCount,
  isSameOffer,
  isSameSelected,
} from "../../utils/helpers.asset";
import "../AssetBuilder.scss";

import ToolbarTable from "shared/components/toolbarTable/ToolbarTable";
import { ToolbarButton } from "shared/types/adLibrary";

import { useIsAdmin } from "shared/hooks/useIsAdmin";
import { IAssetExportQueryParamObj } from "shared/types/assetExport";
import { isEnvVarEquals, isFeatureEnabled, updatePathUrl } from "utils/helpers";
import styles from "./AssetBuilderHeader.module.scss";
import OrderDetailsDrawer from "./assetBuilderHeader/OrderDetailsDrawer";

export type SelectedItem = "ORDERS" | "OFFERS" | "AD LAUNCHER";
export type SelectedSubItem = "INPUT" | "SELECT" | "BUILD" | "REVIEW";

interface IAssetBuilderHeader {
  config?: IConfig;
  actionButtons?: any[];
  selectedItem: SelectedItem;
  selectedSubItem?: SelectedSubItem;
  enabledHeaders: HeaderMatchType[];
  savedOrder: IAssetBuilderState["savedOrder"];
  resetAssetInstances: () => void;
  resetOfferEditsWereSaved: () => void;
  assetInstanceComparator: AssetInstanceRecord;
  assetInstances: AssetInstanceRecord;
  setSaveDialog: (input: boolean, path?: string) => void;
  offerDataComparator: OfferData | null;
  offerData: OfferData | null;
  setOrderHasBeenClicked: (input: boolean) => void;
  commitOrder: () => Promise<void>;
  countGeneratedInstances?: () => number;
  selectedOffers: ISelectedOffer[];
  totalSelectedOffers: number;
  offerTypes: string;
  updateNewOrder: (updateNewOrder: Partial<INewOrderRecord>) => Promise<void>;
  disabledExport?: boolean;
  toggleExportDrawer?: (value: SetStateAction<boolean>) => void;
  toggleForceRenderImages?: (value: SetStateAction<boolean>) => void;
  disabledCoop?: boolean;
  toggleCoopModal?: (value: SetStateAction<boolean>) => void;
  setOpenStatusModal?: (value: SetStateAction<boolean>) => void;
  setOpenLaunchModal?: (value: SetStateAction<boolean>) => void;
  isLaunchButtonDisabled?: boolean;
  setSearchBy?: Dispatch<SetStateAction<string>>;
  searchBy?: string;
  saveNewOffer: () => Promise<boolean>;
  rawSelectedOffers: RawSelectedOffers;
  headerSaveEdits?: (operation: "create" | "update") => void;
  isDuplicateMode?: boolean;
  setAddingMode?: Dispatch<SetStateAction<boolean>>;
}

const AssetBuilderHeader: FC<IAssetBuilderHeader> = ({
  actionButtons,
  selectedItem,
  selectedSubItem = "",
  enabledHeaders,
  resetAssetInstances,
  resetOfferEditsWereSaved,
  assetInstanceComparator,
  assetInstances,
  setSaveDialog,
  offerDataComparator,
  offerData,
  setOrderHasBeenClicked,
  commitOrder,
  countGeneratedInstances,
  selectedOffers,
  totalSelectedOffers,
  offerTypes,
  updateNewOrder,
  savedOrder,
  disabledExport,
  toggleExportDrawer,
  toggleForceRenderImages,
  disabledCoop,
  toggleCoopModal,
  setOpenStatusModal,
  setOpenLaunchModal,
  isLaunchButtonDisabled,
  setSearchBy,
  searchBy,
  saveNewOffer,
  rawSelectedOffers,
  headerSaveEdits,
  isDuplicateMode,
  setAddingMode,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isAdmin = useIsAdmin();

  const reviewValidator =
    countGeneratedInstances && countGeneratedInstances() < 1;

  const selectHeaderEnabled =
    ["edit-offer", "create-offer"].every(path => !pathname.includes(path)) &&
    enabledHeaders.includes("select") &&
    pathname !== "/asset-builder/orders" &&
    isAdmin;

  const buildHeaderEnabled =
    ["edit-offer", "create-offer"].every(path => !pathname.includes(path)) &&
    enabledHeaders.includes("build") &&
    pathname !== "/asset-builder/orders" &&
    isAdmin;

  const reviewHeaderEnabled =
    ["edit-offer", "create-offer"].every(path => !pathname.includes(path)) &&
    (enabledHeaders.includes("review") || !reviewValidator) &&
    pathname !== "/asset-builder/orders";

  const headerMenus: IHeader = {
    style: {
      display: "flex",
      alignItem: "center",
      flex: "initial",
      width: selectedItem === "AD LAUNCHER" ? "20em" : "40em",
    },
    topSteps: {
      selected: selectedItem,
      style: {
        flex: 2,
        borderRight: selectedItem === "AD LAUNCHER" ? "" : "1px solid white",
        paddingRight: "1.5em",
      },
      steps: [
        {
          title: selectedItem === "AD LAUNCHER" ? "BACK" : "ORDERS",
          state:
            enabledHeaders.includes("orders") ||
            enabledHeaders.includes("launch")
              ? "enabled"
              : "disabled",
          onClick: () => {
            if (
              (isEqual(assetInstances, assetInstanceComparator) &&
                isEqual(offerData, offerDataComparator)) ||
              pathname.includes("/asset-builder/orders") ||
              pathname.includes("/review")
            ) {
              resetAssetInstances(); // AV2-1631: ensure that all asset instances are cleared when going to the orders page
              if (selectedItem === "AD LAUNCHER") {
                const reviewUrl = window.location.href.replace(
                  "asset-launcher",
                  "review",
                );
                window.location.replace(reviewUrl);
              } else {
                navigate("/asset-builder/orders");
              }
            } else {
              setSaveDialog(true);
              setOrderHasBeenClicked(true);
            }
          },
        },
        {
          title: selectedItem === "AD LAUNCHER" ? "AD LAUNCHER" : "OFFERS",
          state: enabledHeaders.includes("offers") ? "enabled" : "disabled",
          onClick: () => {
            // do nothing here
          },
        },
      ],
    },
    middleSteps:
      selectedItem === "AD LAUNCHER"
        ? []
        : [
            {
              selected: selectedSubItem,
              style: {
                flex: "initial",
                width: "3em",
                margin: "0 0.5em",
              },
              steps: [
                {
                  title: "INPUT",
                  state: "disabled",
                  onClick: () => {
                    // fill
                  },
                },
              ],
            },
            {
              selected: selectedSubItem,
              style: {
                flex: 3,
              },
              steps: [
                {
                  title: "SELECT",
                  state: selectHeaderEnabled ? "enabled" : "disabled",
                  onClick: () => {
                    resetOfferEditsWereSaved();

                    const url = updatePathUrl(pathname, "select");
                    if (
                      (isEqual(assetInstances, assetInstanceComparator) &&
                        isEqual(offerData, offerDataComparator)) ||
                      pathname.includes("/review")
                    ) {
                      // if this step is enabled for click, that indicates the user is either on select page or after it.
                      // use  path for re-directing...

                      navigate(url);
                    } else {
                      setSaveDialog(true, url);
                    }
                  },
                },
                {
                  title: "BUILD",
                  state: buildHeaderEnabled ? "enabled" : "disabled",
                  onClick: () => {
                    resetOfferEditsWereSaved();

                    const url = updatePathUrl(pathname, "build");

                    if (
                      (isEqual(assetInstances, assetInstanceComparator) &&
                        isEqual(offerData, offerDataComparator)) ||
                      pathname.includes("/review")
                    ) {
                      navigate(url);
                    } else {
                      setSaveDialog(true, url);
                    }
                  },
                },
                {
                  title: "REVIEW",
                  state: reviewHeaderEnabled ? "enabled" : "disabled",
                  onClick: () => {
                    resetOfferEditsWereSaved();
                    const url = updatePathUrl(pathname, "review");

                    if (
                      isEqual(assetInstances, assetInstanceComparator) &&
                      isEqual(offerData, offerDataComparator)
                    ) {
                      navigate(url);
                    } else {
                      setSaveDialog(true, url);
                    }
                  },
                },
              ],
            },
          ],
    saveButton:
      pathname.endsWith("/select") || pathname.endsWith("/build") ? (
        <Button
          key="build-asset-save-button"
          className="action-save-button"
          onClick={async () => {
            const { assetInstances: assetInstanceVals, selectedOrder } =
              savedOrder || {};

            if (selectedOrder) {
              const assetCounter = assetInstanceVals
                ? getAssetCount(assetInstanceVals)
                : selectedOrder.num_assets;
              const stampTotal = assetInstances
                ? getStampCount(assetInstances)
                : selectedOrder.totalUsedStamps;
              const updatedOrder: Partial<INewOrderRecord> = {
                id: selectedOrder.id,
                key: 0,
                actions: "ok",
                selectedInventory:
                  selectedOffers.length || selectedOrder.selectedInventory,
                totalSelectedOffers:
                  totalSelectedOffers || selectedOrder.totalSelectedOffers,
                selectedOrderOffers:
                  offerTypes || selectedOrder.selectedOrderOffers,
                num_assets: assetCounter,
                totalUsedStamps: stampTotal,
              };
              updateNewOrder(updatedOrder).then(() => {
                commitOrder();
              });
            }
          }}
          icon={<SaveOutlined />}
        />
      ) : null,
    actionButtons,
  };

  const [initialOrderState, setInitialOrderState] = useState(
    savedOrder?.selectedOffers,
  );

  useEffect(() => {
    if (!!initialOrderState) return;
    setInitialOrderState(savedOrder?.selectedOffers);
  }, [initialOrderState, savedOrder?.selectedOffers]);

  const isInternalEnv = isEnvVarEquals("CLIENT", "internal");
  const disableInternalLaunch =
    isInternalEnv &&
    ![StatusOptions.COOP_APPROVED, StatusOptions.COOP_NOT_REQUIRED].includes(
      savedOrder?.selectedOrder?.status || StatusOptions.NO_STATUS,
    );

  const isLauncherDisabled = !isAdmin || disableInternalLaunch;

  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(false);

  const validateTabs = (assetInstances: AssetInstanceRecord) => {
    const tabsWithErrors = Object.keys(assetInstances || {}).filter(tab => {
      const sizes = Object.keys(assetInstances[tab] || {});
      return sizes.some(size =>
        assetInstances[tab][size].some(instance => !instance.template),
      );
    });
    if (tabsWithErrors.length) {
      const tabNames = `${tabsWithErrors} ${
        tabsWithErrors.length > 1 ? "have" : "has"
      }`;
      return {
        error: `Unable to save order. ${tabNames} unfinished instances.`,
      };
    }
    return { error: undefined };
  };

  const saveExisitingOffer = useCallback(async () => {
    setIsSaveBtnDisabled(true);
    const { selectedOrder } = savedOrder || {};
    // if the page is selectV2, doesn't need to save order progress
    if (pathname.includes("select-v2")) {
      message.success("Order Progress Saved");
    } else if (selectedOrder) {
      const { error } = validateTabs(assetInstances);
      // Prevent save when instances are incomplete.
      if (error) {
        message.error(error);
        setIsSaveBtnDisabled(false);
        return;
      }

      const assetCounter = assetInstances
        ? getAssetCount(assetInstances)
        : selectedOrder.num_assets;
      const stampTotal = assetInstances
        ? getStampCount(assetInstances)
        : selectedOrder.totalUsedStamps;
      const updatedOrder: Partial<INewOrderRecord> = {
        id: selectedOrder.id,
        key: 0,
        actions: "ok",
        selectedInventory:
          selectedOffers.length || selectedOrder.selectedInventory,
        totalSelectedOffers:
          totalSelectedOffers || selectedOrder.totalSelectedOffers,
        selectedOrderOffers: offerTypes || selectedOrder.selectedOrderOffers,
        num_assets: assetCounter,
        totalUsedStamps: stampTotal,
      };
      try {
        await updateNewOrder(updatedOrder);
        await commitOrder();
        message.success("Order Progress Saved");
      } catch (error) {
        message.success("Unable to save order");
      } finally {
        setIsSaveBtnDisabled(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    assetInstances,
    commitOrder,
    offerTypes,
    selectedOffers.length,
    totalSelectedOffers,
    updateNewOrder,
  ]);

  const onSave = useCallback(async () => {
    if (pathname.endsWith("/create-offer")) saveNewOffer();
    else saveExisitingOffer();
  }, [pathname, saveExisitingOffer, saveNewOffer]);

  const onBuildClick = () => {
    resetOfferEditsWereSaved();
    const url = updatePathUrl(pathname, "build");

    if (pathname.endsWith("/select")) {
      commitOrder();
      const originalVins = initialOrderState?.map(order => order.offerData.vin);
      const editedVins = getSelectedVins(rawSelectedOffers);

      if (
        !isSameSelected(originalVins, editedVins) ||
        !isSameOffer(initialOrderState, selectedOffers)
      ) {
        setSaveDialog(true, url);
        return;
      }
    }

    if (
      (isEqual(assetInstances, assetInstanceComparator) &&
        isEqual(offerData, offerDataComparator)) ||
      pathname.includes("/review")
    ) {
      navigate(url);
    } else {
      setSaveDialog(true, url);
    }
  };

  const onSelectOfferClick = () => {
    resetOfferEditsWereSaved();
    const url = updatePathUrl(pathname, "select");

    if (
      (isEqual(assetInstances, assetInstanceComparator) &&
        isEqual(offerData, offerDataComparator)) ||
      pathname.includes("/review")
    ) {
      // if this step is enabled for click, that indicates the user is either on select page or after it.
      // use  path for re-directing...

      const isInlineEditingEnabled = isFeatureEnabled("ENABLE_INLINE_EDITING");
      if (!isInlineEditingEnabled) {
        navigate(url);

        return;
      }

      Modal.confirm({
        closable: false,
        title: "Skip to Select or Inline Editing?",
        content: `You can either go to "Select" or "Inline Editing" `,
        cancelText: "Select",
        onCancel: e => {
          // When user hit the esc key
          if (e.triggerCancel) return;

          // This is to redirect to select
          Modal.destroyAll();
          const url = updatePathUrl(pathname, "select");
          navigate(url);
        },
        okText: "Inline Editing",
        okButtonProps: { type: "ghost" },
        onOk: () => {
          // This is to redirect to select-v2
          const url = updatePathUrl(pathname, "select-v2");
          navigate(url);
        },
        autoFocusButton: null,
      });
    } else {
      setSaveDialog(true, url);
    }
  };

  // enum represents each part of the url
  // split into 3 parts if redirecting to the orders page and 4 for any other section in asset builder
  const onSelectOfferArrowClick = () => {
    resetOfferEditsWereSaved();
    const splitUrl = pathname.split("/");
    const { DOMAIN, ASSETBUILDER, ORDER, ORDERID } = URLPattern;
    const url = [
      splitUrl[DOMAIN],
      splitUrl[ASSETBUILDER],
      splitUrl[ORDER],
      splitUrl[ORDERID],
      "offers/select",
    ].join("/");
    if (
      splitUrl.length >= 4 &&
      ((isEqual(assetInstances, assetInstanceComparator) &&
        isEqual(offerData, offerDataComparator)) ||
        pathname.includes("/review"))
    ) {
      navigate(url);
    } else {
      setSaveDialog(true, url);
    }
  };
  const onOrdersPageClick = () => {
    const splitUrl = pathname.split("/");
    const { DOMAIN, ASSETBUILDER, ORDER } = URLPattern;
    const url = [
      splitUrl[DOMAIN],
      splitUrl[ASSETBUILDER],
      splitUrl[ORDER],
    ].join("/");
    if (
      splitUrl.length >= 3 &&
      ((isEqual(assetInstances, assetInstanceComparator) &&
        isEqual(offerData, offerDataComparator)) ||
        pathname.includes("/review"))
    ) {
      navigate(url);
    } else {
      setSaveDialog(true, url);
    }
  };

  const onReviewClick = () => {
    const { error } = validateTabs(assetInstances);
    if (error) {
      message.error(error).then();
      return;
    }
    resetOfferEditsWereSaved();
    const url = updatePathUrl(pathname, "review");

    if (
      isEqual(assetInstances, assetInstanceComparator) &&
      isEqual(offerData, offerDataComparator)
    ) {
      navigate(url);
    } else {
      setSaveDialog(true, url);
    }
  };

  const onExportClick = () => {
    toggleExportDrawer?.(true);
    toggleForceRenderImages?.(true);
  };

  const onCoopClick = () => {
    toggleCoopModal?.(true);
    toggleForceRenderImages?.(true);
  };

  const onLaunchClick = () => {
    navigate(
      `/asset-builder/orders/${savedOrder?.selectedOrder?.id}/offers/asset-launcher`,
    );
  };

  const onStatusClick = () => {
    setOpenStatusModal?.(true);
  };
  const onLauncherClick = () => {
    setOpenLaunchModal?.(true);
  };
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const savePaths = ["/select", "/select-v2", "/build"];
  const isSaveOrderEnabled = savePaths.some(path => {
    return pathname.endsWith(path) || pathname.includes(path);
  });

  const editPaths = ["/create-offer", "/edit-offer"];

  const isOfferEditEnabled = editPaths.some(path => {
    return pathname.endsWith(path) || pathname.includes(path);
  });

  const toolbarContents: ToolbarButton = {
    New: {
      disabled: false,
      onClick: () => {
        if (pathname.endsWith("select"))
          navigate(`${pathname.replace("select", "create-offer")}`);
        else !!setAddingMode && setAddingMode(true);
      },
      display: pathname.includes("select"),
    },
    SelectOffer: {
      disabled: false,
      onClick: onSelectOfferClick,
      display: pathname.endsWith("/build") || pathname.endsWith("/review"),
    },
    SelectOfferArrow: {
      disabled: false,
      onClick: onSelectOfferArrowClick,
      display:
        pathname.endsWith("/create-offer") || pathname.includes("/edit-offer"),
    },
    BuildAsset: {
      disabled: !buildHeaderEnabled,
      onClick: onBuildClick,
      display:
        pathname.endsWith("/select") ||
        pathname.includes("select-v2") ||
        pathname.endsWith("/review"),
    },
    Review: {
      disabled: !reviewHeaderEnabled,
      onClick: onReviewClick,
      display:
        pathname.endsWith("/select") ||
        pathname.includes("select-v2") ||
        pathname.endsWith("/build") ||
        pathname.endsWith("/asset-launcher"),
    },
    SaveOrder: {
      disabled: !isSaveOrderEnabled || isSaveBtnDisabled,
      display: !(
        pathname.endsWith("/review") ||
        pathname.endsWith("/asset-launcher") ||
        isOfferEditEnabled
      ),
      onClick: () => {
        onSave();
      },
    },
    CreateOffer: {
      disabled: isSaveBtnDisabled || !isOfferEditEnabled,
      display:
        (!(
          pathname.endsWith("/review") || pathname.endsWith("/asset-launcher")
        ) &&
          pathname.endsWith("/create-offer")) ||
        isDuplicateMode,
      onClick: () => {
        headerSaveEdits && headerSaveEdits("create");
      },
    },
    UpdateOffer: {
      disabled: isSaveBtnDisabled || !isOfferEditEnabled,
      display:
        !(
          pathname.endsWith("/review") || pathname.endsWith("/asset-launcher")
        ) &&
        pathname.includes("/edit-offer") &&
        !isDuplicateMode,
      onClick: () => {
        headerSaveEdits && headerSaveEdits("update");
      },
    },
    OrderPage: {
      display:
        pathname.endsWith("/review") ||
        pathname.endsWith("/select") ||
        pathname.endsWith("/build"),
      onClick: () => {
        onOrdersPageClick();
      },
    },
    OrderDetails: {
      disabled: false,
      onClick: () => setIsDrawerVisible(!isDrawerVisible),
    },
    Export: {
      disabled: disabledExport || !isAdmin,
      display: pathname.endsWith("/review"),
      onClick: onExportClick,
    },
    Coop: {
      disabled: disabledExport || !isAdmin || disabledCoop,
      display: pathname.endsWith("/review"),
      onClick: onCoopClick,
    },
    Launch: {
      disabled: isLauncherDisabled,
      display: pathname.endsWith("/review"),
      onClick: onLaunchClick,
    },
    Status: {
      disabled: false,
      display: pathname.endsWith("/asset-launcher"),
      onClick: onStatusClick,
    },
    Launcher: {
      disabled: isLaunchButtonDisabled,
      display: pathname.endsWith("/asset-launcher"),
      onClick: onLauncherClick,
    },
  };

  const onDrawerClose = () => setIsDrawerVisible(!isDrawerVisible);

  const onSearch = (value: string) => {
    const disabledPaths = ["/build", "/review", "/asset-launcher"];
    const disableSearch = disabledPaths.some(path => pathname.endsWith(path));
    if (disableSearch) return;
    setSearchBy?.(value);
  };

  return (
    <>
      {pathname.endsWith("/orders") ? (
        <Header
          style={headerMenus.style}
          topSteps={headerMenus.topSteps}
          middleSteps={headerMenus.middleSteps}
          actionButtons={headerMenus.actionButtons}
          saveButton={headerMenus.saveButton}
        />
      ) : (
        <div className={styles.header}>
          <ToolbarTable
            searchValue={searchBy || ""}
            toolbarContents={toolbarContents}
            searchPlaceholder="Search"
            titleTooltip=""
            onSearch={onSearch}
          />
          <OrderDetailsDrawer
            visible={isDrawerVisible}
            onClose={onDrawerClose}
          />
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state: any) => {
  const { newOrders, assetBuilder, configuration } = state;
  const { currentSelectedOrder } = newOrders as INewOrderState;
  const {
    selectedOffers,
    assetInstanceComparator,
    offerDataComparator,
    offerData,
    assetInstances,
    savedOrder,
    rawSelectedOffers,
  } = assetBuilder as IAssetBuilderState;
  const { config } = configuration as IConfigurationState;

  let totalSelectedOffers = 0;
  const offerSet = new Set();

  for (const offer of selectedOffers) {
    totalSelectedOffers += offer.offers.length;
    for (const offerType of offer.offers) {
      offerSet.add(offerType);
    }
  }

  return {
    config,
    currentOrder: currentSelectedOrder,
    assetInstanceComparator,
    assetInstances,
    offerDataComparator,
    offerData,
    offerTypes: Array.from(offerSet).join(", \n"),
    totalSelectedOffers,
    selectedOffers,
    savedOrder,
    rawSelectedOffers,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    saveNewOffer: () => {
      return dispatch(
        actions.assetBuilder.saveNewOffer(),
      ) as unknown as Promise<boolean>;
    },
    resetAssetInstances: () => {
      dispatch(actions.assetBuilder.resetAssetInstances());
    },
    setSaveDialog: (input: boolean, path?: string) => {
      dispatch(actions.assetBuilder.setSaveDialog({ input, newPath: path }));
    },
    updateNewOrder: (updateNewOrder: Partial<INewOrderRecord>) => {
      return dispatch(
        actions.newOrders.updateNewOrder(updateNewOrder),
      ) as Promise<void>;
    },
    commitOrder: () => {
      return dispatch(actions.assetBuilder.commitOrder()) as Promise<void>;
    },
    resetOfferEditsWereSaved: () =>
      dispatch(actions.assetBuilder.resetOfferEditsWereSaved()),
    fetchOrderState: (
      orderId: string,
      assetExportQueryObj?: IAssetExportQueryParamObj,
      redirectType?: string,
    ) => {
      dispatch(
        actions.assetBuilder.fetchOrderState(
          orderId,
          assetExportQueryObj,
          redirectType,
        ),
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetBuilderHeader);
