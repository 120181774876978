import { observer } from "mobx-react-lite";
import {
  TextLayerElement,
  ImageLayerElement,
} from "screens/designStudio/designStudioV2/types";
import { Slider } from "@mui/material";

type BlurSliderProps = {
  elements: (TextLayerElement | ImageLayerElement)[];
};
const BlurSlider = observer(({ elements }: BlurSliderProps) => {
  const [layer] = elements;

  const handleBlurChange = (_event: Event, newValue: number | number[]) => {
    const blurValue = Array.isArray(newValue) ? newValue[0] : newValue;
    elements.forEach(element => {
      element.set({ blurEnabled: true, blurRadius: blurValue });
    });
  };

  return (
    <Slider
      value={layer?.blurRadius || 0}
      onChange={handleBlurChange}
      valueLabelDisplay="auto"
      min={0}
      max={100}
      step={1}
    />
  );
});

export default BlurSlider;
