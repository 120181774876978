import API from "services";
import { useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

const flipFacebookCampaigns = async (body: {
  fbAccountId: string;
  selectedCampaigns: Record<React.Key, React.Key[]>;
  flipWithAds: boolean;
  flipDate: string;
}) => {
  const { result, error } = await API.services.adReview.flipFacebookCampaigns(
    body,
  );

  if (error) {
    throw Error(error.message);
  }

  return result;
};

export const useGetFlipStatus = (executionId?: string) =>
  useQuery(
    ["flipStatus", executionId],
    () => {
      if (!executionId) return Promise.reject("Execution ID is not defined");
      return API.services.adReview.getFlipStatus(executionId);
    },
    {
      enabled: !!executionId,
      refetchInterval: data => {
        if (data?.result?.status === "processing") return 2500;
        return false;
      },
    },
  );

export const useFlipFacebookCampaigns = () => {
  const queryClient = useQueryClient();

  const flipMutation = useMutation(flipFacebookCampaigns, {
    onSettled: () => {
      queryClient.invalidateQueries("fbCampaign");
    },
  });

  const { data, isFetching } = useGetFlipStatus(
    flipMutation.data?.executionArn,
  );
  const computedFlipStatus = useMemo(() => {
    if (!isFetching) return JSON.parse(data?.result?.responseJson || "[]");
  }, [data, isFetching]);

  return {
    ...flipMutation,
    flipStatus: computedFlipStatus,
    isLoading:
      flipMutation.isLoading ||
      isFetching ||
      data?.result?.status === "processing",
  };
};
