import Spinner from "screens/adLibrary/adLoadV2/shared/Spinner";
import { LoadCampaignsContext } from "./loadCampaigns/LoadProvider";
import LoadDrawer from "./loadCampaigns/LoadDrawer";
import { useSelectedBeeswaxCampaigns } from "./hooks/useSelectedBeeswaxCampaigns";

const LoadCampaigns = () => {
  const { selectedCampaigns, isLoading } = useSelectedBeeswaxCampaigns();

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <LoadCampaignsContext selectedCampaigns={selectedCampaigns}>
      <LoadDrawer />
    </LoadCampaignsContext>
  );
};

export default LoadCampaigns;
