import { RcFile } from "antd/lib/upload/interface";
import { TRuleCondition } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesContext";
import { IFilter } from "shared/hooks/assetExporter/useFeedConfigFetch";
import {
  DeleteCronFeed,
  DeleteSmartColumn,
  DelSmartCol,
  ExportBatchAssets,
  ExportBatchAssetsBody,
  FeedConfig,
  FeedCron,
  FeedStatus,
  FeedWithRule,
  GetCsvPreview,
  GetCsvUrl,
  GetCsvUrlArgs,
  GetExportAssetBatchesStatus,
  GetFeedCron,
  GetGoogleSheets,
  GetMediaColumnsQueryParams,
  GetPrcdFeedForAdLib,
  GetPrcdRecords,
  GetRawData,
  GetSmartColumns,
  GetValidateCredsBody,
  GetValuesByColumn,
  GoogleDriveAPIUrlResponse,
  GoogleDriveUrlMode,
  MediaColumn,
  PatchFeedRowResult,
  PostCreateFeedCron,
  PostGoogleSheet,
  PostGoogleSheetBody,
  PostProcessPreview,
  PostSignedCsvUrl,
  PrcdFeedForAdLib,
  ProcessedPreview,
  ProcessFeedBody,
  ProcessFeeds,
  ProcessPatchFeedRowField,
  PullLatestFeedData,
  PutCsvFile,
  PutServerConfig,
  SaveSmartColumn,
  ServerConfigParams,
  TAssetBatch,
  TAssetBatchRequest,
  TAssetBatchId,
  THasAudioBody,
  THasAudioResponse,
  TRule,
  TSortArgs,
  ValidateCreds,
} from "shared/types/assetExporter";
import { IConfig } from "shared/types/configuration";
import { IApiResponse } from "shared/types/shared";
import HttpClient from "./httpClient";

export default ({ config }: { headers: any; config: IConfig }) => ({
  getSmartColumns: async (feedId: string) => {
    const { smartColumnUrl } = config.services.assetExporter;
    const url = `${smartColumnUrl}?feedId=${feedId}`;
    return HttpClient.get<GetSmartColumns>(url);
  },

  // TODO - remove this once we have a better way to handle the error
  getApiRawFeed: async (
    feedId: string,
    start: number,
    end: number,
    page: string,
    pageSize: string,
    searchArgs?: string,
    sortArgs?: TSortArgs,
  ) => {
    const { rawFeedsUrl } = config.services.assetExporter;
    const queryParams = `?feedId=${feedId}&includeSmartColumns=true`;
    const addParams = `&start=${start}&end=${end}`;
    const pageParams = `&page=${page}&pageSize=${pageSize}`;
    const sortParams = `&sortColumn=${sortArgs?.column ?? ""}&sortOrder=${
      sortArgs?.direction ?? ""
    }`;
    const sortType = sortArgs?.type ? `&sortType=${sortArgs?.type}` : null;
    const searchParams = `&search=${searchArgs}`;
    const url = `${rawFeedsUrl}${queryParams}`;

    const { result } = await HttpClient.get<GetRawData>(
      `${url}${addParams}${pageParams}${sortArgs ? sortParams : ""}${
        sortType ? sortType : ""
      }${searchArgs ? searchParams : ""}`,
    );

    const rows = result.rows;
    const orders = result.orders.filter(order => order !== "vehicleOfferId");
    return { ...result, orders, rows };
  },

  // TODO - final new method
  getRawFeed: async (
    feedId: string,
    start: number,
    end: number,
    page: string,
    pageSize: string,
    searchArgs?: string,
    sortArgs?: TSortArgs,
  ) => {
    const { rawFeedsUrl } = config.services.assetExporter;

    const queryParams = new URLSearchParams({
      feedId,
      includeSmartColumns: "true",
      start: start.toString(),
      end: end.toString(),
      page: page.toString(),
      pageSize: pageSize.toString(),
      sortColumn: sortArgs?.column ?? "",
      sortOrder: sortArgs?.direction ?? "",
      sortType: sortArgs?.type ?? "",
      search: searchArgs ?? "",
    }).toString();

    const url = `${rawFeedsUrl}?${queryParams}`;

    const { result, error } = await HttpClient.get<GetRawData>(url);

    if (!!error) throw new Error(error.message);

    const rows = result.rows;
    const orders = result.orders.filter(order => order !== "vehicleOfferId");
    return { result: { ...result, orders, rows }, error: null };
  },

  getRawFeedV2: async (
    feedId: string,
    start: number,
    end: number,
    page: string,
    pageSize: string,
    searchArgs?: string,
    sortArgs?: TSortArgs,
    filterArgs?: IFilter[],
    ruleArgs?: TRuleCondition[],
  ) => {
    const { rawFeedsUrl } = config.services.assetExporter;

    const { result, error } = await HttpClient.post<GetRawData>(rawFeedsUrl, {
      feedId,
      includeBatchColumn: "true",
      start: start.toString(),
      end: end.toString(),
      page: page.toString(),
      pageSize: pageSize.toString(),
      sortColumn: sortArgs?.column ?? "",
      sortOrder: sortArgs?.direction ?? "",
      sortType: sortArgs?.type ?? "",
      search: searchArgs ?? "",
      filters: filterArgs ?? [],
      rules: ruleArgs ?? [],
    });

    if (!!error) throw new Error(error.message);

    const rows = !result ? [] : result.rows;
    const orders = !result
      ? []
      : result.orders.filter(order => order !== "vehicleOfferId");
    return { result: { ...result, orders, rows }, error: null };
  },

  getValuesByColumn: async (feedId: string, columnName: string) => {
    const valuesByColumnUrl =
      config.services.assetExporter.getValuesByColumnUrl({
        feedId,
        columnName,
      });

    const { result, error } = await HttpClient.get<GetValuesByColumn>(
      valuesByColumnUrl,
    );

    if (!!error) throw new Error(error.message);

    return result?.values ?? [];
  },

  deleteSmartColumn: async (feedId: string, columName: string) => {
    const { deleteSmartCol } = config.services.assetExporter;
    const url = `${deleteSmartCol}/${feedId}/smart-columns/${columName}`;
    return HttpClient.delete<DeleteSmartColumn>(url);
  },
  saveSmartColumn: async (
    feedId: string,
    columnName: string,
    rules: TRule[],
  ) => {
    const { smartColumnUrl } = config.services.assetExporter;
    const smartColumn = {
      feedId,
      column: columnName,
      rules,
    };
    return HttpClient.post<SaveSmartColumn>(smartColumnUrl, smartColumn);
  },
  processFeed: (body: ProcessFeedBody) => {
    const url = config.services.assetExporter.processFeedsUrl;
    return HttpClient.post<ProcessFeeds>(url, body);
  },
  exportAssetBatches: (body: ExportBatchAssetsBody) => {
    const url = config.services.assetExporter.exportAssetBatches;
    return HttpClient.post<ExportBatchAssets>(url, body);
  },
  getExportAssetBatchesStatus: (executionId: string) => {
    const url = `${config.services.assetExporter.getExportAssetBatchesStatus}?executionId=${executionId}`;
    return HttpClient.get<GetExportAssetBatchesStatus>(url);
  },
  patchFeedRowField: (body: ProcessPatchFeedRowField) => {
    const url = config.services.assetExporter.patchFeedsUrl;
    return HttpClient.patch<PatchFeedRowResult>(url, body);
  },
  getProcessedRecords: (feedId: string, limit?: number) => {
    const url = config.services.assetExporter.processedRecordUrl;
    const queryParams = `?feedId=${feedId}${limit ? `&limit=${limit}` : ""}`;
    return HttpClient.get<GetPrcdRecords>(`${url}${queryParams}`);
  },
  getCsvDownloadUrl: (args: GetCsvUrlArgs) => {
    const { feedId, createdAt } = args;
    const url = config.services.assetExporter.csvDownloadUrl;
    const initQuery = `?feedId=${feedId}`;
    const versionsPageParams = `&createdAt=${createdAt}`;
    const queryParams = `${initQuery}${
      !!createdAt ? `${versionsPageParams}` : ""
    }`;
    return HttpClient.get<GetCsvUrl>(`${url}${queryParams}`);
  },
  pullLatestFeedData: (feedForm: FeedCron, pullLatest: boolean) => {
    const { pullLatestFeedDataUrl } = config.services.assetExporter;
    return HttpClient.post<PullLatestFeedData>(pullLatestFeedDataUrl, {
      ...feedForm,
      pullLatest,
    });
  },
  deleteColRule: (feedId: string, column: string, idx: number) => {
    const { deleteSmartCol } = config.services.assetExporter;
    const url = `${deleteSmartCol}/${feedId}/smart-columns/${column}/rules/${idx}`;
    return HttpClient.delete<DelSmartCol>(url);
  },
  getFeedCrons: () => {
    const url = config.services.assetExporter.fetchFeedCrons;
    return HttpClient.get<GetFeedCron>(url);
  },
  getGoogleSheets: (sheetId: string) => {
    const url = config.services.assetExporter.getGoogleSheets;
    return HttpClient.get<GetGoogleSheets>(`${url}?sheetId=${sheetId}`);
  },
  postGoogleSheets: (body: PostGoogleSheetBody) => {
    const url = config.services.assetExporter.getGoogleSheets;
    return HttpClient.post<PostGoogleSheet>(url, body);
  },
  validateCredentials: (body: GetValidateCredsBody) => {
    const url = config.services.assetExporter.validateCredentials;
    return HttpClient.post<ValidateCreds>(url, body);
  },
  deleteCronFeed: (feedId: string) => {
    const { deleteCronFeedUrl } = config.services.assetExporter;
    const url = `${deleteCronFeedUrl}/${feedId}`;
    return HttpClient.delete<DeleteCronFeed>(url);
  },
  getCsvSignedUrl: (fileName: string) => {
    const url = config.services.assetExporter.csvSignedUrl;
    return HttpClient.post<PostSignedCsvUrl>(url, { fileName });
  },
  putCsvFile: (url: string, data: RcFile) => {
    const headers: HeadersInit = new Headers();
    headers.set("Content-Type", "text/csv");
    headers.set("Accept", "*/*");
    return fetch(url, {
      method: "PUT",
      body: data,
      headers,
    }) as unknown as Promise<PutCsvFile>;
  },
  getCsvPreview: (
    feedFilename: string,
    isUpdate: boolean,
    feedId: string,
    page: number,
    isServer: boolean,
  ) => {
    const url = config.services.assetExporter.getFeedPreview;
    const queryParam = `feedFilename=${feedFilename}&page=${page}&isUpdate=${isUpdate}&feedId=${feedId}&isServer=${isServer}`;
    return HttpClient.get<IApiResponse<GetCsvPreview>>(`${url}?${queryParam}`);
  },
  putServerConfig: (body: ServerConfigParams) => {
    const url = config.services.assetExporter.putServerConfig;
    return HttpClient.post<IApiResponse<PutServerConfig>>(url, body);
  },
  getFeedsWithRules: () => {
    const url = config.services.assetExporter.getFeedsWithRules;
    return HttpClient.get<IApiResponse<FeedWithRule[]>>(url);
  },
  processPreview: (body: PostProcessPreview) => {
    const url = config.services.assetExporter.processPreview;
    return HttpClient.post<IApiResponse<ProcessedPreview>>(url, body);
  },
  createMappedFeedCron: (body: PostCreateFeedCron) => {
    const url = config.services.assetExporter.createFeedCron;
    return HttpClient.post<IApiResponse<{ success: boolean }>>(url, body);
  },
  getFeedConfig: (feedId: string) => {
    const url = config.services.assetExporter.getFeedConfig;
    return HttpClient.get<IApiResponse<FeedConfig>>(`${url}?feedId=${feedId}`);
  },
  getFeedProcessStatus: (feedId: string) => {
    const url = config.services.assetExporter.getFeedProcessStatus;
    return HttpClient.get<IApiResponse<FeedStatus>>(`${url}?feedId=${feedId}`);
  },
  getPrcdFeedForAdLib: (args: GetPrcdFeedForAdLib) => {
    const { feedId, id, createdBy } = args;
    const url = config.services.assetExporter.getPrcdFeedForAdLib;
    const dataParam = `?feedId=${feedId}&createdBy=${createdBy}`;
    const pageParam = !!id ? `&id=${id}` : "";
    const queryParam = `${dataParam}${pageParam}`;
    return HttpClient.get<IApiResponse<PrcdFeedForAdLib>>(
      `${url}${queryParam}`,
    );
  },
  updateRawOfferById: <T>(feedId: string, id: string): Promise<T> => {
    const { services } = config;
    return HttpClient.put(services.assetExporter.updateRawOfferByIdUrl, {
      feedId,
      id,
    });
  },
  postAssetBatch: (body: TAssetBatchRequest) => {
    const url = config.services.assetExporter.assetBatch;
    return HttpClient.post<IApiResponse<{ assetBatch: TAssetBatch }>>(
      url,
      body,
    );
  },
  getAssetBatch: (feedId: string, assetBatchId?: string) => {
    const url = `${config.services.assetExporter.assetBatch}?feedId=${feedId}`;
    return HttpClient.get<TAssetBatch[]>(
      assetBatchId ? `${url}&assetBatchId=${assetBatchId}` : url,
    );
  },
  deleteAssetBatch: (feedId: string, assetBatchId: string, undo?: boolean) => {
    const url = `${
      config.services.assetExporter.assetBatch
    }/${feedId}/${assetBatchId}${undo ? "?undo=true" : ""}`;
    return HttpClient.delete<IApiResponse<TAssetBatchId>>(url);
  },
  getMediaColumns: (
    feedId: string,
    queryParams: GetMediaColumnsQueryParams,
  ) => {
    const url = `${config.services.assetExporter.getMediaColumns}/${feedId}`;
    return HttpClient.post<MediaColumn[]>(url, queryParams);
  },
  getHtmlTemplatePreviewUrl: (templateId: string) => {
    return `${config.services.assetExporter.getHtmlTemplatePreviewUrl}/${templateId}`;
  },
  checkVideoAudio: (body: THasAudioBody) => {
    const url = config.services.assetExporter.checkVideoAudio;
    return HttpClient.post<THasAudioResponse>(url, body);
  },
  getGoogleDriveAPIUrl: async (url: string, mode: GoogleDriveUrlMode) => {
    const code = btoa(url);
    const endpoint = `${config.services.assetExporter.getGoogleDriveAPIUrl}?code=${code}&mode=${mode}`;
    return HttpClient.get<GoogleDriveAPIUrlResponse>(endpoint);
  },
});
