import { VisibilityRounded, VisibilityOffRounded } from "@mui/icons-material";
import { LayerElement } from "screens/designStudio/designStudioV2/types";
import { useLayerActions } from "./useLayerActions";
import { observer } from "mobx-react-lite";

type ToggleLayerVisibilityProps = {
  layer: LayerElement;
};

export const ToggleLayerVisibility = observer(
  ({ layer }: ToggleLayerVisibilityProps) => {
    const { toggleVisibility } = useLayerActions(layer);
    const Icon = layer.visible ? VisibilityRounded : VisibilityOffRounded;

    return (
      <Icon
        onClick={toggleVisibility}
        sx={{ fontSize: "medium", color: "action.active" }}
      />
    );
  },
);
