import { Input, InputProps } from "antd";
import { FilterBy } from "shared/components/FilterByInput/FIlterByInput";

type TextFilterProps = { title: string } & InputProps;

export const TextFilter = ({ title, ...rest }: TextFilterProps) => {
  return (
    <FilterBy title={title}>
      <Input id={title} {...rest} />
    </FilterBy>
  );
};
