import { useMutation, useQueryClient } from "react-query";
import { getErrorMessage } from "utils/errorMessage";
import { BRANDS_QUERY_KEY } from "shared/constants/brandsAccounts";
import { uploadImagesToS3 } from "./uploadImageToS3";
import { formatAccountForTable } from "./useGetFormattedBrands";
import API from "services";
import {
  IAccountRecord,
  IUpdateAccountResponse,
  IUpdateAccountResult,
} from "shared/types/accountManagement";
import { returnDealerDataObjFromRecord } from "redux/dealerManagement/dealerManagement.slice";
import { useBrandsAccountsContext } from "screens/brandsAccountsManagement/contexts/BrandsAccountsContext";
import { BrandRecord, ILogos } from "shared/types/brandsAccounts";
import { ACCOUNT_QUERY_KEY } from "../useFetchDealersV2";
import {
  errorNotification,
  successNotification,
} from "shared/components/customNotification/Notification";

interface IUpdateAccountParams {
  account: IAccountRecord;
  logosToUpload: ILogos;
  existingLogos: ILogos;
  logosToRemove: string[];
}

const updateAccount = async ({
  account,
  logosToUpload,
  existingLogos,
  logosToRemove,
}: IUpdateAccountParams) => {
  try {
    const uploadedLogos = await uploadImagesToS3(
      logosToUpload,
      "dealer-management",
      existingLogos,
    );
    account.logoUrlsFromS3 = uploadedLogos;
    account.logoUrl = uploadedLogos.squareImagesFromS3[0] ?? "";
    account.createdAt = account.createdAt ?? Date.now();
    account.updatedAt = Date.now();
    account.enabled = true;
    const { error, result } =
      await API.privServices.dealerManagement.updateDealer<IUpdateAccountResponse>(
        { ...returnDealerDataObjFromRecord(account) },
        undefined,
        logosToRemove,
      );

    if (error) throw new Error(error.message);
    if (!result) throw new Error("Error updating the account");

    return result;
  } catch (error) {
    const errMsg = getErrorMessage(error);
    throw new Error(errMsg);
  }
};

interface UseUpdateAccountProps {
  onSuccess: () => void;
}

export const useUpdateAccount = ({ onSuccess }: UseUpdateAccountProps) => {
  const queryClient = useQueryClient();
  const { setFormTargetElementForUpdate } = useBrandsAccountsContext();

  return useMutation<
    IUpdateAccountResult,
    Error,
    { params: IUpdateAccountParams; originalAccount: IAccountRecord }
  >(({ params }) => updateAccount(params), {
    onSuccess: (newAccount, { originalAccount }) => {
      queryClient.invalidateQueries(ACCOUNT_QUERY_KEY);
      const brandsResponse =
        queryClient.getQueryData<BrandRecord[]>(BRANDS_QUERY_KEY);
      brandsResponse?.forEach(brand => {
        if (brand.name === newAccount.editedDealer.dealer_oem) {
          const brandUpdated = {
            ...brand,
            children: brand.children.map(account => {
              if (account.name === originalAccount.dealerName) {
                return formatAccountForTable(
                  newAccount.editedDealer,
                  brand.name,
                );
              }
              return account;
            }),
          };
          setFormTargetElementForUpdate(brandUpdated);
        }
      });
      onSuccess();
      if (
        !!originalAccount.newDealerName &&
        originalAccount.newDealerName !== originalAccount.dealerName
      )
        queryClient.clear();

      successNotification({
        messageLabel: "Account updated successfully",
        size: "big",
      });
    },
    onError: error => {
      errorNotification({
        messageLabel:
          error.message === "Account name already exists."
            ? error.message
            : "Error updating account",
      });
    },
  });
};
