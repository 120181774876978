import { Button, Divider, Typography } from "antd";
import { FilterValue } from "antd/lib/table/interface";
import { Key, useMemo } from "react";
import { TagButton } from "./TagButton";
import TagList, { TagType } from "./TagList";
import styles from "./TableFiltersSection.module.scss";

interface TableFiltersSectionProps<KeyType extends string> {
  tags?: TagType<KeyType>[];
  selectedCount: number;
  onFilterClick?: () => void;
  displaySelectedOnly: boolean;
  clearTags?: (tableId?: string) => void;
  removeTag?: (key: KeyType, value: boolean | Key, tableId?: string) => void;
  currentItems: number;
  groupedItems?: number;
  totalItems: number;
  onSelectAll?: () => void;
  showSelectAll?: boolean;
  tableId?: string;
  onAddFilterClick?: () => void;
  disableRowSelection?: boolean;
}

export type FiltersType = Record<string, FilterValue | null>;

export function TableFiltersSection<KeyType extends string>({
  tags,
  onFilterClick,
  selectedCount,
  displaySelectedOnly,
  clearTags,
  removeTag,
  currentItems,
  groupedItems,
  totalItems,
  onSelectAll,
  showSelectAll,
  tableId,
  onAddFilterClick,
  disableRowSelection,
}: TableFiltersSectionProps<KeyType>) {
  const itemsString = useMemo(() => {
    const itemsToUse = groupedItems ?? totalItems;
    return currentItems === itemsToUse
      ? itemsToUse
      : `${currentItems} / ${itemsToUse}`;
  }, [currentItems, groupedItems, totalItems]);

  return (
    <div className={styles.host} data-cy="datalist-tags">
      <Typography.Text className={styles.itemCount}>
        {itemsString} Items
      </Typography.Text>
      {!!selectedCount && !disableRowSelection && (
        <TagButton
          dataCy="show-selected-button"
          disabled={!selectedCount}
          onClick={onFilterClick}
          text={`${selectedCount} selected`}
          tooltipText="Click to show the selected rows only"
          selected={displaySelectedOnly}
        />
      )}
      {onSelectAll && showSelectAll && (
        <Button className={styles.selectAll} type="link" onClick={onSelectAll}>
          {selectedCount === totalItems ? "Deselect All" : "Select All"}
        </Button>
      )}
      {onAddFilterClick && (
        <Divider type="vertical" className={styles.divider} />
      )}
      {onAddFilterClick && (
        <Button type="link" onClick={onAddFilterClick}>
          + Add Filter
        </Button>
      )}
      {tags && clearTags && removeTag && (
        <TagList<KeyType>
          onClear={clearTags}
          onRemove={removeTag}
          tags={tags}
          tableId={tableId}
        />
      )}
    </div>
  );
}
