import { Stack, Collapse, Box } from "@mui/material";
import { LayerElement } from "screens/designStudio/designStudioV2/types";
import { ToggleLayerVisibility } from "./layerItem/ToggleLayerVisibility";
import { ToggleLayerLock } from "./layerItem/ToggleLayerLock";
import { DeleteLayer } from "./layerItem/DeleteLayer";
import { observer } from "mobx-react-lite";
import { useRef } from "react";
import { useRefFocus } from "shared/hooks/useRefFocus";
import useOutsideClick from "shared/hooks/useOutsideClick";
import { LayerIcon } from "./layerItem/LayerIcon";
import { LayerName } from "./layerItem/LayerName";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { isTextElement, isGroupElement } from "../../../utils";
import { ExtraLayerDetails } from "./layerItem/ExtraLayerDetails";
import { ArrowDropDownRounded } from "@mui/icons-material";
import { useLayerCollapse } from "./layerItem/useLayerCollapse";

type LayerItemProps = {
  layer: LayerElement;
  selectedLayerId: string | null;
  onDoubleClick: (id: string) => void;
  onClick: (id: string) => void;
  onKeyDown: (
    layer: LayerElement,
    cleanUp: () => void,
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => void;
  onClickOutside: (layer: LayerElement) => void;
};

export const LayerItem = observer(
  ({
    layer,
    selectedLayerId,
    onDoubleClick,
    onClick,
    onClickOutside,
    onKeyDown,
  }: LayerItemProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const containerRef: React.RefObject<HTMLDivElement> = useOutsideClick(() =>
      onClickOutside(layer),
    );
    const isSelected = layer.id === selectedLayerId;
    const { showDropdown, isCollapsed, toggleCollapse } =
      useLayerCollapse(layer);

    const isHighlighted = !!layer.store.selectedElements.find(
      e => e.id === layer.id,
    );

    useRefFocus(inputRef, isSelected);

    return (
      <Stack
        ref={containerRef}
        direction="column"
        justifyContent="space-between"
        borderRadius={1}
        borderColor="divider"
        p={1}
        py={0.5}
        sx={{
          backgroundColor: isHighlighted ? "action.hover" : "background.paper",
          cursor: "pointer",
          "&:hover:not(:has(.subLayerItem:hover))": {
            backgroundColor: "action.hover",
            "& .layerActions": {
              display: isSelected ? "none" : "flex",
            },
          },
        }}
        onDoubleClick={() => onDoubleClick(layer.id)}
        onClick={() => onClick(layer.id)}
      >
        <Stack
          alignItems="center"
          justifyContent="space-between"
          direction="row"
        >
          <LayerHeader
            layer={layer}
            onKeyDown={onKeyDown}
            isSelected={isSelected}
            showDropdown={showDropdown}
            isCollapsed={isCollapsed}
            toggleCollapse={toggleCollapse}
          />
          <LayerActions layer={layer} />
        </Stack>

        {isTextElement(layer) && (
          <Collapse in={!isCollapsed}>
            <Stack>
              <ExtraLayerDetails layer={layer} />
            </Stack>
          </Collapse>
        )}
        <Collapse in={!isCollapsed}>
          <Stack>
            <Droppable droppableId={layer.id} type="child-layer">
              {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {isGroupElement(layer) &&
                    layer.children
                      .slice()
                      .reverse()
                      .map((child, index) => {
                        return (
                          <Draggable
                            draggableId={child.id}
                            index={index}
                            key={child.id}
                          >
                            {(draggableProvided, snapshot) => (
                              <Box
                                {...draggableProvided.draggableProps}
                                {...draggableProvided.dragHandleProps}
                                ref={draggableProvided.innerRef}
                                sx={{
                                  ...draggableProvided.draggableProps.style,
                                  borderRadius: 1,
                                  boxShadow: theme =>
                                    snapshot.isDragging
                                      ? `0px 2px 0px 0px ${theme.palette.primary.main}`
                                      : "none",
                                }}
                              >
                                <ChildLayerItem
                                  layer={child}
                                  key={child.id}
                                  onDoubleClick={onDoubleClick}
                                  onClickOutside={onClickOutside}
                                  onKeyDown={onKeyDown}
                                  selectedLayerId={selectedLayerId}
                                />
                              </Box>
                            )}
                          </Draggable>
                        );
                      })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Stack>
        </Collapse>
      </Stack>
    );
  },
);

type ChildLayerItemProps = {
  layer: LayerElement;
  onDoubleClick: (id: string) => void;
  onKeyDown: (
    layer: LayerElement,
    cleanUp: () => void,
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => void;
  onClickOutside: (layer: LayerElement) => void;
  selectedLayerId: string | null;
};

const ChildLayerItem = observer(
  ({
    layer,
    onDoubleClick,
    onKeyDown,
    selectedLayerId,
  }: ChildLayerItemProps) => {
    const isSelected = layer.id === selectedLayerId;
    return (
      <Stack
        spacing={1}
        p={1}
        py={0.5}
        pl={5}
        direction="column"
        alignItems="center"
        borderRadius={1}
        className="subLayerItem"
        onDoubleClick={e => {
          // Prevents double click on parent layer
          e.stopPropagation();
          onDoubleClick(layer.id);
        }}
        sx={{
          "&:hover": {
            backgroundColor: "action.hover",
          },
        }}
      >
        <LayerHeader
          layer={layer}
          onKeyDown={onKeyDown}
          isSelected={isSelected}
        />

        <ExtraLayerDetails layer={layer} />
      </Stack>
    );
  },
);

type LayerHeaderProps = Pick<LayerItemProps, "layer" | "onKeyDown"> & {
  isSelected: boolean;
  isCollapsed?: boolean;
  showDropdown?: boolean;
  toggleCollapse?: () => void;
};

const LayerHeader = observer(
  ({
    layer,
    isSelected,
    onKeyDown,
    isCollapsed,
    showDropdown,
    toggleCollapse,
  }: LayerHeaderProps) => {
    return (
      <Stack alignItems="center" direction="row" sx={{ width: 1 }}>
        <Stack direction="row" spacing={0} alignItems="center">
          {showDropdown && (
            <ArrowDropDownRounded
              fontSize="small"
              sx={{
                transform: isCollapsed ? "rotate(-90deg)" : "none",
                transition: "transform 300ms",
                cursor: "pointer",
                color: "action.active",
              }}
              onClick={toggleCollapse}
            />
          )}
          <LayerIcon layer={layer} />
        </Stack>

        <LayerName
          layer={layer}
          onKeyDown={onKeyDown}
          isSelected={isSelected}
        />
      </Stack>
    );
  },
);

type LayerActionsProps = {
  layer: LayerElement;
};

const LayerActions = ({ layer }: LayerActionsProps) => {
  return (
    <Stack
      spacing={0.5}
      direction="row"
      sx={{ display: "none", cursor: "pointer" }}
      // Classname is used to show/hide actions based on parent hover
      className="layerActions"
    >
      <ToggleLayerVisibility layer={layer} />
      <ToggleLayerLock layer={layer} />
      <DeleteLayer layer={layer} />
    </Stack>
  );
};
