import EditCell from "./editableCell/EditCell";
import Cell from "./editableCell/Cell";
import { useMemo, memo } from "react";
import { useEditableCell } from "./hooks/useEditableCell";
import { useCellContext } from "./editableCell/CellContext";
import { useImageURL } from "./editableCell/useImageURL";

interface EditableCellFields {
  value?: string | number;
  rowIdentifier?: string;
  placeholderText?: string;
  index: string;
}
interface EditableCellHandlers {
  onSave: (
    value: string | number,
    oldValue: string | number,
    disableConfirm?: boolean,
  ) => void;
  onApplyToAll?: () => void;
}

type EditableCellProps = EditableCellFields & EditableCellHandlers;

const EditableCell = ({
  value,
  index,
  rowIdentifier,
  placeholderText,
  onSave,
  onApplyToAll,
}: EditableCellProps) => {
  const { editingField, editingRow } = useCellContext();
  const { data: imageURL } = useImageURL(value?.toString() ?? "");

  const { handleToggleClicked } = useEditableCell(
    index,
    rowIdentifier ?? "",
    onSave,
  );

  const CellMemo = useMemo(() => {
    return (
      <Cell
        oldValue={value}
        toggleClicked={handleToggleClicked}
        placeholderText={placeholderText}
        imageURL={imageURL}
      />
    );
  }, [value, handleToggleClicked, placeholderText, imageURL]);

  const EditCellMemo = useMemo(() => {
    return (
      <EditCell
        value={value ?? ""}
        onSave={onSave}
        onApplyToAll={onApplyToAll}
        imageURL={imageURL}
      />
    );
  }, [value, onSave, onApplyToAll, imageURL]);

  return editingField === index && editingRow === rowIdentifier
    ? EditCellMemo
    : CellMemo;
};

EditableCell.displayName = "EditableCell";

export default memo(EditableCell);
