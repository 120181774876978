import { useMutation, useQueryClient } from "react-query";
import { getErrorMessage } from "utils/errorMessage";
import { uploadImagesToS3 } from "../uploadImageToS3";
import API from "services";
import {
  errorNotification,
  successNotification,
} from "shared/components/customNotification/Notification";
import { Account } from "shared/types/salesEnablement";
import { v4 } from "uuid";

const createOrUpdateAccount = async (params: Account) => {
  try {
    const uploadedLogos = await uploadImagesToS3(params.logos, "account");

    const logos = uploadedLogos.map(logo => {
      const accountLogo = params.logos.find(l => l.uid === logo.uid);
      return {
        url: logo.url,
        uid: logo.uid,
        name: logo.name,
        size: accountLogo?.size,
        type: accountLogo?.type,
        dimensions: accountLogo?.dimensions,
        lastModifiedDate: accountLogo?.lastModifiedDate,
      };
    });

    const employees = params.employees.map(employee => {
      return {
        ...employee,
        id: v4(),
      };
    });

    return API.privServices.salesEnablement.createOrUpdateAccount({
      ...params,
      logos,
      employees,
    });
  } catch (error) {
    const errMsg = getErrorMessage(error);
    throw new Error(errMsg);
  }
};

interface IUseMutateAccount {
  onSuccess: (account: Account) => void;
}

export const useMutateAccount = ({ onSuccess }: IUseMutateAccount) => {
  const queryClient = useQueryClient();

  return useMutation<Account, Error, Account>(createOrUpdateAccount, {
    onSuccess: async account => {
      const { id, lastUpdatedAt } = account;
      const isEdit = id !== lastUpdatedAt?.toString();

      await queryClient.invalidateQueries("accounts");

      onSuccess(account);
      successNotification({
        messageLabel: `Account ${isEdit ? "updated" : "created"} successfully`,
        size: "big",
      });
    },
    onError: error => {
      errorNotification({
        messageLabel:
          error.message === "Account name already exists."
            ? error.message
            : `Error creating a new account`,
      });
    },
  });
};
