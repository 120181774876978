import { useState } from "react";
import { getCAMThumbnail } from "../../getCAMThumbnail";
import { useFileTypes } from "../../useFileTypes";
import { EditableCell } from "../EditableCell";
import { AssetMenu } from "./AssetMenu";
import { GridSelect } from "./GridSelect";

import styles from "./Asset.module.scss";

type Props = {
  resource: WDAsset;
};

export const Asset = ({ resource }: Props) => {
  const { url } = getCAMThumbnail(resource);
  const { canSelect } = useFileTypes();
  const [hover, setHover] = useState(false);

  return (
    <div
      data-visible={hover}
      className={styles.host}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <GridSelect resource={resource} hover={hover} />
      <div className={styles.image} data-selectable={canSelect(resource)}>
        <img src={url} alt={resource.name} />
      </div>
      <EditableCell
        editable
        title="Name"
        element="div"
        dataIndex="name"
        record={resource}
      >
        <div className={styles.name}>{resource.name}</div>
      </EditableCell>
      <div>{hover ? <AssetMenu record={resource} visible /> : null}</div>
    </div>
  );
};
