import {
  createContext,
  memo,
  ReactNode,
  RefObject,
  useContext,
  useRef,
} from "react";

interface ContextProps {
  videoRef: RefObject<any>;
}

type ContextProviderProps = {
  children: ReactNode;
};

const Context = createContext<ContextProps>({} as ContextProps);

const ContextProvider = ({ children }: ContextProviderProps) => {
  const videoRef = useRef<any>(null);

  return (
    <Context.Provider
      value={{
        videoRef,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const AssetBatchesMediaRenderProvider = memo(ContextProvider);

export const useAssetBatchesMediaRenderContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error("Context must be used within a ContextProvider");
  }

  return context;
};
