export const getThumbnailURL = (
  feedId: string,
  assetBatchId: string | undefined,
  createdAt: number,
  rowIdentifier: string,
) => {
  if (
    !assetBatchId ||
    !process.env.REACT_APP_S3_UPLOAD_ASSETS ||
    !process.env.REACT_APP_AV2_CDN_DOMAIN
  )
    return "";

  const subdomain = process.env.REACT_APP_S3_UPLOAD_ASSETS;
  const domain = process.env.REACT_APP_AV2_CDN_DOMAIN;
  const path = `ad-engine/asset-batches/${feedId}/${assetBatchId}/thumbnail-results/${createdAt}/${rowIdentifier}.png`;
  return `https://${subdomain}.${domain}/${path}`;
};
