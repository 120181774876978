import { ComponentProps } from "react";
import { MetaKeywordSelect } from "./MetaKeywordSelect";
import { useGetKeywordsQuery } from "redux/media/media.api";

export type SelectProps = ComponentProps<typeof MetaKeywordSelect>;

export const MetaKeywords = (props: any) => {
  const { data: keywords } = useGetKeywordsQuery();
  return <MetaKeywordSelect {...props} mode="multiple" options={keywords} />;
};
