import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Popconfirm,
  Row,
  Select,
  Typography,
  Collapse,
} from "antd";
import { CarouselRef } from "antd/lib/carousel";
import { RefObject, memo } from "react";
import { callToActionValues } from "screens/adLibrary/constants";
import { ICard, IVisuals } from "shared/types/adLibrary";
import { CallToAction } from "screens/adLibrary/facebookUtils/types";
import styles from "./AdCarouselForm.module.scss";
import { formatOption } from "utils/helpers";
import { CarouselCardCollapse } from "./AdCarouselForm/CarouselCardCollapse";
import { CarouselCollapseState } from "screens/adLibrary/AdWizard";

export const SLIDES_TO_SHOW = 4;

interface IProps {
  cards: ICard[];
  addCard(): void;
  moveCard(index: number, to: "left" | "right"): void;
  removeCard(cardIndex: number): void;
  carouselRef: RefObject<CarouselRef>;
  patchCard(
    index: number,
    newValues: Partial<ICard>,
    updatedCards?: ICard[],
  ): void;
  shouldDisplayErrors: boolean;
  adEditVisuals?: IVisuals;
  carouselCollapse: CarouselCollapseState;
  setCarouselCollapse: (carouselCollapse: CarouselCollapseState) => void;
}

const AdCarouselForm = ({
  cards,
  addCard,
  removeCard,
  patchCard,
  shouldDisplayErrors,
  carouselCollapse,
  setCarouselCollapse,
}: IProps) => {
  const handleCTAChange = (value: CallToAction | undefined) => {
    const updatedCards = cards.map(card => ({
      ...card,
      ctaButtonText: value,
    }));

    patchCard(
      carouselCollapse.slide,
      updatedCards[carouselCollapse.slide],
      updatedCards,
    );
  };

  const tabBarExtraContent = {
    right: (
      <div className={styles.buttonsContainer}>
        <Button
          icon={<PlusOutlined />}
          disabled={cards.length > 9}
          onClick={() => {
            addCard();
            setCarouselCollapse({
              ...carouselCollapse,
              slide: cards.length,
            });
          }}
          className={styles.button}
          data-cy="add-visual-button"
        />
        {cards.length && (
          <Popconfirm
            placement="topLeft"
            title={"Are you sure you want to remove this card?"}
            onConfirm={() => {
              removeCard(carouselCollapse.slide);
              setCarouselCollapse({
                ...carouselCollapse,
                slide:
                  carouselCollapse.slide > 0 ? carouselCollapse.slide - 1 : 0,
              });
            }}
            okText="Yes"
            cancelText="No"
            disabled={cards.length <= 2}
            id="delete-visual-confirm"
            data-cy="delete-visual-confirm"
            className={styles.button}
          >
            <Button
              icon={<DeleteOutlined />}
              disabled={cards.length <= 2}
              data-cy="delete-visual-button"
            />
          </Popconfirm>
        )}
      </div>
    ),
  };
  return (
    <>
      <Form.Item
        name={["visuals", "ctaButtonText"]}
        required={true}
        label="CTA Button"
        rules={[
          {
            required: true,
            message: "Please select a CTA button text",
            whitespace: true,
          },
        ]}
      >
        <Select
          onChange={handleCTAChange}
          value={cards[0]?.ctaButtonText}
          data-cy="cta-button-select"
        >
          {callToActionValues.map(({ key, text, value }) => (
            <Select.Option
              key={key}
              value={value}
              data-cy={`option-${formatOption(text)}`}
            >
              {text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Typography.Text className={styles.title}>
        <span>*</span>
        {"Media"}
      </Typography.Text>
      <Row>
        <Col span={24}>
          <div data-cy="carousel-collapse">
            <Collapse
              accordion
              className={styles.stickyCollapse}
              onChange={v =>
                setCarouselCollapse({ ...carouselCollapse, panel: v })
              }
              activeKey={carouselCollapse.panel}
            >
              <Collapse.Panel
                header="In-feed Cards (1:1)"
                key="1:1"
                forceRender
                id="1:1-collapse-panel"
              >
                <CarouselCardCollapse
                  cards={cards}
                  currentSlide={carouselCollapse.slide}
                  setCurrentSlide={slide =>
                    setCarouselCollapse({ ...carouselCollapse, slide })
                  }
                  tabBarExtraContent={tabBarExtraContent}
                  patchCard={patchCard}
                  shouldDisplayErrors={shouldDisplayErrors}
                  type="IN_FEED"
                />
              </Collapse.Panel>
              <Collapse.Panel
                header="Story Cards (9:16)"
                key="9:16"
                forceRender
                id="9:16-collapse-panel"
              >
                <CarouselCardCollapse
                  cards={cards}
                  currentSlide={carouselCollapse.slide}
                  setCurrentSlide={slide =>
                    setCarouselCollapse({ ...carouselCollapse, slide })
                  }
                  tabBarExtraContent={tabBarExtraContent}
                  patchCard={patchCard}
                  shouldDisplayErrors={shouldDisplayErrors}
                  type="STORY"
                />
              </Collapse.Panel>
            </Collapse>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default memo(AdCarouselForm);
