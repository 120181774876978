import { useDataListURLData } from "shared/components/dataListURL/useDataListURLData";
import { useLoadCampaignsContext } from "../LoadProvider";

export const useFilteredDataSource = () => {
  const { dataSource } = useLoadCampaignsContext();

  return useDataListURLData({
    isLoading: false,
    data: dataSource,
    isError: false,
  });
};
