import {
  AlignLayersActionType,
  IDimension,
  IExtendedFabricObject,
} from "shared/types/designStudio";
import { getHeight, getWidth } from "utils/fabric/helpers.utils";

export const isObjectInsideCanvas = (
  canvasAreaDimension: IDimension,
  obj: fabric.Object,
  margin: {
    top: number;
    left: number;
  },
) => {
  const { top = 0, left = 0 } = obj;
  const width = getWidth(obj);
  const height = getHeight(obj);

  return (
    top > margin.top &&
    top + height < margin.top + canvasAreaDimension.height &&
    left > margin.left &&
    left + width < margin.left + canvasAreaDimension.width
  );
};

export const getHighlights = (args: {
  objects: IExtendedFabricObject[];
  canvasMargin: number;

  // Below two dimensions are different
  //  canvasAreaDimension is the area at the center, where canvas contents are rendered.
  //  canvasDimension is the outer area of the actual canvas element
  canvasAreaDimension: IDimension;
  canvasDimension: IDimension;
}) => {
  const margin = {
    top: (args.canvasDimension.height - args.canvasAreaDimension.height) / 2,
    left: (args.canvasDimension.width - args.canvasAreaDimension.width) / 2,
  };

  return args.objects
    .filter(obj => {
      const isCanvasArea = obj.customType === "canvas_area";
      const isCanvasBackground = obj.customType === "canvas_bg";

      const nameExist = !!obj.name;
      const isObjectWithinCanvasArea = isObjectInsideCanvas(
        args.canvasAreaDimension,
        obj,
        margin,
      );

      return (
        !isCanvasArea && // we have to exlcude canvas area from highlighting
        !isCanvasBackground && // canvas_bg is separated from the json and insert into "objects". So we need to filter this out as well.
        nameExist && // If name does not exist, cannot proceed
        !isObjectWithinCanvasArea // an object has to be outside of the canvas area, otherwise no need to highlight
      );
    })
    .map(obj => getHighlight(obj, args.canvasMargin));
};

export const getHighlight = (obj: fabric.Object, canvasMargin: number) => {
  const { left, top, width, height } = obj.getBoundingRect();
  return {
    id: obj.name!,
    top: top + canvasMargin,
    left: left + canvasMargin,
    width,
    height,
  };
};

export const getVariableAroundCursor = (textbox: fabric.Textbox) => {
  const startBracketLocation = getBracketPosition(textbox, true);
  const endBracketLocation = getBracketPosition(textbox, false);

  // If either one returns undefined for each position, there is no variable to extract.
  const noStartBracket =
    startBracketLocation.charIndex === undefined &&
    startBracketLocation.lineIndex === undefined;
  const noEndBracket =
    endBracketLocation.charIndex === undefined &&
    endBracketLocation.lineIndex === undefined;
  if (noStartBracket || noEndBracket) return;

  const textLines = textbox.get("textLines");
  if (startBracketLocation.lineIndex === endBracketLocation.lineIndex) {
    const textLine = textLines[startBracketLocation.lineIndex!];
    return textLine.substring(
      startBracketLocation.charIndex!,
      endBracketLocation.charIndex!,
    );
  } else {
    if (startBracketLocation.lineIndex! > endBracketLocation.lineIndex!)
      throw new Error("start line must be smaller than end line.");

    const combined = textLines
      .filter((line, idx) => {
        return (
          idx >= startBracketLocation.lineIndex! &&
          idx <= endBracketLocation.lineIndex! + 1 // last position is excluded. So add +1
        );
      })
      .join("");

    const regex = /\{(.*?)\}/gi;
    const result = combined.match(regex);

    return result?.[0];
  }
};

export const getBracketPosition = (
  textbox: fabric.Textbox,
  findingStartBracket: boolean,
) => {
  let charIdx: number | undefined;
  let lineIdx: number | undefined;
  const textLines = textbox.get("textLines");
  const { charIndex, lineIndex } = textbox.get2DCursorLocation();

  if (findingStartBracket) {
    for (
      let currentLineIdx = lineIndex;
      currentLineIdx >= 0;
      currentLineIdx--
    ) {
      const currentLineText = textLines[currentLineIdx];
      const startSearchingIdx =
        currentLineIdx === lineIndex ? charIndex : currentLineText.length - 1;
      for (
        let currentCharIdx = startSearchingIdx;
        currentCharIdx >= 0;
        currentCharIdx--
      ) {
        const currentChar = currentLineText[currentCharIdx];
        if (currentChar === "{") {
          charIdx = currentCharIdx;
          lineIdx = currentLineIdx;

          break;
        }
      }
    }
  } else {
    for (
      let currentLineIdx = lineIndex;
      currentLineIdx <= textLines.length - 1;
      currentLineIdx++
    ) {
      const currentLineText = textLines[currentLineIdx];
      const startSearchingIdx =
        currentLineIdx === lineIndex ? charIndex : currentLineText.length - 1;
      for (
        let currentCharIdx = startSearchingIdx;
        currentCharIdx >= 0;
        currentCharIdx--
      ) {
        const startSearchingIdx = currentLineIdx === lineIndex ? charIndex : 0;
        for (
          let currentCharIdx = startSearchingIdx;
          currentCharIdx <= currentLineText.length - 1;
          currentCharIdx++
        ) {
          const currentChar = currentLineText[currentCharIdx];
          if (currentChar === "}") {
            charIdx = currentCharIdx;
            lineIdx = currentLineIdx;

            break;
          }
        }
      }
    }
  }

  return { charIndex: charIdx, lineIndex: lineIdx };
};

/**
 * This function checks the char right before the cursor to see
 *  if we need to open variable selection view or not.
 * @param textbox
 */
export const shouldOpenVariableSelection = (textbox: fabric.Textbox) => {
  const { charIndex, lineIndex } = textbox.get2DCursorLocation();
  const textLines = textbox.get("textLines");
  const line = textLines[lineIndex];

  return line[charIndex - 1] === "{";
};

export const getSelectionBoundary = (args: {
  objects: fabric.Object[];
  canvasAreaMargin: {
    top: number;
    left: number;
  };
  CANVAS_MARGIN: number;
  canvasDimension: {
    width: number;
    height: number;
  };
}) => {
  const {
    objects,
    canvasAreaMargin: margin,
    canvasDimension: dimension,
    CANVAS_MARGIN,
  } = args;
  const marginLeft = margin.left + CANVAS_MARGIN;
  const marginTop = margin.top + CANVAS_MARGIN;
  const left =
    objects.length === 1
      ? marginLeft || 0
      : Math.min(...objects.map(obj => obj.getBoundingRect().left));

  const top =
    objects.length === 1
      ? marginTop || 0
      : Math.min(...objects.map(obj => obj.getBoundingRect().top));

  const right =
    objects.length === 1
      ? margin.left + dimension.width - CANVAS_MARGIN
      : Math.max(
          ...objects.map(obj => {
            const rectBound = obj.getBoundingRect();
            return rectBound.left + rectBound.width;
          }),
        );

  const bottom =
    objects.length === 1
      ? margin.top + dimension.height - CANVAS_MARGIN
      : Math.max(
          ...objects.map(obj => {
            const rectBound = obj.getBoundingRect();
            return rectBound.top + rectBound.height;
          }),
        );

  return { right, left, bottom, top };
};

export const alignObjects = (args: {
  align: AlignLayersActionType;
  objects: fabric.Object[];
  boundary: {
    top: number;
    left: number;
    bottom: number;
    right: number;
  };
  CANVAS_MARGIN: number;
}) => {
  const { objects, boundary, align, CANVAS_MARGIN } = args;
  objects.forEach(obj => {
    const rect = obj.getBoundingRect(true);

    switch (align) {
      case "left":
        obj.set({
          left: boundary.left,
        });

        break;

      case "center":
        // Centering object logic is following.
        //  1. Find the center position within the boundary
        //  2. Find half length of the object.
        //  3. Push the centerPosition to its left by the length found in #2
        const centerPosition = (boundary.right - boundary.left) / 2;
        const halfLength = rect.width / 2;
        obj.set({
          left: boundary.left + centerPosition - halfLength,
        });
        break;

      case "right":
        obj.set({
          left: boundary.right - rect.width,
        });
        break;

      case "top":
        obj.set({
          top: boundary.top,
        });
        break;

      case "middle":
        const middlePosition = (boundary.bottom - boundary.top) / 2;
        const halfHeight = rect.height / 2;
        obj.set({
          top: boundary.top + middlePosition - halfHeight,
        });
        break;

      case "bottom":
        obj.set({
          top: boundary.bottom - rect.height,
        });
        break;
    }
  });
};

export const removeStyleAttr = (textbox: fabric.Textbox, key: string) => {
  if (!textbox) return;

  const { styles } = textbox;
  for (const lineIdx in styles) {
    const lineStyle = styles[lineIdx];
    for (const charIdx in lineStyle) {
      const charStyle = lineStyle[charIdx];

      delete charStyle[key];
    }
  }
};

export const isHighlighted = (textbox: fabric.Textbox) => {
  const { selectionStart, selectionEnd, text } = textbox;
  if (typeof selectionStart !== "number" || typeof selectionEnd !== "number")
    return false;

  if (!text) return false;

  const highlightedNewline =
    selectionEnd! - 1 === selectionStart! && text![selectionEnd! - 1] === "\n";
  return selectionStart !== selectionEnd && !highlightedNewline;
};

type StyleType = Record<string, any>;
type StylesType = Record<number, StyleType>;
export const getStyles = (
  textbox: fabric.Textbox,
  applyingStyle: StyleType,
) => {
  const { text, selectionStart, selectionEnd } = textbox;
  if (!text) return {} as StylesType;

  if (!isHighlighted(textbox)) return textbox.styles as StylesType;

  let lineIdx = 0;
  let charIdx = 0;
  let styles: StylesType = {};

  for (let i = 0; i < text.length; i++) {
    const char = text[i];

    if (i < selectionStart! || i >= selectionEnd!) {
      if (char === "\n") {
        lineIdx++;
        charIdx = 0;
      } else {
        charIdx++;
      }

      continue;
    }

    if (char === "\n") {
      lineIdx++;
      charIdx = 0;
      continue;
    }

    const existingStyles = { ...(textbox.styles || {}) };
    const isUpdatingFontSize = "fontSize" in applyingStyle;
    const resetCachedStyles = isUpdatingFontSize
      ? { originalFontSize: undefined }
      : {};

    styles = {
      ...existingStyles,
      ...styles,
      [lineIdx]: {
        ...(existingStyles[lineIdx] || {}),
        ...(styles[lineIdx] || {}),
        [charIdx]: {
          ...(existingStyles[lineIdx]?.[charIdx] || {}),
          ...applyingStyle,
          // Reset cached/injected styles if needed
          ...resetCachedStyles,
        },
      },
    };

    charIdx++;
  }

  return styles;
};
