import { TemplateFile } from "shared/types/salesEnablement";
import styles from "./Preview.module.scss";
import PDFPreview from "screens/designStudio/shared/preview/PDFPreview";
import HTMLPreview from "screens/designStudio/shared/preview/HTMLPreview";
import { useTemplateRenderContext } from "screens/designStudio/hooks/TemplateRenderDataProvider";
import { message } from "antd";
import { useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";

type Props = {
  selectedFile?: TemplateFile;
};

const Preview = ({ selectedFile }: Props) => {
  const { idmlPreview, isIdmlFetching, isIdmlLoading } =
    useTemplateRenderContext();

  useEffect(() => {
    if (isIdmlLoading) return;
    if (!isIdmlFetching) return message.destroy();

    message.loading({
      content: "Loading preview...",
      key: "loading",
      duration: 0,
    });
  }, [isIdmlFetching, isIdmlLoading]);

  return (
    <div className={styles.container}>
      {selectedFile?.type !== "html" && !idmlPreview.src && (
        <div className={styles.loading}>
          <LoadingOutlined className={styles.icon} />
          <div className={styles.title}>Loading preview...</div>
        </div>
      )}
      <div className={styles.preview}>
        {(selectedFile?.type === "indesign" ||
          selectedFile?.type === "pdf") && <PDFPreview {...idmlPreview} />}
        {selectedFile?.type === "html" && <HTMLPreview />}
      </div>
    </div>
  );
};

export default Preview;
