import { Button, Drawer } from "antd";
import { useState } from "react";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import ImportCsvForm from "screens/adLibrary/adImport/adImportDrawer/ImportCsvForm";
import { processCsvRawData } from "screens/adLibrary/adImport/processingImportUtils";
import { processInstantExperiencesImport } from "redux/designStudio/library/instantExperiences/actions";
import { instantExperiencesImportCSVTemplateUrl } from "utils/constants";

export class ImportInstantExperienceData {
  "Brand"?: string; // OEM
  "Instant Experience Name"?: string;
  "Name (Optional)"?: string;
  "Component Order Number": string;
  "Component Type": string;
  "Media Link URL": string;
  "Product Headline (Optional)"?: string;
  "Product Description (Optional)"?: string;
}

const IMPORT_CSV_HEADERS = Object.keys(new ImportInstantExperienceData());

const InstantExperiencesImportDrawer = ({
  handleClose,
}: {
  handleClose: () => void;
}) => {
  // the data array from the csv file, the first row will have the header.
  const [csvRawData, setCsvRawData] = useState<string[][]>();

  const { data: instantExperiencesData, validCsvHeaders } =
    processCsvRawData<ImportInstantExperienceData>(
      csvRawData,
      IMPORT_CSV_HEADERS,
    );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleImport = async () => {
    const totalCsvHeaders = csvRawData?.[0]?.length ?? 0;
    const columnsIgnored: number = totalCsvHeaders - validCsvHeaders?.length;
    dispatch(
      processInstantExperiencesImport(
        instantExperiencesData,
        { columnsIgnored },
        navigate,
      ),
    );

    handleClose();
  };

  return (
    <Drawer
      title="Import"
      visible
      onClose={handleClose}
      width={720}
      closable
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button onClick={handleClose} style={{ marginRight: 8 }}>
            Cancel
          </Button>

          <Button
            disabled={!csvRawData}
            onClick={handleImport}
            type="primary"
            data-cy="ad-import-btn"
          >
            Import
          </Button>
        </div>
      }
    >
      <ImportCsvForm
        csvJsonData={csvRawData}
        setCsvJsonData={setCsvRawData}
        csvTemplateUrl={instantExperiencesImportCSVTemplateUrl}
      />
    </Drawer>
  );
};

export default InstantExperiencesImportDrawer;
