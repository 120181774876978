import { Modal } from "antd";
import styles from "./MediaPreviewModal.module.scss";

export const MediaPreviewModal = ({
  visible,
  onCancel,
  children,
}: {
  visible: boolean;
  onCancel: () => void;
  children: JSX.Element;
}) => (
  <Modal
    className={styles.adTableThumbnailModal}
    visible={visible}
    footer={null}
    onCancel={e => {
      onCancel();
      e.stopPropagation();
    }}
  >
    {children}
  </Modal>
);
