import { isFeatureEnabled } from "utils/helpers";
import styles from "./Cell.module.scss";
import CellError from "./CellError";
import { useEffect, useState } from "react";
import { Image } from "antd";

const MIN_CHARS_FOR_DISPLAY = 23;
type Props = {
  oldValue?: string | number;
  placeholderText?: string;
  toggleClicked: () => void;
  imageURL?: string | null;
  isLoadingImage?: boolean;
  editable?: boolean;
};

const Cell = ({
  oldValue,
  placeholderText,
  toggleClicked,
  imageURL,
  editable,
}: Props) => {
  const [mounted, setMounted] = useState(true);

  useEffect(() => {
    return () => {
      setMounted(false);
    };
  }, []);

  if (!mounted) {
    return null;
  }
  const displayEllipsis =
    typeof oldValue === "string" && oldValue.length > MIN_CHARS_FOR_DISPLAY;

  const valueToDisplay = displayEllipsis
    ? `${oldValue.substring(0, MIN_CHARS_FOR_DISPLAY)}...`
    : oldValue;

  const adEngineV2Enabled = isFeatureEnabled("ENABLE_AD_ENGINE_V2", false);

  const renderContent = () => {
    if (imageURL) {
      return (
        <div className={styles.imageWrapper}>
          <Image src={imageURL} preview={false} className={styles.image} />
        </div>
      );
    } else if (oldValue) {
      return <div>{valueToDisplay}</div>;
    } else if (!adEngineV2Enabled) {
      return <CellError errorMessage="No match!" />;
    }
    return <span className={styles.placeholderText}>{placeholderText}</span>;
  };

  return (
    <div
      className={styles.cell}
      onClick={e => {
        if (editable === undefined || editable) {
          toggleClicked();
          e.stopPropagation();
        }
      }}
    >
      {renderContent()}
    </div>
  );
};

export default Cell;
