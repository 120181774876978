import React from "react";
import { Collapse } from "antd";

interface Props {
  activeKey: string[];
  idKey: string;
  handleOnChange: (key: string | string[]) => void;
  children: React.ReactNode;
  header: React.ReactNode;
  customStyle?: string;
  bordered?: boolean;
}

export const CollapseSection = ({
  activeKey,
  idKey,
  handleOnChange,
  children,
  header,
  customStyle,
  bordered = false,
}: Props) => {
  return (
    <Collapse
      key={idKey}
      activeKey={activeKey}
      bordered={bordered}
      className={customStyle}
      onChange={handleOnChange}
    >
      <Collapse.Panel key={idKey} header={header} showArrow={false}>
        {children}
      </Collapse.Panel>
    </Collapse>
  );
};
