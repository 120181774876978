import { PropsWithChildren } from "react";
import style from "./SelectedVersionDetails.module.scss";

type SelectedDetailRowProps = PropsWithChildren<{
  label: string;
}>;

const SelectedDetailRow = ({ children, label }: SelectedDetailRowProps) => {
  return (
    <div className={style.detailsRow}>
      <span className={style.label}>{label}</span>
      <span className={style.text}>{children}</span>
    </div>
  );
};

export default SelectedDetailRow;
