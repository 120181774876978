import { notification } from "antd";
import API from "services";
import {
  ImportDescribeExecutionError,
  ImportDescribeExecutionOutput,
  ImportDescribeExecutionResponseData,
  ImportStartExecutionResult,
} from "shared/types/adLibrary";
import {
  AdMedia,
  IGetPutSignedUrlReqBody,
  IGetSignedPutUrlResponse,
  IUploadImage,
  IUploadImageFormInput,
  MultiUploadSizing,
} from "shared/types/uploadManagement";
import { dataURLtoBlob } from "utils/helpers.fabric";
import { createAdVideo } from "../screens/adLibrary/facebookUtils/adVideo";
import * as helpers from "./helpers";
import { adAccount } from "screens/adLibrary/facebookUtils/adAccount";

export interface IUploadMediaUrls {
  thumbnail?: string;
  videoUrl?: string;
  assetId?: string;
  filename?: string;
}

export const importAsset = async (
  url: string,
  createFacebookAdVideo = false,
): Promise<IUploadMediaUrls> => {
  const executionArn = await startImport(url, createFacebookAdVideo);
  const importResults = await pollExecution({
    executionArn,
    interval: 2000,
    maxAttempts: 60,
  });

  const isImage = importResults.type?.indexOf("image") !== -1;
  const thumbnail = importResults.thumbnailUrl;
  const filename = getFilenameFromUrl(url);

  // for images just return the thumbnail
  if (isImage) {
    return {
      thumbnail,
      videoUrl: undefined,
      assetId: undefined,
      filename,
    };
  }

  // for videos, the step function processes them on the backend and uploads them to S3 and FB
  return {
    videoUrl: importResults.location,
    thumbnail,
    filename,
    assetId: importResults.assetId,
  };
};

function getFilenameFromUrl(url: string): string | undefined {
  try {
    const { searchParams } = new URL(url);
    const filename = searchParams
      .get("response-content-disposition")
      ?.split("filename=")[1];
    return filename ? decodeURIComponent(filename) : undefined;
  } catch (error) {
    return undefined;
  }
}

export const uploadVideoToFB = async (videoUrl: string, enabled = true) => {
  const createAdVideoPromise = enabled
    ? createAdVideo(videoUrl)
    : { id: undefined };
  const thumbnail = await generateThumbnailUploadedVideo(videoUrl);
  const { id: assetId = undefined } = await createAdVideoPromise;
  return { videoUrl, thumbnail, assetId };
};

export const uploadMedia = async (
  mediaToUpload: IUploadImageFormInput,
  destination: string,
  createFacebookAdVideo = false,
): Promise<IUploadMediaUrls> => {
  const uploadObj: IUploadImage = {
    filename: mediaToUpload.filename
      ? helpers.generateGuidFilename(mediaToUpload.filename)
      : "",
    fileType: mediaToUpload.type,
    imageData: mediaToUpload.file,
    featureName: destination,
    skipStillImageCreation: mediaToUpload.skipStillImageCreation,
  };

  try {
    const signedPutUrl = await getSignedPutUrl(uploadObj);

    const skipBlob = mediaToUpload.file instanceof Blob;
    await uploadToS3(uploadObj, signedPutUrl, skipBlob);

    const s3AssetUrl = signedPutUrl.split("?")[0];

    const isImage = uploadObj.fileType?.indexOf("image") !== -1;

    // for images just return the thumbnail
    if (isImage) {
      return { thumbnail: s3AssetUrl, videoUrl: undefined, assetId: undefined };
    }

    // for videos generate the thumbnail and create the ad video in facebook
    return uploadVideoToFB(s3AssetUrl, createFacebookAdVideo);
  } catch (error) {
    notification.warning({
      message: "Uploads Unsuccessful",
      description: `The file ${mediaToUpload.filename} could not be uploaded.`,
      placement: "bottomRight",
    });

    throw error;
  }
};

async function generateThumbnailUploadedVideo(
  videoUrl: string,
): Promise<string> {
  const { thumbnailUrl } =
    await API.services.uploadManagement.generateThumbnail({
      videoUrl,
    });
  return thumbnailUrl;
}

export const uploadToS3 = async (
  uploadObj: IUploadImage,
  signedPutUrl: string,
  skipBlob?: boolean,
) => {
  const fileBlob = skipBlob
    ? (uploadObj.imageData as Blob)
    : dataURLtoBlob(uploadObj.imageData as string);

  const commonHeaders: HeadersInit = new Headers();
  commonHeaders.set("Content-Type", uploadObj.fileType!);
  commonHeaders.set("Accept", "application/json");

  const putResult = await fetch(signedPutUrl, {
    method: "PUT",
    headers: commonHeaders,
    body: fileBlob,
  });

  if (putResult.status !== 200) {
    throw new Error("Could not upload asset");
  }
};

export const getSignedPutUrl = async (
  uploadObj: IUploadImage,
  options?: { extendedPath?: string },
): Promise<string> => {
  const getSignedPutUrlReqBody: IGetPutSignedUrlReqBody = {
    uploadParams: {
      file: uploadObj.filename!,
      path: `${uploadObj.featureName}${
        options?.extendedPath || "/uploads-raw"
      }`,
      contentType: uploadObj.fileType!,
    },
  };

  const { result, error } =
    (await API.services.uploadManagement.getSignedPutUrl(
      getSignedPutUrlReqBody,
    )) as IGetSignedPutUrlResponse;

  if (error || !result) {
    throw new Error(error?.message || "Could not get signed URL");
  }
  return result.url;
};

const startImport = async (
  url: string,
  createFacebookAdVideo: boolean,
): Promise<string> => {
  const { result, error } = (await API.services.adLibrary.importStartExecution(
    url,
    createFacebookAdVideo ? adAccount.accountId : undefined,
  )) as ImportStartExecutionResult;

  if (error || !result) {
    throw new Error(error?.message || "Could not start import");
  }
  return result.executionArn;
};

const describeImport = async (
  executionArn: string,
): Promise<ImportDescribeExecutionResponseData> => {
  const { result, error } =
    await API.services.adLibrary.importDescribeExecution(executionArn);

  if (error || !result) {
    throw new Error(error?.message || "Error checking import status");
  }

  return result;
};

const handleExecutionError = (
  error: ImportDescribeExecutionOutput["error"],
) => {
  if (!error?.Cause) {
    return error?.Error;
  }
  try {
    const cause = JSON.parse(error.Cause) as ImportDescribeExecutionError;
    return cause.errorMessage;
  } catch (e) {
    return error?.Error;
  }
};

const pollExecution = async ({
  executionArn,
  interval,
  maxAttempts,
}: {
  executionArn: string;
  interval: number;
  maxAttempts: number;
}) => {
  let attempts = 0;

  const executePoll = async (
    resolve: (info: ImportDescribeExecutionOutput) => void,
    reject: (reason?: unknown) => void,
  ) => {
    try {
      const execution = await describeImport(executionArn);
      attempts++;

      if (execution.status !== "RUNNING") {
        if (execution.output.error) {
          return reject(
            new Error(handleExecutionError(execution.output.error)),
          );
        }
        return resolve(execution.output);
      } else if (maxAttempts && attempts === maxAttempts) {
        return reject(new Error("Exceeded max attempts"));
      }

      setTimeout(executePoll, interval, resolve, reject);
    } catch (error) {
      return reject(error);
    }
  };

  return new Promise(executePoll);
};

// Multi Upload

export const uploadVideoToFBV2 = async (videoUrl: string) => {
  const thumbnail = await generateThumbnailUploadedVideo(videoUrl);
  const { id } = await createAdVideo(videoUrl);
  return { videoUrl, thumbnail, assetId: id };
};

export const uploadMediaV2 = async (
  mediaToUpload: IUploadImageFormInput & { mediaSizing: MultiUploadSizing },
  destination: string,
): Promise<AdMedia> => {
  const uploadObj: IUploadImage = {
    filename: mediaToUpload.filename
      ? helpers.generateGuidFilename(mediaToUpload.filename)
      : "",
    fileType: mediaToUpload.type,
    imageData: mediaToUpload.file,
    featureName: destination,
    skipStillImageCreation: mediaToUpload.skipStillImageCreation,
  };

  try {
    const signedPutUrl = await getSignedPutUrl(uploadObj);

    const skipBlob = mediaToUpload.file instanceof Blob;
    await uploadToS3(uploadObj, signedPutUrl, skipBlob);

    const s3AssetUrl = signedPutUrl.split("?")[0];

    const isImage = uploadObj.fileType?.indexOf("image") !== -1;

    // for images just return the thumbnail
    if (isImage) {
      return {
        thumbnail: s3AssetUrl,
        imageUrl: s3AssetUrl,
        filetype: "image",
        size: mediaToUpload.mediaSizing,
      };
    }

    // for videos generate the thumbnail and create the ad video in facebook
    const video = await uploadVideoToFBV2(s3AssetUrl);
    return {
      ...video,
      filetype: "video",
      size: mediaToUpload.mediaSizing,
    };
  } catch (error) {
    notification.warning({
      message: "Uploads Unsuccessful",
      description: `The file ${mediaToUpload.filename} could not be uploaded.`,
      placement: "bottomRight",
    });

    throw error;
  }
};
