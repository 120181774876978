import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  checkHasDuplicateCols,
  checkIsAllRequiredMatched,
  getErrorMsg,
  getMatchingColsHeaders,
  getRecommendedColumns,
} from "screens/assetExporter/sourceFeed/newFeedDrawer/utils";
import {
  FB_ADS_ADFORMAT_ERROR_TEXT,
  FB_ADS_CTA_ERROR_TEXT,
} from "shared/constants/assetExporter";
import {
  AdEngineGoogleSheetForm,
  AssetExporterState,
  ExportForOption,
  ExportFtpData,
  FeedConfig,
  FeedCron,
  FeedDrawerMode,
  SetSelectedType,
  FeedType,
  FeedUploadType,
  FeedWithRule,
  ServerFeedFreq,
  ServerFeedRepeatOptions,
  ServerFeedStartTime,
  ServerFeedTZ,
  SmartCol,
  SourceFeedRightClickPopup,
  STEP,
} from "shared/types/assetExporter";
import { createActionsHook } from "react-redux-actions-hook";

const initialState: AssetExporterState = {
  sourceFeed: {
    isVersionsDrawerOpen: false,
    isFeedDrawerOpen: false,
    isDeleteModalOpen: false,
    selectedFeedIds: [],
    showSelected: false,
    search: "",
    rightClickPopup: {
      displayX: 0,
      displayY: 0,
      visible: false,
    },
    feedDrawer: {
      googleSheetId: "",
      googleSheetName: "",
      googleSheetUrl: "",
      drawerMode: "CREATE",
      step: STEP.ONE,
      latestStep: STEP.ONE,
      feedName: "",
      uploadType: "custom",
      frequency: "Daily",
      timezone: "EST",
      repeat: undefined,
      startTime: undefined,
      isNextBtnDisabled: true,
      columnData: [],
      recommendedColumns: [],
      selectedSmartColIdx: 0,
      selectedSmartCols: [],
      errMsg: "",
      isEmptySmartColWarning: false,
      isProcessingPreview: false,
      reviewGeneralErrors: {
        cta: "",
        adFormat: "",
      },
      reviewGeneralWarnings: [],
      feedId: "",
      filterColHeader: "",
      oemTags: [],
      locationTags: [],
      storeTags: [],
    },
  },
  configure: {
    isExportDrawerOpen: false,
    feedColumns: [],
    colsToExport: [],
    isExportAllCols: true,
    feedType: "custom",
    exportFor: [],
    areFtpCredsValid: false,
    isExportInProgress: true,
    isExportByUser: false,
    selectedRows: [],
    ftpData: {
      filePath: "",
      password: "",
      port: 22,
      url: "",
      username: "",
    },
    isExportAdLibOpen: false,
  },
  assetBatches: {
    exportExecutionArns: [],
    exportFeedName: null,
    exportAssetBatchNames: [],
    exportRowCount: null,
  },
};

const assetExporterSlice = createSlice({
  name: "assetexporter",
  initialState,
  reducers: {
    setIsVersionsDrawerOpen(
      state,
      { payload: isOpen }: PayloadAction<boolean>,
    ) {
      state.sourceFeed.isVersionsDrawerOpen = isOpen;
    },
    setSearchInput(state, { payload: searchInput }: PayloadAction<string>) {
      state.sourceFeed.search = searchInput;
    },
    setShowSelected(state, { payload: showSelected }: PayloadAction<boolean>) {
      state.sourceFeed.showSelected = showSelected;
    },
    setRightClickPopup(
      state,
      { payload: popup }: PayloadAction<SourceFeedRightClickPopup>,
    ) {
      state.sourceFeed.rightClickPopup = popup;
    },
    setSelectedFeedIds(state, { payload: ids }: PayloadAction<string[]>) {
      state.sourceFeed.selectedFeedIds = ids;
      if (ids.length === 0) {
        state.sourceFeed.showSelected = false;
      }
    },
    setIsDeleteModalOpen(state, { payload: isOpen }: PayloadAction<boolean>) {
      state.sourceFeed.isDeleteModalOpen = isOpen;
    },
    toggleDrawer(state, { payload: mode }: PayloadAction<FeedDrawerMode>) {
      state.sourceFeed.isFeedDrawerOpen = !state.sourceFeed.isFeedDrawerOpen;
      state.sourceFeed.feedDrawer.drawerMode = mode;
    },
    setUploadType(
      state,
      { payload: uploadType }: PayloadAction<FeedUploadType>,
    ) {
      state.sourceFeed.feedDrawer.uploadType = uploadType;
    },
    setCustomFileName(state, { payload: fileName }: PayloadAction<string>) {
      state.sourceFeed.feedDrawer.feedName = fileName.replace(".csv", "");
      state.sourceFeed.feedDrawer.isNextBtnDisabled = false;
    },
    setFeedName(state, { payload: feedName }: PayloadAction<string>) {
      state.sourceFeed.feedDrawer.feedName = feedName;
    },
    setNextStep(state) {
      const nextStep = state.sourceFeed.feedDrawer.step + 1;
      state.sourceFeed.feedDrawer.step = nextStep;
      state.sourceFeed.feedDrawer.latestStep = nextStep;
      const isAssetBuilderFeed =
        state.sourceFeed.feedDrawer.selectedType === "assetBuilder";
      const isAssetBuilderStep3 = nextStep === STEP.THREE && isAssetBuilderFeed;
      state.sourceFeed.feedDrawer.isNextBtnDisabled =
        nextStep <= STEP.TWO || isAssetBuilderStep3;
    },
    setPrevStep(state) {
      const prevStep = state.sourceFeed.feedDrawer.step - 1;
      state.sourceFeed.feedDrawer.step = prevStep;
      state.sourceFeed.feedDrawer.latestStep = prevStep;
      state.sourceFeed.feedDrawer.isNextBtnDisabled = false;
      state.sourceFeed.feedDrawer.errMsg = "";
    },
    setStep(state, { payload: step }: PayloadAction<number>) {
      state.sourceFeed.feedDrawer.step = step;
    },
    setUploadedFile(state, { payload: filename }: PayloadAction<string>) {
      state.sourceFeed.feedDrawer.uploadedFile = filename;
    },
    setFrequency(state, { payload: frequency }: PayloadAction<ServerFeedFreq>) {
      state.sourceFeed.feedDrawer.frequency = frequency;
    },
    setRepeat(
      state,
      { payload: repeat }: PayloadAction<ServerFeedRepeatOptions>,
    ) {
      state.sourceFeed.feedDrawer.repeat = repeat;
    },
    setStartTime(
      state,
      { payload: startTime }: PayloadAction<ServerFeedStartTime>,
    ) {
      state.sourceFeed.feedDrawer.startTime = startTime;
    },
    setTimeZone(state, { payload: timezone }: PayloadAction<ServerFeedTZ>) {
      state.sourceFeed.feedDrawer.timezone = timezone;
    },
    setHost(state, { payload: host }: PayloadAction<string>) {
      state.sourceFeed.feedDrawer.host = host;
    },
    setPath(state, { payload: path }: PayloadAction<string>) {
      state.sourceFeed.feedDrawer.path = path;
    },
    setPort(state, { payload: port }: PayloadAction<string>) {
      state.sourceFeed.feedDrawer.port = port;
    },
    setUsername(state, { payload: name }: PayloadAction<string>) {
      state.sourceFeed.feedDrawer.username = name;
    },
    setPassword(state, { payload: psw }: PayloadAction<string>) {
      if (state.sourceFeed.feedDrawer.drawerMode === "CREATE") {
        // unencrypted password
        state.sourceFeed.feedDrawer.password = psw;
      } else {
        // unencrpted password
        state.sourceFeed.feedDrawer.tempPassword = psw;
      }
    },
    clearNameAndPswd(state) {
      state.sourceFeed.feedDrawer.username = "";
      state.sourceFeed.feedDrawer.password = "";
    },
    setServerValidation(state, { payload: valid }: PayloadAction<boolean>) {
      state.sourceFeed.feedDrawer.isCredValidated = valid;
      state.sourceFeed.feedDrawer.isNextBtnDisabled = !valid;
    },
    setSelectedType(
      state,
      {
        payload: { type, columnsHeaders: colHeaders },
      }: PayloadAction<SetSelectedType>,
    ) {
      const recCols = getRecommendedColumns(type);
      const colData = getMatchingColsHeaders(colHeaders, recCols, type);
      const isAllMatched = colData.every(col => col.isMatched);
      const isAllReqMatched = checkIsAllRequiredMatched(recCols, colData);
      const hasDuplicateCols = checkHasDuplicateCols(colData);
      const errMsg = getErrorMsg(isAllReqMatched, hasDuplicateCols);
      state.sourceFeed.feedDrawer.selectedType = type;
      state.sourceFeed.feedDrawer.recommendedColumns = recCols;
      state.sourceFeed.feedDrawer.columnData = colData;
      state.sourceFeed.feedDrawer.isNextBtnDisabled = !isAllMatched || !!errMsg;
      state.sourceFeed.feedDrawer.errMsg = errMsg;
    },
    setColData(
      state,
      {
        payload: { matchedFrom, matchedTo },
      }: PayloadAction<{ matchedFrom: string; matchedTo?: string }>,
    ) {
      const colData = [...state.sourceFeed.feedDrawer.columnData];
      const recCols = [...state.sourceFeed.feedDrawer.recommendedColumns];
      const colToUpdateIdx = colData.findIndex(
        data => data.matchedFrom === matchedFrom,
      );
      colData[colToUpdateIdx] = {
        ...colData[colToUpdateIdx],
        matchedTo,
        isMatched: Boolean(matchedTo),
      };
      const isAllMatched = colData.every(col => col.isMatched);
      const isAllReqMatched = checkIsAllRequiredMatched(recCols, colData);
      const hasDuplicateCols = checkHasDuplicateCols(colData);
      const errMsg = getErrorMsg(isAllReqMatched, hasDuplicateCols);
      state.sourceFeed.feedDrawer.columnData = colData;
      state.sourceFeed.feedDrawer.isNextBtnDisabled = !isAllMatched || !!errMsg;
      state.sourceFeed.feedDrawer.errMsg = errMsg;
    },
    setInitialColumnState(state) {
      const colData = [...state.sourceFeed.feedDrawer.columnData];
      const recCols = [...state.sourceFeed.feedDrawer.recommendedColumns];
      const isAllMatched = colData.every(col => col.isMatched);
      const isAllReqMatched = checkIsAllRequiredMatched(recCols, colData);
      const hasDuplicateCols = checkHasDuplicateCols(colData);
      const errMsg = getErrorMsg(isAllReqMatched, hasDuplicateCols);
      state.sourceFeed.feedDrawer.columnData = colData;
      state.sourceFeed.feedDrawer.isNextBtnDisabled =
        !isAllMatched || !!errMsg || !state.sourceFeed.feedDrawer.selectedType;
      state.sourceFeed.feedDrawer.errMsg = errMsg;
    },
    sortColData(state) {
      state.sourceFeed.feedDrawer.columnSortedByWarning = true;
    },
    setFeedWithRule(
      state,
      { payload: feedWithRule }: PayloadAction<FeedWithRule>,
    ) {
      state.sourceFeed.feedDrawer.selectedSmartColIdx = 0;
      state.sourceFeed.feedDrawer.selectedSmartCols = [];
      state.sourceFeed.feedDrawer.feedWithRule = feedWithRule;
    },
    setImportSmartCol(state, { payload: smartCol }: PayloadAction<SmartCol>) {
      const smartCols = [...state.sourceFeed.feedDrawer.selectedSmartCols];
      const colIdx = smartCols.findIndex(col => col.column === smartCol.column);

      if (smartCol.rules.length === 0) {
        state.sourceFeed.feedDrawer.selectedSmartCols.splice(colIdx, 1);
      } else {
        if (colIdx === -1) {
          state.sourceFeed.feedDrawer.selectedSmartCols = [
            ...smartCols,
            smartCol,
          ];
        } else {
          state.sourceFeed.feedDrawer.selectedSmartCols[colIdx] = smartCol;
        }
      }
    },
    setSingleImportRule(state, { payload: smartCol }: PayloadAction<SmartCol>) {
      const currSmartIdx =
        state.sourceFeed.feedDrawer.selectedSmartCols.findIndex(
          col => col.column === smartCol.column,
        );
      const smartCols = [...state.sourceFeed.feedDrawer.selectedSmartCols];
      if (currSmartIdx === -1) {
        state.sourceFeed.feedDrawer.selectedSmartCols = [
          ...smartCols,
          smartCol,
        ];
      } else {
        state.sourceFeed.feedDrawer.selectedSmartCols[currSmartIdx] = smartCol;
      }
    },
    setSelectedSmartColIdx(state, { payload: idx }: PayloadAction<number>) {
      state.sourceFeed.feedDrawer.selectedSmartColIdx = idx;
    },
    resetDrawer(state) {
      state.sourceFeed.feedDrawer = initialState.sourceFeed.feedDrawer;
      state.sourceFeed.isFeedDrawerOpen = false;
    },
    setEmptySmartColWarning(state) {
      state.sourceFeed.feedDrawer.isEmptySmartColWarning = true;
    },
    setGeneralErrorMsgs(state, { payload: colHeader }: PayloadAction<string>) {
      if (colHeader === "CTA") {
        state.sourceFeed.feedDrawer.reviewGeneralErrors.cta =
          FB_ADS_CTA_ERROR_TEXT;
      } else if (colHeader === "Ad Format") {
        state.sourceFeed.feedDrawer.reviewGeneralErrors.adFormat =
          FB_ADS_ADFORMAT_ERROR_TEXT;
      } else {
        state.sourceFeed.feedDrawer.reviewGeneralErrors.cta =
          FB_ADS_CTA_ERROR_TEXT;
        state.sourceFeed.feedDrawer.reviewGeneralErrors.adFormat =
          FB_ADS_ADFORMAT_ERROR_TEXT;
      }
      state.sourceFeed.feedDrawer.isNextBtnDisabled = true;
    },
    setGeneralWarningMsgs(
      state,
      { payload: warnings }: PayloadAction<string[]>,
    ) {
      state.sourceFeed.feedDrawer.reviewGeneralWarnings = warnings;
    },
    setIsProcessingPreview(
      state,
      { payload: isProcessing }: PayloadAction<boolean>,
    ) {
      state.sourceFeed.feedDrawer.isProcessingPreview = isProcessing;
    },
    setFeedFormForUpdate(state, { payload: form }: PayloadAction<FeedCron>) {
      const isServerFeed = !!form.frequency;
      const defaultUploadType = isServerFeed ? "server" : "custom";
      const { id, frequency, type, ...rest } = form;

      state.sourceFeed.feedDrawer = { ...state.sourceFeed.feedDrawer, ...rest };
      state.sourceFeed.feedDrawer.feedId = id;
      state.sourceFeed.feedDrawer.frequency = frequency;

      state.sourceFeed.feedDrawer.selectedType = type ?? "custom";
      state.sourceFeed.feedDrawer.googleSheetId = form.googleSheetId ?? "";
      state.sourceFeed.feedDrawer.googleSheetName = form.googleSheetName ?? "";
      state.sourceFeed.feedDrawer.googleSheetUrl = `https://docs.google.com/spreadsheets/d/${form.googleSheetId}/edit#gid=0`;

      state.sourceFeed.feedDrawer.uploadType =
        form.feedUploadType ?? defaultUploadType;

      if (form.feedUploadType !== "server")
        state.sourceFeed.feedDrawer.password = "";

      state.sourceFeed.feedDrawer.isNextBtnDisabled = false;
    },
    setIsNextBtnDisabled(state, { payload }: PayloadAction<boolean>) {
      state.sourceFeed.feedDrawer.isNextBtnDisabled = payload;
    },
    setGoogleSheetData(
      state,
      {
        payload: { key, value },
      }: PayloadAction<{ key: keyof AdEngineGoogleSheetForm; value: string }>,
    ) {
      state.sourceFeed.feedDrawer[key] = value;
      state.sourceFeed.feedDrawer.uploadType = "googlesheet";
    },
    resetGoogleSheetData(state) {
      state.sourceFeed.feedDrawer.feedName = "";
      state.sourceFeed.feedDrawer.googleSheetId = "";
      state.sourceFeed.feedDrawer.googleSheetName = "";
    },
    setFeedConfig(state, { payload: config }: PayloadAction<FeedConfig>) {
      state.sourceFeed.feedDrawer.columnData = config.colData;
      state.sourceFeed.feedDrawer.selectedSmartCols = config.smartCols;
    },
    enableNextButton(state) {
      state.sourceFeed.feedDrawer.isNextBtnDisabled = false;
    },
    disableNextButton(state) {
      state.sourceFeed.feedDrawer.isNextBtnDisabled = true;
    },
    setFilterColHeader(state, { payload: colHeader }: PayloadAction<string>) {
      state.sourceFeed.feedDrawer.filterColHeader = colHeader;
      state.sourceFeed.feedDrawer.isNextBtnDisabled = false;
    },
    setBrandTags(state, { payload: brandTags }: PayloadAction<string[]>) {
      state.sourceFeed.feedDrawer.oemTags = brandTags;
    },
    setLocationTags(state, { payload: locationTags }: PayloadAction<string[]>) {
      state.sourceFeed.feedDrawer.locationTags = locationTags;
    },
    setAccountTags(state, { payload: accountTags }: PayloadAction<string[]>) {
      state.sourceFeed.feedDrawer.storeTags = accountTags;
    },
    toggleConfigureDrawer(state, { payload: isOpen }: PayloadAction<boolean>) {
      state.configure.isExportDrawerOpen = isOpen;
    },
    resetConfigureDrawer(state) {
      state.configure.colsToExport = [];
      state.configure.exportFor = [];
      state.configure.ftpData.filePath = "";
      state.configure.ftpData.password = "";
      state.configure.ftpData.port = 22;
      state.configure.ftpData.url = "";
      state.configure.ftpData.username = "";
    },
    setFeedColumns(
      state,
      {
        payload: { cols, feedType },
      }: PayloadAction<{ cols: string[]; feedType: FeedType }>,
    ) {
      state.configure.feedColumns = cols;
      state.configure.colsToExport = cols;
      state.configure.feedType = feedType;
      state.configure.isExportAllCols = true;
    },
    setColsToExport(state, { payload: cols }: PayloadAction<string[]>) {
      state.configure.colsToExport = cols;
    },
    setExportFor(
      state,
      { payload: exportFor }: PayloadAction<ExportForOption[]>,
    ) {
      state.configure.exportFor = exportFor;
    },
    setIsExportInProgress(
      state,
      { payload: isExportInProgress }: PayloadAction<boolean>,
    ) {
      state.configure.isExportInProgress = isExportInProgress;
    },
    setIsExportByUser(
      state,
      { payload: isExportByUser }: PayloadAction<boolean>,
    ) {
      state.configure.isExportByUser = isExportByUser;
    },
    setProcessSelectedRows(
      state,
      { payload: selectedRows }: PayloadAction<string[]>,
    ) {
      state.configure.selectedRows = selectedRows;
    },
    setExportFtpData(
      state,
      { payload: ftpData }: PayloadAction<ExportFtpData>,
    ) {
      state.configure.ftpData = ftpData;
    },
    setAreValidFtpCreds(
      state,
      { payload: areFtpCredsValid }: PayloadAction<boolean>,
    ) {
      state.configure.areFtpCredsValid = areFtpCredsValid;
    },
    setIsAdLibExportDrawerOpen(
      state,
      { payload: isExportAdLibOpen }: PayloadAction<boolean>,
    ) {
      state.configure.isExportAdLibOpen = isExportAdLibOpen;
    },
    setExportExecutionArns(
      state,
      action: PayloadAction<{ exportExecutionArns: string[] }>,
    ) {
      state.assetBatches.exportExecutionArns =
        action.payload.exportExecutionArns;
    },
    setExportAssetBatchNames(
      state,
      action: PayloadAction<{ exportAssetBatchNames: string[] }>,
    ) {
      state.assetBatches.exportAssetBatchNames =
        action.payload.exportAssetBatchNames;
    },
    setExportFeedName(
      state,
      action: PayloadAction<{ exportFeedName: string | null | undefined }>,
    ) {
      state.assetBatches.exportFeedName = action.payload.exportFeedName;
    },
    setExportRowCount(
      state,
      action: PayloadAction<{ exportRowCount: number }>,
    ) {
      state.assetBatches.exportRowCount = action.payload.exportRowCount;
    },
  },
});

export const useAdEngineActions = createActionsHook(assetExporterSlice.actions);

export default assetExporterSlice.reducer;
