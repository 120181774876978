import { ExclamationCircleOutlined } from "@ant-design/icons";
import { AutoComplete, Form, Input, Modal, Tooltip } from "antd";
import { InternalNamePath } from "antd/lib/form/interface";
import { isEmpty, omit } from "lodash";
import { useState } from "react";
import { LanguageTabs } from "shared/components/salesEnablement/admin/LanguageTabs";
import { useProducts } from "shared/hooks/admin/useProducts";
import {
  Language,
  Product,
  ProductForm as ProductFormType,
} from "shared/types/salesEnablement";
import { Languages } from "utils/helpers.salesEnablement";

type Props = {
  formId: string;
  product?: Product;
  languages?: Language[];
  onFinish: (productForm: ProductFormType) => void;
};

export const ProductForm = ({
  formId,
  product,
  onFinish,
  languages,
}: Props) => {
  const [form] = Form.useForm();
  const { productTypes } = useProducts();
  const [activeLanguage, setActiveLanguage] = useState<string>(
    product?.primaryLanguage ?? "en",
  );
  const [languageTabData, setLanguageTabData] = useState({
    primaryLanguage: product?.primaryLanguage ?? "en",
    languages: languages ?? ["en", "es"],
  });
  const [isSecondTabDisabled, setIsSecondTabDisabled] = useState<boolean>(
    !product,
  );

  const setAvailableLanguages = (languages: Language[]) => {
    if (languageTabData.languages.length === 2) return;
    setLanguageTabData({
      ...languageTabData,
      languages,
    });
  };
  const onRemoveLanguage = (
    removedLanguage: Language,
    newPrimaryLanguage: Language,
  ) => {
    const formValue = form.getFieldsValue();
    const targetLanguage = Languages[removedLanguage]?.label;
    const titleText = `Are you sure you want to remove ${targetLanguage} from this product?`;
    Modal.confirm({
      title: <b>{titleText}</b>,
      okText: "Remove",
      icon: <ExclamationCircleOutlined />,
      okButtonProps: { danger: true },
      onOk: () => {
        setLanguageTabData({
          primaryLanguage: newPrimaryLanguage,
          languages: [newPrimaryLanguage],
        });
        setActiveLanguage(newPrimaryLanguage);
        form.setFieldsValue({
          ...formValue,
          details: {
            ...formValue.details,
            [removedLanguage]: { name: "", description: "" },
          },
        });
      },
    });
  };

  const onFinishFailed = ({
    errorFields,
  }: {
    errorFields: { name: InternalNamePath; errors: string[] }[];
  }) => {
    const { primaryLanguage } = languageTabData;
    const tabsWithError = errorFields.flatMap(({ name }) => name);
    if (tabsWithError.length && tabsWithError.includes(primaryLanguage)) {
      return;
    }
    setActiveLanguage(Object.keys(omit(Languages, primaryLanguage))[0]);
  };

  return (
    <Form<ProductFormType>
      id={formId}
      form={form}
      layout="vertical"
      initialValues={product}
      onFinishFailed={onFinishFailed}
      onFinish={productFormValues =>
        onFinish({ ...languageTabData, ...productFormValues })
      }
      onValuesChange={(_, allValues) => {
        const { primaryLanguage } = languageTabData;
        const { productType, details } = allValues;
        const primaryLanguageDetails = details[primaryLanguage];
        if (!primaryLanguageDetails || isEmpty(primaryLanguageDetails)) {
          setIsSecondTabDisabled(true);
          return;
        }
        const { name, description } = primaryLanguageDetails;
        setIsSecondTabDisabled(!name || !description || !productType);
      }}
    >
      <LanguageTabs
        primaryLanguage={languageTabData.primaryLanguage}
        activeTab={activeLanguage}
        languages={languageTabData.languages}
        isSecondTabDisabled={isSecondTabDisabled}
        onRemoveLanguage={onRemoveLanguage}
        setActiveLanguage={setActiveLanguage}
        setAvailableLanguages={setAvailableLanguages}
        render={(language: Language) => (
          <>
            <Form.Item
              label="Name"
              required
              name={["details", language, "name"]}
              rules={[
                {
                  required: true,
                  message: "Please add a name",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Tooltip
              title={
                language !== languageTabData.primaryLanguage
                  ? `To edit Product Type, please switch to the ${
                      Languages[languageTabData.primaryLanguage].label
                    } language tab.`
                  : ""
              }
            >
              <Form.Item label="Product Type" name="productType" required>
                <AutoComplete
                  placeholder="Please Select or create a new product type"
                  options={productTypes.map(type => ({
                    label: type,
                    value: type,
                  }))}
                  disabled={language !== languageTabData.primaryLanguage}
                />
              </Form.Item>
            </Tooltip>
            <Form.Item
              label="Description"
              required
              name={["details", language, "description"]}
              rules={[
                {
                  required: true,
                  message: "Please add a description",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </>
        )}
      />
    </Form>
  );
};
