import { Drawer, Space, Button, Typography, message } from "antd";
import { useState } from "react";
import SingleAccountSelect from "shared/components/SingleAccountSelect";
import { useUploadToPmax } from "shared/hooks/adLibrary/pmax/useUploadToPmax";
import { IAccount } from "shared/types/accountManagement";
import styles from "../GooglePerformanceMax.module.scss";
import { usePmaxCampaignsContext } from "../PmaxCampaignsProvider";
import { CampaignLoadTable } from "./campaignLoadDrawer/CampaignLoadTable";
import { useUploadProgressNotification } from "../shared/useUploadProgressNotification";

export const CampaignLoadDrawer = () => {
  const {
    loadDrawerOpen,
    setLoadDrawerOpen,
    selectedCampaigns,
    selectedCampaignIds,
  } = usePmaxCampaignsContext();
  const [store, setStore] = useState<IAccount | undefined>();

  const {
    mutate: uploadCampaigns,
    reset: resetUploadCampaignsMutation,
    uploadStatus,
    isUploading,
  } = useUploadToPmax({
    campaignIds: selectedCampaignIds,
    adAccount: {
      dealerName: store?.dealer_name || "",
      id: store?.details.google?.adAccountCID || "",
      dealerOem: store?.dealer_oem,
    },
  });

  const status = uploadStatus?.status;
  const isSuccess = status === "success";
  const { closeNotification } = useUploadProgressNotification(
    uploadStatus ?? {},
    "Loading Campaigns",
  );

  const closeDrawer = () => {
    closeNotification();
    resetUploadCampaignsMutation();
    setLoadDrawerOpen(false);
  };

  const onStoreChange = (selectedStoreName: string, stores?: IAccount[]) => {
    const selectedStore = stores?.find(
      ({ dealer_name }) => dealer_name === selectedStoreName,
    );

    if (!selectedStore?.details.google?.adAccountCID) {
      message.error("Store does not have a Google Ads account CID");
      return;
    }
    setStore(selectedStore);
  };

  const loadButtonDisabled = !store || isUploading;

  return (
    <Drawer
      title={"Assign Campaigns to Store"}
      destroyOnClose
      visible={loadDrawerOpen}
      afterVisibleChange={resetUploadCampaignsMutation}
      closable={false}
      maskClosable={false}
      width={"95%"}
      footer={
        <Space className={styles.loadDrawerFooter}>
          <Button onClick={closeDrawer}>Cancel</Button>
          <Button
            onClick={isSuccess ? closeDrawer : () => uploadCampaigns()}
            type="primary"
            loading={isUploading}
            disabled={loadButtonDisabled}
          >
            {isSuccess ? "Done" : "Load to Google Ads"}
          </Button>
        </Space>
      }
    >
      <Space direction="vertical" className={styles.loadDrawerContent}>
        <Typography>Select a Store</Typography>
        <SingleAccountSelect
          selectedDealer={store?.dealer_name}
          className={styles.storeSelect}
          onChange={onStoreChange}
        />

        <CampaignLoadTable
          store={store}
          campaigns={selectedCampaigns}
          status={status}
        />
      </Space>
    </Drawer>
  );
};
