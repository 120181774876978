import { Typography } from "antd";
import { MarketingMaterial } from "shared/types/marketingMaterials";
import { RenderList } from "./RenderList";
import styles from "./Display.module.scss";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "./utils/constants";
import { MarketingMaterialListItem } from "./MarketingMaterialListItem";
import ErrorState from "./shared/ErrorState";

type MarketingMaterialsDisplayProps = {
  title: string;
  description?: string;
  materials: MarketingMaterial[];
  sortFunction: (materials: MarketingMaterial[]) => MarketingMaterial[];
  handleClick: () => void;
  isError?: boolean;
};

export const MarketingMaterialsDisplay = ({
  title,
  description = "",
  materials,
  sortFunction,
  handleClick,
  isError,
}: MarketingMaterialsDisplayProps) => {
  const { Text } = Typography;
  const items = sortFunction(materials);
  const navigate = useNavigate();

  const handleEdit = (item: MarketingMaterial) => {
    navigate(ROUTES.editMarketingMaterials(item.id));
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerTitle}>
          <Typography.Title level={4}>{title}</Typography.Title>
          {description && (
            <Text className={styles.description}>{description}</Text>
          )}
        </div>
        <Text className={styles.seeAll} onClick={handleClick}>
          See All
        </Text>
      </div>
      {isError && (
        <ErrorState
          title="Sorry! We couldn’t find your recent Marketing Materials"
          description="Please refresh the page or click below to browse all Materials."
          action={{
            title: "Browse Marketing Materials",
            onClick: () => navigate(ROUTES.marketingMaterials),
          }}
        />
      )}
      {!isError && (
        <RenderList<MarketingMaterial>
          items={items}
          renderItem={item => (
            <MarketingMaterialListItem item={item} onEdit={handleEdit} />
          )}
        />
      )}
    </div>
  );
};
