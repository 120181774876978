import { Col, DatePicker, Form, Select } from "antd";
import moment, { Moment } from "moment";
import { useState } from "react";
import { dateFormat } from "shared/constants/dataManagement";
import { useFetchTags } from "shared/hooks/useFetchTags";
import { useMutateTag } from "shared/hooks/useMutateTag";
import { INewOrderRecord } from "shared/types/newOrders";

type DateRowPharmaProps = {
  orderToSubmit: Partial<INewOrderRecord>;
  onChangeDate: (value: Moment | null, date: string) => void;
  onUpdateOrder: (orderUpdated: Partial<INewOrderRecord>) => void;
};

export const DateRowPharma = ({
  orderToSubmit,
  onChangeDate,
  onUpdateOrder,
}: DateRowPharmaProps) => {
  const tags: string[] = orderToSubmit.tags || [];
  const [inputTag, setInputTag] = useState<string>();
  const { data: availableTags } = useFetchTags();
  const { mutate: addNewTag } = useMutateTag();

  const handleAddTag = (value: string) => {
    if (!value || value === "") return;

    if (!availableTags?.includes(value)) {
      addNewTag(value);
      return;
    }

    if (!tags.includes(value)) {
      const tempArr = [...tags, value];

      onUpdateOrder({
        ...orderToSubmit,
        tags: tempArr,
      });

      setInputTag("");
    }
  };

  const handleDeselectTag = (value: string) => {
    onUpdateOrder({
      ...orderToSubmit,
      tags: tags.filter(tag => tag !== value),
    });
    setInputTag("");
  };

  return (
    <>
      <Col span={12}>
        <Form.Item label="Tags">
          <Select
            style={{ width: "100%" }}
            placeholder="Add tags"
            defaultValue={tags}
            value={tags}
            mode="multiple"
            onBlur={() => setInputTag("")}
            searchValue={inputTag}
            onInputKeyDown={({ key }) => {
              if (key === "Enter") handleAddTag(inputTag ?? "");
            }}
            onSelect={handleAddTag}
            onDeselect={handleDeselectTag}
            onSearch={setInputTag}
          >
            {availableTags?.map(tag => {
              return (
                <Select.Option
                  className="oem-order-options"
                  key={tag}
                  value={tag}
                >
                  {tag}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label={
            <div>
              Planned Launch Date
              <span style={{ opacity: "0.45", marginLeft: "0.25em" }}>
                (optional)
              </span>
            </div>
          }
        >
          <DatePicker
            placeholder={"Expires After"}
            format={dateFormat}
            value={
              orderToSubmit?.expiresAt
                ? moment(orderToSubmit.expiresAt)
                : moment().add(7, "days")
            }
            onChange={onChangeDate}
          />
        </Form.Item>
      </Col>
    </>
  );
};
