import { useCallback, useMemo, useState } from "react";
import { Button, Space, Tooltip, Upload, message } from "antd";
import { FileAddOutlined, UploadOutlined } from "@ant-design/icons";
import { UploadRequestOption } from "rc-upload/lib/interface";
import { getBase64, getFileFromUrl, validateFile } from "./files.utils";
import { CAMConfig, getCAMHiResUrl, CAMSelect } from "shared/components/media";

import styles from "./UploadNewIntegration.module.scss";

type FileInfo = {
  base64: string;
  width: number;
  height: number;
};

type Props = {
  dimension: string;
  disabled: boolean;
  onFileSelected: (info: FileInfo) => void;
};

export const UploadNewIntegration = ({
  dimension,
  disabled,
  onFileSelected,
}: Props) => {
  const [isCAMLoading, setIsCAMLoading] = useState(false);
  const [isUploadLoading, setIsUploadLoading] = useState(false);

  const imageDimension = useMemo(() => {
    const [width, height] = dimension.split("x");
    return {
      width: parseInt(width.trim()),
      height: parseInt(height.trim()),
    };
  }, [dimension]);

  const processUpload = useCallback(
    async (base64: string, width: number, height: number) => {
      const { valid, error } = await validateFile(base64, width, height);
      if (valid) {
        onFileSelected({ base64, width, height });
      } else {
        message.error(error);
      }
    },
    [onFileSelected],
  );

  const onUpload = async ({ file }: UploadRequestOption) => {
    try {
      setIsUploadLoading(true);
      const { width, height } = imageDimension;
      const base64 = await getBase64(file as File);
      await processUpload(base64, width, height);
    } finally {
      setIsUploadLoading(false);
    }
  };

  const onCAMSelect = async ([asset]: WDAsset[]) => {
    if (!asset) {
      return;
    }
    try {
      setIsCAMLoading(true);
      const { width, height } = imageDimension;
      const file: File = await getFileFromUrl(
        getCAMHiResUrl(asset),
        asset.name,
      );
      const base64 = await getBase64(file);
      await processUpload(base64, width, height);
    } finally {
      setIsCAMLoading(false);
    }
  };

  return (
    <div
      className={styles.addNewWebIntegration}
      style={{ cursor: disabled ? "not-allowed" : "default" }}
    >
      <div>
        <span>Upload New Integration</span>
        <Space className={styles.webIntegrationUploader} direction="horizontal">
          <Upload
            customRequest={onUpload}
            showUploadList={false}
            accept=".jpg,.jpeg,.png"
          >
            <Tooltip placement="left" title="Upload from your computer">
              <Button
                disabled={disabled}
                loading={isUploadLoading}
                shape="circle"
                size="large"
                icon={<FileAddOutlined />}
              />
            </Tooltip>
          </Upload>
          {CAMConfig.active && (
            <CAMSelect
              maxSelected={1}
              fileTypes={["png", "jpeg", "jpg"]}
              onClose={onCAMSelect}
              title="Upload New Integration"
            >
              <Tooltip placement="right" title="CAM">
                <Button
                  disabled={disabled}
                  loading={isCAMLoading}
                  shape="circle"
                  size="large"
                  icon={<UploadOutlined />}
                />
              </Tooltip>
            </CAMSelect>
          )}
        </Space>
      </div>
    </div>
  );
};
