import { useState } from "react";
import { Button, Drawer, Space, Tooltip } from "antd";
import {
  IFacebookCampaign,
  IFacebookAdset,
} from "screens/adLibrary/facebookUtils/types";
import { useFlipFacebookCampaigns } from "./shared/hooks/useMutateFbCampaign";
import { useSelectedStore } from "shared/hooks/userPreferences/useSelectedStore";
import { getDateRange, renameAsset } from "./shared/utils/helpers.ad";
import { DataNode } from "antd/lib/tree";
import Select from "./flipperDrawer/Select";
import FlipStatus from "./flipperDrawer/FlipStatus";

const FlipperDrawer = ({
  storeName,
  showDrawer,
  setShowDrawer,
  campaigns,
  flipWithAds,
  setFlipWithAds,
  setSelectedDateRange,
}: {
  storeName: string;
  showDrawer: boolean;
  setShowDrawer: (showDrawer: boolean) => void;
  campaigns: IFacebookCampaign[];
  flipWithAds: boolean;
  setFlipWithAds: (flipWithAds: boolean) => void;
  setSelectedDateRange: (dateRange: [number, number]) => void;
}) => {
  const [flipped, setFlipped] = useState<boolean>(false);
  const [targetDate, setTargetDate] = useState<string>("");
  const [checkedKeys, setCheckedKeys] = useState<{
    checked: React.Key[];
    halfChecked: React.Key[];
  }>({ checked: [], halfChecked: [] });
  const [selectedTreeData, setSelectedTreeData] = useState<DataNode[]>([]);

  const onClose = () => {
    setFlipped(false);
    setCheckedKeys({ checked: [], halfChecked: [] });
    setShowDrawer(false);
  };
  const onClickDone = () => {
    onClose();
    const dateRange = getDateRange(targetDate);
    dateRange && setSelectedDateRange(dateRange);
  };

  const {
    flipStatus,
    isLoading,
    mutate: flipFacebookCampaigns,
  } = useFlipFacebookCampaigns();
  const { selectedStore } = useSelectedStore();
  const fbAccountId = selectedStore?.fbAccountId || "";

  const treeData = campaigns.map(campaign => {
    return {
      title: campaign.name,
      children:
        campaign?.adsets?.data.map(adset => {
          return { title: adset.name, key: adset.id, selectable: true };
        }) || [],
      key: campaign.id,
      selectable: true,
    };
  });
  const onClickFlip = () => {
    const selectedCampaigns: Record<React.Key, React.Key[]> = {};
    checkedKeys.checked.forEach(key => {
      if (key in selectedCampaigns) return;
      const campaign = campaigns.find(campaign => campaign.id === key);
      if (campaign) {
        selectedCampaigns[key] = [];
      } else {
        const adset = campaigns
          .map(campaign => campaign?.adsets?.data || [])
          .flat()
          .find(adset => adset.id === key);
        if (!adset) return;
        if (adset?.campaign_id in selectedCampaigns) {
          selectedCampaigns[adset.campaign_id].push(key);
        } else {
          selectedCampaigns[adset.campaign_id] = [adset.id];
        }
      }
    });
    flipFacebookCampaigns({
      fbAccountId,
      selectedCampaigns,
      flipWithAds,
      flipDate: targetDate,
    });
    const selectedCampaignss = treeData.filter(campaign =>
      checkedKeys.checked.includes(campaign.key),
    );
    const selectedData = selectedCampaignss.map(selectedCampaign => {
      return {
        ...selectedCampaign,
        title: renameAsset(selectedCampaign.title, targetDate),
        children: selectedCampaign.children
          .filter(adset => checkedKeys.checked.includes(adset.key))
          .map(selectedAdset => {
            return {
              ...selectedAdset,
              title: renameAsset(selectedAdset.title, targetDate),
            };
          }),
      };
    });
    setSelectedTreeData(selectedData);
    setFlipped(true);
  };

  const parentSelected = () => {
    const parentKeys = checkedKeys.checked.filter(key => {
      const campaign = campaigns.find(campaign => campaign.id === key);
      return campaign;
    });
    const adsets = checkedKeys.checked
      .map(key => {
        const adset = campaigns
          .map(campaign => campaign?.adsets?.data || [])
          .flat()
          .find(adset => adset.id === key);
        if (!adset) return;
        return adset;
      })
      .filter(Boolean);
    return (adsets as IFacebookAdset[]).every(adset =>
      parentKeys.includes(adset.campaign_id),
    );
  };
  const isFlipDisabled =
    !fbAccountId ||
    !checkedKeys?.checked?.length ||
    !targetDate ||
    !parentSelected();

  return (
    <Drawer
      title={<strong>Flipper</strong>}
      placement="right"
      visible={showDrawer}
      bodyStyle={{
        display: "flex",
        padding: "0",
      }}
      width="95%"
      footer={
        <Space>
          <Button onClick={onClose}>Cancel</Button>
          <Tooltip
            visible={!parentSelected()}
            title="Parent campaign should be selected to flip adsets"
            placement="topRight"
          >
            <Button
              type="primary"
              disabled={isFlipDisabled}
              loading={isLoading}
              onClick={flipped ? onClickDone : onClickFlip}
              data-cy="feed-next-btn"
            >
              {flipped ? "Done" : "Flip"}
            </Button>
          </Tooltip>
        </Space>
      }
      footerStyle={{ display: "flex", justifyContent: "flex-end" }}
      onClose={onClose}
    >
      {!flipped ? (
        <Select
          storeName={storeName}
          treeData={treeData}
          flipWithAds={flipWithAds}
          setFlipWithAds={setFlipWithAds}
          checkedKeys={checkedKeys}
          setCheckedKeys={setCheckedKeys}
          setTargetDate={setTargetDate}
        />
      ) : (
        <FlipStatus
          selectedTreeData={selectedTreeData}
          flippedCampaignRes={flipStatus}
        />
      )}
    </Drawer>
  );
};

export default FlipperDrawer;
