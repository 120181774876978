import { useQuery } from "react-query";
import { POLOTNO_API_KEY } from "screens/designStudio/designStudioV2/constants";
import {
  PolotnoJSON,
  StoreType,
  LayerElement,
} from "screens/designStudio/designStudioV2/types";

const templatesUrl = `https://api.polotno.dev/api/get-text-templates?${POLOTNO_API_KEY}`;

type TextTemplatesResponse = {
  hits: number;
  items: TextTemplateResource[];
};

type TextTemplateResource = {
  json: string;
  preview: string;
};

const getTextTemplates = async () => {
  const repsonse = await fetch(templatesUrl);
  return repsonse.json();
};

const getTextTemplateJson = async (url: string): Promise<PolotnoJSON> => {
  const response = await fetch(url);
  return response.json();
};

const getBoundingBox = (elements: LayerElement[]) => {
  let minX = Infinity;
  let minY = Infinity;
  let maxX = 0;
  let maxY = 0;

  elements.forEach(element => {
    minX = Math.min(minX, element.x);
    minY = Math.min(minY, element.y);
    maxX = Math.max(maxX, element.x + element.width);
    maxY = Math.max(maxY, element.y + element.height);
  });

  return {
    width: maxX - minX,
    height: maxY - minY,
    offsetX: minX,
    offsetY: minY,
  };
};

export const useTextTemplates = (store: StoreType) => {
  const query = useQuery<TextTemplatesResponse>(
    ["design-studio", "text-templates"],
    getTextTemplates,
  );

  const insertTemplate = async (url: string) => {
    const json = await getTextTemplateJson(url);
    if (json?.pages?.[0]?.children) {
      const elements = json.pages[0].children;
      const { width, height, offsetX, offsetY } = getBoundingBox(elements);

      const centerX = (store.activePage.computedWidth - width) / 2 - offsetX;
      const centerY = (store.activePage.computedHeight - height) / 2 - offsetY;

      elements.forEach(layer => {
        store.activePage.addElement({
          ...layer,
          x: layer.x + centerX,
          y: layer.y + centerY,
        });
      });
    }
  };

  return { ...query, insertTemplate };
};
