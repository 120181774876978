import { useCallback, useState, useRef } from "react";
import { Stack, TextField, IconButton } from "@mui/material";
import { StoreType } from "polotno/model/store";
import { CAMSelect } from "shared/components/media";
import { useElements } from "../useElements";
import { observer } from "mobx-react-lite";
import { useEditorDrawerContext } from "screens/designStudio/designStudioV2/editorDrawer/editorDrawerContext/useEditorDrawerContext";
import { RecentImagesList } from "./RecentImagesList";
import { SearchRounded } from "@mui/icons-material";

type BrandKitPanelProps = {
  store: StoreType;
};

const RECENT_FILES_KEY = "CAM";

export const BrandKitPanel = observer(({ store }: BrandKitPanelProps) => {
  const { addImage } = useElements(store);
  const [query, setQuery] = useState("");
  const { addRecentImages, getRecentImages, removeRecentImage } =
    useEditorDrawerContext();
  const recentImages = getRecentImages(RECENT_FILES_KEY);

  const ref = useRef<HTMLButtonElement>(null);
  const handleCAMSelection = useCallback(
    ([asset]: WDAsset[]) => {
      const element = {
        src: asset.hiResURLRaw,
        width: Number(asset.width),
        height: Number(asset.height),
      };

      addImage(element);
      addRecentImages(RECENT_FILES_KEY, [element]);
    },
    [addImage, addRecentImages],
  );

  return (
    <Stack spacing={2}>
      <TextField
        fullWidth
        size="small"
        label="Search"
        autoFocus={true}
        value={query}
        onKeyDown={event => event.key === "Enter" && ref.current?.click()}
        InputProps={{
          endAdornment: (
            <CAMSelect
              maxSelected={1}
              title="Select or upload"
              fileTypes={["png", "jpeg", "jpg"]}
              layout="grid"
              onClose={handleCAMSelection}
              search={{ query }}
            >
              <IconButton ref={ref}>
                <SearchRounded sx={{ color: "action.active" }} />
              </IconButton>
            </CAMSelect>
          ),
        }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setQuery(event.target.value);
        }}
      />

      <RecentImagesList
        files={recentImages}
        onClick={addImage}
        onRemove={image => removeRecentImage(RECENT_FILES_KEY, image)}
      />
    </Stack>
  );
});
