import { Badge, DatePicker, Input, Select, Space } from "antd";
import moment from "moment";
import { memo } from "react";
import { useAdLibraryFilters } from "screens/adLibrary/shared/hooks/dataListHooks";
import { useAds } from "screens/adLibrary/shared/hooks/useAds";
import {
  adCategoryValues,
  getColorFromQcStatus,
  qcStatusOptions,
} from "screens/adLibrary/utils";
import { FilterBy } from "shared/components/FilterByInput/FIlterByInput";
import { dateFormat } from "shared/constants/dataManagement";
import { formatDateValue, getUniqValues } from "utils/helpers";

const AdFilter = () => {
  const { originalData, isLoading } = useAds();
  const { filters, updateFilter } = useAdLibraryFilters();

  const oems = getUniqValues(originalData, ad => ad?.inputParameters?.oems);
  const audiences = getUniqValues(
    originalData,
    ad => ad?.inputParameters?.audiences,
  );
  const tags = getUniqValues(originalData, ad => ad?.inputParameters?.tags);

  return (
    <>
      <div className="ad-filter-container" data-cy="ad-filter-container">
        <FilterBy title="Ad Name Search">
          <Input
            data-cy="ad-filter-name-input"
            value={filters.name}
            onChange={event => {
              updateFilter("name", event.target.value);
            }}
          />
        </FilterBy>
        <FilterBy title="OEM">
          <Select
            mode="multiple"
            loading={isLoading}
            showSearch
            style={{ width: "100%" }}
            allowClear
            options={oems.map(oem => ({ label: oem, value: oem }))}
            onChange={(value: string[]) => {
              updateFilter("oem", value);
            }}
          />
        </FilterBy>
        <FilterBy title="Model Year">
          <Input
            value={filters.modelYear}
            onChange={event => updateFilter("modelYear", event.target.value)}
          />
        </FilterBy>
        <FilterBy title="Model Name">
          <Input
            value={filters.modelName}
            onChange={event => updateFilter("modelName", event.target.value)}
          />
        </FilterBy>

        <FilterBy title="Audience(s)">
          <Select
            mode="multiple"
            loading={isLoading}
            showSearch
            style={{ width: "100%" }}
            allowClear
            options={audiences.map(audience => ({
              label: audience,
              value: audience,
            }))}
            onChange={(value: string[]) => updateFilter("audiences", value)}
          />
        </FilterBy>
        <FilterBy title="Market Dates">
          <div style={{ display: "flex" }}>
            <DatePicker
              format={dateFormat}
              style={{ marginRight: 2 }}
              value={
                filters.inMarketStartDate?.[0]
                  ? moment(formatDateValue(filters.inMarketStartDate?.[0]))
                  : null
              }
              onChange={(date, dateString) => {
                updateFilter(
                  "inMarketStartDate",
                  date ? Date.parse(dateString).toString() : null,
                );
              }}
            />
            <DatePicker
              format={dateFormat}
              style={{ marginLeft: 2 }}
              value={
                filters.inMarketEndDate?.[0]
                  ? moment(formatDateValue(filters.inMarketEndDate?.[0]))
                  : null
              }
              onChange={(date, dateString) => {
                updateFilter(
                  "inMarketEndDate",
                  date ? Date.parse(dateString).toString() : null,
                );
              }}
            />
          </div>
        </FilterBy>
        <FilterBy title="Tags">
          <Select
            mode="multiple"
            loading={isLoading}
            showSearch={false}
            style={{ width: "100%" }}
            allowClear
            options={tags.map(tag => ({
              label: tag,
              value: tag,
            }))}
            onChange={(value: string[]) => updateFilter("tags", value)}
          />
        </FilterBy>
        <FilterBy title="Destination URL">
          <Input
            value={filters.destinationUrl}
            onChange={event =>
              updateFilter("destinationUrl", event.target.value)
            }
          />
        </FilterBy>
        <FilterBy title="UTM">
          <Input
            value={filters.utm}
            onChange={event => updateFilter("utm", event.target.value)}
          />
        </FilterBy>
        <FilterBy title="Ad Category">
          <Select
            allowClear
            style={{ width: "100%" }}
            value={filters.category}
            onChange={value => updateFilter("category", value)}
          >
            {adCategoryValues.map(
              ({ icon: IconComponent, key, text, value }) => (
                <Select.Option key={key} value={value}>
                  <Space size={10}>
                    <IconComponent />
                    {text}
                  </Space>
                </Select.Option>
              ),
            )}
          </Select>
        </FilterBy>
        <FilterBy title="Creator">
          <Input
            value={filters.createdBy}
            onChange={event => updateFilter("createdBy", event.target.value)}
          />
        </FilterBy>
        <FilterBy title="Status">
          <Select
            allowClear
            mode="multiple"
            style={{ width: "100%" }}
            value={filters.qcStatus}
            onChange={value => updateFilter("qcStatus", value)}
          >
            {qcStatusOptions().map(({ key, text, value }) => (
              <Select.Option key={key} value={value}>
                <Badge color={getColorFromQcStatus(value)} text={text} />
              </Select.Option>
            ))}
          </Select>
        </FilterBy>
      </div>
    </>
  );
};

export default memo(AdFilter);
