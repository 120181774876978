import { Tabs } from "antd";
import { orderBy } from "lodash";
import { PropsWithChildren, useCallback, useMemo } from "react";
import { defaultLanguageOptions } from "shared/constants/salesEnablement";
import { Language } from "shared/types/salesEnablement";
import styles from "./LanguageTabs.module.scss";

type Props = {
  languages: Language[];
  primaryLanguage?: Language;
  activeTab: string;
  isSecondTabDisabled?: boolean;
  setAvailableLanguages: (languages: Language[]) => void;
  setActiveLanguage: (language: string) => void;
  onRemoveLanguage: (
    removedLanguage: Language,
    newPrimaryLanguage: Language,
  ) => void;
  render: (language: Language) => JSX.Element;
};

export const LanguageTabs = ({
  primaryLanguage,
  languages,
  activeTab,
  isSecondTabDisabled,
  setAvailableLanguages,
  setActiveLanguage,
  onRemoveLanguage,
  render,
}: PropsWithChildren<Props>) => {
  const sortedLanguages = useMemo(() => {
    return primaryLanguage
      ? orderBy(
          defaultLanguageOptions.filter(option =>
            languages.includes(option.value),
          ),
          [option => option.value !== primaryLanguage],
          ["asc"],
        )
      : defaultLanguageOptions.filter(option =>
          languages.includes(option.value),
        );
  }, [languages, primaryLanguage]);

  const onEdit = useCallback(
    (targetKey, action) => {
      if (action === "add") {
        const newTab = defaultLanguageOptions.find(
          option => !languages.includes(option.value),
        );
        if (newTab) {
          const { value } = newTab;
          setAvailableLanguages([...languages, value]);
          if (!isSecondTabDisabled) setActiveLanguage(value);
        }
      } else if (action === "remove") {
        const newActiveTab = languages.filter(tab => tab !== targetKey);
        onRemoveLanguage(targetKey, newActiveTab[0]);
      }
    },
    [
      isSecondTabDisabled,
      languages,
      onRemoveLanguage,
      setActiveLanguage,
      setAvailableLanguages,
    ],
  );

  return (
    <Tabs
      type="editable-card"
      onChange={setActiveLanguage}
      activeKey={activeTab}
      onEdit={onEdit}
      itemScope
      hideAdd={languages.length === 2}
      className={styles.tabs}
    >
      {sortedLanguages.map((language, index) => {
        if (index === 1 && isSecondTabDisabled)
          return <Tabs.TabPane tab={language.label} disabled />;
        return (
          <Tabs.TabPane
            forceRender={true}
            tab={language.label}
            key={language.value}
            closable={languages.length > 1}
          >
            {render(language.value)}
          </Tabs.TabPane>
        );
      })}
    </Tabs>
  );
};
