import { UploadFileStatus } from "antd/lib/upload/interface";
import { CAMSelect, CAMConfig } from "shared/components/media";
import { UploadStatusIcon } from "shared/components/UploadStatusIcon";
import { Button, Input, Tooltip } from "antd";
import AlexiaCopyToClipboard from "shared/components/CopyLink";

type CAMUploadButtonProps = {
  isUploading: boolean;
  uploadStatus: UploadFileStatus | "default" | undefined;
  onClose: (assets: WDAsset[]) => void;
  filename?: string;
};

export const CAMUploadButton = ({
  onClose,
  isUploading,
  uploadStatus,
  filename,
}: CAMUploadButtonProps) => {
  if (!CAMConfig.active) return null;
  return (
    <>
      <CAMSelect
        maxSelected={1}
        fileTypes={["png", "jpeg", "jpg", "mp4", "mov"]}
        onClose={onClose}
      >
        <Button
          disabled={isUploading}
          icon={<UploadStatusIcon status={uploadStatus} />}
        >
          Select From CAM
        </Button>
      </CAMSelect>
      {filename && (
        <Input.Group compact style={{ display: "flex", marginTop: 8 }}>
          <Tooltip title={filename}>
            <div>
              <Input disabled value={filename} />
            </div>
          </Tooltip>
          <Button
            icon={
              <AlexiaCopyToClipboard
                text={filename}
                copyTooltipText="copy asset name"
              />
            }
          />
        </Input.Group>
      )}
    </>
  );
};
