import { LookerEmbedSDK } from "@looker/embed-sdk";
import { Spin } from "antd";
import { useCallback, useState } from "react";
import { useFetchDashboardSettings } from "shared/hooks/dashboardManagement/useFetchDashboardSettings";
import useFetchLookerIframe from "shared/hooks/metrics/useFetchLookerIframe";
import { useIsAdmin } from "shared/hooks/useIsAdmin";
import { useUser } from "shared/hooks/useUser";
import { IDashboard } from "shared/types/dashboardManagement";
import styles from "../Metrics.module.scss";
import { errorNotification } from "shared/components/customNotification/Notification";
import { getEnvVar } from "utils/helpers";

export interface LookerDashboardProps {
  dashboard?: IDashboard;
  loadingDashboard?: boolean;
  allowDownload?: boolean;
}

const LookerDashboard = ({
  dashboard,
  loadingDashboard,
  allowDownload,
}: LookerDashboardProps) => {
  const isAdmin = useIsAdmin();
  const user = useUser();
  const email = user?.email ?? "";
  const { data: dashboardSettings, isIdle } = useFetchDashboardSettings(
    !isAdmin && !!user.email,
    email,
  );
  const { data: iframeUrl, isLoading: loadingIframe } = useFetchLookerIframe(
    dashboard,
    isIdle ? null : dashboardSettings,
    allowDownload,
  );
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const lookerUrl = `https://${getEnvVar("LOOKER_DOMAIN")}`;

  const buildDashboard = useCallback(
    element => {
      if (!element || !iframeUrl) return;
      element.innerHTML = "";
      LookerEmbedSDK.init(lookerUrl);
      LookerEmbedSDK.createDashboardWithUrl(iframeUrl)
        .appendTo(element)
        .withFilters({
          Account: dashboard?.account || "",
          Oem: dashboard?.oem || "",
        })
        .on("dashboard:run:start", () => {
          setIframeLoaded(true);
        })
        .build()
        .connect()
        .catch(() => {
          errorNotification({
            messageLabel: "An unexpected error occurred loading dashboard",
          });
        });
    },
    [dashboard?.account, dashboard?.oem, iframeUrl, lookerUrl],
  );

  return (
    <div className={styles.iframeContainer}>
      <Spin
        wrapperClassName={styles.spinner}
        spinning={!iframeLoaded}
        size="large"
      >
        {iframeUrl ? (
          <div
            ref={buildDashboard}
            id="dashboard"
            className={styles.dashboard}
          />
        ) : (
          !loadingDashboard &&
          !loadingIframe && <div>Unable to fetch dashboard</div>
        )}
      </Spin>
    </div>
  );
};

export default LookerDashboard;
