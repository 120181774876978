import { AutoComplete, Col } from "antd";
import { FieldWrapper } from "screens/assetExporter/feedConfigurationV2/shared/components/FieldWrapper";
import { useAssetBatchesValueMappingContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesValueMappingContext";
import {
  MediaColumn,
  MediaSubtype,
  TAudioFile,
} from "shared/types/assetExporter";
import { getFileNameWithExtFromUrl } from "../../utils";
import { useVideoCompositionAudios } from "screens/assetExporter/feedConfigurationV2/shared/hooks/useVideoCompositionAudios";
import { v4 as uuidv4 } from "uuid";
import { useAssetBatchesContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesContext";
import styles from "../VariableInput.module.scss";

interface Props {
  filterColumns: MediaColumn[];
  value: string;
  audioFile: TAudioFile;
  setShowSelectColumn: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AudioFromFeedInput = ({
  filterColumns,
  value,
  audioFile,
  setShowSelectColumn,
}: Props) => {
  const { selectedRow } = useAssetBatchesValueMappingContext();
  const { createNewAudioFile } = useVideoCompositionAudios();
  const { setAudioFiles } = useAssetBatchesContext();

  const editInputValue = async (url: string, column?: string) => {
    const src = column ? selectedRow[column] : url;

    const fileName = getFileNameWithExtFromUrl(src);
    createNewAudioFile(
      MediaSubtype.Feed,
      src,
      fileName,
      audioFile?.id ?? uuidv4(),
      setAudioFiles,
      undefined,
      undefined,
      column,
    );
    src !== "" && setShowSelectColumn(false);
  };

  const handleOnChange = (val: string, option: any) => {
    if (val && "key" in option && typeof option.key === "string")
      editInputValue("", option.key);
    else editInputValue(val);
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && value) editInputValue("", value);
    if (e.key === "Esc" || e.key === "Escape") editInputValue("");
  };

  return (
    <Col span={24}>
      <FieldWrapper width={24} label="Column or URL" required>
        <AutoComplete
          allowClear={true}
          options={filterColumns?.map(col => {
            return {
              key: col.columnName,
              value: col.columnName,
            };
          })}
          filterOption
          className={styles.input}
          bordered={true}
          value={value}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          onClear={() => editInputValue("")}
        />
      </FieldWrapper>
    </Col>
  );
};
