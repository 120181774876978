import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Box,
  IconButton,
  Menu,
  Slider,
  Stack,
  Typography,
} from "@mui/material";
import FormatLineSpacingIcon from "@mui/icons-material/FormatLineSpacing";
import { TextLayerElement } from "screens/designStudio/designStudioV2/types";

type LetterSpacingControlProps = {
  elements: TextLayerElement[];
};

const LetterSpacingControl = observer(
  ({ elements }: LetterSpacingControlProps) => {
    const [layer] = elements;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleLetterSpacingChange = (
      _event: Event,
      newValue: number | number[],
    ) => {
      const spacingValue = Array.isArray(newValue) ? newValue[0] : newValue;
      elements.forEach(element => {
        element.set({ letterSpacing: spacingValue });
      });
    };

    return (
      <Box>
        <IconButton onClick={handleClick} size="small">
          <FormatLineSpacingIcon fontSize="small" />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Stack direction="column" width={140} m={1} alignItems="center">
            <Typography variant="caption" display="block" gutterBottom>
              Letter Spacing
            </Typography>
            <Slider
              value={
                typeof layer?.letterSpacing === "number"
                  ? layer.letterSpacing
                  : 0
              }
              onChange={handleLetterSpacingChange}
              valueLabelDisplay="auto"
              size="small"
              defaultValue={0}
              aria-label="Small"
              min={-0.2}
              max={5}
              step={0.1}
            />
          </Stack>
        </Menu>
      </Box>
    );
  },
);

export default LetterSpacingControl;
