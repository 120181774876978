import { message } from "antd";
import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import {
  ILifestyleImage,
  OfferData,
  SelectedInstance,
} from "shared/types/assetBuilder";
import { IConfig } from "shared/types/configuration";
import { ITemplate } from "shared/types/designStudio";
import { useFetchThemeImages } from "../useFetchThemeImages";
import { useFetchLifestyleImages } from "../useFetchLifestyleImages";

type ProcessImagesProps = {
  config?: IConfig;
  template?: ITemplate;
  selectedImageType: string;
  setSelectedImageType: (value: string) => void;
  offerData: OfferData | null;
  setLifestyleImages: Dispatch<SetStateAction<ILifestyleImage[]>>;
  setShouldUpdateThemeImage: (value: boolean) => void;
  selectedInstances: SelectedInstance[];
};
const useProcessImages = (props: ProcessImagesProps) => {
  const {
    config,
    selectedImageType,
    offerData,
    template,
    setLifestyleImages,
    setShouldUpdateThemeImage,
  } = props;

  const { data: themeImages, isFetching: isFetchingThemeImages } =
    useFetchThemeImages({
      template,
      offerData,
      config: config,
      onSettled: () => setShouldUpdateThemeImage(false),
    });

  const { data: lifeStyleImages, isFetching: isFetchingLifestyleImages } =
    useFetchLifestyleImages({
      template,
      offerData,
      config: config,
      onSettled: () => setShouldUpdateThemeImage(false),
    });

  const accountImages: ILifestyleImage[] = useMemo(() => {
    if (!offerData) {
      return [];
    }
    const { year, make, model, imageUrls } = offerData;
    const parsedYear = parseInt(year);
    return (
      imageUrls?.split(",").flatMap(url => ({
        url,
        make,
        model,
        year: parsedYear,
        type: "lifestyle",
      })) || []
    );
  }, [offerData]);

  const isFetching = isFetchingThemeImages || isFetchingLifestyleImages;

  useEffect(() => {
    if (!config || !offerData) return;
    if (template?.type !== "lifestyle") {
      setLifestyleImages(themeImages || []);
    } else {
      const { year, make } = offerData;
      const parsedYear = parseInt(year);

      if (!parsedYear) {
        message.error('Selected offer has invalid "year". Unable to proceed.');
        return;
      }

      if (!make) {
        message.error(
          "Year and Make are required fields for Lifestyle image but selected offer has invalid fields.",
        );
        return;
      }

      if (selectedImageType === "account") {
        setLifestyleImages(accountImages);
      } else {
        setLifestyleImages(lifeStyleImages || []);
      }
    }
  }, [
    lifeStyleImages,
    offerData,
    config,
    selectedImageType,
    template,
    themeImages,
    setLifestyleImages,
    accountImages,
  ]);

  return { isFetching };
};

export default useProcessImages;
