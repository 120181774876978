import { Table, Badge, TableColumnType } from "antd";
import moment from "moment";
import style from "./TemplateVersionsDrawer.module.scss";
import { TemplateVersion, VersionTableData } from "shared/types/designStudio";

type TemplateTableProps = {
  sortedTemplates: TemplateVersion[];
  indexSelected: number;
  setIndexSelected: (index: number) => void;
};

const TemplateTable = ({
  sortedTemplates,
  indexSelected,
  setIndexSelected,
}: TemplateTableProps) => {
  const data = sortedTemplates.map((template, idx) => ({
    key: template.uploadedAt.toString(),
    version: template.originalTemplate
      ? "Original"
      : `Version ${sortedTemplates.length - idx}`,
    status: template.status,
    uploadedAt: template.uploadedAt,
    isActive: indexSelected === idx,
  }));

  const columns: TableColumnType<VersionTableData>[] = [
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_text: string, record: any) => (
        <>
          <Badge status={record.status === "done" ? "success" : "error"} />
          {record.status === "done" ? "Uploaded" : "Failed"}
        </>
      ),
    },
    {
      title: "Date",
      dataIndex: "uploadedAt",
      key: "uploadedAt",
      render: (text: string) =>
        text && moment(text).format("YYYY/MM/DD hh:mma"),
    },
  ];

  return (
    <div className={style.table}>
      <Table
        columns={columns}
        dataSource={data}
        rowClassName={record => (record.isActive ? style.historyRowActive : "")}
        onRow={(_record, rowIndex) => ({
          onClick: () => {
            if (typeof rowIndex === "number") {
              setIndexSelected(rowIndex);
            }
          },
        })}
        pagination={false}
      />
    </div>
  );
};

export default TemplateTable;
