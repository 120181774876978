import { isEmpty } from "lodash";
import { dynamicFontMappingsUrl } from "utils/constants";
import { FontNameMappings, MappingsType } from "./FontProperty.types";

export const getFontPropertyValue = (
  textbox?: fabric.Textbox,
  propertyName?: "fontSize" | "fontFamily",
) => {
  if (!textbox || !propertyName) return "-";

  const { [propertyName]: defaultPropertyValue, styles } = textbox;
  const defaultValue = String(defaultPropertyValue);

  if (!styles || isEmpty(styles)) return defaultValue ? defaultValue : "-";

  const filteredPropValues = Object.values(textbox.styles).reduce(
    (acc: string[], style: any) => {
      if (typeof style !== "object" || isEmpty(style)) return acc;

      const values = Object.values(style).reduce(
        (innerAcc: string[], innerStyle: any) => {
          const { [propertyName]: propertyValue } = innerStyle || {};

          if (!propertyValue) return [...innerAcc, String(defaultValue)];
          return [...innerAcc, String(propertyValue)];
        },
        [] as string[],
      );

      return [...acc, ...values];
    },
    [],
  );
  const filteredValues = Array.from(new Set(filteredPropValues));

  return filteredValues.length > 1
    ? "Mixed"
    : filteredValues[0] ?? defaultValue;
};

export const getHighlightedFontPropertyValue = (
  textbox?: fabric.Textbox,
  propertyName?: "fontSize" | "fontFamily",
) => {
  if (!textbox || !propertyName) return "-";
  const defaultValue = textbox[propertyName]
    ? String(textbox[propertyName])
    : "-";

  const propertyValues = Array.from(
    new Set(
      textbox.getSelectionStyles().reduce((acc, style) => {
        if (!style) return acc;
        const { [propertyName]: propertyValue } = style;
        return [...acc, String(propertyValue ?? defaultValue)];
      }, []) as string[],
    ),
  ) as string[];

  if (propertyValues.length === 0) return defaultValue;

  return propertyValues.length > 1 ? "Mixed" : propertyValues[0]!;
};

export const isNumber = (num: any) => {
  return !Number.isNaN(+num);
};

export const fetchMappingsJson = async () => {
  try {
    const url = dynamicFontMappingsUrl;
    if (!url) throw new Error("mappings.json url invalid.");

    const res = await fetch(url, { cache: "no-cache" });
    return (await res.json()) as MappingsType;
  } catch (err) {
    return null;
  }
};

export const parseMappingsJson = (
  mappings: MappingsType,
): [string[], FontNameMappings] => {
  const fontNames = Object.values(mappings).reduce<string[]>(
    (acc, fontObjects) => {
      return [...acc, ...Object.keys(fontObjects)];
    },
    [],
  );
  const fontNameMappings = Object.values(mappings).reduce<FontNameMappings>(
    (acc, fontObjects) => {
      return { ...acc, ...fontObjects };
    },
    {},
  );
  return [fontNames, fontNameMappings];
};

export const loadDynamicFontList = async () => {
  const mappings = await fetchMappingsJson();
  return parseMappingsJson(mappings || {});
};

export const getFontSizeToUse = (textbox: fabric.Textbox) => {
  const selectedStyles = textbox.getSelectionStyles();

  // If there is no selected text, return the root font size.
  if (!selectedStyles || selectedStyles.length === 0) {
    return Number(textbox.fontSize);
  }

  // If there is mixed font size, return the largest font size among the selected text.
  return selectedStyles.reduce((acc, style) => {
    const { fontSize } = style;
    if (!fontSize) return acc;
    const parsedFontSize = Number(fontSize) || 0;

    return parsedFontSize ? parsedFontSize : acc;
  }, Number(textbox.fontSize) ?? 0);
};
