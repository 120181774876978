import { StoreType } from "polotno/model/store";
import { useState } from "react";
import { useElements } from "../editorTabs/tabPanel/useElements";
import { isGroupElement } from "../utils";

type ContextMenuState = {
  mouseX: number;
  mouseY: number;
} | null;

type UseWorkspaceContextMenuReturnType = {
  contextMenu: ContextMenuState;
  handleContextMenu: (event: React.MouseEvent) => void;
  handleClose: () => void;
  actions: {
    label: string;
    onClick: () => void;
    enabled: boolean;
  }[];
};

export const useWorkspaceContextMenu = (
  store: StoreType,
): UseWorkspaceContextMenuReturnType => {
  const [contextMenu, setContextMenu] = useState<ContextMenuState>(null);
  const { groupSelectedElements, selectedElements, ungroupSelectedElements } =
    useElements(store);
  const containsGroupElements = selectedElements.some(isGroupElement);

  const actions = [
    {
      label: "Group elements",
      onClick: () => {
        groupSelectedElements();
        handleClose();
      },
      enabled: selectedElements.length > 1 && !containsGroupElements,
    },
    {
      label: "Ungroup elements",
      onClick: () => {
        ungroupSelectedElements();
        handleClose();
      },
      enabled: containsGroupElements,
    },
  ].filter(a => a.enabled);

  const handleContextMenu = (event: React.MouseEvent) => {
    if (!actions.length) return;

    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX,
            mouseY: event.clientY,
          }
        : null,
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  return {
    contextMenu,
    handleContextMenu,
    handleClose,
    actions,
  };
};
