import { Upload, Button, Space, Typography } from "antd";
import { memo } from "react";
import { IUploadMediaUrls } from "utils/uploadMedia";
import VisualMedia from "screens/adLibrary/shared/VisualMedia";
import styles from "./FileUploadDragger.module.scss";
import { UploadStatusIcon } from "shared/components/UploadStatusIcon";
import { useUploadDragger } from "screens/adLibrary/adWizard/shared/useUploadDragger";
import { CAMUploadButton } from "screens/adLibrary/adWizard/shared/CAMUploadButton";
import { DropPlaceholder } from "screens/adLibrary/adWizard/shared/DropPlaceholder";

interface IProps {
  fileUrls: IUploadMediaUrls;
  handleChangeFile(newFile: IUploadMediaUrls): void;
  placeholder?: string;
}

const FileUploadDragger = ({
  fileUrls,
  handleChangeFile,
  placeholder,
}: IProps) => {
  const {
    isUploading,
    fileName,
    uploadStatus,
    onUpload,
    handleFileSizeValidation,
    onCAMClose,
  } = useUploadDragger(handleChangeFile);

  return (
    <Space align="end">
      {uploadStatus && (
        <VisualMedia
          thumbnailUrl={fileUrls?.thumbnail}
          videoUrl={fileUrls?.videoUrl}
          placeholder={placeholder}
          style={{ width: 200 }}
        />
      )}
      <div>
        <Typography.Text
          style={{ width: 200 }}
          ellipsis={{ tooltip: fileName }}
        >
          {fileName}
        </Typography.Text>
        <Upload.Dragger
          className="ad-carousel-card-upload"
          multiple={false}
          accept="image/*, video/*"
          showUploadList={false}
          style={{ width: uploadStatus ? "50%" : "100%" }}
          beforeUpload={handleFileSizeValidation}
          customRequest={onUpload}
        >
          {uploadStatus ? (
            <Button type="link" disabled={isUploading}>
              <UploadStatusIcon status={uploadStatus} />
              Replace
            </Button>
          ) : (
            <DropPlaceholder className={styles.placeholder} />
          )}
        </Upload.Dragger>
        <CAMUploadButton
          uploadStatus={uploadStatus}
          onClose={onCAMClose}
          isUploading={isUploading}
        />
      </div>
    </Space>
  );
};

export default memo(FileUploadDragger);
