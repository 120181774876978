import { memo, useMemo, useState } from "react";
import { Modal, Checkbox, Button, Row, Col, Divider } from "antd";
import { useWindowSize } from "shared/hooks/useWindowSize";
import useSelectedColumns from "shared/hooks/userPreferences/useSelectedColumns";
import { AdReviewTableEntry } from "shared/types/campaignManagement";
import ExpandIcon from "shared/components/ExpandIcon";
import { getColumns } from "./adTableListContainer/columns";

import styles from "./AdTableListContainer.module.scss";
import { useIsAdmin } from "shared/hooks/useIsAdmin";
import DataListURLTable from "shared/components/dataListURL/DataListURLTable";
import { adReviewFieldKeys, IAdReviewFields } from "../fields";
import { AD_NAME_DELIMITER } from "./shared/utils/helpers.table";

interface IProps {
  displayColumn: boolean;
  stickyColumns?: string[];
  defaultColumns?: string[];
  selectedRowKeys: string[];
  dataSource?: AdReviewTableEntry[];
  expand?: boolean;
}

interface IHandlers {
  setDisplayColumn: (displayColumn: boolean) => void;
  setSelectedRowKeys: (selectedRowKeys: any[]) => void;
  setDestinationUrls: (destinationUrls: (string | undefined)[]) => void;
  setSelectedEntryId: (id: string) => void;
  setShowDetails: (setShowDetails: boolean) => void;
}

type Props = IProps & IHandlers;

const AdTableListContainer = ({
  dataSource,
  displayColumn,
  defaultColumns = [
    "Name of Ad",
    "QC Status",
    "Start Date",
    "End Date",
    "Delivery",
    "Ad Format",
    "Destination URL",
    "Created Date",
  ],
  stickyColumns,
  selectedRowKeys,
  setDisplayColumn,
  setDestinationUrls,
  setSelectedEntryId,
  setShowDetails,
  expand,
}: Props) => {
  const isAdmin = useIsAdmin();

  const { windowInnerHeight } = useWindowSize();
  const { isColumnSelected, selectedColumns, setSelectedColumns } =
    useSelectedColumns(defaultColumns);

  const [tempColumns, setTempColumns] = useState(selectedColumns);

  const columns = getColumns({
    isAdmin,
    setDestinationUrls,
    setSelectedEntryId,
    setShowDetails,
  });

  const visibleColumns = columns.filter(column => !column.hidden);

  stickyColumns?.length &&
    visibleColumns.forEach((column: any) => {
      if (stickyColumns.includes(column.title)) {
        column.fixed = "left";
      }
    });

  const filteredColumns = visibleColumns.filter(column =>
    isColumnSelected(String(column.title)),
  );

  const keysWithChildren = useMemo(
    () =>
      selectedRowKeys.flatMap(key => [key, ...key.split(AD_NAME_DELIMITER)]),
    [selectedRowKeys],
  );

  return (
    <>
      <DataListURLTable<IAdReviewFields, AdReviewTableEntry>
        data-cy="ad-review-container"
        className={styles.adReviewContainer}
        scroll={{ y: windowInnerHeight - (expand ? 280 : 245) }}
        rowKey={record => record?.id || 0}
        expandIcon={props => <ExpandIcon {...props} />}
        pagination={false}
        size={expand ? "large" : "small"}
        columns={filteredColumns}
        dataSource={dataSource}
        type="virtual"
        fieldKeys={adReviewFieldKeys}
        rowSelection={{
          selectedRowKeys: keysWithChildren,
          checkStrictly: false,
          getCheckboxProps: record => ({
            disabled: record.parentId?.includes(AD_NAME_DELIMITER),
          }),
        }}
      />
      <Modal
        className={styles.adTableColumnModal}
        title="Show / Hide Columns"
        width="400px"
        onCancel={() => {
          setDisplayColumn(false);
          setTempColumns(selectedColumns);
        }}
        visible={displayColumn}
        footer={
          <Button
            key="ok"
            onClick={() => {
              setSelectedColumns(tempColumns);
              setDisplayColumn(false);
            }}
          >
            OK
          </Button>
        }
      >
        <Checkbox.Group
          defaultValue={selectedColumns}
          value={tempColumns}
          onChange={newColumns => setTempColumns(newColumns as string[])}
        >
          <Row justify="space-around" align="top">
            <Col span={11}>
              {visibleColumns.slice(0, 12).map(c => (
                <Checkbox
                  key={c.title}
                  value={c.title}
                  disabled={c.title === "Name"}
                >
                  {c.title}
                </Checkbox>
              ))}
            </Col>
            <Col span={1}>
              <Divider type="vertical" orientation="center" />
            </Col>
            <Col span={11}>
              {visibleColumns.slice(12).map(c => (
                <Checkbox key={c.title} value={c.title}>
                  {c.title}
                </Checkbox>
              ))}
            </Col>
          </Row>
        </Checkbox.Group>
      </Modal>
    </>
  );
};

export default memo(AdTableListContainer);
