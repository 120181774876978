import { Input as AntdInput, Select as AntdSelect } from "antd";
import { ColumnType } from "antd/lib/table";
import classNames from "classnames";
import { cloneDeep, get, set } from "lodash";
import { useState, ReactNode } from "react";
import AutoEllipsisText from "shared/components/AutoEllipsisText";
import { SelectOption } from "shared/components/dataList/EditableCell";
import { IAd } from "shared/types/adLibrary";
import styles from "./AdImportEditableCell.module.scss";

interface AdImportEditableCellProps {
  type?: "input" | "select";
  record: IAd;
  dataIndex: string[];
  selectOptions?: SelectOption[];
  children?: ReactNode;
  isEditing?: boolean;
  hasError?: boolean;
  handleSave: (record: IAd) => void;
}

export const AdImportEditableCell = ({
  type,
  record,
  dataIndex,
  selectOptions,
  handleSave,
  isEditing,
  hasError,
}: AdImportEditableCellProps & ColumnType<IAd>) => {
  const [editing, setEditing] = useState(isEditing);
  const value = get(record, dataIndex);

  const commitChanges = (newValue: string) => {
    const editedRecord = set(cloneDeep(record), dataIndex, newValue);
    handleSave(editedRecord);
  };

  if (!editing) {
    return (
      <div onClick={() => setEditing(true)}>
        <AutoEllipsisText>{value}</AutoEllipsisText>
      </div>
    );
  }

  if (type === "select") {
    return (
      <AntdSelect
        className={classNames(styles.input, { [styles.error]: hasError })}
        value={value}
        onChange={event => {
          commitChanges(event);
        }}
      >
        {selectOptions?.map(({ key, text, value: optionValue }) => (
          <AntdSelect.Option key={key} value={optionValue}>
            {text}
          </AntdSelect.Option>
        ))}
      </AntdSelect>
    );
  }

  return (
    <AntdInput
      className={classNames(styles.input, { [styles.error]: hasError })}
      value={value}
      onChange={event => {
        commitChanges(event.target.value);
      }}
    />
  );
};
