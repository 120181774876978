import moment from "moment";
import {
  BeeswaxCampaign,
  BeeswaxCampaignForm,
  Creative,
  LineItem,
  LineItemFormType,
  LoadCampaignRecord,
  LoadCampaignRecordCampaign,
  LoadCampaignRecordLineItem,
  LoadCampaignsBody,
} from "./types";
import { CalendarOutlined } from "@ant-design/icons";
import { dateFormat } from "shared/constants/dataManagement";
import { entries, groupBy, isString } from "lodash";
import { checkDateMatch, formatDateValue } from "utils/helpers";
import { UploadFile } from "antd/lib/upload/interface";
import uuid from "uuid";
import { isPastDate } from "screens/campaignManagement/googlePerformanceMax/pmaxCampaignsTable/newCampaignDrawer/newCampaignForm/helpers";
import { Rule } from "antd/lib/form";
import { IAccount } from "shared/types/accountManagement";
import { accountToken, beeswaxDateFormat } from "./constants";
import { nonNullable } from "utils/helpers.array";
import { getVideoDuration } from "utils/media/utils.input";

export const transformLineItemToForm = (
  lineItem: LineItem | undefined,
  campaignForm: BeeswaxCampaignForm,
): LineItemFormType => ({
  name: lineItem?.name,
  startDate: lineItem?.startDate
    ? moment(lineItem.startDate)
    : campaignForm.startDate,
  endDate: lineItem?.endDate ? moment(lineItem.endDate) : campaignForm.endDate,
  budget: lineItem?.budget,
  cpmBid: lineItem?.cpmBid,
  dealListId: lineItem?.dealListId,
  locationTarget: lineItem?.locationTarget,
  radiusValue: lineItem?.radiusValue ?? 20,
  radiusUnit: lineItem?.radiusUnit,
  lineItemType: lineItem?.type ?? "video",
  creatives: lineItem?.creatives.map(convertCreativeToUploadFile) ?? [],
});

export const convertCreativeToUploadFile = (
  creative: Creative,
): UploadFile => ({
  uid: uuid(),
  name: creative.name,
  status: "done",
  url: creative.url,
  thumbUrl: creative.thumbnailUrl,
});

export const transformFormToLineItem = async (
  form: LineItemFormType,
): Promise<Omit<LineItem, "id">> => {
  const creatives = await Promise.all(
    form.creatives.map(convertUploadFileToCreative),
  );

  return {
    name: form.name!,
    creatives: creatives.filter(Boolean) as Creative[],
    type: form.lineItemType!,
    budget: form.budget,
    startDate: form.startDate?.startOf("day").utc().format(),
    endDate: form.endDate?.endOf("day").utc().format(),
    cpmBid: form.cpmBid!,
    dealListId: form.dealListId,
    locationTarget: form.locationTarget,
    radiusValue:
      form.locationTarget === "radius" ? form.radiusValue : undefined,
    radiusUnit: form.locationTarget === "radius" ? form.radiusUnit : undefined,
  };
};

export const convertUploadFileToCreative = async (
  file: UploadFile,
): Promise<Creative | undefined> => {
  if (!file.url || !file.thumbUrl || file.status !== "done") {
    return undefined;
  }

  return {
    name: file.name,
    url: file.url,
    thumbnailUrl: file.thumbUrl,
    duration: await getVideoDuration(file.url, "mp4"),
  };
};

export const getCalendarIcon = (filtered: boolean) => (
  <CalendarOutlined color={filtered ? "#1890ff" : undefined} />
);

export const renderDate = (date: string) =>
  date ? moment(date).format(dateFormat) : "";

export const dateFilterFn =
  <T,>(key: keyof T) =>
  (value: string, record: T) => {
    const date = record[key];

    if (!date) return false;
    if (!isString(date)) throw new Error("Date is not a string");

    return checkDateMatch(value?.toString(), new Date(date).getTime());
  };

export const displayDate = (value: string) =>
  value.split(" ").map(formatDateValue).join(" - ");

export const getEndDateRules = (field: string): Rule[] => {
  return [
    ({ getFieldValue }) => ({
      validator: async (_, value) =>
        isPastDate(value, getFieldValue(field))
          ? Promise.reject(new Error("End date must be after start date"))
          : Promise.resolve(),
    }),
  ];
};

export const transformCampaignsToRecords = (
  campaigns: BeeswaxCampaign[],
  account?: IAccount,
): LoadCampaignRecord[] => {
  return campaigns.map(campaign => {
    return {
      id: uuid.v4(),
      internalId: campaign.id,
      type: "campaign",
      status: "loading",
      accountId: account?.dealer_name,
      name: replaceToken(campaign.name, account?.dealer_name),
      startDate: campaign.startDate,
      endDate: campaign.endDate,
      budget: campaign.totalBudget,
      dailyBudget: campaign.dailyBudget,
      children:
        campaign.lineItems?.map(lineItem => {
          return {
            id: uuid.v4(),
            internalId: lineItem.id,
            type: "lineItem",
            accountId: account?.dealer_name,
            name: replaceToken(lineItem.name, account?.dealer_name),
            startDate: lineItem.startDate,
            endDate: lineItem.endDate,
            budget: lineItem.budget,
            cpmBid: lineItem.cpmBid,
            creatives: lineItem.creatives,
          };
        }) ?? [],
    };
  });
};

const replaceToken = (name: string, accountName?: string) => {
  return accountName ? name.replace(accountToken, accountName) : name;
};

export const isCampaign = (
  record: LoadCampaignRecord,
): record is LoadCampaignRecordCampaign => record.type === "campaign";

export const isLineItem = (
  record: LoadCampaignRecord,
): record is LoadCampaignRecordLineItem => record.type === "lineItem";

const formatDateToBeeswaxFormat = (date: string | undefined) => {
  return moment(date).format(beeswaxDateFormat);
};

export const transformLoadDataSourceToLoadObjects = (
  dataSource: LoadCampaignRecord[],
  selectedCampaigns: BeeswaxCampaign[],
): LoadCampaignsBody => {
  return {
    loadObjects: entries(groupBy(dataSource, "accountId")).map(
      ([accountId, records]) => {
        return {
          accountId: accountId,
          campaigns: records
            .map(record => {
              if (!isCampaign(record)) return;

              const campaign = selectedCampaigns.find(
                c => c.id === record.internalId,
              );

              if (!campaign) throw new Error("Campaign not found");

              return {
                internalId: record.id,
                name: record.name,
                startDate: formatDateToBeeswaxFormat(record.startDate),
                endDate: record.endDate
                  ? formatDateToBeeswaxFormat(record.endDate)
                  : undefined,
                dailyBudget: record.dailyBudget,
                totalBudget: record.budget,
                lineItems: record.children.map<LineItem>(lineItemRecord => {
                  return transformLineItemToLoadObject(
                    lineItemRecord,
                    campaign,
                  );
                }),
              };
            })
            .filter(nonNullable),
        };
      },
    ),
  };
};

const transformLineItemToLoadObject = (
  lineItemRecord: LoadCampaignRecord,
  campaign: BeeswaxCampaign,
) => {
  const lineItem = campaign?.lineItems?.find(
    li => li.id === lineItemRecord.internalId,
  );

  if (!lineItem) throw new Error("Line item not found");
  if (!isLineItem(lineItemRecord)) throw new Error("Record is not a line item");

  return {
    ...lineItem,
    name: lineItemRecord.name,
    startDate: lineItemRecord.startDate
      ? formatDateToBeeswaxFormat(lineItemRecord.startDate)
      : undefined,
    endDate: lineItemRecord.endDate
      ? formatDateToBeeswaxFormat(lineItemRecord.endDate)
      : undefined,
    budget: lineItemRecord.budget,
    cpmBid: lineItemRecord.cpmBid,
    creatives: lineItemRecord.creatives.map(creative => ({
      name: removeWhitespaces(`${uuid.v4()}_${creative.name}`),
      url: creative.url,
      thumbnailUrl: creative.thumbnailUrl,
      duration: creative.duration,
    })),
  };
};

const removeWhitespaces = (value: string) => value.replace(/\s+/g, "");
