import { AbsoluteFill, Series } from "remotion";
import { CompositionType, TComposition } from "shared/types/assetExporter";
import { useVideoCanvas } from "../../assetBatchDrawerV2/videoStitching/useVideoCanvas";
import { DEFAULT_FPS } from "../constants";
import { useAssetBatchesValueMappingContext } from "../contexts/AssetBatchesValueMappingContext";
import { getSrcMediaComposition } from "./Preview/utils";
import { useCheckIfItIsVideoSrc } from "../hooks/useCheckIfItIsVideoSrc";

interface Props {
  editingComposition: TComposition;
  compositionIndex: number;
  playing: boolean;
  currentFrame: number;
}

export const VideoCanvasForPlayer = ({
  editingComposition,
  compositionIndex,
  playing,
  currentFrame,
}: Props) => {
  const { getVideoCanvas } = useVideoCanvas({
    fps: DEFAULT_FPS,
    playing,
    currentFrame,
  });
  const { selectedRow } = useAssetBatchesValueMappingContext();
  const src =
    editingComposition.type === CompositionType.Media
      ? getSrcMediaComposition(editingComposition, selectedRow)
      : "";
  const {
    data: { isVideo, src: convertedSrc } = { isVideo: false, src: src },
  } = useCheckIfItIsVideoSrc(src);

  return (
    <AbsoluteFill>
      <Series>
        {getVideoCanvas(
          editingComposition,
          compositionIndex,
          true,
          isVideo,
          convertedSrc,
        )}
      </Series>
    </AbsoluteFill>
  );
};
