import { useQuery } from "react-query";
import API from "services";

export const useFetchOrderTags = () => {
  const fetchOrderTags = async () => {
    const { result } = await API.services.assetBuilder.getOrderTags();
    return result;
  };

  const { data, isFetching } = useQuery("orderTags", fetchOrderTags);
  return { tags: data, isFetching };
};
