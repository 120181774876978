import { IDataListURLFields } from "shared/components/dataListURL/types";
import { StringParam } from "use-query-params";
import { onFilterBy, compareStringBy } from "utils/helpers.table";
import { LoadCampaignRecord } from "../../types";
import { dateFilterFn, displayDate } from "screens/beeswaxCampaigns/helpers";

export const fields: IDataListURLFields<
  keyof LoadCampaignRecord,
  LoadCampaignRecord
> = {
  name: {
    filterFn: onFilterBy("name", {
      matchMode: "includes",
      caseInsensitive: true,
    }),
    sorterFn: compareStringBy("name"),
    queryParamConfigFilter: StringParam,
  },
  startDate: {
    filterFn: dateFilterFn("startDate"),
    sorterFn: compareStringBy("startDate"),
    queryParamConfigFilter: StringParam,
    display: displayDate,
  },
  endDate: {
    filterFn: dateFilterFn("endDate"),
    sorterFn: compareStringBy("endDate"),
    queryParamConfigFilter: StringParam,
    display: displayDate,
  },
};

export const fieldKeys = Object.keys(fields) as Array<keyof typeof fields>;
