import { TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import FormatLineSpacingRoundedIcon from "@mui/icons-material/FormatLineSpacingRounded";
import { TextLayerElement } from "screens/designStudio/designStudioV2/types";

type LineHeightControlProps = {
  elements: TextLayerElement[];
};

const LineHeightControl = observer(({ elements }: LineHeightControlProps) => {
  const [layer] = elements;
  const handleLineHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newLineHeight = parseFloat(e.target.value);
    if (isNaN(newLineHeight)) {
      newLineHeight = 1;
    }
    elements.forEach(element => {
      element?.set({ lineHeight: newLineHeight });
    });
  };

  return (
    <TextField
      type="number"
      label="Line height"
      value={layer?.lineHeight || 1}
      onChange={handleLineHeightChange}
      size="small"
      sx={{
        maxWidth: 100,
      }}
      InputProps={{
        startAdornment: (
          <FormatLineSpacingRoundedIcon
            fontSize="small"
            sx={{
              color: "action.active",
              ml: -0.5,
              mr: 0.5,
            }}
          />
        ),
        inputProps: {
          step: 0.1,
          min: 0.1,
        },
      }}
    />
  );
});

export default LineHeightControl;
