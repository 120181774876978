import { Stack, Typography, Grid } from "@mui/material";
import { StoreType } from "polotno/model/store";
import { useElements } from "../editorTabs/tabPanel/useElements";
import { PositionButton } from "./PositionButton";
import { observer } from "mobx-react-lite";
import { SpaceIcon } from "shared/icons/SpaceIcon";

type SpacingActionsProps = {
  store: StoreType;
};

export const SpacingActions = observer(({ store }: SpacingActionsProps) => {
  const { spaceSelectedElements } = useElements(store);
  const isDisabled = store.selectedElements.length < 2;

  return (
    <Stack spacing={1}>
      <Typography variant="body1">Space Evenly</Typography>
      <Grid container columnSpacing={2}>
        <Grid item xs={6}>
          <Stack direction="row">
            <PositionButton
              startIcon={<SpaceIcon variant="vertical" />}
              onClick={() => spaceSelectedElements("space-vertical")}
              disabled={isDisabled}
            >
              Vertically
            </PositionButton>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row">
            <PositionButton
              startIcon={<SpaceIcon variant="horizontal" />}
              onClick={() => spaceSelectedElements("space-horizontal")}
              disabled={isDisabled}
            >
              Horizontally
            </PositionButton>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
});
