import { uniqBy } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSyncedState } from "shared/hooks/useSyncedState";
import { Layer, TemplateFile } from "shared/types/salesEnablement";

type Props = {
  file?: TemplateFile;
};
export default (props: Props) => {
  const { file } = props;
  const [layers, setLayers] = useState<Layer[]>([]);

  const dedupLayers = useCallback((layers: Layer[]) => {
    return uniqBy(layers, "name");
  }, []);

  const orgLayers = useMemo(() => {
    return dedupLayers(
      file?.pages?.reduce<Layer[]>(
        (acc, page) => [...acc, ...(page.layers?.flat() || [])],
        [],
      ) ?? [],
    );
  }, [file, dedupLayers]);

  useEffect(() => {
    if (!file) return;

    setLayers(
      dedupLayers(
        file?.pages?.flatMap(page => page.layers?.flat() ?? []).flat() ?? [],
      ),
    );
  }, [file, dedupLayers]);

  const [filteredLayers, setFilteredLayers] = useSyncedState(orgLayers);

  return {
    layers,
    filteredLayers,
    setLayers,
    setFilteredLayers,
  };
};
