import {
  MarketingMaterial,
  MarketingMaterialTableItem,
  MktMatFieldMap,
  OptionsField,
  ProductField,
  DisclosureField,
  ManualField,
  AccountField,
} from "shared/types/marketingMaterials";
import {
  Template,
  AgentGroupLayer,
  Language,
  Layer,
  TemplateFile,
} from "shared/types/salesEnablement";
import {
  isAccountGroupLayer,
  isAgentGroupLayer,
  isDisclosureGroupLayer,
  isManualGroupLayer,
  isOptionGroupLayer,
  isProductGroupLayer,
} from "screens/designStudio/templates/fileDrawer/utils";
import {
  AgentProfile,
  getAgentValues,
  useUserAsAgentProfile,
} from "./agentManagement";
import moment from "moment-timezone";
import uuid from "uuid";

export function useGetInitialValues(
  template: Template | undefined,
  material: MarketingMaterial | undefined,
  language: Language,
): {
  initialValues: Partial<MarketingMaterialTableItem>;
  hasProductLayer: boolean;
  disclosureFieldId: string | undefined;
} {
  const userAsAgent = useUserAsAgentProfile();

  const initialFields = getInitialValues(userAsAgent, language, template);

  if (!template?.files[language])
    return {
      initialValues: { ...material },
      hasProductLayer: false,
      disclosureFieldId: "",
    };

  return {
    initialValues: {
      ...material,
      language,
      fields: material?.fields ?? initialFields,
      id: material?.id || uuid(),
    },
    hasProductLayer: hasProductLayer(template?.files[language]),
    disclosureFieldId: getDisclosureFieldId(template?.files[language]),
  };
}

export function hasProductLayer(templateFile?: TemplateFile): boolean {
  return !!templateFile?.pages
    ?.flatMap(page => page.layers)
    .some(layer => layer && isProductGroupLayer(layer));
}

export function getDisclosureFieldId(templateFile?: TemplateFile): string {
  return (
    templateFile?.pages
      ?.flatMap(page => page.layers)
      .find(layer => layer && isDisclosureGroupLayer(layer))?.id ?? ""
  );
}

export function getInitialValues(
  userAsAgent: AgentProfile,
  language: Language,
  template?: Template,
) {
  const handleGroupLayer = (layer: Layer) => {
    if (!template) return;

    if (isManualGroupLayer(layer)) {
      return buildManualGroupField(layer);
    }

    if (isAgentGroupLayer(layer)) {
      return getAgentValues(layer as AgentGroupLayer, userAsAgent);
    }

    if (isOptionGroupLayer(layer)) {
      return buildOptionGroupField();
    }

    if (isDisclosureGroupLayer(layer)) {
      return buildDisclosureGroupField(layer);
    }

    if (isProductGroupLayer(layer)) {
      return buildProductGroupField(layer);
    }

    if (isAccountGroupLayer(layer)) {
      return buildAccountGroupField();
    }

    if (layer.smartVariable === "expirationDate") {
      return moment(template.expirationDate).format("M/YY");
    }

    if (layer.smartVariable === "formNumber") {
      return template.formNumber[language];
    }

    return undefined;
  };

  if (!template?.files) return {};
  const layers =
    template.files?.[language]?.pages?.flatMap(page => page.layers ?? []) ?? [];

  return layers.reduce<MktMatFieldMap>((acc, layer) => {
    return {
      ...acc,
      [layer.id]: handleGroupLayer(layer),
    };
  }, {});
}

const buildManualGroupField = (layer: Layer) =>
  ({
    id: layer.id,
    name: layer.name,
    type: "manual",
    fieldsData: [
      {
        id: uuid(),
        field: {},
      },
    ],
  } satisfies ManualField);

const buildProductGroupField = (layer: Layer) =>
  ({
    id: layer.id,
    type: "product",
    name: layer.name,
    productsData: [{ id: "" }],
  } satisfies ProductField);

const buildDisclosureGroupField = (layer: Layer) =>
  ({
    id: layer.id,
    name: layer.name,
    type: "disclosure",
    value: "",
  } satisfies DisclosureField);

const buildAccountGroupField = () =>
  ({
    type: "account",
    value: "",
  } satisfies AccountField);

const buildOptionGroupField = () =>
  ({
    optionId: "",
    childOptions: {},
    type: "options",
  } satisfies OptionsField);
