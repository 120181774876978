import { DeleteRounded } from "@mui/icons-material";
import { LayerElement } from "screens/designStudio/designStudioV2/types";
import { useLayerActions } from "./useLayerActions";
import { observer } from "mobx-react-lite";

type DeleteLayerProps = {
  layer: LayerElement;
};

export const DeleteLayer = observer(({ layer }: DeleteLayerProps) => {
  const { deleteLayer } = useLayerActions(layer);

  return (
    <DeleteRounded
      onClick={deleteLayer}
      sx={{ fontSize: "medium", color: "action.active" }}
    />
  );
});
