import { Slider, SliderSingleProps, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { TAudioFile } from "shared/types/assetExporter";
import { useAssetBatchesContext } from "../../shared/contexts/AssetBatchesContext";
import styles from "./AudioSlider.module.scss";
import { formatTime } from "./utils";

interface Props {
  audioFile: TAudioFile;
  durationMs: number; // duration in milliseconds
  tooltipText?: string;
}

export const AudioSlider = ({ audioFile, durationMs, tooltipText }: Props) => {
  const { setEditingAudioFiles } = useAssetBatchesContext();

  const [sliderValue, setSliderValue] = useState<[number, number]>([
    audioFile.start,
    audioFile.end,
  ]);

  useEffect(() => {
    setSliderValue([audioFile.start, audioFile.end]);
  }, [audioFile]);

  const marks: SliderSingleProps["marks"] = {
    0: formatTime(0),
    [durationMs]: formatTime(durationMs),
  };

  const afterChange = (value: number[], id: string) => {
    setEditingAudioFiles(prev =>
      prev.map(audFile =>
        audFile.id === id
          ? { ...audFile, start: value[0], end: value[1] }
          : audFile,
      ),
    );
  };

  return (
    <div key={audioFile.id} className={styles.audioSliderContainer}>
      <div className={styles.audioSliderContent}>
        <div className={styles.audioSliderName}>
          <Tooltip title={tooltipText ?? audioFile.name}>
            <span className={styles.truncate}>{audioFile.name}</span>
          </Tooltip>
        </div>

        <Slider
          className={styles.slider}
          range
          value={sliderValue}
          max={durationMs}
          marks={marks}
          trackStyle={[{ backgroundColor: "#1890FF" }]}
          handleStyle={[{ borderColor: "#1890FF" }, { borderColor: "#1890FF" }]}
          onChange={value => setSliderValue(value)}
          onAfterChange={value => afterChange(value, audioFile.id)}
          tipFormatter={value => formatTime(value ?? 0)}
        />
      </div>
    </div>
  );
};
