import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useAssetBatchesContext } from "../../shared/contexts/AssetBatchesContext";
import { useAssetBatchesRules } from "../../shared/hooks/useAssetBatchesRules";
import styles from "./Rules.module.scss";
import RuleCondition from "./rules/RuleCondition";
import { hasAnyComparisonOperator } from "../../shared/validators";

const Rules = () => {
  const { ruleConditions } = useAssetBatchesContext();
  const { addEmptyRuleCondition, resetRuleCondition } = useAssetBatchesRules();

  return (
    <div>
      <div className={styles.desc}>
        Add rules to generate assets from the matching rows:
      </div>
      {ruleConditions
        .sort(cond => cond.index)
        .map(ruleCond => {
          return (
            <RuleCondition
              key={`rule-condition-${ruleCond.index}`}
              index={ruleCond.index}
            />
          );
        })}
      <Button
        icon={<PlusOutlined />}
        type={"link"}
        onClick={() => addEmptyRuleCondition()}
        disabled={hasAnyComparisonOperator(ruleConditions)}
      >
        Add Condition
      </Button>
      <Button
        className={styles.clearButton}
        icon={<DeleteOutlined />}
        type={"link"}
        onClick={() => resetRuleCondition()}
      >
        Clear All
      </Button>
    </div>
  );
};

export default Rules;
