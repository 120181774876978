import { useQueries, useQuery, useQueryClient } from "react-query";
import API from "services";
import { AgentFeedInfo } from "shared/types/salesEnablement";
import { useUser } from "../useUser";
import { getResultIfOk } from "services/adLibrary";
import { normalizeBy, isTruthy } from "utils/helpers.array";

export const useAuthedAgentFeedInfo = () => {
  const user = useUser();
  const { sub } = user;
  const subParts = sub.split("|");
  const subUUID = subParts[subParts.length - 1];

  return useQuery<AgentFeedInfo | undefined>(
    ["agent-feed-info", subUUID],
    async () => API.privServices.salesEnablement.getAgentFeedInfo({ subUUID }),
  );
};

export const useAgentFeedInfoByEmail = (email: string) => {
  return useQuery<AgentFeedInfo | undefined, Error>({
    queryKey: ["agent-feed-info", email],
    queryFn: () => API.privServices.salesEnablement.getAgentFeedInfo({ email }),
  });
};

export const useAgentsFeedInfoByEmail = (emails: string[]) => {
  const response = useQueries(
    emails.map(email => ({
      queryKey: ["agent-feed-info", email],
      queryFn: () =>
        API.privServices.salesEnablement.getAgentFeedInfo({ email }),
    })),
  );
  const allData = response.map(res => res.data).filter(isTruthy);
  const agentProfileByEmail = normalizeBy(allData, agentFeedInfo =>
    agentFeedInfo.email?.toLowerCase(),
  );
  return {
    agentProfileByEmail,
    isLoading: response.some(res => res.isLoading),
  };
};

export const useFetchAgentFeedInfoByEmail = () => {
  const queryClient = useQueryClient();

  return async (email: string) => {
    const result = await queryClient.fetchQuery(
      ["agent-feed-info", email],
      () => API.privServices.salesEnablement.getAgentFeedInfo({ email }),
    );

    return getResultIfOk(result);
  };
};
