import { Tooltip, Select, Form } from "antd";
import styles from "../../ConditionalFields.module.scss";
import { useMemo, useCallback } from "react";
import { states } from "shared/constants/states";
import { useProducts } from "shared/hooks/admin/useProducts";
import {
  Language,
  Multilingual,
  ProductDetails,
} from "shared/types/salesEnablement";
import { FormListFieldData } from "antd/lib/form/FormList";
import { Link } from "react-router-dom";
import { Languages } from "utils/helpers.salesEnablement";

type Props = {
  field: FormListFieldData;
  cond: FormListFieldData;
  primaryLanguage?: Language;
  activeLanguage: Language;
  disabled: boolean;
  disabledInputTooltip: string | undefined;
};

export const ValueInput = ({
  field,
  cond,
  disabled,
  primaryLanguage,
  activeLanguage,
  disabledInputTooltip,
}: Props) => {
  const { products, productTypes } = useProducts();

  const { productOptions, missingTranslationProducts } = useMemo(() => {
    const missingTranslationProducts: string[] = [];
    const allProductsOptions = products.map(product => {
      const activeLanguageProductLabel =
        primaryLanguage && product.details[activeLanguage]?.name;
      if (!activeLanguageProductLabel) {
        missingTranslationProducts.push(product.id);
      }
      return {
        label:
          activeLanguageProductLabel ??
          (product.details[
            product.primaryLanguage as keyof Multilingual<ProductDetails>
          ]?.name ||
            product.id),
        value: product.id,
      };
    });
    return {
      productOptions: allProductsOptions,
      missingTranslationProducts,
    };
  }, [activeLanguage, primaryLanguage, products]);

  const getOptions = useCallback(
    option => {
      switch (option) {
        case "specificProduct":
          return productOptions;
        case "productType":
          return productTypes.map(type => ({ label: type, value: type }));

        case "location":
          return Object.values(states).map(state => ({
            label: `${state.id} - ${state.name}`,
            value: state.id,
          }));

        default:
          return [];
      }
    },
    [productOptions, productTypes],
  );

  const getValidationRules = useCallback(
    (
      value: string,
    ): { validateStatus: "warning"; help: React.ReactNode } | undefined => {
      if (missingTranslationProducts.includes(value)) {
        const languageLabel = Languages[activeLanguage].label;
        return {
          validateStatus: "warning",
          help: (
            <>
              {`There's no ${languageLabel} translation to this product. `}
              <Link to={`/sales-enablement/products/${value}`} target="_blank">
                Click here to add
              </Link>
            </>
          ),
        };
      }
      return undefined;
    },
    [activeLanguage, missingTranslationProducts],
  );

  return (
    <Tooltip title={disabledInputTooltip}>
      <Form.Item
        className={styles.formItem}
        dependencies={["fields", field.name, "conditions", cond.name]}
        rules={[
          {
            required: true,
            message: "Please select an operator",
          },
        ]}
      >
        {({ getFieldValue }) => {
          const { if: ifOption, value } = getFieldValue([
            "fields",
            field.name,
            "conditions",
            cond.name,
          ]);
          const { validateStatus, help } = getValidationRules(value) ?? {};
          return (
            <Form.Item
              className={
                validateStatus ? styles.warningFormItem : styles.formItem
              }
              name={[cond.name, "value"]}
              validateStatus={validateStatus}
              help={help}
            >
              <Select<string>
                placeholder="Please Select"
                disabled={disabled}
                options={getOptions(ifOption)}
                showSearch
                optionFilterProp="label"
              />
            </Form.Item>
          );
        }}
      </Form.Item>
    </Tooltip>
  );
};
