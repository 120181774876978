export const MIN_PIXELRATIO = 0.2;
export const MAX_PIXELRATIO = 300 / 72;
export const PIXELRATIO_STEP = 0.2;

export const MIN_FPS = 1;
export const MAX_FPS = 60;
export const FPS_STEP = 1;

export const MIN_PAGE_SIZE_MODIFIER = 0.2;
export const MAX_PAGE_SIZE_MODIFIER = 3;
export const PAGE_SIZE_MODIFIER_STEP = 0.2;
