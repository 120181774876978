import { FormInstance, Modal, Tabs } from "antd";
import { useMemo, useRef, useState } from "react";
import { AssetDetail } from "../AssetDetail";
import { AssetMetaForm } from "../AssetMetaForm";

import styles from "./AssetTabs.module.scss";

type Props = {
  selectedAssets: WDAsset[];
  drawerId: string;
};

type FormInstanceWithSubmit = Omit<FormInstance<MGFormValues>, "submit"> & {
  submit: (callback: () => Promise<void>) => Promise<void>;
};

export const AssetTabs = ({ drawerId, selectedAssets }: Props) => {
  const formRef = useRef<FormInstanceWithSubmit>(null);
  const [selectedIds, setSelectedIds] = useState(selectedAssets.map(a => a.id));
  const activeAssets = useMemo(() => {
    return selectedAssets.filter(asset => selectedIds.includes(asset.id));
  }, [selectedAssets, selectedIds]);

  const onChange = (ids: string) => {
    const formInstance = formRef.current;
    if (ids !== selectedIds.join() && formInstance?.isFieldsTouched()) {
      Modal.confirm({
        title:
          "Unsaved changes detected. Would you like to save before continuing?",
        okText: "Save & Continue",
        cancelText: "Discard",
        onOk: () => {
          const afterSubmit = () => {
            setSelectedIds(ids.split(","));
            return Promise.resolve();
          };
          formInstance?.submit(afterSubmit);
        },
        onCancel: () => {
          setSelectedIds(ids.split(","));
        },
      });
    } else {
      setSelectedIds(ids.split(","));
    }
  };

  return (
    <>
      <Tabs
        activeKey={selectedIds.join()}
        tabBarStyle={{ margin: 0 }}
        tabBarExtraContent={{
          left: <TabHeader />,
        }}
        onChange={onChange}
      >
        {selectedAssets.length > 1 && (
          <Tabs.TabPane
            key={selectedAssets.map(a => a.id).join()}
            tab="All Assets"
          />
        )}
        {selectedAssets.map(asset => {
          return (
            <Tabs.TabPane
              key={asset.id}
              tab={<TabTitle title={asset.name} />}
            />
          );
        })}
      </Tabs>
      <div className={styles.body}>
        {activeAssets.length && (
          <>
            <AssetDetail assets={activeAssets} />
            <AssetMetaForm
              key={selectedIds.join(",")}
              drawerId={drawerId}
              assets={activeAssets}
              ref={formRef}
            />
          </>
        )}
      </div>
    </>
  );
};

const TabHeader = () => {
  return <strong className={styles.header}>View Assets</strong>;
};

const TabTitle = ({ title }: { title: string }) => {
  return <div className={styles.title}>{title}</div>;
};
