import { useState } from "react";
import { Checkbox, CheckboxProps, InputNumber, InputNumberProps } from "antd";
import { AgentGroupLayer, OtherGroupLayer } from "shared/types/salesEnablement";
import { OnLayerChangeFunc } from "../Layers";
import styles from "./GroupLayerMaxItems.module.scss";

type Props = {
  layer: OtherGroupLayer | AgentGroupLayer;
  onChange?: OnLayerChangeFunc;
  disabled?: boolean;
};

const GroupLayerMaxItems = (props: Props) => {
  const { disabled, layer } = props;
  const [disabledInput, setDisabledInput] = useState(
    layer.maxItems === undefined,
  );

  const updateMaxItems = (n: number | undefined) => {
    props.onChange?.(
      { ...layer, maxItems: n },
      { type: "update_smart_variable" },
    );
  };

  const handleCheckboxChange: CheckboxProps["onChange"] = e => {
    setDisabledInput(!e.target.checked);
    updateMaxItems(e.target.checked ? 2 : undefined);
  };

  const handleInputChange: InputNumberProps["onChange"] = value => {
    updateMaxItems(!!value ? Number(value) : undefined);
  };

  return (
    <div className={styles.container}>
      <Checkbox
        defaultChecked={!!layer.maxItems}
        disabled={disabled || !layer.smartVariable}
        onChange={handleCheckboxChange}
      />
      <div className={styles.children}>
        <span>Max number of items:</span>
        <InputNumber
          type="number"
          min={1}
          max={10}
          value={layer.maxItems}
          onChange={handleInputChange}
          disabled={disabled || !layer.smartVariable || disabledInput}
          className={styles.input}
        />
      </div>
    </div>
  );
};

export default GroupLayerMaxItems;
