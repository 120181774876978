import { useQueries } from "react-query";
import { DesignStudioTableTemplate } from "screens/designStudio/context/DesignStudioDataProvider";
import { fetchCanvasJson } from "shared/components/contextAPI/shared/RenderTemplate";
import { getCanvasJsonQueryKey } from "shared/hooks/useFetchCanvasJsonObjects";
import { nonNullable } from "utils/helpers.array";

export const useCanvasJson = (items: DesignStudioTableTemplate[]) => {
  return useQueries(
    items
      .map(item => {
        const url = item.asset.canvasJsonUrl;
        if (!url) return null;
        return {
          queryKey: getCanvasJsonQueryKey(url),
          queryFn: () => fetchCanvasJson(url),
          staleTime: Infinity,
        };
      })
      .filter(nonNullable),
  );
};
