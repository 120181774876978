import { isCampaign } from "screens/beeswaxCampaigns/helpers";
import { LoadCampaignRecord, LoadCampaignRecordCampaign } from "../../types";
import { useLoadCampaignsContext } from "../LoadProvider";

export const useDataSourceActions = () => {
  const { setDataSource } = useLoadCampaignsContext();

  const changeDataSource = (
    id: string,
    type: "campaign" | "lineItem",
    field: keyof LoadCampaignRecord,
    value: any,
  ) => {
    setDataSource(prevDataSource => {
      return prevDataSource.map(record => {
        if (type === "campaign" && record.id === id) {
          return changeCampaignRecord(record, field, value);
        }

        if (type === "lineItem" && isCampaign(record)) {
          return changeLineItemRecord(id, record, field, value);
        }

        return record;
      });
    });
  };

  const changeCampaignRecord = (
    record: LoadCampaignRecord,
    field: keyof LoadCampaignRecord,
    value: any,
  ): LoadCampaignRecord => {
    return {
      ...record,
      [field]: value,
    };
  };

  const changeLineItemRecord = (
    lineItemId: string,
    record: LoadCampaignRecordCampaign,
    field: keyof LoadCampaignRecord,
    value: any,
  ): LoadCampaignRecord => {
    return {
      ...record,
      children: record.children.map(child => {
        if (child.id === lineItemId) {
          return {
            ...child,
            [field]: value,
          };
        }

        return child;
      }),
    };
  };

  return { changeDataSource };
};
