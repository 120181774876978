import { useMemo, useState } from "react";
import { Tooltip } from "antd";
import { AgentFieldData } from "shared/types/marketingMaterials";
import { joinArray } from "utils/helpers.array";
import {
  useAllAgents,
  getAgentDataFromAgent,
  type AgentProfile,
  isMissingLicense,
} from "../../hooks/agentManagement";
import CreateNewSelectLabel from "shared/components/select/CreateNewSelectLabel";
import AgentProfileDrawer from "screens/agentProfiles/AgentProfileDrawer";
import { useMaterialFormInstance } from "screens/adLibrary/marketingMaterials/MarketingMaterialsProvider";
import type { StateKey } from "shared/constants/states";
import { SortedSelect } from "shared/components/select/SortedSelect";

type Props = {
  agentFieldData: AgentFieldData;
  setAgentData: (newAgentData: AgentFieldData) => void;
  templateFieldNames: string[];
  disabled?: boolean;
  selectedAgentIds: string[];
};

const AgentSelect = ({
  agentFieldData,
  setAgentData,
  templateFieldNames,
  disabled,
  selectedAgentIds,
}: Props) => {
  const form = useMaterialFormInstance();
  const locations = form.getFieldValue("locations") as StateKey[] | undefined;
  const { agents, isLoading } = useAllAgents();
  const selectedAgent = useMemo(
    () => agents.find(agent => agent.id === agentFieldData.refAgentId),
    [agents, agentFieldData.refAgentId],
  );

  const [displayAgentDrawer, setDisplayAgentDrawer] = useState(false);
  const createNewOption = useMemo(
    () => ({
      value: "new",
      label: (
        <CreateNewSelectLabel
          onCreateClick={() => setDisplayAgentDrawer(true)}
          label="New Agent"
        />
      ),
    }),
    [],
  );
  const options = useMemo(
    () => [
      createNewOption,
      ...agents
        .filter(
          agent =>
            !selectedAgentIds.includes(agent.id) ||
            agent.id === agentFieldData.refAgentId,
        )
        .map(agent => {
          const disabled = isMissingLicense(agent.licensedStates, locations);
          const name =
            joinArray([agent.firstName, agent.lastName], " ") ?? agent.email;

          return {
            label: (
              <Tooltip
                title={
                  "This agent is not licensed in one or more of the selected states above."
                }
                visible={disabled ? undefined : false}
              >
                <div>{name}</div>
              </Tooltip>
            ),
            value: agent.id,
            disabled,
          };
        }),
    ],
    [
      agentFieldData.refAgentId,
      agents,
      createNewOption,
      locations,
      selectedAgentIds,
    ],
  );

  const onAgentChange = (agentId: string) => {
    const foundAgent = agents.find(agentItem => agentItem.id === agentId);
    if (!foundAgent) return;

    const newAgentData = getAgentDataFromAgent(foundAgent, templateFieldNames);
    setAgentData(newAgentData);
  };

  const onAddAgentProfileSuccess = (agentProfile: AgentProfile) => {
    const newAgentData = getAgentDataFromAgent(
      agentProfile,
      templateFieldNames,
    );

    const foundAgent = agents.find(
      agentItem => agentItem.id === agentProfile.id,
    );

    if (!isMissingLicense(foundAgent?.licensedStates, locations)) {
      setAgentData(newAgentData);
    }
    setDisplayAgentDrawer(false);
  };

  return (
    <>
      <SortedSelect<string>
        loading={isLoading}
        placeholder="Please Select"
        options={options}
        value={selectedAgent?.id}
        onChange={onAgentChange}
        disabled={disabled}
      />
      <AgentProfileDrawer
        agentId={undefined}
        visible={displayAgentDrawer}
        onClose={() => setDisplayAgentDrawer(false)}
        onSuccess={onAddAgentProfileSuccess}
      />
    </>
  );
};

export default AgentSelect;
