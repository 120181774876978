import { DownloadRounded } from "@mui/icons-material";
import {
  IconButton,
  Button,
  Popover,
  Stack,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { StoreType } from "polotno/model/store";
import { useAnchorPopover } from "screens/designStudio/designStudioV2/hooks/useAnchorPopover";
import { useDownload } from "./download/useDownload";
import {
  DOWNLOAD_FILE_TYPES,
  DownloadFileType,
} from "../../../editorDrawerContext/helpers.store";

import { QualitySlider } from "./download/QualitySlider";
import { FPSSlider } from "./download/FPSSlider";
import { PageSizeSlider } from "./download/PageSizeSlider";

type DownloadProps = {
  store: StoreType;
};

export const Download = observer(({ store }: DownloadProps) => {
  const { id, open, onClick, onClose, anchorEl } = useAnchorPopover("download");
  const {
    downloadType,
    onChange,
    onDownload,
    pixelRatio,
    setPixelRatio,
    fps,
    setFps,
    pageSizeModifier,
    setPageSizeModifier,
  } = useDownload(store);

  return (
    <>
      <IconButton size="small" onClick={onClick}>
        <DownloadRounded fontSize="small" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack sx={{ p: 2.5, minWidth: 200 }} spacing={2}>
          <FormControl>
            <InputLabel id="label">File Type</InputLabel>
            <Select<DownloadFileType>
              id="file-type-select"
              labelId="label"
              value={downloadType}
              onChange={onChange}
              size="small"
              label="File Type"
            >
              {DOWNLOAD_FILE_TYPES.map(type => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Stack spacing={1}>
            <QualitySlider
              width={store.width}
              height={store.height}
              dpi={store.dpi}
              pixelRatio={pixelRatio}
              setPixelRatio={setPixelRatio}
              type={downloadType}
            />
            <PageSizeSlider
              width={store.width}
              height={store.height}
              dpi={store.dpi}
              pageSizeModifier={pageSizeModifier}
              setPageSizeModifier={setPageSizeModifier}
              type={downloadType}
            />
            <FPSSlider fps={fps} setFps={setFps} type={downloadType} />
          </Stack>

          <Button variant="contained" onClick={() => onDownload()} size="small">
            Download {downloadType}
          </Button>
        </Stack>
      </Popover>
    </>
  );
});
