import { Tag, Tooltip } from "antd";
import styles from "./TagColumn.module.scss";

type Props = {
  tags: string[];
};

export const TagColumn = ({ tags }: Props) => {
  if (!tags) {
    return null;
  }

  const firstTwoTags = tags.slice(0, 2);

  const tooltipTags = (tag: string) => {
    return (
      <Tooltip title={tag} key={tag}>
        <Tag className={styles.tag}>{tag}</Tag>
      </Tooltip>
    );
  };

  const extraTags = () => {
    return (
      <Tooltip title={tags.slice(2).join(", ")}>
        <Tag>+{tags.length - 2}</Tag>
      </Tooltip>
    );
  };

  return (
    <div className={styles.host}>
      {firstTwoTags.map((tag: string) => tooltipTags(tag))}
      {tags.length > 2 ? extraTags() : null}
    </div>
  );
};
