import { ExportDestination } from "../types";
import { isFinalExportStep } from "./isFinalExportStep";

export const getNextButtonText = (
  exportDestination: ExportDestination | null,
  currentStep: number,
) => {
  if (isFinalExportStep(currentStep, exportDestination)) {
    return "Export";
  }
  return "Next";
};
