import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { ToolbarButton } from "shared/components/toolbarTable/ToolbarTable";
import { BeeswaxCampaignTableRecord } from "./types";
import { DataListTagsURL } from "shared/components/dataListURL/DataListTagsURL";
import { TableActionsContextProvider } from "shared/components/HoverActionsVirtualTable/TableActionsContext";
import { ContextTableActions } from "shared/components/tableActions/ContextTableActions";
import { InlineTableActions } from "shared/components/tableActions/InlineTableActions";
import DataListURLTable from "shared/components/dataListURL/DataListURLTable";
import { fieldKeys } from "./fields";
import Toolbar from "./Toolbar";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "./constants";
import { useDeleteBeeswaxCampaigns } from "./hooks/useDeleteBeeswaxCampaigns";
import { useBeeswaxCampaignsDataSource } from "./hooks/useBeeswaxCampaignsDataSource";
import ExpandIcon from "shared/components/ExpandIcon";
import { isEmpty } from "lodash";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { useColumns } from "./columns";
import { CheckboxProps } from "antd";

const Table = () => {
  const navigate = useNavigate();
  const {
    data: beeswaxCampaigns,
    originalData: unfilteredBeeswaxCampaigns,
    isLoading,
  } = useBeeswaxCampaignsDataSource();
  const { mutate: deleteCampaigns } = useDeleteBeeswaxCampaigns();
  const { windowInnerHeight } = useWindowSize();
  const columns = useColumns();

  const generateToolbarContents: (
    record?: BeeswaxCampaignTableRecord,
  ) => ToolbarButton = record => {
    if (!record || record.type === "lineItem") return {};

    return {
      Edit: {
        icon: <EditOutlined />,
        onClick: () => {
          navigate(ROUTES.update(record.id));
        },
        showInContextMenu: true,
        showInInlineMenu: true,
      },
      Delete: {
        icon: <DeleteOutlined />,
        onClick: () => {
          deleteCampaigns({ ids: [record.id] });
        },
        showInContextMenu: true,
        showInInlineMenu: true,
      },
    };
  };

  const getCheckboxProps = (
    record: BeeswaxCampaignTableRecord,
  ): Partial<Omit<CheckboxProps, "checked" | "defaultChecked">> => {
    return record.type === "lineItem"
      ? { style: { visibility: "hidden" } }
      : { style: { marginRight: 22 } };
  };

  const getExpandIconStyle = (
    record: BeeswaxCampaignTableRecord,
  ): React.CSSProperties =>
    record.type === "campaign" && !isEmpty(record.children)
      ? { marginTop: 5, float: "left" }
      : { visibility: "hidden" };

  return (
    <div style={{ padding: "1em 0" }}>
      <Toolbar />
      <DataListTagsURL<
        keyof BeeswaxCampaignTableRecord,
        BeeswaxCampaignTableRecord
      >
        data={beeswaxCampaigns}
        originalData={unfilteredBeeswaxCampaigns}
      />
      <TableActionsContextProvider<BeeswaxCampaignTableRecord>
        renderContextActions={record => (
          <ContextTableActions
            getItemsToRender={generateToolbarContents}
            record={record}
          />
        )}
        renderHoverActions={record => (
          <InlineTableActions
            getItemsToRender={generateToolbarContents}
            record={record}
          />
        )}
      >
        <DataListURLTable<
          keyof BeeswaxCampaignTableRecord,
          BeeswaxCampaignTableRecord
        >
          pagination={false}
          loading={isLoading}
          dataSource={beeswaxCampaigns}
          columns={columns}
          scroll={{ y: windowInnerHeight - 245 }}
          size="small"
          type="normal"
          fieldKeys={fieldKeys}
          rowSelection={{
            getCheckboxProps: getCheckboxProps,
            columnWidth: 120,
          }}
          expandable={{
            expandIconColumnIndex: 0,
            expandIcon: props => (
              <ExpandIcon style={getExpandIconStyle(props.record)} {...props} />
            ),
          }}
        />
      </TableActionsContextProvider>
    </div>
  );
};

export default Table;
