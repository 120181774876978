import { useDesignStudioEditor } from "../designStudioLayout/designStudioTable/hooks/useDesignStudioEditor";
import { EditorDrawerContent } from "./editorDrawer/EditorDrawerContent";
import { Drawer } from "@mui/material";

export const EditorDrawer = () => {
  const { assetIds, setEditor } = useDesignStudioEditor();

  return (
    <Drawer
      open={!!assetIds?.length}
      disableEscapeKeyDown
      anchor="right"
      PaperProps={{
        sx: { width: 1, height: 1 },
      }}
      onClose={() => setEditor(null)}
    >
      <EditorDrawerContent />
    </Drawer>
  );
};
