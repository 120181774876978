import { last } from "lodash";
import { appendUrlPrefix } from "./appendUrlPrefix";
import { fontThumbSrc } from "./images/fontThumbSrc";
import { zipFileImage } from "./images/zipFileImage";
import { audioFileImage } from "./images/audioFileImage";
import { defaultPsdImage } from "./images/defaultPsdImage";
import { htmlFileImage } from "./images/htmlFileImage";
import {
  isAudioType,
  isHTMLType,
  isPhotoshopType,
  isValidAssetTypeToPreview,
  isZipType,
} from "./utils.filetype";

export const getCAMThumbnail = (asset: WDAsset, pos = 3) => {
  const { thumbnailurls = [] } = asset;
  const isValidAssetType = isValidAssetTypeToPreview(asset);
  const { url, ...rest } = thumbnailurls[pos] ?? last(thumbnailurls);

  const getImageUrl = () => {
    if (isValidAssetType) {
      return url;
    }

    if (isZipType(asset.filetype)) {
      return zipFileImage;
    }

    if (isAudioType(asset.filetype)) {
      return audioFileImage;
    }

    if (isPhotoshopType(asset.filetype)) {
      return defaultPsdImage;
    }

    if (isHTMLType(asset.filetype)) {
      return htmlFileImage;
    }

    return fontThumbSrc;
  };

  return {
    ...rest,
    url: appendUrlPrefix(getImageUrl()),
  };
};
