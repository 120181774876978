import { observer } from "mobx-react-lite";
import { TextField } from "@mui/material";
import { ColorPickerPopover } from "./ColorPickerPopover";
import { StackContainer } from "../containers/StackContainer";
import { ImageLayerElement } from "screens/designStudio/designStudioV2/types";
import { ColorResult } from "react-color";
import LineWeightRoundedIcon from "@mui/icons-material/LineWeightRounded";

type BorderControlProps = {
  element: ImageLayerElement;
};

const Border = observer(({ element }: BorderControlProps) => {
  const handleBorderColorChange = (color: ColorResult) => {
    element.set({ borderColor: color.hex });
  };

  const handleBorderWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const width = parseInt(e.target.value, 10);
    element.set({ borderSize: isNaN(width) ? 1 : width });
  };

  return (
    <StackContainer>
      <ColorPickerPopover
        color={element?.borderColor || "#000000"}
        onChange={handleBorderColorChange}
      />
      <TextField
        type="number"
        size="small"
        label="Weight"
        value={element?.borderSize || 0}
        onChange={handleBorderWidthChange}
        sx={{ width: 95 }}
        InputProps={{
          startAdornment: (
            <LineWeightRoundedIcon
              fontSize="small"
              sx={{
                color: "action.active",
                mr: 1,
              }}
            />
          ),
        }}
      />
    </StackContainer>
  );
});

export default Border;
