import React from "react";
import styles from "./StatusDescription.module.scss";

interface Props {
  isDataExportCompleted: boolean;
  exportingText: string;
  children: React.ReactNode;
}

const StatusDescription = ({
  isDataExportCompleted,
  exportingText,
  children,
}: Props) => {
  return (
    <>
      {isDataExportCompleted ? (
        children
      ) : (
        <div className={styles.statusDescription}>{exportingText}</div>
      )}
    </>
  );
};

export default StatusDescription;
