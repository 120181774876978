import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const BeeswaxLogoSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 121 108"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M37.5969 0.498444H29.1969C28.6969 0.498444 28.1969 0.798447 27.8969 1.29845L0.396875 53.1985C0.096875 53.7985 0.096875 54.5985 0.396875 55.1985L27.8969 107.098C28.1969 107.598 28.6969 107.898 29.1969 107.898H37.5969C38.6969 107.898 39.3969 106.698 38.8969 105.698L11.9969 54.9984C11.6969 54.4984 11.6969 53.7984 11.9969 53.2984L38.8969 2.69844C39.3969 1.59844 38.6969 0.498444 37.5969 0.498444ZM83.0969 0.498444C81.9969 0.498444 81.2968 1.69844 81.7968 2.69844L108.697 53.2984C108.997 53.7984 108.997 54.4984 108.697 54.9984L81.7968 105.598C81.2968 106.598 81.9969 107.798 83.0969 107.798H91.4968C91.9968 107.798 92.4968 107.498 92.7968 106.998L120.297 55.0984C120.597 54.4984 120.597 53.6984 120.297 53.0984L92.7968 1.19844C92.4968 0.698441 91.9968 0.398438 91.4968 0.398438H83.0969M81.0969 26.0984C81.3969 26.0984 81.6968 26.2984 81.7968 26.4984L86.8969 35.9984C87.1969 36.5984 86.7969 37.1985 86.1969 37.1985H34.6969C34.0969 37.1985 33.6968 36.4984 33.9968 35.9984L39.0969 26.4984C39.1969 26.1984 39.4968 26.0984 39.7968 26.0984H81.0969ZM95.8969 53.2984C96.1969 53.7984 96.1969 54.4984 95.8969 54.9984L93.5969 59.2984C93.4969 59.5984 93.1969 59.7984 92.8969 59.7984H27.6969C27.3969 59.7984 27.0969 59.5984 26.9969 59.2984L24.6969 54.9984C24.3969 54.4984 24.3969 53.7984 24.6969 53.2984L26.9969 48.9984C27.0969 48.6984 27.3969 48.4984 27.6969 48.4984H92.8969C93.1969 48.4984 93.4969 48.6984 93.5969 48.9984L95.8969 53.2984ZM39.5969 82.2984C39.2969 82.2984 38.9969 82.0984 38.8969 81.8984L33.7968 72.3984C33.4968 71.7984 33.8968 71.1985 34.4968 71.1985H85.9968C86.5968 71.1985 86.9969 71.8984 86.6969 72.3984L81.5969 81.8984C81.4969 82.1984 81.1969 82.2984 80.8969 82.2984H39.5969ZM67.6969 99.0984C67.9969 98.7984 67.9969 98.1984 67.6969 97.8984L60.9968 91.1985C60.6968 90.8985 60.0968 90.8985 59.7968 91.1985L53.0969 97.8984C52.7969 98.1984 52.7969 98.7984 53.0969 99.0984L59.7968 105.798C60.0968 106.098 60.6968 106.098 60.9968 105.798L67.6969 99.0984Z" />
  </svg>
);

const BeeswaxLogoIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={BeeswaxLogoSvg} {...props} />
);

export default BeeswaxLogoIcon;
