import { ExportDestination } from "../types";

export const isFinalExportStep = (
  currentStep: number,
  exportDestination: ExportDestination | null,
): boolean => {
  if (!exportDestination) return false;

  switch (currentStep) {
    case 0:
      return exportDestination === ExportDestination.CSVDownload;
    case 1:
      return [
        ExportDestination.GoogleSpreadsheet,
        ExportDestination.Portal,
      ].includes(exportDestination);
    case 2:
      return exportDestination === ExportDestination.AdLibrary;
    default:
      return false;
  }
};
