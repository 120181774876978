import { Tabs, Row } from "antd";
import { ICard } from "shared/types/adLibrary";
import { MultiUploadSizing } from "shared/types/uploadManagement";
import AdCarouselCard from "../AdCarouselCard";
import styles from "./CarouselCardCollapse.module.scss";

type CarouselCardCollapseProps = {
  cards: ICard[];
  currentSlide: number;
  setCurrentSlide: (slide: number) => void;
  tabBarExtraContent?: React.ReactNode;
  shouldDisplayErrors: boolean;
  patchCard: (
    index: number,
    newValues: Partial<ICard>,
    updatedCards?: ICard[],
  ) => void;
  type: MultiUploadSizing;
};

export const CarouselCardCollapse = ({
  cards,
  currentSlide,
  setCurrentSlide,
  tabBarExtraContent,
  patchCard,
  shouldDisplayErrors,
  type,
}: CarouselCardCollapseProps) => {
  const { TabPane } = Tabs;
  const getTabPaneLabel = (i: number) => i + 1;

  const isMultiPlacementCarousel = cards.some(
    c => c?.additionalPlacements?.[0]?.thumbnail,
  );

  return (
    <Row className={styles.tabsRow} data-cy="visual-controls">
      <Tabs
        activeKey={currentSlide.toString()}
        onChange={value => setCurrentSlide(parseInt(value))}
        tabBarStyle={{ margin: "unset" }}
        tabBarGutter={8}
        className={styles.tabs}
        tabBarExtraContent={tabBarExtraContent}
      >
        {cards.map((_, index) => (
          <TabPane
            key={index}
            tabKey={index.toString()}
            tab={getTabPaneLabel(index)}
            forceRender
          >
            <div data-cy={`card-${getTabPaneLabel(index)}-${type}`}>
              <AdCarouselCard
                patchCard={patchCard}
                card={cards[index]}
                index={index}
                shouldDisplayErrors={shouldDisplayErrors}
                type={type}
                isMultiPlacementCarousel={isMultiPlacementCarousel}
              />
            </div>
          </TabPane>
        ))}
      </Tabs>
    </Row>
  );
};
