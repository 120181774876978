import {
  useDeleteAgentProfiles,
  useIsMutatingAgentProfile,
} from "shared/hooks/salesEnablement/useAgentProfiles";
import { Button, Space, notification } from "antd";
import { agentProfileFormId } from "./AgentProfileDrawerForm";

type Props = {
  agentId: string | undefined;
  onClose: () => void;
};

const AgentProfileFooter = ({ onClose, agentId }: Props) => {
  const { mutate: deleteAgentProfiles, isLoading: isDeleting } =
    useDeleteAgentProfiles();
  const isMutating = !!useIsMutatingAgentProfile();

  return (
    <>
      {agentId && (
        <Button
          loading={isDeleting}
          danger
          onClick={() => {
            deleteAgentProfiles([agentId], {
              onSuccess: onClose,
              onError: error => {
                notification.error({
                  message: error.message ?? "Error deleting agent",
                });
              },
            });
          }}
        >
          Delete
        </Button>
      )}
      <Space
        style={{
          marginLeft: "auto",
        }}
      >
        <Button onClick={onClose}>Cancel</Button>
        <Button
          type="primary"
          htmlType="submit"
          form={agentProfileFormId}
          loading={isMutating}
        >
          Save
        </Button>
      </Space>
    </>
  );
};

export default AgentProfileFooter;
