import { Stack } from "@mui/material";
import { observer } from "mobx-react-lite";
import {
  FigureLayerElement,
  TextLayerElement,
} from "screens/designStudio/designStudioV2/types";
import { ColorPickerPopover } from "screens/designStudio/designStudioV2/editorDrawer/editorDrawerContent/polotnoEditor/editorTopbar/layerEffects/ColorPickerPopover";
import { ColorResult } from "react-color";

type FillColorEditorProps = {
  elements: (TextLayerElement | FigureLayerElement)[];
};

export const FillColorEditor = observer(
  ({ elements }: FillColorEditorProps) => {
    const [layer] = elements;

    const handleColorChange = (color: ColorResult) => {
      elements.forEach(element => {
        element.set({ fill: color.hex });
      });
    };

    return (
      <Stack position="relative">
        <ColorPickerPopover
          color={layer?.fill || "#000000"}
          onChange={handleColorChange}
        />
      </Stack>
    );
  },
);
