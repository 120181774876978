import { useEffect, useState } from "react";
import {
  TCanvasAction,
  TCanvasActionType,
} from "screens/designStudio/editor/canvasContainer/Canvas";
import { FabricEvent } from "screens/designStudio/editor/canvasContainer/canvas.utils/EventHandlers";
import {
  getFontPropertyValue,
  getHighlightedFontPropertyValue,
} from "../FontProperty.utils";
import { getPreviousValue2d } from "utils/helpers.array";

type Args = {
  textbox?: fabric.Textbox;
  canvasAction?: TCanvasAction;
};

export default (args: Args) => {
  const { textbox, canvasAction } = args;
  const [fontName, setFontName] = useState<string>(
    getFontPropertyValue(textbox, "fontFamily"),
  );
  const [fontSize, setFontSize] = useState<number | string>(
    getFontPropertyValue(textbox, "fontSize"),
  );

  useEffect(() => {
    if (!textbox || textbox.type !== "textbox") return;
    const allowedCanvasActions: TCanvasActionType[] = [
      "objectSelected",
      FabricEvent.TEXT_SELECTION_CHANGED,
    ];
    const okToProceed =
      canvasAction && allowedCanvasActions.includes(canvasAction?.action.type);
    if (!okToProceed) return;

    const highlightedStyles = textbox.getSelectionStyles();

    const isTextboxSelected = canvasAction?.action.type === "objectSelected";
    const isTextHighlighted = highlightedStyles.length > 0;

    if (isTextHighlighted) {
      setFontName(getHighlightedFontPropertyValue(textbox, "fontFamily"));
      const possibleFontSize = getHighlightedFontPropertyValue(
        textbox,
        "fontSize",
      );

      setFontSize(possibleFontSize);

      return;
    }

    if (isTextboxSelected) {
      setFontName(getFontPropertyValue(textbox, "fontFamily"));
      const possibleFontSize = getFontPropertyValue(textbox, "fontSize");

      setFontSize(possibleFontSize);

      return;
    }

    // This fallback is used when textbox is being edited but there is no selected text
    const { styles } = textbox;
    const { lineIndex, charIndex } = textbox.get2DCursorLocation();
    // There is a specific instance where styles is 2 lines but not a 2d array to match the fontFamily
    // selectionStart is used to get the current cursor position in the flat array
    const { selectionStart = 0 } = textbox;

    const charStyle =
      styles?.[lineIndex]?.[charIndex] ||
      getPreviousValue2d(styles, lineIndex, charIndex) ||
      styles?.[0]?.[selectionStart];

    const { fontFamily } = charStyle || styles || {};
    setFontName(prev => fontFamily || textbox.fontFamily || prev);

    const { fontSize } = charStyle || styles || {};
    setFontSize(prev => fontSize || textbox.fontSize || prev);
  }, [textbox, canvasAction]);

  return {
    fontName,
    fontSize,
    setFontSize,
    setFontName,
  };
};
