import {
  FullscreenExitOutlined,
  FullscreenOutlined,
  PauseCircleFilled,
  PlayCircleFilled,
} from "@ant-design/icons";
import {
  RenderFullscreenButton,
  RenderPlayPauseButton,
} from "@remotion/player";
import styles from "./VideoPlayer.module.scss";
import { SpinnerLoading } from "screens/assetExporter/feedConfiguration/SpinnerLoading";

export const renderPlayPauseButton: RenderPlayPauseButton = ({
  playing,
  isBuffering,
}) => {
  return renderPlayPauseButtonWithStyle(
    playing,
    isBuffering,
    styles.controlButton,
  );
};

export const renderPlayPauseButtonGrey: RenderPlayPauseButton = ({
  playing,
  isBuffering,
}) => {
  return renderPlayPauseButtonWithStyle(
    playing,
    isBuffering,
    styles.controlButtonGrey,
  );
};

const renderPlayPauseButtonWithStyle = (
  playing: boolean,
  isBuffering: boolean,
  style: string,
) => {
  if (playing && isBuffering) {
    return <SpinnerLoading />;
  }

  if (playing) {
    return <PauseCircleFilled className={style} />;
  }

  return <PlayCircleFilled className={style} />;
};

export const renderFullscreenButton: RenderFullscreenButton = ({
  isFullscreen,
}) => {
  if (isFullscreen) {
    return <FullscreenExitOutlined className={styles.controlButton} />;
  }

  return <FullscreenOutlined className={styles.controlButton} />;
};
