import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { salesEnablementTheme } from "theme/salesEnablement";

export const onConfirmPublish = ({ onOk }: { onOk: () => void }) => {
  return Modal.confirm({
    title: <b>Uneditable template fields after publish</b>,
    icon: (
      <ExclamationCircleOutlined
        style={{ color: salesEnablementTheme?.primaryColor }}
      />
    ),
    content: `WARNING: Thoroughly review the template before continuing - once published certain configurations will be disabled for edit.`,
    okText: "Confirm",
    cancelText: "Cancel",
    okButtonProps: {
      type: "primary",
      danger: false,
      style: {
        backgroundColor: salesEnablementTheme?.primaryColor,
        borderColor: salesEnablementTheme?.primaryColor,
      },
    },
    onOk,
  });
};

export const onConfirmClose = ({
  onOk,
  content,
}: {
  onOk: () => void;
  content: React.ReactNode;
}) => {
  return Modal.confirm({
    title: <b>Are you sure you want to close without saving?</b>,
    icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
    content,
    okText: "Close",
    cancelText: "Cancel",
    okButtonProps: { type: "primary", danger: true },
    onOk,
  });
};

export const onConfirmDeleteTab = ({
  onOk,
  label,
}: {
  onOk: () => void;
  label: string;
}) => {
  return Modal.confirm({
    title: <b>Are you sure you want to remove {label} from this template?</b>,
    icon: (
      <ExclamationCircleOutlined style={{ color: "var(--primary-color)" }} />
    ),
    okText: "Remove",
    cancelText: "Cancel",
    okButtonProps: { type: "primary", danger: true },
    onOk,
  });
};
