import { Button, Tooltip } from "antd";
import { Input } from "antd";
import { useToggle } from "shared/hooks/useToggle";
import ColumnsOutlined from "shared/icons/ColumnsOutlined";
import { IAdIssue } from "shared/types/adLibrary";
import ErrorsToggle from "./adImportToolbar/ErrorsToggle";
import IssueList from "./adImportToolbar/IssueList";
import NoIssues from "./adImportToolbar/NoIssues";

const Search = Input.Search;

interface Props {
  issues: IAdIssue[];
  onSearch: (value: string) => void;
  searchValue: string;
  setDisplayColumn: (value: boolean) => void;
}

const AdImportToolbar = ({
  issues,
  onSearch,
  searchValue,
  setDisplayColumn,
}: Props) => {
  const [displayIssues, toggleDisplayIssues] = useToggle();
  const isError = (issue: IAdIssue) => issue.type === "error";
  const numberOfErrors = issues.filter(isError).length;

  return (
    <div>
      <div style={{ display: "flex", marginBottom: 12 }}>
        <Search
          allowClear={true}
          style={{ width: "100%", marginRight: 8 }}
          placeholder="Search"
          value={searchValue}
          onChange={value => onSearch(value.target.value)}
        />
        {numberOfErrors > 0 ? (
          <ErrorsToggle
            displayIssues={displayIssues}
            toggleDisplayIssues={toggleDisplayIssues}
            numberOfErrors={numberOfErrors}
          />
        ) : (
          <NoIssues />
        )}
        <Tooltip title={"Select columns to display"}>
          <Button
            icon={<ColumnsOutlined />}
            onClick={() => setDisplayColumn(true)}
          />
        </Tooltip>
      </div>
      {issues.length > 0 && displayIssues && <IssueList issues={issues} />}
    </div>
  );
};

export default AdImportToolbar;
