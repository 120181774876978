import { differenceBy } from "lodash";
import { useQueryClient, type InfiniteData } from "react-query";
import { IGetAdsResponseData, IAd } from "shared/types/adLibrary";
import produce from "immer";

type SetAdsQueryDataArgs = {
  ads?: IAd[];
  adId?: string;
  operation: "mutate" | "delete";
};

const useSetAdsQueryData = () => {
  const queryClient = useQueryClient();
  const adsRes: InfiniteData<IGetAdsResponseData> | undefined =
    queryClient.getQueryData("ads");

  return async ({ ads, adId, operation }: SetAdsQueryDataArgs) => {
    const willDelete = !!adId && operation === "delete";

    // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
    await queryClient.cancelQueries("ads");

    // Optimistically update the ad
    queryClient.setQueryData<InfiniteData<IGetAdsResponseData> | undefined>(
      "ads",
      prevAdRes => {
        if (!prevAdRes) return;
        const prevAds = prevAdRes.pages.flatMap(page => page.ads);
        const newAds = differenceBy(ads, prevAds, "id");

        const updateOrDeleteAds = (prevPageAds: IAd[]) => {
          return willDelete
            ? prevAds.filter(ad => ad.id !== adId)
            : prevPageAds.map(
                originalAd =>
                  ads?.find(ad => ad.id === originalAd.id) ?? originalAd,
              );
        };

        const newPages = produce(prevAdRes.pages, draft => {
          draft.forEach(page => {
            page.ads = updateOrDeleteAds(page.ads);
          });

          if (newAds.length) {
            draft[draft.length - 1].ads.push(...newAds);
          }
        });

        return {
          pages: newPages,
          pageParams: prevAdRes.pageParams,
        };
      },
    );

    return adsRes;
  };
};

export default useSetAdsQueryData;
