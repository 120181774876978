import { Button, Drawer } from "antd";
import { Children, ReactElement, cloneElement } from "react";
import { ButtonGroup } from "../ButtonGroup";
import { SelectFolder } from "../SelectFolder";
import { FormMeta } from "./FormMeta";
import { BlobInput } from "./useUpload";

import styles from "./CAMUpload.module.scss";
import { useCAMUpload } from "./useCAMUpload";

type Props = {
  children: ReactElement;
  files: string[] | BlobInput[];
  onComplete?: () => void;
};

export const CAMUpload = ({ children, files, onComplete }: Props) => {
  const {
    open,
    uploading,
    folderId,
    setMeta,
    setFolderId,
    openDrawer,
    closeDrawer,
    handleClick,
  } = useCAMUpload({ files, onComplete });

  return (
    <>
      {Children.map(children, child =>
        cloneElement(child, { onClick: openDrawer }),
      )}
      <Drawer
        destroyOnClose
        title="Upload Files to CAM"
        width={960}
        visible={open}
        closable={false}
        onClose={closeDrawer}
        footer={
          <ButtonGroup>
            <Button key="cancel" onClick={closeDrawer}>
              Close
            </Button>
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              disabled={!folderId}
              loading={uploading}
              onClick={handleClick}
            >
              Upload
            </Button>
          </ButtonGroup>
        }
      >
        <div className={styles.body}>
          <div>
            <header>
              <h3>Metadata (Optional)</h3>
              <p>Will be used for all images.</p>
            </header>
            <FormMeta onChange={setMeta} />
          </div>
          <div>
            <header>
              <h3>Folder to save</h3>
            </header>
            <SelectFolder
              onSelect={keys => {
                setFolderId(String(keys[0]));
              }}
            />
          </div>
        </div>
      </Drawer>
    </>
  );
};
