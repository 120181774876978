import { Button, Drawer, Space, message } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DEFAULT_DURATION } from "screens/assetExporter/feedConfigurationV2/shared/constants";
import { useAssetBatchesValueMappingContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesValueMappingContext";
import { FiltersType } from "shared/components/TableFiltersSection";
import { videoCompositionEnabled } from "shared/constants/assetExporter";
import { useFetchTemplates } from "shared/hooks/useFetchTemplates";
import { useAssetBatchesContext } from "../../../shared/contexts/AssetBatchesContext";
import styles from "./TemplateDrawer.module.scss";
import Header from "./templateDrawer/Header";
import TemplateList from "./templateDrawer/TemplateList";
import { getFilterOptions } from "./templateDrawer/TemplateList.utils";

type TemplateDrawerProps = {
  open?: boolean;
  onClose?: () => void;
};

export const TemplateDrawer = ({ open, onClose }: TemplateDrawerProps) => {
  const {
    selectedTemplate,
    compositions,
    setCompositions,
    setShowMediaAlert,
    setShowTextAlert,
    setCompositionToReplace,
    setSelectedTemplate,
    compositionToReplace,
    artboard,
    defaultTemplateFilters,
  } = useAssetBatchesContext();
  const { templates: orgTemplates, isFetching } = useFetchTemplates({
    ...(artboard && {
      artboardSize: { width: artboard.width, height: artboard.height },
    }),
  });
  const templates = useMemo(
    () =>
      orgTemplates.filter(
        template => template?.type !== "html" || !!template?.htmlFileUrl,
      ),
    [orgTemplates],
  );

  const filterOptions = useMemo(() => getFilterOptions(templates), [templates]);
  const { saveTemplate } = useAssetBatchesValueMappingContext();

  const [filters, setFilters] = useState<Partial<FiltersType>>({
    ...defaultTemplateFilters,
  });

  useEffect(() => {
    setFilters({
      ...defaultTemplateFilters,
      ...(compositions.length > 0 &&
        templates[0]?.type !== "html" &&
        (!compositionToReplace ||
          (compositionToReplace && compositions.length > 1)) && {
          type: filterOptions?.type?.filter(option => option !== "html"),
        }),
    });
  }, [
    compositions,
    compositionToReplace,
    defaultTemplateFilters,
    filterOptions,
    templates,
  ]);

  useEffect(() => {
    message.config({ maxCount: 10 });
  }, []);

  const handleClose = useCallback(() => {
    if (videoCompositionEnabled) {
      setCompositionToReplace(undefined);
      setSelectedTemplate(undefined);
    }
    onClose?.();
  }, [onClose, setCompositionToReplace, setSelectedTemplate]);

  return (
    <Drawer
      closable={false}
      destroyOnClose
      className={styles.drawer}
      placement="right"
      visible={open}
      width="calc(100vw - 48px)"
      bodyStyle={{ backgroundColor: "#f5f5f5", padding: 0 }}
      title={<Header />}
      footer={
        <Space className={styles.drawerFooter}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type="primary"
            onClick={() => {
              if (!selectedTemplate) return;
              setShowMediaAlert(true);
              setShowTextAlert(true);
              saveTemplate(selectedTemplate, compositions?.length ?? 0);
              setCompositions(oldCompositions => {
                return oldCompositions.length > 1
                  ? oldCompositions.map(comp => {
                      if (comp.duration === 0)
                        return { ...comp, duration: DEFAULT_DURATION };
                      return comp;
                    })
                  : oldCompositions;
              });
              onClose?.();
            }}
            data-cy="feed-next-btn"
            disabled={!selectedTemplate}
          >
            {"Add selected file"}
          </Button>
        </Space>
      }
    >
      <div style={{ width: "100%", height: "100%" }}>
        <TemplateList
          filters={filters}
          setFilters={setFilters}
          filterOptions={filterOptions}
          isFetching={isFetching}
          templates={templates}
        />
      </div>
    </Drawer>
  );
};
