import LockRoundedIcon from "@mui/icons-material/LockRounded";
import LockOpenRoundedIcon from "@mui/icons-material/LockOpenRounded";
import { IconButton, Stack } from "@mui/material";
import { observer } from "mobx-react-lite";
import { StoreType } from "polotno/model/store";
import { useElements } from "../editorTabs/tabPanel/useElements";
import { Opacity } from "./layerEffects/Opacity";

type LayerControlsProps = {
  store: StoreType;
};

export const LayerControls = observer(({ store }: LayerControlsProps) => {
  const { toggleLockElements } = useElements(store);
  const { selectedElements } = store;
  if (!store.selectedElements.length) return null;

  return (
    <Stack direction="row" sx={{ height: 1 }} alignItems="center" spacing={1}>
      {selectedElements[0].contentEditable ? (
        <>
          <Opacity elements={selectedElements} />
          <IconButton size="small" onClick={toggleLockElements}>
            <LockOpenRoundedIcon fontSize="small" />
          </IconButton>
        </>
      ) : (
        <IconButton size="small" onClick={toggleLockElements}>
          <LockRoundedIcon fontSize="small" />
        </IconButton>
      )}
    </Stack>
  );
});
