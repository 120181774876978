import { Dispatch, SetStateAction } from "react";
import { isEqual } from "lodash";
import {
  FeedTblRow,
  STEP_CREATE_BATCH,
  TAssetBatchRequest,
  TTemplateComposition,
} from "shared/types/assetExporter";
import styles from "./AssetBatchDrawer.module.scss";
import { ApplyCondition } from "./assetBatchDrawer/ApplyCondition";
import { DynamicText } from "./assetBatchDrawer/DynamicText";
import { ManageMedia } from "./assetBatchDrawer/ManageMedia";
import { useAssetBatchesContext } from "./shared/contexts/AssetBatchesContext";
import {
  isTemplateComposition,
  parseConditions,
} from "./shared/contexts/AssetBatchesContext.utils";
import { useFeedContext } from "./shared/contexts/FeedContext";
import { AssetBatchDrawerBase } from "./AssetBatchDrawerBase";

interface AssetBatchDrawerProps {
  onSubmit: (assetBatch: TAssetBatchRequest) => void;
  onClose: (showUndoMessage: boolean, batchName: string) => void;
  setFilteredRows: Dispatch<SetStateAction<FeedTblRow[]>>;
}

export const AssetBatchDrawer = ({
  onSubmit,
  onClose,
  setFilteredRows,
}: AssetBatchDrawerProps) => {
  const {
    batchName,
    ruleConditions,
    compositions,
    isAllValid,
    isFirstStepValid,
    isSecondStepValid,
    currentStep,
  } = useAssetBatchesContext();

  const { editingAssetBatch } = useFeedContext();

  const isLastStep = () => currentStep === STEP_CREATE_BATCH.THREE_TEXT;

  const edited = () => {
    if (!editingAssetBatch) return false;

    const areSameNames = editingAssetBatch?.name === batchName;
    const areSameConditions = isEqual(
      parseConditions(editingAssetBatch.conditions),
      ruleConditions,
    );

    const areSameVariables = compositions.every(
      composition =>
        !isTemplateComposition(composition) ||
        isEqual(
          composition.variables,
          (
            editingAssetBatch?.compositions.find(
              com =>
                com.compositionId === composition.compositionId &&
                isTemplateComposition(com),
            ) as TTemplateComposition | undefined
          )?.variables,
        ),
    );

    if (areSameNames && areSameConditions && areSameVariables) {
      return false;
    }

    return true;
  };

  const disabledSaveForFirstStep = !isFirstStepValid || !edited();
  const disabledSaveForSecondStep = !isSecondStepValid || !edited();
  const disabledSaveForLastStep = !isAllValid || !edited();

  const isFirstStep = currentStep === STEP_CREATE_BATCH.ONE_CONDITION;
  const isSecondStep = currentStep === STEP_CREATE_BATCH.TWO_MEDIA;

  return (
    <AssetBatchDrawerBase
      currentStepConst={STEP_CREATE_BATCH.ONE_CONDITION}
      createDisabled={
        (isFirstStep && !isFirstStepValid) ||
        (isSecondStep && !isSecondStepValid) ||
        (isLastStep() && !isAllValid)
      }
      editDisabled={
        (isFirstStep && !isFirstStepValid) ||
        (isSecondStep && !isSecondStepValid)
      }
      editDisabledLastStep={
        (isFirstStep && disabledSaveForFirstStep) ||
        (isSecondStep && disabledSaveForSecondStep) ||
        (isLastStep() && disabledSaveForLastStep)
      }
      steps={
        <div className={styles.stepsContainer}>
          {currentStep === STEP_CREATE_BATCH.ONE_CONDITION && (
            <ApplyCondition setFilteredRows={setFilteredRows} />
          )}
          {currentStep === STEP_CREATE_BATCH.TWO_MEDIA && <ManageMedia />}
          {currentStep === STEP_CREATE_BATCH.THREE_TEXT && <DynamicText />}
        </div>
      }
      onSubmit={onSubmit}
      onClose={onClose}
      setFilteredRows={setFilteredRows}
      isLastStep={isLastStep}
    />
  );
};
