import { DataListURLProvider } from "shared/components/dataListURL/dataListURLContext";
import { fields } from "./products/fields";
import { useProducts } from "shared/hooks/admin/useProducts";
import Table from "./products/Table";
import { Outlet, useParams } from "react-router-dom";
import { ProductDrawer } from "./products/ProductDrawer";

export const Products = () => {
  const { isLoading, productsTable } = useProducts();
  const { productId } = useParams();

  return (
    <DataListURLProvider fields={fields}>
      <Table productsTable={productsTable} loading={isLoading} />
      <Outlet />
      <ProductDrawer
        isVisible={!!productId}
        productId={productId !== "new" ? productId : undefined}
      />
    </DataListURLProvider>
  );
};
