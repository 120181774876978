import { IArtboard } from "shared/types/designStudio";

export const isArtboardRatioValid = (
  artboards: IArtboard[] | undefined,
  artboardName: string | undefined,
) => {
  if (!artboards || !artboardName) return false;

  const currentArtboard = artboards.find(
    artboard => artboard.name === artboardName,
  );

  if (!currentArtboard) return false;

  const aspectRatio = currentArtboard.width / currentArtboard.height;
  // Ad Library requires 1:1 aspect ratio for in-feed images and 9:16 aspect ratio for story images
  return [1, 9 / 16].includes(aspectRatio);
};
