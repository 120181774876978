import { useContext, createContext, ReactNode, useState } from "react";
import {
  MarketingMaterial,
  MarketingMaterialTableItem,
  MktMatField,
  ProductField,
} from "shared/types/marketingMaterials";
import { useFetchDisclosures } from "shared/hooks/admin/useDisclosures";
import { useGetInitialValues } from "../marketingMaterialDrawer/hooks/useGetInitialValues";
import { useDebounceCallback } from "screens/designStudio/hooks/useDebounce";
import { useSelectedMarketingMaterial } from "./hooks/useSelectedMarketingMaterial";
import {
  DisclosureField,
  Language,
  Template,
  TemplateFile,
} from "shared/types/salesEnablement";
import { sortBy, uniq } from "lodash";
import { findMatchingCondition } from "utils/helpers.salesEnablement";
import { useFormInstance, FormProvider } from "shared/hooks/useFormInstance";

export const useMaterialFormInstance =
  useFormInstance<MarketingMaterialTableItem>;
export const MaterialFormProvider = FormProvider<MarketingMaterialTableItem>;

type MarketingMaterialsContextType = {
  material: MarketingMaterialTableItem | undefined;
  template: Template;
  templateFile: TemplateFile | undefined;
  language: Language;
  isLoading: boolean;
  previewMaterial?: Partial<MarketingMaterial>;
  initialValues: Partial<MarketingMaterial>;
  hasProductLayer: boolean;
  disclosureFieldId: string | undefined;
  setLanguage: React.Dispatch<React.SetStateAction<Language>>;
  onValuesChange?: (
    changedValues: Partial<MarketingMaterialTableItem>,
    allValues: MarketingMaterialTableItem,
  ) => void;
  isFormValid: () => Promise<boolean>;
};

const Context = createContext<MarketingMaterialsContextType | null>(null);

export const useMarketingMaterialsContext = () => {
  const ctx = useContext(Context);

  if (!ctx) {
    throw new Error(
      "useMarketingMaterialsContext must be used within a MarketingMaterialsContext",
    );
  }

  return ctx;
};

export const MarketingMaterialsContext = ({
  materialId,
  templateId,
  children,
}: {
  materialId?: string;
  templateId?: string;
  children: ReactNode;
}) => {
  const form = useMaterialFormInstance();

  const { material, template, templateFile, isLoading, language, setLanguage } =
    useSelectedMarketingMaterial({
      materialId,
      templateId,
    });
  const { initialValues, disclosureFieldId, hasProductLayer } =
    useGetInitialValues(template, material, language);
  const [previewMaterial, setPreviewMaterial] = useState<
    Partial<MarketingMaterial> | undefined
  >(initialValues);

  const setMat = useDebounceCallback(
    setPreviewMaterial,
    templateFile.type === "html" ? 0 : 3000,
    {
      trailing: true,
    },
  );

  const { data: disclosures } = useFetchDisclosures();

  const onDisclosuresFiltered = async (
    disclosuresResult: DisclosureField[] | undefined,
    currentFieldsValue: MarketingMaterialTableItem,
  ) => {
    if (!disclosuresResult) return;
    const fieldsWithDisclosures = buildDisclosureField(
      disclosureFieldId,
      disclosuresResult,
      currentFieldsValue,
    );
    if (!fieldsWithDisclosures) return;
    setMat(fieldsWithDisclosures);
    form.setFieldsValue(fieldsWithDisclosures);
  };

  const validateProductFields = async (
    currentFieldsValue: MarketingMaterialTableItem,
    changedFieldsValue: Partial<MarketingMaterialTableItem>,
  ) => {
    const isChangingProduct = Object.values(
      changedFieldsValue?.fields ?? {},
    ).some(isProduct);
    if (!isChangingProduct) return;

    const productFields = Object.values(
      currentFieldsValue.fields ?? {},
    ).flatMap(field => {
      if (isProduct(field)) {
        return field.productsData.map((_, idx) => [
          "fields",
          field.id,
          "productsData",
          idx,
          "productId",
        ]);
      }
      return [];
    });
    form.validateFields([...productFields, "productTypeOffer"]);
  };

  const onValuesChange = (
    changedValues: Partial<MarketingMaterialTableItem>,
    allValues: MarketingMaterialTableItem,
  ) => {
    setMat(allValues);
    const newDisclosureFilter = buildDisclosureFilter(changedValues, allValues);
    if (!newDisclosureFilter) return;
    const filteredDisclosuresResult = sortBy(
      disclosures?.flatMap(({ fields, runAllConditions }) =>
        findMatchingCondition(
          fields,
          runAllConditions,
          newDisclosureFilter.locations,
          newDisclosureFilter.productsIds,
          newDisclosureFilter.productsTypes,
        ),
      ) ?? [],
      disclosureResult => disclosureResult.then?.[language]?.length,
    );
    onDisclosuresFiltered(filteredDisclosuresResult, allValues);
    validateProductFields(allValues, changedValues);
  };
  const isFormValid = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (error) {
      return false;
    }
  };
  return (
    <Context.Provider
      value={{
        material,
        template,
        templateFile,
        language,
        isLoading,
        previewMaterial,
        initialValues,
        disclosureFieldId,
        hasProductLayer,
        setLanguage,
        onValuesChange,
        isFormValid,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const buildDisclosureFilter = (
  changedValues: Partial<MarketingMaterialTableItem>,
  allValues: MarketingMaterialTableItem,
) => {
  const allProductFields = Object.values(allValues.fields ?? {}).filter(
    field => field && field["type" as keyof typeof field] === "product",
  ) as ProductField[];
  if (allProductFields.length) {
    const productsInfo = allProductFields.flatMap(product =>
      product.productsData.map(({ productId, productType }) => ({
        productId,
        productType,
      })),
    );
    return {
      locations: allValues.locations ?? [],
      productsIds: uniq(
        productsInfo.flatMap(({ productId }) => productId ?? []),
      ),
      productsTypes: uniq(
        productsInfo.flatMap(({ productType }) => productType ?? []),
      ),
    };
  }
  if ("productTypeOffer" in allValues) {
    return {
      productsTypes: allValues.productTypeOffer ?? [],
      locations: allValues.locations ?? [],
      productsIds: [],
    };
  }
  if ("locations" in changedValues) {
    return {
      locations: changedValues.locations ?? [],
      productsIds: [],
      productsTypes: [],
    };
  }
  return;
};

const buildDisclosureField = (
  disclosureFieldId: string | undefined,
  disclosuresResult: DisclosureField[],
  currentFieldsValue: MarketingMaterialTableItem,
) => {
  if (!disclosureFieldId) return currentFieldsValue;
  const { fields, language: formLanguage } = currentFieldsValue ?? {};
  if (!fields) return;
  if (!disclosureFieldId) return;
  return {
    ...currentFieldsValue,
    fields: {
      ...currentFieldsValue.fields,
      [disclosureFieldId]: disclosuresResult
        ?.map(disclosure => disclosure.then?.[formLanguage] ?? "")
        .join(" "),
    },
  };
};

const isProduct = (field?: MktMatField): field is ProductField =>
  typeof field !== "string" && field?.type === "product";
