import { message } from "antd";
import { useDesignStudioContext } from "screens/designStudio/context/DesignStudioDataProvider";
import { useDesignStudioEditor } from "screens/designStudio/designStudioLayout/designStudioTable/hooks/useDesignStudioEditor";
import { isV3Template } from "screens/designStudio/helpers";
import { PolotnoEditor } from "./editorDrawerContent/PolotnoEditor";
import { useDesignStudioEditorItems } from "screens/designStudio/designStudioLayout/designStudioTable/hooks/useDesignStudioEditorItems";
import { CircularProgress, Box } from "@mui/material";
import { EditorDrawerContextProvider } from "../editorDrawer/EditorDrawerContext";

export const EditorDrawerContent = () => {
  const { isFetchingTemplates } = useDesignStudioContext();
  const { setEditor } = useDesignStudioEditor();
  const { items } = useDesignStudioEditorItems();

  if (isFetchingTemplates && !items.length) {
    return (
      <Box sx={{ width: 1, height: 1, display: "grid", placeItems: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!items.every(isV3Template)) {
    message.error({
      content:
        "Invalid parameters, one or more of the selected items are not compatible with the editor.",
      key: "invalid-parameters",
    });
    setEditor(null, "replace");

    return null;
  }

  return (
    <EditorDrawerContextProvider>
      <PolotnoEditor />
    </EditorDrawerContextProvider>
  );
};
