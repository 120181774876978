const defaultSort = `sortKey=createdAt&sortOrder=descend`;

export const ROUTES = {
  templates: "/sales-enablement/templates",
  popularTemplates: "/sales-enablement/templates?metrics=popular",
  userTemplates: (email: string) =>
    `/sales-enablement/templates?createdBy=${encodeURIComponent(email)}`,
  newestTemplates: `/sales-enablement/templates?status=new`,
  marketingMaterials: "/sales-enablement/my-marketing-materials",
  userMarketingMaterials: `/sales-enablement/my-marketing-materials?${defaultSort}`,
  editMarketingMaterials: (id: string) =>
    `/sales-enablement/my-marketing-materials/${id}`,
  accountMarketingMaterials: (name: string) =>
    `/sales-enablement/my-marketing-materials?account=${name}`,
  products: "/sales-enablement/products",
  disclosures: "/sales-enablement/disclosures",
  accounts: "/sales-enablement/accounts",
  agents: "/sales-enablement/agents",
};

export const MAX_DISPLAY = 5;
