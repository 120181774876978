import { groupBy, intersection, isEmpty } from "lodash";
import { queryClient } from "queryClient";
import { InfiniteData } from "react-query";
import { accumulateOemPages } from "shared/hooks/useFetchOems";
import {
  ElementType,
  IAd,
  IAdIssue,
  IInstantExperience,
} from "shared/types/adLibrary";
import { IGetBrandsResult } from "shared/types/brandManagement";
import { isImage, isVideo } from "utils/adLibrary.validators";
import ViewIssuesButton from "./components/ViewIssuesButton";
import { message } from "antd";
import { AdType } from "../facebookUtils/types";
import { useDestinationURLValidator } from "./hooks/useDestinationURLValidator";
import { useCallback } from "react";

type IssueType = "error" | "warning";
type IssueValue = "empty" | "invalid";

export type Issue = {
  id: string;
  type: IssueType;
  issue: IssueValue;
  field: string;
};

export const componentTypeTextMap: Record<ElementType, string> = {
  CAROUSEL: "Carousel",
  BUTTON: "Button",
  FOOTER: "Button",
  ELEMENT_GROUP: "Product Set",
  PHOTO: "Image",
  VIDEO: "Video",
};

const validateOem = (
  id: string,
  value?: string,
  type: IssueType = "error",
): Issue | undefined => {
  const oemPages = queryClient.getQueryData<
    InfiniteData<IGetBrandsResult | null> | undefined
  >("oems");

  if (!oemPages) {
    queryClient.refetchQueries("oems");
    return undefined;
  }

  const oems = accumulateOemPages(oemPages?.pages);

  if (!oems.some(oem => oem.oem_name === value)) {
    return { type, issue: "invalid", field: "oem", id };
  }
};

const validateType = (
  id: string,
  value?: string,
  type: IssueType = "error",
): Issue | undefined => {
  const validTypes = Object.keys(componentTypeTextMap);

  if (!validTypes.includes(value ?? "")) {
    return { type, issue: "invalid", field: "type", id };
  }
};

const validateEmpty = (
  id: string,
  field: string,
  value?: string,
  type: IssueType = "error",
): Issue | undefined => {
  if (isEmpty(value)) {
    return { type, issue: "empty", field, id };
  }
};

export const getInstantExperiencesIssues = (
  instantExperiences: IInstantExperience[],
): Issue[] => {
  return instantExperiences.reduce((issuesAcc, instantExperience) => {
    const nameIssue = validateEmpty(
      instantExperience.id!,
      "name",
      instantExperience.name,
    );
    const oemIssue =
      validateEmpty(instantExperience.id!, "oem", instantExperience.oem) ||
      validateOem(instantExperience.id!, instantExperience.oem);

    const bodyElements = instantExperience.body_elements ?? [];

    const bodyElementIssues = bodyElements.reduce(
      (prevElementsAcc, bodyElement) => {
        const typeIssue =
          validateEmpty(bodyElement.id, "type", bodyElement.element_type) ||
          validateType(bodyElement.id, bodyElement.element_type);
        const urlIssue =
          (isImage(bodyElement) || isVideo(bodyElement)) &&
          validateEmpty(bodyElement.id, "url", bodyElement.url);
        return [...prevElementsAcc, typeIssue, urlIssue].filter(
          Boolean,
        ) as Issue[];
      },
      [] as Issue[],
    );

    const newIssues = [
      ...issuesAcc,
      nameIssue,
      oemIssue,
      ...bodyElementIssues,
    ].filter(Boolean) as Issue[];

    return newIssues;
  }, [] as Issue[]);
};

export const hasInstantExperienceIssues = (
  instantExperience: IInstantExperience,
  instantExperienceImportIssues: Issue[],
) => {
  const elementIdsWithIssues = instantExperienceImportIssues.map(ie => ie.id);
  const bodyElementIds =
    instantExperience.body_elements?.map(be => be.id) ?? [];

  const hasIssues =
    intersection(elementIdsWithIssues, [
      instantExperience.id,
      ...bodyElementIds,
    ]).length > 0;

  return hasIssues;
};

const issueToAdIssueMap = {
  empty_oem: {
    message: "Brand/OEM is missing",
  },
  empty_url: {
    message: "Media Link is missing",
  },
  empty_type: {
    message: "Component Type is missing",
  },
  invalid_oem: {
    message: "Brand/OEM does not exist in OEM management",
  },
  invalid_type: {
    message: "Component Type is invalid",
  },
};

export type IssueGroup = keyof typeof issueToAdIssueMap;

export function getInstantExperienceToolbarIssues(
  instantExperienceImportIssues: Issue[],
  selectedGroupToView: IssueGroup | undefined,
  toggleInstantExperiencesToView: (
    issueGroup: IssueGroup,
    idsToSelect: string[],
  ) => void,
) {
  const errorInfoIssue: IAdIssue = {
    message:
      "Issues are required fields and must be resolved before completing the import.",
    type: "info",
  };

  const issuesGrouped = groupBy(
    instantExperienceImportIssues,
    ({ issue, field }) => `${issue}_${field}` as IssueGroup,
  );

  const issues: IAdIssue[] = Object.entries(issuesGrouped).map(
    ([group, ieIssues]) => {
      const { message } = issueToAdIssueMap[group as IssueGroup] ?? {
        message: `Unknown issue: ${group}`,
      };

      const elementIdsWithIssues = ieIssues.map(({ id }) => id);

      return {
        message,
        type: "error",
        actions: [
          <ViewIssuesButton
            key={group}
            group={group as IssueGroup}
            elementIdsWithIssues={elementIdsWithIssues}
            toggleInstantExperiencesToView={toggleInstantExperiencesToView}
            selectedGroupToView={selectedGroupToView}
          />,
        ],
      };
    },
  );

  if (!issues.length) {
    return [];
  }

  return [errorInfoIssue, ...issues];
}

export const onlyAllowNumbers = (input: string) => {
  const onlyIntNumbers = input
    ?.split("")
    .every((digit: string) => Number.isInteger(parseInt(digit)));
  !onlyIntNumbers && message.warning("Inputs should be number");
  return onlyIntNumbers;
};

export type AdValidation = {
  isValid: boolean;
  issues: Issue[];
};

const validateAd = (
  ad: IAd,
  isValidDestinationURL: (value: string | undefined) => boolean,
): AdValidation => {
  switch (ad.type) {
    case AdType.Video:
    case AdType.Still: {
      const isValid = isValidDestinationURL(ad.inputParameters?.destinationUrl);
      return {
        isValid,
        issues: isValid
          ? []
          : [
              {
                type: "error",
                issue: "invalid",
                field: "destinationUrl",
                id: ad.id,
              },
            ],
      };
    }
    case AdType.Carousel: {
      const isValid =
        ad.visuals.cards?.every(card =>
          isValidDestinationURL(card.destinationUrl),
        ) ?? true;
      return {
        isValid,
        issues: isValid
          ? []
          : [
              {
                type: "error",
                issue: "invalid",
                field: "destinationUrl",
                id: ad.id,
              },
            ],
      };
    }
    case AdType.InstantExperience:
    case AdType.Collection: {
      const isValid = !isEmpty(ad.visuals.destination?.instantExperienceId);
      return {
        isValid,
        issues: isValid
          ? []
          : [
              {
                type: "error",
                issue: "empty",
                field: "instantExperienceId",
                id: ad.id,
              },
            ],
      };
    }
    default: {
      return {
        isValid: true,
        issues: [],
      };
    }
  }
};

export const useValidateAd = () => {
  const { isValidDestinationURL, isLoadingStores } =
    useDestinationURLValidator();

  const validateAdCallback = useCallback(
    (ad: IAd) => validateAd(ad, isValidDestinationURL),
    [isValidDestinationURL],
  );

  return {
    validateAd: validateAdCallback,
    isLoadingStores,
  };
};

export const getValidationMessage = (issues?: Issue[]) => {
  if (!issues?.length) {
    return;
  }

  const issue = issues[0];

  switch (issue.field) {
    case "destinationUrl": {
      return "Please assign destination URL";
    }
    case "instantExperienceId": {
      return "Please link Everything Ad";
    }
  }
};
