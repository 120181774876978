import { useMemo } from "react";
import { useAuthedAgentFeedInfo } from "./useAgentFeedData";
import { StateKey } from "shared/constants/states";
import { isStateKeyValue } from "utils/validators";
import { AgentFeedInfo } from "shared/types/salesEnablement";
import { dedupe } from "utils/helpers.array";

export const filterLicensedStates = (
  licensedStates?: AgentFeedInfo["licensedStates"],
) =>
  licensedStates
    ?.split(",")
    .map(stateStr => stateStr.split(":")[0])
    .filter(isStateKeyValue)
    .filter(dedupe) ?? undefined;

export const useAuthedAgentLicensedStates = () => {
  const { data: agentFeedInfo, isLoading } = useAuthedAgentFeedInfo();

  const licensedStates: StateKey[] | undefined = useMemo(
    () => filterLicensedStates(agentFeedInfo?.licensedStates),
    [agentFeedInfo?.licensedStates],
  );

  return {
    licensedStates,
    isLoadingLicensedStates: isLoading,
  };
};
