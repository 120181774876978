import {
  internalVehicleInfoVariableNames,
  internalLeaseVariableNames,
} from "shared/constants/dataManagement";

export const isTextVariable = (text: string) =>
  text.startsWith("{") && text.endsWith("}");

export const getVariableNames = (text: string) =>
  text.match(/(?<=\{)(.*?)(?=\})/g)?.map(name => `{${name.trim()}}`) ?? [];

export const splitIntoComponents = (text: string) => {
  const regex = /{(.*?)}/;
  const components = [];
  let match;

  while ((match = regex.exec(text)) !== null) {
    const [fullMatch] = match;
    const splitIndex = text.indexOf(fullMatch);

    if (splitIndex > 0 && text.substring(0, splitIndex).trim())
      components.push(text.substring(0, splitIndex).trim());
    if (fullMatch.trim()) components.push(fullMatch.trim());

    text = text.substring(splitIndex + fullMatch.length);
  }

  if (text.trim()) components.push(text.trim());

  return components;
};

export const variableNames = [
  ...internalVehicleInfoVariableNames,
  ...internalLeaseVariableNames,
].map(name => `{${name}}`);
