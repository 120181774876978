import { Space, Button } from "antd";
import { PmaxCreateAssetGroupFooterProps } from "../PmaxLoadDrawer";
import styles from "./pmaxLoadStep/PmaxLoadStep.module.scss";

export const LoadStepFooter = ({
  onClose,
  uploadStatus,
}: PmaxCreateAssetGroupFooterProps) => {
  const isAllLoaded = uploadStatus?.responseJson?.percentage === 100;
  const buttonText = isAllLoaded ? "Done" : "Loading...";
  return (
    <Space direction="horizontal" className={styles.loadDrawerActionsContainer}>
      <Space direction="horizontal" align="start">
        <Button onClick={onClose}>Cancel</Button>
      </Space>
      <Space align="end">
        <Button disabled={!isAllLoaded} onClick={onClose} type="primary">
          {buttonText}
        </Button>
      </Space>
    </Space>
  );
};
