import { useMemo } from "react";
import { useFetchBeeswaxCampaigns } from "./useBeeswaxCampaigns";
import { useBeeswaxCampaignsDataSource } from "./useBeeswaxCampaignsDataSource";

export const useSelectedBeeswaxCampaigns = () => {
  const { data: campaigns } = useFetchBeeswaxCampaigns();
  const { selectedItems: selectedRecords, isLoading } =
    useBeeswaxCampaignsDataSource();

  const selectedCampaigns = useMemo(
    () =>
      campaigns?.filter(campaign =>
        selectedRecords.some(record => record.id === campaign.id),
      ) ?? [],
    [campaigns, selectedRecords],
  );

  return {
    selectedCampaigns,
    isLoading,
  };
};
