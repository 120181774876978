import { format, startOfToday } from "date-fns";
import { TInputType } from "./types";
import { COLUMN_ASSET_BATCHES } from "shared/constants/assetExporter";

export const INPUT_TYPES: TInputType[] = ["match_to_column", "regex", "text"];

export const INPUT_TYPE_TITLE: Record<TInputType, string> = {
  match_to_column: "Column",
  regex: "Regex",
  text: "Text",
  logo: "Logo",
};

export const filterOutColumns = [
  "order_number",
  COLUMN_ASSET_BATCHES,
  "build_assets",
  "rowIdentifier",
  "assetBatchId",
  "key",
  "id",
  "lastUpdated",
];

export const DEFAULT_ASSET_BATCH_NAME_PREFIX = `Asset batch ${format(
  startOfToday(),
  "MM-dd-yy",
)}`;

export const DEFAULT_FPS = 24;
export const MINIMUM_DURATION = 2;
export const DEFAULT_DURATION = 5;
