import { Row } from "antd";
import { TAudioFile } from "shared/types/assetExporter";
import { useAssetBatchesContext } from "../../shared/contexts/AssetBatchesContext";
import { useAssetBatchesValueMappingContext } from "../../shared/contexts/AssetBatchesValueMappingContext";
import styles from "./VariableInput.module.scss";
import { useState } from "react";
import { getMediaByType } from "../../shared/contexts/AssetBatchesContext.utils";
import { nonNullable } from "utils/helpers.array";
import { useMediaDataForSelectedRow } from "../../shared/hooks/useMediaDataForSelectedRow";
import { AudioFromFeedInput } from "./AudioFromFeed/AudioFromFeedInput";
import { AudioFromFeedPlayer } from "./AudioFromFeed/AudioFromFeedPlayer";

interface Props {
  audioFile: TAudioFile;
}

export const AudioFromFeed = ({ audioFile }: Props) => {
  const { mediaColumns } = useAssetBatchesContext();
  const { selectedRow } = useAssetBatchesValueMappingContext();
  const [showSelectColumn, setShowSelectColumn] = useState(false);

  const value = audioFile.column || audioFile.src;

  const { isLoading, data: selectedRowMediaUrls } = useMediaDataForSelectedRow(
    selectedRow,
    mediaColumns,
  );
  const filterColumns = getMediaByType(
    ["mp3", "mov"],
    selectedRow,
    isLoading ? [] : selectedRowMediaUrls.filter(nonNullable),
    mediaColumns,
  );

  return (
    <Row className={styles.audioRow} justify="space-around" align="top">
      {(audioFile.src === "" || showSelectColumn) && (
        <AudioFromFeedInput
          audioFile={audioFile}
          filterColumns={filterColumns}
          value={value}
          setShowSelectColumn={setShowSelectColumn}
        />
      )}
      {audioFile.src !== "" && !showSelectColumn && (
        <AudioFromFeedPlayer
          audioFile={audioFile}
          setShowSelectColumn={setShowSelectColumn}
        />
      )}
    </Row>
  );
};
