import { Typography } from "antd";
import MultipleStoreSelect from "shared/components/MultipleAccountSelect";
import { terms } from "utils/terms";
import { useLoadCampaignsContext } from "./LoadProvider";
import { useStepData } from "./useStepData";

const AccountsSelect = () => {
  const { selectedAccounts, selectAccount, deselectAccount } =
    useLoadCampaignsContext();

  const { shouldDisableAccounts } = useStepData();

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Typography.Text>{terms.accountName}</Typography.Text>
      <MultipleStoreSelect
        autoFocus={false}
        disabled={shouldDisableAccounts}
        style={{ width: "30%" }}
        selectedDealers={selectedAccounts.map(account => account.dealer_name)}
        onSelect={(id, accounts) => {
          const account = accounts?.find(acc => acc.dealer_name === id);
          if (account) {
            selectAccount(account);
          }
        }}
        onDeselect={id => {
          deselectAccount(id);
        }}
        filters={{
          enabled: true,
          hadBeeswaxAdvertiser: true,
        }}
      />
    </div>
  );
};

export default AccountsSelect;
