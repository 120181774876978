import { Stack, Typography, Grid } from "@mui/material";
import { StoreType } from "polotno/model/store";
import { useElements } from "../editorTabs/tabPanel/useElements";
import { PositionButton } from "./PositionButton";
import {
  AlignHorizontalLeftRounded,
  AlignHorizontalCenterRounded,
  AlignHorizontalRightRounded,
} from "@mui/icons-material";
import { observer } from "mobx-react-lite";

type PositioningActionsProps = {
  store: StoreType;
};
export const PositioningActions = observer(
  ({ store }: PositioningActionsProps) => {
    const { positionSelectedElements } = useElements(store);
    return (
      <Stack spacing={1}>
        <Typography variant="body1">Position</Typography>
        <Grid container columnSpacing={2}>
          <Grid item xs={6}>
            <Stack alignItems="start">
              <PositionButton
                startIcon={<AlignHorizontalLeftRounded color="action" />}
                onClick={() => positionSelectedElements("horizontal-left")}
              >
                Align left
              </PositionButton>
              <PositionButton
                startIcon={<AlignHorizontalCenterRounded color="action" />}
                onClick={() => positionSelectedElements("horizontal-center")}
              >
                Align center
              </PositionButton>
              <PositionButton
                startIcon={<AlignHorizontalRightRounded color="action" />}
                onClick={() => positionSelectedElements("horizontal-right")}
              >
                Align right
              </PositionButton>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack alignItems="start">
              <PositionButton
                startIcon={<AlignHorizontalLeftRounded color="action" />}
                onClick={() => positionSelectedElements("vertical-top")}
              >
                Align top
              </PositionButton>
              <PositionButton
                startIcon={<AlignHorizontalLeftRounded color="action" />}
                onClick={() => positionSelectedElements("vertical-center")}
              >
                Align middle
              </PositionButton>
              <PositionButton
                startIcon={<AlignHorizontalLeftRounded color="action" />}
                onClick={() => positionSelectedElements("vertical-bottom")}
              >
                Align bottom
              </PositionButton>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    );
  },
);
