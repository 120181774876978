import { Select, SelectProps, Tooltip } from "antd";
import { useMemo, useState } from "react";
import { StateKey, states } from "shared/constants/states";

const LocationsInput = ({
  availableLocations,
  value,
  onChange,
  disabledLocations,
  disabledTooltip,
  disabled,
}: {
  availableLocations?: StateKey[];
  disabledLocations?: StateKey[];
  value?: string[];
  onChange?: (value: string[]) => void;
  disabledTooltip?: string;
  disabled?: boolean;
}) => {
  const [selectedLocations, setSelectedLocations] = useState<string[]>(
    value || [],
  );

  const locations = useMemo(() => {
    return (
      availableLocations?.map(location => states[location]) ||
      Object.values(states)
    ).sort((a, b) => a.name.localeCompare(b.name));
  }, [availableLocations]);

  const handleSelectChange: SelectProps<string[]>["onChange"] = val => {
    const isAllLocationsSelected = val.includes("ALL");
    let newVal;
    if (isAllLocationsSelected) {
      newVal =
        selectedLocations.length == Object.keys(states).length
          ? []
          : locations.map(location => location.id);
    } else {
      newVal = val;
    }
    setSelectedLocations(newVal);
    onChange?.(newVal);
  };

  const handleDeselect = (val: string) => {
    if (val === "ALL") {
      setSelectedLocations([]);
      onChange?.([]);
    } else {
      const newValue = selectedLocations.filter(
        location => location !== val && location !== "ALL",
      );
      setSelectedLocations(newValue);
      onChange?.(newValue);
    }
  };

  const filterOption = (input: string, option?: any) => {
    const searchText = input.toLowerCase();
    const location = states[option.value as keyof typeof states];
    const matchId = option.value?.toLowerCase().includes(searchText);
    const matchName = location?.name?.toLowerCase().includes(searchText);
    return matchId || matchName;
  };

  return (
    <Select
      mode="multiple"
      style={{ width: "100%" }}
      placeholder="Please Select"
      value={value || selectedLocations}
      onChange={handleSelectChange}
      onDeselect={handleDeselect}
      showSearch
      filterOption={filterOption}
      optionLabelProp="id"
      disabled={disabled}
    >
      {!disabledLocations && (
        <Select.Option key="ALL" value="ALL">
          All States
        </Select.Option>
      )}
      {Object.values(locations).map(location => {
        const disabled = !!disabledLocations?.some(loc => loc == location.id);
        return (
          <Select.Option
            value={location.id}
            disabled={disabled}
            key={location.id}
          >
            <Tooltip title={disabled ? disabledTooltip : null}>
              <div style={{ width: "100%" }}>
                {location.id} - {location.name}
              </div>
            </Tooltip>
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default LocationsInput;
