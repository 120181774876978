import { notification } from "antd";
import { UseMutationOptions, useMutation } from "react-query";
import { loadBeeswaxCampaignsMutationKey } from "screens/beeswaxCampaigns/constants";
import { LoadCampaignsBody } from "screens/beeswaxCampaigns/types";
import API from "services";
import { IDataTableError } from "shared/types/errors";
import { IApiResponse } from "shared/types/shared";

type AllOptions = UseMutationOptions<
  IApiResponse<string, IDataTableError>,
  unknown,
  LoadCampaignsBody,
  unknown
>;

export const useLoadBeeswaxCampaigns = (
  options?: Pick<AllOptions, "onSuccess">,
) => {
  return useMutation({
    mutationKey: loadBeeswaxCampaignsMutationKey,
    mutationFn: API.services.beeswaxCampaigns.loadBeeswaxCampaigns,
    onError: () => {
      notification.error({
        message: "Error",
        description: "Something went wrong loading campaigns.",
      });
    },
    ...options,
  });
};
