import {
  FillAdInfoTableData,
  RecommendedColumnTitle,
  ValidityRecord,
} from "../types";
import { isCallToActionValue } from "./callToActionValuesValidations";
import { isValidUrl } from "./urlValuesValidations";

export const validateRowData = (
  rows: FillAdInfoTableData[],
  validity: Record<string, ValidityRecord>,
  setValidity: React.Dispatch<
    React.SetStateAction<Record<string, ValidityRecord>>
  >,
) => {
  rows.forEach((rowData, index) => {
    const ctaValue = rowData[RecommendedColumnTitle.CTAButton]?.toString();
    const destinationUrlValue =
      rowData[RecommendedColumnTitle.DestinationURL]?.toString();

    const newValidity: ValidityRecord = {
      ...validity[index],
      [RecommendedColumnTitle.CTAButton]:
        !ctaValue || isCallToActionValue(ctaValue),
      [RecommendedColumnTitle.DestinationURL]:
        !destinationUrlValue || isValidUrl(destinationUrlValue),
    };

    if (JSON.stringify(validity[index]) !== JSON.stringify(newValidity)) {
      setValidity(prevState => ({
        ...prevState,
        [index]: newValidity,
      }));
    }
  });
};

export const hasColumnInvalidData = (
  validity: Record<string, ValidityRecord>,
  column: RecommendedColumnTitle,
) => {
  return Object.values(validity).some(row => row[column] === false);
};
