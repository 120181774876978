import { Typography, Image } from "antd";
import { useNavigate } from "react-router-dom";
import AflacLogo from "statics/images/aflac-logo.png";
import styles from "./Footer.module.scss";
import { ROUTES } from "./utils/constants";

type FooterProps = {
  isAdmin: boolean;
};

const footerLinks = {
  admin: [
    { name: "Products", path: ROUTES.products },
    { name: "Disclosures", path: ROUTES.disclosures },
    { name: "Templates", path: ROUTES.templates },
    { name: "Marketing Materials", path: ROUTES.marketingMaterials },
    { name: "Accounts", path: ROUTES.accounts },
  ],
  agent: [
    { name: "Templates", path: ROUTES.templates },
    { name: "Marketing Materials", path: ROUTES.marketingMaterials },
    { name: "Accounts", path: ROUTES.accounts },
  ],
};

export const Footer = ({ isAdmin }: FooterProps) => {
  const navigate = useNavigate();
  const links = isAdmin ? footerLinks.admin : footerLinks.agent;
  const { Text } = Typography;

  return (
    <div className={styles.footerContainer}>
      <div className={styles.linksWrapper}>
        <div className={styles.linksContainer}>
          {links.map(link => (
            <div
              key={link.name}
              onClick={() => navigate(link.path)}
              className={styles.linkText}
            >
              <Text>{link.name}</Text>
            </div>
          ))}
        </div>
        <div className={styles.copyrightContainer}>
          <Image src={AflacLogo} preview={false} className={styles.aflacLogo} />
          <Text>Copyright © 2024 Aflac</Text>
        </div>
      </div>
    </div>
  );
};
