import { Button, Drawer } from "antd";
import styles from "./Conditional.module.scss";
import { ThunderboltOutlined } from "@ant-design/icons";
import { useState } from "react";
import { ReactComponent as Edit } from "statics/images/Edit_pen.svg";
import {
  ConditionalGroupLayer,
  ConditionalSet,
  Language,
} from "shared/types/salesEnablement";
import { ConditionalForm } from "shared/components/salesEnablement/conditional/ConditionalForm";

type Props = {
  layer: ConditionalGroupLayer;
  onComplete: (conditionalSet: ConditionalSet) => void;
  language: Language;
  disabled?: boolean;
};
const Conditional = (props: Props) => {
  const { layer, onComplete, language, disabled } = props;

  const [conditionalSet, setConditionalSet] = useState<ConditionalSet>(
    layer.conditionalSet,
  );

  const [open, setOpen] = useState(false);

  const onFinish = (conditionalSet: ConditionalSet) => {
    onComplete(conditionalSet!);
    setOpen(false);
  };

  const formId = "conditionalFields";

  return (
    <div>
      {(layer.conditionalSet?.fields.length ?? 0) > 0 && (
        <>
          <div className={styles.gray}>
            One of the options below will be automatically applied once the
            conditions are met:
          </div>
          <div>
            <ol>
              {layer.conditionalSet?.fields.map(field => {
                return (
                  <li
                    key={`field-list-${field.id}`}
                    className={field.then ? "" : styles.gray}
                  >
                    {field.then?.[language]}
                  </li>
                );
              })}

              <li className={layer.conditionalSet.else ? "" : styles.gray}>
                {!layer.conditionalSet.else
                  ? "Blank"
                  : layer.conditionalSet.else?.[language]}
              </li>
            </ol>
          </div>
        </>
      )}
      <Button
        className={styles.edit}
        type="link"
        icon={<Edit className={styles.editPen} />}
        disabled={disabled}
        onClick={() => {
          setConditionalSet(layer.conditionalSet);
          setOpen(true);
        }}
      >
        Edit
      </Button>

      <Drawer
        width={"80%"}
        title="Edit Conditions"
        visible={open}
        onClose={() => setOpen(false)}
        footer={
          <div className={styles.footer}>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button type="primary" htmlType="submit" form={formId}>
              Save
            </Button>
          </div>
        }
      >
        <div className={styles.layerName}>
          <ThunderboltOutlined />
          {layer.name}
        </div>
        <ConditionalForm
          formId={formId}
          onFinish={onFinish}
          initialValues={conditionalSet}
          activeLanguage={language}
        />
      </Drawer>
    </div>
  );
};

export default Conditional;
