import { ContentCopyRounded } from "@mui/icons-material";
import { observer } from "mobx-react-lite";
import { useElements } from "../editorTabs/tabPanel/useElements";
import { TooltipIconButton } from "./TooltipIconButton";
import { TooltipActionProps } from "../EditorTooltip";

export const Duplicate = observer(({ store }: TooltipActionProps) => {
  const { duplicateSelectedElements } = useElements(store);

  return (
    <TooltipIconButton onClick={duplicateSelectedElements}>
      <ContentCopyRounded fontSize="small" />
    </TooltipIconButton>
  );
});
