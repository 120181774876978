import { useInfiniteQuery } from "react-query";
import { useTrackStockMediaDownload } from "./useTrackStockMediaDownload";
import API from "services";

export const useStockMedia = (query: string) => {
  const { mutate: trackStockMediaDownload } = useTrackStockMediaDownload();
  const { data, ...restQuery } = useInfiniteQuery(
    ["stock-media", query.trim()],
    ({ pageParam = 1 }) =>
      API.services.designStudio.getStockMedia({
        query,
        perPage: "10",
        page: String(pageParam),
      }),
    {
      enabled: !!query,
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage.result) return undefined;
        return pages.length + 1;
      },
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    },
  );

  const flatData =
    data?.pages.flatMap(page => {
      return page?.result?.results ?? [];
    }) ?? [];

  return { trackStockMediaDownload, flatData, ...restQuery };
};
