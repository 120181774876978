import React from "react";
import { Tooltip, Image } from "antd";
import MissingThumbnail from "statics/images/missing-thumbnail.png";
import { EyeOutlined } from "@ant-design/icons";

interface IProps {
  name?: string;
  thumbnail?: string;
  textLengthToEllipsis?: number;
}

interface IHandlers {
  onImageClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onCellClick?: () => void;
}

import classNames from "classnames";
import styles from "./NameCardCell.module.scss";
import EllipsisText from "shared/components/card/EllipsisText";
import AutoEllipsisText from "shared/components/AutoEllipsisText";

type NameCardCellProps = IProps & IHandlers;

const NameCardCell = ({
  name = "",
  thumbnail,
  textLengthToEllipsis,
  onCellClick,
}: NameCardCellProps) => {
  const title = thumbnail ? "Preview image" : "Missing thumbnail";
  return (
    <div
      className={styles.flexCenter}
      style={{ cursor: !!onCellClick ? "pointer" : "default" }}
    >
      <Tooltip title={title}>
        <Image
          className={styles.img}
          preview={{ mask: <EyeOutlined /> }}
          src={thumbnail || MissingThumbnail}
          fallback={MissingThumbnail}
        />
      </Tooltip>
      <div className={styles.text} onClick={onCellClick}>
        {textLengthToEllipsis ? (
          <EllipsisText
            text={name}
            charDisplayLimit={textLengthToEllipsis}
            className={classNames({ [styles.clickable]: !!onCellClick })}
          />
        ) : (
          <AutoEllipsisText
            className={classNames({ [styles.clickable]: !!onCellClick })}
          >
            {name}
          </AutoEllipsisText>
        )}
      </div>
    </div>
  );
};

export default NameCardCell;
