import { DeleteOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Col, Row, Select } from "antd";
import { useCallback, useState } from "react";
import { filterOutColumns } from "screens/assetExporter/feedConfigurationV2/shared/constants";
import {
  TRuleCondition,
  useAssetBatchesContext,
} from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesContext";
import { useFeedContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/FeedContext";
import { useAssetBatchesRules } from "screens/assetExporter/feedConfigurationV2/shared/hooks/useAssetBatchesRules";
import API from "services";
import styles from "./RuleCondition.module.scss";
import { isAnyComparisonOperator } from "screens/assetExporter/feedConfigurationV2/shared/validators";
interface Props {
  index: number;
}

interface Option {
  value: string;
  label: string;
}

const RuleCondition = ({ index }: Props) => {
  const [valueDataSource, setValueDataSource] = useState<Option[]>([]);
  const [temporalValue, setTemporalValue] = useState<TRuleCondition>();
  const { ruleConditions, feedId, columns } = useAssetBatchesContext();

  const { updateRuleCondition, removeRuleCondition } = useAssetBatchesRules();

  const { editingAssetBatch } = useFeedContext();
  const currentRuleCond = ruleConditions.find(
    ruleCond => ruleCond.index === index,
  )!;

  const onChange = useCallback(
    (keys: (keyof TRuleCondition)[], values: string[]) => {
      const updatedRuleCond = keys.reduce(
        (acc, key, idx) => ({
          ...acc,
          [key]: values[idx],
          ...(key === "columnName" && { value: "" }),
        }),
        { ...currentRuleCond },
      );
      updateRuleCondition(index, updatedRuleCond);
    },
    [currentRuleCond, index, updateRuleCondition],
  );

  const onChangeColumn = useCallback(
    async (value: string) => {
      if (temporalValue) {
        setTemporalValue(undefined);
      }
      onChange(["columnName"], [value]);

      const values = await API.services.assetExporter.getValuesByColumn(
        feedId,
        value,
      );
      setValueDataSource(
        values.filter(Boolean).map(val => {
          return { value: String(val), label: val };
        }),
      );
    },
    [feedId, onChange, temporalValue],
  );

  return (
    <div className={styles.container} data-cy="rule-condition">
      <Row className={styles.row}>
        <Col span={5} className={styles.colHeader}></Col>
        <Col span={5} className={styles.colHeader}>
          Column<span className={styles.starContainer}>*</span>
        </Col>
        <Col span={6} className={styles.colHeader}></Col>
        <Col span={6} className={styles.colHeader}>
          Value<span className={styles.starContainer}>*</span>
        </Col>
        <Col span={2} className={styles.colHeader}></Col>
      </Row>

      <Row className={styles.row}>
        <Col span={5} className={styles.col}>
          {index === 0 ? (
            <strong>IF</strong>
          ) : (
            <Select
              onChange={value => onChange(["operator"], [value])}
              value={currentRuleCond.operator}
              options={[
                {
                  value: "and",
                  label: "And",
                },
                {
                  value: "or",
                  label: "Or",
                },
              ]}
            />
          )}
        </Col>
        <Col span={5} className={styles.col}>
          <AutoComplete
            placeholder={"select column"}
            onFocus={() => {
              if (currentRuleCond.columnName !== "")
                setTemporalValue(currentRuleCond);
              onChange(["columnName"], [""]);
            }}
            onBlur={() => {
              if (temporalValue) {
                onChange(
                  ["columnName", "value"],
                  [temporalValue.columnName, temporalValue.value],
                );

                setTemporalValue(undefined);
              }
            }}
            onChange={onChangeColumn}
            dropdownMatchSelectWidth={200}
            disabled={isAnyComparisonOperator(currentRuleCond)}
            value={currentRuleCond.columnName}
            options={columns
              .filter(col =>
                col
                  .toLowerCase()
                  .includes(currentRuleCond.columnName.toLowerCase()),
              )
              .filter(
                col =>
                  !filterOutColumns
                    .map(outCol => outCol.toLowerCase())
                    .includes(col.toLowerCase()),
              )
              .map(col => {
                return {
                  value: col,
                  label: col,
                };
              })}
          />
        </Col>
        <Col span={6} className={styles.col}>
          <Select
            onChange={value => onChange(["comparisonOperator"], [value])}
            value={currentRuleCond.comparisonOperator}
            dropdownMatchSelectWidth={130}
            options={[
              {
                value: "Contains",
                label: "contains",
              },
              {
                value: "DoesNotContain",
                label: "does not contain",
              },
              {
                value: "Equals",
                label: "equals",
              },
              {
                value: "NotEquals",
                label: "does not equal",
              },
              {
                value: "Any",
                label: "any",
              },
            ]}
          />
        </Col>
        <Col span={6} className={styles.col}>
          <AutoComplete
            placeholder={"enter value"}
            onChange={value => {
              onChange(["value"], [value]);
              if (editingAssetBatch && valueDataSource.length === 0)
                onChangeColumn(currentRuleCond.columnName);
            }}
            dropdownMatchSelectWidth={200}
            disabled={isAnyComparisonOperator(currentRuleCond)}
            value={currentRuleCond.value}
            options={valueDataSource.filter(val =>
              val.value.includes(currentRuleCond.value),
            )}
          />
        </Col>
        <Col span={2}>
          <Button
            icon={<DeleteOutlined />}
            type={"text"}
            onClick={() => removeRuleCondition(index)}
          />
        </Col>
      </Row>
    </div>
  );
};

export default RuleCondition;
