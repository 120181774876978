import { uniq } from "lodash";
import { useEffect, useMemo } from "react";
import { InfiniteData, useInfiniteQuery, useQueryClient } from "react-query";
import { useFetchOrderTags } from "screens/assetBuilder/hooks/useFetchOrderTags";
import { OrderQueryType } from "screens/assetBuilder/Orders";
import {
  filterOrder,
  toOrderTableRecord,
} from "screens/assetBuilder/orders/OrdersTable.utils";
import API from "services";
import { OrderTag } from "shared/types/assetBuilder";
import {
  INewOrder,
  INewOrderRecord,
  OrderStatus,
} from "shared/types/newOrders";
import { DecodedValueMap } from "use-query-params";

export const useFetchOrders = (
  query: DecodedValueMap<OrderQueryType>,
  status?: OrderStatus,
  onResults?: (creators: string[]) => void,
) => {
  const mergePages = (
    data?: InfiniteData<{ orders: INewOrder[]; paginationKey: string }>,
  ) =>
    data?.pages.reduce<INewOrder[]>(
      (acc, { orders }) => [...acc, ...orders],
      [],
    ) || [];
  const { data, fetchNextPage, isFetching, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ["orders", status ?? "all"],
      queryFn: fetchOrdersPaginated(status),
      getNextPageParam: lastPage => lastPage.paginationKey,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onSuccess: data =>
        onResults?.(uniq(mergePages(data).map(order => order.creator_name))),
    });
  const { tags } = useFetchOrderTags();

  useEffect(() => {
    if (!hasNextPage || isFetchingNextPage) return;

    fetchNextPage();
  }, [hasNextPage, fetchNextPage, isFetchingNextPage]);

  const orders = useMemo(() => mergePages(data), [data]);

  const queryClient = useQueryClient();
  const invalidate = () => {
    queryClient.invalidateQueries(["orders", status]);
  };

  const records = useMemo(
    () =>
      orders
        .filter(
          filterOrder({
            brandsToFilter: query.orders_oem,
            creatorsToFilter: query.orders_creator,
            searchByToFilter: query.orders_search,
          }),
        )
        .map(toOrderTableRecord),
    [orders, query.orders_creator, query.orders_oem, query.orders_search],
  );

  const filters = useMemo(() => {
    return {
      tags: tags?.map((tag: OrderTag) => {
        return {
          text: tag.name,
          value: tag.name,
        };
      }),
    } satisfies Partial<
      Record<keyof INewOrderRecord, { text: string; value: string }[]>
    >;
  }, [tags]);
  return {
    orders,
    isFetching,
    invalidate,
    records,
    filters,
  };
};

const fetchOrdersPaginated =
  (status?: OrderStatus) =>
  async ({ pageParam }: { pageParam?: string }) => {
    const { result, error } = await API.services.newOrder.getNewOrdersAsync<{
      result: {
        orders: Array<INewOrder>;
        paginationKey?: { [key: string]: any };
      };
      error: { message: string } | null;
    }>(pageParam, status);
    if (error || !result)
      throw Error(
        error?.message ||
          `Some error occured, ${JSON.stringify(error)}, ${JSON.stringify(
            result,
          )}`,
      );
    if (!result.orders) throw Error("API returned wrong formatted data.");

    return {
      orders: result.orders,
      paginationKey: JSON.stringify(result.paginationKey),
    };
  };
