import { Alert, AlertTitle, Snackbar, Stack, Typography } from "@mui/material";
import { MetaForm } from "./MetaForm";
import { LoadingButton } from "@mui/lab";

type MetaFormSectionProps = {
  onChange: (meta: MGFormValues) => void;
  onExportClick: () => void;
  isUploading: boolean;
  notification: {
    open: boolean;
    title: string;
    message: string;
    severity: "success" | "error" | "info" | "warning";
  };
  handleNotificationClose: () => void;
};

export const MetaFormSection = ({
  onChange,
  onExportClick,
  isUploading,
  notification,
  handleNotificationClose,
}: MetaFormSectionProps) => {
  return (
    <Stack position="relative">
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="body1">Input information for the asset</Typography>
        <Typography variant="body2" style={{ color: "gray" }}>
          (optional)
        </Typography>
      </Stack>
      <MetaForm onChange={onChange} />
      <Snackbar
        open={notification.open}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleNotificationClose}
        sx={{
          position: "absolute",
          marginBottom: "25px",
          width: "100%",
        }}
      >
        <Alert severity={notification.severity} sx={{ width: "100%" }}>
          <AlertTitle>{notification.title}</AlertTitle>
          {notification.message}
        </Alert>
      </Snackbar>
      <LoadingButton
        onClick={onExportClick}
        variant="contained"
        loading={isUploading}
      >
        Export
      </LoadingButton>
    </Stack>
  );
};
