import DataListURLTable from "shared/components/dataListURL/DataListURLTable";
import { fieldKeys } from "./fields";
import ExpandIcon from "shared/components/ExpandIcon";
import { isEmpty } from "lodash";
import { LoadCampaignRecord } from "screens/beeswaxCampaigns/types";
import { CSSProperties } from "react";
import { useFilteredDataSource } from "../hooks/useFilteredDataSource";
import { useColumns } from "./columns";

const Table = () => {
  const { data: dataSource } = useFilteredDataSource();
  const columns = useColumns();

  const getExpandIconStyles = (record: LoadCampaignRecord): CSSProperties => {
    if (record.type === "campaign" && !isEmpty(record.children)) {
      return { paddingBottom: 4 };
    }

    return { visibility: "hidden" };
  };

  return (
    <div style={{ padding: "1em 0" }}>
      <DataListURLTable<keyof LoadCampaignRecord, LoadCampaignRecord>
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        size="small"
        type="normal"
        fieldKeys={fieldKeys}
        disableRowSelection
        expandable={{
          expandIconColumnIndex: 0,
          expandIcon: props => (
            <ExpandIcon style={getExpandIconStyles(props.record)} {...props} />
          ),
        }}
      />
    </div>
  );
};

export default Table;
