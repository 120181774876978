import { CloudUploadOutlined, PaperClipOutlined } from "@ant-design/icons";
import { Form, Upload, UploadProps } from "antd";
import Papa from "papaparse";
import { memo, useState } from "react";
import styles from "./ImportCsvForm.module.scss";

interface IProps {
  csvJsonData?: string[][];
  setCsvJsonData: (csvJsonData: string[][]) => void;
  csvTemplateUrl: string;
  disabled?: boolean;
}

const ImportCsvForm = ({
  csvJsonData,
  setCsvJsonData,
  csvTemplateUrl,
  disabled,
}: IProps) => {
  const [fileName, setFileName] = useState("");

  const customRequest: UploadProps["customRequest"] = ({
    file,
    onSuccess,
    onError,
  }) => {
    const csvFile = file as File;

    try {
      Papa.parse<string[]>(csvFile, {
        complete: function (results) {
          onSuccess?.(results.data, new XMLHttpRequest());
          setCsvJsonData(results.data);
          setFileName(csvFile.name);
        },
        skipEmptyLines: true,
      });
    } catch (error) {
      onError?.(error as Error);
    }
  };

  return (
    <Form layout="vertical" requiredMark="optional">
      {!csvJsonData && (
        <Form.Item
          label="Choose your document to be uploaded"
          extra={
            <p style={{ marginTop: 10 }}>
              Need a CSV template?{" "}
              <a
                href={csvTemplateUrl}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                Download here
              </a>
            </p>
          }
          required
        >
          <Upload.Dragger
            accept=".csv"
            multiple={false}
            maxCount={1}
            disabled={disabled}
            showUploadList={false}
            customRequest={customRequest}
            style={{ borderStyle: "solid" }}
          >
            <p className="ant-upload-drag-icon">
              <CloudUploadOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag CSV file to this area to upload
            </p>
          </Upload.Dragger>
        </Form.Item>
      )}
      {csvJsonData && (
        <>
          <h4>Uploaded file</h4>
          <div className={styles.uploadedFile}>
            <Upload
              customRequest={customRequest}
              showUploadList={false}
              disabled={disabled}
              accept=".csv"
            >
              <PaperClipOutlined />
              <a>{fileName}</a>
            </Upload>
          </div>
        </>
      )}
    </Form>
  );
};

export default memo(ImportCsvForm);
