import { Layer } from "shared/types/salesEnablement";
import { OnLayerChangeFunc } from "../../Layers";
import {
  CloseCircleOutlined,
  FontSizeOutlined,
  PictureOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import {
  hasChildren,
  isGroupLayer,
  isImageLayer,
  isTextLayer,
} from "../../utils";

const LayerTypeToggle = (props: {
  disabled?: boolean;
  layer: Layer;
  isChildLayer: boolean;
  onChange?: OnLayerChangeFunc;
}) => {
  const { layer, onChange, disabled } = props;
  const [hovered, setHovered] = useState(false);
  const mouseEnterLeaveProps = {
    onMouseEnter: () => {
      if (disabled === true) return;

      setHovered(true);
    },
    onMouseLeave: () => setHovered(false),
  };

  return hovered ? (
    <>
      {isTextLayer(layer) && (
        <ThunderboltOutlined
          onClick={() => onChange?.(layer, { type: "convert_to_group" })}
          {...mouseEnterLeaveProps}
        />
      )}

      {isGroupLayer(layer) && !hasChildren(layer) && (
        <CloseCircleOutlined
          onClick={() => {
            onChange?.(layer, { type: "convert_to_text" });
          }}
          {...mouseEnterLeaveProps}
        />
      )}
    </>
  ) : (
    <>
      {isImageLayer(layer) && <PictureOutlined />}
      {isGroupLayer(layer) && !hasChildren(layer) && (
        <ThunderboltOutlined {...mouseEnterLeaveProps} />
      )}
      {isTextLayer(layer) && <FontSizeOutlined {...mouseEnterLeaveProps} />}
    </>
  );
};
export default LayerTypeToggle;
