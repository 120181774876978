import { useLocalStorage } from "shared/hooks/useLocalStorage";
import { ImageListItem } from "../editorDrawerContent/polotnoEditor/editorTabs/tabPanel/media/ImageList";

const initialRecentImages: { [key: string]: ImageListItem[] } = {};

export const useRecentImages = () => {
  const [recentImages, setRecentImages] = useLocalStorage(
    "recentImages",
    initialRecentImages,
  );

  const getRecentImages = (key: string) => {
    return recentImages[key] ?? [];
  };

  const addRecentImages = (key: string, files: ImageListItem[]) => {
    setRecentImages({
      ...recentImages,
      [key]: [
        ...files,
        ...getRecentImages(key).filter(
          f => !files.some(ff => ff.src === f.src),
        ),
      ],
    });
  };

  const removeRecentImage = (key: string, file: ImageListItem) => {
    setRecentImages({
      ...recentImages,
      [key]: getRecentImages(key).filter(f => f.src !== file.src),
    });
  };

  return {
    recentImages,
    addRecentImages,
    getRecentImages,
    removeRecentImage,
  };
};
