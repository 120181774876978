import { UploadFile } from "antd/lib/upload/interface";
import { z } from "zod";
import { ImageType } from "shared/types/brandsAccounts";
import { IFile } from "shared/types/uploadAsset";
import { imageRatioValidator } from "./uploadAssets";

const MAX_LOGOS = 45;
const MAX_FONTS = 10;

export const horizontalLogosSchema = z
  .array(
    z
      .custom<UploadFile<IFile>>()
      .refine(
        async file => imageRatioValidator(file, 2),
        "Horizontal Logos ratio should be 2",
      ),
  )
  .max(15, "A maximum of 15 Landscape Logos is allowed");

export const squareLogosSchema = z
  .array(
    z
      .custom<UploadFile<IFile>>()
      .refine(
        async file => imageRatioValidator(file, 1),
        "Square Logos ratio should be 1",
      ),
  )
  .max(15, "A maximum of 15 Square Logos is allowed");

export const verticalLogosSchema = z
  .array(
    z
      .custom<UploadFile<IFile>>()
      .refine(
        async file => imageRatioValidator(file, 0.5),
        "Vertical Images ratio should be 0.5",
      ),
  )
  .max(15, "A maximum of 15 Vertical Logos is allowed");

const uploadsSchema = z
  .object({
    horizontalLogos: horizontalLogosSchema,
    squareLogos: squareLogosSchema,
    verticalLogos: verticalLogosSchema,
  })
  .refine(
    uploads => Object.values(uploads).flat().length <= MAX_LOGOS,
    `The maximum amount of images is ${MAX_LOGOS} (sum of horizontal, square, and vertical). Please remove the exceeding images.`,
  );

export const getUploadsSchema = (title?: ImageType) => {
  switch (title) {
    case "Horizontal":
      return horizontalLogosSchema;
    case "Square":
      return squareLogosSchema;
    case "Vertical":
      return verticalLogosSchema;
    default:
      return uploadsSchema;
  }
};

export const brandFontSchema = z
  .array(z.custom<UploadFile>())
  .max(10, `A maximum of ${MAX_FONTS} fonts is allowed`)
  .superRefine((files, ctx) => {
    if (!files.every(file => file.type === "font/ttf")) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Invalid file type. Please upload a .ttf file",
      });
    }
    if (
      files
        .map(file => file.name)
        .some((fileName, i, fileNames) => fileNames.indexOf(fileName) !== i)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Duplicate file names. Please upload files with unique names",
      });
    }
  });
