import { useRef, useState } from "react";
import { Button } from "antd";
import { useOnWindowResize } from "shared/hooks/useOnWindowResize";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import styles from "./ExpandableParagraph.module.scss";

type Props = {
  children: string | undefined;
};

const ExpandableParagraph = ({ children: description }: Props) => {
  const [showButton, setShowButton] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);

  const handleViewMoreClick = () => {
    setShowFullDescription(true);
  };

  const handleViewLessClick = () => {
    setShowFullDescription(false);
  };

  const handleParagraphOverflow = () => {
    if (!paragraphRef.current) return;

    const hasEllipsis =
      paragraphRef.current.offsetHeight < paragraphRef.current.scrollHeight;

    setShowButton(hasEllipsis);
  };

  const paragraphRef = useRef<HTMLParagraphElement | null>(null);

  const handleParagraphRef = (paragraph: HTMLParagraphElement | null) => {
    paragraphRef.current = paragraph;
    handleParagraphOverflow();
  };

  useOnWindowResize(() => {
    handleParagraphOverflow();
  });

  if (!description) {
    return null;
  }

  return (
    <div className={styles.container}>
      <p
        style={{
          WebkitLineClamp: showFullDescription ? "unset" : 3,
        }}
        className={styles.lineClamp}
        ref={handleParagraphRef}
      >
        {description}
      </p>
      {showButton && !showFullDescription && (
        <ButtonLink onClick={handleViewMoreClick}>
          View more <DownOutlined />
        </ButtonLink>
      )}
      {showFullDescription && (
        <ButtonLink onClick={handleViewLessClick}>
          View less
          <UpOutlined />
        </ButtonLink>
      )}
    </div>
  );
};

const ButtonLink = ({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick: () => void;
}) => (
  <Button type="link" className={styles.button} onClick={onClick}>
    {children}
  </Button>
);

export default ExpandableParagraph;
