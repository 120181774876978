import { StoreType } from "polotno/model/store";
import { Duplicate } from "./editorTooltip/Duplicate";
import { Remove } from "./editorTooltip/Remove";
import { Position } from "./Position";

export type TooltipActionProps = {
  store: StoreType;
};

export const EditorTooltip = {
  Remove,
  Duplicate,
  Position,
};
