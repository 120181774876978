import { useAssetBatchesContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesContext";
import styles from "../PanelTemplate.module.scss";
import { PlusSquareFilled } from "@ant-design/icons";
import { isTemplateComposition } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesContext.utils";
import { TemplateHeader } from "./TemplateHeader";
import { TemplatePreviewSection } from "screens/assetExporter/feedConfigurationV2/shared/components/TemplatePreviewSection";
import { TemplateDrawer } from "./TemplateDrawer";
import { useAssetBatchesValueMappingContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesValueMappingContext";

export const PanelTemplateWithoutComposition = () => {
  const {
    compositions,
    openFileManager,
    setOpenFileManager,
    templatesToUse: templates,
  } = useAssetBatchesContext();

  const { selectedRow } = useAssetBatchesValueMappingContext();

  const firstTemplateComposition = compositions[0];

  return (
    <div className={styles.main}>
      {!templates[0] && (
        <div
          className={styles.noSelection}
          onClick={() => {
            setOpenFileManager(true);
          }}
          data-cy="select-template"
        >
          <PlusSquareFilled className={styles.plusIcon} />
          Select Template
        </div>
      )}

      {!!templates[0] &&
        firstTemplateComposition &&
        isTemplateComposition(firstTemplateComposition) && (
          <>
            <TemplateHeader />
            <TemplatePreviewSection
              template={templates[0]}
              composition={firstTemplateComposition}
              selectedRow={selectedRow}
            />
          </>
        )}
      <TemplateDrawer
        open={openFileManager}
        onClose={() => setOpenFileManager(false)}
      />
    </div>
  );
};
