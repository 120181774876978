import { IDataListURLFields } from "shared/components/dataListURL/types";
import { BeeswaxCampaignTableRecord } from "./types";
import { StringParam } from "use-query-params";
import { onFilterBy, compareStringBy } from "utils/helpers.table";
import { dateFilterFn, displayDate } from "./helpers";

export const fields: IDataListURLFields<
  keyof BeeswaxCampaignTableRecord,
  BeeswaxCampaignTableRecord
> = {
  name: {
    filterFn: onFilterBy("name", {
      matchMode: "includes",
      caseInsensitive: true,
    }),
    sorterFn: compareStringBy("name"),
    queryParamConfigFilter: StringParam,
  },
  startDate: {
    filterFn: dateFilterFn("startDate"),
    sorterFn: compareStringBy("startDate"),
    queryParamConfigFilter: StringParam,
    display: displayDate,
  },
  endDate: {
    filterFn: dateFilterFn("endDate"),
    sorterFn: compareStringBy("endDate"),
    queryParamConfigFilter: StringParam,
    display: displayDate,
  },
  createdAt: {
    filterFn: dateFilterFn("createdAt"),
    sorterFn: compareStringBy("createdAt"),
    queryParamConfigFilter: StringParam,
    display: displayDate,
  },
  updatedAt: {
    filterFn: dateFilterFn("updatedAt"),
    sorterFn: compareStringBy("updatedAt"),
    queryParamConfigFilter: StringParam,
    display: displayDate,
  },
  updatedBy: {
    filterFn: onFilterBy("updatedBy", {
      matchMode: "includes",
      caseInsensitive: true,
    }),
    sorterFn: compareStringBy("updatedBy"),
    queryParamConfigFilter: StringParam,
  },
};

export const fieldKeys = Object.keys(fields) as Array<keyof typeof fields>;
