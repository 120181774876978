import { LayerElement } from "screens/designStudio/designStudioV2/types";
import { useLayerName } from "./layerName/useLayerName";
import { TextField } from "@mui/material";
import { useRefFocus } from "shared/hooks/useRefFocus";
import { useRef } from "react";

import { observer } from "mobx-react-lite";

type LayerNameProps = {
  layer: LayerElement;
  isSelected: boolean;
  onKeyDown: (
    layer: LayerElement,
    cleanUp: () => void,
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => void;
};

export const LayerName = observer(
  ({ isSelected, layer, onKeyDown }: LayerNameProps) => {
    const { layerName, setLayerName, resetName } = useLayerName(
      layer,
      isSelected,
    );

    const inputRef = useRef<HTMLInputElement>(null);
    useRefFocus(inputRef, isSelected);

    return (
      <TextField
        value={layerName}
        onChange={e => setLayerName(e.target.value)}
        size="small"
        variant="outlined"
        fullWidth
        sx={{
          pointerEvents: isSelected ? "initial" : "none",
          "& fieldset": { borderWidth: isSelected ? 1 : 0 },
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: theme => theme.palette.text.primary,
          },
        }}
        color="primary"
        inputProps={{
          ref: inputRef,
          sx: {
            fontSize: "small",
            py: 0.5,
            px: 1,
            color: "text.primary",
            textOverflow: "ellipsis",
            "&:hover": {
              backgroundColor: "gray.200",
            },
          },

          onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) =>
            onKeyDown(layer, resetName, e),
        }}
        disabled={!isSelected}
      />
    );
  },
);
