import { Tree, TreeProps } from "antd";
import { DataNode, EventDataNode } from "rc-tree/lib/interface";
import { Key, useEffect, useState } from "react";
import { useGetOnlyFoldersMutation } from "redux/media/media.api";
import { Loading } from "../Loading";
import { formatFolders, updateTreeData } from "./utils";

interface Props extends TreeProps {
  onSelect?: (
    selectedKeys: Key[],
    info: {
      event: "select";
      selected: boolean;
      node: EventDataNode;
      selectedNodes: DataNode[];
      nativeEvent: MouseEvent;
    },
    path?: string[],
  ) => void;
}

export const SelectFolder = ({ onSelect }: Props) => {
  const [treeData, setTreeData] = useState<DataNode[]>();
  const [getFolders] = useGetOnlyFoldersMutation();

  const onLoad = async (treeNode: EventDataNode) => {
    const folderId = +treeNode.key;
    const folders = await getFolders(folderId).unwrap();

    setTreeData(prev => {
      const children = formatFolders(folders);

      return updateTreeData(folderId, prev!, children);
    });
  };

  useEffect(() => {
    if (!treeData) {
      getFolders(0)
        .unwrap()
        .then(folders => {
          setTreeData(formatFolders(folders));
        });
    }
  }, [getFolders, treeData]);

  return !!treeData ? (
    <Tree.DirectoryTree
      expandAction={false}
      treeData={treeData}
      loadData={onLoad}
      onSelect={(selectedKeys, info) => {
        const [, ...res] = info.node.pos.split("-");
        const initialValue: [DataNode[], string[]] = [treeData, []];
        const [, path] = res.reduce(([tree, path], val) => {
          const { children = [], key } = tree[+val];

          return [children, path.concat(String(key))];
        }, initialValue);

        onSelect?.(selectedKeys, info, path);
      }}
    />
  ) : (
    <Loading />
  );
};
