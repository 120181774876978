import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { AgentProfile } from "screens/adLibrary/marketingMaterialDrawer/hooks/agentManagement";
import { ToolbarButton } from "shared/types/adLibrary";
import { useDeleteProfilesPrompt } from "./useDeleteProfilesPrompt";
import useNavigateWithSearch from "shared/hooks/useNavigateWithSearch";

export const useToolbarContents = () => {
  const navigate = useNavigateWithSearch();
  const showDeletePrompt = useDeleteProfilesPrompt();

  const generateToolbarContents: (
    record?: AgentProfile,
  ) => ToolbarButton = record => ({
    Edit: {
      onClick: () => record && navigate(record.id),
      showInInlineMenu: true,
      showInContextMenu: true,
      icon: <EditOutlined />,
    },
    Delete: {
      onClick: () => {
        record && showDeletePrompt([record.id]);
      },
      showInInlineMenu: true,
      showInContextMenu: true,
      icon: <DeleteOutlined style={{ fill: "red" }} />,
    },
  });

  return generateToolbarContents;
};
