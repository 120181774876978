import { useParams } from "react-router-dom";
import { useFetchDashboard } from "shared/hooks/dashboardManagement/useFetchDashboards";
import { IDashboard } from "shared/types/dashboardManagement";
import { DashboardDetailHeader } from "./DashboardDetailHeader";
import LookerDashboard from "./LookerDashboard";

export type DashboardDetailProps = {
  setShareDashboard?: (dashboard?: IDashboard) => void;
  setShowLinkModal?: (showLinkModal: boolean) => void;
  allowDownload?: boolean;
};

const DashboardDetail = ({
  setShareDashboard,
  setShowLinkModal,
  allowDownload,
}: DashboardDetailProps) => {
  const { id } = useParams() as { id: string };
  const { data, isLoading: loadingDashboard } = useFetchDashboard(id);

  return (
    <>
      <DashboardDetailHeader
        dashboard={data?.dashboard}
        setShareDashboard={setShareDashboard}
        setShowLinkModal={setShowLinkModal}
      />
      <LookerDashboard
        dashboard={data?.dashboard}
        loadingDashboard={loadingDashboard}
        allowDownload={allowDownload}
      />
    </>
  );
};

export default DashboardDetail;
