import styles from "./TotalDuration.module.scss";

interface Props {
  duration: number;
}

export const TotalDuration = ({ duration }: Props) => (
  <div className={styles.totalDurationContainer}>
    <span className={styles.totalDurationText}>total duration</span>
    <span>{duration}</span>
    <span className={styles.totalDurationUnit}>s</span>
  </div>
);
