import { DeleteOutlined, FormOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { useMediaActions } from "../../../../../redux/media/media.slice";
import { useFetchPerms } from "../../useFetchPerms";
import { useDeleteFolder } from "../useDeleteFolder";
import { MenuContainer } from "./MenuContainer";

type Props = {
  record: WDFolder;
  visible: boolean;
};
export const FolderMenu = (props: Props) => {
  const { record, visible } = props;
  const { setEditableRow } = useMediaActions();
  const { canRename, canDelete } = useFetchPerms([record.id], visible);

  return (
    <MenuContainer>
      <Tooltip title="Rename">
        <li data-clickeable={!canRename}>
          <FormOutlined
            onClick={() => setEditableRow(record)}
            disabled={!canRename}
          />
        </li>
      </Tooltip>
      <Tooltip title="Delete">
        <li data-clickeable={!canDelete}>
          <DeleteOutlined
            onClick={useDeleteFolder(record)}
            disabled={!canRename}
          />
        </li>
      </Tooltip>
    </MenuContainer>
  );
};
