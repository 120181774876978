import { useMutation, useQueryClient } from "react-query";
import API from "services";
import {
  UploadPmaxAssetGroupsArgs,
  UploadPmaxCampaignsArgs,
} from "shared/types/adLibrary";

import { message } from "antd";
import { useUser } from "shared/hooks/useUser";
import { raise } from "utils/errorMessage";
import { pmaxAssetGroupsQueryKey, pmaxCampaignsQueryKey } from "./constants";
import { useGetPmaxUploadStatus } from "./useGetPmaxUploadStatus";
import { useMemo } from "react";
import { getPmaxUploadTempStatus } from "screens/campaignManagement/googlePerformanceMax/shared/helpers";

type UseUploadToPmaxArgs =
  | Omit<UploadPmaxCampaignsArgs, "user">
  | Omit<UploadPmaxAssetGroupsArgs, "user">;

export const useUploadToPmax = (args: UseUploadToPmaxArgs) => {
  const user = useUser();
  const queryClient = useQueryClient();

  const loadMutation = useMutation(
    async () => {
      const response = await API.services.adLibrary.pmaxLoad({
        ...args,
        user: user ?? raise("User is not defined"),
      });

      if (response.error) throw response.error;
      return response;
    },
    {
      onSettled: (data, error) => {
        if (!data?.result || error) {
          message.error(error ?? data?.error ?? "Something went wrong");
          return;
        }

        queryClient.refetchQueries(pmaxCampaignsQueryKey);
        queryClient.refetchQueries(pmaxAssetGroupsQueryKey);
      },
    },
  );

  const { data: uploadStatus, isFetching: isFetchingUploadStatus } =
    useGetPmaxUploadStatus(loadMutation.data?.result?.executionArn);

  const computedUploadStatus = useMemo(() => {
    if (loadMutation.isIdle) return getPmaxUploadTempStatus("idle");
    if (
      loadMutation.isLoading ||
      (isFetchingUploadStatus && !uploadStatus?.result)
    )
      return getPmaxUploadTempStatus("start-upload");
    if (
      loadMutation.isError ||
      (loadMutation.isSuccess && !loadMutation?.data?.result) ||
      (uploadStatus?.error && !uploadStatus?.result)
    )
      getPmaxUploadTempStatus("error");

    return uploadStatus?.result ?? {};
  }, [uploadStatus, loadMutation, isFetchingUploadStatus]);

  return {
    ...loadMutation,
    uploadStatus: computedUploadStatus,
    isUploading:
      loadMutation.isLoading ||
      isFetchingUploadStatus ||
      uploadStatus?.result?.status === "processing",
  };
};
