import { useMemo } from "react";
import {
  useGetAutocompleteOptionsQuery,
  useGetCurrentUserQuery,
} from "redux/media/media.api";
import { SkipToken, skipToken } from "@reduxjs/toolkit/dist/query";
import { useQPBreadcrumb } from "shared/components/media/useQPBreadcrumb";

const transformFolderPath = (folderPath: string) =>
  folderPath.replace("Folders /", "");

export const useAutocomplete = (query: string) => {
  const { current: albumId = "0" } = useQPBreadcrumb();
  const { data: currentUser } = useGetCurrentUserQuery();

  const [folderPayload] = useMemo<WDAutoCompletePayload[] | SkipToken[]>(() => {
    if (!currentUser || query.length <= 1) {
      return [skipToken];
    }

    return [
      {
        query,
        user_id: currentUser.id.toString(),
        size: "15",
        album_id: albumId,
        type: "folder",
      },
    ];
  }, [query, albumId, currentUser]);

  const { data: foldersData } = useGetAutocompleteOptionsQuery(folderPayload);

  const folders = useMemo(() => {
    if (!foldersData?.options) return [];
    return foldersData.options.map(folder => ({
      label: folder.text,
      value: folder.id.toString(),
      folderPath: transformFolderPath(folder.album_path),
    }));
  }, [foldersData]);

  return folders;
};
