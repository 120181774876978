import { observer } from "mobx-react-lite";
import useMediaElementEffects from "screens/designStudio/designStudioV2/hooks/useMediaElementEffects";
import { ImageLayerElement } from "screens/designStudio/designStudioV2/types";
import { StoreType } from "screens/designStudio/designStudioV2/types";
import { ButtonContainer } from "../containers/ButtonContainer";
type ImageControlProps = {
  element: ImageLayerElement;
  store: StoreType;
};

const ImagePageFit = observer(({ element, store }: ImageControlProps) => {
  const { toggleImagePageFit } = useMediaElementEffects(element, store);

  return (
    <>
      <ButtonContainer
        size="small"
        onClick={toggleImagePageFit}
        sx={{
          minWidth: 2,
        }}
      >
        Fit to Page
      </ButtonContainer>
    </>
  );
});

export default ImagePageFit;
