import { observer } from "mobx-react-lite";
import { Stack, IconButton, useMediaQuery, Popover } from "@mui/material";
import useTextElementEffects from "screens/designStudio/designStudioV2/hooks/useTextElementEffects";
import FormatAlignLeftRoundedIcon from "@mui/icons-material/FormatAlignLeftRounded";
import FormatAlignCenterRoundedIcon from "@mui/icons-material/FormatAlignCenterRounded";
import FormatAlignRightRoundedIcon from "@mui/icons-material/FormatAlignRightRounded";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatStrikethroughIcon from "@mui/icons-material/FormatStrikethrough";
import LetterSpacingControl from "./LetterSpacingControl";
import FontEffectControls from "./FontEffectsControls";
import { TextLayerElement } from "screens/designStudio/designStudioV2/types";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useAnchorPopover } from "screens/designStudio/designStudioV2/hooks/useAnchorPopover";

type Alignment = "left" | "center" | "right";

type TextFormatControlsProps = {
  elements: TextLayerElement[];
};

const TextFormatControls = observer(({ elements }: TextFormatControlsProps) => {
  const [layer] = elements;
  const currentAlignment = layer.align || "left";
  const { toggleBold, toggleItalic, toggleUnderline, toggleStrikethrough } =
    useTextElementEffects(elements);

  const getNextAlignment = (alignment: Alignment) => {
    switch (alignment) {
      case "left":
        return "center";
      case "center":
        return "right";
      case "right":
        return "left";
      default:
        return "left";
    }
  };

  const alignmentIcons: Record<Alignment, JSX.Element> = {
    left: <FormatAlignLeftRoundedIcon fontSize="small" />,
    center: <FormatAlignCenterRoundedIcon fontSize="small" />,
    right: <FormatAlignRightRoundedIcon fontSize="small" />,
  };

  const handleAlignment = () => {
    const nextAlignment = getNextAlignment(currentAlignment);
    elements.forEach((element: any) => {
      element.set({ align: nextAlignment });
    });
  };

  const isSmallScreen = useMediaQuery("(max-width: 1265px)");

  const { onClick, onClose, open, anchorEl, id } = useAnchorPopover(
    "text-format-controls",
  );

  const toggleButtons = (
    <>
      <IconButton onClick={handleAlignment} size="small">
        {alignmentIcons[currentAlignment]}
      </IconButton>
      <IconButton onClick={toggleBold} size="small">
        <FormatBoldIcon fontSize="small" />
      </IconButton>
      <IconButton onClick={toggleItalic} size="small">
        <FormatItalicIcon fontSize="small" />
      </IconButton>
      <IconButton onClick={toggleUnderline} size="small">
        <FormatUnderlinedIcon fontSize="small" />
      </IconButton>
      <IconButton onClick={toggleStrikethrough} size="small">
        <FormatStrikethroughIcon fontSize="small" />
      </IconButton>
    </>
  );

  if (!isSmallScreen) {
    return (
      <Stack flexDirection="row" alignItems="center">
        {toggleButtons}
        <LetterSpacingControl elements={elements} />
        <FontEffectControls elements={elements} />
      </Stack>
    );
  }
  return (
    <Stack direction="row" alignItems="center">
      <IconButton
        aria-label="more"
        id="long-button"
        size="small"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={onClick}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>

      <Popover id={id} anchorEl={anchorEl} open={open} onClose={onClose}>
        <Stack sx={{ p: 1 }} direction="row">
          {toggleButtons}
        </Stack>
      </Popover>

      <LetterSpacingControl elements={elements} />
      <FontEffectControls elements={elements} />
    </Stack>
  );
});

export default TextFormatControls;
