import { Field } from "shared/components/dataListURL/types";
import { DesignStudioTableItem } from "./DesignStudioDataProvider";
import { Template } from "shared/types/salesEnablement";
import {
  checkArrayIncludes,
  checkDateMatch,
  checkFilterMatch,
  checkIntersectionExists,
  compareString,
  formatDateValue,
} from "utils/helpers";
import { AllKeys } from "utils/utilityTypes";
import { DelimitedArrayParam, StringParam } from "use-query-params";
import {
  getArtboardName,
  getAssetType,
  getCategoryName,
  getMediaType,
  getStores,
  getOems,
  getStoresArray,
  getTemplateType,
  getOemsArray,
  getNumberOfStamps,
  getLocation,
  getCreatedAt,
  getUpdatedAt,
  getTags,
  getDimensions,
  getStatus,
} from "../helpers";
import moment from "moment";
import { designStudioTableColumns } from "../designStudioLayout/designStudioTable/columnFilters/columns";

export type DesignStudioTableItemField = Field<DesignStudioTableItem>;

type DesignStudioTableColumnKey = keyof typeof designStudioTableColumns;
type Fields = Partial<
  Record<DesignStudioTableColumnKey, DesignStudioTableItemField>
>;

export type DesignStudioFields = keyof Fields;

export const designStudioTableFields: Fields = {
  name: {
    filterFn: (value, record) =>
      checkFilterMatch(value.toString(), record.asset.name),
    sorterFn: (a, b) => compareString(a.asset.name, b.asset.name),
    queryParamConfigFilter: StringParam,
  },
  category: {
    filterFn: (value, record) =>
      checkArrayIncludes(value, getCategoryName(record)),
    sorterFn: (a, b) => compareString(getCategoryName(a), getCategoryName(b)),
    queryParamConfigFilter: DelimitedArrayParam,
  },
  status: {
    filterFn: (value, record) => checkArrayIncludes(value, getStatus(record)),
    sorterFn: (a, b) => compareString(getStatus(a), getStatus(b)),
    queryParamConfigFilter: DelimitedArrayParam,
  },
  assetType: {
    filterFn: (value, record) =>
      checkArrayIncludes(value, getAssetType(record)),
    sorterFn: (a, b) => compareString(getAssetType(a), getAssetType(b)),
    queryParamConfigFilter: DelimitedArrayParam,
  },
  mediaType: {
    filterFn: (value, record) =>
      checkArrayIncludes(value, getMediaType(record)),
    sorterFn: (a, b) => compareString(getMediaType(a), getMediaType(b)),
    queryParamConfigFilter: DelimitedArrayParam,
  },
  templateType: {
    filterFn: (value, record) =>
      checkArrayIncludes(value, getTemplateType(record)),
    sorterFn: (a, b) => compareString(getTemplateType(a), getTemplateType(b)),
    queryParamConfigFilter: DelimitedArrayParam,
  },
  artboardName: {
    filterFn: (value, record) =>
      checkArrayIncludes(value, getArtboardName(record)),
    sorterFn: (a, b) => compareString(getArtboardName(a), getArtboardName(b)),
    queryParamConfigFilter: DelimitedArrayParam,
  },
  stores: {
    filterFn: (value, record) =>
      checkIntersectionExists(value, getStoresArray(record)),
    sorterFn: (a, b) => compareString(getStores(a), getStores(b)),
    queryParamConfigFilter: DelimitedArrayParam,
  },
  oems: {
    filterFn: (value, record) =>
      checkIntersectionExists(value, getOemsArray(record)),
    sorterFn: (a, b) => compareString(getOems(a), getOems(b)),
    queryParamConfigFilter: DelimitedArrayParam,
  },
  numberOfStamps: {
    filterFn: (value, record) =>
      checkFilterMatch(value.toString(), getNumberOfStamps(record)),
    sorterFn: (a, b) =>
      compareString(getNumberOfStamps(a), getNumberOfStamps(b)),
    queryParamConfigFilter: DelimitedArrayParam,
  },
  location: {
    filterFn: (value, record) =>
      checkFilterMatch(value.toString(), getLocation(record)),
    sorterFn: (a, b) => compareString(getLocation(a), getLocation(b)),
    queryParamConfigFilter: DelimitedArrayParam,
  },
  created: {
    sorterFn: (a, b) =>
      moment(formatDateValue(getCreatedAt(a)?.toString())).valueOf() -
      moment(formatDateValue(getCreatedAt(b)?.toString())).valueOf(),
    filterFn: (value, record) =>
      checkDateMatch(value?.toString(), getCreatedAt(record) ?? 0),
    queryParamConfigFilter: StringParam,
    display: value => value.split(" ").map(formatDateValue).join(" - "),
    defaultSortOrder: "descend",
  },
  lastEdited: {
    sorterFn: (a, b) =>
      moment(formatDateValue(getUpdatedAt(a)?.toString())).valueOf() -
      moment(formatDateValue(getUpdatedAt(b)?.toString())).valueOf(),
    filterFn: (value, record) =>
      checkDateMatch(value?.toString(), getUpdatedAt(record) ?? 0),
    queryParamConfigFilter: StringParam,
    display: value => value.split(" ").map(formatDateValue).join(" - "),
  },
  tags: {
    filterFn: (value, record) =>
      checkIntersectionExists(value, getTags(record)),
    sorterFn: (a, b) => compareString(getTags(a).join(), getTags(b).join()),
    queryParamConfigFilter: DelimitedArrayParam,
  },
  dimensions: {
    filterFn: (value, record) =>
      checkFilterMatch(value.toString(), getDimensions(record)),
    sorterFn: (a, b) => compareString(getDimensions(a), getDimensions(b)),
    queryParamConfigFilter: StringParam,
  },
};

export const designStudioFieldKeys = Object.keys(
  designStudioTableFields,
) as Array<keyof typeof designStudioTableFields>;

// Aflac Template
export type AflacTemplateTableItemField = Field<Template>;

type AflacTemplateTableColumnKey = AllKeys<Template> | "language";
type ATFields = Partial<
  Record<AflacTemplateTableColumnKey, AflacTemplateTableItemField>
>;

export type AflacTemplateFields = keyof ATFields;
