import { useUpdateAssetMetaMutation } from "redux/media/media.api";
import {
  finishUpload,
  getPresignedUrl,
  processUploadFile,
  uploadToS3,
} from "../utils.upload";
import { UploadRequestOption } from "rc-upload/lib/interface";

export const useCustomRequest = (
  folderId: string,
  successCallback: (id: string) => void,
) => {
  const [updateMeta] = useUpdateAssetMetaMutation();

  return async ({ file, onSuccess, onError }: UploadRequestOption) => {
    try {
      // Get pre-signed url from AWS.
      const { presignedUrl, processId } = await getPresignedUrl(
        folderId,
        processUploadFile(file as File),
      );
      // Upload filt to S3.
      await uploadToS3(presignedUrl, file as File);
      // Notify to webdam.
      const { id } = await finishUpload(processId);
      // setUploadedAssets(prev => [...prev, id]);
      // Update asset metadata.
      await updateMeta({ payload: [{ id }] }).unwrap();
      // Notify uploader.
      onSuccess?.(id, new XMLHttpRequest());
      // Run callback.
      successCallback(id);
    } catch (e) {
      onError?.(e as Error);
    }
  };
};
