import { message } from "antd";
import { FilterValue } from "antd/lib/table/interface";
import { merge } from "lodash";
import React, { Key, ReactNode, useCallback, useMemo, useState } from "react";
import { generateEmptyAssetGroup } from "screens/adLibrary/pmaxAssetGroups/PmaxAssetGroupList.utils";
import { Mode } from "screens/adLibrary/pmaxAssetGroups/pmaxAssetGroupList/AssetGroupDetailDrawer";
import useCreateNewAssetGroupSelector, {
  CreateAssetGroupSelectionHandlers,
} from "screens/adLibrary/pmaxAssetGroups/pmaxLoadDrawer/hooks/useCreateNewAssetGroupSelector";

import { pmaxSchema } from "shared/schemas/pmax";
import {
  IAssetGroup,
  IUpdatedAssetGroup,
  UpdateAssetGroupsResponse,
} from "shared/types/adLibrary";
import { IApiResponse } from "shared/types/shared";
import { searchInAssetGroup } from "utils/adLibrary.pmax";
import {
  isUnableToLoad,
  processImgAssetForUpload,
} from "./PmaxDataProvider.utils";
import { useCreateNewAssetGroup } from "shared/hooks/adLibrary/pmax/useCreateNewAssetGroup";
import { useUpdateAssetGroupStatus } from "shared/hooks/adLibrary/pmax/useUpdateAssetGroupStatus";
import { useUpdateAssetGroup } from "shared/hooks/adLibrary/pmax/useUpdateAssetGroup";
import { useUpdatePmaxAssetGroups } from "shared/hooks/adLibrary/pmax/useUpdatePmaxAssetGroups";
import useAssetGroups from "shared/hooks/adLibrary/pmax/useAssetGroups";
import useDeleteAssetGroups from "shared/hooks/adLibrary/pmax/useDeleteAssetGroups";

type FilterType = {
  displaySelectedOnly: boolean;
  search: string;
};

type PmaxDataContext = PmaxContextProps & PmaxContextHandlers;

export type PmaxLoadMode = "new" | "update";

type PmaxContextProps = {
  selectedAssetGroup?: Partial<IAssetGroup>;
  selectedRowIds: string[];
  mode: Mode;
  dataSource: IAssetGroup[];
  selectedDataSource: IAssetGroup[];
  fetchingData: boolean;
  isLoadDrawerOpen: boolean;
  isSaving: boolean;
  isUpdating: boolean;
  loadDrawerWillClose: boolean;
  dataFilters?: FilterType;
  drawerSelection: React.Key[];
  originalDataSource: IAssetGroup[];
  assetGroupSelection: CreateAssetGroupSelectionHandlers;
};

type PmaxContextHandlers = {
  setSelectedRowIds: React.Dispatch<React.SetStateAction<any[]>>;
  setMode: React.Dispatch<React.SetStateAction<Mode>>;
  setUpdatedAssetGroups: React.Dispatch<
    React.SetStateAction<
      Record<string, Partial<IUpdatedAssetGroup>> | undefined
    >
  >;
  save: () => Promise<string[] | null>;
  deleteAssetGroups: (record?: IAssetGroup) => void;
  closeLoadDrawer: () => void;
  openLoadDrawer: (record?: React.Key) => void;
  onUpdateGoogleAds: () => void;
  setDataFilters: React.Dispatch<React.SetStateAction<FilterType>>;
  filteredInfo: Record<string, FilterValue | null>;
  setFilteredInfo: React.Dispatch<Record<string, FilterValue | null>>;
  clearFilters: () => void;
  removeFilter: (key: string, value: React.Key | boolean) => void;
  setDrawerSelection: React.Dispatch<React.SetStateAction<React.Key[]>>;
  getComputedSelection: (mode: PmaxLoadMode) => IAssetGroup[];
  setEditAssetGroup: (assetGroup: Partial<IAssetGroup>, mode?: Mode) => void;
  delayCloseLoadDrawer: (delayMs: number) => void;
  duplicateAssetGroup: (assetGroup: IAssetGroup) => void;
};

const baseFilters = {
  adAccount: null,
  campaign: null,
  feedName: null,
  feedStatus: null,
  id: null,
  name: null,
  pmaxStatus: null,
} as const;

const context = React.createContext<PmaxDataContext | null>(null);

/*
 * This holds most if not all the logic for the PMAX Asset Group List
 * It has a lot of similarities with useDataList(), but it is specific to PMAX.
 * TODO: In the future, it would be nice to combine these two into one hook.
 */
export const usePMaxDataContext = () => {
  const ctx = React.useContext(context);

  if (!ctx) {
    throw new Error(
      "usePMaxDataContext must be used within a PMaxDataProvider",
    );
  }
  return ctx;
};

type Props = {
  children: ReactNode;
};

const Provider = (props: Props) => {
  const [filteredInfo, setFilteredInfo] =
    useState<Record<string, FilterValue | null>>(baseFilters);
  const [selectedAssetGroup, setSelectedAssetGroup] =
    useState<Partial<IAssetGroup>>();
  const [selectedRowIds, setSelectedRowIds] = useState<any[]>([]);
  const [mode, setMode] = useState<Mode>("idle");
  const [loadDrawerOpen, setLoadDrawerOpen] = useState(false);
  const [loadDrawerWillClose, setLoadDrawerWillClose] = useState(false);
  const { isFetching, assetGroups, selectedAssetGroups } = useAssetGroups({
    selectedIds: selectedRowIds,
  });
  const [dataFilters, setDataFilters] = useState<FilterType>({
    displaySelectedOnly: false,
    search: "",
  });

  const [drawerSelection, setDrawerSelection] = useState<Key[]>([]);

  const assetGroupSelection = useCreateNewAssetGroupSelector();

  const openLoadDrawer = (rowId?: React.Key) => {
    setDrawerSelection(rowId ? [rowId] : selectedRowIds);
    setLoadDrawerOpen(true);
  };

  const closeLoadDrawer = () => {
    setLoadDrawerOpen(false);
  };

  const delayCloseLoadDrawer = (delayMs: number) => {
    setLoadDrawerWillClose(true);
    setTimeout(() => {
      closeLoadDrawer();
      setLoadDrawerWillClose(false);
    }, delayMs);
  };

  const clearFilters = () => {
    setFilteredInfo(baseFilters);
  };

  const removeFilter = (key: string, value: boolean | React.Key) => {
    setFilteredInfo({
      ...filteredInfo,
      [key]: filteredInfo[key]!.filter(item => item !== value),
    });
  };

  const duplicateAssetGroup = useCallback((assetGroup: IAssetGroup) => {
    const newAssetGroupName = `${assetGroup.name} (COPY)`;
    const assetGroupCopy = {
      ...assetGroup,
      name: newAssetGroupName,
      id: `manual_${newAssetGroupName}`,
    };
    setEditAssetGroup(assetGroupCopy, "create");
    setUpdatedAssetGroups(prev => {
      return {
        ...prev,
        [assetGroupCopy.id]: {
          ...assetGroupCopy,
          uploads: Object.entries(assetGroupCopy.uploads).reduce(
            (acc, [key, imgAssets]) => {
              return {
                ...acc,
                [key]: {
                  added: imgAssets,
                },
              };
            },
            {},
          ),
        },
      };
    });
  }, []);

  const dataSource = useMemo(() => {
    if (!dataFilters) return assetGroups;

    const { displaySelectedOnly, search } = dataFilters;

    const isFilterBySearch = (grp: IAssetGroup) =>
      !search || searchInAssetGroup(grp, search);

    const isFilterBySelected = (grp: IAssetGroup) =>
      !displaySelectedOnly || selectedRowIds.includes(grp.id);

    return assetGroups.filter(
      grp => isFilterBySelected(grp) && isFilterBySearch(grp),
    );
  }, [dataFilters, assetGroups, selectedRowIds]);

  const { mutate: updatePmaxAssetGroupsMutation, isLoading: isUpdating } =
    useUpdatePmaxAssetGroups();
  const { mutate: deleteAssetGroupsMutation } = useDeleteAssetGroups();
  const {
    mutateAsync: createNewAssetGroupMutation,
    isLoading: isCreatingAssetGroup,
  } = useCreateNewAssetGroup();
  const {
    mutateAsync: updateAssetGroupStatusMutation,
    isLoading: isUpdatingAssetGroupStatus,
  } = useUpdateAssetGroupStatus();
  const {
    mutateAsync: updateAssetGroupMutation,
    isLoading: isUpdatingAssetGroup,
  } = useUpdateAssetGroup();

  const [updatedAssetGroups, setUpdatedAssetGroups] =
    useState<Record<string, Partial<IUpdatedAssetGroup>>>();

  const isSaving =
    isCreatingAssetGroup || isUpdatingAssetGroup || isUpdatingAssetGroupStatus;

  const save = async () => {
    if (mode === "idle") return message.error("There was a system error");
    message.loading({
      key: "create_asset_group",
      content:
        mode === "create"
          ? "Creating Asset Group..."
          : "Updating Asset Group...",
    });

    if (!updatedAssetGroups) return null;
    try {
      return Promise.all(
        Object.entries(updatedAssetGroups).map(async ([id, assetGroup]) => {
          const { uploads = {} } = assetGroup || {};
          const updatedUploads = Object.entries(uploads).reduce<
            IUpdatedAssetGroup["uploads"]
          >((acc, [key, imgAssets]) => {
            return {
              ...acc,
              [key]: processImgAssetForUpload(imgAssets),
            };
          }, {});

          /**
           * Here, we treat images differently because they are relatively larger than other attrs.
           * So in the request, only updated images are added (the images that are added or deleted).
           * But other attrs, all attrs are sent. So these have to be treated properly on the api side.
           */
          const assetGroupFromDS = assetGroups.find(item => item.id === id);
          const assetGroupToUse = {
            ...assetGroupFromDS,
            ...assetGroup,
            multiInputAsset: {
              ...(assetGroupFromDS?.multiInputAsset || {}),
              ...(assetGroup?.multiInputAsset || {}),
            },
            id,
            uploads: updatedUploads,
          };

          // NOTE!
          // When click on "New", we needed to provide empty asset group object and set it as selected.
          // When creating empty asset group, "id" must be provided so we create an uuid.
          // But on the client side, we set "id" as combination of <feed id>_<name>. We can't do this for new asset group,
          //  because <name> isnt there yet. So we need to set proper "id" here. NOTE: the <feed id> for new asset group, we decided to set it as "manual", see https://constellationagency.slack.com/archives/C03G8SNJG5A/p1664373783140879
          const savedAssetGroup =
            mode === "create"
              ? await createNewAssetGroupMutation({
                  assetGroup: {
                    ...assetGroupToUse,
                    id: `manual_${assetGroupToUse.name}`,
                  },
                  mode: "new",
                })
              : await updateAssetGroupMutation(assetGroupToUse);

          if (savedAssetGroup.error) {
            throw new Error(`${savedAssetGroup.error}`);
          }

          updateStatusIfNeeded(savedAssetGroup.result, mode);

          if (mode === "create") {
            setMode("update");
          }
          setUpdatedAssetGroups(undefined);
          return `manual_${assetGroupToUse.name}`;
        }),
      );
    } catch (err: any) {
      message.error(err?.message ?? "An error occurred while saving changes");
    }
    message.destroy("create_asset_group");
    return null;
  };

  const updateStatusIfNeeded = (
    assetGroup: IAssetGroup | null,
    currentMode: Mode,
  ) => {
    if (!assetGroup) return;

    const parsedAssetGroup = pmaxSchema.safeParse(assetGroup);

    const newFeedStatus = getFeedStatus(
      parsedAssetGroup.success,
      assetGroup.feedId,
    );

    if (currentMode === "update" && newFeedStatus !== assetGroup.feedStatus) {
      updateAssetGroupStatusMutation({
        id: assetGroup.id,
        feedStatus: newFeedStatus,
      });
    }
  };

  const getFeedStatus = (success: boolean, feedId: string) => {
    if (feedId == "manual") {
      return "Draft";
    }
    return success ? "Up to date" : "Error";
  };

  const onFinishUpdateAssetGroups = (values: IAssetGroup[]) => {
    updatePmaxAssetGroupsMutation(values, {
      onSuccess: (updatedData: IApiResponse<UpdateAssetGroupsResponse>) => {
        if (updatedData?.error) {
          message.error(
            `An error occurred while updating asset group status to Google PMax`,
          );
          setLoadDrawerOpen(false);
        } else if (updatedData?.result) {
          message.success(
            `Successfully updated asset group status to Google PMax`,
          );
          setLoadDrawerOpen(false);
        }
      },
    });
  };

  const getComputedSelection = (mode: PmaxLoadMode) => {
    return selectedAssetGroups.filter(
      grp => drawerSelection.includes(grp.id) && !isUnableToLoad(grp, mode),
    );
  };

  const onUpdateGoogleAds = async () => {
    const computedSelection = getComputedSelection("update");

    if (computedSelection.length === 0) return;

    const assetGroups = computedSelection.map(assetGroup => {
      return {
        ...assetGroup,
        pmaxStatus: "Paused",
      };
    });
    onFinishUpdateAssetGroups(assetGroups as any);
  };

  const setEditAssetGroup = (assetGroup: Partial<IAssetGroup>, mode?: Mode) => {
    // Merging the empty asset group with the selected asset group to ensure that all required fields are present, even for drafts
    setSelectedAssetGroup(merge(generateEmptyAssetGroup(), assetGroup));
    mode && setMode(mode);
  };

  return (
    <context.Provider
      value={{
        selectedAssetGroup,
        selectedRowIds,
        setSelectedRowIds,
        mode,
        setMode,
        dataSource,
        selectedDataSource: selectedAssetGroups,
        setUpdatedAssetGroups,
        fetchingData: isFetching,
        save,
        isSaving,
        deleteAssetGroups: record =>
          deleteAssetGroupsMutation(record ? [record] : selectedAssetGroups),
        onUpdateGoogleAds,
        isUpdating,
        dataFilters,
        setDataFilters,
        filteredInfo,
        setFilteredInfo,
        removeFilter,
        clearFilters,
        drawerSelection,
        setDrawerSelection,
        openLoadDrawer,
        isLoadDrawerOpen: loadDrawerOpen,
        closeLoadDrawer,
        getComputedSelection,
        setEditAssetGroup,
        originalDataSource: assetGroups,
        loadDrawerWillClose,
        delayCloseLoadDrawer,
        assetGroupSelection,
        duplicateAssetGroup,
      }}
    >
      {props.children}
    </context.Provider>
  );
};

export default Provider;
