import { IAd, IInstantExperience, IVisuals } from "shared/types/adLibrary";
import { AdType, CallToAction } from "./types";
import {
  LiveInventoryAdProtoDraft,
  AdProto,
  AdProtoDraft,
  CarouselAdProtoCard,
  CarouselAdProtoDraft,
  MediaAdProtoDraft,
} from "./adProto.types";
import { isFeatureEnabled } from "utils/helpers";
import { raise } from "utils/errorMessage";
import { IAccount } from "shared/types/accountManagement";
import { NormalizedCampaignData } from "../adLoadV2/campaignData.utils";
import { getEverythingAdWithDests } from "screens/designStudio/library/instantExperiences/generateUrlDrawer/GenerateUrlForm.utils";
import { getFirstElementForAdWithIE } from "../adLoad/adLoadDrawer/loadStep/helpers.ie";

type AdProtoProps = {
  adProtoDraft: AdProtoDraft;
  ad: IAd;
  instantExperiences: IInstantExperience[];
  store: IAccount;
  adsetsData: NormalizedCampaignData["adsets"];
};

export const getAdProto = (adProtoProps: AdProtoProps): AdProto => {
  const { adProtoDraft, ad, instantExperiences } = adProtoProps;
  const { visuals } = ad;

  const commonProps = getCommonProps(adProtoProps);

  const getVideoOrStillAdProto = (mediaAdProtoDraft: {
    callToAction: CallToAction | undefined;
    destinationUrl: string | undefined;
    displayUrl: string | undefined;
    type: AdType.Video | AdType.Still;
  }): AdProto => {
    const props = {
      ...commonProps,
      imageUrl:
        visuals.thumbnail ??
        raise(`Thumbnail not found for ad ${commonProps.name}`),
      primaryText: visuals.postCopy,
      callToAction:
        mediaAdProtoDraft.callToAction ??
        raise(`Call to action not found for ad ${commonProps.name}`),
      headline: visuals.headline,
      description: visuals.headlineDescription,
      destinationUrl:
        mediaAdProtoDraft.destinationUrl ??
        raise(`Destination url not found for ad ${commonProps.name}`),
      displayUrl: mediaAdProtoDraft.displayUrl,
      additionalPlacements: visuals.additionalPlacements,
    };

    return mediaAdProtoDraft.type === AdType.Video
      ? {
          ...props,
          type: AdType.Video,
          videoUrl:
            visuals.videoUrl ??
            raise(`Video url not found for ad ${commonProps.name}`),
        }
      : {
          ...props,
          type: AdType.Still,
        };
  };

  switch (adProtoDraft.type) {
    case AdType.Video:
    case AdType.Still: {
      return getVideoOrStillAdProto(adProtoDraft);
    }
    case AdType.Carousel: {
      return {
        ...commonProps,
        type: AdType.Carousel,
        primaryText: visuals.postCopy,
        callToAction:
          adProtoDraft.callToAction ??
          raise(`Call to action not found for ad ${commonProps.name}`),
        cards: getCarouselCards(visuals, adProtoDraft, commonProps.name),
      };
    }
    case AdType.InstantExperience:
    case AdType.Collection: {
      if (adProtoDraft.convertToVideo) {
        return getVideoOrStillAdProto({
          type: visuals.videoUrl ? AdType.Video : AdType.Still,
          callToAction: adProtoDraft.callToAction,
          destinationUrl: adProtoDraft.destinationUrl,
          displayUrl: adProtoDraft.displayUrl,
        });
      }

      const instExpId = visuals.destination?.instantExperienceId;
      const instExp =
        instantExperiences.find(ie => ie.id === instExpId) ??
        raise(`Everything ad ${instExpId} not found`);

      const isForCollection = adProtoDraft.type === AdType.Collection;

      const bodyElements = isForCollection
        ? [getFirstElementForAdWithIE(ad), ...(instExp.body_elements ?? [])]
        : instExp.body_elements;

      const everythingAd = getEverythingAdWithDests({
        instExp: {
          ...instExp,
          body_elements: bodyElements,
        },
        instantExperiences,
        urlLabels: [],
        elementDests: adProtoDraft.elementDests,
      });

      return {
        ...commonProps,
        type: adProtoDraft.type,
        everythingAd,
        primaryText: visuals.postCopy,
        headline: visuals.headline,
        description: visuals.headlineDescription,
        imageUrl:
          visuals.thumbnail ??
          raise(`Thumbnail not found for ad ${commonProps.name}`),
        videoUrl: visuals.videoUrl,
      };
    }
    case AdType.DPA:
    case AdType.AIA: {
      return {
        ...commonProps,
        type: adProtoDraft.type,
        primaryText: visuals.primaryText,
        headline: visuals.headline,
        description: visuals.headlineDescription,
        imageUrl:
          visuals.thumbnail ??
          raise(`Thumbnail not found for ad ${commonProps.name}`),
        videoUrl: visuals.videoUrl,
        callToAction:
          adProtoDraft.callToAction ??
          raise(`Call to action not found for ad ${commonProps.name}`),
        destinationUrl:
          adProtoDraft.destinationUrl ??
          raise(`Destination url not found for ad ${commonProps.name}`),
        displayUrl: adProtoDraft.displayUrl,
        format:
          visuals.format ??
          raise(`Format not found for ad ${commonProps.name}`),
        creativeOption: visuals.creativeOption,
      };
    }
    default:
      throw new Error(`Ad type ${ad.type} not supported`);
  }
};

const getCarouselCards = (
  visuals: IVisuals,
  adProtoDraft: CarouselAdProtoDraft,
  adName: string,
): CarouselAdProtoCard[] => {
  return (
    visuals.cards?.map<CarouselAdProtoCard>((card, index) => {
      const raiseDestinationUrlError = () =>
        raise(`Destination url not found for ad ${adName} card ${index + 1}`);
      const adProtoDraftCard = adProtoDraft.cards[index];

      return {
        imageUrl: card.thumbnail,
        headline: card.headline,
        description: card.description,
        destinationUrl:
          adProtoDraftCard.destinationUrl ?? raiseDestinationUrlError(),
        videoUrl: card.videoUrl,
        additionalPlacements: card.additionalPlacements?.map(
          (placement, placementIndex) => {
            const adProtoDraftCardPlacement =
              adProtoDraftCard.additionalPlacements?.[placementIndex];
            const destinationUrl =
              adProtoDraftCardPlacement?.destinationUrl ??
              raiseDestinationUrlError();
            const isVideoCard = "videoUrl" in placement;

            return {
              headline: placement.headline,
              description: placement.description,
              thumbnailUrl: placement.thumbnail,
              destinationUrl: destinationUrl,
              videoUrl: isVideoCard ? placement.videoUrl : undefined,
              videoId: isVideoCard ? placement.assetId : undefined,
              imageUrl: !isVideoCard ? placement.thumbnail : undefined,
            };
          },
        ),
      };
    }) ?? []
  );
};

export const getCustomAdName = ({
  adName,
  adsetName,
  adFormat,
}: {
  adName: string;
  adsetName: string;
  adFormat: AdType;
}) => {
  const concatNameEnabled = isFeatureEnabled("ENABLE_AD_TO_LOAD_CONCAT_NAME");

  if (!concatNameEnabled) return adName;

  return `${adsetName}_${adFormat}_${adName}`;
};

const getCommonProps = ({
  ad,
  store,
  adProtoDraft,
  adsetsData,
}: AdProtoProps) => {
  const { inputParameters } = ad;
  const accountId =
    store?.details?.facebook?.fbAccountId ??
    raise(`Account for ${store.dealer_name} not found`);
  const pageId =
    store?.details?.facebook?.fbPageId ??
    raise(`Page for ${store.dealer_name} not found`);
  const pixelId = store?.details?.facebook?.fbPixelId;

  return {
    id: ad.id,
    name:
      inputParameters.name ??
      raise(`Ad name not found for ad with id ${ad.id}`),
    utm: inputParameters.utm,
    accountId,
    dests: adProtoDraft.dests.map(dest => {
      const adsetName = adsetsData[dest.adsetId]?.name;

      const adFormat =
        "convertToVideo" in adProtoDraft && adProtoDraft.convertToVideo
          ? AdType.Video
          : ad.type;

      const customAdName = getCustomAdName({
        adName: inputParameters.name ?? "",
        adFormat,
        adsetName,
      });

      return {
        ...dest,
        customAdName,
      };
    }),
    pageId,
    pixelId,
  } satisfies Partial<AdProto>;
};

export function getAdProtoDraftPredicate({
  adId,
  storeName,
}: {
  adId: string;
  storeName: string;
}) {
  return (adProtoDraft: AdProtoDraft) => {
    return adProtoDraft.id === adId && adProtoDraft.storeName === storeName;
  };
}

export const findAdProtoDraft = ({
  adProtoDrafts,
  adId,
  storeName,
}: {
  adProtoDrafts: AdProtoDraft[];
  adId: string;
  storeName: string;
}) => {
  return (
    adProtoDrafts.find(getAdProtoDraftPredicate({ adId, storeName })) ??
    raise(`The session data for the ad with id ${adId} was not found`)
  );
};

export const hasCallToAction = (
  protoDraft: AdProtoDraft,
): protoDraft is
  | MediaAdProtoDraft
  | CarouselAdProtoDraft
  | LiveInventoryAdProtoDraft => {
  return [
    AdType.Video,
    AdType.Still,
    AdType.Carousel,
    AdType.AIA,
    AdType.DPA,
  ].includes(protoDraft.type);
};
