import { WarningFilled, FileImageOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { ImagePreviewCell } from "screens/adLibrary/adLoadV2/adLoadDrawer/reviewStep/adLoadReviewTable/columns/ImagePreviewCell";

export const MediaCell = ({
  thumbnail,
}: {
  thumbnail?: "loading" | "error" | string;
}) => {
  if (!thumbnail) return null;

  switch (thumbnail) {
    case "error":
      return (
        <Tooltip title="Error uploading media, try again after import">
          <WarningFilled style={{ color: "#FF4D4F", fontSize: 22 }} />
        </Tooltip>
      );
    case "403":
      return (
        <Tooltip title="You do not have permission to view this link, it is incorrect, or has expired">
          <WarningFilled style={{ color: "#FF4D4F", fontSize: 22 }} />
        </Tooltip>
      );

    case "loading":
      return (
        <FileImageOutlined
          style={{ fontSize: 22, color: "rgba(0, 0, 0, 0.25)" }}
        />
      );
    default:
      return <ImagePreviewCell thumbnail={thumbnail} legend={""} size={22} />;
  }
};
