import { Col, Row, Typography } from "antd";
import { PropsWithChildren } from "react";
import styles from "./CommonDrawer.module.scss";
import localStyles from "./FieldWrapper.module.scss";

type InputLabelProps = PropsWithChildren<{
  label?: string;
  width?: number;
  required?: boolean;
  customStyle?: string;
}>;

export const FieldWrapper = ({
  label,
  width,
  children,
  required,
  customStyle,
}: InputLabelProps) => {
  return (
    <Col
      span={!width ? 20 : width}
      className={customStyle ? `${customStyle}` : styles.field}
    >
      <Row>
        {label && (
          <Col span={24} className={customStyle ? customStyle : ""}>
            <Typography.Text>{label}</Typography.Text>
            {required && (
              <Typography.Text className={localStyles.requiredMark}>
                *
              </Typography.Text>
            )}
          </Col>
        )}
        <Col span={24}>{children}</Col>
      </Row>
    </Col>
  );
};
