import { Steps } from "antd";
import {
  assetNamingEnabled,
  videoCompositionEnabled,
} from "shared/constants/assetExporter";
import {
  CREATE_BATCH_TITLES,
  CREATE_BATCH_TITLES_V2,
  CREATE_BATCH_TITLES_V3,
  STEP_CREATE_BATCH,
  STEP_CREATE_BATCH_V2,
} from "shared/types/assetExporter";
import { useAssetBatchesContext } from "../shared/contexts/AssetBatchesContext";
import { useFeedContext } from "../shared/contexts/FeedContext";
import styles from "./StepsConfig.module.scss";

const { Step } = Steps;

type AssetBatchStepsProps = {
  step: number;
  maxStep: number;
  onChangeStep: (step: STEP_CREATE_BATCH | STEP_CREATE_BATCH_V2) => void;
  selectedItems?: number;
};

const getTitles = () => {
  if (!videoCompositionEnabled) return CREATE_BATCH_TITLES;
  if (!assetNamingEnabled) return CREATE_BATCH_TITLES_V2;
  return CREATE_BATCH_TITLES_V3;
};

export const AssetBatchSteps = ({
  step,
  selectedItems,
  onChangeStep,
}: AssetBatchStepsProps) => {
  const {
    getStepStatus,
    getStepsDisabled,
    maxStep,
    isTemporalRemovedComposition,
  } = useAssetBatchesContext();
  const { assetBatchDrawerMode } = useFeedContext();
  const titles = getTitles();

  return (
    <Steps
      direction="vertical"
      size="small"
      current={step}
      className={styles.steps}
    >
      {titles.map((currStep, idx) => {
        const status = getStepStatus(step, idx);
        const isDisabled =
          isTemporalRemovedComposition ||
          (assetBatchDrawerMode === "Create"
            ? getStepsDisabled(idx) || idx > maxStep
            : getStepsDisabled(idx));

        return (
          <Step
            key={currStep}
            title={currStep}
            description={
              currStep === CREATE_BATCH_TITLES[0] && selectedItems
                ? `${selectedItems} matching rows`
                : undefined
            }
            onClick={() => !isDisabled && onChangeStep(idx)}
            disabled={isDisabled}
            status={status}
          />
        );
      })}
    </Steps>
  );
};
