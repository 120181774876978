import { FolderOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Ellipsis } from "../../Ellipsis";
import { useQPBreadcrumb } from "../../useQPBreadcrumb";
import { EditableCell } from "../EditableCell";

import styles from "./Folder.module.scss";
import { FolderMenu } from "./FolderMenu";
import { useState } from "react";

type Props = {
  resource: WDFolder;
};

export const Folder = ({ resource }: Props) => {
  const [hover, setHover] = useState(false);
  const { toFolder } = useQPBreadcrumb();

  return (
    <div
      data-visible={hover}
      className={styles.host}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Link to={{ search: toFolder(resource.id) }}>
        <div className={styles.icon}>
          <FolderOutlined />
        </div>
        <EditableCell
          editable
          title="Name"
          element="div"
          dataIndex="name"
          record={resource}
        >
          <Ellipsis>{resource.name}</Ellipsis>
        </EditableCell>
      </Link>
      <div>
        {hover ? <FolderMenu record={resource} visible={true} /> : null}
      </div>
    </div>
  );
};
