import { Form } from "antd";
import { AllEntryTypes } from "../../AssignDestsTable.types";
import { useSetCurrentSession } from "screens/adLibrary/adLoadV2/hooks/useCurrentSession";
import { getRecordIssue } from "../recordIssues.utils";
import { useAdLoadIssues } from "screens/adLibrary/adLoadV2/hooks/useAdLoadIssues";
import CtaButtonSelect from "shared/components/select/CtaButtonSelect";
import { CallToAction } from "screens/adLibrary/facebookUtils/types";
import {
  findAdProtoDraft,
  hasCallToAction,
} from "screens/adLibrary/facebookUtils/adProto";

type Props = {
  record: AllEntryTypes;
};

const RecordCtaButtonSelect = ({ record }: Props) => {
  const { setSession } = useSetCurrentSession();
  const { issuesByField } = useAdLoadIssues();

  if (!("ctaButton" in record)) return null;
  const issue = getRecordIssue({
    record,
    issues: issuesByField["callToAction"] ?? [],
    field: "callToAction",
  });

  const onChange = (cta: CallToAction) => {
    setSession(prevSession => {
      const protoDraft = findAdProtoDraft({
        adProtoDrafts: prevSession.data.adProtoDrafts,
        adId: record.adId,
        storeName: record.storeName,
      });

      if (hasCallToAction(protoDraft)) {
        protoDraft.callToAction = cta;
      }
    });
  };

  return (
    <Form.Item style={{ margin: 0 }} validateStatus={issue?.status}>
      <CtaButtonSelect value={record.ctaButton} onChange={onChange} />
    </Form.Item>
  );
};

export default RecordCtaButtonSelect;
