import { Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import { startCase } from "lodash";
import AdNameCell from "screens/adLibrary/adLoadV2/shared/AdNameCell";
import { terms } from "utils/terms";
import { AdLoadReviewTableEntry } from "../AdLoadReviewTable";
import { useNormalizedCampaignsData } from "screens/adLibrary/adLoadV2/hooks/useNormalizedCampaignsData";
import { getFiltersFromData, onFilterBy } from "utils/helpers.table";
import ProductCatalogName from "./columns/ProductCatalogName";
import ProductSetName from "./columns/ProductSetName";
import LinkToStep from "./columns/LinkToStep";
import { useFilteredProtosDrafts } from "screens/adLibrary/adLoadV2/hooks/useFilteredProtosDrafts";
import StoreTag from "screens/adLibrary/adLoadV2/shared/StoreTag";
import { ImagePreviewCell } from "./columns/ImagePreviewCell";
import { AdType } from "screens/adLibrary/facebookUtils/types";

export const useColumns = (
  dataSource: AdLoadReviewTableEntry[],
): ColumnsType<AdLoadReviewTableEntry> => {
  const { campaignData } = useNormalizedCampaignsData();
  const { adProtoDraftsWithDestUrls, adProtoDraftsWithProductSets } =
    useFilteredProtosDrafts();
  const hasDestUrls = adProtoDraftsWithDestUrls.length > 0;
  const hasProductSets =
    adProtoDraftsWithProductSets.length > 0 ||
    dataSource.some(entry => entry.type === AdType.AIA);

  const getFilters = getFiltersFromData(dataSource);

  const destUrlsColumns: ColumnsType<AdLoadReviewTableEntry> = [
    {
      title: "Destination URL",
      dataIndex: "destinationUrls",
      key: "destinationUrls",
      width: 250,
      ellipsis: true,
      render: (_, record) => {
        const count = record.destinationUrls?.length ?? 0;
        return (
          <LinkToStep step="destinations">
            {count > 1 ? `Multiple (${count})` : record.destinationUrls?.[0]}
          </LinkToStep>
        );
      },
    },
    {
      title: "Display URL",
      dataIndex: "displayUrl",
      key: "displayUrl",
      width: 250,
      ellipsis: true,
      render: (_, record) => {
        return <LinkToStep step="destinations">{record.displayUrl}</LinkToStep>;
      },
    },
    {
      title: "CTA Button",
      dataIndex: "ctaButton",
      key: "ctaButton",
      width: 150,
      ellipsis: true,
      render: (_, record) => {
        return <LinkToStep step="destinations">{record.ctaButton}</LinkToStep>;
      },
    },
  ];

  const productSetsColumns: ColumnsType<AdLoadReviewTableEntry> = [
    {
      title: "Product Catalog",
      dataIndex: "productCatalogs",
      key: "productCatalogs",
      width: 250,
      render: (_, record) => {
        const productCatalogIds = record.productSets.map(
          productSet => productSet.productCatalogId,
        );

        if (record.type == AdType.AIA)
          return <ProductCatalogName productCatalogIds={productCatalogIds} />;

        return (
          <LinkToStep step="productSets">
            <ProductCatalogName productCatalogIds={productCatalogIds} />;
          </LinkToStep>
        );
      },
    },
    {
      title: "Product Set",
      dataIndex: "productSets",
      key: "productSets",
      width: 250,
      render: (_, record) => {
        if (record.type === AdType.AIA)
          return (
            <ProductSetName
              productSetEntries={record.productSets}
              showProductCount
            />
          );
        return (
          <LinkToStep step="productSets">
            <ProductSetName productSetEntries={record.productSets} />
          </LinkToStep>
        );
      },
    },
  ];

  return [
    {
      title: "Ad name",
      dataIndex: "name",
      key: "name",
      width: 250,
      render: (_, record) => {
        return <AdNameCell thumbnail={record.thumbnail} name={record.name} />;
      },
      fixed: "left",
      filters: getFilters("name"),
      onFilter: onFilterBy("name"),
    },
    {
      title: "Format",
      dataIndex: "type",
      key: "type",
      width: 150,
      render: startCase,
      filters: getFilters("type"),
      onFilter: onFilterBy("type"),
    },
    {
      title: "In-Feed",
      dataIndex: "thumbnail",
      key: "thumbnail",
      width: 120,
      render: (_, record) => {
        return <ImagePreviewCell thumbnail={record.thumbnail} legend={"1:1"} />;
      },
    },
    {
      title: "Story",
      dataIndex: "storyThumbnail",
      key: "storyThumbnail",
      width: 120,
      render: (_, record) => {
        return (
          record.storyThumbnail && (
            <ImagePreviewCell
              thumbnail={record.storyThumbnail}
              legend={"9:16"}
            />
          )
        );
      },
    },
    {
      title: terms.accountName,
      dataIndex: "storeName",
      key: "storeName",
      width: 200,
      render: (_, record) => {
        return <StoreTag storeName={record.storeName} />;
      },
      filters: getFilters("storeName"),
      onFilter: onFilterBy("storeName"),
    },
    {
      title: "Campaign Name",
      dataIndex: "campaignId",
      key: "campaignId",
      width: 250,
      render: (_, record) => {
        const campaignName = campaignData.campaigns[record.campaignId]?.name;
        return <Tag>{campaignName}</Tag>;
      },
      filters: getFilters("campaignId").map(campaign => ({
        ...campaign,
        text: campaignData.campaigns[campaign.value]?.name,
      })),
      onFilter: onFilterBy("campaignId"),
    },
    {
      title: "Ad Set Name",
      dataIndex: "adsetId",
      key: "adsetId",
      width: 250,
      render: (_, record) => {
        const adsetName = campaignData.adsets[record.adsetId]?.name;
        return <Tag>{adsetName}</Tag>;
      },
      filters: getFilters("adsetId").map(adset => ({
        ...adset,
        text: campaignData.adsets[adset.value]?.name,
      })),
      onFilter: onFilterBy("adsetId"),
    },
    ...(hasDestUrls ? destUrlsColumns : []),
    ...(hasProductSets ? productSetsColumns : []),
  ];
};
