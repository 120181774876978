import { SelectProps } from "antd";
import { FilterBy } from "shared/components/FilterByInput/FIlterByInput";
import { SelectValue } from "antd/lib/select";
import styles from "./Filter.module.scss";
import { SortedSelect } from "shared/components/select/SortedSelect";

type SelectFilterProps<T> = {
  title: string;
  children?: never;
} & SelectProps<T>;

export const SelectFilter = <T extends SelectValue>({
  title,
  ...rest
}: SelectFilterProps<T>) => {
  return (
    <FilterBy title={title}>
      <SortedSelect
        id={title}
        className={styles.filterInput}
        allowClear
        {...rest}
      />
    </FilterBy>
  );
};
