import {
  IQueryParameters,
  RawOfferData,
  OfferData,
  ILauncherData,
  IPDFData,
  ICoopIntegrationData,
  IZipExport,
  IRawOfferDataFromService,
  TOfferListQueryParameters,
  ISavedOrderState,
  IVideoParams,
  IThemeBackgroundImage,
  IWorkfrontProofData,
  IOfferListResponse,
  IOffer,
  FeedData,
  FeedTab,
  GetOfferExists,
  CreateEditOffer,
  DealerDataFeed,
  Tab,
} from "shared/types/assetBuilder";
import { IConfig } from "shared/types/configuration";
import { IStamp } from "shared/types/designStudio";
import { IApiResponse, OfferType } from "shared/types/shared";
import { PaymentEngineRequestBody } from "shared/types/paymentEngine";
import { INewOrder } from "shared/types/newOrders";
import { AssetExportQueryParams } from "shared/types/assetExport";
import {
  formatRateAsDecimal,
  formatRateAsPercentage,
} from "utils/helpers.offer";
import HttpClient from "./httpClient";
import { TOfferListSection } from "screens/assetBuilder/offers/select.hooks/useOfferList";
import { UseGetTabData } from "shared/hooks/assetBuilder/useGetTabData";
import { IPostThemeImagesResponse } from "screens/designStudio/artboardDrawer/types";
import { removeSpaces } from "utils/helpers";

export default ({ headers, config }: { headers: any; config: IConfig }) => ({
  getWebIntegrationCode: <T>(website: string, id: string): Promise<T> => {
    const { services } = config;

    const url = services.assetBuilder.getWebIntegrationCodeUrl({ website, id });
    return fetch(url, {
      method: "GET",
      headers,
    }).then<T>(response => {
      return response.json();
    });
  },

  getWebIntegrations: <T>(domain: string): Promise<T> => {
    const { services } = config;

    const url = services.assetBuilder.getWebIntegrationsUrl({ domain });
    return fetch(url, {
      method: "GET",
      headers,
    }).then<T>(response => {
      return response.json();
    });
  },

  createWebIntegration: <T>(webIntegrationData: ILauncherData): Promise<T> => {
    const { services } = config;

    return fetch(services.assetBuilder.createWebIntegrationUrl, {
      method: "POST",
      body: JSON.stringify({
        webIntegrationData,
      }),
      headers,
    }).then<T>(response => {
      return response.json();
    });
  },

  deleteWebIntegration: <T>(webIntegrationData: ILauncherData): Promise<T> => {
    const { services } = config;

    return fetch(services.assetBuilder.createWebIntegrationUrl, {
      method: "DELETE",
      body: JSON.stringify({
        webIntegrationData,
      }),
      headers,
    }).then<T>(response => {
      return response.json();
    });
  },

  getWebIntegrationStatus: <T>(
    domain: string,
    widgetURL: string,
  ): Promise<T> => {
    const { services } = config;
    const url = services.assetBuilder.getWebIntegrationStatusUrl({
      domain,
      widgetURL,
    });
    return fetch(url, {
      method: "GET",
      headers,
    }).then<T>(response => {
      return response.json();
    });
  },

  getRawOfferByVin: <T>(vin: string): Promise<T> => {
    const { services } = config;
    return fetch(services.getRawOfferByVinUrl(vin), {
      method: "GET",
      headers,
    })
      .then<T>(async response => {
        const { result } = await response.json();
        const { status } = response;

        if (status !== 200) {
          return {
            error: {
              message: "There was an error while requesting raw offer.",
            },
          } as any;
        }

        const resultTransformed = {
          ...result,
          offerEdit: {
            ...result.offerEdit,
            aprRate: formatRateAsPercentage(result.offerEdit?.aprRate),
            financeRate: formatRateAsPercentage(result.offerEdit?.financeRate),
          },
          rawOffer: {
            ...result.rawOffer,
            row: {
              ...result.rawOffer.row,
              aprRate: formatRateAsPercentage(result.rawOffer?.row?.aprRate),
              financeRate: formatRateAsPercentage(
                result.rawOffer?.row?.financeRate,
              ),
            },
          },
        };

        // result contains: {rawOffer}
        return {
          result: resultTransformed,
        };
      })
      .catch<T>(async () => {
        return {
          error: {
            message: "The Raw offer could not be retrieved",
          },
        } as any;
      });
  },
  fetchRawOfferByVin: <T>(vin: string): Promise<T> => {
    const { services } = config;
    return HttpClient.get(services.getRawOfferByVinUrl(vin));
  },

  fetchSelectedOfferList: (selectedOfferVins: string[]) => {
    const { services } = config;
    const url = services.fetchSelectedOfferListUrl(selectedOfferVins);

    return HttpClient.get<IOfferListResponse>(url)
      .then(response => {
        const { result } = response;

        const { selectedOfferList, flaggedVins } = result;

        const selectedOfferListTransformed: IOffer[] = selectedOfferList.map(
          (offer: any) => ({
            ...offer,
            row: {
              ...offer.row,
              aprRate: formatRateAsPercentage(offer.row.aprRate),
              financeRate: formatRateAsPercentage(offer.row.financeRate),
            },
          }),
        );

        return {
          result: {
            selectedOfferList: selectedOfferListTransformed,
            flaggedVins,
          },
          error: null,
        };
      })
      .catch(() => {
        return {
          result: null,
          error: {
            message: "Offer list cannot be retrieved at this moment.",
          },
        };
      });
  },

  fetchSelectedOfferListV2: <T>(selectedOfferVins: string[]): Promise<T> => {
    const { services } = config;
    return HttpClient.get<{
      result: {
        selectedOfferList: IRawOfferDataFromService[];
        flaggedVins: string[];
      };
    }>(services.fetchSelectedOfferListUrl(selectedOfferVins))
      .then<T>(async response => {
        const { selectedOfferList, flaggedVins } = response.result;

        const selectedOfferListTransformed = selectedOfferList.map(
          (offer: any) => ({
            ...offer,
            row: {
              ...offer.row,
              aprRate: formatRateAsPercentage(offer.row.aprRate),
              financeRate: formatRateAsPercentage(offer.row.financeRate),
            },
          }),
        );

        return {
          selectedOfferList: selectedOfferListTransformed,
          flaggedVins,
        } as any;
      })
      .catch<T>(async () => {
        return {
          error: {
            message: "Offer list cannot be retrieved at this moment.",
          },
        } as any;
      });
  },

  fetchOfferList: <T>(parameters: IQueryParameters): Promise<T> => {
    const { services } = config;
    return fetch(services.fetchOfferListUrl(parameters), {
      method: "GET",
      headers,
    })
      .then<T>(async response => {
        const { result } = await response.json();
        const { status } = response;

        if (status !== 200) {
          return {
            error: {
              message: "There was an error while requesting offer list.",
            },
          } as any;
        }

        const { total, offerList } = result;

        const offerListTransformed = offerList.map((offer: any) => ({
          ...offer,
          row: {
            ...offer.row,
            aprRate: formatRateAsPercentage(offer.row.aprRate),
            financeRate: formatRateAsPercentage(offer.row.financeRate),
          },
        }));

        return {
          result: {
            total,
            offerList: offerListTransformed,
          },
        } as any;
      })
      .catch<T>(async () => {
        return {
          error: {
            message: "Offer list cannot be retrieved at this moment.",
          },
        } as any;
      });
  },
  fetchOfferListV2: <T>(parameters: TOfferListQueryParameters): Promise<T> => {
    const { services } = config;

    return HttpClient.get<any>(services.fetchOfferListUrlV2(parameters))
      .then<T>(async ({ result }) => {
        const { total, offerList } = result;

        const offerListTransformed = offerList.map((offer: any) => ({
          ...offer,
          row: {
            ...offer.row,
            aprRate: formatRateAsPercentage(offer.aprRate),
            financeRate: formatRateAsPercentage(offer.financeRate),
          },
        }));

        return {
          result: {
            ...(result || {}),
            total,
            offerList: offerListTransformed,
          },
        } as any;
      })
      .catch<T>(async () => {
        return {
          error: {
            message: "Offer list cannot be retrieved at this moment.",
          },
        } as any;
      });
  },
  updateOrder: (savedOrder: ISavedOrderState) => {
    const { services } = config;
    const {
      assetBuilder: { orderStateUrl },
    } = services;
    return HttpClient.post(orderStateUrl, savedOrder);
  },
  editOffer: async (
    edit: RawOfferData,
    editedKeys: Record<string, boolean>,
  ) => {
    const { services } = config;

    const editTransformed = {
      ...edit,
      aprRate: formatRateAsDecimal(edit.aprRate),
      financeRate: formatRateAsDecimal(edit.financeRate),
    };

    return fetch(services.offerEditUrl, {
      method: "PUT",
      body: JSON.stringify({
        edit: editTransformed,
        editedKeys,
      }),
      headers,
    }).then<Response>(async response => {
      const { result } = await response.json();
      const { status } = response;

      if (status !== 200) {
        return {
          error: {
            message: "There was an error while requesting offer list.",
          },
        };
      }
      return {
        result,
      } as any;
    });
  },
  fetchStampData: async (id: string, offerType: OfferType) => {
    const { services } = config;

    return fetch(
      `${services.fetchStampUrl}?id=${encodeURIComponent(
        id,
      )}&offerType=${encodeURIComponent(offerType)}`,
      {
        method: "GET",
        headers,
      },
    ).then(async response => {
      const { result } = await response.json();
      const { status } = response;

      if (status !== 200) {
        return {
          error: {
            message: "There was an error while fetching stamp data",
          },
        };
      }

      return {
        result: result as { stamp: IStamp },
      };
    });
  },
  generateCanvasZipUrl: <T>(
    images: string[],
    wfID: string,
    store233: string,
  ): Promise<T> => {
    const { services } = config;
    return fetch(services.generateCanvasUrl, {
      method: "POST",
      body: JSON.stringify({
        canvasImages: {
          images,
          wfID,
          store233,
        },
      }),
      headers,
    }).then<T>(response => {
      return response.json();
    });
  },

  generatePDF: <T>(
    assets: IPDFData[],
    userName: string,
    projectName: string,
    currentDate: string,
  ): Promise<T> => {
    const { services } = config;

    return fetch(services.assetBuilder.generatePDF, {
      method: "POST",
      body: JSON.stringify({
        pdfData: {
          assets,
          userName,
          projectName,
          currentDate,
        },
      }),
      headers,
    }).then<T>(response => {
      return response.json();
    });
  },

  feedDataToPDF: <T>(
    feedData: {
      selectedOffers: string;
      offerData: OfferData;
      finalDisclosure: string;
    }[],
    userName: string,
    projectName: string,
  ): Promise<T> => {
    const { services } = config;
    return fetch(services.assetBuilder.feedDataToPDF, {
      method: "POST",
      body: JSON.stringify({
        pdfData: {
          feedData,
          userName,
          projectName,
        },
      }),
      headers,
    }).then<T>(response => {
      return response.json();
    });
  },

  coopSubmission: <T>(
    filename: string,
    coopData: ICoopIntegrationData,
    order: INewOrder,
  ): Promise<T> => {
    const { services } = config;
    return fetch(services.assetBuilder.coopSubmission, {
      method: "POST",
      body: JSON.stringify({
        filename,
        coopData,
        order,
      }),
      headers,
    }).then<T>(response => {
      return response.json();
    });
  },

  uploadCanvasImage: <T>(
    canvas: string,
    templateAndDimensions: string,
  ): Promise<T> => {
    const { services } = config;
    return fetch(services.uploadCanvasImageUrl, {
      method: "POST",
      body: JSON.stringify({
        canvas64Data: {
          canvas,
          templateAndDimensions,
        },
      }),
      headers,
    }).then<T>(response => {
      return response.json();
    });
  },

  exportToImageAndVideo: <T>(
    body: IZipExport | IVideoParams,
    jobId: string,
  ): Promise<T> => {
    const { services } = config;
    const url = services.exportToImageAndVideo;
    return HttpClient.post(url, { canvasData: body, jobId });
  },

  feedDataToCSV: <T>(
    feedData: { offerData: OfferData; finalDisclosure: string }[],
    orderID: string,
  ): Promise<T> => {
    const { services } = config;
    return fetch(services.assetBuilder.feedDataToCSV, {
      method: "POST",
      body: JSON.stringify({
        feedData,
        orderID,
      }),
      headers,
    }).then<T>(response => {
      return response.json();
    });
  },

  uploadAssetToWF: <T>(
    canvas: string,
    templateAndDimensions: string,
    wfProject: string,
  ): Promise<T> => {
    const { services } = config;
    return fetch(services.uploadAssetToWF, {
      method: "POST",
      body: JSON.stringify({
        wfInfo: {
          canvas,
          templateAndDimensions,
          wfProject,
        },
      }),
      headers,
    }).then<T>(response => {
      return response.json();
    });
  },

  proofExportData: <T>(orderId: string): Promise<T> => {
    const { services } = config;
    const url = services.assetBuilder.proofExportData({
      orderId,
    });

    return HttpClient.get(url);
  },

  pushToProof: <T>(
    canvases: string[],
    wfProject: string,
    wfID: string,
    templateID: string,
    subject: string,
    proofMessage: string,
    stageAndRecipientString: string,
    folderID: string,
    parentFileToken: string,
    documentID: string,
    updateOrder: INewOrder,
    csvName: string,
    pdfName: string,
    proofName: string,
    selectedProofArr: IWorkfrontProofData | null,
    selectedProofKey: string | null,
  ): Promise<T> => {
    const { services } = config;
    return fetch(services.assetBuilder.pushToProof, {
      method: "POST",
      body: JSON.stringify({
        canvasImages: {
          images: canvases,
          wfProject,
          wfID,
          templateID,
          newOrder: updateOrder,
          messageObj: {
            subject,
            message: `<span>${proofMessage}</span><br />`,
          },
          selectedProofArr: selectedProofArr
            ? JSON.stringify(selectedProofArr)
            : "[]",
          selectedProofKey,
          stageAndRecipientString,
          folderID,
          parentFileToken,
          documentID,
          csvName,
          pdfName,
          proofName,
        },
      }),
      headers,
    }).then<T>(response => {
      return {
        result: { response },
      } as any;
    });
  },

  duplicateOrderState: <T>(
    originalOrderId: string,
    newOrderId: string,
    resetToFeedData: boolean,
  ): Promise<T> => {
    const { services } = config;
    return fetch(services.assetBuilder.duplicateOrderState, {
      method: "POST",
      body: JSON.stringify({
        originalOrderId,
        newOrderId,
        resetToFeedData,
      }),
      headers,
    }).then<T>(response => {
      return response.json();
    });
  },

  getProofTemplates: <T>(): Promise<T> => {
    const { services } = config;

    const getWFTemplatesUrl = services.wfTemplateUrl;
    return fetch(getWFTemplatesUrl, {
      method: "GET",
      headers,
    }).then<T>(response => {
      return response.json();
    });
  },

  getStagesAndRecipients: <T>(templateID: string): Promise<T> => {
    const { services } = config;

    const getWFTemplatesUrl = services.wfTemplateUrl;
    return fetch(getWFTemplatesUrl, {
      method: "POST",
      body: JSON.stringify({
        templateID,
      }),
      headers,
    }).then<T>(response => {
      return response.json();
    });
  },

  getWorkfrontFolders: <T>(wfID: string, wfProject: string): Promise<T> => {
    const { services } = config;

    const getWorkfrontFoldersUrl = services.assetBuilder.getWorkfrontFolders;
    return fetch(getWorkfrontFoldersUrl, {
      method: "POST",
      body: JSON.stringify({
        wfID,
        wfProject,
      }),
      headers,
    }).then<T>(response => {
      return response.json();
    });
  },

  // type is any in this case because the data that we send to dealer science is different based on what case it is
  getPaymentEngineData: <T>(
    paymentEngineRequestBody: PaymentEngineRequestBody,
  ): Promise<T> => {
    const { services } = config;

    const getPaymentEngineDataUrl = services.assetBuilder.getPaymentEngineData;
    return fetch(getPaymentEngineDataUrl, {
      method: "POST",
      body: JSON.stringify({ ...paymentEngineRequestBody }),
      headers,
    }).then<T>(response => {
      return response.json();
    });
  },

  getTransactabilityScores: <T>(vin: string): Promise<T> => {
    const url = `${config.services.assetBuilder.getTransactabilityScores}/${vin}`;
    return HttpClient.get<T>(url);
  },

  // change to optional year make model
  getJellybeanImages: <T>(searchObj: {
    year: string;
    make: string;
    model: string;
  }): Promise<T> => {
    const { services } = config;
    const { year, make, model } = searchObj || {
      year: "",
      make: "",
      model: "",
    };
    const getJellybeanImagesUrl = services.assetBuilder.getJellybeanImages;
    return HttpClient.get<T>(
      `${getJellybeanImagesUrl}?year=${year}&make=${make}&model=${model}`,
    );
  },

  exportForBatchToS3: <T>(queryParams: AssetExportQueryParams): Promise<T> => {
    const { services } = config;

    const exportForBatchUrl = services.assetBuilder.exportForBatchToS3Url;
    return fetch(exportForBatchUrl, {
      method: "POST",
      body: JSON.stringify(queryParams),
      headers,
    }).then<T>(response => {
      return response.json();
    });
  },

  updateOfferFields: (args: {
    vin?: string;
    orderId?: string;
    keyValues: KeyValues;
    sectionKey: TOfferListSection;
  }) => {
    const { services } = config;
    // NOTE: the returning response will be either no content or error object.
    //       The error object is there for developers to take a look.
    //       The status codes will be bad_request if anything goes bad. So will go to catch block and update UI.
    const body = {
      orderId: args.orderId,
      keyValues: args.keyValues,
      sectionKey: args.sectionKey,
    };
    return HttpClient.put(
      services.assetBuilder.getUpdateSingleFieldUrl(args.vin!),
      body,
    );
  },

  deleteOfferEdits: async (vin: string) => {
    const { services } = config;
    const url = `${services.assetBuilder.getOfferEditsUrl(vin)}`;

    return (await HttpClient.delete(url)) as {
      result: Partial<OfferData> | null;
      error: { message: string } | null;
    };
  },

  saveOfferEdits: async (vin: string, offerEdits: Partial<OfferData>) => {
    const { services } = config;
    const url = `${services.assetBuilder.getOfferEditsUrl(vin)}`;

    return HttpClient.post(url, offerEdits);
  },

  fetchOrderState: async (
    id: string,
  ): Promise<{ result: ISavedOrderState; error: { message: string } }> => {
    const {
      services: { assetBuilder },
    } = config;
    const url = `${assetBuilder.orderStateUrl}?orderId=${id}`;
    return HttpClient.get(url);
  },
  getLifeStyleImages: async (ids?: string) => {
    const { services } = config;
    const url = services.assetBuilder.fetchThemeBackgroundImageUrl;
    const { result, error } = await HttpClient.post<IPostThemeImagesResponse>(
      url,
      { ids },
      { cache: "no-cache" },
    );

    if (error || !result?.backgroundThemeImages) {
      throw Error(error.message || "Unable to fetch theme images");
    }

    const allBgImages: IThemeBackgroundImage[] = [];
    allBgImages.push(...(result.backgroundThemeImages || []));

    while (result.lastEvaluatedId) {
      const res = await HttpClient.post<IPostThemeImagesResponse>(
        url,
        { ids, lastId: result.lastEvaluatedId },
        { cache: "no-cache" },
      );

      allBgImages.push(...(res.result.backgroundThemeImages || []));
      result.lastEvaluatedId = res.result.lastEvaluatedId || undefined;
    }
    return allBgImages.map(bgImg => {
      const { url } = bgImg;
      const isVideo = url.endsWith("mp4");
      const thumbUrl = isVideo ? url.replace("mp4", "png") : url;
      return {
        ...bgImg,
        uid: bgImg.id,
        thumbUrl,
      };
    });
  },
  getFeedOrder: (args: AssetExportQueryParams) => {
    const { services } = config;
    const queryParams = new URLSearchParams(args).toString();

    const url = `${services.assetBuilder.feedOrderUrl}?${queryParams}`;
    return HttpClient.get<{
      result: ISavedOrderState & DealerDataFeed;
      error: { message: string };
    }>(url);
  },
  getTabs: (orderId: string) => {
    const { services } = config;
    const url = `${services.assetExporter.getTabs}?orderId=${orderId}`;
    return HttpClient.get<IApiResponse<FeedTab[]>>(url);
  },
  getTabsV2: (orderId: string) => {
    const { services } = config;
    const url = `${services.assetExporter.getTabs}/orders/${orderId}`;
    return HttpClient.get<IApiResponse<{ selected: Tab[]; available: Tab[] }>>(
      url,
    );
  },
  getTabData: (params: UseGetTabData, page: number) => {
    const { services } = config;
    const baseUrl = services.assetExporter.getTabData;
    const { sortingOptions, ...rest } = params;
    const sorts = sortingOptions.reduce(
      (prev, curr) => ({
        ...prev,
        [removeSpaces(curr.content)]: curr.desc ? "desc" : "asc",
      }),
      {},
    );
    const normalizedQueries = { ...rest, ...sorts, page } as unknown as Record<
      string,
      string
    >;
    const queries = Object.keys(normalizedQueries).map(
      k => `${k}=${normalizedQueries[k]}`,
    );

    const queryParams = queries.join("&");
    const url = `${baseUrl}?${queryParams}`;
    return HttpClient.get<IApiResponse<FeedData>>(url);
  },
  updateTabOrder: (
    feedId: string,
    orderId: string,
    action: "ADD" | "REMOVE",
  ) => {
    const { services } = config;
    const url = services.assetExporter.updateTabOrder;
    const body = { feedId, orderId, action };
    return HttpClient.put<IApiResponse<FeedData>>(url, body);
  },
  getOfferExists: (feedId: string, vin: string) => {
    const { services } = config;
    const baseUrl = services.assetExporter.getOfferExists;
    const queryParams = `feedId=${feedId}&vin=${vin}`;
    const url = `${baseUrl}?${queryParams}`;
    return HttpClient.get<IApiResponse<GetOfferExists>>(url);
  },
  createNewOffer: (feedId: string, offer: RawOfferData) => {
    const { services } = config;
    const url = services.assetExporter.createUpdateFeedOffer;
    return HttpClient.post<IApiResponse<CreateEditOffer>>(url, {
      feedId,
      offer,
    });
  },
  updateOffer: (
    feedId: string,
    offer: RawOfferData,
    editedKeys: Record<string, boolean>,
  ) => {
    const { services } = config;
    const url = services.assetExporter.createUpdateFeedOffer;
    return HttpClient.put<IApiResponse<CreateEditOffer>>(url, {
      feedId,
      offer,
      editedKeys,
    });
  },
  getFlaggedVins: (feedId: string, vins: string[]) => {
    const { services } = config;
    const { assetBuilder } = services;
    const url = `${
      assetBuilder.getFlaggedVins
    }?feedId=${feedId}&vins=${vins.join(",")}`;
    return HttpClient.get<IApiResponse<{ flaggedVins: string[] }>>(url);
  },

  getOfferEdits: <T = any>(vins: string[]) => {
    const { services } = config;
    const { assetBuilder } = services;
    const url = `${assetBuilder.offerEdits}?vins=${vins.join(",")}`;
    return HttpClient.get<IApiResponse<T>>(url);
  },

  getOrderTags: <T = any>() => {
    const { services } = config;
    const { assetBuilder } = services;
    const url = assetBuilder.tagsUrl;
    return HttpClient.get<IApiResponse<T>>(url);
  },

  createOrderTag: <T = any>(name: string) => {
    const { services } = config;
    const { assetBuilder } = services;
    const url = assetBuilder.tagsUrl;
    return HttpClient.post<IApiResponse<T>>(url, { name });
  },
});

export type KeyValues = {
  [key in keyof OfferData]?: {
    value: string;
    shouldRemove?: boolean;
  };
};
