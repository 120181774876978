import { useQuery } from "react-query";
import { loadBeeswaxCampaignsStatusQueryKey } from "screens/beeswaxCampaigns/constants";
import API from "services";

export const useBeeswaxCampaignsLoadingStatus = (
  sessionId: string | undefined,
) => {
  return useQuery({
    queryKey: [loadBeeswaxCampaignsStatusQueryKey, sessionId],
    queryFn: async () => {
      if (!sessionId) throw new Error("Session ID is not defined");

      const data =
        await API.services.beeswaxCampaigns.getBeeswaxCampaignsLoadingStatus(
          sessionId,
        );

      if (data.error || !data.result) {
        throw new Error("Error fetching load status");
      }

      return data.result;
    },
    refetchInterval: statuses => {
      if (statuses?.some(item => item.status === "loading")) {
        return 1000;
      }
      return false;
    },
    cacheTime: 0,
  });
};
