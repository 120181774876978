import { useState } from "react";
import { Form, Select, Spin } from "antd";
import _ from "lodash";
import { useFetchArtboards } from "shared/hooks/useFetchArtboards";
import { IArtboard } from "shared/types/designStudio";
import { useAssetBatchesContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesContext";
import { formatDimensions } from "utils/helpers";
import common from "../shared/components/CommonDrawer.module.scss";

export const ArtboardSelect = () => {
  const { data: artboardResponse, isLoading } = useFetchArtboards();
  const [filteredArtboards, setFilteredArtboards] = useState<IArtboard[]>([]);
  const {
    artboard,
    setArtboard,
    clearCompositions,
    setDefaultTemplateFilters,
  } = useAssetBatchesContext();

  return (
    <Form.Item
      label={
        <>
          Select size <span className={common.required}>*</span>
        </>
      }
    >
      {isLoading ? (
        <Spin spinning={isLoading} size="large" />
      ) : (
        <Select
          showSearch
          value={artboard?.name}
          filterOption={true}
          style={{ width: "100%" }}
          onFocus={() => {
            setFilteredArtboards(
              _.orderBy(
                artboardResponse?.result?.artboards,
                [artboard => artboard.name.toLowerCase()],
                ["asc"],
              ),
            );
          }}
          onChange={value => {
            const selectedArtboard = filteredArtboards.find(
              artboard =>
                artboard.name === // this key text must equal to the key in Select.Option
                value,
            );

            if (!selectedArtboard) {
              throw new Error(`The selected artboard: ${value} must exists.`);
            }
            setArtboard(selectedArtboard);
            setDefaultTemplateFilters({
              status: ["PUBLISHED"],
              stamps: [0],
              dimension: [formatDimensions(selectedArtboard)],
            });
            clearCompositions();
          }}
        >
          {filteredArtboards.map((artboard: IArtboard) => (
            <Select.Option
              value={artboard.name}
              key={`new-template-artboard-option-${artboard.name}`}
            >
              {artboard.name}
            </Select.Option>
          ))}
        </Select>
      )}
    </Form.Item>
  );
};
