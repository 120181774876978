import { CloudUploadOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Drawer, Upload } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import { useState } from "react";
import { util, useGetAssetsListMutation } from "redux/media/media.api";
import { TooltipPerms } from "../TooltipPerms";
import { useAppDispatch } from "../redux";
import { usePermissions } from "../usePermissions";
import { useQPBreadcrumb } from "../useQPBreadcrumb";
import { useMediaActions } from "redux/media/media.slice";
import { useCustomRequest } from "./useCustomRequest";
import { ButtonGroup } from "../ButtonGroup";
import { UPLOAD_ACCEPT_FILETYPES } from "../constants";

type UploadAssetsInfo = {
  uploadedAssetIds: WDAsset["id"][];
  allDone: boolean;
};

export const FileUpload = () => {
  const { current } = useQPBreadcrumb();
  const { assets: assetPermissions } = usePermissions();
  const [visible, setVisible] = useState(false);
  const dispatch = useAppDispatch();
  const allowed = assetPermissions.includes("upload");
  const { setAssetsToEdit } = useMediaActions();
  const [getAssets] = useGetAssetsListMutation();
  const [uploadedAssetsInfo, setUploadedAssetsInfo] =
    useState<UploadAssetsInfo>({ uploadedAssetIds: [], allDone: false });

  const onClose = () => {
    setVisible(false);
    setUploadedAssetsInfo({ uploadedAssetIds: [], allDone: false });
  };

  const onOpen = () => {
    setVisible(true);
  };

  const handleChange = (info: UploadChangeParam) => {
    const allDone = info.fileList.every(file => file.percent === 100);
    if (allDone) {
      setUploadedAssetsInfo(prev => ({ ...prev, allDone }));
      dispatch(util.invalidateTags(["assets", "searchResults"]));
    }
  };

  const handleViewAssets = async () => {
    const { uploadedAssetIds } = uploadedAssetsInfo;
    setAssetsToEdit(await getAssets(uploadedAssetIds).unwrap());
    setUploadedAssetsInfo({ uploadedAssetIds: [], allDone: false });
    setVisible(false);
  };

  const uploadFileSuccessCallback = (id: string) => {
    setUploadedAssetsInfo(prev => ({
      ...prev,
      uploadedAssetIds: [...prev.uploadedAssetIds, id],
    }));
  };

  return (
    <div
      onDragOver={event => {
        event.dataTransfer.dropEffect = "none";
        event.preventDefault();
      }}
      onDrop={event => event.preventDefault()}
    >
      <TooltipPerms action="Upload Assets" enabled allowed={allowed}>
        <Button
          type="primary"
          shape="round"
          icon={<UploadOutlined />}
          onClick={onOpen}
          disabled={!allowed}
        >
          Upload
        </Button>
      </TooltipPerms>
      <Drawer
        title="Upload files"
        destroyOnClose
        maskClosable
        width={464}
        onClose={onClose}
        visible={visible}
        footer={
          <ButtonGroup>
            <Button onClick={onClose}>Done</Button>
            <Button
              disabled={!uploadedAssetsInfo.allDone}
              type="primary"
              onClick={handleViewAssets}
            >
              View assets & metadata
            </Button>
          </ButtonGroup>
        }
      >
        <div style={{ padding: 24 }}>
          <Upload.Dragger
            multiple
            accept={UPLOAD_ACCEPT_FILETYPES}
            showUploadList={{ showRemoveIcon: false }}
            customRequest={useCustomRequest(current, uploadFileSuccessCallback)}
            onChange={handleChange}
          >
            <p className="ant-upload-drag-icon">
              <CloudUploadOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </Upload.Dragger>
        </div>
      </Drawer>
    </div>
  );
};
