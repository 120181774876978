import {
  Checkbox,
  Collapse,
  Form,
  InputNumber,
  Select,
  Table,
  Typography,
} from "antd";
import { useState } from "react";
import {
  MarketingMaterialPrintDeliveryForm,
  MarketingMaterialPrintTableEntry,
  MarketingMaterialTableItem,
} from "shared/types/marketingMaterials";
import styles from "./PrintForm.module.scss";
import { pricePerQuantity } from "./utils/constants";
import { ColumnsType } from "antd/lib/table";

export const PrintForm = ({
  material,
  formId,
}: {
  material: MarketingMaterialTableItem;
  formId: string;
}) => {
  const [form] = Form.useForm();
  const [totalAmount, setTotalAmount] = useState(0);

  const columns: ColumnsType<MarketingMaterialPrintTableEntry> = [
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Simplex",
      dataIndex: "simplex",
      key: "simplex",
    },
    {
      title: "Duplex",
      dataIndex: "duplex",
      key: "duplex",
    },
  ];

  const dataSource: MarketingMaterialPrintTableEntry[] = pricePerQuantity.map(
    item => ({
      ...item,
      quantity: `${item.quantity.min}-${item.quantity.max}`,
      simplex: `$${item.simplex.toFixed(2)}`,
      duplex: `$${item.duplex.toFixed(2)}`,
    }),
  );

  const getPriceByQuantity = (quantity: number) => {
    const entry = pricePerQuantity.find(
      item => quantity >= item.quantity.min && quantity <= item.quantity.max,
    );
    if (entry)
      return {
        simplex: entry.simplex,
        duplex: entry.duplex,
      };
    return null;
  };

  const onValuesChange = (
    _: Partial<MarketingMaterialPrintDeliveryForm>,
    allValues: MarketingMaterialPrintDeliveryForm,
  ) => {
    const { copies, printOption } = allValues;
    const priceByQuantity = getPriceByQuantity(Number(copies));
    if (!priceByQuantity) {
      setTotalAmount(0);
      return;
    }
    const priceByOption = priceByQuantity[printOption];
    const price = Number(copies) * Number(priceByOption);
    if (!price) {
      setTotalAmount(0);
      return;
    }

    setTotalAmount(Number(price.toFixed(2)));
  };

  return (
    <Form<MarketingMaterialPrintDeliveryForm>
      form={form}
      id={formId}
      initialValues={{
        printOption: "simplex",
      }}
      layout="horizontal"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 20 }}
      labelAlign="left"
      onValuesChange={onValuesChange}
      className={styles.formContainer}
    >
      <Typography.Title level={4} className={styles.title}>
        {material.name}
      </Typography.Title>
      <div className={styles.divContainer}>
        <Form.Item
          name="copies"
          label="Copies"
          required
          wrapperCol={{ span: 4 }}
        >
          <InputNumber min={25} max={999} />
        </Form.Item>
        <Form.Item name="printOption" label="Print Option" required>
          <Select
            defaultActiveFirstOption
            options={[
              {
                label: "Simplex",
                value: "simplex",
              },
              {
                label: "Duplex",
                value: "duplex",
              },
            ]}
          />
        </Form.Item>
        <Form.Item label="Total Amount">
          <div className={styles.totalAmountContainer}>
            <Typography.Text strong className={styles.totalAmountText}>
              {`$${totalAmount}`}
            </Typography.Text>
            <Typography.Text className={styles.totalAmountPages}>
              {` (${form.getFieldValue("copies") || 0} pages total)`}
            </Typography.Text>
          </div>
        </Form.Item>
      </div>
      <Collapse className={styles.collapsibleTable} defaultActiveKey="table">
        <Collapse.Panel header="Pages and Prices (Bulk Pack)" key="table">
          <Table columns={columns} dataSource={dataSource} pagination={false} />
        </Collapse.Panel>
      </Collapse>
      <div className={styles.confirmationContainer}>
        <Form.Item
          style={{ marginBottom: 0 }}
          required
          label="I confirm that:"
        />
        <Form.Item
          name={["confirm", "text"]}
          valuePropName="checked"
          rules={[
            {
              required: true,
              message:
                "Please confirm the text is clear and spelled correctly.",
            },
          ]}
        >
          <Checkbox>The text is clear and spelled correctly.</Checkbox>
        </Form.Item>
        <Form.Item
          name={["confirm", "images"]}
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: "Please confirm the images are correctly placed.",
            },
          ]}
        >
          <Checkbox>
            The images are correctly placed; there are no blur or scale issues.
          </Checkbox>
        </Form.Item>
        <Form.Item
          name={["confirm", "review"]}
          valuePropName="checked"
          rules={[
            {
              required: true,
              message:
                "Please confirm you have reviewed and approve this material.",
            },
          ]}
        >
          <Checkbox>I have reviewed and approve this material.</Checkbox>
        </Form.Item>
      </div>
    </Form>
  );
};
