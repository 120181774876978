import { useCallback, useMemo, useState } from "react";
import { TablePaginationConfig } from "antd";
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
} from "antd/lib/table/interface";
import { HoverActionsVirtualTable } from "shared/components/HoverActionsVirtualTable/HoverActionsVirtualTable";
import { useWindowSize } from "shared/hooks/useWindowSize";
import {
  AccountRecord,
  BrandAccountRecord,
  BrandRecord,
  SourceBrandsRightClickPopup,
} from "shared/types/brandsAccounts";
import { useDataList } from "shared/components/dataList";
import ExpandIcon from "shared/components/ExpandIcon";
import { getColumns } from "./Columns";
import { useBrandsAccountsContext } from "./contexts/BrandsAccountsContext";
import { mapRows, filterRows, filterChildren } from "./utils/table.utils";
import { useBrandsAccountsToolbar } from "shared/hooks/brandsAccountsManagement/useBrandsAccountsToolbar";
import classNames from "classnames";
import styles from "./BrandsAccountsTable.module.scss";

interface Props {
  accountsToDisplayForAccountsMode?: AccountRecord[];
  onlyAccountsMode?: boolean;
  tableDataCy?: string;
  tableId: string;
}

export const BrandsAccountsTable = ({
  accountsToDisplayForAccountsMode = [],
  onlyAccountsMode,
  tableDataCy,
  tableId,
}: Props) => {
  const [rightClickPopup, setRightClickPopup] =
    useState<SourceBrandsRightClickPopup>();
  const [dataList, dataListActions] = useDataList<BrandRecord>();
  dataList.setTableId(tableId);
  const { onEdit } = useBrandsAccountsToolbar();

  const onNameClick = useCallback(
    (recordClicked: BrandAccountRecord) => {
      onEdit({
        clickedTargetId: recordClicked?.id ?? "",
        isBrand: recordClicked.type === "Brand",
        isOnlyAccountsMode: onlyAccountsMode,
      });
    },
    [onEdit, onlyAccountsMode],
  );

  const columns = useMemo(
    () =>
      getColumns(
        onlyAccountsMode ? accountsToDisplayForAccountsMode : dataList.rows,
        dataList.filters[tableId],
        onNameClick,
        onlyAccountsMode,
      ),
    [
      onlyAccountsMode,
      accountsToDisplayForAccountsMode,
      dataList.rows,
      dataList.filters,
      tableId,
      onNameClick,
    ],
  );

  const { search, selectedAccounts, setSelectedAccounts } =
    useBrandsAccountsContext();

  const { windowInnerHeight } = useWindowSize();

  const defaultRightClickState: SourceBrandsRightClickPopup = {
    displayX: 0,
    displayY: 0,
    visible: false,
  };

  const onRightClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>, id: string) => {
      setRightClickPopup({
        displayX: event.clientX,
        displayY: event.clientY,
        visible: true,
      });
      dataListActions.setSelectedItems([id]);
    },
    [dataListActions, setRightClickPopup],
  );

  const addSelectedIds = useCallback(
    (record: BrandAccountRecord) => {
      const newIds =
        record.type === "Brand" && record.children?.length > 0
          ? [
              record.id,
              ...dataList.selectedIds,
              ...record.children.map(child => child.id),
            ]
          : [...dataList.selectedIds, record.id];

      dataListActions.setSelectedItems([...new Set(newIds)]);

      const newNames = (
        record.type === "Brand" && record.children?.length > 0
          ? [...selectedAccounts, ...record.children.map(child => child.name)]
          : [...selectedAccounts, record.name]
      ) as string[];
      setSelectedAccounts(newNames);
    },
    [dataList, dataListActions, selectedAccounts, setSelectedAccounts],
  );

  const removeSelectedIds = useCallback(
    (record: BrandAccountRecord) => {
      const idsToRemove =
        record.type === "Brand" && record.children?.length > 0
          ? [record.id, ...record.children.map(child => child.id)]
          : [record.id];

      const newIds = dataList.selectedIds.filter(
        id => !idsToRemove.includes(id),
      );

      dataListActions.setSelectedItems(newIds);
      const namesToRemove =
        record.type === "Brand" && record.children?.length > 0
          ? [...record.children.map(child => child.name)]
          : [record.name];

      const newNames = selectedAccounts.filter(
        name => !namesToRemove.includes(name),
      );
      setSelectedAccounts(newNames);
    },
    [dataList, dataListActions, selectedAccounts, setSelectedAccounts],
  );

  const rowsWithSearchAndFilters = useMemo(() => {
    if (onlyAccountsMode) {
      return filterChildren(
        accountsToDisplayForAccountsMode,
        dataList.conditions[tableId],
      );
    } else {
      const newRows = mapRows(dataList.rows, dataList.conditions[tableId]);
      const filteredRows = filterRows(newRows, search).filter(row => !!row);

      return filteredRows.map(row => ({
        ...row,
        children: row.children.filter(child =>
          child.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
        ),
      }));
    }
  }, [
    search,
    dataList,
    onlyAccountsMode,
    accountsToDisplayForAccountsMode,
    tableId,
  ]);

  const onTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      _filters: Record<string, FilterValue | null>,
      sorter:
        | SorterResult<BrandAccountRecord>
        | SorterResult<BrandAccountRecord>[],
      extra: TableCurrentDataSource<BrandAccountRecord>,
    ) => {
      if (extra.action === "filter")
        dataListActions.setFilters(_filters, tableId);
    },
    [dataListActions, tableId],
  );

  if (
    onlyAccountsMode &&
    (accountsToDisplayForAccountsMode ?? []).length === 0
  ) {
    return <></>;
  }

  return (
    <HoverActionsVirtualTable
      disableVirtualization
      data-cy={tableDataCy ?? "brands-accounts-table"}
      size="small"
      className={classNames([
        styles.table,
        ...(!onlyAccountsMode ? [styles.brandsTable] : [styles.accountsTable]),
      ])}
      dataSource={rowsWithSearchAndFilters}
      scroll={{ y: windowInnerHeight - 200, x: onlyAccountsMode ? 1300 : 1512 }}
      columns={columns}
      loading={!dataList.rows.length}
      expandable={{
        expandIconColumnIndex: 1,
        indentSize: 0,
        expandIcon: ({ record, ...props }) =>
          record.type === "Brand" &&
          record.children?.length > 0 && (
            <div
              className={styles.expandIcon}
              data-cy={`expand-button-${record.name}`}
            >
              {<ExpandIcon record={record} {...props} />}
            </div>
          ),
      }}
      rowSelection={
        onlyAccountsMode
          ? undefined
          : {
              selectedRowKeys: dataList.selectedIds,
              columnWidth: 64,
              onSelect: (record, selected) => {
                selected ? addSelectedIds(record) : removeSelectedIds(record);
              },
              onSelectAll: (selected, selectedRows) => {
                selected
                  ? dataListActions.setSelectedItems(
                      selectedRows.map(row => row.id) as string[],
                    )
                  : dataListActions.setSelectedItems([]);
              },
            }
      }
      // Adding right click functionality
      onRow={record => ({
        onContextMenu: event => {
          event.preventDefault();
          document.addEventListener("click", function onClickOutside() {
            document.removeEventListener("click", onClickOutside);
          });

          if (!rightClickPopup?.visible) {
            document.addEventListener("click", function onClickOutside() {
              setRightClickPopup(defaultRightClickState);
              document.removeEventListener("click", onClickOutside);
            });
          }
          onRightClick(event, record.id);
        },
      })}
      onChange={onTableChange}
    />
  );
};
