import { useMemo } from "react";
import { TemplateFile } from "shared/types/salesEnablement";
import useLayers from "./useLayers";
import { MarketingMaterial } from "shared/types/marketingMaterials";

export type FileDataProps = {
  file: TemplateFile | undefined;
  material?: Partial<MarketingMaterial>;
  onError: () => void;
};

export type IdmlPreview = {
  src: string | undefined;
  isError: boolean;
  isLoading: boolean;
};

export const useFileData = ({ file }: FileDataProps) => {
  const { layers, setLayers, filteredLayers, setFilteredLayers } = useLayers({
    file,
  });

  const sp = useMemo(() => file?.spMetadata, [file]);

  return {
    layers,
    setLayers,
    filteredLayers,
    setFilteredLayers,
    spMetaData: sp,
  };
};
