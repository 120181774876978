import { AutoComplete, Checkbox, Col, Row, Select } from "antd";
import { useEffect, useMemo, useState } from "react";
import { FieldWrapper } from "screens/assetExporter/feedConfigurationV2/shared/components/FieldWrapper";
import { useAssetBatchesContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesContext";
import {
  TMediaResizeType,
  TValueMapping,
  TVariable,
} from "screens/assetExporter/feedConfigurationV2/shared/types";
import { videoCompositionEnabled } from "shared/constants/assetExporter";
import { MediaColumn, TVideoAudio } from "shared/types/assetExporter";
import { nonNullable } from "utils/helpers.array";
import { FieldTitle } from "../../shared/components/FieldTitle";
import {
  getImageMappingValue,
  getInputValueForMapping,
  getMediaByType,
} from "../../shared/contexts/AssetBatchesContext.utils";
import { useAssetBatchesTemplateCompositionContext } from "../../shared/contexts/AssetBatchesTemplateCompositionContext";
import { useAssetBatchesValueMappingContext } from "../../shared/contexts/AssetBatchesValueMappingContext";
import { useVideoStitchingContext } from "../../shared/contexts/VideoStitchingContext";
import { useHasAudioVideo } from "../../shared/hooks/useHasAudioVideo";
import { useMediaDataForSelectedRow } from "../../shared/hooks/useMediaDataForSelectedRow";
import styles from "./VariableInput.module.scss";
import { useHightlightVarEvents } from "./hooks/useHighlightVarEvents";
import { useImageVariableInput } from "./hooks/useImageVariableInput";

type VariableInputProps = {
  compositionId: string;
  variable: TVariable;
  valueMapping?: TValueMapping;
  mappingKey: string;
  mediaColumns?: MediaColumn[];
};

const updateVideoAudios = ({
  compositionId,
  variableId,
  setVideoAudios,
}: {
  compositionId: string;
  variableId: string;
  setVideoAudios: React.Dispatch<React.SetStateAction<TVideoAudio[]>>;
}) => {
  setVideoAudios(prev => {
    let exists = false;
    const updatedVideoAudios = prev.map(videoAudio => {
      if (videoAudio.compositionId === compositionId) {
        exists = true;
        // If the compositionId already exists, add mappingKey to the variableIds array
        if (!videoAudio.variableIds?.includes(variableId)) {
          return {
            ...videoAudio,
            variableIds: [...(videoAudio.variableIds || []), variableId],
          } as TVideoAudio;
        }
      }
      return videoAudio as TVideoAudio;
    });

    // If the compositionId doesn't exist, add a new element with mappingKey as the variableId
    if (!exists) {
      updatedVideoAudios.push({
        variableIds: [variableId],
        compositionId,
        canvas: undefined,
      });
    }
    return updatedVideoAudios;
  });
};

export const ImageVariableInput = ({
  variable,
  valueMapping,
  mappingKey,
  compositionId,
}: VariableInputProps) => {
  const [showAudioSelection, setShowAudioSelection] = useState(false);
  const [updateData, setUpdateData] = useState<{
    compositionId: string;
    variableId: string;
  } | null>(null);
  const { setShowMediaAlert, mediaColumns, rows } = useAssetBatchesContext();
  const { getMediaInputError, selectedRow } =
    useAssetBatchesValueMappingContext();
  const { onSelectResizeType, onSelectInputValueSelect, onSelectInputValue } =
    useAssetBatchesTemplateCompositionContext();
  const { onMouseEnter, onMouseLeave, onFocus, onBlur, onValueChange } =
    useHightlightVarEvents(mappingKey);
  const { templatesToUse: templates } = useAssetBatchesContext();
  const { setVideoAudios } = useVideoStitchingContext();
  const { getLeftColSpan, handleChange, handleClear, handleKeyDown } =
    useImageVariableInput();
  const value = getInputValueForMapping(valueMapping);
  const rawSrc = useMemo(
    () => valueMapping && getImageMappingValue(valueMapping, selectedRow),
    [valueMapping, selectedRow],
  );
  const { data: hasAudioVideo } = useHasAudioVideo(rawSrc);
  const { isLoading, data: selectedRowMediaUrls } = useMediaDataForSelectedRow(
    selectedRow,
    mediaColumns,
  );
  const filterColumns = getMediaByType(
    ["mp4", "png", "jpg", "jpeg"],
    selectedRow,
    isLoading ? [] : selectedRowMediaUrls.filter(nonNullable),
    mediaColumns,
  );

  useEffect(() => {
    setShowAudioSelection((hasAudioVideo ?? false) && videoCompositionEnabled);
  }, [hasAudioVideo]);

  const resizeType = valueMapping?.resizeType ?? "fill";
  const error = getMediaInputError(value, valueMapping);

  const leftColSpan = getLeftColSpan(showAudioSelection, templates);

  useEffect(() => {
    if (updateData && showAudioSelection) {
      updateVideoAudios({
        compositionId: updateData.compositionId,
        variableId: updateData.variableId,
        setVideoAudios,
      });
    }
  }, [updateData, showAudioSelection, setVideoAudios]);

  return (
    <>
      <FieldTitle title={variable.variable} />
      <Row
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={styles.row}
        justify="space-around"
        align="top"
      >
        <Col span={leftColSpan}>
          <FieldWrapper label="Column or URL" width={24}>
            <AutoComplete
              allowClear={true}
              options={filterColumns?.map(col => {
                return {
                  key: col.columnName,
                  value: col.columnName,
                };
              })}
              filterOption
              className={error ? `${styles.inputError}` : `${styles.input}`}
              onFocus={() => onFocus(mappingKey)}
              onBlur={() => onBlur(mappingKey)}
              onClear={() =>
                handleClear({
                  compositionId,
                  mappingKey,
                  rows,
                  onSelectInputValue,
                  setVideoAudios,
                })
              }
              onChange={(val, option) => {
                handleChange({
                  val,
                  option,
                  mappingKey,
                  rows,
                  setShowMediaAlert,
                  onValueChange,
                  onSelectInputValueSelect,
                  onSelectInputValue,
                });
                if (val)
                  setUpdateData({ compositionId, variableId: variable.id });
              }}
              onKeyDown={e =>
                handleKeyDown({
                  e,
                  value,
                  mappingKey,
                  resizeType,
                  rows,
                  onSelectResizeType,
                  onSelectInputValue,
                  onSelectInputValueSelect,
                })
              }
              value={value}
              data-cy={mappingKey}
              bordered={!!error}
            />
            {error && <div className={styles.errorDesc}>{error}</div>}
          </FieldWrapper>
        </Col>
        {templates?.[0]?.type !== "html" && (
          <>
            <Col span={4}>
              <FieldWrapper label="Resize">
                <Select
                  defaultValue={"fill"}
                  disabled={!value}
                  onSelect={val => {
                    onSelectResizeType(mappingKey, val as TMediaResizeType);
                  }}
                  value={resizeType}
                >
                  <Select.Option value="fill">Fill</Select.Option>
                  <Select.Option value="fit">Fit</Select.Option>
                </Select>
              </FieldWrapper>
            </Col>
            {videoCompositionEnabled && showAudioSelection && (
              <Col span={4}>
                <FieldWrapper label="Audio">
                  <Checkbox
                    value={variable.id}
                    className={styles.checkbox}
                  ></Checkbox>
                </FieldWrapper>
              </Col>
            )}
          </>
        )}
      </Row>
    </>
  );
};
