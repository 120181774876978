import ExpandIcon from "shared/components/ExpandIcon";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { IAd, IAdWithChildren } from "shared/types/adLibrary";
import { isChildRecord } from "../adCompleteImportDrawer/adImportTable/adImportTableContainer/adImportTable.utils";
import { getValidationForAdId, IAdValidations } from "../adValidationUtils";
import "../adCompleteImportDrawer/adImportTable/AdImportTableContainer.scss";
import { Alert, Space, Table, Typography } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import { getColumns } from "./adImportDrawerTable/columns";
import { CheckboxCell } from "./adImportDrawerTable/CheckboxCell";
import { useAdImportTable } from "./adImportDrawerTable/useAdImportTable";
import { useAdImportTableComponents } from "./adImportDrawerTable/useAdImportTableComponents";
import styles from "./AdImportDrawerTable.module.scss";

type AdImportDrawerTableProps = {
  ads: IAd[];
  setAd: (adId: string, ad: IAd) => void;
  selectedRowKeys: string[];
  setSelectedRowKeys: (selectedRowKeys: string[]) => void;
  adValidations?: IAdValidations[];
  adsLoadingMedia: string[];
  selectEnabled: boolean;
};

export const AdImportDrawerTable = ({
  ads,
  setAd,
  selectedRowKeys,
  setSelectedRowKeys,
  adValidations,
  adsLoadingMedia,
  selectEnabled,
}: AdImportDrawerTableProps) => {
  const validationErrors = adValidations?.filter(
    validation => validation.hasErrors,
  );

  const { expandedRowKeys, onToggleExpand, onExpand, isDisabled, adRecords } =
    useAdImportTable({ ads });

  const { windowInnerHeight } = useWindowSize();

  const columns = getColumns({
    validationErrors,
    onToggleExpand,
    expandedRowKeys,
    setAd,
  });

  const { editableColumns } = useAdImportTableComponents({
    columns,
    setAd,
  });

  const hasErrors = Boolean(validationErrors?.length);
  return (
    <Space direction="vertical" className={styles.tableContainer}>
      {hasErrors && (
        <Alert
          message={
            <Space>
              <Typography.Text>
                Please fill out missing or invalid information beore proceeding
              </Typography.Text>
            </Space>
          }
          type="error"
          icon={<InfoCircleFilled />}
          showIcon
          closable
        />
      )}
      <Table<IAdWithChildren>
        className="ad-import-table-container"
        scroll={{ y: windowInnerHeight - 245 }}
        rowKey={record => record.id}
        pagination={false}
        size="small"
        rowSelection={{
          hideSelectAll: !selectEnabled,
          renderCell: (selected, record) => {
            if (isChildRecord(record)) return null;
            return (
              <CheckboxCell
                selectEnabled={selectEnabled}
                isSelected={selected}
                adValidation={getValidationForAdId(
                  record.id,
                  adValidations ?? [],
                )}
                isLoading={adsLoadingMedia.includes(record.id)}
                onChange={e => {
                  if (e.target.checked)
                    setSelectedRowKeys([...selectedRowKeys, record.id]);
                  else
                    setSelectedRowKeys(
                      selectedRowKeys.filter(x => x !== record.id),
                    );
                }}
              />
            );
          },
          selectedRowKeys,
          onChange: selection => setSelectedRowKeys(selection.map(String)),
          getCheckboxProps: adRecord => ({
            style: isChildRecord(adRecord) ? { display: "none" } : {},
            disabled: isDisabled(adRecord, validationErrors),
          }),
        }}
        columns={editableColumns}
        dataSource={adRecords}
        expandable={{
          expandedRowKeys,
          onExpand,
          expandIcon: props => (
            <ExpandIcon
              {...props}
              tooltip={
                props.expanded
                  ? "Click to collapse Carousel"
                  : "Click to expand Carousel"
              }
              style={{ height: 30, display: "flex", alignItems: "center" }}
            />
          ),
          indentSize: 60,
        }}
      />
    </Space>
  );
};
