import { Typography } from "antd";
import { RenderList } from "./RenderList";
import { Template } from "shared/types/salesEnablement";
import styles from "./Display.module.scss";
import useTemplateEditing from "../../shared/hooks/salesEnablement/useTemplateEditing";
import { TemplateListItem } from "./TemplateListItem";
import ErrorState from "./shared/ErrorState";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "./utils/constants";

type TemplatesDisplayProps = {
  title: string;
  description?: string;
  templates: Template[];
  filterFunction?: (templates: Template[]) => Template[];
  handleClick: () => void;
  showExpirationDate?: boolean;
  isError?: boolean;
};

export const TemplatesDisplay = ({
  title,
  description = "",
  templates,
  filterFunction,
  handleClick,
  showExpirationDate = false,
  isError,
}: TemplatesDisplayProps) => {
  const { Text } = Typography;
  const items = filterFunction ? filterFunction(templates) : templates;
  const { setupDrawer } = useTemplateEditing();

  const handleEdit = (item: Template) =>
    setupDrawer({ drawerMode: "edit", template: item });
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerTitle}>
          <Typography.Title level={4}>{title}</Typography.Title>
          {description && (
            <Text className={styles.description}>{description}</Text>
          )}
        </div>
        <Text className={styles.seeAll} onClick={handleClick}>
          See All
        </Text>
      </div>
      {isError && (
        <ErrorState
          title="Sorry! We couldn’t find your recent Templates."
          description="Please refresh the page or click below to browse all Templates."
          action={{
            title: "Browse Templates",
            onClick: () => navigate(ROUTES.templates),
          }}
        />
      )}
      {!isError && (
        <RenderList<Template>
          items={items}
          renderItem={item => (
            <TemplateListItem
              item={item}
              onEdit={handleEdit}
              showExpirationDate={showExpirationDate}
            />
          )}
        />
      )}
    </div>
  );
};
