import { DownloadOutlined, FolderOutlined } from "@ant-design/icons";
import { Button, Image, Skeleton, Typography, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { CAMUpload, CAM_ENABLED } from "shared/components/media";
import useGenerateImages from "shared/hooks/genAI/useGenerateImages";
import { AiIcon } from "shared/icons/AiIcon";
import ImagePreview from "../components/ImagePreview";
import ToolPicker from "../components/ToolPicker";
import { handleDownloadAllFromUrl } from "../utils/functions/imageInfoUtils";
import { useAiToolsSession } from "../utils/hooks/useAiToolsSession";
import styles from "./AiImageGen.module.scss";

const AiImageGen = () => {
  const { session } = useAiToolsSession();
  const [prompt, setPrompt] = useState(session?.state?.prompt || "");
  const [carouselCurrentImage, setCarouselCurrentImage] = useState<number>(-1);
  const { sessionId = "" } = useParams<{ sessionId: string }>();

  const { generateImages } = useGenerateImages({
    sessionId: sessionId,
    prompt,
    height: 512,
    width: 512,
    nSamples: 4,
  });

  const handleGenerate = async () => {
    generateImages();
  };

  const handleDownloadAll = () => {
    handleDownloadAllFromUrl(session?.state?.images);
  };

  const getButtonText = () => {
    if (session?.status === "pending") {
      return "Generating...";
    }

    if (session && session.state.images.length > 0) {
      return "Re-generate";
    }

    return "Generate";
  };

  const isLoading = session?.status === "pending";

  return (
    <div className={styles.aiImageGenContainer}>
      <div className={styles.leftPanel}>
        <ToolPicker />
        <div className={styles.inputSectionContainer}>
          <div>
            <Typography.Title level={5}>Generate Image</Typography.Title>
            <Typography.Text>
              Describe the image you want to generate. The more detailed the
              description, the better the results.
            </Typography.Text>
          </div>
          <TextArea
            value={prompt || session?.state?.prompt}
            onKeyDown={e => {
              if (e.key === "Enter") {
                handleGenerate();
                e.preventDefault();
                e.currentTarget.blur();
              }
            }}
            onChange={e => setPrompt(e.target.value)}
            className={styles.inputTextArea}
            placeholder="Photograph of a city skyline, with skyscrapers, from ground level, extremely detailed"
            data-cy="prompt-input"
          />
          <Button
            type="primary"
            onClick={handleGenerate}
            disabled={!prompt.trim()}
            data-cy="generate-button"
            loading={isLoading}
          >
            {getButtonText()}
          </Button>
        </div>
      </div>
      <div className={styles.resultSectionContainer}>
        {session && session.state.images.length > 0 ? (
          <div>
            <div className={styles.buttonsRow}>
              <Button onClick={handleDownloadAll}>
                <DownloadOutlined />
                Download All
              </Button>
              {CAM_ENABLED && (
                <CAMUpload
                  files={session?.state.images}
                  onComplete={() => {
                    message.success("All images saved");
                  }}
                >
                  <Button type="primary">
                    <FolderOutlined /> Save all
                  </Button>
                </CAMUpload>
              )}
            </div>
            <div className={styles.gridContainer}>
              {session?.state?.images.map((image: string, index: number) =>
                !isLoading ? (
                  <ImagePreview
                    data-cy="image-preview"
                    index={index}
                    image={image}
                    key={image}
                    openCarousel={imageIndex =>
                      setCarouselCurrentImage(imageIndex)
                    }
                    prompt={session?.state?.prompt}
                  />
                ) : (
                  <Skeleton.Image key={image} className={styles.skeleton} />
                ),
              )}
            </div>
          </div>
        ) : (
          <div className={styles.emptyState}>
            <AiIcon height={100} width={100} color="#1890FF" />
            <Typography.Text>
              Enter a prompt, and let the magic begin
            </Typography.Text>
          </div>
        )}
        <div style={{ display: "none" }}>
          <Image.PreviewGroup
            preview={{
              current: carouselCurrentImage,
              visible: carouselCurrentImage > -1,
              onVisibleChange: vis =>
                setCarouselCurrentImage(vis ? carouselCurrentImage : -1),
            }}
          >
            {session?.state?.images?.map((image: string) => (
              <Image src={image} key={image} />
            ))}
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
  );
};

export default AiImageGen;
