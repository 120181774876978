import { Button, ButtonProps } from "@mui/material";

export const ButtonContainer = (props: ButtonProps) => {
  const { sx, ...restProps } = props;
  return (
    <Button
      variant="text"
      sx={{
        textTransform: "none",
        color: "text.primary",
        minWidth: "auto",
        fontSize: 13,
        borderRadius: 1,
        ...sx,
      }}
      {...restProps}
    >
      {props.children}
    </Button>
  );
};
