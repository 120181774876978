import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import styles from "./InfoIcon.module.scss";

export const InfoIcon = () => {
  return (
    <Tooltip title="Video resized to fit your screen" placement="topRight">
      <div className={styles.container}>
        <InfoCircleOutlined className={styles.icon} />
      </div>
    </Tooltip>
  );
};
