import { useCallback } from "react";
import { Button, Col } from "antd";
import { TMediaComposition } from "shared/types/assetExporter";
import { CAMOption } from "../../assetBatchDrawerV2/manageVariables/panelVariables/CAMOption";
import { useAssetBatchesContext } from "../../shared/contexts/AssetBatchesContext";
import { getDefaultDuration } from "../utils";
import styles from "./VariableInput.module.scss";
import { AudioCheckboxMedia } from "../../assetBatchDrawerV2/manageVariables/panelVariables/UploadAudio/AudioCheckboxMedia";
import {
  videoCompositionAudioFilesEnabled,
  videoCompositionEnabled,
} from "shared/constants/assetExporter";

type MediaFromFeedInputProps = {
  composition: TMediaComposition;
};

export const MediaFromCamInput = ({ composition }: MediaFromFeedInputProps) => {
  const { compositions, setCompositions } = useAssetBatchesContext();
  const spanValue =
    videoCompositionEnabled && videoCompositionAudioFilesEnabled ? 16 : 19;

  const onSelectAsset = useCallback(
    async (url: string, name?: string) => {
      const duration = await getDefaultDuration(
        url,
        (compositions?.length ?? 0) + 1,
      );
      const newCompositions = compositions.map(comp => {
        if (comp.compositionId === composition.compositionId) {
          return {
            ...comp,
            name,
            url,
            duration,
            audioEnabled: true,
          };
        }

        return comp;
      });

      setCompositions(newCompositions);
    },
    [compositions, setCompositions, composition.compositionId],
  );

  return (
    <div className={styles.containerInput}>
      <Col span={5}>
        <CAMOption onSelectAsset={onSelectAsset}>
          <Button>Change</Button>
        </CAMOption>
      </Col>
      <Col span={spanValue}>
        <span>{composition.name}</span>
      </Col>
      {videoCompositionEnabled && videoCompositionAudioFilesEnabled && (
        <Col span={3}>
          <AudioCheckboxMedia composition={composition} />
        </Col>
      )}
    </div>
  );
};
