import { useQuery } from "react-query";
import API from "services";

export const useFetchTemplateVersions = (templateId: string) => {
  const queryKey = ["template-versions", templateId];
  const queryFn = () =>
    API.services.designStudio.getTemplateVersions(templateId);

  const { data, error, isLoading } = useQuery(queryKey, queryFn);

  return {
    result: data?.result,
    error,
    isLoading,
  };
};
