import { Select, Badge, BadgeProps } from "antd";
import { fields } from "./FormFields.schema";
import { useSalesEnablementContext } from "screens/designStudio/hooks/SalesEnablementDataProvider";
import { TemplateStatus } from "shared/types/salesEnablement";

export const statusColorIndicator = (
  status: string,
  isExpired: boolean,
): BadgeProps["color"] => {
  if (isExpired) return "red";
  if (status === "UNPUBLISHED") return "blue";

  return "green";
};

export const StatusSelector = () => {
  const { status, setStatus } = useSalesEnablementContext();

  const handleStatusChange = (value: TemplateStatus) => {
    setStatus(value);
  };

  return (
    <Select<TemplateStatus>
      value={status}
      onChange={handleStatusChange}
      style={{ width: 150 }}
    >
      {fields.status.options.map(option => (
        <Select.Option key={option.value} value={option.value}>
          <Badge color={option.color} text={option.label} />
        </Select.Option>
      ))}
    </Select>
  );
};
