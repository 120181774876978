import { Button, Select } from "antd";
import {
  FullscreenExitOutlined,
  FullscreenOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import styles from "./ZoomControl.module.scss";

interface Props {
  zoomLevel: number;
  setZoomLevel: React.Dispatch<React.SetStateAction<number>>;
  isFitToScreen: boolean;
  toggleFitToScreen: () => void;
  onZoomIn?: () => void;
  onZoomOut?: () => void;
}

const zoomValues = [25, 50, 75, 100, 125, 150, 175, 200, "Reset"];

export const ZoomControl = ({
  zoomLevel,
  setZoomLevel,
  isFitToScreen,
  toggleFitToScreen,
  onZoomIn,
  onZoomOut,
}: Props) => {
  const handleZoomChange = (value: string | number) => {
    if (typeof value === "number") {
      setZoomLevel(value);
      return;
    }
    setZoomLevel(100);
  };

  const handleZoomIn = () => {
    const newZoomLevel = Math.min(zoomLevel + 5, 200);
    setZoomLevel(newZoomLevel);
    onZoomIn && onZoomIn();
    onZoomIn?.();
  };

  const handleZoomOut = () => {
    const newZoomLevel = Math.max(zoomLevel - 5, 25);
    setZoomLevel(newZoomLevel);
    onZoomOut && onZoomOut();
    onZoomOut?.();
  };

  const formatDisplayValue = (value: string | number) => {
    return typeof value === "number" ? `${value}%` : value;
  };

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <Button
          type="text"
          icon={
            isFitToScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />
          }
          onClick={toggleFitToScreen}
        />
      </div>
      <div className={styles.container}>
        <Button type="text" icon={<ZoomInOutlined />} onClick={handleZoomIn} />
        <Select
          value={formatDisplayValue(zoomLevel)}
          onChange={handleZoomChange}
          showArrow={false}
          bordered={false}
          dropdownAlign={{ points: ["bl", "tl"] }}
        >
          {zoomValues.map(value => (
            <Select.Option key={value} value={value}>
              {value === "Reset" ? "Reset" : `${value}%`}
            </Select.Option>
          ))}
        </Select>
        <Button
          type="text"
          icon={<ZoomOutOutlined />}
          onClick={handleZoomOut}
        />
      </div>
    </div>
  );
};
