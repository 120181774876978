import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

export type User = {
  sub: string;
  email: string;
  name: string;
} & AppMetadata &
  UserMetadata;

export type Permissions = {
  permissions?: string[];
};

export type UserData = {
  stores?: string[];
  dashboards?: string[];
};

export type AppMetadata = {
  app_metadata?: {
    role?: string;
    writingNumbers?: string;
  };
};

export type UserMetadata = {
  user_metadata?: {
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
  };
};

type AuthState = {
  user: User & Permissions & UserData;
};

const initialState: AuthState = {
  user: {
    sub: "",
    email: "",
    name: "",
    permissions: [],
    stores: [],
    dashboards: [],
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    initUser(state, action: PayloadAction<AuthState>) {
      state.user = action.payload.user;
    },
    addPerms(
      state,
      action: PayloadAction<Pick<AuthState["user"], "permissions">>,
    ) {
      state.user.permissions = action.payload.permissions;
    },
    // TODO: for v3
    // updateUserV3(
    //   state,
    //   action: PayloadAction<Pick<AuthState["user"], "firstName" | "lastName">>,
    // ) {
    //   const { firstName, lastName } = action.payload;
    //   state.user.firstName = firstName ?? "";
    //   state.user.lastName = lastName ?? "";
    // },
  },
});

export const { initUser, addPerms } = authSlice.actions;

export const selAuth = (state: AppState) => state.auth;

export const selPerms = createSelector(
  [selAuth],
  state => state.user.permissions,
);

export default authSlice.reducer;
