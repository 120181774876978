import { useContext, createContext, ReactNode, useState } from "react";
import {
  BeeswaxCampaignForm,
  BeeswaxCampaignFormInstance,
  LineItem,
} from "./types";
import { useForm } from "antd/lib/form/Form";

type BeeswaxCampaignsContextType = {
  form: BeeswaxCampaignFormInstance;
  lineItemId: string | undefined;
  draftLineItems: LineItem[];
  setDraftLineItems: React.Dispatch<React.SetStateAction<LineItem[]>>;
  resetSelectedLineItem: () => void;
  selectLineItem: (id: string) => void;
};

const Context = createContext<BeeswaxCampaignsContextType | null>(null);

export const useBeeswaxCampaignsContext = () => {
  const ctx = useContext(Context);

  if (!ctx) {
    throw new Error(
      "useBeeswaxCampaignsContext must be used within a BeeswaxCampaignsContext",
    );
  }
  return ctx;
};

type Props = {
  children: ReactNode;
  lineItems: LineItem[];
};

export const BeeswaxCampaignsContext = ({ children, lineItems }: Props) => {
  const [form] = useForm<BeeswaxCampaignForm>();
  const [draftLineItems, setDraftLineItems] = useState<LineItem[]>(lineItems);
  const [lineItemId, setLineItemId] = useState<string | undefined>();

  const resetSelectedLineItem = () => {
    setLineItemId(undefined);
  };

  const selectLineItem = (id: string) => {
    setLineItemId(id);
  };

  return (
    <Context.Provider
      value={{
        form,
        lineItemId,
        draftLineItems,
        setDraftLineItems,
        resetSelectedLineItem,
        selectLineItem,
      }}
    >
      {children}
    </Context.Provider>
  );
};
