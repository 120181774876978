import { useEffect, useMemo } from "react";
import { useFetchBrandsByName } from "shared/hooks/brandsAccountsManagement/useFetchBrandsByName";
import { StoreWithAssetId } from "screens/designStudio/designStudioV2/types";

export const useLoadFontsToStores = (
  items: any[],
  storeData: StoreWithAssetId[],
  selectedId: string | null | undefined,
) => {
  const oems = useMemo(
    () => Array.from(new Set(items.map(item => item.asset?.oems).flat())),
    [items],
  );
  const { data: brands } = useFetchBrandsByName(oems);

  useEffect(() => {
    if (!brands || brands.length === 0) return;
    const brandFonts = brands[0].fonts || [];
    storeData.forEach(data => {
      if (data.assetId === selectedId) {
        const store = data.store;
        brandFonts.forEach(font => {
          store.addFont({
            fontFamily: font.name,
            url: font.url,
          });
        });
      }
    });
  }, [brands, selectedId, storeData]);

  return {
    isLoading: !brands,
  };
};
