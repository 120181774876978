import {
  KeyboardArrowUpRounded,
  KeyboardDoubleArrowUpOutlined,
  KeyboardArrowDownRounded,
  KeyboardDoubleArrowDownOutlined,
} from "@mui/icons-material";
import { Stack, Typography, Grid } from "@mui/material";
import { StoreType } from "polotno/model/store";
import { useElements } from "../editorTabs/tabPanel/useElements";
import { PositionButton } from "./PositionButton";
import { observer } from "mobx-react-lite";

type LayeringActionsProps = {
  store: StoreType;
};

export const LayeringActions = observer(({ store }: LayeringActionsProps) => {
  const { moveSelectedElements } = useElements(store);
  return (
    <Stack spacing={1}>
      <Typography variant="body1">Layering</Typography>
      <Grid container columnSpacing={2}>
        <Grid item xs={6}>
          <Stack alignItems="start">
            <PositionButton
              startIcon={<KeyboardArrowUpRounded color="action" />}
              onClick={() => moveSelectedElements("moveUp")}
            >
              Up
            </PositionButton>
            <PositionButton
              startIcon={<KeyboardDoubleArrowUpOutlined color="action" />}
              onClick={() => moveSelectedElements("toForward")}
            >
              To forward
            </PositionButton>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack alignItems="start">
            <PositionButton
              startIcon={<KeyboardArrowDownRounded color="action" />}
              onClick={() => moveSelectedElements("moveDown")}
            >
              Down
            </PositionButton>
            <PositionButton
              startIcon={<KeyboardDoubleArrowDownOutlined color="action" />}
              onClick={() => moveSelectedElements("toBottom")}
            >
              To bottom
            </PositionButton>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
});
