import { useCallback, useEffect, useRef, useState } from "react";
import { isOutsideFromTable } from "../tableActions/utils";
import { useTableActionsContext } from "./TableActionsContext";

export const useTableActions = <RecordType,>(isNested: boolean) => {
  const inlineActionsRef = useRef<HTMLDivElement | null>(null);
  const contextActionsRef = useRef<HTMLDivElement | null>(null);

  const [hoverRecord, setHoverRecord] = useState<RecordType>();
  const [contextRecord, setContextRecord] = useState<RecordType>();

  const context = useTableActionsContext<RecordType>();

  // this is not ideal, but antd does not expose a scroll event so we don't have
  // a reliable way to bind this event apart from this
  useEffect(() => {
    document.addEventListener("scroll", onTableScroll, true);
    return () => {
      document.removeEventListener("scroll", onTableScroll);
    };
  }, []);

  // binding the event to the container div doesn't work
  const onClickAnywhere = useCallback(function clickAnywhere() {
    setContextRecord(undefined);
    document.removeEventListener("contextmenu", clickAnywhere);
  }, []);

  useEffect(() => {
    document.addEventListener("click", onClickAnywhere);
    return () => {
      document.removeEventListener("click", onClickAnywhere);
    };
  }, [setContextRecord, onClickAnywhere]);

  const onTableScroll = () => {
    setContextRecord(undefined);
    setHoverRecord(undefined);
  };

  const onMouseEnter = (event: React.MouseEvent, record: RecordType) => {
    // inlineActionsRef.current.clientWidth is not updated until the next render
    // need a delay to get the correct width
    setTimeout(() => {
      if (inlineActionsRef.current) {
        setHoverRecord(record);

        const style = inlineActionsRef.current?.style;

        // Since we are deferring the call, we need to persist the event
        // thus changing the type from an EventTarget to a HTMLElement
        const tg = event.target as HTMLElement;
        // The below conversion is correct because tr elements are of type HTMLElement
        const closestRowOrTarget = (tg.closest("tr") as HTMLElement) ?? tg;
        const parentElement = inlineActionsRef.current.parentElement;

        if (parentElement) {
          const parentPadding = parentElement
            ? parseFloat(
                window
                  .getComputedStyle(parentElement)
                  .getPropertyValue("padding-right"),
              )
            : 0;

          // Aligns the action buttons to the right side of their parent container
          const parentRight = parentElement.getBoundingClientRect().right;
          style.right =
            window.innerWidth < parentRight
              ? `${parentRight - window.innerWidth}px`
              : `${window.innerWidth - parentRight + parentPadding}px`;
        }

        // If the table is nested on another table, calculations are done from
        // the relative row instead of the top of the table
        const top =
          closestRowOrTarget.getBoundingClientRect().y -
          (isNested
            ? closestRowOrTarget?.offsetParent?.getBoundingClientRect().y ?? 0
            : 0);

        style.top = `${top}px`;
        style.height = `${closestRowOrTarget.getBoundingClientRect().height}px`;
      }
    }, 1);
  };

  const onContextMenu = (event: React.MouseEvent, record: RecordType) => {
    if (contextActionsRef.current) {
      event.preventDefault();
      const style = contextActionsRef.current.style;

      const distanceFromLeft =
        event.clientX -
        (contextActionsRef.current.offsetParent?.getBoundingClientRect()
          ?.left ?? 0);

      const distanceFromTop =
        event.clientY -
        (contextActionsRef.current.offsetParent?.getBoundingClientRect()?.top ??
          0);

      style.top = `${distanceFromTop}px`;
      style.left = `${distanceFromLeft}px`;

      // If the context menu is going to be cut off by the bottom of the table,
      // move it up so it is visible
      setContextRecord(record);
      setTimeout(() => rerenderContextMenuOnCutOff(event), 1);
    }
  };

  const rerenderContextMenuOnCutOff = (event: React.MouseEvent) => {
    if (!contextActionsRef.current) return;

    const parentElement = contextActionsRef.current.parentElement;
    const distanceFromTop =
      event.clientY -
      (contextActionsRef.current.offsetParent?.getBoundingClientRect()?.top ??
        0);
    const isCutOff =
      parentElement?.clientHeight &&
      distanceFromTop + contextActionsRef.current.clientHeight + 20 >
        parentElement?.clientHeight;
    const style = contextActionsRef.current.style;

    if (isNested && isCutOff) {
      style.top = `${
        parentElement?.clientHeight - contextActionsRef.current.clientHeight
      }px`;
    }
  };

  const onRowMouseLeave = (event: React.MouseEvent) => {
    const relatedTarget = event.relatedTarget;
    if (
      inlineActionsRef.current &&
      relatedTarget instanceof Element &&
      isOutsideFromTable(relatedTarget)
    ) {
      setHoverRecord(undefined);
    }
  };

  return {
    onMouseEnter,
    onContextMenu,
    inlineActionsRef,
    contextActionsRef,
    renderContextActions: () => context.renderContextActions(contextRecord),
    renderHoverActions: () => context.renderHoverActions(hoverRecord),
    onMouseLeave: onRowMouseLeave,
    hoverActionsOpen: !!hoverRecord,
    contextActionsOpen: !!contextRecord,
  };
};
