import { GetLayersWithDataReturn } from "screens/designStudio/hooks/useLayersWithData";
import {
  DownloadDeliveryInput,
  MarketingMaterial,
  MarketingMaterialTableItem,
} from "shared/types/marketingMaterials";
import {
  SPVariables,
  Template,
  TemplateFile,
} from "shared/types/salesEnablement";
import { getPageLayers, getVariables } from "utils/helpers.salesEnablement";
import { getMaterialFromMaterialTableItem } from "../hooks/useFetchMarketingMaterials";
import { raise } from "utils/errorMessage";
import { Progress, notification } from "antd";

export const pdfDownloadProgressKey = "pdfDownloadProgress";

export const getPdfDeliveryInput = ({
  material,
  template,
}: {
  material: MarketingMaterialTableItem;
  template: Template;
}): DownloadDeliveryInput => ({
  material: getMaterialFromMaterialTableItem(material),
  template: template,
  deliveryMethod: "download",
});

export const getIdmlDeliveryInput = ({
  material,
  template,
  getLayersWithData,
}: {
  material: MarketingMaterialTableItem;
  template: Template;
  getLayersWithData: GetLayersWithDataReturn;
}): DownloadDeliveryInput => {
  const { data, variables } = getIdmlDataAndVariables({
    templateFile:
      template.files[material.language] ?? raise("No template file found"),
    material,
    getLayersWithData,
  });

  return {
    material: getMaterialFromMaterialTableItem(material),
    template,
    variables,
    renderVariables: data,
    deliveryMethod: "download",
  };
};

type GetIdmlDataAndVariablesArgs = {
  templateFile: TemplateFile;
  material: MarketingMaterial;
  getLayersWithData: GetLayersWithDataReturn;
};

export const getIdmlDataAndVariables = ({
  templateFile,
  material,
  getLayersWithData,
}: GetIdmlDataAndVariablesArgs) => {
  const pageLayers = getPageLayers(templateFile);
  if (!pageLayers)
    return {
      data: {},
      variables: undefined,
    };
  const data = pageLayers
    ? getLayersWithData({
        layers: pageLayers,
        material,
      })
    : {};
  const variables: SPVariables | undefined = getVariables(material);
  return {
    data,
    variables,
  };
};

const isPdfType = (
  templateFile?: TemplateFile,
): templateFile is TemplateFile & { type: "pdf"; url: string } =>
  !!templateFile &&
  templateFile.type === "pdf" &&
  typeof templateFile.url === "string";

type GetDeliveriesFromMaterialsArgs = {
  materials: MarketingMaterialTableItem[];
  templates: Template[];
  getLayersWithData: GetLayersWithDataReturn;
};

type Delivery = {
  material: MarketingMaterialTableItem;
  templateFile: TemplateFile;
  deliveryData: DownloadDeliveryInput;
};

export const getDeliveriesFromMaterials = ({
  materials,
  templates,
  getLayersWithData,
}: GetDeliveriesFromMaterialsArgs): Delivery[] => {
  return materials.map(material => {
    const marketingMaterialTemplate: Template | undefined = templates.find(
      template => template.id === material.templateId,
    );
    const templateFile = marketingMaterialTemplate?.files[material.language];

    if (!marketingMaterialTemplate || !templateFile) {
      throw new Error("Template not found");
    }

    const isPdfDelivery = isPdfType(templateFile) && marketingMaterialTemplate;

    const deliveryData = isPdfDelivery
      ? getPdfDeliveryInput({
          material,
          template: marketingMaterialTemplate,
        })
      : getIdmlDeliveryInput({
          material,
          template: marketingMaterialTemplate,
          getLayersWithData,
        });

    return {
      material,
      templateFile,
      deliveryData,
    };
  });
};

export const openNotificationProgress = (
  downloadProgress: number,
  type: "pdf" | "zip",
) => {
  notification.open({
    key: pdfDownloadProgressKey,
    message: <strong>Creating {type} for download</strong>,
    duration: downloadProgress == 100 ? 4 : 0,
    bottom: 50,
    placement: "bottomRight",
    description: (
      <>
        <span>{type} is being created</span>
        <Progress type="line" percent={downloadProgress} />
      </>
    ),
  });
};
