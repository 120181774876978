export enum Platform {
  Facebook = "facebook",
  Google = "google",
}

type Audience = {
  id: string;
  name: string;
};

type Account = {
  adAccountId: string;
  adAccountName: string;
  dealerName: string;
};

export type GetAudienceIntegrationLogsResponse = {
  items: AudienceIntegrationLog[];
  next: string | undefined;
};

export type GetAudienceIntegrationLogsParameters = {
  next?: string;
};

export type AudienceIntegrationLog = {
  id: string;
  audience: Audience;
  account: Account;
  platform: Platform;
  createdAt: number;
  updatedAt: number;
  notes: string;
};

export type IUpdateLogParams = {
  notes: string;
};
