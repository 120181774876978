import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

export const useClientRect = (): [
  MutableRefObject<HTMLElement | undefined>,
  (node: HTMLElement) => void,
  ClientRect | null,
] => {
  const ref = useRef<HTMLElement>();
  const [rect, setRect] = useState<ClientRect | null>(null);

  const setRef = useCallback((node: HTMLElement) => {
    if (node) {
      setRect(node.getBoundingClientRect());
    }

    ref.current = node;
  }, []);

  return [ref, setRef, rect];
};

export const usePrevious = <T>(value: T): T => {
  const ref = useRef<T>();

  // updating the current previous value
  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current as T;
};
