import { NoEncryptionRounded, LockRounded } from "@mui/icons-material";
import { LayerElement } from "screens/designStudio/designStudioV2/types";
import { useLayerActions } from "./useLayerActions";
import { observer } from "mobx-react-lite";
import { isLocked } from "../helpers";

type ToggleLayerLockProps = {
  layer: LayerElement;
};

export const ToggleLayerLock = observer(({ layer }: ToggleLayerLockProps) => {
  const { toggleLock } = useLayerActions(layer);
  const Icon = isLocked(layer) ? LockRounded : NoEncryptionRounded;

  return (
    <Icon
      onClick={toggleLock}
      sx={{ fontSize: "medium", color: "action.active" }}
    />
  );
});
