import { message } from "antd";

export const handleDownload = (blob: Blob | null) => {
  if (blob) {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "image.jpg";
    link.click();
  }
};

export const handleDownloadFromUrl = async (image: string) => {
  const response = await fetch(image);
  const blob = await response.blob();

  handleDownload(blob);
};

export const handleDownloadAllFromUrl = (images?: string[]) => {
  if (!images) return;

  images.forEach(image => handleDownloadFromUrl(image));
};

export const handleCopyLink = (image: string) => {
  navigator.clipboard.writeText(image);
  message.success("URL copied to clipboard");
};

export const getImageInfoOnLoad = async (
  event: React.SyntheticEvent<HTMLImageElement, Event>,
  image: string,
) => {
  const target = event.target as HTMLImageElement;

  const response = await fetch(image);
  const blob = await response.blob();

  return { width: target.naturalWidth, height: target.naturalHeight, blob };
};
