import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
export const TextVariableIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M13.9997 6.66699C13.633 6.66699 13.333 6.36699 13.333 6.00033V4.66699C13.333 3.56033 12.4397 2.66699 11.333 2.66699H9.99968C9.63301 2.66699 9.33301 2.96699 9.33301 3.33366C9.33301 3.70033 9.63301 4.00033 9.99968 4.00033H11.333C11.6997 4.00033 11.9997 4.30033 11.9997 4.66699V6.00033C11.9997 6.86699 12.5597 7.61366 13.333 7.88699V8.11366C12.5597 8.38699 11.9997 9.12699 11.9997 10.0003V11.3337C11.9997 11.7003 11.6997 12.0003 11.333 12.0003H9.99968C9.63301 12.0003 9.33301 12.3003 9.33301 12.667C9.33301 13.0337 9.63301 13.3337 9.99968 13.3337H11.333C12.4397 13.3337 13.333 12.4403 13.333 11.3337V10.0003C13.333 9.63366 13.633 9.33366 13.9997 9.33366C14.3663 9.33366 14.6663 9.03366 14.6663 8.66699V7.33366C14.6663 6.96699 14.3663 6.66699 13.9997 6.66699Z"
          fill="black"
          fillOpacity="0.56"
        />
        <path
          d="M2.66634 4.66699V6.00033C2.66634 6.36699 2.36634 6.66699 1.99967 6.66699C1.63301 6.66699 1.33301 6.96699 1.33301 7.33366V8.66699C1.33301 9.03366 1.63301 9.33366 1.99967 9.33366C2.36634 9.33366 2.66634 9.63366 2.66634 10.0003V11.3337C2.66634 12.4403 3.55967 13.3337 4.66634 13.3337H5.99967C6.36634 13.3337 6.66634 13.0337 6.66634 12.667C6.66634 12.3003 6.36634 12.0003 5.99967 12.0003H4.66634C4.29967 12.0003 3.99967 11.7003 3.99967 11.3337V10.0003C3.99967 9.13366 3.43967 8.38699 2.66634 8.11366V7.88699C3.43967 7.61366 3.99967 6.86699 3.99967 6.00033V4.66699C3.99967 4.30033 4.29967 4.00033 4.66634 4.00033H5.99967C6.36634 4.00033 6.66634 3.70033 6.66634 3.33366C6.66634 2.96699 6.36634 2.66699 5.99967 2.66699H4.66634C3.55967 2.66699 2.66634 3.56033 2.66634 4.66699Z"
          fill="black"
          fillOpacity="0.56"
        />
        <path
          d="M5.33301 6.4C5.33301 6.62133 5.52108 6.8 5.75406 6.8H6.73652V9.6C6.73652 9.82133 6.92459 10 7.15757 10C7.39055 10 7.57862 9.82133 7.57862 9.6V6.8H8.56108C8.79406 6.8 8.98213 6.62133 8.98213 6.4C8.98213 6.17867 8.79406 6 8.56108 6H5.75406C5.52108 6 5.33301 6.17867 5.33301 6.4ZM10.2453 7.33333H8.56108C8.3281 7.33333 8.14003 7.512 8.14003 7.73333C8.14003 7.95467 8.3281 8.13333 8.56108 8.13333H8.98213V9.6C8.98213 9.82133 9.1702 10 9.40318 10C9.63617 10 9.82424 9.82133 9.82424 9.6V8.13333H10.2453C10.4783 8.13333 10.6663 7.95467 10.6663 7.73333C10.6663 7.512 10.4783 7.33333 10.2453 7.33333Z"
          fill="black"
          fillOpacity="0.56"
        />
      </svg>
    </SvgIcon>
  );
};
