import { queryClient } from "queryClient";
import { useMutation } from "react-query";
import API from "services";

export const useMutateOrderTags = () => {
  const newTag = async (name: string) => {
    const { result } = await API.services.assetBuilder.createOrderTag(name);
    return result;
  };

  const createTag = useMutation("newTag", newTag, {
    onSuccess: () => queryClient.invalidateQueries("orderTags"),
  });

  return { createTag };
};
