import { observer } from "mobx-react-lite";
import { ImageLayerElement } from "screens/designStudio/designStudioV2/types";
import { Slider } from "@mui/material";

type CornerRadiusProps = {
  element: ImageLayerElement;
};

const CornerRadius = observer(({ element }: CornerRadiusProps) => {
  const handleCornerRadiusChange = (
    _event: Event,
    newValue: number | number[],
  ) => {
    const cornerRadiusValue = Array.isArray(newValue) ? newValue[0] : newValue;
    element.set({ cornerRadius: cornerRadiusValue });
  };

  return (
    <Slider
      value={element?.cornerRadius || 1}
      onChange={handleCornerRadiusChange}
      valueLabelDisplay="auto"
      min={1}
      max={200}
      step={1}
      valueLabelFormat={value => value.toFixed(0)}
    />
  );
});

export default CornerRadius;
