import { Button, Drawer } from "antd";
import {
  Children,
  ReactElement,
  ReactNode,
  cloneElement,
  useState,
} from "react";
import { useGetAssetsListMutation } from "redux/media/media.api";
import { AssetMetadata } from "../AssetMetadata";
import { ButtonGroup } from "../ButtonGroup";
import { MediaHeader } from "../MediaHeader";
import { MediaProvider } from "../MediaProvider";
import { MediaTable } from "../MediaTable";
import { getSearchState } from "../getSearchState";
import { useQPLayout } from "../useQPLayout";
import { useQPSearch } from "../useQPSearch";
import { useSelected } from "../useSelected";

import styles from "./CAMSelect.module.scss";

type Props = {
  children: ReactElement;
  maxSelected?: number;
  fileTypes?: string[];
  search?: MGSearch;
  title?: string | ReactNode;
  resetOnClose?: boolean;
  width?: string;
  layout?: "table" | "grid";
  onClose?: (assets: WDAsset[]) => void;
};

export const CAMSelect = ({
  children,
  maxSelected = 0,
  fileTypes,
  search,
  title,
  resetOnClose = true,
  width = "95vw",
  layout,
  onClose,
}: Props) => {
  const [open, setOpen] = useState(false);
  const { selectedAssets } = useSelected();
  const [getAssets] = useGetAssetsListMutation();
  const { searchState, setSearchState, resetSearchState } = useQPSearch();
  const [, setLayout] = useQPLayout();
  const disabled =
    selectedAssets.length < 1 ||
    (maxSelected ? selectedAssets.length > maxSelected : false);

  const openDrawer = () => {
    if (search && !searchState) {
      setSearchState(getSearchState(search));
    }
    if (layout) {
      setLayout(layout);
    }
    setOpen(true);
  };

  const closeDrawer = () => {
    if (resetOnClose) {
      resetSearchState();
      setLayout(null);
    }
    setOpen(false);
  };

  const handleClick = async () => {
    if (selectedAssets.every(row => row.hiResURLRaw)) {
      onClose?.(selectedAssets);
    } else {
      onClose?.(await getAssets(selectedAssets.map(row => row.id)).unwrap());
    }
    closeDrawer();
  };

  return (
    <>
      {Children.map(children, child =>
        cloneElement(child, { onClick: openDrawer }),
      )}
      <Drawer
        destroyOnClose
        title={title || children.props.children}
        width={width}
        visible={open}
        closable={false}
        onClose={closeDrawer}
        className={styles.drawer}
        bodyStyle={{ background: "#f0f2f5", padding: "0 15px" }}
        headerStyle={{ borderColor: "#dedede" }}
        footerStyle={{ borderColor: "#dedede" }}
        footer={
          <ButtonGroup>
            <Button key="cancel" onClick={closeDrawer}>
              Close
            </Button>
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              disabled={disabled}
              hidden={maxSelected < 1}
              onClick={handleClick}
            >
              Select
            </Button>
          </ButtonGroup>
        }
      >
        <MediaProvider isDrawer fileTypes={fileTypes}>
          <div className={styles.body}>
            <MediaHeader />
            <MediaTable />
            <AssetMetadata />
          </div>
        </MediaProvider>
      </Drawer>
    </>
  );
};
