import { useMutation, useQueryClient } from "react-query";
import API from "services";

export const useAddRecentTemplate = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ userId, templateId }: { userId: string; templateId: string }) =>
      API.services.salesEnablement.addRecentTemplate(userId, templateId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["recent-templates"]);
      },
    },
  );

  return mutation;
};
