import { useEffect } from "react";
import { assetBatchExportNotification } from "screens/assetExporter/feedConfigurationV2/ProcessNotification";
import { createLinkAndRoute } from "screens/assetExporter/sourceFeed/utils";
import { useAssetBatchesExportRequests } from "./useExportAssetBatches";
import { useExportExecutionArns } from "./useExportExecutionArns";
import {
  ExportAssetBatchesStatusOutput,
  ExportForOption,
} from "shared/types/assetExporter";
import { useAppSelector } from "../useAppSelector";
import { ExportDestination } from "screens/assetExporter/feedConfigurationV2/exportDrawer/types";

const getExportTitle = (
  googleSpreadsheetOutput: ExportAssetBatchesStatusOutput | undefined,
  adLibraryOutput: ExportAssetBatchesStatusOutput | undefined,
): ExportForOption => {
  if (googleSpreadsheetOutput) {
    return "Off Platform Portal";
  } else if (adLibraryOutput) {
    return "Ad Library";
  }
  return "CSV download";
};

export function useAssetBatchExportNotification() {
  const { exportExecutionArns, handleSetExportExecutionArns } =
    useExportExecutionArns();
  const { exportAssetBatchNames, exportFeedName, exportRowCount } =
    useAppSelector(state => state.assetExporter.assetBatches);

  const assetBatchRequests = useAssetBatchesExportRequests(exportExecutionArns);

  useEffect(() => {
    assetBatchRequests.forEach(({ data: job }) => {
      if (!job) return;

      const outputs = job?.responseJson?.outputs || [];
      const googleSpreadsheetOutput = outputs.find(
        output => output.type === ExportDestination.GoogleSpreadsheet,
      );
      const csvOutput = outputs.find(
        output => output.type === ExportDestination.CSVDownload,
      );

      const adLibraryOutput = outputs.find(
        output => output.type === ExportDestination.AdLibrary,
      );

      const { fileUrl, portalUrl } = googleSpreadsheetOutput || {};

      const exportFor = getExportTitle(
        googleSpreadsheetOutput,
        adLibraryOutput,
      );

      assetBatchExportNotification({
        getCsvDownloadUrl: () => {
          if (csvOutput?.url) createLinkAndRoute(csvOutput.url);
        },
        notificationKey: job.executionId,
        onClose: () => {
          handleSetExportExecutionArns(
            exportExecutionArns.filter(arn => arn !== job.executionId),
          );
        },
        message: job.description,
        status: job.status,
        percentage: job.responseJson?.percentage ?? 0,
        exportFor: exportFor,
        onAdLibClick: () => {
          return;
        },
        portalLink: portalUrl,
        sheetLink: fileUrl,
        exportRowCount,
        exportAssetBatchNames,
        exportFeedName,
      });
    });
  }, [
    assetBatchRequests,
    handleSetExportExecutionArns,
    exportExecutionArns,
    exportRowCount,
    exportAssetBatchNames,
    exportFeedName,
  ]);
}
