import { Image, Input } from "antd";
import { useEffect, useState } from "react";
import { useToggle } from "shared/hooks/useToggle";
import { useCellContext } from "./CellContext";
import styles from "./EditCell.module.scss";
import { useEditableCell } from "../hooks/useEditableCell";
import useOutsideClick from "shared/hooks/useOutsideClick";
import { ActionIcons } from "./ActionIcons";

type Props = {
  value: string | number;
  imageURL?: string | null;
  onSave: (
    value: string | number,
    oldValue: string | number,
    disableConfirm?: boolean,
  ) => void;
  onApplyToAll?: () => void;
};

const EditCell = ({ value, onSave, onApplyToAll, imageURL }: Props) => {
  const [editing, toggleEditing] = useToggle(false);
  const { newValue, setNewValue, setOldValue } = useCellContext();
  const [textAreaValue, setTextAreaValue] = useState(value);

  const { handleToggleClicked, handleSave, handleKeyDown, handleEscape } =
    useEditableCell(undefined, undefined, onSave);

  const targetRef = useOutsideClick(() => {
    handleToggleClicked();
  });

  useEffect(() => {
    setNewValue(value);
    setOldValue(value);
  }, [value, setNewValue, setOldValue]);

  useEffect(() => {
    window.addEventListener("keydown", handleEscape);

    return () => {
      window.removeEventListener("keydown", handleEscape);
    };
  }, [handleEscape]);

  const rowsNumber =
    typeof newValue === "string" ? Math.ceil(newValue.length / 20) : 0; // assuming 20 characters per row

  return (
    <div>
      <div className={styles.editCellContainer}>
        <span className={styles.editCell} ref={targetRef}>
          {editing && (
            <Input.TextArea
              className={styles.textArea}
              rows={rowsNumber}
              value={textAreaValue}
              onChange={e => setTextAreaValue(e.target.value)}
              onKeyDown={handleKeyDown}
              onPressEnter={() => handleSave(textAreaValue, newValue, true)}
            />
          )}
          {!editing && (
            <div className={styles.notEditableContainer}>
              <ActionIcons
                value={newValue.toString()}
                onApplyToAll={onApplyToAll}
                toggleEditing={toggleEditing}
                imageURL={imageURL}
              />

              <div className={styles.valueContainer}>
                {!!imageURL && (
                  <div className={styles.imageContainer}>
                    <Image
                      preview={false}
                      height="40px"
                      src={newValue.toString()}
                    />
                  </div>
                )}
                {newValue}
              </div>
            </div>
          )}
        </span>
      </div>
    </div>
  );
};

export default EditCell;
