import { AudioPreviewHeader } from "./AudioPreviewHeader";
import styles from "./AudioPreviewCollapseSection.module.scss";
import { CollapseSection } from "./CollapseSection";

interface Props {
  activeKey: string[];
  idKey: string;
  handleOnChange: (key: string) => void;
  isActive: boolean;
  title: string;
  children?: React.ReactNode;
  duration: number;
}
const handleHeaderButtonClick = (
  event: React.MouseEvent<HTMLElement, MouseEvent>,
) => {
  event.stopPropagation();
};

export const AudioPreviewCollapseSection = ({
  activeKey,
  idKey,
  handleOnChange,
  isActive,
  title,
  children,
  duration,
}: Props) => {
  return (
    <CollapseSection
      activeKey={activeKey}
      idKey={idKey}
      handleOnChange={() => handleOnChange(activeKey[0])}
      customStyle={styles.collapseContainer}
      header={
        <AudioPreviewHeader
          isActive={isActive}
          title={title}
          onPlayClicked={handleHeaderButtonClick}
          duration={duration}
        />
      }
    >
      {children}
    </CollapseSection>
  );
};
