import { Button, Form } from "antd";
import styles from "../ConditionalFields.module.scss";
import { Language } from "shared/types/salesEnablement";
import uuid from "uuid";
import { useCallback } from "react";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { defaultLanguageOptions } from "shared/constants/salesEnablement";
import { FormListFieldData } from "antd/lib/form/FormList";
import { ValueInput } from "./conditions/ValueInput";
import { IfInput } from "./conditions/IfInput";

type Props = {
  activeLanguage: Language;
  field: FormListFieldData;
  primaryLanguage?: Language;
  isFirstField?: boolean;
  removeField?: () => void;
};

export const Conditions = ({
  field,
  activeLanguage,
  primaryLanguage,
  isFirstField,
  removeField,
}: Props) => {
  const disabled = primaryLanguage !== activeLanguage;

  const primaryLanguageLabel = defaultLanguageOptions.find(
    languageOption => languageOption.value === primaryLanguage,
  )?.label;

  const disabledInputTooltip = disabled
    ? `For editing conditions, please switch to ${primaryLanguageLabel} language`
    : undefined;

  const getFieldLabel = useCallback(
    (isFirstCondition: boolean) => {
      if (!isFirstCondition) return "And";
      if (isFirstField) return "If";
      return "Or If";
    },
    [isFirstField],
  );

  return (
    <Form.List name={[field.name, "conditions"]}>
      {(conditions, { add, remove }) =>
        conditions.map((cond, idx) => {
          const isLastCondition = idx === conditions.length - 1;
          const label = getFieldLabel(idx === 0);
          return (
            <div key={cond.key} className={styles.condition}>
              <div className={styles.label}>{label}</div>
              <IfInput
                cond={cond}
                disabled={disabled}
                disabledInputTooltip={disabledInputTooltip}
              />
              <ValueInput
                field={field}
                cond={cond}
                primaryLanguage={primaryLanguage}
                activeLanguage={activeLanguage}
                disabled={disabled}
                disabledInputTooltip={disabledInputTooltip}
              />
              {!disabled && (
                <div className={styles.actions}>
                  <Button
                    icon={<DeleteOutlined />}
                    type="text"
                    onClick={() => {
                      conditions.length === 1
                        ? removeField?.()
                        : remove(cond.name);
                    }}
                  />
                  {isLastCondition && (
                    <Button
                      icon={<PlusCircleOutlined />}
                      type="text"
                      onClick={() => add({ id: uuid.v4() })}
                    />
                  )}
                </div>
              )}
            </div>
          );
        })
      }
    </Form.List>
  );
};
