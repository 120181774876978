import { SearchOutlined } from "@ant-design/icons";
import { DateFilterDropdown } from "shared/components/DateFilterDropdown";
import { SearchFilterDropdown } from "shared/components/SearchFilterDropdown";
import { ITableColumnURL } from "shared/components/dataListURL/DataListURLTable";
import { Creative, LoadCampaignRecord } from "../../types";
import { numberInputFormatter } from "screens/campaignManagement/googlePerformanceMax/pmaxCampaignsTable/newCampaignDrawer/newCampaignForm/helpers";
import { InputNumber, Tag } from "antd";
import { useLoadCampaignsContext } from "../LoadProvider";
import { ThumbnailCell } from "shared/components/ThumbnailCell";
import { Link } from "@mui/material";
import { renderDate, getCalendarIcon } from "../../helpers";
import { useDataSourceActions } from "../hooks/useDataSourceActions";

export const useColumns = () => {
  const { setSelectedLineItem } = useLoadCampaignsContext();
  const { changeDataSource } = useDataSourceActions();

  const columns: ITableColumnURL<
    keyof LoadCampaignRecord,
    LoadCampaignRecord
  >[] = [
    {
      title: "Name",
      dataIndex: ["name"],
      key: "name",
      width: 350,
      filterDropdown: SearchFilterDropdown,
      filterIcon: <SearchOutlined />,
      render: (_, record) => {
        if (record.type === "campaign") {
          return record.name;
        }

        return (
          <div style={{ width: 350 }}>
            <ThumbnailCell
              name={record.name}
              image={record.creatives?.[0]?.thumbnailUrl}
            />
          </div>
        );
      },
    },
    {
      title: "Account",
      dataIndex: ["accountId"],
      key: "accountId",
      render: (_, record) => record.accountId && <Tag>{record.accountId}</Tag>,
    },
    {
      key: "startDate",
      title: "Start Date",
      dataIndex: ["startDate"],
      render: renderDate,
      filterDropdown: DateFilterDropdown,
      filterIcon: getCalendarIcon,
    },
    {
      key: "endDate",
      title: "End Date",
      dataIndex: ["endDate"],
      render: renderDate,
      filterDropdown: DateFilterDropdown,
      filterIcon: getCalendarIcon,
    },
    {
      key: "budget",
      title: "Budget",
      dataIndex: ["budget"],
      render: (value, record) => (
        <InputNumber
          onChange={newValue => {
            changeDataSource(record.id, record.type, "budget", +newValue);
          }}
          value={`${value || "0"}`}
          formatter={numberInputFormatter}
          style={{ width: "100%" }}
          precision={2}
        />
      ),
    },
    {
      key: "dailyBudget",
      title: "Daily Budget",
      dataIndex: ["dailyBudget"],
      render: (value, record) => {
        if (record.type === "lineItem") return undefined;

        return (
          <InputNumber
            onChange={newValue => {
              changeDataSource(
                record.id,
                record.type,
                "dailyBudget",
                +newValue,
              );
            }}
            value={`${value || "0"}`}
            formatter={numberInputFormatter}
            style={{ width: "100%" }}
            precision={2}
          />
        );
      },
    },
    {
      key: "cpmBid",
      title: "CPM Bid",
      dataIndex: ["cpmBid"],
      render: (value, record) => {
        if (record.type === "campaign") return undefined;

        return (
          <InputNumber
            onChange={newValue => {
              changeDataSource(record.id, record.type, "cpmBid", +newValue);
            }}
            value={`${value || "0"}`}
            bordered={true}
            formatter={numberInputFormatter}
            style={{ width: "100%" }}
            precision={2}
          />
        );
      },
    },
    {
      key: "creatives",
      title: "Creatives",
      dataIndex: ["creatives"],
      render: (creatives: Creative[] | undefined, record) => {
        if (record.type === "campaign") return undefined;

        if (!creatives?.length)
          return (
            <Link
              onClick={() => {
                setSelectedLineItem(record);
              }}
            >
              Add creative (0)
            </Link>
          );

        return (
          <Link
            onClick={() => {
              setSelectedLineItem(record);
            }}
          >{`Edit Creative (${creatives.length})`}</Link>
        );
      },
    },
  ];

  return columns;
};
