import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import styles from "./EditableSelectLabel.module.scss";

type Props = Readonly<{
  onEditClick: () => void;
  label: string;
}>;

const EditableSelectLabel = ({ onEditClick, label }: Props) => {
  return (
    <div className={styles.editableSelectLabel}>
      <span>{label}</span>{" "}
      <Button
        onClick={event => {
          event.stopPropagation();
          event.preventDefault();
          onEditClick();
        }}
        onMouseDown={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
        style={{
          padding: "0",
        }}
        type="link"
        icon={<EditOutlined />}
      />
    </div>
  );
};

export default EditableSelectLabel;
