import { Typography, Progress } from "antd";
import {
  PmaxUploadProgressNotification,
  getStatus,
  isCompleteNotificationData,
} from "./helpers";

export const ProgressNotification = (props: PmaxUploadProgressNotification) => {
  if (!isCompleteNotificationData(props)) return null;

  const { status, description, responseJson } = props;
  const notificationStatus = getStatus(status);
  const percentage =
    notificationStatus === "exception" ? 100 : responseJson?.percentage ?? 0;
  return (
    <div>
      <Typography.Text>{description}</Typography.Text>
      <Progress percent={percentage} size="small" status={notificationStatus} />
    </div>
  );
};
