import { memo } from "react";
import DashboardDetail from "./metrics/DashboardDetail";
import PublicLinkModal from "./metrics/PublicLinkModal";
import { Route, Routes } from "react-router-dom";
import { DashboardList } from "./metrics/DashboardList";
import { PublicDashboardDetail } from "./metrics/PublicDashboardDetail";
import { DataListProvider } from "shared/components/dataList";
import { PublicDashboardsList } from "./metrics/PublicDashboardsMgmt/PublicDashboardsList";
import useMetricsState from "./metrics/shared/hooks/useMetricsState";

const Metrics = () => {
  const {
    config,
    dashboardEnabled,
    shareDashboard,
    showLinkModal,
    setShowLinkModal,
    setShareDashboard,
  } = useMetricsState();

  return (
    <>
      {dashboardEnabled && (
        <Routes>
          <Route
            path={"detail/:id"}
            element={
              <>
                <PublicLinkModal
                  dashboard={shareDashboard}
                  showLinkModal={showLinkModal}
                  setShowLinkModal={setShowLinkModal}
                />
                <DashboardDetail
                  setShareDashboard={setShareDashboard}
                  setShowLinkModal={setShowLinkModal}
                />
              </>
            }
          />
          <Route
            path={"share/:id"}
            element={
              <>
                <PublicLinkModal
                  dashboard={shareDashboard}
                  showLinkModal={showLinkModal}
                  setShowLinkModal={setShowLinkModal}
                />
                <PublicDashboardDetail
                  setShareDashboard={setShareDashboard}
                  setShowLinkModal={setShowLinkModal}
                />
              </>
            }
          />
          <Route
            index
            element={
              <>
                <PublicLinkModal
                  key={`share-modal-${shareDashboard?.id}`}
                  dashboard={shareDashboard}
                  showLinkModal={showLinkModal}
                  setShowLinkModal={setShowLinkModal}
                />
                <DashboardList
                  setShareDashboard={setShareDashboard}
                  setShowLinkModal={setShowLinkModal}
                />
              </>
            }
          />
          <Route
            path="/shared-links"
            element={
              <>
                <DataListProvider config={config}>
                  <PublicDashboardsList />
                </DataListProvider>
              </>
            }
          ></Route>
        </Routes>
      )}
    </>
  );
};

export default memo(Metrics);
