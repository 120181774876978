import { ImageLayerElement, StoreType } from "../types";

const useMediaElementEffects = (
  element: ImageLayerElement,
  store?: StoreType,
) => {
  const setElementProperty = (
    property: string,
    value: boolean | number | string,
  ) => {
    element.set({ [property]: value });
  };

  const toggleBlur = () => {
    const newValue = !element.blurEnabled;
    setElementProperty("blurEnabled", newValue);
  };

  const toggleBrightness = () => {
    const newValue = !element.brightnessEnabled;
    setElementProperty("brightnessEnabled", newValue);
  };

  const toggleBorder = () => {
    const newValue = !element.borderSize ? 1 : 0;
    setElementProperty("borderSize", newValue);
  };

  const toggleCornerRadius = () => {
    const newValue = !element.cornerRadius ? 1 : 0;
    setElementProperty("cornerRadius", newValue);
  };

  const toggleGrayscale = () => {
    const newValue = !element.grayscaleEnabled;
    setElementProperty("grayscaleEnabled", newValue);
  };

  const toggleSepia = () => {
    const newValue = !element.sepiaEnabled;
    setElementProperty("sepiaEnabled", newValue);
  };

  const toggleShadow = () => {
    const newValue = !element.shadowEnabled;
    setElementProperty("shadowEnabled", newValue);
  };

  const toggleFlipX = () => {
    const newValue = !element.flipX;
    setElementProperty("flipX", newValue);
  };

  const toggleFlipY = () => {
    const newValue = !element.flipY;
    setElementProperty("flipY", newValue);
  };

  const toggleImagePageFit = () => {
    if (!store) return;

    const { height, width } = store.activePage;
    const pageWidth = width === "auto" ? store.width : width;
    const pageHeight = height === "auto" ? store.height : height;

    element.set({
      width: pageWidth,
      height: pageHeight,
      x: 0,
      y: 0,
    });
  };

  return {
    toggleBlur,
    toggleBrightness,
    toggleBorder,
    toggleCornerRadius,
    toggleGrayscale,
    toggleImagePageFit,
    toggleSepia,
    toggleShadow,
    toggleFlipX,
    toggleFlipY,
  };
};

export default useMediaElementEffects;
