import { Col, message as AntMessage, notification, Row, Spin } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { useRouteQuery } from "shared/hooks/useRouteQuery";

// redux
import actions from "../redux/rootActions";

// types
import {
  IAccountRecord,
  IGetAccountsResult,
} from "shared/types/accountManagement";
import { IDataTableError } from "shared/types/errors";
import { ISelectValue, OperationMode } from "shared/types/inputValues";

import { FeedType } from "shared/types/configuration";
import { IGetBrandsResult, IBrandRecord } from "shared/types/brandManagement";
import {
  IUploadImageFormInput,
  IUploadImagesResult,
  TUploadGroup,
  TUploadLogosDictionary,
  TUploadLogosS3DictionaryKey,
} from "shared/types/uploadManagement";

// components
import DealerDrawer from "./dealerManagement/dealerDrawer/DealerDrawer";
import DealerTable from "./dealerManagement/DealerTable";

import { useNavigate } from "react-router-dom";
import { useDatadog } from "shared/hooks/useDatadog";
import { industryType } from "utils/helpers";

type TLogosUploadStatus = null | "UPLOADING" | "COMPLETE";

interface IDealerManagement {
  loggedInRole: string;
  dealersResult: null | IGetAccountsResult;
  dealersError: null | IDataTableError;
  dealersMessage: string;
  processingDealers: boolean;
  oems: IBrandRecord[] | any[];
  getOemsResult: IGetBrandsResult | null;
  processingOems: boolean;
  getOems: (paginationToken?: string) => void;
  selectedOems: ISelectValue[];
  dealers: IAccountRecord[];
  getDealers: (paginationToken?: string) => void;
  createDealer: (inputDealer: IAccountRecord) => void;
  deleteDealer: (inputDealer: IAccountRecord) => void;
  updateDealer: (inputDealer: IAccountRecord) => void;
  resetDealerFeedback: () => void;
  uploadImages: (
    imagesToUpload: IUploadImageFormInput[],
    featureName: string,
    group: TUploadGroup,
    mode: OperationMode,
    logoKeysToBeUploaded?: string[],
    newOemButtonClicked?: boolean,
  ) => void;
  processingUpload: boolean;
  uploadError: null | IDataTableError;
  s3AssetBucket: string;

  imagesToUpload: { images: IUploadImagesResult[] };
  logosFromS3InDictionary: TUploadLogosDictionary;

  logosUploadStatus: TLogosUploadStatus;
  setLogosUploadStatus: (status: TLogosUploadStatus) => void;
  resetUploadImagesInRedux: () => void;
  feed: FeedType;
}

const initialDealerObj: IAccountRecord = {
  key: 0,
  dealerName: "",
  dealerCode: "",
  dealerOem: "",
  dealerUrl: "",
  logoUrl: "",
  dealerAddress: "",
  dealerCity: "",
  dealerUsState: "",
  dealerZip: "",
  dealerZipCodeList: [],
  dealerPhoneNumber: "",
  dealerFinalPriceName: "",
  logoUrlsFromS3: {
    horizontalImagesFromS3: [],
    verticalImagesFromS3: [],
    squareImagesFromS3: [],
    horizontalEventImagesFromS3: [],
    verticalEventImagesFromS3: [],
    squareEventImagesFromS3: [],
  },
  webIntPositions: [],
  coopDetails: {
    emailOrPortal: "",
    coopSite: "",
    coopEmail: "",
    coopUsername: "",
    coopPassword: "",
    coopDealerCode: "",
    coopLoginLocked: false,
  },
  details: {
    facebook: {
      fbPageId: "",
      fbAccountId: "",
      fbCatalogId: "",
      fbPixelId: "",
      fbInstagramId: "",
    },
  },
};

const DealerManagement: FC<IDealerManagement> = ({
  loggedInRole,
  dealersResult,
  dealersError,
  dealersMessage,
  processingDealers,
  getOems,
  oems,
  getOemsResult,
  processingOems,
  dealers,
  getDealers,
  createDealer,
  deleteDealer,
  updateDealer,
  resetDealerFeedback,
  uploadImages,
  processingUpload,
  uploadError,
  s3AssetBucket,

  imagesToUpload,
  logosFromS3InDictionary,
  setLogosUploadStatus,
  logosUploadStatus,
  resetUploadImagesInRedux,
  feed,
}) => {
  const navigate = useNavigate();
  const storeKeyToEdit = useRouteQuery("store_key");

  const [dealerToEditWithLogos, setDealerToEditWithLogos] =
    useState<IAccountRecord>({
      ...initialDealerObj,
      logoUrlsFromS3: {
        horizontalImagesFromS3: [],
        verticalImagesFromS3: [],
        squareImagesFromS3: [],
        horizontalEventImagesFromS3: [],
        verticalEventImagesFromS3: [],
        squareEventImagesFromS3: [],
      },
    });

  // This state will be used to detect if any changes were made
  const [compareDealerToEditWithLogos, setCompareDealerToEditWithLogos] =
    useState<IAccountRecord>({
      ...initialDealerObj,
      logoUrlsFromS3: {
        horizontalImagesFromS3: [],
        verticalImagesFromS3: [],
        squareImagesFromS3: [],
        horizontalEventImagesFromS3: [],
        verticalEventImagesFromS3: [],
        squareEventImagesFromS3: [],
      },
    });

  const [mode, setMode] = useState<OperationMode>("");

  const [showAddDealerDrawer, setShowAddDealerDrawer] =
    useState<boolean>(false);
  const [newDealerButtonClicked, toggleNewDealerButtonClicked] =
    useState<boolean>(false);

  const pathParam = industryType("auto")
    ? "store-management"
    : "account-management";
  const toggleAddDealerDrawer = useCallback(
    (show: boolean, drawerMode: OperationMode, storeKeySelect?: string) => {
      if (show && drawerMode === "UPDATE" && storeKeySelect) {
        navigate(`/${pathParam}?store_key=${storeKeySelect}`);
      }
      if (!show) {
        navigate(`/${pathParam}`);
      }
      setShowAddDealerDrawer(show);
      setMode(drawerMode);
    },
    [navigate, pathParam],
  );

  // "horizontalLogos" | "verticalLogos" | "squareLogos" | "horizontalLogosEvent" | "verticalLogosEvent" | "squareLogosEvent"
  const [logoKeysToBeUploaded, setLogoKeysToBeUploaded] = useState<string[]>(
    [],
  );

  useDatadog();

  useEffect(() => {
    if (!dealerToEditWithLogos || !dealerToEditWithLogos.logoUrlsFromS3) {
      return;
    }

    if (newDealerButtonClicked && logosFromS3InDictionary) {
      if (logoKeysToBeUploaded.length === 0) return;
      // check if logosFromS3InDictionary matches up with logoKeysToBeUploaded
      for (const key of logoKeysToBeUploaded) {
        const type = key.split("Logos")[0]; // horizontal, vertical, square
        const tempKey = `${type}ImagesFromS3`;

        if (
          logosFromS3InDictionary[tempKey as TUploadLogosS3DictionaryKey]
            .length === 0
        ) {
          return;
        }
      }
      // If we reach this point without returning, then it matches up.
      // So reset logoKeysToBeUploaded to an empty array.
      // This is so that if we hit this useEffect again, we won't setOemToEditWithLogos twice
      setLogoKeysToBeUploaded([]);
    }

    const { logoUrlsFromS3 } = dealerToEditWithLogos;
    const { horizontalImagesFromS3, verticalImagesFromS3, squareImagesFromS3 } =
      logoUrlsFromS3;

    const tempDealerToEditWithLogos =
      mode === "UPDATE" || newDealerButtonClicked
        ? {
            horizontalImagesFromS3: [
              ...horizontalImagesFromS3,
              ...logosFromS3InDictionary.horizontalImagesFromS3,
            ],
            verticalImagesFromS3: [
              ...verticalImagesFromS3,
              ...logosFromS3InDictionary.verticalImagesFromS3,
            ],
            squareImagesFromS3: [
              ...squareImagesFromS3,
              ...logosFromS3InDictionary.squareImagesFromS3,
            ],
            horizontalEventImagesFromS3: [],
            verticalEventImagesFromS3: [],
            squareEventImagesFromS3: [],
          }
        : {
            horizontalImagesFromS3:
              logosFromS3InDictionary.horizontalImagesFromS3,

            verticalImagesFromS3: logosFromS3InDictionary.verticalImagesFromS3,

            squareImagesFromS3: logosFromS3InDictionary.squareImagesFromS3,

            horizontalEventImagesFromS3: [],
            verticalEventImagesFromS3: [],
            squareEventImagesFromS3: [],
          };

    setDealerToEditWithLogos({
      ...dealerToEditWithLogos,
      logoUrlsFromS3: tempDealerToEditWithLogos,
    });

    if (newDealerButtonClicked) {
      setTimeout(() => {
        setLogosUploadStatus("COMPLETE");
        setLogosUploadStatus(null);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logosFromS3InDictionary]);

  const [dealerToEdit, setDealerToEdit] = useState<IAccountRecord>({
    ...initialDealerObj,
  });

  const [imageToUpload, setImageToUpload] = useState<IUploadImageFormInput>({
    file: null,
    filename: "",
    type: "",
    preview: "",
  });

  const [dealersState, setDealers] = useState<IAccountRecord[]>([]);

  useEffect(() => {
    // !TODO: This function is Overkill-complexity.  Needs to be refactored.
    /*
      TODO: Create more general function that can be applied to
      multiple data management screens
      that require loading on scroll
    */
    const layoutContentElement = document.querySelector(".ant-layout-content");
    const { scannedCount, paginationKey = { dealer_name: "" } } =
      dealersResult || {
        scannedCount: 0,
        paginationKey: { dealer_name: "" },
      };
    if (layoutContentElement) {
      const handleScroll = (event: any) => {
        const { target } = event;
        if (target) {
          const { scrollHeight, clientHeight, scrollTop } = target;
          if (
            scrollTop >= scrollHeight - clientHeight &&
            dealers.length < scannedCount
          ) {
            const backupKey =
              dealers.length > 0 && dealers[dealers.length - 1]
                ? dealers[dealers.length - 1]?.dealerName
                : "";
            getDealers(paginationKey?.dealer_name || backupKey);
          }
        }
      };

      layoutContentElement.addEventListener("scroll", handleScroll);

      return () =>
        layoutContentElement.removeEventListener("scroll", handleScroll);
    }

    // eslint-disable-next-line
  }, [dealersResult]);

  useEffect(() => {
    getDealers();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const { scannedCount, paginationKey = { oem_name: "" } } =
      getOemsResult || {
        scannedCount: 0,
        paginationKey: undefined,
      };
    if (!paginationKey || oems.length >= scannedCount) {
      return;
    }
    getOems(paginationKey.oem_name);
  }, [getOems, getOemsResult, oems]);

  useEffect(() => {
    if (dealers.length > 0 && dealersResult) {
      setDealers(dealers);
      resetDealerFeedback();
    }
    // eslint-disable-next-line
  }, [dealers, dealersResult]);

  useEffect(() => {
    const { scannedCount, paginationKey = { dealer_name: "" } } =
      dealersResult || {
        scannedCount: 0,
        paginationKey: undefined,
      };

    if (!paginationKey || dealers.length >= scannedCount) {
      return;
    }
    getDealers(paginationKey.dealer_name);
  }, [dealers, dealersResult, getDealers]);

  useEffect(() => {
    if (dealersError) {
      AntMessage.error(`Stores error: ${dealersError.message}`);
      resetDealerFeedback();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (uploadError) {
      AntMessage.error(`Upload error: ${uploadError.message}`);
    }
  }, [uploadError]);

  useEffect(() => {
    if (dealersMessage) {
      AntMessage.success(dealersMessage);
      resetDealerFeedback();
    }

    // eslint-disable-next-line
  }, [dealersMessage]);

  useEffect(() => {
    if (!showAddDealerDrawer) {
      setImageToUpload({
        file: null,
        filename: "",
        type: "",
        preview: "",
      });
      setMode("");
      setDealerToEdit({
        // eslint-disable-next-line
        ...initialDealerObj,
        key: dealers.length,
      });
    }

    // eslint-disable-next-line
  }, [showAddDealerDrawer, dealers]);

  const isLoading: boolean =
    processingDealers ||
    !!dealersResult?.paginationKey?.dealer_name ||
    processingOems ||
    !!getOemsResult?.paginationKey?.oem_name;

  useEffect(() => {
    if (isLoading) return;
    const storeToEdit = dealers.find(
      store => store.key === Number(storeKeyToEdit ?? -1),
    );

    if (storeToEdit) {
      setDealerToEditWithLogos(storeToEdit);
      setCompareDealerToEditWithLogos(storeToEdit);
      toggleAddDealerDrawer(true, "UPDATE");
    }
    if (dealers?.length && storeKeyToEdit && !storeToEdit) {
      notification.info({
        message: "The store you are trying to edit does not exist",
      });
      navigate("/store-management", { replace: true });
    }
  }, [storeKeyToEdit, dealers, isLoading, toggleAddDealerDrawer, navigate]);

  const onDeleteDealer = () => {
    deleteDealer(dealerToEditWithLogos);
    navigate(`/${pathParam}`, { replace: true });
    toggleAddDealerDrawer(false, "");
  };

  const dealerDrawerProps = {
    mode,
    dealerToEdit,
    processingDealers,
    processingUpload,
    showAddDealerDrawer,
    setDealerToEdit,
    imageToUpload,
    setImageToUpload,
    s3AssetBucket,
    dealers,
  };

  const onClickNew = () => {
    setDealerToEdit({
      ...initialDealerObj,
    });
    toggleAddDealerDrawer(true, "CREATE");
  };

  return (
    <>
      <Row>
        <Spin
          spinning={processingDealers || processingOems}
          aria-busy={processingDealers || processingOems}
          size="large"
          data-cy="store-management-spinner"
        >
          <Col span={24}>
            <DealerTable
              onClickNew={onClickNew}
              onClickEdit={(record: IAccountRecord) => {
                setDealerToEditWithLogos(record);
                setCompareDealerToEditWithLogos(record);
                toggleAddDealerDrawer(true, "UPDATE", record.key.toString());
              }}
              onClickDuplicate={(record: IAccountRecord) => {
                setDealerToEditWithLogos(record);
                setCompareDealerToEditWithLogos(record);
                toggleAddDealerDrawer(true, "DUPLICATE", record.key.toString());
              }}
              feed={feed}
              dealers={dealersState}
              userRole={loggedInRole}
              dealersMessage={dealersMessage}
            />
          </Col>
        </Spin>
      </Row>
      <DealerDrawer
        {...dealerDrawerProps}
        feed={feed}
        initialDealerObj={initialDealerObj}
        closeAddDealerDrawer={() => {
          toggleAddDealerDrawer(false, "");
          setDealerToEdit({
            ...initialDealerObj,
          });
        }}
        submitDealerForm={() => {
          switch (mode) {
            case "CREATE":
            case "DUPLICATE":
              createDealer(dealerToEditWithLogos);
              break;
            case "UPDATE":
              updateDealer(dealerToEditWithLogos);
              break;
            default:
              break;
          }
        }}
        deleteDealerForm={onDeleteDealer}
        uploadImages={uploadImages}
        imagesToUpload={imagesToUpload}
        dealerToEditWithLogos={dealerToEditWithLogos}
        setDealerToEditWithLogos={setDealerToEditWithLogos}
        resetUploadImagesInRedux={resetUploadImagesInRedux}
        toggleNewDealerButtonClicked={toggleNewDealerButtonClicked}
        newDealerButtonClicked={newDealerButtonClicked}
        logosUploadStatus={logosUploadStatus}
        setLogoKeysToBeUploaded={setLogoKeysToBeUploaded}
        logoKeysToBeUploaded={logoKeysToBeUploaded}
        compareDealerToEditWithLogos={compareDealerToEditWithLogos}
        setCompareDealerToEditWithLogos={setCompareDealerToEditWithLogos}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  const {
    auth,
    dealerManagement,
    uploadManagement,
    oemManagement,
    configuration,
  } = state;
  const { dealerRecords, processingDealers, error, result, dealersMessage } =
    dealerManagement;
  const { config, feed } = configuration;
  const {
    imagesToUpload,
    processingUpload,

    logosFromS3InDictionary,
    logosUploadStatus,

    error: uploadError,
  } = uploadManagement;
  const {
    selectedOems,
    oemRecords: oems,
    processingOems,
    result: getOemsResult,
  } = oemManagement;

  return {
    loggedInRole: auth.user?.role,
    selectedOems,
    oems,
    getOemsResult,
    processingOems,
    dealers: dealerRecords,
    processingDealers,
    dealersError: error,
    dealersResult: result,
    dealersMessage,
    processingUpload,
    imagesToUpload,

    uploadError,
    s3AssetBucket: config.s3AssetBucket,

    logosFromS3InDictionary,
    logosUploadStatus,
    feed,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => {
  return {
    getDealers: (paginationToken = "") => {
      dispatch(actions.dealerManagement.getDealers(paginationToken));
    },
    createDealer: (inputDealer: IAccountRecord) => {
      dispatch(actions.dealerManagement.createDealer(inputDealer));
    },
    updateDealer: (inputDealer: IAccountRecord) => {
      dispatch(actions.dealerManagement.updateDealer(inputDealer));
    },
    deleteDealer: (inputDealer: IAccountRecord) => {
      dispatch(actions.dealerManagement.deleteDealer(inputDealer));
    },
    resetDealerFeedback: () => {
      dispatch(actions.dealerManagement.resetDealerFeedback());
    },
    uploadImages: (
      imagesToUpload: IUploadImageFormInput[],
      featureName: string,
      group: TUploadGroup,
      mode: OperationMode,
      logoKeysToBeUploaded?: string[],
      newOemButtonClicked?: boolean,
    ) => {
      dispatch(
        actions.uploadManagement.uploadImages(
          imagesToUpload,
          featureName,
          group,
          mode,
          logoKeysToBeUploaded,
          newOemButtonClicked,
        ),
      );
    },
    getOems: (paginationToken = "") => {
      dispatch(actions.oemManagement.getOems(paginationToken));
    },
    setLogosUploadStatus: (status: TLogosUploadStatus) => {
      dispatch(actions.uploadManagement.setLogosUploadStatus(status));
    },
    resetUploadImagesInRedux: () => {
      dispatch(actions.uploadManagement.resetUploadImagesInRedux());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DealerManagement);
