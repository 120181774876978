import { useMatch } from "react-router-dom";
import { ROUTES } from "../../constants";

export const useLoadStep = (): "select" | "result" => {
  const isSelectStep = !!useMatch(ROUTES.selectStep);
  const isResultStep = !!useMatch(ROUTES.resultStep);

  if (isSelectStep) {
    return "select";
  }

  if (isResultStep) {
    return "result";
  }

  throw new Error("Invalid step");
};
