import { chunk } from "lodash";
import { getTransactabilityScore } from "shared/hooks/assetBuilder/useFetchTransactabilityScores";
import { OfferData } from "shared/types/assetBuilder";
import { OfferType } from "shared/types/shared";
import { formatDateValue } from "utils/helpers";
import { formatRate } from "utils/helpers.offer";
import { TCellPropsArray, TCollapsableTypes } from "./OfferTypeCollapse";
import * as utils from "./OfferTypeCollapse.schema.utils";
import { Props as RowProps } from "./offerTypeCollapse/Row";
import { TCellOfferKey } from "./offerTypeCollapse/row/Cell";
/**
 *
 * NOTE: parser and formatter
 *  - parser: from displayed data to offer data
 *  - formatter: from offer data to the data to be displayed
 * @param offerType
 * @param offerData
 * @param transactabilities
 * @returns
 */
export const getHeaderSchema = (
  offerType: TCollapsableTypes,
  offerData?: Partial<OfferData>,
  transactabilities?: { [key: string]: string },
): TCellPropsArray | undefined => {
  switch (offerType) {
    case "vehicleInfo":
      return [
        {
          type: "offer_info",
          title: "Vehicle Info",
          editable: false,
        },
        {
          type: "image",
          offerKey: "imageUrl",
          value: offerData?.imageUrl,
          editable: false,
        },
        {
          type: "text",
          offerKey: "vin",
          value: offerData?.vin,
          title: "VIN",
          editable: false,
        },
        {
          type: "text",
          offerKey: "msrp",
          value: offerData?.msrp,
          title: "MSRP",
          editable: true,
        },
        {
          type: "date",
          offerKey: "expirationDate",
          value: offerData?.expirationDate,
          title: "Expiration Date",
          editable: true,
        },
        {
          type: "text",
          offerKey: "daysInStock",
          value: offerData?.daysInStock,
          title: "Days In Stock",
          editable: false,
        },
      ];

    case OfferType.Lease:
      return [
        { type: "offer_info", title: "Lease", editable: false },
        {
          type: "text",
          offerKey: "numberOfPayments",
          value: offerData?.numberOfPayments,
          title: "Number of Payments",
          editable: true,
          allowPaymentEngine: true,
        },
        {
          type: "text",
          offerKey: "monthlyPayment",
          value: offerData?.monthlyPayment,
          title: "Monthly Payment",
          editable: true,
          allowPaymentEngine: true,
        },
        {
          type: "text",
          offerKey: "totalDueAtSigning",
          value:
            offerData?.leaseTotalDueAtSigning || offerData?.totalDueAtSigning,
          title: "Down Payment",
          editable: true,
          allowPaymentEngine: true,
        },
        {
          type: "text",
          offerKey: "residualValue",
          value: offerData?.residualValue,
          title: "Residual Sales Value",
          editable: true,
        },
        {
          type: "text",
          offerKey: "leaseVehicleSalesPrice",
          value: offerData?.leaseVehicleSalesPrice,
          title: "Vehicle Sales Price",
          editable: true,
          allowPaymentEngine: true,
        },
        {
          type: "score",
          offerKey: "transactabilityScore",
          value: `${
            getTransactabilityScore(OfferType.Lease, transactabilities) || "N/A"
          }`,
          title: "Transactability Score",
          editable: false,
        },
      ];

    case OfferType.ZeroDownLease:
      return [
        {
          type: "offer_info",
          title: "Zero Down Lease",
        },
        {
          type: "text",
          offerKey: "zeroDownLeaseNumberOfPayments",
          value: offerData?.zeroDownLeaseNumberOfPayments,
          title: "Number of Payments",
          editable: true,
          allowPaymentEngine: true,
        },
        {
          type: "text",
          offerKey: "zeroDownLeaseMonthlyPayment",
          value: offerData?.zeroDownLeaseMonthlyPayment,
          title: "Monthly Payment",
          editable: true,
          allowPaymentEngine: true,
        },
        {
          type: "text",
          offerKey: "zeroDownLeaseResidualValue",
          value: offerData?.zeroDownLeaseResidualValue,
          title: "Residual Sales Value",
          editable: true,
        },
        {
          type: "text",
          offerKey: "zeroDownLeaseTotalDueAtSigningInclRebates",
          value: offerData?.zeroDownLeaseTotalDueAtSigningInclRebates,
          title: "Total Due at Signing Incl. Rebates",
          editable: true,
        },
      ];

    case OfferType.Finance:
      return [
        {
          type: "offer_info",
          title: "Finance",
        },
        {
          type: "text",
          offerKey: "financeRate",
          value: formatRate(offerData?.financeRate || ""),
          title: "Rate (%)",
          editable: true,
          parser: value => {
            return value.replace("%", "");
          },
        },
        {
          type: "text",
          offerKey: "financeTerm",
          value: offerData?.financeTerm,
          title: "Term",
          editable: true,
          allowPaymentEngine: true,
        },
        {
          type: "text",
          offerKey: "financePayment",
          value: offerData?.financePayment,
          title: "Payment",
          editable: true,
          allowPaymentEngine: true,
        },
      ];

    case OfferType.Purchase:
      return [
        {
          type: "offer_info",
          title: "Purchase",
        },
        {
          type: "text",
          offerKey: "dealerPrice",
          value: offerData?.dealerPrice,
          title: "Dealer Enrolled Price",
          editable: true,
        },
        {
          type: "text",
          offerKey: "dealerDiscount",
          value: offerData?.dealerDiscount,
          title: "Dealer Discount",
          editable: true,
        },
        {
          type: "text",
          offerKey: "finalPriceName",
          value: offerData?.finalPriceName,
          title: "Final Price Name",
          editable: true,
        },
        {
          type: "text",
          offerKey: "finalPrice",
          value: offerData?.finalPrice,
          title: "Final Price",
          editable: true,
        },
        {
          type: "text",
          offerKey: "finalPriceName",
          value: offerData?.finalPriceName,
          title: "Final Price Name",
          editable: true,
        },
        {
          type: "text",
          offerKey: "accessoryPrice",
          value: offerData?.accessoryPrice,
          title: "Accessory Price",
          editable: true,
        },
      ];

    case OfferType.APR:
      return [
        {
          type: "offer_info",
          title: "APR",
        },
        {
          type: "text",
          offerKey: "aprRate",
          value: formatRate(offerData?.aprRate || ""),
          title: "Rate (%)",
          editable: true,
          parser: value => {
            return value.replace("%", "");
          },
        },
        {
          type: "text",
          offerKey: "aprTerm",
          value: offerData?.aprTerm,
          title: "Term",
          editable: true,
        },
        {
          type: "computed",
          offerKey: "aprPayment",
          value: offerData?.aprPayment,
          title: "Payment",
          warningMessage: "This value is calculated from other fields",
        },
        {
          type: "text",
          offerKey: "aprAmntFinanced",
          value: offerData?.aprAmntFinanced,
          title: "Amount Financed",
          editable: true,
        },
      ];

    case OfferType.Misc:
      return [{ type: "offer_info", title: "All fileds" }];
  }
};

export const getRowSchema = (
  offerType: TCollapsableTypes,
  offerData?: Partial<OfferData>,
  transactabilities?: { [key: string]: string },
):
  | Array<{
      type: RowProps["type"];
      row: TCellPropsArray;
    }>
  | undefined => {
  const nonMiscKeys: string[] = [
    "vin",
    "aprRate",
    "daysInStock",
    "financeRate",
    "lastUpdated",
  ];

  switch (offerType) {
    case "vehicleInfo":
      return [
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "year",
              value: offerData?.year,
              title: "Year",
              editable: true,
            },
            {
              type: "text",
              offerKey: "make",
              value: offerData?.make,
              title: "Make",
              editable: true,
            },
            {
              type: "text",
              offerKey: "model",
              value: offerData?.model,
              title: "Model",
              editable: true,
            },
            {
              type: "text",
              offerKey: "trim",
              value: offerData?.trim,
              title: "Trim",
              editable: true,
            },
            {
              type: "text",
              offerKey: "driveTrain",
              value: offerData?.driveTrain,
              title: "Drivetrain",
              editable: true,
            },
          ],
        },
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "transmission",
              value: offerData?.transmission,
              title: "Transmission",
              editable: true,
            },
            {
              type: "text",
              offerKey: "cylinders",
              value: offerData?.cylinders,
              title: "Cyllinders",
              editable: true,
            },
            {
              type: "text",
              offerKey: "exteriorColor",
              value: offerData?.exteriorColor,
              title: "Exterior Color",
              editable: true,
            },
            {
              type: "text",
              offerKey: "feature1",
              value: offerData?.feature1,
              title: "Feature 1",
              editable: true,
            },
            {
              type: "text",
              offerKey: "feature2",
              value: offerData?.feature2,
              title: "Feature 2",
              editable: true,
            },
          ],
        },
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "vehicleCondition",
              value: offerData?.vehicleCondition,
              title: "Condition",
              editable: true,
            },
            {
              type: "text",
              offerKey: "mileage",
              value: offerData?.mileage,
              title: "Mileage",
              editable: true,
            },
            {
              type: "text",
              offerKey: "accessories",
              value: offerData?.accessories,
              title: "Accessories",
              editable: true,
            },
            {
              type: "text",
              offerKey: "modelCode",
              value: offerData?.modelCode,
              title: "Model Code",
              editable: true,
            },
            {
              type: "text",
              offerKey: "modelCode",
              value: offerData?.packagesExtended,
              title: "Package Extended",
              editable: true,
            },
          ],
        },
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "vehicleType",
              value: offerData?.vehicleType,
              title: "Vehicle Type",
              editable: true,
            },
            {
              type: "text",
              offerKey: "cabStyle",
              value: offerData?.cabStyle,
              title: "Cab Style",
              editable: true,
            },
            {
              type: "text",
              offerKey: "stockNumber",
              value: offerData?.stockNumber,
              title: "Stock Number",
              editable: true,
            },
            {
              type: "text",
              offerKey: "dateInStock",
              value: offerData?.dateInStock,
              title: "Date in Stock",
              editable: offerData ? false : true,
              formatter: value => {
                return formatDateValue(value);
              },
            },
          ],
        },
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "advertisedPrice",
              value: offerData?.advertisedPrice,
              title: "Advertised Price",
              editable: true,
            },
            {
              type: "text",
              offerKey: "invoicePrice",
              value: offerData?.invoicePrice,
              title: "Invoice Price",
              editable: true,
            },
            {
              type: "text",
              offerKey: "accountingCost",
              value: offerData?.accountingCost,
              title: "Accounting Cost",
              editable: true,
            },
            {
              type: "text",
              offerKey: "modelId",
              value: offerData?.modelId,
              title: "Model ID",
              editable: true,
            },
          ],
        },
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "packageNames",
              value: offerData?.packageNames,
              title: "Package Names",
              editable: true,
            },
            {
              type: "text",
              offerKey: "packageCodes",
              value: offerData?.packageCodes,
              title: "Package Codes",
              editable: true,
            },
            {
              type: "carousel",
              offerKey: "imageUrls",
              value: offerData?.imageUrls,
              title: "Account Images",
              editable: true,
            },
            {
              type: "text",
              offerKey: "optionCodes",
              value: offerData?.optionCodes,
              title: "Option Codes",
              editable: true,
            },
            {
              type: "text",
              offerKey: "dmsStatusCode",
              value: offerData?.dmsStatusCode,
              title: "DMS Status Code",
              editable: true,
            },
          ],
        },
      ];
    case OfferType.Lease:
      return [
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "totalDueAtSigningInclRebates",
              value: offerData?.totalDueAtSigningInclRebates,
              title: "Total Due at Signing Incl.Rebates",
              editable: true,
            },
            {
              type: "text",
              offerKey: "capCost",
              value: offerData?.capCost,
              title: "Capitalized Cost",
              editable: true,
            },
            {
              type: "text",
              offerKey: "capCostReduction",
              value: offerData?.capCostReduction,
              title: "Cap Cost Reduction",
              editable: true,
            },
            {
              type: "text",
              offerKey: "netAdjustedCapitalizedCost",
              value: offerData?.netAdjustedCapitalizedCost,
              title: "Net Adjusted Cap Cost",
              editable: true,
            },
            {
              type: "text",
              offerKey: "totalLeaseCharge",
              value: offerData?.totalLeaseCharge,
              title: "Total Leasse Charge",
              editable: true,
            },
          ],
        },
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "totalPayments",
              value: offerData?.totalPayments,
              title: "Total Payments",
              editable: true,
            },
            {
              type: "text",
              offerKey: "securityDeposit",
              value: offerData?.securityDeposit,
              title: "Security Deposit",
              editable: true,
            },
            {
              type: "text",
              offerKey: "terminationFee",
              value: offerData?.terminationFee,
              title: "Termination Fee",
              editable: true,
            },
            {
              type: "text",
              offerKey: "minFICO",
              value: offerData?.minFICO,
              title: "min FICO",
              editable: true,
            },
            {
              type: "text",
              offerKey: "milesPerYear",
              value: offerData?.milesPerYear,
              title: "Miles Per Year",
              editable: true,
              allowPaymentEngine: true,
            },
          ],
        },
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "centsPerMile",
              value: offerData?.centsPerMile,
              title: "Cents Per Mile",
              editable: true,
            },
            {
              type: "text",
              offerKey: "acquisitionFee",
              value: offerData?.acquisitionFee,
              title: "Acquisition Fee",
              editable: true,
            },
            {
              type: "text",
              offerKey: "suggestedDealerContribution",
              value: offerData?.suggestedDealerContribution,
              title: "Suggested Dealer Contribution",
              editable: true,
            },
          ],
        },
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "capCostIncludesAcqusitionFee",
              value: offerData?.capCostIncludesAcqusitionFee,
              title: "Cap Cost Includes Acq. Fee",
              editable: true,
            },
            {
              type: "text",
              offerKey: "capCostIncludesFirstMonthPayment",
              value: offerData?.capCostIncludesFirstMonthPayment,
              title: "Cap Cost Incl. 1st Month Payment",
              editable: true,
            },
            {
              type: "text",
              offerKey: "dasIncludesAcqusitionFee",
              value: offerData?.dasIncludesAcqusitionFee,
              title: "DAS Incl Acq. Fee",
              editable: true,
            },
            {
              type: "text",
              offerKey: "dasIncludesFirstMonthPayment",
              value: offerData?.dasIncludesFirstMonthPayment,
              title: "DAS Incl 1st Month Payment",
              editable: true,
            },
          ],
        },
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "additionalLeaseDisclosure",
              value: offerData?.additionalLeaseDisclosure,
              title: "Additional Lease Disclosure",
              editable: true,
            },
            {
              type: "text",
              offerKey: "leaseLender",
              value: offerData?.leaseLender,
              title: "Lease Lender",
              editable: true,
            },
          ],
        },
        ...utils.generateRabateFieldRows(OfferType.Lease, offerData),
        ...utils.generateCustomFieldRows(OfferType.Lease, offerData),
      ];

    case OfferType.ZeroDownLease:
      return [
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "capCost",
              value: offerData?.capCost,
              title: "Capitalized Cost",
              editable: true,
            },
            {
              type: "text",
              offerKey: "capCostReduction",
              value: offerData?.capCostReduction,
              title: "Cap Cost Reduction",
              editable: true,
            },
            {
              type: "text",
              offerKey: "netAdjustedCapitalizedCost",
              value: offerData?.netAdjustedCapitalizedCost,
              title: "Net Adjusted Cap Cost",
              editable: true,
            },
            {
              type: "text",
              offerKey: "zeroDownLeaseTotalLeaseCharge",
              value: offerData?.zeroDownLeaseTotalLeaseCharge,
              title: "Totala Lease Charge",
              editable: true,
            },
            {
              type: "text",
              offerKey: "zeroDownLeaseVehicleSalesPrice",
              value: offerData?.zeroDownLeaseVehicleSalesPrice,
              title: "Vehicle Sales Price",
              editable: true,
              allowPaymentEngine: true,
            },
          ],
        },
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "totalPayments",
              value: offerData?.totalPayments,
              title: "Total Payments",
              editable: true,
            },
            {
              type: "text",
              offerKey: "securityDeposit",
              value: offerData?.securityDeposit,
              title: "Security Deposit",
              editable: true,
            },
            {
              type: "text",
              offerKey: "zeroDownLeaseTotalDueAtSigning",
              value: offerData?.zeroDownLeaseTotalDueAtSigning,
              title: "Down Payment",
              editable: true,
            },
            {
              type: "text",
              offerKey: "zeroDownLeaseTerminationFee",
              value: offerData?.zeroDownLeaseTerminationFee,
              title: "Termination Fee",
              editable: true,
            },
            {
              type: "text",
              offerKey: "zeroDownLeaseMinFICO",
              value: offerData?.zeroDownLeaseMinFICO,
              title: "FICO",
              editable: true,
              allowPaymentEngine: true,
            },
          ],
        },
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "zeroDownLeaseMilesPerYear",
              value: offerData?.zeroDownLeaseMilesPerYear,
              title: "Miles per Year",
              editable: true,
              allowPaymentEngine: true,
            },
            {
              type: "text",
              offerKey: "zeroDownLeaseCentsPerMile",
              value: offerData?.zeroDownLeaseCentsPerMile,
              title: "Cents per Mile",
              editable: true,
            },
            {
              type: "text",
              offerKey: "zeroDownLeaseAcquisitionFee",
              value: offerData?.zeroDownLeaseAcquisitionFee,
              title: "Acquisition Fee",
              editable: true,
            },
            {
              type: "text",
              offerKey: "zeroDownSuggestedDealerContribution",
              value: offerData?.zeroDownSuggestedDealerContribution,
              title: "Sug Dealer Contribution",
              editable: true,
            },
            {
              type: "text",
              offerKey: "additionalZeroDownLeaseDisclosure",
              value: offerData?.additionalZeroDownLeaseDisclosure,
              title: "Addtl Zero Down Lease Discl",
              editable: true,
            },
          ],
        },

        // rebate fields
        ...utils.generateRabateFieldRows(OfferType.ZeroDownLease, offerData),
        ...utils.generateCustomFieldRows(OfferType.ZeroDownLease, offerData),
      ];

    case OfferType.Finance:
      return [
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "amntFinanced",
              value: offerData?.amntFinanced,
              title: "Amount Financed",
              editable: true,
            },
            {
              type: "text",
              offerKey: "financeDownPayment",
              value: offerData?.financeDownPayment,
              title: "Finance Down Payment",
              editable: true,
              allowPaymentEngine: true,
            },
            {
              type: "text",
              offerKey: "financeMinFICO",
              value: offerData?.financeMinFICO,
              title: "Min FICO",
              editable: true,
            },
            {
              type: "text",
              offerKey: "leaseVehicleSalesPrice",
              value: offerData?.leaseVehicleSalesPrice,
              title: "Sales Price",
              editable: true,
            },
            {
              type: "text",
              offerKey: "financeLender",
              value: offerData?.financeLender,
              title: "Finance Lender",
              editable: true,
            },
          ],
        },
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "additionalFinanceDisclosure",
              value: offerData?.additionalFinanceDisclosure,
              title: "Additional Finance Disclosure",
              editable: true,
            },
          ],
        },

        ...utils.generateCustomFieldRows(OfferType.Finance, offerData),
      ];

    case OfferType.Purchase:
      return [
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "savingsOffMSRP",
              value: offerData?.savingsOffMSRP,
              title: "Savings Off MSRP",
              editable: true,
            },
            {
              type: "text",
              offerKey: "savingsOffMSRPTitle",
              value: offerData?.savingsOffMSRPTitle,
              title: "Savings Off MSRP Title",
              editable: true,
            },
            {
              type: "text",
              offerKey: "savingsOffMSRPDescription",
              value: offerData?.savingsOffMSRPDescription,
              title: "Savings Off MSRP Description",
              editable: true,
            },
            {
              type: "computed",
              offerKey: "percentOffMSRP",
              value: offerData?.percentOffMSRP,
              title: "Percentage Off MSRP",
              warningMessage: "This value is calculated from other fields",
            },
            {
              type: "text",
              offerKey: "priceAfterConditionalRebates",
              value: offerData?.priceAfterConditionalRebates,
              title: "Price After Conditional Rebates",
              editable: true,
            },
            {
              type: "text",
              offerKey: "transactabilityScore",
              value: `${
                getTransactabilityScore(
                  OfferType.Purchase,
                  transactabilities,
                ) || "N/A"
              }`,
              title: "Transactability Score",
              editable: false,
            },
          ],
        },
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "additionalPurchaseDisclosure",
              value: offerData?.additionalPurchaseDisclosure,
              title: "Additional Purchase Disclosure",
              editable: true,
            },
          ],
        },

        ...utils.generateRabateFieldRows(OfferType.Purchase, offerData),
        ...utils.generateRabateFieldRows(
          OfferType["Purchase(Conditional)"],
          offerData,
        ),
        ...utils.generateCustomFieldRows(OfferType.Purchase, offerData),
      ];

    case OfferType.APR:
      return [
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "aprMinFICO",
              value: offerData?.aprMinFICO,
              title: "Min FICO",
              editable: true,
            },
            {
              type: "text",
              offerKey: "aprLender",
              value: offerData?.aprLender,
              title: "APR Lender",
              editable: true,
            },
            {
              type: "text",
              offerKey: "additionalAPRDisclosure",
              value: offerData?.additionalAPRDisclosure,
              title: "Additional APR Disclosure",
              editable: true,
            },
          ],
        },
        ...utils.generateCustomFieldRows(OfferType.APR, offerData),
      ];

    case OfferType.Misc:
      const offerDataKeys = Object.keys(
        offerData ?? {},
      ) as Array<TCellOfferKey>;

      const slices = chunk(
        offerDataKeys.filter(key => !nonMiscKeys.includes(key)),
        5,
      );

      return slices.map(slice => ({
        type: "row",
        row: (slice as Array<TCellOfferKey>).map(key => ({
          type: "text",
          offerKey: key,
          value: (offerData as any)?.[key],
          title: key,
          editable: true,
        })),
      }));
  }
};

export const getNewRowSchema = (
  offerType: TCollapsableTypes,
  offerData?: Partial<OfferData>,
):
  | Array<{
      type: RowProps["type"];
      row: TCellPropsArray;
    }>
  | undefined => {
  switch (offerType) {
    case "vehicleInfo":
      return [
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "year",
              value: offerData?.year,
              title: "Year",
              editable: true,
            },
            {
              type: "text",
              offerKey: "make",
              value: offerData?.make,
              title: "Make",
              editable: true,
            },
            {
              type: "text",
              offerKey: "model",
              value: offerData?.model,
              title: "Model",
              editable: true,
            },
            {
              type: "text",
              offerKey: "trim",
              value: offerData?.trim,
              title: "Trim",
              editable: true,
            },
            {
              type: "text",
              offerKey: "driveTrain",
              value: offerData?.driveTrain,
              title: "Drivetrain",
              editable: true,
            },
          ],
        },
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "transmission",
              value: offerData?.transmission,
              title: "Transmission",
              editable: true,
            },
            {
              type: "text",
              offerKey: "cylinders",
              value: offerData?.cylinders,
              title: "Cyllinders",
              editable: true,
            },
            {
              type: "text",
              offerKey: "exteriorColor",
              value: offerData?.exteriorColor,
              title: "Exterior Color",
              editable: true,
            },
            {
              type: "text",
              offerKey: "feature1",
              value: offerData?.feature1,
              title: "Feature 1",
              editable: true,
            },
            {
              type: "text",
              offerKey: "feature2",
              value: offerData?.feature2,
              title: "Feature 2",
              editable: true,
            },
          ],
        },
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "vehicleCondition",
              value: offerData?.vehicleCondition,
              title: "Condition",
              editable: true,
            },
            {
              type: "text",
              offerKey: "mileage",
              value: offerData?.mileage,
              title: "Mileage",
              editable: true,
            },
            {
              type: "text",
              offerKey: "accessories",
              value: offerData?.accessories,
              title: "Accessories",
              editable: true,
            },
            {
              type: "text",
              offerKey: "modelCode",
              value: offerData?.modelCode,
              title: "Model Code",
              editable: true,
            },
            {
              type: "text",
              offerKey: "modelCode",
              value: offerData?.packagesExtended,
              title: "Package Extended",
              editable: true,
            },
          ],
        },
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "vehicleType",
              value: offerData?.vehicleType,
              title: "Vehicle Type",
              editable: true,
            },
            {
              type: "text",
              offerKey: "cabStyle",
              value: offerData?.cabStyle,
              title: "Cab Style",
              editable: true,
            },
            {
              type: "text",
              offerKey: "stockNumber",
              value: offerData?.stockNumber,
              title: "Stock Number",
              editable: true,
            },
            {
              type: "text",
              offerKey: "dateInStock",
              value: offerData?.dateInStock,
              title: "Date in Stock",
              editable: offerData ? false : true,
              formatter: value => {
                return formatDateValue(value);
              },
            },
          ],
        },
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "advertisedPrice",
              value: offerData?.advertisedPrice,
              title: "Advertised Price",
              editable: true,
            },
            {
              type: "text",
              offerKey: "invoicePrice",
              value: offerData?.invoicePrice,
              title: "Invoice Price",
              editable: true,
            },
            {
              type: "text",
              offerKey: "accountingCost",
              value: offerData?.accountingCost,
              title: "Accounting Cost",
              editable: true,
            },
            {
              type: "text",
              offerKey: "modelId",
              value: offerData?.modelId,
              title: "Model ID",
              editable: true,
            },
          ],
        },
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "packageNames",
              value: offerData?.packageNames,
              title: "Package Names",
              editable: true,
            },
            {
              type: "text",
              offerKey: "packageCodes",
              value: offerData?.packageCodes,
              title: "Package Codes",
              editable: true,
            },
            {
              type: "carousel",
              offerKey: "imageUrls",
              value: offerData?.imageUrls,
              title: "Account Images",
              editable: true,
            },
            {
              type: "text",
              offerKey: "optionCodes",
              value: offerData?.optionCodes,
              title: "Option Codes",
              editable: true,
            },
            {
              type: "text",
              offerKey: "dmsStatusCode",
              value: offerData?.dmsStatusCode,
              title: "DMS Status Code",
              editable: true,
            },
          ],
        },
      ];
    case OfferType.Lease:
      return [
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "totalDueAtSigningInclRebates",
              value: offerData?.totalDueAtSigningInclRebates,
              title: "Total Due at Signing Incl.Rebates",
              editable: true,
            },
            {
              type: "text",
              offerKey: "capCost",
              value: offerData?.capCost,
              title: "Capitalized Cost",
              editable: true,
            },
            {
              type: "text",
              offerKey: "capCostReduction",
              value: offerData?.capCostReduction,
              title: "Cap Cost Reduction",
              editable: true,
            },
            {
              type: "text",
              offerKey: "netAdjustedCapitalizedCost",
              value: offerData?.netAdjustedCapitalizedCost,
              title: "Net Adjusted Cap Cost",
              editable: true,
            },
            {
              type: "text",
              offerKey: "totalLeaseCharge",
              value: offerData?.totalLeaseCharge,
              title: "Total Leasse Charge",
              editable: true,
            },
          ],
        },
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "totalPayments",
              value: offerData?.totalPayments,
              title: "Total Payments",
              editable: true,
            },
            {
              type: "text",
              offerKey: "securityDeposit",
              value: offerData?.securityDeposit,
              title: "Security Deposit",
              editable: true,
            },
            {
              type: "text",
              offerKey: "terminationFee",
              value: offerData?.terminationFee,
              title: "Termination Fee",
              editable: true,
            },
            {
              type: "text",
              offerKey: "minFICO",
              value: offerData?.minFICO,
              title: "min FICO",
              editable: true,
            },
            {
              type: "text",
              offerKey: "milesPerYear",
              value: offerData?.milesPerYear,
              title: "Miles Per Year",
              editable: true,
            },
          ],
        },
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "centsPerMile",
              value: offerData?.centsPerMile,
              title: "Cents Per Mile",
              editable: true,
            },
            {
              type: "text",
              offerKey: "acquisitionFee",
              value: offerData?.acquisitionFee,
              title: "Acquisition Fee",
              editable: true,
            },
            {
              type: "text",
              offerKey: "suggestedDealerContribution",
              value: offerData?.suggestedDealerContribution,
              title: "Suggested Dealer Contribution",
              editable: true,
            },
          ],
        },
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "capCostIncludesAcqusitionFee",
              value: offerData?.capCostIncludesAcqusitionFee,
              title: "Cap Cost Includes Acq. Fee",
              editable: true,
            },
            {
              type: "text",
              offerKey: "capCostIncludesFirstMonthPayment",
              value: offerData?.capCostIncludesFirstMonthPayment,
              title: "Cap Cost Incl. 1st Month Payment",
              editable: true,
            },
            {
              type: "text",
              offerKey: "dasIncludesAcqusitionFee",
              value: offerData?.dasIncludesAcqusitionFee,
              title: "DAS Incl Acq. Fee",
              editable: true,
            },
            {
              type: "text",
              offerKey: "dasIncludesFirstMonthPayment",
              value: offerData?.dasIncludesFirstMonthPayment,
              title: "DAS Incl 1st Month Payment",
              editable: true,
            },
          ],
        },
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "additionalLeaseDisclosure",
              value: offerData?.additionalLeaseDisclosure,
              title: "Additional Lease Disclosure",
              editable: true,
            },
            {
              type: "text",
              offerKey: "leaseLender",
              value: offerData?.leaseLender,
              title: "Lease Lender",
              editable: true,
            },
          ],
        },
        ...utils.generateRabateFieldRows(OfferType.Lease, offerData),
        ...utils.generateCustomFieldRows(OfferType.Lease, offerData),
      ];

    case OfferType.ZeroDownLease:
      return [
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "capCost",
              value: offerData?.capCost,
              title: "Capitalized Cost",
              editable: true,
            },
            {
              type: "text",
              offerKey: "capCostReduction",
              value: offerData?.capCostReduction,
              title: "Cap Cost Reduction",
              editable: true,
            },
            {
              type: "text",
              offerKey: "netAdjustedCapitalizedCost",
              value: offerData?.netAdjustedCapitalizedCost,
              title: "Net Adjusted Cap Cost",
              editable: true,
            },
            {
              type: "text",
              offerKey: "zeroDownLeaseTotalLeaseCharge",
              value: offerData?.zeroDownLeaseTotalLeaseCharge,
              title: "Totala Lease Charge",
              editable: true,
            },
            {
              type: "text",
              offerKey: "zeroDownLeaseVehicleSalesPrice",
              value: offerData?.zeroDownLeaseVehicleSalesPrice,
              title: "Vehicle Sales Price",
              editable: true,
            },
          ],
        },
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "totalPayments",
              value: offerData?.totalPayments,
              title: "Total Payments",
              editable: true,
            },
            {
              type: "text",
              offerKey: "securityDeposit",
              value: offerData?.securityDeposit,
              title: "Security Deposit",
              editable: true,
            },
            {
              type: "text",
              offerKey: "zeroDownLeaseTotalDueAtSigning",
              value: offerData?.zeroDownLeaseTotalDueAtSigning,
              title: "Down Payment",
              editable: true,
            },
            {
              type: "text",
              offerKey: "zeroDownLeaseTerminationFee",
              value: offerData?.zeroDownLeaseTerminationFee,
              title: "Termination Fee",
              editable: true,
            },
            {
              type: "text",
              offerKey: "zeroDownLeaseMinFICO",
              value: offerData?.zeroDownLeaseMinFICO,
              title: "FICO",
              editable: true,
            },
          ],
        },
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "zeroDownLeaseMilesPerYear",
              value: offerData?.zeroDownLeaseMilesPerYear,
              title: "Miles per Year",
              editable: true,
            },
            {
              type: "text",
              offerKey: "zeroDownLeaseCentsPerMile",
              value: offerData?.zeroDownLeaseCentsPerMile,
              title: "Cents per Mile",
              editable: true,
            },
            {
              type: "text",
              offerKey: "zeroDownLeaseAcquisitionFee",
              value: offerData?.zeroDownLeaseAcquisitionFee,
              title: "Acquisition Fee",
              editable: true,
            },
            {
              type: "text",
              offerKey: "zeroDownSuggestedDealerContribution",
              value: offerData?.zeroDownSuggestedDealerContribution,
              title: "Sug Dealer Contribution",
              editable: true,
            },
            {
              type: "text",
              offerKey: "additionalZeroDownLeaseDisclosure",
              value: offerData?.additionalZeroDownLeaseDisclosure,
              title: "Addtl Zero Down Lease Discl",
              editable: true,
            },
          ],
        },

        // rebate fields
        ...utils.generateRabateFieldRows(OfferType.ZeroDownLease, offerData),
        ...utils.generateCustomFieldRows(OfferType.ZeroDownLease, offerData),
      ];

    case OfferType.Finance:
      return [
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "amntFinanced",
              value: offerData?.amntFinanced,
              title: "Amount Financed",
              editable: true,
            },
            {
              type: "text",
              offerKey: "financeDownPayment",
              value: offerData?.financeDownPayment,
              title: "Finance Down Payment",
              editable: true,
            },
            {
              type: "text",
              offerKey: "financeMinFICO",
              value: offerData?.financeMinFICO,
              title: "Min FICO",
              editable: true,
            },
            {
              type: "text",
              offerKey: "leaseVehicleSalesPrice",
              value: offerData?.leaseVehicleSalesPrice,
              title: "Sales Price",
              editable: true,
            },
            {
              type: "text",
              offerKey: "financeLender",
              value: offerData?.financeLender,
              title: "Finance Lender",
              editable: true,
            },
          ],
        },
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "additionalFinanceDisclosure",
              value: offerData?.additionalFinanceDisclosure,
              title: "Additional Finance Disclosure",
              editable: true,
            },
          ],
        },

        ...utils.generateCustomFieldRows(OfferType.Finance, offerData),
      ];

    case OfferType.Purchase:
      return [
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "savingsOffMSRP",
              value: offerData?.savingsOffMSRP,
              title: "Savings Off MSRP",
              editable: true,
            },
            {
              type: "text",
              offerKey: "savingsOffMSRPTitle",
              value: offerData?.savingsOffMSRPTitle,
              title: "Savings Off MSRP Title",
              editable: true,
            },
            {
              type: "text",
              offerKey: "savingsOffMSRPDescription",
              value: offerData?.savingsOffMSRPDescription,
              title: "Savings Off MSRP Description",
              editable: true,
            },
            {
              type: "computed",
              offerKey: "percentOffMSRP",
              value: offerData?.percentOffMSRP,
              title: "Percentage Off MSRP",
              warningMessage: "This value is calculated from other fields",
            },
            {
              type: "text",
              offerKey: "priceAfterConditionalRebates",
              value: offerData?.priceAfterConditionalRebates,
              title: "Price After Conditional Rebates",
              editable: true,
            },
          ],
        },
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "additionalPurchaseDisclosure",
              value: offerData?.additionalPurchaseDisclosure,
              title: "Additional Purchase Disclosure",
              editable: true,
            },
          ],
        },

        ...utils.generateRabateFieldRows(OfferType.Purchase, offerData),
        ...utils.generateRabateFieldRows(
          OfferType["Purchase(Conditional)"],
          offerData,
        ),
        ...utils.generateCustomFieldRows(OfferType.Purchase, offerData),
      ];

    case OfferType.APR:
      return [
        {
          type: "row",
          row: [
            {
              type: "text",
              offerKey: "aprMinFICO",
              value: offerData?.aprMinFICO,
              title: "Min FICO",
              editable: true,
            },
            {
              type: "text",
              offerKey: "aprLender",
              value: offerData?.aprLender,
              title: "APR Lender",
              editable: true,
            },
            {
              type: "text",
              offerKey: "additionalAPRDisclosure",
              value: offerData?.additionalAPRDisclosure,
              title: "Additional APR Disclosure",
              editable: true,
            },
          ],
        },
        ...utils.generateCustomFieldRows(OfferType.APR, offerData),
      ];
  }
};

export const getNewHeaderSchema = (
  offerType: TCollapsableTypes,
  offerData?: Partial<OfferData>,
  transactabilities?: { [key: string]: string },
): TCellPropsArray | undefined => {
  switch (offerType) {
    case "vehicleInfo":
      return [
        {
          type: "offer_info",
          title: "Vehicle Info",
          editable: false,
        },
        {
          type: "image",
          offerKey: "imageUrl",
          value: offerData?.imageUrl,
          editable: false,
        },

        {
          type: "text",
          offerKey: "vin",
          value: offerData?.vin,
          title: "VIN",
          editable: true,
        },
        {
          type: "text",
          offerKey: "msrp",
          value: offerData?.msrp,
          title: "MSRP",
          editable: true,
        },
        {
          type: "date",
          offerKey: "expirationDate",
          value: offerData?.expirationDate,
          title: "Expiration Date",
          editable: true,
        },
        {
          type: "text",
          offerKey: "daysInStock",
          value: offerData?.daysInStock,
          title: "Days In Stock",
          editable: true,
        },
      ];

    case OfferType.Lease:
      return [
        { type: "offer_info", title: "Lease", editable: false },
        {
          type: "text",
          offerKey: "numberOfPayments",
          value: offerData?.numberOfPayments,
          title: "Number of Payments",
          editable: true,
        },
        {
          type: "text",
          offerKey: "monthlyPayment",
          value: offerData?.monthlyPayment,
          title: "Monthly Payment",
          editable: true,
        },
        {
          type: "text",
          offerKey: "totalDueAtSigning",
          value:
            offerData?.leaseTotalDueAtSigning || offerData?.totalDueAtSigning,
          title: "Down Payment",
          editable: true,
        },
        {
          type: "text",
          offerKey: "residualValue",
          value: offerData?.residualValue,
          title: "Residual Sales Value",
          editable: true,
        },
        {
          type: "text",
          offerKey: "leaseVehicleSalesPrice",
          value: offerData?.leaseVehicleSalesPrice,
          title: "Vehicle Sales Price",
          editable: true,
        },
        {
          type: "score",
          offerKey: "transactabilityScore",
          value: `${
            getTransactabilityScore(OfferType.Lease, transactabilities) || "N/A"
          }`,
          title: "Transactability Score",
          editable: false,
        },
      ];

    case OfferType.ZeroDownLease:
      return [
        {
          type: "offer_info",
          title: "Zero Down Lease",
        },
        {
          type: "text",
          offerKey: "zeroDownLeaseNumberOfPayments",
          value: offerData?.zeroDownLeaseNumberOfPayments,
          title: "Number of Payments",
          editable: true,
        },
        {
          type: "text",
          offerKey: "zeroDownLeaseMonthlyPayment",
          value: offerData?.zeroDownLeaseMonthlyPayment,
          title: "Monthly Payment",
          editable: true,
        },
        {
          type: "text",
          offerKey: "zeroDownLeaseResidualValue",
          value: offerData?.zeroDownLeaseResidualValue,
          title: "Residual Sales Value",
          editable: true,
        },
        {
          type: "text",
          offerKey: "zeroDownLeaseTotalDueAtSigningInclRebates",
          value: offerData?.zeroDownLeaseTotalDueAtSigningInclRebates,
          title: "Total Due at Signing Incl. Rebates",
          editable: true,
        },
      ];

    case OfferType.Finance:
      return [
        {
          type: "offer_info",
          title: "Finance",
        },
        {
          type: "text",
          offerKey: "financeRate",
          value: formatRate(offerData?.financeRate || ""),
          title: "Rate (%)",
          editable: true,
          parser: value => {
            return value.replace("%", "");
          },
        },
        {
          type: "text",
          offerKey: "financeTerm",
          value: offerData?.financeTerm,
          title: "Term",
          editable: true,
        },
        {
          type: "text",
          offerKey: "financePayment",
          value: offerData?.financePayment,
          title: "Payment",
          editable: true,
        },
      ];

    case OfferType.Purchase:
      return [
        {
          type: "offer_info",
          title: "Purchase",
        },
        {
          type: "text",
          offerKey: "dealerPrice",
          value: offerData?.dealerPrice,
          title: "Dealer Enrolled Price",
          editable: true,
        },
        {
          type: "text",
          offerKey: "dealerDiscount",
          value: offerData?.dealerDiscount,
          title: "Dealer Discount",
          editable: true,
        },
        {
          type: "text",
          offerKey: "dealerDiscountName",
          value: offerData?.dealerDiscountName,
          title: "Dealer Discount Name",
          editable: true,
        },
        {
          type: "text",
          offerKey: "finalPrice",
          value: offerData?.finalPrice,
          title: "Final Price",
          editable: true,
        },
        {
          type: "text",
          offerKey: "finalPriceName",
          value: offerData?.finalPriceName,
          title: "Final Price Name",
          editable: true,
        },
        {
          type: "text",
          offerKey: "accessoryPrice",
          value: offerData?.accessoryPrice,
          title: "Accessory Price",
          editable: true,
        },
      ];

    case OfferType.APR:
      return [
        {
          type: "offer_info",
          title: "APR",
        },
        {
          type: "text",
          offerKey: "aprRate",
          value: formatRate(offerData?.aprRate || ""),
          title: "Rate (%)",
          editable: true,
          parser: value => {
            return value.replace("%", "");
          },
        },
        {
          type: "text",
          offerKey: "aprTerm",
          value: offerData?.aprTerm,
          title: "Term",
          editable: true,
        },
        {
          type: "computed",
          offerKey: "aprPayment",
          value: offerData?.aprPayment,
          title: "Payment",
          warningMessage: "This value is calculated from other fields",
        },
        {
          type: "text",
          offerKey: "aprAmntFinanced",
          value: offerData?.aprAmntFinanced,
          title: "Amount Financed",
          editable: true,
        },
      ];
  }
};
