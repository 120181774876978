import _ from "lodash";
import { IAccount } from "shared/types/accountManagement";
import {
  ExtendedObjectType,
  ILayerObject,
  ITemplate,
  TSelectionStyle,
  TTextSelectionProperty,
  IVariableAlias,
  CanvasType,
  TVariableAlias,
} from "shared/types/designStudio";
import { TDisclosureType } from "shared/types/legalLingo";
import { getSnakeCaseVarName } from "utils/helpers.legal";
import {
  ICanvasObject,
  ICanvasData,
  isDisclosure,
  ITextObject,
  OfferData,
} from "shared/types/assetBuilder";
import {
  storeVariableNames,
  numberAtThisPriceVariableNames,
  dateFormat,
} from "shared/constants/dataManagement";
import * as offerHelpers from "../../utils/helpers.offer";
import * as helpers from "../../utils/helpers";

import FontFaceObserver from "fontfaceobserver";
import { OfferType } from "shared/types/shared";
import {
  commaFormattedFields,
  fieldsToNotPopulateUsingOfferData,
  roundableProps,
} from "shared/constants/assetBuilder";
import moment from "moment";
import { ITextOptions } from "fabric/fabric-impl";
import { initiateTextbox } from "utils/helpers.fabric";
import { loadFontFromCAM, CAM_ENABLED } from "shared/components/media";
import { UseBrandsFonts } from "screens/designStudio/editor/propertySectionV2/propertyRow/manageText/fontProperty.hooks/useBrandsFonts";

export type StylePropKeys =
  | "deltaY"
  | "fill"
  | "fontFamily"
  | "fontSize"
  | "fontStyle"
  | "fontWeight"
  | "linethrough"
  | "overline"
  | "stroke"
  | "strokeWidth"
  | "textBackgroundColor"
  | "underline";

export const styleProps: StylePropKeys[] = [
  "deltaY",
  "fill",
  "fontFamily",
  "fontSize",
  "fontStyle",
  "fontWeight",
  "linethrough",
  "overline",
  "stroke",
  "strokeWidth",
  "textBackgroundColor",
  "underline",
];

type CharStyleObject = Partial<Record<StylePropKeys, string | number>>;
type LineStyle = Record<number, CharStyleObject>;
type FabricStyles = Record<number, LineStyle>;

export interface IStyleTracker {
  char: string;
  style: CharStyleObject;
  indexInLine: number;
  lineNumber: number;
}

export const sentenceRegex = /[^.!?]+[.!?]+\s/g;
export const generalVariableRegex = /((\()?(\{(.*?)\}|\{(.*?)\})(\))?)/g;
const endOfSentenceRegex = /\!|\?|\.\s/g;
const emptySentenceRegex = /\s\s+(\.|\!|\?)/g;
const websiteRegex = /^(http)(s)?:\/\/(www.)?/;
export const rebateDisclosureRegex = /^[a-zA-Z]+Rebate\d{1}Disclosure$/gi;
export const rebateFieldRegex = /Rebate\d{1}(Name|Disclosure)?/gi;
export const returnSpecialAsciiPattern = (char: string) =>
  `^${char.charCodeAt(0).toString()}^`;
const remainingDiscVarRegex =
  /\{(Lease|Zero Down Lease|Finance|APR|Purchase|Extra Cost|Number at this Price|Expiration Date)\}/g;

export const returnNewStyleAfterMasking = (
  oldTextbox: fabric.Textbox,
  newText: string,
  searchPattern: string,
  replacePattern: string,
) => {
  const { styles, text: oldText } = oldTextbox;

  if (!oldText || !newText) {
    return styles;
  }

  const styleTrackersMatrix = returnStyleTrackerMatrix(oldTextbox);
  const styleTrackers = styleTrackersMatrix.flat();

  const foundIndex = styleTrackers.findIndex(
    (tracker, index) =>
      index === oldText.toLowerCase().indexOf(searchPattern.toLowerCase()),
  );

  if (foundIndex === -1) {
    return styles;
  }

  const { lineNumber, indexInLine } = styleTrackers[foundIndex];

  const trackersToSplice = replacePattern.split("").map(char => ({
    ...styleTrackers[foundIndex],
    char,
  }));

  const newStyleTrackersMatrix = [...styleTrackersMatrix];
  newStyleTrackersMatrix[lineNumber].splice(
    indexInLine - lineNumber,
    searchPattern.length,
    ...trackersToSplice,
  );

  const newStyles = returnStylesObjectFromTrackerMatrix(newStyleTrackersMatrix);

  return newStyles;
};

const returnAreAllStylesSame = (selectionStyles: TSelectionStyle[]) => {
  let i = 0;
  let allStylesSame = true;

  while (i < selectionStyles.length - 1 && allStylesSame) {
    const current = selectionStyles[i];
    const next = selectionStyles[i + 1];
    const currentKeys = Object.keys(current);
    const nextKeys = Object.keys(next);
    if (currentKeys.length !== nextKeys.length) {
      i = selectionStyles.length - 1;
      allStylesSame = false;
      break;
    }
    for (const propertyName in current) {
      if (
        current[propertyName as TTextSelectionProperty] !==
        next[propertyName as TTextSelectionProperty]
      ) {
        allStylesSame = false;
        break;
      }
    }
    i += 1;
  }

  return allStylesSame;
};

const returnSelectionObjWithAlteredProps = (
  selectionStyles: TSelectionStyle[],
) => {
  const propsWithMultipleVals: TTextSelectionProperty[] = [];
  let propsWithSameVals = Object.keys(
    selectionStyles[0] || {},
  ) as TTextSelectionProperty[];
  let i = 0;
  while (i < selectionStyles.length - 1) {
    const current = selectionStyles[i];
    const next = selectionStyles[i + 1];
    for (const propertyName in current) {
      if (
        current[propertyName as TTextSelectionProperty] !==
        next[propertyName as TTextSelectionProperty]
      ) {
        const typedProp = propertyName as TTextSelectionProperty;
        propsWithMultipleVals.push(typedProp);
        propsWithSameVals = propsWithSameVals.filter(
          prop => prop !== typedProp,
        );
        break;
      }
    }
    i += 1;
  }

  const propsWithMultipleValsSetArray = Array.from(
    new Set(propsWithMultipleVals),
  );
  const propsWithSameValsSetArray = Array.from(new Set(propsWithSameVals));
  return {
    propsWithMultipleVals: propsWithMultipleValsSetArray,
    propsWithSameVals: propsWithSameValsSetArray,
  };
};

const returnTextSelectionProps = (selectionStyles: TSelectionStyle[]) => {
  const areAllStylesSame = returnAreAllStylesSame(selectionStyles);
  if (areAllStylesSame) {
    return selectionStyles[0] || {};
  }

  const propObj: Record<TTextSelectionProperty, string> = {
    fontWeight: "",
    fontFamily: "",
    fontSize: "",
    deltaY: "",
    fill: "",
  };

  const { propsWithMultipleVals, propsWithSameVals } =
    returnSelectionObjWithAlteredProps(selectionStyles);

  propsWithMultipleVals.forEach((prop: TTextSelectionProperty) => {
    propObj[prop] = "Multiple";
  });

  propsWithSameVals.forEach((prop: TTextSelectionProperty) => {
    propObj[prop] = selectionStyles[0] ? selectionStyles[0][prop] : "";
  });

  return propObj;
};

export const returnActiveLayerTextSelectionObj = (
  activeLayerObject: ILayerObject,
  selectionStyles: TSelectionStyle[],
) => {
  const newActiveLayerObj = { ...activeLayerObject };
  const textSelectionProps = returnTextSelectionProps(selectionStyles);
  const propKeys = Object.keys(textSelectionProps) as TTextSelectionProperty[];

  propKeys.forEach(key => {
    if (textSelectionProps[key]) {
      (newActiveLayerObj[key] as string) = textSelectionProps[key];
    }
  });

  return newActiveLayerObj;
};

export const returnFlattenedStyles = (styles: any) => {
  const arrayOfObjects = Object.values(styles);
  const arrayOfArrays = arrayOfObjects.map((obj: any) => Object.values(obj));
  return arrayOfArrays.flat();
};

export const loadFontsFromJson = async (
  loadedJson: ICanvasData,
  brandFonts?: UseBrandsFonts,
) => {
  const fontList = getUsedFontsFromLoadedJson(loadedJson);

  if (brandFonts?.shouldUseBrandFonts) {
    const fontListToLoad = fontList.filter(
      font => !brandFonts.fontFamilies.includes(font),
    );

    const loadedFonts = await loadFontsWithFontFace(fontListToLoad);
    const fontListToLoadFromBrand = fontList.filter(font =>
      brandFonts.fontFamilies.includes(font),
    );

    await Promise.all(
      fontListToLoadFromBrand.map(font => brandFonts.loadFont(font)),
    );

    return loadedFonts;
  }

  return loadFontsWithFontFace(fontList);
};

const loadFontsWithFontFace = async (fontList: string[]) => {
  const loadedFonts = [];

  for await (const fontName of fontList) {
    const fontObserver = new FontFaceObserver(fontName);
    try {
      await fontObserver.load();
      loadedFonts.push(fontObserver);
    } catch (error) {
      if (CAM_ENABLED) {
        const loadedExternalFont = await loadFontFromCAM(fontName);
        if (loadedExternalFont) {
          loadedFonts.push(loadedExternalFont);
        }
      }
    }
  }
  return loadedFonts;
};

export const getUsedFontsFromLoadedJson = (loadedJson: ICanvasData) => {
  const { objects } = loadedJson as unknown as { objects: fabric.Object[] };

  const textboxes = objects.filter(
    obj => obj.type === "textbox",
  ) as fabric.Textbox[];

  if (textboxes.length < 0) {
    return [];
  }

  const fontsWithRepeatsMatrix = textboxes.map(obj => {
    const { fontFamily = "Times New Roman" } = obj;
    const charFonts = returnCharFonts(obj);
    return [fontFamily, ...charFonts];
  });
  const flatFontsWithRepeats = _.flatten(fontsWithRepeatsMatrix);
  const textFontSetArray = Array.from(new Set(flatFontsWithRepeats));

  return textFontSetArray;
};

const returnCharFonts = (textbox: fabric.Textbox) => {
  const { styles } = textbox;
  const flattenedStyles = returnFlattenedStyles(styles) as any[];

  const repeatingFonts = flattenedStyles.map(
    style => style.fontFamily || "Times New Roman",
  ) as string[];

  const charFontSetArray = Array.from(new Set(repeatingFonts));

  return charFontSetArray;
};

export const dynamicallyResizeDisclosureText = (
  obj: fabric.Object,
  template: ITemplate,
  canvasHeight: number,
  previewHeight?: number,
) => {
  const object = obj as fabric.Textbox & {
    customType: ExtendedObjectType;
  };
  const { customType, textLines, top = 0 } = object;

  if (customType !== "disclosure" || !textLines) {
    return;
  }

  const { artboard } = template || { artboard: null };
  const { height = 0 } = artboard || {};
  const heightLimit = previewHeight || canvasHeight;

  object.set({
    height: (height || canvasHeight) - top,
    fontSize: object.fontSize || 12,
    text: object.get("text"),
  });

  let textHeight = object.calcTextHeight();

  while (textHeight + top > heightLimit) {
    object.set({
      fontSize: (object.fontSize || 12) - 1,
    });
    textHeight = object.calcTextHeight();
  }
};

export const removeSentencesWithUnfilledVars = (
  disclosureText: string,
  filledText: string,
  disclosureIdentifiers: string[],
) => {
  const filledTextMatches = filledText.split(endOfSentenceRegex);
  const disclosureTextMatches = disclosureText.split(endOfSentenceRegex);

  const disclosureIdentifierRegex = returnDisclosureIdentifierRegex(
    disclosureIdentifiers,
  );

  let finalText = "";

  for (let i = 0; i < disclosureTextMatches.length; i++) {
    const isNotLast = i < disclosureTextMatches.length - 1;
    const currentUnfilled = disclosureTextMatches[i];
    const currentFilled = filledTextMatches[i];
    const processedText = currentFilled;

    const variables = returnVariablesInSentence(currentUnfilled);
    const remainingVariables = returnVariablesInSentence(currentFilled);

    const isExpirationDateText =
      variables.length === 1 && variables.includes("Expiration Date");
    const hasNoVars = variables.length < 1 && remainingVariables.length < 1;
    const badTextFill =
      remainingVariables.length === 0 && emptySentenceRegex.test(currentFilled);

    if (isExpirationDateText || hasNoVars) {
      finalText = finalText.concat(
        `${currentFilled.trim()}${
          isExpirationDateText || !isNotLast ? "" : ". "
        }`,
      );
      continue;
    }

    if (variables.length === remainingVariables.length || badTextFill) {
      const identifierMatchesInSentence = currentUnfilled.match(
        disclosureIdentifierRegex,
      );
      /*
        This preserves patterns like "PURCHASE:" if they were cut off
        while removing sentences with all unfilled variables.
        These patterns need to be preserved
      */
      if (identifierMatchesInSentence) {
        finalText = `${finalText} ${identifierMatchesInSentence[0]} `;
      }
      continue;
    }

    finalText = finalText.replace(/\s\s+/g, " ");

    // AV2-1526: this helps replace any variables that got left behind
    finalText = finalText.replace(generalVariableRegex, "");
    finalText = finalText.replace(/\s\.\s/g, " ");
    finalText = finalText.replace(/\:\./g, ":"); // AV2-1526: this helps remove an empty sentenc preceded by <DISCLOSURE_IDENTIFIER>:

    const textToConcat = `${processedText.trim()}.${isNotLast ? " " : ""}`;

    /*
      AV2-1470: Right now, only "." is supported
      inside RebateDisclosure field values. This
      replaces the special pattern with the proper char
    */
    finalText = finalText.concat(textToConcat.replace(/\^(\d+)\^/g, "."));
  }

  // AV2-1853: if Disclosure vars like {Lease} are left over, remove them
  if (remainingDiscVarRegex.test(finalText)) {
    let artefactPeriodRegexPattern = "";
    const remainingDiscVarMatches =
      finalText.match(remainingDiscVarRegex) || [];

    for (const match of remainingDiscVarMatches) {
      finalText = finalText.replace(match, "").replace(/\s\s/, " ");
      artefactPeriodRegexPattern = `${artefactPeriodRegexPattern}\\.`;
    }

    if (remainingDiscVarMatches.length > 0) {
      const periodRegex = new RegExp(artefactPeriodRegexPattern);
      finalText = finalText.replace(periodRegex, ".");
    }
  }

  /*
    AV2-2313 & AV2-2457: if the {cylinders} var returnes 0 or null, \
    the pattern "-cyl" or "0-cyl" should be removed as as well
  */
  const cylindersRegex =
    /((\,)?(\s)?(\d*|null|undefined|{cylinders})?\-(cyl|CYL|Cyl))/g;
  const cylinderMatches = finalText.match(cylindersRegex);
  const cylNumber = cylinderMatches?.[0]?.split("-")?.[0] || "0";
  const parsedCylInt = parseInt(cylNumber);

  if (isNaN(parsedCylInt) || !parsedCylInt) {
    finalText = finalText.replace(cylindersRegex, "");
    finalText = finalText.replace(/\.\./, ".");
  }

  return finalText;
};

export const returnSentenceVarMatrix = (text: string) => {
  const sentences = text.match(sentenceRegex);
  if (!sentences) {
    return [];
  }
  const matchesInSentence = sentences.map(sentence => {
    let matches: string[] = [];
    const variableNameMatches = sentence.match(generalVariableRegex);
    if (variableNameMatches) {
      matches = matches.concat(variableNameMatches);
    }
    return matches;
  });

  return matchesInSentence;
};

export const returnVariablesInSentence = (sentence: string) => {
  const variableNameMatches = sentence.match(generalVariableRegex);
  if (!variableNameMatches) {
    return [];
  }
  const variables = variableNameMatches.map(variableNameMatch =>
    variableNameMatch.replace(/\{|\}/g, ""),
  );
  return variables;
};

export const replaceDealerTokens = (
  text: string,
  dealer: IAccount,
  variableAlias?: IVariableAlias,
) =>
  text.replace(/\{(\w+)\}/g, (substring: string, match: string) => {
    const key = match[0].toLowerCase() + match.slice(1);
    const snakeCaseKey = getSnakeCaseVarName({
      name: key,
      requiredByStates: [],
    });

    if (snakeCaseKey === "store_url") {
      return dealer["dealer_url"].replace(websiteRegex, "");
    }

    if (
      snakeCaseKey in dealer &&
      snakeCaseKey !== "new_dealer_name" &&
      snakeCaseKey !== "final_price_name"
    ) {
      const value = (dealer[snakeCaseKey as keyof IAccount] || "").toString();

      return variableAlias?.[`{${match}}`]?.isCapsOn
        ? value.toUpperCase()
        : value;
    }

    return substring;
  });

export const replaceDisclosureTypeVariable = (
  disclosureType: TDisclosureType,
  inputText: string,
  replacementText: string,
) => {
  const replacePattern = `{${disclosureType}}`;
  const regex = new RegExp(replacePattern, "g");
  const newText = inputText.replace(regex, replacementText);

  return newText;
};

export const dealerVariablesRegex = () => {
  let regexString = `{${storeVariableNames[0]}}`;
  for (let i = 1; i < storeVariableNames.length; i++) {
    regexString = `${regexString}|{${storeVariableNames[i]}}`;
  }
  const regex = new RegExp(regexString, "gi");

  return regex;
};

export const usableDisclosureIdentifiers = [
  OfferType["Purchase(%)"].replace("(%)", ""),
  OfferType.Lease,
  OfferType.ZeroDownLease,
  OfferType.Finance,
].map(offerType => offerType.toUpperCase());

export const returnDisclosureIdentifierRegex = (
  disclosureIdentifiers: string[],
) => {
  const separatePatterns = [];

  for (const identifier of disclosureIdentifiers) {
    if (!usableDisclosureIdentifiers.includes(identifier)) {
      continue;
    }
    let replacedSpaces = identifier.replace(" ", `\\s`);
    if (identifier === OfferType.ZeroDownLease.toUpperCase()) {
      replacedSpaces = `ZERO(\\-|\\s)DOWN\\sLEASE`;
    }
    separatePatterns.push(replacedSpaces);
  }

  const identifierRegex = new RegExp(`(${separatePatterns.join("|")}):`, "g");
  return identifierRegex;
};

export const finalPricePriceNameRegex = /\{finalPriceName\}/gi;

export const numberAtThisPriceVarRegex = () => {
  const variables = numberAtThisPriceVariableNames.map(
    varName => `\\{${varName}\\}`,
  );
  const joinedString = variables.join("|");
  const regex = new RegExp(joinedString);
  return regex;
};

export const replaceFinalPriceVariable = (
  inputText: string,
  dealer: IAccount,
) => {
  /**
   * AV2-1693
   * When displaying final price, we first find if final_price_name is defined from the dealer instance.
   * If it is defined, then we should use it. Otherwise, we should display "Final Price".
   */
  const { final_price_name: finalPriceNameFromDealer } = dealer;

  const emptyFromDealer =
    !finalPriceNameFromDealer || finalPriceNameFromDealer.trim() === "";

  if (emptyFromDealer) {
    return inputText.replace(finalPricePriceNameRegex, "Final Price");
  }

  return inputText.replace(finalPricePriceNameRegex, finalPriceNameFromDealer);
};

export const isNewLineChar = (char: string) =>
  char === "↵" || char === "\n" || char === "\r";

const returnAllMatches = (text: string, regex: any) => {
  let match;
  const matches = [];
  do {
    match = regex.exec(text);
    if (match) {
      matches.push({
        fullPattern: match[0],
        variable: match[1] ? match[1][0].toLowerCase() + match[1].slice(1) : "",
        startIndex: match.index,
        endIndex: match[1] ? match.index + match[1].length + 1 : match.index,
      });
    }
  } while (match);

  return matches;
};

export const returnOpenCurlyIndex = (text: string) => {
  const variablesRegex = /\{(\w+)\}/g;
  const openCurlyRegex = /\{/g;

  const variableMatches = returnAllMatches(text, variablesRegex);
  const openCurlyMatches = returnAllMatches(text, openCurlyRegex);

  if (variableMatches.length !== openCurlyMatches.length) {
    const openCurlyMatch = openCurlyMatches.find(
      curlyMatch =>
        !variableMatches.find(
          varMatch => varMatch.startIndex === curlyMatch.startIndex,
        ),
    );
    const indexToUse = openCurlyMatch ? openCurlyMatch.startIndex : null;
    return indexToUse;
  }

  return null;
};

export const returnOfferDataValueForVarPopulation = (args: {
  dealer?: IAccount;
  variable: keyof OfferData;
  offerData: OfferData;
  variableAlias?: IVariableAlias;
  willRoundValue?: boolean;
  overridePunctuationReplace?: boolean;
}) => {
  const {
    variable,
    offerData,
    variableAlias,
    willRoundValue,
    overridePunctuationReplace,
  } = args;

  const lowerCaseVar = variable.toLowerCase();
  const lcFieldsToNotPopulateUsingOfferData =
    fieldsToNotPopulateUsingOfferData.map(field => field.toLowerCase());

  if (variable === "aprAmntFinanced" || lowerCaseVar === "apramntfinanced") {
    return "$1000";
  }

  const variableAliasProp = Object.keys(variableAlias || {}).find(aliasVar =>
    isVarInBracket(lowerCaseVar)
      ? `${aliasVar.toLowerCase()}` === lowerCaseVar
      : aliasVar.toLowerCase() === `{${lowerCaseVar}}`,
  );

  const alias = variableAlias?.[variableAliasProp || ""];

  if (lcFieldsToNotPopulateUsingOfferData.includes(lowerCaseVar)) {
    return `{${variable}}`;
  }

  const matchingProp = Object.keys(offerData).find(
    prop => prop.toLowerCase() === lowerCaseVar,
  ) as keyof OfferData | undefined;

  /*
  // AV2-3653: Lithia/LADTech requested that this be disabled, but will be used later
  // AV-3472: dealerDiscount should === 0 if dealer.state === TX and accessoryPrice = 0
  if (
    variable === "dealerDiscount" &&
    !parseInt(offerData[matchingProp || variable] as string) &&
    dealer?.state === "TX"
  ) {
    return "0";
  }
  */
  // There was problem when "matchingProp" and "variable" are defined but the value of the "matchingProp" is empty.
  // For ex, "matchingProp" === 'APRterm' and "variable" === 'aprTerm' but offerData["matchingProps"] === ""

  const value =
    matchingProp && offerData[matchingProp]
      ? offerData[matchingProp].toString()
      : offerData[variable]?.toString();

  if (!value) {
    return `{${variable}}`;
  }

  const lcCommaFormattedFields = commaFormattedFields.map(field =>
    field.toLowerCase(),
  );

  if (lcCommaFormattedFields.includes(lowerCaseVar)) {
    return offerHelpers.applyCommaFormat(value, willRoundValue);
  }

  const lcRoundableProps = roundableProps.map(prop => prop.toLowerCase());

  if (lcRoundableProps.includes(lowerCaseVar)) {
    const valueToUse = value.replace(",", "");
    const numberValue = Number.parseFloat(valueToUse);
    const roundedValue = Math.round(numberValue);
    const moneyValue = helpers.returnMoneyString(roundedValue);
    return moneyValue.toString();
  }

  if (variable.toLowerCase().endsWith("rebatename")) {
    return value.replace(/\.|\!\|\?/g, "");
  }

  if (
    !overridePunctuationReplace &&
    (rebateDisclosureRegex.test(variable) || sentenceRegex.test(value))
  ) {
    /*
      AV2-1683: fields can have punctuation,
      which conflicts with the textHelers.removeSentencesWithUnfilledVars()
      so, the punctuation needs to be replaced temporarily (I used the pattern `^${ASCII_CODE}^`)
      Right now, only "." is supported
    */

    const replacedPunctuation = value.replace(
      /\./g,
      returnSpecialAsciiPattern("."),
    );

    return replacedPunctuation;
  }

  const lcDataVariableNames = offerHelpers.dateVariableNames.map(varName =>
    varName.toLowerCase(),
  );

  // AV2-1387, issue was rendering epoch without formatting it on the canvas.
  const shouldFormatDate =
    lcDataVariableNames.includes(lowerCaseVar) &&
    !Number.isNaN(parseInt(value)); // AV2-1739: if any date value is in epoch time, then format it

  if (shouldFormatDate) {
    const valueClean = value.replace(/,/g, "");
    const valueFinal = moment(valueClean).isValid() ? valueClean : +valueClean; // if valid date, use as is, else convert to epoch
    const formattedDateString = moment(valueFinal).format(dateFormat); // convert date to expected format

    return formattedDateString;
  }

  const formattedValue =
    (variable.toLowerCase().includes("rate") ||
      lowerCaseVar === "percentoffmsrp") &&
    value.includes("%")
      ? value.replace("%", "")
      : alias?.isCapsOn
      ? value.toUpperCase()
      : value;

  // AV2-3482 - Round percentOffMSRP if in stamp
  if (willRoundValue && lowerCaseVar === "percentoffmsrp") {
    const floatValue = parseFloat(formattedValue);
    return isNaN(floatValue)
      ? formattedValue
      : Math.floor(floatValue).toFixed(0);
  }

  return formattedValue;
};

export const isVarInBracket = (variable: string) => {
  return variable?.[0] === "{" && variable[variable?.length - 1] === "}";
};

export const returnDealerDataValueForVarPopulation = (
  variable: string,
  dealer: IAccount,
  variableAlias?: IVariableAlias,
) => {
  const snakeCaseKey = getSnakeCaseVarName({
    name: variable,
    requiredByStates: [],
  });

  if (snakeCaseKey === "store_url") {
    return dealer["dealer_url"].replace(websiteRegex, "");
  }

  if (
    !(snakeCaseKey in dealer) ||
    snakeCaseKey === "new_dealer_name" ||
    snakeCaseKey === "final_price_name" // final_price_name comes from the offer itself
  ) {
    return `{${variable}}`;
  }

  const value = (dealer[snakeCaseKey as keyof IAccount] || "").toString();

  if (!value) {
    return `{${variable}}`;
  }

  return variableAlias?.[`{${variable}}`]?.isCapsOn
    ? value.toUpperCase()
    : value;
};

export const returnFinalPriceValueForVarPopulation = (
  dealer: IAccount,
  variableAlias?: IVariableAlias,
) => {
  const { final_price_name: finalPriceNameFromDealer } = dealer;

  const emptyFromDealer =
    !finalPriceNameFromDealer || finalPriceNameFromDealer.trim() === "";

  if (emptyFromDealer) {
    return "Final Price";
  }

  const variableAliasProp = Object.keys(variableAlias || {}).find(
    aliasVar => aliasVar.toLowerCase() === `{finalpricename}`,
  );

  return variableAlias?.[variableAliasProp || `{finalPriceName}`]?.isCapsOn
    ? finalPriceNameFromDealer.toUpperCase()
    : finalPriceNameFromDealer;
};

export const returnDefaultStyle = (textbox: fabric.Textbox) => {
  const defaultStyle = {} as CharStyleObject;
  for (const prop of styleProps) {
    if (!textbox[prop]) {
      continue;
    }
    defaultStyle[prop] = textbox[prop] as string;
  }
  return defaultStyle;
};

export const returnTextLinesFromFlatText = (text: string) => {
  const textLines: string[] = [];
  let tempCharArray: string[] = [];

  if (!text) return [];

  for (const char of text?.split("") || []) {
    if (isNewLineChar(char)) {
      textLines.push(tempCharArray.join(""));
      tempCharArray = [];
      continue;
    }
    tempCharArray.push(char);
  }

  textLines.push(tempCharArray.join(""));

  return textLines;
};

export const returnStyleTrackerMatrix = (
  textbox: fabric.Textbox,
  isNew?: boolean,
) => {
  const { styles, text = "" } = textbox;
  const defaultStyle = returnDefaultStyle(textbox);

  // Hanle when the styles are empty (usually with a new textbox)
  if (!styles || Object.keys(styles).length < 1) {
    const defaultMatrix: IStyleTracker[][] = [];
    let tempArray: IStyleTracker[] = [];
    let lineNumber = 0;
    let indexInLine = 0;
    for (let i = 0; i < text.length; i++) {
      const char = text[i];
      if (isNewLineChar(char)) {
        defaultMatrix.push(tempArray);
        tempArray = [];
        lineNumber += 1;
        indexInLine = 0;
        continue;
      }
      tempArray.push({
        char,
        style: defaultStyle,
        lineNumber,
        indexInLine: indexInLine,
      });
      indexInLine += 1;
    }

    if (!defaultMatrix[lineNumber]) {
      defaultMatrix[lineNumber] = tempArray;
    } else {
      defaultMatrix[lineNumber] = defaultMatrix[lineNumber].concat(tempArray);
    }

    return defaultMatrix;
  }

  const typedStyles = styles as FabricStyles;
  try {
    const textLines = returnTextLinesFromFlatText(text || "");

    if (textLines.length < 1) return [];

    /*
    // styles: {[lineNumber]:{[charIndex]:{style}}}
    // example:
    styles: {0: {
                    0:{fill:"#ffffff"},
                    1:{fill:"#ffffff"}
                },
            1: {
                    0:{fill:"#ffffff"},
                    1:{fill:"#ffffff"}
                },
            }
  */

    const lineStyleTrackers: Array<IStyleTracker[]> = [];
    const textLinesToUse = isNew ? textbox.textLines : textLines;

    const styleLineCount = Object.keys(typedStyles).length;
    const textLineCount = textLinesToUse.length;

    let newTypedStyles = Object.assign({}, { ...typedStyles }) as FabricStyles;

    if (styleLineCount < textLineCount) {
      for (let i = 0; i < textLineCount; i++) {
        if (typedStyles[i]) {
          continue;
        }

        typedStyles[i] = {};

        for (let j = 0; j < textLinesToUse[i].length; j++) {
          typedStyles[i][j] = defaultStyle;
        }
      }
    } else if (isNew && styleLineCount > textLineCount) {
      /*
        AV2-2118: during variable insertion, there can be line mismatching,
        especially when adding a long variable, this logic shifts the style
        tracker to the new text changes
      */
      const extraIndices: number[] = [];

      for (const lineNumber in typedStyles) {
        const lineNumberInt = parseInt(lineNumber);
        if (lineNumberInt < styleLineCount) {
          continue;
        }
        extraIndices.push(lineNumberInt);
      }

      for (const index of extraIndices) {
        const prevStyleLineArr = Object.values(typedStyles[index - 1]);
        const currStyleLineArr = Object.values(typedStyles[index]);
        const newStyleLineArr = prevStyleLineArr.concat(currStyleLineArr);
        const newStyleLine = Object.assign({}, newStyleLineArr);

        newTypedStyles[index - 1] = newStyleLine;
        let stylesArray = Object.values(newTypedStyles);
        stylesArray = stylesArray
          .slice(0, index)
          .concat(stylesArray.slice(index + 1));

        newTypedStyles = Object.assign({}, stylesArray) as FabricStyles;
      }
    }

    const typedStylesToUse = isNew ? newTypedStyles : typedStyles;

    for (const lineNumber in typedStylesToUse) {
      const lineIndex = parseInt(lineNumber);
      const lineStyle = styles[lineNumber];
      const textLine = textLinesToUse[lineIndex];
      const newLineTrackers: IStyleTracker[] = [];
      for (let i = 0; i < textLine.length; i++) {
        const currentChar = textLine[i];
        const currentStyle = lineStyle[i] || {};
        if (Object.keys(currentStyle).length < 1) {
          newLineTrackers.push({
            char: currentChar,
            style: defaultStyle,
            lineNumber: lineIndex,
            indexInLine: i,
          });
          continue;
        }
        newLineTrackers.push({
          char: currentChar,
          style: { ...defaultStyle, ...currentStyle },
          lineNumber: lineIndex,
          indexInLine: i,
        });
      }
      lineStyleTrackers.push(newLineTrackers);
    }
    return lineStyleTrackers;
  } catch (error) {
    return [];
  }
};

export const returnStylesObjectFromTrackerMatrix = (
  styleTrackers: IStyleTracker[][],
) => {
  const styles: FabricStyles = {};

  for (const lineNumber in styleTrackers) {
    styles[lineNumber] = {};
    const currentTrackerLine = styleTrackers[lineNumber];
    for (const charIndex in currentTrackerLine) {
      const charStyle = currentTrackerLine[charIndex];
      styles[lineNumber][charIndex] = charStyle.style;
    }
  }

  return styles;
};

// may give type to styles
interface ITextAndStyleAdjustmentInput {
  text: string;
  styles: any;
  textboxProps: ITextOptions;
  searchPattern?: string;
  replacePattern?: string;
  isCaseInsenstive?: boolean;
}

export const getCleanedStyles = (styles: any) => {
  return Object.keys(styles)
    .map((lineIdx: string) => {
      const lineStyles = styles[lineIdx];
      return Object.keys(lineStyles)
        .map((charIdx: string) => {
          const style = lineStyles[charIdx];
          if (!style.hasOwnProperty("fontFamily")) return;
          return {
            ...style,
          };
        })
        .filter(Boolean);
    })
    .filter(Boolean);
};

export const replaceStylesWithVariables = (
  originalTextArr: string[],
  styles: any,
  flatStyles: any[],
  newTextLines: string[],
  variablesAndValues: Record<string, string>,
) => {
  if (
    !styles ||
    flatStyles.length !== originalTextArr.length ||
    !newTextLines.length
  )
    return styles;

  const variables = Object.keys(variablesAndValues);
  const updatedFlatStyles = [...flatStyles];

  const orgText = originalTextArr.join("");
  let replacedText = orgText;

  variables.forEach(variable => {
    if (replacedText.includes(variable)) {
      const value = variablesAndValues[variable];

      if (variable.length > value.length) {
        const startIdx = replacedText.indexOf(variable) + 1;
        const needToRemoveLength = variable.length - value.length;
        updatedFlatStyles.splice(startIdx, needToRemoveLength);
      }

      if (variable.length < value.length) {
        const startIdx = replacedText.indexOf(variable);
        const needToAddLength = value.length - variable.length;
        const fontIdx = orgText.indexOf(variable);
        const style = flatStyles[fontIdx];
        const newStyles = Array(needToAddLength).fill(style);
        updatedFlatStyles.splice(startIdx, 0, ...newStyles);
      }

      replacedText = replacedText.replace(variable, value);
    }
  });

  const replacedTextArr = replacedText.split("");
  let replacedTextIdx = 0;
  let updatedStyles = {};

  newTextLines.forEach((textLine, lineIdx) => {
    let textLineStyle = {};
    textLine.split("").forEach((char, charIdx) => {
      if (replacedTextArr[replacedTextIdx] !== char) replacedTextIdx++;
      if (replacedTextArr[replacedTextIdx] === char) {
        textLineStyle = {
          ...textLineStyle,
          [charIdx]: updatedFlatStyles[replacedTextIdx],
        };
      }
      replacedTextIdx++;
    });
    updatedStyles = { ...updatedStyles, [lineIdx]: textLineStyle };
  });

  return updatedStyles;
};

export const returnTextAndStylesForVariablePopulation = ({
  text,
  styles,
  textboxProps,
  searchPattern = "",
  replacePattern = "",
}: ITextAndStyleAdjustmentInput) => {
  const dummyTextbox = initiateTextbox({
    text,
    properties: { ...textboxProps },
  });
  dummyTextbox.set("styles", styles);
  // replace on var pattern at a time, even when there are duplicates
  const splitText = text.split("");
  const foundIndex = text.toLowerCase().indexOf(searchPattern.toLowerCase()); // will never be -1 because of matching

  // It was found in gtb that foundIndex could be -1, so if there is no match, then skip
  if (foundIndex === -1) {
    return { text, styles };
  }

  const newText = `${splitText
    .slice(0, foundIndex)
    .join("")}${replacePattern}${splitText
    .slice(foundIndex + searchPattern.length)
    .join("")}`;

  const newStyles = returnNewStyleAfterMasking(
    dummyTextbox,
    newText,
    searchPattern,
    replacePattern,
  );

  dummyTextbox.set("text", newText);

  return {
    text: newText,
    styles: newStyles,
    textLines: dummyTextbox.textLines,
  };
};

export const returnTextAndStylesAfterRemovingUnfilledVars = ({
  text,
  styles,
  textboxProps,
}: ITextAndStyleAdjustmentInput) => {
  const unfilledVarMatches = text.match(generalVariableRegex) || [];
  let finalText = text;
  let finalStyles = styles;
  for (const variable of unfilledVarMatches) {
    const { text: newText, styles: newStyles } =
      returnTextAndStylesForVariablePopulation({
        text: finalText,
        styles: finalStyles,
        textboxProps,
        searchPattern: variable,
        replacePattern: "",
      });

    finalText = newText;
    finalStyles = newStyles;
  }

  const dummyTextbox = initiateTextbox({
    text: finalText,
    properties: textboxProps,
  });
  dummyTextbox.set("styles", finalStyles);

  const styleTrackerMatrix = returnStyleTrackerMatrix(dummyTextbox);

  if (styleTrackerMatrix.length === 0)
    return { text: finalText, styles: finalStyles };

  // These patterns appear as a result of replacing variables with ""
  // "   hello   world   " => "hello world"
  // styleTrackerMatrix doesn't have newlines, so we need to compare without newlines.
  const { newStyleTrackerMatrix, trimmedText } =
    returnTextAndNewStylesAfterTrimming(styleTrackerMatrix, finalText);

  finalStyles = returnStylesObjectFromTrackerMatrix(newStyleTrackerMatrix);

  return { text: trimmedText, styles: finalStyles };
};

export const returnTextAndNewStylesAfterTrimming = (
  styleTrackerMatrix: IStyleTracker[][],
  text: string,
) => {
  const textLines = text.split(/\r\n|\n|\r/);
  const trimmedTextLines: string[] = [];
  const newStyleTrackerMatrix = [...styleTrackerMatrix];

  textLines.forEach((line, lineNumber) => {
    const trimmedText = line.trim().replace(/[ ]{2,}/g, " ");

    let originalIndex = 0;
    let lenOfRemovedChars = 0;

    for (const element of trimmedText) {
      while (line[originalIndex] !== element) {
        // remove the style at this index
        newStyleTrackerMatrix[lineNumber].splice(
          originalIndex - lenOfRemovedChars,
          1,
        );
        lenOfRemovedChars++;
        originalIndex++;
      }

      originalIndex++;
    }

    while (originalIndex < line.length) {
      // remove the style at this index
      newStyleTrackerMatrix[lineNumber].splice(
        originalIndex - lenOfRemovedChars,
        1,
      );
      lenOfRemovedChars++;
      originalIndex++;
    }
    trimmedTextLines.push(trimmedText);
  });

  return {
    newStyleTrackerMatrix: [...newStyleTrackerMatrix],
    trimmedText: trimmedTextLines.join("\n"),
  };
};

export const returnNewStyleAfterVariableInsert = (
  oldTextbox: fabric.Textbox,
  newTextbox: fabric.Textbox,
  variable: string,
) => {
  try {
    const { styles, text: oldText } = oldTextbox;
    const { text: newText } = newTextbox;
    if (!oldText || !newText) {
      return styles;
    }

    let styleTrackersMatrix = returnStyleTrackerMatrix(oldTextbox);
    let styleTrackers = styleTrackersMatrix.flat();

    const openCurlyIndex = returnOpenCurlyIndex(oldText);
    let currentTracker = styleTrackers[openCurlyIndex];
    const varPattern = `{${variable}}`;

    if (!openCurlyIndex) {
      return styles;
    }

    if (styleTrackers.length < 1) {
      styleTrackersMatrix = returnStyleTrackerMatrix(newTextbox, true);
      styleTrackers = styleTrackersMatrix.flat();
      /*
        AV2-2118: An edge case was found that causes the style lines
        to exceed the line count when inserting a variable,
        resulting in an error thrown, which yields []. This block helps
        handle that.
      */
      const newCurlyIndex =
        openCurlyIndex -
        (currentTracker || styleTrackers[openCurlyIndex]).lineNumber;
      currentTracker = styleTrackers[newCurlyIndex];
    }

    const willAddNewLine =
      !styleTrackers[openCurlyIndex] && openCurlyIndex >= styleTrackers.length;

    let i = 0;

    while (!currentTracker && i < oldText.length) {
      currentTracker = styleTrackers[openCurlyIndex - i];
      i += 1;
    }

    const { lineNumber, indexInLine } = currentTracker;

    const trackersToSplice = varPattern.split("").map(char => ({
      ...currentTracker,
      char,
    }));

    const newStyleTrackersMatrix = [...styleTrackersMatrix];

    if (willAddNewLine) {
      newStyleTrackersMatrix[lineNumber].splice(indexInLine, 1);
      newStyleTrackersMatrix[lineNumber + 1] = trackersToSplice;
    } else {
      newStyleTrackersMatrix[lineNumber].splice(
        indexInLine,
        1,
        ...trackersToSplice,
      );
    }

    const finalStyles = returnStylesObjectFromTrackerMatrix(
      newStyleTrackersMatrix,
    );

    const newTextLines = returnTextLinesFromFlatText(newText);

    // This handles if new lines were added after inserting a variable
    if (newTextLines.length !== newStyleTrackersMatrix.length) {
      const adjustedStyleMatrix = [...newStyleTrackersMatrix];
      const newLineIndices: number[] = [];
      newText.split("").forEach((char, index) => {
        if (!isNewLineChar(char)) {
          return;
        }
        newLineIndices.push(index);
      });

      newLineIndices.forEach((charIndex, lineNumber) => {
        const newTextChar = newText[charIndex];
        const oldTextChar = oldText[charIndex];
        const shouldAdjustStyle =
          isNewLineChar(newTextChar) &&
          (!isNewLineChar(oldTextChar) || !newTextChar);

        if (!shouldAdjustStyle) {
          return;
        }

        const styleTrackersToMove = newStyleTrackersMatrix
          .flat()
          .slice(charIndex);

        const indexOffset = adjustedStyleMatrix[lineNumber - 1]?.length || 0;
        const newLineTrackers = styleTrackersToMove.map((tracker, index) => ({
          ...tracker,
          lineNumber: lineNumber + 1,
          indexInLine: index,
        }));
        adjustedStyleMatrix[lineNumber]?.splice(charIndex - indexOffset - 1);
        adjustedStyleMatrix[lineNumber + 1] = newLineTrackers;
      });

      const adjustedStyles =
        returnStylesObjectFromTrackerMatrix(adjustedStyleMatrix);

      return adjustedStyles;
    }

    return finalStyles;
  } catch (error) {
    return oldTextbox.styles;
  }
};

//Possible TO DO: move logic of populating variables to here for all cases
export const processTextAndStylesWitOfferData = ({
  obj,
  offerData,
  canvasType,
  dealer,
}: {
  obj: ITextObject;
  offerData: OfferData;
  canvasType?: CanvasType;
  dealer?: IAccount;
}) => {
  const textboxProps: Partial<Record<StylePropKeys, any>> = {};
  for (const prop of styleProps) {
    if (!obj[prop as keyof ITextObject]) {
      continue;
    }
    textboxProps[prop] = obj[prop as keyof ITextObject];
  }

  const { styles, text, originalText, variableAlias } = obj as any;

  let templateText: string = text;

  const masksOn =
    Object.keys(variableAlias || {}).filter(
      aliasVar => variableAlias[aliasVar]?.isMaskOn,
    ).length > 0;

  // check if masks are turned on here
  if (masksOn && originalText) {
    templateText = originalText as string;
  }

  let finalText = templateText;
  let finalStyles = styles;

  const matches = templateText
    .match(/\{([a-zA-Z0-9]+)\}/g)
    ?.map(match => match.replace(/\{|\}/g, ""));

  const offerDataVariables = (matches?.filter(
    variable => templateText.includes(`{${variable}}`) && variable in offerData,
  ) ||
    Object.keys(offerData).filter(key => templateText.includes(key))) as Array<
    keyof OfferData
  >;

  for (const variable of offerDataVariables) {
    const searchPattern = `{${variable}}`;
    const replacePattern = returnOfferDataValueForVarPopulation({
      dealer,
      variable,
      offerData,
      variableAlias,
      willRoundValue:
        canvasType === "stamp" ||
        (isDisclosure(obj as unknown as ICanvasObject) &&
          variable !== "percentOffMSRP"), // AV2-3482: percentOffMSRP should only be rounded for stamps
    });

    if (searchPattern === replacePattern) {
      continue;
    }

    const { text: newText, styles: newStyles } =
      returnTextAndStylesForVariablePopulation({
        text: finalText,
        styles: finalStyles,
        textboxProps,
        searchPattern,
        replacePattern,
      });

    finalText = newText;
    finalStyles = newStyles;
  }

  return { text: finalText, styles: finalStyles };
};

/**
 * Creates an array of dense styles for a text line in FabricJS
 *
 * FabricJS Textboxes have sparse styles, that means that the styles are not defined for every character unless necessary
 * To make the styles dense, we convert them to an array with the same length as the original text line
 * and use an empty object for the characters that don't have a specific style
 * the resulting array is then used to update the styles
 *
 * @param text - the text line to get the dense styles for
 * @param styles - the styles object for the text line
 * @returns an array of styles with the same length as the text line
 */
export const getDenseStylesArrayForTextLine = (
  text: string,
  styles: Record<string, Record<string, number | string>>,
) =>
  Array.from({ length: text.length }, (_, i) => (styles?.[i] ? styles[i] : {}));

/**
 * This helper calculates the length difference of a variable when masking/unmasking, based on the current variableAlias and the previous variableAlias
 * as well as its mask status
 * @param variableName name of the variable to replace
 * @param variableAlias current variableAlias
 * @param prevVariableAlias previousVariableAliasand the alias
 * @returns the difference in length between the variable
 */
export const getDiffLength = (
  variableName: string,
  variableAlias: TVariableAlias,
  prevVariableAlias?: TVariableAlias,
) => {
  const isMaskOn = variableAlias[variableName].isMaskOn;
  const prevIsMaskOn = prevVariableAlias?.[variableName]?.isMaskOn;
  const maskChanged = prevIsMaskOn !== isMaskOn;

  const varLength = variableName.length;
  const aliasLength = variableAlias[variableName].customVariable.length;
  const prevAliasLength =
    prevVariableAlias?.[variableName]?.customVariable?.length ?? 0;

  if (isMaskOn && maskChanged) return aliasLength - varLength;
  if (isMaskOn && !maskChanged) return aliasLength - prevAliasLength;
  if (!isMaskOn && maskChanged) return varLength - aliasLength;
  return prevAliasLength - aliasLength;
};
