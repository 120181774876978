import { IDataTableError } from "shared/types/errors";

export type OrderTableButtonTypes =
  | "Edit Order"
  | "Asset Builder"
  | "Archive Order"
  | "Web Asset Launcher"
  | "Duplicate Order"
  | "Restore Order"
  | "";

export interface IWFData {
  ID: string;
  name: string;
  objCode: string;
  percentComplete: number;
  plannedCompletionDate: string;
  plannedStartDate: string;
  priority: number;
  projectedCompletionDate: string;
  status: string;
}

// When updating this type, make sure to review the other versions of INewOrder on other projects
export interface INewOrder {
  id: string;
  dealer_name: string;
  createdAt: number;
  creator_name: string;
  dealer_code: string;
  dealer_oem: string;
  is_archived: boolean;
  num_assets: number;
  pushed_WF: boolean;
  expiresAt: number;
  wfProjectName?: string;
  wfProjectNumber: string;
  wfID: string;
  wfFullProjectName: string;
  parentFileToken: string;
  documentID: string;
  proofUploadData: string;
  coopSubmissionNotice: string;
  selectTemplateCollapseSearchInput: string;
  selectImageCollapseSearchInput: string;
  selectedOrderOffers: string;
  integrationCount: number;
  selectedInventory: number;
  totalSelectedOffers: number;
  totalUsedStamps: number;
  status?: StatusOptions;
  feedIds?: string[]; // selectred feed ids
}

export interface INewOrderRecord {
  key: number;
  id: string;
  dealer_name: string;
  createdAt: number;
  creator_name: string;
  dealer_code: string;
  dealer_oem: string;
  is_archived: boolean;
  num_assets: number;
  pushed_WF: boolean;
  expiresAt: number;
  wfProjectName: string;
  wfProjectNumber: string;
  actions: string;
  wfID: string;
  wfFullProjectName: string;
  parentFileToken: string;
  documentID: string;
  proofUploadData: string;
  coopSubmissionNotice: string;
  selectTemplateCollapseSearchInput: string;
  selectImageCollapseSearchInput: string;
  selectedInventory: number;
  totalSelectedOffers: number;
  selectedOrderOffers: string;
  integrationCount: number;
  totalUsedStamps: number;
  status?: StatusOptions;
  tags?: string[]; // Pharma Industry.
}
interface ICreateNewOrderResult {
  createdOem: INewOrder;
}

interface IGetWorkFrontDataResult {
  workFrontArray: IWFData[];
}

interface IGetWorkFrontStoreNameResult {
  dealerCode233: string;
  wfStoreName: string;
}

export interface ICreateNewOrderResponse {
  result: null | ICreateNewOrderResult;
  error: null | IDataTableError;
  statusCode: number;
}

interface IUpdateNewOrderResult {
  updateddOrder: INewOrder;
}

export interface IUpdateNewOrderResponse {
  result: null | IUpdateNewOrderResult;
  error: null | IDataTableError;
  statusCode: number;
}

export interface IGetWorkFrontDataResponse {
  result: null | IGetWorkFrontDataResult;
  error: null | IDataTableError;
  statusCode: number;
}

export interface IGetWorkFrontStoreNameResponse {
  result: null | IGetWorkFrontStoreNameResult;
  error: null | IDataTableError;
  statusCode: number;
}

interface ISetCurrentOrderResult {
  currentOrder: INewOrder;
}

export interface ISetCurrentOrderResponse {
  result: null | ISetCurrentOrderResult;
  error: null | IDataTableError;
}

export interface IDeleteNewOrderResult {
  deletedOrder: INewOrder;
}

export interface INewOrderTableRecord {
  key: number | string;
  id: string;
  dealer_name: string;
  createdAt: number;
  createdText: string;
  creator_name: string;
  dealer_code: string;
  dealer_oem: string;
  is_archived: boolean;
  num_assets: number;
  pushed_WF: boolean;
  expiresAt: string;
  wfProjectName: string;
  wfProjectNumber: string;
  wfID: string;
  wfFullProjectName: string;
  parentFileToken: string;
  documentID: string;
  proofUploadData: string;
  selectedInventory: number;
  totalSelectedOffers: number;
  selectedOrderOffers: string;
  totalUsedStamps: number;
  integrationCount: number;
  actions: string;
  status?: StatusOptions;
  tags?: string[];
}

export enum StatusOptions {
  COOP_READY_TO_SUBMIT = "Co-op Ready to Submit",
  COOP_SUBMITTED = "Co-op Submitted",
  COOP_APPROVED = "Co-op Approved",
  COOP_NOT_REQUIRED = "Co-op Not Required",
  COOP_DENIED = "Co-op Denied",
  NO_STATUS = "No Status",
}

export enum LADStatusOptions {
  NO_STATUS = "No Status",
  PENDING = "Pending",
  APPROVED = "Approved",
}

export enum PharmaStatusOptions {
  NO_STATUS = "No Status",
  READY_FOR_CREATIVE = "Ready for Creative Review",
  READY_FOR_MED_LEGAL = "Ready for Med/Legal Review",
  SUBMITTED_FOR_MED_LEGAL = "Submitted for Med/Legal Review",
  APPROVED = "Med/Legal Approved",
}

export interface IWFProjectNameRecord {
  key: number;
  name: string;
  ID: string;
}

export interface INewOrderState {
  currentSelectedOrder: INewOrderRecord;
  newOrdersMessage: string;
  newOrderError: string;
  newOrdersPaginationKey: string;
  wfProjectNames: IWFProjectNameRecord[];
  storeName: string;
  error: null | IDataTableError;
  dealerCode233: string;
  wfStoreName: string;
  orderCreated: boolean;
  result: any;
}

export type OrderStatus = "active" | "archived" | "expired";
