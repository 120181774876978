import { Tooltip, Typography } from "antd";
import AutoEllipsisText from "shared/components/AutoEllipsisText";
import { IAd, IAdWithChildren } from "shared/types/adLibrary";
import { adDoesNotHaveChildren } from "../../adCompleteImportDrawer/adImportTable/adImportTableContainer/adImportTable.utils";

type MultipleIfHasChildrenProps = {
  text: string;
  record: IAdWithChildren;
  numberOfItems: number;
  tooltip: string;
  onToggleExpand: (record: IAd) => void;
};

export const MultipleIfHasChildren = ({
  text,
  record,
  numberOfItems,
  tooltip,
  onToggleExpand,
}: MultipleIfHasChildrenProps) => {
  if (adDoesNotHaveChildren(record)) {
    return <AutoEllipsisText>{text}</AutoEllipsisText>;
  }

  return (
    <Tooltip title={tooltip}>
      <Typography.Link
        onClick={e => {
          e.stopPropagation();
          onToggleExpand(record);
        }}
      >
        Multiple ({numberOfItems})
      </Typography.Link>
    </Tooltip>
  );
};
