import { Image } from "antd";
import { useSearchParams } from "react-router-dom";
import { VIDEO_TYPES, WD_CDN_PATH } from "./constants";
import { handleImgError } from "./handleImgError";
import { EyeOutlined } from "@ant-design/icons";

import styles from "./CAMSharedAssets.module.scss";

export const CAMSharedAssets = () => {
  const [search] = useSearchParams();
  const data = atob(search.get("data") ?? "").split(",");

  return (
    <div className={styles.host}>
      {data.map(str => {
        const url = str.startsWith("https://") ? str : WD_CDN_PATH + str;

        if (VIDEO_TYPES.some(t => url.includes(t))) {
          return (
            <div key={url} className={styles.video}>
              <video src={url} controls></video>
            </div>
          );
        }

        return (
          <Image
            key={url}
            src={url}
            preview={{ mask: <EyeOutlined /> }}
            loading="lazy"
            onError={handleImgError}
          />
        );
      })}
    </div>
  );
};
