import API from "services";
import { useInfiniteQuery } from "react-query";
import {
  IAd,
  type GetAdsQueryParams,
  type IGetAdsResponseData,
} from "shared/types/adLibrary";
import { AdType } from "screens/adLibrary/facebookUtils/types";

import AIADefaultImage from "statics/images/svg/aia-default.svg";
import DPADefaultImage from "statics/images/svg/dpa-default.svg";
import FTADefaultImage from "statics/images/svg/fta-default.svg";
import { useValidateAd } from "screens/adLibrary/shared/validationUtils";
import { QcStatus } from "shared/types/adLibrary";
import { useMemo } from "react";

export const fetchAds = async (
  args?: GetAdsQueryParams,
): Promise<IGetAdsResponseData> => {
  const { result, error } = await API.services.adLibrary.getAds(args);

  if (!result) {
    throw Error(error?.message || "Something went wrong while fetching ads.");
  }

  const ads = result.ads.map(ad => {
    switch (ad.type) {
      case AdType.AIA:
        return getDynamicAdWithThumbnail(ad, AIADefaultImage);
      case AdType.DPA:
        return getDynamicAdWithThumbnail(ad, DPADefaultImage);
      case AdType.FTA:
        return getDynamicAdWithThumbnail(ad, FTADefaultImage);
    }
    return ad;
  });
  return {
    ads,
    paginationKey: result.paginationKey,
  };
};

export const useFetchAds = () => {
  const { validateAd, isLoadingStores } = useValidateAd();
  const {
    data,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    "ads",
    ({ pageParam: paginationKey }) =>
      fetchAds({
        limit: 2000,
        paginationKey,
      }),
    {
      staleTime: 300000,
      getNextPageParam: lastPage => lastPage.paginationKey,
    },
  );

  if (hasNextPage && !isFetchingNextPage) {
    fetchNextPage();
  }

  const flattenData = useMemo(
    () => data?.pages.flatMap(page => page.ads),
    [data],
  );

  const ads = useMemo(
    () =>
      flattenData?.map(ad => ({
        ...ad,
        validation: validateAd(ad),
        qcStatus: ad.qcStatus === QcStatus.ERROR ? QcStatus.DRAFT : ad.qcStatus,
      })),
    [flattenData, validateAd],
  );

  const isLoadingSomething =
    isLoading || isLoadingStores || isFetchingNextPage || !!hasNextPage;

  return { data: ads, isLoading: isLoadingSomething, isError };
};

const getDynamicAdWithThumbnail = (ad: IAd, thumbnail: string): IAd => {
  return {
    ...ad,
    visuals: {
      ...ad.visuals,
      thumbnail: thumbnail,
      thumbnail25: thumbnail,
      thumbnail50: thumbnail,
    },
  };
};
