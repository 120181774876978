import { useMutation, useQueryClient } from "react-query";
import { getErrorMessage } from "utils/errorMessage";
import API from "services";
import {
  errorNotification,
  successNotification,
} from "shared/components/customNotification/Notification";
import { Account } from "shared/types/salesEnablement";

const deleteAccounts = async (ids: string[]) => {
  try {
    // replace userId with Aflac userId
    const res = await API.privServices.salesEnablement.deleteAccounts(ids);
    return res;
  } catch (error) {
    const errMsg = getErrorMessage(error);
    throw new Error(errMsg);
  }
};

interface IUseDeleteAccounts {
  onSuccess?: () => void;
}
export const useDeleteAccounts = ({ onSuccess }: IUseDeleteAccounts) => {
  const queryClient = useQueryClient();

  return useMutation<Account[], Error, string[]>(deleteAccounts, {
    onSuccess: () => {
      successNotification({
        messageLabel: "Accounts deleted successfully",
        size: "big",
      });
      onSuccess?.();
    },
    onSettled: () => {
      queryClient.invalidateQueries("accounts");
    },
    onError: () => {
      errorNotification({
        messageLabel: "Error deleting accounts",
      });
    },
  });
};
