import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";

const ErrorFeedback = () => {
  const navigate = useNavigate();
  return (
    <Result
      status="error"
      title="Something went wrong"
      subTitle="Please try again or contact support."
      extra={[
        <Button type="primary" key="reload" onClick={() => navigate(0)}>
          Reload
        </Button>,
        <Button key="buy" onClick={() => navigate(-1)}>
          Go back
        </Button>,
      ]}
    />
  );
};

export default ErrorFeedback;
