import { message } from "antd";
import { useCallback, useState } from "react";
import { useQuery } from "react-query";
import { TRuleCondition } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesContext";
import API from "services";
import { FeedRow, FeedTblRow, TRawData } from "shared/types/assetExporter";
import { getErrorMessage } from "utils/errorMessage";

import { isFeatureEnabled } from "utils/helpers";
import { TSortArgs } from "./../../types/assetExporter";

const adEngineV2Enabled = isFeatureEnabled("ENABLE_AD_ENGINE_V2", false);
const LIMIT_OPTIONS = adEngineV2Enabled ? 30 : 15;
const PAGE_SIZE = String(LIMIT_OPTIONS);
const messageKeyData = "feedConfigFetch";

export interface IFilter {
  column: string;
  selection: string[];
}

export type TFetchArgs = {
  feedId: string;
  page: string;
  searchArgs?: string;
  sortArgs?: TSortArgs;
  filterArgs?: IFilter[];
  ruleArgs?: TRuleCondition[];
};

const feedConfigFetch = async ({
  feedId,
  page,
  searchArgs,
  sortArgs,
}: TFetchArgs): Promise<TRawData> => {
  try {
    const res = await API.services.assetExporter.getRawFeed(
      feedId,
      0,
      30,
      page,
      PAGE_SIZE,
      searchArgs,
      sortArgs,
    );

    const { result } = res;

    if (!result) throw new Error("Unable to get feed config fetch");

    return result;
  } catch (error) {
    const errMsg = getErrorMessage(error);
    throw new Error(errMsg);
  }
};

const getTableRows = (rows: Array<FeedRow>) =>
  rows.map(
    row =>
      ({
        key: row.rowIdentifier,
        order_number: "order_number",
        ...row,
        id: row.rowIdentifier,
      } as FeedTblRow),
  );

export const fetchFeedTblRow = async ({
  feedId,
  page,
  searchArgs,
  sortArgs,
  filterArgs,
  ruleArgs,
}: TFetchArgs) => {
  const { result } = await API.services.assetExporter.getRawFeedV2(
    feedId,
    0,
    30,
    page,
    PAGE_SIZE,
    searchArgs,
    sortArgs,
    filterArgs,
    ruleArgs,
  );

  if (!result || !result.rows) {
    throw new Error("Unable to get feed config fetch");
  }

  return {
    rows: getTableRows(result.rows),
    rowsCount: result.rowsCount,
    totalFeedCount: result.totalFeedCount,
    totalFilteredRows: result.totalFilteredRows,
    filteredCount: result.filteredCount,
    orders: result.orders,
  };
};

export const useFeedConfigFetch = (feedId: string) => {
  const [inputTimer, setInputTimer] = useState<NodeJS.Timeout>();
  const [page, setPage] = useState("1");
  const [searchArgs, setSearchArgs] = useState<string>();
  const [searchValue, setSearchValue] = useState("");
  const [sortArgs, setSortArgs] = useState<TSortArgs>();

  const onChangeSort = useCallback(
    (params: TSortArgs) => {
      if (inputTimer) {
        clearTimeout(inputTimer);
      }

      const tmpTimer = setTimeout(() => {
        setPage("1");
        setSortArgs(params);
      }, 500);

      setInputTimer(tmpTimer);
    },
    [inputTimer, setInputTimer],
  );

  const args: TFetchArgs = {
    feedId,
    page,
    searchArgs,
    sortArgs,
  };

  const onChangeSearch = (searchArg: string) => {
    setSearchValue(searchArg);

    if (inputTimer) {
      clearTimeout(inputTimer);
    }

    const tmpTimer = setTimeout(() => {
      setPage("1");
      setSearchArgs(searchArg);
    }, 2000);

    setInputTimer(tmpTimer);
  };

  const onChangePage = () => {
    setPage(prevPage => String(Number(prevPage) + 1));
  };

  const result = useQuery<TRawData | undefined, Error>(
    ["feedConfig", args],
    () => feedConfigFetch(args),
    {
      staleTime: Infinity,
      enabled: !!feedId && !!args,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        message.destroy(messageKeyData);
      },
      onError: () => {
        message.error(
          {
            key: messageKeyData,
            content: "Error fetching feed data",
          },
          3,
        );
      },
    },
  );

  if (result.isLoading) {
    message.loading({
      key: messageKeyData,
      content: "Loading feed data...",
    });
  }

  const rows = result?.data?.rows;
  const searchLoading = result?.isLoading && Boolean(args?.searchArgs);
  const hasRows = Boolean(rows?.length);
  const hasOnePageOnly = Boolean(!!rows && rows?.length < Number(PAGE_SIZE));

  const hasMorePages = hasRows && !hasOnePageOnly;

  return {
    ...result,
    searchLoading,
    hasMorePages,
    page,
    searchValue,
    fetchArgs: args,
    setSearchArgs,
    setSortArgs,
    setPage,
    onChangeSort,
    onChangeSearch,
    onChangePage,
  };
};
