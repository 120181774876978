import { Button, Popover, Stack, Divider } from "@mui/material";
import { observer } from "mobx-react-lite";
import { LayersRounded } from "@mui/icons-material";
import { StoreType } from "polotno/model/store";
import { useAnchorPopover } from "../../../hooks/useAnchorPopover";
import { LayeringActions } from "./position/LayeringActions";
import { PositioningActions } from "./position/PositioningActions";
import { SpacingActions } from "./position/SpacingActions";

type PositionProps = {
  store: StoreType;
};

export const Position = observer(({ store }: PositionProps) => {
  const { id, open, onClick, onClose, anchorEl } = useAnchorPopover("position");
  const hideGroup = store.selectedElements.some(el => el.type === "group");

  return (
    <>
      <Button
        startIcon={<LayersRounded color="action" />}
        size="small"
        variant="text"
        onClick={onClick}
        sx={{
          backgroundColor: "background.paper",
          borderRadius: 0,
          lineHeight: 1.7,
          color: "text.primary",
          "&:hover": { backgroundColor: "grey.300" },
        }}
      >
        Position
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack
          sx={{ p: 2.5, pr: 5 }}
          spacing={1}
          divider={<Divider flexItem />}
        >
          <LayeringActions store={store} />
          {!hideGroup && <PositioningActions store={store} />}
          {!hideGroup && <SpacingActions store={store} />}
        </Stack>
      </Popover>
    </>
  );
});
