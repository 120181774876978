import { memo } from "react";
import { Menu, notification } from "antd";
import { IMenu } from "shared/types/menu";
import MenuLink from "./MenuLink";
import { useAuthenticated } from "shared/hooks/useAuthenticated";
import { User } from "redux/auth/auth.slice";
import { useIsAdmin } from "shared/hooks/useIsAdmin";
import { isFeatureEnabled } from "utils/helpers";

interface IProps {
  menus?: IMenu[];
  selectedMenu?: IMenu;
  user?: User | null;
  setCollapsed?: (value: boolean) => void;
}

const MenuOverlay = ({ menus, selectedMenu, setCollapsed }: IProps) => {
  const isAdmin = useIsAdmin();
  const authenticated = useAuthenticated();
  const selectedMenuKey = selectedMenu?.header ? [selectedMenu?.header] : [];

  if (isFeatureEnabled("ENABLE_SALES_ENABLEMENT")) {
    return (
      <Menu selectedKeys={selectedMenuKey}>
        {menus
          ?.filter(({ hidden }) => !hidden)
          ?.map(({ header, Icon, subMenus, path }) => {
            const menuPath = path || subMenus?.[0].path;

            if (subMenus?.length) {
              return (
                <Menu.SubMenu
                  disabled={!authenticated}
                  title={
                    <div>
                      {Icon && <Icon />}
                      {header}
                    </div>
                  }
                  key={header}
                >
                  {subMenus.map(subMenu => (
                    <Menu.Item key={subMenu.path} disabled={!authenticated}>
                      <MenuLink to={subMenu.path || ""}>
                        {subMenu.header}
                      </MenuLink>
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              );
            }

            return (
              <Menu.Item
                key={header}
                disabled={!authenticated}
                onClick={() => !!setCollapsed && setCollapsed(true)}
              >
                <MenuLink Icon={Icon} to={menuPath || ""}>
                  {header}
                </MenuLink>
              </Menu.Item>
            );
          })}
      </Menu>
    );
  }

  return (
    <Menu selectedKeys={selectedMenuKey}>
      {menus
        ?.filter(({ hidden }) => !hidden)
        ?.map(({ header, Icon, subMenus, path }) => {
          const menuPath = path || subMenus?.[0].path;
          const headers = ["Asset Builder", "Ad Engine", "Design Studio"];

          if (headers.includes(header) && !isAdmin) {
            return (
              <Menu.Item
                key={header}
                disabled={!authenticated}
                onClick={() => {
                  notification.warning({
                    message: "Warning",
                    description: `Unable to access ${header}. Please reach out to an account admin if you require access.`,
                    placement: "bottomRight",
                  });
                }}
              >
                {Icon && <Icon />}
                {header}
              </Menu.Item>
            );
          }

          if (subMenus?.length && !isAdmin) {
            return (
              <Menu.SubMenu
                disabled={!authenticated}
                title={
                  <div>
                    {Icon && <Icon />}
                    {header}
                  </div>
                }
                key={header}
              >
                {subMenus.map(subMenu => (
                  <Menu.Item
                    disabled={!authenticated}
                    key={subMenu.path}
                    onClick={() => {
                      notification.warning({
                        message: "Warning",
                        description: `Unable to access ${subMenu.header}. Please reach out to an account admin if you require access.`,
                        placement: "bottomRight",
                      });
                    }}
                  >
                    {Icon && <Icon />}
                    {subMenu.header}
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            );
          } else if (subMenus?.length && isAdmin) {
            return (
              <Menu.SubMenu
                disabled={!authenticated}
                title={
                  <div>
                    {Icon && <Icon />}
                    {header}
                  </div>
                }
                key={header}
              >
                {subMenus.map(subMenu => (
                  <Menu.Item key={subMenu.path} disabled={!authenticated}>
                    <MenuLink to={subMenu.path || ""}>
                      {subMenu.header}
                    </MenuLink>
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            );
          }

          return (
            <Menu.Item
              key={header}
              disabled={!authenticated}
              onClick={() => !!setCollapsed && setCollapsed(true)}
            >
              <MenuLink Icon={Icon} to={menuPath || ""}>
                {header}
              </MenuLink>
            </Menu.Item>
          );
        })}
    </Menu>
  );
};

export default memo(MenuOverlay);
