import { Button, Drawer, Input } from "antd";
import { useCallback, useMemo, useState } from "react";
import Card from "shared/components/CardV2";
import { InMemoryAdapter } from "shared/components/dataListURL/InMemoryAdapter";
import { IDataListURLFields } from "shared/components/dataListURL/types";
import { useFetchAccounts } from "shared/hooks/salesEnablement/useFetchAccounts";
import { QueryParamProvider, StringParam } from "use-query-params";
import { compareStringBy, onFilterBy } from "utils/helpers.table";
import style from "./AccountLogosDrawer.module.scss";
import { DataListURLProvider } from "shared/components/dataListURL/dataListURLContext";
import { AccountLogosList } from "./AccountLogosList";
import Folder from "statics/images/folder.png";
import { AccountLogo } from "shared/types/salesEnablement";

export const AccountLogosDrawer = ({
  onClose,
  isOpen,
  onSelectLogo,
}: {
  onClose: () => void;
  isOpen: boolean;
  onSelectLogo: (logo: AccountLogo) => void;
}) => {
  const accounts = useFetchAccounts();
  const [selectedAccountId, setSelectedAccountId] = useState<
    string | undefined
  >();
  const [selectedLogo, setSelectedLogo] = useState<AccountLogo | undefined>();
  const [accountSearchValue, setAccountSearchValue] = useState<string>("");

  const closeDrawer = useCallback(() => {
    setSelectedLogo(undefined);
    setSelectedAccountId(undefined);
    onClose();
  }, [onClose]);

  const selectLogo = useCallback(() => {
    if (!selectedLogo) return;
    onSelectLogo(selectedLogo);
    closeDrawer();
  }, [selectedLogo, onSelectLogo, closeDrawer]);

  const filteredAccounts = useMemo(() => {
    if (!accountSearchValue) return accounts.data ?? [];
    return (
      accounts.data?.filter(account => {
        return account.name
          .toLowerCase()
          .includes(accountSearchValue.toLowerCase());
      }) ?? []
    );
  }, [accounts.data, accountSearchValue]);
  return (
    <QueryParamProvider adapter={InMemoryAdapter}>
      <Drawer
        title={
          selectedAccountId ? (
            <div>
              <b>Media: </b>
              Select Image
            </div>
          ) : (
            "Account Logos"
          )
        }
        width="calc(100vw - 48px)"
        visible={isOpen}
        onClose={closeDrawer}
        closable={false}
        destroyOnClose
        bodyStyle={{
          backgroundColor: "#F5F5F5",
          padding: "0",
        }}
        footer={
          <div className={style.footerContainer}>
            <Button onClick={closeDrawer}>Cancel</Button>
            <Button
              type="primary"
              disabled={!selectedLogo}
              onClick={selectLogo}
            >
              Select
            </Button>
          </div>
        }
      >
        <div className={style.container}>
          {selectedAccountId ? (
            <DataListURLProvider fields={fields}>
              <AccountLogosList
                accounts={accounts.data ?? []}
                selectedAccountId={selectedAccountId}
                setSelectedLogo={setSelectedLogo}
                selectedLogo={selectedLogo}
              />
            </DataListURLProvider>
          ) : (
            <>
              <div className={style.searchInputContainer}>
                <Input
                  value={accountSearchValue}
                  placeholder={"Search"}
                  onChange={value => setAccountSearchValue(value.target.value)}
                  className={style.searchInput}
                />
              </div>
              <div className={style.cardsContainer}>
                {filteredAccounts.map(account => (
                  <div
                    key={account.id}
                    onClick={() => setSelectedAccountId(account.id)}
                    className={style.cardContainer}
                  >
                    <Card
                      thumbnail={Folder}
                      title={account.name}
                      tags={[]}
                      checkboxType="none"
                      item={account}
                      onSelect={() => setSelectedAccountId(account.id)}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </Drawer>
    </QueryParamProvider>
  );
};

const fields: IDataListURLFields<keyof AccountLogo, AccountLogo> = {
  name: {
    filterFn: onFilterBy("name", {
      matchMode: "includes",
      caseInsensitive: true,
    }),
    sorterFn: compareStringBy("name"),
    queryParamConfigFilter: StringParam,
  },
  type: {
    filterFn: onFilterBy("type", {
      matchMode: "includes",
      caseInsensitive: true,
    }),
    sorterFn: compareStringBy("type"),
    queryParamConfigFilter: StringParam,
  },
};
