import ShareOutlined from "shared/icons/ShareOutlined";
import { AdType } from "screens/adLibrary/facebookUtils/types";
import Link from "antd/lib/typography/Link";
import Paragraph from "antd/lib/typography/Paragraph";
import { hasEA } from "../../../utils/helpers.ad";
import { isDynamicAd } from "utils/helpers";

interface IProps {
  destinationUrl: string | undefined;
  adFormat: AdType | null | undefined;
}

type DestinationUrlProps = IProps;

const DestinationUrl = ({ destinationUrl, adFormat }: DestinationUrlProps) => {
  const urls = destinationUrl?.split("\n").filter(Boolean) ?? [];
  const [firstUrl = ""] = urls;
  const isEA = hasEA(adFormat);

  if (isEA) return <Paragraph>Unable to get URL from Meta.</Paragraph>;
  if (!urls.length) return null;

  const hasMultipleUrls = urls.length > 1;
  return (
    <>
      {hasMultipleUrls && <MultipleDestinationUrls urls={urls} />}
      {!hasMultipleUrls && <SingleDestinationUrl url={firstUrl} />}
      {isDynamicAd(adFormat) && (
        <Paragraph>
          Clicking on each product will bring you directly to the VDP of that
          product
        </Paragraph>
      )}
    </>
  );
};

export default DestinationUrl;

const MultipleDestinationUrls = ({ urls }: { urls: string[] }) => (
  <ul>
    {urls.map(url => (
      <li key={url}>
        <SingleDestinationUrl url={url} />
      </li>
    ))}
  </ul>
);

const SingleDestinationUrl = ({ url }: { url: string }) => (
  <>
    {url}
    <Link href={url} target="_blank">
      <ShareOutlined style={{ marginLeft: 8, cursor: "pointer" }} />
    </Link>
  </>
);
