import { Select } from "antd";
import { useBeeswaxAdvertisers } from "./hooks/useBeeswaxAdvertisers";

type Props = {
  onChange: (value: string | undefined) => void;
  value: string | undefined;
};

const BeeswaxAdvertiserSelect = ({ onChange, value }: Props) => {
  const { data: advertisers, isLoading } = useBeeswaxAdvertisers();

  return (
    <Select
      allowClear
      showSearch
      placeholder="Select an advertiser"
      onChange={onChange}
      value={isLoading ? "Fetching advertisers..." : value}
      loading={isLoading}
      disabled={isLoading}
      optionFilterProp="label"
      options={advertisers?.map(advertiser => ({
        label: `${advertiser.name} - ${advertiser.id}`,
        value: `${advertiser.id}`,
      }))}
    />
  );
};

export default BeeswaxAdvertiserSelect;
