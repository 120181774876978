import { Button, Stack, ButtonProps } from "@mui/material";

type BigButtonProps = ButtonProps;
export const BigButton = ({ children, ...buttonProps }: BigButtonProps) => {
  return (
    <Button
      variant="outlined"
      sx={{
        p: 3,
        backgroundColor: "action.hover",
        borderColor: "divider",
      }}
      {...buttonProps}
    >
      <Stack alignItems="center" spacing={1}>
        {children}
      </Stack>
    </Button>
  );
};
