import { Collapse } from "antd";
import { useMemo } from "react";

import {
  IDisclosureVariable,
  IOfferTypeVariable,
} from "../../../../shared/types/legalLingo";

import styles from "./OfferTypeVariablesCollapse.module.scss";

interface IDisclosuresContent {
  offerType: string;
  selectedStateTab: string;
  selectedOem: string;
  variables: IDisclosureVariable[];
  activeOfferTypeKey: string;
  setActiveOfferTypeKey: (offerTypeKey: string) => void;
  variableNamesInDisclosures: string[];
  variableNamesInExceptions: string[];
  requiredVarsByOem?: IOfferTypeVariable;
  requiredVarsByState?: IOfferTypeVariable;
}

const OfferTypeVariablesCollapse: React.FC<IDisclosuresContent> = ({
  selectedStateTab,
  selectedOem,
  offerType,
  variables,
  activeOfferTypeKey,
  setActiveOfferTypeKey,
  variableNamesInDisclosures,
  variableNamesInExceptions,
  requiredVarsByOem,
  requiredVarsByState,
}) => {
  const panelKey = `${offerType}-collapse`;

  const isSingleVarGroupFulfilled = (
    inputOfferType: string,
    variableGroup: string[],
  ) => {
    return (
      offerType === inputOfferType && variableGroup.includes(inputOfferType)
    );
  };

  const returnIsSectionRequired = (
    section: "oem" | "state",
    variableGroup: string[],
  ) => {
    let stillRequired = true;

    switch (offerType) {
      case "Extra Cost":
        stillRequired =
          section !== "oem"
            ? !isSingleVarGroupFulfilled("Extra Cost", variableGroup)
            : false;
        break;
      case "Expiration Date":
        stillRequired =
          section !== "oem"
            ? !isSingleVarGroupFulfilled("Expiration Date", variableGroup)
            : false;
        break;
      default:
        stillRequired =
          section !== "oem"
            ? !!requiredVarsByState &&
              requiredVarsByState.variables.filter(
                variable =>
                  variable.requiredByStates.includes(
                    selectedStateTab.toUpperCase(),
                  ) && !variableGroup.includes(variable.name),
              ).length > 0
            : !!requiredVarsByOem &&
              requiredVarsByOem.variables.filter(
                variable =>
                  variable.requiredByOems &&
                  variable.requiredByOems.includes(selectedOem.toLowerCase()) &&
                  !variableGroup.includes(variable.name),
              ).length > 0;
        break;
    }

    return stillRequired;
  };

  const groupIstStillRequired = selectedOem
    ? returnIsSectionRequired("state", variableNamesInDisclosures) ||
      returnIsSectionRequired("oem", variableNamesInExceptions)
    : returnIsSectionRequired("state", variableNamesInDisclosures);

  const allVariables = useMemo(() => {
    if (!requiredVarsByOem) {
      return variables;
    }

    const diffVarObjs = requiredVarsByOem.variables
      .filter(
        oemVarObj =>
          oemVarObj.requiredByOems &&
          oemVarObj.requiredByOems.includes(selectedOem.toLowerCase()),
      )
      .filter(
        oemVarObj =>
          !variables.find(stateVarObj => stateVarObj.name === oemVarObj.name),
      );

    const combinedVars = variables.concat(diffVarObjs);

    return combinedVars;

    // eslint-disable-next-line
  }, [requiredVarsByOem, variables]);

  const headerContent = (
    <div className={styles.variableCollapseHeader}>
      <span
        className={
          groupIstStillRequired
            ? styles.isRequiredIndicator
            : styles.notRequiredIndicator
        }
      />
      {offerType}
    </div>
  );

  const returnReqClassName = (
    fullReqFulfilled: boolean,
    reqByVariable: boolean,
    currentOfferType: string,
  ) =>
    !fullReqFulfilled &&
    (reqByVariable ||
      currentOfferType === "Extra Cost" ||
      currentOfferType === "Expiration Date")
      ? styles.isRequiredIndicator
      : styles.notRequiredIndicator;

  return (
    <div>
      <Collapse
        className={styles.collapseContainer}
        activeKey={activeOfferTypeKey}
        onChange={() => {
          setActiveOfferTypeKey(
            activeOfferTypeKey === panelKey ? "" : panelKey,
          );
        }}
      >
        <Collapse.Panel key={panelKey} header={headerContent}>
          {allVariables.map(variable => {
            const defaultOfferType =
              offerType === "Extra Cost" || offerType === "Expiration Date";
            const reqStateVariable =
              variable.requiredByStates.filter(
                requiredState =>
                  requiredState === selectedStateTab.toUpperCase(),
              ).length > 0;
            const reqByOemVariable =
              requiredVarsByOem && selectedOem && !defaultOfferType
                ? !!requiredVarsByOem.variables
                    .filter(
                      oemVarObj =>
                        oemVarObj.requiredByOems &&
                        oemVarObj.requiredByOems.includes(
                          selectedOem.toLowerCase(),
                        ),
                    )
                    .find(varObj => varObj.name === variable.name)
                : false;
            const stateReqFulfilled = variableNamesInDisclosures.includes(
              variable.name,
            );
            const oemReqFulfilled =
              selectedOem && requiredVarsByOem && !defaultOfferType
                ? variableNamesInExceptions.includes(variable.name)
                : true;
            const fullReqFulfilled = stateReqFulfilled && oemReqFulfilled;
            const requiredVar = reqStateVariable || reqByOemVariable;
            return (
              <div key={`${variable.name}-variable-div`}>
                <span
                  className={returnReqClassName(
                    fullReqFulfilled,
                    requiredVar,
                    offerType,
                  )}
                />
                {variable.name}
                {selectedOem && (
                  <ul className={styles.reqVariableUl}>
                    <li>
                      <span
                        className={returnReqClassName(
                          stateReqFulfilled,
                          reqStateVariable,
                          offerType,
                        )}
                      />
                      State
                    </li>
                    <li>
                      <span
                        className={returnReqClassName(
                          oemReqFulfilled,
                          reqByOemVariable,
                          offerType,
                        )}
                      />
                      OEM
                    </li>
                  </ul>
                )}
              </div>
            );
          })}
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default OfferTypeVariablesCollapse;
