import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { observer } from "mobx-react-lite";
import { TextLayerElement } from "screens/designStudio/designStudioV2/types";

type FontFamilySelectProps = {
  elements: TextLayerElement[];
  brandFronts: string[];
};

const FontFamilySelect = observer(
  ({ elements, brandFronts }: FontFamilySelectProps) => {
    const [layer] = elements;
    const defaultFonts = ["Arial", "Roboto", "Times New Roman", "Verdana"];
    const fonts = [...new Set([...defaultFonts, ...brandFronts])];

    const handleFontChange = (e: SelectChangeEvent) => {
      elements.forEach((element: any) => {
        const newFont = e.target.value;
        element?.set({ fontFamily: newFont });
      });
    };

    return (
      <FormControl size="small">
        <InputLabel>Font</InputLabel>
        <Select
          size="small"
          value={layer?.fontFamily || fonts[0]}
          label="Font"
          variant="outlined"
          onChange={handleFontChange}
          sx={{ minWidth: 100, maxWidth: 200 }}
        >
          {fonts.map(font => (
            <MenuItem key={font} value={font}>
              {font}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  },
);

export default FontFamilySelect;
