import { getAccessToken } from "./auth";
import { config } from "./config";

export const getPresignedUrl = async (
  folderId: string,
  file: File,
  assetId?: string,
): Promise<MGPresignedUrl> => {
  const params = new URLSearchParams({
    folderid: folderId,
    filesize: String(file.size),
    filename: file.name,
    contenttype: file.type,
    ...(!!assetId && { assetid: assetId, intent: "newversion" }),
  });

  const res = await fetch(
    `${config.wdBaseUrl}/awss3/generateupload?${params}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    },
  );

  if (res.ok) {
    return res.json();
  }

  throw new Error("Failed to fetch presigned url.");
};

export const processUploadFile = (file: File): File => {
  if (file.type) {
    return file;
  }
  return handleFilesWithoutFileType(file);
};

const handleFilesWithoutFileType = (file: File): File => {
  const extension = file.name.split(".").pop();
  if (!extension) {
    return file;
  }
  const mimeType = getMimeTypeForExtension(extension);
  return mimeType ? new File([file], file.name, { type: mimeType }) : file;
};

const getMimeTypeForExtension = (extension: string): string | null => {
  const mimeTypes: { [key: string]: string } = {
    psd: "image/vnd.adobe.photoshop",
  };
  return mimeTypes[extension] || null;
};

export const finishUpload = async (
  processid: string,
): Promise<{ id: string }> => {
  const res = await fetch(
    `${config.wdBaseUrl}/awss3/finishupload/${processid}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    },
  );

  if (res.ok) {
    return res.json();
  }

  throw new Error("Failed to finish upload.");
};

export const uploadToS3 = (url: string, body: File) => {
  return fetch(url, {
    method: "PUT",
    body,
  });
};
