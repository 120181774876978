import { useDeleteFolderMutation } from "../../../../redux/media/media.api";
import { message, Modal } from "antd";
import { handleApiError } from "../handleApiError";

export const useDeleteFolder = (record: WDFolder) => {
  const [deleteFolder] = useDeleteFolderMutation();

  return () =>
    Modal.confirm({
      title: "Delete Folder",
      okText: "Delete",
      okButtonProps: {
        danger: true,
      },
      content: (
        <p>
          <strong>IMPORTANT: This folder can contain resources.</strong>
          <br />
          Are you sure you want to delete <strong>{record.name}</strong>?
        </p>
      ),
      async onOk() {
        try {
          await deleteFolder(record.id).unwrap();
          message.success("Folder was deleted.");
        } catch (error) {
          handleApiError(error);
        }
      },
    });
};
