import { useSalesEnablementContext } from "screens/designStudio/hooks/SalesEnablementDataProvider";
import {
  FileDrawerMode,
  Language,
  Template,
} from "shared/types/salesEnablement";
import { successNotification } from "shared/components/customNotification/Notification";

const useTemplateEditing = () => {
  const {
    uploadForm,
    setFiles,
    setFileDrawerMode,
    setSelectedTemplate,
    setStatus,
    setOpen,
    setDrawerDisabledMap,
    setComponentsTabsFilter,
    setActiveLanguageTab,
  } = useSalesEnablementContext();

  const initComponentsTabs = (files: Template["files"]) => {
    if (Object.values(files).length === 1) {
      const filteredLang = Object.keys(files)[0].includes("en") ? "es" : "en";
      setComponentsTabsFilter(filteredLang);
      setActiveLanguageTab(Object.keys(files)[0] as Language);
    }
  };

  const setupDrawer = ({
    template,
    drawerMode,
  }: {
    template?: Template;
    drawerMode: FileDrawerMode;
  }) => {
    if (drawerMode === "new" || !template) {
      setFileDrawerMode("new");
      return setOpen(true);
    }

    const name =
      drawerMode === "duplicate" ? `Copy of ${template.name}` : template.name;
    const status = drawerMode === "duplicate" ? "UNPUBLISHED" : template.status;

    const values = {
      name,
      description: template.description,
      audience: template.audience,
      expirationDate: template.expirationDate,
      englishFormNumber: template.formNumber?.en,
      spanishFormNumber: template.formNumber?.es,
      locations: template.locations,
      tags: template.tags?.map(t => t.name),
      salesCycle: template.salesCycle,
      materialType: template.materialType,
    };

    uploadForm?.setFieldsValue(values);
    setStatus(status);

    setFileDrawerMode(drawerMode);
    setSelectedTemplate(template);

    if (drawerMode === "edit" && template.statusHistory.includes("PUBLISHED")) {
      setDrawerDisabledMap({
        settings: true,
        files: {
          en: !!template.files.en,
          es: !!template.files.es,
        },
      });
    }

    setFiles({
      en: template.files?.en
        ? { ...template.files.en, status: "done" }
        : undefined,
      es: template.files?.es
        ? { ...template.files.es, status: "done" }
        : undefined,
    });

    initComponentsTabs(template.files);

    setOpen(true);

    if (drawerMode === "duplicate") {
      successNotification({
        messageLabel: "The template has been successfully duplicated",
        size: "big",
      });
    }
  };

  return { setupDrawer };
};

export default useTemplateEditing;
