import { useCallback } from "react";
import useDeepEffect from "shared/hooks/useDeepEffect";
import {
  CanvasProcessing,
  IDimension,
  IPublishCanvasStatus,
} from "shared/types/designStudio";
import { toJSON } from "../canvas.utils/Parsers";
import { CANVAS_MARGIN } from "./useCanvas";

type Args = {
  onComplete?: (json: any, isPublishing: boolean) => void;
  canvas?: fabric.Canvas;
  wrapperDiv: HTMLDivElement | null;
  dimension: IDimension;
  savingDraft: CanvasProcessing;
  publishCanvasStatus: IPublishCanvasStatus | null;
};

export default (args: Args) => {
  const {
    canvas,
    dimension,
    wrapperDiv,
    publishCanvasStatus,
    savingDraft,
    onComplete,
  } = args;
  const getSerializingProps = useCallback(
    () => ({
      originalDimension: dimension,
      wrapperDiv,
      CANVAS_MARGIN,
    }),
    [dimension, wrapperDiv],
  );

  // Exit editing mode while preserving the selectable state by adjusting textboxes before serialization
  const exitTextboxEditing = (canvas: fabric.Canvas | undefined) => {
    canvas
      ?.getObjects()
      .filter(obj => obj.type === "textbox")
      .forEach(obj => {
        const textbox = obj as fabric.Textbox;
        const wasSelectable = textbox.selectable;
        if (textbox.isEditing) {
          textbox.exitEditing();
        }
        textbox.set("selectable", wasSelectable);
      });
  };

  useDeepEffect(() => {
    const okToProceed =
      savingDraft === "SAVE_FIRED" || publishCanvasStatus?.status === "BEGIN";
    if (!okToProceed) return;

    exitTextboxEditing(canvas);

    const shouldPublish = publishCanvasStatus?.status === "BEGIN";
    toJSON({ canvas, ...getSerializingProps() }).then(json =>
      onComplete?.(json, shouldPublish),
    );
  }, [canvas, savingDraft, getSerializingProps, publishCanvasStatus]);
};
