import {
  CarOutlined,
  ControlOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditFilled,
  EyeOutlined,
  FileDoneOutlined,
  InboxOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Avatar, message, Modal, Space, Tooltip, Typography } from "antd";
import { memo, useEffect, useState } from "react";

import { shallowEqual, useSelector } from "react-redux";
import actions from "redux/rootActions";
import { useAppDispatch } from "shared/hooks/useAppDispatch";

import Card from "shared/components/Card";

import { Link, useLocation } from "react-router-dom";

import { fabric } from "fabric";

import { getColorFromConflictStatus } from "screens/adLibrary/utils";
import { fetchCanvasJson } from "shared/components/contextAPI/shared/RenderTemplate";
import placeholder from "statics/images/image-placeholder.png";
import { roundTimeStamp } from "utils/helpers";

import { ConflictStatus } from "shared/types/adLibrary";
import { ICardButtonObj } from "shared/types/card";
import {
  customFabricAttributes,
  HeaderMenu,
  IDesignStudioState,
  IPublishCanvasStatus,
  ITemplate,
  TabMenu,
} from "shared/types/designStudio";

import RenderTemplateProvider from "shared/components/contextAPI/shared/RenderTemplate";
import { useAppSelector } from "shared/hooks/useAppSelector";
import StampOutlined from "shared/icons/StampOutlined";
import UnpublishOutlined from "shared/icons/UnpublishOutlined";
import Preview from "../../../editor/canvasContainer/toolbar/Preview";
import "./TemplateCard.scss";

import { useIsAdmin } from "shared/hooks/useIsAdmin";
import { useMutateTemplate } from "shared/hooks/useMutateTemplate";

type PopoverMenuItemType =
  | "edit"
  | "duplicate"
  | "delete"
  | "archive"
  | "publish"
  | "un-publish"; // this type is defined within the component since it will be local type

interface ICardProps {
  selectedHeader: HeaderMenu;
  template: ITemplate;
  previewTemplateId: string;

  canvas: fabric.Canvas | null; // renamed to canvasV1
}

interface ICardHandlers {
  archiveTemplate: (templateId: string) => void;
  setPreviewTemplateId: (previewTemplateId: string) => void;

  // below two methods comes from DesignStudio.tsx
  setSelectedTab?: (tabName: TabMenu) => void;
  setSelectedHeader?: (header: HeaderMenu) => void;

  setCanvas: (canvas: fabric.Canvas) => void; // renamed to setCanvasV1
}

const TemplateCard: React.FC<ICardProps & ICardHandlers> = ({
  archiveTemplate,
  selectedHeader,
  template,
  setPreviewTemplateId,
  setSelectedHeader,
  setSelectedTab,
  canvas: canvasV1,
  setCanvas: setCanvasV1,
  previewTemplateId,
}) => {
  const isAdmin = useIsAdmin();
  const config = useAppSelector(state => state.configuration.config);

  const accessDeniedMessage =
    "This feature is disabled for this account. Contact your team lead to request access.";

  const {
    deletingDataId,
    publishingTemplateId,
    publishCanvasStatus,
    templateToEditor,
  } = useSelector(
    ({ designStudio }: { designStudio: IDesignStudioState }) => ({
      deletingDataId: designStudio.deletingDataId,
      publishCanvasStatus: designStudio.publishCanvasStatus,
      publishingTemplateId: designStudio.publishingTemplateId,
      templateToEditor: designStudio.templateToEditor,
    }),
    shallowEqual,
  );
  const dispatch = useAppDispatch();
  const { search } = useLocation();

  const {
    updateTemplate,
    deleteTemplate,
    publishCanvas,
    setTemplateToUpdate,
    setTogglePropId,
  } = actions.designStudio;

  const updateCanvasPublishStatus = ({
    publishCanvasStatus,
    template,
  }: {
    publishCanvasStatus: IPublishCanvasStatus;
    template?: ITemplate;
  }) => {
    dispatch(
      publishCanvas({
        publishCanvasStatus,
        template: template || null,
        stamp: null,
        canvasJson: null,
        base64Thumbnail: null,
      }),
    );
  };

  const MINUTE = 1000 * 60;

  useEffect(() => {
    if (!templateToEditor || template.id !== templateToEditor.id) {
      return;
    }
    setPreviewTemplateId(templateToEditor.id!);
  }, [setPreviewTemplateId, template.id, templateToEditor]);

  useEffect(() => {
    if (template.id !== previewTemplateId || canvasV1) {
      return;
    }
    fetchCanvasJson(template.canvasJsonUrl as string).then(canvasJson => {
      const initialCanvas = new fabric.Canvas(document.createElement("canvas"));
      initialCanvas.loadFromJSON(canvasJson, async () => {
        const canvasWidth = (template && template.artboard.width) || 0;
        const canvasHeight = (template && template.artboard.height) || 0;

        initialCanvas.setDimensions({
          width: canvasWidth,
          height: canvasHeight,
        });
        setCanvasV1(initialCanvas);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewTemplateId]);

  const popoverMenuItems: Array<{
    title: string;
    type: PopoverMenuItemType;
  }> = [
    {
      title: "Edit Parameters",
      type: "edit",
    },
    {
      title: "Duplicate",
      type: "duplicate",
    },
    {
      title: "Archive",
      type: "archive",
    },
  ];

  if (template.status === "PUBLISHED") {
    popoverMenuItems.push({
      title: "Un-publish",
      type: "un-publish",
    });
  } else {
    popoverMenuItems.push({
      title: "Publish",
      type: "publish",
    });
  }

  const mutation = useMutateTemplate();
  const onPopoverMenuClick =
    (
      type: PopoverMenuItemType,
      selectedTemplate: ITemplate,
      disable: boolean,
    ) =>
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      event.preventDefault();
      if (disable) return;

      switch (type) {
        case "edit":
          // The way that edit template works is following.
          //  1. set template to update in redux state.
          //  2. in the component that contains template drawer, check if this template is set in the redux state and display if it is
          //  3. click on close clears this template in redux state
          //  4. click on submit updates the template
          dispatch(setTemplateToUpdate(template));

          break;

        case "duplicate":
          Modal.confirm({
            title: "Are you sure you want to duplicate?",
            content: (
              <div>
                Are you sure you want to duplicate
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.1rem",
                    marginLeft: "1em",
                  }}
                >
                  {selectedTemplate.name}
                </span>
                ?
              </div>
            ),

            onOk: () => {
              mutation.mutate(
                {
                  template: {
                    ...selectedTemplate,
                    name: `${selectedTemplate.name}-Copy`,
                    status: "UN-PUBLISHED",
                  },
                  mode: "DUPLICATE",
                },
                {
                  onSettled: createdTemplate => {
                    dispatch(setTemplateToUpdate(createdTemplate)); // this is needed to open the drawer
                  },
                },
              );
            },
          });
          break;

        case "archive":
          Modal.confirm({
            title: "Are you sure you want to archive?",
            content: (
              <div>
                Are you sure you want to archive
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.1rem",
                    marginLeft: "1em",
                  }}
                >
                  {selectedTemplate.name}
                </span>
                ?
              </div>
            ),

            okType: "primary",
            okButtonProps: { danger: true },
            onOk: () => {
              dispatch(
                updateTemplate(
                  { ...selectedTemplate, isDeleted: true },
                  "archiving",
                ),
              );
              archiveTemplate(selectedTemplate?.id || "");
            },
          });
          break;

        case "delete":
          Modal.confirm({
            title: "Are you sure you want to delete?",
            content: (
              <div>
                Are you sure you want to delete
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.1rem",
                    marginLeft: "1em",
                  }}
                >
                  {selectedTemplate.name}
                </span>
                ?
              </div>
            ),

            okType: "primary",
            okButtonProps: { danger: true },
            onOk: () => {
              dispatch(
                deleteTemplate(
                  template.id as string,
                  template.createdAt as number,
                ),
              );
            },
          });
          break;

        case "publish":
          Modal.confirm({
            title: "Are you sure you want to publish?",
            content: (
              <div>
                Are you sure you want to publish
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.1rem",
                    marginLeft: "1em",
                  }}
                >
                  {selectedTemplate.name}
                </span>
                ?
              </div>
            ),
            okType: "primary",
            onOk: () => {
              if (!template || !template.id) {
                return;
              }

              dispatch(setTogglePropId(template.id, "publishingTemplateId"));
              // NOTE: This call here will only initiate the PUBLISH_BEGIN state.
              // The actual update will be handled in useEffect above.
              updateCanvasPublishStatus({
                publishCanvasStatus: {
                  type: "templates",
                  status: "BEGIN",
                },
              });
            },
          });
          break;

        case "un-publish":
          Modal.confirm({
            title: "Are you sure you want to un-publish?",
            content: (
              <div>
                Are you sure you want to un-publish
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.1rem",
                    marginLeft: "1em",
                  }}
                >
                  {selectedTemplate.name}
                </span>
                ?
              </div>
            ),
            okType: "primary",
            onOk: () => {
              if (!template || !template.id) {
                return;
              }

              dispatch(setTogglePropId(template.id, "publishingTemplateId"));

              // NOTE: This call here will only initiate the PUBLISH_BEGIN state.
              // The actual update will be handled in useEffect in this component.
              updateCanvasPublishStatus({
                publishCanvasStatus: {
                  type: "templates",
                  status: "UN-PUBLISHING",
                },
              });
            },
          });
          break;

        default:
          break;
      }
    };

  useEffect(() => {
    if (!publishCanvasStatus || !template || !template.id) {
      return;
    }

    const { status } = publishCanvasStatus;

    if (status === "SUCCESS" || status === "FAIL") {
      if (publishingTemplateId !== template.id) {
        return;
      }

      if (status === "SUCCESS") {
        message.success("The template was successfully processed.");
      } else {
        message.error("The template was not able to be processed.");
      }

      dispatch(setTogglePropId(null, "publishingTemplateId"));

      return;
    }

    if (publishingTemplateId === template.id) {
      updateCanvasPublishStatus({
        publishCanvasStatus,
        template: {
          ...template,
          status:
            publishCanvasStatus.status === "UN-PUBLISHING"
              ? "UN-PUBLISHED"
              : "PUBLISHED",
        } as ITemplate,
      });
    }

    // eslint-disable-next-line
  }, [publishCanvasStatus, template, publishingTemplateId]);

  let loadingIndicatorMessage = "";
  if (deletingDataId && deletingDataId === template.id) {
    loadingIndicatorMessage = "Deleting this template...";
  } else if (publishingTemplateId && publishingTemplateId === template.id) {
    loadingIndicatorMessage = "Publishing template...";
  }

  const cardStores = template.stores.map(store => {
    return {
      type: "store",
      value: store,
    };
  });
  const cardTags = template.tags.map(tag => {
    return {
      type: "tag",
      value: tag,
    };
  });

  const tagsArray = [
    {
      type: "state",
      value: template.state,
    },
    ...cardStores,
    ...cardTags,
  ];

  const oemsText = template.oems.join(", ");

  const activeTemplateButtons: ICardButtonObj[] = [
    {
      tooltipProps: {
        title: !isAdmin ? accessDeniedMessage : "Edit Parameters",
      },
      buttonText: "Edit Parameters",
      buttonProps: {
        style: {
          cursor: !isAdmin ? "not-allowed" : "pointer",
        },
        icon: <ControlOutlined style={!isAdmin ? { opacity: 0.3 } : {}} />,
        onClick: onPopoverMenuClick("edit", template, !isAdmin),
      },
    },
    {
      tooltipProps: {
        title: !isAdmin
          ? accessDeniedMessage
          : template.status === "PUBLISHED"
          ? "Unpublish"
          : "Publish",
      },
      buttonText: template.status === "PUBLISHED" ? "Unpublish" : "Publish",
      buttonProps: {
        style: {
          cursor: !isAdmin ? "not-allowed" : "pointer",
        },
        icon:
          template.status === "PUBLISHED" ? (
            <UnpublishOutlined style={!isAdmin ? { opacity: 0.3 } : {}} />
          ) : (
            <FileDoneOutlined style={!isAdmin ? { opacity: 0.3 } : {}} />
          ),
        onClick: onPopoverMenuClick(
          template.status === "PUBLISHED" ? "un-publish" : "publish",
          template,
          !isAdmin,
        ),
      },
    },
    {
      tooltipProps: {
        title: !isAdmin ? accessDeniedMessage : "Duplicate",
      },
      buttonText: "Duplicate",
      buttonProps: {
        style: {
          cursor: !isAdmin ? "not-allowed" : "pointer",
        },
        onClick: onPopoverMenuClick("duplicate", template, !isAdmin),
        icon: <CopyOutlined style={!isAdmin ? { opacity: 0.3 } : {}} />,
      },
    },
    {
      tooltipProps: {
        title: !isAdmin ? accessDeniedMessage : "Archive",
      },
      buttonText: "Archive",
      buttonProps: {
        style: {
          cursor: !isAdmin ? "not-allowed" : "pointer",
        },
        onClick: onPopoverMenuClick("archive", template, !isAdmin),
        icon: <InboxOutlined style={!isAdmin ? { opacity: 0.3 } : {}} />,
      },
    },
  ];

  const archivedTemplateButtons: ICardButtonObj[] = [
    {
      tooltipProps: {
        title: !isAdmin ? accessDeniedMessage : "Delete Forever",
      },
      buttonText: "Delete Forever",
      buttonProps: {
        style: {
          cursor: !isAdmin ? "not-allowed" : "pointer",
        },
        onClick: onPopoverMenuClick("delete", template, !isAdmin),
        icon: <DeleteOutlined style={!isAdmin ? { opacity: 0.3 } : {}} />,
      },
    },
    {
      buttonText: "Restore",
      tooltipProps: {
        title: !isAdmin ? accessDeniedMessage : "Restore",
      },
      buttonProps: {
        style: {
          cursor: !isAdmin ? "not-allowed" : "pointer",
        },
        icon: <ReloadOutlined style={!isAdmin ? { opacity: 0.3 } : {}} />,

        onClick: (event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation();
          if (!isAdmin) return;
          dispatch(setTogglePropId(null, "canvasEditId"));
          if (!setSelectedHeader || !setSelectedTab) {
            return;
          }
          updateTemplate({ ...template, isDeleted: false }, "restoring");
        },
      },
    },
  ];

  const shouldDisableEdit = template.type === "html";

  const [canvas, setCanvas] = useState<fabric.Canvas>();
  const [openPreview, setOpenPreview] = useState<boolean>(false);

  const buttonsObjects: ICardButtonObj[] = [
    {
      tooltipProps: {
        title: !isAdmin
          ? accessDeniedMessage
          : shouldDisableEdit
          ? "HTML template cannot be edited."
          : "Edit V2",
      },
      buttonText: "Edit V2",
      buttonProps: {
        style: {
          cursor: !isAdmin || shouldDisableEdit ? "not-allowed" : "pointer",
        },
        icon:
          !isAdmin || shouldDisableEdit ? (
            <EditFilled style={{ opacity: 0.3 }} />
          ) : (
            <Link
              to={
                `/design-studio/editor/templates/${
                  template.id ?? ""
                }/editor-v2` + search
              }
            >
              <EditFilled />
            </Link>
          ),
      },
    },
    {
      buttonText: "Preview",
      tooltipProps: {
        title: !isAdmin ? accessDeniedMessage : "Preview",
      },
      buttonProps: {
        style: {
          cursor: !isAdmin ? "not-allowed" : "pointer",
        },
        icon: <EyeOutlined style={!isAdmin ? { opacity: 0.3 } : {}} />,
        onClick: event => {
          event.preventDefault();
          event.stopPropagation();
          if (!isAdmin) return;

          // NOTE: There are two mixed logics here.
          //        One for the editor v1 and the other for v2.
          //        The v2 preview will be rendered here in TemplateCard.tsx
          //        The v1 rpeview will be rendered in TemplateList.tsx

          const { canvasJsonUrl } = template;
          if (!canvasJsonUrl) {
            message.error("Unable to preview!");
            return;
          }

          fetchCanvasJson(canvasJsonUrl).then(json => {
            const canvas = new fabric.Canvas(document.createElement("canvas"));
            canvas.loadFromJSON(json, () => {
              setCanvas(canvas);
              setOpenPreview(true);
            });
          });
        },
      },
    },
    ...(selectedHeader !== "DELETED ASSETS"
      ? activeTemplateButtons
      : archivedTemplateButtons),
  ];

  const status = (
    <Space key="category-and-oem-div" align="center">
      <Avatar
        style={{
          backgroundColor: getColorFromConflictStatus(
            template.status === "UN-PUBLISHED"
              ? ConflictStatus.SETUP
              : ConflictStatus.READY,
          ),
          marginTop: "-3px",
        }}
        size={12}
      />
      {template.status === "PUBLISHED" ? "PUBLISHED" : "UN-PUBLISHED"}
    </Space>
  );

  const numOfStamps = template.numOfStamps || 0;

  const badges = [
    <Space key="oems-badge">
      <CarOutlined
        style={{
          marginLeft: "auto",
          marginTop: "4px",
          fontSize: "14px",
        }}
      />
      {template.oems.length === 1 && (
        <Typography.Text>{oemsText}</Typography.Text>
      )}
      {template.oems.length > 1 && (
        <Tooltip title={oemsText}>
          <Typography.Text>{oemsText.split(", ")[0]}...</Typography.Text>
        </Tooltip>
      )}
    </Space>,
    <span key="stamp-count-badge" className="stamp-counter-wrapper">
      <Tooltip
        title={`${numOfStamps} stamp${
          numOfStamps !== 1 ? "s" : ""
        } used in this template`}
        placement="left"
      >
        <Space className="stamp-counter">
          <StampOutlined
            style={{ marginLeft: "auto", marginTop: 4, fontSize: 14 }}
          />
          <span>{`${numOfStamps} Stamp${numOfStamps !== 1 ? "s" : ""}`}</span>
        </Space>
      </Tooltip>
    </span>,
  ];

  const tags = tagsArray.map(tag => tag.value).filter(tag => !!tag);

  return (
    <div data-cy={`template-card-${template.id}`}>
      <Card
        spinProps={{
          tip: loadingIndicatorMessage,
          spinning:
            (deletingDataId ? deletingDataId === template.id : false) ||
            publishingTemplateId
              ? publishingTemplateId === template.id
              : false,
        }}
        title={template.name || "--"}
        imageSrc={
          (template.thumbnailUrl &&
            `${template.thumbnailUrl}?date=${roundTimeStamp(MINUTE)}`) ||
          placeholder
        }
        status={status}
        badges={badges.filter(badge => !!badge)}
        tags={tags.filter(tag => !!tag) as string[]}
        buttonObjects={buttonsObjects.filter(btn => !!btn)}
      />

      {openPreview && canvas && (
        <RenderTemplateProvider config={config}>
          <Preview
            canvas={canvas}
            resource={template}
            openPreview={openPreview}
            onPreviewClose={() => {
              setOpenPreview(false);
            }}
            isDarkBackground={false}
            customAttributes={customFabricAttributes}
            variableAlias={{}}
            disablePlayButton={true}
          />
        </RenderTemplateProvider>
      )}
    </div>
  );
};

export default memo(TemplateCard);
