import {
  TextField,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";

type FieldProps = {
  item: {
    field: string;
    label: string;
    name: string;
    position: string;
    searchable: string;
    status: string;
    type: string;
    values?: string[];
  };
  formValues: MGFormValues;
  handleFieldChange: (field: string, value: string | string[]) => void;
};

export const TextFieldItem = ({
  item,
  formValues,
  handleFieldChange,
}: FieldProps) => (
  <TextField
    key={item.label}
    label={item.label}
    variant="outlined"
    value={formValues[item.field] || ""}
    onChange={e => handleFieldChange(item.field, e.target.value)}
    fullWidth
  />
);

export const MultiSelectItem = ({
  item,
  formValues,
  handleFieldChange,
}: FieldProps) => (
  <FormControl key={item.label} fullWidth>
    <InputLabel id={`${item.label}-label`}>{item.field}</InputLabel>
    <Select
      labelId={`${item.label}-label`}
      multiple
      value={formValues[item.field] as string[]}
      onChange={e => handleFieldChange(item.field, e.target.value as string[])}
      renderValue={selected =>
        typeof selected === "string" ? selected : selected.join(", ")
      }
    >
      {item.values?.map((option: string) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);
