import { LineItem } from "../../types";
import { useDataListURLData } from "shared/components/dataListURL/useDataListURLData";
import { useBeeswaxCampaignsContext } from "../../BeeswaxCampaignsProvider";

export const useLineItems = () => {
  const { draftLineItems } = useBeeswaxCampaignsContext();

  return useDataListURLData<keyof LineItem, LineItem>({
    isError: false,
    isLoading: false,
    data: draftLineItems,
  });
};
