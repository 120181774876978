import {
  Tabs,
  Box,
  Tab,
  Typography,
  Grid,
  Collapse,
  Stack,
  IconButton,
} from "@mui/material";
import { useEditorTabs, EditorTab } from "./editorTabs/useEditorTabs";
import { observer } from "mobx-react-lite";
import { TabPanel } from "./editorTabs/TabPanel";
import { useEditorDrawerContext } from "../../editorDrawerContext/useEditorDrawerContext";
import { ChevronLeftRounded } from "@mui/icons-material";

const a11yProps = (index: EditorTab) => ({
  id: `vertical-tab-${index}`,
  "aria-controls": `vertical-tabpanel-${index}`,
});

type EditorTabsProps = { open?: boolean; setOpen?: (open: boolean) => void };
export const EditorTabs = observer(({ open, setOpen }: EditorTabsProps) => {
  const { tabs, currentTab, setTab } = useEditorTabs();
  const { activeStore } = useEditorDrawerContext();
  const handleChange = (_event: React.SyntheticEvent, newValue: EditorTab) => {
    setTab(newValue);
  };

  return (
    <Grid container wrap="nowrap" sx={{ height: 1, position: "relative" }}>
      <Grid
        item
        sx={{
          height: 1,
          display: "flex",
          borderRight: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          orientation="vertical"
          value={currentTab}
          scrollButtons={true}
          onChange={handleChange}
          TabIndicatorProps={{ sx: { display: "none" } }}
          aria-label="Editor tabs"
          sx={{ pt: 1, position: "sticky", color: "action.active" }}
        >
          {tabs.map(tab => {
            const Icon = tab.Icon;
            return (
              <Tab
                key={tab.label}
                sx={{ px: 0, py: 1 }}
                label={
                  <Stack
                    alignItems="center"
                    spacing={1}
                    sx={{
                      width: 1,
                      height: 1,
                    }}
                  >
                    <IconButton
                      size="small"
                      component="span"
                      sx={{
                        backgroundColor:
                          currentTab === tab.value ? "divider" : "transparent",
                        color: "action.active",
                        transition: "background-color 0.2s ease-in-out",
                        fontSize: 24,
                        borderRadius: 1,
                      }}
                    >
                      <Icon />
                    </IconButton>

                    <Typography
                      variant="body2"
                      sx={{ fontSize: 12, color: "text.primary" }}
                      color="primary"
                    >
                      {tab.label}
                    </Typography>
                  </Stack>
                }
                {...a11yProps(tab.value)}
                value={tab.value}
              />
            );
          })}
        </Tabs>
      </Grid>
      <Grid
        item
        sx={{
          width: 1,
          display: open ? "block" : "none",
          px: 2,

          borderRight: 1,
          borderColor: "divider",
          // The drawer header is 51px tall
          maxHeight: "calc(100vh - 51px)",
          backgroundColor: "background.paper",
          overflowY: "auto",
          alignItems: "stretch",
        }}
      >
        <Collapse
          in={open}
          orientation="horizontal"
          sx={{
            width: 1,
            "& .MuiCollapse-wrapperInner": { width: 1 },
          }}
        >
          <Box sx={{ width: 1 }}>
            <TabPanel tab={currentTab} store={activeStore} />
          </Box>
        </Collapse>
      </Grid>
      <Stack
        alignItems="center"
        justifyContent="center"
        onClick={() => setOpen?.(!open)}
        sx={{
          alignSelf: "center",
          position: "absolute",
          backgroundColor: "background.paper",
          zIndex: 1000,
          right: -10,
          borderTopRightRadius: 12,
          borderBottomRightRadius: 12,
          height: "69px",
          cursor: "pointer",
        }}
      >
        <ChevronLeftRounded sx={{ fontSize: 11 }} />
      </Stack>
    </Grid>
  );
});
