import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Button, Col, Row, Switch, Typography } from "antd";
import { useState } from "react";
import { useAssetBatchesContext } from "../../contexts/AssetBatchesContext";
import { useAssetBatchesValueMappingContext } from "../../contexts/AssetBatchesValueMappingContext";
import styles from "./NavTemplateItems.module.scss";
import { videoCompositionEnabled } from "shared/constants/assetExporter";

export const NavTemplateItems = () => {
  const {
    rows,
    setPreviewLoading,
    previewCount,
    compositions,
    setPreventAutoScroll,
  } = useAssetBatchesContext();

  const { onSelectedRowChange, showVariablesOn, setShowVariablesOn } =
    useAssetBatchesValueMappingContext();

  const [currentIdx, setCurrentIdx] = useState(0);

  const handleChange = (direction: "prev" | "next") => {
    setPreviewLoading(true);
    if (direction === "prev" && currentIdx > 0) {
      const updatedIdx = currentIdx - 1;
      setCurrentIdx(updatedIdx);
      setPreventAutoScroll(true);
      compositions.forEach(composition => {
        onSelectedRowChange(rows[updatedIdx], composition.compositionId);
      });
      setPreviewLoading(false);
      return;
    }

    if (direction === "next" && currentIdx + 1 < previewCount) {
      const updatedIdx = currentIdx + 1;
      setCurrentIdx(updatedIdx);
      setPreventAutoScroll(true);
      compositions.forEach(composition => {
        onSelectedRowChange(rows[updatedIdx], composition.compositionId);
      });
      setPreviewLoading(false);
    }
  };

  const nextDisabled = Boolean(currentIdx + 1 === previewCount);
  const prevDisabled = !Boolean(currentIdx);

  return (
    <>
      <div className={styles.navTemplateItems}>
        <Button
          onClick={() => handleChange("prev")}
          disabled={prevDisabled}
          className={styles.navTemplateButton}
        >
          <ArrowLeftOutlined className={styles.navTemplateArrow} />
        </Button>
        <Typography.Text className={styles.navTemplateItemsText}>
          {`${currentIdx + 1} / ${previewCount} assets`}
        </Typography.Text>
        <Button
          onClick={() => handleChange("next")}
          disabled={nextDisabled}
          className={styles.navTemplateButton}
        >
          <ArrowRightOutlined className={styles.navTemplateArrow} />
        </Button>
      </div>
      {videoCompositionEnabled && (
        <div className={styles.showVariablesContainer}>
          <Row>
            <Col>
              <span>Show variables</span>
            </Col>
            <Col className={styles.switchContainer}>
              <Switch
                size={"small"}
                onChange={setShowVariablesOn}
                checked={showVariablesOn}
              />
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};
