import { Stack } from "@mui/material";
import { observer } from "mobx-react-lite";
import { StoreType } from "screens/designStudio/designStudioV2/types";
import ImageFlip from "./ImageFlip";
import ImageEffectControls from "./ImageEffectsControls";
import ImagePageFit from "./ImagePageFit";
import Crop from "./Crop";

interface EditorControlsProps {
  store: StoreType;
}

const MediaEditor = observer(({ store }: EditorControlsProps) => {
  const element = store.selectedElements[0];
  return (
    <Stack flexDirection="row" alignItems="center" justifyContent="center">
      <ImageFlip element={element} />
      <ImageEffectControls element={element} />
      <ImagePageFit element={element} store={store} />
      <Crop store={store} />
    </Stack>
  );
});

export default MediaEditor;
