import ToolbarTable from "shared/components/toolbarTable/ToolbarTable";
import { ToolbarButton } from "shared/types/adLibrary";
import { useAgentProfiles } from "shared/hooks/salesEnablement/useAgentProfiles";
import { useDataListURLGlobalFilter } from "shared/components/dataListURL/useDataListURLGlobalFilter";
import { useDeleteProfilesPrompt } from "./useDeleteProfilesPrompt";
import useNavigateWithSearch from "shared/hooks/useNavigateWithSearch";

const AgentProfilesToolbar = () => {
  const { selectedItems } = useAgentProfiles();
  const navigate = useNavigateWithSearch();

  const showDeletePrompt = useDeleteProfilesPrompt();

  const toolbarContents: ToolbarButton = {
    New: {
      disabled: false,
      onClick: () => navigate("new"),
      extraInfo: {
        text: "New Agent",
        tooltip: "Create new agent profile",
      },
    },
    Edit: {
      disabled: selectedItems.length !== 1,
      onClick: () => {
        const [item] = selectedItems;
        navigate(item.id);
      },
    },
    Delete: {
      disabled: !selectedItems.length,
      onClick: () => showDeletePrompt(selectedItems.map(({ id }) => id)),
    },
  };

  const { globalFilter, setGlobalFilter } = useDataListURLGlobalFilter();

  return (
    <ToolbarTable
      searchValue={globalFilter}
      onSearch={setGlobalFilter}
      toolbarContents={toolbarContents}
      searchPlaceholder="Search"
      titleTooltip="Search agent profiles by name"
    />
  );
};

export default AgentProfilesToolbar;
