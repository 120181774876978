import {
  IExtendedFabricObject,
  TExtendedVideo,
} from "shared/types/designStudio";
import { TValueMapping } from "../../types";
import { isTextbox, isVideo } from "../../validators";
import {
  replaceStylesWithVariables,
  replaceVariablesWithinTextLines,
} from "screens/assetExporter/feedConfigurationV2/assetBatchDrawer/dynamicText/utils.variable";
import { TComposition, TMediaComposition } from "shared/types/assetExporter";
import { DEFAULT_FPS } from "../../constants";

export const getVideoData = (canvas?: fabric.StaticCanvas | fabric.Canvas) => {
  const layers = canvas?.getObjects().filter(isVideo);
  const background = [canvas?.backgroundImage].filter(isVideo);
  return [...(background ?? []), ...(layers ?? [])].filter(
    Boolean,
  ) as TExtendedVideo[];
};

export const setTargetOpacity = (
  target: IExtendedFabricObject,
  newOpacity: number,
) => target.set({ opacity: newOpacity });

export const renderTextVars = (
  target: IExtendedFabricObject,
  groupedMappings: Record<number, TValueMapping[]>,
  selectedRow: any,
  showVariablesOn: boolean,
) => {
  if (isTextbox(target)) {
    const {
      originalText = undefined,
      originalTextLines = undefined,
      styles: originalStyles,
      templateStyles,
    } = target;
    if (!originalText || !originalTextLines) return;
    const { updatedTextLines, groupedMappings: newGroupedMappings } =
      replaceVariablesWithinTextLines(
        originalTextLines,
        groupedMappings,
        selectedRow,
      );
    const updatedStyles = replaceStylesWithVariables(
      originalTextLines,
      newGroupedMappings,
      selectedRow,
      originalStyles,
    );
    target.set({
      text: showVariablesOn ? updatedTextLines.join("\n") : originalText,
      styles: showVariablesOn ? updatedStyles : templateStyles,
    });
  }
};

export const isCompositionPlaying = (
  startFrame: number,
  endFrame: number,
  currentFrame: number,
) => {
  // Check if the current frame is within the start and end frames of the composition
  return currentFrame >= startFrame && currentFrame <= endFrame;
};

export const endFramesDuration = (
  compositions: TComposition[],
  index: number,
) => {
  return compositions.reduce((acc, comp, idx) => {
    return idx <= index ? acc + comp.duration * DEFAULT_FPS : acc;
  }, 0);
}; // it is the last frame of the current composition

export const startFrameDuration = (
  compositions: TComposition[],
  index: number,
) => {
  return compositions.reduce((acc, comp, idx) => {
    return idx < index ? acc + comp.duration * DEFAULT_FPS : acc;
  }, 0);
}; // it is the first frame of the current composition

export const getSrcMediaComposition = (
  composition: TMediaComposition,
  selectedRow: any,
) => {
  return composition.column
    ? selectedRow[composition.column]
    : composition?.url;
};
