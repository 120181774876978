import { FillAdInfoColumnTitle, FillAdInfoTableData } from "../types";
import { isCallToActionValue } from "./callToActionValuesValidations";
import { isValidUrl } from "./urlValuesValidations";

export const columnTitleTooltipContent: Partial<
  Record<FillAdInfoColumnTitle, string>
> = {
  [FillAdInfoColumnTitle.StoryMedia]: "Story Media Size: 1080x1920",
  [FillAdInfoColumnTitle.InFeedMedia]: "In-Feed media size: 1080x1080",
};

export const hasRowError = (
  rowData: FillAdInfoTableData,
  columnTitle: FillAdInfoColumnTitle,
): boolean => {
  const columnValue = rowData[columnTitle];
  if (!columnValue) return false;

  switch (columnTitle) {
    case FillAdInfoColumnTitle.CTAButton:
      return !isCallToActionValue(columnValue);
    case FillAdInfoColumnTitle.DestinationURL:
      return !isValidUrl(columnValue);
    default:
      return false;
  }
};
