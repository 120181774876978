import { Stack, Typography, Link } from "@mui/material";

type AttributionLinksProps = {
  name: string;
  imgLink: string;
  userLink: string;
};

export const AttributionLinks = ({
  name,
  imgLink,
  userLink,
}: AttributionLinksProps) => {
  const utm = "?utm_source=constellation&utm_medium=referral";
  return (
    <Stack
      alignItems="center"
      className="imgAttribution"
      onClick={e => e.stopPropagation()}
      sx={{
        position: "absolute",
        bottom: 0,
        padding: 1,
        visibility: "hidden",
        color: "white",
      }}
    >
      <Typography sx={{ fontSize: 10 }} align="center">
        Photo by{" "}
        <Link href={userLink + utm} target="_blank" rel="noopener">
          {name}
        </Link>{" "}
        on{" "}
        <Link href={imgLink + utm} target="_blank" rel="noopener">
          Unsplash
        </Link>
        .
      </Typography>
    </Stack>
  );
};
