import { setupEditableCells } from "shared/components/dataList/setupEditableCells";
import useResizableTable from "shared/hooks/useResizableTable";
import { IAd } from "shared/types/adLibrary";
import { AdTableColumn } from "../../adCompleteImportDrawer/adImportTable/adImportTableContainer/columns";

type UseAdImportTableComponentsParams = {
  setAd: (adId: string, ad: IAd) => void;
  columns: AdTableColumn[];
};

export const useAdImportTableComponents = ({
  columns,
  setAd,
}: UseAdImportTableComponentsParams) => {
  const handleSave = (ad: IAd) => setAd(ad.id, ad);
  const { components: resizableComponents, resizableColumns } =
    useResizableTable<IAd>(columns);

  const { components: editableComponents, columns: editableColumns } =
    setupEditableCells({
      columns: resizableColumns ?? [],
      handleSave,
    });

  const components = {
    ...resizableComponents,
    ...editableComponents,
  };

  return {
    components,
    editableComponents,
    editableColumns,
    resizableComponents,
  };
};
