import { DataListURLProvider } from "shared/components/dataListURL/dataListURLContext";
import Table from "./brochureManagement/Table";
import { fields } from "./brochureManagement/fields";

const BrochureManagement = () => {
  return (
    <DataListURLProvider fields={fields}>
      <Table />
    </DataListURLProvider>
  );
};

export default BrochureManagement;
