import { useMemo } from "react";
import { MarketingMaterial } from "shared/types/marketingMaterials";
import { SPVariables, TemplateFile } from "shared/types/salesEnablement";
import { LayerData, useLayersWithData } from "./useLayersWithData";
import { getPageLayers, getVariables } from "utils/helpers.salesEnablement";

export function useIdmlData({
  file,
  material,
}: {
  file?: TemplateFile;
  material?: Partial<MarketingMaterial>;
}): LayerData {
  const { getLayersWithData } = useLayersWithData();
  const data = useMemo<LayerData["data"]>(() => {
    if (!fileExists(file)) return {};

    const pageLayers = getPageLayers(file);

    if (!pageLayers) return {};

    return getLayersWithData({
      layers: pageLayers,
      material,
    });
  }, [file, getLayersWithData, material]);

  const variables: SPVariables | undefined = useMemo(() => {
    if (!fileExists(file) || !material?.fields) return undefined;
    return getVariables(material);
  }, [file, material]);

  return { data, variables };
}

const fileExists = (file?: TemplateFile) => {
  return file?.status === "done";
};
