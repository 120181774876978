import { useCallback } from "react";
import { TLayerItem } from "screens/designStudio/editor.hooks/useLayers";
import { ExtendedObjectType } from "shared/types/designStudio";

export default () => {
  const onLayerItemClick = useCallback(
    (layer: TLayerItem, updater: () => void) =>
      (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();

        // background object cannot be selected
        const isSelectableLayerType = !(
          ["canvas_area", "canvas_bg"] as ExtendedObjectType[]
        ).includes(layer.type);

        const isLocked = !layer.original.selectable;

        if (!isSelectableLayerType || isLocked) return;

        updater();
      },
    [],
  );

  return {
    onLayerItemClick,
  };
};
