import { PropsWithChildren } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useCheckPerms } from "shared/hooks/useCheckPerms";

type Props = {
  permissions: string[];
  redirectPath: string;
};

export const PermissionRoute = ({
  children,
  permissions,
  redirectPath,
}: PropsWithChildren<Props>) => {
  if (!useCheckPerms(permissions)) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? <>{children}</> : <Outlet />;
};
