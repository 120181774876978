import Title from "antd/lib/typography/Title";
import style from "./TemplateVersionsDrawer.module.scss";

const TemplateVersionsHeader = () => {
  return (
    <div className={style.drawer}>
      <div className={style.title}>
        <Title level={4} className={style.h3}>
          Version History
        </Title>
      </div>
    </div>
  );
};

export default TemplateVersionsHeader;
