import { IDataListURLFields } from "shared/components/dataListURL/types";
import { BrochureRequest } from "../../shared/types/brochureManagement";
import { DelimitedArrayParam, StringParam } from "use-query-params";
import { compareStringBy, onFilterBy } from "utils/helpers.table";
import {
  checkArrayIncludes,
  checkDateMatch,
  formatDateValue,
} from "utils/helpers";
import { isString } from "lodash";

const dateFilterFn =
  (key: keyof BrochureRequest) => (value: string, record: BrochureRequest) => {
    const date = record[key];

    if (!isString(date)) return false;

    return checkDateMatch(value?.toString(), new Date(date).getTime());
  };

const displayDate = (value: string) =>
  value.split(" ").map(formatDateValue).join(" - ");

export const fields: IDataListURLFields<
  keyof BrochureRequest,
  BrochureRequest
> = {
  status: {
    filterFn: (value, record) => checkArrayIncludes(value, record.status),
    globalFilterFn: onFilterBy("status", {
      matchMode: "includes",
      caseInsensitive: true,
    }),
    sorterFn: compareStringBy("status"),
    queryParamConfigFilter: DelimitedArrayParam,
  },
  planId: {
    filterFn: (value, record) => checkArrayIncludes(value, record.planId),
    globalFilterFn: onFilterBy("planId", {
      matchMode: "includes",
      caseInsensitive: true,
    }),
    sorterFn: compareStringBy("planId"),
    queryParamConfigFilter: DelimitedArrayParam,
  },
  productLine: {
    filterFn: (value, record) => checkArrayIncludes(value, record.productLine),
    globalFilterFn: onFilterBy("productLine", {
      matchMode: "includes",
      caseInsensitive: true,
    }),
    sorterFn: compareStringBy("productLine"),
    queryParamConfigFilter: DelimitedArrayParam,
  },
  companyName: {
    filterFn: (value, record) => checkArrayIncludes(value, record.companyName),
    globalFilterFn: onFilterBy("companyName", {
      matchMode: "includes",
      caseInsensitive: true,
    }),
    sorterFn: compareStringBy("companyName"),
    queryParamConfigFilter: DelimitedArrayParam,
  },
  createdAt: {
    filterFn: dateFilterFn("createdAt"),
    sorterFn: compareStringBy("createdAt"),
    queryParamConfigFilter: StringParam,
    display: displayDate,
  },
  createdBy: {
    filterFn: (value, record) => checkArrayIncludes(value, record.createdBy),
    globalFilterFn: onFilterBy("createdBy", {
      matchMode: "includes",
      caseInsensitive: true,
    }),
    sorterFn: compareStringBy("createdBy"),
    queryParamConfigFilter: DelimitedArrayParam,
  },
  state: {
    filterFn: (value, record) => checkArrayIncludes(value, record.state),
    globalFilterFn: onFilterBy("state", {
      matchMode: "includes",
      caseInsensitive: true,
    }),
    sorterFn: compareStringBy("state"),
    queryParamConfigFilter: DelimitedArrayParam,
  },
  productSeries: {
    filterFn: (value, record) =>
      checkArrayIncludes(value, record.productSeries),
    globalFilterFn: onFilterBy("productSeries", {
      matchMode: "includes",
      caseInsensitive: true,
    }),
    sorterFn: compareStringBy("productSeries"),
    queryParamConfigFilter: DelimitedArrayParam,
  },
};

export const fieldKeys = Object.keys(fields) as Array<keyof typeof fields>;
