import { useMemo } from "react";
import { useCampaignPlanner } from "./CampaignPlannerContext";
import { useParams } from "react-router-dom";
import { DetailAdList } from "./shared/DetailAdList";
import { unionBy, xorBy } from "lodash";
import { IAd, ToolbarButton } from "shared/types/adLibrary";
import {
  CopyOutlined,
  EditOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { toCampaignPlannerAd } from "./utils";
import { Messages } from "./shared/messages";
import { getPlannerInstanceColumns } from "./CampaignPlannerInstanceDetail/PlannerInstanceColumns";
import { longAlert } from "utils/antd/longAlert/longAlert";
import produce from "immer";
import { CampaignAdStatus } from "shared/types/campaignPlanner";
import { Modal } from "antd";
import { DataListURLProvider } from "shared/components/dataListURL/dataListURLContext";
import { IAdFields, fields } from "screens/adLibrary/fields";

export const CampaignPlannerInstanceDetail = () => {
  const { plannerId, instanceId } = useParams<{
    plannerId: string;
    instanceId: string;
  }>();
  const { getPlanner, updateAdInstanceStatus, updateInstanceAds } =
    useCampaignPlanner();

  const { instance, planner } = useMemo(() => {
    if (!plannerId || !instanceId) return {};
    const planner = getPlanner(plannerId);
    if (!planner) return {};
    const currentInst = planner?.instances.find(inst => inst.id === instanceId);
    return { instance: currentInst, planner };
  }, [plannerId, instanceId, getPlanner]);

  const columns = useMemo(() => {
    if (!instance || !plannerId) return [];
    return getPlannerInstanceColumns(
      instance,
      plannerId,
      updateAdInstanceStatus,
    );
  }, [instance, plannerId, updateAdInstanceStatus]);

  if (!instance || !planner) return null;

  const onDeleteClick = (adsToDelete: IAd[]) => {
    if (!plannerId) return;
    const updatedInstance = {
      ...instance,
      adShells: xorBy(
        instance.adShells,
        adsToDelete.map(ad => toCampaignPlannerAd(ad, instance.id)),
        "id",
      ),
    };
    updateInstanceAds(updatedInstance, plannerId, () =>
      longAlert({
        type: "success",
        header: Messages.removeInstanceAds(adsToDelete.length),
      }),
    );
  };

  const onAddAds = (ads: IAd[]) => {
    if (!plannerId) return;
    const updatedInstance = {
      ...instance,
      adShells: unionBy(
        instance.adShells,
        ads.map(ad => toCampaignPlannerAd(ad, instance.id)),
        "id",
      ),
    };
    updateInstanceAds(updatedInstance, plannerId, () =>
      longAlert({
        type: "success",
        header: Messages.addInstanceAds(ads.length),
      }),
    );
  };

  const onResetClick = (ads: IAd[]) => {
    if (!plannerId) return;

    Modal.confirm({
      title: "Are You Sure?",
      content:
        "The load status of all selected ads in this instance will be set to Pending",
      okButtonProps: {
        style: {
          backgroundColor: "#FF4D4F",
          borderColor: "#FF4D4F",
          color: "white",
        },
      },
      okType: "danger",
      cancelText: "No",
      okText: "Reset",
      onOk: () => {
        const updatedInstance = produce(instance, draft => {
          ads.forEach(ad => {
            draft.adStatuses[ad.id] = CampaignAdStatus.NEVER_LOADED;
          });
        });

        updateInstanceAds(updatedInstance, plannerId, () =>
          longAlert({
            type: "success",
            header: Messages.resetInstanceAds(ads.length),
          }),
        );
      },
    });
  };

  const generateTableActions: (
    record?: IAd,
    onEditClick?: (record: IAd) => void,
    onDuplicateClick?: (record: IAd) => void,
  ) => ToolbarButton = (record, onEditClick, onDuplicateClick) => ({
    Edit: {
      onClick: () => {
        record && onEditClick?.(record);
      },
      showInInlineMenu: true,
      showInContextMenu: true,
      icon: <EditOutlined />,
    },
    Delete: {
      onClick: (): void => onDeleteClick(record ? [record] : []),
      showInInlineMenu: true,
      showInContextMenu: true,
      disabled: record?.source == planner.name,
      extraInfo: {
        title: "Remove from planner instance",
        icon: <MinusCircleOutlined style={{ fill: "red" }} />,
        tooltip: "Remove from planner instance",
      },
      icon: <MinusCircleOutlined style={{ fill: "red" }} />,
    },
    Duplicate: {
      onClick: () => {
        record && onDuplicateClick?.(record);
      },
      showInInlineMenu: true,
      showInContextMenu: true,
      extraInfo: {
        title: "Duplicate",
        icon: <CopyOutlined />,
      },
      icon: <CopyOutlined />,
    },
  });

  return (
    <DataListURLProvider<IAdFields, IAd> fields={fields}>
      <DetailAdList
        ads={instance.adShells}
        onDeleteClick={onDeleteClick}
        onResetClick={onResetClick}
        onAddAds={onAddAds}
        extraColumns={columns}
        instance={instance}
        plannerId={plannerId}
        generateTableActions={generateTableActions}
      />
    </DataListURLProvider>
  );
};
