import { IDataListURLFields } from "shared/components/dataListURL/types";
import { DelimitedArrayParam, StringParam } from "use-query-params";
import {
  NormalizedDisclosure,
  NormalizedDisclosureKey,
} from "shared/types/salesEnablement";
import {
  onFilter,
  onSort,
  onFilterProduct,
  onFilterArray,
  displayProductName,
} from "./utils/helpers.table";

export const fields: IDataListURLFields<
  NormalizedDisclosureKey,
  NormalizedDisclosure
> = {
  name: {
    filterFn: onFilter("name"),
    sorterFn: onSort("name"),
    queryParamConfigFilter: StringParam,
  },
  products: {
    filterFn: onFilterProduct,
    sorterFn: onSort("products"),
    queryParamConfigFilter: DelimitedArrayParam,
    display: displayProductName,
  },
  locations: {
    filterFn: onFilterArray("locations"),
    sorterFn: onSort("locations"),
    queryParamConfigFilter: DelimitedArrayParam,
  },
  productTypes: {
    filterFn: onFilterArray("productTypes"),
    sorterFn: onSort("productTypes"),
    queryParamConfigFilter: DelimitedArrayParam,
  },
  availableLanguages: {
    filterFn: onFilterArray("availableLanguages"),
    sorterFn: onSort("availableLanguages"),
    queryParamConfigFilter: StringParam,
  },
  disclosures: {
    filterFn: onFilter("disclosures"),
    sorterFn: onSort("disclosures"),
    queryParamConfigFilter: StringParam,
  },
};

export const fieldKeys = Object.keys(fields) as Array<keyof typeof fields>;
