import {
  ConditionalGroupLayer,
  ConditionalSet,
  Layer,
  Option,
  OptionGroupLayer,
  OtherGroupLayer,
  Template,
  TemplateFile,
  TextLayer,
} from "shared/types/salesEnablement";
import {
  isConditionalGroupLayer,
  isGroupLayer,
  isOptionGroupLayer,
  isOtherGrouplayer,
  isTextLayer,
} from "./fileDrawer/utils";

export const toOtherGroupLayer = (layer: Layer): OtherGroupLayer => {
  if (!isTextLayer(layer)) throw new Error("Must be text layer");

  return {
    ...layer,
    type: "group",
    subLayers: [],
    orgLayer: { ...layer },
    source: "manual",
  };
};

export const toTextLayer = (layer: Layer): TextLayer => {
  if (!isGroupLayer(layer)) throw new Error("Must be group layer");

  const { orgLayer } = layer;
  if (!orgLayer) throw new Error("orgLayer must be defiend");

  const { id, name } = orgLayer;
  return {
    id,
    name,
    type: "text",
  };
};

export const addSubLayer = (
  layer: Layer,
  targetLayer: Layer,
  value?: string,
) => {
  if (isGroupLayer(layer) && layer.smartVariable === "options") {
    return {
      ...layer,
      value,
      subLayers: [],
    };
  }
  if (!isOtherGrouplayer(layer) || !isTextLayer(targetLayer)) return layer;
  const layerExists = layer.subLayers.some(sl => sl.id === targetLayer.id);
  return {
    ...layer,
    value,
    subLayers: layerExists
      ? layer.subLayers
      : [...layer.subLayers, targetLayer],
  };
};

export const toConditionalGroupLayer = (
  layer: Layer,
  conditionalSet?: ConditionalSet,
): ConditionalGroupLayer => {
  if (!isConditionalGroupLayer(layer))
    throw new Error("Must be conditional group layer");

  if (!conditionalSet) throw new Error("Conditional set is missing");
  return {
    ...layer,
    conditionalSet,
  };
};

export const toOptionGroupLayer = (
  layer: Layer,
  options?: Option[],
): OptionGroupLayer => {
  if (!isOptionGroupLayer(layer)) throw new Error("Must be option group layer");

  if (!options) throw new Error("Missing options");

  return {
    ...layer,
    options,
  };
};

export const validate = (
  possibleTemplate: Partial<Template>,
): { status: "error" | "success"; message: string } => {
  const fields: (keyof Template)[] = [
    "name",
    "files",
    "createdBy",
    "lastUpdatedBy",
  ];

  const isValid = fields.every(field => field in possibleTemplate);
  if (!isValid)
    return {
      status: "error",
      message: `Missing required fields from template: ${fields
        .filter(field => !(field in possibleTemplate))
        .join(", ")}`,
    };

  const { files } = possibleTemplate;
  if (!files || !Object.values(files).length)
    return {
      status: "error",
      message: "Missing at least 1 file from the template",
    };

  const fileFields: (keyof TemplateFile)[] = ["id", "pages", "type", "id"];

  const fileErrors = Object.entries(files).reduce((acc, [lang, file]) => {
    if (!file) return acc;

    const hasAllFields = fileFields.every(field => !(field in file));
    if (hasAllFields) return acc;

    const labels: Record<typeof lang, string> = {
      en: "english",
      es: "spanish",
    };
    const prefix = acc.length
      ? `, and from ${labels[lang]} file: `
      : `from ${labels[lang]} file: `;

    return (
      acc +
      `${prefix}${fileFields.filter(field => !(field in file)).join(", ")}`
    );
  }, "");

  if (!fileErrors.length)
    return {
      status: "error",
      message: `Missing required fields from file: ${fileErrors}`,
    };

  return {
    status: "success",
    message: "Valid template",
  };
};
