import { notification } from "antd";
import { ArgsProps as NotificationProps } from "antd/lib/notification";
import {
  useCurrentSession,
  useSetCurrentSession,
} from "screens/adLibrary/adLoadV2/hooks/useCurrentSession";
import {
  AdProtoDraft,
  EAElementDest,
} from "screens/adLibrary/facebookUtils/adProto.types";
import { AdType } from "screens/adLibrary/facebookUtils/types";
import { useAds } from "screens/adLibrary/shared/hooks/useAds";
import { useFetchInstantExperiences } from "shared/hooks/adLibrary/useFetchInstantExperiences";
import { useFetchInfiniteDealers } from "shared/hooks/useFetchDealers";
import {
  IAd,
  IInstantExperience,
  InstantExperienceElementStyle,
} from "shared/types/adLibrary";
import { AccountUrl, IAccount } from "shared/types/accountManagement";
import { dedupeBy, isTruthy } from "utils/helpers.array";

export const useSelectStore = () => {
  const { setSession } = useSetCurrentSession();
  const { session } = useCurrentSession();
  const { dealers: stores } = useFetchInfiniteDealers();
  const { originalData: ads } = useAds();
  const { data: instantExperiences = [] } = useFetchInstantExperiences();

  const onSelectStore = (newStoreName: string) => {
    const validation = validateStore({
      newStoreName,
      selectedStoreNames: session.data.selectedStoreNames,
      stores,
    });

    if (!validation.isValid) {
      notification.warn(validation.notificationProps);
      return;
    }

    setSession(prevSession => {
      prevSession.data.selectedStoreNames.push(newStoreName);

      const newStore = stores.find(store => store.dealer_name === newStoreName);
      const { adIdsToLoad } = prevSession.data;

      const newAdProtoDrafts = adIdsToLoad.map(adId => {
        const ad = ads.find(adItem => adItem.id === adId);
        if (!ad || !newStore) return;

        return getAdProtoDraft({
          ad,
          store: newStore,
          instantExperiences,
        });
      });

      prevSession.data.adProtoDrafts.push(...newAdProtoDrafts.filter(isTruthy));
    });
  };

  const onDeselectStore = (storeNameToRemove: string): void => {
    setSession(prevSession => {
      prevSession.data.selectedStoreNames =
        prevSession.data.selectedStoreNames.filter(
          storeName => storeName !== storeNameToRemove,
        );

      prevSession.data.adProtoDrafts = prevSession.data.adProtoDrafts.filter(
        adProtoDraft => adProtoDraft.storeName !== storeNameToRemove,
      );
    });
  };

  return {
    onSelectStore,
    onDeselectStore,
  };
};

const getAdProtoDraft = ({
  ad,
  store,
  instantExperiences,
}: {
  ad: IAd;
  store: IAccount;
  instantExperiences: IInstantExperience[];
}): AdProtoDraft | undefined => {
  const { inputParameters, visuals } = ad;
  const urlLabels = store.labels ?? [];
  const adType = ad.type;

  switch (adType) {
    case AdType.Video:
    case AdType.Still: {
      return {
        id: ad.id,
        type: adType,
        storeName: store.dealer_name,
        dests: [],
        destinationUrl: getUrlFromLabel(
          inputParameters.destinationUrl,
          urlLabels,
        ),
        displayUrl: getUrlFromLabel(ad?.visuals.displayUrl, urlLabels),
        callToAction: ad.visuals.ctaButtonText,
      };
    }
    case AdType.Carousel: {
      return {
        id: ad.id,
        type: adType,
        storeName: store.dealer_name,
        callToAction: ad.visuals.ctaButtonText,
        dests: [],
        cards:
          ad.visuals?.cards?.map(card => ({
            destinationUrl: getUrlFromLabel(card.destinationUrl, urlLabels),
            additionalPlacements: card.additionalPlacements?.map(placement => ({
              destinationUrl: getUrlFromLabel(
                placement.destinationUrl,
                urlLabels,
              ),
            })),
          })) ?? [],
      };
    }
    case AdType.InstantExperience:
    case AdType.Collection: {
      const instantExperience = instantExperiences.find(
        ie => ie.id === visuals.destination?.instantExperienceId,
      );
      if (!instantExperience) {
        throw new Error(
          `Instant experience with id ${visuals.destination?.instantExperienceId} not found`,
        );
      }

      return {
        id: ad.id,
        type: adType,
        storeName: store.dealer_name,
        dests: [],
        convertToVideo: false,
        elementDests: getInstExpDests({
          instantExperience,
          instantExperiences,
          adId: ad.id,
          store,
        }).filter(dedupeBy("elementId")),
      };
    }

    case AdType.DPA:
    case AdType.AIA: {
      return {
        id: ad.id,
        type: adType,
        storeName: store.dealer_name,
        dests: [],
        callToAction: ad.visuals.ctaButtonText,
        destinationUrl: getUrlFromLabel(
          inputParameters.destinationUrl,
          urlLabels,
        ),
        displayUrl: getUrlFromLabel(ad?.visuals.displayUrl, urlLabels),
        destType: "productSet",
      };
    }
    default: {
      const _exhaustiveCheck: AdType.DPA | AdType.FTA = adType;

      throw Error(`Unknown ad format: ${_exhaustiveCheck}`);
    }
  }
};

const getInstExpDests = ({
  instantExperience,
  instantExperiences,
  store,
  adId,
}: {
  instantExperience: IInstantExperience;
  instantExperiences: IInstantExperience[];
  store: IAccount;
  adId: string;
}): EAElementDest[] => {
  const urlLabels = store.labels ?? [];

  return (
    instantExperience.body_elements?.flatMap<EAElementDest>(element => {
      switch (element.element_type) {
        case "PHOTO":
        case "BUTTON":
        case "FOOTER": {
          const targetElement =
            element.element_type === "FOOTER"
              ? element.child_elements[0]
              : element;

          if (
            targetElement.element_type === "PHOTO" &&
            targetElement.style &&
            targetElement.style !== InstantExperienceElementStyle.FIT_TO_WIDTH
          ) {
            return [];
          }

          const destinationIeId =
            targetElement.destination?.instantExperienceId;
          const foundInstExt = instantExperiences.find(
            ie => ie.id === destinationIeId,
          );

          return foundInstExt
            ? getInstExpDests({
                instantExperience: foundInstExt,
                instantExperiences,
                store,
                adId,
              })
            : {
                elementId: targetElement.id,
                destType: "url",
                destinationUrl: getUrlFromLabel(
                  targetElement.destination?.urlLabel,
                  urlLabels,
                ),
              };
        }
        case "CAROUSEL": {
          return element.child_elements.flatMap<EAElementDest>(
            childPhotoElement => {
              const destEverythingAdId =
                childPhotoElement.destination?.instantExperienceId;
              const foundInstExt =
                destEverythingAdId &&
                instantExperiences.find(ie => ie.id === destEverythingAdId);

              return foundInstExt
                ? getInstExpDests({
                    instantExperience: foundInstExt,
                    instantExperiences,
                    store,
                    adId,
                  })
                : {
                    elementId: childPhotoElement.id,
                    destType: "url",
                    destinationUrl: getUrlFromLabel(
                      childPhotoElement.destination?.urlLabel,
                      urlLabels,
                    ),
                  };
            },
          );
        }
        case "ELEMENT_GROUP": {
          return {
            elementId: element.id,
            destType: "productSet",
            productCatalogId: store.details.facebook.fbCatalogId,
            productSetId: undefined,
          };
        }
        default: {
          return [];
        }
      }
    }) ?? []
  );
};

export const getUrlFromLabel = (
  urlLabel: string | undefined,
  urlLabels: AccountUrl[] | undefined,
) => {
  if (!urlLabel) return "";

  const foundLabel = urlLabels?.find(
    label => label.name.toLowerCase() === urlLabel?.toLowerCase(),
  );

  return foundLabel?.url ?? urlLabel ?? "";
};

type ValidateStore =
  | {
      isValid: true;
    }
  | {
      isValid: false;
      notificationProps: NotificationProps;
    };

const validateStore = ({
  newStoreName,
  selectedStoreNames,
  stores,
}: {
  newStoreName: string;
  selectedStoreNames: string[];
  stores: IAccount[];
}): ValidateStore => {
  const selectedStores = stores.filter(store =>
    selectedStoreNames.includes(store.dealer_name),
  );
  const newStore = stores.find(store => store.dealer_name === newStoreName);
  if (!newStore) {
    throw new Error(`Store ${newStoreName} not found.`);
  }

  const missingFbAccountValidation = validateMissingFbAccount({ newStore });
  if (!missingFbAccountValidation.isValid) {
    return missingFbAccountValidation;
  }

  const duplicatedFbAccountValidation = validateDuplicatedFbAccount({
    newStore,
    selectedStores,
  });
  if (!duplicatedFbAccountValidation.isValid) {
    return duplicatedFbAccountValidation;
  }

  return {
    isValid: true,
  };
};

const validateMissingFbAccount = ({
  newStore,
}: {
  newStore: IAccount;
}): ValidateStore => {
  const newStoreFbAccountId = newStore?.details.facebook.fbAccountId;
  if (!newStoreFbAccountId) {
    return {
      isValid: false,
      notificationProps: {
        message: "Missing Account",
        description: `The Facebook Account ID for ${newStore.dealer_name} is missing.`,
      },
    };
  }

  return {
    isValid: true,
  };
};

const validateDuplicatedFbAccount = ({
  newStore,
  selectedStores,
}: {
  newStore: IAccount;
  selectedStores: IAccount[];
}): ValidateStore => {
  const newStoreFbAccountId = newStore?.details.facebook.fbAccountId;
  const selectedStoresFbAccountIds = selectedStores
    .map(store => store.details.facebook.fbAccountId)
    .filter(isTruthy);
  const storeWithSameFbAccount = selectedStoresFbAccountIds.find(
    account => account === newStoreFbAccountId,
  );

  if (storeWithSameFbAccount) {
    return {
      isValid: false,
      notificationProps: {
        message: "Duplicated Account",
        description: `The Facebook Account ID for ${newStore.dealer_name} is also being used in another selected store.`,
      },
    };
  }

  return {
    isValid: true,
  };
};
