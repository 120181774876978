import { notification } from "antd";
import { orderBy, uniq } from "lodash";
import { queryClient } from "queryClient";
import { useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import APIs from "services";
import {
  Language,
  Multilingual,
  ProductDetails,
} from "shared/types/salesEnablement";
import { sortByPrimaryLanguage } from "./utils";
import { QUERY_KEYS } from "screens/admin/products/utils/constants";

const { fetchProducts } = QUERY_KEYS;

export const useFetchProducts = () => {
  return useQuery({
    queryKey: fetchProducts,
    queryFn: APIs.services.salesEnablement.getProducts,
  });
};

export const useCreateProduct = () => {
  return useMutation({
    mutationFn: APIs.services.salesEnablement.createProduct,
    onSettled: () => {
      queryClient.invalidateQueries([fetchProducts]);
    },
    onError: () => {
      notification.error({
        message: "Error",
        description: "Something went wrong creating the product.",
      });
    },
  });
};

export const useDeleteProduct = () => {
  return useMutation({
    mutationFn: APIs.services.salesEnablement.deleteProduct,
    onSettled: () => {
      queryClient.invalidateQueries([fetchProducts]);
    },
    onError: () => {
      notification.error({
        message: "Error",
        description: "Something went wrong deleting the product.",
      });
    },
  });
};

export const useUpdateProduct = () => {
  return useMutation({
    mutationFn: APIs.services.salesEnablement.updateProduct,
    onSettled: () => {
      queryClient.invalidateQueries([fetchProducts]);
    },
    onError: () => {
      notification.error({
        message: "Error",
        description: "Something went wrong updating the product.",
      });
    },
  });
};

export const useProducts = () => {
  const { data, isLoading } = useFetchProducts();
  const products = useMemo(() => data?.result || [], [data]);
  const productTypes = useMemo(
    () => uniq(products.map(product => product.productType)),
    [products],
  );

  const productsTable = useMemo(
    () =>
      orderBy(
        products.map(product => ({
          ...product,
          language: sortByPrimaryLanguage(
            Object.keys(product.details),
            product.primaryLanguage,
          ),
          name:
            product.details[
              product.primaryLanguage as keyof Multilingual<ProductDetails>
            ]?.name || "",
          description:
            product.details[
              product.primaryLanguage as keyof Multilingual<ProductDetails>
            ]?.description || "",
        })),
        ["updatedAt"],
        "desc",
      ),
    [products],
  );

  const productsOptions = useMemo(() => {
    const defaultOption = {
      label: "None",
      value: "none",
    };

    const allProducts = products.map(product => ({
      label:
        product.details[
          product.primaryLanguage as keyof Multilingual<ProductDetails>
        ]?.name || product.id,
      value: product.id,
    }));
    return [defaultOption, allProducts].flat();
  }, [products]);

  return { products, isLoading, productTypes, productsTable, productsOptions };
};

export const useProduct = (productId?: string) => {
  const { data, isLoading } = useFetchProducts();
  const product = useMemo(
    () => data?.result?.find(({ id }) => id === productId),
    [data, productId],
  );

  const languages: Language[] | undefined = useMemo(() => {
    if (product) {
      // Workaround to enforce Language type on keys, as Object.keys returns string[].
      return Object.keys(product.details).flatMap(key =>
        key === "en" || key === "es" ? key : [],
      );
    }

    return undefined;
  }, [product]);

  return { product, isLoading, languages };
};

export const useProductsByType = (productTypes: string[]) => {
  const { products, isLoading } = useProducts();
  const productsByType = useMemo(
    () =>
      products.filter(product => productTypes?.includes(product.productType)),
    [products, productTypes],
  );

  return { productsByType, isLoading };
};
