import { useState } from "react";
import { raise } from "utils/errorMessage";
import { getTextareaCaretCoords } from "../../editorDrawerContext/helpers.positioning";
import { getPolotnoTextarea } from "../../editorDrawerContext/helpers.text";
import { TextLayerElement } from "screens/designStudio/designStudioV2/types";
import { StoreType } from "polotno/model/store";
import { getSelectedElements, isGroupElement, isTextElement } from "./utils";

export type AnchorPosition = {
  top: number;
  left: number;
  cursor: number;
  element?: TextLayerElement;
} | null;

export const useTextVariableInsert = (store: StoreType | null) => {
  const [anchorPosition, setAnchorPosition] = useState<AnchorPosition>(null);

  const openTextVariableMenu = () => {
    if (!store) return;
    const [element] = getSelectedElements(store);
    const textAreaElement =
      getPolotnoTextarea() ?? raise("Couldn't retrieve text element");

    const elementToUse = isGroupElement(element)
      ? element.children.find(
          childEl => childEl.type === "text" && childEl._editModeEnabled,
        )
      : element;

    if (!isTextElement(elementToUse)) return;

    setAnchorPosition({
      cursor: textAreaElement.selectionStart,
      element: elementToUse,
      ...getTextareaCaretCoords(textAreaElement),
    });
  };

  const closeTextVariableMenu = () => setAnchorPosition(null);

  return {
    openTextVariableMenu,
    anchorPosition,
    closeTextVariableMenu,
  };
};
