import moment from "moment";
import { subDays, isAfter } from "date-fns";

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

/**
 * Gets an string with the duration in minutes and seconds between two dates,
 * in case the duration is less than a minutes, it will return just the seconds.
 * @param startDate
 * @param stopDate
 * @returns The duration in minutes and seconds between the two dates
 */
export const getDuration = (startDate: string, stopDate?: string) => {
  const duration = moment.duration(moment(stopDate).diff(startDate));
  const durationMinutes = duration.minutes();
  const durationSeconds = duration.seconds();
  const durationString = `Duration: ${
    durationMinutes > 0 ? durationMinutes + "m " : ""
  }${durationSeconds}s`;
  return durationString;
};

export const transformMillisecondsToSeconds = (value: number) => {
  return value / 1000;
};

export const transformSecondsToMilliseconds = (value: number) => {
  return value * 1000;
};

export const isWithinDays = (date: string | number, days: number) => {
  return isAfter(new Date(date), subDays(new Date(), days));
};
