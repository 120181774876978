import { useContext } from "react";
import { editorDrawerContext } from "../EditorDrawerContext";

export const useEditorDrawerContext = () => {
  const ctx = useContext(editorDrawerContext);

  if (!ctx) {
    throw new Error(
      "useEditorDrawerContext must be used within an EditorDrawerContextProvider",
    );
  }
  return ctx;
};
