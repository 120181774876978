import { Typography } from "antd";

export const LogoInstructionText = ({ disabled }: { disabled?: boolean }) => {
  return (
    <div style={{ marginBottom: 8 }}>
      <Typography.Text
        style={{
          fontWeight: 400,
          color: "rgba(0, 0, 0, 0.45)",
          fontSize: 12,
        }}
      >
        Please provide a .png file with a transparent background (
        <Typography.Link
          target="_blank"
          disabled={disabled}
          href="https://constellation-aflac.zendesk.com/hc/en-us/articles/26260402648084-Logo-Requirements-and-Finding-Transparent-Logos"
        >
          {"instructions here"}
        </Typography.Link>
        ).
        <br />
        {`Minimum recommended image dimension is 3/4" (60px wide).`}
      </Typography.Text>
    </div>
  );
};
