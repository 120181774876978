import { fabric } from "fabric";

export const getDummyTarget = (canvas: fabric.Canvas | undefined) => {
  return new fabric.Rect({
    left: 0,
    top: 0,
    width: canvas?.vptCoords?.br?.x,
    height: canvas?.vptCoords?.br?.y,
  });
};

export const getVideoImage = (videoEle: HTMLVideoElement) => {
  return new fabric.Image(videoEle, {
    left: 0,
    top: 0,
    selectable: false,
    evented: false,
    objectCaching: true,
    statefullCache: true,
    cacheProperties: ["videoTime"],
    type: "video",
  });
};
