import { Player } from "@remotion/player";
import { Button, Col } from "antd";
import { AudioVisualizer } from "screens/assetExporter/feedConfigurationV2/assetBatchDrawerV2/manageVariables/panelVariables/UploadAudio/AudioVisualizer";
import { calculateDurationInFrames } from "screens/assetExporter/feedConfigurationV2/assetBatchDrawerV2/manageVariables/panelVariables/utils";
import {
  renderFullscreenButton,
  renderPlayPauseButton,
} from "screens/assetExporter/feedConfigurationV2/shared/components/VideoPlayerControllers";
import { DEFAULT_FPS } from "screens/assetExporter/feedConfigurationV2/shared/constants";
import { TAudioFile } from "shared/types/assetExporter";
import styles from "../VariableInput.module.scss";
interface Props {
  audioFile: TAudioFile;
  setShowSelectColumn: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AudioFromFeedPlayer = ({
  audioFile,
  setShowSelectColumn,
}: Props) => {
  return (
    <Col span={24}>
      <div className={styles.containerInput}>
        <Button onClick={() => setShowSelectColumn(true)}>Change</Button>
        <span>{audioFile.name || ""}</span>
      </div>
      <Player
        className={styles.playerTest}
        style={{ backgroundColor: "white" }}
        component={AudioVisualizer}
        durationInFrames={calculateDurationInFrames(
          0,
          audioFile?.file?.duration ?? audioFile.end / 1000,
        )}
        compositionWidth={432}
        compositionHeight={74}
        renderPlayPauseButton={renderPlayPauseButton}
        renderFullscreenButton={renderFullscreenButton}
        fps={DEFAULT_FPS}
        controls
        allowFullscreen={false}
        inputProps={{
          src: audioFile?.src ?? "",
          start: 0,
          end: audioFile?.file?.duration ?? audioFile.end / 1000,
        }}
      />
    </Col>
  );
};
