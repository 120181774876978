import { useState, ChangeEvent } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useAutocomplete } from "./useFolderAutocomplete";
import { ListItem, Stack, Typography } from "@mui/material";

type FolderOption = {
  value: string;
  label: string;
  folderPath?: string;
};

type FolderProps = {
  onSelect: (folderId: string, folderPath: string) => void;
};

export const FolderAutocomplete = ({ onSelect }: FolderProps) => {
  const [query, setQuery] = useState("");
  const folders = useAutocomplete(query);

  return (
    <Autocomplete
      disableClearable
      options={folders ?? []}
      style={{ width: "auto" }}
      onInputChange={(_event: ChangeEvent<object>, newInputValue: string) => {
        setQuery(newInputValue);
      }}
      onChange={(_event: ChangeEvent<object>, newValue: FolderOption) => {
        if (newValue) {
          onSelect(newValue.value, newValue.label);
        }
      }}
      renderInput={params => (
        <TextField {...params} label="Search for folders" />
      )}
      renderOption={(props, option) => (
        <ListItem {...props} key={option.value}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="left"
            alignItems="center"
            margin={1}
          >
            <Stack direction="column">
              <Stack alignItems="flex-start" justifyContent="center">
                <Typography variant="body1">{option.label}</Typography>
              </Stack>
              <Stack alignItems="center" justifyContent="center">
                <Typography
                  variant="body2"
                  style={{ color: "gray", fontSize: "0.8rem" }}
                >
                  {option.folderPath}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </ListItem>
      )}
    />
  );
};
