import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Input, Tooltip } from "antd";
import { CloseCircleFilled, InfoCircleFilled } from "@ant-design/icons";
import styles from "./ReviewStep.module.scss";
import { useAdEngineActions } from "redux/assetExporter/assetExporter.slice";
import { useAppSelector } from "shared/hooks/useAppSelector";
import useFetchPreview from "shared/hooks/assetExporter/useFetchPreview";
import Table, { ColumnType } from "antd/lib/table";
import {
  DEFINED_ERRORS_NUM,
  EMPTY_SMART_COL_WARNING_TEXT,
  ERROR_TEXT_SUFFIX,
  MAX_CHAR_DISPLAY,
} from "shared/constants/assetExporter";
import { v4 as uuidv4 } from "uuid";
import useProcessPreview from "shared/hooks/assetExporter/useProcessPreview";
import {
  getErrorMsgCount,
  getProcessChunks,
  getWarningCols,
  hasValidColumns,
  isFbAdsError,
  mergeColsWithSmartCols,
} from "./utils";
import { FeedColData, ProcessedPreview } from "shared/types/assetExporter";
import Link from "antd/lib/typography/Link";
import { adEngineFBAdsUrl } from "utils/constants";

const { Search } = Input;

export const ReviewStep = () => {
  const {
    drawerMode,
    feedId,
    uploadedFile,
    uploadType,
    selectedSmartCols,
    columnData,
    selectedType,
    isEmptySmartColWarning,
    reviewGeneralWarnings,
    reviewGeneralErrors,
  } = useAppSelector(state => state.assetExporter.sourceFeed.feedDrawer);
  const actions = useAdEngineActions();
  const isUpdate = drawerMode === "UPDATE" && !!uploadedFile;

  const {
    previewData,
    columnHeaders,
    isLoading: isLoadingPreviewData,
    fetchingNextPage,
  } = useFetchPreview({
    filename: uploadedFile!,
    isUpdate,
    feedId,
    isServer: uploadType === "server",
  });

  const { mutateAsync: processPreview, isLoading: isProcessing } =
    useProcessPreview();
  const [processedRows, setProcessedRows] = useState<ProcessedPreview>([]);
  const [processedRowsCopy, setProcessedRowsCopy] = useState<ProcessedPreview>(
    [],
  );
  const [displayErrors, setDisplayErrors] = useState(false);

  const processPreviewData = useCallback(() => {
    const startProcess = async () => {
      actions.setIsProcessingPreview(true);
      const smartColumns = selectedSmartCols;
      const chunksToProcess = getProcessChunks(previewData, columnData);
      const promises = chunksToProcess.map(rows =>
        processPreview({ rows, smartColumns }),
      );
      const results = await Promise.all(promises);
      const flattenedRes = [...results.flat(1)];
      setProcessedRows(flattenedRes);
      setProcessedRowsCopy(flattenedRes);
      const colHeaders = columnData.map(col => col.matchedTo!);
      actions.setGeneralWarningMsgs(getWarningCols(colHeaders));
      if (!hasValidColumns(columnData, selectedType)) {
        actions.setGeneralErrorMsgs("");
      }
      actions.setIsProcessingPreview(false);
    };
    startProcess();
  }, [
    actions,
    previewData,
    processPreview,
    columnData,
    selectedSmartCols,
    selectedType,
  ]);

  const enableCompleteBtn = useCallback(() => {
    actions.enableNextButton();
  }, [actions]);

  const disableCompleteBtn = useCallback(() => {
    actions.disableNextButton();
  }, [actions]);

  useEffect(processPreviewData, [processPreviewData]);
  useEffect(() => {
    if (fetchingNextPage) {
      disableCompleteBtn();
      return;
    }
    enableCompleteBtn();
  }, [disableCompleteBtn, enableCompleteBtn, fetchingNextPage]);

  const filterData = (value: string) => {
    if (!value) {
      setProcessedRows(processedRowsCopy);
      return;
    }
    const lcValue = value.toLowerCase();
    const filteredRows = processedRowsCopy.filter(row => {
      return (Object.values(row) as string[])
        .map(row => row?.toLowerCase())
        .some(rowVal => rowVal?.includes(lcValue));
    });
    setProcessedRows(filteredRows);
  };

  const errorMsgsCount = getErrorMsgCount(
    isEmptySmartColWarning,
    reviewGeneralWarnings,
    reviewGeneralErrors,
  );
  const formattedColumns = mergeColsWithSmartCols(
    columnHeaders,
    selectedSmartCols,
  ).map(column => ({
    ...column,
    dataIndex: column.colHeader || `empty-header`,
  }));

  const dataSource = useMemo(() => {
    return processedRows.map(row => {
      const newRow: Record<string, any> = { key: uuidv4() };
      formattedColumns.forEach(({ colHeader, dataIndex }, index) => {
        const formattedColHeader =
          columnData.find(
            (colData: FeedColData) => colData.matchedFrom === dataIndex,
          )?.matchedTo ?? colHeader;

        if (formattedColHeader === "Import without formatting") {
          newRow[dataIndex] = row[dataIndex];
          return;
        }

        newRow[dataIndex] = formattedColHeader
          ? row[formattedColHeader]
          : row[`field${index + 1}`];
      });
      return newRow;
    });
  }, [columnData, formattedColumns, processedRows]);

  const generalErrorMsgsCount =
    Object.values(reviewGeneralErrors).filter(Boolean).length;

  const columns: ColumnType<Record<string, any>>[] = formattedColumns.map(
    ({ colHeader, isSmartCol, dataIndex }) => ({
      title: colHeader || "\u00A0", // Unicode for non-breaking space for empty-header
      dataIndex,
      key: colHeader || uuidv4(),
      render: function renderCell(value?: string) {
        const isFbAds = selectedType === "facebookAds";

        if (isSmartCol && !value) {
          !isEmptySmartColWarning && actions.setEmptySmartColWarning();
        } else if (isFbAds && isFbAdsError(colHeader, value)) {
          generalErrorMsgsCount < DEFINED_ERRORS_NUM &&
            actions.setGeneralErrorMsgs(colHeader);
        }
        const displayTooltip = (value?.length ?? 0) > MAX_CHAR_DISPLAY;
        if (displayTooltip) {
          return (
            <Tooltip title={value}>{`${value?.substring(
              0,
              MAX_CHAR_DISPLAY,
            )}...`}</Tooltip>
          );
        }
        return <span>{value}</span>;
      },
      onCell: () => ({ style: { whiteSpace: "nowrap" } }),
      onHeaderCell: () => ({ style: { whiteSpace: "nowrap" } }),
    }),
  );

  return (
    <>
      <div className={styles.toolBar}>
        <Search
          placeholder="Search"
          onChange={e => filterData(e.target.value)}
        />
        <Button
          disabled={errorMsgsCount === 0}
          onClick={() => setDisplayErrors(prev => !prev)}
          data-cy={`feed-errors`}
        >
          <InfoCircleFilled className={styles.infoIcon} />
          <span>{errorMsgsCount} Error(s)</span>
        </Button>
      </div>
      {displayErrors && (
        <>
          {isEmptySmartColWarning && (
            <div className={styles.errorContainer}>
              <InfoCircleFilled className={styles.infoIcon} />{" "}
              {EMPTY_SMART_COL_WARNING_TEXT}
            </div>
          )}
          {reviewGeneralWarnings.map(warning => (
            <div className={styles.errorContainer} key={warning}>
              <InfoCircleFilled className={styles.infoIcon} /> {warning}
            </div>
          ))}
          {Object.keys(reviewGeneralErrors).map((errMsg, idx) => {
            const msg = reviewGeneralErrors[errMsg as "cta"];
            if (!msg) return null;
            return (
              <div
                className={styles.errorContainer}
                key={errMsg}
                data-cy={`feed-error-${idx}`}
              >
                <div className={styles.generalError}>
                  <div>
                    <CloseCircleFilled className={styles.errorIcon} /> {msg}
                  </div>
                  <Link download={adEngineFBAdsUrl} href={adEngineFBAdsUrl}>
                    {ERROR_TEXT_SUFFIX}
                  </Link>
                </div>
              </div>
            );
          })}
        </>
      )}
      <div className={styles.tableContainer}>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          scroll={{ x: true }}
          loading={isProcessing || isLoadingPreviewData || fetchingNextPage}
        />
      </div>
    </>
  );
};
