import { useState } from "react";

export type MediaPanelTabs = "stock" | "brandKit" | "upload";

const tabs = [
  { value: "brandKit", label: "Brand Kit", enabled: true },
  { value: "upload", label: "Upload", enabled: true },
  {
    value: "stock",
    label: "Stock",
    enabled: true,
  },
] as const;

export const useMediaPanelTabs = () => {
  const [currentTab, setTab] = useState<MediaPanelTabs | null>("brandKit");
  const enabledTabs = tabs.filter(tab => tab.enabled);
  return { tabs: enabledTabs, currentTab: currentTab ?? null, setTab };
};
