import { useState } from "react";
import { Workspace } from "polotno/canvas/workspace";
import { Grid, Box } from "@mui/material";
import { EditorTabs } from "./polotnoEditor/EditorTabs";
import { EditorHeader } from "./polotnoEditor/EditorHeader";
import { useEditorDrawerContext } from "../editorDrawerContext/useEditorDrawerContext";
import { observer } from "mobx-react-lite";
import { EditorTopBar } from "./polotnoEditor/EditorTopbar";
import PageControls from "./polotnoEditor/PageControls";
import ZoomControls from "./polotnoEditor/ZoomControls";
import { Tooltip } from "polotno/canvas/tooltip";
import { EditorTooltip } from "./polotnoEditor/EditorTooltip";
import { handleHotkey } from "polotno/canvas/hotkeys";
import { useTextVariableInsert } from "./polotnoEditor/useTextVariableInsert";
import { VariableInsertionPopover } from "./polotnoEditor/VariableInsertionPopover";
import { WorkspaceContainer } from "./polotnoEditor/WorkspaceContainer";
import { EditorLoader } from "./polotnoEditor/EditorLoader";
import { useElements } from "./polotnoEditor/editorTabs/tabPanel/useElements";
import { StoreType } from "polotno/model/store";
import Snackbar from "@mui/material/Snackbar";
import { SaveAlert } from "./SaveAlert";
import { AutoSaveIndicator } from "./polotnoEditor/AutoSaveIndicator";

export const PolotnoEditor = observer(() => {
  const { activeStore } = useEditorDrawerContext();
  if (!activeStore?.pages?.length) return <EditorLoader />;
  return <WorkspaceArea store={activeStore} />;
});

type WorkspaceAreaProps = {
  store: StoreType;
};

const WorkspaceArea = observer(({ store }: WorkspaceAreaProps) => {
  const { isAutoSaving, isSaving, removeStore, changeActiveStore } =
    useEditorDrawerContext();
  const { openTextVariableMenu, closeTextVariableMenu, anchorPosition } =
    useTextVariableInsert(store);
  const { groupSelectedElements } = useElements(store);

  const [open, setOpen] = useState(true);

  return (
    <Box sx={{ width: 1, height: 1, display: "flex", flexDirection: "column" }}>
      <EditorHeader onRemove={removeStore} onChange={changeActiveStore} />
      <Grid
        container
        sx={{
          width: 1,
          flex: 1,
        }}
        wrap="nowrap"
      >
        <Grid
          item
          sx={{
            flexBasis: open ? 450 : 90,
            maxWidth: 450,
            minWidth: open ? 250 : 90,
          }}
        >
          <EditorTabs open={open} setOpen={setOpen} />
        </Grid>
        <Grid item sx={{ flex: 1 }}>
          <WorkspaceContainer store={store}>
            <EditorTopBar store={store} />
            {isAutoSaving && <AutoSaveIndicator />}
            <Workspace
              onKeyDown={(e, store) => {
                if (e.key === "{") {
                  openTextVariableMenu();
                  return;
                }
                if ((e.key === "g" || e.key === "G") && e.ctrlKey) {
                  groupSelectedElements();
                  return;
                }
                handleHotkey(e, store);
              }}
              store={store}
              components={{
                Tooltip,
                PageControls,
                ...EditorTooltip,
              }}
              paddingY={70}
            />

            <VariableInsertionPopover
              anchorPosition={anchorPosition}
              onClose={closeTextVariableMenu}
            />
            <ZoomControls store={store} />
            <Snackbar
              open={isSaving && !isAutoSaving}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <SaveAlert />
            </Snackbar>
          </WorkspaceContainer>
        </Grid>
      </Grid>
    </Box>
  );
});
