import { ITableColumnURL } from "shared/components/dataListURL/DataListURLTable";
import { AgentProfileKeys } from "./fields";
import { AgentProfile } from "screens/adLibrary/marketingMaterialDrawer/hooks/agentManagement";
import { SearchOutlined } from "@ant-design/icons";
import { joinArray } from "utils/helpers.array";
import { SearchFilterDropdown } from "shared/components/SearchFilterDropdown";

export const useColumns = (): ITableColumnURL<
  AgentProfileKeys,
  AgentProfile
>[] => {
  return [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 300,
      filterIcon: <SearchOutlined />,
      filterDropdown: SearchFilterDropdown,
      render: (_, record) => joinArray([record.firstName, record.lastName]),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,
      filterDropdown: SearchFilterDropdown,
      filterIcon: <SearchOutlined />,
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: 200,
      filterDropdown: SearchFilterDropdown,
      filterIcon: <SearchOutlined />,
    },
  ];
};
