import cloneDeep from "lodash/cloneDeep";
import { assetBuildInstanceToAssetInstance } from "utils/helpers.asset";
import {
  IAssetBuildInstance,
  IAssetInstance,
} from "../../../../../../shared/types/assetBuilder";
import { IDisclosure } from "../../../../../../shared/types/legalLingo";

type Props = {
  updateAssetInstances: (
    instance: IAssetInstance | IAssetInstance[],
    assetType: string,
    size: string,
    order?: number,
  ) => void;
};
export const useRefreshDisclosures = (props: Props) => {
  const { updateAssetInstances } = props;
  const getOfferTypes = (
    instances: Record<string, Record<string, IAssetBuildInstance[]>>,
  ) => {
    return Object.keys(instances);
  };

  const getInstancesByOfferType = (
    instances: Record<string, Record<string, IAssetBuildInstance[]>>,
    screenSize: string,
  ) => {
    return instances[screenSize];
  };

  const getInstanceSizes = (
    offerInstancesBySize: Record<string, IAssetBuildInstance[]>,
  ) => {
    return Object.keys(offerInstancesBySize);
  };
  const findAndUpdateDisclosure = (
    disclosure: any,
    disclosures: IDisclosure[],
    propertyName: string,
  ) => {
    const updatedDisclosure = disclosures.find(
      d => d.name === disclosure?.name,
    );
    if (updatedDisclosure) {
      disclosure[propertyName] = updatedDisclosure;
    }
    return updatedDisclosure;
  };

  const processDisclosures = (
    instance: IAssetBuildInstance,
    disclosures: IDisclosure[],
  ) => {
    if (!instance.disclosure)
      return assetBuildInstanceToAssetInstance(instance);
    const { disclosure } = instance;

    if (disclosure.allSizes) {
      disclosure.allSizes = findAndUpdateDisclosure(
        disclosure.allSizes,
        disclosures,
        "allSizes",
      );
    } else if (disclosure.currentSize) {
      disclosure.currentSize = findAndUpdateDisclosure(
        disclosure.currentSize,
        disclosures,
        "currentSize",
      );
    } else if (disclosure.selectedAsset) {
      disclosure.selectedAsset = findAndUpdateDisclosure(
        disclosure.selectedAsset,
        disclosures,
        "selectedAsset",
      );
    }
    return assetBuildInstanceToAssetInstance(instance);
  };

  const refresh = (
    instances: Record<string, Record<string, IAssetBuildInstance[]>>,
    disclosures: IDisclosure[],
  ) => {
    const offerTypes = getOfferTypes(instances);
    offerTypes.forEach(offerType => {
      const instancesByOfferType = getInstancesByOfferType(
        instances,
        offerType,
      );
      const sizes = getInstanceSizes(instancesByOfferType);
      sizes.forEach(size => {
        const instancesBySize = cloneDeep(instancesByOfferType[size]);
        const updatedInstances = instancesBySize.flatMap(instance => {
          return processDisclosures(instance, disclosures);
        });
        updateAssetInstances(updatedInstances, offerType, size);
      });
    });
  };
  return { refresh };
};
