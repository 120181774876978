import { Col, Form, Input, Row, Typography } from "antd";
import { IAccountRecord } from "shared/types/accountManagement";
import styles from "./DetailsTab.module.scss";

interface AddressProps {
  disabled: boolean;
  accountToEdit: IAccountRecord;
  setAccountToEdit: (accountToEdit: IAccountRecord) => void;
}

const Locations = ({
  disabled,
  accountToEdit,
  setAccountToEdit,
}: AddressProps) => {
  return (
    <>
      <Row>
        <Typography.Text className={styles.title}>
          Locations for Ad Targetting
        </Typography.Text>
      </Row>
      <div className={styles.paddingContainer}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label="Zip code list">
              <Input
                value={accountToEdit.dealerZipCodeList?.join(",") ?? ""}
                disabled={disabled}
                onChange={({ target }) => {
                  const { value: zipCodeList } = target;

                  setAccountToEdit({
                    ...accountToEdit,
                    dealerZipCodeList: zipCodeList.split(","),
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Locations;
