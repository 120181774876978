/* eslint-disable react/display-name */
import { IOfferDataSection } from "shared/types/assetBuilder";
import { OfferType } from "shared/types/shared";
import { OfferImageCarousel } from "../OfferImageCarousel";
import { OfferDate } from "./OfferDate";
import { OfferImage } from "./OfferImage";
import { OfferInput } from "./OfferInput";
import { OfferInputCalculated } from "./OfferInputCalculated";
import { OfferText } from "./OfferText";

const pipeMessage = "Use the pipe ( | ) for multiple values";
const floatMessage = "This field must be a number";

export const internalFormSchema: IOfferDataSection[] = [
  {
    title: "Vehicle Info",
    header: [
      {
        fields: [
          {
            title: "Image",
            key: "imageUrl",
            Component: OfferImage,
            Input: OfferImage,
          },
          {
            title: "VIN",
            key: "vin",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Year",
            key: "year",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Make",
            key: "make",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Model",
            key: "model",
            Component: OfferText,
            Input: OfferInput,
          },
        ],
      },
    ],
    body: [
      {
        fields: [
          {
            title: "Trim",
            key: "trim",
            Component: OfferText,
            Input: OfferInput,
          },
        ],
      },
    ],
  },

  {
    title: "Lease",
    offerType: OfferType.Lease,
    header: [
      {
        fields: [
          {
            title: "Selling Price",
            key: "leaseSellingPrice",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Number of Payments",
            key: "numberOfPayments", // Not sure if leaseTerm is the same thing
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Monthly Payment",
            key: "leaseMonthlyPayment",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Down Payment",
            key: "leaseDownPayment",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Total Due at Signing",
            key: "leaseTotalDueAtSigning",
            Component: OfferText,
            Input: OfferInput,
          },
        ],
      },
    ],
    body: [
      {
        fields: [
          {
            title: "Security Deposit",
            key: "securityDeposit",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Term (Months)",
            key: "leaseTerm",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Expiration Date",
            key: "leaseExpirationDate",
            Component: OfferText,
            Input: props => <OfferDate {...props} period="date" />,
          },
          {
            title: "Cents Per Mile",
            key: "centsPerMile",
            Component: OfferText,
            Input: OfferInput,
          },
        ],
      },
      {
        fields: [
          {
            title: "Miles Per Year",
            key: "milesPerYear",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Additional Lease Disclosure",
            key: "additionalLeaseDisclosure",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Lease Lender",
            key: "leaseLender",
            Component: OfferText,
            Input: OfferInput,
          },
        ],
      },
    ],
  },
  {
    title: "Zero Down Lease",
    offerType: OfferType.ZeroDownLease,
    header: [
      {
        fields: [
          {
            title: "Number of Payments",
            key: "zeroDownLeaseNumberOfPayments",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Monthly Payment",
            key: "zeroDownLeaseMonthlyPayment",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Total Due at Signing",
            key: "zeroDownLeaseTotalDueAtSigning",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Security Deposit",
            key: "zeroDownLeaseSecurityDeposit",
            Component: OfferText,
            Input: OfferInput,
          },
        ],
      },
    ],
    body: [],
  },
  {
    title: "Finance",
    offerType: OfferType.Finance,
    header: [
      {
        fields: [
          {
            title: "Rate",
            key: "financeRate",
            Component: OfferText,
            Input: OfferInput,
            help: "This value should be a percentage number without the %",
          },
          {
            title: "Term",
            key: "financeTerm",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Payment",
            key: "financePayment",
            Component: OfferText,
            Input: OfferInput,
          },
        ],
      },
    ],
    body: [],
  },
  {
    title: "APR",
    offerType: OfferType.APR,
    header: [
      {
        fields: [
          {
            title: "Rate",
            key: "aprRate",
            Component: OfferText,
            Input: OfferInput,
            help: "This value should be a percentage number without the %",
          },
          {
            title: "Term",
            key: "aprTerm",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Payment",
            key: "aprPayment",
            Component: OfferText,
            Input: OfferInput,
          },
        ],
      },
    ],
    body: [],
  },
  {
    title: "Purchase",
    offerType: OfferType.Purchase,
    header: [
      {
        fields: [
          {
            title: "Dealer Enrolled Price",
            key: "dealerPrice",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Dealer Discount",
            key: "dealerDiscount",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Dealer Discount Name",
            key: "dealerDiscountName",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Final Price",
            key: "finalPrice",
            Component: OfferText,
            Input: OfferInput,
            help: floatMessage,
          },
          {
            title: "Savings off MSRP",
            key: "savingsOffMSRP",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Percentage off MSRP",
            key: "percentOffMSRP",
            Component: OfferText,
            Input: OfferInputCalculated,
          },
        ],
      },
    ],
    body: [],
  },
];

export const formSchema: IOfferDataSection[] = [
  {
    title: "Vehicle Info",
    header: [
      {
        fields: [
          {
            title: "Image",
            key: "imageUrl",
            Component: OfferImage,
            Input: OfferImage,
          },
          {
            title: "VIN",
            key: "vin",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "MSRP",
            key: "msrp",
            Component: OfferText,
            Input: OfferInput,
            help: floatMessage,
          },
          {
            title: "Expiration Date",
            key: "expirationDate",
            Component: OfferText,
            Input: props => <OfferDate {...props} period="date" />,
          },
          {
            title: "Days in Stock",
            key: "daysInStock",
            Component: OfferText,
            Input: OfferText,
          },
        ],
      },
    ],
    body: [
      {
        fields: [
          {
            title: "Year",
            key: "year",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Make",
            key: "make",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Model",
            key: "model",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Trim",
            key: "trim",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Drivetrain",
            key: "driveTrain",
            Component: OfferText,
            Input: OfferInput,
          },
        ],
      },
      {
        fields: [
          {
            title: "Transmission",
            key: "transmission",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Cylinders",
            key: "cylinders",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Exterior Color",
            key: "exteriorColor",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Feature 1",
            key: "feature1",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Feature 2",
            key: "feature2",
            Component: OfferText,
            Input: OfferInput,
          },
        ],
      },
      {
        fields: [
          {
            title: "Condition",
            key: "vehicleCondition",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Mileage",
            key: "mileage",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Accessories",
            key: "accessories",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Model Code",
            key: "modelCode",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Packages Extended",
            key: "packagesExtended",
            Component: OfferText,
            Input: OfferInput,
          },
        ],
      },
      {
        fields: [
          {
            title: "Vehicle Type",
            key: "vehicleType",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Cab Style",
            key: "cabStyle",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Stock Number",
            key: "stockNumber",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Date in Stock",
            key: "dateInStock",
            Component: OfferText,
            Input: OfferText,
            help: floatMessage,
          },
        ],
      },
      {
        fields: [
          {
            title: "Advertised Price",
            key: "advertisedPrice",
            Component: OfferText,
            Input: OfferInput,
            help: floatMessage,
          },
          {
            title: "Invoice Price",
            key: "invoicePrice",
            Component: OfferText,
            Input: OfferInput,
            help: floatMessage,
          },
          {
            title: "Accounting Cost",
            key: "accountingCost",
            Component: OfferText,
            Input: OfferInput,
            help: floatMessage,
          },
          {
            title: "Model ID",
            key: "modelId",
            Component: OfferText,
            Input: OfferInput,
          },
        ],
      },
      {
        fields: [
          {
            title: "Package Names",
            key: "packageNames",
            Component: OfferText,
            Input: OfferInput,
            help: pipeMessage,
          },
          {
            title: "Package Codes",
            key: "packageCodes",
            Component: OfferText,
            Input: OfferInput,
            help: pipeMessage,
          },
          {
            title: "Option Codes",
            key: "optionCodes",
            Component: OfferText,
            Input: OfferInput,
            help: pipeMessage,
          },
          {
            title: "DMS Status Code",
            key: "dmsStatusCode",
            Component: OfferText,
            Input: OfferInput,
          },
        ],
      },
      {
        fields: [
          {
            title: "Black Book Average Price",
            key: "blackBookAverage",
            Component: OfferText,
            Input: OfferInput,
            help: floatMessage,
          },
          {
            title: "Black Book Clean Price",
            key: "blackBookClean",
            Component: OfferText,
            Input: OfferInput,
            help: floatMessage,
          },
          {
            title: "Kelly Blue Book Retail Price",
            key: "kbbRetail",
            Component: OfferText,
            Input: OfferInput,
            help: floatMessage,
          },
          {
            title: "Kelly Blue Book Trade-in Price",
            key: "kbbTradeIn",
            Component: OfferText,
            Input: OfferInput,
            help: floatMessage,
          },
        ],
      },
      {
        fields: [
          {
            title: "NADA Retail Price",
            key: "nadaRetail",
            Component: OfferText,
            Input: OfferInput,
            help: floatMessage,
          },
          {
            title: "NADA Trade-in Price",
            key: "nadatradeIn",
            Component: OfferText,
            Input: OfferInput,
            help: floatMessage,
          },
          {
            title: "Blind Spot Monitor",
            key: "BlindSpotMonitor",
            Component: OfferText,
            Input: OfferText,
            help: floatMessage,
          },
          {
            title: "Priority Score",
            key: "priorityScore",
            Component: OfferText,
            Input: OfferText,
            help: floatMessage,
          },
        ],
      },
      {
        fields: [
          {
            colspan: 2,
            title: "Account Images",
            key: "imageUrls",
            Component: OfferImageCarousel,
            Input: OfferInput,
          },
        ],
      },
    ],
  },
  {
    title: "Lease",
    offerType: OfferType.Lease,
    header: [
      {
        fields: [
          {
            title: "Number of Payments",
            key: "numberOfPayments",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Monthly Payment",
            key: "monthlyPayment",
            Component: OfferText,
            Input: OfferInput,
            help: floatMessage,
          },
          {
            title: "Total Due at Signing",
            key: "totalDueAtSigning",
            Component: OfferText,
            Input: OfferInput,
            help: floatMessage,
          },
          {
            title: "Down Payment",
            key: "leaseDownPayment",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Residual Sales Value",
            key: "residualValue",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Vehicle Sales Price",
            key: "leaseVehicleSalesPrice",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Transactability Score",
            key: "transactabilityScore",
            Component: OfferText,
            Input: OfferInput,
          },
        ],
      },
    ],
    body: [
      {
        fields: [
          {
            title: "Total Due at Signing incl. Rebates",
            key: "totalDueAtSigningInclRebates",
            Component: OfferText,
            Input: OfferInput,
            help: floatMessage,
          },
          {
            title: "Cap Cost Reduction",
            key: "capCostReduction",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Net Adjusted Caplitalized Cost",
            key: "netAdjustedCapitalizedCost",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Total Lease Charge",
            key: "totalLeaseCharge",
            Component: OfferText,
            Input: OfferInput,
          },
        ],
      },
      {
        fields: [
          {
            title: "Total Payments",
            key: "totalPayments",
            Component: OfferText,
            Input: OfferInput,
            help: floatMessage,
          },
          {
            title: "Security Deposit",
            key: "securityDeposit",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Termination Fee",
            key: "terminationFee",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "min FICO",
            key: "minFICO",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Miles Per Year",
            key: "milesPerYear",
            Component: OfferText,
            Input: OfferInput,
          },
        ],
      },
      {
        fields: [
          {
            title: "Cents Per Mile",
            key: "centsPerMile",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Acquistion Fee",
            key: "acquisitionFee",
            Component: OfferText,
            Input: OfferInput,
            help: floatMessage,
          },
          {
            title: "Suggested Dealer Contribution",
            key: "suggestedDealerContribution",
            Component: OfferText,
            Input: OfferInput,
            help: floatMessage,
          },
        ],
      },
      {
        fields: [
          {
            title: "Cap Cost Includes Acq. Fee",
            key: "capCostIncludesAcqusitionFee",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Cap Cost Includes 1st Month Payment",
            key: "capCostIncludesFirstMonthPayment",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "DAS Includes Acq. Fee",
            key: "dasIncludesAcqusitionFee",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "DAS Includes 1st Month Payment",
            key: "dasIncludesFirstMonthPayment",
            Component: OfferText,
            Input: OfferInput,
          },
        ],
      },
      {
        fields: [
          {
            title: "Additional Lease Disclosure",
            key: "additionalLeaseDisclosure",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Lease Lender",
            key: "leaseLender",
            Component: OfferText,
            Input: OfferInput,
          },
        ],
      },
      {
        repeatable: true,
        fields: [
          {
            title: "Lease Rebate Amount",
            key: "leaseRebate",
            Component: OfferText,
            Input: OfferInput,
            help: floatMessage,
          },
          {
            title: "Lease Rebate Name",
            key: "leaseRebateName",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Lease Rebate Disclosure",
            key: "leaseRebateDisclosure",
            Component: OfferText,
            Input: OfferInput,
            colspan: 2,
          },
        ],
      },
      {
        repeatable: true,
        fields: [
          {
            title: "Lease Conditional Rebate Amount",
            key: "leaseConditionalRebate",
            Component: OfferText,
            Input: OfferInput,
            help: floatMessage,
          },
          {
            title: "Lease Conditional Rebate Name",
            key: "leaseConditionalRebateName",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Lease Conditional Rebate Disclosure",
            key: "leaseConditionalRebateDisclosure",
            Component: OfferText,
            Input: OfferInput,
            colspan: 2,
          },
        ],
      },
      { fields: [] },
      {
        repeatable: true,
        fields: [
          {
            title: "Lease Custom",
            key: "leaseCustom",
            Component: OfferText,
            Input: OfferInput,
          },
        ],
      },
    ],
  },
  {
    title: "Zero Down Lease",
    offerType: OfferType.ZeroDownLease,
    header: [
      {
        fields: [
          {
            title: "Number of Payments",
            key: "zeroDownLeaseNumberOfPayments",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Monthly Payment",
            key: "zeroDownLeaseMonthlyPayment",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Residual Sales Value",
            key: "zeroDownLeaseResidualValue",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Total Due at Signing incl. Rebates",
            key: "zeroDownLeaseTotalDueAtSigningInclRebates",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Transactability Score",
            key: "transactabilityScore",
            Component: OfferText,
            Input: OfferInput,
          },
        ],
      },
    ],
    body: [
      {
        fields: [
          {
            title: "Cap Cost",
            key: "zeroDownLeaseCapCost",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Cap Cost Reduction",
            key: "zeroDownLeaseCapCostReduction",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Net Adjusted Capitalized Cost",
            key: "zeroDownLeaseNetAdjustedCapitalizedCost",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Total Lease Charge",
            key: "zeroDownLeaseTotalLeaseCharge",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Vehicle Sales Price",
            key: "zeroDownLeaseVehicleSalesPrice",
            Component: OfferText,
            Input: OfferInput,
          },
        ],
      },
      {
        fields: [
          {
            title: "Total Payments",
            key: "zeroDownLeaseTotalPayments",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Security Deposit",
            key: "zeroDownLeaseSecurityDeposit",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Total Due at Signing",
            key: "zeroDownLeaseTotalDueAtSigning",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Termination Fee",
            key: "zeroDownLeaseTerminationFee",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "FICO",
            key: "zeroDownLeaseMinFICO",
            Component: OfferText,
            Input: OfferInput,
          },
        ],
      },
      {
        fields: [
          {
            title: "Miles per Year",
            key: "zeroDownLeaseMilesPerYear",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Cents per Mile",
            key: "zeroDownLeaseCentsPerMile",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Acquisition Fee",
            key: "zeroDownLeaseAcquisitionFee",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Suggested Dealer Contribution",
            key: "zeroDownSuggestedDealerContribution",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Additional Zero Down Lease Disclosure",
            key: "additionalZeroDownLeaseDisclosure",
            Component: OfferText,
            Input: OfferInput,
          },
        ],
      },
      {
        fields: [
          {
            title: "Zero Down Lease Lender",
            key: "zeroDownLeaseLender",
            Component: OfferText,
            Input: OfferInput,
          },
        ],
      },
      {
        repeatable: true,
        fields: [
          {
            title: "Zero Down Lease Rebate Amount",
            key: "zeroDownLeaseRebate",
            Component: OfferText,
            Input: OfferInput,
            help: floatMessage,
          },
          {
            title: "Zero Down Lease Rebate Name",
            key: "zeroDownLeaseRebateName",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Zero Down Lease Rebate Disclosure",
            key: "zeroDownLeaseRebateDisclosure",
            Component: OfferText,
            Input: OfferInput,
            colspan: 2,
          },
        ],
      },
      {
        repeatable: true,
        fields: [
          {
            title: "Zero Down Lease Conditional Rebate Amount",
            key: "zeroDownLeaseConditionalRebate",
            Component: OfferText,
            Input: OfferInput,
            help: floatMessage,
          },
          {
            title: "Zero Down Lease Conditional Rebate Name",
            key: "zeroDownLeaseConditionalRebateName",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Zero Down Lease Conditional Rebate Disclosure",
            key: "zeroDownLeaseConditionalRebateDisclosure",
            Component: OfferText,
            Input: OfferInput,
            colspan: 2,
          },
        ],
      },
      { fields: [] },
      {
        repeatable: true,
        fields: [
          {
            title: "Zero Down Lease Custom",
            key: "zeroDownLeaseCustom",
            Component: OfferText,
            Input: OfferInput,
          },
        ],
      },
    ],
  },
  {
    title: "Finance",
    offerType: OfferType.Finance,
    header: [
      {
        fields: [
          {
            title: "Rate",
            key: "financeRate",
            Component: OfferText,
            Input: OfferInput,
            help: "This value should be a percentage number without the %",
          },
          {
            title: "Term",
            key: "financeTerm",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Payment",
            key: "financePayment",
            Component: OfferText,
            Input: OfferInput,
            help: floatMessage,
          },
          {
            title: "Transactability Score",
            key: "transactabilityScore",
            Component: OfferText,
            Input: OfferInput,
          },
        ],
      },
    ],
    body: [
      {
        fields: [
          {
            title: "Amount Financed",
            key: "amntFinanced",
            Component: OfferText,
            Input: OfferInput,
            help: floatMessage,
          },
          {
            title: "Finance Down Payment",
            key: "financeDownPayment",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Min FICO",
            key: "financeMinFICO",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Sales Price",
            key: "leaseVehicleSalesPrice",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Finance Lender",
            key: "financeLender",
            Component: OfferText,
            Input: OfferInput,
          },
        ],
      },
      {
        fields: [
          {
            title: "Additional Finance Disclosure",
            key: "additionalFinanceDisclosure",
            Component: OfferText,
            Input: OfferInput,
            colspan: 2,
          },
        ],
      },
      {
        repeatable: true,
        fields: [
          {
            title: "Finance Custom",
            key: "financeCustom",
            Component: OfferText,
            Input: OfferInput,
          },
        ],
      },
      {
        repeatable: true,
        fields: [
          {
            title: "Finance Conditional Rebate Amount",
            key: "financeConditionalRebate",
            Component: OfferText,
            Input: OfferInput,
            help: floatMessage,
          },
          {
            title: "Finance Conditional Rebate Name",
            key: "financeConditionalRebateName",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Finance Conditional Rebate Disclosure",
            key: "financeConditionalRebateDisclosure",
            Component: OfferText,
            Input: OfferInput,
            colspan: 2,
          },
        ],
      },
    ],
  },
  {
    title: "Purchase",
    offerType: OfferType.Purchase,
    header: [
      {
        fields: [
          {
            title: "Dealer Enrolled Price",
            key: "dealerPrice",
            Component: OfferText,
            Input: OfferInput,
            help: floatMessage,
          },
          {
            title: "Dealer Discount",
            key: "dealerDiscount",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Dealer Discount Name",
            key: "dealerDiscountName",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Final Price",
            key: "finalPrice",
            Component: OfferText,
            Input: OfferInput,
            help: floatMessage,
          },
          {
            title: "Final Price Name",
            key: "finalPriceName",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Accessory Price",
            key: "accessoryPrice",
            Component: OfferText,
            Input: OfferInput,
          },
        ],
      },
    ],

    body: [
      {
        fields: [
          {
            title: "Savings off MSRP",
            key: "savingsOffMSRP",
            Component: OfferText,
            Input: OfferInput,
            help: floatMessage,
          },
          {
            title: "Savings Off MSRP Title",
            key: "savingsOffMSRPTitle",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Savings Off MSRP Description",
            key: "savingsOffMSRPDescription",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Percentage off MSRP",
            key: "percentOffMSRP",
            Component: OfferText,
            Input: OfferInputCalculated,
          },
          {
            title: "Price After Conditional Rebates",
            key: "priceAfterConditionalRebates",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Transactability Score",
            key: "transactabilityScore",
            Component: OfferText,
            Input: OfferInput,
          },
        ],
      },

      {
        fields: [
          {
            title: "Additional Purchase Disclosure",
            key: "additionalPurchaseDisclosure",
            Component: OfferText,
            Input: OfferInput,
            colspan: 2,
          },
        ],
      },

      {
        repeatable: true,
        fields: [
          {
            title: "Purchase Rebate Amount",
            key: "purchaseRebate",
            Component: OfferText,
            Input: OfferInput,
            help: floatMessage,
          },
          {
            title: "Purchase Rebate Name",
            key: "purchaseRebateName",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Purchase Rebate Disclosure",
            key: "purchaseRebateDisclosure",
            Component: OfferText,
            Input: OfferInput,
            colspan: 2,
          },
        ],
      },
      { fields: [] },
      {
        repeatable: true,
        fields: [
          {
            title: "Conditional Rebate Amount",
            key: "conditionalRebate",
            Component: OfferText,
            Input: OfferInput,
            help: floatMessage,
          },
          {
            title: "Conditional Rebate Name",
            key: "conditionalRebateName",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Conditional Rebate Disclosure",
            key: "conditionalRebateDisclosure",
            Component: OfferText,
            Input: OfferInput,
            colspan: 2,
          },
        ],
      },
      { fields: [] },
      {
        repeatable: true,
        fields: [
          {
            title: "Purchase Custom",
            key: "purchaseCustom",
            Component: OfferText,
            Input: OfferInput,
          },
        ],
      },
    ],
  },
  {
    title: "APR",
    offerType: OfferType.APR,
    header: [
      {
        fields: [
          {
            title: "Rate",
            key: "aprRate",
            Component: OfferText,
            Input: OfferInput,
            help: "This value should be a percentage number without the %",
          },
          {
            title: "Term",
            key: "aprTerm",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Payment",
            key: "aprPayment",
            Component: OfferText,
            Input: OfferInputCalculated,
          },
          {
            title: "Amount Financed",
            key: "aprAmntFinanced",
            Component: OfferText,
            Input: OfferInput,
          },
        ],
      },
    ],
    body: [
      {
        fields: [
          {
            title: "Min FICO",
            key: "aprMinFICO",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "APR Lender",
            key: "aprLender",
            Component: OfferText,
            Input: OfferInput,
          },
          {
            title: "Additional APR Disclosure",
            key: "additionalAPRDisclosure",
            Component: OfferText,
            Input: OfferInput,
            colspan: 2,
          },
        ],
      },
      {
        repeatable: true,
        fields: [
          {
            title: "APR Custom",
            key: "aprCustom",
            Component: OfferText,
            Input: OfferInput,
          },
        ],
      },
    ],
  },
];
