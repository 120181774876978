import { IAd, ICard } from "shared/types/adLibrary";
import { MultiUploadSizing } from "shared/types/uploadManagement";

export const getHasThumbnailError = (type: MultiUploadSizing, card: ICard) =>
  !getCardData(type, card)?.thumbnail;

export const getCardData = (type: MultiUploadSizing, card: ICard) => {
  if (type === "IN_FEED") return card;

  return card?.additionalPlacements?.[0];
};

export const getAdThumbnail = (type: MultiUploadSizing, ad: IAd) => {
  if (type === "IN_FEED") return ad.visuals.thumbnail;
  return ad.visuals?.additionalPlacements?.[0]?.thumbnail;
};
