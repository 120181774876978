import { Rule } from "antd/lib/form";
import { NamePath, StoreValue } from "antd/lib/form/interface";
import { StateKey, states } from "shared/constants/states";
import { MktMatFieldMap, ProductField } from "shared/types/marketingMaterials";
import { Languages } from "utils/helpers.salesEnablement";
import { pluralize } from "utils/terms";

export const baseRules = (customMessage?: string): Rule[] => {
  const defaultMessage = "This field is required.";
  return [
    {
      required: true,
      message: customMessage || defaultMessage,
      validator: (_, value) => {
        if (value?.trim() === "") {
          return Promise.reject();
        }
        return Promise.resolve();
      },
    },
  ];
};

export const getProductTypeOfferDisclosureRule =
  (disclosureFieldId?: string): Rule =>
  ({ getFieldValue }) => ({
    validator: (_, value) => {
      if (!value?.length) return Promise.resolve();
      const languageLabel = getLanguageLabel(getFieldValue("language"));
      const disclosureLabel = getDisclosureLabel(
        getFieldValue,
        disclosureFieldId,
      );
      const locationListLabel = getLocationListLabel(
        getFieldValue("locations"),
      );
      if (disclosureLabel || !languageLabel || !locationListLabel)
        return Promise.resolve();
      return Promise.reject(
        new Error(
          `There is no ${languageLabel} disclosure for this product type offer in combination with the ${locationListLabel}`,
        ),
      );
    },
  });

export const getProductDisclosureRule =
  (disclosureFieldId?: string): Rule =>
  ({ getFieldValue }) => ({
    validator: (_, value) => {
      if (!value) return Promise.resolve();
      const languageLabel = getLanguageLabel(getFieldValue("language"));
      const disclosureLabel = getDisclosureLabel(
        getFieldValue,
        disclosureFieldId,
      );

      const locationListLabel =
        getLocationListLabel(getFieldValue("locations")) ??
        "selected location.";

      if (disclosureLabel || !languageLabel) return Promise.resolve();
      return Promise.reject(
        new Error(
          `There is no ${languageLabel} disclosure for this product in combination with the ${locationListLabel}`,
        ),
      );
    },
  });

export const getLocationDisclosureRule =
  (disclosureFieldId?: string): Rule =>
  ({ getFieldValue }) => ({
    validator: (_, value) => {
      if (!value) return Promise.resolve();
      const selectedProducts = getSelectedProducts(getFieldValue("fields"));
      if (!selectedProducts?.length) {
        if (!getFieldValue("productTypeOffer")) return Promise.resolve();
      }
      const languageLabel = getLanguageLabel(getFieldValue("language"));
      const disclosureLabel = getDisclosureLabel(
        getFieldValue,
        disclosureFieldId,
      );
      const locationListLabel = getLocationListLabel(
        getFieldValue("locations"),
      );
      if (disclosureLabel || !languageLabel || !locationListLabel)
        return Promise.resolve();
      return Promise.reject(
        new Error(
          `There is no ${languageLabel} disclosure for this product in combination with the ${locationListLabel}`,
        ),
      );
    },
  });

function getDisclosureLabel(
  getFieldValue: (name: NamePath) => StoreValue,
  disclosureFieldId?: string,
) {
  return disclosureFieldId ? getFieldValue(["fields", disclosureFieldId]) : "";
}

function getLanguageLabel(currentLanguage?: keyof typeof Languages) {
  if (!currentLanguage) return;
  return Languages[currentLanguage]?.label;
}

function getLocationListLabel(locations?: StateKey[]) {
  const listFormat = new Intl.ListFormat("en-GB", {
    style: "long",
    type: "conjunction",
  });
  const locationsList =
    locations?.map((fieldLocation: StateKey) => states[fieldLocation].name) ??
    [];
  return `${pluralize("location", locationsList.length)} ${listFormat.format(
    locationsList,
  )}`;
}

function getSelectedProducts(fields: MktMatFieldMap) {
  const productFields = Object.values(fields).filter(
    (field): field is ProductField =>
      typeof field == "object" && field.type === "product",
  );
  if (!productFields.length) return undefined;
  return productFields.flatMap(({ productsData }) =>
    productsData.flatMap(({ productId }) => productId ?? []),
  );
}
