import { useMemo } from "react";
import { Steps as AntSteps } from "antd";
import { useAdLibraryExportContext } from "./AdLibraryExportContext";
import { exportDestinationStepTitles } from "./constants";

const { Step } = AntSteps;

interface StepsProps {
  currentStep: number;
}

export const Steps = ({ currentStep }: StepsProps) => {
  const { exportDestination } = useAdLibraryExportContext();

  const stepTitles = useMemo(() => {
    if (!exportDestination || currentStep === 0) return [];
    return exportDestinationStepTitles[exportDestination];
  }, [exportDestination, currentStep]);

  return (
    <AntSteps direction="horizontal" current={currentStep}>
      <Step title={"Export Settings"} />
      {stepTitles.map(stepTitle => (
        <Step title={stepTitle} key={stepTitle} />
      ))}
    </AntSteps>
  );
};
