import { useQuery } from "react-query";
import APIs from "services";
import { getResultIfOk } from "services/adLibrary";
import { Account } from "shared/types/salesEnablement";

export const useFetchAccounts = () => {
  return useQuery<Account[]>("accounts", async () => {
    const { result } = getResultIfOk(
      await APIs.services.salesEnablement.getAccounts(),
    );

    return result;
  });
};
