import { Button } from "antd";
import { useCallback } from "react";
import { CAMOption } from "../../assetBatchDrawerV2/manageVariables/panelVariables/CAMOption";
import { useAssetBatchesContext } from "../../shared/contexts/AssetBatchesContext";
import styles from "./VariableInput.module.scss";
import { useVideoCompositionAudios } from "../../shared/hooks/useVideoCompositionAudios";
import { Player } from "@remotion/player";
import { AudioVisualizer } from "../../assetBatchDrawerV2/manageVariables/panelVariables/UploadAudio/AudioVisualizer";
import { calculateDurationInFrames } from "../../assetBatchDrawerV2/manageVariables/panelVariables/utils";
import {
  renderFullscreenButton,
  renderPlayPauseButton,
} from "../../shared/components/VideoPlayerControllers";
import { DEFAULT_FPS } from "../../shared/constants";
import { MediaSubtype, TAudioFile } from "shared/types/assetExporter";

interface Props {
  audioFile: TAudioFile;
}

export const AudioFromCam = ({ audioFile }: Props) => {
  const { setAudioFiles } = useAssetBatchesContext();
  const { createNewAudioFile } = useVideoCompositionAudios();

  const onSelectAsset = useCallback(
    async (url: string, name?: string) => {
      createNewAudioFile(
        MediaSubtype.CAM,
        url,
        name ?? "",
        audioFile.id,
        setAudioFiles,
      );
    },
    [audioFile.id, createNewAudioFile, setAudioFiles],
  );

  return (
    <>
      <div className={styles.containerInput}>
        <CAMOption
          onSelectAsset={onSelectAsset}
          title="Advanced CAM Search"
          fileTypes={["mp3", "wav"]}
          search='(.mp3 OR .wav) AND NOT "._"'
        >
          <Button>Change</Button>
        </CAMOption>
        <span>{audioFile.name || ""}</span>
      </div>
      <Player
        className={styles.playerTest}
        component={AudioVisualizer}
        durationInFrames={calculateDurationInFrames(
          0,
          audioFile?.file?.duration ?? audioFile.end / 1000,
        )}
        compositionWidth={432}
        compositionHeight={74}
        renderPlayPauseButton={renderPlayPauseButton}
        renderFullscreenButton={renderFullscreenButton}
        fps={DEFAULT_FPS}
        controls
        allowFullscreen={false}
        inputProps={{
          src: audioFile?.src ?? "",
          start: 0,
          end: audioFile?.file?.duration ?? audioFile.end / 1000,
        }}
      />
    </>
  );
};
