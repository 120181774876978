import uuid from "uuid";
import { useBeeswaxCampaignsContext } from "../../BeeswaxCampaignsProvider";
import { LineItem } from "../../types";

export const useLineItemActions = () => {
  const { draftLineItems, setDraftLineItems } = useBeeswaxCampaignsContext();

  const createLineItem = (lineitem: Omit<LineItem, "id">) => {
    setDraftLineItems(prev => {
      return [...prev, { id: uuid(), ...lineitem }];
    });
  };

  const editLineItem = (id: string, lineitem: Omit<LineItem, "id">) => {
    setDraftLineItems(prev => {
      return prev.map(item =>
        item.id === id ? { id: id, ...lineitem } : item,
      );
    });
  };

  const deleteLineItems = (ids: string[]) => {
    setDraftLineItems(prev => prev.filter(item => !ids.includes(item.id)));
  };

  const getLineItem = (id: string | undefined) => {
    if (!id) return undefined;

    return draftLineItems.find(item => item.id === id);
  };

  return { getLineItem, createLineItem, editLineItem, deleteLineItems };
};
