import {
  ConditionalGroupLayer,
  GroupLayer,
  Language,
  Layer,
  TemplateFile,
} from "shared/types/salesEnablement";
import { OnLayerChangeFunc } from "../Layers";
import styles from "./SmartLayer.module.scss";
import LayerName from "../shared/LayerName";
import {
  hasChildren,
  isConditionalGroupLayer,
  isGroupLayer,
  isOptionGroupLayer,
  isOtherGrouplayer,
  isSnippetGroupLayer,
} from "../utils";
import GroupSelect from "./smartLayer/GroupSelect";
import Conditional from "./smartLayer/Conditional";
import Options from "./smartLayer/Options";
import GroupLayerMaxItems from "../shared/GroupLayerMaxItems";

type Props = {
  layer: Layer;
  onChange?: OnLayerChangeFunc;
  groupLayers?: GroupLayer[];
  language: Language;
  disabled?: boolean;
  hideOptions?: boolean;
  selectedFile?: TemplateFile;
};
const SmartLayer = (props: Props) => {
  const {
    layer,
    onChange,
    groupLayers,
    language,
    disabled,
    hideOptions = false,
    selectedFile,
  } = props;

  return (
    <div className={styles.container}>
      <LayerName layer={layer} onChange={onChange} disabled={disabled} />

      {isGroupLayer(layer) && !hideOptions && (
        <GroupSelect
          layer={layer}
          groupLayers={groupLayers}
          onChange={onChange}
          disabled={disabled}
          selectedFile={selectedFile}
        />
      )}

      {isOtherGrouplayer(layer) && hasChildren(layer) && (
        <div className={styles.children}>
          {layer.subLayers?.map((subLayer: Layer) => {
            const { smartVariable } = subLayer;
            if (smartVariable === "conditional") {
              return (
                <div key={`sublayer-${subLayer.name}`}>
                  <LayerName
                    groupable={layer.groupable}
                    isChildLayer={true}
                    layer={subLayer}
                    onChange={onChange}
                    disabled={disabled}
                  />
                  <Conditional
                    layer={subLayer as ConditionalGroupLayer}
                    language={language}
                    onComplete={conditionalSet => {
                      onChange?.(layer, {
                        type: "group_set_conditional",
                        data: { conditionalSet, subLayer, isSublayer: true },
                      });
                    }}
                    disabled={disabled}
                  />
                </div>
              );
            }
            return (
              <LayerName
                key={`sublayer-${subLayer.name}`}
                groupable={layer.groupable}
                isChildLayer={true}
                layer={subLayer}
                onChange={onChange}
                disabled={disabled}
              />
            );
          })}
        </div>
      )}

      {isSnippetGroupLayer(layer) && (
        <GroupLayerMaxItems
          disabled={disabled}
          layer={layer}
          onChange={onChange}
        />
      )}

      {isOptionGroupLayer(layer) && (
        <Options layer={layer} onChange={onChange} disabled={disabled} />
      )}

      {isConditionalGroupLayer(layer) && (
        <Conditional
          layer={layer}
          language={language}
          onComplete={conditionalSet => {
            onChange?.(layer, {
              type: "group_set_conditional",
              data: { conditionalSet },
            });
          }}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default SmartLayer;
