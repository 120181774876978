import { message } from "antd";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import useUrlParameters from "shared/hooks/useUrlParameters";
import { DestinationUrls } from "shared/types/campaignManagement";
import useAdReviewPreferences from "./useAdReviewPreferences";
import usePdfGeneration from "./usePdfGeneration";
import { AD_NAME_DELIMITER } from "../utils/helpers.table";
import { useCampaignManagementIds } from "screens/adLibrary/shared/hooks/dataListHooks";

const useAdReviewState = () => {
  message.config({ maxCount: 1 });
  const dateFormat = moment.localeData().longDateFormat("L");

  const { layout, selectedStore, selectedDateRange, setSelectedDateRange } =
    useAdReviewPreferences();

  const { initPreferences } = useUrlParameters();

  useEffect(() => {
    initPreferences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [displayColumn, setDisplayColumn] = useState(false);
  const [destinationDrawer, setDestinationDrawer] = useState(false);
  const [destinationUrls, setDestinationUrls] = useState<DestinationUrls>([]);
  const [showDetails, setShowDetails] = useState(false);
  const [isShowingShareModal, setIsShowingShareModal] = useState(false);
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [expand, setExpand] = useState(false);
  const [showFlipperDrawer, setShowFlipperDrawer] = useState(false);
  const [flipWithAds, setFlipWithAds] = useState(true);

  // An array of IDs, where ID could be a concatenated string of multiple nested IDs
  const { selectedIds } = useCampaignManagementIds();

  const selectedIndividualAdIds = selectedIds
    .flatMap(entryId => entryId.split(AD_NAME_DELIMITER))
    .filter(id => id);

  const {
    isStartingPdfGeneration,
    isLoadingPdfExecutionDescriptions,
    handleGeneratePdfStartExecution,
  } = usePdfGeneration({
    selectedIds,
    selectedStore,
    selectedDateRange,
  });

  const completeDateRange = useMemo(
    () => selectedDateRange.filter(Boolean).length === 2,
    [selectedDateRange],
  );

  const showContent = useMemo(() => !!selectedStore, [selectedStore]);

  const setDateOnAccountSelect = useCallback(() => {
    if (completeDateRange || !showContent) return;
    const currentDate = moment().format(dateFormat);
    const currentMoment = moment(currentDate);
    const rangeStart = currentMoment.startOf("week").format(dateFormat);
    const rangeEnd = currentMoment.endOf("week").format(dateFormat);
    setSelectedDateRange([
      moment(rangeStart).valueOf(),
      moment(rangeEnd).valueOf(),
    ]);
  }, [completeDateRange, dateFormat, setSelectedDateRange, showContent]);

  useEffect(setDateOnAccountSelect, [setDateOnAccountSelect]);

  useEffect(() => {
    setDestinationDrawer(!!destinationUrls.length);
  }, [destinationUrls]);

  useEffect(() => {
    if (destinationDrawer) return;
    setDestinationUrls([]);
  }, [destinationDrawer]);

  const props = {
    layout,
    selectedIndividualAdIds,
    displayColumn,
    destinationUrls,
    destinationDrawer,

    showContent,
    showDetails,
    completeDateRange,

    isLoadingPdfExecutionDescriptions,
    isStartingPdfGeneration,
    isShowingShareModal,

    filterDrawer,
    expand,
    showFlipperDrawer,
    flipWithAds,
  };

  const handlers = {
    setDisplayColumn,
    setDestinationUrls,
    setDestinationDrawer,
    setShowDetails,
    handleGeneratePdfStartExecution,
    setIsShowingShareModal,
    setFilterDrawer,
    setExpand,
    setShowFlipperDrawer,
    setFlipWithAds,
  };

  return {
    props,
    handlers,
  };
};
export default useAdReviewState;
