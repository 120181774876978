import { MenuItem, Stack, TextField } from "@mui/material";
import { config } from "shared/components/media/config";
import { MetaKeywordSelect } from "./MetaKeywordSelect";
import { MetaKeywords } from "./MetaKeywords";
import { TextFieldItem, MultiSelectItem } from "./FieldItems";

type Props = {
  schema: MGSchemas;
  formValues: MGFormValues;
  handleFieldChange: (field: string, value: string | string[]) => void;
};

export const MetaFormItems = ({
  schema,
  formValues,
  handleFieldChange,
}: Props) => {
  return (
    <Stack direction="column" alignItems="center">
      {config.keywordsUrl ? (
        <MetaKeywords handleFieldChange={handleFieldChange} />
      ) : (
        <MetaKeywordSelect handleFieldChange={handleFieldChange} mode="tags" />
      )}
      {schema.xmpschema.map(item => {
        switch (item.type) {
          case "text":
            if (item.field !== "keyword") {
              return (
                <TextFieldItem
                  item={item}
                  formValues={formValues}
                  handleFieldChange={handleFieldChange}
                />
              );
            }
            break;
          case "multiselect":
            return (
              <MultiSelectItem
                item={item}
                formValues={formValues}
                handleFieldChange={handleFieldChange}
              />
            );
          default:
            return (
              <TextField
                key={item.label}
                select
                label={item.label}
                value={formValues[item.field] || ""}
                onChange={e => handleFieldChange(item.field, e.target.value)}
                variant="outlined"
                fullWidth
              >
                {item.values?.map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            );
        }
      })}
    </Stack>
  );
};
