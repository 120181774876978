import { Stack, Typography } from "@mui/material";
import {
  isTextVariable,
  splitIntoComponents,
} from "screens/designStudio/designStudioV2/editorDrawer/editorDrawerContext/helpers.variables";
import { LayerElement } from "screens/designStudio/designStudioV2/types";
import { TextVariableIcon } from "shared/icons/TextVariableIcon";

import { observer } from "mobx-react-lite";
import { TitleRounded } from "@mui/icons-material";
import { isTextElement } from "../../../../utils";

type ExtraLayerDetailsProps = {
  layer: LayerElement;
};

export const ExtraLayerDetails = observer(
  ({ layer }: ExtraLayerDetailsProps) => {
    if (!isTextElement(layer)) return null;

    const components = splitIntoComponents(layer.text);

    if (components.length <= 1) return null;
    return (
      <Stack direction="column">
        {components.map(name => (
          <SubLayerItem name={name} key={name} />
        ))}
      </Stack>
    );
  },
);

type SubLayerItemProps = {
  name: string;
};

export const SubLayerItem = ({ name }: SubLayerItemProps) => {
  const Icon = isTextVariable(name) ? TextVariableIcon : TitleRounded;

  return (
    <Stack
      spacing={1}
      p={1}
      pl={5}
      py={0.5}
      direction="row"
      alignItems="center"
      borderRadius={1}
      borderColor="transparent"
      className="subLayerItem"
      sx={{
        "&:hover": { borderColor: "divider", backgroundColor: "action.hover" },
      }}
    >
      <Icon sx={{ fontSize: "medium", color: "action.active" }} />
      <Typography fontSize="small">{name}</Typography>
    </Stack>
  );
};
