import { getCAMHiResUrl, useCAMSearch } from "shared/components/media";
import { OfferData } from "../../../../shared/types/assetBuilder";
import { getJellybeanImages } from "./offerForm/getJellybeanImages";

export const useGetDefaultOfferImg = () => {
  const searchImg = useCAMSearch();

  return async (offerData: OfferData): Promise<string | undefined> => {
    const { year, make, model, trim } = offerData;

    try {
      const result: WDSearchResult = await searchImg({
        year,
        make,
        model,
        trim,
      });

      if (!result.items || result.items.length === 0) {
        return getJellybeanImages(offerData);
      }

      const firstItem = result.items[0];
      const thumbnailUrl = getCAMHiResUrl(firstItem);
      return thumbnailUrl || getJellybeanImages(offerData);
    } catch (error) {
      console.error("Error fetching default offer image:", error);
      return undefined;
    }
  };
};
