import {
  GroupLayer,
  ProductGroupLayer,
  SmartVariable,
  SmartVariableOptions,
  TemplateFile,
} from "shared/types/salesEnablement";
import styles from "./GroupSelect.module.scss";
import { OnLayerChangeFunc } from "../../Layers";
import { Form, FormItemProps, Select } from "antd";
import { useMemo, useState } from "react";
import {
  isAgentSnippet,
  isProductSnippet,
  isSnippetGroupLayer,
} from "../../utils";
import { pickBy } from "lodash";
import { useProducts } from "shared/hooks/admin/useProducts";

type Props = {
  layer: GroupLayer;
  onChange?: OnLayerChangeFunc;
  groupLayers?: GroupLayer[];
  disabled?: boolean;
  selectedFile?: TemplateFile;
};
const GroupSelect = (props: Props) => {
  const { layer, onChange, disabled, selectedFile } = props;

  const { productTypes } = useProducts();

  const options: Partial<typeof SmartVariableOptions> = useMemo(() => {
    if (!isSnippetGroupLayer(layer)) {
      if (selectedFile?.type === "html") return SmartVariableOptions; // For HTML template, we should return all smart variable options

      return pickBy(SmartVariableOptions, (_, key) => {
        return key !== "agent" && key !== "product" && key !== "manual";
      });
    }

    return pickBy(SmartVariableOptions, (_, key) => {
      return key === "agent" || key === "product" || key === "manual";
    });
  }, [layer, selectedFile]);
  const [formValidation, setFormValidation] = useState<{
    status: FormItemProps["validateStatus"];
    help: string;
  }>();

  const onSelect = (value: SmartVariable) => {
    setFormValidation(undefined);

    // If a snippet group layer is displayed, then user would see 2 options.
    //  1. Agent
    //  2. Product
    // In this case, just update the "source" attr of the OtherGroupLayer type
    if (isSnippetGroupLayer(layer)) {
      onChange?.(
        {
          ...layer,
          smartVariable: value as SmartVariable,
        },
        {
          type: "update_snippet_group",
        },
      );

      // validation has to be done when agent and product smart variable is selected.
      // Typically, we check if the sublayers of the group layer contains the required variables.

      if (value === "agent") {
        if (isAgentSnippet(layer)) return;

        setFormValidation({
          status: "error",
          help: "The Agent component must only contain the variables {{Name}}, {{Phone}}, {{Email}} and {{License}}.",
        });
      } else if (value === "product") {
        if (isProductSnippet(layer)) return;

        setFormValidation({
          status: "error",
          help: "The Product component must only contain the variables {{Product}}.",
        });
      }

      return;
    }

    onChange?.(
      {
        ...layer,
        smartVariable: value as SmartVariable,
      } as GroupLayer,
      {
        type: "update_smart_variable",
      },
    );
  };
  return (
    <Form>
      <Form.Item
        className={styles.formItem}
        validateStatus={formValidation?.status}
        help={formValidation?.help}
      >
        <div className={styles.selection}>
          <div>
            <Select<SmartVariable>
              disabled={disabled}
              value={layer.smartVariable}
              onSelect={onSelect}
              placeholder="Select Smart Component Type"
            >
              {Object.entries(options).map(([key, value]) => (
                <Select.Option key={key} value={key}>
                  {value}
                </Select.Option>
              ))}
            </Select>
          </div>

          {layer.smartVariable === "product" && (
            <Select<string[]>
              placeholder="Select Allowed"
              disabled={disabled}
              mode="multiple"
              value={(layer as ProductGroupLayer).productTypes}
              onChange={productTypes =>
                onChange?.(
                  { ...layer, productTypes, smartVariable: "product" },
                  {
                    type: "update_snippet_group",
                  },
                )
              }
              showSearch
              optionFilterProp="children"
            >
              {productTypes.map(productType => (
                <Select.Option key={productType} value={productType}>
                  {productType}
                </Select.Option>
              ))}
            </Select>
          )}
        </div>
        {["expirationDate", "formNumber"].includes(
          layer.smartVariable ?? "",
        ) && (
          <span className={styles.extraInfo}>
            Will be shown on Marketing Material as set above.
          </span>
        )}
      </Form.Item>
    </Form>
  );
};

export default GroupSelect;
