import { Table } from "antd";
import { LoadStatus } from "screens/adLibrary/pmaxAssetGroups/shared/types";
import {
  GoogleAdCampaignInternal,
  PmaxUploadStatus,
} from "shared/types/adLibrary";
import { IAccount } from "shared/types/accountManagement";
import { getColumns } from "./campaignLoadTable/columns";

export type GoogleAdCampaignInternalWithStatus = GoogleAdCampaignInternal & {
  status: LoadStatus;
};

type CampaignLoadTableProps = {
  store?: IAccount;
  campaigns: GoogleAdCampaignInternal[];
  status?: PmaxUploadStatus;
};

export const CampaignLoadTable = ({
  store,
  campaigns,
  status,
}: CampaignLoadTableProps) => {
  const columns = getColumns(store, status);

  return (
    <Table
      size="small"
      dataSource={campaigns}
      columns={columns}
      pagination={false}
    />
  );
};
