import { useState, useEffect } from "react";
import API from "services";
import { CompositionType, TAssetBatch } from "shared/types/assetExporter";
import { ITemplate } from "shared/types/designStudio";
import { errorNotification } from "shared/components/customNotification/Notification";

/**
 * Fetch all templates used in the selected asset batches
 */
export const useFetchTemplates = (
  assetBatchesForSelectedRows: TAssetBatch[],
) => {
  const [templates, setTemplates] = useState<ITemplate[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchTemplateById = async (
    templateId: string,
  ): Promise<ITemplate | null> => {
    try {
      const response = await API.services.designStudio.getTemplateById(
        templateId,
      );
      if (!response.result?.template) {
        throw new Error(`Template with ID ${templateId} not found.`);
      }
      return response.result?.template;
    } catch (err) {
      throw new Error(
        `Failed to fetch template with ID ${templateId}: ${
          (err as Error).message
        }`,
      );
    }
  };

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        setIsLoading(true);
        const newTemplates: ITemplate[] = [];

        for (const assetBatch of assetBatchesForSelectedRows) {
          for (const composition of assetBatch.compositions) {
            if (composition.type !== CompositionType.Template) continue;
            const template = await fetchTemplateById(composition.template);
            if (!template) continue;
            newTemplates.push(template);
          }
        }

        setTemplates(newTemplates);
      } catch (err) {
        if (err instanceof Error)
          errorNotification({
            messageLabel: `Failed to fetch templates: ${err.message}`,
          });
        errorNotification({
          messageLabel: "Failed to fetch templates: An unknown error occurred.",
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchTemplates();
  }, [assetBatchesForSelectedRows]);

  return { templates, isLoading };
};
