type UserSwitchIconProps = {
  height?: number;
  width?: number;
  color?: string;
};

const UserSwitchIcon = ({
  height = 36,
  width = 36,
  color = "#0070A9",
}: UserSwitchIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 36 36"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.7541 22.6358C30.0791 23.9683 31.1349 25.5483 31.8624 27.2877C32.5796 28.9966 32.9614 30.8068 32.9999 32.6675C33.001 32.7091 32.9937 32.7504 32.9787 32.7891C32.9637 32.8278 32.9411 32.863 32.9124 32.8928C32.8836 32.9225 32.8492 32.9462 32.8113 32.9623C32.7918 32.9705 32.7718 32.9767 32.7513 32.9808C32.7316 32.9848 32.7116 32.9867 32.6914 32.9867H30.9772L30.9786 33H6.02364C6.02153 32.9957 6.0193 32.9912 6.01708 32.9867H4.30859C4.26747 32.9867 4.2267 32.9784 4.18873 32.9623C4.15077 32.9462 4.11644 32.9225 4.08762 32.8928C4.05892 32.863 4.0363 32.8278 4.02131 32.7891C4.00631 32.7504 3.99905 32.7091 4.0001 32.6675C4.03548 30.8186 4.42166 28.9937 5.13776 27.2915C5.86652 25.5529 6.92205 23.9732 8.24588 22.6396C9.57639 21.2927 11.1266 20.2378 12.8543 19.502C12.8698 19.4943 12.8851 19.4904 12.9006 19.4826C10.498 17.7269 8.93621 14.8735 8.93621 11.6542C8.93621 6.32101 13.2168 2 18.5001 2C23.7832 2 28.0638 6.32101 28.0638 11.6542C28.0638 14.8735 26.502 17.7269 24.0994 19.4787C24.1149 19.4865 24.1302 19.4904 24.1457 19.4982C25.8681 20.2339 27.433 21.2994 28.7541 22.6358Z"
      fill="#E6F7FF"
    />
    <path
      d="M27.9239 10.8783C27.9239 5.37388 23.4641 0.914062 17.9596 0.914062C12.4552 0.914062 7.99536 5.37388 7.99536 10.8783C7.99536 14.2051 9.62661 17.1542 12.1337 18.9623C12.1177 18.9703 12.1056 18.9743 12.0976 18.9783C10.3016 19.7377 8.69045 20.8266 7.30429 22.2167C5.92613 23.5924 4.82894 25.2232 4.07393 27.0181C3.33108 28.7757 2.93017 30.6589 2.89268 32.5667C2.89161 32.6096 2.89913 32.6523 2.9148 32.6922C2.93047 32.7321 2.95398 32.7685 2.98393 32.7992C3.01389 32.8299 3.04969 32.8543 3.08922 32.871C3.12875 32.8876 3.17121 32.8962 3.21411 32.8962H5.6208C5.79357 32.8962 5.93821 32.7556 5.94223 32.5828C6.02259 29.481 7.26411 26.5761 9.46187 24.3743C11.732 22.0962 14.7454 20.8426 17.9596 20.8426C23.4641 20.8426 27.9239 16.3828 27.9239 10.8783ZM17.9596 17.7891C14.1427 17.7891 11.0489 14.6953 11.0489 10.8783C11.0489 7.06138 14.1427 3.96763 17.9596 3.96763C21.7766 3.96763 24.8704 7.06138 24.8704 10.8783C24.8704 14.6953 21.7766 17.7891 17.9596 17.7891ZM22.1784 26.6685H32.7855C32.9623 26.6685 33.107 26.5239 33.107 26.3471V24.0971C33.107 23.9203 32.9623 23.7757 32.7855 23.7757H25.694L27.5904 21.3609C27.6342 21.3046 27.6582 21.2354 27.6587 21.1641C27.6587 20.9873 27.5141 20.8426 27.3373 20.8426H24.4204C24.2235 20.8426 24.0387 20.935 23.9141 21.0877L21.1619 24.5873C20.9851 24.8123 20.8887 25.0935 20.8887 25.3828C20.8927 26.094 21.4672 26.6685 22.1784 26.6685ZM31.8212 29.24H21.2141C21.0373 29.24 20.8927 29.3846 20.8927 29.5614V31.8114C20.8927 31.9882 21.0373 32.1328 21.2141 32.1328H28.3056L26.4092 34.5475C26.3655 34.6039 26.3415 34.6731 26.3409 34.7444C26.3409 34.9212 26.4855 35.0658 26.6623 35.0658H29.5793C29.7762 35.0658 29.961 34.9734 30.0855 34.8208L32.8378 31.3212C33.0145 31.0962 33.111 30.815 33.111 30.5257C33.107 29.8145 32.5324 29.24 31.8212 29.24Z"
      fill="#0070A9"
    />
  </svg>
);

export default UserSwitchIcon;
