import { useContext, createContext, ReactNode, useState } from "react";
import { BeeswaxCampaign, LoadCampaignRecord } from "../types";
import { IAccount } from "shared/types/accountManagement";
import { isEmpty } from "lodash";
import { transformCampaignsToRecords } from "../helpers";

type LoadCampaignsContextType = {
  dataSource: LoadCampaignRecord[];
  selectedAccounts: IAccount[];
  selectAccount: (account: IAccount) => void;
  deselectAccount: (id: string) => void;
  setDataSource: React.Dispatch<React.SetStateAction<LoadCampaignRecord[]>>;
  selectedLineItem: LoadCampaignRecord | undefined;
  setSelectedLineItem: React.Dispatch<
    React.SetStateAction<LoadCampaignRecord | undefined>
  >;
  selectedCampaigns: BeeswaxCampaign[];
  session: string | undefined;
  setSession: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const Context = createContext<LoadCampaignsContextType | null>(null);

export const useLoadCampaignsContext = () => {
  const ctx = useContext(Context);

  if (!ctx) {
    throw new Error(
      "useLoadCampaignsContext must be used within a LoadCampaignsContext",
    );
  }
  return ctx;
};

type Props = {
  children: ReactNode;
  selectedCampaigns: BeeswaxCampaign[];
};

export const LoadCampaignsContext = ({
  children,
  selectedCampaigns,
}: Props) => {
  const [dataSource, setDataSource] = useState<LoadCampaignRecord[]>(
    transformCampaignsToRecords(selectedCampaigns),
  );
  const [selectedAccounts, setSelectedAccounts] = useState<IAccount[]>([]);
  const [selectedLineItem, setSelectedLineItem] =
    useState<LoadCampaignRecord>();
  const [session, setSession] = useState<string>();

  const selectAccount = (account: IAccount) => {
    setSelectedAccounts(prev => [...prev, account]);

    setDataSource(prevDataSource => {
      const recordsWithAccount = prevDataSource.filter(
        record => record.accountId,
      );

      const newRecords = transformCampaignsToRecords(
        selectedCampaigns,
        account,
      );

      return [...recordsWithAccount, ...newRecords];
    });
  };

  const deselectAccount = (id: string) => {
    setSelectedAccounts(prev => prev.filter(acc => acc.dealer_name !== id));

    setDataSource(prevDataSource => {
      const recordsWithoutThisAccount = prevDataSource.filter(
        record => record.accountId !== id,
      );

      return isEmpty(recordsWithoutThisAccount)
        ? transformCampaignsToRecords(selectedCampaigns)
        : recordsWithoutThisAccount;
    });
  };

  return (
    <Context.Provider
      value={{
        dataSource,
        selectedAccounts,
        selectAccount,
        deselectAccount,
        setDataSource,
        selectedLineItem,
        setSelectedLineItem,
        selectedCampaigns,
        session,
        setSession,
      }}
    >
      {children}
    </Context.Provider>
  );
};
