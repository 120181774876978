import { Button } from "antd";
import { AgentField, AgentFieldData } from "shared/types/marketingMaterials";
import AgentLayer from "./AgentLayer";
import { getEmptyAgentFieldData } from "../hooks/agentManagement";
import { raise } from "utils/errorMessage";
import { isTruthy } from "utils/helpers.array";
import { useMaterialFormInstance } from "screens/adLibrary/marketingMaterials/MarketingMaterialsProvider";
import { Rule } from "antd/lib/form";

type Props = {
  value?: AgentField;
  onChange?: (agentField: AgentField) => void;
  templateFieldNames: string[];
  disabled?: boolean;
  layerId: string;
  maxItems?: number;
};

const AgentList = ({
  value: agentField,
  onChange: onChangeAgentField,
  templateFieldNames,
  disabled,
  layerId,
  maxItems = 2,
}: Props) => {
  const form = useMaterialFormInstance();
  if (agentField === undefined || !onChangeAgentField) {
    return raise("AgentList must be provided with value and onChange");
  }

  const { agentsData } = agentField;

  const setAgentData =
    (index: number) =>
    (newAgent: AgentFieldData): void => {
      const newAgents = agentsData.map((agent, i) =>
        i === index ? newAgent : agent,
      );
      onChangeAgentField({ ...agentField, agentsData: newAgents });
    };
  const emptyAgentData = getEmptyAgentFieldData(templateFieldNames);

  const removeAgent = (index: number) => {
    const newAgents = agentsData.filter((_, i) => i !== index);
    onChangeAgentField({ ...agentField, agentsData: newAgents });
    // this is because I am using Form.Item to validate the individual fields and it's causing issues when I remove an agent, an alternative solution would be to try Form.List later
    form.setFieldsValue({
      fields: { [layerId]: { ...agentField, agentsData: newAgents } },
    });
  };

  return (
    <div>
      {agentField.agentsData.map((agentData, index) => (
        <AgentLayer
          key={index}
          agentIndex={index}
          agentFieldData={agentData}
          setAgentData={setAgentData(index)}
          removeAgent={removeAgent}
          templateFieldNames={templateFieldNames}
          disabled={disabled}
          layerId={layerId}
          selectedAgentIds={agentsData
            .map(agent => agent.refAgentId)
            .filter(isTruthy)}
        />
      ))}
      {agentsData.length < maxItems && (
        <Button
          type="link"
          onClick={() =>
            onChangeAgentField({
              ...agentField,
              agentsData: [...agentsData, emptyAgentData],
            })
          }
          disabled={disabled}
        >
          + Add{agentsData.length > 0 ? " Another" : ""} Agent
        </Button>
      )}
    </div>
  );
};

export const validateRequired: Rule[] = [
  {
    validator: async (_, value?: AgentField) => {
      if (!value) throw new Error("Agent is required");

      if (!value.agentsData.length) throw new Error("Agent is required");

      return Promise.resolve();
    },
    type: "object",
    required: true,
    message: "This field is required.",
  },
];

export default AgentList;
