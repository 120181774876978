import { useState } from "react";
import { AlertColor } from "@mui/material";
import {
  BlobInput,
  useUpload,
} from "shared/components/media/CAMUpload/useUpload";

type CAMUploadProps = {
  files: BlobInput[];
  folderPath: string;
};

export const useCustomCAMUpload = ({ files, folderPath }: CAMUploadProps) => {
  const [uploading, setUploading] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    title: "",
    message: "",
    severity: "info" as AlertColor,
  });
  const [meta, setMeta] = useState<MGFormValues>();
  const [folderId, setFolderId] = useState<string>();
  const upload = useUpload();

  const handleNotificationClose = () => {
    setNotification(prev => ({ ...prev, open: false }));
  };

  const uploadFiles = async () => {
    if (!files.length) {
      return;
    }
    setUploading(true);
    try {
      const uploadPromises = files.map(file => upload(file, folderId!, meta));
      await Promise.all(uploadPromises);
      setNotification({
        open: true,
        title: "Upload Successful",
        message: `Asset successfully added to ${folderPath}`,
        severity: "success",
      });
      setTimeout(handleNotificationClose, 3000);
    } catch (error) {
      setNotification({
        open: true,
        title: "Upload Failed",
        message: "Error uploading asset. Please try again.",
        severity: "error",
      });
      setTimeout(handleNotificationClose, 3000);
    } finally {
      setUploading(false);
    }
  };

  return {
    uploading,
    uploadFiles,
    setFolderId,
    setMeta,
    notification,
    handleNotificationClose,
  };
};
