import { SelectOutlined } from "@ant-design/icons";
import { message, Result, Spin } from "antd";
import { memo } from "react";

import AdCardListContainer from "./adReview/AdCardListContainer";
import AdTableListContainer from "./adReview/AdTableListContainer";
import HeaderToolbar from "./adReview/HeaderToolbar";
import AdDetailsDrawer from "./adReview/shared/components/AdDetailsDrawer";
import DestinationDrawer from "./adReview/shared/components/DestinationDrawer";

import useAdReviewState from "./adReview/shared/hooks/useAdReviewState";
import useAdReviewPreferences from "screens/campaignManagement/adReview/shared/hooks/useAdReviewPreferences";

import FilterDrawer from "screens/adLibrary/library/FilterDrawer";
import {
  useCampaignManagementFilters,
  useCampaignManagementGlobalFilter,
  useCampaignManagementIds,
} from "screens/adLibrary/shared/hooks/dataListHooks";
import { DataListTagsURL } from "shared/components/dataListURL/DataListTagsURL";
import { useAdReviewAds } from "shared/hooks/adLibrary/adReview/useAdReviewAds";
import { isFeatureEnabled } from "utils/helpers";
import styles from "./AdReview.module.scss";
import AdReviewFilter from "./adReview/AdReviewDrawerFilters";
import ShareAdsModal from "./adReview/ShareAdsModal";
import { getDedupedAds } from "./adReview/shared/utils/helpers.ad";
import FlipperDrawer from "./adReview/FlipperDrawer";

const AdReview = () => {
  message.config({ maxCount: 1 });

  const { props, handlers } = useAdReviewState();
  const {
    ads,
    data,
    isLoading,
    originalData,
    selectedEntry,
    selectedEntryIds,
    setSelectedEntryId,
    facebookAdsFromEntry,
    isGroupedAds,
    setGroupedAds,
    campaigns,
  } = useAdReviewAds();
  const { selectedStore, setSelectedDateRange } = useAdReviewPreferences();
  const storeName = selectedStore?.name || "";

  const {
    layout,
    selectedIndividualAdIds,
    displayColumn,
    isLoadingPdfExecutionDescriptions,
    isStartingPdfGeneration,
    isShowingShareModal,
    filterDrawer,
    expand,
    showContent,
    completeDateRange,
    destinationUrls,
    destinationDrawer,
    showDetails,
    showFlipperDrawer,
    flipWithAds,
  } = props;

  const {
    setShowDetails,
    handleGeneratePdfStartExecution,
    setIsShowingShareModal,
    setFilterDrawer,
    setExpand,
    setDisplayColumn,
    setDestinationUrls,
    setShowFlipperDrawer,
    setFlipWithAds,
  } = handlers;

  const { selectedIds, setSelectedItemIds } = useCampaignManagementIds();
  const { setGlobalFilter } = useCampaignManagementGlobalFilter();
  const { filters, resetFilters } = useCampaignManagementFilters();

  const selectedCount =
    selectedIndividualAdIds.length &&
    getDedupedAds(data, selectedIndividualAdIds).length;

  const isEnhancedSearchEnabled = isFeatureEnabled("ENABLE_ENHANCED_SEARCH");

  return (
    <div className={styles.container}>
      <DestinationDrawer
        destinationUrls={destinationUrls}
        destinationDrawer={destinationDrawer}
      />
      {facebookAdsFromEntry && (
        <AdDetailsDrawer
          showDetails={showDetails}
          selectedEntry={selectedEntry}
          facebookAdsFromEntry={facebookAdsFromEntry}
          selectedCount={selectedCount}
          selectedEntryIds={selectedEntryIds}
          setShowDetails={setShowDetails}
          setSelectedEntryId={setSelectedEntryId}
        />
      )}
      <HeaderToolbar
        showContent={showContent}
        displayColumn={displayColumn}
        validDateRange={completeDateRange}
        allowSelected={selectedIndividualAdIds.length > 0}
        selectedAdIds={selectedIndividualAdIds}
        isLoadingPdfExecutionDescriptions={isLoadingPdfExecutionDescriptions}
        isStartingPdfGeneration={isStartingPdfGeneration}
        handleGeneratePdfStartExecution={handleGeneratePdfStartExecution}
        setDisplayColumn={setDisplayColumn}
        showShareModal={() => setIsShowingShareModal(true)}
        enhancedSearch={isEnhancedSearchEnabled}
        onGroupChange={setGroupedAds}
        isGroupedAds={isGroupedAds}
        filterDrawer={filterDrawer}
        setFilterDrawer={setFilterDrawer}
        onSearch={setGlobalFilter}
        expand={expand}
        setExpand={setExpand}
        setShowFlipperDrawer={setShowFlipperDrawer}
      />
      <FlipperDrawer
        storeName={storeName}
        showDrawer={showFlipperDrawer}
        setShowDrawer={setShowFlipperDrawer}
        campaigns={campaigns}
        flipWithAds={flipWithAds}
        setFlipWithAds={setFlipWithAds}
        setSelectedDateRange={setSelectedDateRange}
      />
      <Spin spinning={isLoading}>
        {!showContent && (
          <Result
            icon={<SelectOutlined />}
            title="Select an account to begin."
          />
        )}
        {showContent && (
          <>
            <div className={styles.filtersContainer}>
              <DataListTagsURL
                data={data}
                originalData={originalData}
                showSelectAll={layout === "card"}
              />
            </div>
          </>
        )}
        {showContent &&
          (layout === "table" ? (
            <AdTableListContainer
              dataSource={data}
              displayColumn={displayColumn}
              setDisplayColumn={setDisplayColumn}
              setDestinationUrls={setDestinationUrls}
              selectedRowKeys={selectedIds}
              setSelectedRowKeys={setSelectedItemIds}
              setSelectedEntryId={setSelectedEntryId}
              setShowDetails={setShowDetails}
              expand={expand}
            />
          ) : (
            <>
              <AdCardListContainer
                dataSource={data}
                facebookAds={ads}
                setShowDetails={setShowDetails}
                setSelectedEntryId={setSelectedEntryId}
              />
              <FilterDrawer
                filterDrawer={filterDrawer}
                setFilterDrawer={setFilterDrawer}
                resetFilters={resetFilters}
                isSomeFilterApplied={Object.values(filters).some(
                  value => !!value,
                )}
              >
                <AdReviewFilter />
              </FilterDrawer>
            </>
          ))}
        {isShowingShareModal && (
          <ShareAdsModal onClose={() => setIsShowingShareModal(false)} />
        )}
      </Spin>
    </div>
  );
};

export default memo(AdReview);
