import { Player, PlayerRef } from "@remotion/player";
import { Button, Col, Drawer, Row, Space } from "antd";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useAssetBatchesContext } from "../../shared/contexts/AssetBatchesContext";
import { useVideoStitchingContext } from "../../shared/contexts/VideoStitchingContext";
import { VideoComposition } from "./VideoComposition";
import styles from "./VideoStitching.module.scss";
import { DEFAULT_FPS } from "../../shared/constants";
import { useVideoPlayer } from "../../shared/hooks/useVideoPlayer";
import { SpinnerLoading } from "screens/assetExporter/feedConfiguration/SpinnerLoading";
import usePrefetchVideoData from "../../shared/hooks/usePrefechVideoData";
import {
  renderFullscreenButton,
  renderPlayPauseButton,
} from "../../shared/components/VideoPlayerControllers";
import { videoCompositionAudioFilesEnabled } from "shared/constants/assetExporter";
import { AudioFilePreview } from "./AudioFilePreview";

interface Props {
  fps?: number;
}

export const VideoStitching = ({ fps = DEFAULT_FPS }: Props) => {
  const [player, setPlayer] = useState<PlayerRef | null>(null);
  const {
    compositions,
    artboard,
    audioFiles,
    setAudioFiles,
    setEditingAudioFiles,
    editingAudioFiles,
  } = useAssetBatchesContext();

  const [audioTrackActive, setAudioTrackActive] = useState(true);

  const {
    setIsVideoPreviewOpen,
    setIsPlaying,
    setCurrentFrame,
    currentFrame,
    setPlayerRef,
    canvas,
    setBackgroundAudios,
    isLoading,
    isVideoPreviewOpen,
  } = useVideoStitchingContext();
  const { togglePlaying } = useVideoPlayer({
    canvas,
    currentFrame,
  });
  const containerRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ height: 720, width: 720 });

  useEffect(() => {
    setEditingAudioFiles(audioFiles);
  }, [audioFiles, setEditingAudioFiles]);

  const closePreviewHandler = async () => {
    await player?.pause();
    setCurrentFrame(0);
    setIsPlaying(false);
    togglePlaying(false, 0);

    setBackgroundAudios(prev => {
      Object.values(prev).forEach(audio => {
        audio?.pause();
      });

      return {};
    });
    setEditingAudioFiles(audioFiles);
    setIsVideoPreviewOpen(false);
  };

  const resetAudioFilesHandler = () => {
    setEditingAudioFiles(audioFiles);
  };

  const saveAudioFilesHandler = () => {
    setAudioFiles(editingAudioFiles);
    closePreviewHandler();
  };

  useLayoutEffect(() => {
    if (containerRef.current && artboard) {
      const clientWidth = containerRef.current.clientWidth;
      const clientHeight = containerRef.current.clientHeight - 140;

      const ratioWidth = clientWidth / (artboard?.width ?? 1);
      const ratioHeight = clientHeight / (artboard?.height ?? 1);

      if (ratioWidth > ratioHeight) {
        const width = Math.round(
          ((artboard?.width ?? 1) / (artboard?.height ?? 1)) * clientHeight,
        );
        setDimensions({ height: clientHeight, width });
      } else {
        const height = Math.round(
          ((artboard?.height ?? 1) / (artboard?.width ?? 1)) * clientWidth,
        );
        setDimensions({ height, width: clientWidth });
      }
    }
  }, [artboard]);

  const duration = compositions
    .map(comp => comp.duration ?? 5)
    .reduce((a, b) => a + b, 0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (player) {
        const newFrame = player.getCurrentFrame();
        setCurrentFrame(newFrame);
      }
    }, 1000 / fps);

    return () => clearInterval(interval);
  }, [currentFrame, fps, player, setCurrentFrame]);

  usePrefetchVideoData();

  useEffect(() => {
    if (player) {
      setPlayerRef(player);
    }
  }, [setPlayerRef, player]);

  const compositionWidth = dimensions.width > 0 ? dimensions.width : 720;
  const compositionHeight = dimensions.height > 0 ? dimensions.height : 720;
  const shouldShowAudioFilePreview =
    videoCompositionAudioFilesEnabled &&
    editingAudioFiles.length > 0 &&
    editingAudioFiles.some(audioFile => audioFile.src);

  return (
    <Drawer
      closable={false}
      title={<strong>Preview and Edit</strong>}
      placement="right"
      visible={isVideoPreviewOpen}
      width={"calc(100vw - 40px)"}
      destroyOnClose={true}
      footer={
        <div className={styles.footer}>
          <Button onClick={resetAudioFilesHandler}>Undo</Button>
          <Space>
            <Button onClick={closePreviewHandler}>Cancel</Button>
            <Button type="primary" onClick={saveAudioFilesHandler}>
              Save
            </Button>
          </Space>
        </div>
      }
      footerStyle={{ display: "flex", justifyContent: "flex-end" }}
      bodyStyle={{ padding: 0 }}
    >
      <div className={styles.compositionContainer}>
        <Row>
          <Col span={24}>
            <div className={styles.contentContainer}>
              <div className={styles.header}>
                <span>
                  Dimensions: {artboard?.width}x{artboard?.height}
                </span>
              </div>
              <div
                className={styles.playerContainer}
                ref={containerRef}
                style={{ minHeight: `${dimensions.height}px` }}
              >
                {isLoading ? (
                  <SpinnerLoading />
                ) : (
                  <Player
                    component={VideoComposition}
                    durationInFrames={duration * fps}
                    compositionWidth={compositionWidth}
                    compositionHeight={compositionHeight}
                    renderPlayPauseButton={renderPlayPauseButton}
                    renderFullscreenButton={renderFullscreenButton}
                    fps={fps}
                    controls
                    ref={setPlayer}
                    inputProps={{
                      fps: fps,
                      playerWidth: dimensions.width,
                      playerHeight: dimensions.height,
                    }}
                    numberOfSharedAudioTags={10}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {shouldShowAudioFilePreview && (
              <div
                className={`${styles.audioFilePreviewContainer} ${
                  audioTrackActive ? styles.active : ""
                }`}
              >
                <AudioFilePreview
                  duration={duration}
                  active={audioTrackActive}
                  setActive={setAudioTrackActive}
                  audioFiles={editingAudioFiles}
                />
              </div>
            )}
          </Col>
        </Row>
      </div>
    </Drawer>
  );
};
