import { useCallback, useEffect, useState } from "react";
import { useForm } from "antd/lib/form/Form";
import { ILogos } from "shared/types/brandsAccounts";
import { AccountBaseDrawer } from "./AccountBaseDrawer";
import { OperationMode } from "shared/types/inputValues";
import {
  IAccount,
  IAccountRecord,
  initialAccountObj,
} from "shared/types/accountManagement";
import { useCreateAccount } from "shared/hooks/brandsAccountsManagement/useCreateAccount";
import { useUpdateAccount } from "shared/hooks/brandsAccountsManagement/useUpdateAccount";
import { dealerToDealerRecordData } from "utils/helpers";
import { useLogos } from "shared/hooks/brandsAccountsManagement/useLogos";
import { useBrandsAccountsContext } from "screens/brandsAccountsManagement/contexts/BrandsAccountsContext";

interface AccountDrawerProps {
  visible: boolean;
  mode: OperationMode;
  title: string;
  accountToUpdate?: IAccount;
  brandNameFromDrawer?: string;
  onCancel: () => void;
  accountDrawerRef?: React.MutableRefObject<HTMLDivElement | null>;
}

export const AccountDrawer = ({
  brandNameFromDrawer,
  ...props
}: AccountDrawerProps) => {
  const [form] = useForm<IAccountRecord>();
  const { drawerState } = useBrandsAccountsContext();

  const [accountToEdit, setAccountToEdit] = useState<IAccountRecord>(
    props.mode === "UPDATE"
      ? dealerToDealerRecordData(props.accountToUpdate)
      : {
          ...initialAccountObj,
          dealerOem:
            brandNameFromDrawer ||
            (drawerState.targetElement as string | undefined) ||
            "",
          newDealerName: initialAccountObj.dealerName,
        },
  );

  const { filterLogos, initialLogoValues, logosEmpty } = useLogos({
    accountToEdit,
  });

  const [logos, setLogos] = useState<ILogos>(initialLogoValues);
  const [logosToRemove, setLogosToRemove] =
    useState<Record<keyof ILogos, string[]>>(logosEmpty);

  useEffect(() => {
    if (props.mode === "UPDATE") {
      const updatedValues = {
        newDealerName: accountToEdit.newDealerName,
        dealerCode: accountToEdit.dealerCode,
        dealerUrl: accountToEdit.dealerUrl,
      };
      form.setFieldsValue(updatedValues);
    }
  }, [form, accountToEdit, props.mode]);

  const onResetForm = useCallback(() => {
    form.resetFields();
    setLogos(logosEmpty);
  }, [form, logosEmpty]);

  const onCreateAccountSuccess = useCallback(() => {
    onResetForm();
    props.onCancel();
  }, [props, onResetForm]);

  const { mutate: createAccount, isLoading: createAccountIsLoading } =
    useCreateAccount({ onSuccess: onCreateAccountSuccess });

  const { mutate: updateAccount, isLoading: updateAccountIsLoading } =
    useUpdateAccount({
      onSuccess: () => {
        setLogosToRemove(logosEmpty);
      },
    });

  const onSubmit = useCallback(async () => {
    switch (props.mode) {
      case "CREATE":
        createAccount({
          logos: logos,
          account: accountToEdit,
        });
        break;
      case "UPDATE":
        const formValues = {
          logosToUpload: {
            horizontalLogos: filterLogos(logos.horizontalLogos, true),
            squareLogos: filterLogos(logos.squareLogos, true),
            verticalLogos: filterLogos(logos.verticalLogos, true),
          },
          existingLogos: {
            horizontalLogos: filterLogos(logos.horizontalLogos, false),
            squareLogos: filterLogos(logos.squareLogos, false),
            verticalLogos: filterLogos(logos.verticalLogos, false),
          },
        };

        updateAccount({
          params: {
            ...formValues,
            account: accountToEdit,
            logosToRemove: Object.values(logosToRemove).flat(),
          },
          originalAccount: accountToEdit,
        });
        break;
      default:
        break;
    }
  }, [
    props.mode,
    createAccount,
    logos,
    accountToEdit,
    filterLogos,
    updateAccount,
    logosToRemove,
  ]);

  const onRemoveLogo = useCallback(
    (url: string, fieldKey: keyof ILogos) => {
      setLogosToRemove(prev => ({
        ...prev,
        [fieldKey]: [...prev[fieldKey], url],
      }));
    },
    [setLogosToRemove],
  );

  return (
    <AccountBaseDrawer
      buttonText="Save"
      drawerTitle={props.title}
      mutationIsLoading={createAccountIsLoading || updateAccountIsLoading}
      onSubmit={onSubmit}
      logos={logos}
      setLogos={setLogos}
      form={form}
      accountToEdit={accountToEdit}
      setAccountToEdit={setAccountToEdit}
      onRemoveLogo={onRemoveLogo}
      {...props}
    />
  );
};
