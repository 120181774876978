import { FigureLayerElement } from "screens/designStudio/designStudioV2/types";
import { EffectsMenu } from "../containers/EffectsMenu";
import Shadow from "../layerEffects/Shadow";
import { observer } from "mobx-react-lite";
import { Typography, Switch } from "@mui/material";
import { StackContainer } from "../containers/StackContainer";

type FigureEffectsEditorProps = {
  elements: FigureLayerElement[];
};

export const FigureEffectsEditor = observer(
  ({ elements }: FigureEffectsEditorProps) => {
    const isShadowEnabled = Boolean(elements[0]?.shadowEnabled);

    const handleShadowToggle = () =>
      elements.forEach(el => el.set({ shadowEnabled: !isShadowEnabled }));

    return (
      <EffectsMenu>
        <StackContainer>
          <Switch checked={!!isShadowEnabled} onChange={handleShadowToggle} />
          <Typography variant="caption">Shadow</Typography>
        </StackContainer>
        {isShadowEnabled && <Shadow elements={elements} />}
      </EffectsMenu>
    );
  },
);
