import { Language } from "shared/types/salesEnablement";
import { states } from "./states";

export const defaultLanguageOptions: { value: Language; label: string }[] = [
  {
    value: "en",
    label: "English",
  },
  {
    value: "es",
    label: "Spanish",
  },
];

export const specialStateMessage: Record<string, string> = {
  [states.AR.id]: "AR Insurance Producer License No. XXXXXXXX",
  [states.CA.id]: "CA License No. XXXXXXX",
};

export const specialStateLicensePattern: Record<string, string> = {
  [states.AR.id]: "AR Insurance Producer License No\\. [a-zA-Z0-9]{8,14}",
  [states.CA.id]: "CA License No\\. [a-zA-Z0-9]{7,14}",
};
