/* eslint-disable no-console */
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { useAuth0 } from "@auth0/auth0-react";
import { createClient } from "graphql-ws";
import { useCallback } from "react";

const HASURA_ENDPOINT =
  process.env.REACT_APP_AV2_HASURA_ENDPOINT || "localhost:8080";

const apolloClientHolder = {
  client: null,
} as {
  client: ApolloClient<any> | null;
};

export const useApolloClient = () => {
  const { getAccessTokenSilently } = useAuth0();

  const auth = useCallback(
    async (headers: any = {}) => {
      const token = await getAccessTokenSilently();

      return {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : "",
        },
      };
    },
    [getAccessTokenSilently],
  );

  if (apolloClientHolder.client) {
    return apolloClientHolder;
  }

  const authLink = setContext(async (req, { headers }) => auth(headers));
  const httpLink = createHttpLink({
    uri: `http${
      HASURA_ENDPOINT.includes("localhost") ? "" : "s"
    }://${HASURA_ENDPOINT}/v1/graphql`,
  });
  const wsLink = new GraphQLWsLink(
    createClient({
      url: `wss://${HASURA_ENDPOINT}/v1/graphql`,
      connectionParams: auth,
    }),
  );
  const sublink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink,
  );
  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(data => {
        const opName = operation.operationName;
        const vars = operation.variables;
        console.error(`[GraphQL error]: ${opName} - ${data.message}`);
        console.error(`variables: ${JSON.stringify(vars, null, 2)}`);
      });
    if (networkError) console.error(`[Network error]: ${networkError}`);
  });
  const link = ApolloLink.from([errorLink, authLink, sublink]);
  const cache = new InMemoryCache({});
  const client = new ApolloClient({ link, cache, connectToDevTools: true });
  apolloClientHolder.client = client;
  return { client };
};
