import { Component, ReactNode, useEffect, useState } from "react";
import API from "services";
import { IClientError } from "shared/types/errors";
import ErrorFeedback from "./ErrorFeedback";
import { useLocation } from "react-router-dom";

interface Props {
  children: ReactNode;
  feedbackComponent?: ReactNode;
  hasError: boolean;
  setHasError: (hasError: boolean) => void;
}

interface State {
  hasError: boolean;
}

class ErrorBoundaryInner extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidUpdate(prevProps: Props) {
    if (!this.props.hasError && prevProps.hasError) {
      this.setState({ hasError: false });
    }
  }

  public async componentDidCatch(error: Error /*, errorInfo: ErrorInfo*/) {
    this.props.setHasError(true);

    const clientError: IClientError = {
      browser: navigator.appCodeName,
      os: navigator.platform,
      url: location.href,
      client: process.env.REACT_APP_AV2_CLIENT,
      env: process.env.REACT_APP_ENV!,
      datetime: new Date().getTime(),
      page: location.pathname,
      viewportSize: `${window.innerWidth} x ${window.innerHeight}`,
      // TODO: get username from auth0
      username: "",
      error: error.message,
      stacktrace: error.stack,
    };

    if (process.env.NODE_ENV === "development") {
      return;
    }

    API.services.errorLogging.postClientError(clientError);
  }

  public render() {
    if (this.state.hasError) {
      return this.props.feedbackComponent ?? <ErrorFeedback />;
    }

    return this.props.children;
  }
}

function ErrorBoundary({
  children,
  feedbackComponent,
}: {
  children: ReactNode;
  feedbackComponent?: ReactNode;
}) {
  const [hasError, setHasError] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setHasError(false);
  }, [location.key]);
  return (
    /**
     * NEW: The class component error boundary is now
     *      a child of the functional component.
     */
    <ErrorBoundaryInner
      hasError={hasError}
      setHasError={setHasError}
      feedbackComponent={feedbackComponent}
    >
      {children}
    </ErrorBoundaryInner>
  );
}
export default ErrorBoundary;
