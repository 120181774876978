import { FileDoneOutlined } from "@ant-design/icons";
import { notification } from "antd";
import {
  PmaxUploadProgressNotification,
  isCompleteNotificationData,
} from "./helpers";
import { ProgressNotification } from "./ProgressNotification";

export const PMAX_UPLOAD_NOTIFICATION_KEY = "pmaxUploadNotification";

export const useUploadProgressNotification = (
  notificationData: PmaxUploadProgressNotification,
  title: string,
) => {
  if (isCompleteNotificationData(notificationData)) {
    notification.open({
      key: PMAX_UPLOAD_NOTIFICATION_KEY,
      message: title,
      icon: <FileDoneOutlined style={{ color: "#1890FF" }} />,
      duration: 0,
      bottom: 50,
      placement: "bottomRight",
      description: <ProgressNotification {...notificationData} />,
    });
  }

  return {
    closeNotification: () => {
      notification.close(PMAX_UPLOAD_NOTIFICATION_KEY);
    },
  };
};
