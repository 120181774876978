import { Spin, message } from "antd";
import classnames from "classnames";
import { fabric } from "fabric";
import { keys } from "lodash";
import ResizeObserver from "rc-resize-observer";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useCAMFonts } from "shared/components/media";
import { videoCompositionEnabled } from "shared/constants/assetExporter";
import { getBrandsByName } from "shared/hooks/brandsAccountsManagement/useFetchBrandsByName";
import { IDimension } from "shared/types/designStudio";
import { getUsedFontsFromLoadedJson } from "utils/fabric/helpers.text";
import { loadFont as loadFontFn } from "utils/helpers";
import {
  addPaddingAdjustment,
  fetchTemplateJson,
  getGroupedMappings,
  getHighlightBox,
  getVarsGroupById,
  highlightVariablesInCanvas,
  removeImageForImageVar,
  renderImageVars,
  scaleCanvas,
  setImageForVideoVar,
} from "../../assetBatchDrawer/dynamicText/utils.fabric";
import { setTextboxDataForCanvas } from "../../assetBatchDrawer/dynamicText/utils.variable";
import { useAssetBatchesContext } from "../contexts/AssetBatchesContext";
import { getImageMappingValue } from "../contexts/AssetBatchesContext.utils";
import { useAssetBatchesRenderContext } from "../contexts/AssetBatchesRenderContext";
import { useAssetBatchesValueMappingContext } from "../contexts/AssetBatchesValueMappingContext";
import { loadFontsFromTemplate } from "../utils";
import {
  isExtendedFabricObject,
  isFixedVideo,
  isImage,
  isTextbox,
  isVideo,
} from "../validators";
import styles from "./Preview.module.scss";
import { MaskItems } from "./Preview/MaskItems";
import { NavTemplateItems } from "./Preview/NavTemplateItems";
import PlaybackControls from "./Preview/PlaybackControls";
import { renderTextVars, setTargetOpacity } from "./Preview/utils";
import { ReloadTemplate } from "./ReloadTemplate";

type Props = {
  ChangeTemplateNode?: React.ReactNode;
  useMask?: boolean;
};

const Preview = ({ useMask = true }: Props) => {
  const canvasContainerRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const {
    previewLoading,
    setPreviewLoading,
    currentStep,
    showReload,
    currentCompositionId,
    useTemplateBackgroundEffect,
    editingTemplate,
    backgroundMedias,
    compositions,
    setHasFixedBackground,
  } = useAssetBatchesContext();

  const {
    canvas,
    setCanvas,
    setCanvasContainerDim,
    valueMappings,
    onResize,
    isMediaMaskOn,
    isTextMaskOn,
    currentSwitchType,
    template,
    setCurrentSwitchType,
    templateHasFixedVideo,
    setTemplateHasFixedVideo,
    editingComposition,
  } = useAssetBatchesRenderContext();

  const {
    changedMappingKey,
    setChangedMappingKey,
    hoveredMappingKey,
    selectedRow,
    emptyValueHighlight,
    showVariablesOn,
  } = useAssetBatchesValueMappingContext();

  const compositionIndex = compositions.indexOf(editingComposition);

  const { fontFamilies, loadFont: loadCAMFont } = useCAMFonts();
  const [loadedCamFonts, setLoadedCamFonts] = useState<boolean>(false);

  useEffect(() => {
    const loadFonts = async () => {
      if (!template) return;
      const json = await fetchTemplateJson(template);
      const fonts = getUsedFontsFromLoadedJson(json);
      const promises = fonts.map(font => {
        if (!fontFamilies.includes(font)) return Promise.resolve();
        return loadCAMFont(font, loadFontFn);
      });
      await Promise.all(promises);
      fabric.util.clearFabricFontCache();
      setLoadedCamFonts(true);
    };
    loadFonts();
  }, [fontFamilies, loadCAMFont, loadedCamFonts, template]);

  useEffect(() => {
    if (!template || !loadedCamFonts) return;
    const newCanvas = new fabric.Canvas(
      `canvas_${editingComposition.compositionId}_${editingComposition.template}`,
    );
    newCanvas.setWidth(0);
    newCanvas.setHeight(0);
    setChangedMappingKey(undefined);

    const canvasContainerRect =
      canvasContainerRef.current?.getBoundingClientRect();
    if (!canvasContainerRect) return;

    const render = async () => {
      setPreviewLoading(true);
      const json = await fetchTemplateJson(template);

      const brands = template.oems.length
        ? await getBrandsByName(template.oems)
        : [];
      const brandFonts = brands?.flatMap(brand => brand.fonts) ?? [];

      await loadFontsFromTemplate(json, brandFonts);

      setTemplateHasFixedVideo(false);
      newCanvas.loadFromJSON(json, () => {
        const backgroundImg = newCanvas.backgroundImage;
        if (isImage(backgroundImg) && backgroundImg.visible)
          setHasFixedBackground(true);
        newCanvas.getObjects().forEach(obj => {
          obj.set({ selectable: false, evented: false });
          if (isTextbox(obj)) setTextboxDataForCanvas(obj);
          if (isFixedVideo(obj)) setTemplateHasFixedVideo(true);
          if (isVideo(obj) || isFixedVideo(obj))
            setImageForVideoVar(
              newCanvas,
              obj,
              obj.customData.videoSrc,
              "fill",
            );
          addPaddingAdjustment(obj, newCanvas);
        });
        const { artboard } = template;
        const canvasDim: IDimension = {
          width: artboard.width,
          height: artboard.height,
        };
        const containerDim: IDimension = {
          width: canvasContainerRect.width,
          height: canvasContainerRect.height,
        };
        setCanvasContainerDim({
          width: canvasContainerRect.width,
          height: canvasContainerRect.height,
        });
        scaleCanvas(newCanvas, canvasDim, containerDim);
        setCanvas(newCanvas);
        setPreviewLoading(false);
      });
    };

    render().catch(() => {
      message.error("Failed to render template.");
      setPreviewLoading(false);
    });
  }, [
    template,
    setCanvas,
    setCanvasContainerDim,
    setPreviewLoading,
    setTemplateHasFixedVideo,
    setChangedMappingKey,
    editingComposition.compositionId,
    editingComposition.template,
    compositionIndex,
    loadedCamFonts,
    setHasFixedBackground,
  ]);

  useEffect(() => {
    if (!canvas || !selectedRow) return;

    highlightVariablesInCanvas(
      hoveredMappingKey,
      canvasRef,
      currentCompositionId,
      canvas,
      emptyValueHighlight,
      valueMappings,
      selectedRow,
    );
  }, [
    canvas,
    hoveredMappingKey,
    valueMappings,
    selectedRow,
    emptyValueHighlight,
    editingComposition,
    currentCompositionId,
  ]);

  useEffect(() => {
    if (!canvas) return;

    if (useMask) {
      const varsGroupById = getVarsGroupById(valueMappings);

      keys(varsGroupById).forEach(id => {
        if (changedMappingKey && !changedMappingKey.includes(id)) return;
        const { groupedMappings, mappings } = getGroupedMappings(
          isMediaMaskOn,
          showVariablesOn,
          varsGroupById,
          id,
          isTextMaskOn,
        );

        const target = canvas
          ?.getObjects()
          .filter(isExtendedFabricObject)
          .find(obj => obj.name === id);
        if (!target) return;
        if (!mappings[0].value && !varsGroupById[id][0].value) {
          const highlightBox = getHighlightBox(target, false, false);
          if (highlightBox) {
            canvas?.add(highlightBox);
            canvas?.renderAll();
          }
        }
        renderTextVars(target, groupedMappings, selectedRow, showVariablesOn);
        if (currentSwitchType !== "text" && showVariablesOn)
          renderImageVars(target, mappings, currentStep, canvas, selectedRow);
        else {
          canvas.setBackgroundColor("lightgrey", () => {
            removeImageForImageVar(canvas, target);
            setTargetOpacity(target, 1);
          });
        }
      });

      canvas.renderAll();
    } else {
      canvas.getObjects().forEach(obj => {
        if (!isTextbox(obj)) return;

        const originalText = (obj as any).originalText;
        obj.set({ text: originalText });
      });

      canvas.renderAll();
    }
  }, [
    valueMappings,
    canvas,
    selectedRow,
    useMask,
    isTextMaskOn,
    isMediaMaskOn,
    currentSwitchType,
    setCurrentSwitchType,
    currentStep,
    changedMappingKey,
    showVariablesOn,
  ]);

  useTemplateBackgroundEffect(
    canvas,
    editingComposition,
    isMediaMaskOn,
    showVariablesOn,
    selectedRow,
    backgroundMedias,
    undefined,
    undefined,
  );

  const canvasClientWidth = canvasRef.current?.parentElement?.clientWidth;
  const canvasClientHeight = canvasRef.current?.parentElement?.clientHeight;

  const showPlayButton = useMemo(() => {
    if (videoCompositionEnabled) return false;
    if (templateHasFixedVideo) return true;
    return Object.keys(valueMappings).some(mappingKey => {
      const mapping = valueMappings[mappingKey];
      const mappingValue: string = getImageMappingValue(mapping, selectedRow);
      return mappingValue?.toString().includes("mp4");
    });
  }, [templateHasFixedVideo, valueMappings, selectedRow]);

  return (
    <div className={styles.previewContainer}>
      {!videoCompositionEnabled && (
        <>
          <div className={styles.templateNav}>
            <NavTemplateItems />
          </div>
          <MaskItems step={currentStep} />
        </>
      )}
      <div className={styles.preview}>
        <ResizeObserver
          onResize={({ width, height }) => onResize({ width, height })}
        >
          <div
            ref={canvasContainerRef}
            className={styles.canvasContainer}
            data-cy="canvas-preview"
          >
            <Spin
              spinning={previewLoading}
              className={styles.spin}
              size="large"
            />
            <canvas
              id={`canvas_${editingComposition.compositionId}_${editingComposition.template}`}
              ref={canvasRef}
              className={
                previewLoading
                  ? classnames([styles.canvasHidden, styles.canvas])
                  : styles.canvas
              }
            />
            {showReload && editingTemplate === editingComposition.template && (
              <ReloadTemplate
                canvasClientHeight={canvasClientHeight}
                canvasClientWidth={canvasClientWidth}
              />
            )}
          </div>
        </ResizeObserver>
      </div>
      {showPlayButton && (
        <div className={styles.playbackControls}>
          <PlaybackControls />
        </div>
      )}
    </div>
  );
};

export default Preview;
