import { isArray } from "lodash";
import { dedupe, isTruthy } from "./helpers.array";
import {
  type KeysWithStringOrUndefinedValues,
  type KeysWithArrayOrUndefinedValues,
  type KeysWithStringOrUndefinedOrArrayValues,
} from "./utilityTypes";

export const compareStringBy = <T>(
  iteratee:
    | KeysWithStringOrUndefinedValues<T>
    | ((record: T) => string | undefined),
) => {
  const getValue =
    typeof iteratee === "function" ? iteratee : (record: T) => record[iteratee];

  return (a: T, b: T) =>
    String(getValue(a) ?? "").localeCompare(String(getValue(b) ?? ""));
};

type Value = string | number | boolean;
/**
 * Returns a filter function that filters by the given key or function.
 * In case the value is an array, it will return true if the record value includes the filter value.
 * @param iteratee The key or function to get the value from the record to filter by.
 * @param options The options for the filter. Defaults to "equals" and case sensitive.
 * @returns A filter function that returns whether the record value matches the filter value.
 */
export function onFilterBy<T>(
  iteratee:
    | KeysWithArrayOrUndefinedValues<T>
    | ((record: T) => string[] | undefined),
): (value: Value | string[], record: T) => boolean;
export function onFilterBy<T>(
  iteratee:
    | KeysWithStringOrUndefinedValues<T>
    | ((record: T) => string | undefined),
  options?: {
    /**
     * If `matchMode` is "includes", the filter will return true if the record value includes the filter value.
     * @default "equals"
     */
    matchMode?: "equals" | "includes";
    /**
     * If `caseInsensitive` is `true`, the filter will be case insensitive.
     * @default false
     */
    caseInsensitive?: boolean;
  },
): (value: Value | string[], record: T) => boolean;
export function onFilterBy<T>(
  iteratee:
    | KeysWithStringOrUndefinedOrArrayValues<T>
    | ((record: T) => string | string[] | undefined),
  options?: {
    matchMode?: "equals" | "includes";
    caseInsensitive?: boolean;
  },
): (value: Value | string[], record: T) => boolean {
  return (value: Value | string[], record: T) => {
    const getSrtValue = (val: Value) => {
      return options?.caseInsensitive ? String(val).toLowerCase() : String(val);
    };

    const recordValue =
      typeof iteratee === "function"
        ? iteratee(record)
        : (record[iteratee] as string | string[] | undefined);

    if (!recordValue) return false;

    if (isArray(recordValue)) {
      return isArray(value)
        ? recordValue.some(val1 =>
            value.some(val2 => compareValues(val1, val2)),
          )
        : recordValue.some(val => compareValues(val, value));
    }
    if (isArray(value)) {
      return value.some(val => compareValues(recordValue, val));
    }

    function compareValues(val1: Value, val2: Value) {
      return options?.matchMode === "includes"
        ? getSrtValue(val1).includes(getSrtValue(val2))
        : getSrtValue(val1) === getSrtValue(val2);
    }

    return compareValues(recordValue, value);
  };
}

export const getFiltersFromData =
  <T>(dataSource: T[]) =>
  (
    key: KeysWithStringOrUndefinedValues<T>,
    options?: {
      splitBy?: string;
      sorted?: boolean;
      textMapper?: (value: string) => string;
    },
  ) => {
    const sorted = options?.sorted ?? true;
    const splitBy = options?.splitBy ?? "";

    return dataSource
      .slice()
      .sort(sorted ? compareStringBy(key) : undefined)
      .map(target => target[key] as string | undefined)
      .filter(isTruthy)
      .flatMap(value => (splitBy ? value.split(splitBy) : value))
      .filter(dedupe)
      .map(value => ({
        text: options?.textMapper ? options.textMapper(value) : value,
        value,
      }));
  };
