import { isArray, isEmpty } from "lodash";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { loadFontsFromTemplate } from "screens/assetExporter/feedConfigurationV2/shared/utils";
import API from "services";
import { getBrandsByName } from "shared/hooks/brandsAccountsManagement/useFetchBrandsByName";
import { IStamp, ITemplate, TabMenu } from "shared/types/designStudio";
import { v4 as uuid } from "uuid";

const getTemplateById = async (
  id?: string,
): Promise<{ resource: ITemplate; json: any }> => {
  if (!id) throw new Error("Template id is required.");

  const { result, error } = await API.services.designStudio.getTemplateById(id);

  if (error || !result)
    throw new Error(error?.message || "Template was not able to be loaded.");

  const { template } = result;
  const { canvasJsonUrl } = template;

  if (!canvasJsonUrl) return { resource: template, json: {} };

  const url = `${canvasJsonUrl}?timestamp=${Date.now()}`;

  const response = await fetch(url);
  const json = await response.json();
  if (!json) return json;

  const objects = json?.objects ? [...json.objects] : [];

  return {
    resource: template,
    json: {
      ...json,
      objects: objects.map(obj => ({
        ...obj,
        name: obj.name || uuid(),
      })),
    },
  };
};

const getStampById = async (
  id?: string,
  selectedOfferType?: string,
): Promise<{ resource: IStamp[]; json: any }> => {
  if (!id) throw new Error("Stamp id is required.");
  if (!selectedOfferType) throw new Error("Offer type is required.");

  const { result, error } = await API.services.designStudio.getStampById(id);

  if (error || !result)
    throw new Error(error?.message || "Stamp was not able to be loaded.");

  const { stamps } = result;
  const selectedStamp = stamps.find(
    stmp => stmp.offerType === selectedOfferType,
  );

  const stamp = selectedStamp || stamps[0];
  const { stampJsonUrl: canvasJsonUrl } = stamp || {};

  if (!canvasJsonUrl) return { resource: stamps, json: {} };
  const url = `${canvasJsonUrl}?timestamp=${Date.now()}`;
  const response = await fetch(url);
  const json = await response.json();
  return { resource: stamps, json };
};

const getResources = async ({ type, id, selectedOfferType }: ResourceInfo) => {
  // fetch template/stamp and its canvas json
  const { resource, json } =
    type === "templates"
      ? await getTemplateById(id)
      : await getStampById(id, selectedOfferType);

  // fetch brands used in the resource
  const brandNames = isArray(resource) ? resource[0]?.oems : resource?.oems;
  const brands = brandNames.length ? await getBrandsByName(brandNames) : [];

  const isValidJson = !!json && !isEmpty(json);

  // list fonts currently available in the brands
  const brandFonts = brands.flatMap(brand => brand.fonts);

  if (isValidJson) await loadFontsFromTemplate(json, brandFonts);

  return {
    resource,
    json,
    brands,
  };
};

type ResourceInfo = {
  type?: TabMenu;
  id?: string;
  selectedOfferType?: string;
};

type UseEditorResourcesArgs = ResourceInfo & {
  onSuccess: (data: { resource: IStamp[] | ITemplate; json: any }) => void;
  onError: (error: string) => void;
};

export const useEditorResources = (args: UseEditorResourcesArgs) => {
  const { type, id, onSuccess, onError, selectedOfferType } = args;
  const { data, isFetching, refetch } = useQuery({
    queryKey: ["editorResource", type, id],
    staleTime: Infinity,
    queryFn: () => getResources(args),
    onSettled: (resourcesData, resourcesError) => {
      if (!resourcesData || resourcesError)
        return onError("Resource was not able to be loaded.");
      onSuccess(resourcesData);
    },
  });

  useEffect(() => {
    if (selectedOfferType) refetch();
  }, [selectedOfferType, refetch]);

  return {
    isLoading: isFetching,
    resource: data?.resource,
    canvasJson: data?.json,
  };
};
