import { Alert, AlertProps } from "antd";

import styles from "./AlertMessage.module.scss";

export const AlertMessage = ({
  message,
  description,
  type = "info",
  ...rest
}: AlertProps) => {
  return (
    <Alert
      className={styles.host}
      type={type}
      message={message}
      description={description}
      showIcon
      banner
      closable
      {...rest}
    />
  );
};
