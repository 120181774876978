import { TFileType } from "screens/designStudio/editor/canvasContainer/canvas.hooks/useImageInsertData";

export const readFileFromInput = (
  type: "video" | "image",
): Promise<TFileType> => {
  return new Promise((resolve, reject) => {
    const input = document.createElement("input");
    input.type = "file";

    let sizeLimitInMB = 0;

    switch (type) {
      case "image":
        input.accept = "image/png,image/jpeg";
        sizeLimitInMB = 1;
        break;
      case "video":
        input.accept = "video/mpeg,video/mp4";
        sizeLimitInMB = 100;
        break;
    }

    input.click();

    input.onchange = e => {
      const { target } = e;
      const { files } = (target as HTMLInputElement | null) || {};
      if (!files || files.length !== 1) return;

      const file = files[0];

      const { type: fileType, size: fileSize } = file;

      // const newFileType = fileType.split("/")[1] || "";
      const fileSizeInMB = fileSize / 1000000; // convert bytes to MB

      if (fileSizeInMB > sizeLimitInMB) {
        return reject(
          `Invalid media. ${fileType} must not exceed ${sizeLimitInMB}MB, but selected file is ${fileSizeInMB}MB.`,
        );
      }

      const fileReader: FileReader = new FileReader();
      fileReader.onload = progressEvent => {
        if (!progressEvent.target) {
          // error

          return reject(
            "some technical issue has been raised! Please contact the IT support.",
          );
        }

        const dataUri = (progressEvent.target as FileReader).result as string;

        if (type === "image") {
          resolve({
            dataUri,
            file: {
              type: fileType,
              size: fileSize,
            },
            element: input,
          });
        } else if (type === "video") {
          resolve({
            dataUri,
            file: {
              type: fileType,
              size: fileSize,
            },
          });
        }
      };

      fileReader.readAsDataURL(file);
    };
  });
};

export async function getUriFromUrl(
  url: string,
  name: string,
): Promise<string> {
  const response = await fetch(url);
  const data = await response.blob();
  const file = new File([data], name, {
    type: data.type,
  });

  return new Promise(res => {
    const fileReader = new FileReader();
    fileReader.onload = progressEvent => {
      const dataUri = (progressEvent.target as FileReader).result as string;
      res(dataUri);
    };

    fileReader.readAsDataURL(file);
  });
}

export const createVideoElement = (
  sourceUrl: string,
  fileType: "mp4",
  muted: boolean = true,
  loop?: boolean,
  play?: boolean,
) => {
  return new Promise<HTMLVideoElement>(resolve => {
    const video = document.createElement("video");
    video.muted = muted;
    video.crossOrigin = "anonymous";
    video.controls = true;
    video.loop = loop ?? true;
    if (play) video.play();

    const sourceElement = document.createElement("source");
    sourceElement.src = sourceUrl;
    sourceElement.type = `video/${fileType}`;

    video.appendChild(sourceElement);

    /** Steps on loading video data.
     * 1. First, assign video dimension in loadedmetadata event
     * 2. Second, creating thumbnail image with video frame at 1 sec. This part is needed. Without this part,
     *     the video object displays blank object in the canvas.
     * 3. Once, dimensions and thumbnail image are assigned, fulfill the promise.
     */
    Promise.all([
      loadVideoData("loadedmetadata", video),
      loadVideoData("timeupdate", video),
    ]).then(() => {
      resolve(video);
    });
  });
};

export const getVideoDuration = (sourceUrl: string, fileType: "mp4") => {
  return new Promise<number>(resolve => {
    const video = document.createElement("video");
    video.crossOrigin = "anonymous";

    const sourceElement = document.createElement("source");
    sourceElement.src = sourceUrl;
    sourceElement.type = `video/${fileType}`;

    video.appendChild(sourceElement);

    video.addEventListener("loadedmetadata", () => {
      resolve(video.duration);
    });
  });
};

const loadVideoData = (
  eventType: "loadedmetadata" | "timeupdate",
  videoElement: HTMLVideoElement,
) => {
  return new Promise<void>(resolve => {
    switch (eventType) {
      case "loadedmetadata":
        videoElement.addEventListener(
          eventType,
          () => {
            videoElement.width = videoElement.videoWidth;
            videoElement.height = videoElement.videoHeight;
            resolve();
          },
          { once: true },
        );

        break;

      case "timeupdate":
        videoElement.currentTime = 1;
        videoElement.addEventListener(
          eventType,
          () => {
            const canvas = document.createElement(
              "canvas",
            ) as HTMLCanvasElement;
            const context = canvas.getContext("2d");

            context?.drawImage(
              videoElement,
              0,
              0,
              videoElement.videoWidth,
              videoElement.videoHeight,
            );

            videoElement.poster = canvas.toDataURL();
            resolve();
          },
          { once: true },
        );

        break;
    }
  });
};
