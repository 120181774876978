import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Box,
  IconButton,
  Menu,
  Slider,
  Stack,
  Typography,
} from "@mui/material";
import InvertColorsRoundedIcon from "@mui/icons-material/InvertColorsRounded";
import {
  TextLayerElement,
  ImageLayerElement,
} from "screens/designStudio/designStudioV2/types";

type OpacityControlProps = {
  elements: (TextLayerElement | ImageLayerElement)[];
};

export const Opacity = observer(({ elements }: OpacityControlProps) => {
  const [layer] = elements;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpacityChange = (_event: Event, newValue: number | number[]) => {
    const opacityValue = Array.isArray(newValue) ? newValue[0] : newValue;
    elements.forEach(element => {
      element.set({ opacity: opacityValue });
    });
  };

  return (
    <Box>
      <IconButton size="small" onClick={handleClick}>
        <InvertColorsRoundedIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack direction="column" width={140} m={1} alignItems="center">
          <Typography variant="caption" display="block" gutterBottom>
            Opacity
          </Typography>
          <Slider
            value={layer.opacity as number}
            onChange={handleOpacityChange}
            valueLabelDisplay="auto"
            size="small"
            defaultValue={1}
            aria-label="Small"
            min={0}
            max={1}
            step={0.01}
            valueLabelFormat={value => (value * 100).toFixed(0)}
          />
        </Stack>
      </Menu>
    </Box>
  );
});
