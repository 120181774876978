import { Button } from "antd";
import style from "./TemplateVersionsDrawer.module.scss";

type TemplateVersionsFooterProps = {
  onClose: () => void;
};

const TemplateVersionsFooter = ({ onClose }: TemplateVersionsFooterProps) => {
  return (
    <div className={style.footer}>
      <Button onClick={onClose}>Close</Button>
    </div>
  );
};

export default TemplateVersionsFooter;
