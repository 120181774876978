import { DataListURLProvider } from "shared/components/dataListURL/dataListURLContext";
import Table from "./beeswaxCampaigns/Table";
import { fields } from "./beeswaxCampaigns/fields";
import { Outlet } from "react-router-dom";

const BeeswaxCampaigns = () => {
  return (
    <DataListURLProvider fields={fields}>
      <Table />
      <Outlet />
    </DataListURLProvider>
  );
};

export default BeeswaxCampaigns;
