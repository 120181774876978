import { useNavigate } from "react-router-dom";
import { Typography } from "antd";
import Card from "antd/lib/card";
import styles from "./FeatureCard.module.scss";
import { isFunction } from "lodash";

type FeatureCardProps = {
  icon: React.ReactNode;
  title: string;
  description: string;
  navigateTo: string | (() => void);
};

const FeatureCard = ({
  icon,
  title,
  description,
  navigateTo,
}: FeatureCardProps) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    return isFunction(navigateTo) ? navigateTo() : navigate(navigateTo);
  };

  return (
    <Card
      hoverable
      onClick={handleCardClick}
      className={styles.featureCard}
      bodyStyle={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 0,
        width: "100%",
      }}
    >
      <div className={styles.featureCardContent}>
        <div className={styles.featureCardText}>
          <Typography.Title level={4}>{title}</Typography.Title>
          <Typography.Text>{description}</Typography.Text>
        </div>
        <div>
          <div className={styles.iconCircle}>{icon}</div>
        </div>
      </div>
    </Card>
  );
};

export default FeatureCard;
