import { Button, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import Duck from "statics/images/aflac-duck-pose-looking.png";
import styles from "./SalesEnablementErrorFeedback.module.scss";
import { Footer } from "screens/homepage/Footer";
import { useIsSalesEnablementAdmin } from "shared/hooks/useIsAdmin";

const SalesEnablementErrorFeedback = () => {
  const isAdmin = useIsSalesEnablementAdmin();
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.feedback}>
        <div className={styles.message}>
          <div>
            <Typography.Title level={2}>Something went wrong</Typography.Title>
            <Typography.Text>
              Please try again or contact support.
            </Typography.Text>
          </div>

          <div>
            <Button
              size="large"
              type="primary"
              onClick={() => {
                navigate("/sales-enablement/home", {
                  replace: true,
                });
              }}
            >
              Go to Home Page
            </Button>
          </div>
        </div>
        <img width={512} height={411} src={Duck} alt="Aflac Duck" />
      </div>

      <Footer isAdmin={isAdmin} />
    </div>
  );
};

export default SalesEnablementErrorFeedback;
