import { Typography } from "antd";
import { HTMLAttributes } from "react";
import styles from "./DropPlaceholder.module.scss";

type DropPlaceholderProps = HTMLAttributes<HTMLDivElement>;

export const DropPlaceholder = (props: DropPlaceholderProps) => (
  <div {...props}>
    <Typography.Text>
      {`Drop your files here, or `}
      <span className={styles.spanText}>browse</span>
    </Typography.Text>
  </div>
);
