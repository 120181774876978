import { message } from "antd";
import { RcFile } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { IFile } from "shared/types/uploadAsset";

const OFFSET_LIMIT = 0.01;
const MAX_SIZE = 5120;

export const toBase64 = (file?: RcFile) => {
  return new Promise<string>((resolve, reject) => {
    if (!file) return reject("No file exists.");

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64 = reader.result as string;
      resolve(base64);
    };
  });
};

export const validateFile = (file: UploadFile<IFile>, validRatio: number) => {
  const img = new Image();
  img.src = file.url as string;
  return new Promise<string>((resolve, reject) => {
    img.onload = () => {
      const imgRatio = img.width / img.height;
      if (Math.abs(imgRatio - validRatio) > OFFSET_LIMIT) {
        reject(`Please check image dimension (expected ratio: ${validRatio})`);
        return;
      }
      resolve("Success");
    };
  });
};

export const validateImageSize = async (url: string) => {
  try {
    const fileImg = await fetch(url).then(r => r.blob());
    return fileImg.size / 1024 < MAX_SIZE;
  } catch (err) {
    message.error(`Failed to validate image: ${url} \n ${err}`);
    return false;
  }
};

const getLocalFileBlob = (file: UploadFile<RcFile> | RcFile) => {
  if (file instanceof Blob) return file;
  return file.originFileObj!;
};

export const validateLocalFile = (
  file: UploadFile<RcFile> | RcFile,
  ratio: number,
) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(getLocalFileBlob(file));
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result as string;
      img.onload = () => {
        const { width, height } = img;
        const imgRatio = width / height;
        if ((file?.size || 0) / 1024 > MAX_SIZE) {
          reject(`Upload image size must be smaller than ${MAX_SIZE}KB`);
        } else if (Math.abs(imgRatio - ratio) > OFFSET_LIMIT) {
          reject(`Please check image dimension (expected ratio: ${ratio})`);
        } else if (file.type !== "image/jpeg" && file.type !== "image/png") {
          reject(`Invalid image type. Please use jpeg / png image`);
        } else {
          resolve("Success");
        }
      };
    };
  });
};

export const isFontFile = (file: UploadFile) => file.type === "font/ttf";

export const getDimensions = async (
  file: UploadFile<IFile>,
): Promise<{ width: number; height: number }> => {
  const b64Image = await toBase64(file.originFileObj);
  return new Promise(resolve => {
    const img = new Image();
    img.src = b64Image;
    img.onload = () => {
      const width = img.width;
      const height = img.height;
      resolve({ width, height });
    };
  });
};

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

export const validateLogo = (file: RcFile): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    if (file.size > 10 * 1024 * 1024) {
      return reject(
        new Error("File size exceeds the allowable limit of 10MB."),
      );
    }

    const hasSpecialCharacters = /[^a-zA-Z0-9 .-]/.test(file.name);
    if (hasSpecialCharacters) {
      return reject(
        new Error("File name must not contain special characters."),
      );
    }

    const img: HTMLImageElement = new window.Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      URL.revokeObjectURL(img.src);
      if (img.width < 60) {
        return reject(new Error("Image must be at least 60px wide."));
      } else {
        return resolve();
      }
    };

    img.onerror = () => {
      return reject(new Error("Invalid image file."));
    };
  });
};
