import styles from "./Homepage.module.scss";
import { useIsSalesEnablementAdmin } from "shared/hooks/useIsAdmin";
import { WelcomeSection } from "./homepage/WelcomeSection";
import { Footer } from "./homepage/Footer";
import { FeatureCardSection } from "./homepage/FeatureCardSection";
import { SalesCycleSection } from "./homepage/SalesCycleSection";
import { ListSection } from "./homepage/ListSection";
import { AccountsSection } from "./homepage/AccountsSection";
import { DataProvider } from "./designStudio/hooks/SalesEnablementDataProvider";
import { useNavigate } from "react-router-dom";
import { useFetchAccounts } from "shared/hooks/salesEnablement/useFetchAccounts";
import { useFetchMarketingMaterials } from "./adLibrary/marketingMaterials/hooks/useFetchMarketingMaterials";
import { ROUTES } from "./homepage/utils/constants";
export const Homepage = () => {
  const isAdmin = useIsSalesEnablementAdmin();
  const navigate = useNavigate();

  const { data: accounts = [], isError: isAccountError } = useFetchAccounts();
  const {
    marketingMaterials,
    isLoading,
    isError: isMMError,
  } = useFetchMarketingMaterials();

  if (!accounts) return null;

  const matchedAccounts = accounts.filter(account =>
    marketingMaterials.some(material => material.account === account.name),
  );

  const navigateToAccounts = () => navigate(ROUTES.accounts);
  const isError = isAccountError || isMMError;
  return (
    <div className={styles.homepage}>
      <div className={styles.container}>
        <DataProvider>
          <WelcomeSection isAdmin={isAdmin} />
          {!isAdmin && <SalesCycleSection />}
          <ListSection isAdmin={isAdmin} />
          {!isAdmin && (
            <AccountsSection
              isLoading={isLoading}
              isError={isError}
              navigateToAccounts={navigateToAccounts}
              accounts={matchedAccounts}
            />
          )}
          <FeatureCardSection />
          <div className={styles.footerWrapper}>
            <Footer isAdmin={isAdmin} />
          </div>
        </DataProvider>
      </div>
    </div>
  );
};
