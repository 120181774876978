import {
  saveDraftFail,
  publishCanvasBegin,
  publishCanvasSuccess,
  publishCanvasStatusReset,
  publishCanvasFail,
  randomizeTemplateDataFail,
  randomizeTemplateDataSuccess,
  randomizeTemplateDataReset,
  randomizeTemplateDataBegin,
} from "redux/designStudio/designStudio.slice";
import { AppThunk } from "redux/store";
import API from "services";
import GenericError from "shared/errors/GenericError";
import { UseGetTabData } from "shared/hooks/assetBuilder/useGetTabData";
import { IPreviewSelectedOptions } from "shared/types/assetBuilder";
import { IConfig } from "shared/types/configuration";
import {
  IPublishCanvasStatus,
  IResponse,
  IStamp,
  ITemplate,
} from "shared/types/designStudio";
import { delay, getRandomInt } from "utils/helpers";

/**
 * each stamp has 7 different offer types.
 * When user edits stamp, they can freely switch between differnt offer type and app should retain changes.
 * In order to do this, I am updating stamp whenever user switches offer type.
 *  1. user select differnt offer type.
 *  2. update (stampJson) the stamp that is being displayed in the global store.
 *  3. find the next stamp with differnt offer type and display.
 *
 * When this action is being called, the stamp passed into this function will be THE LAST STAMP that was modified.
 * Plan is to get all stamps with offer type and save them in DB.
 */
export const publishCanvas =
  ({
    publishCanvasStatus,
    stamp = null,
    template = null,
    canvasJson = null,
    base64Thumbnail = null,
  }: {
    publishCanvasStatus: IPublishCanvasStatus;
    stamp: IStamp | null;
    template: ITemplate | null;
    canvasJson: string | null;
    base64Thumbnail: string | null;
  }): AppThunk =>
  async (dispatch, getState) => {
    // this function will be used in 2 different places, DesignStudio.tsx and Canvas.tsx.
    // the action will be initiated from DesignStudio.tsx by clicking Publish button.
    // and then template or stamp will be assigned from Canvas.tsx and this function will be called again.
    // so if template or stamp but not both is valid, skip publishCanvasBegin() part.
    if (!stamp && !template) {
      return dispatch(publishCanvasBegin(publishCanvasStatus));
    }

    if (stamp && template) {
      throw new GenericError({
        message:
          "Unknown error! Cannot publish stamp and template both at the same time.",
      });
    }

    const { configuration } = getState();
    const { config } = configuration;

    if (stamp) {
      dispatch(
        publishCanvasSuccess({
          publishCanvasStatus,
          stamp,
        }),
      );

      dispatch(publishCanvasStatusReset());
    } else {
      let toBePublishedTemplate: ITemplate = {
        ...(template as ITemplate),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        type: template!.type || "carcut", // NOTE: if template.type does not exist, that means this template was created before lifestyle type change. Default to carcut
      };
      // save canvas thumbnail if canvasJson and base64Thumbnail are valid.
      if (canvasJson && base64Thumbnail) {
        const savingDraftRequest: RequestInfo = new Request(
          (config as IConfig).services.designStudio.saveDraftUrl,
          {
            method: "POST",
            body: JSON.stringify({
              base64Thumbnail,
              template: toBePublishedTemplate,
              canvasJson,
            }),
          },
        );
        const { error: savingDraftError, result: savedDraftResult } =
          await API.send<IResponse<ITemplate>>(savingDraftRequest);

        if (savingDraftError) {
          return dispatch(
            saveDraftFail(
              new GenericError({
                message: "Template was not able to be saved.",
              }),
            ),
          );
        }

        const { template: savedTemplate } = savedDraftResult;

        toBePublishedTemplate = {
          ...(toBePublishedTemplate as ITemplate),
          thumbnailUrl: savedTemplate.thumbnailUrl,
          canvasJsonUrl: savedTemplate.canvasJsonUrl,
        };
      }

      const request: RequestInfo = new Request(
        (config as IConfig).services.designStudio.updateTemplateUrl,
        {
          method: "put",
          body: JSON.stringify({
            templateToUpdate: {
              ...toBePublishedTemplate,
            } as ITemplate,
          }),
        },
      );

      const { result, error } = await API.send<IResponse<ITemplate>>(request);

      if (error || !result.template) {
        if (result && !result.template) {
          return dispatch(
            saveDraftFail(
              new GenericError({
                message: "Unknown error while publishing stamp.",
              }),
            ),
          );
        }
        return dispatch(
          publishCanvasFail({
            publishCanvasStatus,
            error: error as GenericError,
          }),
        );
      }

      dispatch(
        publishCanvasSuccess({
          publishCanvasStatus,
          template: result.template,
        }),
      );

      await delay(2000);

      dispatch(publishCanvasStatusReset());
    }
  };

export const randomizePreviewData =
  (
    params: UseGetTabData,
    previewOfferTotal?: number,
    preSelectedOptions?: IPreviewSelectedOptions,
  ): AppThunk =>
  async dispatch => {
    dispatch(randomizeTemplateDataBegin());

    const chosenOem = preSelectedOptions?.oem || "";
    const chosenStore = preSelectedOptions?.storeName || "";
    const chosenCondition = preSelectedOptions?.vehicleCondition || "All";

    const offersPerPage = 15;
    const pageCount = Math.floor((previewOfferTotal || 1) / offersPerPage) || 1; // each full page should be ~15 offers

    const randomPage = getRandomInt(pageCount) || 1;

    const res = await API.services.assetBuilder.getTabData(params, randomPage);

    if (!res.result?.offerList?.length) {
      return dispatch(
        randomizeTemplateDataFail(
          new GenericError({
            message: `No offers found from the OEM ${chosenOem} with vehicle condition ${chosenCondition}`,
          }),
        ),
      );
    }

    const randomOfferIdx = getRandomInt(res.result?.offerList?.length || 0);

    const { rowIdentifier, ...offer } = res.result?.offerList?.[
      randomOfferIdx
    ] || {
      rowIdentifier: "",
    };

    const offerData = { rowIdentifier, ...offer }; // rowIdentifier is used for OfferData and can be anything for this case

    return dispatch(
      randomizeTemplateDataSuccess({
        offerData,
        dealerName: chosenStore,
      }),
    );
  };

export const resetRandomizationData = () => async (dispatch: any) => {
  return dispatch(randomizeTemplateDataReset());
};
