import { TAssetBatch } from "shared/types/assetExporter";
import { ITemplate } from "shared/types/designStudio";
import { hasHTMLTemplateCompositions } from "../../assetBatchDrawer/utils";

export const getHTMLTemplatesMap = (
  assetBatchesForSelectedRows: TAssetBatch[],
  templates: ITemplate[],
): Record<string, boolean> =>
  assetBatchesForSelectedRows.reduce((accu, curr) => {
    const containsHTMLTemplates = hasHTMLTemplateCompositions(
      curr.compositions,
      templates,
    );

    if (!curr.assetBatchId) return accu;
    return {
      ...accu,
      [curr.assetBatchId]: containsHTMLTemplates,
    };
  }, {});
