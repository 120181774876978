import { Form, Input } from "antd";
import { memo } from "react";
import EditableAdToLoadUrlText from "screens/adLibrary/adLoad/adLoadDrawer/shared/components/adsToLoadTable/EditableAdToLoadUrlText";
import { useStoreLabels } from "shared/hooks/useStoreLabels";
import { AdditionalCardPlacement, ICard } from "shared/types/adLibrary";
import { SingleFileUploadDragger } from "../shared/SingleFileUploadDragger";
import styles from "./AdCarouselCard.module.scss";
import { useDestinationURLValidator } from "screens/adLibrary/shared/hooks/useDestinationURLValidator";
import { getCardData, getHasThumbnailError } from "./AdCarouselForm/utils";
import { MultiUploadSizing } from "shared/types/uploadManagement";

const emptydata = {
  headline: "",
  description: "",
  destinationUrl: "",
  thumbnail: undefined,
  videoUrl: undefined,
  assetId: undefined,
};

interface IProps {
  index: number;
  card: ICard;
  patchCard(index: number, newValues: Partial<ICard>): void;
  shouldDisplayErrors?: boolean;
  type: MultiUploadSizing;
  isMultiPlacementCarousel: boolean;
}

const AdCarouselCard = ({
  index,
  card,
  patchCard,
  shouldDisplayErrors,
  type,
  isMultiPlacementCarousel,
}: IProps) => {
  const data = getCardData(type, card) ?? emptydata;

  const { allStoreLabels } = useStoreLabels();
  const { isValidDestinationURL } = useDestinationURLValidator();
  const hasThumbnailError =
    shouldDisplayErrors && getHasThumbnailError(type, card);

  const hasDestinationURLError = !isValidDestinationURL(data.destinationUrl);

  const updateCard = (newValues: Partial<ICard>) => {
    if (type === "IN_FEED") return patchCard(index, newValues);
    return patchCard(index, {
      ...card,
      additionalPlacements: [
        { ...(data as AdditionalCardPlacement), ...newValues },
      ],
    });
  };

  const isRequired = type === "IN_FEED" || isMultiPlacementCarousel;

  return (
    <div className={styles.adCarouselCard}>
      <Form.Item
        name={["cards", index, "asset", type]}
        validateStatus={hasThumbnailError ? "error" : "success"}
        help={isRequired && hasThumbnailError && "Please add an asset"}
        style={{ width: 200 }}
        data-cy="card-file-item"
        trigger="handleChangeFile"
        valuePropName="fileUrls"
        initialValue={data}
        rules={[
          {
            required: isRequired,
            message: "Please add an asset",
          },
        ]}
      >
        <SingleFileUploadDragger
          fileUrls={data}
          handleChangeFile={updateCard}
          size={type}
        />
      </Form.Item>
      <Form.Item
        label="Headline"
        name={["cards", index, "headline", type]}
        initialValue={data.headline}
        required={isRequired}
        rules={[
          {
            required: isRequired,
            message: "Please add a headline",
          },
        ]}
      >
        <Input
          value={data.headline}
          onChange={event => {
            updateCard({ ...data, headline: event.target.value });
          }}
          data-cy="card-headline-input"
        />
      </Form.Item>
      <Form.Item
        label="Description"
        name={["cards", index, "description", type]}
        initialValue={data.description}
      >
        <Input.TextArea
          value={data.description}
          onChange={event =>
            updateCard({ ...data, description: event.target.value })
          }
          data-cy="description-textarea"
        />
      </Form.Item>

      <Form.Item
        label="Destination Url"
        name={["cards", index, "destinationUrl", type]}
        initialValue={data.destinationUrl}
        validateStatus={
          isRequired && hasDestinationURLError ? "error" : "success"
        }
        required={isRequired}
        rules={[
          {
            required: isRequired,
            message: "Please add a destination url",
          },
        ]}
        help={
          isRequired &&
          hasDestinationURLError &&
          "Please use “https://” or “http://” to format url correctly"
        }
      >
        <EditableAdToLoadUrlText
          showInputOnly
          linkValue={data.destinationUrl}
          urlLabels={allStoreLabels}
          onChange={value => {
            updateCard({ ...data, destinationUrl: value });
          }}
          disableLabelPopulation
          willCheckFullUrl={false}
        />
      </Form.Item>
    </div>
  );
};

export default memo(AdCarouselCard);
