import CommonFilterDrawer from "shared/components/filterDrawer/FilterDrawer";
import { FilterFields } from "shared/components/filterDrawer/useFilterFields";
import { ProductTable } from "shared/types/salesEnablement";
import { useProducts } from "shared/hooks/admin/useProducts";
import { languageOptions } from "utils/helpers.salesEnablement";

type Props = {
  onClose: () => void;
  open: boolean;
};

const ProductFilterDrawer = ({ open, onClose }: Props) => {
  const { productsTable, productTypes } = useProducts();

  const filterFields: FilterFields<Partial<ProductTable>> = [
    {
      title: "Name",
      key: "name",
      type: "text",
    },
    {
      title: "Product Type",
      key: "productType",
      type: "select",
      options: productTypes.map(productType => ({
        label: productType,
        value: productType,
        key: productType,
      })),
    },
    {
      title: "Language",
      key: "language",
      type: "select",
      options: languageOptions,
    },
  ];

  return (
    <CommonFilterDrawer
      data={productsTable}
      filterKey="salesenablement-products"
      fields={filterFields}
      open={open}
      onClose={onClose}
    />
  );
};

export default ProductFilterDrawer;
