import AdAccount from "screens/adLibrary/facebookUtils/adAccount";
import { flatten } from "lodash";
import {
  AdLoadAdStatus,
  AdLoadAdStatusEnum,
  IAdToLoadData,
  ReviewStepKeys,
  SessionLoadStatus,
} from "shared/types/adLibrary";
import {
  AdType,
  ICreateOrUpdateResponse,
  IFacebookAccount,
  IFacebookErrorObject,
} from "screens/adLibrary/facebookUtils/types";
import {
  AdsToLoadByAccountId,
  ProgressCounter,
  PromiseTracker,
} from "../shared/types";
import { IAccount } from "shared/types/accountManagement";
import { LoadToFacebookArgs } from "shared/hooks/adLibrary/adLoad/useMutateFacebookAd";
import { getStoreMatch } from "../shared/utils";

type ReturnLoadToFacebookArgsArgs = {
  adToLoad: IAdToLoadData;
  selectedDealers?: IAccount[];
  selectedAccounts?: IFacebookAccount[] | null;
};

export const returnAdsGroupedByAccount = (
  selectedAdsToLoad: IAdToLoadData[],
  selectedAccounts?: IFacebookAccount[] | null,
) =>
  selectedAccounts?.reduce(
    (acc, curr) => (
      (acc[curr.name!] = selectedAdsToLoad.filter(
        adToLoad => adToLoad.account.account_id === curr.account_id,
      )),
      acc
    ),
    {} as AdsToLoadByAccountId,
  );

export const returnLoadToFacebookArgs = (
  args: ReturnLoadToFacebookArgsArgs,
): LoadToFacebookArgs => {
  const selectedAccount = args.selectedAccounts?.find(
    account => account.account_id === args.adToLoad?.account?.account_id,
  );
  return {
    adToLoad: args.adToLoad,
    facebookAdAccount: selectedAccount
      ? new AdAccount(selectedAccount!.account_id!)
      : undefined,
    selectedDealer: getStoreMatch(args.selectedDealers, selectedAccount),
  };
};

export const returnDataNotFoundObj = (
  adToLoad: IAdToLoadData,
  errorDetails?: IFacebookErrorObject,
): IAdToLoadData => ({
  ...adToLoad,
  adLoadStatus: {
    status: "error",
    facebookError: errorDetails,
    errorMessage: "Account / Store data could not be found.",
  },
});

type ReturnAdToLoadStatusArgs = {
  adToLoad: IAdToLoadData;
  newAdLoadStatus: AdLoadAdStatus;
  response: ICreateOrUpdateResponse;
};

export const returnAdToLoadStatus = (
  args: ReturnAdToLoadStatusArgs,
): IAdToLoadData["adLoadStatus"] => ({
  errorMessage: args.response.error?.message,
  reviewAndQAIssues:
    args.adToLoad.ad.type === AdType.Collection && args.response?.error
      ? (args.adToLoad.adLoadStatus.reviewAndQAIssues ?? [])?.concat([
          {
            status: "error",
            requiredStep: ReviewStepKeys.CONVERT_TO_VIDEO,
            message: args.response.error.message || "",
          },
        ])
      : args.adToLoad.adLoadStatus.reviewAndQAIssues,
  facebookError: (args.response.error as IFacebookErrorObject) || undefined,
  status:
    args.response.error || !args.response.result
      ? "error"
      : args.newAdLoadStatus,
});

export const recursivelyRacePromises = (
  currPromisesObjs: PromiseTracker[],
  setCurrentAdToLoad?: (adToLoad: IAdToLoadData) => void,
) => {
  if (!currPromisesObjs.length) return;
  Promise.race(currPromisesObjs.map(obj => obj.promise))
    .then(newCurrentAdToLoad => {
      setCurrentAdToLoad?.({ ...newCurrentAdToLoad });
      const newPromiseObjs = currPromisesObjs.filter(
        obj => obj.key !== newCurrentAdToLoad.key,
      );
      if (!newPromiseObjs.length) {
        return;
      }
      recursivelyRacePromises(newPromiseObjs, setCurrentAdToLoad);
    })
    .catch(() => {
      return;
    });
};

const getSuccessCount = (selectedAdsToLoad: IAdToLoadData[]) => {
  const adsWithCreativeCount = selectedAdsToLoad.filter(
    adToLoad =>
      !adToLoad.facebookAd?.id &&
      !!adToLoad.facebookAd?.creative?.id &&
      adToLoad.adLoadStatus.status === "loading",
  ).length;
  const adsWithFacebookAdsCount = selectedAdsToLoad.filter(
    adToLoad => adToLoad.adLoadStatus.status === "success",
  ).length;
  if (!adsWithCreativeCount) return adsWithFacebookAdsCount;
  return (adsWithCreativeCount + adsWithFacebookAdsCount) / 2;
};

const getErrorCount = (
  selectedAdsToLoad: IAdToLoadData[],
  excludeQAIssues?: boolean,
) => {
  /** filtering out qa issues allows proper determination of the session's load status when saving */
  const adsReadyToLoad = selectedAdsToLoad.filter(
    adToLoad =>
      !adToLoad.adLoadStatus.reviewAndQAIssues?.filter(
        issue => issue.status === "error",
      ).length,
  );
  return (excludeQAIssues ? adsReadyToLoad : selectedAdsToLoad).filter(
    adToLoad => adToLoad.adLoadStatus.status === "error",
  ).length;
};

const getStatusCount = (
  adsToLoad: IAdToLoadData[],
  status: AdLoadAdStatus,
  excludeQAIssues?: boolean,
) => {
  if (status === "success") return getSuccessCount(adsToLoad);
  if (status === "error") return getErrorCount(adsToLoad, excludeQAIssues);
  return adsToLoad.filter(adToLoad => adToLoad.adLoadStatus.status === status)
    .length;
};

const returnProgressCounter = (
  adsToLoad: IAdToLoadData[],
  status: AdLoadAdStatus,
  excludeQAIssues?: boolean,
): ProgressCounter => {
  const adCount = adsToLoad.length;
  const statusCount = getStatusCount(adsToLoad, status, excludeQAIssues);
  const totalProgress = Math.round((statusCount / adCount) * 100);

  return {
    count: adsToLoad.filter(adToLoad => adToLoad.adLoadStatus.status === status)
      .length,
    progress: totalProgress,
  };
};

const returnObjectsWithVideos = (adsToLoad: IAdToLoadData[]) => ({
  ads: adsToLoad.filter(adToLoad => !!adToLoad.ad.visuals.videoUrl),
  cards: flatten(
    adsToLoad.map(adToLoad => adToLoad.ad.visuals.cards ?? []),
  ).filter(card => !!card.videoUrl),
});

export const returnNumberOfRequiredVideos = (adsToLoad: IAdToLoadData[]) => {
  const objs = returnObjectsWithVideos(adsToLoad);
  return objs.ads.length + objs.cards.length;
};

export const returnUploadedCount = (adsToLoad: IAdToLoadData[]) => {
  const objs = returnObjectsWithVideos(adsToLoad);
  const numberOfAds = objs.ads.filter(
    adToLoad =>
      !!adToLoad.ad.visuals.assetId || adToLoad.adLoadStatus.status === "error",
  ).length;
  const numberOfCards = objs.cards.filter(card => !!card.assetId).length;
  return numberOfAds + numberOfCards;
};

export const returnSessionLoadStatus = (
  selectedAdsToLoad: IAdToLoadData[],
): SessionLoadStatus => {
  const { count: errorCount } = returnProgressCounter(
    selectedAdsToLoad,
    "error",
    true,
  );
  const { count: successCount } = returnProgressCounter(
    selectedAdsToLoad,
    "success",
  );
  if (!errorCount && !successCount) return SessionLoadStatus.NotLoaded;
  if (successCount === selectedAdsToLoad.length)
    return SessionLoadStatus.Loaded;
  if (errorCount) return SessionLoadStatus.Failed;
  return SessionLoadStatus.Unknown;
};

export const handleLoadProcess = (
  adToLoad: IAdToLoadData,
  handleUnsuccessfulAd: () => Promise<IAdToLoadData>,
  handleSuccessfulAd: () => Promise<IAdToLoadData>,
) => {
  return adToLoad.adLoadStatus.status === AdLoadAdStatusEnum.SUCCESS
    ? handleSuccessfulAd()
    : handleUnsuccessfulAd();
};
