import { useDataList } from "shared/components/dataList";
import { TagType } from "../TagList";
import { TableFiltersSection } from "../TableFiltersSection";
import { DEFAULT_KEY } from "./useDataList";

export const DataListTags = () => {
  const [dataList, dataListActions] = useDataList();

  const tags: TagType[] = Object.entries(
    dataList.filters[DEFAULT_KEY] ?? [],
  ).reduce<TagType[]>((acc, [key, val]) => {
    return [
      ...acc,
      ...val.map(value => ({
        key,
        value,
        displayValue: dataList.config.columns[key].display?.(value) ?? value,
      })),
    ];
  }, []);

  return (
    <TableFiltersSection
      tags={tags}
      onFilterClick={dataListActions.toggleSelectedItems}
      selectedCount={dataList.selectedIds.length}
      displaySelectedOnly={dataList.showSelected}
      clearTags={dataListActions.resetFilters}
      removeTag={dataListActions.removeFilter}
      currentItems={dataList.rows.length}
      totalItems={dataList.data.length}
    />
  );
};
