import { useDataListURLData } from "shared/components/dataListURL/useDataListURLData";
import { BeeswaxCampaign, BeeswaxCampaignTableRecord } from "../types";
import { useFetchBeeswaxCampaigns } from "./useBeeswaxCampaigns";

export const useBeeswaxCampaignsDataSource = () => {
  const { data, isLoading, isError } = useFetchBeeswaxCampaigns();

  const tableRecords = transformBeeswaxCampaignsIntoTableRecords(data ?? []);

  return useDataListURLData<
    keyof BeeswaxCampaignTableRecord,
    BeeswaxCampaignTableRecord
  >({
    isError,
    isLoading,
    data: tableRecords,
  });
};

const transformBeeswaxCampaignsIntoTableRecords = (
  beeswaxCampaigns: BeeswaxCampaign[],
): BeeswaxCampaignTableRecord[] => {
  return beeswaxCampaigns.map(campaign => {
    return {
      id: campaign.id,
      type: "campaign",
      name: campaign.name,
      budget: campaign.totalBudget,
      startDate: campaign.startDate,
      endDate: campaign.endDate,
      createdAt: campaign.createdAt,
      updatedAt: campaign.updatedAt,
      updatedBy: campaign.updatedBy,
      children:
        campaign.lineItems?.map(lineItem => {
          return {
            id: lineItem.id,
            type: "lineItem",
            name: lineItem.name,
            budget: lineItem.budget,
            startDate: lineItem.startDate,
            endDate: lineItem.endDate,
            creatives: lineItem.creatives,
          };
        }) ?? [],
    };
  });
};
