import { Drawer, Space, Button, Row, Col, Typography, Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import CampaignForm from "./CampaignForm";
import { BeeswaxCampaignsContext } from "./BeeswaxCampaignsProvider";
import { ROUTES, campaignFormId } from "./constants";
import { BeeswaxCampaignURLParams } from "./types";
import { useBeeswaxCampaign } from "./hooks/useBeeswaxCampaign";
import Spinner from "screens/adLibrary/adLoadV2/shared/Spinner";
import { useMutateBeeswaxCampaign } from "./hooks/useMutateBeeswaxCampaign";
import LineItems from "./LineItems";
import styles from "./CampaignDrawer.module.scss";

const { Title } = Typography;

const CampaignDrawer = () => {
  const navigate = useNavigate();
  const { campaignId } = useParams<BeeswaxCampaignURLParams>();
  const { isSubmitting } = useMutateBeeswaxCampaign();
  const { isLoading: isLoadingCampaign } = useBeeswaxCampaign(campaignId);

  const onCancel = () => {
    navigate(ROUTES.home);
  };

  return (
    <Drawer
      title={"New Campaign Template"}
      destroyOnClose
      visible
      closable={false}
      maskClosable={false}
      width={"98%"}
      footerStyle={{ textAlign: "right" }}
      bodyStyle={{
        padding: "0 24px",
        display: "flex",
      }}
      footer={
        <Space>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            type="primary"
            loading={isSubmitting}
            htmlType="submit"
            form={campaignFormId}
          >
            Save
          </Button>
        </Space>
      }
    >
      {isLoadingCampaign ? (
        <Spinner />
      ) : (
        <Row gutter={16} className={styles.drawerBody}>
          <Col span={8} style={{ padding: 24 }}>
            <CampaignForm />
          </Col>
          <Col
            flex={1}
            span={16}
            className={styles.lineItems}
            style={{ padding: 24 }}
          >
            <Title level={5}>Line Items</Title>
            <LineItems />
          </Col>
        </Row>
      )}
    </Drawer>
  );
};

const CampaignDrawerWrapper = () => {
  const { campaignId } = useParams<BeeswaxCampaignURLParams>();
  const { data: campaign, isLoading } = useBeeswaxCampaign(campaignId);

  if (isLoading) {
    return <Spin tip="Loading..." className={styles.spinner} />;
  }

  return (
    <BeeswaxCampaignsContext lineItems={campaign?.lineItems ?? []}>
      <CampaignDrawer />
    </BeeswaxCampaignsContext>
  );
};

export default CampaignDrawerWrapper;
