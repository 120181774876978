import { useState } from "react";
import { Button, Drawer } from "antd";
import TemplateList from "screens/designStudio/TemplateList";
import { InMemoryAdapter } from "shared/components/dataListURL/InMemoryAdapter";
import { DataListURLProvider } from "shared/components/dataListURL/dataListURLContext";
import { useDataListURLIds } from "shared/components/dataListURL/useDataListURLIds";
import { getAllAdIds } from "shared/components/dataListURL/utils";
import { Template } from "shared/types/salesEnablement";
import { QueryParamProvider } from "use-query-params";
import { fields } from "../../designStudio/fields";
import { ROUTES } from "./utils/constants";
import styles from "./CreateMarketingMaterialDrawer.module.scss";
import { useLayoutPreference } from "shared/hooks/useLayoutPreference";
import { useAddRecentTemplate } from "shared/hooks/salesEnablement/useAddRecentTemplate";
import { useUser } from "shared/hooks/useUser";
import { useIsSalesEnablementAdmin } from "shared/hooks/useIsAdmin";
import useNavigateWithSearch from "shared/hooks/useNavigateWithSearch";

const localStorageLayoutKey = "MarketingMaterialsLayout";

function CreateMarketingMaterialDrawer() {
  const [isVisible, setIsVisible] = useState(true);
  const [layout] = useLayoutPreference(localStorageLayoutKey);
  const { selectedIds } = useDataListURLIds<Template>(getAllAdIds);

  const navigateWithSearch = useNavigateWithSearch();
  const { mutate: addRecentTemplate } = useAddRecentTemplate();
  const user = useUser();
  const isAdmin = useIsSalesEnablementAdmin();

  const onClose = () => {
    setIsVisible(false);
  };

  const onCreate = () => {
    if (selectedIds.length !== 1) return;
    if (!isAdmin) {
      addRecentTemplate({
        userId: user.sub,
        templateId: selectedIds[0],
      });
    }
    navigateWithSearch(`${ROUTES.create(selectedIds[0])}`);
  };

  const handleAfterVisibleChange = (visible: boolean) => {
    if (!visible) {
      navigateWithSearch(ROUTES.home);
    }
  };

  return (
    <Drawer
      title={
        <div>
          <b>Create Marketing Material:</b> Select Template
        </div>
      }
      width="calc(100vw - 48px)"
      bodyStyle={{
        padding: 0,
        overflow: layout === "table" ? "hidden" : undefined,
        backgroundColor: "#F5F5F5",
      }}
      footerStyle={{
        zIndex: 5,
        backgroundColor: "#fff",
      }}
      visible={isVisible}
      onClose={onClose}
      destroyOnClose
      afterVisibleChange={handleAfterVisibleChange}
      footer={
        <div className={styles.footer}>
          <Button type="default" htmlType="button" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={onCreate}
            disabled={selectedIds.length !== 1}
          >
            Create
          </Button>
        </div>
      }
    >
      <TemplateList view="compact" select="single" />
    </Drawer>
  );
}

export default function CreateMarketingMaterialDrawerWrapper() {
  return (
    <DataListURLProvider fields={fields}>
      <QueryParamProvider adapter={InMemoryAdapter}>
        <CreateMarketingMaterialDrawer />
      </QueryParamProvider>
    </DataListURLProvider>
  );
}
