export const ROUTES = {
  home: "/sales-enablement/my-marketing-materials",
  create: (id: string) => `/sales-enablement/my-marketing-materials/new/${id}`,
  edit: (id: string) => `/sales-enablement/my-marketing-materials/${id}`,
  emailDelivery: (id: string) =>
    `/sales-enablement/my-marketing-materials/send-by-email/${id}`,
  emailDeliveryEditing: (id: string) =>
    `/sales-enablement/my-marketing-materials/${id}/send-by-email`,
  print: (id: string) => `/sales-enablement/my-marketing-materials/${id}/print`,
};

export const QUERY_KEYS = {
  marketingMaterials: "marketingMaterials",
  deliveries: "deliveries",
};

export const disabledPrintDeliveryMessage = `To have materials printed and shipped to you, request a quote by emailing the following information to aflacserviceexcellence@communicorp.com: \n• Downloaded PDF of the marketing material \n• Quantity to print \n• Mailing address`;
