import { ITableColumnURL } from "shared/components/dataListURL/DataListURLTable";
import { BeeswaxCampaignTableRecord } from "./types";
import { SearchOutlined } from "@ant-design/icons";
import { SearchFilterDropdown } from "shared/components/SearchFilterDropdown";
import AutoEllipsisText from "shared/components/AutoEllipsisText";
import { DateFilterDropdown } from "shared/components/DateFilterDropdown";
import { TimeAgo } from "shared/components/TimeAgo";
import { toUSD } from "utils/helpers.currency";
import { renderDate, getCalendarIcon } from "./helpers";
import { ThumbnailCell } from "shared/components/ThumbnailCell";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "./constants";
import Link from "antd/lib/typography/Link";

export const useColumns = () => {
  const navigate = useNavigate();

  const columns: ITableColumnURL<
    keyof BeeswaxCampaignTableRecord,
    BeeswaxCampaignTableRecord
  >[] = [
    {
      title: "Campaign Name",
      dataIndex: ["name"],
      key: "name",
      filterDropdown: SearchFilterDropdown,
      filterIcon: <SearchOutlined />,
      render: (value, record) => {
        if (record.type === "campaign")
          return (
            <Link
              onClick={() => {
                navigate(ROUTES.update(record.id));
              }}
            >
              {value}
            </Link>
          );

        return (
          <ThumbnailCell
            name={value}
            image={record.creatives?.[0]?.thumbnailUrl}
          />
        );
      },
    },
    {
      key: "startDate",
      title: "Start Date",
      dataIndex: ["startDate"],
      render: renderDate,
      filterDropdown: DateFilterDropdown,
      filterIcon: getCalendarIcon,
    },
    {
      key: "endDate",
      title: "End Date",
      dataIndex: ["endDate"],
      render: renderDate,
      filterDropdown: DateFilterDropdown,
      filterIcon: getCalendarIcon,
    },
    {
      key: "budget",
      title: "Budget",
      dataIndex: ["budget"],
      render: toUSD,
    },
    {
      key: "createdAt",
      title: "Created",
      dataIndex: ["createdAt"],
      render: (date?: Date) => date && <TimeAgo date={date} />,
      filterDropdown: DateFilterDropdown,
      filterIcon: getCalendarIcon,
    },
    {
      key: "updatedAt",
      title: "Last Edit",
      dataIndex: ["updatedAt"],
      render: (date?: Date) => date && <TimeAgo date={date} />,
      filterDropdown: DateFilterDropdown,
      filterIcon: getCalendarIcon,
    },
    {
      key: "updatedBy",
      title: "Last Edited By",
      dataIndex: ["updatedBy"],
      render: (text: string) => <AutoEllipsisText>{text}</AutoEllipsisText>,
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
    },
  ];

  return columns;
};
