export const validateFile = async (
  base64: string,
  instanceWidth: number,
  instanceHeight: number,
) => {
  const isCorrectDimension = await new Promise<boolean>(async resolve => {
    const image = new Image();
    image.src = base64;
    image.onload = () => {
      const { width, height } = image;
      resolve(width === instanceWidth && height === instanceHeight);
    };
  });

  if (!isCorrectDimension) {
    return {
      valid: false,
      error: `Images must have ${instanceWidth}x${instanceHeight} dimensions. Please try another image.`,
    };
  }

  return { valid: true };
};

export const getFileFromUrl = async (
  url: string,
  name: string,
  defaultType = "image/jpeg",
) => {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: data.type || defaultType,
  });
};

export const getBase64 = (file: File) => {
  return new Promise<string>(resolve => {
    const reader = new FileReader();
    reader.addEventListener("load", event => {
      resolve(event.target?.result as string);
    });
    reader.readAsDataURL(file);
  });
};
