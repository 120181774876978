import { Stack, Typography, Slider } from "@mui/material";
import { FPS_STEP, MIN_FPS, MAX_FPS } from "./constants";
import { DownloadFileType } from "screens/designStudio/designStudioV2/editorDrawer/editorDrawerContext/helpers.store";

type FPSSliderProps = {
  fps: number;
  setFps: (value: number) => void;
  type: DownloadFileType;
};

export const FPSSlider = ({ fps, setFps, type }: FPSSliderProps) => {
  if (type !== "GIF") return null;
  return (
    <Stack>
      <Typography variant="caption">FPS</Typography>
      <Slider
        step={FPS_STEP}
        min={MIN_FPS}
        max={MAX_FPS}
        value={fps}
        onChange={(_, value) => setFps(value as number)}
      />
      <Typography variant={"subtitle2"} sx={{ color: "text.secondary" }}>
        {fps} fps
      </Typography>
    </Stack>
  );
};
