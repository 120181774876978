import {
  createContext,
  memo,
  ReactNode,
  useContext,
  useState,
  useCallback,
} from "react";
import {
  Employee,
  Account,
  AccountDrawerState,
} from "shared/types/salesEnablement";

interface IAccountsContext {
  isDrawerOpen: boolean;
  drawerState: AccountDrawerState;
  toggleDrawer: (mode: AccountDrawerState) => void;
  resetDrawer: () => void;
  search: string;
  onSearch: (search: string) => void;
  selectedAccounts: Account[];
  setSelectedAccounts: (accounts: Account[]) => void;

  isEmployeeDrawerOpen: boolean;
  setEmployeeDrawerOpen: (isOpen: boolean) => void;
  updateEmployeeList: (employee: Employee) => void;
  employees: Employee[];
  setEmployees: (employees: Employee[]) => void;
  selectedEmployee: Employee | null;
  setSelectedEmployee: (employee: Employee | null) => void;
  closeEmployeeDrawer: () => void;
  removeCurrentEmployee: () => void;
}

const Context = createContext<IAccountsContext | null>(null);

const ContextProvider = ({ children }: { children: ReactNode }) => {
  const [search, setSearch] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerState, setDrawerState] = useState<AccountDrawerState>("NONE");
  const [isEmployeeDrawerOpen, setEmployeeDrawerOpen] = useState(false);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [selectedAccounts, setSelectedAccounts] = useState<Account[]>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(
    null,
  );

  const toggleDrawer = (mode: AccountDrawerState) => {
    setIsDrawerOpen(true);
    setDrawerState(mode);
  };
  const closeEmployeeDrawer = () => {
    setEmployeeDrawerOpen(false);
    setSelectedEmployee(null);
  };

  const resetDrawer = () => {
    setDrawerState("NONE");
    setEmployees([]);
    setSelectedAccounts([]);
    setIsDrawerOpen(false);
  };
  const onSearch = useCallback(
    (searchInput: string) => {
      setSearch(searchInput);
    },
    [setSearch],
  );
  const updateEmployeeList = useCallback(
    employee => {
      if (selectedEmployee) {
        setEmployees(prev =>
          [...prev.filter(c => c.id !== selectedEmployee.id), employee].filter(
            Boolean,
          ),
        );
      } else {
        setEmployees(prev => [...prev, employee]);
      }
    },
    [setEmployees, selectedEmployee],
  );
  const removeCurrentEmployee = () => {
    if (selectedEmployee) {
      setEmployees(prev => prev.filter(c => c.id !== selectedEmployee.id));
    }
  };

  return (
    <Context.Provider
      value={{
        isDrawerOpen,
        drawerState,
        toggleDrawer,
        resetDrawer,
        search,
        onSearch,
        isEmployeeDrawerOpen,
        setEmployeeDrawerOpen,
        updateEmployeeList,
        employees,
        setEmployees,
        selectedAccounts,
        setSelectedAccounts,
        selectedEmployee,
        setSelectedEmployee,
        closeEmployeeDrawer,
        removeCurrentEmployee,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const AccountsProvider = memo(ContextProvider);

export const useAccountsContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error("Accounts Context must be used within a ContextProvider");
  }

  return context;
};
