import { fabric } from "fabric";
import {
  createContext,
  Dispatch,
  memo,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { videoCompositionEnabled } from "shared/constants/assetExporter";
import {
  FeedTblRow,
  MediaColumn,
  STEP_CREATE_BATCH,
  STEP_CREATE_BATCH_V2,
  StepStatus,
  TAssetBatch,
  TAudioFile,
  TComposition,
  TConditionType,
  TTemplateComposition,
} from "shared/types/assetExporter";
import {
  GetArtboardsResponse,
  IArtboard,
  ITemplate,
} from "shared/types/designStudio";
import { formatDimensions } from "utils/helpers";
import { createVideoElement } from "utils/media/utils.input";
import { TFilterOption } from "../../assetBatchDrawer/dynamicText/panelTemplate/templateDrawer/TemplateList.utils";
import { getImageObjectWithResizeType } from "../../assetBatchDrawer/dynamicText/utils.fabric";
import { useGetMediaColumns } from "../hooks/useGetMediaColumns";
import {
  FiltersType,
  TBackgroundMedia,
  TMediaResizeType,
  TVariable,
} from "../types";
import { isValidApplyCondition, isValidDynamicText } from "../validators";
import {
  getBackgroundResizeType,
  isTemplateComposition,
  parseCompositionToVariables,
  parseConditions,
  removeItemByCompositionId,
  replaceBackground,
} from "./AssetBatchesContext.utils";
import { getDummyTarget, getVideoImage } from "./utils";

export type TRuleCondition = {
  index: number;
  operator: "and" | "or";
  columnName: string;
  comparisonOperator: TConditionType;
  value: string;
};

export const getEmptyRuleCondition = (index: number) => {
  return {
    index,
    operator: "and",
    columnName: "",
    comparisonOperator: "Equals",
    value: "",
  } as TRuleCondition;
};

interface ContextProps {
  loading: boolean;
  previewLoading: boolean;
  selectedTemplate?: ITemplate;
  searchValue: string;
  variables: Array<TCompositionVariables>;
  columns: string[];
  rows: FeedTblRow[];
  feedId: string;
  ruleConditions: TRuleCondition[];
  previewCount: number;
  batchName: string | undefined;
  currentGroupNum: number;
  hasFixedBackground: boolean;
  backgroundMedias?: Record<string, TBackgroundMedia | undefined>;
  backgroundColumns?: Record<string, string | undefined>;
  backgroundResizeTypes?: Record<string, TMediaResizeType | undefined>;
  mediaColumns?: MediaColumn[];
  isAllValid: boolean;
  isFirstStepValid: boolean;
  isSecondStepValid: boolean;
  maxStep: STEP_CREATE_BATCH | STEP_CREATE_BATCH_V2;
  currentStep: STEP_CREATE_BATCH | STEP_CREATE_BATCH_V2;
  nameInputError: string | undefined;
  showMediaAlert: boolean;
  showTextAlert: boolean;
  showReload: boolean;
  openFileManager: boolean;
  compositions: TComposition[];
  templatesToUse: ITemplate[];
  currentCompositionId?: string;
  preventAutoScroll: boolean;
  isFetchingMediaCols: boolean;
  addTemplateBlocked: boolean;
  compositionToReplace: TComposition | undefined;
  editingTemplate: string | undefined;
  artboard: IArtboard | undefined;
  defaultTemplateFilters: Partial<FiltersType> | undefined;
  isTemporalRemovedComposition: boolean;
  compositionPreviewPlaying: TComposition | undefined;
  namingRulePattern: string;
  namingRuleText: string;
  isNamingRuleTextCleared: boolean;
  audioFiles: TAudioFile[];
  isFetchingAudioFiles: boolean;
  editingAudioFiles: TAudioFile[];
  setOpenFileManager: Dispatch<SetStateAction<boolean>>;
  setShowMediaAlert: Dispatch<SetStateAction<boolean>>;
  setShowTextAlert: Dispatch<SetStateAction<boolean>>;
  setPreviewLoading: (previewLoading: boolean) => void;
  clearContext: () => void;
  saveBatchName: (newBatchName: string | undefined) => void;
  getStepStatus: (currentStep: number, idx: number) => StepStatus;
  getStepsDisabled: (stepIndex: number) => boolean;
  updateMaxStep: (newMaxStep: STEP_CREATE_BATCH | STEP_CREATE_BATCH_V2) => void;
  updateCurrentStep: (
    currentStep: STEP_CREATE_BATCH | STEP_CREATE_BATCH_V2,
  ) => void;
  setSelectedTemplate: Dispatch<SetStateAction<ITemplate | undefined>>;
  setTemplates: Dispatch<SetStateAction<ITemplate[]>>;
  setVariables: Dispatch<SetStateAction<Array<TCompositionVariables>>>;
  setBackgroundResizeTypes: (
    resizeType: Record<string, TMediaResizeType | undefined> | undefined,
  ) => void;
  setBackgroundMedias: Dispatch<
    SetStateAction<Record<string, TBackgroundMedia | undefined> | undefined>
  >;
  setBackgroundColumns: Dispatch<
    SetStateAction<Record<string, string | undefined> | undefined>
  >;
  setHasFixedBackground: (hasFixedBackground: boolean) => void;
  setShowReload: Dispatch<SetStateAction<boolean>>;
  useEditingAssetBatchEffect: (
    editingAssetBatch: TAssetBatch | undefined,
    filterOptions: TFilterOption,
    artboards: GetArtboardsResponse | undefined,
  ) => void;
  useTemplateBackgroundEffect: (
    canvas: fabric.Canvas | undefined,
    editingComposition: TComposition,
    isMediaMaskOn: boolean,
    showVariablesOn: boolean,
    selectedRow: any,
    backgroundMedias: Record<string, TBackgroundMedia | undefined> | undefined,
    setBackgroundAudios:
      | Dispatch<SetStateAction<Record<string, HTMLAudioElement | undefined>>>
      | undefined,
    setBackgroundEle:
      | Dispatch<SetStateAction<HTMLVideoElement | null>>
      | undefined,
  ) => void;
  setRuleConditions: Dispatch<SetStateAction<TRuleCondition[]>>;
  setPreviewCount: Dispatch<SetStateAction<number>>;
  saveRuleConditions: (newRuleConditions: TRuleCondition[]) => void;
  setCompositions: Dispatch<SetStateAction<TComposition[]>>;
  setCurrentCompositionId: Dispatch<SetStateAction<string | undefined>>;
  setPreventAutoScroll: Dispatch<SetStateAction<boolean>>;
  replaceTemplateComposition: (
    editingComposition: TTemplateComposition,
  ) => void;
  setCompositionToReplace: (
    value: SetStateAction<TComposition | undefined>,
  ) => void;
  removeComposition: (compositionToRemove: TComposition) => void;
  removeBackgrounds: (compositionToRemove: TTemplateComposition) => void;
  setEditingTemplate: Dispatch<SetStateAction<string | undefined>>;
  changeTemporalRemoved: (
    compositionToRemove: TComposition,
    value: boolean,
  ) => void;
  setArtboard: Dispatch<SetStateAction<IArtboard | undefined>>;
  clearCompositions: () => void;
  setDefaultTemplateFilters: (
    value: SetStateAction<Partial<FiltersType> | undefined>,
  ) => void;
  setCompositionPreviewPlaying: Dispatch<
    SetStateAction<TComposition | undefined>
  >;
  setNamingRulePattern: Dispatch<SetStateAction<string>>;
  setNamingRuleText: Dispatch<SetStateAction<string>>;
  setIsNamingRuleTextCleared: Dispatch<SetStateAction<boolean>>;
  setAudioFiles: Dispatch<React.SetStateAction<TAudioFile[]>>;
  setIsFetchingAudioFiles: Dispatch<SetStateAction<boolean>>;
  setEditingAudioFiles: Dispatch<SetStateAction<TAudioFile[]>>;
}

export type TCompositionVariables = {
  compositionId: string;
  variables: TVariable[];
};

type ContextProviderProps = {
  columns: string[];
  children: ReactNode;
  rows: FeedTblRow[];
  feedId: string;
};

const Context = createContext<ContextProps>({} as ContextProps);

const ContextProvider = ({
  children,
  columns,
  rows,
  feedId,
}: ContextProviderProps) => {
  const [templates, setTemplates] = useState<ITemplate[]>([]);
  const [compositions, setCompositions] = useState<TComposition[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<ITemplate>();
  const [variables, setVariables] = useState<TCompositionVariables[]>([]);

  const [artboard, setArtboard] = useState<IArtboard>();

  const [nameInputError, setNameInputError] = useState<string | undefined>();
  const [loading, setLoading] = useState(true);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [ruleConditions, setRuleConditions] = useState<TRuleCondition[]>([
    getEmptyRuleCondition(0),
  ]);
  const [previewCount, setPreviewCount] = useState(0);
  const [batchName, setBatchName] = useState<string>();
  const [currentGroupNum, setCurrentGroupNum] = useState(1);
  const [hasFixedBackground, setHasFixedBackground] = useState(false);
  // For AssetBatchDrawer START
  const [backgroundResizeTypes, setBackgroundResizeTypes] =
    useState<Record<string, TMediaResizeType | undefined>>();
  const [backgroundColumns, setBackgroundColumns] =
    useState<Record<string, string | undefined>>();
  const [backgroundMedias, setBackgroundMedias] =
    useState<Record<string, TBackgroundMedia | undefined>>();
  // For AssetBatchDrawer END
  const [currentCompositionId, setCurrentCompositionId] = useState<string>();
  const [preventAutoScroll, setPreventAutoScroll] = useState(false);
  const [maxStep, setMaxStep] = useState<
    STEP_CREATE_BATCH | STEP_CREATE_BATCH_V2
  >(STEP_CREATE_BATCH.ONE_CONDITION);
  const [currentStep, setCurrentStep] = useState<
    STEP_CREATE_BATCH | STEP_CREATE_BATCH_V2
  >(STEP_CREATE_BATCH.ONE_CONDITION);
  const [showMediaAlert, setShowMediaAlert] = useState(true);
  const [showTextAlert, setShowTextAlert] = useState(true);
  const { data: mediaColumns, isFetching: isFetchingMediaCols } =
    useGetMediaColumns(feedId, { rules: ruleConditions });
  const [showReload, setShowReload] = useState(false);
  const [editingTemplate, setEditingTemplate] = useState<string>();
  const [openFileManager, setOpenFileManager] = useState(false);
  const [compositionToReplace, setCompositionToReplace] =
    useState<TComposition>();
  const [defaultTemplateFilters, setDefaultTemplateFilters] =
    useState<Partial<FiltersType>>();
  const [compositionPreviewPlaying, setCompositionPreviewPlaying] =
    useState<TComposition>();
  const [namingRulePattern, setNamingRulePattern] = useState("");
  const [namingRuleText, setNamingRuleText] = useState("");
  const [isNamingRuleTextCleared, setIsNamingRuleTextCleared] = useState(false);
  const [audioFiles, setAudioFiles] = useState<TAudioFile[]>([]);
  const [editingAudioFiles, setEditingAudioFiles] = useState<TAudioFile[]>([]);
  const [isFetchingAudioFiles, setIsFetchingAudioFiles] = useState(false);

  const updateMaxStep = useCallback(
    (newMaxStep: STEP_CREATE_BATCH | STEP_CREATE_BATCH_V2) => {
      setMaxStep(newMaxStep);
    },
    [],
  );
  const updateCurrentStep = useCallback(
    (currStep: STEP_CREATE_BATCH | STEP_CREATE_BATCH_V2) => {
      setCurrentStep(currStep);
    },
    [],
  );

  const saveBatchName = useCallback((newBatchName: string | undefined) => {
    setBatchName(newBatchName);
    if (newBatchName && newBatchName.trim() !== "") {
      setNameInputError(undefined);
      return;
    }
    setNameInputError("create asset batch name");
  }, []);

  const saveRuleConditions = useCallback(
    (newRuleConditions: TRuleCondition[]) => {
      updateMaxStep(currentStep);
      setRuleConditions(newRuleConditions);
    },
    [currentStep, updateMaxStep],
  );

  const clearContext = useCallback(() => {
    setCompositions([]);
    setTemplates([]);
    setSelectedTemplate(undefined);
    setSearchValue("");
    setBackgroundMedias(undefined);
    setBackgroundColumns(undefined);
    setBackgroundResizeTypes(undefined);
    setVariables([]);
    setRuleConditions([getEmptyRuleCondition(0)]);
    setPreviewCount(0);
    setBatchName(undefined);
    setCurrentGroupNum(1);
    setCurrentStep(STEP_CREATE_BATCH.ONE_CONDITION);
    setMaxStep(STEP_CREATE_BATCH.ONE_CONDITION);
    setNameInputError(undefined);
    setCurrentCompositionId(undefined);
    setPreventAutoScroll(false);
    setCompositionToReplace(undefined);
    setEditingTemplate(undefined);
    setArtboard(undefined);
    setNamingRulePattern("");
    setNamingRuleText("");
    setIsNamingRuleTextCleared(false);
    setAudioFiles([]);
    setIsFetchingAudioFiles(false);
  }, []);

  useEffect(() => {
    const contextMounted = () => {
      setLoading(false);
    };

    contextMounted();

    return () => {
      setLoading(true);
      setCompositions([]);
      setTemplates([]);
      setSelectedTemplate(undefined);
      setSearchValue("");
      setBackgroundMedias(undefined);
      setBackgroundColumns(undefined);
      setBackgroundResizeTypes(undefined);
      setVariables([]);
      setRuleConditions([getEmptyRuleCondition(0)]);
      setPreviewCount(0);
      setBatchName(undefined);
      setCurrentGroupNum(1);
      setCurrentStep(STEP_CREATE_BATCH.ONE_CONDITION);
      setMaxStep(STEP_CREATE_BATCH.ONE_CONDITION);
      setNameInputError(undefined);
      setCurrentCompositionId(undefined);
      setPreventAutoScroll(false);
      setCompositionToReplace(undefined);
      setEditingTemplate(undefined);
      setArtboard(undefined);
      setNamingRulePattern("");
      setNamingRuleText("");
      setIsNamingRuleTextCleared(false);
      setAudioFiles([]);
      setIsFetchingAudioFiles(false);
    };
  }, []);

  const isFirstStepValid =
    !!batchName?.trim() &&
    isValidApplyCondition(ruleConditions, previewCount) &&
    (videoCompositionEnabled ? !!artboard : true);
  const isSecondStepValid = compositions.length > 0;
  const isTemporalRemovedComposition = useMemo(
    () => compositions.some(comp => comp.temporalRemoved),
    [compositions],
  );

  const getStepStatus = (currentStep: number, idx: number) => {
    if (currentStep === idx) return "process";
    if (currentStep < idx) return "wait";
    if (idx === 0 && isFirstStepValid) return "finish";
    if (idx === 1 && isSecondStepValid) return "finish";
    return "error";
  };

  const getStepsDisabled = (stepIndex: number) => {
    if (stepIndex === 1) return !isFirstStepValid;
    if (stepIndex === 2) return !isFirstStepValid || !isSecondStepValid;
    return false;
  };

  const isAllValid =
    isFirstStepValid &&
    compositions.length > 0 &&
    compositions.every(
      composition =>
        !composition.temporalRemoved &&
        (isTemplateComposition(composition)
          ? isValidDynamicText(
              composition.variables,
              variables.find(
                variable =>
                  variable.compositionId === composition.compositionId,
              )?.variables ?? [],
              templates.find(template => template.id === composition.template),
            )
          : !!composition.column || !!composition.url),
    ) &&
    (compositions.length < 2 || compositions.every(comp => comp.duration > 0));

  const useEditingAssetBatchEffect = (
    editingAssetBatch: TAssetBatch | undefined,
    filterOptions: TFilterOption,
    artboards: GetArtboardsResponse | undefined,
  ) => {
    useEffect(() => {
      if (
        editingAssetBatch &&
        currentStep === STEP_CREATE_BATCH.ONE_CONDITION
      ) {
        saveBatchName(editingAssetBatch.name);
        saveRuleConditions(parseConditions(editingAssetBatch.conditions));
        setCompositions(editingAssetBatch.compositions);

        let currentArtboard: IArtboard | undefined;

        if (editingAssetBatch.artboardName && artboards?.result?.artboards) {
          currentArtboard = artboards.result.artboards.find(
            artboard => artboard.name === editingAssetBatch.artboardName,
          );
          setArtboard(currentArtboard);
        }

        const templateCompositions = editingAssetBatch.compositions.filter(
          isTemplateComposition,
        ) as TTemplateComposition[];

        setDefaultTemplateFilters({
          status: ["PUBLISHED"],
          stamps: [0],
          ...(videoCompositionEnabled && currentArtboard
            ? {
                dimension: [formatDimensions(currentArtboard)],
                ...(compositions.length > 1
                  ? {
                      type: filterOptions?.type?.filter(
                        option => option !== "html",
                      ),
                    }
                  : {}),
              }
            : {}),
        });

        setVariables(parseCompositionToVariables(templateCompositions));
        templateCompositions.forEach(composition => {
          setBackgroundResizeTypes(prev => ({
            ...prev,
            [composition.compositionId]: getBackgroundResizeType(
              composition.variables,
            ),
          }));
        });
        setPreventAutoScroll(false);
        setCurrentCompositionId(
          editingAssetBatch.compositions[0]?.compositionId,
        );
      }
    }, [editingAssetBatch, filterOptions, artboards]);
  };

  const addTemplateBlocked = useMemo(
    () =>
      isTemporalRemovedComposition ||
      (templates.length > 0 &&
        templates.some(template => !!template.htmlFileUrl)),

    [templates, isTemporalRemovedComposition],
  );

  const useTemplateBackgroundEffect = (
    canvas: fabric.Canvas | undefined,
    editingComposition: TComposition,
    isMediaMaskOn: boolean,
    showVariablesOn: boolean,
    selectedRow: any,
    backgroundMedias: Record<string, TBackgroundMedia | undefined> | undefined,
    setBackgroundAudios:
      | Dispatch<SetStateAction<Record<string, HTMLAudioElement | undefined>>>
      | undefined,
    setBackgroundEle:
      | Dispatch<SetStateAction<HTMLVideoElement | null>>
      | undefined,
  ) => {
    useEffect(() => {
      const resetBackgroundImage = () => {
        canvas?.setBackgroundImage(new fabric.Image(""), () => {
          canvas.renderAll();

          setPreviewLoading(false);
        });
      };

      if (hasFixedBackground) {
        setPreviewLoading(false);
        return;
      }

      if (
        !backgroundMedias?.[editingComposition.compositionId] ||
        !isMediaMaskOn ||
        !showVariablesOn
      ) {
        resetBackgroundImage();
        return;
      }
      const { type, src } = backgroundMedias[editingComposition.compositionId]!;

      // remove theme background layer if exists
      const themeBg = canvas
        ?.getObjects()
        .find(obj => (obj as any).customType === "theme_background");
      if (themeBg) canvas?.remove(themeBg);

      const dummyTarget = getDummyTarget(canvas);

      // Set video background
      if (["mp4"].includes(type)) {
        const addVideoBackground = async () => {
          const videoEle = await createVideoElement(src, "mp4");
          videoEle.id = themeBg?.name ?? "";
          if (setBackgroundAudios) {
            const audioEle = document.createElement("audio");
            audioEle.src = src;
            audioEle.id = themeBg?.name ?? "";
            setBackgroundAudios(prev => ({
              ...prev,
              [editingComposition.compositionId]: audioEle,
            }));
          }
          const videoImage = getVideoImage(videoEle);
          const resizedVideo = getImageObjectWithResizeType(
            videoImage,
            dummyTarget,
            backgroundResizeTypes?.[editingComposition.compositionId] ?? "fill",
          );
          (resizedVideo as any).contentType = "video";

          setBackgroundEle?.(videoEle);
          canvas?.setBackgroundImage(resizedVideo, () =>
            setPreviewLoading(false),
          );
        };

        addVideoBackground();
        return;
      }
      // Set image background
      fabric.Image.fromURL(src, urlImg => {
        const img = urlImg.set({
          left: 0,
          top: 0,
          selectable: false,
        });

        const resizedImg = getImageObjectWithResizeType(
          img,
          dummyTarget,
          backgroundResizeTypes?.[editingComposition.compositionId] ?? "fill",
        );
        canvas?.setBackgroundImage(resizedImg, () => {
          canvas.renderAll();
          setPreviewLoading(false);
        });
      });
    }, [
      canvas,
      isMediaMaskOn,
      editingComposition.compositionId,
      showVariablesOn,
      selectedRow,
      backgroundMedias,
      setBackgroundAudios,
      setBackgroundEle,
    ]);
  };

  const replaceTemplateComposition = useCallback(
    (editingComposition: TTemplateComposition) => {
      setOpenFileManager(true);
      setCompositionToReplace(editingComposition);
    },
    [setOpenFileManager],
  );

  const removeBackgrounds = (compositionToRemove: TTemplateComposition) => {
    setBackgroundMedias(backMedias =>
      replaceBackground(backMedias, compositionToRemove),
    );
    setBackgroundColumns(backCols =>
      replaceBackground(backCols, compositionToRemove),
    );
    setBackgroundResizeTypes(backResize =>
      replaceBackground(backResize, compositionToRemove),
    );
  };

  const removeComposition = (compositionToRemove: TComposition) => {
    setCompositions(oldCompositions =>
      removeItemByCompositionId(oldCompositions, compositionToRemove),
    );

    if (!isTemplateComposition(compositionToRemove)) return;

    setVariables(oldVariables =>
      removeItemByCompositionId(oldVariables, compositionToRemove),
    );
    setTemplates(oldTemplates =>
      oldTemplates.filter(
        oldTemplate => oldTemplate.id !== compositionToRemove.template,
      ),
    );

    removeBackgrounds(compositionToRemove);
  };

  const changeTemporalRemoved = useCallback(
    (compositionToRemove: TComposition, value: boolean) => {
      setCompositions(oldCompositions =>
        oldCompositions.map(composition => {
          if (composition.compositionId === compositionToRemove.compositionId) {
            return {
              ...composition,
              temporalRemoved: value,
            };
          }
          return composition;
        }),
      );
    },
    [setCompositions],
  );

  const clearCompositions = () => {
    setCompositions([]);
    setVariables([]);
    setBackgroundMedias(undefined);
    setBackgroundColumns(undefined);
    setBackgroundResizeTypes(undefined);
  };

  return (
    <Context.Provider
      value={{
        loading,
        previewLoading,
        searchValue,
        selectedTemplate,
        columns,
        rows,
        variables,
        feedId,
        ruleConditions,
        previewCount,
        batchName,
        currentGroupNum,
        compositions: compositions.filter(
          composition => !composition.temporalRemoved,
        ),
        backgroundMedias,
        backgroundColumns,
        backgroundResizeTypes,
        hasFixedBackground,
        mediaColumns,
        isAllValid,
        isFirstStepValid,
        isSecondStepValid,
        maxStep,
        currentStep,
        nameInputError,
        showMediaAlert,
        showTextAlert,
        showReload,
        openFileManager,
        isFetchingMediaCols,
        currentCompositionId,
        preventAutoScroll,
        templatesToUse: templates,
        addTemplateBlocked,
        compositionToReplace,
        editingTemplate,
        artboard,
        defaultTemplateFilters,
        isTemporalRemovedComposition,
        compositionPreviewPlaying,
        namingRulePattern,
        namingRuleText,
        isNamingRuleTextCleared,
        audioFiles,
        isFetchingAudioFiles,
        editingAudioFiles,
        setOpenFileManager,
        setShowMediaAlert,
        setShowTextAlert,
        setPreviewLoading,
        clearContext,
        saveBatchName,
        getStepStatus,
        getStepsDisabled,
        updateMaxStep,
        updateCurrentStep,
        setSelectedTemplate,
        setTemplates,
        setVariables,
        setCompositions,
        setBackgroundColumns,
        setBackgroundMedias,
        setBackgroundResizeTypes,
        setHasFixedBackground,
        setShowReload,
        useEditingAssetBatchEffect,
        useTemplateBackgroundEffect,
        setRuleConditions,
        setPreviewCount,
        saveRuleConditions,
        setCurrentCompositionId,
        setPreventAutoScroll,
        setCompositionToReplace,
        replaceTemplateComposition,
        removeComposition,
        removeBackgrounds,
        setEditingTemplate,
        changeTemporalRemoved,
        setArtboard,
        clearCompositions,
        setDefaultTemplateFilters,
        setCompositionPreviewPlaying,
        setNamingRulePattern,
        setNamingRuleText,
        setIsNamingRuleTextCleared,
        setAudioFiles,
        setIsFetchingAudioFiles,
        setEditingAudioFiles,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const AssetBatchesProvider = memo(ContextProvider);

export const useAssetBatchesContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error("Context must be used within a ContextProvider");
  }

  return context;
};
