import { useEffect, useLayoutEffect, useRef } from "react";

export const useOnWindowResize = (onResize: () => void) => {
  const callbackRef = useRef(onResize);
  useLayoutEffect(() => {
    callbackRef.current = onResize;
  }, [onResize]);

  useEffect(() => {
    const handleResize = () => {
      callbackRef.current();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
};
