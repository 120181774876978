import { Button, Drawer, Space } from "antd";
import { useMatch, useParams } from "react-router-dom";
import { ROUTES } from "./utils/constants";
import Preview from "../marketingMaterialDrawer/Preview";
import TemplateRenderDataProvider from "screens/designStudio/hooks/TemplateRenderDataProvider";
import { useSelectedMarketingMaterial } from "./hooks/useSelectedMarketingMaterial";
import { PrintForm } from "./printDrawer/PrintForm";
import styles from "./PrintDrawer.module.scss";
import useNavigateWithSearch from "shared/hooks/useNavigateWithSearch";

const printFormId = "printForm";

export const PrintDrawer = () => {
  const navigateWithSearch = useNavigateWithSearch();
  const { materialId } = useParams();
  const onClose = () => {
    navigateWithSearch(ROUTES.home);
  };
  const { material, templateFile } = useSelectedMarketingMaterial({
    materialId,
  });

  const isVisible = useMatch(ROUTES.print(materialId ?? ""));

  if (!material) return null;
  return (
    <Drawer
      title="Order Prints"
      className={styles.drawer}
      width="calc(100vw - 48px)"
      visible={!!isVisible}
      onClose={onClose}
      destroyOnClose
      footerStyle={{ textAlign: "right" }}
      footer={
        <Space>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" htmlType="submit" form={printFormId}>
            Next
          </Button>
        </Space>
      }
    >
      <div className={styles.container}>
        <div className={styles.preview}>
          <TemplateRenderDataProvider file={templateFile} material={material}>
            <Preview selectedFile={templateFile} />
          </TemplateRenderDataProvider>
        </div>
        <PrintForm material={material} formId={printFormId} />
      </div>
    </Drawer>
  );
};
