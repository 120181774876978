import { CloudUploadOutlined } from "@ant-design/icons";
import { Button, Drawer, Modal, notification, Upload } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadRequestOption } from "rc-upload/lib/interface";
import { util } from "redux/media/media.api";
import { useMediaActions } from "redux/media/media.slice";
import { useAppDispatch, useAppSelector } from "./redux";
import { useQPBreadcrumb } from "./useQPBreadcrumb";
import { finishUpload, getPresignedUrl, uploadToS3 } from "./utils.upload";
import { UPLOAD_ACCEPT_FILETYPES } from "./constants";

export const FileReplace = () => {
  const assetToReplace = useAppSelector(state => state.media.assetToReplace);
  const { current } = useQPBreadcrumb();
  const { setAssetToReplace } = useMediaActions();
  const dispatch = useAppDispatch();

  const onClose = () => {
    setAssetToReplace();
  };

  const uploadedSuccessfully = () => {
    notification.success({
      message: "File Replaced",
      duration: 3,
      placement: "bottomRight",
    });
  };

  const uploadedFailed = () => {
    notification.error({
      message: "Failed to replace file",
      duration: 3000,
      placement: "bottomRight",
    });
  };

  const handleChange = (info: UploadChangeParam) => {
    const allDone = info.fileList.every(file => file.percent === 100);
    if (allDone) {
      dispatch(util.invalidateTags(["assets", "searchResults"]));
      onClose();
    }
  };

  const confirmReplaceModal = (file: File) => {
    return (
      (!!assetToReplace &&
        new Promise<boolean>((resolve, reject) => {
          Modal.confirm({
            title: `Are you sure you want to replace ${assetToReplace?.name} with ${file.name}?`,
            okText: "Replace",
            cancelText: "Cancel",
            onOk: () => resolve(true),
            onCancel: () => reject(false),
          });
        })) ||
      true
    );
  };

  return (
    <Drawer
      title={`Replace ${assetToReplace?.filename}`}
      destroyOnClose
      maskClosable
      width={500}
      onClose={onClose}
      visible={!!assetToReplace}
      footer={
        <Button type="primary" onClick={onClose}>
          Ok
        </Button>
      }
      footerStyle={{ display: "flex", justifyContent: "flex-end" }}
    >
      <div>
        <Upload.Dragger
          multiple={false}
          accept={UPLOAD_ACCEPT_FILETYPES}
          beforeUpload={confirmReplaceModal}
          showUploadList={{ showRemoveIcon: true }}
          customRequest={replaceFileRequest({
            folderId: current,
            assetId: assetToReplace?.id as string,
            successCallback: uploadedSuccessfully,
            errorCallback: uploadedFailed,
          })}
          onChange={handleChange}
        >
          <p className="ant-upload-drag-icon">
            <CloudUploadOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Upload.Dragger>
      </div>
    </Drawer>
  );
};

const replaceFileRequest = ({
  folderId,
  assetId,
  successCallback,
  errorCallback,
}: {
  folderId: string;
  assetId: string;
  successCallback: () => void;
  errorCallback: () => void;
}) => {
  return async ({ file, onSuccess, onError }: UploadRequestOption) => {
    try {
      // Get pre-signed url from AWS.
      const { presignedUrl, processId } = await getPresignedUrl(
        folderId,
        file as File,
        assetId,
      );
      // Upload filt to S3.
      await uploadToS3(presignedUrl, file as File);
      // Notify to webdam.
      await finishUpload(processId);
      // Notify uploader.
      onSuccess?.(null, new XMLHttpRequest());
      successCallback();
    } catch (e) {
      onError?.(e as Error);
      errorCallback();
    }
  };
};
