import { Input, Switch, Tooltip } from "antd";
import { ChangeEvent, useState } from "react";
import {
  TVariable,
  TVariableAlias,
  TVariableAliasInfo,
} from "shared/types/designStudio";
import { TAliasAction } from "../VariableAlias";
import * as utils from "../VariableAlias.utils";

type Props = {
  variables?: TVariable[];
  selectedVariable?: TVariable;
  variableAlias?: TVariableAlias;
};

type Handlers = {
  onAliasUpdate: (
    previousAlias: TVariableAlias,
    alias: TVariableAlias,
    aliasInfo: TVariableAliasInfo,
  ) => void;
  onSwitchChange: (type: TAliasAction) => (isOn: boolean) => void;
};
const AliasPopoverContent = ({
  variables,
  selectedVariable,
  variableAlias,
  onAliasUpdate,
  onSwitchChange,
}: Props & Handlers) => {
  const invalidMaskValueMessage =
    "The mask value should not be the same as any variable name.";
  const duplicatedMaskValueMessage =
    "The alias name must be unique to each variable, where masks cannot have matching text with others.";

  const [flagInvalidMaskErrorType, setFlagInvalidMaskErrorType] = useState<
    "invalidMaskValue" | "duplicatedMask"
  >();

  const isValidVariable = (value: string, variableAlias: TVariableAlias) => {
    const isThereDuplicate = utils.isDuplicateMask(
      variableAlias,
      selectedVariable?.value || "",
      value,
    );
    if (isThereDuplicate) {
      setFlagInvalidMaskErrorType("duplicatedMask");
      return false;
    }

    const isSameAsVariable = utils.isSameAsVariableName(value, variables || []);

    if (isSameAsVariable) {
      setFlagInvalidMaskErrorType("invalidMaskValue");
      return false;
    }

    setFlagInvalidMaskErrorType(undefined);
    return true;
  };

  const onInputFocus = (e: ChangeEvent<HTMLInputElement>) => {
    if (!variableAlias || !e.target.value) {
      return;
    }
    const { value } = e.target;
    if (!value.trim()) return;

    return isValidVariable(value, variableAlias);
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!variableAlias || !selectedVariable) return;

    const {
      target: { value },
    } = e;

    const okToProceed = isValidVariable(value, variableAlias);
    if (!okToProceed) return;

    const variable = selectedVariable.value;
    const previousVariableAlias: TVariableAlias = {
      ...variableAlias,
    };

    const variableInfo = {
      ...(variableAlias[variable] || {}),
      // If the value is empty, then it should fall back to the variable name
      customVariable: value || variable,
    };
    const updatedAlias = {
      ...(variableAlias || {}),
      [variable]: variableInfo,
    };

    onAliasUpdate(previousVariableAlias, updatedAlias, variableInfo);
  };

  return (
    <div className="variable-alias-content">
      <span className="variable-alias-input-wrapper">
        <div className="label" style={{ flex: 1 }}>
          Custom variable
        </div>
        <div className="input" style={{ flex: 1 }}>
          <Tooltip
            title={
              flagInvalidMaskErrorType === "duplicatedMask"
                ? duplicatedMaskValueMessage
                : invalidMaskValueMessage
            }
            visible={!!flagInvalidMaskErrorType}
          >
            <Input
              onFocusCapture={e => e.target.select()}
              placeholder="Custom variable"
              value={
                variableAlias?.[selectedVariable?.value || ""]?.customVariable
              }
              onFocus={onInputFocus}
              onBlur={e => {
                e.preventDefault();

                setFlagInvalidMaskErrorType(undefined);
              }}
              onChange={onInputChange}
            />
          </Tooltip>
        </div>
      </span>

      {(["mask", "caps"] as TAliasAction[]).map(type => {
        const alias = variableAlias?.[selectedVariable?.value || ""];

        let title: string;
        let isOn: boolean | undefined;
        switch (type) {
          case "mask":
            title = "Mask";
            isOn = alias?.isMaskOn ?? false;
            break;

          case "caps":
            title = "CAPS";
            isOn = alias?.isCapsOn ?? false;
            break;
        }
        return (
          <div key={`${type}-switch`} style={{ marginLeft: "14px" }}>
            <span
              className="variable-alias-trigger-wrapper"
              onMouseEnter={e => {
                e.preventDefault();
              }}
              onMouseLeave={e => {
                e.preventDefault();
              }}
            >
              <div className="label" style={{ flex: 1 }}>
                {title}
              </div>
              <div className="input" style={{ flex: 1 }}>
                <Switch onChange={onSwitchChange(type)} checked={isOn} />
              </div>
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default AliasPopoverContent;
