import { Col, Row } from "antd";
import ResizeObserver from "rc-resize-observer";
import { useEffect, useRef, useState, useCallback } from "react";
import { BROADCAST_CHANNEL_NAME } from "shared/constants/assetExporter";
import { useBroadCastChannel } from "shared/hooks/useBroadcastChannel";
import { useAssetBatchesContext } from "../../shared/contexts/AssetBatchesContext";
import { TemplateDrawer } from "./panelTemplate/TemplateDrawer";
import { useMediaPreviewContext } from "../../shared/contexts/MediaPreviewContext";
import { PanelTemplateHeader } from "./panelTemplate/PanelTemplateHeader";
import { PanelTemplateZoom } from "./panelTemplate/PanelTemplateZoom";
import { CompositionPreview } from "./panelTemplate/CompositionPreview";
import styles from "./PanelTemplate.module.scss";
import { hasHTMLTemplateCompositions } from "../utils";

export const DEFAULT_CANVAS_HEIGHT = 500;
export const DEFAULT_CANVAS_WIDTH = 500;

export const PanelTemplate = () => {
  const previewRef = useRef<HTMLDivElement>(null);
  const [isZoomed, setIsZoomed] = useState(false);
  const [fitToScreenDimensions, setFitToScreenDimensions] = useState({
    height: 0,
    width: 0,
    scrollHeight: 0,
  });
  const {
    compositions,
    setShowReload,
    openFileManager,
    setOpenFileManager,
    templatesToUse: templates,
  } = useAssetBatchesContext();
  const { zoomPercentage, setZoomPercentage, isFitToScreen, setIsFitToScreen } =
    useMediaPreviewContext();

  const playerWidth = fitToScreenDimensions.width
    ? (fitToScreenDimensions.width * zoomPercentage) / 100
    : undefined;

  const mainStyle = {
    width: playerWidth ? `${playerWidth}px` : "auto",
    height: isFitToScreen ? `${fitToScreenDimensions.height}px` : "auto",
    maxHeight: isFitToScreen ? `${fitToScreenDimensions.height}px` : "none",
  };

  useBroadCastChannel(BROADCAST_CHANNEL_NAME, () => setShowReload(true));

  const handleResize = useCallback(() => {
    if (previewRef.current && !isZoomed && !isFitToScreen) {
      const { clientHeight, clientWidth, scrollHeight } = previewRef.current;
      setFitToScreenDimensions(prev => ({
        height: Math.max(prev.height, clientHeight),
        width: clientWidth,
        scrollHeight,
      }));
    }
  }, [isFitToScreen, isZoomed]);

  useEffect(() => {
    handleResize();
  }, [handleResize, compositions.length]);

  useEffect(() => {
    const observer = new MutationObserver(mutations => {
      for (const mutation of mutations) {
        if (
          mutation.type === "childList" ||
          mutation.type === "characterData"
        ) {
          if (!previewRef.current) return;
          const newScrollHeight = previewRef.current.scrollHeight;
          if (
            newScrollHeight !== fitToScreenDimensions.scrollHeight &&
            !isZoomed &&
            !isFitToScreen
          ) {
            setFitToScreenDimensions(prev => ({
              ...prev,
              scrollHeight: newScrollHeight,
            }));
          }
        }
      }
    });

    if (previewRef.current) {
      observer.observe(previewRef.current, {
        childList: true,
        subtree: true,
        characterData: true,
      });
    }

    return () => observer.disconnect();
  }, [
    fitToScreenDimensions.scrollHeight,
    isFitToScreen,
    isZoomed,
    compositions.length,
  ]);

  return (
    <>
      <PanelTemplateHeader />
      <ResizeObserver
        onResize={() => {
          if (!isZoomed) handleResize();
        }}
      >
        <Row ref={previewRef} className={styles.previewRow}>
          <Col
            span={24}
            className={zoomPercentage < 100 ? styles.previewColumn : ""}
          >
            {!hasHTMLTemplateCompositions(compositions, templates) && (
              <PanelTemplateZoom
                isFitToScreen={isFitToScreen}
                fitToScreenDimensions={fitToScreenDimensions}
                setIsFitToScreen={setIsFitToScreen}
                setIsZoomed={setIsZoomed}
                setZoomPercentage={setZoomPercentage}
                zoomPercentage={zoomPercentage}
              />
            )}

            <div className={styles.main} style={mainStyle}>
              <TemplateDrawer
                open={openFileManager}
                onClose={() => setOpenFileManager(false)}
              />

              {!!compositions?.length &&
                compositions.map(composition => {
                  return (
                    <CompositionPreview
                      key={`compositionPreview-${composition.compositionId}`}
                      composition={composition}
                      zoomPercentage={zoomPercentage}
                      playerWidth={playerWidth}
                    />
                  );
                })}
            </div>
          </Col>
        </Row>
      </ResizeObserver>
    </>
  );
};
