import { useMediaActions } from "../../../../../redux/media/media.slice";
import { useDownloadAsset } from "../../useDownloadAsset";
import { useShareLink } from "../../useShareLink";
import { useFetchPerms } from "../../useFetchPerms";
import { MenuContainer } from "./MenuContainer";
import { Tooltip } from "antd";
import {
  BlockOutlined,
  CloudDownloadOutlined,
  DeleteOutlined,
  EyeOutlined,
  FormOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { useDeleteAsset } from "../useDeleteAsset";

type Props = {
  record: WDAsset;
  visible: boolean;
};
export const AssetMenu = (props: Props) => {
  const { record, visible } = props;
  const { setAssetsToEdit, setEditableRow, setAssetToReplace } =
    useMediaActions();
  const downloadAsset = useDownloadAsset();
  const shareLink = useShareLink();
  const { canDelete, canEdit, canDownload } = useFetchPerms(
    [record.folder.id],
    visible,
  );
  return (
    <MenuContainer>
      <Tooltip title="View asset">
        <li
          onClick={() => {
            setAssetsToEdit([record]);
          }}
        >
          <EyeOutlined />
        </li>
      </Tooltip>
      <Tooltip title="Rename">
        <li
          data-clickeable={!canEdit}
          onClick={() => {
            setEditableRow(record);
          }}
        >
          <FormOutlined />
        </li>
      </Tooltip>
      <Tooltip title="Replace">
        <li
          data-clickeable={!canEdit}
          onClick={() => {
            setAssetToReplace(record);
          }}
        >
          <BlockOutlined />
        </li>
      </Tooltip>
      <Tooltip title="Download">
        <li
          data-clickeable={!canDownload}
          onClick={async () => {
            await downloadAsset(record);
          }}
        >
          <CloudDownloadOutlined />
        </li>
      </Tooltip>
      <Tooltip title="Share">
        <li
          onClick={async () => {
            await shareLink(record);
          }}
        >
          <ShareAltOutlined />
        </li>
      </Tooltip>
      <Tooltip title="Delete asset">
        <li data-clickeable={!canDelete} onClick={useDeleteAsset(record)}>
          <DeleteOutlined />
        </li>
      </Tooltip>
    </MenuContainer>
  );
};
