import { Stack, Typography, Slider } from "@mui/material";
import * as unit from "polotno/utils/unit";
import {
  PAGE_SIZE_MODIFIER_STEP,
  MIN_PAGE_SIZE_MODIFIER,
  MAX_PAGE_SIZE_MODIFIER,
} from "./constants";
import { DownloadFileType } from "screens/designStudio/designStudioV2/editorDrawer/editorDrawerContext/helpers.store";

type PageSizeSliderProps = {
  type: DownloadFileType;
  pageSizeModifier: number;
  width: number;
  height: number;
  dpi: number;
  setPageSizeModifier: (value: number) => void;
};

export const PageSizeSlider = ({
  pageSizeModifier,
  setPageSizeModifier,
  width,
  height,
  dpi,
  type,
}: PageSizeSliderProps) => {
  if (type !== "PDF") return null;
  return (
    <Stack>
      <Typography variant="caption">Page Size</Typography>
      <Slider
        value={pageSizeModifier}
        onChange={(_, value) => {
          setPageSizeModifier(value as number);
        }}
        step={PAGE_SIZE_MODIFIER_STEP}
        min={MIN_PAGE_SIZE_MODIFIER}
        max={MAX_PAGE_SIZE_MODIFIER}
      />
      <Typography variant={"subtitle2"} sx={{ color: "text.secondary" }}>
        {unit.pxToUnitRounded({
          px: width * pageSizeModifier,
          dpi,
          precious: 0,
          unit: "mm",
        })}{" "}
        x{" "}
        {unit.pxToUnitRounded({
          px: height * pageSizeModifier,
          dpi,
          precious: 0,
          unit: "mm",
        })}{" "}
        mm
      </Typography>
    </Stack>
  );
};
