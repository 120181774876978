import { MailOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Button, Popover, Space, Typography } from "antd";
import { queryClient } from "queryClient";
import { useCallback } from "react";
import { useUser } from "shared/hooks/useUser";
import { getEnvVar } from "utils/helpers";
import styles from "./UserProfilePopover.module.scss";

const UserProfilePopover = () => {
  const { logout } = useAuth0();
  const user = useUser();
  const {
    name = "Alexia",
    email = "",
    app_metadata,
    user_metadata,
  } = user || {};
  const displayName =
    user_metadata?.firstName && user_metadata?.lastName
      ? `${user_metadata.firstName} ${user_metadata.lastName}`
      : name;
  const appVersion = `v. ${getEnvVar("VERSION") || "0.0.0"}`;

  const userLogout = useCallback(() => {
    const asyncUserLogout = async () => {
      queryClient.clear();
      // Remove WD data.
      localStorage.removeItem("media");
      logout({ returnTo: window.location.origin });
    };
    asyncUserLogout();
  }, [logout]);

  return (
    <>
      <Popover
        trigger="hover"
        placement="topRight"
        title={
          <span className={styles.popoverTitle}>
            <Typography.Title level={4}>{`${displayName}`}</Typography.Title>
          </span>
        }
        content={
          <Space
            direction="vertical"
            size={4}
            className={styles.popoverContainer}
          >
            <div>
              <div className={styles.menuList}>
                {email && (
                  <div className={styles.menuItem}>
                    <MailOutlined />
                    {email}
                  </div>
                )}
                {user_metadata?.phoneNumber && (
                  <div className={styles.menuItem}>
                    <PhoneOutlined />
                    {user_metadata.phoneNumber}
                  </div>
                )}
                {app_metadata?.writingNumbers && (
                  <div className={styles.menuItem}>
                    <UserOutlined />
                    {app_metadata.writingNumbers}
                  </div>
                )}
              </div>
              <Button className={styles.popoverItem} onClick={userLogout}>
                Log Out
              </Button>
              <br />
              <span className={styles.appVersion}>{appVersion}</span>
            </div>
          </Space>
        }
      >
        <Avatar
          gap={5}
          size={24}
          data-cy="user-avatar"
          style={{ backgroundColor: "#061178" }}
        >
          {name.charAt(0).toUpperCase()}
        </Avatar>
      </Popover>
    </>
  );
};

export default UserProfilePopover;
