import { Button, Drawer, Space } from "antd";
import { useLoadCampaignsContext } from "../LoadProvider";
import FileUpload from "../../lineItems/FileUpload";
import {
  convertCreativeToUploadFile,
  convertUploadFileToCreative,
} from "../../helpers";
import { useDataSourceActions } from "../hooks/useDataSourceActions";
import { UploadFile } from "antd/lib/upload/interface";
import { useState } from "react";

const CreativesDrawer = () => {
  const { selectedLineItem, setSelectedLineItem } = useLoadCampaignsContext();
  const { changeDataSource } = useDataSourceActions();
  const [files, setFiles] = useState<UploadFile[]>(
    selectedLineItem?.creatives?.map(convertCreativeToUploadFile) ?? [],
  );

  const close = () => {
    setSelectedLineItem(undefined);
  };

  const onSave = async () => {
    if (!selectedLineItem) throw new Error("No selected line item");

    const creatives = await Promise.all(
      files
        .filter(file => file.status === "done")
        .map(convertUploadFileToCreative),
    );

    changeDataSource(
      selectedLineItem.id,
      selectedLineItem.type,
      "creatives",
      creatives,
    );

    close();
  };

  return (
    <Drawer
      title={"Creatives"}
      destroyOnClose
      visible
      width={"30%"}
      onClose={close}
      footerStyle={{ textAlign: "right" }}
      footer={
        <Space>
          <Button onClick={close}>Cancel</Button>
          <Button onClick={onSave} type="primary">
            Save
          </Button>
        </Space>
      }
    >
      <FileUpload value={files} onChange={setFiles} />
    </Drawer>
  );
};

export default CreativesDrawer;
