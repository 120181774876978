import {
  GetPmaxUploadStatusResponse,
  PmaxUploadStatus,
} from "shared/types/adLibrary";

export type PmaxUploadProgressNotification =
  Partial<GetPmaxUploadStatusResponse>;

export const isCompleteNotificationData = (
  args: PmaxUploadProgressNotification,
) =>
  Boolean(
    args.status &&
      args.description &&
      args.responseJson?.percentage !== undefined,
  );

export const getStatus = (status?: PmaxUploadStatus) => {
  if (status === "success") return "success";
  if (status === "failed") return "exception";
  return "active";
};

type UploadStage = "idle" | "start-upload" | "error";

/**
 * This function returns a mock upload status, used while we fetch the real status from the backend or when an abrupt error happens.
 */
export const getPmaxUploadTempStatus = (
  stage: UploadStage,
): Partial<GetPmaxUploadStatusResponse> => {
  if (stage === "idle") return {};

  if (stage === "start-upload") {
    return {
      status: "processing",
      description: "Preparing for upload",
      responseJson: {
        percentage: 10,
      },
    };
  }

  if (stage === "error") {
    return {
      status: "failed",
      description: "Something went wrong while uploading to Performance Max",
      responseJson: {
        percentage: 100,
      },
    };
  }

  return {};
};
