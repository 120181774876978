import { createContext, useMemo } from "react";
import { useManageStores } from "./editorDrawerContext/useManageStores";
import { useRecentImages } from "./editorDrawerContext/useRecentImages";

type EditorDrawerContext = ReturnType<typeof useManageStores> &
  ReturnType<typeof useRecentImages>;

export const editorDrawerContext = createContext<EditorDrawerContext | null>(
  null,
);

type EditorDrawerContextProviderProps = {
  children: React.ReactNode;
};

export const EditorDrawerContextProvider = ({
  children,
}: EditorDrawerContextProviderProps) => {
  const { Provider } = editorDrawerContext;
  const storeManagement = useManageStores();
  const recentImagesManagement = useRecentImages();

  const memoizedValue = useMemo(
    () => ({
      ...storeManagement,
      ...recentImagesManagement,
    }),
    [storeManagement, recentImagesManagement],
  );
  return <Provider value={memoizedValue}>{children}</Provider>;
};
