import { Breadcrumb } from "antd";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { TreeNavSwitch } from "./TreeNav";
import { useActiveFolders } from "./useActiveFolders";
import { useQPBreadcrumb } from "./useQPBreadcrumb";
import { useSelected } from "./useSelected";

import styles from "./MediaBreadCrumb.module.scss";

export const MediaBreadCrumb = () => {
  const { toFolder } = useQPBreadcrumb();
  const { stack, current } = useActiveFolders();
  const { clearSelected } = useSelected();

  useEffect(() => {
    clearSelected();
  }, [current, clearSelected]);

  return (
    <div className={styles.host}>
      <TreeNavSwitch />
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={{ search: toFolder("0") }}>Creative Asset Manager</Link>
        </Breadcrumb.Item>
        {stack.map(folder => {
          return (
            <Breadcrumb.Item key={folder.id}>
              <Link to={{ search: toFolder(folder.id) }}>{folder.name}</Link>
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    </div>
  );
};
