import { Typography } from "antd";
import Title from "antd/lib/typography/Title";
import classnames from "classnames";
import { isEmpty, last } from "lodash";
import { ReactNode, useMemo } from "react";
import { IFacebookAd } from "screens/adLibrary/facebookUtils/types";
import { AdReviewTableEntry } from "shared/types/campaignManagement";
import { formatDateValue } from "utils/helpers";
import {
  getCanvasId,
  getDestinationUrlsFromIE,
  hasEA,
} from "../../utils/helpers.ad";
import { getParentAd, getRelatedAds } from "../../utils/helpers.table";
import drawerStyles from "../AdDetailsDrawer.module.scss";
import DeliveryStatusBadge from "../DeliveryStatusBadge";
import styles from "./AdDetails.module.scss";
import AdInformations from "./AdDetails/AdInformations";
import DestinationUrl from "./AdDetails/DestinationUrl";
import { useFetchInstantExperiences } from "./AdPreviewSelector/useFetchInstantExperiences";
import { useRouteQuery } from "shared/hooks/useRouteQuery";

interface IProps {
  selectedEntry: AdReviewTableEntry;
  facebookAdsFromEntry: IFacebookAd[];
}

type AdDetailsProps = IProps;

const AdDetails = ({ selectedEntry, facebookAdsFromEntry }: AdDetailsProps) => {
  const parentAd = getParentAd(selectedEntry, facebookAdsFromEntry);
  const relatedAds = getRelatedAds(selectedEntry, facebookAdsFromEntry);
  const sharedAdReviewId = useRouteQuery("sharedAdReviewId") ?? undefined;
  const { adFormat } = selectedEntry;

  const { data: instantExperiences } = useFetchInstantExperiences(
    parentAd?.id,
    sharedAdReviewId,
    hasEA(adFormat),
  );

  const destinationUrl = useMemo(() => {
    if (hasEA(adFormat)) {
      return last(
        getDestinationUrlsFromIE({
          instantExperienceId: getCanvasId(parentAd || {}) || "",
          instantExperiences: instantExperiences || [],
        }),
      );
    }

    return selectedEntry.destinationUrl;
  }, [adFormat, instantExperiences, parentAd, selectedEntry.destinationUrl]);

  const RelatedAds = () => (
    <>
      {relatedAds.map(ad => (
        <span key={ad.id}>
          <AdInformations facebookAd={ad} />
        </span>
      ))}
    </>
  );

  const AdDetail = ({ label, value }: { label: string; value: ReactNode }) => (
    <div className={drawerStyles.section}>
      <Typography.Text className={drawerStyles.value}>
        <b className={drawerStyles.label}>{`${label}:`}</b>
        {value}
      </Typography.Text>
    </div>
  );

  return (
    <div
      data-cy="ad-details"
      className={classnames(drawerStyles.module, styles.adDetails)}
    >
      <Title style={{ margin: 0 }} level={4} className={drawerStyles.title}>
        <b>Ad Details</b>
      </Title>
      <div className={drawerStyles.moduleContent}>
        <AdDetail label="Ad Name" value={selectedEntry?.name} />
        <AdDetail
          label="Primary Text"
          value={
            parentAd?.creative?.asset_feed_spec?.bodies[0]?.text ||
            selectedEntry?.primaryText
          }
        />
        {selectedEntry.effectiveStatus && (
          <AdDetail
            label="Delivery"
            value={
              <div className={styles.deliveryStatus}>
                <DeliveryStatusBadge status={selectedEntry.effectiveStatus} />
              </div>
            }
          />
        )}
        <AdDetail
          label="Start Date"
          value={formatDateValue(selectedEntry?.startDate)}
        />
        <AdDetail
          label="End Date"
          value={formatDateValue(selectedEntry?.endDate)}
        />
        <AdDetail label="Ad Format" value={selectedEntry.adFormat ?? ""} />
        {parentAd?.productSet?.catalog?.name && (
          <AdDetail
            label="Product Catalog"
            value={parentAd.productSet.catalog.name}
          />
        )}
        {parentAd?.productSet?.name && (
          <AdDetail label="Product Set" value={parentAd.productSet.name} />
        )}
        <AdDetail
          label="Destination URL"
          value={
            <DestinationUrl
              adFormat={adFormat}
              destinationUrl={destinationUrl}
            />
          }
        />
        {selectedEntry.urlTags && (
          <AdDetail label="UTM" value={selectedEntry.urlTags} />
        )}
        <AdDetail
          label="This ad"
          value={<AdInformations facebookAd={parentAd as IFacebookAd} />}
        />
        {relatedAds && !isEmpty(relatedAds) && (
          <AdDetail label="Related ads" value={<RelatedAds />} />
        )}
      </div>
    </div>
  );
};

export default AdDetails;
