import { memo, useCallback, useRef, useState } from "react";
import { fabric } from "fabric";
import { isEqual } from "lodash";
import {
  AlignLayersActionType,
  CanvasProcessing,
  ICustomShapeData,
  IDimension,
  IExtendedFabricObject,
  IPublishCanvasStatus,
  TExtendedTextbox,
  TVariableAlias,
} from "shared/types/designStudio";

import useResize from "./canvas.hooks/useResize";
import { toJSON } from "./canvas.utils/Parsers";

import Highlight, { THightlight } from "./canvas/Highlight";

import useImageInsertData, {
  TImageInsertData,
} from "screens/designStudio/editor/canvasContainer/canvas.hooks/useImageInsertData";
import useCanvasRender from "./canvas.hooks/useCanvasRender";
import useVariableSelection from "./canvas.hooks/useVariableSelection";
import VariableSelection from "./canvas/VariableSelection";
import { IConfigurationState } from "shared/types/configuration";

import useCopyPasteObject, {
  TCopyAction,
} from "./canvas.hooks/useCopyPasteObject";

import useDeleteObject from "./canvas.hooks/useDeleteObject";
import {
  TLayerAction,
  TLayerActionType,
} from "screens/designStudio/editor.hooks/useLayers";
import useGrid from "./canvas.hooks/useGrid";
import useCanvas, { CANVAS_MARGIN } from "./canvas.hooks/useCanvas";
import useLayerAction from "./canvas.hooks/useLayerAction";
import { TObjectUpdateAction } from "../propertySectionV2/propertyRow/ManageText";
import useVariableAliasTrigger from "./canvas.hooks/useVariableAliasTrigger";
import VariableAlias from "./canvas/VariableAlias";
import {
  EventsAddableToHistory,
  FabricEvent,
  onDrop,
} from "./canvas.utils/EventHandlers";
import { getHighlight } from "./canvas.utils/Utils";
import useObjectUpdateAction from "./canvas.hooks/useObjectUpdateAction";
import useAlignObjects from "./canvas.hooks/useAlignObjects";
import useVideoControl from "./canvas.hooks/useVideoControl";
import VideoControl from "../canvas/VideoControl";
import useCallbacks, { AddToHistory } from "./canvas.hooks/useCallbacks";
import { CardLabel } from "shared/components/templatePreview/CardLabel";
import CardLabelComponent from "shared/components/templatePreview/CardLabel";
import useSaveDraft from "./canvas.hooks/useSaveDraft";
import useArrowKeys from "./canvas.hooks/useArrowKeys";
import useUndoRedoShortcuts from "./canvas.hooks/useUndoRedoShortcuts";
import {
  getDenseStylesArrayForTextLine,
  getDiffLength,
} from "utils/fabric/helpers.text";

fabric.Object.NUM_FRACTION_DIGITS = 8;
fabric.Object.prototype.borderColor = "#9eb5e8";
fabric.Object.prototype.cornerColor = "#9eb5e8";

export type TCanvasAction = {
  target?: fabric.Object;
  action: {
    type: TCanvasActionType;
    keyPressed?: "shift";
  };
};

export type TCanvasActionType =
  | "mouseOver"
  | "mouseOut"
  | "objectSelected"
  | "objectDeSelected"
  | "objectUpdated"
  | FabricEvent.TEXT_SELECTION_CHANGED;

export interface CanvasProps {
  json?: any;
  dimension: IDimension;
  imageInsertData?: TImageInsertData;
  feed: IConfigurationState["feed"];
  config: IConfigurationState["config"];
  copyAction?: TCopyAction;
  layerAction?: TLayerAction;
  align: AlignLayersActionType;
  showGrid: boolean;
  objectUpdateAction?: TObjectUpdateAction;
  savingDraft: CanvasProcessing;
  publishCanvasStatus: IPublishCanvasStatus | null;
  redo: () => void;
  undo: () => void;
}

export interface CanvasHandlers {
  onCanvasJsonUpdate: (args: {
    json: any;
    base64Thumbnail?: string;
    for: "save" | "history" | "publish";
    replace?: boolean;
  }) => void;
  resetImageInsertData: () => void;
  fetchVariables: () => void;
  updateImageInsertData: (imageInsertData: TImageInsertData) => void;
  setCanvasAction: (canvasAction: TCanvasAction) => void;
  resetAlign: () => void;
  saveDraft: (json: any, isPublishing: boolean) => void;
}

interface TextStyle {
  fontFamily?: string;
  fontStyle?: string;
  fontWeight?: string;
  fontSize?: string;
  color?: string;
}

const replaceStylesWithVariables = (
  originalTextLines: string[],
  groupedMappings: Record<
    number,
    {
      startIdx: number;
      diffLength: number;
    }[]
  >,
  styles: any,
) => {
  const updatedStyles = { ...styles };

  originalTextLines.forEach((originalTextLine, lineIdx) => {
    const mappings = groupedMappings[lineIdx];
    if (!mappings) return;

    const denseStyleArray = getDenseStylesArrayForTextLine(
      originalTextLine,
      styles?.[lineIdx] ?? {},
    );

    mappings.forEach(({ startIdx, diffLength }) => {
      if (diffLength > 0) {
        // We use the style of the first character of the variable to fill the rest of the characters
        // This means a variable with a different style for each character is not supported
        const firstCharStyles = denseStyleArray[Number(startIdx)];

        // We insert the styles of the first character of the variable in the array, for each new character
        // For example, if the variable is "{var}" and the replacement value is "cities",
        // we insert the style of the first character of the variable 1 time, because cities has one extra character over {var}
        denseStyleArray.splice(
          Number(startIdx),
          0,
          ...Array(diffLength).fill(firstCharStyles),
        );
      }

      if (diffLength < 0) {
        // We remove the styles of the characters that are being replaced
        denseStyleArray.splice(Number(startIdx), -diffLength);
      }
    });

    updatedStyles[lineIdx] = denseStyleArray.reduce(
      (acc, style, idx) => ({ ...acc, [idx]: style }),
      {},
    );
  });

  return updatedStyles;
};

const applyMaskingStyles = (
  textbox: TExtendedTextbox,
  variableAlias: TVariableAlias,
  originalStyles: Record<string, Record<number, TextStyle>>,
  previousAlias?: TVariableAlias,
): Record<string, Record<number, TextStyle>> => {
  const originalTextLines = textbox.text.split("\n");
  const groupedMappings: Record<
    number,
    {
      startIdx: number;
      diffLength: number;
    }[]
  > = {};

  // Reference of the last variable's length difference, used to calculate the startIndex of the next variable
  let lastVarDiff = 0;

  Object.entries(variableAlias).forEach(
    ([variable, { customVariable, isMaskOn, startIndex }]) => {
      const lineIdx = 0; // Assuming single line for simplicity
      if (!groupedMappings[lineIdx]) groupedMappings[lineIdx] = [];
      const value = {
        startIdx: isMaskOn ? startIndex + lastVarDiff : startIndex,
        diffLength: getDiffLength(variable, variableAlias, previousAlias),
      };

      groupedMappings[lineIdx].push(value);
      lastVarDiff = isMaskOn
        ? customVariable.length - variable.length
        : variable.length - customVariable.length;
    },
  );

  const newStyles = replaceStylesWithVariables(
    originalTextLines,
    groupedMappings,
    originalStyles,
  );

  return newStyles;
};

interface VariableAlias {
  customVariable: string;
  isMaskOn: boolean;
}

const Canvas = (props: CanvasProps & CanvasHandlers) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [selectionHighlight, setSelectionHighlight] = useState<THightlight>();
  const clearAllBoundingRect = useCallback(() => {
    setSelectionHighlight(undefined);
  }, []);
  const highlightObject = useCallback((object: fabric.Object | undefined) => {
    if (!object) return;

    setSelectionHighlight(getHighlight(object, CANVAS_MARGIN));
  }, []);
  const addToHistory: AddToHistory = useCallback(
    async (
      canvas: fabric.Canvas,
      originalDimension: IDimension,
      wrapperDiv: HTMLDivElement | null,
      CANVAS_MARGIN: number,
      onCanvasJsonUpdate: CanvasHandlers["onCanvasJsonUpdate"],
      replace?: boolean,
    ) => {
      const json = await toJSON({
        canvas,
        originalDimension,
        wrapperDiv,
        CANVAS_MARGIN,
      });

      onCanvasJsonUpdate({
        json,
        for: "history",
        replace,
      });
    },
    [],
  );

  const [hiddenObjectHighlights, setHiddenObjectsHighlights] =
    useState<THightlight[]>();
  const [labels, setLabels] = useState<CardLabel[]>();

  const [variableSelectionTarget, setVariableSelectionTarget] =
    useState<IExtendedFabricObject>();
  const [currMenuFilter, setCurrMenuFilter] = useState<string>("");
  const [isMouseDown, setIsMouseDown] = useState<boolean>(false);

  // NOTE: At the time of writing this, below ref is used to wait for text changed event gets added into history.
  const addingHistoryTimerRef = useRef<NodeJS.Timeout>();
  const [activeObject, setActiveObject] = useState<fabric.Object>();
  const canvas = useCanvas({
    props,
    wrapperDiv: wrapperRef.current,
    setHiddenObjectsHighlights,
    highlightObject,
    clearAllBoundingRect,
    onComplete: args => {
      if (args.hiddenObjectHighlights) {
        setHiddenObjectsHighlights(args.hiddenObjectHighlights);
      }

      if (args.labels) {
        setLabels(args.labels);
      }

      if (args.textChangedObject) {
        const textbox = args.textChangedObject as unknown as fabric.Textbox;
        const { lineIndex, charIndex } = textbox.get2DCursorLocation();
        const currentText = textbox.textLines[lineIndex];
        const textLeftToCursor = currentText.substring(0, charIndex);
        const textRightToCursor = currentText.substring(charIndex);

        const leftOpeningBrace = textLeftToCursor.lastIndexOf("{");
        const leftClosingBrace = textLeftToCursor.lastIndexOf("}");

        const rightOpeningBrace = textRightToCursor.indexOf("{");
        const rightClosingBrace = textRightToCursor.indexOf("}");

        const leftConditionSatisfied = leftOpeningBrace > leftClosingBrace;
        const rightConditionSatisfied =
          [rightOpeningBrace, rightClosingBrace].every(index => index === -1) ||
          (rightOpeningBrace > -1 && rightOpeningBrace < rightClosingBrace);

        if (leftConditionSatisfied && rightConditionSatisfied) {
          setCurrMenuFilter(
            currentText?.substr(
              leftOpeningBrace + 1,
              charIndex - leftOpeningBrace - 1,
            ),
          );
          setVariableSelectionTarget(args.textChangedObject);
        } else {
          setVariableSelectionTarget(undefined);
        }
      }

      switch (args.event) {
        case FabricEvent.MOUSE_DOWN:
          setIsMouseDown(true);
          break;
        case FabricEvent.MOUSE_UP:
          setIsMouseDown(false);
          break;

        case FabricEvent.SELECTION_CREATED:
        case FabricEvent.SELECTION_UPDATED:
          setActiveObject(canvas?.getActiveObject());
          break;
        case FabricEvent.SELECTION_CLEARED:
          setActiveObject(undefined);
          break;
        case FabricEvent.OBJECT_SCALED:
          const activeObject =
            (canvas?.getActiveObject() as IExtendedFabricObject) || {};
          const changingRadius =
            activeObject.customType === "shape" &&
            !!(activeObject as fabric.Rect).rx;

          if (!changingRadius) break;

          const scaleX = (activeObject as fabric.Rect).scaleX ?? 1;
          const scaleY = (activeObject as fabric.Rect).scaleY ?? 1;
          const calculatedRx =
            (activeObject.customData as ICustomShapeData).radius * scaleY;
          const calculatedRy =
            (activeObject.customData as ICustomShapeData).radius * scaleX;
          (activeObject as fabric.Rect).set({
            rx: calculatedRx,
            ry: calculatedRy,
          });
          break;
      }

      if (!canvas) return;

      if (EventsAddableToHistory.includes(args.event)) {
        // For text changes, it is probably better to wait little before we add it into the history.
        // If we dont wait, the undo will be done letter by letter which can be little confusing.
        if (args.event === FabricEvent.TEXT_CHANGED) {
          if (addingHistoryTimerRef.current)
            clearTimeout(addingHistoryTimerRef.current);

          addingHistoryTimerRef.current = setTimeout(() => {
            addToHistory(
              canvas,
              props.dimension,
              wrapperRef.current,
              CANVAS_MARGIN,
              props.onCanvasJsonUpdate,
            );
          }, 1000);
        } else {
          addToHistory(
            canvas,
            props.dimension,
            wrapperRef.current,
            CANVAS_MARGIN,
            props.onCanvasJsonUpdate,
          );
        }
      }
    },
    setLabels: setLabels,
  });

  const [canvasDimension, margin, widthDiff] = useResize({
    dimension: props.dimension,
    div: wrapperRef.current,
    CANVAS_MARGIN,
  });

  const { canvasArea } = useCanvasRender({
    canvas,
    canvasJson: props.json,
    margin,
    widthDiff,
    originalDimension: props.dimension,
    canvasDimension,
  });

  useImageInsertData({
    canvas,
    canvasArea,
    canvasDimension: props.dimension,
    canvasAreaMargin: margin,
    imageInsertData: props.imageInsertData,
    onComplete: async () => {
      if (!canvas) return;

      // we need to reset image insert data
      props.resetImageInsertData();

      // Whenever an object has been added into the canvas, we need to add it to the history
      addToHistory(
        canvas,
        props.dimension,
        wrapperRef.current,
        CANVAS_MARGIN,
        props.onCanvasJsonUpdate,
      );
    },
  });

  const variableSelection = useVariableSelection({
    target: variableSelectionTarget,
    wrapperDiv: wrapperRef.current,
    canvasDimension,
  });

  useCopyPasteObject({
    canvas,
    copyAction: props.copyAction,
    onComplete: async () => {
      if (!canvas) return;

      addToHistory(
        canvas,
        props.dimension,
        wrapperRef.current,
        CANVAS_MARGIN,
        props.onCanvasJsonUpdate,
      );
    },
  });

  useDeleteObject({
    canvas,
    onComplete: () => {
      if (!canvas) return;

      addToHistory(
        canvas,
        props.dimension,
        wrapperRef.current,
        CANVAS_MARGIN,
        props.onCanvasJsonUpdate,
      );
    },
  });

  useLayerAction({
    canvas,
    layerAction: props.layerAction,
    clearAllBoundingRect,
    highlightObject,
    onComplete: layerAction => {
      if (!canvas) return;

      const { action } = layerAction;
      const { type } = action;

      // Whenever, an action is triggered, we need to add canvas to history for few action types.
      // Reason is those few actions to not triggered by fabric event.
      //  - Visibility change
      //  - Lock
      // NOTE: We do not need to add canvas to history for 'hover' action
      const shouldAddToHistory = !(
        ["mouseEnter", "mouseLeave", "selected"] as TLayerActionType[]
      ).includes(type);
      if (shouldAddToHistory) {
        addToHistory(
          canvas,
          props.dimension,
          wrapperRef.current,
          CANVAS_MARGIN,
          props.onCanvasJsonUpdate,
        );
      }
    },
  });

  useAlignObjects({
    canvas,
    align: props.align,
    canvasAreaMargin: margin,
    CANVAS_MARGIN,
    canvasDimension: props.dimension,
    onComplete: () => {
      props.resetAlign();
      if (!canvas) return;

      addToHistory(
        canvas,
        props.dimension,
        wrapperRef.current,
        CANVAS_MARGIN,
        props.onCanvasJsonUpdate,
      );
    },
  });

  useGrid({
    showGrid: props.showGrid,
    canvasDimension: props.dimension,
    canvasAreaMargin: margin,
    onComplete: gridGroup => {
      if (!gridGroup) {
        const existingGridGroup = canvas
          ?.getObjects()
          .find(
            obj =>
              (obj as unknown as IExtendedFabricObject).customType === "grid",
          );

        if (existingGridGroup) canvas?.remove(existingGridGroup);
      } else {
        let index = 1; // index starts at one because the canvasArea must be all the way on the bottom.
        const doesBackgroundExist = !!canvas
          ?.getObjects()
          .find(
            obj =>
              (obj as unknown as IExtendedFabricObject).customType ===
              "canvas_bg",
          );

        if (doesBackgroundExist) index += 1;

        canvas?.insertAt(gridGroup, index, false);
      }
    },
  });

  useObjectUpdateAction({
    objectUpdateAction: props.objectUpdateAction,
    canvas,
    onComplete: selectedObject => {
      // This part simplify re-select already selected object to update the UI in the text property section
      props.setCanvasAction({
        target: selectedObject,
        action: {
          type: "objectSelected",
        },
      });

      if (!canvas) return;

      addToHistory(
        canvas,
        props.dimension,
        wrapperRef.current,
        CANVAS_MARGIN,
        props.onCanvasJsonUpdate,
      );
    },
  });

  useArrowKeys({ canvas });

  const [triggerPosition, aliasTextbox] = useVariableAliasTrigger({
    canvas,
    activeObject,
    isMouseDown,
  });

  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false);
  const [videoControlInfo] = useVideoControl({
    canvas,
    isMouseDown,
    isVideoPlaying,
    margin: {
      top: CANVAS_MARGIN,
      left: CANVAS_MARGIN,
    },
  });

  const enableVariableAliasTrigger = !!triggerPosition && !!aliasTextbox;

  const { onAfterDrop, onAfterVariableSelected } = useCallbacks({
    updateImageInsertData: props.updateImageInsertData,
    props,
    wrapperDiv: wrapperRef.current,
    CANVAS_MARGIN,
    addToHistory,
    canvas,
  });

  const { savingDraft, saveDraft, dimension, publishCanvasStatus } = props;

  // this will be triggered when user clicks on save draft or publish button
  useSaveDraft({
    canvas,
    wrapperDiv: wrapperRef.current,
    dimension,
    savingDraft,
    publishCanvasStatus,
    onComplete: (json, isPublishing) => saveDraft(json, isPublishing),
  });

  useUndoRedoShortcuts({
    redo: props.redo,
    undo: props.undo,
  });

  return (
    <div
      ref={wrapperRef}
      className="canvas-inner-wrapper"
      style={{
        minWidth: props.dimension.width,
        minHeight: props.dimension.height + 200,
        padding: CANVAS_MARGIN,
      }}
      onDrop={onDrop({
        canvasArea,
        canvasAreaMargin: margin,
        cb: onAfterDrop,
      })}
    >
      <canvas className="canvas" id="canvas" />
      {hiddenObjectHighlights?.map(highlightObj => (
        <Highlight
          key={`highlight-${highlightObj.id}`}
          highlight={highlightObj}
        />
      ))}
      {labels?.map(label => (
        <CardLabelComponent key={`card-label-${label.title}`} {...label} />
      ))}
      {selectionHighlight && (
        <Highlight
          key={`selection-highlight-${selectionHighlight.id}`}
          highlight={selectionHighlight}
          style={{
            border: "3px solid #40a9ff",
          }}
        />
      )}
      {videoControlInfo && (
        <VideoControl
          isPlaying={isVideoPlaying}
          videoControlInfo={videoControlInfo}
          onPlay={() => {
            setIsVideoPlaying(true);
          }}
          onPause={() => {
            setIsVideoPlaying(false);
          }}
        />
      )}
      {variableSelection && (
        <VariableSelection
          variableSelection={variableSelection}
          currMenuFilter={currMenuFilter}
          setCurrMenuFilter={setCurrMenuFilter}
          setVariableSelection={setVariableSelectionTarget}
          feed={props.feed}
          config={props.config}
          onVisibleChange={visible => {
            setVariableSelectionTarget(target =>
              visible ? target : undefined,
            );
          }}
          onTextUpdated={onAfterVariableSelected}
        />
      )}
      {enableVariableAliasTrigger && (
        <VariableAlias
          triggerPosition={triggerPosition!}
          textbox={aliasTextbox!}
          updateTextbox={(textbox, alias, textToUpdate, previousAlias) => {
            (textbox as any).variableAlias = alias;

            if (textToUpdate) {
              const styles = applyMaskingStyles(
                textbox,
                alias,
                textbox.styles,
                previousAlias,
              );
              textbox.set({ text: textToUpdate, styles: styles });

              canvas?.renderAll();
            }

            if (!canvas) return;

            addToHistory(
              canvas,
              props.dimension,
              wrapperRef.current,
              CANVAS_MARGIN,
              props.onCanvasJsonUpdate,
            );
          }}
        />
      )}
    </div>
  );
};

const areEqual = (prev: CanvasProps, next: CanvasProps) => {
  return isEqual(
    {
      json: prev.json,
      imageInsertData: prev.imageInsertData,
      feed: prev.feed,
      copyAction: prev.copyAction,
      layerAction: prev.layerAction,
      align: prev.align,
      showGrid: prev.showGrid,
      objectUpdateAction: prev.objectUpdateAction,
      savingDraft: prev.savingDraft,
      publishCanvasStatus: prev.publishCanvasStatus,
    },
    {
      json: next.json,
      imageInsertData: next.imageInsertData,
      feed: next.feed,
      copyAction: next.copyAction,
      layerAction: next.layerAction,
      align: next.align,
      showGrid: next.showGrid,
      objectUpdateAction: next.objectUpdateAction,
      savingDraft: next.savingDraft,
      publishCanvasStatus: next.publishCanvasStatus,
    },
  );
};
export default memo(Canvas, areEqual);
