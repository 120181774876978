import { Button, Drawer, Tabs } from "antd";
import styles from "./PreviewDrawer.module.scss";
import { Language } from "shared/types/salesEnablement";
import Preview from "../shared/Preview";
import { useSalesEnablementContext } from "../hooks/SalesEnablementDataProvider";
import TemplateRenderDataProvider from "../hooks/TemplateRenderDataProvider";
import { useMemo, useState } from "react";
import useNavigateWithSearch from "shared/hooks/useNavigateWithSearch";
import { ROUTES } from "./constants";
import { useAddRecentTemplate } from "shared/hooks/salesEnablement/useAddRecentTemplate";
import { useUser } from "shared/hooks/useUser";
import { useIsSalesEnablementAdmin } from "shared/hooks/useIsAdmin";

type Props = {
  onClose: () => void;
  open: boolean;
  componentsTabsFilter: string;
};

const componentsTabs: { label: string; key: Language }[] = [
  {
    label: "English",
    key: "en",
  },
  {
    label: "Spanish",
    key: "es",
  },
];

const PreviewDrawer = (props: Props) => {
  const user = useUser();
  const { onClose, open } = props;

  const { files, selectedTemplate } = useSalesEnablementContext();
  const navigate = useNavigateWithSearch();
  const { mutate: addRecentTemplate } = useAddRecentTemplate();
  const isAdmin = useIsSalesEnablementAdmin();

  const [activeLanguageTab, setActiveLanguageTab] = useState<Language>(
    () => selectedTemplate?.languages?.[0] ?? "en",
  );
  const filteredcomponentsTabs = componentsTabs.filter(
    tab => !props.componentsTabsFilter.includes(tab.key),
  );

  const { activeFile } = useMemo(
    () => ({
      activeFile: files?.[activeLanguageTab],
    }),
    [activeLanguageTab, files],
  );

  return (
    <Drawer
      title={
        <span>
          <b>Preview Template:</b>
          {` ${selectedTemplate?.name || ""}`}
        </span>
      }
      placement="right"
      onClose={onClose}
      visible={open}
      width={`min(calc(100% - 48px), 928px)`}
      bodyStyle={{ padding: 0 }}
      closeIcon={null}
      footer={
        <div className={styles.footer}>
          <Button onClick={onClose}>Close</Button>
          <Button
            type="primary"
            onClick={() => {
              if (selectedTemplate?.id) {
                if (!isAdmin) {
                  addRecentTemplate({
                    userId: user.sub,
                    templateId: selectedTemplate.id,
                  });
                }
                navigate(ROUTES.newMaterial(selectedTemplate.id));
              }
            }}
          >
            Create Marketing Material
          </Button>
        </div>
      }
    >
      <TemplateRenderDataProvider file={activeFile} material={undefined}>
        <div className={styles.container}>
          <Tabs
            className={styles.componentsTabs}
            tabPosition={"top"}
            activeKey={activeLanguageTab}
            hideAdd={true}
            animated={false}
            onChange={activeKey => setActiveLanguageTab(activeKey as Language)}
            style={{ height: "100%" }}
            tabBarGutter={0}
            tabBarStyle={{
              visibility:
                filteredcomponentsTabs.length > 1 ? "visible" : "hidden",
              marginBottom: "0",
              height: filteredcomponentsTabs.length > 1 ? "46px" : "0px",
              marginLeft: "24px",
            }}
          >
            {filteredcomponentsTabs.map(componentTab => (
              <Tabs.TabPane
                tab={componentTab.label}
                key={componentTab.key}
                closable={false}
              >
                <div className={styles.previewContainer}>
                  <Preview
                    key={componentTab.key}
                    lang={componentTab.key}
                    isActive={activeLanguageTab === componentTab.key}
                  />
                </div>
              </Tabs.TabPane>
            ))}
          </Tabs>
        </div>
      </TemplateRenderDataProvider>
    </Drawer>
  );
};

export default PreviewDrawer;
