import { Drawer } from "antd";
import { useStepData } from "./useStepData";
import AccountsSelect from "./AccountsSelect";

const LoadDrawer = () => {
  const { Table, Footer, DataListProvider } = useStepData();

  return (
    <DataListProvider>
      <Drawer
        title={"Load to Beeswax"}
        destroyOnClose
        visible
        closable={false}
        maskClosable={false}
        width={"98%"}
        footerStyle={{ textAlign: "right" }}
        footer={<Footer />}
      >
        <AccountsSelect />
        <Table />
      </Drawer>
    </DataListProvider>
  );
};

export default LoadDrawer;
