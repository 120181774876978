import { Drawer } from "antd";
import TemplateRenderDataProvider from "screens/designStudio/hooks/TemplateRenderDataProvider";
import Preview from "../marketingMaterialDrawer/Preview";
import styles from "./MarketingMaterialDrawer.module.scss";
import { Outlet, useParams, useLocation } from "react-router-dom";
import { StateKey } from "shared/constants/states";
import { difference, isEmpty, omit } from "lodash";
import { ROUTES } from "./utils/constants";
import MarketingMaterialForm from "../marketingMaterialDrawer/MarketingMaterialForm";
import DrawerFooter from "../marketingMaterialDrawer/DrawerFooter";
import { useSalesEnablementContext } from "screens/designStudio/hooks/SalesEnablementDataProvider";
import Spinner from "../adLoadV2/shared/Spinner";
import {
  MarketingMaterialsContext,
  MaterialFormProvider,
  useMarketingMaterialsContext,
  useMaterialFormInstance,
} from "./MarketingMaterialsProvider";
import { useMarketingMaterialActions } from "./hooks/useMarketingMaterialActions";
import {
  MarketingMaterial,
  SaveMarketingMaterial,
  MarketingMaterialTableItem,
} from "shared/types/marketingMaterials";
import useNavigateWithSearch from "shared/hooks/useNavigateWithSearch";
import { usePdfDeliveryData } from "./hooks/usePdfDeliveryData";
import { onConfirmClose } from "screens/designStudio/shared/components/publish";
import {
  errorNotification,
  successNotification,
} from "shared/components/customNotification/Notification";
import { useForm } from "antd/lib/form/Form";
import { DeliveryMethod } from "shared/types/salesEnablement";
import { longAlert } from "utils/antd/longAlert/longAlert";
import { useCallback, useState, useEffect } from "react";
import { isDeepEqual } from "utils/helpers";

type Props = {
  type: "create" | "edit" | "archive";
};

const MarketingMaterialDrawerContainer = ({ type }: Props) => {
  const { materialId, templateId } = useParams<{
    materialId: string;
    templateId: string;
  }>();

  const [form] = useForm<MarketingMaterialTableItem>();

  return (
    <MaterialFormProvider form={form}>
      <MarketingMaterialsContext
        materialId={materialId}
        templateId={templateId}
        key={materialId}
      >
        <MarketingMaterialDrawer type={type} />
      </MarketingMaterialsContext>
    </MaterialFormProvider>
  );
};

const MarketingMaterialDrawer = ({ type }: Props) => {
  const location = useLocation();
  const fromTemplate = location.pathname.includes("templates/");

  const {
    material,
    template,
    templateFile,
    isLoading,
    previewMaterial,
    isFormValid,
  } = useMarketingMaterialsContext();
  const form = useMaterialFormInstance();

  const { getPdfDeliveryData, isLoading: isPdfLoading } = usePdfDeliveryData();

  const navigateWithSearch = useNavigateWithSearch();
  const { onUpdate, onCreate, isSaving } = useMarketingMaterialActions();

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const onSave = ({
    values,
    template: templateData,
    material: materialData,
  }: SaveMarketingMaterial) => {
    const onSuccess = () => {
      successNotification({
        messageLabel: "The marketing material has been saved.",
      });
    };

    if (!materialData) {
      onCreate({
        values,
        templateId: templateData.id,
        onSuccess: ({ id }: MarketingMaterial) => {
          onSuccess();
          if (fromTemplate) {
            navigateWithSearch(`../${id}`, { relative: "path", replace: true });
          } else {
            navigateWithSearch(ROUTES.edit(id), { replace: true });
          }
        },
      });
      return;
    }
    onUpdate(values, materialData, onSuccess);
  };

  const onClose = useCallback(() => {
    const handleClose = () => {
      setIsVisible(false);
    };
    const materialForm = form.getFieldsValue(true);
    const draftMaterial: MarketingMaterialTableItem = {
      ...materialForm,
      fields: isEmpty(materialForm.fields) ? undefined : materialForm.fields,
    };
    const isFormChanged = !isDeepEqual(
      omit(draftMaterial, ["updatedAt", "materialErrorStatus"]),
      omit(material, ["updatedAt", "materialErrorStatus"]),
    );
    isFormChanged && type !== "archive" && !isSaving
      ? onConfirmClose({
          onOk: handleClose,
          content: "",
        })
      : handleClose();
  }, [form, material, type, isSaving]);

  const handleAfterVisibleChange = (visible: boolean) => {
    if (!visible) {
      navigateWithSearch("..");
    }
  };

  const { availableLocations } = useSalesEnablementContext();

  if (isLoading || !template?.files) {
    return null;
  }

  const disabledLocations: StateKey[] = difference<StateKey>(
    availableLocations ?? [],
    template?.locations,
  );

  const onSendEmail = async () => {
    const valid = await isFormValid();
    if (!valid || !material?.id) {
      longAlert({
        header: "Please fill all required items before Emailing.",
        type: "error",
      });
      return;
    }
    const route = fromTemplate
      ? "send-by-email"
      : ROUTES.emailDeliveryEditing(material.id);
    navigateWithSearch(route);
  };

  const onDownload = async () => {
    if (!material?.id) return;
    const materialForm = form.getFieldsValue(true);
    const isValid = await isFormValid();
    if (!isValid) {
      longAlert({
        header: "Please fill all required items before Downloading.",
        type: "error",
      });
      return;
    }
    getPdfDeliveryData([materialForm ?? material]);
  };

  const isDeliveryEnabled = (method: DeliveryMethod) =>
    material?.id &&
    material?.templateDeliveryMethods?.includes(method) &&
    template.status === "PUBLISHED";

  const onSaveMaterial = async () => {
    const values = form.getFieldsValue();
    try {
      await form.validateFields(["name", "language", "locations"]);
    } catch (error) {
      errorNotification({
        messageLabel: "Please fix all errors before saving.",
      });
      return;
    }

    onSave({ values, template, material });
  };

  return (
    <TemplateRenderDataProvider
      file={templateFile}
      material={previewMaterial ?? material}
    >
      <Drawer
        title={`${material ? "Edit" : "Create"} Marketing Material`}
        width="calc(100vw - 48px)"
        visible={isVisible}
        onClose={onClose}
        afterVisibleChange={handleAfterVisibleChange}
        closable={false}
        destroyOnClose
        footer={
          <DrawerFooter
            isSaving={isSaving}
            onSave={onSaveMaterial}
            onClose={onClose}
            onSendEmail={isDeliveryEnabled("email") ? onSendEmail : undefined}
            isDownloading={isPdfLoading}
            onDownload={isDeliveryEnabled("download") ? onDownload : undefined}
            type={type}
          />
        }
      >
        <div className={styles.drawer}>
          {!isLoading && (
            <MarketingMaterialForm
              agentLocations={availableLocations}
              disabledLocations={disabledLocations}
              readonly={type === "archive"}
            />
          )}
          {isLoading && <Spinner />}
          {!isLoading && <Preview selectedFile={templateFile} />}
        </div>
      </Drawer>
      <Outlet />
    </TemplateRenderDataProvider>
  );
};

export default MarketingMaterialDrawerContainer;
