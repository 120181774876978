import { LayerElement } from "screens/designStudio/designStudioV2/types";
import {
  toggleLayerVisibility,
  toggleLayerLock,
  deleteLayer,
} from "../helpers";

export const useLayerActions = (layer: LayerElement) => {
  return {
    toggleVisibility: () => toggleLayerVisibility(layer),
    toggleLock: () => toggleLayerLock(layer),
    deleteLayer: () => deleteLayer(layer),
  };
};
