export const terms = {
  account: "Account",
  accountName: "Account Name",
} as const;

/**
 * Pluralize a word based on a count, this is a very simple implementation
 */
export const pluralize = (
  singular: string,
  count: number,
  plural = `${singular}s`,
) => {
  return count === 1 ? singular : plural;
};
