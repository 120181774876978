import {
  Typography,
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  InputNumber,
} from "antd";
import styles from "./CampaignForm.module.scss";
import { BeeswaxCampaignForm, BeeswaxCampaignURLParams } from "./types";
import {
  calcDailyBudget,
  numberInputFormatter,
} from "screens/campaignManagement/googlePerformanceMax/pmaxCampaignsTable/newCampaignDrawer/newCampaignForm/helpers";
import { useBeeswaxCampaignsContext } from "./BeeswaxCampaignsProvider";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import { useBeeswaxCampaign } from "./hooks/useBeeswaxCampaign";
import { requiredRule, budgetRule, campaignFormId } from "./constants";
import { getEndDateRules } from "./helpers";
import { useMutateBeeswaxCampaign } from "./hooks/useMutateBeeswaxCampaign";

const { Text } = Typography;

const CampaignForm = () => {
  const { form } = useBeeswaxCampaignsContext();
  const { campaignId } = useParams<BeeswaxCampaignURLParams>();
  const { data: campaign } = useBeeswaxCampaign(campaignId);
  const { onSubmit } = useMutateBeeswaxCampaign();

  const onValuesChange = (
    changedValues: Partial<BeeswaxCampaignForm>,
    values: BeeswaxCampaignForm,
  ) => {
    const dailyBudget = changedValues.totalBudget
      ? parseFloat(
          calcDailyBudget(
            changedValues.totalBudget,
            values.startDate?.toDate(),
            values.endDate?.toDate(),
          ),
        )
      : values.dailyBudget;

    form.setFieldsValue({ ...values, dailyBudget });
  };

  const CampaignName = () => (
    <Form.Item
      label={<Text>Campaign Name</Text>}
      name="name"
      rules={[requiredRule]}
    >
      <Input />
    </Form.Item>
  );

  const StartDate = () => (
    <Form.Item
      label={<Text>Start Date</Text>}
      name="startDate"
      rules={[requiredRule]}
    >
      <DatePicker format="MM-DD-YYYY" className={styles.input} />
    </Form.Item>
  );

  const EndDate = () => (
    <Form.Item
      label={<Text>End Date</Text>}
      name="endDate"
      rules={getEndDateRules("startDate")}
      dependencies={["startDate"]}
    >
      <DatePicker format="MM-DD-YYYY" className={styles.input} />
    </Form.Item>
  );

  const Budget = () => (
    <Form.Item
      label={<Text>Budget</Text>}
      name="totalBudget"
      rules={[requiredRule, budgetRule]}
    >
      <InputNumber
        formatter={numberInputFormatter}
        className={styles.input}
        precision={2}
      />
    </Form.Item>
  );

  const DailyBudget = () => (
    <Form.Item
      label={<Text>Daily Budget</Text>}
      name="dailyBudget"
      rules={[budgetRule]}
    >
      <InputNumber
        formatter={numberInputFormatter}
        className={styles.input}
        precision={2}
      />
    </Form.Item>
  );

  const initialValues: BeeswaxCampaignForm = {
    name: campaign?.name,
    startDate: campaign?.startDate ? moment(campaign.startDate) : undefined,
    endDate: campaign?.endDate ? moment(campaign.endDate) : undefined,
    totalBudget: campaign?.totalBudget,
    dailyBudget: campaign?.dailyBudget,
  };

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={initialValues}
      onFinish={onSubmit}
      id={campaignFormId}
      onValuesChange={onValuesChange}
    >
      <CampaignName />
      <Row gutter={16} align="top">
        <Col span={12}>
          <StartDate />
        </Col>
        <Col span={12}>
          <EndDate />
        </Col>
      </Row>
      <Row gutter={16} align="top">
        <Col span={12}>
          <Budget />
        </Col>
        <Col span={12}>
          <DailyBudget />
        </Col>
      </Row>
    </Form>
  );
};

export default CampaignForm;
