import { authEndpoints } from "./configs";

const lithia = {
  name: "Lithia",
  cid: "lithia",
  xcid: "",
  region: "us-east-1",
  auth: {
    ...authEndpoints,
    clientId: "T6gRsvVu3z0FPP1VKzdpOjUAgGwK83cS",
    organization: "org_xJbAbcc8vP52EYXu",
  },
  identityPoolId: "us-east-1:eed0bbf9-2909-4e50-b5d3-ee4d6e08fee5",
  gqlId: "abzm5imj7zgzvhyeq2ubc7qsyu",
};

export const clientsArr = [lithia];
