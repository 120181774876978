export const months = [
  { index: "01", name: "Jan", fullName: "January", endDay: 31 },
  { index: "02", name: "Feb", fullName: "February", endDay: 28 },
  { index: "03", name: "Mar", fullName: "March", endDay: 31 },
  { index: "04", name: "Apr", fullName: "April", endDay: 30 },
  { index: "05", name: "May", fullName: "May", endDay: 31 },
  { index: "06", name: "Jun", fullName: "June", endDay: 30 },
  { index: "07", name: "Jul", fullName: "July", endDay: 31 },
  { index: "08", name: "Aug", fullName: "August", endDay: 31 },
  { index: "09", name: "Sep", fullName: "September", endDay: 30 },
  { index: "10", name: "Oct", fullName: "October", endDay: 31 },
  { index: "11", name: "Nov", fullName: "November", endDay: 30 },
  { index: "12", name: "Dec", fullName: "December", endDay: 31 },
];
