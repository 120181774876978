import { useMemo } from "react";
import { useDesignStudioContext } from "screens/designStudio/context/DesignStudioDataProvider";
import { isTemplate, isArtboard } from "screens/designStudio/helpers";
import { nonNullable } from "utils/helpers.array";
import { useDesignStudioEditor } from "./useDesignStudioEditor";

export const useDesignStudioEditorItems = () => {
  const { assetIds, selectedId } = useDesignStudioEditor();
  const { designStudioAssets } = useDesignStudioContext();

  const items = useMemo(() => {
    if (!assetIds?.length || !designStudioAssets.length) return [];

    return assetIds
      .map(assetId =>
        designStudioAssets.find(
          item => !isArtboard(item) && item.asset.id === assetId,
        ),
      )
      .filter(isTemplate)
      .filter(nonNullable);
  }, [assetIds, designStudioAssets]);

  const activeItem = useMemo(
    () => items.find(item => item.asset.id === selectedId) ?? null,
    [selectedId, items],
  );

  return {
    items,
    activeItem,
  };
};
