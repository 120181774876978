import API from "services";
import { AudienceIntegrationLog } from "../types";

export const fetchAudienceIntegrationLogsWrapper = (): Promise<
  AudienceIntegrationLog[]
> => fetchAudienceIntegrationLogs();

const fetchAudienceIntegrationLogs = async (
  next?: string,
): Promise<AudienceIntegrationLog[]> => {
  const { result, error } =
    await API.services.adLibrary.getAudienceIntegrationLogs(next);

  if (!result || error) {
    throw Error(
      error?.message ||
        "Something went wrong while fetching audience integration logs.",
    );
  }

  if (result.next) {
    const nextPage = await fetchAudienceIntegrationLogs(result.next);
    return [...result.items, ...nextPage];
  }

  return result.items;
};
