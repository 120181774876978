import { ReactNode } from "react";
import { Box, Menu, MenuItem, Stack, IconButton } from "@mui/material";
import { useAnchorPopover } from "screens/designStudio/designStudioV2/hooks/useAnchorPopover";
interface EffectsMenuProps {
  icon: ReactNode;
  children: ReactNode;
  id: string;
}

export const menuStyles = {
  borderRadius: 1,
  boxShadow: 1,
  ".MuiMenuItem-root": {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
};

export const PopoverMenu = ({
  children,
  id: menuId,
  icon,
}: EffectsMenuProps) => {
  const { id, open, anchorEl, onClick, onClose } = useAnchorPopover(menuId);

  return (
    <Box>
      <IconButton onClick={onClick}>{icon}</IconButton>
      <Menu
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={menuStyles}
      >
        <MenuItem disableRipple>
          <Stack
            direction="column"
            spacing={1}
            alignItems="center"
            mx={1}
            minWidth={190}
          >
            {children}
          </Stack>
        </MenuItem>
      </Menu>
    </Box>
  );
};
