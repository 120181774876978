import React, { useState, ReactNode } from "react";
import { Box, Menu, MenuItem, Stack } from "@mui/material";
import { ButtonContainer } from "./ButtonContainer";
import AutoFixHighRoundedIcon from "@mui/icons-material/AutoFixHighRounded";

interface EffectsMenuProps {
  children: ReactNode;
}

export const menuStyles = {
  borderRadius: 1,
  boxShadow: 1,
  ".MuiMenuItem-root": {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
};

export const EffectsMenu = ({ children }: EffectsMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <ButtonContainer
        startIcon={<AutoFixHighRoundedIcon fontSize="small" color="action" />}
        onClick={handleClick}
      >
        Effects
      </ButtonContainer>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={menuStyles}
      >
        <MenuItem disableRipple>
          <Stack
            direction="column"
            spacing={1}
            alignItems="center"
            mx={1}
            width={190}
          >
            {children}
          </Stack>
        </MenuItem>
      </Menu>
    </Box>
  );
};
