import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { StringParam, useQueryParams } from "use-query-params";
import { Loading } from "../Loading";
import { createAuthToken } from "../auth";
import { useMediaStorage } from "../useMediaStorage";
import { renameWebdamAuthCodeEventListener } from "./redirectWebdamAuthCodeEventListener";

window.addEventListener("DOMContentLoaded", renameWebdamAuthCodeEventListener);

export const CAMCallback = () => {
  const navigate = useNavigate();
  const [, setData] = useMediaStorage();
  const [{ wd_code: code, state }] = useQueryParams({
    wd_code: StringParam,
    state: StringParam,
  });

  useEffect(() => {
    if (code && state) {
      createAuthToken(code)
        .then(setData)
        .catch(() => {
          setData(null);
        })
        .finally(() => {
          const url = new URL(state, window.location.origin);
          const search = new URLSearchParams(url.search);
          const next = search.get("state") ?? "/";

          navigate(next, { replace: true });
        });
    }
  }, [state, code, navigate, setData]);

  return <Loading />;
};
