import { useState } from "react";
import { notification } from "antd";
import { useUser } from "shared/hooks/useUser";
import { updateAd } from "../adCompleteImportDrawer/adImportTable/adImportTableContainer/adImportTable.utils";
import {
  mapCsvDataToAd,
  processCsvRawData,
  updateAdWithLoadedMedia,
} from "../processingImportUtils";
import { IMPORT_CSV_HEADERS, ImportAdData } from "./utils";
import { IUploadMediaUrls } from "utils/uploadMedia";
import { IAd } from "shared/types/adLibrary";

export const useImportCSV = (handleClose: () => void) => {
  const [loadingAds, setLoadingAds] = useState(false);
  const [adsLoadingMedia, setAdsLoadingMedia] = useState<string[]>([]);
  const [csvRawData, setCsvRawData] = useState<string[][]>();
  const [ads, setAds] = useState<IAd[]>([]);

  const user = useUser();

  const handleImportError = (description: string) => {
    notification.error({
      bottom: 45,
      duration: 5,
      message: "",
      placement: "bottomRight",
      description,
    });

    handleClose();
  };

  const setAd = (adId: string, adUpdate: Partial<IAd>) => {
    setAds(newAds =>
      updateAd({ ...newAds.find(x => x.id == adId)!, ...adUpdate }, newAds),
    );
  };

  const importData = async (rawData: string[][]) => {
    setCsvRawData(rawData);
    const { data: adsData } = processCsvRawData<ImportAdData>(
      rawData,
      IMPORT_CSV_HEADERS,
    );

    if (!adsData.length) {
      handleImportError("Empty CSV file");
      return;
    }
    setLoadingAds(true);

    const adsResult = adsData.map(ad => mapCsvDataToAd(ad, user));
    setAds(adsResult.map(result => result.ad));

    adsResult.forEach(
      async ({ ad, feedUploadPromises, storyUploadPromises }) => {
        setAdsLoadingMedia(newAds => [...newAds, ad.id]);

        const feedMedia = handleMediaPromises(await feedUploadPromises);
        const storyMedia = handleMediaPromises(await storyUploadPromises);

        setAd(ad.id, updateAdWithLoadedMedia(feedMedia, storyMedia, ad));

        setAdsLoadingMedia(newAds => newAds.filter(id => id !== ad.id));
      },
    );

    setLoadingAds(false);
  };

  return {
    isLoadingAds: loadingAds,
    adsLoadingMedia,
    csvRawData,
    importData,
    ads,
    setAd,
    setAds,
  };
};

const handleMediaPromises = (
  media: PromiseSettledResult<IUploadMediaUrls>[],
) => {
  return media.map(result => {
    if (result.status == "fulfilled") return result.value;
    return {
      thumbnail: "error",
    };
  });
};
