import { LoadingOutlined, PictureOutlined } from "@ant-design/icons";
import styles from "./Preview.module.scss";
import PDFPreview from "./preview/PDFPreview";
import HTMLPreview from "./preview/HTMLPreview";
import { useSalesEnablementContext } from "../hooks/SalesEnablementDataProvider";
import { Language } from "shared/types/salesEnablement";
import {
  RenderingPreviewStatus,
  useTemplateRenderContext,
} from "../hooks/TemplateRenderDataProvider";

const Preview = ({
  lang,
  isActive = false,
  helpTexts,
}: {
  lang: Language;
  isActive?: boolean;
  helpTexts?: Partial<Record<RenderingPreviewStatus, string>>;
}) => {
  const { files } = useSalesEnablementContext();
  const { idmlPreview, renderingPreviewStatus } = useTemplateRenderContext();
  const { type } = files?.[lang] || {};

  return (
    <div
      className={styles.container}
      style={{
        zIndex: isActive ? 20 : 10,
        display: isActive ? "flex" : "none",
      }}
    >
      {renderingPreviewStatus === "idle" && (
        <div>{helpTexts?.idle ?? "Upload file to preview"}</div>
      )}
      {renderingPreviewStatus === "loading" && (
        <div className={styles.loading}>
          <PictureOutlined className={styles.icon} />
          <div className={styles.title}>Loading preview...</div>
        </div>
      )}

      {renderingPreviewStatus == "done" && (
        <div className={styles.preview}>
          {idmlPreview.isLoading && (
            <div className={styles.loading}>
              <LoadingOutlined className={styles.icon} />
              <div className={styles.title}>Loading preview...</div>
            </div>
          )}
          {(type === "indesign" || type === "pdf") && (
            <PDFPreview {...idmlPreview} />
          )}
          {type === "html" && <HTMLPreview />}
        </div>
      )}
    </div>
  );
};

export default Preview;
