import { isFileSizeValid } from "screens/adLibrary/utils";
import { MultiUploadSizing } from "shared/types/uploadManagement";
import uuid from "uuid";

export const getFileList = (file: {
  thumbnail?: string;
  videoUrl?: string;
  size?: MultiUploadSizing;
}) => {
  if (file?.thumbnail) {
    const url = file.videoUrl ? file.videoUrl : file.thumbnail;
    return [
      {
        uid: uuid(),
        name: file.thumbnail,
        status: "done",
        thumbUrl: file.thumbnail,
        url,
        preview: url,
      } as const,
    ];
  }
};

export const validateFileSize = (file: Blob, onInvalidSize: () => void) => {
  const shouldUploadFile = isFileSizeValid(file);

  if (!shouldUploadFile) onInvalidSize();
  return shouldUploadFile;
};
