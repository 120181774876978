import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { connect } from "react-redux";
import { Route, Routes, useLocation, useParams } from "react-router-dom";
import actions from "../../redux/rootActions";
import { orderBy } from "lodash";
import RenderTemplateProvider from "shared/components/RenderTemplateProvider";
import {
  AssetInstanceRecord,
  IAssetInstance,
  ICheckedOfferFilter,
  ILauncherData,
  InstanceCheckboxDict,
  IAssetBuilderState,
  VehicleConditions,
  ISortingOption,
  FeedTab,
} from "shared/types/assetBuilder";
import SelectV1 from "./offers/Select";

import EditOffer from "../EditOffer";
import TabContainer from "shared/components/TabContainer";
import {
  FeedType,
  IConfigurationState,
  IConfig,
} from "shared/types/configuration";
import { INewOrderRecord } from "shared/types/newOrders";

import OfferFilter from "./offers/OfferFilter";

import "./Offers.scss";
import SelectV2 from "./offers/SelectV2";
import Build from "./offers/Build";
import Review from "./offers/Review";
import AssetLauncher from "./offers/AssetLauncher";
import AssetLauncherPanel from "./offers/launch/panelSettings/AssetLauncherPanel";
import { isAuto, timezoneOffset } from "utils/helpers";

import { removePurchasePlaceholder } from "utils/helpers.asset";
import {
  IAccount,
  IAccountManagementState,
  IAccountRecord,
  IGetAccountsResult,
} from "shared/types/accountManagement";
import { Spin, Select, message } from "antd";
import API from "services";
import { SelectedOffers } from "shared/types/shared";
import { getErrorMessage } from "utils/errorMessage";

interface IOfferList {
  feed: FeedType;
  total: number;
  fetchingOfferList: boolean;
  isDataEmpty: boolean;
  currentPage: number;
  checkedOfferFilters: ICheckedOfferFilter;

  launcherImages: string[];

  fetchOrderState: (orderId: string) => void;
  errorMessage: string | null;
  savedOrder: IAssetBuilderState["savedOrder"];
  showCoopModal: boolean;
  toggleCoopModal: (open: boolean) => void;
  showExportDrawer: boolean;
  toggleExportDrawer: (open: boolean) => void;
  forceRenderImages: boolean;
  toggleForceRenderImages: (force: boolean) => void;
  createNewOrderStateUpdate: () => void;
  currentSelectedOrder: INewOrderRecord;
  offerEditsWereSaved: boolean;
  searchQuery: string;
  config?: IConfig;

  assetInstances: AssetInstanceRecord;
  selectedExportedAssets: InstanceCheckboxDict;
  getDealer: (dealerName: string) => void;
  dealerResult: IAccount | null | undefined;
  createWebIntegration: (webIntegrationData: ILauncherData) => void;
  getWebIntegrations: (domain: string) => void;
  openStatusModal: boolean;
  setOpenStatusModal: () => void;

  getWebIntegrationStatus: (domain: string, widgetURL: string) => void;
  generalMessage: string;
  integratedToPage: boolean;

  updateDealer: (
    inputDealer: IAccountRecord,
    updatedData?: Partial<IAccount>,
  ) => void;
  updateDealerResult: IGetAccountsResult | null;

  openLaunchModal: boolean;
  setOpenLaunchModal: (openLaunchModal: boolean) => void;

  setIsLaunchButtonDisabled: (isLaunchButtonDisabled: boolean) => void;
  setSearchBy: Dispatch<SetStateAction<string>>;
  searchBy: string;
  sortByFilter: ISortingOption[];
  updateSelectedTab: (feedTab: FeedTab) => void;
  currentTab: FeedTab | undefined;
  addingMode: boolean;
  setAddingMode: Dispatch<SetStateAction<boolean>>;
}

const Offers: FC<IOfferList> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  errorMessage,
  fetchOrderState,
  savedOrder,
  showCoopModal,
  toggleCoopModal,
  showExportDrawer,
  toggleExportDrawer,

  forceRenderImages,
  toggleForceRenderImages,
  createNewOrderStateUpdate,
  feed,
  searchQuery,
  assetInstances,
  selectedExportedAssets,
  getDealer,
  dealerResult,
  createWebIntegration,
  getWebIntegrations,
  getWebIntegrationStatus,
  launcherImages,
  generalMessage,
  integratedToPage,
  updateDealer,
  updateDealerResult,
  currentTab,
  openLaunchModal,
  setOpenLaunchModal,
  setIsLaunchButtonDisabled,
  setSearchBy,
  searchBy,
  sortByFilter,
  updateSelectedTab,
  addingMode,
  setAddingMode,
  ...props
}) => {
  const { orderId } = useParams() as { orderId: string };
  const location = useLocation();
  const [archivedOrder, setArchivedOrder] = useState<boolean>(false);
  const [launcherData, setLauncherData] = useState<ILauncherData>({
    domain: "",
    integrationID: "",
    integrationStatus: true,
    instanceID: "",
    relativePath: "",
    location: "",
    positionModifer: "",
    mobileLocation: "",
    mobilePositionModifer: "",
    type: "singleImage",
    images: [],
    instanceStatus: "LIVE",
    createdBy: "",
    createdDate: Date.now(),
    lastModifiedBy: "",
    lastModifiedDate: Date.now(),
    launchLabel: "",
    startDate: null,
    endDate: null,
  });

  const [templatesOrderedByDimension, setTemplatesOrderedByDimension] =
    useState<IAssetInstance[]>([]);

  const [goLiveButtonClicked, setGoLiveButtonClicked] =
    useState<boolean>(false);

  const [selectedLauncherInstances, setSelectedLauncherInstances] = useState<
    Record<number, IAssetInstance>
  >({});

  const [
    currOrderOfSelectedLauncherInstances,
    setCurrOrderOfSelectedLauncherInstances,
  ] = useState<number[]>([]); // Array of nums representing the order the user has selected the selectedLauncherInstances
  const [refIndex, setRefIndex] = useState<number | null>(null);
  const [linkDimension, setLinkDimension] = useState<string | string[]>("");

  const [launcherSelectedTab, setLauncherSelectedTab] = useState<
    "website" | "facebook" | "email" | "display" | "social"
  >("website");

  useEffect(() => {
    if (!assetInstances) return;
    const orderedDimensions = assetInstances
      ? Object.keys(assetInstances).length > 0
        ? orderBy(
            launcherSelectedTab in assetInstances
              ? Object.keys(assetInstances[launcherSelectedTab])
              : [],
          )
        : []
      : [];

    const templatesOrderedByDimension = orderedDimensions.map(
      (dimension: string) => {
        return assetInstances[launcherSelectedTab][dimension];
      },
    );

    const mergedTemplateOrderedByDimension: IAssetInstance[] =
      Array.prototype.concat.apply([], templatesOrderedByDimension);

    setTemplatesOrderedByDimension(mergedTemplateOrderedByDimension);
  }, [assetInstances, launcherSelectedTab]);

  useEffect(() => {
    if (savedOrder) {
      if (
        savedOrder?.selectedOrder?.expiresAt <
        Math.round(Date.now()) + timezoneOffset
      ) {
        setArchivedOrder(true);
        return;
      }
    }
    setArchivedOrder(false);
  }, [savedOrder]);

  useEffect(() => {
    createNewOrderStateUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // skip fetching is data is available or orderId is not valid.
    // commiting newly updated savedOrder has to be dones in proper action function.
    if (!orderId) return;
    fetchOrderState(orderId);
  }, [fetchOrderState, orderId]);

  /*
     It seems that removing the useEffect that was here that contains setSavedOrder
     fixes issue from AV2-1375m where the instance button does not add instances
     url: https://theconstellationagency.atlassian.net/browse/AV2-1375
   */

  // TODO: add back in the currentPage parameter and allow multiple query params in  for elastic search
  // to add back pagination, add back in currentPage

  const [numInstancesInAssetLauncher, setNumInstancesInAssetLauncher] =
    useState(0);

  const [selectedTab, setSelectedTab] = useState<FeedTab>();
  const [selectedTabs, setSelectedTabs] = useState<FeedTab[]>();
  const [unselectedTabs, setUnselectedTabs] = useState<FeedTab[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filteryBy, setFilterBy] = useState<VehicleConditions>("New");
  const [sortingOptions, setSortingOptions] =
    useState<ISortingOption[]>(sortByFilter);
  const [globalSelectedList, setGlobalSelectedList] = useState<
    SelectedOffers[]
  >([]);
  const [currentFilterField, setCurrentFilterField] = useState("");
  const [filterFieldSearch, setFilterFieldSearch] = useState("");

  const getTabs = useCallback(() => {
    const isSelectPage =
      window.location.pathname.endsWith("/select") ||
      window.location.pathname.includes("/select-v2");
    if (!isSelectPage) return;
    const fetchTabs = async () => {
      setIsLoading(true);
      try {
        const tabs = await API.services.assetBuilder.getTabs(orderId);

        if (!tabs || !tabs.result) {
          throw new Error(tabs.error?.message || "Unable to get feeds");
        }

        if (!tabs.result || tabs.error) {
          throw new Error(
            "Default feed does not exist. Please add one on Ad Engine",
          );
        }

        const defaultTab = tabs.result.find(tab => {
          const lcName = tab.feedName.toLowerCase();
          return lcName.includes("default");
        });

        if (!defaultTab) {
          throw new Error(
            "Default feed does not exist. Please add one on Ad Engine",
          );
        }
        setCurrentFilterField(defaultTab.filterColHeader ?? "dealerId");
        const savedTabs = tabs.result.filter(tab => tab.saved);
        const unsavedTabs = tabs.result.filter(tab => {
          const lcName = tab.feedName.toLowerCase();
          if (lcName.includes("default")) return false;
          return !tab.saved;
        });

        setUnselectedTabs([...unsavedTabs]);
        updateSelectedTab(defaultTab);
        setSelectedTab(defaultTab);
        savedTabs.unshift(defaultTab);
        setSelectedTabs([...savedTabs]);
        setIsLoading(false);
      } catch (error) {
        const errorMsg = getErrorMessage(error);
        message.error(errorMsg);
        setIsLoading(false);
      }
    };
    fetchTabs();
  }, [orderId, updateSelectedTab]);

  const onFeedSelect = async (value: string) => {
    const { feedId } = JSON.parse(value) as FeedTab;
    try {
      await API.services.assetBuilder.updateTabOrder(feedId, orderId, "ADD");
      getTabs();
    } catch (error) {
      message.error("Unable to link feed with this order");
    }
  };

  useEffect(getTabs, [getTabs, location]);

  const updateCurrentTab = useCallback(
    (tabName: string) => {
      const currentFeed = selectedTabs?.find(tab => {
        const lcName = tab.feedName.toLowerCase();
        const lcTabName = tabName.toLowerCase();
        return lcName === lcTabName;
      });
      updateSelectedTab(currentFeed!);
      setSelectedTab(currentFeed!);
      setCurrentFilterField(currentFeed?.filterColHeader ?? "dealerId");
    },
    [selectedTabs, updateSelectedTab],
  );

  const AvailableTabs = () => (
    <Select
      style={{ width: 180, color: "#1890FF" }}
      bordered={false}
      onClick={e => e.stopPropagation()}
      value="+ Add Feed"
      onChange={onFeedSelect}
    >
      {unselectedTabs.map(tab => (
        <Select.Option value={JSON.stringify(tab)} key={tab.feedId}>
          {tab.feedName}
        </Select.Option>
      ))}
    </Select>
  );

  return (
    <Spin
      tip="Loading..."
      spinning={
        isLoading ||
        (!savedOrder?.selectedOrder?.wfFullProjectName && !archivedOrder)
      }
    >
      <div className="offer-list-container">
        {(savedOrder?.selectedOrder?.wfFullProjectName || archivedOrder) && (
          <Routes>
            <Route
              path="select"
              element={
                <div style={{ paddingLeft: "2px" }}>
                  <TabContainer
                    selectedTab={
                      currentTab?.feedName ?? selectedTabs?.[0].feedName
                    }
                    displaySearchView={{
                      displayNewOffer: true,
                      displaySearchInput: true,
                      displayPlusButton: false,
                    }}
                    onChange={updateCurrentTab}
                    displayFilterSection={isAuto}
                    onSearchInputChange={(newSearchBy: string) => {
                      setSearchBy(newSearchBy);
                    }}
                    searchBy={searchBy}
                    filterTab={
                      <OfferFilter
                        setFilterBy={setFilterBy}
                        setSortingOptions={setSortingOptions}
                        sortingOptions={sortingOptions}
                        filterBy={filteryBy}
                        filterField={currentFilterField}
                        setFilterFieldSearch={setFilterFieldSearch}
                        filterFieldSearch={filterFieldSearch}
                      />
                    }
                    searchQuery={searchQuery}
                    contentTabs={(selectedTabs || []).map(tab => ({
                      title: tab.feedName,
                      component: (
                        <SelectV1
                          globalSelectedList={globalSelectedList}
                          feedTab={tab}
                          setGlobalSelectedList={setGlobalSelectedList}
                          savedOrder={savedOrder}
                          searchBy={searchBy}
                          lastUpdated={tab.updated}
                          filterBy={filteryBy}
                          sortingOptions={sortingOptions}
                          filterFieldSearch={filterFieldSearch}
                        />
                      ),
                    }))}
                    addButton={<AvailableTabs />}
                    updateSelectedTabs={async title => {
                      if (selectedTabs) {
                        const removedFeedTab = [...selectedTabs];
                        const removedTabIndex = removedFeedTab.findIndex(
                          tab => {
                            return tab.feedName === title;
                          },
                        );

                        const unselectedTab = removedFeedTab[removedTabIndex];
                        removedFeedTab.splice(removedTabIndex, 1);
                        setSelectedTabs(removedFeedTab);
                        setUnselectedTabs([...unselectedTabs, unselectedTab]);
                        try {
                          await API.services.assetBuilder.updateTabOrder(
                            unselectedTab.feedId,
                            orderId,
                            "REMOVE",
                          );
                          getTabs();
                        } catch (error) {
                          message.error("Unable to link feed with this order");
                        }
                      }
                    }}
                  />
                </div>
              }
            />
            <Route
              path="select-v2"
              element={
                <div>
                  <SelectV2
                    feed={feed}
                    savedOrder={savedOrder}
                    searchBy={searchBy}
                    addingMode={addingMode}
                    setAddingMode={setAddingMode}
                  />
                </div>
              }
            />
            <Route
              path="build"
              element={
                <Build
                  savedOrder={savedOrder}
                  feedTabs={
                    savedOrder?.currentTab
                      ? [savedOrder?.currentTab]
                      : selectedTabs
                  }
                />
              }
            />
            <Route
              path="review"
              element={
                <RenderTemplateProvider>
                  <Review
                    feedTab={savedOrder?.currentTab ?? (selectedTabs || [])[0]}
                    showCoopModal={showCoopModal}
                    toggleCoopModal={toggleCoopModal}
                    showExportDrawer={showExportDrawer}
                    toggleExportDrawer={toggleExportDrawer}
                    forceRenderImages={forceRenderImages}
                    toggleForceRenderImages={toggleForceRenderImages}
                    offers={savedOrder?.selectedOffers || []}
                    fetchOrderState={() => {
                      fetchOrderState(orderId);
                    }}
                  />
                </RenderTemplateProvider>
              }
            />
            <Route
              path="asset-launcher"
              element={
                <RenderTemplateProvider>
                  {/* could move the container to the screen itself */}
                  <TabContainer
                    selectedTab={launcherSelectedTab}
                    onChange={setLauncherSelectedTab as any}
                    displaySearchView={{
                      displayNewOffer: false,
                      displaySearchInput: false,
                      displayPlusButton: false,
                    }}
                    filterTab={
                      <AssetLauncherPanel
                        enableLaunchPage={true}
                        selectedExportedAssets={selectedExportedAssets}
                        launcherSelectedTab={launcherSelectedTab}
                        numInstancesInAssetLauncher={
                          numInstancesInAssetLauncher
                        }
                        assetInstances={assetInstances}
                        savedOrder={savedOrder}
                        getDealer={getDealer}
                        dealerResult={dealerResult}
                        createWebIntegration={createWebIntegration}
                        getWebIntegrations={getWebIntegrations}
                        launcherImages={launcherImages}
                        launcherData={launcherData}
                        setLauncherData={setLauncherData}
                        templatesOrderedByDimension={
                          templatesOrderedByDimension
                        }
                        setTemplatesOrderedByDimension={
                          setTemplatesOrderedByDimension
                        }
                        setRefIndex={setRefIndex}
                        setLinkDimension={setLinkDimension}
                        goLiveButtonClicked={goLiveButtonClicked}
                        setGoLiveButtonClicked={setGoLiveButtonClicked}
                        generalMessage={generalMessage}
                        selectedLauncherInstances={selectedLauncherInstances}
                        currOrderOfSelectedLauncherInstances={
                          currOrderOfSelectedLauncherInstances
                        }
                        getWebIntegrationStatus={getWebIntegrationStatus}
                        integratedToPage={integratedToPage}
                        updateDealer={updateDealer}
                        updateDealerResult={updateDealerResult}
                        openLaunchModal={openLaunchModal}
                        setOpenLaunchModal={setOpenLaunchModal}
                        setIsLaunchButtonDisabled={setIsLaunchButtonDisabled}
                        setCurrOrderOfSelectedLauncherInstances={
                          setCurrOrderOfSelectedLauncherInstances
                        }
                        setSelectedLauncherInstances={
                          setSelectedLauncherInstances
                        }
                      ></AssetLauncherPanel>
                    }
                    contentTabs={["Website"]
                      // Keeping the line below for when we add in other tabs
                      // Object.keys(assetInstances || [])
                      .map(assetType => {
                        return {
                          title:
                            assetType.charAt(0).toUpperCase() +
                            assetType.slice(1),
                          component: (
                            <AssetLauncher
                              forceRenderImages={forceRenderImages}
                              toggleForceRenderImages={toggleForceRenderImages}
                              fetchOrderState={() => {
                                fetchOrderState(orderId);
                              }}
                              launcherSelectedTab={launcherSelectedTab}
                              setLauncherSelectedTab={setLauncherSelectedTab}
                              assetInstances={assetInstances}
                              selectedExportedAssets={selectedExportedAssets}
                              setNumInstancesInAssetLauncher={
                                setNumInstancesInAssetLauncher
                              }
                              refIndex={refIndex}
                              linkDimension={linkDimension}
                              setLinkDimension={setLinkDimension}
                              openStatusModal={props.openStatusModal}
                              setOpenStatusModal={props.setOpenStatusModal}
                              launcherImages={launcherImages}
                              launcherData={launcherData}
                              setLauncherData={setLauncherData}
                              templatesOrderedByDimension={
                                templatesOrderedByDimension
                              }
                              goLiveButtonClicked={goLiveButtonClicked}
                              setGoLiveButtonClicked={setGoLiveButtonClicked}
                              selectedLauncherInstances={
                                selectedLauncherInstances
                              }
                              setSelectedLauncherInstances={
                                setSelectedLauncherInstances
                              }
                              currOrderOfSelectedLauncherInstances={
                                currOrderOfSelectedLauncherInstances
                              }
                              setCurrOrderOfSelectedLauncherInstances={
                                setCurrOrderOfSelectedLauncherInstances
                              }
                              openLaunchModal={openLaunchModal}
                              setOpenLaunchModal={() => {
                                setOpenLaunchModal(openLaunchModal);
                              }}
                            />
                          ),
                        };
                      })}
                  />
                </RenderTemplateProvider>
              }
            />

            <Route path="create-offer" element={<EditOffer />} />

            <Route
              path="edit-offer/*"
              element={<EditOffer feedId={selectedTab?.feedId} />}
            />

            <Route path="*" element={<div>Wrong page</div>} />
          </Routes>
        )}
      </div>
    </Spin>
  );
};

const mapStateToProps = (state: any) => {
  const { assetBuilder, configuration, dealerManagement } = state as {
    assetBuilder: IAssetBuilderState;
    configuration: IConfigurationState;
    dealerManagement: IAccountManagementState;
  };
  const { feed, config } = configuration;
  const {
    fetchingOfferList,
    isDataEmpty,
    total = 0,
    currentPage,
    savedOrder,
    errorMessage,
    offerEditsWereSaved,
    searchQuery,
    selectedExportedAssets,
    launcherImages,
    generalMessage,
    integratedToPage,
    sortByFilter,
    currentTab,
  } = assetBuilder;

  const { dealerResult, result: updateDealerResult } = dealerManagement;

  const { newOrders } = state;
  const { currentSelectedOrder } = newOrders as {
    currentSelectedOrder: INewOrderRecord;
  };

  // pre-process the assetInstances
  let assetInstances: Record<string, Record<string, IAssetInstance[]>> = {};
  if (savedOrder?.assetInstances) {
    assetInstances = removePurchasePlaceholder(savedOrder?.assetInstances);
  }

  return {
    sortByFilter,
    feed,
    total,
    fetchingOfferList,
    isDataEmpty,
    currentPage,
    errorMessage,
    savedOrder,
    currentSelectedOrder,
    offerEditsWereSaved,
    searchQuery,
    config,
    currentTab,
    assetInstances,
    selectedExportedAssets,
    dealerResult: dealerResult?.dealer,
    launcherImages,
    generalMessage,
    integratedToPage,

    updateDealerResult: updateDealerResult as IGetAccountsResult | null,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateSelectedTab: (feedTab: FeedTab) => {
      dispatch(actions.assetBuilder.updateSelectedTab(feedTab));
    },
    fetchOrderState: (orderId: string) => {
      dispatch(actions.assetBuilder.fetchOrderState(orderId));
    },
    createNewOrderStateUpdate: () => {
      dispatch(actions.newOrders.createNewOrderStateUpdate());
    },
    getDealer: (dealerName: string) => {
      dispatch(actions.dealerManagement.getDealer(dealerName));
    },
    createWebIntegration: (webIntegrationData: ILauncherData) => {
      dispatch(actions.assetBuilder.createWebIntegration(webIntegrationData));
    },
    getWebIntegrations: (domain: string) => {
      dispatch(actions.assetBuilder.getWebIntegrations(domain));
    },
    getWebIntegrationStatus: (domain: string, widgetURL: string) => {
      dispatch(actions.assetBuilder.getWebIntegrationStatus(domain, widgetURL));
    },
    updateDealer: (
      inputDealer: IAccountRecord,
      updatedData?: Partial<IAccount>,
    ) => {
      dispatch(
        actions.dealerManagement.updateDealer(inputDealer, true, updatedData),
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Offers);
