import { Switch } from "antd";
import { useMediaActions } from "redux/media/media.slice";
import { useAppSelector } from "../redux";

import styles from "./TreeNavSwitch.module.scss";

export const TreeNavSwitch = () => {
  const open = useAppSelector(({ media }) => media.treeNavOpen);
  const { setTreeNavOpen } = useMediaActions();

  return (
    <div className={styles.host}>
      <Switch checked={open} size="small" onChange={setTreeNavOpen} />
      <span>Show Navigator</span>
    </div>
  );
};
