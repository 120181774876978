import { Checkbox } from "antd";
import { FieldWrapper } from "screens/assetExporter/feedConfigurationV2/shared/components/FieldWrapper";
import styles from "./AudioCheckboxMedia.module.scss";
import { useAssetBatchesContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesContext";
import { TMediaComposition } from "shared/types/assetExporter";

interface AudioCheckboxMediaProps {
  composition: TMediaComposition;
}

export const AudioCheckboxMedia = ({
  composition,
}: AudioCheckboxMediaProps) => {
  const { compositions, setCompositions } = useAssetBatchesContext();
  const handleCheckboxChange = (checked: boolean) => {
    const newCompositions = compositions.map(comp => {
      if (comp.compositionId === composition.compositionId) {
        return {
          ...comp,
          audioEnabled: checked,
        };
      }
      return comp;
    });
    setCompositions(newCompositions);
  };

  return (
    <FieldWrapper label="Audio" customStyle={styles.label}>
      <Checkbox
        defaultChecked={composition?.audioEnabled ?? true}
        checked={composition?.audioEnabled ?? true}
        className={styles.checkbox}
        onChange={e => handleCheckboxChange(e.target.checked)}
      />
    </FieldWrapper>
  );
};
