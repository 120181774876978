import { useCallback, useMemo } from "react";
import { message } from "antd";
import { UndoMessage } from "shared/components/UndoMessage";
import {
  CompositionType,
  MediaSubtype,
  TComposition,
} from "shared/types/assetExporter";
import { useAssetBatchesContext } from "../contexts/AssetBatchesContext";
import styles from "../../FeedList.module.scss";

export const useUndoDeleteComposition = (composition: TComposition) => {
  const { removeComposition, changeTemporalRemoved, templatesToUse } =
    useAssetBatchesContext();

  const messageKey = useMemo(
    () => `removeComposition-${composition.compositionId}`,
    [composition.compositionId],
  );
  const name = useMemo(() => {
    if (composition.type === CompositionType.Template)
      return templatesToUse.find(
        compTemplate => compTemplate.id === composition.template,
      )?.name;
    if (composition.subtype === MediaSubtype.CAM) return composition.name;
    return `Media composition from column - ${composition.column}`;
  }, [composition, templatesToUse]);

  const onClose = useCallback(() => {
    removeComposition(composition);
    message.destroy(messageKey);
  }, [composition, messageKey, removeComposition]);

  const undoMessage = useCallback(() => {
    message.success({
      className: styles.undoMessage,
      key: messageKey,
      content: (
        <UndoMessage
          message={`"${name}" was removed`}
          onClick={() => {
            changeTemporalRemoved(composition, false);
            message.destroy(messageKey);
          }}
          onClose={onClose}
        />
      ),
      duration: 5,
      size: "small",
      onClose,
    });
  }, [composition, messageKey, name, changeTemporalRemoved, onClose]);

  return {
    undoMessage,
  };
};
