import { Image, Carousel, message } from "antd";
import ReactPlayer from "react-player";

import { IAd, ICard } from "shared/types/adLibrary";

import { MediaPreviewModal } from "shared/components/MediaPreviewModal";

const AdPreview = <T extends IAd = IAd>(
  ad: T,
  setAd: (ad: T | null) => void,
) => {
  const displayPreviewError = (isError: boolean) => {
    if (isError) {
      setAd(null);
      return message.warning("Could not display ad preview");
    }
  };
  switch (ad.type) {
    case "Still":
      displayPreviewError(!ad.visuals.thumbnail);
      return <Image preview={false} src={ad.visuals.thumbnail} />;
    case "Carousel":
      displayPreviewError(!ad.visuals.cards);
      return (
        <Carousel draggable autoplay>
          {ad.visuals.cards?.map((card: ICard) => {
            return card.videoUrl ? (
              <ReactPlayer
                playing={true}
                width="100%"
                height="100%"
                loop={true}
                key={card.videoUrl}
                url={card.videoUrl}
              />
            ) : (
              <Image
                preview={false}
                width="100%"
                height="100%"
                key={card.thumbnail}
                src={card.thumbnail}
              />
            );
          })}
        </Carousel>
      );
    case "Video":
      displayPreviewError(!ad.visuals.videoUrl);
      return (
        <ReactPlayer
          playing={true}
          width="100%"
          loop={true}
          height="100%"
          url={ad.visuals.videoUrl}
          controls={true}
        />
      );
    // Handle AIA (confirm which thumbnail/video/carousel url to use)
    default:
      displayPreviewError(!ad?.visuals.thumbnail);
      return <Image preview={false} src={ad?.visuals.thumbnail} />;
  }
};

export const AdPreviewModal = <T extends IAd = IAd>({
  ad,
  setAd,
}: {
  ad: T;
  setAd: (ad: T | null) => void;
}) => (
  <MediaPreviewModal visible={!!ad} onCancel={() => setAd(null)}>
    {AdPreview(ad, setAd)}
  </MediaPreviewModal>
);
