import { useState } from "react";

export const useAnchorPopover = (name?: string) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? `${name ?? "custom"}-popover` : undefined;

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const onClose = () => setAnchorEl(null);

  return {
    id,
    open,
    anchorEl,
    onClick,
    onClose,
  };
};
