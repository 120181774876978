import { UseInfiniteQueryResult } from "react-query";
import API from "services";
import {
  ArtboardSizeType,
  IGetTemplatesResult,
  TemplateStatusType,
} from "shared/types/designStudio";
import { IDataTableError } from "shared/types/errors";
import { useConcurrentInfiniteQueries } from "./useConcurrentInfiniteQueries";

type Props = {
  status?: TemplateStatusType;
  isDeleted?: boolean;
  artboardSize?: ArtboardSizeType;
};

export const useFetchTemplatesConcurrently = (query: Props) => {
  const results = useConcurrentInfiniteQueries(6, {
    queryKey: ["templates", query],
    staleTime: Infinity,
    getNextPageParam(params: any) {
      return params.paginationKey;
    },
    async queryFn({ pageParam, queryKey }) {
      const { result, error } =
        await API.services.designStudio.getTemplatesPaginated({
          ...query,
          paginationKey: pageParam,
          // last query key is the segments value
          segments: queryKey[queryKey.length - 1] as [number, number],
        });

      if (error) {
        throw Error(error.message);
      }

      return result;
    },
  }) as UseInfiniteQueryResult<IGetTemplatesResult, IDataTableError>[];

  return {
    templates: results.flatMap(
      res => res.data?.pages.flatMap(page => page.templates) || [],
    ),
    isFetching: results.some(r => r.isFetching),
  };
};
