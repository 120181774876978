import { useState, useCallback, useEffect } from "react";
import { getLayerName } from "screens/designStudio/designStudioV2/editorDrawer/editorDrawerContext/helpers.store";
import { LayerElement } from "screens/designStudio/designStudioV2/types";

export const useLayerName = (layer: LayerElement, isSelected: boolean) => {
  const originalLayerName = getLayerName(layer);
  const [layerName, setLayerName] = useState(originalLayerName);

  const resetName = useCallback(() => {
    setLayerName(originalLayerName);
  }, [originalLayerName]);

  useEffect(resetName, [isSelected, resetName]);

  return {
    layerName,
    setLayerName,
    resetName,
  };
};
