import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { queryClient } from "queryClient";
import { AppThunk } from "redux/store";
import GenericError from "shared/errors/GenericError";
import { dealerPagesQueryKey } from "shared/hooks/useFetchDealers";
import { ACCOUNT_QUERY_KEY } from "shared/hooks/useFetchDealersV2";
import API from "../../services";
import {
  ICreateAccountResponse,
  IAccount,
  IAccountManagementState,
  IAccountRecord,
  IDeleteAccountResponse,
  IEditAccountResponse,
  IGetAccountsResponse,
  IGetAccountsResult,
} from "../../shared/types/accountManagement";
import { delay } from "../../utils/helpers";

const initialState: IAccountManagementState = {
  processingDealers: false,
  dealersMessage: "",
  dealerPaginationKey: "",
  dealerRecords: [],
  result: null,
  error: null,
  dealerResult: null,
};

const generateKeys = (
  dealers: IAccount[],
  currentRecordCount?: number,
): (IAccount & { key: number })[] =>
  dealers
    .sort((a, b) => a.created_at - b.created_at)
    .map((dealer, index) => {
      const key =
        currentRecordCount && currentRecordCount > 0
          ? currentRecordCount + index
          : index;

      return { key, ...dealer };
    });

const returnDealerRecordsFromDealers = (
  dealers: IAccount[],
  currentRecordCount?: number,
): IAccountRecord[] =>
  generateKeys(dealers, currentRecordCount)
    .map(dealer => {
      const logoUrls = dealer.logo_urls_from_S3
        ? JSON.parse(dealer.logo_urls_from_S3)
        : {
            horizontalEventImagesFromS3: [],
            horizontalImagesFromS3: [],
            squareEventImagesFromS3: [],
            squareImagesFromS3: [],
            verticalEventImagesFromS3: [],
            verticalImagesFromS3: [],
          };
      return {
        key: dealer.key,
        dealerName: dealer.dealer_name,
        dealerCode: dealer.dealer_code,
        dealerOem: dealer.dealer_oem,
        dealerUrl: dealer.dealer_url,
        logoUrl: dealer.logo_url,
        dealerAddress: dealer.address,
        dealerCity: dealer.city || "",
        dealerUsState: dealer.state,
        dealerZip: dealer.zip,
        dealerZipCodeList: dealer.zipCodeList,
        dealerPhoneNumber: dealer.phone_number || "",
        dealerFinalPriceName: dealer.final_price_name || "",
        logoUrlsFromS3: logoUrls,
        webIntPositions: dealer.web_int_positions || [],
        labels: dealer.labels || [],
        coopDetails: dealer.coopDetails || {
          emailOrPortal: "",
          coopSite: "",
          coopEmail: "",
          coopUsername: "",
          coopPassword: "",
          coopDealerCode: "",
          coopLoginLocked: false,
        },
        details: dealer.details || {
          facebook: {
            fbPageId: "",
            fbAccountId: "",
            fbCatalogId: "",
            fbPixelId: "",
            fbInstagramId: "",
          },
        },
        createdAt: dealer.created_at,
        updatedAt: dealer.updated_at,
        enabled: dealer.enabled,
        offerlogixSourceCode: dealer.offerlogix_source_code,
      };
    })
    .sort((a, b) => (b.createdAt ?? 0) - (a.createdAt ?? 0));

export const returnDealerDataObjFromRecord = (inputDealer: IAccountRecord) => {
  const dealer: IAccount = {
    dealer_name: inputDealer.dealerName.trim(),
    dealer_code: inputDealer.dealerCode,
    dealer_oem: inputDealer.dealerOem,
    dealer_url: inputDealer.dealerUrl,
    logo_url: inputDealer.logoUrl.trim(),
    address: inputDealer.dealerAddress,
    city: inputDealer.dealerCity,
    state: inputDealer.dealerUsState,
    zip: inputDealer.dealerZip,
    zipCodeList: inputDealer.dealerZipCodeList,
    phone_number: inputDealer.dealerPhoneNumber || "",
    final_price_name: inputDealer.dealerFinalPriceName || "",
    new_dealer_name: inputDealer.newDealerName?.trim() || "",
    logo_urls_from_S3: JSON.stringify(inputDealer.logoUrlsFromS3) || null,
    web_int_positions: inputDealer.webIntPositions,
    labels: inputDealer.labels || [],
    offerlogix_source_code: inputDealer.offerlogixSourceCode,
    coopDetails: inputDealer.coopDetails || {
      emailOrPortal: "",
      coopSite: "",
      coopEmail: "",
      coopUsername: "",
      coopPassword: "",
      coopDealerCode: "",
      coopLoginLocked: false,
    },
    details: inputDealer.details || {
      facebook: {
        fbCatalogId: "",
        fbPageId: "",
        fbAccountId: "",
        fbPixelId: "",
        fbInstagramId: "",
      },
    },
    created_at: inputDealer.createdAt || Date.now(),
    updated_at: Date.now(),
    enabled: inputDealer.enabled ?? false,
  };
  return dealer;
};

const dealerManagementSlice = createSlice({
  name: "dealerManagement",
  initialState,
  reducers: {
    getDealerFail(state) {
      return state;
    },
    getDealerSuccess(
      state,
      {
        payload,
      }: PayloadAction<{
        result: {
          dealer: IAccount;
        } | null;
        error: any;
      }>,
    ) {
      const { result: dealerResult } = payload;
      return {
        ...state,
        dealerResult,
      };
    },
    getDealersBegin(state) {
      return {
        ...state,
        processingDealers: true,
      };
    },
    getDealersFail(state, { payload: error }: PayloadAction<GenericError>) {
      return {
        ...state,
        processingDealers: false,
        error,
      };
    },
    getDealersSuccess(
      state,
      {
        payload,
      }: PayloadAction<{
        response: IGetAccountsResponse;
        paginationToken: string;
      }>,
    ) {
      const getDealersPage = () => {
        const { result: getDealersFromPageResult } = payload.response;
        const { dealers: dealersFromPage, paginationKey: pageKeyFromPage } =
          getDealersFromPageResult ?? {
            dealers: [],
            paginationKey: { dealer_name: "" },
          };
        const { dealer_name: paginationKeyDealerFromPage = "" } =
          pageKeyFromPage || {};
        const dealerRecordsFromPage = returnDealerRecordsFromDealers(
          dealersFromPage,
          state.dealerRecords.length,
        );
        const scannedCount = state.result
          ? (state.result as IGetAccountsResult).scannedCount
          : getDealersFromPageResult?.scannedCount ?? 0;

        let newDealerRecords: IAccountRecord[] = [];
        if (state.dealerRecords.length < scannedCount) {
          newDealerRecords = [...state.dealerRecords, ...dealerRecordsFromPage];
        } else if (state.dealerRecords.length >= scannedCount) {
          newDealerRecords = [...state.dealerRecords];
        }

        const updatedResult: IGetAccountsResult = {
          ...getDealersFromPageResult,
          dealers: getDealersFromPageResult?.dealers || [],
          scannedCount: state.result
            ? (state.result as IGetAccountsResult).scannedCount
            : getDealersFromPageResult?.scannedCount ?? 0,
        };

        return {
          ...state,
          dealerRecords: newDealerRecords,
          processingDealers: false,
          dealerPaginationKey: paginationKeyDealerFromPage || "",
          result: updatedResult,
        };
      };

      const getDealers = () => {
        const { result } = payload.response;
        const { dealers, paginationKey } = result as IGetAccountsResult;
        const { dealer_name: paginationKeyDealer = "" } = paginationKey || {};
        const dealerRecords = returnDealerRecordsFromDealers(dealers);
        return {
          ...state,
          dealerRecords,
          processingDealers: false,
          dealerPaginationKey: paginationKeyDealer || "",
          result,
        };
      };
      if (payload.paginationToken) {
        return getDealersPage();
      } else {
        return getDealers();
      }
    },
    createDealerBegin(state) {
      return {
        ...state,
        dealersMessage: "",
        processingDealers: true,
      };
    },
    createDealerFail(state, { payload: error }: PayloadAction<GenericError>) {
      return {
        ...state,
        error,
        processingDealers: false,
      };
    },
    createDealerSuccess(
      state,
      { payload }: PayloadAction<ICreateAccountResponse>,
    ) {
      const { result: createDealerResult } = payload;
      const { createdDealer } = createDealerResult as {
        createdDealer: IAccount;
      };
      const { dealerRecords: prevDealerRecords } = state;

      const newDealerRecord: IAccountRecord = {
        key: prevDealerRecords.length,
        dealerName: createdDealer.dealer_name,
        dealerCode: createdDealer.dealer_code,
        dealerOem: createdDealer.dealer_oem,
        dealerUrl: createdDealer.dealer_url,
        logoUrl: createdDealer.logo_url,
        dealerAddress: createdDealer.address || "",
        dealerCity: createdDealer.city || "",
        dealerUsState: createdDealer.state,
        dealerZip: createdDealer.zip || "",
        dealerZipCodeList: createdDealer.zipCodeList,
        dealerPhoneNumber: createdDealer.phone_number || "",
        dealerFinalPriceName: createdDealer.final_price_name || "",
        details: createdDealer.details || {
          facebook: {
            fbAccountId: "",
            fbPageId: "",
            fbCatalogId: "",
            fbPixelId: "",
            fbInstagramId: "",
          },
        },
        createdAt: createdDealer.created_at,
        updatedAt: createdDealer.updated_at,
      };

      const updatedDealerRecords = prevDealerRecords.filter(
        (dealer: IAccountRecord) =>
          dealer.dealerName !== newDealerRecord.dealerName,
      );

      updatedDealerRecords.push(newDealerRecord);

      return {
        ...state,
        result: createDealerResult,
        dealerRecords: [...updatedDealerRecords],
        dealersMessage: "Store Created successfully.",
        processingDealers: false,
      };
    },
    deleteDealerBegin(state) {
      return {
        ...state,
        dealersMessage: "",
        processingDealers: true,
      };
    },
    deleteDealerFail(state, { payload: error }: PayloadAction<Error>) {
      return {
        ...state,
        error,
        processingDealers: false,
      };
    },
    deleteDealerSuccess(
      state,
      { payload }: PayloadAction<IDeleteAccountResponse>,
    ) {
      const { result: deleteDealerResult } = payload;
      return {
        ...state,
        result: deleteDealerResult,
        dealersMessage: "Store Deleted successfully.",
        processingDealers: false,
      };
    },
    updateDealerBegin(state) {
      return {
        ...state,
        dealersMessage: "",
        processingDealers: true,
      };
    },
    updateDealerFail(state, { payload: error }: PayloadAction<GenericError>) {
      return {
        ...state,
        error,
        processingDealers: false,
      };
    },
    updateDealerSuccess(
      state,
      { payload }: PayloadAction<IEditAccountResponse>,
    ) {
      const { result: updateDealerResult, sendMessage } = payload;
      return {
        ...state,
        result: updateDealerResult,
        dealersMessage:
          sendMessage === false ? "" : "Store Updated successfully.",
      };
    },
    resetDealerFeedbackSuccess(state) {
      return {
        ...state,
        dealersMessage: "",
        error: null,
      };
    },
  },
});

export const getDealer =
  (dealerName = ""): AppThunk =>
  async dispatch => {
    try {
      const response = await API.privServices.dealerManagement.getDealer(
        dealerName,
      );

      const { error } = response;

      if (error) {
        dispatch(getDealerFail());
        return;
      }

      dispatch(getDealerSuccess(response));
    } catch (error) {
      dispatch(getDealerFail());
    }
  };

export const getDealers =
  (paginationToken = ""): AppThunk =>
  async dispatch => {
    dispatch(getDealersBegin());

    try {
      const response =
        await API.privServices.dealerManagement.getDealers<IGetAccountsResponse>(
          paginationToken,
        );

      const { error } = response;

      if (error) {
        const { message } = error;
        dispatch(
          getDealersFail(
            new GenericError({
              message: message || "Some error occurred.",
            }),
          ),
        );
        return;
      }

      dispatch(getDealersSuccess({ response, paginationToken }));
    } catch (error) {
      dispatch(
        getDealersFail(
          new GenericError({
            message: (error as Error).message || "Some error occurred.",
            errorObject: error,
          }),
        ),
      );
    }
  };

export const createDealer =
  (inputDealer: IAccountRecord): AppThunk =>
  async dispatch => {
    dispatch(createDealerBegin());

    try {
      const dealer: IAccount = returnDealerDataObjFromRecord(inputDealer);

      const response =
        await API.privServices.dealerManagement.createDealer<ICreateAccountResponse>(
          dealer,
        );

      const { error } = response;

      if (error) {
        const { message } = error;
        dispatch(
          createDealerFail(
            new GenericError({
              message: message || "Some error occurred.",
            }),
          ),
        );
        return;
      }

      dispatch(createDealerSuccess(response));
    } catch (error) {
      dispatch(
        createDealerFail(
          new GenericError({
            message: (error as Error).message || "Some error ocurred.",
            errorObject: error,
          }),
        ),
      );
    } finally {
      queryClient.refetchQueries(dealerPagesQueryKey);
      queryClient.refetchQueries(ACCOUNT_QUERY_KEY);
    }
  };

export const deleteDealer =
  (inputDealer: IAccountRecord): AppThunk =>
  async dispatch => {
    dispatch(deleteDealerBegin());

    try {
      const dealer: IAccount = returnDealerDataObjFromRecord(inputDealer);

      const response =
        await API.privServices.dealerManagement.deleteDealer<IDeleteAccountResponse>(
          dealer,
        );

      const { error } = response;

      if (error) {
        const { message } = error;
        dispatch(
          deleteDealerFail(
            new GenericError({
              message: message || "Some error ocurred.",
            }),
          ),
        );
        return;
      }

      dispatch(deleteDealerSuccess(response));
    } catch (error) {
      dispatch(
        deleteDealerFail(
          new GenericError({
            message: (error as Error).message || "Some error ocurred.",
            errorObject: error,
          }),
        ),
      );
    } finally {
      queryClient.refetchQueries(dealerPagesQueryKey);
      queryClient.refetchQueries(ACCOUNT_QUERY_KEY);
    }
  };

export const updateDealer =
  (
    inputDealer: IAccountRecord,
    sendMessage: boolean = true,
    updatedData?: Partial<IAccount>,
  ): AppThunk =>
  async dispatch => {
    dispatch(updateDealerBegin());

    await delay(500);

    try {
      const dealer: IAccount = returnDealerDataObjFromRecord(inputDealer);

      const response =
        await API.privServices.dealerManagement.updateDealer<IEditAccountResponse>(
          dealer,
          updatedData,
        );

      const { error } = response;

      if (error) {
        const { message } = error;
        dispatch(
          updateDealerFail(
            new GenericError({
              message: message || "Some error occurred.",
            }),
          ),
        );
        return;
      }

      const newResponse = {
        ...response,
        sendMessage,
      };
      dispatch(updateDealerSuccess(newResponse));
    } catch (error) {
      dispatch(
        updateDealerFail(
          new GenericError({
            message: (error as Error).message || "Some error occurred.",
            errorObject: error,
          }),
        ),
      );
    } finally {
      queryClient.refetchQueries(dealerPagesQueryKey);
      queryClient.refetchQueries(ACCOUNT_QUERY_KEY);
    }
  };

export const resetDealerFeedback = (): AppThunk => dispatch => {
  dispatch(resetDealerFeedbackSuccess());
};

export const {
  createDealerBegin,
  createDealerFail,
  createDealerSuccess,
  deleteDealerBegin,
  deleteDealerFail,
  deleteDealerSuccess,
  getDealerFail,
  getDealerSuccess,
  getDealersBegin,
  getDealersFail,
  getDealersSuccess,
  resetDealerFeedbackSuccess,
  updateDealerBegin,
  updateDealerFail,
  updateDealerSuccess,
} = dealerManagementSlice.actions;

export default dealerManagementSlice.reducer;
