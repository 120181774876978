import { Stack } from "@mui/material";
import { observer } from "mobx-react-lite";
import { StoreType } from "polotno/model/store";
import { figureElementSelected } from "../utils";
import { FigureEffectsEditor } from "./figureEditor/FigureEffectsEditor";
import { FigureStrokeEditor } from "./figureEditor/FigureStrokeEditor";
import { FillColorEditor } from "./shared/FillColorEditor";

type FigureEditorProps = {
  store: StoreType;
};

export const FigureEditor = observer(({ store }: FigureEditorProps) => {
  const selection = store.selectedElements;
  const correctSelection = figureElementSelected(selection);

  if (!correctSelection) return null;

  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      gap={1}
    >
      <FillColorEditor elements={selection} />
      <FigureStrokeEditor elements={selection} />
      <FigureEffectsEditor elements={selection} />
    </Stack>
  );
});
