import { TextField } from "@mui/material";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import { observer } from "mobx-react-lite";
import { TextLayerElement } from "screens/designStudio/designStudioV2/types";

type FontSizeInputProps = {
  elements: TextLayerElement[];
};

const FontSizeInput = observer(({ elements }: FontSizeInputProps) => {
  const [layer] = elements;
  const fontSizeValue = layer?.fontSize || 1;

  const handleFontSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newSize = parseInt(e.target.value, 10);
    newSize = !isNaN(newSize) && newSize > 0 ? newSize : 1;
    elements.forEach(element => {
      element?.set({ fontSize: newSize });
    });
  };

  const handleFontSizeBlur = () => {
    if (typeof fontSizeValue === "number" && fontSizeValue <= 0) {
      elements.forEach(element => {
        element.set({ fontSize: 1 });
      });
    }
  };

  return (
    <TextField
      id="outlined-number"
      variant="outlined"
      label="Font size"
      type="number"
      value={fontSizeValue}
      onChange={handleFontSizeChange}
      onBlur={handleFontSizeBlur}
      size="small"
      sx={{
        maxWidth: 100,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
      InputProps={{
        startAdornment: (
          <TextFieldsIcon
            fontSize="small"
            sx={{
              color: "action.active",
              ml: -0.5,
              mr: 0.5,
            }}
          />
        ),
      }}
    />
  );
});

export default FontSizeInput;
