import { fabric } from "fabric";
import { groupBy, keys, orderBy, reduce } from "lodash";
import capitalize from "lodash/capitalize";
import { ImageType, LogoType } from "shared/types/brandsAccounts";
import {
  IExtendedFabricObject,
  TExtendedLogo,
  TExtendedTextbox,
  TVariableAlias,
} from "shared/types/designStudio";
import uuid from "uuid";
import { TValue, TValueMapping, TVariable } from "../../shared/types";
import {
  isCarCut,
  isColumnValue,
  isImageRect,
  isLogo,
  isTextbox,
} from "../../shared/validators";

export const extractVarsFromSingleLine = (
  line: string,
): Array<Partial<TVariable>> => {
  const varialbes: Array<Partial<TVariable>> = [];

  const regexp = /\{.*?\}/g;
  let result: RegExpExecArray | null = null;
  while ((result = regexp.exec(line))) {
    const [raw] = result;
    const { index } = result;

    const variable = raw.replace(/(\{|\})/g, "");

    varialbes.push({
      variable,
      raw,
      startIdx: index,
    });
  }

  return varialbes;
};

export const textLineForVarAlias = (
  textLine = "",
  variableAlias?: TVariableAlias,
) => {
  if (!variableAlias) return textLine;
  return Object.keys(variableAlias).reduce((acc: string, curr: string) => {
    const { customVariable, isMaskOn } = variableAlias[curr] ?? {};
    if (!customVariable || !isMaskOn) return acc;
    const regex = new RegExp(customVariable, "g");
    return acc.replace(regex, curr);
  }, textLine);
};

const getFlatStyles = (styles: any) => {
  return Object.keys(styles)
    .map((lineIdx: string) => {
      const lineStyles = styles[lineIdx];
      return Object.keys(lineStyles).map((charIdx: string) => {
        const style = lineStyles[charIdx];
        return {
          ...style,
        };
      });
    })
    .flat();
};

const getAlignedStyles = (
  originalText: string | undefined,
  originalTextLines: string[],
  styles: any,
) => {
  const originalTextStyles: any = { ...styles };

  const originalTextWithoutLine = originalText?.replace(/(\r\n|\n|\r)/gm, "");
  const flatStyles = getFlatStyles(styles);

  // sometimes the originalTextWithoutLine is not the same length as the flatStyles
  // in this case we cannot align the styles
  if (originalTextWithoutLine?.length !== flatStyles.length) return styles;

  let currFlatStyleIdx = 0;
  originalTextLines.forEach((textLine: string, lineIdx: number) => {
    const textLineStyle: any = {};
    textLine.split("").forEach((char: string, charIdx: number) => {
      // there is a case when the originalText is empty string but the originalTextLines is not
      if (originalTextWithoutLine?.charAt(currFlatStyleIdx) !== char)
        currFlatStyleIdx += 1;

      textLineStyle[charIdx] = flatStyles[currFlatStyleIdx];
      currFlatStyleIdx += 1;
    });
    originalTextStyles[lineIdx] = textLineStyle;
  });

  return originalTextStyles;
};

export const setTextboxDataForCanvas = (textbox: TExtendedTextbox) => {
  const { variableAlias, text, textLines, styles } = textbox;

  const originalText = variableAlias
    ? textLineForVarAlias(text, variableAlias)
    : text;
  const originalTextLines = variableAlias
    ? textLines.map((textLine: string) =>
        textLineForVarAlias(textLine, variableAlias),
      )
    : textLines;

  (textbox as any).set({
    originalText,
    originalTextLines,
    ...(styles && {
      styles: getAlignedStyles(originalText, originalTextLines, styles),
    }),
    templateStyles: styles,
    objectCaching: false,
  });
};

export const extractTextVariables = (
  textbox: fabric.Textbox,
): Array<TVariable> => {
  const { originalTextLines, originalText } = textbox as fabric.Textbox & {
    originalTextLines: string[];
    originalText: string;
  };
  const lines = originalTextLines ?? [originalText];

  return lines.reduce<Array<TVariable>>((acc, line, lineIdx) => {
    const partialVars = extractVarsFromSingleLine(line);
    const variables = partialVars.map(partialVar => ({
      ...partialVar,
      lineIdx,
      id: textbox.name,
      type: "text",
    })) as Array<TVariable>;

    return [...acc, ...variables];
  }, []);
};

export const extractImageVariables = (
  obj: IExtendedFabricObject,
): Array<TVariable> => {
  const { customData, customType, name } = obj;
  const variable = customType === "car_cut" ? "car_cut" : customData.layerName;
  return [
    {
      id: name ?? uuid.v4(),
      variable,
      raw: variable,
      type: "image",
      startIdx: 0,
      lineIdx: 0,
    },
  ];
};

export const canvasLogoToLogoVar = (logoObj: TExtendedLogo) => {
  const { customData } = logoObj;
  let logoType: LogoType;
  const artboardName = capitalize(customData.logoDropZoneType) as ImageType;
  switch (customData.logoEventType) {
    case "SALES_EVENT_LOGO":
      logoType = "Sales Event";
      break;
    case "OEM_LOGO":
    case "BRAND_LOGO":
      logoType = "Brand";
      break;
    case "STORE_LOGO":
    case "ACCOUNT_LOGO":
      logoType = "Account";
      break;
  }
  return {
    logoType,
    artboardName,
  };
};

export const extractLogoVariables = (obj: TExtendedLogo): Array<TVariable> => {
  const { customData, name } = obj;
  const variable = customData.layerName;
  return [
    {
      id: name ?? uuid.v4(),
      variable,
      raw: variable,
      type: "logo",
      startIdx: 0,
      lineIdx: 0,
      logoData: canvasLogoToLogoVar(obj),
    },
  ];
};

export const extractVariables = (
  obj: fabric.Textbox | fabric.Image | fabric.Rect | fabric.Group,
): Array<TVariable> => {
  if (isTextbox(obj)) return extractTextVariables(obj);
  if (isImageRect(obj) || isCarCut(obj)) return extractImageVariables(obj);
  if (isLogo(obj)) return extractLogoVariables(obj);
  return [];
};

export const replaceVariablesWithinTextLines = (
  originalTextLines: string[],
  groupedMappings: Record<number, TValueMapping[]>,
  row: any,
) => {
  let replacedTextLines = originalTextLines.reduce<string[]>(
    (prev, line, lineIdx) => {
      const mappings = groupedMappings[lineIdx];

      if (!mappings) return [...prev, line];

      let text = line;

      for (const { variable, value } of mappings) {
        const { raw } = variable;
        const val = getValue(value, row);

        if (val != null) text = text.replace(raw, val as string);
      }

      return [...prev, text];
    },
    [],
  );

  // For edge cases
  Object.keys(groupedMappings).forEach((lineIdx: string) => {
    const mappings = groupedMappings[Number(lineIdx)];

    if (!mappings) return;

    let replacedText = replacedTextLines.join("\n");

    for (const mapping of mappings) {
      const { variable, value } = mapping;
      const { raw } = variable;
      const val = getValue(value, row);
      if (val == null || !replacedText.includes(raw)) return;
      const rawWithoutBrackets = raw.replace(/[{}]/g, "");
      const beforeTextLines = replacedTextLines;
      replacedText = replacedText.replace(
        new RegExp(`\{${rawWithoutBrackets}\}`, "g"),
        val as string,
      );
      const afterTextLines = replacedText.split("\n");
      beforeTextLines.forEach((beforeTextLine, beforeLineIdx) => {
        const afterTextLine = afterTextLines[beforeLineIdx];
        if (beforeTextLine === afterTextLine) return;
        const newMapping = {
          ...mapping,
          variable: {
            ...variable,
            startIdx: beforeTextLine.indexOf(raw),
          },
        };
        if (!groupedMappings[beforeLineIdx]) {
          groupedMappings[beforeLineIdx] = [newMapping];
          return;
        }
        groupedMappings[beforeLineIdx].push(newMapping);
      });
    }
    replacedTextLines = replacedText.split("\n");
  });

  return {
    updatedTextLines: replacedTextLines,
    groupedMappings,
  };
};

const findPreviousStyle = (idx: number, denseStyleArray: any[]) => {
  for (let i = idx; i >= 0; i--) {
    if (denseStyleArray[i] !== undefined) return denseStyleArray[i];
  }
  return { fontStyle: "normal", fontWeight: "normal" };
};

export const replaceStylesWithVariables = (
  originalTextLines: string[],
  groupedMappings: Record<number, TValueMapping[]>,
  row: any,
  styles: any,
) => {
  if (!styles) return styles;
  const updatedStyles = { ...styles };

  originalTextLines.forEach((originalTextLine, lineIdx) => {
    const mappings = groupedMappings[lineIdx];

    if (!mappings) return;

    // FabricJS Textboxes have sparse styles, that means that the styles are not defined for every character unless necessary
    // To make the styles dense, we convert them to an array with the same length as the original text line
    // and use an empty object for the characters that don't have a specific style
    // the resulting array is then used to update the styles
    const denseStyleArray = Array.from(
      { length: originalTextLine.length },
      (_, i) =>
        updatedStyles?.[lineIdx]?.[i] ? updatedStyles[lineIdx][i] : {},
    );

    mappings.forEach(({ variable, value }) => {
      const val = getValue(value, row);
      const { raw, startIdx } = variable;
      const diffLength = val ? val.length - raw.length : 0;

      if (!originalTextLine.includes(raw)) return;
      if (diffLength > 0) {
        // We use the style of the first character of the variable to fill the rest of the characters
        // This means a variable with a different style for each character is not supported
        const firstCharStyles = denseStyleArray[startIdx];

        // We insert the styles of the first character of the variable in the array, for each new character
        // For example, if the variable is "{var}" and the replacement value is "cities",
        // we insert the style of the first character of the variable 1 time, because cities has one extra character over {var}
        denseStyleArray.splice(
          startIdx,
          0,
          ...Array(diffLength).fill(firstCharStyles),
        );
      }

      if (diffLength < 0) {
        // We remove the styles of the characters that are being replaced
        denseStyleArray.splice(startIdx, -diffLength);
      }
    });

    // We use the style of the previous character if no style is defined for the current character,
    // or a default style if no style is defined for the previous character
    updatedStyles[lineIdx] = denseStyleArray.reduce((acc, style, idx) => {
      const foundStyle = style ?? findPreviousStyle(idx, denseStyleArray);
      return { ...acc, [idx]: foundStyle };
    }, {});
  });

  return updatedStyles;
};

export const getValue = (value: TValue | undefined, row: any) => {
  if (typeof value === "string") return value;

  if (isColumnValue(value)) {
    const { column, type, regexPattern } = value;
    switch (type) {
      case "regex":
        if (!regexPattern) return;

        try {
          const regex = new RegExp(regexPattern);
          const result = row?.[value.column].match(regex);
          const [extracted] = result || [];
          return extracted;
        } catch (err) {
          // the pattern must be validated when it is inserted and give feedback to the user.
        }

        return;

      case "match_to_column":
        return row?.[column] ?? "";
    }
  }
};

export const groupMappingsByLineIdx = (mappings: TValueMapping[]) => {
  const groupByLineIdx = groupBy(mappings, mapping => mapping.variable.lineIdx);

  return reduce(
    keys(groupByLineIdx),
    (prev, lineIdx) => {
      const groupedByMappings = groupByLineIdx[lineIdx];

      return {
        ...prev,
        [lineIdx]: orderBy(
          groupedByMappings,
          mapping => mapping.variable.startIdx,
          ["asc"],
        ),
      };
    },
    {} as Record<number, TValueMapping[]>,
  );
};
