import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { useDeleteAgentProfiles } from "shared/hooks/salesEnablement/useAgentProfiles";
import { salesEnablementTheme } from "theme/salesEnablement";
import { pluralize } from "utils/terms";

export const useDeleteProfilesPrompt = () => {
  const { mutate: deleteAgentProfiles } = useDeleteAgentProfiles();
  return (selectedAgentProfileIds: string[]) => {
    Modal.confirm({
      title: (
        <b>
          Are you sure you want to delete{" "}
          {selectedAgentProfileIds.length > 1 ? "these" : "this"} agent{" "}
          {pluralize("profile", selectedAgentProfileIds.length)}?
        </b>
      ),
      icon: (
        <ExclamationCircleOutlined
          style={{ color: salesEnablementTheme?.primaryColor }}
        />
      ),
      okText: "Delete",
      cancelText: "Cancel",
      okButtonProps: { type: "primary", danger: true },
      onOk: () => {
        return deleteAgentProfiles(selectedAgentProfileIds);
      },
    });
  };
};
