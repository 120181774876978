import { Spin, Typography } from "antd";
import styles from "./AccountsSection.module.scss";
import ErrorState from "./shared/ErrorState";
import { AccountItem } from "./AccountItem";
import { Account } from "shared/types/salesEnablement";

type Props = {
  isLoading: boolean;
  isError: boolean;
  navigateToAccounts: () => void;
  accounts: Account[];
};
export const AccountsSection = (props: Props) => {
  const { isLoading, isError, navigateToAccounts, accounts } = props;
  return (
    <div className={styles.container}>
      <Spin spinning={isLoading}>
        <div className={styles.content}>
          <div className={styles.section}>
            <div className={styles.headerTitle}>
              <Typography.Title level={4}>
                My Materials by Account
              </Typography.Title>
            </div>
            <div className={styles.accounts}>
              {isError && (
                <ErrorState
                  title="Sorry! We couldn’t find any Accounts."
                  description="Please refresh the page or click below to browse all Accounts."
                  action={{
                    title: "Browse Accounts",
                    onClick: navigateToAccounts,
                  }}
                />
              )}
              {!isError && (
                <div className={styles.accountList}>
                  {accounts.map(account => (
                    <AccountItem key={account.id} account={account} />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};
