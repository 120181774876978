import { TextLayerElement } from "../types";

const useElementEffects = (elements: TextLayerElement[]) => {
  const setElementProperty = (
    property: string,
    value: boolean | number | string,
  ) => {
    elements.forEach(element => {
      element.set({ [property]: value });
    });
  };

  const toggleBlur = () => {
    elements.forEach(element => {
      const newValue = !element.blurEnabled;
      setElementProperty("blurEnabled", newValue);
    });
  };

  const toggleTextStroke = () => {
    elements.forEach(element => {
      const newValue = !element.strokeWidth ? 1 : 0;
      setElementProperty("strokeWidth", newValue);
    });
  };

  const toggleBackground = () => {
    elements.forEach(element => {
      const newValue = !element.backgroundEnabled;
      setElementProperty("backgroundEnabled", newValue);
    });
  };

  const toggleShadow = () => {
    elements.forEach(element => {
      const newValue = !element.shadowEnabled;
      setElementProperty("shadowEnabled", newValue);
    });
  };

  const toggleBold = () => {
    elements.forEach(element => {
      const newValue = element.fontWeight === "bold" ? "normal" : "bold";
      setElementProperty("fontWeight", newValue);
    });
  };

  const toggleItalic = () => {
    elements.forEach(element => {
      const newValue = element.fontStyle === "italic" ? "normal" : "italic";
      setElementProperty("fontStyle", newValue);
    });
  };

  const toggleUnderline = () => {
    elements.forEach(element => {
      const newValue =
        element.textDecoration === "underline" ? "none" : "underline";
      setElementProperty("textDecoration", newValue);
    });
  };

  const toggleStrikethrough = () => {
    elements.forEach(element => {
      const newValue =
        element.textDecoration === "line-through" ? "none" : "line-through";
      setElementProperty("textDecoration", newValue);
    });
  };

  return {
    toggleBlur,
    toggleTextStroke,
    toggleBackground,
    toggleShadow,
    toggleBold,
    toggleItalic,
    toggleUnderline,
    toggleStrikethrough,
  };
};

export default useElementEffects;
