import { observer } from "mobx-react-lite";
import { HorizontalSplitRounded } from "@mui/icons-material";
import { PopoverMenu } from "../shared/PopoverMenu";
import { FigureLayerElement } from "screens/designStudio/designStudioV2/types";
import { Typography, Button, ButtonGroup, Switch, Slider } from "@mui/material";
import { StackContainer } from "../containers/StackContainer";
import { ColorPickerPopover } from "../layerEffects/ColorPickerPopover";
import { DashStrokeIcon } from "shared/icons/DashStrokeIcon";
import { useFigureStroke } from "./figureStrokeEditor/useFigureStroke";

type FigureStrokeEditorProps = {
  elements: FigureLayerElement[];
};

export const FigureStrokeEditor = observer(
  ({ elements }: FigureStrokeEditorProps) => {
    const [element] = elements;
    const {
      isStrokeEnabled,
      handleStrokeToggle,
      handleStrokeColorChange,
      handleStrokeWidthChange,
      handleCornerRadiusChange,
      handleDashStrokeChange,
    } = useFigureStroke(elements);

    return (
      <PopoverMenu id="figure-stroke" icon={<HorizontalSplitRounded />}>
        <StackContainer>
          <Switch checked={isStrokeEnabled} onChange={handleStrokeToggle} />
          <Typography variant="caption">Stroke</Typography>
        </StackContainer>
        {isStrokeEnabled && (
          <>
            <ButtonGroup sx={{ height: 34 }} fullWidth variant="outlined">
              <Button onClick={() => handleDashStrokeChange(1, 0)}>
                <DashStrokeIcon variant="one" />
              </Button>

              <Button onClick={() => handleDashStrokeChange(9, 1)}>
                <DashStrokeIcon variant="two" />
              </Button>
              <Button onClick={() => handleDashStrokeChange(3, 1)}>
                <DashStrokeIcon variant="three" />
              </Button>
              <Button onClick={() => handleDashStrokeChange(1, 1)}>
                <DashStrokeIcon variant="four" />
              </Button>
            </ButtonGroup>
            <ColorPickerPopover
              color={element?.stroke || "#000000"}
              onChange={handleStrokeColorChange}
              label="Color"
            />
            <StackContainer>
              <Typography variant="caption">Stroke Width</Typography>
            </StackContainer>
            <Slider
              value={element?.strokeWidth || 1}
              onChange={handleStrokeWidthChange}
              valueLabelDisplay="auto"
              min={1}
              max={205}
              step={1}
              valueLabelFormat={value => value.toFixed(0)}
            />
            <StackContainer>
              <Typography variant="caption">Corner Radius</Typography>
            </StackContainer>
            <Slider
              value={element?.cornerRadius || 0}
              onChange={handleCornerRadiusChange}
              valueLabelDisplay="auto"
              min={0}
              max={100}
              step={1}
              valueLabelFormat={value => value.toFixed(0)}
            />
          </>
        )}
      </PopoverMenu>
    );
  },
);
