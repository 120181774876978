import { LinkOutlined, ShareAltOutlined } from "@ant-design/icons";
import { Button, Input, Modal, message } from "antd";
import { useMemo, useState } from "react";
import { useGetAssetsListMutation } from "redux/media/media.api";
import { TooltipPerms } from "./TooltipPerms";
import { IMG_TYPES, VIDEO_TYPES, WD_CDN_PATH } from "./constants";
import { useSelected } from "./useSelected";

export const AssetsShare = () => {
  const { selectedAssets, selectedIds, allAssets } = useSelected();
  const [getAssets] = useGetAssetsListMutation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");
  const enabled = useMemo(() => {
    if (allAssets) {
      const allowedExtensions = IMG_TYPES.concat(VIDEO_TYPES);

      return selectedAssets.every(a => allowedExtensions.includes(a.filetype));
    }

    return false;
  }, [selectedAssets, allAssets]);

  const openModal = async () => {
    setLoading(true);
    const assets = await getAssets(selectedIds).unwrap();
    const arr = assets.map(a => a.hiResURLRaw.replace(WD_CDN_PATH, ""));
    const str = btoa(arr.join());

    setUrl(`${location.origin}/media/shared?data=${str}`);
    setLoading(false);
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    setUrl("");
  };

  const copyLink = () => {
    navigator.clipboard.writeText(url);
    message.info("URL copied to clipboard");
  };

  return (
    <>
      <TooltipPerms action="Share Images and Videos" allowed enabled={!!url}>
        <Button
          loading={loading}
          shape="circle"
          icon={<ShareAltOutlined />}
          onClick={openModal}
          disabled={!enabled}
        />
      </TooltipPerms>
      <Modal
        title="Share Link URL"
        destroyOnClose
        visible={open}
        onCancel={closeModal}
        footer={
          <Button
            type="link"
            size="small"
            icon={<LinkOutlined />}
            onClick={copyLink}
          >
            Copy Link
          </Button>
        }
      >
        <p>
          Share this URL for another viewer to see a preview of these assets.
        </p>
        <Input type="text" value={url} />
      </Modal>
    </>
  );
};
