import { useEffect, useMemo } from "react";
import { useInfiniteQuery, useMutation } from "react-query";
import API from "services";
import { useDataListURLData } from "shared/components/dataListURL/useDataListURLData";
import {
  BrochureRequest,
  BrochureTriggerResponse,
} from "shared/types/brochureManagement";

const brochureRequestsQueryKey = "brochure-requests";

const getBrochureRequestsPaginated =
  () =>
  async ({ pageParam }: { pageParam?: string }) => {
    const { result, error } =
      await API.privServices.brochure.getBrochureRequests({
        paginationKey: pageParam,
      });

    if (error) {
      throw Error(error.message);
    }

    return result;
  };

export const useFetchBrochureRequests = () => {
  return useInfiniteQuery({
    queryKey: [brochureRequestsQueryKey],
    refetchInterval: 15000,
    queryFn: getBrochureRequestsPaginated(),
    getNextPageParam: lastPage => {
      return lastPage?.paginationKey;
    },
  });
};

export const useBrochureRequests = () => {
  const { data, hasNextPage, fetchNextPage, isFetched, isFetchingNextPage } =
    useFetchBrochureRequests();

  useEffect(() => {
    if (!hasNextPage || isFetchingNextPage) return;
    fetchNextPage();
  }, [hasNextPage, fetchNextPage, isFetchingNextPage]);

  const brochureRequests = useMemo(
    () => data?.pages.flatMap(page => page?.brochureRequests ?? []) ?? [],
    [data?.pages],
  );

  return useDataListURLData<keyof BrochureRequest, BrochureRequest>({
    isError: false,
    isLoading: !isFetched,
    data: brochureRequests,
  });
};

export const inputForLog = (input: Record<string, unknown>) => {
  const { name, firstName, lastName, ...rest } = input;
  return {
    Name: name,
    FirstName: firstName,
    LastName: lastName,
    ...rest,
  };
};

export const useTriggerBrochure = () => {
  return useMutation<BrochureTriggerResponse, Error, Record<string, string>>({
    mutationFn: (request: Record<string, unknown>) => {
      const req = "name" in request ? inputForLog(request) : request;
      return API.privServices.brochure.triggerBrochure(req);
    },
  });
};
