import { TextLayerElement } from "../../types";

export const insertTextAtPosition = (
  element: TextLayerElement,
  text: string,
  position: number,
) => {
  element.set({
    text: element.text.slice(0, position) + text + element.text.slice(position),
  });
  element.toggleEditMode();

  // This is a hack to run the code on the next tick, which ensures that the
  // textarea is rendered and we can focus it as well as move the cursor.
  // We will be able to remove this once Polotno makes some of its internals available on the SDK.
  setTimeout(() => {
    const textarea = getPolotnoTextarea();
    const cursorPosition = position + text.length;
    textarea?.setSelectionRange(cursorPosition, cursorPosition);
  }, 0);
};

// We're only interacting with the DOM here because Polotno doesn't expose a reference to the textarea used for editing text elements.
// We will be able to remove this once Polotno makes some of its internals available on the SDK.
export const getPolotnoTextarea = () =>
  document.querySelector<HTMLTextAreaElement>("textarea.polotno-input");
