import { ReactNode } from "react";

type Props = {
  isCustomIntegration: boolean;
  customIntegration: ReactNode;
  children: ReactNode;
};

export const RenderAssetInstance = ({
  isCustomIntegration,
  children,
  customIntegration,
}: Props) => {
  if (isCustomIntegration) {
    return <>{customIntegration}</>;
  } else {
    return <>{children}</>;
  }
};
