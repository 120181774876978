import { Spin } from "antd";
import { ITableColumn } from "shared/components/dataList";
import {
  SyncOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import NameTag from "screens/adLibrary/shared/components/NameTag";
import { IAccount } from "shared/types/accountManagement";
import moment from "moment";
import styles from "../../../GooglePerformanceMax.module.scss";
import {
  GoogleAdCampaignInternal,
  PmaxUploadStatus,
} from "shared/types/adLibrary";

const CampaignStatusIconCell = ({ status }: { status?: PmaxUploadStatus }) => {
  if (status === "failed")
    return <CloseCircleFilled className={styles.errorIcon} />;
  if (status === "success")
    return <CheckCircleFilled className={styles.successIcon} />;
  if (status === "processing")
    return <Spin size="small" indicator={<SyncOutlined spin />} />;

  return null;
};

export const getColumns = (
  store: IAccount | undefined,
  status?: "processing" | "failed" | "success",
): ITableColumn<GoogleAdCampaignInternal>[] => {
  return [
    {
      key: "status",
      title: "",
      width: 30,
      render: () => <CampaignStatusIconCell status={status} />,
    },
    {
      key: "name",
      title: "Campaign Name",
      dataIndex: ["name"],
      width: 300,
    },
    {
      key: "store",
      title: "Store",
      render: () =>
        store ? (
          <NameTag
            name={store.dealer_name}
            id={`CID: ${store.details.google?.adAccountCID}`}
          />
        ) : (
          "-"
        ),
    },
    {
      key: "startDate",
      title: "Start Date",
      dataIndex: ["startDate"],
      render: date => moment(date).format("MM/DD/YYYY"),
    },
    {
      key: "endDate",
      title: "End Date",
      dataIndex: ["endDate"],
      render: date => (date ? moment(date).format("MM/DD/YYYY") : "-"),
    },
    {
      key: "budget",
      title: "Budget",
      dataIndex: ["dailyBudget"],
      render: budget => `$${budget}/day`,
    },
  ];
};
