/* eslint-disable react/display-name */

import { memo, ReactText, useState } from "react";

import { IAd } from "shared/types/adLibrary";

import useSelectedColumns from "shared/hooks/userPreferences/useSelectedColumns";

import "./AdImportTableContainer.scss";
import { IAdValidations } from "screens/adLibrary/adImport/adValidationUtils";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { AdTableColumn, getColumns } from "./adImportTableContainer/columns";
import { IndustryType } from "shared/types/shared";
import { getEnvVar } from "utils/helpers";
import ColumnsFilter from "screens/adLibrary/shared/components/ColumnsFilter";
import { AdPreviewModal } from "screens/adLibrary/shared/AdPreviewModal";
import ExpandIcon from "shared/components/ExpandIcon";
import { isChildRecord } from "./adImportTableContainer/adImportTable.utils";
import VirtualTable from "shared/components/VirtualTable";
import { useAdImportTable } from "../../adImportDrawer/adImportDrawerTable/useAdImportTable";
import { useAdImportTableComponents } from "../../adImportDrawer/adImportDrawerTable/useAdImportTableComponents";

const filterColumns = (
  columns: AdTableColumn[],
  selectedColumns: string[],
  isColumnSelected: (columnName: string) => boolean,
) => {
  return columns
    .filter(c => isColumnSelected(c.name))
    .sort(
      (a, b) =>
        selectedColumns.indexOf(a.name) - selectedColumns.indexOf(b.name),
    );
};

interface Props {
  ads: IAd[];
  setAd: (adId: string, ad: IAd) => void;
  setAds: (ads: IAd[]) => void;
  displayColumn: boolean;
  defaultColumns?: string[];
  setDisplayColumn: (displayColumn: boolean) => void;
  selectedRowKeys: string[];
  setSelectedRowKeys: (selectedRowKeys: any[]) => void;
  stickyColumns?: string[];
  validationErrors?: IAdValidations[];
}

const AdImportTableContainer = ({
  ads,
  setAd,
  setAds,
  displayColumn,
  setDisplayColumn,
  selectedRowKeys,
  setSelectedRowKeys,
  stickyColumns,
  validationErrors,
}: Props) => {
  const { expandedRowKeys, onToggleExpand, onExpand, isDisabled, adRecords } =
    useAdImportTable({ ads });

  const defaultColumns = [
    "Name",
    "In Feed Media",
    "Ad Format",
    "Status",
    "Primary Text",
    "Headline",
    "Description",
    "CTA Button",
  ];
  const { isColumnSelected, selectedColumns, setSelectedColumns } =
    useSelectedColumns(defaultColumns);

  const industry = getEnvVar("INDUSTRY");
  const [clientType, setClientType] = useState<IndustryType>(
    (industry as IndustryType) || "auto",
  );

  const [previewAd, setPreviewAd] = useState<IAd | null>(null);

  const { windowInnerHeight } = useWindowSize();

  const columns = getColumns({
    ads,
    setAd,
    setAds,
    stickyColumns,
    validationErrors,
    onToggleExpand,
    expandedRowKeys,
  });

  const filteredColumns = filterColumns(
    columns,
    selectedColumns,
    isColumnSelected,
  );

  const { components, editableColumns } = useAdImportTableComponents({
    columns: filteredColumns,
    setAd,
  });

  return (
    <>
      <VirtualTable
        data-cy="ad-import-table-container"
        className="ad-import-table-container"
        scroll={{ y: windowInnerHeight - 245 }}
        rowKey={record => record.id}
        pagination={false}
        size="small"
        rowSelection={{
          selectedRowKeys,
          onChange: (selectedRowKeys: ReactText[]) => {
            setSelectedRowKeys(selectedRowKeys);
          },
          getCheckboxProps: adRecord => ({
            style: isChildRecord(adRecord) ? { display: "none" } : {},
            disabled: isDisabled(adRecord, validationErrors),
          }),
        }}
        columns={editableColumns}
        components={components}
        dataSource={adRecords}
        expandable={{
          expandedRowKeys,
          onExpand,
          expandIcon: props => (
            <ExpandIcon
              {...props}
              tooltip={
                props.expanded
                  ? "Click to collapse Carousel"
                  : "Click to expand Carousel"
              }
              style={{
                height: 30,
                display: "flex",
                alignItems: "center",
              }}
            />
          ),
          indentSize: 60,
        }}
      />
      <ColumnsFilter
        displayColumn={displayColumn}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        setDisplayColumn={setDisplayColumn}
        columnNames={columns.map(column => column.name)}
        clientType={clientType}
        setClientType={setClientType}
      />
      {previewAd && <AdPreviewModal ad={previewAd} setAd={setPreviewAd} />}
    </>
  );
};

export default memo(AdImportTableContainer);
