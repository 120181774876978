import { message } from "antd";
import { useCallback, useEffect } from "react";
import { useSaveTemplateDraft } from "screens/designStudio/designStudioV2/hooks/useSaveTemplateDraft";
import {
  getBase64Thumbnail,
  toJSON,
} from "../../../editorDrawerContext/helpers.store";
import { StoreType } from "polotno/model/store";
import { DesignStudioTableTemplate } from "screens/designStudio/context/DesignStudioDataProvider";
import { isTemplate } from "screens/designStudio/helpers";

export const useSaveStore = (
  store: StoreType | null,
  item: DesignStudioTableTemplate | null,
  enableAutoSave: boolean = false,
  autoSaveInterval: number = 60000,
) => {
  const {
    mutate: saveDraftMutation,
    isLoading: isSaving,
    variables,
  } = useSaveTemplateDraft();
  const isAutoSaving = variables?.autoSave && isSaving;

  const save = useCallback(
    async (isAutoSaving = false) => {
      if (!store || !item || !isTemplate(item)) return;

      const { asset: template } = item;
      const base64Thumbnail = await getBase64Thumbnail(store);
      saveDraftMutation(
        {
          template,
          json: toJSON(store),
          base64Thumbnail,
          autoSave: isAutoSaving,
        },
        {
          onError: () => {
            message.error("Failed to save template draft");
          },
        },
      );
    },
    [saveDraftMutation, item, store],
  );

  useEffect(() => {
    if (!enableAutoSave) return undefined;

    const autoSaveTimer = setInterval(() => {
      save(true); // Pass true to indicate it's an auto-save
    }, autoSaveInterval);

    return () => clearInterval(autoSaveTimer);
  }, [save, enableAutoSave, autoSaveInterval]);

  return {
    save,
    isSaving,
    isAutoSaving,
  };
};
