import { TreeNav } from "../TreeNav";
import { useAppSelector } from "../redux";
import { useQPSearch } from "../useQPSearch";
import { ListMode } from "./ListMode";
import { SearchMode } from "./SearchMode";

import styles from "./MediaTable.module.scss";

export const MediaTable = () => {
  const { searchState } = useQPSearch();
  const treeNavOpen = useAppSelector(({ media }) => media.treeNavOpen);

  return (
    <div className={styles.host} data-open={treeNavOpen}>
      <TreeNav />
      <div>{searchState ? <SearchMode /> : <ListMode />}</div>
    </div>
  );
};
