import { LoadCampaignRecord } from "../../types";
import DataListURLTable from "shared/components/dataListURL/DataListURLTable";
import { fieldKeys } from "./fields";
import { useLoadCampaignsContext } from "../LoadProvider";
import { useColumns } from "./columns";
import ExpandIcon from "shared/components/ExpandIcon";
import { isEmpty } from "lodash";
import { CSSProperties } from "react";
import CreativesDrawer from "./CreativesDrawer";
import { useFilteredDataSource } from "../hooks/useFilteredDataSource";

const Table = () => {
  const { data: dataSource } = useFilteredDataSource();
  const columns = useColumns();
  const { selectedLineItem } = useLoadCampaignsContext();

  const getExpandIconStyles = (record: LoadCampaignRecord): CSSProperties => {
    if (record.type === "campaign" && !isEmpty(record.children)) {
      return { marginTop: 3, float: "left" };
    }

    return { visibility: "hidden" };
  };

  const getCheckboxProps = (
    record: LoadCampaignRecord,
  ): { style: CSSProperties } => {
    if (record.type === "lineItem") {
      return {
        style: {
          visibility: "hidden",
        },
      };
    }

    return { style: { marginRight: 22 } };
  };

  return (
    <>
      <div style={{ padding: "1em 0" }}>
        <DataListURLTable<keyof LoadCampaignRecord, LoadCampaignRecord>
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          size="small"
          type="normal"
          fieldKeys={fieldKeys}
          expandable={{
            expandIconColumnIndex: 0,
            expandIcon: props => (
              <ExpandIcon
                style={getExpandIconStyles(props.record)}
                {...props}
              />
            ),
          }}
          rowSelection={{
            getCheckboxProps: getCheckboxProps,
            columnWidth: 120,
          }}
        />
      </div>
      {selectedLineItem && <CreativesDrawer />}
    </>
  );
};

export default Table;
