import { Drawer, Space, Button } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES, lineItemFormId } from "../constants";
import { BeeswaxCampaignURLParams } from "../types";
import LineItemForm from "./LineItemForm";
import { useBeeswaxCampaignsContext } from "../BeeswaxCampaignsProvider";
import { LineItemDrawerContext } from "./LineItemFormProvider";

const LineItemDrawer = () => {
  const navigate = useNavigate();
  const { campaignId } = useParams<BeeswaxCampaignURLParams>();
  const { resetSelectedLineItem } = useBeeswaxCampaignsContext();

  const closeDrawer = () => {
    resetSelectedLineItem();
    navigate(campaignId ? ROUTES.update(campaignId) : ROUTES.create);
  };

  return (
    <Drawer
      title={"New Line Item"}
      destroyOnClose
      visible
      closable={false}
      maskClosable={false}
      width={"30%"}
      footerStyle={{ textAlign: "right" }}
      footer={
        <Space>
          <Button onClick={closeDrawer}>Cancel</Button>
          <Button type="primary" htmlType="submit" form={lineItemFormId}>
            Save
          </Button>
        </Space>
      }
    >
      <LineItemForm />
    </Drawer>
  );
};

const LineItemDrawerWrapper = () => {
  return (
    <LineItemDrawerContext>
      <LineItemDrawer />
    </LineItemDrawerContext>
  );
};

export default LineItemDrawerWrapper;
