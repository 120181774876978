import { AgentProfile } from "screens/adLibrary/marketingMaterialDrawer/hooks/agentManagement";
import { IDataListURLFields } from "shared/components/dataListURL/types";
import { StringParam } from "use-query-params";
import { joinArray } from "utils/helpers.array";
import { onFilterBy, compareStringBy } from "utils/helpers.table";

export type AgentProfileKeys =
  | keyof Omit<AgentProfile, "id" | "firstName" | "lastName">
  | "name";

export const fields: IDataListURLFields<AgentProfileKeys, AgentProfile> = {
  name: {
    filterFn: onFilterBy(
      ({ firstName, lastName }) => joinArray([firstName, lastName]),
      {
        matchMode: "includes",
        caseInsensitive: true,
      },
    ),
    sorterFn: compareStringBy(({ firstName, lastName }) =>
      joinArray([firstName, lastName]),
    ),
    queryParamConfigFilter: StringParam,
  },
  email: {
    filterFn: onFilterBy("email", {
      matchMode: "includes",
      caseInsensitive: true,
    }),
    sorterFn: compareStringBy("email"),
    queryParamConfigFilter: StringParam,
  },
  phoneNumber: {
    filterFn: onFilterBy("phoneNumber", {
      matchMode: "includes",
      caseInsensitive: true,
    }),
    sorterFn: compareStringBy("phoneNumber"),
    queryParamConfigFilter: StringParam,
  },
  createdAt: {
    filterFn: onFilterBy("createdAt"),
    sorterFn: compareStringBy("createdAt"),
    queryParamConfigFilter: StringParam,
  },
  updatedAt: {
    filterFn: onFilterBy("updatedAt"),
    sorterFn: compareStringBy("updatedAt"),
    queryParamConfigFilter: StringParam,
  },
};

export const fieldKeys = Object.keys(fields) as Array<keyof typeof fields>;
