import { IDataListURLFields } from "shared/components/dataListURL/types";
import { DelimitedArrayParam, StringParam } from "use-query-params";
import { compareStringBy, onFilterBy } from "utils/helpers.table";
import { Language, ProductTable } from "shared/types/salesEnablement";
import { Languages } from "utils/helpers.salesEnablement";

export const fields: IDataListURLFields<keyof ProductTable, ProductTable> = {
  name: {
    filterFn: onFilterBy("name", {
      matchMode: "includes",
      caseInsensitive: true,
    }),
    sorterFn: compareStringBy("name"),
    queryParamConfigFilter: StringParam,
  },
  productType: {
    filterFn: onFilterBy("productType", {
      matchMode: "includes",
      caseInsensitive: true,
    }),
    sorterFn: compareStringBy("productType"),
    queryParamConfigFilter: DelimitedArrayParam,
  },
  language: {
    filterFn: onFilterBy(product => product.language?.join(","), {
      matchMode: "includes",
    }),
    queryParamConfigFilter: StringParam,
    display: language => Languages[language as Language].label,
  },
  description: {
    filterFn: onFilterBy("description", {
      matchMode: "includes",
      caseInsensitive: true,
    }),
    sorterFn: compareStringBy("description"),
    queryParamConfigFilter: StringParam,
  },
};

export const fieldKeys = Object.keys(fields) as Array<keyof typeof fields>;
