import { MinusOutlined } from "@ant-design/icons";
import { useCallback, useState } from "react";
import styles from "./Fill.module.scss";
import Color from "./shared/Color";
import Opacity from "./fill/Opacity";
import Control from "./shared/Control";

type Props = {
  object?: fabric.Rect | fabric.Circle;
  onChange: (key: "fill" | "opacity", value?: string | number) => void;
};

const EMPTY_FILL = "rgba(0,0,0,0)";

const Fill = (props: Props) => {
  const { onChange, object } = props;
  const [openControl, setOpenControl] = useState<boolean>(
    object && (object.fill || object.opacity) ? true : false,
  );

  const onClick = useCallback((e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();

    setOpenControl(true);
  }, []);

  const onValueChange = useCallback(
    (key: "fill" | "opacity") => (value: number | string) => {
      onChange(key, value);
    },
    [onChange],
  );

  const onDeleteControl = useCallback(
    async (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault();

      setOpenControl(false);

      // on multiple state change right after each other wont update the object.
      // here, giving little bit of delay.
      onChange("fill", EMPTY_FILL);
    },
    [onChange, setOpenControl],
  );

  const hasFill = object?.fill !== EMPTY_FILL;

  return (
    <Control
      openControl={openControl || hasFill}
      onAddControl={onClick}
      title="Fill"
      controlContent={
        <div className={styles.FillControl}>
          <Color
            fill={(props.object?.fill as string) ?? "#FFFFFF"}
            onColorSelected={onValueChange("fill")}
          />
          <Opacity
            opacity={props.object?.opacity ?? 1}
            onOpacityChange={onValueChange("opacity")}
          />
          <MinusOutlined
            className={styles.DeleteIcon}
            onClick={onDeleteControl}
          />
        </div>
      }
    />
  );
};

export default Fill;
