/* eslint-disable react-hooks/exhaustive-deps */
import {
  Badge,
  Button,
  Checkbox,
  Collapse,
  Divider,
  message,
  notification,
  Spin,
  Tooltip,
} from "antd";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";
import moment from "moment";
import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import actions from "redux/rootActions";
import {
  AssetInstanceRecord,
  ExportDestination,
  ExportImageType,
  ExportType,
  ExportVideoType,
  FeedOffer,
  FeedTab,
  IAssetBuilderState,
  IAssetInstance,
  ICoopIntegrationData,
  IExportedAssetStatus,
  InstanceCheckboxDict,
  IOffer,
  IOfferWithDisclosure,
  IPDFCustomDisclosure,
  IPDFDisclosureParams,
  ISavedOrderState,
  ISelectedOffer,
  IVideoParams,
  IWorkfrontProofData,
  IZipExport,
  OfferData,
  ReviewExportType,
  VehicleConditions,
} from "shared/types/assetBuilder";
import { IAccount, IAccountRecord } from "shared/types/accountManagement";
import { IDimension, IResponse } from "shared/types/designStudio";
import {
  IDisclosure,
  IStateDisclosureElement,
  IStateDisclosureRecord,
  IStateExceptionElement,
  IStateExceptionRecord,
} from "shared/types/legalLingo";
import {
  INewOrder,
  INewOrderRecord,
  StatusOptions,
} from "shared/types/newOrders";
import * as textHelpers from "utils/fabric/helpers.text";
import {
  dealerToDealerRecordData,
  defaultDealerData,
  delay,
  formatDateValue,
  getProofData,
  isEnvVarEquals,
  isFeatureEnabled,
  isRawEnvVarEquals,
  parseSelectedProofs,
  timezoneOffset,
} from "utils/helpers";
import {
  assetInstancesToAssetBuild,
  getAssetCount,
  getAssetStrCount,
  removePurchasePlaceholder,
  returnAssetInstanceCheckboxKeys,
  returnAssetInstanceVins,
  returnAssetName,
  returnInitialAssetsToExport,
  returnUploadMediaBatches,
} from "utils/helpers.asset";
import {
  getFontNames,
  replaceTextTokens,
  returnDisclosureNamesToUse,
} from "utils/helpers.fabric";
import * as offerHelpers from "utils/helpers.offer";
import { coopInstructions } from "../../../shared/constants/assetBuilder";

import TemplateRenderProvider from "shared/components/contextAPI/shared/RenderTemplate";
import { useRenderTemplate } from "shared/components/RenderTemplateProvider";
import TabContainer from "shared/components/TabContainer";
import TemplatePreviewMemo from "shared/components/TemplatePreviewMemo";
import { fullStateOptions } from "shared/constants/dataManagement";
import { getColorFromStatus } from "../utils";
import CoopSubmissionModal from "./review/CoopSubmissionModal";
import ReviewExportModal from "./review/ReviewExportModal";
import ReviewExportResultModal from "./review/ReviewExportResultModal";

import { PauseCircleOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { fabric } from "fabric";
import { Canvas } from "fabric/fabric-impl";
import API from "services";
import BadgeCount from "shared/components/BadgeCount";
import useFetchRenderTemplate from "shared/hooks/useFetchRenderTemplate";
import { AssetExportQueryParams } from "shared/types/assetExport";
import { IConfigurationState } from "shared/types/configuration";
import { OfferType } from "shared/types/shared";
import { JobStatusString } from "utils/aws/lambda";
import { getContainsVideo } from "utils/helpers.video";
import { useLocation } from "react-router-dom";
import { AppState } from "redux/store";
import { RenderAssetInstance } from "./build/buildAssetList/RenderAssetInstance";

import "./Review.scss";

interface IReviewProps {
  enableLauncher: boolean;
  enableWorkfront: boolean;
  selectedOffers: ISelectedOffer[];
  order: INewOrder | null;
  showCoopModal: boolean;
  showExportDrawer: boolean;
  forceRenderImages: boolean;
  assetInstances: AssetInstanceRecord;
  disableExport: IAssetBuilderState["disableExport"];
  errorMessage: IAssetBuilderState["errorMessage"];
  generalMessage: IAssetBuilderState["generalMessage"];
  proofHQTemplates: Array<{ templateName: string; templateID: string }>;
  wfFolderList: Array<{ name: string; id: string }>;
  processingExport: boolean;
  currentSelectedOrder: INewOrderRecord;
  offersWithDisclosures: IOfferWithDisclosure[];
  s3Url: string;
  pdfUrl: string;
  feedPDFUrl: string;
  config?: IConfigurationState["config"];
  selectedExportedAssets: InstanceCheckboxDict;

  orderFeedTab?: FeedTab | null;
  willFireBatchUpload?: boolean;
  redirect?: IAssetBuilderState["redirect"];
  currentUser: string;
  userEmail: string;
  executionId?: string;
  pdfJobStatus: JobStatusString;
  exportedAssets?: IExportedAssetStatus[];
  exportAssetsUploaded?: number;
  isExportCompleted?: boolean;
  wfProofArr?: IWorkfrontProofData[]; // change this
  imageExports: IExportedAssetStatus[];
  processingImageExport: boolean;
}

interface IReviewHandlers {
  feedTab?: FeedTab;
  offers: ISelectedOffer[];
  toggleCoopModal: (open: boolean) => void;
  toggleExportDrawer: (open: boolean) => void;
  toggleForceRenderImages: (force: boolean) => void;
  pushToProof: (
    canvas: string[],
    templateAndDimensions: string[],
    wfProject: string,
    wfID: string,
    templateID: string,
    subject: string,
    proofMessage: string,
    folderID: string,
    parentFileToken: string,
    documentID: string,
    updatedOrder: INewOrder,
    feedData: IOfferWithDisclosure[],
    orderID: string,
    userName: string,
    projectName: string,
    includeAssets: boolean,
    includePDF: boolean,
    includeCSV: boolean,
    currentDate: string,
    proofName: string,
    selectedProofArr: IWorkfrontProofData | null,
    selectedProofKey: string | null,
    customInstanceDisclosures?: IPDFCustomDisclosure[][],
  ) => void;
  updateNewOrder: (updateNewOrder: Partial<INewOrderRecord>) => void;
  generateCanvasZipUrl: (
    images: string[],
    templateAndDimensionsArr: string[],
    wfID: string,
    store233: string,
    exportingVideo: boolean,
  ) => void;
  startVideoHtmlExport: (requestBody: IZipExport[], dest?: string) => void;
  fetchExportZipUrl: (
    exportedAssets: IExportedAssetStatus[],
    ignoreDownload: boolean,
  ) => void;
  fetchExportState: (exportedAssets: IExportedAssetStatus[]) => void;
  feedDataToCSV: (feedData: IOfferWithDisclosure[], orderID: string) => void;
  getWorkfrontFolders: (wfID: string, wfProject: string) => void;
  setOfferWithDisclosure: (offerData: IOfferWithDisclosure) => void;
  assetInstanceCounter: () => void;
  resetAssetInstanceCounter: () => void;
  getProofTemplates: () => void;
  setAssetInstanceComparator: (assetInstance: AssetInstanceRecord) => void;
  fetchOrderState: () => void;
  updateExportedAssetsArr: (
    assetNames: Record<string, Record<string, string[]>>,
  ) => void;
  generatePDF: (
    images: string[],
    templateAndDimensionsArr: string[],
    feedData: IOfferWithDisclosure[],
    userName: string,
    projectName: string,
    currentDate: string,
    customInstanceDisclosures?: IPDFCustomDisclosure[][],
  ) => void;
  feedDataToPDF: (
    feedData: IOfferWithDisclosure[],
    userName: string,
    projectName: string,
  ) => void;
  updateDealer: (inputDealer: IAccountRecord) => void;

  coopSubmission: (
    images: string[],
    templateAndDimensionsArr: string[],
    feedData: IOfferWithDisclosure[],
    userName: string,
    projectName: string,
    coopData: ICoopIntegrationData,
    order: INewOrder,
    currentDate: string,
  ) => void;

  proofExportData: (orderId: string) => void;

  getDealer: (dealerName: string) => void;

  toggleWillBatchUpload?: (value: boolean) => void;

  generateImagesForLauncherPage: (
    images: string[],
    templateAndDimensionsArr: string[],
  ) => void;

  enableExportButton: () => void;
  disableExportButton: () => void;
  resetPdfUrl: () => void;
}

message.config({ maxCount: 1 });

const Review: FC<IReviewProps & IReviewHandlers> = props => {
  const { search } = useLocation();
  const assetExportQueryObj = Object.fromEntries(
    new URLSearchParams(search),
  ) as AssetExportQueryParams;

  const renderTemplateContext = useRenderTemplate();
  const assetTypes: string[] = Object.keys(props.assetInstances);

  const [templateID, setTemplateID] = useState<string>("");
  const [folderID, setFolderID] = useState<string>("");
  // pass these to the export modal then pass to the export summary modal
  const [templateName, setTemplateName] = useState<string>("");
  const [folderName, setFolderName] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [proofMessage, setMessage] = useState<string>("");
  const [useVersioning, setUseVersioning] = useState<boolean>(false);
  const [processingUpload, setProcessingUpload] = useState<boolean>(false);
  const [processingZip, setProcessingZip] = useState<boolean>(false);
  const [processingPDF, setProcessingPDF] = useState<boolean>(false);
  const [processingFeedPDF, setProcessingFeedPDF] = useState<boolean>(false);
  const [renderUploadIcon, setRenderUploadIcon] = useState<boolean>(false);
  const [currentCoopToken, setCurrentCoopToken] = useState<string>("");
  const [includeAssets, setIncludeAssets] = useState<boolean>(true);
  const [includePDF, setIncludePDF] = useState<boolean>(false);
  const [includeCSV, setIncludeCSV] = useState<boolean>(false);
  const [assetSizeState, setAssetSizeState] = useState<number>(0);
  const [exportAssetSizeState, setExportAssetSizeState] = useState<number>(0);
  const [containsVideo, setContainsVideo] = useState<boolean>(false);
  // set to false and empty arr after testing
  const [showResultModal, toggleResultModal] = useState<boolean>(false);
  const [exportTypes, setExportTypes] = useState<Array<ReviewExportType>>([]);
  const [selectedAssets, setSelectedAssets] = useState<number>(0);
  const [currentDealer, setCurrentDealer] = useState<IAccount>({
    ...defaultDealerData(),
  });
  const [imageOption, setImageOption] = useState<ExportImageType | undefined>(
    "jpeg",
  );

  const [proofName, setProofName] = useState<string>("");

  const [selectedProofToUpdate, setSelectedProofToUpdate] =
    useState<string>("{}");
  const [coopToSubmit, setCoopToSubmit] = useState<ICoopIntegrationData>({
    dealerName: "",
    dealerOEM: "",
    primaryCategory: "internet",
    subCategory: "Social", // category will always be Internet so we will set that in the backend
    dealerSiteOnly: "Yes",
    publication: "Ladtech", // will be set to something generic like Ladtech for now until decided later...
    startDate: "",
    endDate: "",
    resubmission: false,
    referenceCode: "",
    ccEmails: props.userEmail,
    phoneNumber: "",
    coopSite: "",
    mkcOption: "No",
    seasonOption: "290",
  });

  const [namesOfAssetsPlayingVideo, setNamesOfAssetsPlayingVideo] =
    useState<InstanceCheckboxDict>({});

  const [exportWFImageType, setExportWFImageType] =
    useState<ExportImageType>("jpeg");
  const [exportImageType, setExportImageType] = useState<
    ExportImageType | "none"
  >("jpeg");
  const [exportWFVideoType, setExportWFVideoType] =
    useState<ExportVideoType>("mp4");
  const [exportVideoType, setExportVideoType] =
    useState<ExportVideoType>("mp4");

  const { services } = props.config || {};

  const coopSupported = !coopInstructions.find(store =>
    props.order?.dealer_oem?.includes(store.oem),
  );

  const [isHtmlExport, setIsHtmlExport] = useState(false);
  const [isVideoExport, setIsVideoExport] = useState(false);
  const [ignoreDownload, setIgnoreDownload] = useState(false);

  // This below state will be used as indicator of which type is being currently processed
  const [beingProcessedTypes, setBeingProcesseedTypes] = useState<
    ReviewExportType[]
  >([]);

  const { offers } = props;
  const defaultDisclosure: IDisclosure = {
    id: 0,
    name: "",
    vin: true,
    condition: [],
    location: [],
    oem: [],
    store: [],
    disclosures: [],
  };

  const client = process.env.REACT_APP_AV2_CLIENT;
  const legalLingoV2Enabled = isFeatureEnabled("ENABLE_LEGAL_LINGO_V2", false);

  const { feedId } = props?.orderFeedTab || props.feedTab || {};
  const dealerOem = props.order?.dealer_oem ?? "";
  const filterBy: VehicleConditions = "New";

  useEffect(() => {
    if (!showResultModal) {
      setSelectedProofToUpdate("{}");
    }
  }, [showResultModal]);
  // disclosure/exception code extracted from rendertemplate (but doesnt need the template/canvas)
  // gets the dealer's state, disclosures and exceptions and sets it to the appropriate offer type in the csv data
  const getExceptions = async (oem: string, state: string) => {
    const exceptionUrl = `${
      services?.legal.getStateExceptionsUrl
    }?oem=${oem}&state=${state.toLowerCase()}`;
    const exceptionRequest = new Request(exceptionUrl, {
      method: "GET",
      cache: "no-cache",
    });

    const { result: exceptionResult, error: exceptionError } = await API.send<
      IResponse<IStateExceptionRecord[]>
    >(exceptionRequest);
    if (exceptionError && props.willFireBatchUpload === undefined) {
      message.warning("Exception data could not be found.");
    }
    const { stateExceptions } = exceptionResult || {};
    const [exceptionObj] = stateExceptions || [];

    const { exceptions } = exceptionObj || { exceptions: [] };

    return exceptions;
  };

  const getDisclosureData = async () => {
    try {
      const dealerUrl = `${
        services?.getDealerUrl
      }?dealerName=${encodeURIComponent(props.order?.dealer_name || "")}`;
      const dealerRequest = new Request(dealerUrl, {
        method: "GET",
        cache: "no-cache",
      });

      const {
        result: { dealer },
      } = await API.send<IResponse<IAccount>>(dealerRequest);

      const { state } = dealer;
      const disclosureUrl = `${
        services?.legal.getStateDisclosuresUrl
      }?state=${state.toLowerCase()}`;
      const disclosureRequest = new Request(disclosureUrl, {
        method: "GET",
        cache: "no-cache",
      });

      const { result, error: disclosureError } = await API.send<
        IResponse<IStateDisclosureRecord[]>
      >(disclosureRequest);
      if (disclosureError) {
        message.warning("Disclosure data could not be found.");
      }

      const { stateDisclosures } = result || {};
      const [disclosureObj] = stateDisclosures || [];
      const { disclosures } = disclosureObj || { disclosures: [] };

      const { dealer_oem: oem } = dealer;
      const exceptions: IStateExceptionElement[] =
        oem.split(",").length === 1 ? await getExceptions(oem, state) : [];

      await Promise.all(
        offers.map(async selectedOffer => {
          const { offerData } = selectedOffer;

          const exceptionsToUse =
            exceptions.length > 1
              ? exceptions
              : await getExceptions(offerData.make, state);

          let numberAtPriceDisclosure: IStateDisclosureElement | undefined;

          const { trim, modelCode, msrp, dealerCode, vin } = offerData || {};
          const fieldsExist = trim && msrp && modelCode && dealerCode;
          if (fieldsExist) {
            numberAtPriceDisclosure =
              disclosures?.find(
                discObj => discObj.offerType === "Number at this Price",
              ) ||
              exceptionsToUse?.find(
                discObj => discObj.offerType === "Number at this Price",
              );
          }

          const offerList: IOffer[] = [];
          const useNumberAtPrice =
            !!numberAtPriceDisclosure && offerList.length > 0;
          const disclosureNames = returnDisclosureNamesToUse(
            selectedOffer.offers || [],
            useNumberAtPrice,
          );

          let currentException: IStateExceptionElement | undefined;
          let textHasNumberAtPriceValues = false;
          let disclosureText = "";
          disclosureNames.forEach((disclosureName, index) => {
            let currentDisclosure = disclosures?.find(
              disclosure => disclosure.offerType === disclosureName,
            );
            if ((exceptionsToUse?.length || 0) > 0) {
              currentException = exceptionsToUse?.find(
                exception => exception.offerType === disclosureName,
              );

              const replaceDisclosure =
                currentException &&
                currentException.text &&
                currentException.text.trim() !== "";

              currentDisclosure = replaceDisclosure
                ? currentException
                : currentDisclosure;
            }

            let { text = "" } = currentDisclosure || {};

            if (disclosureName === "Vehicle Info") {
              textHasNumberAtPriceValues = textHelpers
                .numberAtThisPriceVarRegex()
                .test(text);

              if (textHasNumberAtPriceValues && numberAtPriceDisclosure) {
                const { max_number_of_vins: maxNumberOfVins } = JSON.parse(
                  numberAtPriceDisclosure.text,
                ) as offerHelpers.INumberAtPriceDisclosureObj;
                text = offerHelpers.replaceNumberAtPriceVarText(
                  text,
                  offerList,
                  maxNumberOfVins,
                  vin,
                  [],
                  offers,
                );
              }
            } else if (
              disclosureName === "Number at this Price" &&
              useNumberAtPrice &&
              !textHasNumberAtPriceValues
            ) {
              text = offerHelpers.returnNumberAtThisPriceText(
                (currentException ||
                  numberAtPriceDisclosure) as IStateDisclosureElement,
                offerList,
                vin,
                [],
                offers,
              );
            }

            const skipNumberAtThisPrice =
              disclosureName === "Number at this Price" &&
              textHasNumberAtPriceValues;

            disclosureText += skipNumberAtThisPrice
              ? ""
              : text
              ? `${text}`
              : `{${disclosureName}}`;

            if (index < disclosureNames.length - 1) {
              disclosureText += " ";
            }
          });

          const disclosureIdentiers = disclosureNames.map(disclosureName =>
            disclosureName.toUpperCase(),
          );

          let filledText = replaceTextTokens({
            text: disclosureText,
            offerData,
            replacePunctuation: true,
            skipValueIfZero: true,
          });

          const textHasStoreVarsRegex = textHelpers.dealerVariablesRegex();
          if (textHasStoreVarsRegex.test(filledText)) {
            filledText = dealer
              ? textHelpers.replaceDealerTokens(filledText, dealer)
              : filledText;

            filledText = textHelpers.replaceFinalPriceVariable(
              filledText,
              dealer,
            );
          }

          const textWithExpDate = textHelpers.replaceDisclosureTypeVariable(
            "Expiration Date",
            filledText,
            offerData.expirationDate
              ? formatDateValue(offerData.expirationDate)
              : "",
          );

          const cleanedText = textHelpers.removeSentencesWithUnfilledVars(
            disclosureText,
            textWithExpDate,
            disclosureIdentiers,
          );

          props.setOfferWithDisclosure?.({
            selectedOffers: selectedOffer.offers
              .join(", ")
              .replace(/"/g, '\\"'),
            offerData,
            finalDisclosure: cleanedText,
          });
        }),
      );
    } catch (err) {
      props.selectedOffers?.forEach(selectedOffer => {
        const { offerData } = selectedOffer;
        props.setOfferWithDisclosure?.({
          selectedOffers: selectedOffer.offers.join(", ").replace(/"/g, '\\"'),
          offerData,
          finalDisclosure: "",
        });
      });
    }
  };

  const getV2Disclosures = async (
    oem: string,
    dealerName: string,
    vehicleCondition: string,
    vin: string,
    fullStateName: string,
  ) => {
    let v2Disclosures: IStateDisclosureElement[];

    try {
      const currentDisclosures = await API.services.legalLingoV2.getDisclosure({
        storeName: dealerName,
        stateName: fullStateName,
        condition: vehicleCondition,
        vin,
        oem,
      });
      const { result } = currentDisclosures as {
        result: IDisclosure;
      };

      v2Disclosures = result.disclosures || [];
    } catch (err) {
      v2Disclosures = [];
    }
    return v2Disclosures;
  };
  const defaultFoundDisc: IPDFDisclosureParams = {
    assetType: "",
    idx: 0,
    dimension: "",
    vin: "",
    disclosure: defaultDisclosure,
  };
  const defaultCustomDisc: IPDFCustomDisclosure = {
    ...defaultFoundDisc,
    cleanedText: "",
  };

  const getDisclosureDataV2 = async (
    foundDisclosure?: IPDFDisclosureParams,
  ) => {
    try {
      const dealerUrl = `${
        services?.getDealerUrl
      }?dealerName=${encodeURIComponent(props.order?.dealer_name || "")}`;
      const dealerRequest = new Request(dealerUrl, {
        method: "GET",
        cache: "no-cache",
      });

      const {
        result: { dealer },
      } = await API.send<IResponse<IAccount>>(dealerRequest);

      const { state } = dealer;

      return props.selectedOffers
        ? await Promise.all(
            props.selectedOffers.map(async selectedOffer => {
              const { offerData } = selectedOffer;
              if (
                !feedId ||
                (foundDisclosure && offerData.vin !== foundDisclosure.vin)
              ) {
                return defaultCustomDisc;
              }

              const useFoundDisc = offerHelpers.useFoundDisc(
                feedId,
                offerData,
                foundDisclosure,
              );

              let numberAtPriceDisclosure: IStateDisclosureElement | undefined;
              const fullStateName =
                fullStateOptions.find(el => el.abbreviation == state)?.name ||
                "";

              const {
                trim,
                modelCode,
                msrp,
                dealerCode,
                make,
                vehicleCondition,
                vin,
              } = offerData || {};

              let disclosures: IStateDisclosureElement[] = [];

              if (!useFoundDisc || !foundDisclosure) {
                disclosures = await getV2Disclosures(
                  make,
                  dealer.dealer_name,
                  vehicleCondition,
                  vin,
                  fullStateName,
                );
              }

              if (foundDisclosure) {
                disclosures = foundDisclosure.disclosure.disclosures;
              }

              const params = {
                feedId,
                dealerCode: dealer.dealer_code ?? dealerCode,
                dealerOem,
                filterBy,
                searchBy: "",
                filterField: "dealerId",
                filterFieldSearch: "",
                msrp,
                sortingOptions: [],
              };
              const res = await API.services.assetBuilder.getTabData(params, 1);
              const { result, error: apiError } = res;

              const feedData: FeedOffer[] = [];
              if (!apiError && result?.offerList)
                feedData.push(...result.offerList);

              const fieldsExist = trim && msrp && modelCode && dealerCode;
              const isRealVin = vin?.length === 17;
              if (fieldsExist && isRealVin) {
                numberAtPriceDisclosure = disclosures?.find(
                  discObj => discObj.offerType === "Number at this Price",
                );
              }

              const offerList: IOffer[] = [];
              const useNumberAtPrice =
                !!numberAtPriceDisclosure && offerList.length > 0;
              const disclosureNames = returnDisclosureNamesToUse(
                selectedOffer.offers || [],
                useNumberAtPrice,
              );

              let textHasNumberAtPriceValues = false;
              let disclosureText = "";
              disclosureNames.forEach((disclosureName, index) => {
                const currentDisclosure = disclosures?.find(
                  disclosure => disclosure.offerType === disclosureName,
                );

                let { text = "" } = currentDisclosure || {};

                if (disclosureName === "Vehicle Info") {
                  textHasNumberAtPriceValues = textHelpers
                    .numberAtThisPriceVarRegex()
                    .test(text);

                  const selectedNumAtPriceDisc = disclosures?.find(
                    disc => disc.offerType === "Number at this Price",
                  );

                  if (textHasNumberAtPriceValues && selectedNumAtPriceDisc) {
                    const numAtThisPriceReplacement =
                      offerHelpers.returnNumberAtThisPriceText(
                        (selectedNumAtPriceDisc ||
                          numberAtPriceDisclosure) as IStateDisclosureElement,
                        offerList,
                        vin,
                        feedData,
                        offers,
                      );
                    text = text.replace(
                      /\{numberAtThisPrice\}/gi,
                      `${numAtThisPriceReplacement}`,
                    );
                  }
                  if (textHasNumberAtPriceValues && numberAtPriceDisclosure) {
                    const { max_number_of_vins: maxNumberOfVins } = JSON.parse(
                      numberAtPriceDisclosure.text,
                    ) as offerHelpers.INumberAtPriceDisclosureObj;
                    text = offerHelpers.replaceNumberAtPriceVarText(
                      text,
                      offerList,
                      maxNumberOfVins,
                      vin,
                      feedData,
                      offers,
                    );
                  }
                } else if (
                  disclosureName === "Number at this Price" &&
                  useNumberAtPrice &&
                  !textHasNumberAtPriceValues
                ) {
                  text = offerHelpers.returnNumberAtThisPriceText(
                    numberAtPriceDisclosure as IStateDisclosureElement,
                    offerList,
                    vin,
                    feedData,
                    offers,
                  );
                }

                const skipNumberAtThisPrice =
                  disclosureName === "Number at this Price" &&
                  textHasNumberAtPriceValues;

                disclosureText += skipNumberAtThisPrice
                  ? ""
                  : text
                  ? `${text}`
                  : `{${disclosureName}}`;

                if (index < disclosureNames.length - 1) {
                  disclosureText += " ";
                }
              });

              const disclosureIdentiers = disclosureNames.map(disclosureName =>
                disclosureName.toUpperCase(),
              );

              let filledText = replaceTextTokens({
                text: disclosureText,
                offerData,
                replacePunctuation: true,
                skipValueIfZero: true,
              });

              const textHasStoreVarsRegex = textHelpers.dealerVariablesRegex();
              if (textHasStoreVarsRegex.test(filledText)) {
                filledText = dealer
                  ? textHelpers.replaceDealerTokens(filledText, dealer)
                  : filledText;

                filledText = textHelpers.replaceFinalPriceVariable(
                  filledText,
                  dealer,
                );
              }

              const textWithExpDate = textHelpers.replaceDisclosureTypeVariable(
                "Expiration Date",
                filledText,
                offerData.expirationDate
                  ? formatDateValue(offerData.expirationDate)
                  : moment(props.order?.expiresAt).format("MM/DD/YYYY"),
              );

              const cleanedText = textHelpers.removeSentencesWithUnfilledVars(
                disclosureText,
                textWithExpDate,
                disclosureIdentiers,
              );

              if (!foundDisclosure || !useFoundDisc) {
                props.setOfferWithDisclosure?.({
                  selectedOffers: selectedOffer.offers
                    .join(", ")
                    .replace(/"/g, '\\"'),
                  offerData,
                  finalDisclosure: cleanedText,
                });
                return defaultCustomDisc;
              }

              const foundCustomDisclosure: IPDFCustomDisclosure = {
                ...foundDisclosure,
                cleanedText,
              };
              return foundCustomDisclosure;
            }),
          )
        : [defaultCustomDisc];
    } catch (err) {
      props.selectedOffers?.forEach(selectedOffer => {
        const { offerData } = selectedOffer;
        props.setOfferWithDisclosure?.({
          selectedOffers: selectedOffer.offers.join(", ").replace(/"/g, '\\"'),
          offerData,
          finalDisclosure: "",
        });
      });
      return [defaultCustomDisc];
    }
  };

  useEffect(() => {
    if (props.order || props.offersWithDisclosures.length < 1) {
      legalLingoV2Enabled ? getDisclosureDataV2() : getDisclosureData();
    }

    if (props.order) {
      props.proofExportData(props.order.id);
    }
  }, [props.order]);

  useEffect(() => {
    let assetCount = 0;
    if (!isEmpty(props.assetInstances))
      props.setAssetInstanceComparator(props.assetInstances);
    props.resetAssetInstanceCounter();
    if (isRawEnvVarEquals("REACT_APP_WORKFRONT_ENABLED", "true"))
      props.getProofTemplates();
    if (props.assetInstances && Object.keys(props.assetInstances).length > 0) {
      const templateTypes = Object.keys(props.assetInstances);
      if (templateTypes.length > 0) {
        templateTypes.forEach(templateType => {
          const assetTemplates = props.assetInstances[templateType];
          if (assetTemplates) {
            const templateSizes = Object.keys(assetTemplates);
            templateSizes.forEach(size => {
              assetTemplates[size].forEach(() => {
                assetCount++;
              });
            });
          }
        });
      }
    }
    setExportAssetSizeState(assetCount);

    if (!props.order) {
      return;
    }

    if (props.enableWorkfront && !assetExportQueryObj.feedId) {
      props.getWorkfrontFolders(
        props.order?.wfID,
        props.order?.wfFullProjectName,
      );
    }

    return () => {
      props.disableExportButton();
      props.resetPdfUrl();
    };
  }, []);

  useEffect(() => {
    if (props.s3Url && processingZip) {
      setProcessingZip(!processingZip);
    }
  }, [props.s3Url]);

  useEffect(() => {
    if (props.pdfUrl && processingPDF) {
      setProcessingPDF(!processingPDF);
    }
  }, [props.pdfUrl]);

  useEffect(() => {
    if (props.feedPDFUrl && processingFeedPDF) {
      setProcessingFeedPDF(!processingFeedPDF);
    }
  }, [props.feedPDFUrl]);

  useEffect(() => {
    if (!props.disableExport) {
      message.success("Assets are ready to be exported");
    } else {
      message.warning(
        "All assets must be pre-loaded before Export option is made available ...",
        2,
      );
    }
  }, [props.disableExport]);

  useEffect(() => {
    props.generalMessage &&
      !props.errorMessage &&
      message.info(props.generalMessage);
  }, [props.generalMessage, props.errorMessage]);

  useEffect(() => {
    // close modal if for some reason the asset export fails
    if (props.errorMessage) {
      message.error(props.errorMessage, 5);
      toggleResultModal(false);
    }
  }, [props.errorMessage]);

  useEffect(() => {
    props.generalMessage && message.info(props.generalMessage);
  }, [props.generalMessage]);

  useEffect(() => {
    if (
      !props.assetInstances ||
      Object.keys(props.assetInstances).length < 1 ||
      Object.keys(props.selectedExportedAssets).length >= 1
    ) {
      return;
    }
    const initalAssetsToExport = returnInitialAssetsToExport(
      props.assetInstances,
      props.selectedOffers,
      false,
      props.order,
    );

    props.updateExportedAssetsArr(initalAssetsToExport);
  }, [props.assetInstances, props.selectedExportedAssets]);

  useEffect(() => {
    if (Object.keys(props.selectedExportedAssets).length === 0) return;

    for (const tab in props.selectedExportedAssets) {
      for (const dimension in props.selectedExportedAssets[tab]) {
        const fileNames = props.selectedExportedAssets[tab][dimension];
        const canvases = renderTemplateContext?.getExportingCanvases(fileNames);
        const canvasData = getCanvasDataForRequest(canvases || {});
        const containsVideo = canvasData.some(canvas =>
          getContainsVideo(canvas.json),
        );
        setContainsVideo(containsVideo);
        break;
      }
    }
  }, [props.selectedExportedAssets, props.disableExport]);

  useEffect(() => {
    const okToProceed = exportTypes.includes("WORKFRONT")
      ? props.order?.documentID && props.order.parentFileToken
      : props.order?.coopSubmissionNotice;
    if (!okToProceed) return;

    props.order?.parentFileToken &&
      props.order?.documentID &&
      setProcessingUpload(false); // for proof uploads
    props.order?.coopSubmissionNotice !== currentCoopToken &&
      !props.order?.coopSubmissionNotice.includes("Error") &&
      setProcessingUpload(false); // for coop submissions

    if (
      props.order?.coopSubmissionNotice !== currentCoopToken &&
      props.order?.coopSubmissionNotice.includes("Error")
    ) {
      const dealerAsIDealerRecord = dealerToDealerRecordData(currentDealer);
      const dealerWithLockedLogin: IAccountRecord = {
        ...dealerAsIDealerRecord,
        coopDetails: dealerAsIDealerRecord.coopDetails
          ? {
              ...dealerAsIDealerRecord.coopDetails,
              coopLoginLocked: true,
            }
          : undefined,
      };

      props.updateDealer(dealerWithLockedLogin);
      message.error(
        "There was an error with the submission. Reenter the password and try again.",
        10,
      );
    }
    if (props.order) {
      setCurrentCoopToken(props.order.coopSubmissionNotice);
    }
  }, [
    props.order?.parentFileToken,
    props.order?.documentID,
    props.order?.coopSubmissionNotice,
  ]);

  // gets the savedorder every 5 seconds so that we can get the right tokens when the workfront upload ends
  useEffect(() => {
    if (!renderUploadIcon || !processingUpload) {
      return;
    }
    const interval = setInterval(() => {
      props.fetchOrderState();
    }, 5000);
    return () => clearInterval(interval);
  }, [renderUploadIcon, processingUpload]);

  // this runs HTML or Video Export
  useEffect(() => {
    const { exportedAssets, isExportCompleted, processingImageExport } = props;
    const { fetchExportZipUrl, fetchExportState } = props;
    if (!exportedAssets?.length || processingImageExport) return;
    if (isExportCompleted && !!exportedAssets?.length) {
      const exportedAssets =
        props.exportedAssets?.concat(props.imageExports) || [];
      fetchExportZipUrl(exportedAssets, ignoreDownload);
      if (props.willFireBatchUpload && ignoreDownload) {
        props.toggleWillBatchUpload?.(false);
        setIgnoreDownload(false);
        notification.success({
          message: "Upload Successful",
          description: `Asset(s) were uploaded.`,
          placement: "bottomRight",
        });
      }
    }
    const interval = setInterval(() => {
      fetchExportState(props.exportedAssets || []);
    }, 5000);
    return () => clearInterval(interval);
  }, [
    renderUploadIcon,
    props.isExportCompleted,
    props.exportedAssets,
    props.processingImageExport,
  ]);

  useEffect(() => {
    if (window.location.pathname === "/asset-export") {
      setImageOption("png");
    }
  }, []);

  const getCanvasDataForRequest = (canvases: Record<string, Canvas>) => {
    return Object.keys(canvases).reduce(
      (acc, filename) => {
        const canvas = canvases[filename];
        const dimension: IDimension = {
          width: canvas.getWidth(),
          height: canvas.getHeight(),
        };
        const json = canvas.toJSON(["customType", "customData", "name"]);
        const fonts = getFontNames(canvas);

        // remove filters because when de-serializing in the page, filters were causing rendering issue
        (
          json as unknown as {
            objects: fabric.Object[];
          }
        ).objects.map((obj: any) => {
          delete obj["filters"];
        });

        acc.push({
          filename,
          json,
          dimension,
          fonts,
        });

        return acc;
      },
      [] as Array<{
        filename: string;
        dimension: IDimension;
        json: string;
        fonts: string[]; // list of font family
      }>,
    );
  };

  type CheckboxType = {
    checkedValue: boolean;
    assetType: string;
    dimension: string;
    checkedKey: string;
    isForPlayingVideo?: boolean;
  };

  const handleInstanceCheckboxToggle = ({
    checkedValue,
    assetType,
    dimension,
    checkedKey,
    isForPlayingVideo,
  }: CheckboxType) => {
    const newAssetInstanceNames = {
      ...(isForPlayingVideo
        ? (JSON.parse(
            JSON.stringify(namesOfAssetsPlayingVideo),
          ) as typeof namesOfAssetsPlayingVideo)
        : (JSON.parse(
            JSON.stringify(props.selectedExportedAssets),
          ) as typeof props.selectedExportedAssets)),
    };

    if (!newAssetInstanceNames?.[assetType]) {
      newAssetInstanceNames[assetType] = {};
    }

    if (!newAssetInstanceNames[assetType][dimension]) {
      newAssetInstanceNames[assetType][dimension] = [];
    }

    const selectedInstanceNamesToUse = isForPlayingVideo
      ? namesOfAssetsPlayingVideo
      : props.selectedExportedAssets;

    if (!checkedValue) {
      newAssetInstanceNames[assetType][dimension] = selectedInstanceNamesToUse[
        assetType
      ][dimension].filter(assetName => assetName !== checkedKey);
    } else {
      newAssetInstanceNames[assetType][dimension].push(checkedKey);
    }

    if (isForPlayingVideo) {
      setNamesOfAssetsPlayingVideo(newAssetInstanceNames);
    } else {
      props.updateExportedAssetsArr(newAssetInstanceNames);
    }
  };

  const handleInstanceGroupCheckboxToggle = async ({
    checkedValue,
    assetType,
    dimensions,
  }: {
    checkedValue: boolean;
    assetType: string;
    dimensions: string[];
  }) => {
    const newAssetInstanceNames = cloneDeep(props.selectedExportedAssets);

    const assetArrClone: InstanceCheckboxDict = {};
    for await (const dimension of dimensions) {
      if (!checkedValue) {
        assetArrClone[assetType] = { ...assetArrClone[assetType] };
        assetArrClone[assetType][dimension] = [];
      } else {
        const instancesFromProps = props.assetInstances[assetType][dimension];

        assetArrClone[assetType] = { ...assetArrClone[assetType] };
        assetArrClone[assetType][dimension] = returnAssetInstanceCheckboxKeys(
          assetType,
          dimension,
          instancesFromProps,
          props.selectedOffers,
          false,
          props.order,
        );
      }
      props.updateExportedAssetsArr({
        ...newAssetInstanceNames,
        [assetType]: { ...assetArrClone[assetType] },
      });
    }
  };
  const returnInstanceCountDict = (
    selectedInstances: AssetInstanceRecord | InstanceCheckboxDict,
  ) => {
    const counterDictionary = {} as Record<string, number>;
    // tslint:disable-next-line: forin
    for (const assetType in selectedInstances) {
      counterDictionary[assetType] = 0;
      const currentCheckboxGroup = selectedInstances[assetType];
      for (const dimension in currentCheckboxGroup) {
        if (currentCheckboxGroup[dimension].length < 1) {
          continue;
        }
        const currentInstances = selectedInstances[assetType][dimension];
        counterDictionary[assetType] += currentInstances.length;
      }
    }

    return counterDictionary;
  };

  const getVideoStillUri = (
    template: IZipExport,
    imageOption?: ExportImageType,
  ) => {
    const canvas = new fabric.Canvas(null);
    canvas.width = template.dimension.width;
    canvas.height = template.dimension.height;
    return new Promise<string>(resolve => {
      canvas.loadFromJSON(template.json, () => {
        resolve(canvas.toDataURL({ format: imageOption || "jpeg" }));
      });
    });
  };

  const getMediaAttrs = async (canvas: IZipExport) => {
    if (!renderTemplateContext) {
      return {
        fileName: "",
        base64: "",
      };
    }
    if (canvas.isVideo) {
      const base64 = await getVideoStillUri(canvas, imageOption);
      return {
        fileName: canvas.filename,
        base64,
      };
    }
    const { toDataURLsAndFilename } = renderTemplateContext;
    const { filename, quality } = canvas;
    const imageData = await toDataURLsAndFilename(
      [filename],
      imageOption,
      quality,
    );

    if (!imageData[0]?.base64) {
      const base64Fallback = await getVideoStillUri(canvas, imageOption);
      return {
        fileName: canvas.filename,
        base64: base64Fallback,
      };
    }
    return imageData[0];
  };

  const exportAssets = async (
    destination: ExportDestination,
    exportOption?: ExportType,
    imageOption?: ExportImageType,
    videoOption?: ExportVideoType,
    quality?: number,
  ) => {
    if (!renderTemplateContext) {
      return;
    }
    if (destination === "zip") {
      videoOption && setExportVideoType(videoOption);
      imageOption
        ? setExportImageType(imageOption)
        : setExportImageType("none");
    } else if (destination === "workfront") {
      videoOption && setExportWFVideoType(videoOption);
      imageOption && setExportWFImageType(imageOption);
    }
    if (destination === "coop" || destination === "pdf") {
      imageOption && setExportImageType(imageOption);
    }

    const exportingFilenameObject = returnInitialAssetsToExport(
      props.assetInstances,
      props.selectedOffers,
      false,
      props.order,
    );

    const customInstanceDisclosures: IPDFDisclosureParams[][] = [];

    // Get list of formatted filenames to export.
    // Initially, this will contain all instances.
    let exportingFilenames: string[] = [];
    for (const assetType in exportingFilenameObject) {
      for (const dimension in exportingFilenameObject[assetType]) {
        exportingFilenames = [
          ...exportingFilenames,
          ...exportingFilenameObject[assetType][dimension],
        ];
        customInstanceDisclosures.push(
          props.assetInstances[assetType][dimension].map((instance, index) => {
            return {
              assetType,
              dimension,
              idx: index,
              vin: Object.keys(instance.offers)[0],
              disclosure: offerHelpers.findValidCustomDisclosure(instance),
            };
          }),
        );
      }
    }

    if (exportOption === "selected") {
      let selectedFilenames: string[] = [];
      for (const assetType in props.selectedExportedAssets) {
        for (const dimension in props.selectedExportedAssets[assetType]) {
          selectedFilenames = [
            ...selectedFilenames,
            ...props.selectedExportedAssets[assetType][dimension],
          ];
        }
      }

      const selectedIndexes: number[] = [];
      selectedFilenames.forEach(file => {
        selectedIndexes.push(exportingFilenames.indexOf(file));
      });

      exportingFilenames = [...selectedFilenames]; // replace with selected filename list
    }

    if (exportingFilenames.length < 1) {
      message.warn("No assets were selected for export.");
      return;
    }

    const foundCustomDiscs: IPDFDisclosureParams[] = [];
    for (const exportedName of exportingFilenames) {
      const splitExportedName = exportedName.length
        ? exportedName.split("_")
        : [];
      if (!splitExportedName.length || splitExportedName.length < 5) break;
      const [assetType, index, dimension] =
        offerHelpers.extractOfferVarsFromName(splitExportedName);
      const idx = Number(index);
      for (const instanceSize of customInstanceDisclosures) {
        const discToCheck = instanceSize[idx];
        offerHelpers.pushCustomDiscs(
          foundCustomDiscs,
          discToCheck,
          assetType,
          idx,
          dimension,
        );
      }
    }

    const cleanedDiscs = await Promise.all(
      foundCustomDiscs.map(async customDisc => {
        return getDisclosureDataV2(customDisc);
      }),
    );

    const { getExportingCanvases } = renderTemplateContext;
    const canvases = getExportingCanvases(exportingFilenames);

    if (!canvases) {
      message.error("There was an error while processing instances.");
      return;
    }

    const canvasData = getCanvasDataForRequest(canvases);

    const requestBodyForZip = canvasData.map(canvas => {
      const isVideo = getContainsVideo(canvas.json);
      return {
        ...canvas,
        isVideo,
        imageOption: isVideo ? videoOption : imageOption,
        quality: quality ? quality : -1,
      };
    }) as IZipExport[];

    const {
      wfID,
      wfProjectName,
      wfProjectNumber,
      wfFullProjectName,
      dealer_code: dealerCode,
    } = props.order || {
      wfID: "",
      wfProjectName: "",
      wfProjectNumber: "",
      wfFullProjectName: "",
      dealer_code: "",
    };

    const promises = requestBodyForZip.map(canvas => getMediaAttrs(canvas));
    const toDataResult = await Promise.all(promises);
    const containsVideo = !!requestBodyForZip.find(req => req.isVideo);
    setIsVideoExport(containsVideo);

    const data: string[] = [];
    const newAssetArr: string[] = [];
    toDataResult.forEach(res => {
      const splitExportedName = res.fileName.length
        ? res.fileName.split("_")
        : [];

      const bgFileName = offerHelpers.getBgFileName(
        splitExportedName,
        props.assetInstances,
      );
      const fileName = !bgFileName
        ? res.fileName
        : `${bgFileName}_${res.fileName}`;
      data.push(res.base64);
      newAssetArr.push(
        wfProjectNumber && client !== "ladtech"
          ? `${
              props.order?.dealer_name ? `${props.order?.dealer_name}_` : ""
            }${wfProjectNumber}_${fileName}`
          : fileName,
      );
    });

    if (destination === "zip") {
      setRenderUploadIcon(true);
      toggleResultModal(true);
      setProcessingZip(true);
      setExportTypes(exportTypes => [...exportTypes, "ZIP"]);
    }

    if (destination === "pdf") {
      setRenderUploadIcon(true);
      toggleResultModal(true);
      setProcessingPDF(true);
      setExportTypes(exportTypes => [...exportTypes, "PDF"]);
    }

    if (destination === "coop") {
      setExportTypes(exportTypes => [...exportTypes, "COOP"]);
      toggleResultModal(true);
      setRenderUploadIcon(true);
      setProcessingUpload(true);
    }

    setSelectedAssets(data.length);

    const currentDate = moment(Date.now()).format("MM/DD/YYYY, h:mm:ss a");

    switch (destination) {
      case "coop":
        const coopSubmitData: ICoopIntegrationData = {
          ...coopToSubmit,
        };
        if (props.order) {
          setCurrentCoopToken(props.order.coopSubmissionNotice);
        }

        if (props.order && coopSupported) {
          props.currentUser &&
            props.coopSubmission(
              data,
              newAssetArr,
              props.offersWithDisclosures,
              props.currentUser,
              wfProjectNumber,
              coopSubmitData,
              props.order,
              currentDate,
            );
        } else {
          setProcessingPDF(true);
          props.currentUser &&
            props.generatePDF(
              data,
              newAssetArr,
              props.offersWithDisclosures,
              props.currentUser,
              wfProjectNumber,
              currentDate,
              cleanedDiscs,
            );
        }
        break;
      case "pdf":
        props.currentUser &&
          props.generatePDF(
            data,
            newAssetArr,
            props.offersWithDisclosures,
            props.currentUser,
            wfProjectNumber,
            currentDate,
            cleanedDiscs,
          );
        break;
      case "zip":
        if (imageOption) {
          props.generateCanvasZipUrl(
            data,
            newAssetArr,
            wfProjectNumber,
            dealerCode,
            containsVideo,
          );
        }

        props.startVideoHtmlExport(requestBodyForZip);
        break;
      case "workfront":
        if (!props.order) {
          message.error(
            "An error occurred while exporting this order to workfront.",
          );
          return;
        }

        const fullWFProject =
          wfFullProjectName && wfFullProjectName.trim() !== ""
            ? wfFullProjectName
            : `${wfProjectNumber}${wfProjectName}`;

        const parsedSelectedArr = parseSelectedProofs(selectedProofToUpdate);

        const wfOrder: INewOrder = {
          ...props.order,
        };

        const {
          templateID: tmpId,
          folderID: fId,
          parentFileToken: ptID,
          documentID: docID,
        } = parsedSelectedArr;

        const parentFileToken = ptID && useVersioning ? ptID : "";
        const documentID = docID && useVersioning ? docID : "";
        const tempID = tmpId && useVersioning ? tmpId : templateID;
        const foldID = fId && useVersioning ? fId : folderID;

        const { key, proof } = getProofData(props.wfProofArr ?? [], docID) || {
          key: null,
          proof: null,
        };

        const selectedProofKey = key;

        const selectedProofArr = proof;

        props.pushToProof(
          data,
          newAssetArr,
          fullWFProject,
          wfID,
          tempID,
          subject,
          proofMessage,
          foldID,
          parentFileToken,
          documentID,
          wfOrder,
          props.offersWithDisclosures,
          props.order.wfFullProjectName,
          props.currentUser,
          wfProjectNumber,
          includeAssets,
          includePDF,
          includeCSV,
          currentDate,
          proofName,
          selectedProofArr,
          selectedProofKey,
          cleanedDiscs,
        );

        setProcessingUpload(true);
        setRenderUploadIcon(true);
        setExportTypes(exportTypes => [...exportTypes, "WORKFRONT"]);

        // show loading icon for this one in the modal
        toggleResultModal(true);
        break;
      default:
        break;
    }
  };

  const exportAssetsToS3 = async () => {
    if (!renderTemplateContext || !props.order) {
      return;
    }

    const { feedId, id } = assetExportQueryObj;

    if (!feedId || !id) {
      notification.error({
        message: "Could not export assets",
        description: "An execution id is missing from the page's URL",
        placement: "bottomRight",
      });
      props.toggleWillBatchUpload?.(false);
      return;
    }

    const { id: orderId } = props.order;
    const exportFilenames = returnInitialAssetsToExport(
      props.assetInstances,
      props.selectedOffers,
      false,
      props.order,
    );
    const exportingVinsObject = returnAssetInstanceVins(props.assetInstances);

    // filename and offer index is 1:1 for now
    const filenames: string[] = [];
    const exportingVins: string[] = [];

    // Selection process is not needed in s3 exports for now
    for (const assetType in exportFilenames) {
      for (const dimension in exportFilenames[assetType]) {
        filenames.push(...exportFilenames[assetType][dimension]);
        exportingVins.push(...exportingVinsObject[assetType][dimension]);
      }
    }

    const { getExportingCanvases } = renderTemplateContext;
    const canvases = getExportingCanvases(filenames);
    const canvasData = getCanvasDataForRequest(canvases);
    const orderData = canvasData.map(canvas => {
      const isVideo = getContainsVideo(canvas.json);
      const { filename } = canvas;
      const vinIdx = filenames.findIndex(fn => fn === filename);
      const canvasImageOption = isVideo ? "mp4" : imageOption;
      return {
        ...canvas,
        isVideo,
        imageOption: canvasImageOption as IVideoParams["imageOption"],
        quality: 0.9,
        vin: exportingVins[vinIdx],
      };
    });

    const pngExports = orderData.filter(data => !data.isVideo);
    const videoExports = orderData.filter(data => data.isVideo);
    const pngPromise = pngExports.map(canvas => getMediaAttrs(canvas));
    const toDataResult = await Promise.all(pngPromise);

    const data: string[] = [];
    const newAssetArr: string[] = [];
    toDataResult.forEach(res => {
      data.push(res.base64);
      newAssetArr.push(`${orderId}_${res.fileName}`);
    });

    // Sample path: /batch-export/<feedId>/<id>/<FLATTENEDINDEX>.<MIMETYPE>
    const destination = `batch-export/${orderId}/${id}`;

    // TO DO: add video support, but isolate each video upload
    const uploadMediaObjs = toDataResult.map(assetObj => {
      const matchingVinIndex = filenames.findIndex(
        filename => filename === assetObj.fileName,
      );
      const matchingVin = exportingVins[matchingVinIndex]; // it is called vin, but really, it is just a unique ID

      return {
        file: assetObj.base64,
        filename: `${matchingVin}.${imageOption}`,
        type: `image/${imageOption}`,
      };
    });

    const batches = returnUploadMediaBatches(uploadMediaObjs, 5);
    const { uploadMedia } = renderTemplateContext;
    const hasVideos = !!videoExports.length;
    const jpegPromises = batches.map(batch =>
      uploadMedia?.(batch, destination, undefined, hasVideos),
    );
    await Promise.all(jpegPromises);
    if (hasVideos) {
      setIgnoreDownload(true);
      props.startVideoHtmlExport(videoExports, destination);
    } else {
      props.toggleWillBatchUpload?.(false);
    }
  };

  useEffect(() => {
    if (!props.willFireBatchUpload) return;

    exportAssetsToS3();
  }, [props.willFireBatchUpload]);

  const allInstanceCountDict = returnInstanceCountDict(props.assetInstances);
  const selectedInstanceCountDict = returnInstanceCountDict(
    props.selectedExportedAssets,
  );

  const supportVideos = props.config?.featureFlags?.supportVideos;

  /*
    Possible TO DO: pass unique identifier ("vin","vehicleOfferId"m etc)
    query param to pass into service for giving value to uniqueIdentifier
    const idProp = assetExportQueryObj?.identifier || "vin";
  */
  // if feedId is used in the hidden Asset Export module
  const showTabContainer =
    assetExportQueryObj?.feedId ||
    props.order?.wfFullProjectName ||
    (props.order?.expiresAt || 0) < Math.round(Date.now() + timezoneOffset);

  const title = `Summary of Assets Approval - ${
    props.order?.wfFullProjectName
      ? props.order.wfFullProjectName.split("_").join(" ")
      : props.currentSelectedOrder?.wfFullProjectName.split("_").join(" ")
  }`;

  const [activeKeys, setActiveCollapseKeys] = useState<string[]>(assetTypes);

  // For RenderTemplate cache and disable/enable export button logics
  const assetBuild = useMemo(
    () =>
      assetInstancesToAssetBuild(props.assetInstances, props.selectedOffers),
    [props.assetInstances, props.selectedOffers],
  );
  const cache = useFetchRenderTemplate({
    order: props.order,
    instances: assetBuild.instances,
    config: props.config,
  });
  const [templateIds, setTemplateIds] = useState<string[]>([]);
  const [renderCompletedTemplateIds, setRenderCompletedTemplateIds] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (!assetBuild) return;

    // if all template ids from assetBuild.instances are in renderCompletedTemplateIds,
    //   we can conclude that all assets are rendered.

    const templateIds: string[] = [];
    for (const assetType in assetBuild.instances) {
      for (const size in assetBuild.instances[assetType]) {
        for (const instance of assetBuild.instances[assetType][size]) {
          const template = instance.template;

          // This below logic is related to enable/disable export button.
          // We should exclude if current offer vin is missing in selected offers since missing vin will skip rendering canvas.
          const offerData = instance.selectedOffer;
          const offerFound = props.selectedOffers.some(
            selectedOffer => selectedOffer.offerData.vin === offerData?.vin,
          );

          // If the instance is a custom image we are not exporting it.
          if (template?.id && offerFound && !instance.isCustomImage)
            templateIds.push(template.id);
        }
      }
    }

    setTemplateIds(templateIds);
  }, [assetBuild]);

  const { enableExportButton } = props;
  const enableExport = useCallback(
    async renderCompleted => {
      if (renderCompleted) {
        await delay(3000);
        enableExportButton();
      }
    },
    [enableExportButton],
  );

  useEffect(() => {
    if (isEmpty(renderCompletedTemplateIds) || isEmpty(templateIds)) return;

    const renderCompleted = templateIds.every(id =>
      renderCompletedTemplateIds.includes(id),
    );

    enableExport(renderCompleted);
  }, [enableExport, renderCompletedTemplateIds, templateIds]);

  const isInnerCollapseChecked = (dimension: string, assetType: string) => {
    const selectedAssets = props.selectedExportedAssets[assetType]?.[dimension];
    const selectedCount = selectedAssets?.length;
    const totalCount = props.assetInstances[assetType][dimension].length;
    return selectedCount === totalCount;
  };

  const isInnerCollapseIndeterminate = (
    dimension: string,
    assetType: string,
  ) => {
    const selectedAssets = props.selectedExportedAssets[assetType]?.[dimension];
    const selectedCount = selectedAssets?.length;
    const totalCount = props.assetInstances[assetType][dimension].length;
    return totalCount > selectedCount && selectedCount !== 0;
  };

  const deselectDimensionAssets = (dimension: string, assetType: string) => {
    const currAssets = cloneDeep(props.selectedExportedAssets);
    const assets = [...props.selectedExportedAssets[assetType][dimension]];

    if (!!assets.length) {
      currAssets[assetType][dimension] = [];
    } else {
      const instancesFromProps = props.assetInstances[assetType][dimension];
      currAssets[assetType][dimension] = returnAssetInstanceCheckboxKeys(
        assetType,
        dimension,
        instancesFromProps,
        props.selectedOffers,
        false,
        props.order,
      );
    }
    props.updateExportedAssetsArr(currAssets);
  };

  const getInstanceTitle = (
    { isCustomImage }: IAssetInstance,
    { year, make, model, trim }: OfferData,
  ) => {
    if (isCustomImage) {
      return "Custom Integration";
    } else {
      return `${year} ${make} ${model} ${trim ? trim : ""}`;
    }
  };

  return (
    <div className="review-container">
      {
        <ReviewExportResultModal
          isHtmlExport={isHtmlExport}
          setIsHtmlExport={setIsHtmlExport}
          isVideoExport={isVideoExport}
          setIsVideoExport={setIsVideoExport}
          exportTypes={exportTypes}
          exportImageType={exportImageType}
          exportVideoType={exportVideoType}
          exportWFVideoType={exportWFVideoType}
          exportWFImageType={exportWFImageType}
          processingUpload={processingUpload}
          processingZip={processingZip}
          processingPDF={processingPDF}
          processingFeedPDF={processingFeedPDF}
          proofUploadData={props.order?.proofUploadData}
          assetsExported={assetSizeState}
          exportAssetSizeState={exportAssetSizeState}
          showResultModal={showResultModal}
          toggleResultModal={toggleResultModal}
          templateName={templateName}
          folderName={folderName}
          subject={subject}
          proofMessage={proofMessage}
          offersExported={props.offersWithDisclosures.length}
          selectedAssets={selectedAssets}
          clearExportTypes={() => {
            setExportTypes([]);
          }}
          setProcessingUpload={() => {
            setProcessingUpload(false);
          }}
          pdfUrl={props.pdfUrl}
          feedPDFUrl={props.feedPDFUrl}
          beingProcessedTypes={beingProcessedTypes}
          exportedAssets={props.exportAssetsUploaded || 0}
          isExportCompleted={props.isExportCompleted || false}
          coopSupported={coopSupported}
          order={props.order}
          dealer={currentDealer}
          containsVideo={containsVideo}
        ></ReviewExportResultModal>
      }
      {props.order && showTabContainer && (
        <TabContainer
          displayStatusSection={isEnvVarEquals("CLIENT", "internal")}
          displayStatusSelect={
            <Badge
              color={getColorFromStatus(
                props.order.status || StatusOptions.NO_STATUS,
              )}
              text={props.order.status || StatusOptions.NO_STATUS}
            />
          }
          displaySearchView={{
            displayNewOffer: false,
            displaySearchInput: false,
            displayPlusButton: false,
          }}
          displayFilterSection={false}
          contentTabs={[
            {
              title,
              component: (
                <div className="review-container">
                  <Spin spinning={props.processingExport} size="large">
                    <Collapse
                      onChange={value => {
                        setActiveCollapseKeys(value as string[]);
                      }}
                      activeKey={activeKeys}
                    >
                      {assetTypes.map(assetType => {
                        const assetInstancesByDimension =
                          props.assetInstances[assetType];
                        const orderedDimensions = orderBy(
                          Object.keys(assetInstancesByDimension),
                        );

                        const dimensions = Object.keys(
                          assetInstancesByDimension,
                        );

                        const disableCheckbox =
                          !props.selectedExportedAssets[assetType] ||
                          props.disableExport;

                        const instanceCount = allInstanceCountDict[assetType];
                        const selectedInstanceCount =
                          selectedInstanceCountDict[assetType];

                        return (
                          <Collapse.Panel
                            key={`${assetType}`}
                            className="asset-type-collapse-container"
                            header={
                              <div className="asset-type-info">
                                <Tooltip
                                  title="Toggle selection of all instances in this group for selective export"
                                  placement="bottomLeft"
                                  trigger="hover"
                                >
                                  <span
                                    className={
                                      disableCheckbox
                                        ? "text-disabled"
                                        : "text-enabled"
                                    }
                                  >
                                    {assetType}
                                    <BadgeCount
                                      count={selectedInstanceCount}
                                      showZero={true}
                                    />
                                  </span>
                                </Tooltip>
                                <Checkbox
                                  className="dimension-checkbox"
                                  disabled={disableCheckbox}
                                  checked={
                                    selectedInstanceCount === instanceCount
                                  }
                                  indeterminate={
                                    selectedInstanceCount > 0 &&
                                    selectedInstanceCount !== instanceCount
                                  }
                                  onClick={event => event.stopPropagation()}
                                  onChange={({ target }) => {
                                    handleInstanceGroupCheckboxToggle({
                                      checkedValue: target.checked,
                                      assetType,
                                      dimensions: orderedDimensions,
                                    });
                                  }}
                                />
                                <span className="text-enabled">Select All</span>
                              </div>
                            }
                          >
                            <Collapse className="dimension-collapse-container">
                              {dimensions.map((dimension, idx) => (
                                <Collapse.Panel
                                  key={`${dimension}_${idx}`}
                                  header={
                                    <div>
                                      <span>{dimension}</span>
                                      <span className="number-counter">
                                        {
                                          props.selectedExportedAssets[
                                            assetType
                                          ]?.[dimension]?.length
                                        }
                                      </span>
                                      <Checkbox
                                        className="inner-dimension-checkbox"
                                        disabled={disableCheckbox}
                                        checked={isInnerCollapseChecked(
                                          dimension,
                                          assetType,
                                        )}
                                        indeterminate={isInnerCollapseIndeterminate(
                                          dimension,
                                          assetType,
                                        )}
                                        onClick={e => e.stopPropagation()}
                                        onChange={() => {
                                          deselectDimensionAssets(
                                            dimension,
                                            assetType,
                                          );
                                        }}
                                      />
                                      <span className="text-enabled">
                                        Select All
                                      </span>
                                    </div>
                                  }
                                  forceRender={true}
                                >
                                  {assetInstancesByDimension[dimension].map(
                                    (instance, index) => {
                                      const { template } = instance;
                                      // Old IAssetInstance type has list of offers.
                                      // This has done based on the assumption that we can selected multiple offers for one instance (like more than one carcut image in a template should have more than one offers).
                                      // This has been changed. One offer can be selected for each instance.
                                      // So when extracting the offer from IAssetInstance, we take the first one
                                      const vins = Object.keys(instance.offers);
                                      if (vins.length != 1) {
                                        message.warning(
                                          `Data corruption for order id: ${props.order?.id}`,
                                        );
                                      }

                                      const [vin] = vins;

                                      // Possible TO DO: use (selected.offerData as any)[idProp]
                                      const offerData =
                                        props.selectedOffers.find(
                                          selected =>
                                            selected.offerData.vin === vin,
                                        )?.offerData;
                                      const offerTypes = Object.keys(
                                        instance.offers[vin].offerTypes,
                                      ).filter(
                                        key =>
                                          instance.offers[vin].offerTypes[
                                            key as OfferType
                                          ],
                                      ) as OfferType[];

                                      if (!offerData || !offerTypes) {
                                        return null;
                                      }

                                      const offer = {
                                        offerData,
                                        offerTypes,
                                      };

                                      if (isEmpty(offer)) {
                                        return null;
                                      }

                                      const instanceTitle = getInstanceTitle(
                                        instance,
                                        offerData,
                                      );

                                      const exportFilename = returnAssetName(
                                        dimension,
                                        offerData,
                                        vin,
                                        assetType,
                                        index,
                                        props.order,
                                      );

                                      const isChecked =
                                        props.selectedExportedAssets[
                                          assetType
                                        ]?.[dimension]?.includes(
                                          exportFilename,
                                        );

                                      // This below filename has to be unique to each canvas because there might be a case where
                                      //  user selects same template, offer and offer types. Then there will be two or more canvases
                                      //  that has same key and exported images will be overwritten with the last canvas that comes in.
                                      // NOTE: instance.offers has to have ONLY one key (vin) because user can select ONLY one offer for each template.
                                      const imgUrl =
                                        instance.lifestyleImageUrl || "";
                                      const isBgVideo = imgUrl.endsWith("mp4");

                                      const showPlayButton =
                                        supportVideos &&
                                        (template?.mediaType === "mp4" ||
                                          isBgVideo);

                                      const playVideos =
                                        !!namesOfAssetsPlayingVideo?.[
                                          assetType
                                        ]?.[dimension]?.includes(
                                          exportFilename,
                                        );
                                      return (
                                        <div
                                          key={`previews-${assetType}-${dimension}_${index}`}
                                          className="review-collapse-panel-previews"
                                        >
                                          <div className="template-previews">
                                            <Fragment
                                              key={`fragment-key-${dimension}-${offer.offerData.vin}-${index}`}
                                            >
                                              <Divider
                                                className="template-previews-divider"
                                                dashed={false}
                                              >
                                                {instanceTitle}
                                              </Divider>

                                              <div>
                                                <div
                                                  style={{
                                                    width: "2%",
                                                    float: "left",
                                                  }}
                                                >
                                                  <Tooltip
                                                    title="Toggle inclusion of this template in selective export"
                                                    placement="topLeft"
                                                    trigger="hover"
                                                  >
                                                    {/* this is the important checkbox */}
                                                    <Checkbox
                                                      style={{
                                                        paddingTop: "0.1em",
                                                      }}
                                                      disabled={disableCheckbox}
                                                      checked={isChecked}
                                                      onChange={({
                                                        target,
                                                      }) => {
                                                        handleInstanceCheckboxToggle(
                                                          {
                                                            checkedValue:
                                                              target.checked,
                                                            assetType,
                                                            dimension,
                                                            checkedKey:
                                                              exportFilename,
                                                          },
                                                        );
                                                      }}
                                                    />
                                                  </Tooltip>
                                                </div>
                                                <div
                                                  className="dimension"
                                                  style={{
                                                    width: "98%",
                                                    float: "right",
                                                  }}
                                                >
                                                  <h3>
                                                    <u>Dimensions</u>
                                                  </h3>
                                                  <h5>Original: {dimension}</h5>
                                                </div>
                                              </div>
                                              <div
                                                key={`template-preview-${offer.offerData.vin}`}
                                                className="preview-wrapper"
                                              >
                                                <RenderAssetInstance
                                                  isCustomIntegration={
                                                    !!instance.isCustomImage
                                                  }
                                                  customIntegration={
                                                    <img
                                                      src={
                                                        instance.imageDataUrl
                                                      }
                                                      style={{
                                                        paddingLeft: "15px",
                                                      }}
                                                    />
                                                  }
                                                >
                                                  <TemplateRenderProvider
                                                    config={props.config}
                                                    offers={
                                                      props.selectedOffers
                                                    }
                                                  >
                                                    <TemplatePreviewMemo
                                                      renderTemplateCache={
                                                        cache
                                                      }
                                                      template={template}
                                                      offer={offer}
                                                      order={props.order}
                                                      selectedOffers={
                                                        props.selectedOffers
                                                      }
                                                      displayLabelBox={false}
                                                      disclosure={
                                                        instance.disclosure
                                                      }
                                                      objectVisibilities={
                                                        instance.visibilities
                                                      }
                                                      logoSubstitutions={
                                                        instance.logoSubstitutions
                                                      }
                                                      displayCanvasObjects={
                                                        false
                                                      }
                                                      lifestyleImageUrl={imgUrl}
                                                      lifestyleFabricImageJson={
                                                        instance.lifestyleFabricImageJson
                                                      }
                                                      assetInstanceCounter={() => {
                                                        props.assetInstanceCounter();
                                                      }}
                                                      exportFilename={
                                                        exportFilename
                                                      }
                                                      supportVideos={
                                                        supportVideos
                                                      }
                                                      playVideos={playVideos}
                                                      feedTabs={
                                                        props.feedTab
                                                          ? [props.feedTab]
                                                          : []
                                                      }
                                                      onRenderComplete={templateId => {
                                                        setRenderCompletedTemplateIds(
                                                          prev => {
                                                            const idNotAdded =
                                                              templateId &&
                                                              !prev.includes(
                                                                templateId,
                                                              );
                                                            if (idNotAdded) {
                                                              return [
                                                                ...prev,
                                                                templateId!,
                                                              ];
                                                            }

                                                            return prev;
                                                          },
                                                        );
                                                      }}
                                                    />
                                                  </TemplateRenderProvider>
                                                </RenderAssetInstance>
                                                {showPlayButton && (
                                                  <Divider>
                                                    <Button
                                                      type="primary"
                                                      className="control-button"
                                                      size="large"
                                                      shape="circle"
                                                      icon={
                                                        playVideos ? (
                                                          <PauseCircleOutlined />
                                                        ) : (
                                                          <PlayCircleOutlined />
                                                        )
                                                      }
                                                      title={`${
                                                        playVideos
                                                          ? "Stop"
                                                          : "Start"
                                                      } videos in this instance`}
                                                      onClick={() => {
                                                        handleInstanceCheckboxToggle(
                                                          {
                                                            checkedValue:
                                                              !playVideos,
                                                            assetType,
                                                            dimension,
                                                            checkedKey:
                                                              exportFilename,
                                                            isForPlayingVideo:
                                                              true,
                                                          },
                                                        );
                                                      }}
                                                    />
                                                  </Divider>
                                                )}
                                              </div>
                                            </Fragment>
                                          </div>
                                        </div>
                                      );
                                    },
                                  )}
                                </Collapse.Panel>
                              ))}
                            </Collapse>
                          </Collapse.Panel>
                        );
                      })}
                    </Collapse>
                  </Spin>

                  <CoopSubmissionModal
                    handleExport={exportAssets}
                    savedOrder={props.order}
                    showCoopModal={props.showCoopModal}
                    coopToSubmit={coopToSubmit}
                    setCoopToSubmit={setCoopToSubmit}
                    toggleCoopModal={props.toggleCoopModal}
                    selectedAssetBool={
                      props.assetInstances &&
                      props.selectedExportedAssets &&
                      getAssetCount(props.assetInstances) !==
                        getAssetStrCount(props.selectedExportedAssets)
                    }
                    config={props.config}
                    currentDealer={currentDealer}
                    setCurrentDealer={(currentDealer: IAccount) => {
                      setCurrentDealer(currentDealer);
                    }}
                    coopSupported={coopSupported}
                  ></CoopSubmissionModal>

                  <ReviewExportModal
                    showExportDrawer={props.showExportDrawer}
                    toggleExportDrawer={props.toggleExportDrawer}
                    handleExport={exportAssets}
                    imageOption={imageOption}
                    setImageOption={setImageOption}
                    proofHQTemplates={props.proofHQTemplates}
                    templateID={templateID}
                    setTemplateID={setTemplateID}
                    setTemplateName={setTemplateName}
                    folderID={folderID}
                    setFolderID={setFolderID}
                    setFolderName={setFolderName}
                    subject={subject}
                    setSubject={setSubject}
                    proofMessage={proofMessage}
                    setMessage={setMessage}
                    containsVideo={containsVideo}
                    feedDataToCSV={() => {
                      if (props.offersWithDisclosures && props.order) {
                        // change to the saved order
                        props.feedDataToCSV(
                          props.offersWithDisclosures,
                          props.order.wfFullProjectName,
                        );
                        setRenderUploadIcon(true);
                        toggleResultModal(true);
                        setExportTypes(exportTypes => [...exportTypes, "CSV"]);
                      }
                    }}
                    feedDataToPDF={() => {
                      const { wfProjectNumber } = props.order || {
                        wfProjectNumber: "",
                      };
                      props.currentUser &&
                        props.feedDataToPDF(
                          props.offersWithDisclosures,
                          props.currentUser,
                          wfProjectNumber,
                        );
                      setExportTypes(exportTypes => [
                        ...exportTypes,
                        "OFFERPDF",
                      ]);
                      setRenderUploadIcon(true);
                      toggleResultModal(true);
                      setProcessingFeedPDF(true);
                    }}
                    wfFolderList={props.wfFolderList}
                    includeAssets={includeAssets}
                    includeCSV={includeCSV}
                    includePDF={includePDF}
                    setIncludeAssets={setIncludeAssets}
                    setIncludeCSV={setIncludeCSV}
                    setIncludePDF={setIncludePDF}
                    wfProofArr={props.wfProofArr}
                    proofName={proofName}
                    setProofName={setProofName}
                    setSelectedProofToUpdate={selectedProof => {
                      setSelectedProofToUpdate(selectedProof);
                    }}
                    setUseVersioning={setUseVersioning}
                    proofUploadData={props.order?.proofUploadData}
                    enableWorkfront={props.enableWorkfront}
                    selectedAssetBool={
                      props.assetInstances &&
                      props.selectedExportedAssets &&
                      getAssetCount(props.assetInstances) !==
                        getAssetStrCount(props.selectedExportedAssets)
                    }
                    exportAsHtml={async () => {
                      setExportTypes(types => [...types, "HTML"]);
                      setBeingProcesseedTypes(types => [...types, "HTML"]);
                      setRenderUploadIcon(true);
                      toggleResultModal(true);

                      const exportingFilenameObject =
                        returnInitialAssetsToExport(
                          props.assetInstances,
                          props.selectedOffers,
                          false,
                          props.order,
                        );

                      // collect only filenames and create an array
                      let filenames: string[] = [];
                      for (const assetType in exportingFilenameObject) {
                        for (const size in exportingFilenameObject[assetType]) {
                          filenames = filenames.concat(
                            exportingFilenameObject[assetType][size],
                          );
                        }
                      }

                      const canvases =
                        renderTemplateContext?.getExportingCanvases(filenames);

                      // It might be case that user selected portion of the assets.
                      // We have to excluded canvases that are not selected.
                      let selectedFilenames: string[] = [];
                      for (const assetType in props.selectedExportedAssets) {
                        for (const size in props.selectedExportedAssets[
                          assetType
                        ]) {
                          selectedFilenames = [
                            ...selectedFilenames,
                            ...props.selectedExportedAssets[assetType][size],
                          ];
                        }
                      }

                      Object.keys(canvases || {}).forEach(filename => {
                        if (!selectedFilenames.includes(filename))
                          delete canvases?.[filename];
                      });

                      setAssetSizeState(Object.keys(canvases || {}).length);

                      if (!canvases) {
                        message.error(
                          "There was an error while processing instances.",
                        );

                        return;
                      }

                      const canvasData = getCanvasDataForRequest(canvases);
                      setIsHtmlExport(true);
                      const requestBodyForZip = canvasData.map(canvas => {
                        return {
                          ...canvas,
                          imageOption: "html",
                        };
                      }) as IZipExport[];

                      props.startVideoHtmlExport(requestBodyForZip);

                      setBeingProcesseedTypes(types =>
                        types.filter(type => type !== "HTML"),
                      );
                    }}
                  />
                </div>
              ),
            },
          ]}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  const {
    assetBuilder: { savedOrder },
    configuration: { config },
    auth,
  } = state;

  // pre-process the assetInstances
  let assetInstances: Record<string, Record<string, IAssetInstance[]>> = {};
  if (savedOrder?.assetInstances) {
    assetInstances = removePurchasePlaceholder(savedOrder?.assetInstances);
  }

  const { assetBuilder, newOrders } = state;
  const { currentSelectedOrder } = newOrders;
  const {
    s3Url,
    pdfUrl,
    feedPDFUrl,
    canvasIDArr,
    processingExport,
    generalMessage,
    errorMessage,
    disableExport,
    proofHQTemplates,
    offersWithDisclosures,
    wfFolderList,
    selectedExportedAssets,
    redirect,
    executionId,
    pdfJobStatus,
    exportedAssets,
    exportAssetsUploaded,
    isExportCompleted,
    wfProofArr,
    imageExports,
    processingImageExport,
  } = assetBuilder as IAssetBuilderState;

  const { user } = auth;
  return {
    selectedOffers: savedOrder?.selectedOffers || [],
    assetInstances,
    s3Url,
    pdfUrl,
    feedPDFUrl,
    order: savedOrder && (savedOrder as ISavedOrderState).selectedOrder,
    orderFeedTab: savedOrder && (savedOrder as ISavedOrderState).currentTab,
    canvasIDArr,
    processingExport,
    generalMessage,
    errorMessage,
    disableExport,
    proofHQTemplates,
    currentSelectedOrder,
    offersWithDisclosures,
    wfFolderList,
    config,
    enableWorkfront: config?.featureFlags?.enableWorkfront
      ? config?.featureFlags?.enableWorkfront
      : false,
    selectedExportedAssets,
    redirect,
    enableLauncher: config?.featureFlags?.enableLauncher
      ? config?.featureFlags?.enableLauncher
      : false,
    currentUser: user.name || "",
    userEmail: user.email || "",
    executionId,
    pdfJobStatus,
    exportedAssets,
    exportAssetsUploaded,
    isExportCompleted,
    wfProofArr: wfProofArr || [],
    imageExports,
    processingImageExport,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => {
  return {
    fetchExportZipUrl: (
      exportedAssets: IExportedAssetStatus[],
      ignoreDownload: boolean,
    ) => {
      dispatch(
        actions.assetBuilder.fetchExportZipUrl(exportedAssets, ignoreDownload),
      );
    },
    fetchExportState: (exportedAssets: IExportedAssetStatus[]) => {
      dispatch(actions.assetBuilder.fetchExportState(exportedAssets));
    },
    startVideoHtmlExport: (requestBody: IZipExport[], dest?: string) => {
      dispatch(actions.assetBuilder.startVideoHtmlExport(requestBody, dest));
    },
    generateCanvasZipUrl: (
      images: string[],
      templateAndDimensionsArr: string[],
      wfID: string,
      store233: string,
      exportingVideo: boolean,
    ) => {
      dispatch(
        actions.assetBuilder.generateCanvasZipUrl(
          images,
          templateAndDimensionsArr,
          wfID,
          store233,
          exportingVideo,
        ),
      );
    },
    generatePDF: (
      images: string[],
      templateAndDimensionsArr: string[],
      feedData: IOfferWithDisclosure[],
      userName: string,
      projectName: string,
      currentDate: string,
      customInstanceDisclosures?: IPDFCustomDisclosure[][],
    ) => {
      dispatch(
        actions.assetBuilder.generatePDF(
          images,
          templateAndDimensionsArr,
          feedData,
          userName,
          projectName,
          currentDate,
          customInstanceDisclosures,
        ),
      );
    },
    feedDataToPDF: (
      feedData: IOfferWithDisclosure[],
      userName: string,
      projectName: string,
    ) => {
      dispatch(
        actions.assetBuilder.feedDataToPDF(feedData, userName, projectName),
      );
    },
    coopSubmission: (
      images: string[],
      templateAndDimensionsArr: string[],
      feedData: IOfferWithDisclosure[],
      userName: string,
      projectName: string,
      coopData: ICoopIntegrationData,
      order: INewOrder,
      currentDate: string,
    ) => {
      dispatch(
        actions.assetBuilder.coopSubmission(
          images,
          templateAndDimensionsArr,
          feedData,
          userName,
          projectName,
          coopData,
          order,
          currentDate,
        ),
      );
    },
    getDealer: (dealerName: string) => {
      dispatch(actions.dealerManagement.getDealer(dealerName));
    },
    uploadCanvasImage: (canvas: string, templateAndDimensions: string) => {
      dispatch(
        actions.assetBuilder.uploadCanvasImage(canvas, templateAndDimensions),
      );
    },
    updateExportedAssetsArr: (
      assetNames: Record<string, Record<string, string[]>>,
    ) => {
      dispatch(actions.assetBuilder.updateExportedAssetsArr(assetNames));
    },
    pushToProof: (
      canvas: string[],
      templateAndDimensions: string[],
      wfProject: string,
      wfID: string,
      templateID: string,
      subject: string,
      proofMessage: string,
      folderID: string,
      parentFileToken: string,
      documentID: string,
      updatedOrder: INewOrder,
      feedData: IOfferWithDisclosure[],
      orderID: string,
      userName: string,
      projectName: string,
      includeAssets: boolean,
      includePDF: boolean,
      includeCSV: boolean,
      currentDate: string,
      proofName: string,
      selectedProofArr: IWorkfrontProofData | null,
      selectedProofKey: string | null,
      customInstanceDisclosures?: IPDFCustomDisclosure[][],
    ) => {
      dispatch(
        actions.assetBuilder.pushToProof(
          canvas,
          templateAndDimensions,
          wfProject,
          wfID,
          templateID,
          subject,
          proofMessage,
          folderID,
          parentFileToken,
          documentID,
          updatedOrder,
          feedData,
          orderID,
          userName,
          projectName,
          includeAssets,
          includePDF,
          includeCSV,
          currentDate,
          proofName,
          selectedProofArr,
          selectedProofKey,
          customInstanceDisclosures,
        ),
      );
    },
    feedDataToCSV: (feedData: IOfferWithDisclosure[], orderID: string) => {
      dispatch(actions.assetBuilder.feedDataToCSV(feedData, orderID));
    },

    updateNewOrder: (updateNewOrder: Partial<INewOrderRecord>) => {
      dispatch(actions.newOrders.updateNewOrder(updateNewOrder));
    },

    updateDealer: (inputDealer: IAccountRecord) => {
      dispatch(actions.dealerManagement.updateDealer(inputDealer));
    },

    setAssetInstanceComparator: (assetInstances: AssetInstanceRecord) => {
      dispatch(actions.assetBuilder.setAssetInstanceComparator(assetInstances));
    },

    assetInstanceCounter: () => {
      dispatch(actions.assetBuilder.assetInstanceCounter());
    },

    setOfferWithDisclosure: (offerData: IOfferWithDisclosure) => {
      dispatch(actions.assetBuilder.setOfferWithDisclosure(offerData));
    },

    resetAssetInstanceCounter: () => {
      dispatch(actions.assetBuilder.resetAssetInstanceCounter());
    },

    getProofTemplates: () => {
      dispatch(actions.assetBuilder.getProofTemplates());
    },

    getWorkfrontFolders: (wfID: string, wfProject: string) => {
      dispatch(actions.assetBuilder.getWorkfrontFolders(wfID, wfProject));
    },
    generateImagesForLauncherPage: (
      images: string[],
      templateAndDimensionsArr: string[],
    ) => {
      dispatch(
        actions.assetBuilder.generateImagesForLauncherPage(
          images,
          templateAndDimensionsArr,
        ),
      );
    },
    enableExportButton: () => {
      dispatch(actions.assetBuilder.enableExport());
    },

    disableExportButton: () => {
      dispatch(actions.assetBuilder.disableExport());
    },

    resetPdfUrl: () => {
      dispatch(actions.assetBuilder.resetPdfUrl());
    },
    proofExportData: (orderId: string) => {
      dispatch(actions.assetBuilder.proofExportData(orderId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Review);
