import React, { createContext, useState, useContext } from "react";

interface MediaPreviewContextType {
  zoomPercentage: number;
  isFitToScreen: boolean;
  setZoomPercentage: React.Dispatch<React.SetStateAction<number>>;
  setIsFitToScreen: React.Dispatch<React.SetStateAction<boolean>>;
  clearMediaPreview: () => void;
}

const MediaPreviewContext = createContext<MediaPreviewContextType | undefined>(
  undefined,
);

export const MediaPreviewProvider: React.FC = ({ children }) => {
  const [zoomPercentage, setZoomPercentage] = useState(100);
  const [isFitToScreen, setIsFitToScreen] = useState(false);

  const clearMediaPreview = () => {
    setZoomPercentage(100);
    setIsFitToScreen(false);
  };

  const value = {
    zoomPercentage,
    setZoomPercentage,
    isFitToScreen,
    setIsFitToScreen,
    clearMediaPreview,
  };

  return (
    <MediaPreviewContext.Provider value={value}>
      {children}
    </MediaPreviewContext.Provider>
  );
};

export const useMediaPreviewContext = (): MediaPreviewContextType => {
  const context = useContext(MediaPreviewContext);
  if (!context) {
    throw new Error(
      "useMediaPreviewContext must be used within a MediaPreviewProvider",
    );
  }
  return context;
};
