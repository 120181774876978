import { updateDurationWithCompositionId } from "../../assetBatchDrawerV2/manageVariables/panelVariables/utils";
import { TComposition } from "shared/types/assetExporter";
import { successNotification } from "shared/components/customNotification/Notification";
import { useAssetBatchesContext } from "../contexts/AssetBatchesContext";
import { MAX_VIDEO_DURATION } from "shared/constants/assetExporter";

export const useCompositionDuration = () => {
  const { setCompositions } = useAssetBatchesContext();

  const calculateTotalDuration = (compositions: TComposition[]) => {
    return (
      compositions
        .flatMap(comp => comp.duration)
        .reduce((acc, curr) => acc + curr, 0) ?? 0
    );
  };

  const manageDurationExceeded = (
    duration: number,
    composition: TComposition,
    newCompositions: TComposition[],
    setInputValue?: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    const totalDuration = calculateTotalDuration(newCompositions);
    if (totalDuration <= MAX_VIDEO_DURATION) {
      setCompositions(newCompositions);
      return;
    }

    const adjustedDuration = MAX_VIDEO_DURATION - (totalDuration - duration);
    setInputValue && setInputValue(`${adjustedDuration}`);

    const adjustedCompositions = updateDurationWithCompositionId(
      newCompositions,
      composition.compositionId,
      adjustedDuration,
    );

    setCompositions([...adjustedCompositions]);

    successNotification({
      messageLabel: `We adjusted your duration from ${duration}s to ${adjustedDuration}s since total video limit is ${MAX_VIDEO_DURATION}s`,
      key: `durationAlert-${composition.compositionId}`,
      duration: 5,
      size: "big",
    });
  };

  return { manageDurationExceeded, calculateTotalDuration };
};
