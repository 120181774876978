import { useCallback, useState } from "react";
import styles from "./SimpleUpload.module.scss";
import { Modal, Upload, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { RcFile, UploadFile, UploadProps } from "antd/lib/upload/interface";
import * as utils from "./UploadAsset.utils";
import * as helpers from "utils/helpers";
import { AccountLogo } from "shared/types/salesEnablement";
import { validateLogo } from "shared/components/uploadAsset/UploadAsset.utils";

interface Props {
  logos?: AccountLogo[];
  setLogos?: (logos: AccountLogo[]) => void;
  disabled?: boolean;
}

export const SimpleUpload = ({ disabled, logos = [], setLogos }: Props) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [error, setError] = useState<string | null>(null);

  if (!setLogos) throw new Error("setLogos is required");

  const onLogoChange = useCallback(
    ({
      isAdding,
      updatedFile,
    }: {
      isAdding: boolean;
      updatedFile: AccountLogo;
    }) => {
      const newLogos = isAdding
        ? [...logos, updatedFile]
        : logos?.filter(f => f.uid !== updatedFile.uid);

      if (!isAdding || updatedFile.status === "error") {
        const hasError = newLogos.some(logo => logo.status === "error");
        if (!hasError) {
          setError(null);
        }
      }

      setLogos(newLogos);
    },
    [setLogos, logos, setError],
  );

  const onChange: UploadProps["onChange"] = async ({
    file: currentFile,
    fileList: updatedFileList,
  }) => {
    const isAdding = updatedFileList.length > logos.length;
    const file = isAdding
      ? updatedFileList[updatedFileList.length - 1]
      : currentFile;

    if (!file) return;

    if (isAdding) {
      try {
        await validateLogo(file.originFileObj as RcFile);

        const dimensions = await utils.getDimensions(file);
        const updatedFile: AccountLogo = {
          ...file,
          name: helpers.generateGuidFilename(file.name),
          thumbUrl: await utils.toBase64(file.originFileObj),
          size: file.size,
          type: file.type,
          lastModifiedDate: file.lastModifiedDate,
          dimensions,
        };

        onLogoChange({ isAdding: true, updatedFile });
        setError(null);
      } catch (err) {
        const error = err as Error;
        const errorFile: AccountLogo = {
          uid: file.uid,
          name: file.name,
          status: "error",
        };
        onLogoChange({ isAdding: true, updatedFile: errorFile });
        setError(error.message);
      }
    } else {
      onLogoChange({ isAdding: false, updatedFile: file });
    }
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await utils.getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  return (
    <>
      <Upload
        beforeUpload={() => false}
        accept="image/*"
        listType="picture-card"
        fileList={logos}
        onChange={onChange}
        data-cy={"logos"}
        disabled={disabled}
        onPreview={handlePreview}
      >
        {!error && logos.length < 5 && (
          <div className={styles.uploadButton}>
            <PlusOutlined />
            <div>Upload</div>
          </div>
        )}
      </Upload>
      {error && <Typography.Text type="danger">{error}</Typography.Text>}
      <Modal
        footer={null}
        onCancel={() => setPreviewOpen(false)}
        visible={previewOpen}
      >
        <img style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};
