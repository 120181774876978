import { CSSProperties } from "react";
import { ReactComponent as AflacAccount } from "statics/images/svg/aflac-account-logo.svg";

export interface IProps {
  width?: string | number;
  height?: string | number;
  style?: CSSProperties;
}

export const AflacAccountIcon = ({
  width = "1em",
  height = "1em",
  style,
}: IProps) => {
  return (
    <span role="img" aria-label="columns" className="anticon" style={style}>
      <AflacAccount width={width} height={height} />
    </span>
  );
};
