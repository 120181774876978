import { message } from "antd";
import useDeepEffect from "shared/hooks/useDeepEffect";
import {
  IDimension,
  IExtendedFabricObject,
  imageType,
  isCanvasBackground,
  IStamp,
  LogoDropZoneType,
  LogoEventType,
  TShape,
} from "shared/types/designStudio";
import { insert } from "utils/canvas/helpers.insert";
import { fabric } from "fabric";
import { getHeight, getWidth } from "utils/fabric/helpers.utils";
import GenericError from "shared/errors/GenericError";

export type TImageInsertData = {
  type: imageType;
  data?:
    | Partial<IStamp>
    | TShape
    | TFileType
    | TLogoDropZone
    | boolean
    | string;
};

export type TFileType = {
  dataUri: string;
  sourceUrl?: string; // If this is present, it means the file was uploaded to s3. Use this if it is valid url.
  file: { type: string; size: number };
  element?: HTMLInputElement | HTMLVideoElement;
};

export type TLogoDropZone = {
  logoEventType: LogoEventType;
  logoDropZoneType: LogoDropZoneType;
  position?: {
    top: number;
    left: number;
  };
  logoDimension?: IDimension;
};

export default (args: {
  canvas?: fabric.Canvas;
  canvasArea?: IExtendedFabricObject;
  imageInsertData?: TImageInsertData;
  canvasDimension: IDimension;
  canvasAreaMargin: {
    top: number;
    left: number;
  };
  onStart?: (imageInsertData: TImageInsertData) => void;
  onComplete?: (
    imageInsertData: TImageInsertData,
    image: IExtendedFabricObject | null,
  ) => void;
  onError?: (error: GenericError) => void;
}) => {
  useDeepEffect(() => {
    if (!args.imageInsertData) {
      // If args.imageInsertData is falsy, it means it has been assigned or resetting.

      return;
    }

    const messageId = `loading-id-for-${args.imageInsertData.type}`;

    message.loading({
      key: messageId,
      content: `Inserting media...`,
    });

    args.onStart?.(args.imageInsertData);

    const { canvasDimension, canvasAreaMargin, imageInsertData } = args;
    insert({
      canvasDimension,
      canvasAreaMargin,
      imageInsertData,
    })
      .then(object => {
        if (!object || !args.canvasArea) {
          if (!object) {
            message.error(`${args.imageInsertData?.type} cannot be inserted.`);
          } else {
            message.error("There was system error."); // canvasArea must be present
          }

          return;
        }

        (object as fabric.Object)?.set({
          clipPath: args.canvasArea,
        });
        args.canvas?.add(object).renderAll();

        // After completed object creation,
        //  attach event handler if needed
        switch (args.imageInsertData?.type) {
          // TODO: continue on text insertion ticket
          case "TEXT":
            break;

          case "SELECTED_VIDEO":
            fabric.util.requestAnimFrame(function render() {
              try {
                args.canvas?.renderAll();
                fabric.util.requestAnimFrame(render);
              } catch (error) {
                /*
             Sometimes, crashing occurs when the user
             is about to switch screens. Some sort of
             animation stopping has to be added OR
             just keep this here
           */
              }
            });
            break;

          case "THEME_BACKGROUND":
            // we need to resize the background image to fit the canvasArea
            const { top, left } = args.canvasArea;
            object.set({
              top,
              left,
              width: getWidth(args.canvasArea),
              height: getHeight(args.canvasArea),
            });

            // NOTE: here, we are moving the theme background to the index 1.
            //       This is because canvasAread must be at index 0 otherwise, this theme background will be covered by canvasArea
            args.canvas?.moveTo(object, 1);
            break;

          case "BACKGROUND":
            // first, remove existing canvas_bg type. There should be only one canvas background.
            const bgImages = args.canvas
              ?.getObjects()
              .filter(obj => isCanvasBackground(obj as IExtendedFabricObject));
            if (bgImages) args.canvas?.remove(...bgImages);

            const { width, height } = canvasDimension;

            // We have to fit this image into the canvasArea.
            // Find <original dimension>/<new dimension> and fit to the bigger side.
            const backgroundImage = object as unknown as fabric.Image;
            const bgWidth = getWidth(backgroundImage);
            const bgHeight = getHeight(backgroundImage);

            const widthRatio = width / bgWidth;
            const heightRetio = height / bgHeight;

            widthRatio > heightRetio
              ? backgroundImage.scaleToWidth(width)
              : backgroundImage.scaleToHeight(height);

            backgroundImage.set({
              top: args.canvasArea.top,
              left: args.canvasArea.left,
            });

            args.canvas?.moveTo(backgroundImage, 1);
            break;
        }

        message.success({
          key: messageId,
          content: `Selected object successfully inserted!`,
          duration: 3,
        });

        args.onComplete?.(args.imageInsertData!, object);
      })
      .catch(err => {
        message.error({
          key: messageId,
          content: `Attempted to insert selected object but failed.`,
          duration: 3,
        });
        args.onError?.(
          new GenericError({
            message: err.message
              ? err.message
              : `${args.imageInsertData?.type} insert failed.`,
          }),
        );
      });
  }, [args.imageInsertData]);
};
