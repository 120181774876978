import { message } from "antd";
import { useMemo } from "react";
import { DataNode } from "antd/lib/tree";
import { IFlippedFacebookCampaignRes } from "shared/types/adReview";
import styles from "../../FlipperDrawer.module.scss";

export const useFlipStatus = (
  treeData: DataNode[],
  campaignRes: IFlippedFacebookCampaignRes[],
) => {
  const totalCnt = treeData.reduce((acc, cur) => {
    const adsetCnt = cur?.children?.length || 0;
    return acc + adsetCnt + 1;
  }, 0) as number;
  const flippedCnt =
    campaignRes
      ?.filter(c => c.status === "fulfilled")
      ?.reduce((acc, cur) => {
        const adsetCnt = cur?.value?.adsets?.length || 0;
        return acc + adsetCnt + 1;
      }, 0) || 0;

  const failedCnt =
    campaignRes
      ?.filter(c => c.status === "rejected")
      ?.reduce((acc, cur) => {
        const adsetCnt = cur?.reason?.adsets?.length || 0;
        return acc + adsetCnt + 1;
      }, 0) || 0;

  const loadingCnt = totalCnt - flippedCnt - failedCnt;

  const successfulFlips = useMemo(
    () => campaignRes?.filter(c => c.status === "fulfilled").flat() || [],
    [campaignRes],
  );

  useMemo(
    () =>
      totalCnt === flippedCnt &&
      message.success({
        className: styles.successMessage,
        content: "Successfully flipped all campaigns",
      }),
    [totalCnt, flippedCnt],
  );

  const dataSource = useMemo(() => {
    if (!successfulFlips.length && !failedCnt) return treeData;

    return treeData.map(d => {
      const errMessage =
        campaignRes.find(c => c?.reason?.title === d?.title)?.reason?.message ||
        "Error occurred while flipping campaign";

      const successfullyFlippedCampaign = successfulFlips.find(
        campaign => campaign?.value?.oldId === d?.key,
      );

      if (successfullyFlippedCampaign) {
        return {
          ...d,
          status: "success",
          title: successfullyFlippedCampaign?.value?.title,
          children: d?.children?.map(c => {
            const successFullyFlippedAdSet =
              successfullyFlippedCampaign?.value?.adsets?.find(
                adSet => adSet?.oldId === c?.key,
              );
            if (successFullyFlippedAdSet) {
              return {
                ...c,
                title: successFullyFlippedAdSet?.title,
                status: "success",
              };
            } else {
              return {
                ...c,
                status: "error",
                message: errMessage,
              };
            }
          }),
        };
      } else {
        return {
          ...d,
          status: "error",
          message: errMessage,
          children: d?.children?.map(c => {
            return {
              ...c,
              status: "error",
              message: errMessage,
            };
          }),
        };
      }
    });
  }, [treeData, successfulFlips, failedCnt, campaignRes]);

  return { totalCnt, loadingCnt, flippedCnt, failedCnt, dataSource };
};
