import { purchaseOptions as purchaseOptionOfferTypes } from "shared/constants/dataManagement";
import {
  AssetInstanceRecord,
  IAssetBuild,
  IAssetBuildInstance,
  InstanceCheckboxDict,
  IAssetInstance,
  ISelectedOffer,
  OfferData,
  RawSelectedOffers,
} from "shared/types/assetBuilder";
import { INewOrder } from "shared/types/newOrders";
import { OfferType } from "shared/types/shared";
import {
  LogoEventType,
  LogoDropZoneType,
  IExtendedFabricObject,
  ILogoObjectData,
} from "shared/types/designStudio";
import { IBox } from "shared/components/templatePreview/CardToggle";
import { IBrand } from "shared/types/brandManagement";
import { IAccount } from "shared/types/accountManagement";
import { IUploadImageFormInput } from "shared/types/uploadManagement";
import { isEmpty, orderBy } from "lodash";
import { convertInstanceOffers } from "./helpers";
import uuid from "uuid";

// NOTE: this will be temporary transformation function for IAssetBuild type
export const assetInstancesToAssetBuild = (
  assetInstances: Record<string, Record<string, IAssetInstance[]>>,
  availableOffers: ISelectedOffer[],
): IAssetBuild => {
  const offers = availableOffers.map(data => ({
    offerData: data.offerData,
    savedOfferTypes: data.offers,
  }));

  const instances: IAssetBuild["instances"] = {};

  for (const assetType in assetInstances) {
    for (const size in assetInstances[assetType]) {
      const assetInstanceArray: IAssetInstance[] =
        assetInstances[assetType][size];

      const assetBuildInstances: IAssetBuildInstance[] = [];

      for (const assetInstance of assetInstanceArray) {
        const { template, offers: currentOffers, id } = assetInstance;
        const producedId = id || uuid.v4();
        if (!template || Object.keys(currentOffers).length === 0) {
          // NOTE: when +Instance button is clicked, it is adding empty asset instance.
          assetBuildInstances.push({ id: producedId });

          continue;
        }

        const offer = currentOffers[Object.keys(currentOffers)[0]];

        if (!offer) {
          continue;
        }

        // handling purchaseTypes
        // any selected purchaseTypes will be combined into offerTypes.
        // so we need to split this offerTypes into two:
        //  1. purchaseOptions
        //  2. rest of the offer types
        const { offerTypes } = offer;

        // AV2-2963: Order Stamps based on offerType selection order
        const offerSelectSortingTracker = Object.keys(
          offerTypes,
        ) as OfferType[];

        const purchaseOptions = Object.keys(offerTypes).filter(offerType => {
          return purchaseOptionOfferTypes.includes(offerType as OfferType);
        }) as OfferType[];

        const filteredOfferTypes = Object.keys(offer.offerTypes)
          .filter(
            offerType =>
              !purchaseOptionOfferTypes.includes(offerType as OfferType) &&
              offer.offerTypes[offerType as OfferType],
          ) // [{ Lease: true | false }]
          .map(offerType => offerType as OfferType) as (
          | OfferType
          | "PurchasePlaceholder"
        )[]; // get the name of theo offerType only

        // "PurchasePlaceholder" is needed for handling Purchase option checkbox logic.
        // So if purchaseOptions is not empty, and "PurchasePlaceholder" is not in the offerTypes, add one.
        if (
          purchaseOptions.length > 0 &&
          !filteredOfferTypes.find(
            offerType => (offerType as string) === "PurchasePlaceholder",
          )
        ) {
          filteredOfferTypes.push("PurchasePlaceholder");
        }

        const { offerData } = offer;
        const { vin } = offerData;

        const {
          visibilities,
          logoSubstitutions,
          lifestyleImageUrl,
          lifestyleImageName,
          lifestyleFabricImageJson,
          disclosure,
          imageDataUrl,
          isCustomImage,
        } = assetInstance;
        assetBuildInstances.push({
          id: producedId,
          template,
          selectedOffer: {
            vin,
            offerData,
            offerTypes: filteredOfferTypes,
            purchaseOptions,
            offerSelectSortingTracker,
          },
          visibilities,
          logoSubstitutions,
          imageDataUrl,
          isCustomImage,
          lifestyleImageUrl,
          lifestyleImageName,
          lifestyleFabricImageJson,
          disclosure,
        });
      }

      if (!instances[assetType]) {
        instances[assetType] = {
          [size]: assetBuildInstances,
        };
      } else {
        instances[assetType][size] = assetBuildInstances;
      }
    }
  }

  return {
    offers,
    templates: [],
    instances,
  };
};

export const assetBuildInstanceToAssetInstance = (
  assetBuildInstance: IAssetBuildInstance,
): IAssetInstance => {
  const { template, selectedOffer, id } = assetBuildInstance;

  if (!template || !selectedOffer) {
    return { id } as IAssetInstance;
  }

  const { vin, offerData, offerTypes, purchaseOptions } = selectedOffer;

  // AV2-2963: put the purchaseOptions in-place instead of at end of offerTypes
  const placeholderIndex = offerTypes.findIndex(
    offerType => offerType === "PurchasePlaceholder",
  );

  let newOfferTypes: (OfferType | "PurchasePlaceholder")[] = [];

  for (let i = 0; i < offerTypes.length; i++) {
    if (i !== placeholderIndex) {
      newOfferTypes.push(offerTypes[i]);
      continue;
    }
    newOfferTypes = newOfferTypes.concat([offerTypes[i], ...purchaseOptions]);
  }

  const offers = {
    [vin]: {
      offerData,
      offerTypes: newOfferTypes.reduce<Record<string, boolean>>(
        (acc, offerType) => Object.assign(acc, { [offerType]: true }),
        {},
      ),
    },
  };

  const {
    visibilities,
    logoSubstitutions,
    lifestyleImageUrl,
    lifestyleImageName,
    lifestyleFabricImageJson,
    disclosure,
    isCustomImage,
    imageDataUrl,
  } = assetBuildInstance;

  return {
    id,
    offers,
    template,
    visibilities,
    logoSubstitutions,
    lifestyleImageUrl,
    lifestyleImageName,
    lifestyleFabricImageJson,
    isCustomImage,
    imageDataUrl,
    disclosure,
  };
};

export const removePurchasePlaceholder = (
  assetInstances: Record<string, Record<string, IAssetInstance[]>>,
) => {
  const instances: Record<string, Record<string, IAssetInstance[]>> = {};
  for (const assetType of Object.keys(assetInstances)) {
    for (const size of Object.keys(assetInstances[assetType])) {
      const aassetInstanceArray: IAssetInstance[] =
        assetInstances[assetType][size];

      const assetBuildInstances: IAssetInstance[] = [];

      for (const assetInstance of aassetInstanceArray) {
        if (!assetInstance) {
          continue;
        }

        const { template, offers } = assetInstance;
        if (!template) {
          continue;
        }

        const updatedOffers: Record<
          string,
          {
            offerData: OfferData;
            offerTypes: Record<OfferType | "PurchasePlaceholder", boolean>;
          }
        > = {};

        for (const vin of Object.keys(offers)) {
          const current = offers[vin] as {
            offerData: OfferData;
            offerTypes: Record<OfferType | "PurchasePlaceholder", boolean>;
          };

          const updatedOfferTypes = Object.keys(current.offerTypes).reduce(
            (prev, curr) => {
              const key = curr as OfferType | "PurchasePlaceholder";
              if (key === "PurchasePlaceholder") return prev;

              prev[key] = current.offerTypes[key];

              return prev;
            },
            {} as Record<OfferType | "PurchasePlaceholder", boolean>,
          );

          updatedOffers[vin] = {
            offerData: offers[vin].offerData,
            offerTypes: updatedOfferTypes,
          };
        }

        assetBuildInstances.push({
          ...assetInstance,
          template,
          offers: {
            ...updatedOffers,
          },
        });
      }

      if (!instances[assetType]) {
        instances[assetType] = {
          [size]: assetBuildInstances,
        };
      } else {
        instances[assetType][size] = assetBuildInstances;
      }
    }
  }

  return instances;
};

export const returnPropToUse = (
  logoEventType: LogoEventType,
  logoDropZoneType: LogoDropZoneType,
) => {
  let propsToUse = [];
  switch (logoEventType) {
    case "OEM_LOGO":
    case "BRAND_LOGO":
      propsToUse = [
        "horizontalImagesFromS3",
        "squareImagesFromS3",
        "verticalImagesFromS3",
      ];
      break;
    case "SALES_EVENT_LOGO":
      propsToUse = [
        "horizontalEventImagesFromS3",
        "squareEventImagesFromS3",
        "verticalEventImagesFromS3",
      ];
      break;
    case "STORE_LOGO":
    case "ACCOUNT_LOGO":
      propsToUse = [
        "horizontalImagesFromS3",
        "squareImagesFromS3",
        "verticalImagesFromS3",
      ];
      break;

    default:
      propsToUse = ["horizontal", "vertical", "square"];
      break;
  }

  const finalProp = propsToUse.find(propString =>
    propString.includes(logoDropZoneType),
  );
  return finalProp;
};

export const returnLogoOptions = (
  matchingObj: IExtendedFabricObject,
  toggledBox: IBox,
  currentBrandData?: IBrand,
  currentStoreData?: IAccount,
) => {
  const { customData } = matchingObj;
  const { logoDropZoneType, logoEventType } = customData as ILogoObjectData;

  const isBrandLogo = ["OEM_LOGO", "BRAND_LOGO", "SALES_EVENT_LOGO"].includes(
    logoEventType,
  );
  const finalProp = returnPropToUse(logoEventType, logoDropZoneType); // horizontalImagesFromS3, vertical..., square...

  if (!finalProp) {
    return [];
  }

  if (isBrandLogo) {
    let finalLogos: string[] = [];

    const trimmedLogoUrls = returnTrimmedLogoUrls(
      currentBrandData?.logo_urls_from_S3 || "",
      finalProp,
    );

    finalLogos = finalLogos.concat(trimmedLogoUrls);
    return Array.from(new Set(finalLogos));
  }

  // Getting the store logos. Reminder, there will not be multiple stores but could be multiple logos
  const storeLogoJsonString = currentStoreData?.logo_urls_from_S3 || "";

  if (!storeLogoJsonString) return [];

  const logoUrlsObject = JSON.parse(storeLogoJsonString);

  const storeLogosToUse = logoUrlsObject[finalProp];

  return storeLogosToUse;
};

const returnTrimmedLogoUrls = (logoJsonString: string, finalProp: string) => {
  if (!logoJsonString) return [];

  const logoUrlsObject = JSON.parse(logoJsonString);
  const logosToUse = logoUrlsObject[finalProp];
  const logoUrlsArray = logosToUse as string[];
  const trimmedLogoUrls = logoUrlsArray.map(logo => logo.replace(/\?.*/g, ""));
  return trimmedLogoUrls;
};

export const returnReplacedLogoUrl = (
  logoUrlsArray: string[],
  currentImageUrl: string,
  direction: "back" | "forward",
) => {
  const currentIndex = logoUrlsArray.findIndex(
    logo => logo === currentImageUrl,
  );

  let newCurrentImageUrl = currentImageUrl;

  let newIndex = currentIndex;

  if (currentIndex !== -1 && logoUrlsArray.length > 0) {
    if (direction === "forward") {
      newIndex = newIndex + 1;
      newIndex = newIndex % logoUrlsArray.length;
    } else {
      if (newIndex === 0) {
        newIndex = logoUrlsArray.length;
      }
      newIndex = newIndex - 1;
    }
    newCurrentImageUrl = logoUrlsArray[newIndex];
  }

  return newCurrentImageUrl;
};

export const returnUploadMediaBatches = (
  uploadMediaObjs: IUploadImageFormInput[],
  batchSizeLimit = 5,
) => {
  let tempUploadArr: IUploadImageFormInput[] = [];
  const uploadMediaBatches: IUploadImageFormInput[][] = [];

  let currentMb = 0;

  for (const uploadObj of uploadMediaObjs) {
    const currentFile = (uploadObj?.file as string) || "";
    if (!currentFile) {
      continue;
    }
    const currentFileSize = currentFile.length / 1000000;

    if (currentMb + currentFileSize > batchSizeLimit) {
      currentMb = currentFileSize;
      uploadMediaBatches.push(tempUploadArr);
      tempUploadArr = [uploadObj];
      continue;
    }
    tempUploadArr.push(uploadObj);
    currentMb += currentFileSize;
  }

  uploadMediaBatches.push(tempUploadArr);

  return uploadMediaBatches;
};

export const returnAssetName = (
  dimension: string,
  offerData: OfferData,
  vin: string,
  assetType: string,
  index: number,
  order?: INewOrder | null,
  comingFromLauncher?: boolean,
) => {
  const { year, make, model, trim } = offerData;

  const client = process.env.REACT_APP_AV2_CLIENT;

  const assetName =
    client === "ladtech" && !comingFromLauncher
      ? `${order?.dealer_code}_${order?.wfProjectNumber}_${assetType}_${year}_${make}_${model}_${dimension}_${vin}_${index}`.toLowerCase()
      : `${assetType}_${vin}_${dimension}_${year}_${make}_${model}_${
          trim ? `${trim}_` : ""
        }_${index}`.toLowerCase();

  return assetName;
};

export const returnAssetInstanceCheckboxKeys = (
  assetType: string,
  dimension: string,
  assetInstances: IAssetInstance[],
  selectedOffers: ISelectedOffer[],
  comingFromLauncher?: boolean,
  order?: INewOrder | null,
) => {
  const checkedInstanceNames = assetInstances.map((instance, index) => {
    const convertedOffers = convertInstanceOffers(instance);
    const [offer] = convertedOffers;

    if (isEmpty(offer)) {
      return "";
    }
    const { vin } = offer.offerData;
    const offerData = selectedOffers.find(
      selected => selected.offerData.vin === vin,
    )?.offerData;

    const offerDataToUse = offerData || offer.offerData;
    const exportFilename = returnAssetName(
      dimension,
      offerDataToUse,
      vin,
      assetType,
      index,
      order,
      comingFromLauncher,
    );

    return exportFilename;
  });

  return checkedInstanceNames;
};

export const returnInitialAssetsToExport = (
  assetInstances: AssetInstanceRecord,
  selectedOffers: ISelectedOffer[],
  comingFromLauncher?: boolean,
  order?: INewOrder | null,
) => {
  const assetTypes: string[] = Object.keys(assetInstances);
  const newAssetInstanceNames: Record<string, Record<string, string[]>> = {};

  for (const assetType of assetTypes) {
    newAssetInstanceNames[assetType] = {};
    const assetInstancesByDimension = assetInstances[assetType];
    const orderedDimensions = orderBy(Object.keys(assetInstancesByDimension));
    for (const dimension of orderedDimensions) {
      if (
        newAssetInstanceNames[assetType][dimension] &&
        newAssetInstanceNames[assetType][dimension].length > 1
      ) {
        continue;
      }
      const checkedInstanceNames = returnAssetInstanceCheckboxKeys(
        assetType,
        dimension,
        assetInstances[assetType][dimension],
        selectedOffers,
        comingFromLauncher ? comingFromLauncher : false,
        order,
      );
      newAssetInstanceNames[assetType][dimension] = checkedInstanceNames;
    }
  }
  return newAssetInstanceNames;
};

export const returnAssetInstanceVins = (
  assetInstances: AssetInstanceRecord,
) => {
  const assetTypes: string[] = Object.keys(assetInstances);
  const assetInstanceVins: Record<string, Record<string, string[]>> = {};

  for (const assetType of assetTypes) {
    assetInstanceVins[assetType] = {};
    const assetInstancesByDimension = assetInstances[assetType];
    const orderedDimensions = orderBy(Object.keys(assetInstancesByDimension));
    for (const dimension of orderedDimensions) {
      const instances = assetInstances[assetType][dimension];
      if (
        assetInstanceVins[assetType][dimension] &&
        assetInstanceVins[assetType][dimension].length > 1
      ) {
        continue;
      }
      assetInstanceVins[assetType][dimension] = [];
      for (const instance of instances) {
        const { offers } = instance;
        const vins = Object.keys(offers); // offers only contains one offer for now, but may increase in the future
        assetInstanceVins[assetType][dimension] =
          assetInstanceVins[assetType][dimension].concat(vins);
      }
    }
  }

  return assetInstanceVins;
};

export const getAssetCount = (
  assetInstanceVals: Record<string, Record<string, IAssetInstance[]>>,
) => {
  let totalCounter = 0;
  if (!assetInstanceVals || !Object.keys(assetInstanceVals).length) {
    return 0;
  }
  for (const canvasType in assetInstanceVals) {
    for (const canvasSizeInstance in assetInstanceVals[canvasType]) {
      if (assetInstanceVals[canvasType].hasOwnProperty(canvasSizeInstance)) {
        for (const assetSize of assetInstanceVals[canvasType][
          canvasSizeInstance
        ]) {
          if (!assetSize.offers || !assetSize.template) {
            break;
          }
          totalCounter++;
        }
      }
    }
  }
  return totalCounter;
};

export const getStampCount = (assetInstances: AssetInstanceRecord) => {
  let stampCount = 0;
  const assetTypes: string[] = Object.keys(assetInstances);
  for (const assetType of assetTypes) {
    const assetInstancesByDimension = assetInstances[assetType];
    const orderedDimensions = orderBy(Object.keys(assetInstancesByDimension));
    for (const dimension of orderedDimensions) {
      const instances = assetInstances[assetType][dimension];
      for (const instance of instances) {
        const { template, visibilities } = instance;
        if (visibilities) {
          for (const element of visibilities) {
            if (element.type === "stamp" && element.isVisible) {
              stampCount++;
            }
          }
        } else {
          stampCount += template?.numOfStamps || 0;
        }
      }
    }
  }
  return stampCount;
};

export const getAssetStrCount = (assetInstanceVals: InstanceCheckboxDict) => {
  let totalCounter = 0;
  if (!assetInstanceVals || !Object.keys(assetInstanceVals).length) {
    return 0;
  }
  for (const canvasType in assetInstanceVals) {
    for (const canvasSizeInstance in assetInstanceVals[canvasType]) {
      if (assetInstanceVals[canvasType].hasOwnProperty(canvasSizeInstance)) {
        totalCounter +=
          assetInstanceVals[canvasType][canvasSizeInstance].length;
      }
    }
  }
  return totalCounter;
};

const getSelectedOfferTypes = (offerIndex: Record<OfferType, boolean>) => {
  // return OfferType[] that is set to true
  return Object.keys(offerIndex).filter(
    offerType => offerIndex[offerType as OfferType],
  ) as OfferType[];
};

export const getSelectedVins = (selectedOffers: RawSelectedOffers) => {
  return Object.keys(selectedOffers).filter(
    vin => getSelectedOfferTypes(selectedOffers[vin].offerIndex).length > 0,
  );
};

export const isSameSelected = (vins?: string[], editedVins?: string[]) => {
  if (!vins || !editedVins) return true;
  if (vins.length > editedVins.length)
    return vins?.every(vn => editedVins?.includes(vn));
  return editedVins?.every(vn => vins?.includes(vn));
};

const getObjFromArr = (
  obj: { [x: string]: OfferType[] }[],
): Record<string, string[]> => Object.assign({}, ...obj);

export const isSameOffer = (
  original?: ISelectedOffer[] | null,
  newRawOffers?: ISelectedOffer[],
) => {
  if (!original || !newRawOffers) return true;
  const originalOffersByVin = original.map(offers => ({
    [offers.offerData.vin]: offers.offers,
  }));
  const originalOffers = getObjFromArr(originalOffersByVin);
  const newOffersByVin = newRawOffers.map(raw => ({
    [raw.offerData.vin]: raw.offers,
  }));
  const newOffers = getObjFromArr(newOffersByVin);

  if (originalOffersByVin.length >= newOffersByVin.length) {
    return Object.keys(originalOffers).every(key => {
      const orOffer = originalOffers[key];
      const newOffer = newOffers[key];
      return orOffer.every(o => newOffer.includes(o));
    });
  }

  return Object.keys(newOffers).every(key => {
    const orOffer = originalOffers[key];
    const newOffer = newOffers[key];
    return newOffer.every(o => orOffer.includes(o));
  });
};
