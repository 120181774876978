import { useMemo } from "react";
import { StoreType } from "screens/designStudio/designStudioV2/types";
import {
  getSelectedElements,
  isImageElement,
  layerCropModeEnabled,
} from "../editorDrawer/editorDrawerContent/polotnoEditor/utils";

export const useCropHandler = (store: StoreType) => {
  const [element] = getSelectedElements(store);
  const isImageLayerElement = isImageElement(element);
  const isCropModeEnabled = isImageLayerElement && layerCropModeEnabled(store);

  const originalElementValues = useMemo(() => {
    if (!element || !isCropModeEnabled) return {};
    return {
      x: element.x,
      y: element.y,
      width: element.width,
      height: element.height,
      cropX: element.cropX,
      cropY: element.cropY,
      cropWidth: element.cropWidth,
      cropHeight: element.cropHeight,
    };
  }, [element, isCropModeEnabled]);

  const handleCrop = () => {
    if (isImageLayerElement) {
      element.toggleCropMode();
    }
  };

  const cropSave = () => {
    if (!isImageLayerElement) return;
    element.toggleCropMode();
  };

  const cropCancel = () => {
    if (!isImageLayerElement || !originalElementValues) return;
    element.set(originalElementValues);
    element.toggleCropMode();
  };

  return { handleCrop, cropSave, cropCancel };
};
