import { StoreType } from "screens/designStudio/designStudioV2/types";

export const useStoreZoom = (store: StoreType | null) => {
  return {
    zoomIn: () => {
      store?.setScale(store.scale + 0.01);
    },
    zoomOut: () => {
      store?.setScale(store.scale - 0.01);
    },
  };
};
