import { FileDrawerMode, Template } from "shared/types/salesEnablement";
import {
  BookOutlined,
  PlusOutlined,
  FileImageOutlined,
  FormOutlined,
  FileAddOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { ReactComponent as AflacAccount } from "statics/images/svg/aflac-account-logo-white.svg";
import FeatureCard from "./FeatureCard";
import { useIsSalesEnablementAdmin } from "shared/hooks/useIsAdmin";
import { useSalesEnablementContext } from "screens/designStudio/hooks/SalesEnablementDataProvider";
import styles from "./FeatureCardSection.module.scss";
import TemplateDrawer from "screens/designStudio/templates/TemplateDrawer";
import useTemplateEditing from "shared/hooks/salesEnablement/useTemplateEditing";
import { ROUTES } from "./utils/constants";

type SetupDrawerProps = ({
  template,
  drawerMode,
}: {
  template?: Template;
  drawerMode: FileDrawerMode;
}) => void;

const getAdminCards = (setupDrawer: SetupDrawerProps) => [
  {
    icon: <FileImageOutlined />,
    title: "Manage Templates",
    description: "Review, edit and publish templates.",
    navigateTo: ROUTES.templates,
  },
  {
    icon: <PlusOutlined />,
    title: "Create a Template",
    description: "Set up a template for Agents to use.",
    navigateTo: () => setupDrawer({ drawerMode: "new" }),
  },
  {
    icon: <BookOutlined />,
    title: "Set Up Products",
    description: "Create and edit products.",
    navigateTo: ROUTES.products,
  },
  {
    icon: <FormOutlined />,
    title: "Configure Disclosures",
    description: "Create and edit disclosures shown on Marketing Materials.",
    navigateTo: ROUTES.disclosures,
  },
];

const agentCards = [
  {
    icon: <FileImageOutlined />,
    title: "Popular Templates",
    description: "Explore the top templates used by agents.",
    navigateTo: ROUTES.popularTemplates,
  },
  {
    icon: <FileAddOutlined />,
    title: "Newest Templates",
    description: "Check out the most recently added templates.",
    navigateTo: ROUTES.newestTemplates,
  },
  {
    icon: <AflacAccount />,
    title: "Accounts",
    description: "Manage your accounts, company logos, and contact lists.",
    navigateTo: ROUTES.accounts,
  },
  {
    icon: <TeamOutlined />,
    title: "Agents",
    description: "Create and update agent profile info.",
    navigateTo: ROUTES.agents,
  },
];

export const FeatureCardSection = () => {
  const isAdmin = useIsSalesEnablementAdmin();
  const { open } = useSalesEnablementContext();
  const { setupDrawer } = useTemplateEditing();

  const cards = isAdmin ? getAdminCards(setupDrawer) : agentCards;

  return (
    <>
      <div className={styles.container}>
        {cards.map((card, index) => (
          <div key={index} className={styles.cardContainer}>
            <FeatureCard
              icon={card.icon}
              title={card.title}
              description={card.description}
              navigateTo={card.navigateTo}
            />
          </div>
        ))}
      </div>
      {open && <TemplateDrawer drawerMode="new" toHomepage />}
    </>
  );
};
