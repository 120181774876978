import { capitalize, get, identity } from "lodash";
import moment from "moment/moment";
import { transformBytes } from "../utils.filesize";

const toPx = (value: string) => `${value} px`;
const toDate = (value: string) => {
  return moment(value).format("YYYY-MM-DD HH:mm:ss").toString();
};

const fields = new Map<string, [string, (x: string) => string]>([
  ["Asset Id", ["id", identity]],
  ["File Name", ["filename", identity]],
  ["Date Created", ["datecreated", toDate]],
  ["Date Updated", ["datemodified", toDate]],
  ["Uploaded By", ["user.name", identity]],
  ["Width", ["width", toPx]],
  ["Height", ["height", toPx]],
  ["File Size", ["filesize", transformBytes]],
  ["File Type", ["type", capitalize]],
]);

export const getAssetDetails = (assets: WDAsset[]) => {
  const [first] = assets;

  return Array.from(fields).map(([label, [field, mapTo]]) => {
    const firstValue = get(first, field);
    const allSame = assets.every(asset => firstValue === get(asset, field));

    return [label, allSame ? mapTo(firstValue) : "Mixed"];
  });
};
