import { Language } from "shared/types/salesEnablement";

export const sortByPrimaryLanguage = (
  languages: string[],
  primaryLanguage: Language,
): Language[] =>
  languages
    .flatMap(language =>
      language === "en" || language === "es" ? language : [],
    )
    .slice()
    .sort((a, b) => {
      if (a === primaryLanguage) return -1;
      if (b === primaryLanguage) return 1;
      return 0;
    });
