import { FileDoneOutlined, FileSyncOutlined } from "@ant-design/icons";
import { Button, Col, notification, Progress, Row, Tag } from "antd";
import { ExportForOption } from "shared/types/assetExporter";
import styles from "./ProcessNotification.module.scss";
import { LinkButton } from "./exportDrawer/exportMessage/LinkButton";
import StatusDescription from "./exportDrawer/exportMessage/StatusDescription";

type Props = {
  message: string;
  status: string;
  percentage: number;
  getCsvDownloadUrl: () => void;
  notificationKey: string;
  exportFor: ExportForOption;
  onClose: () => void;
  onAdLibClick: () => void;
  portalLink?: string;
  sheetLink?: string;
  exportRowCount: number | null;
  exportAssetBatchNames: string[];
  exportFeedName: string | null | undefined;
};

const descriptionCompleted = (
  type: ExportForOption,
  completedText: string,
  portalLink: string,
  sheetLink: string,
  onCsvClick: () => void,
) => {
  switch (type) {
    case "CSV download":
      return (
        <div
          onClick={onCsvClick}
          className={`${styles.downloadBtn} ${styles.statusDescription}`}
        >
          Download
        </div>
      );
    case "FTP":
      return <div className={styles.statusDescription}>{completedText}</div>;
    case "Off Platform Portal":
      return (
        <div className={`${styles.downloadBtn} ${styles.statusDescription}`}>
          <LinkButton href={portalLink ?? ""} label="Go to Portal" />
          <LinkButton href={sheetLink ?? ""} label="Go to Spreadsheet" />
        </div>
      );
    case "Ad Library":
      return (
        <div className={`${styles.downloadBtn} ${styles.statusDescription}`}>
          <LinkButton href="/ad-library" label="Go to Ad Library" />
        </div>
      );
    default:
      return "";
  }
};

const getProgressStatus = (status: string) => {
  if (status === "Error") return "exception";
  if (status === "Completed") return "success";
  return "active";
};

const Description = ({
  message,
  status,
  percentage,
  getCsvDownloadUrl,
  exportFor,
  notificationKey,
  portalLink,
  sheetLink,
  exportRowCount,
  exportAssetBatchNames,
  exportFeedName,
}: Props) => {
  const currStatus = getProgressStatus(status);
  const isDataProcessing = percentage !== 100 && status !== "Error";
  const isCsvExport = exportFor.includes("CSV download");
  const isFtpExport = exportFor.includes("FTP");
  const isAdLibExport = exportFor.includes("Ad Library");
  const isOffPlatformExport = exportFor.includes("Off Platform Portal");

  const isDataExportCompleted = percentage === 100;
  const exportingText = "Generating assets";
  const completedText = "Completed";

  return (
    <div>
      {isDataProcessing ? (
        <span>{message}</span>
      ) : (
        <Row>
          <Col span={24}>
            {status === "Error" ? (
              <div>{message}</div>
            ) : (
              <>
                {isAdLibExport && (
                  <div className={styles.adLibExportDescription}>
                    <div>
                      <strong>{exportRowCount}</strong> ads have been created in
                      Ad Library
                    </div>
                    <div>
                      {`Feed: `}
                      <strong>{exportFeedName}</strong>
                    </div>
                    <div>
                      Asset batches:{" "}
                      {exportAssetBatchNames.map(name => (
                        <Tag key={name}>{name}</Tag>
                      ))}
                    </div>
                  </div>
                )}
                {isCsvExport && <div>Export to CSV</div>}
                {isFtpExport && <div>Export to FTP</div>}
                {isOffPlatformExport && (
                  <div>
                    The assets have been exported to the spreadsheet-enabled
                    portal.
                  </div>
                )}
              </>
            )}
          </Col>
          <Col span={24}>
            {status === "Error" ? (
              <div className={styles.errorDescription}>
                <div className={styles.errorText}>Failed</div>
                <Button
                  type="link"
                  onClick={() => notification.close(notificationKey)}
                >
                  Return to Configure
                </Button>
              </div>
            ) : (
              <StatusDescription
                exportingText={exportingText}
                isDataExportCompleted={isDataExportCompleted}
              >
                {descriptionCompleted(
                  exportFor,
                  completedText,
                  portalLink ?? "",
                  sheetLink ?? "",
                  getCsvDownloadUrl,
                )}
              </StatusDescription>
            )}
          </Col>
        </Row>
      )}

      <Progress percent={percentage} showInfo={true} status={currStatus} />
    </div>
  );
};

export const assetBatchExportNotification = (props: Props) => {
  const currStatus = getProgressStatus(props.status);

  notification.open({
    key: props.notificationKey,
    icon: (
      <div className={styles.notificationIcon}>
        {currStatus !== "success" ? <FileSyncOutlined /> : <FileDoneOutlined />}
      </div>
    ),
    message: (
      <strong>
        {currStatus !== "success" ? "Exporting" : "Export Complete"}
      </strong>
    ),
    description: <Description {...props} />,
    duration: null,
    placement: "bottomRight",
    onClose: props.onClose,
    style: { width: "400px" },
  });
};
