import { ColorResult } from "react-color";
import { FigureLayerElement } from "screens/designStudio/designStudioV2/types";

export const useFigureStroke = (elements: FigureLayerElement[]) => {
  const [firstElement] = elements;
  const isStrokeEnabled = Boolean(firstElement?.strokeWidth);

  const handleStrokeToggle = () => {
    elements.forEach(el => el.set({ strokeWidth: isStrokeEnabled ? 0 : 2 }));
  };

  const handleStrokeColorChange = (color: ColorResult) => {
    elements.forEach(el => el.set({ stroke: color.hex }));
  };

  const handleStrokeWidthChange = (e: Event, newValue: number | number[]) => {
    const value = Array.isArray(newValue) ? newValue[0] : newValue;
    elements.forEach(element => {
      element.set({
        strokeWidth: Number(value.toFixed(0)),
      });
    });
  };

  const handleCornerRadiusChange = (e: Event, newValue: number | number[]) => {
    const value = Array.isArray(newValue) ? newValue[0] : newValue;
    elements.forEach(element => {
      element.set({ cornerRadius: Number(value.toFixed(0)) });
    });
  };

  const handleDashStrokeChange = (dashLength: number, dashSpacing: number) => {
    elements.forEach(element => {
      element.set({ dash: [dashLength, dashSpacing] });
    });
  };

  return {
    isStrokeEnabled,
    handleStrokeToggle,
    handleStrokeColorChange,
    handleStrokeWidthChange,
    handleCornerRadiusChange,
    handleDashStrokeChange,
  };
};
