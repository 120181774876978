import { AdLoadStep } from "screens/adLibrary/facebookUtils/adProto.types";
import { pluralize } from "utils/terms";
import { useAdLoadIssues } from "./useAdLoadIssues";
import { useTrackCurrentExec } from "./useCurrentExec";
import {
  useCurrentSession,
  useSessionParam,
  useSetCurrentSession,
} from "./useCurrentSession";
import { useFilteredProtosDrafts } from "./useFilteredProtosDrafts";
import { useLoadAdsToFb } from "./useLoadAdsToFb";
import { AdType } from "screens/adLibrary/facebookUtils/types";

export type AdLoadStepInfo = {
  visible: boolean;
  title: string;
  stepNumber: number;
  disabled: boolean;
  canContinue: boolean;
  errorMessage: string | undefined;
  onClickNext: (() => void) | undefined;
  onClickBack: (() => void) | undefined;
  nextButtonText: string;
};

export const useAdLoadStepInfo = () => {
  const { adProtoDraftsWithDestUrls, adProtoDraftsWithProductSets } =
    useFilteredProtosDrafts();
  const isProductSetActive = !!adProtoDraftsWithProductSets.filter(
    ads => ads.type !== AdType.AIA,
  ).length;
  const isDestinationsActive = !!adProtoDraftsWithDestUrls.length;

  const { session, adDests, adDestToLoad } = useCurrentSession();
  const { setSession } = useSetCurrentSession();
  const { loadAdsToFb, isStartingExecution } = useLoadAdsToFb();
  const { closeSession } = useSessionParam();

  const { adsWithError, percent, adsLoading } = useTrackCurrentExec();
  const countAdsWithError = adsWithError.length;

  const adLoadStep = session.data.step;

  const disableAllSteps = adLoadStep === "load" || adDests.length === 0;

  const { issuesByField, hasIssues, issues } = useAdLoadIssues();

  const getDestinationStepErrorMessage = () => {
    const callToActionIssue = issues.find(
      issue => issue.field === "callToAction",
    );
    const destinationURLIssue = issues.find(
      issue => issue.field === "destinationUrl",
    );

    if (destinationURLIssue) {
      return "Please fix all URL issues before proceeding";
    }

    if (callToActionIssue) {
      return "Please fix all CTA issues before proceeding";
    }

    return undefined;
  };

  const hasProductSetIssues = !!issuesByField["productSetId"]?.length;

  const getLoadStepTitle = () => {
    if (adLoadStep !== "load") {
      return "Load to Facebook";
    }
    if (percent !== 100) {
      return "Loading progress";
    }
    if (countAdsWithError) {
      return `${countAdsWithError} ${pluralize(
        "Ad",
        countAdsWithError,
      )} failed to load`;
    }
    return "Loading completed";
  };

  const productSetStepCount = isProductSetActive ? 1 : 0;
  const destinationStepCount = isDestinationsActive ? 1 : 0;

  const goToStep = (nextStep: AdLoadStep) => {
    setSession(prevSession => {
      prevSession.data.step = nextStep;
    });
  };

  const getLoadStepNextBtnText = () => {
    if (adsLoading.length || isStartingExecution) {
      return "Loading";
    }

    return adsWithError.length ? "Retry failed" : "Done";
  };

  return {
    select: {
      visible: true,
      stepNumber: 0,
      title: "Select Campaigns",
      disabled: disableAllSteps,
      canContinue: adDests.length > 0,
      errorMessage: "Select destinations before proceeding",
      onClickNext() {
        if (isProductSetActive) {
          return goToStep("productSets");
        }
        return goToStep(isDestinationsActive ? "destinations" : "review");
      },
      nextButtonText: "Next",
      onClickBack: undefined,
    },
    productSets: {
      visible: isProductSetActive,
      stepNumber: productSetStepCount || -1,
      title: "Assign Product Sets",
      disabled: disableAllSteps,
      canContinue: !hasProductSetIssues,
      errorMessage: "Fix Product Set issues before proceeding",
      onClickNext() {
        goToStep(isDestinationsActive ? "destinations" : "review");
      },
      nextButtonText: "Next",
      onClickBack() {
        goToStep("select");
      },
    },
    destinations: {
      visible: isDestinationsActive,
      stepNumber: productSetStepCount + destinationStepCount || -1,
      title: "Assign Destinations",
      disabled: disableAllSteps || hasProductSetIssues,
      canContinue: !hasIssues,
      errorMessage: getDestinationStepErrorMessage(),
      onClickNext() {
        goToStep("review");
      },
      nextButtonText: "Next",
      onClickBack() {
        goToStep(isProductSetActive ? "productSets" : "select");
      },
    },
    review: {
      visible: adDests.length > 0,
      stepNumber: productSetStepCount + destinationStepCount + 1,
      title: "Review Ad Load",
      disabled: disableAllSteps || hasIssues,
      canContinue: !!adDestToLoad.length,
      errorMessage: "Select at least one Ad to load",
      onClickNext: loadAdsToFb,
      nextButtonText: `Load ${adDestToLoad.length} ${pluralize(
        "Ad",
        adDestToLoad.length,
      )} to Facebook`,
      onClickBack() {
        if (isDestinationsActive) {
          return goToStep("destinations");
        }
        return goToStep(isProductSetActive ? "productSets" : "select");
      },
    },
    load: {
      visible: adDests.length > 0,
      stepNumber: productSetStepCount + destinationStepCount + 2,
      title: getLoadStepTitle(),
      disabled: true,
      canContinue: !adsLoading.length,
      errorMessage: undefined,
      onClickNext: adsWithError.length ? loadAdsToFb : closeSession,
      nextButtonText: getLoadStepNextBtnText(),
      onClickBack: undefined,
    },
  } satisfies Record<AdLoadStep, AdLoadStepInfo>;
};
