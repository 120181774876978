import { notification } from "antd";
import { useLazyGetAssetEmbedlinksQuery } from "redux/media/media.api";

export const useShareLink = () => {
  const [getAssetUrl] = useLazyGetAssetEmbedlinksQuery();

  const getLink = async (record: WDAsset) => {
    const { directlinks } = await getAssetUrl(record.id, false).unwrap();
    return new Blob([directlinks.original], { type: "text/plain" });
  };

  return async (record: WDAsset) => {
    try {
      await navigator.clipboard.write([
        new ClipboardItem({ "text/plain": getLink(record) }),
      ]);
      notification.success({
        message: "Copied Link to Clipboard",
      });
    } catch (error) {
      notification.error({
        message: "Failed to share link to clipboard",
      });
    }
  };
};
