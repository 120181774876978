import { Button, Empty, Spin, notification } from "antd";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import { connect } from "react-redux";
import {
  AssetInstanceRecord,
  IAssetBuilderState,
  ICheckedOfferFilter,
  OfferData,
  RawSelectedOffers,
  VehicleConditions,
  ISortingOption,
  ISelectedOffer,
  FeedTab,
  FeedOffer,
  RawOfferData,
} from "shared/types/assetBuilder";
import { AlertMessage } from "shared/components/AlertMessage";
import { OfferForm } from "./select/OfferForm";

import { FeedType, IConfigurationState } from "shared/types/configuration";
import { INewOrderState } from "shared/types/newOrders";
import { OfferType, SelectedOffers } from "shared/types/shared";
import actions from "../../../redux/rootActions";
import { ArgsProps } from "antd/lib/notification";
import {
  UseGetTabData,
  useGetTabData,
} from "shared/hooks/assetBuilder/useGetTabData";
import { getSelectedVins } from "utils/helpers.asset";
import API from "services";
import uniqBy from "lodash/uniqBy";
import { usePrevious } from "utils/helpers.hooks";
import { isEqual } from "lodash";
import { useGetDefaultOfferImg } from "./select/useGetDefaultOfferImg";

interface ISelect {
  feedTab?: FeedTab;
  setGlobalSelectedList: Dispatch<SetStateAction<any[]>>;
  feed: FeedType;
  selectedOfferList: Array<{
    row: OfferData;
    updated?: number;
    editedKeys?: Record<string, boolean>;
  }>;
  globalSelectedList: SelectedOffers[];
  enablePaymentEngine: boolean;
  isDataEmpty: boolean;
  currentPage: number;
  currentDealerCode: string;
  rawSelectedOffers: RawSelectedOffers;
  checkedOfferFilters: ICheckedOfferFilter;
  searchBy: string;
  savedOrder: IAssetBuilderState["savedOrder"];
  saveOffer: (
    useUploadedJellyBean: boolean,
    type: "create" | "update",
    revertingOffer?: boolean,
  ) => Promise<void>;
  fetchSelectedOfferList: (selectedOfferVin: string[]) => void;
  setEditOfferData: (
    offer: OfferData,
    editedKeys: Record<string, boolean>,
  ) => Promise<void>;
  toggleOffer: (offerType: OfferType, offerData: OfferData) => void;
  switchOfferEditMode?: (editMode: boolean) => void;
  resetAssetInstanceCounter: () => void;
  offerEditsWereSaved: boolean;
  commitOrder: () => Promise<void>;
  fetchOrderState: (orderId: string) => void;
  setAssetInstanceComparator: (assetInstances: AssetInstanceRecord) => void;
  assetInstances: AssetInstanceRecord;
  vehicleConditionFilter: VehicleConditions;
  lastUpdated: number;
  filterBy: VehicleConditions;
  sortingOptions: ISortingOption[];
  selectedOffers: ISelectedOffer[];
  filterFieldSearch: string;
  updateRawSelectedOffer: (offer: OfferData) => void;
}

const Select = ({
  feedTab,
  lastUpdated,
  feed,
  selectedOfferList,
  enablePaymentEngine,
  isDataEmpty,
  rawSelectedOffers,
  setEditOfferData,
  checkedOfferFilters,
  searchBy,
  savedOrder,
  saveOffer,
  fetchSelectedOfferList,
  toggleOffer,
  switchOfferEditMode,
  resetAssetInstanceCounter,
  commitOrder,
  assetInstances,
  setAssetInstanceComparator,
  filterBy,
  setGlobalSelectedList,
  globalSelectedList,
  sortingOptions,
  selectedOffers,
  currentDealerCode,
  filterFieldSearch,
  fetchOrderState,
  updateRawSelectedOffer,
}: ISelect) => {
  const { feedId } = feedTab ?? {};
  const filterField = feedTab?.filterColHeader ?? "dealerId";
  const dealerOem = savedOrder?.selectedOrder.dealer_oem;
  const dealerCode = savedOrder?.selectedOrder.dealer_code ?? currentDealerCode;
  const previousRawSelectedOffer = usePrevious(rawSelectedOffers);
  const [offerList, setOfferList] = useState<OfferData[]>([]);
  const [selectedOfferVins, setSelectedOfferVins] = useState<string[]>(
    getSelectedVins(rawSelectedOffers),
  ); // This is OfferType[] from rawSelectedOffers but it is extracted by looking at the offerIndex attribute
  const [selectedFeedOffers, setSelectedFeedOffers] = useState<FeedOffer[]>([]);
  const [rawOfferUpdated, setRawOfferUpdated] = useState(true);
  const [activeVins, setActiveVins] = useState<string[]>([]); // to determin the offers that are expanded
  const getDefaultOfferImg = useGetDefaultOfferImg();

  const { error, isFetchingPage, offerListCp, totalOffers, getNextPage } =
    useGetTabData({
      feedId,
      dealerCode,
      dealerOem,
      filterBy,
      searchBy,
      sortingOptions,
      filterField,
      filterFieldSearch,
      rawOfferUpdated,
      searchType: "strict",
    });

  useEffect(() => {
    if (!isFetchingPage) setRawOfferUpdated(false);
  }, [isFetchingPage]);

  useEffect(() => {
    setAssetInstanceComparator(assetInstances);
    resetAssetInstanceCounter();
    if (switchOfferEditMode) {
      switchOfferEditMode(false);
    }
  }, [
    assetInstances,
    resetAssetInstanceCounter,
    setAssetInstanceComparator,
    switchOfferEditMode,
  ]);

  useEffect(() => {
    if (!feedTab?.feedName.toLowerCase().includes("default")) return;
    const updatedSelectedOfferVins = getSelectedVins(rawSelectedOffers);
    if (updatedSelectedOfferVins.length === selectedOfferVins.length) return;

    const isOfferAdded =
      selectedOfferVins.length < updatedSelectedOfferVins.length;

    const updatedVin = isOfferAdded
      ? updatedSelectedOfferVins[updatedSelectedOfferVins.length - 1]
      : selectedOfferVins[selectedOfferVins.length - 1];

    const message = !isOfferAdded
      ? `An offer has been successfully removed`
      : "An offer has been successfully added";
    const labelAction = isOfferAdded ? "added." : "removed.";
    const vinDescription = `${updatedVin} has been ${labelAction}`;
    const vinlessDescription = `A vinless offer has been ${labelAction}`;
    /**
     * VINs always contain less than 20 chars. Example: 3N1CP5CU6JL544840
     * Vinless offers, have an uuid (around 30 chars) instead of traditional vins so that we always
     * have an unique identifier for every offer
     */
    const isVinless = updatedVin.length > 20;
    const description = isVinless ? vinlessDescription : vinDescription;
    const placement: ArgsProps["placement"] = "bottomRight";

    isOfferAdded
      ? notification.success({
        message,
        description,
        placement,
      })
      : notification.warn({
        message,
        description,
        placement,
      });
    setSelectedOfferVins(updatedSelectedOfferVins);
  }, [feedTab?.feedName, rawSelectedOffers, selectedOfferVins]);

  useEffect(() => {
    fetchSelectedOfferList(selectedOfferVins);
  }, [feedTab?.feedName, fetchSelectedOfferList, selectedOfferVins]);

  const getSelectedOffers = useCallback(async () => {
    if (isEqual(previousRawSelectedOffer, rawSelectedOffers)) return;

    const feedName = feedTab?.feedName.toLowerCase();

    if (Object.keys(rawSelectedOffers).length < 1) {
      if (feedName?.includes("default")) setGlobalSelectedList([]);
    }

    const offerListVins = offerList.map(ol => ol?.vin);

    const updatedList: OfferData[] = [];
    selectedOfferList.forEach(sol => {
      if (offerListVins.length > 0 && !offerListVins.includes(sol.row?.vin)) {
        updatedList.push(sol.row);
      }
    });

    if (updatedList.length > 0)
      setOfferList(prev => uniqBy([...prev, ...updatedList], "vin"));

    const selectedOfferVins = getSelectedVins(rawSelectedOffers);
    const searchVinString = selectedOfferVins.join(" ");
    const getSelectedData = async () => {
      const params: UseGetTabData = {
        feedId,
        dealerCode,
        dealerOem,
        filterBy,
        sortingOptions,
        searchBy: searchVinString,
        filterField,
        filterFieldSearch,
        termOperator: "OR",
      };

      return (await API.services.assetBuilder.getTabData(params, 1)).result;
    };

    const selectedData = await getSelectedData();

    setSelectedFeedOffers(prev => {
      return uniqBy([...prev, ...(selectedData?.offerList ?? [])], "vin");
    });

    const selectedOffersAndImages = await Promise.all(
      selectedOfferVins.map(async rawKey => {
        const rawLastUpdated = selectedData?.offerList.find(
          offer => offer.vin === rawKey,
        )?.lastUpdated;

        const offerData = rawSelectedOffers[rawKey].offerData;
        const imageUrlExist = offerData?.imageUrl && offerData?.imageUrl.length;
        const imageUrl = imageUrlExist
          ? offerData?.imageUrl
          : await getDefaultOfferImg(offerData);
        const row: OfferData = { ...offerData, imageUrl: imageUrl || "" };

        if (!imageUrlExist) updateRawSelectedOffer(row);

        return {
          row,
          updated: rawLastUpdated,
        };
      }),
    );

    if (feedName?.includes("default"))
      setGlobalSelectedList(selectedOffersAndImages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    feedTab?.feedName,
    offerList,
    rawSelectedOffers,
    savedOrder?.selectedOffers,
    selectedOfferList,
    selectedOfferVins,
    selectedOffers,
    setGlobalSelectedList,
  ]);

  useEffect(() => {
    (async () => {
      await getSelectedOffers();
    })();
  }, [getSelectedOffers]);

  const availableOfferList = useMemo(() => {
    const filtered = offerListCp.filter(
      offer => !selectedOfferVins.includes(offer.vin),
    );

    const updatedVins = selectedFeedOffers.map(offer => offer.vin);

    const updatedAndConsolidated = filtered.map(offer => {
      if (!updatedVins.includes(offer.vin)) return { ...offer, imageUrl: "" };

      const lastUpdateds = selectedFeedOffers
        .filter(selectedOffer => selectedOffer.vin === offer.vin)
        .map(offer => offer.lastUpdated ?? 0);
      return { ...offer, lastUpdated: Math.max(...lastUpdateds), imageUrl: "" };
    });

    return updatedAndConsolidated.filter(
      fil =>
        !globalSelectedList.some(
          globalOffer => globalOffer.row.vin === fil.vin,
        ),
    );
  }, [globalSelectedList, offerListCp, selectedFeedOffers, selectedOfferVins]);

  const allCheckedOfferFilters = Object.keys(checkedOfferFilters).reduce(
    (acc: any, key: string) => {
      acc[key] = true;
      return acc;
    },
    {} as { [K in keyof ICheckedOfferFilter]?: boolean },
  );

  const checkOfferStillValid = useCallback(
    async (offerVin: string) => {
      const params = {
        feedId,
        dealerCode,
        dealerOem,
        filterBy,
        sortingOptions,
        searchBy: offerVin,
        filterField,
        filterFieldSearch,
      };
      const res = await API.services.assetBuilder.getTabData(params, 1);
      const { result, error } = res;

      if (error || !result || result?.total === 0) return false;

      return true;
    },
    [
      feedId,
      dealerCode,
      dealerOem,
      filterBy,
      sortingOptions,
      filterField,
      filterFieldSearch,
    ],
  );

  const fetchSavedOrderState = () => {
    if (!savedOrder?.orderId) return;
    fetchOrderState(savedOrder.orderId);
  };

  return (
    <Spin
      spinning={!isDataEmpty && isFetchingPage}
      size="large"
      tip="Loading..."
    >
      {error && <AlertMessage type="warning" message={error} />}
      <div className="offer-list">
        {isDataEmpty && (
          <Empty className="empty" image={Empty.PRESENTED_IMAGE_DEFAULT} />
        )}

        {!isDataEmpty && (
          <div>
            {globalSelectedList.length > 0 && (
              <div data-cy="selected-offer-ctn">
                <div style={{ fontSize: "0.9rem", fontWeight: "bold" }}>
                  Selected Offers
                </div>
                <div>
                  {globalSelectedList.map(
                    ({ row, editedKeys, updated }, index) => {
                      return (
                        <OfferForm
                          feedId={feedId ?? ""}
                          feed={feed}
                          offerData={row}
                          orderId={savedOrder?.orderId}
                          editedKeys={editedKeys}
                          key={index}
                          editMode={false}
                          saveOffer={saveOffer}
                          savedOrder={savedOrder}
                          setEditOfferData={setEditOfferData}
                          enablePaymentEngine={enablePaymentEngine}
                          toggleOffer={toggleOffer}
                          rawSelectedOffers={rawSelectedOffers}
                          checkedOfferFilters={allCheckedOfferFilters}
                          keyId={""}
                          timestamp={updated}
                          activeVins={activeVins}
                          onActiveCollapseChange={(activeVins: string[]) => {
                            setActiveVins(activeVins);
                          }}
                          commitOrder={commitOrder}
                          selected={true}
                          sortingOptions={sortingOptions}
                          checkOfferStillValid={checkOfferStillValid}
                          setRawOfferUpdated={setRawOfferUpdated}
                          fetchOrderState={fetchSavedOrderState}
                        />
                      );
                    },
                  )}
                </div>
              </div>
            )}

            <div style={{ margin: "1em 0" }}>
              <div style={{ fontSize: "0.9rem", fontWeight: "bold" }}>
                Available Offers
              </div>
              <div>
                {availableOfferList.map((offer, idx) => {
                  return (
                    <OfferForm
                      feedId={feedId}
                      feed={feed}
                      offerData={offer}
                      sortingOptions={sortingOptions}
                      orderId={savedOrder?.orderId}
                      key={idx}
                      editMode={false}
                      saveOffer={saveOffer}
                      savedOrder={savedOrder}
                      setEditOfferData={setEditOfferData}
                      enablePaymentEngine={enablePaymentEngine}
                      toggleOffer={toggleOffer}
                      rawSelectedOffers={rawSelectedOffers}
                      checkedOfferFilters={checkedOfferFilters}
                      keyId={""}
                      timestamp={offer.lastUpdated || lastUpdated}
                      activeVins={activeVins}
                      onActiveCollapseChange={(activeVins: string[]) => {
                        setActiveVins(activeVins);
                      }}
                      commitOrder={commitOrder}
                      isAvailableOffer={true}
                      setRawOfferUpdated={setRawOfferUpdated}
                      fetchOrderState={fetchSavedOrderState}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        )}

        {!isDataEmpty && totalOffers > offerListCp.length && (
          <Button
            style={{ marginTop: "15px" }}
            loading={isFetchingPage}
            onClick={() => {
              if (!savedOrder) {
                return;
              }
              getNextPage();
            }}
          >
            Load more
          </Button>
        )}
      </div>
    </Spin>
  );
};
const mapStateToProps = (state: any) => {
  const { assetBuilder, configuration, newOrders } = state as {
    assetBuilder: IAssetBuilderState;
    configuration: IConfigurationState;
    newOrders: INewOrderState;
  };

  const { currentSelectedOrder } = newOrders;
  const { feed, config } = configuration;

  const {
    isDataEmpty,
    currentPage,
    rawSelectedOffers,
    checkedOfferFilters,
    offerEditsWereSaved,
    assetInstances,
    vehicleConditionFilter,
    selectedOfferList,
    selectedOffers,
  } = assetBuilder;

  return {
    selectedOfferList,
    selectedOffers,
    feed,
    isDataEmpty,
    enablePaymentEngine: config?.featureFlags?.enablePaymentEngine || false,
    currentDealerCode: currentSelectedOrder.dealer_code,
    currentPage,
    rawSelectedOffers,
    checkedOfferFilters,

    offerEditsWereSaved,
    assetInstances,
    vehicleConditionFilter,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    toggleOffer: (offerType: OfferType, offerData: OfferData) => {
      dispatch(actions.assetBuilder.toggleOffer({ offerType, offerData }));
    },
    setEditOfferData: (
      offer: OfferData,
      editedKeys: Record<string, boolean>,
    ) => {
      return dispatch(
        actions.assetBuilder.setEditOfferData({
          rawOfferData: offer,
          editedKeys: editedKeys,
          operation: "UPDATE",
        }),
      ) as Promise<void>;
    },
    switchOfferEditMode: (editMode: boolean) =>
      dispatch(actions.assetBuilder.switchOfferEditMode(editMode)),
    resetAssetInstanceCounter: () => {
      dispatch(actions.assetBuilder.resetAssetInstanceCounter());
    },
    commitOrder: () => {
      return dispatch(actions.assetBuilder.commitOrder()) as Promise<void>;
    },
    fetchOrderState: (orderId: string) => {
      dispatch(actions.assetBuilder.fetchOrderState(orderId));
    },
    fetchSelectedOfferList: (selectedOfferVins: string[]) => {
      dispatch(actions.assetBuilder.fetchSelectedOfferList(selectedOfferVins));
    },
    setAssetInstanceComparator: (assetInstances: AssetInstanceRecord) => {
      dispatch(actions.assetBuilder.setAssetInstanceComparator(assetInstances));
    },
    saveOffer: (
      useUploadedJellyBean: boolean,
      type: "create" | "update",
      revertingOffer?: boolean,
      feedId?: string,
      resetOffer?: RawOfferData,
    ) => {
      return dispatch(
        actions.assetBuilder.saveOffer(
          useUploadedJellyBean,
          type,
          revertingOffer,
          feedId,
          resetOffer,
        ),
      ) as unknown as Promise<void>;
    },
    updateRawSelectedOffer: (offer: OfferData) => {
      return dispatch(actions.assetBuilder.updateRawSelectedOffer(offer));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Select);
