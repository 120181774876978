import React, { useMemo, useState } from "react";
import { Empty, Spin, message, Input } from "antd";
import API from "services";
import { UndoMessage } from "shared/components/UndoMessage";
import { useWindowSize } from "shared/hooks/useWindowSize";
import {
  AssetBatchDrawerMode,
  STEP_CREATE_BATCH,
  TAssetBatch,
  TTemplateComposition,
} from "shared/types/assetExporter";
import styles from "./AssetBatchesPanel.module.scss";
import { ItemAssetBatch } from "./assetBatchesPanel/ItemAssetBatch";
import { useAssetBatchesContext } from "./shared/contexts/AssetBatchesContext";
import {
  getBackgroundResizeType,
  isTemplateComposition,
  parseConditions,
  parseValueMappings,
} from "./shared/contexts/AssetBatchesContext.utils";
import { useFeedContext } from "./shared/contexts/FeedContext";
import { useDeleteAssetBatch } from "./shared/hooks/useDeleteAssetBatch";
import { StringParam, useQueryParam } from "use-query-params";
import { ITemplate } from "shared/types/designStudio";
import { useVideoCompositionAudios } from "./shared/hooks/useVideoCompositionAudios";
import { useVideoStitchingContext } from "./shared/contexts/VideoStitchingContext";
import { useUpsertAssetBatch } from "./shared/hooks/useUpsertAssetBatch";
import {
  errorNotification,
  successNotification,
} from "shared/components/customNotification/Notification";

type AssetBatchesPanelProps = {
  feedId: string;
  onClick: (assetBatch: TAssetBatch) => void;
  onReset: () => void;
  setOpenAssetBatchDrawer: (open: boolean) => void;
  setAssetBatchDrawerMode: (mode: AssetBatchDrawerMode) => void;
};

export const AssetBatchesPanel = ({
  feedId,
  onClick,
  onReset,
  setOpenAssetBatchDrawer,
  setAssetBatchDrawerMode,
}: AssetBatchesPanelProps) => {
  const { assetBatches, isFetching, setEditingAssetBatch, setIsUpdated } =
    useFeedContext();
  const { processAudioFiles } = useVideoCompositionAudios();
  const { setVideoAudios } = useVideoStitchingContext();
  const { windowInnerHeight } = useWindowSize();
  const { mutateAsync: upsertAssetBatch, isLoading: isCreatingAssetBatch } =
    useUpsertAssetBatch();
  const handleClick = (assetBatch: TAssetBatch) => onClick(assetBatch);
  const [_, setAssetId] = useQueryParam("assetId", StringParam);
  const [searchValue, setSearchValue] = useState("");

  const assetBatchesSearchResults = useMemo(() => {
    const lowercaseSearchValue = searchValue.toLowerCase();
    return assetBatches.filter(assetBatch => {
      const name = assetBatch.name;
      const lowercaseName = name.toLowerCase();
      return lowercaseName.includes(lowercaseSearchValue);
    });
  }, [assetBatches, searchValue]);

  const showUndoMessage = (assetBatch: TAssetBatch, allowUndo?: boolean) => {
    const { assetBatchId, name } = assetBatch;
    const messageKey = `deleteAssetBatch-${feedId}-${assetBatchId}`;

    if (allowUndo) {
      message.success({
        className: styles.undoMessage,
        key: messageKey,
        content: (
          <UndoMessage
            message={`Asset batch ${name} configuration was deleted.`}
            onClick={() => {
              deleteAssetBatch({ ...assetBatch, undo: true });
              message.destroy(messageKey);
            }}
            onClose={() => {
              message.destroy(messageKey);
            }}
          />
        ),
        duration: 10,
        size: "small",
      });
      return;
    }

    message.destroy(messageKey);
    message.success({
      className: styles.undoMessage,
      key: messageKey,
      content: "Asset Batch Restored.",
      duration: 2,
    });
  };

  const { mutate: deleteAssetBatch, isLoading } =
    useDeleteAssetBatch(showUndoMessage);

  const {
    saveBatchName,
    saveRuleConditions,
    setTemplates,
    setVariables,
    setCompositions,
    updateMaxStep,
    backgroundResizeTypes,
    setBackgroundResizeTypes,
    setAudioFiles,
  } = useAssetBatchesContext();

  const handleEdit = async (assetBatch: TAssetBatch) => {
    updateMaxStep(STEP_CREATE_BATCH.THREE_TEXT);
    setIsUpdated(false);
    setOpenAssetBatchDrawer(true);
    setAssetBatchDrawerMode("Edit");
    setEditingAssetBatch(assetBatch);
    setAssetId(assetBatch.assetBatchId?.toString() ?? "");

    const { name, conditions, compositions, audioFiles } = assetBatch;
    const templateCompositions = compositions.filter(
      isTemplateComposition,
    ) as TTemplateComposition[];
    saveBatchName(name);
    saveRuleConditions(parseConditions(conditions));
    const promises = templateCompositions.flatMap(composition =>
      API.services.designStudio.getTemplateById(composition.template),
    );
    const results = await Promise.all(promises);
    const templates = results
      .map(({ result }) => result?.template)
      .filter(Boolean);
    setTemplates(templates as ITemplate[]);

    setCompositions(compositions);
    setVariables(
      templateCompositions.map(composition => ({
        compositionId: composition.compositionId,
        variables: parseValueMappings(composition.variables),
      })),
    );

    templateCompositions.forEach(composition => {
      const bgResizeType = getBackgroundResizeType(composition.variables);
      setBackgroundResizeTypes({
        ...backgroundResizeTypes,
        [composition.compositionId]: bgResizeType,
      });
      setVideoAudios(prev => {
        const existingVideoAudio = prev.find(
          videoAudio => videoAudio.compositionId === composition.compositionId,
        );
        if (existingVideoAudio) {
          return prev.map(videoAudio =>
            videoAudio.compositionId === composition.compositionId
              ? {
                  ...videoAudio,
                  variableIds: [
                    ...new Set([
                      ...(videoAudio.variableIds || []),
                      ...composition.audioSelVariableId,
                    ]),
                  ],
                }
              : videoAudio,
          );
        } else {
          if (!composition.audioSelVariableId) return prev;
          return [
            ...prev,
            {
              compositionId: composition.compositionId,
              variableIds: [...composition.audioSelVariableId],
              canvas: undefined,
            },
          ];
        }
      });
    });

    if (!audioFiles) {
      setAudioFiles([]);
      return;
    }
    processAudioFiles(audioFiles, setAudioFiles);
  };

  const handleDuplicate = async (assetBatch: TAssetBatch) => {
    const {
      assetBatchId: _assetBatchId,
      variables,
      ...restAssetBatch
    } = assetBatch;

    const duplicateName = `${assetBatch.name} (copy)`;
    const upsertAssetBatchData: TAssetBatch = {
      ...restAssetBatch,
      name: duplicateName,
      ...(variables && { variables }),
    };

    try {
      const { assetBatch: newAssetBatch } = await upsertAssetBatch(
        upsertAssetBatchData,
      );
      handleEdit(newAssetBatch);
      successNotification({
        messageLabel: "You're editing the asset batch you just duplicated",
      });
    } catch (error) {
      errorNotification({
        messageLabel:
          "There was an error trying to duplicate the asset batch. Please try again.",
      });
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  return (
    <div
      className={styles.container}
      style={{ maxHeight: windowInnerHeight - 205 }}
    >
      <div className={styles.header}>Asset batches</div>
      <div className={styles.searchInputWrapper}>
        <Input
          value={searchValue}
          placeholder="Search"
          onChange={handleSearch}
        />
      </div>
      <Spin
        tip="Loading..."
        spinning={isLoading || isFetching || isCreatingAssetBatch}
        wrapperClassName={styles.batchListWrapper}
      >
        <div className={styles.panel}>
          {!assetBatchesSearchResults?.length && (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
          {assetBatchesSearchResults?.map(assetBatch => {
            const { assetBatchId } = assetBatch;
            return (
              <ItemAssetBatch
                key={assetBatchId}
                assetBatch={assetBatch}
                onClick={handleClick}
                onEdit={handleEdit}
                onDuplicate={handleDuplicate}
                onDelete={() => {
                  onReset();
                  deleteAssetBatch({ ...assetBatch, undo: false });
                }}
                isLoading={isLoading}
              />
            );
          })}
        </div>
      </Spin>
    </div>
  );
};
