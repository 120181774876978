import { useCallback, useRef } from "react";
import { getVideoData } from "../components/Preview/utils";
import { useVideoStitchingContext } from "../contexts/VideoStitchingContext";
import { DEFAULT_FPS } from "../constants";

interface Props {
  canvas: fabric.Canvas | undefined;
  currentFrame: number;
}

export const useVideoPlayer = ({ canvas, currentFrame }: Props) => {
  const animFrameRequest = useRef<number>(0);
  const playStatus = useRef(false);
  const { volume, isMuted, videoAudios, setVideoAudios } =
    useVideoStitchingContext();

  const updateVideoVolume = useCallback(
    (
      isPlaying: boolean,
      compositionId: string,
      audioBackgound?: HTMLAudioElement,
    ) => {
      if (!canvas) return;
      const videos = getVideoData(canvas);
      const variableAudioIds = videoAudios?.find(
        audio => audio.compositionId === compositionId,
      )?.variableIds;
      videos.forEach(element => {
        const video = element.getElement() as HTMLVideoElement;
        let isElementNameInVariableAudioIds = false;
        if (element.name) {
          isElementNameInVariableAudioIds =
            variableAudioIds?.some(variableAudioId =>
              element.name?.includes(variableAudioId),
            ) || false;
        }

        if (isPlaying && variableAudioIds && isElementNameInVariableAudioIds) {
          video.volume = volume;
        } else {
          video.volume = 0;
        }
      });
      if (isPlaying && audioBackgound) {
        audioBackgound.volume = volume;
      }
    },
    [canvas, videoAudios, volume],
  );

  const updateVideoMuted = useCallback(
    (
      isPlaying: boolean,
      compositionId: string,
      audioBackgound?: HTMLAudioElement,
    ) => {
      if (!canvas) return;
      const videos = getVideoData(canvas);
      const videoAudioSelected = videoAudios?.find(
        audio => audio.compositionId === compositionId,
      );
      const variableAudioIds = videoAudioSelected?.variableIds;

      videos.forEach(element => {
        const video = element.getElement() as HTMLVideoElement;
        let isElementNameInVariableAudioIds = false;
        if (element.name) {
          isElementNameInVariableAudioIds =
            variableAudioIds?.some(variableAudioId =>
              element.name?.includes(variableAudioId),
            ) || false;
        }

        if (isPlaying && variableAudioIds && isElementNameInVariableAudioIds) {
          video.muted = isMuted;
          if (!videoAudioSelected.canvas)
            setVideoAudios(prevVideoAudios =>
              prevVideoAudios?.map(audio =>
                audio.compositionId === compositionId
                  ? { ...audio, canvas }
                  : audio,
              ),
            );
        } else {
          video.muted = true;
        }
      });
      if (!audioBackgound) return;
      audioBackgound.muted =
        isPlaying && variableAudioIds?.includes(audioBackgound.id)
          ? isMuted
          : true;
    },
    [canvas, isMuted, setVideoAudios, videoAudios],
  );

  const updateVideoElements = useCallback(
    (isPlaying: boolean) => {
      if (!canvas) return;
      const videos = getVideoData(canvas);
      videos.forEach(element => {
        const video = element.getElement() as HTMLVideoElement;
        if (isPlaying) {
          video.play();
        } else {
          video.pause();
          video.currentTime = currentFrame / DEFAULT_FPS;
        }
      });
    },
    [canvas, currentFrame],
  );

  const playFrames = useCallback(
    (isPlaying: boolean, duration: number) => {
      if (!playStatus.current || !canvas) return;
      const videos = getVideoData(canvas);
      if (!videos) {
        return;
      }

      const rendering = videos.map(video => {
        const videoEle = video.getElement() as HTMLVideoElement;
        const maxDuration = duration === 0 ? 0 : duration + 0.0025;

        if (videoEle.currentTime >= maxDuration && isPlaying) {
          //the video must be paused if it is at the end of the main video duration
          videoEle.muted = true;
          return false;
        }
        video.videoTime = videoEle.currentTime;
        canvas.requestRenderAll();
        return true;
      });

      if (rendering.filter(Boolean).length !== 0) {
        requestAnimationFrame(() => {
          playFrames(isPlaying, duration);
        });
        return;
      }
      cancelAnimationFrame(animFrameRequest.current);
    },
    [canvas],
  );

  const togglePlaying = useCallback(
    (isPlaying: boolean, duration: number) => {
      updateVideoElements(isPlaying);
      playStatus.current = isPlaying;

      if (isPlaying) {
        animFrameRequest.current = requestAnimationFrame(() => {
          playFrames(isPlaying, duration);
        });
        return;
      }
      cancelAnimationFrame(animFrameRequest.current);
    },
    [playFrames, updateVideoElements],
  );

  return {
    togglePlaying,
    updateVideoVolume,
    updateVideoMuted,
  };
};
