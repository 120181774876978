import {
  PlusOutlined,
  UploadOutlined,
  InfoCircleFilled,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Upload,
  UploadProps,
  message,
  Tooltip,
  Drawer,
  Spin,
} from "antd";
import { fabric } from "fabric";
import _ from "lodash";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import API from "services";
import FormDrawer from "shared/components/FormDrawer";
import { IAccount } from "shared/types/accountManagement";
import { IBrand } from "shared/types/brandManagement";
import { IConfig } from "shared/types/configuration";
import {
  CreatingDataStatus,
  CustomUploadFile,
  FetchingDataStatus,
  IArtboard,
  IDesignStudioState,
  IExtendedFabricObject,
  IResponse,
  ITemplate,
  ITemplateTag,
  TTemplateType,
  UpdatingDataStatus,
  templateAssetTypes,
} from "shared/types/designStudio";
import { OperationMode } from "shared/types/inputValues";
import * as fabricHelpers from "../../utils/helpers.fabric";

import actions from "../../redux/rootActions";

import { useNavigate } from "react-router-dom";
import { User } from "redux/auth/auth.slice";
import { AppState } from "redux/store";
import { GenericLocationSelect } from "shared/components/select/GenericLocationSelect";
import { useFetchDealers } from "shared/hooks/useFetchDealersV2";
import { useFetchOems } from "shared/hooks/useFetchOems";
import {
  OnCompleteFunc,
  useMutateTemplate,
} from "shared/hooks/useMutateTemplate";
import uuid from "uuid";
import styles from "./NewTemplateDrawer.module.scss";
import { useDesignStudioEditor } from "./designStudioLayout/designStudioTable/hooks/useDesignStudioEditor";
import {
  DESIGN_STUDIO_V2_EDITOR_ENABLED,
  POLOTNO_EDITOR,
} from "./designStudioV2/constants";
import { availableTemplateTypes } from "./helpers";
import TemplateVersionsDrawer from "./templateVersions/TemplateVersionsDrawer";
import { useFetchTemplateVersions } from "./hooks/useFetchTemplateVersions";
import TemplateVersionsHeader from "./templateVersions/TemplateVersionsHeader";
import TemplateVersionsFooter from "./templateVersions/TemplateVersionsFooter";

const DEFAULT_UPLOAD_ERROR_MSG = "please upload HTML file or zip file";
const ZIP_FILE_ERROR_MSG = "zip file must contain 1 html file and imgs folder";

const getInitialTemplate = (user: User): ITemplate => ({
  status: "PUBLISHED",
  type: "carcut",
  name: "",
  assetType: "",
  thumbnailUrl: null,
  canvasJsonUrl: null,
  numOfStamps: 0,
  artboard: {
    name: "",
    asset_type: "",
    width: 0,
    height: 0,
    created_at: null,
    updated_at: null,
  },
  oems: [],
  stores: [],
  tags: [],
  createdBy: user.email,
  createdAt: Date.now(),
  lastUpdatedBy: null,
  lastUpdatedAt: null,
  isDeleted: false,
  state: "",
});

interface INewTemplateDrawer {
  fetchingData: FetchingDataStatus;
  creatingData: CreatingDataStatus;
  updatingData: UpdatingDataStatus;

  fetchData: (dataType: FetchingDataStatus, query?: string) => void;
  createTag: (tag: ITemplateTag) => void;
  tags: ITemplateTag[];
  artboards: IArtboard[];
  user: User;
  templateToUpdate?: ITemplate | null;
  mode?: OperationMode;
  showNewTemplateDrawer: boolean;
  closeDrawer: () => void;
  removeTemplateToUpdate: () => void;
  config?: IConfig;
}

const NewTemplateDrawer: FC<INewTemplateDrawer> = ({
  fetchingData,
  creatingData,
  updatingData,
  fetchData,
  createTag,
  tags,
  artboards,
  user,
  templateToUpdate,
  mode = "",
  showNewTemplateDrawer = false,
  closeDrawer,
  removeTemplateToUpdate,

  ...props
}) => {
  const [newTemplate, setNewTemplate] = useState<ITemplate>(
    getInitialTemplate(user),
  );

  const [filteredAssetTypes] = useState<string[]>(templateAssetTypes);

  const filterData = (
    type: FetchingDataStatus | "states" | "brands",
    arr: IBrand[] | IAccount[] | ITemplateTag[] | IArtboard[] | string[],
    searchby: string,
  ) => {
    if (searchBy.toLowerCase().trim() === "") {
      return arr;
    }

    switch (type) {
      case "brands":
        return (arr as IBrand[]).filter(brand =>
          brand.oem_name.toLowerCase().includes(searchby.toLowerCase()),
        );
      case "accounts":
        return (arr as IAccount[]).filter(account =>
          account.dealer_name.toLowerCase().includes(searchby.toLowerCase()),
        );
      case "tags":
        return (arr as ITemplateTag[]).filter(tag =>
          tag.name.toLowerCase().includes(searchby.toLowerCase()),
        );
      case "states":
        return (arr as string[]).filter(state =>
          state.toLowerCase().includes(searchby.toLowerCase()),
        );
      default:
        return arr;
    }
  };

  const { oems, isLoading: fetchingBrands } = useFetchOems();

  const { dealers, isFetching: fetchingDealers } = useFetchDealers();

  const [searchBy, setSearchBy] = useState<string>("");

  const filteredBrands = filterData("brands", oems, searchBy) as IBrand[];
  const filteredAccounts = filterData(
    "accounts",
    dealers,
    searchBy,
  ) as IAccount[];
  const filteredTags = filterData("tags", tags, searchBy) as ITemplateTag[];

  const [filteredArtboards, setFilteredArtboards] = useState<IArtboard[]>([]);

  const [selectedBrandNames, setSelectedBrandNames] = useState<string[]>([]);
  const [selectedAccountNames, setSelectedAccountNames] = useState<string[]>(
    [],
  );
  const [selectedTagNames, setSelectedTagNames] = useState<string[]>([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>("");
  const [selectedState, setSelectedState] = useState<string>("");
  const navigate = useNavigate();
  const [uploadFileErrorMsg, setUploadFileErrorMsg] = useState("");
  const [selectedHtmlZipId, setSelectedHtmlZipId] = useState<string>();
  const { setEditor } = useDesignStudioEditor();

  useEffect(() => {
    if (mode === "" || mode === "CREATE") {
      setNewTemplate(getInitialTemplate(user));
      setSelectedBrandNames([]);
      setSelectedAccountNames([]);
      setSelectedTagNames([]);
      setSelectedState("");
      setSelectedTemplateId("");
    }
  }, [mode, user]);

  const reset = useCallback(() => {
    // clear selected options
    setSelectedBrandNames([]);
    setSelectedAccountNames([]);
    setSelectedTagNames([]);
    setSelectedState("");
    setUploadFileErrorMsg("");
    setFileList([]);
    setSelectedTemplateId("");

    if (selectedHtmlZipId)
      API.services.designStudio.deleteZipFile(selectedHtmlZipId);

    // reset the template in state
    setNewTemplate(getInitialTemplate(user));
    closeDrawer();
  }, [closeDrawer, selectedHtmlZipId, user]);

  const goToEditor = useCallback(
    (templateId: string, isV3Template: boolean) => {
      if (DESIGN_STUDIO_V2_EDITOR_ENABLED && isV3Template) {
        setEditor({
          assetIds: [templateId],
          selectedId: templateId,
          sessionId: uuid.v4(),
        });

        return;
      }

      // For legacy design studio (V1)
      setTimeout(() => {
        const urlTemplate =
          "/design-studio/editor/templates/:targetId/editor-v2";
        navigate(urlTemplate.replace(":targetId", templateId));
      }, 300);
      return;
    },
    [setEditor, navigate],
  );

  const onComplete: OnCompleteFunc = useCallback(
    (variables, updatedTemplate) => {
      reset();
      message.success({
        content: `Template (${updatedTemplate.name}) updated successfully`,
        duration: 5,
      });

      if (variables.mode === "CREATE" && updatedTemplate.type !== "html") {
        goToEditor(
          updatedTemplate.id ?? "",
          updatedTemplate.type === POLOTNO_EDITOR,
        );
      }
    },
    [reset, goToEditor],
  );

  const mutation = useMutateTemplate(onComplete);

  useEffect(() => {
    if (!templateToUpdate) return;

    setNewTemplate({
      ...templateToUpdate,
    });
    setSelectedBrandNames(templateToUpdate.oems);
    setSelectedAccountNames(templateToUpdate.stores);
    setSelectedTagNames(templateToUpdate.tags);
    setSelectedState(templateToUpdate.state);
    if (templateToUpdate.id) {
      setSelectedTemplateId(templateToUpdate.id);
    }
  }, [templateToUpdate]);

  const {
    result: templateVersions,
    isLoading,
    error,
  } = useFetchTemplateVersions(selectedTemplateId);

  const [removeCarcutFromCanvas, setRemoveCarcutFromCanvas] =
    useState<boolean>(false);

  const onSelectBrandOrAccount =
    (type: "brands" | "accounts") => (value: string) => {
      setSearchBy(""); // we need to reset the list of options

      if (type === "brands" && !selectedBrandNames.includes(value)) {
        setSelectedBrandNames([...selectedBrandNames, value]);
      }

      if (type === "accounts" && !selectedAccountNames.includes(value)) {
        setSelectedAccountNames([...selectedAccountNames, value]);
      }
    };

  const [selectedHtmlText, setSelectedHtmlText] = useState<string>();
  const [fileList, setFileList] = useState<CustomUploadFile[]>([]);
  const [visible, setVisible] = useState<boolean>(false);

  const shouldDisplayUploadHtmlButton = useMemo(
    () => newTemplate.type === "html",
    [newTemplate],
  );

  const onHtmlFileChange: UploadProps["onChange"] = info => {
    setUploadFileErrorMsg("");
    const { file } = info;
    if (!file) return;
    const { status } = file;
    if (status === "removed") {
      setSelectedHtmlText(undefined);
      setFileList([]);
      return;
    }

    const reader = new FileReader();
    if (file.type === "application/zip") {
      reader.readAsDataURL(file as any);
      reader.onload = async () => {
        setFileList([{ ...file, name: file.name, status: "uploading" }]);
        const id = uuid();
        const { result, error } =
          await API.services.designStudio.getZipSignedUrl(id);

        if (error || !result) {
          message.error("Unable to get signed url");
          return;
        }

        const fileBlob = fabricHelpers.dataURLtoBlob(reader.result as string);
        const putResult = await API.services.designStudio.putZipFile(
          result.url,
          fileBlob,
        );

        if (putResult.status !== 200) {
          message.error("Unable to upload zip file");
          return;
        }

        const { result: isValid } =
          await API.services.designStudio.validateZipFile(id);

        if (isValid) {
          setSelectedHtmlZipId(id);
          setFileList([
            { ...file, name: file.name, email: user.email, status: "done" },
          ]);
          return;
        }

        API.services.designStudio.deleteZipFile(id);
        setSelectedHtmlZipId(undefined);
        setUploadFileErrorMsg(ZIP_FILE_ERROR_MSG);
        setFileList([]);
      };
      return;
    }
    reader.readAsText(file as any);
    reader.onload = async () => {
      const htmlText = reader.result as string;
      setFileList([{ ...file, name: file.name, status: "uploading" }]);

      const id = uuid();
      const { result, error } =
        await API.services.designStudio.getHtmlSignedUrl(id);

      if (error || !result) {
        message.error("Unable to get signed url");
        return;
      }
      const fileBlob = new Blob([htmlText], { type: "text/html" });
      const putResult = await API.services.designStudio.putHtmlFile(
        result.url,
        fileBlob,
      );

      if (putResult.status !== 200) {
        message.error("Unable to upload HTML file");
        return;
      }
      setSelectedHtmlText(id);
      setFileList([
        { ...file, name: file.name, email: user.email, status: "done" },
      ]);
    };
  };

  const drawerForm = (
    <Form layout="vertical" hideRequiredMark={true}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Select Asset Type">
            <Select
              className="asset-type-select"
              key="template-assetType-select"
              filterOption={false}
              style={{ width: "100%" }}
              value={newTemplate.assetType}
              onChange={assetType => {
                if (artboards.length === 0 && fetchingData === null) {
                  fetchData("artboards");
                }

                setNewTemplate({
                  ...newTemplate,
                  assetType,
                });
              }}
            >
              {filteredAssetTypes.map((assetType: string, index: number) => (
                <Select.Option
                  value={assetType}
                  key={`assetType-option-key-${index}`}
                >
                  {assetType}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Define Template Name">
            <Input
              className="template-name-input"
              key="template-name-input"
              value={newTemplate.name}
              onChange={event => {
                setNewTemplate({
                  ...newTemplate,
                  name: event.target.value,
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Set Artboard Size + Name">
            <Select
              showSearch
              key="new-template-artboard-key"
              value={newTemplate.artboard.name}
              filterOption={true}
              style={{ width: "100%" }}
              onFocus={() => {
                if (artboards.length === 0 && fetchingData === null) {
                  fetchData("artboards");
                } else {
                  setFilteredArtboards(
                    _.orderBy(
                      artboards.filter(
                        tmpArtboard =>
                          tmpArtboard.asset_type === newTemplate.assetType,
                      ),
                      [artboard => artboard.name.toLowerCase()],
                      ["asc"],
                    ),
                  );
                }
              }}
              onChange={value => {
                const selectedArtboard = filteredArtboards.find(
                  artboard =>
                    artboard.name === // this key text must equal to the key in Select.Option
                    value,
                );

                if (!selectedArtboard) {
                  throw new Error(
                    `The selected artboard: ${value} must exists.`,
                  );
                }
                setNewTemplate({
                  ...newTemplate,
                  artboard: selectedArtboard,
                });
              }}
            >
              {filteredArtboards.map((artboard: IArtboard) => (
                <Select.Option
                  value={artboard.name}
                  key={`new-template-artboard-option-${artboard.name}`}
                >
                  {artboard.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Type">
            <Select
              key="new-template-type-key"
              value={newTemplate.type}
              className="type-select"
              style={{ width: "100%" }}
              onChange={(selectedType: TTemplateType) => {
                if (selectedType === "html") {
                  setNewTemplate({
                    ...newTemplate,
                    type: selectedType,
                  });

                  return;
                }

                // resetting html text for "html" type template.
                setSelectedHtmlText(undefined);
                setFileList([]);

                // NOTE: check if use tries to change template type.
                // If the existing template type was carcut and tries to change to lifestyle,
                // display confirmation that this action will remove the carcut image within the canvas.
                // If changing from lifestyle to carcut, proceed as normal.
                const carcutToLifestyle =
                  mode === "UPDATE" &&
                  (!templateToUpdate?.type || // handling existing template (default type to carcut) before lifestyle change
                    templateToUpdate?.type === "carcut") &&
                  selectedType === "lifestyle";

                if (carcutToLifestyle) {
                  // If this is true and the user wants to proceed, the actual operation
                  // that removes carcut has to be done at the very end because user might
                  // select cancel and close the drawer at the end.
                  Modal.confirm({
                    title: "Changing template type.",
                    content:
                      "This action will remove Carcut image within the template. Are you sure you want to proceed?",
                    okText: "Remove",
                    okType: "primary",
                    okButtonProps: { danger: true },
                    onOk: () => {
                      setRemoveCarcutFromCanvas(true);
                      setNewTemplate({
                        ...newTemplate,
                        type: selectedType,
                      });
                    },
                  });

                  return;
                }

                setRemoveCarcutFromCanvas(false); // setting back to false

                setNewTemplate({
                  ...newTemplate,
                  type: selectedType,
                });
              }}
            >
              {availableTemplateTypes.map(type => (
                <Select.Option
                  key={`new-template-type-option-${type}`}
                  value={type!}
                >
                  <span>{type}</span>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      {shouldDisplayUploadHtmlButton && (
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label={
                <>
                  .zip folder<span style={{ color: "red" }}>*</span> &nbsp;
                  <Tooltip
                    overlayStyle={{ maxWidth: "491px" }}
                    title="The .zip file must have an HTML file as well as a folder containing its image assets. Supported formats for images: jpg, jpeg, gif, png, webp, svg."
                  >
                    <InfoCircleFilled style={{ color: "#00000073" }} />
                  </Tooltip>
                </>
              }
            >
              <Upload
                accept=".html, .zip"
                showUploadList={{ showRemoveIcon: true }}
                multiple={false}
                beforeUpload={() => false}
                onChange={onHtmlFileChange}
                fileList={fileList}
                maxCount={1}
              >
                <Button
                  icon={<UploadOutlined />}
                  style={uploadFileErrorMsg ? { borderColor: "red" } : {}}
                >
                  Click to Upload
                </Button>
              </Upload>
              {uploadFileErrorMsg && (
                <span className={styles.uploadHtmlFileError}>
                  {uploadFileErrorMsg}
                </span>
              )}
              <div style={{ marginTop: "10px" }}>
                <Spin spinning={isLoading} size="small" />
                {templateVersions && selectedTemplateId && (
                  <span
                    className={styles.versionLink}
                    onClick={() => {
                      if (templateVersions.length > 0) {
                        setVisible(true);
                      }
                    }}
                  >
                    {`Version History (${templateVersions.length ?? 0})`}
                  </span>
                )}
                {error && <span>Failed to load versions</span>}
              </div>
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row key="Row-select-brands" gutter={16}>
        <Col span={24}>
          <Form.Item label="Assign Brand(s)">
            <Select
              className="select-for-oems"
              key="new-template-brands-select"
              mode="multiple"
              filterOption={true}
              style={{ width: "100%" }}
              value={selectedBrandNames.filter(name => name)}
              onFocus={() => {
                setSearchBy(""); // reset the list
              }}
              loading={fetchingBrands}
              onSearch={value => {
                setSearchBy(value);
              }}
              onSelect={onSelectBrandOrAccount("brands")}
              onDeselect={value => {
                setSelectedBrandNames(
                  selectedBrandNames.filter(name => name !== value),
                );
              }}
            >
              {filteredBrands.map(brand => (
                <Select.Option
                  data-cy={brand.oem_name.replace(/\s/g, "-")}
                  value={brand.oem_name}
                  key={`brands-key-${brand.oem_name}`}
                  className={`oem-select-item`}
                >
                  {brand.oem_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row key="Row-select-accounts" gutter={16}>
        <Col span={24}>
          <Form.Item label="Assign Account(s) (optional)">
            <Select
              className="select-for-stores"
              key="new-template-account-select"
              mode="multiple"
              filterOption={true}
              style={{ width: "100%" }}
              value={selectedAccountNames.filter(name => name)}
              onFocus={() => {
                setSearchBy(""); // reset the list
              }}
              loading={fetchingDealers}
              onSearch={value => {
                setSearchBy(value);
              }}
              onSelect={onSelectBrandOrAccount("accounts")}
              onDeselect={value => {
                setSelectedAccountNames(
                  selectedAccountNames.filter(name => name !== value),
                );
              }}
            >
              {filteredAccounts.map(account => (
                <Select.Option
                  value={account.dealer_name}
                  key={`accounts-key-${account.dealer_name}`}
                >
                  {account.dealer_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Assign State (optional)">
            <GenericLocationSelect
              className="select-for-states"
              defaultValue={selectedState}
              value={selectedState}
              onChange={(value: string) => {
                setSelectedState(value);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Assign Tag(s) (optional)">
            <Select
              className={`select-for-tags`}
              key="new-template-tags-select"
              mode="multiple"
              filterOption={false}
              loading={
                creatingData === "tag" ||
                (tags.length === 0 && fetchingData === "tags")
              }
              style={{ width: "100%" }}
              onFocus={() => {
                if (tags.length === 0 && fetchingData === null) {
                  fetchData("tags");
                }

                setSearchBy("");
              }}
              onSearch={(value: string) => {
                setSearchBy(value);
              }}
              onSelect={value => {
                setSelectedTagNames([...selectedTagNames, value]);

                setSearchBy("");
              }}
              onDeselect={value => {
                setSelectedTagNames(
                  selectedTagNames.filter(tagName => tagName !== value),
                );
              }}
              value={selectedTagNames.filter(tagName => tagName)}
              dropdownRender={menu => {
                /**
                 * This part is to add "Add Tag" button in the dropdown menu.
                 * So the user interaction goes as following. User clicks on the select and then the
                 * dropdown shows. If the dropdown do not contain any data (by user search or no data at init),
                 * the "menu" object parameter will have only ONE object data, menu.props.menuItems[0].key === "NOT_FOUND".
                 * Use this to determine if we need to show "Add Tag" button or not.
                 *
                 * NOTE: if there is at least one data, the menu.props.menuItems will have length equal to the number of data.
                 */

                const menuItems = menu.props.options;
                if (menuItems.length === 0) {
                  return (
                    <>
                      <div
                        className={styles.addTagDropdownContainer}
                        style={{
                          zIndex: 1000,
                        }}
                        onMouseDown={e => {
                          e.preventDefault(); // ******** This part is needed to make the onClick method to work properly..
                        }}
                        onClick={() => {
                          /**
                           * the internal start "searchBy" will be the tag that user tries to add
                           * at the moment this button clicked. This is true because we clear searchBy
                           * onFocus handler.
                           *
                           * NOTE: validate the searchBy. It is possible that searchBy is an empty string
                           * and user clicks on the "Add Tag" button when there is no tag data at init.
                           */
                          if (searchBy.trim() === "") {
                            Modal.warning({
                              title:
                                "The tag name is not valid. Please enter valid tag name.",
                            });

                            return;
                          }

                          Modal.confirm({
                            title: "Do you want to add new tag?",
                            content: (
                              <span
                                className={styles.addTemplateTagContainerSpan}
                              >
                                Are you sure you want to add new tag,
                                <span>{searchBy}</span>
                                for this template?
                              </span>
                            ),
                            okText: "Add",
                            onOk: () => {
                              // fill
                              const tagObject: ITemplateTag = {
                                name: searchBy,
                                createdAt: new Date().getTime(),
                                createdBy: user.email,
                              };

                              setSelectedTagNames([
                                ...selectedTagNames,
                                tagObject.name,
                              ]);
                              createTag(tagObject);
                              setSearchBy("");
                            },
                          });
                        }}
                      >
                        <PlusOutlined /> Add Tag
                      </div>
                    </>
                  );
                }

                return menu;
              }}
            >
              {filteredTags.map((tag: ITemplateTag) => (
                <Select.Option
                  value={tag.name}
                  key={`option-key-${tag.name}`}
                  className="tag-select-item"
                >
                  {tag.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );

  const handleClose = () => {
    // clear prev data
    setSelectedBrandNames([]);
    setSelectedAccountNames([]);
    setSelectedTagNames([]);
    setNewTemplate(getInitialTemplate(user));
    setUploadFileErrorMsg("");
    setFileList([]);
    setSelectedTemplateId("");

    if (selectedHtmlZipId)
      API.services.designStudio.deleteZipFile(selectedHtmlZipId);

    // clear templateToUpdate
    removeTemplateToUpdate();
    closeDrawer();
  };

  const validateTemplate = (template: Partial<ITemplate>) => {
    const { name, assetType, artboard } = template;
    return !!name?.trim() && !!assetType?.trim() && !!artboard?.name?.trim();
  };

  return (
    <>
      <FormDrawer
        mode={mode}
        drawerWidth={720}
        dataName={"Template"}
        processingData={
          creatingData === "template" || updatingData === "template"
        }
        showDrawer={showNewTemplateDrawer}
        validForm={true}
        drawerForm={drawerForm}
        handleClose={handleClose}
        handleSubmit={() => {
          if (creatingData || fetchingData) {
            message.warning(
              "The template cannot be sumitted due to some operations are in progress!",
            );

            return;
          }

          if (
            newTemplate.type === "html" &&
            !selectedHtmlText &&
            !selectedHtmlZipId
          ) {
            setUploadFileErrorMsg(DEFAULT_UPLOAD_ERROR_MSG);
            return;
          }

          setUploadFileErrorMsg("");
          const template: ITemplate = {
            ...newTemplate,
            oems: selectedBrandNames,
            stores: selectedAccountNames,
            tags: selectedTagNames,
            createdAt:
              (templateToUpdate && templateToUpdate.createdAt) ||
              new Date().getTime(),
            // if this is edit, the lastUpdated* have to be updated as well
            lastUpdatedAt: (templateToUpdate && new Date().getTime()) || null,
            lastUpdatedBy: (templateToUpdate && user.email) || null,
            state: selectedState || "",
            mediaType: newTemplate.mediaType ?? "png", // png will be the default mediaType (file mimeType of exported asset)
          };

          if (!validateTemplate(template)) {
            message.warning(
              "The template cannot be sumitted due to missing fields.",
            );

            return;
          }

          const mutate = async () => {
            const templateToMutate = removeCarcutFromCanvas
              ? await removeCarcut(template, props.config)
              : template;

            if (!templateToMutate) return; // NOTE: error has been handled in removeCarcut()

            mutation.mutate({
              mode,
              template: templateToMutate,
              htmlText: selectedHtmlText,
              zipFileId: selectedHtmlZipId,
              fileList,
            });
          };

          mutate();

          closeDrawer();
        }}
      />
      <Drawer
        className={"drawer"}
        closable={false}
        title={<TemplateVersionsHeader />}
        width={840}
        visible={visible}
        bodyStyle={{ padding: 0 }}
        footer={<TemplateVersionsFooter onClose={() => setVisible(false)} />}
      >
        {visible && (
          <TemplateVersionsDrawer templateVersions={templateVersions ?? []} />
        )}
      </Drawer>
    </>
  );
};

const removeCarcut = async (template: ITemplate, config?: IConfig) => {
  const messageKey = "remove-carcut-message-key";

  message.loading({
    duration: 0,
    key: messageKey,
    content: "Downloading template...",
  });

  // download canvasJson first
  const { canvasJsonUrl } = template;
  const canvasJson = await (
    await fetch(`${canvasJsonUrl}?date=${new Date().getTime()}`)
  ).json();

  message.loading({
    duration: 0,
    key: messageKey,
    content: "Removing carcut images...",
  });

  // remove all carcut images
  const updatedCanvasJson = {
    ...canvasJson,
    objects: (canvasJson.objects as IExtendedFabricObject[])
      .map(obj => {
        if (obj.customType === "car_cut") {
          return null;
        }

        return obj;
      })
      .filter(obj => !!obj),
  };

  message.loading({
    duration: 0,
    key: messageKey,
    content: "Generating thumbnail image...",
  });

  const params = await getParamsForSaveDraft({
    ...updatedCanvasJson,
  });

  // save
  const request: RequestInfo = new Request(
    (config as IConfig).services.designStudio.saveDraftUrl,
    {
      method: "POST",
      body: JSON.stringify({
        base64Thumbnail: params.thumbnail,
        template,
        canvasJson: updatedCanvasJson,
      }),
    },
  );

  const { result, error } = await API.send<IResponse<ITemplate>>(request);

  if (error || !result.template) {
    message.error({
      key: messageKey,
      content: "There was an error while saving update. Please try again.",
    });

    return;
  }

  message.success({
    key: messageKey,
    content: "Sucessfully removed carcut images.",
  });

  return result.template;
};

const getParamsForSaveDraft = (canvasJson: string) => {
  return new Promise<{ thumbnail: string; numberOfStamps: number }>(
    (resolve, reject) => {
      const canvas = document.createElement("canvas");
      const fabricCanvas = new fabric.Canvas(canvas);

      try {
        fabricCanvas.loadFromJSON(canvasJson, () => {
          const canvasDataUri = fabricCanvas.toDataURL({
            format: "jpeg",
            quality: 0.6,
          });

          const base64ImageContent = canvasDataUri.replace(
            /^data:image\/(jpg|jpeg);base64,/,
            "",
          );

          const numOfStamps = fabricHelpers.countStamps(fabricCanvas);

          resolve({
            thumbnail: base64ImageContent,
            numberOfStamps: numOfStamps,
          });
        });
      } catch (err) {
        reject(null);
      }
    },
  );
};

const mapStateToProps = (state: AppState) => {
  const { auth, designStudio, configuration } = state;
  const { user } = auth;
  const { config } = configuration;

  const {
    fetchingData,
    creatingData,
    updatingData,
    tags,
    artboards,
    templateToUpdate,
  } = designStudio as IDesignStudioState;

  return {
    user,
    fetchingData,
    creatingData,
    updatingData,
    tags,
    artboards,
    templateToUpdate,
    config,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchData: (dataType: FetchingDataStatus, query = "") => {
      dispatch(actions.designStudio.fetchData(dataType, query));
    },
    createTag: (tag: ITemplateTag) => {
      dispatch(actions.designStudio.createTag(tag));
    },
    removeTemplateToUpdate: () => {
      dispatch(actions.designStudio.removeTemplateToUpdate());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewTemplateDrawer);
