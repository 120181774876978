import { useMemo } from "react";
import { useInfiniteQuery } from "react-query";
import API from "services";
import { IAssetGroup } from "shared/types/adLibrary";

type Args = {
  selectedIds: string[];
};

type QueryReturnType = { assetGroups: IAssetGroup[]; paginationKey?: string };

export default (args: Args) => {
  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useInfiniteQuery<QueryReturnType, Error>({
      queryKey: ["asset-groups"],
      queryFn: ({ pageParam: paginationKey }) => {
        return API.services.adLibrary.getAssetGroups({ paginationKey });
      },
      getNextPageParam: lastPage => {
        return lastPage?.paginationKey;
      },
    });

  if (hasNextPage && !isFetchingNextPage) {
    fetchNextPage();
  }

  const { selectedIds } = args;

  const flattenedAssetGroups = useMemo(
    () => data?.pages.flatMap(page => page.assetGroups) || [],
    [data],
  );
  const selectedAssetGroups = useMemo(
    () =>
      flattenedAssetGroups.filter(flattenedAssetGroup =>
        selectedIds.includes(flattenedAssetGroup.id),
      ) || [],
    [selectedIds, flattenedAssetGroups],
  );

  return {
    isFetching: isLoading,
    assetGroups: flattenedAssetGroups,
    selectedAssetGroups,
  };
};
