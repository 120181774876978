// TODO: note that we are skipping the unit test for this file because we weren't able to find a
//       way to mock fabric class. In order to add this file in coverage, check jest.collectCoverageFrom in package.json
import { fabric } from "fabric";
import {
  CanvasObject,
  CommaField,
  ICanvasData,
  ICanvasObject,
  IGroupObject,
  IImageObject,
  IOffer,
  IProcessedOfferData,
  IQueryParameters,
  IStampObject,
  ITextObject,
  OfferData,
  isDisclosure,
  isImage,
  isLogo,
  isStamp,
  isText,
} from "shared/types/assetBuilder";
import {
  AlignLayersActionType,
  CanvasType,
  ExtendedObjectType,
  ICustomObjectData,
  IDimension,
  IExtendedFabricObject,
  IExtendedTextbox,
  IFloatingSelectType,
  ILayerObject,
  IStamp,
  ITemplate,
  ITextSelection,
  IVariableAlias,
  TCustomDataObject,
  TLayerObjectProperty,
  TTextObjectProperty,
  TVerticalAlign,
} from "shared/types/designStudio";
import { returnOpenCurlyIndex } from "utils/fabric/helpers.text";
import { isEmpty, keys } from "lodash";
import moment from "moment";
import API from "services";
import {
  commaFormattedFields,
  fieldsToNotPopulateUsingOfferData,
  roundableProps,
} from "shared/constants/assetBuilder";
import {
  dateFormat,
  storeVariableNames,
} from "shared/constants/dataManagement";
import { IAccount } from "shared/types/accountManagement";
import {
  IStateDisclosureElement,
  IStateDisclosureRecord,
  IStateExceptionElement,
  IStateExceptionRecord,
} from "shared/types/legalLingo";
import { IBrand } from "shared/types/brandManagement";
import { OfferType } from "shared/types/shared";
import uuid from "uuid";
import carCutImagePath from "../statics/images/car_cut.png";
import * as helpers from "../utils/helpers";
import * as offerHelpers from "../utils/helpers.offer";
import * as textHelpers from "./fabric/helpers.text";
import { getHeight, getWidth } from "./fabric/helpers.utils";

interface IRenderContext {
  offerData: OfferData;
  getStampData: (id: string) => Promise<IStamp | null>;
  getOemData: (name: string) => Promise<IBrand[]>;
  getDealerData: () => Promise<IAccount>;
  getDisclosureData?: (state: string) => Promise<IStateDisclosureRecord[]>;
  getExceptionData?: (
    state: string,
    oem: string,
  ) => Promise<IStateExceptionRecord[]>;
  getOfferData: (parameters?: IQueryParameters) => Promise<IOffer[]>;
  offerTypes?: OfferType[];
}

interface IStyleTracker {
  char: string;
  style: any;
  indexInLine: number;
  lineNumber: number;
}

/**
 *
 * @param textbox if this parameter is defined, it means to clone it.
 */
export const initiateTextbox = ({
  text,
  properties,
}: {
  text: string;
  properties?: fabric.TextOptions;
}) => {
  const newTextbox = new fabric.Textbox(text);

  if (properties) {
    // There was case where this object "properties" have value that's undefined.
    // When any of the value is set to undefined, the fabricjs crashes with "Cannot read properties of undefined (reading 'toLowerCase')"
    // Here, we are trying to flter out the keys that have value undefined.

    const filteredProperties = keys(properties).reduce<fabric.TextOptions>(
      (acc, key) => {
        const value = properties[key as keyof fabric.TextOptions];
        if (!value) return acc;

        return {
          ...acc,
          [key]: value,
        };
      },
      {},
    );
    newTextbox.set(filteredProperties);
  }

  return newTextbox;
};

export const disableTextboxControls = (textbox: fabric.Textbox) => {
  textbox.set({
    borderColor: "#3399ff",
  });
  textbox.setControlVisible("tl", false);
  textbox.setControlVisible("tr", false);
  textbox.setControlVisible("mb", false);
  textbox.setControlVisible("mt", false);
  textbox.setControlVisible("br", false);
  textbox.setControlVisible("bl", false);
};

export const getDefaultTextboxProperties = (from: IExtendedTextbox) => {
  const {
    fill,
    fontSize,
    fontFamily,
    width,
    height,
    name,
    styles,
    charSpacing,
    lineHeight,
    deltaY,
    opacity,
    textAlign,
    padding,
    originalText,
    variableAlias,
  } = from;
  return {
    fill,
    fontSize,
    fontFamily,
    width,
    height,
    name,
    styles,
    charSpacing,
    lineHeight,
    deltaY,
    opacity,
    textAlign,
    padding,
    originalText,
    variableAlias,
  };
};

/**
 * @param canvas fabric canvas
 * @param object fabric object to find its index from canvas.getObjects()
 *
 * @returns number 0 or positive number | null
 */
export const findIndexOfActiveObjectInReverseOrder = ({
  canvas,
}: {
  canvas: fabric.Canvas;
}) => {
  const activeObject = canvas.getActiveObject();
  const foundIndex = canvas
    .getObjects()
    .findIndex(object => object === activeObject);

  return foundIndex >= 0 ? foundIndex : null;
};

export const replaceCanvasObjectProps = (
  canvasObject: fabric.Object,
  layerObject: ILayerObject,
  selectionObject: ITextSelection | null,
  textOperationProp: "" | TTextObjectProperty,
) => {
  if (canvasObject.type === "textbox" && textOperationProp) {
    replaceTextObjectProps(
      canvasObject as fabric.Textbox,
      layerObject,
      selectionObject,
      textOperationProp,
    );
  } else {
    const extendedObject = canvasObject as IExtendedFabricObject;
    const { customData } = extendedObject;
    const propKeys = Object.keys(layerObject) as TLayerObjectProperty[];
    propKeys.forEach((key: TLayerObjectProperty) => {
      if (typeof layerObject[key] !== "undefined") {
        if (key === "name" && customData) {
          extendedObject.set("customData", {
            ...customData,
            layerName:
              layerObject.name || (customData as ICustomObjectData).layerName,
          });
        } else {
          canvasObject.set(key, layerObject[key]);
        }
      }
    });
  }
};

const replaceTextObjectProps = (
  textObject: fabric.Textbox,
  layerObject: ILayerObject,
  selectionObject: ITextSelection | null,
  textOperationProp: TTextObjectProperty,
) => {
  // Fabric JS defines the styles arg with the any type, so I am not sure what other type to use...
  const styles = {} as any;
  // check if there is text selected in the textbox

  if (textOperationProp === "deltaY") {
    toggleSuperOrSubscript(
      textObject,
      layerObject.deltaY || 1,
      selectionObject,
    );
  } else if (textOperationProp === "verticalAlign") {
    setVerticalAlign(textObject, layerObject);
  } else {
    if (selectionObject) {
      styles[textOperationProp] = layerObject[textOperationProp];
      textObject.setSelectionStyles(styles);
    } else {
      textObject.removeStyle(textOperationProp);
      textObject.set(textOperationProp, layerObject[textOperationProp]);
      /*
         AV2-1885: set each char style when applying style to whole textbox
         to prevent any issues with styles being undefined
       */
      try {
        const newStyles = { ...textObject.styles };
        for (const lineNumber in textObject.styles) {
          const currentLine = textObject.styles[lineNumber];
          for (const charIndex in currentLine) {
            const charStyle = currentLine[charIndex];
            const newStyle = {
              ...charStyle,
              [textOperationProp]: layerObject[textOperationProp],
            };
            newStyles[lineNumber][charIndex] = newStyle;
          }
        }

        textObject.set({ styles: newStyles, dirty: true });
      } catch (error) {
        /*
           just skip single char style setting
           if an error is thrown.
         */
      }
    }
  }
};

const toggleSuperOrSubscript = (
  textObject: fabric.Textbox,
  deltaY: number,
  selectionObject: ITextSelection | null,
) => {
  const textSelection = selectionObject || {
    start: 0,
    end: (textObject.text || "").length,
  };

  if (!selectionObject) {
    textObject.setSelectionStart(textSelection.start);
    textObject.setSelectionEnd(textSelection.end);
  }

  switch (deltaY) {
    case -14:
      textObject.setSuperscript(textSelection.start, textSelection.end);
      break;
    case 4.4:
      textObject.setSubscript(textSelection.start, textSelection.end);
      break;
    default:
      textObject.setSelectionStyles({
        deltaY: undefined,
        fontSize: undefined,
      });
      break;
  }
};

export const setTextboxHeightForVerticalAlign = (
  canvasObject: fabric.Object,
  layerObject?: ILayerObject,
) => {
  const extendedObj = canvasObject as IExtendedFabricObject;
  const textbox = canvasObject as fabric.Textbox;
  const { text = "" } = textbox;
  const rect = textbox.getBoundingRect();
  const textHeight = textbox.calcTextHeight();
  const textLineHeight = textHeight / textbox.textLines.length;
  const lastChar = text[text.length > 0 ? text.length - 1 : 0];
  const islastCharNewLine =
    lastChar === "↵" || lastChar === "\n" || lastChar === "\r";

  if (!textbox.height) {
    textbox.height = rect.height;
  }

  const { verticalAlign = "top", height = 0 } = layerObject || {};

  if (verticalAlign === "top") {
    textbox.height = islastCharNewLine ? height + textLineHeight : height;
    textbox.set("dirty", true);
    return;
  }

  if (layerObject) {
    const charDeltaY = getCharDeltaYFromCanvasObj(textbox);
    if (textHeight + (charDeltaY || 0) < height) {
      textbox.height =
        verticalAlign === "middle" && islastCharNewLine
          ? height + textLineHeight
          : height;
    } else {
      switch (verticalAlign) {
        case "middle":
          textbox.height = height + (height - textHeight + (charDeltaY || 0));
          break;
        case "bottom":
          textbox.height = height + textLineHeight;
          break;
        default:
          break;
      }
    }
    textbox.set("dirty", true);
    if (extendedObj.customData) {
      (extendedObj.customData as ICustomObjectData).textboxHeight =
        textbox.height;
    }
  } else {
    const charDeltaY = getCharDeltaYFromCanvasObj(textbox);

    if (charDeltaY) {
      textbox.height += charDeltaY;
    }
  }
};

export const getCharDeltaYFromCanvasObj = (
  canvasObject: fabric.Object,
): number | undefined => {
  const textboxObject = canvasObject as fabric.Textbox;
  const objStyles = textboxObject.styles || {};
  const firstStyle = objStyles[0] && objStyles[0][0] ? objStyles[0][0] : {};
  const { deltaY } = firstStyle;
  return deltaY;
};

export const getVericalAlignFromCanvasObj = (canvasObject: fabric.Object) => {
  /*
     Possible TO DO: extend canvas object to contain
     "verticalAlign" property  instead of doing this
   */

  let newVerticalAlign: TVerticalAlign = "top";
  const deltaY = getCharDeltaYFromCanvasObj(canvasObject);

  const activeTextbox = canvasObject as fabric.Textbox;
  const { fontSize = 40 } = activeTextbox;

  const subscriptDelta = parseFloat((fontSize / 9.09).toFixed(1));
  const superscriptDelta = -parseFloat((fontSize / 2.857).toFixed(1));

  const rectBound = activeTextbox.getBoundingRect(true, true);
  const { height: rectHeight } = rectBound;
  const textHeight = activeTextbox.calcTextHeight();
  const heightDiff = Math.ceil(rectHeight - textHeight);

  if (deltaY && deltaY !== subscriptDelta && deltaY !== superscriptDelta) {
    if (deltaY > 0 && deltaY <= heightDiff / 2) {
      newVerticalAlign = "middle";
    } else if (deltaY >= heightDiff / 2 && deltaY <= heightDiff) {
      newVerticalAlign = "bottom";
    } else {
      newVerticalAlign = "top";
    }
  }

  return newVerticalAlign;
};

export const setVerticalAlign = (
  textObject: fabric.Textbox,
  layerObject: ILayerObject,
) => {
  const extTextbox = textObject as fabric.Object as IExtendedFabricObject;

  const { verticalAlign = "top" } = layerObject;
  const rectBound = textObject.getBoundingRect(true, true);
  const { height: rectHeight } = rectBound;
  const textHeight = textObject.calcTextHeight();
  const heightDiff = Math.ceil(rectHeight - textHeight);

  const charStyles = textObject.getSelectionStyles(
    0,
    (textObject.text || "").length,
  );

  charStyles.forEach((style, index) => {
    const { deltaY = 0 } = style;
    let newDeltaY = deltaY;
    switch (verticalAlign) {
      case "top":
        newDeltaY = 0;
        break;
      case "middle":
        if (newDeltaY > heightDiff / 2) {
          newDeltaY -= heightDiff / 2;
        } else {
          newDeltaY += heightDiff / 2;
        }
        break;
      case "bottom":
        newDeltaY += newDeltaY > 0 ? heightDiff / 2 : heightDiff;
        break;
      default:
        break;
    }
    textObject.setSelectionStyles({ deltaY: newDeltaY }, index, index + 1);
  });

  /*
     it seems customData needs to be set again due to
     when getting object usign canvas.getObjects, so
     the customData has to be set again
   */
  extTextbox.set({
    customData: {
      layerName: layerObject.name || "",
      verticalAlign,
      textboxHeight: layerObject.height,
    },
  });
};

export const rearrangeCanvasObjects = (
  canvas: fabric.Canvas,
  canvasObjectToAlterIndex: number,
  arrangeValue: string,
) => {
  const currentObject = canvas.getObjects().reverse()[canvasObjectToAlterIndex];
  const foundIndex = canvas
    .getObjects()
    .findIndex(object => object === currentObject);
  if (arrangeValue === "up") {
    canvas.moveTo(currentObject, foundIndex + 1);
  }
  if (arrangeValue === "down") {
    canvas.moveTo(currentObject, foundIndex - 1);
  }
};

export const combineTextLinesForSelectedVariable = ({
  variable,
  textbox,
  floatingSelect,
  caretIndexRef,
  currMenuFilter,
}: {
  variable: string;
  textbox: fabric.Textbox;
  floatingSelect: IFloatingSelectType;
  caretIndexRef: React.MutableRefObject<number | null>;
  currMenuFilter: string;
}): string => {
  const { textLines } = textbox;

  if (!textLines) {
    return "";
  }

  const text =
    textLines && floatingSelect.cursorPosition.lineIndex <= textLines.length - 1
      ? textLines[floatingSelect.cursorPosition.lineIndex]
      : null;

  if (!text) {
    return "";
  }

  const keyToInsert = `{${variable}}`;

  if (textLines.length > 1) {
    const index = returnOpenCurlyIndex(textbox.text || "");

    const firstPart = textbox?.text?.split("").splice(0, index).join("");

    const secondPart = textbox?.text
      ?.split("")
      .splice(index + currMenuFilter.length + 1)
      .join("");

    return `${firstPart}${keyToInsert}${secondPart}`;
  } else {
    const { current } = caretIndexRef;

    const firstPart = text.slice(
      0,
      (current || 0) - (currMenuFilter.length + 1),
    );

    const secondPart = text.slice(current || text.length);

    const newText = firstPart.concat(keyToInsert).concat(secondPart);

    return newText;
  }
};

export const extendFabricObject = ({
  objectType,
  object,
  properties,
}: {
  objectType: ExtendedObjectType;
  object: fabric.Object;
  properties?: TCustomDataObject;
}) => {
  return fabric.util.object.extend(object, {
    customType: objectType,
    customData: properties
      ? {
          ...properties,
        }
      : {},
  }) as IExtendedFabricObject;
};

export const insertVariableAliasAttr = ({
  object,
  variableAlias,
}: {
  object: fabric.Textbox;
  variableAlias: IVariableAlias;
}) => {
  return fabric.util.object.extend(object, {
    variableAlias,
  }) as fabric.Textbox & IVariableAlias;
};

export const insertOriginalText = ({
  object,
  originalText,
  originalStyles,
}: {
  object: fabric.Textbox;
  originalText: string;
  originalStyles: any;
}) => {
  return fabric.util.object.extend(object, {
    originalText,
    originalStyles,
  }) as fabric.Textbox & { originalText: string; originalStyles: any };
};

export const createGetCanvasData = () => {
  const canvasDataCache: { [key: string]: ICanvasData } = {};

  return async (url: string) => {
    if (canvasDataCache[url]) {
      return canvasDataCache[url];
    }
    const canvasData: ICanvasData = await fetch(
      `${url}?date=${new Date().getTime()}`,
    ).then(res => res.json()); //
    canvasDataCache[url] = canvasData;
    return canvasData;
  };
};

export const createGetBrandData = () => {
  const brandDataCache: { [key: string]: IBrand[] } = {};

  return async (name: string) => {
    if (brandDataCache[name]) {
      return brandDataCache[name];
    }

    const brands: IBrand[] = [];
    for (const brand of name.split(",")) {
      const { oem: brandResult } = await API.privServices.oemManagement.getOem<{
        oem: IBrand;
      }>(brand);

      if (!brandResult) {
        continue;
      }

      brands.push(brandResult);
    }

    brandDataCache[name] = brands;

    return brands;
  };
};

export const createGetDealerData = () => {
  const dealerDataCache: { [key: string]: IAccount } = {};

  return async (name: string) => {
    if (dealerDataCache[name]) {
      return dealerDataCache[name];
    }

    const { result, error } = await API.privServices.dealerManagement.getDealer(
      name,
    );

    if (error) {
      throw error;
    }
    if (result) {
      const { dealer } = result;
      dealerDataCache[name] = dealer;
      return dealer;
    } else {
      throw new Error("Result is falsey");
    }
  };
};

export const createGetStampData = () => {
  const stampDataCache: { [key: string]: Record<OfferType, IStamp> } = {};

  return async (id: string, offerType: OfferType) => {
    if (stampDataCache[id] && stampDataCache[id][offerType]) {
      return stampDataCache[id][offerType];
    }

    const { result, error } =
      await API.privServices.assetBuilder.fetchStampData(id, offerType);
    if (error !== undefined) {
      throw new Error(error.message);
    }

    if (!result) {
      throw new Error(`No data for stamp and offer type ${offerType}`);
    }

    stampDataCache[id] = stampDataCache[id] || {};
    return (stampDataCache[id][offerType] = result.stamp);
  };
};

export const createGetDisclosureData = () => {
  const discslosureDataCache: { [key: string]: IStateDisclosureRecord[] } = {};

  return async (state: string) => {
    if (discslosureDataCache[state]) {
      return discslosureDataCache[state];
    }

    const { result, error } =
      await API.privServices.legalLingo.getStateDisclosuresByState<{
        result: { stateDisclosures: IStateDisclosureRecord[] };
        error: Error;
      }>(state);

    if (error) {
      throw error;
    }
    if (result) {
      const { stateDisclosures } = result;
      discslosureDataCache[state] = stateDisclosures;
      return stateDisclosures;
    } else {
      throw new Error("Result is falsey");
    }
  };
};

export const createGetExceptionData = () => {
  const exceptionDataCache: {
    [key: string]: Record<string, IStateExceptionRecord[]>;
  } = {};

  return async (state: string, oem: string) => {
    if (exceptionDataCache[state] && exceptionDataCache[state][oem]) {
      return exceptionDataCache[state][oem];
    }

    const { result, error } =
      await API.privServices.legalLingo.getStateExceptionsByStateAndOem<{
        result: any;
        error: any;
      }>(state, oem);

    if (error) {
      throw error;
    }

    if (result) {
      const { stateExceptions } = result;
      exceptionDataCache[state] = exceptionDataCache[state] || {};
      return (exceptionDataCache[state][oem] = stateExceptions);
    } else {
      throw new Error("Result is falsey");
    }
  };
};

export const createGetOfferData = () => {
  const offerDataCache: { [key: string]: IOffer[] } = {};

  return async (parameters?: IQueryParameters) => {
    const lowerCaseKeys = parameters
      ? Object.keys(parameters).map(param => param.toLowerCase())
      : [];
    const name = lowerCaseKeys.join("_"); // introducing undef paramters means name can be ""

    const { result, error } =
      await API.privServices.assetBuilder.fetchOfferList<{
        result: { offerList: IProcessedOfferData[] };
        error: any;
      }>(parameters || {});

    if (error) {
      throw error;
    }
    if (result) {
      const { offerList } = result;
      const formattedOfferList = offerList.map((offer: IProcessedOfferData) =>
        offerHelpers.formatFieldValues(offer),
      );
      if (name) {
        offerDataCache[name] = formattedOfferList as unknown as IOffer[];
      }
      return formattedOfferList as unknown as IOffer[];
    } else {
      throw new Error("Result is falsey");
    }
  };
};

export const replaceTextTokens = ({
  text,
  offerData,
  variableAlias,
  replacePunctuation,
  skipValueIfZero,
}: {
  text: string;
  offerData: OfferData;
  variableAlias?: IVariableAlias;
  replacePunctuation?: boolean;
  canvasType?: CanvasType;
  skipValueIfZero?: boolean;
}) =>
  text.replace(/\{(\w+)\}/g, (substring: string, match: string) => {
    const key = match[0].toLowerCase() + match.slice(1);
    if (key === "aprAmntFinanced") {
      return "$1000";
    }
    const alias = variableAlias?.[`{${match}}`];

    const typedKey = key as keyof OfferData;

    /**
     * Fix for AV2-3652:
     * dealerDiscount defaults to "0"
     * When dealerDiscount === "0", do not populate the variable,
     * with this value, so it can be removeed
     */
    const willSkipVariable =
      skipValueIfZero &&
      typedKey === "dealerDiscount" &&
      offerData[typedKey] === "0";

    if (
      key in offerData &&
      offerData[typedKey] &&
      !fieldsToNotPopulateUsingOfferData.includes(key) &&
      !willSkipVariable
    ) {
      const value = offerData[typedKey].toString();

      if (commaFormattedFields.includes(typedKey as CommaField)) {
        return offerHelpers.applyCommaFormat(value, key !== "aprPayment");
      }

      if (roundableProps.includes(typedKey)) {
        const valueToUse = value.replace(",", "");
        const numberValue = Number.parseFloat(valueToUse);
        const roundedValue = Math.round(numberValue);
        const moneyValue = helpers.returnMoneyString(roundedValue);
        return moneyValue.toString();
      }

      if (
        replacePunctuation &&
        (key.match(textHelpers.rebateDisclosureRegex) ||
          value.match(textHelpers.sentenceRegex))
      ) {
        /*
           AV2-1683: fields can have punctuation,
           which conflicts with the textHelers.removeSentencesWithUnfilledVars()
           so, the punctuation needs to be replaced temporarily (I used the pattern `^${ASCII_CODE}^`)
           Right now, only "." is supported
         */

        const replacedPunctuation = value.replace(
          /\./g,
          textHelpers.returnSpecialAsciiPattern("."),
        );

        return replacedPunctuation;
      }

      if (
        key.match(textHelpers.rebateFieldRegex) &&
        key.toLowerCase().includes("name")
      ) {
        return value.replace(/\!|\?|/g, "");
      }

      // AV2-1387, issue was rendering epoch without formatting it on the canvas.
      const shouldFormatDate =
        offerHelpers.dateVariableNames.includes(typedKey) &&
        !Number.isNaN(parseInt(value)); // AV2-1739: if any date value is in epoch time, then format it

      if (shouldFormatDate) {
        const parsedEpoch = +value;
        const date = new Date(parsedEpoch + helpers.timezoneOffset);

        const formattedDateString = moment(date).format(dateFormat);

        return formattedDateString;
      }

      const formattedValue =
        (key.toLowerCase().includes("rate") || key === "percentOffMSRP") &&
        value.includes("%")
          ? value.replace("%", "")
          : alias?.isCapsOn
          ? value.toUpperCase()
          : value;

      return formattedValue;
    }

    return substring;
  });

export const returnDisclosureNamesToUse = (
  selectedOfferTypes: OfferType[],
  useNumberAtPrice: boolean,
) => {
  let offerTypes: string[] = [];

  const onlyFinanceSelected =
    selectedOfferTypes.length === 1 &&
    selectedOfferTypes[0] === OfferType.Finance;

  if (onlyFinanceSelected) {
    offerTypes = ["Vehicle Info", "Finance", "Purchase"];
  } else {
    offerTypes = ["Vehicle Info", ...selectedOfferTypes];
  }

  if (useNumberAtPrice) {
    offerTypes.splice(1, 0, "Number at this Price");
  }

  const purchaseTypes = selectedOfferTypes.filter(offerType =>
    offerType.includes("Purchase"),
  );

  if (purchaseTypes.length > 0 && !onlyFinanceSelected) {
    offerTypes = offerTypes.filter(
      offerType => !offerType.includes("Purchase"),
    );
    offerTypes.push("Purchase");
  } else if (
    purchaseTypes.length === 0 &&
    !onlyFinanceSelected &&
    offerTypes.includes(OfferType.Finance)
  ) {
    const indexOfFinance = offerTypes.findIndex(
      offerType => offerType === OfferType.Finance,
    );
    offerTypes.splice(indexOfFinance + 1, 0, "Purchase");
  }

  return offerTypes.concat(["Extra Cost", "Expiration Date"]);
};

export const renderCanvasObject = async (
  obj: CanvasObject,
  {
    offerData,
    getStampData,
    getOemData,
    getDealerData,
    getDisclosureData,
    getExceptionData,
    getOfferData,
    offerTypes,
  }: IRenderContext,
  canvasType?: CanvasType,
): Promise<CanvasObject> => {
  let toAdd: CanvasObject = {
    ...obj,
  };
  try {
    let latestOfferData = { ...offerData } as OfferData;

    try {
      const fetchLatestOfferRes = await getOfferData({ vin: offerData.vin });
      const offerWithAggRebateFields = offerHelpers.aggregateRebateFieldValues(
        fetchLatestOfferRes[0],
      );

      latestOfferData = {
        ...offerData,
        ...offerWithAggRebateFields.row,
      } as OfferData;
    } catch (error) {
      latestOfferData = offerData;
    }

    offerData = latestOfferData;

    const textHasStoreVarsRegex = textHelpers.dealerVariablesRegex();
    const vinsAtThisPriceRegex = /\{vinsAtThisPrice\}/g;

    const params = {
      dealerCode: offerData.dealerCode,
      trim: offerData.trim || "",
      modelCode: offerData.modelCode || "",
      msrp: offerData.msrp?.replace(",", "") || "",
    };

    if (obj.customType && obj.customType === "disclosure") {
      let state: string;
      let brand: string;
      let disclosureText = "";
      let dealer: IAccount;
      let offerList: IOffer[] = [];
      let numberAtPriceDisclosure: IStateDisclosureElement | undefined;
      let currentException: IStateExceptionElement | undefined;
      let textHasNumberAtPriceValues = false;
      const { trim, modelCode, msrp, dealerCode } = offerData;
      const fieldsExist = trim && msrp && modelCode && dealerCode;

      try {
        dealer = await getDealerData();
        // possible TO DO: recover state and oem if they DNE
        state = dealer.state || "";
        brand = offerData.make || "";
      } catch (error) {
        throw new Error("Pulling disclosures failed: a store is required");
      }

      if (!state) {
        throw new Error(`No state was found in the Store`);
      }

      /*
      // AV2-3653: Lithia/LADTech requested that this be disabled, but will be used later
      // Original Ticket: https://theconstellationagency.atlassian.net/browse/AV2-3472
      if (state === "TX") {
        const accessoryPrice = parseFloat(latestOfferData.accessoryPrice);
        const accessoryPriceNotZero =
          !isNaN(accessoryPrice) && accessoryPrice !== 0;
        latestOfferData = {
          ...latestOfferData,
          dealerDiscount: accessoryPriceNotZero
            ? "0"
            : latestOfferData.dealerDiscount,
        };
      }
      */

      const stateDisclosures = getDisclosureData
        ? await getDisclosureData(state || "")
        : [];

      if (stateDisclosures.length < 1) {
        throw new Error(`No disclosures could be pulled`);
      }

      const disclosuresObj = stateDisclosures.find(
        object => object.state.toLowerCase() === state.toLowerCase(),
      );
      if (!disclosuresObj) {
        throw new Error(`Could not find disclosures for ${state}`);
      }
      const { disclosures } = disclosuresObj;

      const stateAndBrandExceptions = getExceptionData
        ? await getExceptionData(state || "", brand || "")
        : [];

      /*
         the data returned in the result is an array
         but there is always one element due to the state and brand params
       */
      const exceptions =
        stateAndBrandExceptions.length > 0
          ? stateAndBrandExceptions[0].exceptions
          : [];

      // check here for number at price
      if (fieldsExist) {
        numberAtPriceDisclosure =
          disclosures.find(
            discObj => discObj.offerType === "Number at this Price",
          ) ||
          exceptions.find(
            discObj => discObj.offerType === "Number at this Price",
          );
        if (numberAtPriceDisclosure) {
          offerList = await getOfferData(params);
        }
      }

      const useNumberAtPrice = numberAtPriceDisclosure && offerList.length > 0;

      const disclosuresNames = returnDisclosureNamesToUse(
        offerTypes || [],
        useNumberAtPrice || false,
      );

      disclosuresNames.forEach((disclosureName, index) => {
        let currentDisclosure = disclosures.find(
          disclosure => disclosure.offerType === disclosureName,
        );
        if (exceptions.length > 0) {
          currentException = exceptions.find(
            exception => exception.offerType === disclosureName,
          );

          const replaceDisclosure =
            currentException &&
            currentException.text &&
            currentException.text.trim() !== "";

          currentDisclosure = replaceDisclosure
            ? currentException
            : currentDisclosure;
        }

        let { text = "" } = currentDisclosure || {};

        /*
           AV2-1548: Lithia requested the separate use of
           Number at this Price disclosures variables  from the disclosure itself
           They can be used in the Vehicle Info disclosure. If used,
           the entire Number of this Price Disclosure is skipped
         */
        if (disclosureName === "Vehicle Info") {
          textHasNumberAtPriceValues = textHelpers
            .numberAtThisPriceVarRegex()
            .test(text);

          if (textHasNumberAtPriceValues && numberAtPriceDisclosure) {
            const { max_number_of_vins: maxNumberOfVins } = JSON.parse(
              numberAtPriceDisclosure.text,
            ) as offerHelpers.INumberAtPriceDisclosureObj;
            const replacedDiscText = offerHelpers.replaceNumberAtPriceVarText(
              text,
              offerList,
              maxNumberOfVins,
            );
            text = replacedDiscText;
          }
        } else if (
          disclosureName === "Number at this Price" &&
          useNumberAtPrice &&
          !textHasNumberAtPriceValues
        ) {
          text = offerHelpers.returnNumberAtThisPriceText(
            (currentException ||
              numberAtPriceDisclosure) as IStateDisclosureElement,
            offerList,
          );
          text = replaceTextTokens({ text, offerData });
        }

        const skipNumberAtThisPrice =
          disclosureName === "Number at this Price" &&
          textHasNumberAtPriceValues;

        /*
           AV2-1548: if no Number at this Price variables
           were used independently use disclosure like before
         */
        disclosureText += skipNumberAtThisPrice
          ? ""
          : text
          ? `${text}`
          : `{${disclosureName}}`;

        if (index < disclosuresNames.length - 1) {
          disclosureText += " ";
        }
      });

      const disclosureIdentiers = disclosuresNames.map(disclosureName =>
        disclosureName.toUpperCase(),
      );

      let filledText = replaceTextTokens({
        text: disclosureText,
        offerData,
        replacePunctuation: true,
        skipValueIfZero: true,
      });

      if (textHasStoreVarsRegex.test(filledText)) {
        filledText = dealer
          ? textHelpers.replaceDealerTokens(filledText, dealer)
          : filledText;

        filledText = textHelpers.replaceFinalPriceVariable(filledText, dealer);
      }

      const textWithExpDate = textHelpers.replaceDisclosureTypeVariable(
        "Expiration Date",
        filledText,
        latestOfferData.expirationDate
          ? helpers.formatDateValue(latestOfferData.expirationDate)
          : "",
      );

      const cleanedText = textHelpers.removeSentencesWithUnfilledVars(
        disclosureText,
        textWithExpDate,
        disclosureIdentiers,
      );

      toAdd = {
        ...toAdd,
        styles: {}, // AV2-1489: single char styles should not be applied to disclosure text
        text: cleanedText,
      } as ITextObject;
    } else if (isText(obj)) {
      /*** NOTE: below type conversion to any is necessary because in order to extract originalText, obj need to converted to fabric.Textbox & { originalText: string }
       * But the compiler complains because obj is missing attributes from fabric.Textbox.
       */
      const textboxProps: Partial<Record<textHelpers.StylePropKeys, any>> = {};
      for (const prop of textHelpers.styleProps) {
        if (!obj[prop as keyof ITextObject]) {
          continue;
        }
        textboxProps[prop] = obj[prop as keyof ITextObject];
      }

      const { styles, text, originalText, variableAlias } = obj as any;

      let templateText: string = text;

      const masksOn =
        Object.keys(variableAlias || {}).filter(
          aliasVar => variableAlias[aliasVar]?.isMaskOn,
        ).length > 0;

      // check if masks are turned on here
      if (masksOn && originalText) {
        templateText = originalText as string;
      }

      let finalText = templateText;
      let finalStyles = styles;

      const matches = templateText
        .match(/\{([a-zA-Z0-9]+)\}/g)
        ?.map(match => match.replace(/\{|\}/g, ""));

      const offerDataVariables = (matches?.filter(
        variable =>
          templateText.includes(`{${variable}}`) && variable in offerData,
      ) ||
        Object.keys(offerData).filter(key =>
          templateText.includes(key),
        )) as Array<keyof OfferData>;

      let dealer: IAccount | undefined = await getDealerData();

      for (const variable of offerDataVariables) {
        const searchPattern = `{${variable}}`;
        const replacePattern = textHelpers.returnOfferDataValueForVarPopulation(
          {
            dealer: dealer,
            variable,
            offerData,
            variableAlias,
            willRoundValue:
              canvasType === "stamp" ||
              isDisclosure(obj as unknown as ICanvasObject),
            overridePunctuationReplace: true,
          },
        );

        if (searchPattern === replacePattern) {
          continue;
        }

        const { text: newText, styles: newStyles } =
          textHelpers.returnTextAndStylesForVariablePopulation({
            text: finalText,
            styles: finalStyles,
            textboxProps,
            searchPattern,
            replacePattern,
          });

        finalText = newText;
        finalStyles = newStyles;
      }

      if (textHasStoreVarsRegex.test(finalText)) {
        try {
          const dealerDataVariables = matches?.filter(
            variable =>
              templateText.includes(`{${variable}}`) &&
              storeVariableNames.includes(variable),
          );

          if (!dealerDataVariables || !dealer) {
            throw new Error("Could not replace store-based variables.");
          }

          for (const variable of dealerDataVariables) {
            const searchPattern = `{${variable}}`;
            const replacePattern =
              textHelpers.returnDealerDataValueForVarPopulation(
                variable,
                dealer,
                variableAlias,
              );

            if (searchPattern === replacePattern) {
              continue;
            }

            const { text: newText, styles: newStyles } =
              textHelpers.returnTextAndStylesForVariablePopulation({
                text: finalText,
                styles: finalStyles,
                textboxProps,
                searchPattern,
                replacePattern,
              });

            finalText = newText;
            finalStyles = newStyles;
          }

          if (textHelpers.finalPricePriceNameRegex.test(finalText)) {
            const finalPriceNameSearchPattern = "{finalPriceName}";
            const finalPriceVarReplacePattern =
              offerData.finalPriceName ||
              textHelpers.returnFinalPriceValueForVarPopulation(
                dealer,
                variableAlias,
              );

            const { text: newText, styles: newStyles } =
              textHelpers.returnTextAndStylesForVariablePopulation({
                text: finalText,
                styles: finalStyles,
                textboxProps,
                searchPattern: finalPriceNameSearchPattern,
                replacePattern: finalPriceVarReplacePattern,
              });

            finalText = newText;
            finalStyles = newStyles;
          }
        } catch (error) {
          /*
             Just continue if an error occurs.
             Unfilled variables will be handled at the end
           */
        }
      }

      let offerList: IOffer[] = [];

      if (vinsAtThisPriceRegex.test(finalText)) {
        try {
          offerList = await getOfferData(params);

          const vinsAThisPriceSearchPattern = "{vinsAtThisPrice}";
          const vinsAThisPriceReplacePattern = `${offerList.length || 1}`;

          const { text: newText, styles: newStyles } =
            textHelpers.returnTextAndStylesForVariablePopulation({
              text: finalText,
              styles: finalStyles,
              textboxProps,
              searchPattern: vinsAThisPriceSearchPattern,
              replacePattern: vinsAThisPriceReplacePattern,
            });

          finalText = newText;
          finalStyles = newStyles;
        } catch (error) {
          /*
             Just continue if an error occurs.
             Unfilled variables will be handled at the end
           */
        }
      }

      // handle numberAtThisPrice variable
      if (finalText.match("{numberAtThisPrice}")) {
        if (!dealer) {
          dealer = await getDealerData();
        }

        const stateDisclosures = getDisclosureData
          ? await getDisclosureData(dealer.state || "")
          : [];

        if (stateDisclosures.length < 1) {
          throw new Error(`No disclosures could be pulled`);
        }

        const disclosuresObj = stateDisclosures.find(
          object => object.state.toLowerCase() === dealer?.state.toLowerCase(),
        );

        if (!disclosuresObj) {
          throw new Error(`Could not find disclosures for ${dealer.state}`);
        }
        const { disclosures } = disclosuresObj;

        const numberAtThisPriceDisc = disclosures?.find(
          discObj => discObj.offerType === "Number at this Price",
        );

        let legalObjToUse = numberAtThisPriceDisc;

        const stateAndBrandExceptions = getExceptionData
          ? await getExceptionData(dealer?.state || "", offerData.make || "")
          : [];

        const exceptions =
          stateAndBrandExceptions.length > 0
            ? stateAndBrandExceptions[0].exceptions
            : [];

        const numberAtThisPriceExcp = exceptions?.find(
          excpObj => excpObj.offerType === "Number at this Price",
        );

        if (numberAtThisPriceExcp) {
          legalObjToUse = numberAtThisPriceExcp;
        }

        if (!legalObjToUse) {
          throw new Error(
            "No disclosure data was found for populating numberAtThisPrice",
          );
        }

        if (offerList.length < 1) {
          offerList = await getOfferData(params);
        }

        let replacementText = offerHelpers.returnNumberAtThisPriceText(
          legalObjToUse as IStateDisclosureElement,
          offerList,
        );

        replacementText = replaceTextTokens({
          text: replacementText,
          offerData,
        });

        const numberAtThisPriceSearchPattern = "{numberAtThisPrice}";
        const numberAtThisPriceReplacePattern = replacementText;

        const { text: newText, styles: newStyles } =
          textHelpers.returnTextAndStylesForVariablePopulation({
            text: finalText,
            styles: finalStyles,
            textboxProps,
            searchPattern: numberAtThisPriceSearchPattern,
            replacePattern: numberAtThisPriceReplacePattern,
          });

        finalText = newText;
        finalStyles = newStyles;
      }

      if (textHelpers.generalVariableRegex.test(finalText)) {
        const { text: newText, styles: newStyles } =
          textHelpers.returnTextAndStylesAfterRemovingUnfilledVars({
            text: finalText,
            styles: finalStyles,
            textboxProps,
          });

        finalText = newText;
        finalStyles = newStyles;
      }

      toAdd = {
        ...toAdd,
        text: finalText,
        styles: finalStyles,
      } as ITextObject;
    } else if (isStamp(obj)) {
      const stamp = await getStampData(
        obj.customData.stampId || getBackupStampId(obj),
      );

      // When we render the stamp, we have to consider the stamp exception.
      // So if stamp.expception contains stamp exception for the current dealer, we need to render that stamp.
      const dealer = await getDealerData();

      let stampJsonUrl = stamp?.stampJsonUrl;
      const exception = stamp?.exceptions?.find(
        exp => exp.type === "state" && exp.value === dealer.state,
      );

      if (exception) {
        stampJsonUrl = exception.stampJsonUrl;
      }

      if (!stamp || (!(stamp as any).stampJson && !stampJsonUrl)) {
        throw new Error(
          `Stamp json undefined for stamp: ${obj.customData.stampId}`,
        );
      }

      type OldStamp = IStamp & { stampJson: string };
      let json = null;
      if ((stamp as OldStamp).stampJson && !stampJsonUrl) {
        json = JSON.parse((stamp as OldStamp).stampJson);
      } else {
        const response = await fetch(
          `${stampJsonUrl}?timestamp=${Date.now()}`,
          {
            method: "GET",
            cache: "no-store",
          },
        );
        json = await response.json();
      }

      const { top, left } = toAdd;

      const stampGroup = await getStampFromJson(JSON.stringify(json), {
        getOemData,
        getStampData,
        getDealerData,
        getOfferData,
        offerData,
      });

      if (!stampGroup) {
        throw new Error("stamp group must be defined.");
      }

      const { width, height } = stampGroup;

      const stampObjects = await getObjects(stampGroup);

      const canvas = document.createElement("canvas");
      canvas.width = width || 0;
      canvas.height = height || 0;

      const fabricCanvas = new fabric.Canvas(canvas);

      stampObjects.forEach(tmpObj => {
        fabricCanvas.add(tmpObj);
      });

      fabricCanvas.forEachObject(element => {
        if (element.type !== "textbox") {
          return;
        }

        const canvasTextbox = element as fabric.Textbox;

        const { text: newText, styles: newStyles } =
          textHelpers.processTextAndStylesWitOfferData({
            obj: canvasTextbox as unknown as ITextObject,
            offerData,
            canvasType: "stamp",
          });

        canvasTextbox.set({
          text: newText,
          styles: newStyles,
        });
      });

      const image = await new Promise<fabric.Image>(resolve => {
        fabric.Image.fromURL(
          fabricCanvas.toDataURL(),
          img => {
            img.set({
              width: width || 0,
              height: height || 0,
            });

            resolve(img);
          },
          {
            crossOrigin: "anonymous",
          },
        );
      });

      toAdd = {
        ...toAdd,
        ...(image.toJSON() as IGroupObject),
        top,
        left,
      };
    } else if (isImage(obj) && obj.src.indexOf(carCutImagePath) !== -1) {
      /* if there is no carcut image, use default */
      const carcutImageUrl = offerData.imageUrl
        ? offerData.imageUrl + `?time=${Date.now()}`
        : carCutImagePath;
      const { top, left, width, height, scaleX, scaleY } = toAdd;
      const placeholder: IPlaceholder = {
        top,
        left,
        dimension: {
          width: width * scaleX,
          height: height * scaleY,
        },
      };

      const carcutImage = await new Promise<fabric.Image>(resolve => {
        fabric.Image.fromURL(
          carcutImageUrl,
          img => {
            return resolve(img);
          },
          {
            crossOrigin: "anonymous",
            top: toAdd.top,
            left: toAdd.left,
          },
        );
      });

      const repositionedCarcutImage = returnRePositionedImage(
        carcutImage,
        placeholder,
      );

      toAdd = {
        ...toAdd,
        ...repositionedCarcutImage.toJSON(),
      } as IImageObject;
    } else if (isLogo(obj)) {
      const {
        customData: { logoEventType },
      } = obj;

      const { top, left, width, height, scaleX, scaleY } = toAdd;
      const placeholder: IPlaceholder = {
        top,
        left,
        dimension: {
          width: width * scaleX,
          height: height * scaleY,
        },
      };
      if (logoEventType === "OEM_LOGO" || logoEventType === "BRAND_LOGO") {
        const brand = await getOemData(offerData.make);

        const logoUrls = brand?.[0].logo_urls_from_S3
          ? JSON.parse(brand[0].logo_urls_from_S3)
          : {
              horizontalEventImagesFromS3: [""],
              horizontalImagesFromS3: [""],
              squareEventImagesFromS3: [""],
              squareImagesFromS3: [""],
              verticalEventImagesFromS3: [""],
              verticalImagesFromS3: [""],
            };

        const image = await new Promise<fabric.Image>((resolve, reject) =>
          fabric.Image.fromURL(
            logoUrls[`${obj.customData.logoDropZoneType}ImagesFromS3`][0] +
              `?time=${Date.now()}`,
            img => {
              return img
                ? resolve(img)
                : reject(new Error(`Logo image is: ${JSON.stringify(img)}`));
            },

            {
              crossOrigin: "anonymous",
              top: toAdd.top,
              left: toAdd.left,
            },
          ),
        );

        const repositionedImage = returnRePositionedImage(image, placeholder);

        toAdd = {
          ...toAdd,
          ...repositionedImage.toJSON(),
        };
      } else if (
        logoEventType === "STORE_LOGO" ||
        logoEventType === "ACCOUNT_LOGO"
      ) {
        let account: IAccount;

        try {
          account = await getDealerData();
        } catch (error) {
          throw new Error("There is no account logo to retrieve.");
        }

        const logoUrls = account.logo_urls_from_S3
          ? JSON.parse(account.logo_urls_from_S3)
          : {
              horizontalEventImagesFromS3: [""],
              horizontalImagesFromS3: [""],
              squareEventImagesFromS3: [""],
              squareImagesFromS3: [""],
              verticalEventImagesFromS3: [""],
              verticalImagesFromS3: [""],
            };

        const image = await new Promise<fabric.Image>((resolve, reject) =>
          fabric.Image.fromURL(
            logoUrls[`${obj.customData.logoDropZoneType}ImagesFromS3`][0] +
              `?time=${Date.now()}`,
            img =>
              img
                ? resolve(img)
                : reject(new Error(`Logo image is: ${JSON.stringify(img)}`)),
            {
              crossOrigin: "anonymous",
              top: toAdd.top,
              left: toAdd.left,
            },
          ),
        );

        toAdd = {
          ...toAdd,
          ...returnRePositionedImage(image, placeholder).toJSON(),
        };
      } else if (logoEventType === "SALES_EVENT_LOGO") {
        const brand = await getOemData(offerData.make);

        const logoUrls = brand?.[0].logo_urls_from_S3
          ? JSON.parse(brand?.[0].logo_urls_from_S3)
          : {
              horizontalEventImagesFromS3: [""],
              horizontalImagesFromS3: [""],
              squareEventImagesFromS3: [""],
              squareImagesFromS3: [""],
              verticalEventImagesFromS3: [""],
              verticalImagesFromS3: [""],
            };

        const image = await new Promise<fabric.Image>((resolve, reject) =>
          fabric.Image.fromURL(
            logoUrls[`${obj.customData.logoDropZoneType}EventImagesFromS3`][0] +
              `?time=${Date.now()}`,
            img =>
              img
                ? resolve(img)
                : reject(new Error(`Logo image is: ${JSON.stringify(img)}`)),
            {
              crossOrigin: "anonymous",
              top: toAdd.top,
              left: toAdd.left,
            },
          ),
        );

        toAdd = {
          ...toAdd,
          ...returnRePositionedImage(image, placeholder).toJSON(),
        };
      }
    }
  } catch (error) {
    // added for the debugging purpose
    /* failed image load replaces placeholder with empty image" */
    const failImage = await new Promise<fabric.Image>((resolve, reject) =>
      fabric.Image.fromURL(
        `https://via.placeholder.com/${obj.width}x${obj.height}`,
        img => {
          if (!img) {
            return reject(new Error("Could not generate image."));
          }

          img.set({
            top: obj.top,
            left: obj.left,
          });

          if (isLogo(obj)) {
            img.set("opacity", 0);
          }

          img.scaleToWidth(obj.width);
          return resolve(img);
        },
      ),
    );

    return { ...toAdd, ...failImage.toJSON() };
  }

  return {
    ...toAdd,
    selectable: false,
  };
};

const getBackupStampId = (obj: IStampObject) => {
  const { customData, src } = obj as any as {
    customData: { stampId: string };
    src: string;
  };
  const { stampId } = customData;
  if (stampId) {
    return stampId;
  }

  const srcSplit = src.split("/");
  if (srcSplit.length !== 6) {
    return "";
  }

  const thumbnail = srcSplit[5];
  const thumbnailSplit = thumbnail.split(".");

  if (thumbnailSplit.length !== 2) {
    return "";
  }

  const idFromThumbnail = thumbnailSplit[0] || "";

  return idFromThumbnail;
};

export const returnRePositionedImage = (
  image: fabric.Image,
  placeholder: IPlaceholder,
  boundingRects?: {
    wrapperRect: ClientRect;
    canvasRect: ClientRect;
  },
  fitTo?: keyof IDimension,
  useRawImage?: boolean,
) => {
  const scaledImage = useRawImage
    ? image
    : returnScaledImage(image, placeholder, fitTo);

  // To find the center points of placeholder and scaled image
  const scaledImgHalfWidth = getWidth(scaledImage) / 2;
  const scaledImgHalfHeight = getHeight(scaledImage) / 2;
  const scaledImgCenterPoint = new fabric.Point(
    placeholder.left + scaledImgHalfWidth,
    placeholder.top + scaledImgHalfHeight,
  );

  const placeholderHalfWidth = placeholder.dimension.width / 2;
  const placeholderHalfHeight = placeholder.dimension.height / 2;
  const placeholderCenterPoint = new fabric.Point(
    placeholder.left + placeholderHalfWidth,
    placeholder.top + placeholderHalfHeight,
  );

  const { xDiff, yDiff } = returnDiffsFromPlaceholderCenterPoint(
    scaledImgCenterPoint,
    placeholderCenterPoint,
  );

  const paddingTop =
    (boundingRects?.canvasRect.top || 0) -
    (boundingRects?.wrapperRect.top || 0);
  const paddingLeft =
    (boundingRects?.canvasRect.left || 0) -
    (boundingRects?.wrapperRect.left || 0);

  // NOTE: we have to subtract the paddings because those paddings are already added in placeholder's position
  scaledImage.set({
    top: placeholder.top + yDiff - paddingTop,
    left: placeholder.left + xDiff - paddingLeft,
  });

  return scaledImage;
};
/**
 * This function will calculate fraction of logo width over placeholder's width and the logo height and the placeholder's height.
 * Then, it will scale to whichever that has higher fraction. This process will make the other dimenstion smaller in repect of its aspect ratios.
 *
 * @param img fabric.Image with loaded image
 * @param placeholder toAdd
 */
const returnScaledImage = (
  img: fabric.Image,
  placeholder: IPlaceholder,
  fitTo?: keyof IDimension,
): fabric.Image => {
  const { dimension } = placeholder;
  if (fitTo) {
    fitTo === "width"
      ? img.scaleToWidth(dimension.width)
      : img.scaleToHeight(dimension.height);
  } else {
    const fracWidth = getWidth(img) / dimension.width;
    const fracHeight = getHeight(img) / dimension.height;

    if (fracWidth > fracHeight) {
      img.scaleToWidth(dimension.width);
    } else {
      img.scaleToHeight(dimension.height);
    }
  }

  return img;
};

export interface IPlaceholder {
  top: number;
  left: number;
  dimension: {
    width: number;
    height: number;
  };
}

const returnDiffsFromPlaceholderCenterPoint = (
  imgCenterPoint: fabric.Point,
  placeholderCenterPoint: fabric.Point,
): { xDiff: number; yDiff: number } => {
  const xDiff = placeholderCenterPoint.x - imgCenterPoint.x;
  const yDiff = placeholderCenterPoint.y - imgCenterPoint.y;

  return { xDiff, yDiff };
};

const getStampFromJson = async (
  stampJson: string,
  renderContext: IRenderContext,
): Promise<fabric.Group> => {
  const data = JSON.parse(stampJson) as IGroupObject;
  data.objects = await Promise.all(
    (data.objects as CanvasObject[]).map(obj =>
      renderCanvasObject(obj, renderContext, "stamp"),
    ),
  );

  return await new Promise<fabric.Group>(resolve => {
    fabric.Group.fromObject(data, async group => {
      // re-create textboxes within group
      resolve(group);
    });
  });
};

export const getObjects = async (
  group: fabric.Group,
): Promise<fabric.Object[]> => {
  return await new Promise<fabric.Object[]>(resolve => {
    const objects = (group.getObjects() as fabric.Textbox[]).map(tmpObj => {
      return initiateTextbox({
        text: tmpObj.text || "",
        properties: {
          left: tmpObj.left,
          top: tmpObj.top,
          width: tmpObj.width,
          height: tmpObj.height,
          fill: tmpObj.fill,
          fontFamily: tmpObj.fontFamily,
          fontSize: tmpObj.fontSize,
          fontStyle: tmpObj.fontStyle,
          fontWeight: tmpObj.fontWeight,
          textAlign: tmpObj.textAlign,
          lineHeight: tmpObj.lineHeight || 1.0,
          superscript: tmpObj.superscript,
          subscript: tmpObj.subscript,
          charSpacing: tmpObj.charSpacing,
          styles: tmpObj.styles,
        },
      });
    });

    resolve(objects);
  });
};

export const renderCanvasData = async (
  canvasData: ICanvasData,
  context: {
    offerData: OfferData;
    getStampData: (id: string, index: number) => Promise<IStamp | null>;
    getOemData: (name: string) => Promise<IBrand[]>;
    getDealerData: () => Promise<IAccount>;
    getDisclosureData?: (state: string) => Promise<IStateDisclosureRecord[]>;
    getExceptionData?: (
      state: string,
      oem: string,
    ) => Promise<IStateExceptionRecord[]>;
    getOfferData: (parameters?: IQueryParameters) => Promise<IOffer[]>;
    dealerName: string;
    offerTypes?: OfferType[];
  },
): Promise<ICanvasData> => {
  await textHelpers.loadFontsFromJson(canvasData);

  let stampIndex = 0;
  const promises: Array<Promise<ICanvasObject>> = [];

  for (const object of canvasData.objects) {
    promises.push(
      renderCanvasObject(object, {
        ...context,
        // eslint-disable-next-line no-loop-func
        getStampData: (id: string) => context.getStampData(id, stampIndex++),
      }),
    );
  }

  const objects = await Promise.all(promises);

  return {
    ...canvasData,
    objects,
  };
};

export const createRenderTemplate = (
  getCanvasData: ReturnType<typeof createGetCanvasData>,
  getStampData: ReturnType<typeof createGetStampData>,
  getOemData: ReturnType<typeof createGetBrandData>,
  getDealerData: ReturnType<typeof createGetDealerData>,
  getDisclosureData: ReturnType<typeof createGetDisclosureData>,
  getExceptionData: ReturnType<typeof createGetExceptionData>,
  getOfferData: ReturnType<typeof createGetOfferData>,
) => {
  return async (
    template: ITemplate,
    offerData: OfferData,
    offerTypes: OfferType[],
    dealerName: string,
    prefetchedCanvasData?: ICanvasData,
    offerToUse?: OfferData,
  ) => {
    if (!template.canvasJsonUrl) {
      throw new Error("Template is unsaved");
    }

    const canvasData =
      prefetchedCanvasData || (await getCanvasData(template.canvasJsonUrl));

    const newOfferData = !isEmpty(offerToUse)
      ? [offerToUse]
      : await getOfferData({ vin: offerData.vin });

    // this is needed to get the latest data of edited offers
    const newGetOfferData = async () => {
      return newOfferData as IOffer[];
    };

    return await renderCanvasData(canvasData, {
      offerData,
      getStampData: async (id: string, index: number) =>
        index < offerTypes.length ? getStampData(id, offerTypes[index]) : null,
      getOemData,
      getDealerData: () => getDealerData(dealerName),
      getDisclosureData,
      getExceptionData,
      getOfferData: newGetOfferData,
      dealerName,
      offerTypes,
    });
  };
};

export const createRenderStamp = (
  getStampData: ReturnType<typeof createGetStampData>,
  getOemData: ReturnType<typeof createGetBrandData>,
  getDealerData: ReturnType<typeof createGetDealerData>,
  getOfferData: ReturnType<typeof createGetOfferData>,
) => {
  return async (
    stamp: IStamp,
    canvasJsonString: string,
    offerData: OfferData,
    offerTypes: OfferType[],
    dealerName: string,
  ) => {
    const canvasData = JSON.parse(canvasJsonString);

    return await renderCanvasData(canvasData, {
      offerData,
      getStampData: async (id: string, index: number) =>
        index < offerTypes.length ? getStampData(id, offerTypes[index]) : null,
      getOemData,
      getDealerData: () => getDealerData(dealerName || offerData.dealerName),
      getOfferData,
      dealerName,
      offerTypes,
    });
  };
};

export const createRenderPreview = (
  getStampData: ReturnType<typeof createGetStampData>,
  getOemData: ReturnType<typeof createGetBrandData>,
  getDealerData: ReturnType<typeof createGetDealerData>,
  getDisclosureData: ReturnType<typeof createGetDisclosureData>,
  getExceptionData: ReturnType<typeof createGetExceptionData>,
  getOfferData: ReturnType<typeof createGetOfferData>,
) => {
  return async (
    canvasData: ICanvasData,
    offerData: OfferData,
    offerTypes: OfferType[],
    dealerName: string,
  ) => {
    return await renderCanvasData(canvasData, {
      offerData,
      getStampData: async (id: string, index: number) =>
        index < offerTypes.length ? getStampData(id, offerTypes[index]) : null,
      getOemData,
      getDealerData: () => getDealerData(dealerName),
      getDisclosureData,
      getExceptionData,
      getOfferData,
      dealerName,
      offerTypes,
    });
  };
};

export const dataURLtoBlob = (dataurl: string) => {
  const arr = dataurl.split(",");
  const mime = arr[0]?.match(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export const downloadCanvas = (canvas: HTMLCanvasElement, filename: string) => {
  const link = document.createElement("a");
  const imgData = canvas.toDataURL("image/jpeg");
  const blob = dataURLtoBlob(imgData);
  const objurl = URL.createObjectURL(blob);

  link.download = `${filename}.jpeg`;

  link.href = objurl;

  link.click();
};

export const toDataURL = (canvas: HTMLCanvasElement) => {
  const base64 = canvas.toDataURL("image/jpeg", 1);

  return base64;
};
/*
   This function checks all individual char styles and sees
   if they are all the same. If they are, then the textbox
   object has its properties set
 */

export const recoverStyleForTextbox = (obj: fabric.Object) => {
  const textboxObj = obj as fabric.Textbox;
  const lineStyles = Object.values(textboxObj.styles);
  const charStylesPerLine = lineStyles.map((lineStyle: any) =>
    Object.values(lineStyle),
  );
  const textLength = (textboxObj.text || "").length;

  let unitedStyleCount = 0;
  charStylesPerLine.forEach((charStyleLine: any) => {
    charStyleLine.forEach((charStyle: any, index: number) => {
      const propKeys = Object.keys(charStyle);
      const prevElement = index > 0 ? charStyleLine[index - 1] : null;
      const differentProps = prevElement
        ? propKeys.filter((key: string) => charStyle[key] !== prevElement[key])
            .length > 0
        : false;
      if (!differentProps) {
        unitedStyleCount += 1;
      }
    });
  });

  if (unitedStyleCount >= textLength) {
    const firstCharStyle = charStylesPerLine.flat().flat()[0];
    return firstCharStyle;
  }
  return null;
};

export const returnStylesFromStyleTrackers = (
  styleTrackers: IStyleTracker[],
) => {
  const styles: Record<number, Record<number, any>> = {};
  let scanCount = 0;
  let currentLineNumber = 0;

  for (let i = 0; i < styleTrackers.length; i++) {
    const { char, lineNumber, style } = styleTrackers[i];

    if (char === "^" || char === "↵" || char === "\n" || char === "\r") {
      continue;
    }

    // Possible TO DO: set space (" ") styling to {}

    if (!styles[lineNumber]) {
      styles[lineNumber] = {};
    }

    if (currentLineNumber < lineNumber) {
      scanCount = 0;
      currentLineNumber = lineNumber;
    }

    styles[lineNumber][scanCount] =
      style || styleTrackers[i > 0 ? i - 1 : 0]?.style || {};

    scanCount += 1;
  }

  return styles;
};

export const returnStyleTrackers = (
  text: string,
  styles: any,
  ignoreNewLineChars?: boolean,
) => {
  const flattenedStyles = textHelpers.returnFlattenedStyles(styles);
  const styleTrackers: IStyleTracker[] = [];
  let currentLineNumber = 0;
  let scanCount = 0;

  for (let i = 0; i < text.length; i++) {
    const char = text.charAt(i);
    const currentStyle = flattenedStyles[i];
    const currentTracker = {
      char,
      style: currentStyle,
      indexInLine: scanCount,
      lineNumber: currentLineNumber,
    };

    if (textHelpers.isNewLineChar(char)) {
      scanCount = 0;
      currentLineNumber += 1;
      currentTracker.indexInLine = 0;
      currentTracker.lineNumber = currentLineNumber;
      styleTrackers.push(currentTracker);
      continue;
    }

    const isPrevTrackerNewLine = textHelpers.isNewLineChar(
      styleTrackers[i > 0 ? i - 1 : 0]?.char,
    );

    const useOffset =
      !!styleTrackers.find(
        obj =>
          textHelpers.isNewLineChar(obj.char) &&
          obj.lineNumber === currentLineNumber,
      ) && styleTrackers[i - 1]?.char !== " ";

    const offsetStyle = flattenedStyles[useOffset && i > 0 ? i - 1 : i] || {};

    const ignoreOffset =
      isPrevTrackerNewLine &&
      JSON.stringify(currentStyle) !== JSON.stringify(offsetStyle);

    if (!ignoreOffset) {
      currentTracker.style = offsetStyle;
    }

    styleTrackers.push(currentTracker);

    scanCount += 1;
  }

  if (ignoreNewLineChars) {
    const trackersWithNoNewlines = [];

    for (const tracker of styleTrackers) {
      if (textHelpers.isNewLineChar(tracker.char)) {
        continue;
      }
      trackersWithNoNewlines.push(tracker);
    }

    return trackersWithNoNewlines;
  }

  return styleTrackers;
};

interface IGetStampJsonToPublishParameters {
  width: number;
  height: number;
  objects: fabric.Textbox[];
  customAttributes: string[];
}

export const getStampJsonToPublish = ({
  width,
  height,
  objects,
  customAttributes,
}: IGetStampJsonToPublishParameters) => {
  const objs = objects.map(obj => {
    return initiateTextbox({
      text: obj.text || "",
      properties: {
        ...getDefaultTextboxProperties(obj),
        left: obj.left || 0,
        top: obj.top || 0,
        originX: "left",
        originY: "top",
      },
    });
  });

  const group = new fabric.Group(objs, {
    left: 0,
    top: 0,

    width,
    height,

    originX: "left",
    originY: "top",
    centerPoint: new fabric.Point(0, 0),
  } as any);

  return group.toJSON(customAttributes);
};

/**
 * This function do needed processes before serialization.
 *  1. For those customType === 'logo', assign its centerPoint of the placeholder.
 * @param canvas
 */
export const processCanvasBeforeSerialization = (canvas: fabric.Canvas) => {
  const logoTypeObjects = canvas
    .getObjects()
    .filter(obj => isLogo(obj as unknown as ICanvasObject));

  logoTypeObjects.forEach(obj => {
    const { customData } = obj as any;

    (obj as any).customData = {
      ...customData,
      centerPoint: (obj as fabric.Object).getCenterPoint(),
    };
  });
};

export const findTriggerPosition = (
  canvas: fabric.Canvas,
  textbox: fabric.Textbox,
  margins: {
    top: number;
    left: number;
  },
) => {
  // by default, place trigger to the right side of the textbox
  // if there is not enough space to the right side of the textbox,
  // place trigger to the above or bottom depending on the available spaces
  // between the canvas rect and the textbox.
  const TRIGGER_WIDTH = 50;
  const TRIGGER_HEIGHT = 50;

  const { top, left, width, height } = textbox.getBoundingRect();
  // try to see if right side of the textbox is available
  const possibleTriggerLeft = (left || 0) + (width || 0);
  if (possibleTriggerLeft + TRIGGER_WIDTH <= canvas.getWidth()) {
    return {
      top: margins.top + (top || 0),
      left: margins.left + possibleTriggerLeft + 15, // margin left for not to cover the right control of textbox
      width: TRIGGER_WIDTH,
      height: TRIGGER_HEIGHT,
    };
  } else {
    // place trigger above or bottom of the textbox
    // try to see if there is enough space above the textbox
    if (TRIGGER_HEIGHT <= (top || 0)) {
      return {
        top: margins.top + (top || 0) - TRIGGER_HEIGHT,
        left: margins.left + (left || 0),
        width: width || 0,
        height: TRIGGER_HEIGHT,
      };
    } else {
      return {
        top: margins.top + (top || 0) + (height || 0),
        left: margins.left + (left || 0),
        width: width || 0,
        height: TRIGGER_HEIGHT,
      };
    }
  }
};

export const extractVariables = (
  textbox: fabric.Textbox,
  complete: (variables: string[]) => void,
) => {
  const { originalText, originalStyles } = textbox as fabric.Textbox & {
    originalText: string;
    originalStyles: any;
  };

  const aliasTextbox = textbox as fabric.Textbox & {
    variableAlias: IVariableAlias;
  };

  let restoredStyles;

  const restoredText = restoreOriginalText(aliasTextbox);

  const variablesToPreserve = [];

  /*
    AV2-1513: Only the text was scanned for variables before.
    This helps preseve aliases, where the mask is turned on.
  */
  if (aliasTextbox.variableAlias) {
    restoredStyles = restoreOriginalStyle(aliasTextbox);
    for (const varKey in aliasTextbox.variableAlias) {
      variablesToPreserve.push(varKey.replace(/\{|\}/g, ""));
    }
  }

  // We need the text that we should extract the variables from.
  // This suppose to be originalText (un-altered text).
  // This is fix for the ticket https://theconstellationagency.atlassian.net/browse/AV2-2266
  const textToExtractVarsFrom = originalText || restoredText;

  // if originalText was not found in the textbox, set it to restoredText
  // this restoredText will be the textbox.text if originalText was not valid
  if (!originalText) {
    insertOriginalText({
      object: textbox,
      originalText: restoredText,
      originalStyles: !originalStyles ? restoredStyles : textbox.styles,
    });
  }

  const regex = /{.*?}/g;

  const variables = [];
  let variable;
  do {
    variable = regex.exec(textToExtractVarsFrom);

    if (variable) {
      const [matchedVariable] = variable;

      variables.push(matchedVariable);
    }
  } while (variable);

  for (const variable of variablesToPreserve) {
    const aliasVar = `{${variable}}`;
    if (variables.includes(aliasVar)) {
      continue;
    }
    variables.push(aliasVar);
  }

  return complete(variables);
};

export const restoreOriginalText = (textbox: IExtendedTextbox) => {
  const { variableAlias, text } = textbox;

  if (!variableAlias) {
    return textbox.text || "";
  }

  let currentText = text || "";
  Object.keys(variableAlias).forEach(key => {
    if (variableAlias[key].isMaskOn) {
      currentText = currentText.replace(
        variableAlias[key].isCapsOn
          ? variableAlias[key].customVariableCaps
          : variableAlias[key].customVariable,
        key,
      ); // swap
    }
  });

  return currentText;
};

export const restoreOriginalStyle = (
  textbox: fabric.Textbox & { variableAlias: IVariableAlias },
) => {
  const { variableAlias, text, styles } = textbox;

  if (!text || !variableAlias) {
    return styles;
  }

  const styleTrackers = returnStyleTrackers(text, styles);

  const objectKeys = Object.keys(variableAlias).filter(
    key => variableAlias[key] && variableAlias[key].isMaskOn,
  );

  let j = 0;
  const newStyleTrackers: IStyleTracker[] = [];
  while (j < text.length) {
    const lowerCaseText = text.toLowerCase();
    const lowerCaseVar = (key: string) =>
      variableAlias[key].customVariable.toLowerCase();
    const matchingKey = objectKeys.find(
      key => lowerCaseText.indexOf(`${lowerCaseVar(key)}`) === j,
    );

    if (matchingKey) {
      for (const char of matchingKey.toString()) {
        newStyleTrackers.push({
          ...styleTrackers[j],
          char,
        });
      }
      j += variableAlias[matchingKey].customVariable.length - 1;
    } else {
      newStyleTrackers.push(styleTrackers[j]);
      j += 1;
    }
  }

  const newStyles = returnStylesFromStyleTrackers(newStyleTrackers);

  return newStyles;
};

export const validateVariableAlias = (variableAlias: IVariableAlias) => {
  // check  if there is duplicate customVariable names
  const variableCounts: { [key: string]: number } = {};
  for (const variable of Object.keys(variableAlias)) {
    const { customVariable, isMaskOn } = variableAlias[variable];

    if (!customVariable || customVariable.trim() === "") {
      continue;
    }

    // Count only if maskOn === true
    if (!isMaskOn) continue;

    if (variableCounts[customVariable]) {
      variableCounts[customVariable] += 1;
    } else {
      variableCounts[customVariable] = 1;
    }
  }

  return Object.keys(variableCounts).every(key => variableCounts[key] <= 1);
};

export const hasVariables = (
  textbox: fabric.Textbox & { originalText: string },
) => {
  const regex = /{.*?}/g;

  const { text, originalText } = textbox;

  const textToBeUsed = originalText ? originalText : text || "";

  return regex.test(textToBeUsed);
};

export const countStamps = (canvas: fabric.Canvas) => {
  const objects = canvas.getObjects();

  const stamps = objects.filter(object => {
    const { customType } = object as fabric.Object & {
      customType: ExtendedObjectType;
    };

    return customType && customType === "stamp";
  });

  return stamps.length;
};

export const countStampsFromObj = (objects: fabric.Object[]) => {
  if (!objects) return 0;

  const stamps = objects.filter(object => {
    const { customType } = object as fabric.Object & {
      customType: ExtendedObjectType;
    };

    return customType && customType === "stamp";
  });

  return stamps.length;
};

export const createVideoObject = (
  sourceUrl: string,
  // Possible TO DO: take all needed props (need scaleY, scaleX, and opacity for now)
  props?: { width?: number; height?: number; top?: number; left?: number },
) => {
  const videoElement = document.createElement("video");
  const { width = 530, height = 298, top = 0, left = 0 } = props || {};

  videoElement.width = width;
  videoElement.height = height;
  videoElement.muted = true;
  videoElement.crossOrigin = "anonymous";
  videoElement.controls = true;
  videoElement.loop = true;

  const sourceElement = document.createElement("source");
  sourceElement.src = sourceUrl;
  sourceElement.type = "video/mp4";
  videoElement.appendChild(sourceElement);

  const fabricVideo = new fabric.Image(videoElement, {
    left,
    top,
    name: uuid(),
  });

  return fabricVideo;
};

/**
 * Returns the boundaries of the selection of the specified fabric objects,
 * if only one fabric object is specified, returns the boundaries of the canvas
 * @param fabricObjects List of objects
 * @param canvas The canvas instance
 */
export const getSelectionBoundaries = (
  fabricObjects: fabric.Object[],
  canvas: fabric.Canvas,
): {
  left: number;
  top: number;
  right: number;
  bottom: number;
} => {
  const left =
    fabricObjects.length === 1
      ? 0
      : Math.min(...fabricObjects.map(obj => obj.getBoundingRect(true).left));

  const top =
    fabricObjects.length === 1
      ? 0
      : Math.min(...fabricObjects.map(obj => obj.getBoundingRect(true).top));

  const right =
    fabricObjects.length === 1
      ? canvas.width!
      : Math.max(
          ...fabricObjects.map(obj => {
            const rectBound = obj.getBoundingRect(true);
            return rectBound.left + rectBound.width;
          }),
        );

  const bottom =
    fabricObjects.length === 1
      ? canvas.height!
      : Math.max(
          ...fabricObjects.map(obj => {
            const rectBound = obj.getBoundingRect(true);
            return rectBound.top + rectBound.height;
          }),
        );

  return { right, left, bottom, top };
};

/**
 * Aligns the specified fabric object to the specified container boundaries
 * @param fabricObject The fabric object to process
 * @param alignLayersActionType The align layers action type
 * @param selectionBoundaries The boundaries to align the fabric object to
 */
export const processAlignment = (
  fabricObject: fabric.Object,
  alignLayersActionType: AlignLayersActionType,
  selectionBoundaries: {
    left: number;
    top: number;
    right: number;
    bottom: number;
  },
) => {
  const objectBoundRect = fabricObject.getBoundingRect(true);
  const leftDiff = Math.abs(
    objectBoundRect.left - (fabricObject.left ?? objectBoundRect.left),
  );
  const topDiff = Math.abs(
    objectBoundRect.top - (fabricObject.top ?? objectBoundRect.top),
  );

  switch (alignLayersActionType) {
    case "left":
      fabricObject.set("left", selectionBoundaries.left + leftDiff);
      break;

    case "center":
      fabricObject.set(
        "left",
        selectionBoundaries.left +
          (selectionBoundaries.right - selectionBoundaries.left) / 2 -
          objectBoundRect.width / 2 +
          leftDiff,
      );
      break;

    case "right":
      fabricObject.set(
        "left",
        selectionBoundaries.right - objectBoundRect.width + leftDiff,
      );
      break;

    case "top":
      fabricObject.set("top", selectionBoundaries.top + topDiff);
      break;

    case "middle":
      fabricObject.set(
        "top",
        selectionBoundaries.top +
          (selectionBoundaries.bottom - selectionBoundaries.top) / 2 -
          objectBoundRect.height / 2 +
          topDiff,
      );
      break;

    case "bottom":
      fabricObject.set(
        "top",
        selectionBoundaries.bottom - objectBoundRect.height + topDiff,
      );
      break;
    default:
      break;
  }

  fabricObject.setCoords();
};

export const getFontNames = (canvas: fabric.Canvas) => {
  const stampFontFamilyArray = canvas
    .getObjects()
    .filter(
      obj => isStamp(obj as unknown as ICanvasObject) && obj.type === "group",
    )
    .map(obj => {
      const fontFamilyArray = (obj as fabric.Group)
        .getObjects()
        .map(obj => {
          // styles is an object with index as a key.
          // { 0: { 0: {...}, 1: {...} }}
          // The outer 0 means the text line
          // The inner 0 and 1 means the index of each char in a text
          const fontFamilySet = new Set<string>();
          const styles = (obj as fabric.Textbox).get("styles");
          for (const textLineIndex in styles) {
            for (const charIndex in styles[textLineIndex]) {
              const style = styles[textLineIndex][charIndex];
              if (style.fontFamily) {
                fontFamilySet.add(style.fontFamily);
              }
            }
          }

          return Array.from(fontFamilySet);
        })
        .reduce((acc, current) => {
          return acc.concat(current);
        }, []);

      return Array.from(new Set(fontFamilyArray));
    })
    .reduce((acc, current) => {
      return acc.concat(current);
    }, []);

  const textboxFontFamilyArray = canvas
    .getObjects()
    .filter(obj => obj.type === "textbox")
    .map(obj => (obj as fabric.Textbox).fontFamily)
    .filter(fontFamily => !!fontFamily) as string[];

  return Array.from(
    new Set(stampFontFamilyArray.concat(textboxFontFamilyArray)),
  );
};
