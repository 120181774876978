import { useNavigate } from "react-router-dom";
import { SelectFolder } from "../SelectFolder";
import { useAppSelector } from "../redux";
import { useQPBreadcrumb } from "../useQPBreadcrumb";

import styles from "./TreeNav.module.scss";

export const TreeNav = () => {
  const { toFolder } = useQPBreadcrumb();
  const navigate = useNavigate();
  const treeNavOpen = useAppSelector(({ media }) => media.treeNavOpen);

  return (
    <div className={styles.host} data-open={treeNavOpen}>
      <SelectFolder
        onSelect={(keys, info, path) => {
          navigate({ search: toFolder(path!) });
        }}
      />
    </div>
  );
};
