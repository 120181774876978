import { useState } from "react";
import { Stack, Tabs, Tab } from "@mui/material";
import { StoreType } from "screens/designStudio/designStudioV2/types";
import { MediaPanelTabs, useMediaPanelTabs } from "./media/useMediaTabs";
import { StockPanel } from "./media/Stock";
import { BrandKitPanel } from "./media/BrandKit";
import { UploadPanel } from "./media/Upload";

type MediaProps = {
  store: StoreType;
};

const a11yProps = (name: MediaPanelTabs) => ({
  id: `media-panel-tab-${name}`,
  "aria-controls": `tabpanel-${name}`,
});

export const Media = ({ store }: MediaProps) => {
  const { currentTab, setTab, tabs } = useMediaPanelTabs();

  const [search, setSearch] = useState("constellation");
  const handleChange = (
    _event: React.SyntheticEvent,
    newValue: MediaPanelTabs,
  ) => {
    setTab(newValue);
  };

  return (
    <Stack
      direction="column"
      alignItems="stretch"
      sx={{ width: 1 }}
      spacing={2}
    >
      <Tabs
        value={currentTab}
        onChange={handleChange}
        aria-label="media panel tabs"
        variant="fullWidth"
        centered
        sx={{
          pt: 1,
          position: "sticky",
          top: 0,
          backgroundColor: "background.paper",
          zIndex: 1,
          width: 1,
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        {tabs.map(tab => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
            {...a11yProps(tab.value)}
          />
        ))}
      </Tabs>
      {currentTab === "stock" && (
        <StockPanel store={store} search={search} setSearch={setSearch} />
      )}
      {currentTab === "brandKit" && <BrandKitPanel store={store} />}
      {currentTab === "upload" && <UploadPanel store={store} />}
    </Stack>
  );
};
