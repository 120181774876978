import { useState, useCallback } from "react";
import { IAd, IAdWithChildren } from "shared/types/adLibrary";
import {
  isChildRecord,
  mapAdCardToAdImportTableRecord,
} from "../../adCompleteImportDrawer/adImportTable/adImportTableContainer/adImportTable.utils";
import { IAdValidations, getValidationForAdId } from "../../adValidationUtils";

type UseAdImportTableParams = {
  ads: IAd[];
};

export const useAdImportTable = ({ ads }: UseAdImportTableParams) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const onExpand = useCallback(
    (expanded: boolean, record: IAd): void => {
      if (expanded) {
        setExpandedRowKeys([...expandedRowKeys, record.id]);
      } else {
        setExpandedRowKeys(expandedRowKeys.filter(key => key !== record.id));
      }
    },
    [expandedRowKeys],
  );

  const onToggleExpand = useCallback(
    (record: IAd): void => {
      const keyFound = expandedRowKeys.includes(record.id);
      if (keyFound) {
        setExpandedRowKeys(expandedRowKeys.filter(key => key !== record.id));
      } else {
        setExpandedRowKeys([...expandedRowKeys, record.id]);
      }
    },
    [expandedRowKeys],
  );

  const isDisabled = (
    adRecord: IAdWithChildren,
    errors: IAdValidations[] | undefined,
  ) => {
    if (isChildRecord(adRecord)) return false;
    return getValidationForAdId(adRecord.id, errors ?? [])?.hasErrors ?? false;
  };

  const adRecords: IAdWithChildren[] = ads.map(ad => {
    return {
      ...ad,
      children: ad?.visuals?.cards?.map((card, index) =>
        mapAdCardToAdImportTableRecord(ad, card, index),
      ),
    };
  });

  return {
    expandedRowKeys,
    onExpand,
    onToggleExpand,
    isDisabled,
    adRecords,
  };
};
