import { useDataListURLContext } from "./dataListURLContext";
import { Field } from "./types";
import { useDataListURLFilters } from "./useDataListURLFilters";
import { useDataListURLGlobalFilter } from "./useDataListURLGlobalFilter";
import { useDataListURLIds } from "./useDataListURLIds";
import { useDataListURLSorter } from "./useDataListURLSorter";
import { getAllAdIds, getEntries, getFilters, getSelectedItems } from "./utils";
import { nonNullable } from "utils/helpers.array";

export type Props<RecordType> = {
  data: RecordType[] | undefined;
  isLoading: boolean;
  isError: boolean;
};

export type RecordWithId = {
  id: string;
  children?: Omit<RecordWithId, "children">[];
};

export const useDataListURLData = <
  FieldKey extends string,
  RecordType extends RecordWithId,
>({
  data,
  isLoading,
  isError,
}: Props<RecordType>) => {
  const { selectedIds, showSelected, toggleSelectedIds, setSelectedItemIds } =
    useDataListURLIds<RecordType>(getAllAdIds);
  const selectedItems = getSelectedItems<RecordType>(data, selectedIds);
  const dataToFilter = showSelected ? selectedItems : data;

  const filteredData = useSortData<FieldKey, RecordType>(
    useFilterDataByGlobalFilter<FieldKey, RecordType>(
      useFilterDataByFilters<FieldKey, RecordType>(dataToFilter),
    ),
  );

  return {
    data: filteredData,
    originalData: data ?? [],
    toggleSelectedIds,
    setSelectedItemIds,
    selectedItems,
    isLoading,
    isError,
  };
};

export const useFilterDataByFilters = <FieldKey extends string, RecordType>(
  data: RecordType[] | undefined,
  uncommittedFilters?: Record<string, string[]>,
) => {
  const { fields } = useDataListURLContext<FieldKey, RecordType>();
  const { filters } = useDataListURLFilters<FieldKey, RecordType>();

  if (!data) return [];

  const fieldEntries = getEntries(fields);
  const filterValues = getFilters<FieldKey, RecordType>(
    fieldEntries,
    uncommittedFilters ?? filters,
  );

  return data.filter(record =>
    filterValues.every(([filterValue, filterFn]) =>
      filterFn(filterValue, record),
    ),
  );
};

export const useFilterDataByGlobalFilter = <
  FieldKey extends string,
  RecordType,
>(
  data: RecordType[] | undefined,
) => {
  const { fields } = useDataListURLContext<FieldKey, RecordType>();
  const { globalFilter } = useDataListURLGlobalFilter();

  if (!data) return [];
  if (!globalFilter) return data;

  const filterFunctions = Object.values<Field<RecordType> | undefined>(fields)
    .map(field => field?.globalFilterFn ?? field?.filterFn)
    .filter(nonNullable);

  return data.filter(record =>
    filterFunctions.some(filterFn => filterFn([globalFilter], record)),
  );
};

export const useSortData = <FieldKey extends string, RecordType>(
  data: RecordType[] | undefined,
) => {
  const { fields } = useDataListURLContext<FieldKey, RecordType>();
  const { sortKey, sortOrder } = useDataListURLSorter<FieldKey, RecordType>();

  if (!data) return [];
  if (!sortKey || !sortOrder) return data;

  const { sorterFn } = fields[sortKey] ?? {};

  if (!sorterFn) return data;

  return data.sort((a, b) =>
    sortOrder === "ascend" ? sorterFn(a, b) : sorterFn(b, a),
  );
};
