/*
 * This function is used to avoid collission with auth0 code query param after the user has been authenticated with Webdam login.
 * It renames the code query param to wd_de and removes the code query param from the url.
 * The original idea was to have the window.addEventListener('DOMContentLoaded', redirectWebdamAuthCodeEventListener) within this file.
 * But due to react constranints it had to be done in src/shared/components/media/CAMCallback/CAMCallback.tsx (<CAMCallback/>) file.
 */

export const renameWebdamAuthCodeEventListener = () => {
  const isCamCallback = window.location.pathname === "/media-callback";
  const search = new URLSearchParams(window.location.search);
  if (isCamCallback && !search.get("wd_code")) {
    search.set("wd_code", search.get("code")!);
    search.delete("code");
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${search.toString()}`,
    );
  }
};
