import Konva from "konva";
import { raise } from "utils/errorMessage";

// The following functions use Konva's internals to get the stage and page container
// There's no Polotno API for this, so we have to use Konva directly
const getKonvaContainer = () =>
  document.querySelector("canvas")?.parentElement ??
  raise("No canvas container found");

const getKonvaStageFromContainer = (container: HTMLElement) =>
  Konva.stages.find(s => s.content === container) ??
  raise("No canvas container found");

const getPageContainer = (stage: Konva.Stage) =>
  stage.findOne(".page-container") ?? raise("No page container found");

const getPageContainerPosition = (pageContainer: Konva.Node) =>
  pageContainer.getRelativePointerPosition() ?? raise("No position found");

type DraggableElement = {
  width?: number;
  height?: number;
};

export const useDragToCanvas = () => {
  const getDragEndPosition = (elem: DraggableElement) => {
    const stage = getKonvaStageFromContainer(getKonvaContainer());
    const pageContainer = getPageContainer(stage);
    const position = getPageContainerPosition(pageContainer);
    const { width = 200, height = 200 } = elem;

    return {
      x: position.x - width / 2,
      y: position.y - height / 2,
    };
  };

  return {
    getDragEndPosition,
  };
};
