import { useQuery } from "react-query";
import { beeswaxCampaignsQueryKey } from "../constants";
import API from "services";

export const useFetchBeeswaxCampaigns = () => {
  return useQuery(beeswaxCampaignsQueryKey, async () => {
    const { result, error } =
      await API.services.beeswaxCampaigns.getCampaigns();

    if (error || !result) {
      throw new Error(error?.message ?? "Failed to fetch beeswax campaigns");
    }

    return result;
  });
};
