import { Stack, Typography } from "@mui/material";
import { ImageList, ImageListItem } from "./ImageList";

type RecentImagesListProps = {
  files: ImageListItem[];
  onClick: (file: ImageListItem) => void;
  onRemove?: (file: ImageListItem) => void;
};

export const RecentImagesList = ({
  files,
  onClick,
  onRemove,
}: RecentImagesListProps) => {
  return (
    <Stack spacing={2}>
      <Typography variant="subtitle2" fontWeight="fontWeightBold">
        Recent files
      </Typography>
      {!files.length ? (
        <Typography variant="subtitle2">
          {"You haven't added any images yet."}
        </Typography>
      ) : (
        <ImageList images={files} onClick={onClick} onRemove={onRemove} />
      )}
    </Stack>
  );
};
