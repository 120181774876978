import { useDeviceDetection } from "screens/designStudio/hooks/useDeviceDetection";
import { Outlet } from "react-router-dom";
import { Typography } from "antd";
import Duck from "statics/images/aflac-duck-pose-looking.png";
import styles from "./SalesEnablementMobileGuard.module.scss";
import { useEffect } from "react";

export const SalesEnablementMobileGuard = () => {
  const { isSupportedDevice } = useDeviceDetection();

  useEffect(() => {
    document.body.style.overflow = isSupportedDevice ? "auto" : "hidden";
    const header = document.querySelector("header");
    if (header) header.style.display = isSupportedDevice ? "block" : "none";
  }, [isSupportedDevice]);

  if (isSupportedDevice !== undefined && !isSupportedDevice) {
    return (
      <div className={styles.container}>
        <div className={styles.message}>
          <div>
            <Typography.Title level={2}>
              Mobile Support is still in the works!
            </Typography.Title>
            <Typography.Text>
              For the optimal Sales Enablement experience please use your
              Desktop or Laptop.
            </Typography.Text>
          </div>
        </div>
        <img width={512} height={411} src={Duck} alt="Aflac Duck" />
      </div>
    );
  }

  return <Outlet />;
};
