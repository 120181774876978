import { MarketingMaterial } from "shared/types/marketingMaterials";
import { Layer, Page, TemplateFile } from "shared/types/salesEnablement";
import { LayerDataValue, useLayersWithData } from "./useLayersWithData";
import { useMemo } from "react";
import { isTruthy } from "utils/helpers.array";
import { get } from "lodash";

export type RenderableHtmlVariables = Record<
  string,
  { type: string; value?: string | string[] }
>;

const variableTypes = {
  disclosure: "list",
  conditional: "conditional",
  product: "snippet",
  agent: "snippet",
  manual: "snippet",
};

export const renderableHtmlVariables = (
  data: Record<string, LayerDataValue>,
  layers: Layer[],
): RenderableHtmlVariables => {
  const htmlData: RenderableHtmlVariables = {};

  for (const key in data) {
    const pageLayer = layers.find(layer => layer.id === key);
    if (!pageLayer) continue;

    const type = get(variableTypes, pageLayer.smartVariable ?? "", "text");
    const rawValue = get(data[key], "value", data[key] as string);
    const value = type === "list" ? rawValue?.split("\n") : rawValue;

    htmlData[key] = { type, value };
  }

  return htmlData;
};

export function useHTMLData({
  file,
  material,
}: {
  file?: TemplateFile;
  material?: Partial<MarketingMaterial>;
}): { data: RenderableHtmlVariables } {
  const { getLayersWithData } = useLayersWithData({
    getName: layer => {
      return layer.id;
    },
    getNestedLayersName: (layer, subLayer) => {
      return subLayer?.id ?? layer.id;
    },
  });

  const data = useMemo<RenderableHtmlVariables>(() => {
    const pageLayers = file?.pages
      ?.flatMap((page: Page) => page.layers)
      .filter(isTruthy);

    if (!pageLayers) return {};

    const layersWithData = getLayersWithData({
      layers: pageLayers,
      material,
    });

    return renderableHtmlVariables(layersWithData, pageLayers);
  }, [file, material, getLayersWithData]);

  return { data };
}
