import { LayerElement } from "../../types";
import getCaretCoordinates from "textarea-caret";

const getCorner = (
  pivotX: number,
  pivotY: number,
  diffX: number,
  diffY: number,
  angle: number,
) => {
  const distance = Math.sqrt(diffX * diffX + diffY * diffY);
  const pivotAngle = angle + Math.atan2(diffY, diffX);
  const x = pivotX + distance * Math.cos(pivotAngle);
  const y = pivotY + distance * Math.sin(pivotAngle);

  return { x, y };
};

export const getClientRect = (element: LayerElement) => {
  const { x, y, width, height, rotation } = element;
  const rad = (rotation / 180) * Math.PI;

  const p1 = getCorner(x, y, 0, 0, rad);
  const p2 = getCorner(x, y, width, 0, rad);
  const p3 = getCorner(x, y, width, height, rad);
  const p4 = getCorner(x, y, 0, height, rad);

  const minX = Math.min(p1.x, p2.x, p3.x, p4.x);
  const minY = Math.min(p1.y, p2.y, p3.y, p4.y);
  const maxX = Math.max(p1.x, p2.x, p3.x, p4.x);
  const maxY = Math.max(p1.y, p2.y, p3.y, p4.y);

  return {
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY,
    minX,
    minY,
    maxX,
    maxY,
  };
};

export const positionHorizontalLeft = (
  elements: LayerElement[],
  pageWidth: number,
) => {
  let minX = pageWidth;
  elements.forEach(element => {
    minX = Math.min(minX, getClientRect(element).x);
  });
  if (elements.length === 1) {
    minX = 0;
  }

  elements.forEach(element => {
    const box = getClientRect(element);
    element.set({ x: element.x - box.x + minX });
  });
};

export const positionHorizontalRight = (
  elements: LayerElement[],
  pageWidth: number,
) => {
  let maxX = 0;
  elements.forEach(element => {
    const rect = getClientRect(element);
    maxX = Math.max(maxX, rect.x + rect.width);
  });
  if (elements.length === 1) {
    maxX = pageWidth;
  }

  elements.forEach(element => {
    const rect = getClientRect(element);
    element.set({
      x: element.x - rect.x + maxX - rect.width,
    });
  });
};

export const positionHorizontalCenter = (
  elements: LayerElement[],
  pageWidth: number,
) => {
  let minX = pageWidth;
  let maxX = 0;
  elements.forEach(element => {
    const rect = getClientRect(element);
    minX = Math.min(minX, rect.x);
    maxX = Math.max(maxX, rect.x + rect.width);
  });
  let middle = (maxX + minX) / 2;
  if (elements.length === 1) {
    middle = pageWidth / 2;
  }

  elements.forEach(element => {
    const rect = getClientRect(element);
    const delta = element.x - rect.x;
    element.set({
      x: middle + delta - rect.width / 2,
    });
  });
};

export const positionVerticalTop = (
  elements: LayerElement[],
  pageHeight: number,
) => {
  let minY = pageHeight;
  elements.forEach(element => {
    minY = Math.min(minY, getClientRect(element).y);
  });
  if (elements.length === 1) {
    minY = 0;
  }

  elements.forEach(element => {
    const box = getClientRect(element);
    element.set({ y: element.y - box.y + minY });
  });
};

export const positionVerticalBottom = (
  elements: LayerElement[],
  pageHeight: number,
) => {
  let maxY = 0;
  elements.forEach(element => {
    const rect = getClientRect(element);
    maxY = Math.max(maxY, rect.y + rect.height);
  });
  if (elements.length === 1) {
    maxY = pageHeight;
  }

  elements.forEach(element => {
    const rect = getClientRect(element);
    element.set({
      y: element.y - rect.y + maxY - rect.height,
    });
  });
};

export const positionVerticalCenter = (
  elements: LayerElement[],
  pageHeight: number,
) => {
  let minY = pageHeight;
  let maxY = 0;

  elements.forEach(element => {
    const rect = getClientRect(element);
    minY = Math.min(minY, rect.y);
    maxY = Math.max(maxY, rect.y + rect.height);
  });

  let middle = (maxY + minY) / 2;

  if (elements.length === 1) {
    middle = pageHeight / 2;
  }

  elements.forEach(element => {
    const rect = getClientRect(element);
    const delta = element.y - rect.y;
    element.set({
      y: middle + delta - rect.height / 2,
    });
  });
};

export const getTextareaCaretCoords = (textarea: HTMLTextAreaElement) => {
  const coords = getCaretCoordinates(textarea, textarea.selectionEnd, {
    debug: true,
  });
  const textareaBoundingRect = textarea.getBoundingClientRect();

  return {
    top: textareaBoundingRect.top + (coords.height + coords.top) / 2,
    left: textareaBoundingRect.left + coords.left / 2,
  };
};

export const spaceHorizontally = (elements: LayerElement[], minX: number) => {
  let maxX = 0;
  let totalElementsWidth = 0;

  elements.forEach(element => {
    const rect = getClientRect(element);
    minX = Math.min(minX, rect.x);
    maxX = Math.max(maxX, rect.x + rect.width);
    totalElementsWidth += rect.width;
  });

  const space = (maxX - minX - totalElementsWidth) / (elements.length - 1);

  const sorted = elements.slice().sort((a, b) => {
    const aBox = getClientRect(a);
    const bBox = getClientRect(b);
    return aBox.x - bBox.x;
  });

  let offset = 0;
  sorted.forEach(element => {
    const box = getClientRect(element);
    const newX = element.x - box.x + minX + offset;
    element.set({ x: newX });
    offset += box.width + space;
  });
};

export const spaceVertically = (elements: LayerElement[], minY: number) => {
  let maxY = 0;
  let totalElementsHeight = 0;

  elements.forEach(element => {
    const rect = getClientRect(element);
    minY = Math.min(minY, rect.y);
    maxY = Math.max(maxY, rect.y + rect.height);
    totalElementsHeight += rect.height;
  });

  const space = (maxY - minY - totalElementsHeight) / (elements.length - 1);

  const sorted = elements.slice().sort((a, b) => {
    const aBox = getClientRect(a);
    const bBox = getClientRect(b);
    return aBox.y - bBox.y;
  });

  let offset = 0;
  sorted.forEach(element => {
    const box = getClientRect(element);
    const newY = element.y - box.y + minY + offset;
    element.set({ y: newY });
    offset += box.height + space;
  });
};
