import { Typography, Slider } from "@mui/material";
import { observer } from "mobx-react-lite";
import { StackContainer } from "../containers/StackContainer";
import {
  ImageLayerElement,
  FigureLayerElement,
  TextLayerElement,
} from "screens/designStudio/designStudioV2/types";
import { ColorResult } from "react-color";
import { ColorPickerPopover } from "screens/designStudio/designStudioV2/editorDrawer/editorDrawerContent/polotnoEditor/editorTopbar/layerEffects/ColorPickerPopover";

type ShadowProps = {
  elements: (TextLayerElement | ImageLayerElement | FigureLayerElement)[];
};

const Shadow = observer(({ elements }: ShadowProps) => {
  const [layer] = elements;
  const shadowBlur = layer?.shadowBlur || 0;
  const shadowOpacity = layer?.shadowOpacity || 0;
  const shadowOffsetX = layer?.shadowOffsetX || 0;
  const shadowOffsetY = layer?.shadowOffsetY || 0;

  const handleSliderChange =
    (property: string) => (e: Event, newValue: number | number[]) => {
      const value = Array.isArray(newValue) ? newValue[0] : newValue;
      elements.forEach(element => {
        element.set({ [property]: Number(value.toFixed(0)) });
      });
    };

  const handleOpacityChange =
    (property: string, scale: number = 100) =>
    (_e: Event, newValue: number | number[]) => {
      const value = Array.isArray(newValue) ? newValue[0] : newValue;
      elements.forEach(element => {
        element.set({ [property]: Number((value / scale).toFixed(2)) });
      });
    };

  const handleShadowColorChange = (color: ColorResult) => {
    elements.forEach(element => {
      element.set({ shadowColor: color.hex });
    });
  };

  return (
    <>
      <StackContainer>
        <Typography variant="caption">Blur</Typography>
      </StackContainer>
      <Slider
        value={shadowBlur}
        onChange={handleSliderChange("shadowBlur")}
        valueLabelDisplay="auto"
        min={0}
        max={50}
        step={1}
        valueLabelFormat={value => value.toFixed(0)}
      />
      <StackContainer>
        <Typography variant="caption">Offset X</Typography>
      </StackContainer>
      <Slider
        value={shadowOffsetX}
        onChange={handleSliderChange("shadowOffsetX")}
        valueLabelDisplay="auto"
        min={-50}
        max={50}
        step={1}
        valueLabelFormat={value => value.toFixed(0)}
      />
      <StackContainer>
        <Typography variant="caption">Offset Y</Typography>
      </StackContainer>
      <Slider
        value={shadowOffsetY}
        onChange={handleSliderChange("shadowOffsetY")}
        valueLabelDisplay="auto"
        min={-50}
        max={50}
        step={1}
        valueLabelFormat={value => value.toFixed(0)}
      />
      <StackContainer>
        <Typography variant="caption">Opacity</Typography>
      </StackContainer>
      <Slider
        value={shadowOpacity * 100}
        onChange={handleOpacityChange("shadowOpacity")}
        valueLabelDisplay="auto"
        min={0}
        max={100}
        step={1}
        valueLabelFormat={value => value.toFixed(0)}
      />
      <ColorPickerPopover
        color={layer?.shadowColor || "#000000"}
        onChange={handleShadowColorChange}
        label="Color"
      />
    </>
  );
});

export default Shadow;
