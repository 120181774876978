import { useMemo, useState } from "react";
import style from "./TemplateVersionsDrawer.module.scss";
import { TemplateVersion } from "shared/types/designStudio";
import TemplateTable from "./TemplateTable";
import SelectedVersionDetails from "./SelectedVersionDetails";

type TemplateVersionsProps = {
  templateVersions: TemplateVersion[];
};

const TemplateVersionsDrawer = ({
  templateVersions,
}: TemplateVersionsProps) => {
  const [indexSelected, setIndexSelected] = useState<number>(0);

  const sortedTemplates: TemplateVersion[] = useMemo(() => {
    return [...templateVersions].sort((a, b) => b.uploadedAt - a.uploadedAt);
  }, [templateVersions]);
  const selectedVersion = useMemo(() => {
    return sortedTemplates[indexSelected];
  }, [indexSelected, sortedTemplates]);

  return (
    <div className={style.drawer}>
      <div className={style.historyContainer}>
        <TemplateTable
          sortedTemplates={sortedTemplates}
          indexSelected={indexSelected}
          setIndexSelected={setIndexSelected}
        />
      </div>
      <div className={style.detailsContainer}>
        {selectedVersion && (
          <SelectedVersionDetails
            selectedVersion={selectedVersion}
            indexSelected={indexSelected}
            sortedTemplatesLength={sortedTemplates.length}
          />
        )}
      </div>
    </div>
  );
};

export default TemplateVersionsDrawer;
