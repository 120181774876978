import { Stack, Divider } from "@mui/material";
import { observer } from "mobx-react-lite";
import { StoreType } from "screens/designStudio/designStudioV2/types";
import { FillColorEditor } from "../shared/FillColorEditor";
import FontFamilySelect from "./FontFamilySelect";
import FontSizeInput from "./FontSizeInput";
import LineHeightControl from "./LineHeightControl";
import TextFormatControls from "./TextFormatControls";

interface EditorControlsProps {
  store: StoreType;
}

const TextEditor = observer(({ store }: EditorControlsProps) => {
  const elements = store.selectedElements.filter(el => el.type === "text");
  const loadedFonts = store?.fonts?.map(fontNode => fontNode?.fontFamily) || [];

  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      gap={1}
    >
      <FillColorEditor elements={elements} />
      <FontFamilySelect elements={elements} brandFronts={loadedFonts} />
      <FontSizeInput elements={elements} />
      <LineHeightControl elements={elements} />
      <TextFormatControls elements={elements} />
      <Divider orientation="vertical" flexItem />
    </Stack>
  );
});

export default TextEditor;
