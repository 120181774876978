import { AbsoluteFill, Audio, Sequence, Series } from "remotion";
import { CompositionType } from "shared/types/assetExporter";
import { checkIfItIsVideoSrc } from "utils/helpers.adEngine";
import { getSrcMediaComposition } from "../../shared/components/Preview/utils";
import { useAssetBatchesContext } from "../../shared/contexts/AssetBatchesContext";
import { useAssetBatchesValueMappingContext } from "../../shared/contexts/AssetBatchesValueMappingContext";
import { useVideoStitchingContext } from "../../shared/contexts/VideoStitchingContext";
import { InfoIcon } from "./InfoIcon";
import { useVideoCanvas } from "./useVideoCanvas";
import { useEffect, useState } from "react";

interface Props {
  fps: number;
  playerWidth: number;
  playerHeight: number;
}

export const VideoComposition = ({ fps, playerWidth, playerHeight }: Props) => {
  const { isPlaying: playing, currentFrame } = useVideoStitchingContext();
  const { compositions, artboard, editingAudioFiles } =
    useAssetBatchesContext();
  const { selectedRow } = useAssetBatchesValueMappingContext();
  const [videoCanvases, setVideoCanvases] = useState<JSX.Element[]>([]);

  const { getVideoCanvas } = useVideoCanvas({
    fps,
    playerWidth,
    playerHeight,
    playing,
    currentFrame,
  });

  useEffect(() => {
    const getVideoCanvases = async () => {
      const newVideoCanvases = await Promise.all(
        compositions.map(async (composition, index) => {
          if (composition.type === CompositionType.Media) {
            const src = getSrcMediaComposition(composition, selectedRow);
            const { isVideo, src: convertedSrc } = await checkIfItIsVideoSrc(
              src,
            );
            return getVideoCanvas(
              composition,
              index,
              false,
              isVideo,
              convertedSrc,
            );
          }
          return getVideoCanvas(composition, index, false, false, "");
        }),
      );
      setVideoCanvases(newVideoCanvases);
    };
    getVideoCanvases();
  }, [compositions, selectedRow, getVideoCanvas]);

  return (
    <>
      {videoCanvases && <Series>{videoCanvases}</Series>}
      {artboard &&
        (artboard?.width > playerWidth || artboard?.height > playerHeight) && (
          <AbsoluteFill>
            <InfoIcon />
          </AbsoluteFill>
        )}
      {editingAudioFiles &&
        editingAudioFiles.map((audioFile, index) => {
          if (!audioFile?.src) return;

          const startInFrames = (audioFile.start / 1000) * fps;
          const endInFrames = (audioFile.end / 1000) * fps;
          const durationInFrames = endInFrames - startInFrames;

          return (
            <Sequence
              key={index}
              from={startInFrames}
              durationInFrames={durationInFrames}
            >
              <Audio loop src={audioFile.src} />
            </Sequence>
          );
        })}
    </>
  );
};
