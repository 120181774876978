import {
  GroupLayer,
  ImageLayer,
  Layer,
  TextLayer,
  OptionGroupLayer,
  ConditionalGroupLayer,
  OtherGroupLayer,
  AccountGroupLayer,
  AgentGroupLayer,
  ProductGroupLayer,
  DisclosureGroupLayer,
  ManualGroupLayer,
} from "shared/types/salesEnablement";

export const isGroupLayer = (layer: Layer): layer is GroupLayer =>
  layer.type === "group";

export const isOtherGrouplayer = (layer: Layer): layer is OtherGroupLayer => {
  return (
    isGroupLayer(layer) &&
    !isOptionGroupLayer(layer) &&
    !isConditionalGroupLayer(layer)
  );
};

export const isAccountGroupLayer = (layer: Layer): layer is AccountGroupLayer =>
  layer.type === "group" && layer.smartVariable === "account";

export const isSnippetGroupLayer = (layer: Layer): layer is OtherGroupLayer => {
  return isOtherGrouplayer(layer) && layer.source === "snippet";
};
export const isOptionGroupLayer = (layer: Layer): layer is OptionGroupLayer =>
  isGroupLayer(layer) && layer.smartVariable === "options";

export const isAgentGroupLayer = (layer: Layer): layer is AgentGroupLayer =>
  isGroupLayer(layer) && layer.smartVariable === "agent";

export const isProductGroupLayer = (layer: Layer): layer is ProductGroupLayer =>
  isGroupLayer(layer) && layer.smartVariable === "product";

export const isManualGroupLayer = (layer: Layer): layer is ManualGroupLayer =>
  isGroupLayer(layer) && layer.smartVariable === "manual";

export const isConditionalGroupLayer = (
  layer: Layer,
): layer is ConditionalGroupLayer => {
  return isGroupLayer(layer) && layer.smartVariable === "conditional";
};

export const isDisclosureGroupLayer = (
  layer: Layer,
): layer is DisclosureGroupLayer => {
  return isGroupLayer(layer) && layer.smartVariable === "disclosure";
};

export const isImageLayer = (layer: Layer): layer is ImageLayer =>
  layer.type === "image";
export const isTextLayer = (layer: Layer): layer is TextLayer =>
  layer.type === "text";

export const hasChildren = (layer: Layer): layer is GroupLayer =>
  isOtherGrouplayer(layer) && layer.subLayers.length > 0;

// If template only has "expirationDate", "formNumber", "Disclosure", it is considered as non-customizable template.
export const isFileCustomizable = (layers: Layer[]) => {
  const nonCustomizables = ["expirationDate", "formNumber", "disclosure"];
  const containsCustomizableLayer = layers.some(layer => !layer.smartVariable); // any layer that is not a smart layer is customizable.
  if (containsCustomizableLayer) return true;

  const hasCustomizableLayers = layers.some(
    layer =>
      layer.smartVariable &&
      !nonCustomizables
        .map(item => item.toLowerCase())
        .includes(layer.smartVariable.toLowerCase()),
  );
  return hasCustomizableLayers;
};

export const isAgentSnippet = (layer: GroupLayer) =>
  hasAllVariables(["Name", "Phone", "Email", "License"], layer) ||
  hasAllVariables(["Name", "Phone", "Email", "LicenseA", "LicenseB"], layer);

export const isProductSnippet = (
  layer: GroupLayer,
): layer is ProductGroupLayer => hasAllVariables(["Product"], layer);

export const hasAllVariables = (requiredVars: string[], layer: GroupLayer) => {
  if (!isSnippetGroupLayer(layer)) return false;

  return requiredVars.every(varName =>
    layer.subLayers.some(
      subLayer =>
        subLayer.variable?.name.toLowerCase() === varName.toLowerCase(),
    ),
  );
};
export const isValidProductSnippet = (layer: GroupLayer) =>
  isProductSnippet(layer) && layer.productTypes?.length > 0;

export const validateLayers = (filteredLayers: Layer[]) => {
  const hasExpirationDate = filteredLayers.some(
    layer => layer.smartVariable === "expirationDate",
  );
  const hasFormNumber = filteredLayers.some(
    layer => layer.smartVariable === "formNumber",
  );

  const isInvalidLayerSnippet = (layer: Layer) => {
    if (!isSnippetGroupLayer(layer)) return false;
    const isValidAgent =
      layer.smartVariable === "agent" && !isAgentSnippet(layer);
    const isValidProduct =
      layer.smartVariable === "product" && !isValidProductSnippet(layer);
    return !layer.smartVariable || isValidAgent || isValidProduct;
  };
  const hasInvalidSnippets = filteredLayers.some(isInvalidLayerSnippet);

  return hasExpirationDate && hasFormNumber && !hasInvalidSnippets;
};
