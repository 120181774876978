import { EyeOutlined } from "@ant-design/icons";
import { Image, Modal } from "antd";
import { useState } from "react";
import ReactPlayer from "react-player";
import { getCAMThumbnail } from "./getCAMThumbnail";
import { getPreviewThumbnail } from "./getPreviewThumbnail";
import { handleImgError } from "./handleImgError";
import { isValidAssetTypeToPreview } from "./utils.filetype";

import styles from "./MediaThumb.module.scss";

type Props = {
  asset: WDAsset;
  size?: number;
};

export const MediaThumb = ({ asset, size = 40 }: Props) => {
  const [visible, setVisible] = useState(false);
  const mediaThumbnail = getCAMThumbnail(asset, 1);
  const previewThumbnail = getPreviewThumbnail(asset);
  const preview = getPreview(asset);

  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        bodyStyle={{ height: 0, padding: 0 }}
        footer={null}
        closable={false}
      >
        {previewThumbnail.isVideo ? (
          <ReactPlayer
            playing={true}
            muted={true}
            loop={true}
            key={previewThumbnail.url}
            url={previewThumbnail.url}
          />
        ) : (
          <Image
            src={previewThumbnail.url}
            preview={false}
            className={styles.image}
          />
        )}
      </Modal>
      <Image
        src={mediaThumbnail.url}
        width={size}
        height={size}
        loading="lazy"
        className={styles.image}
        onClick={() => !!preview && setVisible(true)}
        onError={handleImgError}
        preview={preview}
      />
    </>
  );
};

const getPreview = (asset: WDAsset) => {
  const hasPreview = isValidAssetTypeToPreview(asset);
  const isActive = asset.status === "active";

  if (hasPreview && isActive) {
    return {
      visible: false,
      mask: <EyeOutlined />,
    };
  }

  return false;
};
