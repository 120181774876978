import { useEffect, useRef, useState } from "react";
import {
  InfiniteQueryObserver,
  QueryKey,
  QueryObserver,
  UseBaseQueryOptions,
  UseInfiniteQueryResult,
  notifyManager,
  useQueryClient,
} from "react-query";

const Observer = InfiniteQueryObserver as typeof QueryObserver;

// This hook is based on the internal `useBaseQuery` hook
// which is used by `useQuery` and `useInfiniteQuery` but adapted
// work with an array of queries.
// https://github.com/TanStack/query/blob/v3/src/react/useBaseQuery.ts
export function useConcurrentInfiniteQueries<
  TQueryFnData,
  TError,
  TData,
  TQueryData,
  TQueryKey extends QueryKey,
>(
  concurrency: number,
  options: UseBaseQueryOptions<
    TQueryFnData,
    TError,
    TData,
    TQueryData,
    TQueryKey
  >,
) {
  const mountedRef = useRef(false);
  const [, forceUpdate] = useState(0);

  const queryClient = useQueryClient();

  const [optionsArr] = useState(
    [...Array(concurrency)].map((_, index, array) => {
      const opts = queryClient.defaultQueryObserverOptions({
        ...options,
        optimisticResults: true,
        queryKey: options.queryKey?.concat([
          [index, array.length],
        ] as any) as TQueryKey, // TODO: fix type
      });

      return queryClient.defaultQueryObserverOptions(opts);
    }),
  );

  const [observers] = useState(() => {
    return optionsArr.map(
      options =>
        new Observer<TQueryFnData, TError, TData, TQueryData, TQueryKey>(
          queryClient,
          options,
        ),
    );
  });

  const results = observers.map((observer, index) => {
    return observer.getOptimisticResult(optionsArr[index]);
  }) as UseInfiniteQueryResult<TData, TError>[];

  useEffect(() => {
    mountedRef.current = true;

    const subscriptions = observers.map(observer => {
      return observer.subscribe(
        notifyManager.batchCalls(() => {
          if (mountedRef.current) {
            forceUpdate(x => x + 1);
          }
        }),
      );
    });

    // Update result to make sure we did not miss any query updates
    // between creating the observer and subscribing to it.
    // observer.updateResult();
    for (const observer of observers) {
      observer.updateResult();
    }

    return () => {
      mountedRef.current = false;

      for (const unsubscribe of subscriptions) {
        unsubscribe();
      }
    };
  }, [observers]);

  useEffect(() => {
    // Do not notify on updates because of changes in the options because
    // these changes should already be reflected in the optimistic result.
    // observer.setOptions(defaultedOptions, { listeners: false });
    for (const [index, observer] of observers.entries()) {
      observer.setOptions(optionsArr[index], { listeners: false });
    }
  }, [observers, optionsArr]);

  useEffect(() => {
    for (const res of results) {
      if (res.isSuccess && !res.isFetching && res.hasNextPage) {
        res.fetchNextPage();
      }
    }
  }, [results]);

  return results;
}
