import { Typography, List } from "antd";
import { MarketingMaterial } from "shared/types/marketingMaterials";
import styles from "./ListItem.module.scss";
import { Thumbnail } from "./Thumbnail";

type MarketingMaterialItemProps = {
  item: MarketingMaterial;
  onEdit: (item: MarketingMaterial) => void;
};

export const MarketingMaterialListItem = ({
  item,
  onEdit,
}: MarketingMaterialItemProps) => {
  const { Text } = Typography;
  const thumbnail = item?.templateThumbnail;

  return (
    <List.Item title={item.name}>
      <div className={styles.thumbnailContainer}>
        <Thumbnail thumbnail={thumbnail ?? ""} />
      </div>
      <div className={styles.itemDetails}>
        <div className={styles.itemCell}>
          <Text className={styles.itemName} onClick={() => onEdit(item)}>
            {item.name}
          </Text>
        </div>
      </div>
    </List.Item>
  );
};
