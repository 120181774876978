import { FeedOffer, ISortingOption } from "shared/types/assetBuilder";
import { timezoneOffset } from "utils/helpers";
import { toUSD } from "utils/helpers.currency";
import { formatOfferDataFieldValue } from "utils/helpers.offer";
import { getDataType } from "../offerListSection/offerCollapse/CollapsableHeader.utils";

export const calcSortingOptionValue = (
  { key, content }: ISortingOption,
  offerData: FeedOffer,
) => {
  if (key === "daysInStock" && !offerData.daysInStock) {
    const dateInStock = offerData.dateInStock
      ? formatOfferDataFieldValue(
          "dateInStock",
          offerData.dateInStock.toString(),
        )
      : "";
    const inStock = offerData.dateInStock
      ? parseInt(dateInStock, 10) + timezoneOffset
      : 0;
    const diff = Date.now() - inStock;
    return {
      key,
      content,
      sortingOptionValue: !inStock
        ? "N/A"
        : (diff / (1000 * 60 * 60 * 24)).toFixed(0),
    };
  } else {
    return {
      key,
      content,
      sortingOptionValue:
        getDataType(key) === "number" ||
        key === "aprRate" ||
        key === "priorityScore"
          ? offerData[key]
          : toUSD(offerData[key]),
    };
  }
};
