import {
  ImageObjectParams,
  TextObjectParams,
  createImageObject,
  createTextObject,
  SVGObjectParams,
  createSVGObject,
  deleteSelectedElements,
  duplicateSelectedElements,
  positionSelectedElements,
  PositionType,
  moveSelectedElements,
  LayeringAction,
  toggleLockElements,
  groupSelectedElements,
  ungroupSelectedElements,
  SpacingType,
  spaceSelectedElements,
  FigureObjectParams,
  createFigureObject,
} from "screens/designStudio/designStudioV2/editorDrawer/editorDrawerContext/helpers.store";
import { StoreType } from "polotno/model/store";
import { getSelectedElements } from "../../utils";

export const useElements = (store: StoreType) => {
  const selectedElements = getSelectedElements(store);

  return {
    selectedElements,
    addImage: (params: ImageObjectParams) => createImageObject(store, params),
    addText: (params: TextObjectParams) => createTextObject(store, params),
    addSVG: (params: SVGObjectParams) => createSVGObject(store, params),
    addFigure: (params: FigureObjectParams) =>
      createFigureObject(store, params),
    deleteSelectedElements: () => deleteSelectedElements(store),
    duplicateSelectedElements: () => duplicateSelectedElements(store),
    positionSelectedElements: (position: PositionType) =>
      positionSelectedElements(store, position),
    moveSelectedElements: (action: LayeringAction) =>
      moveSelectedElements(store, action),
    toggleLockElements: () => toggleLockElements(store),
    groupSelectedElements: () => groupSelectedElements(store),
    ungroupSelectedElements: () => ungroupSelectedElements(store),
    spaceSelectedElements: (spacing: SpacingType) =>
      spaceSelectedElements(store, spacing),
  };
};
