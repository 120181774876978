import { Button } from "antd";

interface LinkButtonProps {
  href: string;
  label: string;
}

export const LinkButton = ({ href, label }: LinkButtonProps) => (
  <Button type="link" href={href} target="_blank" rel="noopener noreferrer">
    <span>{label}</span>
  </Button>
);
