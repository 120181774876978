import { UseMutationOptions, useMutation } from "react-query";
import API from "services";
import { beeswaxCampaignsQueryKey } from "../constants";
import { queryClient } from "queryClient";
import { notification } from "antd";
import { IApiResponse } from "shared/types/shared";
import { DeleteBeeswaxCampaignsBody } from "../types";

export const useDeleteBeeswaxCampaigns = (
  options?: Pick<
    UseMutationOptions<
      IApiResponse,
      unknown,
      DeleteBeeswaxCampaignsBody,
      unknown
    >,
    "onSuccess"
  >,
) => {
  return useMutation({
    mutationFn: API.services.beeswaxCampaigns.deleteCampaigns,
    onSettled: () => {
      queryClient.invalidateQueries([beeswaxCampaignsQueryKey]);
    },
    onError: () => {
      notification.error({
        message: "Error",
        description: "Something went wrong deleting campaigns.",
      });
    },
    ...options,
  });
};
