import {
  BlockOutlined,
  CalendarOutlined,
  DeleteOutlined,
  EyeOutlined,
  FormOutlined,
  FullscreenOutlined,
  CloudDownloadOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import { useMediaActions } from "redux/media/media.slice";
import { useFetchPerms } from "../useFetchPerms";
import { useDownloadAsset } from "../useDownloadAsset";
import { useShareLink } from "../useShareLink";
import { useDeleteAsset } from "./useDeleteAsset";

export const useMenuAsset = (record: WDAsset, visible: boolean) => {
  const {
    setAssetsToEdit,
    setEditableRow,
    setAssetToReplace,
    setAssetsToMove,
    setAssetsToExpire,
  } = useMediaActions();
  const downloadAsset = useDownloadAsset();
  const shareLink = useShareLink();
  const { canDelete, canEdit, canMove, canDownload } = useFetchPerms(
    [record.folder.id],
    visible,
  );

  return (
    <Menu>
      <Menu.Item
        icon={<EyeOutlined />}
        key="edit"
        onClick={() => {
          setAssetsToEdit([record]);
        }}
      >
        View asset
      </Menu.Item>
      <Menu.Item
        icon={<FormOutlined />}
        key="rename"
        onClick={() => {
          setEditableRow(record);
        }}
        disabled={!canEdit}
      >
        Rename
      </Menu.Item>
      <Menu.Item
        icon={<BlockOutlined />}
        key="replace"
        onClick={() => {
          setAssetToReplace(record);
        }}
        disabled={!canEdit}
      >
        Replace
      </Menu.Item>
      <Menu.Item
        icon={<CloudDownloadOutlined />}
        key="download"
        disabled={!canDownload}
        onClick={async () => {
          await downloadAsset(record);
        }}
      >
        Download
      </Menu.Item>
      <Menu.Item
        onClick={async () => {
          await shareLink(record);
        }}
        key="share"
        icon={<ShareAltOutlined />}
      >
        Share
      </Menu.Item>
      <Menu.Item
        key="delete"
        icon={<DeleteOutlined />}
        onClick={useDeleteAsset(record)}
        disabled={!canDelete}
      >
        Delete asset
      </Menu.Item>
      <Menu.Item
        icon={<FullscreenOutlined />}
        key="move"
        onClick={() => {
          setAssetsToMove([record]);
        }}
        disabled={!canMove}
      >
        Move File
      </Menu.Item>
      <Menu.Item
        icon={<CalendarOutlined />}
        key="expire"
        onClick={() => {
          setAssetsToExpire([record]);
        }}
        disabled={!canEdit}
      >
        Set expiration date
      </Menu.Item>
    </Menu>
  );
};
