import { CloseOutlined, FilterFilled } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import classNames from "classnames";
import styles from "./TagButton.module.scss";

type TagButtonProps = {
  onClick?: () => void;
  selected: boolean;
  disabled: boolean;
  text: string;
  tooltipText?: string;
  dataCy?: string;
};

export function TagButton({
  onClick,
  selected,
  disabled,
  text,
  tooltipText,
  dataCy,
}: TagButtonProps) {
  return (
    <Tooltip title={tooltipText}>
      <Button
        onClick={onClick}
        size="small"
        data-cy={dataCy ?? "tag-button"}
        className={classNames([
          styles.filterButton,
          selected ? "ant-tag-blue" : "ant-tag-default",
        ])}
        disabled={disabled}
      >
        {text}
        {selected && <CloseOutlined className={styles.close} />}
        {!selected && !disabled && <FilterFilled />}
      </Button>
    </Tooltip>
  );
}
