import { Typography, Slider } from "@mui/material";
import { observer } from "mobx-react-lite";
import { StackContainer } from "../containers/StackContainer";
import { TextLayerElement } from "screens/designStudio/designStudioV2/types";
import { ColorPickerPopover } from "screens/designStudio/designStudioV2/editorDrawer/editorDrawerContent/polotnoEditor/editorTopbar/layerEffects/ColorPickerPopover";
import { ColorResult } from "react-color";

type BackgroundProps = {
  elements: TextLayerElement[];
};

const Background = observer(({ elements }: BackgroundProps) => {
  const [layer] = elements;
  const cornerRadius = layer?.backgroundCornerRadius || 0;
  const backgroundPadding = layer?.backgroundPadding || 0;
  const backgroundOpacity = layer?.backgroundOpacity || 0;

  const handleSlider =
    (property: string, scale: number = 1) =>
    (_: Event, newValue: number | number[]) => {
      const value = typeof newValue === "number" ? newValue : 0;
      elements.forEach(element => {
        element.set({ [property]: value / scale });
      });
    };

  const handleBackgroundColorChange = (color: ColorResult) => {
    elements.forEach(element => {
      element.set({ backgroundColor: color.hex });
    });
  };

  return (
    <>
      <StackContainer>
        <Typography variant="caption">Corner Radius</Typography>
      </StackContainer>
      <Slider
        value={cornerRadius * 100}
        onChange={handleSlider("backgroundCornerRadius", 100)}
        valueLabelDisplay="auto"
        min={0}
        max={100}
        step={1}
        valueLabelFormat={value => value.toFixed(0)}
      />
      <StackContainer>
        <Typography variant="caption">Padding</Typography>
      </StackContainer>
      <Slider
        value={backgroundPadding * 100}
        onChange={handleSlider("backgroundPadding", 100)}
        valueLabelDisplay="auto"
        min={0}
        max={100}
        step={1}
        valueLabelFormat={value => value.toFixed(0)}
      />
      <StackContainer>
        <Typography variant="caption">Opacity</Typography>
      </StackContainer>
      <Slider
        value={backgroundOpacity * 100}
        onChange={handleSlider("backgroundOpacity", 100)}
        valueLabelDisplay="auto"
        min={0}
        max={100}
        step={1}
        valueLabelFormat={value => value.toFixed(0)}
      />
      <ColorPickerPopover
        color={layer?.backgroundColor || "#000000"}
        onChange={handleBackgroundColorChange}
        label="Color"
      />
    </>
  );
});

export default Background;
