import { MutableRefObject, ReactNode } from "react";
import { CAMSelect } from "shared/components/media";

interface IProps {
  onSelectAsset: (url: string, name: string, id?: string) => void;
  ref?: MutableRefObject<any>;
  children?: ReactNode;
  title?: string;
  fileTypes?: string[];
  search?: string;
}

export const CAMOption = ({
  ref,
  onSelectAsset,
  children,
  title,
  fileTypes,
  search,
}: IProps) => {
  const searchObj: MGSearchObj = {
    query: search,
  };
  return (
    <CAMSelect
      width="95vw"
      layout="grid"
      title={title ?? "Select media from CAM"}
      maxSelected={1}
      fileTypes={fileTypes ?? ["png", "jpeg", "jpg", "mp4"]}
      onClose={async assets => {
        onSelectAsset(assets[0].hiResURLRaw, assets[0].name, assets[0].id);
      }}
      search={search ? searchObj : undefined}
    >
      <div ref={ref}>{children ?? "Media from CAM"}</div>
    </CAMSelect>
  );
};
