import { Image } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import MissingThumbnail from "statics/images/missing-thumbnail.png";
import styles from "./Thumbnail.module.scss";

type Props = {
  thumbnail: string;
};

export const Thumbnail = ({ thumbnail }: Props) => {
  return (
    <div className={styles.thumbnailCell}>
      <div className={styles.thumbnailWrapper}>
        <Image
          src={thumbnail || MissingThumbnail}
          preview={
            thumbnail
              ? { mask: <EyeOutlined className={styles.preview} /> }
              : false
          }
          fallback={MissingThumbnail}
          className={styles.thumbnail}
        />
      </div>
    </div>
  );
};
