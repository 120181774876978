import { useMemo } from "react";
import { Space, Typography } from "antd";

import Hyperlink from "shared/components/text/Hyperlink";
// import PatentSymbol from "statics/images/patent.png";

import styles from "../Layout.module.scss";
import { animated, useSpring } from "react-spring";

type PatentProps = {
  collapsed?: boolean;
};

const Patent = ({ collapsed }: PatentProps) => {
  const year = useMemo(() => new Date().getFullYear(), []);

  const style = useSpring({
    opacity: collapsed ? 0 : 1,
  });

  return (
    <animated.div style={style} className={styles.siderFooter}>
      <Space size={0} align="center" direction="vertical">
        {/* <img
          className={styles.patentSymbol}
          src={PatentSymbol}
          alt="Patented"
          width="125"
          height="48"
        /> */}
        <Typography.Text strong>©{year} Constellation Software</Typography.Text>
        <Hyperlink
          text="Privacy Policy"
          href="https://helloconstellation.com/legal/privacy-policy"
        />
      </Space>
    </animated.div>
  );
};

export default Patent;
