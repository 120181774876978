import { useState } from "react";
import { useFetchFilterPresets } from "shared/hooks/designStudio/useFetchFilterPresets";
import {
  useCreateFilterPreset,
  useDeleteFilterPreset,
} from "shared/hooks/designStudio/useMutateFilterPreset";
import { useFilterFields } from "../designStudioV2/filterDrawer/useFilterFields";

export type Feature =
  | "design-studio"
  | "aflac-template"
  | "salesenablement-archive"
  | "marketing-materials"
  | "salesenablement-products"
  | "salesenablement-disclosures";

export type FilterPreset = {
  id: string;
  userId?: string;
  name: string;
  feature: Feature;
  filters: ReturnType<typeof useFilterFields>["tempFilters"];
};

export default (feature: Feature) => {
  const { data: presets = [], isLoading } = useFetchFilterPresets(feature);

  const { mutate: createPreset, isLoading: isCreating } =
    useCreateFilterPreset();
  const { mutate: deletePreset, isLoading: isDeleting } =
    useDeleteFilterPreset();

  const [selectedPreset, setSelectedPreset] = useState<FilterPreset>();
  return {
    presets,
    loading: isLoading || isDeleting || isCreating,
    deletePreset,
    selectedPreset,
    setSelectedPreset,
    createPreset,
  };
};
