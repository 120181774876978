import { Drawer } from "antd";
import { useMediaActions } from "redux/media/media.slice";
import { useAppSelector } from "../redux";
import { AssetTabs } from "./AssetTabs";

export const AssetMetadata = () => {
  const { setAssetsToEdit } = useMediaActions();
  const assets = useAppSelector(({ media }) => media.assetsToEdit);
  const drawerId = "asset-metadata";

  const handleClose = () => {
    setAssetsToEdit([]);
  };

  return (
    <Drawer
      closable={false}
      destroyOnClose
      maskClosable
      width={"928px"}
      onClose={handleClose}
      visible={!!assets.length}
      bodyStyle={{ padding: 0 }}
      data-id={drawerId}
      footer
    >
      <AssetTabs drawerId={drawerId} selectedAssets={assets} />
    </Drawer>
  );
};
