import { DeleteOutlined } from "@ant-design/icons";
import { Button, Form } from "antd";
import { useProductsByType } from "shared/hooks/admin/useProducts";
import { ProductField } from "shared/types/marketingMaterials";
import { Language, ProductGroupLayer } from "shared/types/salesEnablement";
import uuid from "uuid";
import styles from "./ProductLayer.module.scss";
import { Tooltip } from "@mui/material";
import { defaultLanguageOptions } from "shared/constants/salesEnablement";
import { useMemo } from "react";
import { isTruthy } from "utils/helpers.array";
import { SortedSelect } from "shared/components/select/SortedSelect";
import { useMaterialFormInstance } from "screens/adLibrary/marketingMaterials/MarketingMaterialsProvider";
import { getProductDisclosureRule } from "../utils/helpers";
import { Rule } from "antd/lib/form";

type Props = {
  value?: ProductField;
  language: Language;
  layer: ProductGroupLayer;
  onChange?: (productField: ProductField) => void;
  disabled?: boolean;
  disclosureFieldId?: string;
};

export const ProductLayer = ({
  value,
  onChange,
  language,
  layer,
  disabled,
  disclosureFieldId,
}: Props) => {
  const form = useMaterialFormInstance();
  const isArchiveForm = form.getFieldValue("isArchiveForm");

  return isArchiveForm ? (
    <ArchiveProductLayer
      value={value}
      onChange={onChange}
      language={language}
      layer={layer}
      disabled={disabled}
      disclosureFieldId={disclosureFieldId}
    />
  ) : (
    <EditableProductLayer
      value={value}
      onChange={onChange}
      language={language}
      layer={layer}
      disabled={disabled}
      disclosureFieldId={disclosureFieldId}
    />
  );
};

const MAX_PRODUCTS = 6;

export const EditableProductLayer = ({
  layer,
  language,
  value: productField,
  onChange: onChangeProductField,
  disabled,
  disclosureFieldId,
}: Props) => {
  const { maxItems = 6 } = layer;
  const { productsByType: products } = useProductsByType(layer.productTypes);
  const selectedProductIds = (productField?.productsData ?? [])
    .map(productData => productData.productId)
    .filter(isTruthy);

  const form = useMaterialFormInstance();

  const options = useMemo(() => {
    return products.flatMap(option => {
      const currentLanguageName = option.details[language]?.name;
      if (currentLanguageName)
        return {
          label: currentLanguageName,
          value: option.id,
          title: currentLanguageName,
          productType: option.productType,
        };

      if (option.details[option.primaryLanguage]?.name) {
        const missingLanguageName = defaultLanguageOptions.find(
          languageOption => language !== languageOption.value,
        );
        if (!missingLanguageName) return [];

        return {
          label: (
            <Tooltip
              title={`This product doesn't have a ${missingLanguageName.label} translation. Please set the Language to English or contact support.`}
            >
              <span>{option.details[missingLanguageName.value]?.name}</span>
            </Tooltip>
          ),
          value: option.id,
          disabled: true,
          title: option.details[missingLanguageName.value]?.name,
          productType: option.productType,
        };
      }

      return [];
    });
  }, [products, language]);

  if (productField === undefined || !onChangeProductField) return null;

  const onSelectChange = (productId: string | undefined, fieldId: string) => {
    const selectedProduct = products.find(product => product.id === productId);
    if (!selectedProduct) return;
    onChangeProductField({
      ...productField,
      productsData: productField.productsData.map(productData => {
        if (productData.id !== fieldId) return productData;
        return {
          ...productData,
          productId: selectedProduct.id,
          productType: selectedProduct.productType,
          name: selectedProduct.details[language]?.name,
          description: selectedProduct.details[language]?.description,
        };
      }),
    });
  };

  const onClear = (fieldId: string) => {
    onChangeProductField({
      ...productField,
      productsData: productField.productsData.flatMap(productData => {
        if (productData.id !== fieldId) return productData;
        return {
          ...productData,
          productId: undefined,
          productType: undefined,
          name: undefined,
          description: undefined,
        };
      }),
    });
  };

  const onRemoveProduct = (fieldId: string) => {
    const newProducts = productsData.filter(({ id }) => id !== fieldId);
    const newValue = { ...productField, productsData: newProducts };
    onChangeProductField(newValue);

    form.setFieldsValue({
      fields: { [layer.id]: newValue },
    });
  };

  const { productsData } = productField;

  return (
    <div className={styles.container}>
      {productsData.map((productData, index) => {
        const availableOptions = options
          .filter(
            option =>
              !selectedProductIds.includes(option.value) ||
              option.value === productData.productId,
          )
          .reduce<{ label: string; options: typeof options }[]>(
            function groupByProductType(acc, item) {
              const type = item.productType;
              const existingGroup = acc.find(group => group.label === type);
              if (existingGroup) {
                existingGroup.options.push(item);
              } else {
                acc.push({
                  label: type,
                  options: [item],
                });
              }
              return acc;
            },
            [],
          );

        return (
          <div className={styles.form} key={index}>
            {index > 0 && index < maxItems && `Product ${index + 1}`}
            <div className={styles.fields}>
              <Form.Item
                name={["fields", layer?.id, "productsData", index, "productId"]}
                dependencies={[
                  ["fields", disclosureFieldId ?? ""],
                  ["language"],
                  ["locations"],
                ]}
                rules={[getProductDisclosureRule(disclosureFieldId)]}
                className={styles.formItem}
                key={productData.id}
              >
                <SortedSelect<string>
                  placeholder="Please Select"
                  allowClear
                  onChange={selectedProduct =>
                    onSelectChange(selectedProduct, productData.id)
                  }
                  onClear={() => onClear(productData.id)}
                  options={availableOptions}
                  disabled={disabled}
                />
              </Form.Item>
              {index >= 1 && (
                <DeleteOutlined
                  className={styles.deleteIcon}
                  onClick={() => !disabled && onRemoveProduct(productData.id)}
                />
              )}
            </div>
          </div>
        );
      })}
      {productsData.length < maxItems && (
        <Button
          type="link"
          onClick={() =>
            onChangeProductField({
              ...productField,
              productsData: [...productsData, { id: uuid.v4() }],
            })
          }
          disabled={disabled}
        >
          + Add{productField.productsData.length > 0 ? " Another" : ""} Product
        </Button>
      )}
    </div>
  );
};

export const ArchiveProductLayer = ({
  layer,
  value: productField,
  onChange: onChangeProductField,
  disabled,
}: Props) => {
  if (productField === undefined || !onChangeProductField) return null;

  const { productsData } = productField;

  return (
    <>
      {productsData.map((productData, index) => {
        return (
          <div className={styles.form} key={index}>
            {index > 0 && index < MAX_PRODUCTS && `Product ${index + 1}`}
            <div className={styles.fields}>
              <Form.Item
                name={["fields", layer?.id, "productsData", index, "productId"]}
              >
                <SortedSelect<string>
                  placeholder="Please Select"
                  options={[
                    {
                      label: productData.name ?? "",
                      value: productData?.productId ?? "",
                    },
                  ]}
                  disabled={disabled}
                />
              </Form.Item>
            </div>
          </div>
        );
      })}
    </>
  );
};

export const validateRequired: Rule[] = [
  {
    validator: async (_, value?: ProductField) => {
      if (!value) throw new Error("Product is required");

      if (
        !value.productsData.length ||
        value.productsData.some(product => !product.productId)
      ) {
        throw new Error("Product is required");
      }
    },
    type: "object",
    required: true,
    message: "This field is required.",
    validateTrigger: "onSubmit",
  },
];
