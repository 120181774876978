import { List } from "antd";
import styles from "./RenderList.module.scss";

type RenderListProps<T> = {
  items: T[];
  renderItem: (item: T) => JSX.Element;
};

export const RenderList = <T,>({ items, renderItem }: RenderListProps<T>) => {
  if (items.length === 0) {
    return <List dataSource={items} className={styles.emptyList} />;
  }

  return (
    <div className={styles.container}>
      <List
        grid={{ gutter: 16, column: 1 }}
        dataSource={items}
        renderItem={renderItem}
      />
    </div>
  );
};
