import { ExportDestination } from "../types";
import { getNextButtonText } from "./getNextButtonText";

export const getNextButtonTitle = (
  exportDestination: ExportDestination | null,
  selectedAssetBatches: string[],
  currentStep: number,
) => {
  const isAssetBatchSelected = selectedAssetBatches.length > 0;

  if (!isAssetBatchSelected) return "Please select at least one asset batch";
  return getNextButtonText(exportDestination, currentStep);
};
