import {
  DownOutlined,
  PlusCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Menu,
  message,
  Modal,
  Popover,
  Switch,
  Tooltip,
} from "antd";
import modal from "antd/lib/modal";
import { useContext, useState } from "react";
import { IConfigurationState } from "shared/types/configuration";

import { context } from "../../Editor.context";
import { fabric } from "fabric";

import styles from "./Toolbar.module.scss";
import InsertButtonMenus from "./toolbar/InsertButtonMenus";
import StampSelection from "./toolbar/StampSelection";
import RenderTemplateProviderV2 from "shared/components/contextAPI/shared/RenderTemplate";
import RenderTemplateProvider from "shared/components/RenderTemplateProvider";
import Preview from "./toolbar/Preview";
import {
  AlignLayersActionType,
  customFabricAttributes,
  isStamp,
  isTemplate,
} from "shared/types/designStudio";
import { preProcessCanvasJson } from "../CanvasContainer";

export type InsertMenu =
  | "Stamp"
  | "Disclosure"
  | "Text"
  | "Image"
  | "Car cut"
  | "Lifestyle"
  | "Video"
  | "Theme BG"
  | "Canvas BG"
  | "Shapes"
  | "Media"
  | "Background";

interface Props {
  config: IConfigurationState["config"];
  canvasJson?: any; // This canvasJson is updated whenever there is edit on canvas. Different than canvasJson in Editor.context at the time of writing...
}

type ToolbarProps = Props;
/**
 * For Stamp Editor
 *  - Insert Text
 *  - Undo
 *  - Redo
 *  - Guidelines
 *  - Preview
 *
 */
const Toolbar = (props: ToolbarProps) => {
  const editorContext = useContext(context);

  const [showInsertMenuPopover, toggleInsertMenuPopover] =
    useState<boolean>(false);

  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const [canvas, setCanvas] = useState<fabric.Canvas>();

  return (
    <div className={styles.ToolbarContainer}>
      <div className={styles.ButtonContainer}>
        <Popover
          className={styles.insertMenuPopover}
          visible={showInsertMenuPopover}
          placement="bottomLeft"
          trigger="click"
          onVisibleChange={visible => {
            toggleInsertMenuPopover(visible);
          }}
          content={
            <InsertButtonMenus
              showInsertMenuPopover={showInsertMenuPopover}
              toggleInsertMenuPopover={show => {
                toggleInsertMenuPopover(show);
              }}
              config={props.config}
            />
          }
        >
          <Button
            data-cy="insert-button"
            className={styles.insertButton}
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={() => {
              toggleInsertMenuPopover(true);
            }}
          >
            Insert
          </Button>
        </Popover>

        <Button.Group>
          <Button
            data-cy="redo-button"
            disabled={
              !editorContext?.canvasHistory.history ||
              editorContext.canvasHistory.history.length - 1 ===
                editorContext.canvasHistory.historyIndex
            }
            onClick={e => {
              e.preventDefault();

              editorContext?.redo();
            }}
          >
            Redo
          </Button>
          <Button
            data-cy="undo-button"
            disabled={
              !editorContext?.canvasHistory.history ||
              editorContext.canvasHistory.historyIndex <= 0
            }
            onClick={e => {
              e.preventDefault();

              editorContext?.undo();
            }}
          >
            Undo
          </Button>
        </Button.Group>
        <Button.Group>
          <Button
            disabled={editorContext?.loading}
            onClick={() => {
              editorContext?.setCopyAction({
                ...(editorContext.copyAction || {}),
                type: "duplicate",
                toggle: !editorContext.copyAction?.toggle,
              });
            }}
          >
            Duplicate
          </Button>
          <Button
            disabled={editorContext?.loading}
            onClick={e => {
              e.preventDefault();

              editorContext?.setCopyAction({
                ...(editorContext.copyAction || {}),
                type: "copy",
                toggle: !editorContext.copyAction?.toggle,
              });
            }}
          >
            Copy
          </Button>
          <Button
            disabled={editorContext?.loading}
            onClick={e => {
              e.preventDefault();

              editorContext?.setCopyAction({
                ...(editorContext.copyAction || {}),
                type: "paste",
                toggle: !editorContext.copyAction?.toggle,
              });
            }}
          >
            Paste
          </Button>
        </Button.Group>
        <Button.Group>
          <Dropdown
            trigger={["click"]}
            overlay={
              <Menu
                onClick={({ key }) => {
                  editorContext?.setAlign(
                    key.toString().toLowerCase() as AlignLayersActionType,
                  );
                }}
              >
                {["Left", "Center", "Right"].map(align => (
                  <Menu.Item key={align}>{align}</Menu.Item>
                ))}
                <Menu.Divider />
                {["Top", "Middle", "Bottom"].map(align => (
                  <Menu.Item key={align}>{align}</Menu.Item>
                ))}
              </Menu>
            }
          >
            <Button>
              Align <DownOutlined />
            </Button>
          </Dropdown>
        </Button.Group>

        <div className={styles.SwitchContainer}>
          <span>Grid</span>
          <Switch
            checked={editorContext?.showGrid}
            onChange={checked => {
              editorContext?.toggleGrid(checked);
            }}
          />
        </div>

        {(props.config?.env === "dev" || props.config?.env === "local") && (
          <Tooltip title="This button is for automated tests only.">
            <Button
              data-cy="to-json-button"
              onClick={() => {
                modal.info({
                  className: "to-json-modal",
                  title: "Canvas JSON",
                  content: JSON.stringify(
                    editorContext?.updatedCanvasJson ||
                      editorContext?.canvasJson,
                  ),
                });
              }}
            >
              ToJSON
            </Button>
          </Tooltip>
        )}
      </div>

      <Button
        className={styles.PreviewButton}
        onClick={() => {
          const { history, historyIndex } = editorContext?.canvasHistory || {
            historyIndex: -1,
          };
          if (!history) {
            message.error("It looks like there was a system error.");
            return;
          }

          const canvasJson = history[historyIndex!];

          if (!canvasJson) return;

          const canvasElement = document.createElement(
            "canvas",
          ) as HTMLCanvasElement;
          const canvas = new fabric.Canvas(canvasElement);

          canvas.loadFromJSON(preProcessCanvasJson(canvasJson), () => {
            setCanvas(canvas);
            setOpenPreview(true);
          });
        }}
      >
        Preview
      </Button>

      <Modal
        className={styles.StampListModal}
        visible={editorContext?.showStampListModalInToolbar}
        title={
          <div>
            <span style={{ color: "black" }}>Stamps</span>
            <ReloadOutlined
              className={styles.ReloadIcon}
              spin={editorContext?.isFetchingStampsForInsert}
              onClick={e => {
                e.preventDefault();

                editorContext?.fetchStampsByOEM({
                  force: true,
                });
              }}
            />
          </div>
        }
        footer={null}
        onCancel={() => {
          editorContext?.toggleStampListModalInToolbar(false);
        }}
      >
        <StampSelection
          onStampSelected={() => {
            editorContext?.toggleStampListModalInToolbar(false);
          }}
        />
      </Modal>

      {openPreview && canvas && isTemplate(editorContext?.resource) && (
        <RenderTemplateProviderV2 config={props.config}>
          <Preview
            canvas={canvas}
            resource={editorContext?.resource}
            openPreview={openPreview}
            onPreviewClose={() => {
              setOpenPreview(false);
            }}
            isDarkBackground={false}
            customAttributes={customFabricAttributes}
            variableAlias={{}}
            disablePlayButton={true}
          />
        </RenderTemplateProviderV2>
      )}

      {openPreview && canvas && isStamp(editorContext?.resource) && (
        <RenderTemplateProvider>
          <Preview
            canvas={canvas}
            resource={editorContext?.resource}
            openPreview={openPreview}
            onPreviewClose={() => {
              setOpenPreview(false);
            }}
            isDarkBackground={false}
            customAttributes={customFabricAttributes}
            variableAlias={{}}
            disablePlayButton={true}
          />
        </RenderTemplateProvider>
      )}
    </div>
  );
};

export default Toolbar;
