import { Typography, List } from "antd";
import { Template } from "shared/types/salesEnablement";
import styles from "./ListItem.module.scss";
import { Thumbnail } from "./Thumbnail";

type TemplateListItemProps = {
  item: Template;
  onEdit: (item: Template) => void;
  showExpirationDate: boolean;
};

export const TemplateListItem = ({
  item,
  onEdit,
  showExpirationDate = false,
}: TemplateListItemProps) => {
  const { Text } = Typography;
  const { en, es } = item.files;
  const thumbnail = en?.thumbnail || es?.thumbnail;

  const formatDate = (dateString: string) =>
    new Date(dateString ?? "").toLocaleString("en-US", {
      timeZone: "America/New_York",
    });

  return (
    <List.Item title={item.name}>
      <div className={styles.thumbnailContainer}>
        <Thumbnail thumbnail={thumbnail ?? ""} />
      </div>
      <div className={styles.itemDetails}>
        <div className={styles.itemCell}>
          <Text className={styles.itemName} onClick={() => onEdit(item)}>
            {item.name}
          </Text>
          {showExpirationDate && (
            <Text className={styles.itemExpiration}>
              Expires on {formatDate(item.expirationDate || "")}
            </Text>
          )}
        </div>
      </div>
    </List.Item>
  );
};
