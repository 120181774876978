/**
 * Converts a JSX element to a string representation without HTML tags.
 *
 * @param element - The JSX element to convert.
 * @return The string representation of the JSX element.
 */
export const jsxToString = (element: JSX.Element): string => {
  if (!element) {
    return "";
  }

  if (typeof element === "string") {
    return element;
  }

  if (typeof element === "number") {
    return String(element);
  }

  if (Array.isArray(element)) {
    return element.map(subElement => jsxToString(subElement)).join("");
  }

  if (element.props && element.props.children) {
    return jsxToString(element.props.children);
  }

  if (element.props && !element.props.children) {
    return "";
  }

  return "";
};
