import { useEffect, useRef } from "react";
import { prefetch } from "remotion";
import { CompositionType } from "shared/types/assetExporter";
import { checkIfItIsVideoSrc } from "utils/helpers.adEngine";
import { getVideoData } from "../components/Preview/utils";
import { useAssetBatchesContext } from "../contexts/AssetBatchesContext";
import { useAssetBatchesValueMappingContext } from "../contexts/AssetBatchesValueMappingContext";
import { useVideoStitchingContext } from "../contexts/VideoStitchingContext";

type PrefetchFunction = {
  free: () => void;
  done: Promise<string>;
};

const usePrefetchVideoData = () => {
  const { selectedRow } = useAssetBatchesValueMappingContext();
  const { compositions } = useAssetBatchesContext();
  const { setIsLoading, videoAudios } = useVideoStitchingContext();

  const freeFunctions = useRef<PrefetchFunction[]>([]);

  useEffect(() => {
    let isMounted = true;
    compositions.forEach(async composition => {
      if (composition.type === CompositionType.Media) {
        const src = composition.column
          ? selectedRow[composition.column]
          : composition?.url;
        const { isVideo, src: convertedSrc } = await checkIfItIsVideoSrc(src);
        if (isVideo) {
          setIsLoading(true);

          const { free, waitUntilDone } = prefetch(convertedSrc, {
            method: "base64",
            contentType: "video/mp4",
          });

          freeFunctions.current.push({ free, done: waitUntilDone() });

          waitUntilDone().then(() => {
            if (!isMounted) return;
            setIsLoading(false);
          });
        }
      } else if (composition.type === CompositionType.Template) {
        const videoAudio = videoAudios?.find(
          audio => audio.compositionId === composition.compositionId,
        );
        if (!videoAudio) return;
        const videos = getVideoData(videoAudio.canvas);

        setIsLoading(true);

        const prefetchPromises = videos.map(element => {
          if (!element.src) return Promise.resolve();
          const { free, waitUntilDone } = prefetch(element.src, {
            method: "base64",
            contentType: "audio/mp3",
          });

          freeFunctions.current.push({ free, done: waitUntilDone() });
          return waitUntilDone();
        });

        Promise.all(prefetchPromises).then(() => {
          if (!isMounted) return;
          setIsLoading(false);
        });
      }
    });

    const currentFreeFunctions = [...freeFunctions.current];

    return () => {
      isMounted = false;
      currentFreeFunctions.forEach(({ free, done }) => {
        done.finally(free);
      });
    };
  }, [compositions, selectedRow, setIsLoading, videoAudios]);

  return {};
};

export default usePrefetchVideoData;
