import { IAd } from "shared/types/adLibrary";
import { adAccount } from "./adAccount";
import { returnAdCreative } from "./adPreviewCreative";

import { getDefaultPropValue } from "./utils";
import { AdCard, AdFormat, AdPrototype, AdType, CallToAction } from "./types";
import { isEnvVarEquals, getEnvVar } from "utils/helpers";
import memoizee from "memoizee";
import API from "services";
import { notification } from "antd";
import {
  getAdThumbnail,
  getCardData,
} from "../adWizard/AdViewEdit/AdCarouselForm/utils";
import { MultiUploadSizing } from "shared/types/uploadManagement";
import { uploadVideoToFBV2 } from "utils/uploadMedia";

const getFacebookImageHashMemoized = memoizee(
  async (imageUrl: string, accountId: string) => {
    if (!imageUrl) return undefined;

    const { imageHash } = await API.services.adLibrary.uploadImageToFb(
      imageUrl,
      accountId,
    );

    return imageHash;
  },
  {
    promise: true,
    length: 2,
    maxAge: 1000 * 60 * 15,
  },
);

const getFacebookImageHash = async ({
  imageUrl,
  accountId,
}: {
  imageUrl: string;
  accountId: string;
}) => {
  try {
    return await getFacebookImageHashMemoized(imageUrl, accountId);
  } catch (_) {
    notification.error({
      message: "Error while uploading image to Facebook.",
    });
    return undefined;
  }
};

export const generateAdPreview = async (
  adFormat: AdFormat,
  adType: AdType,
  adPrototype: AdPrototype,
): Promise<string> => {
  const forceDummyData = isEnvVarEquals("CLIENT", "ihg");
  const formParameters = {
    forceDummyData,
    pageId: getDefaultPropValue("PAGEID"),
  };

  const previewCreative = {
    ...returnAdCreative({
      adType,
      adPrototype: {
        ...adPrototype,
        assetId: !forceDummyData ? adPrototype.assetId : "",
      },
      formParameters,
      isForLiveAd: true,
    }),
    instagram_actor_id: getDefaultPropValue("INSTAGRAMACTORID") || undefined,
  };

  const params = {
    creative: JSON.stringify(previewCreative),
    ad_format: adFormat,
  };
  const { data } = await adAccount.getGeneratePreviews(params);
  return data[0].body;
};

export const getAdPrototypeFromAd = async (
  ad: IAd,
  accountId: string,
  size: MultiUploadSizing = "IN_FEED",
  needToUploadVideoToFB = false, // Determine whether video needs to be uploaded to Facebook in case ads come from adExport.
): Promise<AdPrototype> => {
  const isDynamic = [AdType.AIA, AdType.DPA, AdType.FTA].includes(ad.type);

  const callToAction = [AdType.Collection, AdType.InstantExperience].includes(
    ad.type,
  )
    ? undefined
    : ad.visuals?.ctaButtonText || CallToAction.SHOP_NOW;

  const isCarousel = ad.type === AdType.Carousel;
  // If ad has no story data, fallback to in feed data for the preview
  const adThumbnail = getAdThumbnail(size, ad) ?? ad.visuals?.thumbnail;
  const imageHash =
    !isCarousel && !isDynamic && adThumbnail
      ? await getFacebookImageHash({
          imageUrl: adThumbnail,
          accountId: accountId,
        })
      : undefined;

  let assetId = ad.visuals?.assetId ?? "";
  if (needToUploadVideoToFB && !ad.visuals?.assetId && ad.visuals?.videoUrl) {
    const { assetId: assetIdGenerated } = await uploadVideoToFBV2(
      ad.visuals.videoUrl,
    );
    assetId = assetIdGenerated;
  }

  const cards = await Promise.all(
    ad.visuals?.cards?.map<Promise<AdCard>>(async card => {
      // If carousel has no story data, fallback to in feed data for the preview
      const tileData = getCardData(size, card) ?? card;
      const { assetId, ctaButtonText, ...rest } = card;

      const cardHash = await getFacebookImageHash({
        imageUrl: tileData?.thumbnail,
        accountId,
      });

      return {
        ...rest,
        asset_id: assetId,
        destination_url: tileData?.destinationUrl,
        callToAction: ctaButtonText,
        imageHash: cardHash || "",
        thumbnail: tileData?.thumbnail,
      };
    }) ?? [],
  );

  return {
    callToAction: callToAction,
    thumbnail: adThumbnail,
    imageHash: imageHash ?? "",
    message:
      ad.visuals?.postCopy ||
      ad.visuals?.primaryText ||
      "This is placeholder text for Copy",
    assetId,
    cards,
    title: ad.visuals?.headline || "This is placeholder text for Headline",
    utm: ad.inputParameters?.utm,
    footerText: ad.visuals?.headlineDescription,
    format: ad.visuals?.format,
    creativeOption: ad.visuals?.creativeOption,
    destinationUrl: ad.inputParameters?.destinationUrl,
    displayUrl: ad.visuals?.displayUrl,
    canvasUrl: ad.visuals.destination?.websiteURL?.includes(
      "https://fb.com/canvas_doc",
    )
      ? ad.visuals.destination?.websiteURL
      : getEnvVar("ADPREVIEW_URL"),
  };
};
