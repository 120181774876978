import { Alert } from "antd";
import styles from "./DisabledMarketingMaterialAlert.module.scss";

const DisabledMarketingMaterialAlert = ({
  className,
}: {
  className?: string;
}) => {
  return (
    <Alert
      type="info"
      showIcon
      className={`${styles.container} ${styles[className ?? "components"]}`}
      message={`Marketing Materials cannot be edited in the Archive Section.
                To use this Marketing Material, click on the button 'Use Marketing Material' on this drawer and re-deliver it.`}
    />
  );
};

export default DisabledMarketingMaterialAlert;
