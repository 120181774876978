import { Button, Drawer } from "antd";
import { useLogoSelectContext } from "shared/components/logoSelect/context/LogoSelectContext";
import { LogoSelectFilterTags } from "./LogoSelectFilterTags";
import { LogoSelectHeader } from "./LogoSelectHeader";
import { LogoSelectTable } from "./LogoSelectTable";
import { ButtonGroup } from "../media/ButtonGroup";
import { useDataList } from "../dataList";
import { BrandLogo } from "shared/types/brandsAccounts";
import styles from "./LogoSelectDrawer.module.scss";

export const LogoSelectDrawer = () => {
  const { showLogoSelectDrawer, setShowLogoSelectDrawer, onSelect } =
    useLogoSelectContext();
  const [dataList, dataListActions] = useDataList<BrandLogo>();

  const closeDrawer = () => {
    dataListActions.setSelectedItems([]);
    setShowLogoSelectDrawer(false);
  };

  const handleSave = () => {
    onSelect(dataList.selectedItems?.[0]);
    closeDrawer();
  };

  const disabled = dataList.selectedItems.length === 0;

  return (
    <Drawer
      title={<strong>Select logo from Brands and Accounts</strong>}
      onClose={closeDrawer}
      visible={showLogoSelectDrawer}
      width="calc(100vw - 48px)"
      className={styles.logoSelectDrawer}
      bodyStyle={{ backgroundColor: "#f5f5f5" }}
      footer={
        <ButtonGroup>
          <Button key="cancel" onClick={closeDrawer}>
            Close
          </Button>
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            disabled={disabled}
            onClick={handleSave}
          >
            Select
          </Button>
        </ButtonGroup>
      }
    >
      <LogoSelectHeader />
      <LogoSelectFilterTags />
      <LogoSelectTable />
    </Drawer>
  );
};
