import { useState } from "react";
import { Box } from "@mui/material";
import { useMetaSchema } from "shared/components/media/useMetaSchema";
import { MetaFormItems } from "./MetaFormItems";
import { Spin } from "antd";

type Props = {
  onChange: (values: MGFormValues) => void;
};

type FormValues = { [key: string]: string | string[] };

export const MetaForm = ({ onChange }: Props) => {
  const { schema } = useMetaSchema();
  const [formValues, setFormValues] = useState<FormValues>(() => {
    const initialValues: FormValues = {};
    if (schema) {
      schema.xmpschema.forEach(item => {
        initialValues[item.field] = item.type === "multiselect" ? [] : "";
      });
    }
    return initialValues;
  });

  const handleFieldChange = (field: string, value: string | string[]) => {
    const updatedValues = { ...formValues, [field]: value };
    setFormValues(updatedValues);
    onChange(updatedValues);
  };

  if (!schema) {
    return <Spin />;
  }

  return (
    <Box sx={{ "& .MuiFormControl-root": { m: 1, width: "100%" } }}>
      <MetaFormItems
        schema={schema}
        formValues={formValues}
        handleFieldChange={handleFieldChange}
      />
    </Box>
  );
};
