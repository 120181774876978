import { IdmlPreview } from "screens/designStudio/hooks/useFileData";
import styles from "./PDFPreview.module.scss";

const PDFPreview = ({ src, isError }: IdmlPreview) => {
  return (
    <div className={styles.container}>
      <div className={styles.canvasContainer}>
        {src && !isError && (
          <object
            className={styles.iframe}
            data={`${src}#toolbar=0&navpanes=0&view=FitH`}
          />
        )}
        {isError && (
          <div className={styles.error}>
            <h1>Unable to render PDF</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default PDFPreview;
