import { useQuery } from "react-query";

export const fetchImage = async (url: string) => {
  try {
    new URL(url);

    const response = await fetch(url);
    if (!response.ok) {
      throw new Error();
    }

    const contentType = response.headers.get("Content-Type") || "";
    if (!contentType.startsWith("image")) {
      throw new Error();
    }

    const blob = await response.blob();
    return URL.createObjectURL(blob);
  } catch (error) {
    return null;
  }
};

export const useImageURL = (value: string) => {
  return useQuery(
    ["imageUrl", value],
    async () => await fetchImage(value.toString()),
    {
      staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
};
