import { useCallback, useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import useDeepEffect from "shared/hooks/useDeepEffect";
import { fabric } from "fabric";
import { clone } from "utils/fabric/helpers.utils";
import {
  customFabricAttributes,
  IDimension,
  IExtendedFabricObject,
  isActiveSelection,
} from "shared/types/designStudio";
import { paste } from "utils/canvas/helpers.paste";
import uuid from "uuid/v4";
import { ICanvasData } from "shared/types/assetBuilder";
import { loadFontsFromJson } from "utils/fabric/helpers.text";

export type TCopyAction = {
  type: "copy" | "paste" | "duplicate";
  toggle: boolean;
};

export default (args: {
  canvas?: fabric.Canvas;
  copyAction?: TCopyAction;
  onComplete?: () => void;
}) => {
  const onPasteFromClipboard = (
    parsed: IExtendedFabricObject,
    canvas?: fabric.Canvas,
  ) => {
    canvas?.discardActiveObject();

    const canvasDimension: IDimension = {
      width: canvas?.width ?? 0,
      height: canvas?.height ?? 0,
    };
    const canvasAreaMargin = {
      top: canvasDimension.height < (parsed.top ?? 0) ? 0 : parsed.top ?? 0,
      left: canvasDimension.width < (parsed.left ?? 0) ? 0 : parsed.left ?? 0,
    };

    if (isActiveSelection(parsed)) {
      parsed._objects.forEach(customObj => {
        /* adjust coordinates for each child element
           - top: top value for entire canvas + top value for child element
                + height/2 to get center point (relative position)
           - left: ditto */

        const adjustedCanvasAreaMargin = {
          top:
            canvasAreaMargin.top +
            (customObj?.top ?? 0) +
            (parsed?.height || 0) / 2,
          left:
            canvasAreaMargin.left +
            (customObj?.left ?? 0) +
            (parsed?.width || 0) / 2,
        };
        const transformedCustomObj = customObj as IExtendedFabricObject;
        paste({
          canvasDimension,
          canvasAreaMargin: adjustedCanvasAreaMargin,
          pasteData: transformedCustomObj,
        }).then(obj => {
          if (!obj) return;
          canvas?.add(obj);
          canvas?.requestRenderAll();
        });
      });
    } else {
      paste({
        canvasDimension,
        canvasAreaMargin,
        pasteData: parsed,
      }).then(async obj => {
        if (!obj) return;

        canvas?.add(obj);
        canvas?.setActiveObject(obj);
        await loadFontsFromJson(canvas?.toJSON() as unknown as ICanvasData);
        canvas?.requestRenderAll();
      });
    }
  };

  const onPasteFromCloned = async (
    cloned: fabric.Object,
    canvas: fabric.Canvas,
  ) => {
    canvas?.discardActiveObject();

    if (isActiveSelection(cloned)) {
      cloned.canvas = canvas;
      cloned.forEachObject(obj => {
        obj.set({
          name: uuid(),
          top: (obj.top || 0) + 20,
          left: (obj.left || 0) + 20,
        });
        canvas?.add(obj);
      });
      cloned.setCoords();
    } else {
      cloned.set({
        name: uuid(),
        top: (cloned.top || 0) + 20,
        left: (cloned.left || 0) + 20,
      });

      if (copiedObjectRef.current) {
        copiedObjectRef.current.top = copiedObjectRef.current.top
          ? copiedObjectRef.current.top + 20
          : 20;
        copiedObjectRef.current.left = copiedObjectRef.current.left
          ? copiedObjectRef.current.left + 20
          : 20;
      }
      canvas?.add(cloned);
    }

    canvas?.setActiveObject(cloned);
    await loadFontsFromJson(canvas?.toJSON() as unknown as ICanvasData);
    canvas?.requestRenderAll();
  };

  const onPaste = useCallback(
    async (copiedObject?: fabric.Object, canvas?: fabric.Canvas) => {
      const isSameCanvas: boolean = false;
      try {
        const jsonText = await navigator.clipboard.readText();
        const parsed: IExtendedFabricObject = JSON.parse(
          jsonText,
        ) as IExtendedFabricObject;
        const isVideo: boolean = parsed.customType === "selected_video";

        if (!isSameCanvas || isVideo) {
          return onPasteFromClipboard(parsed, canvas);
        }
      } catch (e) {}

      if (!isSameCanvas || !copiedObject || !canvas) return;

      const cloned = await clone(copiedObject);
      await onPasteFromCloned(cloned, canvas);
      args.onComplete?.();
    },
    [args],
  );

  const copiedObjectRef = useRef<fabric.Object>();
  const getCopiedObjects = useCallback((canvas?: fabric.Canvas) => {
    const object = canvas?.getActiveObject();
    if (!object) return;

    return clone(object);
  }, []);

  const onCopy = useCallback(async () => {
    const orgObject = args.canvas?.getActiveObject();
    copiedObjectRef.current = await getCopiedObjects(args.canvas);
    if (!orgObject || !copiedObjectRef.current) return;
    try {
      if (isActiveSelection(orgObject)) {
        const activeSelectionObj = orgObject.toJSON(
          Object.keys(copiedObjectRef.current),
        );
        const customObjs = orgObject._objects.map(obj => {
          return obj.toJSON(customFabricAttributes);
        });
        activeSelectionObj._objects = customObjs;

        navigator.clipboard.writeText(JSON.stringify(activeSelectionObj));
      } else {
        navigator.clipboard.writeText(
          JSON.stringify(orgObject.toJSON(customFabricAttributes)),
        );
      }
    } catch {}
  }, [args.canvas, getCopiedObjects]);

  const onDuplicate = useCallback(async () => {
    if (!args.canvas) return;
    const activeObject = args.canvas?.getActiveObject();
    if (!activeObject) return;

    const cloned = await clone(activeObject);
    if (!cloned) return;

    onPasteFromCloned(cloned, args.canvas);
  }, [args.canvas]);

  useDeepEffect(() => {
    if (!args.copyAction) return;

    switch (args.copyAction.type) {
      case "copy":
        onCopy();
        break;

      case "paste":
        // We have to keep clone the copied object otherwise,
        //  we gonna have multiple objects with same name.

        onPaste(copiedObjectRef.current, args.canvas);
        break;
      case "duplicate":
        onDuplicate();
    }
  }, [args.copyAction]);

  useHotkeys(
    "ctrl+c,cmd+c",
    () => {
      onCopy();
    },
    undefined,
    [args.canvas],
  );

  useHotkeys(
    "ctrl+x,cmd+x",
    () => {
      getCopiedObjects(args.canvas)?.then(copied => {
        copiedObjectRef.current = copied;

        // delete current object
        const activeObject = args.canvas?.getActiveObject();
        if (!activeObject) return;

        if (isActiveSelection(activeObject)) {
          activeObject.forEachObject(obj => {
            args.canvas?.remove(obj);
          });

          args.canvas?.discardActiveObject();
        } else {
          args.canvas?.remove(activeObject);
        }
      });
    },
    undefined,
    [args.canvas],
  );

  useHotkeys(
    "ctrl+v,cmd+v",
    () => {
      // We have to keep clone the copied object otherwise,
      //  we gonna have multiple objects with same name.
      onPaste(copiedObjectRef.current, args.canvas);
    },
    undefined,
    [args.canvas],
  );
};
