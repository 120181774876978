import React, { useState } from "react";
import { Menu, Stack } from "@mui/material";
import { observer } from "mobx-react-lite";
import SwapHorizontalCircleRoundedIcon from "@mui/icons-material/SwapHorizontalCircleRounded";
import SwapVerticalCircleRoundedIcon from "@mui/icons-material/SwapVerticalCircleRounded";
import { ButtonContainer } from "../containers/ButtonContainer";
import useMediaElementEffects from "screens/designStudio/designStudioV2/hooks/useMediaElementEffects";
import { ImageLayerElement } from "screens/designStudio/designStudioV2/types";
import { menuStyles } from "../containers/EffectsMenu";

type FontEffectsControlProps = {
  element: ImageLayerElement;
};
const ImageFlip = observer(({ element }: FontEffectsControlProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { toggleFlipX, toggleFlipY } = useMediaElementEffects(element);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ButtonContainer
        size="small"
        onClick={handleClick}
        sx={{
          minWidth: 2,
        }}
      >
        Flip
      </ButtonContainer>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={menuStyles}
      >
        <Stack direction="column" mx={1}>
          <ButtonContainer
            startIcon={
              <SwapHorizontalCircleRoundedIcon
                fontSize="small"
                color="action"
              />
            }
            onClick={() => toggleFlipX()}
            sx={{ justifyContent: "flex-start", width: "100%" }}
          >
            Flip Horizontally
          </ButtonContainer>
          <ButtonContainer
            startIcon={
              <SwapVerticalCircleRoundedIcon fontSize="small" color="action" />
            }
            onClick={() => toggleFlipY()}
            sx={{ justifyContent: "flex-start", width: "100%" }}
          >
            Flip Veritcally
          </ButtonContainer>
        </Stack>
      </Menu>
    </>
  );
});

export default ImageFlip;
