import { DeleteOutlined, FormOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import { useMediaActions } from "redux/media/media.slice";
import { useFetchPerms } from "../useFetchPerms";
import { useDeleteFolder } from "./useDeleteFolder";

export const useMenuFolder = (record: WDFolder, visible: boolean) => {
  const { setEditableRow } = useMediaActions();
  const { canRename, canDelete } = useFetchPerms([record.id], visible);

  return (
    <Menu>
      <Menu.Item
        icon={<FormOutlined />}
        disabled={!canRename}
        key="rename"
        onClick={() => {
          setEditableRow(record);
        }}
      >
        Rename
      </Menu.Item>
      <Menu.Item
        disabled={!canDelete}
        key="delete"
        icon={<DeleteOutlined />}
        onClick={useDeleteFolder(record)}
      >
        Delete folder
      </Menu.Item>
    </Menu>
  );
};
