import { TableActionsContextProvider } from "shared/components/HoverActionsVirtualTable/TableActionsContext";
import { AgentProfile } from "screens/adLibrary/marketingMaterialDrawer/hooks/agentManagement";
import { ContextTableActions } from "shared/components/tableActions/ContextTableActions";
import { InlineTableActions } from "shared/components/tableActions/InlineTableActions";
import DataListURLTable from "shared/components/dataListURL/DataListURLTable";
import { AgentProfileKeys, fieldKeys } from "./fields";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { useColumns } from "./useColumns";
import { useAgentProfiles } from "shared/hooks/salesEnablement/useAgentProfiles";
import { useToolbarContents } from "./agentProfileDrawer/useToolbarContents";

const AgentProfilesTable = () => {
  const { windowInnerHeight } = useWindowSize();
  const columns = useColumns();
  const { data: filteredAgentProfiles, isLoading } = useAgentProfiles();

  const generateToolbarContents = useToolbarContents();

  return (
    <TableActionsContextProvider<AgentProfile>
      renderContextActions={record => (
        <ContextTableActions
          getItemsToRender={generateToolbarContents}
          record={record}
        />
      )}
      renderHoverActions={record => (
        <InlineTableActions
          getItemsToRender={generateToolbarContents}
          record={record}
        />
      )}
    >
      <DataListURLTable<AgentProfileKeys, AgentProfile>
        loading={isLoading}
        dataSource={filteredAgentProfiles}
        columns={columns}
        pagination={false}
        size="small"
        scroll={{ y: windowInnerHeight - 245 }}
        type="hoverActionsVirtualTable"
        fieldKeys={fieldKeys}
      />
    </TableActionsContextProvider>
  );
};

export default AgentProfilesTable;
