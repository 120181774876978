import { StoreType } from "polotno/model/store";
import {
  LayerElement,
  SVGLayerElement,
  ImageLayerElement,
  TextLayerElement,
  GroupLayerElement,
  FigureLayerElement,
} from "screens/designStudio/designStudioV2/types";
import { splitIntoComponents } from "../../editorDrawerContext/helpers.variables";

export const textElementSelected = (
  selectedElements: LayerElement[],
): selectedElements is TextLayerElement[] => {
  return selectedElements.length > 0 && selectedElements.every(isTextElement);
};

export const mediaElementSelected = (selectedElements: LayerElement[]) => {
  return (
    selectedElements.length === 1 &&
    (selectedElements[0].type === "image" || selectedElements[0].type === "svg")
  );
};

export const figureElementSelected = (
  selectedElements: LayerElement[],
): selectedElements is FigureLayerElement[] => {
  return (
    selectedElements.length > 0 &&
    selectedElements.every(elem => elem.type === "figure")
  );
};

export const layerElementUnlocked = (store: StoreType) => {
  return store.selectedElements[0]?.contentEditable;
};

export const layerCropModeEnabled = (store: StoreType) => {
  return store.selectedElements[0]?._cropModeEnabled;
};

export const getSelectedElements = (store: StoreType): LayerElement[] => {
  return store.selectedElements;
};

export const isImageElement = (
  element?: LayerElement,
): element is ImageLayerElement => element?.type === "image";

export const isSVGElement = (
  element?: LayerElement,
): element is SVGLayerElement => element?.type === "svg";

export const isTextElement = (
  element?: LayerElement,
): element is TextLayerElement => element?.type === "text";

export const isGroupElement = (
  element?: LayerElement,
): element is GroupLayerElement => element?.type === "group";

// Text layers with multiple components are displayed like a group on the layers panel
export const isGroupTextLayer = (layer: LayerElement) =>
  layer.type === "text" && splitIntoComponents(layer.text).length > 1;

export const localFileToDataURL = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      typeof reader.result === "string"
        ? resolve(reader.result)
        : reject("No result");
    reader.onerror = error => reject(error);
  });
};
