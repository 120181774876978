import { useQuery } from "react-query";
import API from "services";
import { Feature } from "screens/designStudio/DrawerContext/useFilterPresets";
import { useUser } from "../useUser";

export const useFetchFilterPresets = (feature: Feature) => {
  const { sub: userId } = useUser();
  return useQuery(["filter-presets", feature], () =>
    fetchFilterPresets(feature, userId),
  );
};

const fetchFilterPresets = async (feature: Feature, userId: string) => {
  const { result, error } = await API.services.designStudio.fetchFilterPresets(
    feature,
    userId,
  );

  if (error) {
    throw error;
  }

  return result?.presets;
};
