import { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { KeywordChip } from "./KeywordChip";

type Option = {
  value: string;
  label: string;
};

type MetaKeywordSelectProps = {
  options?: Option[];
  handleFieldChange: (field: string, value: string[]) => void;
  mode?: "tags" | "multiple";
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const MetaKeywordSelect = ({
  options: keywords,
  handleFieldChange,
}: MetaKeywordSelectProps) => {
  const [keywordOptions, setKeywordOptions] = useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof keywordOptions>) => {
    const {
      target: { value },
    } = event;
    const newKeywords = typeof value === "string" ? value.split(",") : value;
    setKeywordOptions(newKeywords);
    handleFieldChange(
      "keyword",
      Array.isArray(newKeywords) ? newKeywords : [newKeywords],
    );
  };

  const handleDelete = (chipToDelete: string) => () => {
    const newKeywords = keywordOptions.filter(
      keyword => keyword !== chipToDelete,
    );
    setKeywordOptions(newKeywords);
    handleFieldChange("keyword", newKeywords);
  };

  return (
    <FormControl>
      <InputLabel id="keywords-label">Keywords</InputLabel>
      <Select
        fullWidth
        multiple
        labelId="keywords-label"
        label="Keywords"
        value={keywordOptions}
        onChange={handleChange}
        renderValue={selected => (
          <KeywordChip selected={selected} handleDelete={handleDelete} />
        )}
        MenuProps={MenuProps}
      >
        {keywords?.map(({ value, label }: Option) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
