import { Space, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "screens/beeswaxCampaigns/constants";
import { useFilteredDataSource } from "../hooks/useFilteredDataSource";
import { useLoadBeeswaxCampaigns } from "../hooks/useLoadBeeswaxCampaigns";
import { useLoadCampaignsContext } from "../LoadProvider";
import { transformLoadDataSourceToLoadObjects } from "screens/beeswaxCampaigns/helpers";

const Footer = () => {
  const navigate = useNavigate();
  const { selectedCampaigns, setSession } = useLoadCampaignsContext();
  const { selectedItems } = useFilteredDataSource();
  const { mutate: loadCampaigns, isLoading } = useLoadBeeswaxCampaigns({
    onSuccess: response => {
      if (!response.result) throw new Error("No response");

      setSession(response.result);
      navigate(ROUTES.resultStep);
    },
  });

  const closeDrawer = () => {
    navigate(ROUTES.home);
  };

  const onLoad = () => {
    loadCampaigns(
      transformLoadDataSourceToLoadObjects(selectedItems, selectedCampaigns),
    );
  };

  const hasValidCampaigns =
    selectedItems.filter(item => !!item.accountId).length > 0;

  return (
    <Space>
      <Button onClick={closeDrawer}>Cancel</Button>
      <Button
        onClick={onLoad}
        type="primary"
        disabled={!hasValidCampaigns}
        loading={isLoading}
      >
        Load to Beeswax
      </Button>
    </Space>
  );
};

export default Footer;
