import { Tooltip } from "antd";
import { AntdIconProps } from "@ant-design/icons/lib/components/AntdIcon";
import styles from "./EditCell.module.scss";

interface ActionIconProps {
  icon: React.ForwardRefExoticComponent<
    AntdIconProps & React.RefAttributes<HTMLSpanElement>
  >;
  tooltipTitle?: string;
  onClick: () => void;
  visible?: boolean;
}

export const ActionIcon: React.FC<ActionIconProps> = ({
  icon: Icon,
  tooltipTitle,
  onClick,
  visible = true,
}) => {
  return (
    <>
      {visible && (
        <Tooltip title={tooltipTitle}>
          <Icon
            onClick={e => {
              onClick();
              e.stopPropagation();
            }}
            className={styles.icon}
          />
        </Tooltip>
      )}
    </>
  );
};
