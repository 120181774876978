import { useState } from "react";
import { LayerElement } from "screens/designStudio/designStudioV2/types";
import { isGroupTextLayer, isGroupElement } from "../../../../utils";

export const useLayerCollapse = (layer: LayerElement) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  const showDropdown = isGroupTextLayer(layer) || isGroupElement(layer);

  return {
    showDropdown,
    isCollapsed,
    toggleCollapse,
  };
};
