import { useLayoutEffect } from "react";
import { ZoomControl } from "screens/assetExporter/feedConfigurationV2/shared/components/Zoom/ZoomControl";

interface Props {
  isFitToScreen: boolean;
  setIsFitToScreen: React.Dispatch<React.SetStateAction<boolean>>;
  zoomPercentage: number;
  setZoomPercentage: React.Dispatch<React.SetStateAction<number>>;
  setIsZoomed: React.Dispatch<React.SetStateAction<boolean>>;
  fitToScreenDimensions: {
    height: number;
    width: number;
    scrollHeight: number;
  };
}

export const PanelTemplateZoom = ({
  isFitToScreen,
  setIsFitToScreen,
  zoomPercentage,
  setIsZoomed,
  setZoomPercentage,
  fitToScreenDimensions,
}: Props) => {
  const handleZoomInOut = () => {
    if (isFitToScreen) setIsFitToScreen(false);
    if (zoomPercentage !== 100) setIsZoomed(true);
    else setIsZoomed(false);
  };

  useLayoutEffect(() => {
    if (!fitToScreenDimensions.height) return;

    if (isFitToScreen) {
      const newZoomPercentage =
        Math.ceil(
          ((fitToScreenDimensions.height / fitToScreenDimensions.scrollHeight) *
            100) /
            5,
        ) * 5;

      if (newZoomPercentage > 0) setZoomPercentage(newZoomPercentage);
    }
  }, [
    isFitToScreen,
    fitToScreenDimensions.height,
    setZoomPercentage,
    fitToScreenDimensions.scrollHeight,
  ]);

  return (
    <ZoomControl
      zoomLevel={zoomPercentage}
      setZoomLevel={val => {
        setZoomPercentage(val);
      }}
      isFitToScreen={isFitToScreen}
      toggleFitToScreen={() => {
        if (isFitToScreen) setZoomPercentage(100);
        setIsFitToScreen(!isFitToScreen);
      }}
      onZoomIn={handleZoomInOut}
      onZoomOut={handleZoomInOut}
    />
  );
};
