import {
  NormalizedDisclosure,
  NormalizedDisclosureKey,
} from "shared/types/salesEnablement";
import { joinArray } from "utils/helpers.array";
import { compareStringBy, onFilterBy } from "utils/helpers.table";

const normalizeStringInput = (
  input: NormalizedDisclosure[NormalizedDisclosureKey],
): string => {
  if (Array.isArray(input)) {
    return joinArray(input);
  }
  return input ?? "";
};

export const onFilter = (fieldKey: NormalizedDisclosureKey) => {
  return onFilterBy(
    (record: NormalizedDisclosure) => normalizeStringInput(record[fieldKey]),
    {
      matchMode: "includes",
      caseInsensitive: true,
    },
  );
};

export const onFilterProduct = (
  values: string[],
  record: NormalizedDisclosure,
) => {
  // Product filter value have the format: productId/productName
  const productsIds = values.map(value => value.split("/")[0]);
  return productsIds.some(productId =>
    record.products.some(product => product.id === productId),
  );
};

export const onSort = (fieldKey: NormalizedDisclosureKey) =>
  compareStringBy((record: NormalizedDisclosure) =>
    normalizeStringInput(record[fieldKey]),
  );

type TableArrayFields = "productTypes" | "availableLanguages" | "locations";
export const onFilterArray =
  (fieldKey: TableArrayFields) =>
  (
    filterValue: string[],
    record: Pick<NormalizedDisclosure, TableArrayFields>,
  ) =>
    record[fieldKey]?.some((value: string) => filterValue.includes(value));

export const displayProductName = (value: string) => {
  // Getting second part of the string (productName)
  return value.split("/")[1];
};
