import { deliveryMethods } from "screens/designStudio/utils";
import { StringParam } from "serialize-query-params";
import { Field } from "shared/components/dataListURL/types";

import { MarketingMaterialTableItem } from "shared/types/marketingMaterials";
import type { Language } from "shared/types/salesEnablement";
import { DelimitedArrayParam } from "use-query-params";
import { checkDateMatch, formatDateValue } from "utils/helpers";
import { compareStringBy, onFilterBy } from "utils/helpers.table";
import { customizableMap } from "screens/designStudio/templates/fileDrawer/FormFields.schema";
import {
  Languages,
  audienceFilterOptions,
} from "utils/helpers.salesEnablement";

export type MarketingMaterialField = Field<MarketingMaterialTableItem>;

export type MarketingMaterialKey = keyof MarketingMaterialTableItem;
type Fields = Partial<Record<MarketingMaterialKey, MarketingMaterialField>>;

export type MarketingMaterialsFields = keyof Fields;

const getDateFieldConfig = (
  fieldName: "updatedAt" | "createdAt" | "templateExpirationDate",
) => ({
  sorterFn: compareStringBy(fieldName),
  filterFn: (value: string | string[], record: MarketingMaterialTableItem) => {
    return checkDateMatch(
      value.toString(),
      new Date(record[fieldName] ?? "").getTime(),
    );
  },
  queryParamConfigFilter: StringParam,
  display: (value: string) => value.split(" ").map(formatDateValue).join(" - "),
});

export const marketingMaterialFields: Fields = {
  name: {
    filterFn: onFilterBy("name", {
      matchMode: "includes",
      caseInsensitive: true,
    }),
    sorterFn: compareStringBy("name"),
    queryParamConfigFilter: StringParam,
  },
  createdAt: getDateFieldConfig("createdAt"),
  updatedAt: {
    ...getDateFieldConfig("updatedAt"),
    defaultSortOrder: "descend",
  },
  templateExpirationDate: getDateFieldConfig("templateExpirationDate"),
  templateDeliveryMethods: {
    filterFn: onFilterBy("templateDeliveryMethods"),
    queryParamConfigFilter: DelimitedArrayParam,
    display: (method: string) =>
      deliveryMethods.find(m => m.value === method)?.label ?? method,
  },
  templateCustomizable: {
    filterFn: onFilterBy(material =>
      material.templateCustomizable ? "customizable" : "nonCustomizable",
    ),
    queryParamConfigFilter: DelimitedArrayParam,
    display: (customizable: keyof typeof customizableMap) =>
      customizableMap[customizable],
  },
  templateAudience: {
    filterFn: onFilterBy("templateAudience"),
    queryParamConfigFilter: DelimitedArrayParam,
    display: (audience: string) =>
      audienceFilterOptions.find(item => item.value === audience)?.label ??
      audience,
  },
  locations: {
    filterFn: onFilterBy("locations"),
    queryParamConfigFilter: DelimitedArrayParam,
  },
  language: {
    filterFn: onFilterBy("language"),
    queryParamConfigFilter: StringParam,
    display: (language: Language) => Languages[language].label,
  },
  templateMaterialType: {
    filterFn: onFilterBy("templateMaterialType"),
    queryParamConfigFilter: StringParam,
  },
  account: {
    filterFn: onFilterBy(material => material?.account, {
      matchMode: "includes",
    }),
    queryParamConfigFilter: StringParam,
  },
  products: {
    filterFn: onFilterBy("products"),
    queryParamConfigFilter: StringParam,
  },
};

export const marketingMaterialFieldsKeys = Object.keys(
  marketingMaterialFields,
) as Array<keyof typeof marketingMaterialFields>;
