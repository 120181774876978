import { Space, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "screens/beeswaxCampaigns/constants";
import { useLoadCampaignsContext } from "../LoadProvider";
import { useBeeswaxCampaignsLoadingStatus } from "../hooks/useBeeswaxCampaignsLoadingStatus";

const Footer = () => {
  const { setSession, session } = useLoadCampaignsContext();
  const { data: statuses } = useBeeswaxCampaignsLoadingStatus(session);
  const navigate = useNavigate();

  const closeDrawer = () => {
    setSession(undefined);
    navigate(ROUTES.home);
  };

  const isLoadInProgress = statuses?.some(
    status => status.status === "loading",
  );

  return (
    <Space>
      <Button onClick={closeDrawer}>Cancel</Button>
      <Button onClick={closeDrawer} type="primary" loading={isLoadInProgress}>
        Done
      </Button>
    </Space>
  );
};

export default Footer;
