import { DataListURLProvider } from "shared/components/dataListURL/dataListURLContext";
import { fields } from "./disclosures/fields";
import styles from "./disclosures/Disclosures.module.scss";
import Table from "./disclosures/Table";
import { Outlet, useParams } from "react-router-dom";
import { DisclosureDrawer } from "./disclosures/DisclosureDrawer";

export const Disclosures = () => {
  const { disclosureId } = useParams();

  return (
    <DataListURLProvider fields={fields}>
      <div className={styles.container}>
        <Table />
      </div>
      <Outlet />
      <DisclosureDrawer
        isVisible={!!disclosureId}
        disclosureId={disclosureId !== "new" ? disclosureId : undefined}
      />
    </DataListURLProvider>
  );
};
