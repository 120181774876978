import { Button, Checkbox, Collapse, Select, Tag, Input } from "antd";
import { ChangeEvent, Dispatch, FC, SetStateAction } from "react";

import { connect } from "react-redux";
import {
  checkedOfferFilterKeys,
  ICheckedOfferFilter,
  IAssetBuilderState,
  ISortingOption,
  VehicleConditions,
  SingletonOfferKeys,
} from "shared/types/assetBuilder";
import actions from "../../../redux/rootActions";

import "./OfferFilter.scss";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { TSortByKey } from "./select/OfferFilterV2";

interface IOfferFilter {
  checkedOfferFilters: ICheckedOfferFilter;
  updateCheckedOfferFilters: (checkedOfferFilters: { title: string }) => void;
  vehicleConditionFilter: VehicleConditions;
  setFilterBy: Dispatch<SetStateAction<VehicleConditions>>;
  sortingOptions: ISortingOption[];
  setSortingOptions: Dispatch<SetStateAction<ISortingOption[]>>;
  filterBy: VehicleConditions;
  filterField: string;
  setFilterFieldSearch: Dispatch<SetStateAction<string>>;
  filterFieldSearch: string;
}

const { Search } = Input;

const OfferFilter: FC<IOfferFilter> = ({
  filterBy,
  updateCheckedOfferFilters,
  checkedOfferFilters,
  setFilterBy,
  sortingOptions,
  setSortingOptions,
  filterField,
  setFilterFieldSearch,
  filterFieldSearch,
}) => {
  const ulStyle = {
    listStyleType: "none",
    paddingLeft: "0",
  };

  const offerTypeFilterOptions = [
    {
      title: "Lease",
    },
    {
      title: "Zero Down Lease",
    },
    {
      title: "Finance",
    },
    {
      title: "Purchase",
    },
    {
      title: "APR",
    },
  ];

  const sortByTitleMap: { [key in TSortByKey]?: string } = {
    msrp: "MSRP",
    monthlyPayment: "Lease Payment",
    financePayment: "Finance Payment",
    daysInStock: "Days In Stock",
    savingsOffMSRP: "Total Savings Off MSRP",
    finalPrice: "Final Price",
    aprRate: "APR Rate",
    priorityScore: "Priority Score",
  };

  const sortByFilterOptions = [
    ...Object.values(sortByTitleMap),
    "Last Updated",
  ];

  const reorder = (
    list: ISortingOption[],
    startIndex: number,
    endIndex: number,
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const isActive = (filter: VehicleConditions) => {
    return filter === filterBy;
  };

  const isKnownFilterField =
    !filterField || filterField === "make" || filterField === "dealerId";

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setFilterFieldSearch(e.target.value);
  };
  return (
    <Collapse
      className="filter-collapse-container"
      defaultActiveKey={["filter-panel"]}
      bordered={false}
      expandIconPosition="right"
    >
      <Collapse.Panel
        key="filter-panel"
        className="filter-section-container-panel filter-offer-container"
        header="Filter Offers"
      >
        <div className="filter-field-container">
          Filter Field
          <div>
            {isKnownFilterField ? (
              <Tag className="known-filter-field">{filterField}</Tag>
            ) : (
              <div className="unknown-filter-field">
                <Tag className="unknown-field">{filterField}:</Tag>
                <Search
                  placeholder="Filter value"
                  allowClear={true}
                  onChange={onChange}
                  value={filterFieldSearch}
                />
              </div>
            )}
          </div>
        </div>
        <div className="vehicle-condition-filter">
          Vehicle Condition
          <Button.Group className="filter-vehicle-condition-button-group">
            {["New", "Used", "CPO", "All"].map(btnType => (
              <Button
                key={`vehicle-condition-type-${btnType}`}
                onClick={() => setFilterBy(btnType as VehicleConditions)}
                className={
                  isActive(btnType as VehicleConditions) ? "active-btn" : ""
                }
              >
                {btnType}
              </Button>
            ))}
          </Button.Group>
        </div>
        <div className="filter-section-option-container">
          <div className="by-offer-type">
            <div className="label">Offer Lease</div>
            <div className="filter-options">
              <ul style={ulStyle}>
                {offerTypeFilterOptions.map(filterOption => (
                  <li
                    key={`offer-type-filter-option-key-${filterOption.title}`}
                  >
                    <Checkbox
                      checked={
                        checkedOfferFilters[
                          filterOption.title as checkedOfferFilterKeys
                        ]
                      }
                      onClick={() => {
                        updateCheckedOfferFilters(filterOption as any);
                      }}
                    >
                      {filterOption.title}
                    </Checkbox>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="sort-by">
            <div className="label">Sort By</div>
            <div className="sort-options">
              <DragDropContext
                onDragEnd={result => {
                  if (!result.destination) {
                    return;
                  }
                  const sortOptions = reorder(
                    sortingOptions,
                    result.source.index,
                    result.destination.index,
                  );
                  setSortingOptions(sortOptions as ISortingOption[]);
                }}
              >
                <Select
                  placeholder={"Select Sorting Options"}
                  style={{ width: "100%" }}
                  onSelect={(val: string) => {
                    if (
                      sortingOptions.some(
                        (option: ISortingOption) => option.content === val,
                      )
                    ) {
                      const deleted = [
                        ...sortingOptions.filter(
                          (option: ISortingOption) => option.content !== val,
                        ),
                      ];
                      setSortingOptions(deleted);
                    } else {
                      const updated = [
                        ...sortingOptions,
                        {
                          content: val,
                          desc: true,
                          key: Object.keys(sortByTitleMap)[
                            Object.values(sortByTitleMap).indexOf(val)
                          ] as SingletonOfferKeys,
                        },
                      ];
                      setSortingOptions(updated);
                    }
                  }}
                >
                  {sortByFilterOptions.map(option => {
                    return (
                      <Select.Option value={`${option}`} key={`${option}`}>
                        {option}
                      </Select.Option>
                    );
                  })}
                </Select>
                <Droppable droppableId="droppable">
                  {provided => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {sortingOptions.map(
                        (item: ISortingOption, index: number) => {
                          const handleIconClick = () => {
                            const [key] =
                              Object.entries(sortByTitleMap).find(
                                ([, value]) => value === item.content,
                              ) || [];
                            const updated = {
                              content: item.content,
                              desc: !item.desc,
                              key: key as SingletonOfferKeys,
                            };
                            const updatedSortingOptions = sortingOptions.map(
                              (option: ISortingOption) => {
                                if (option.content === item.content) {
                                  return updated;
                                } else {
                                  return option;
                                }
                              },
                            );
                            setSortingOptions(updatedSortingOptions);
                          };
                          return (
                            <Draggable
                              key={item.content}
                              draggableId={item.content}
                              index={index}
                            >
                              {provided => (
                                <div
                                  className={`sort-option-box ${item.content}`}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <Tag
                                    closable={true}
                                    onClose={() => {
                                      const updated = sortingOptions.filter(
                                        (option: ISortingOption) => {
                                          return (
                                            option.content !== item.content
                                          );
                                        },
                                      );
                                      setSortingOptions(updated);
                                    }}
                                  >
                                    {item.content}
                                    {item.desc ? (
                                      <CaretDownOutlined
                                        onClick={handleIconClick}
                                      />
                                    ) : (
                                      <CaretUpOutlined
                                        onClick={handleIconClick}
                                      />
                                    )}
                                  </Tag>
                                </div>
                              )}
                            </Draggable>
                          );
                        },
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        </div>
      </Collapse.Panel>
    </Collapse>
  );
};

const mapStateToProps = (state: any) => {
  const { assetBuilder } = state as {
    assetBuilder: IAssetBuilderState;
  };

  const { vehicleConditionFilter, checkedOfferFilters } = assetBuilder;

  return {
    vehicleConditionFilter,
    checkedOfferFilters,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateCheckedOfferFilters: (checkedOfferFilters: { title: string }) => {
      dispatch(
        actions.assetBuilder.updateCheckedOfferFilters(checkedOfferFilters),
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferFilter);
