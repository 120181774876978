import { ToolbarButton } from "shared/types/adLibrary";
import { keys } from "shared/types/shared";

function parseTableSingleItemActions<T>(
  getItemsToRender: (record: T) => ToolbarButton,
  record: T,
  key: "showInContextMenu" | "showInInlineMenu",
) {
  const buttonsWithRecord = getItemsToRender(record);
  return keys(buttonsWithRecord).reduce<ToolbarButton>((result, currentKey) => {
    const button = buttonsWithRecord[currentKey]!;
    if (button?.[key]) {
      button.type = key == "showInContextMenu" ? "context" : "inline";
      result[currentKey] = button;
    }
    return result;
  }, {});
}

export function parseContextMenuActions<T>(
  getItemsToRender: (record: T) => ToolbarButton,
  record: T,
) {
  return parseTableSingleItemActions(
    getItemsToRender,
    record,
    "showInContextMenu",
  );
}
export function parseInlineMenuActions<T>(
  getItemsToRender: (record: T) => ToolbarButton,
  record: T,
) {
  return parseTableSingleItemActions(
    getItemsToRender,
    record,
    "showInInlineMenu",
  );
}

export const isOutsideFromTable = (relatedTarget: Element) => {
  return (
    relatedTarget.tagName !== "svg" && // this means the mouse enters the one of the action buttons
    relatedTarget.tagName !== "path" && // this means the mouse enters the one of the action buttons
    relatedTarget.tagName !== "SPAN" && // this means the mouse enters the one of the action buttons
    !relatedTarget.className.includes("action-btn-toolbar") && // this means the mouse enters the container of action buttons
    !relatedTarget.firstElementChild?.className?.includes(
      "action-btn-toolbar",
    ) && // this means the mouse enters the one of the action buttons
    !relatedTarget.className.includes("contextActions")
  );
};
