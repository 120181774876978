import { useCallback, useEffect, useState } from "react";
import { Input, message, Select, Tooltip } from "antd";
import { fontWeightOptions } from "shared/constants/canvas";
import styles from "./FontProperty.module.scss";
import { SketchPicker } from "react-color";
import { TCanvasAction } from "screens/designStudio/editor/canvasContainer/Canvas";
import useFontProperty from "./fontProperty.hooks/useFontProperty";
import useFonts from "./fontProperty.hooks/useFonts";
import { useBrandsFonts } from "./fontProperty.hooks/useBrandsFonts";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { useCAMFonts, useCAMHookWithFallback } from "shared/components/media";
import { loadFont as loadFontFn } from "utils/helpers";
import { getFontSizeToUse } from "./FontProperty.utils";

type Props = {
  textbox?: fabric.Textbox;
  presetColors: string[];
  canvasAction?: TCanvasAction;

  onChange: (key: keyof fabric.Textbox) => (value: string | number) => void;
};

const FontProperty = (props: Props) => {
  const { onChange, presetColors } = props;
  const {
    fontWeight = "normal",
    charSpacing,
    lineHeight,
    fill,
    name,
  } = props.textbox || {};

  const { shouldUseBrandFonts } = useBrandsFonts();

  const { fontFamilies, loadFont } = useCAMHookWithFallback(
    useCAMFonts,
    useFonts,
    useBrandsFonts,
    shouldUseBrandFonts,
  )();

  const onFontChange = useCallback(
    async (value: string) => {
      // If value (which is selected font family) is in the font mappings,
      //   first load that font dynamically.
      try {
        await loadFont(value, loadFontFn);
        onChange("fontFamily")(value);
      } catch (err) {
        message.warning(`${value} could not be loaded`);
      }
    },
    [onChange, loadFont],
  );

  const [selectedColor, setSelectedColor] = useState<string>(
    (fill as string) || "#ffffff",
  );
  useEffect(() => {
    if (!name || !fill) return;

    setSelectedColor(fill as string);
  }, [fill, name]);

  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);

  const { textbox, canvasAction } = props;
  const { fontName, fontSize, setFontSize } = useFontProperty({
    textbox,
    canvasAction,
  });

  const onFontSizeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();

      const {
        target: { value },
      } = e;
      setFontSize(value);

      const isNumber = !Number.isNaN(+value);
      if (!isNumber) return;

      onChange("fontSize")(value);
    },
    [onChange, setFontSize],
  );

  const [showFontSizeArrows, toggleFontSizeArrows] = useState<boolean>(false);
  const onMouseEnterFontSizeInput = useCallback(() => {
    toggleFontSizeArrows(true);
  }, []);
  const onMouseLeaveFontSizeInput = useCallback(() => {
    toggleFontSizeArrows(false);
  }, []);

  const onArrowClick = useCallback(
    (which: "up" | "down") => () => {
      if (!textbox) return;
      const alteringValue = which === "up" ? 1 : -1;
      const newFontSize = getFontSizeToUse(textbox) + alteringValue;
      onChange("fontSize")(newFontSize);
    },
    [textbox, onChange],
  );

  return (
    <div className={styles.FontProperty}>
      <div className={styles.FontSelect}>
        <Select showSearch={true} value={fontName} onChange={onFontChange}>
          {fontFamilies.map((option, idx) => (
            <Select.Option key={`${option}-${idx}`} value={option}>
              <Tooltip
                title={option}
                trigger="hover"
                placement="right"
                mouseEnterDelay={0.5}
              >
                {option}
              </Tooltip>
            </Select.Option>
          ))}
        </Select>
      </div>
      <div className={styles.FontWeightStyleSize}>
        <span>
          <Select<string | number>
            value={fontWeight}
            onChange={onChange("fontWeight")}
          >
            {fontWeightOptions.map(option => (
              <Select.Option
                key={`${option}-font-weight-option`}
                value={option}
              >
                {option}
              </Select.Option>
            ))}
          </Select>
        </span>
        <span className={styles.FontSizeProperty}>
          <div
            className={styles.FontSizeInput}
            onMouseEnter={onMouseEnterFontSizeInput}
            onMouseLeave={onMouseLeaveFontSizeInput}
          >
            <Input onChange={onFontSizeChange} value={fontSize} />
            {showFontSizeArrows && (
              <div className={styles.Arrows}>
                <div className={styles.Wrapper}>
                  <CaretUpOutlined
                    className={styles.Up}
                    onClick={onArrowClick("up")}
                  />
                  <CaretDownOutlined
                    className={styles.Down}
                    onClick={onArrowClick("down")}
                  />
                </div>
              </div>
            )}
          </div>
        </span>
      </div>
      <div className={styles.FontColor}>
        <div className={styles.FontColorInputContainer}>
          <Input value={selectedColor} type="text" />
          <span
            style={{
              background: selectedColor,
            }}
            onClick={() => {
              setDisplayColorPicker(!displayColorPicker);
            }}
          />
        </div>

        <div
          className={`${styles.ColorPicker} ${
            !displayColorPicker ? styles.Hide : ""
          }`}
        >
          <SketchPicker
            disableAlpha={true}
            color={selectedColor}
            onChange={colorResult => {
              setSelectedColor(colorResult.hex);
            }}
            onChangeComplete={colorResult => onChange("fill")(colorResult.hex)}
            presetColors={presetColors}
          />
        </div>
      </div>

      <div className={styles.FontLineProperties}>
        <span key={`font-line-property-Character`}>
          <Input
            type="number"
            step={0.1}
            value={charSpacing}
            onChange={e => onChange("charSpacing")(e.target.value)}
          />
          <div className={styles.Label}>Character</div>
        </span>
        <span key={`font-line-property-Line`}>
          <Input
            type="number"
            step={0.01}
            value={lineHeight}
            onChange={e => onChange("lineHeight")(e.target.value)}
          />
          <div className={styles.Label}>Line</div>
        </span>
        <span key={`font-line-property-Paragraph`}>
          <Input value={0} disabled={true} />
          <div className={styles.Label}>Paragraph</div>
        </span>
      </div>
    </div>
  );
};

export default FontProperty;
