import { useNavigate, useParams } from "react-router-dom";
import ToolbarTable, {
  ToolbarButton,
} from "shared/components/toolbarTable/ToolbarTable";
import { ROUTES } from "../constants";
import { BeeswaxCampaignURLParams } from "../types";
import { useLineItems } from "./hooks/useLineItems";
import { useDataListURLGlobalFilter } from "shared/components/dataListURL/useDataListURLGlobalFilter";
import { useBeeswaxCampaignsContext } from "../BeeswaxCampaignsProvider";
import { useLineItemActions } from "./hooks/useLineItemActions";

const LineItemToolbar = () => {
  const navigate = useNavigate();
  const { campaignId } = useParams<BeeswaxCampaignURLParams>();
  const { selectedItems } = useLineItems();
  const { globalFilter, setGlobalFilter } = useDataListURLGlobalFilter();
  const { selectLineItem } = useBeeswaxCampaignsContext();
  const { deleteLineItems } = useLineItemActions();

  const toolbarContents: ToolbarButton = {
    New: {
      extraInfo: {
        text: "New Line Item",
      },
      onClick: () => {
        navigate(ROUTES.createLineItem(campaignId));
      },
    },
    Edit: {
      onClick: () => {
        const record = selectedItems[0];
        if (!record) throw new Error("No record selected");

        selectLineItem(record.id);
        navigate(ROUTES.editLineItem(campaignId));
      },
      disabled: selectedItems.length !== 1,
    },
    Delete: {
      onClick: () => {
        deleteLineItems(selectedItems.map(item => item.id));
      },
      disabled: selectedItems.length < 1,
    },
  };

  return (
    <ToolbarTable
      toolbarContents={toolbarContents}
      clientType={"auto"}
      searchPlaceholder="Search"
      searchValue={globalFilter || ""}
      onSearch={setGlobalFilter}
      titleTooltip="Search by any line item information"
    />
  );
};

export default LineItemToolbar;
