import { ITableColumnURL } from "shared/components/dataListURL/DataListURLTable";
import { BeeswaxCampaignURLParams, Creative, LineItem } from "../types";
import { SearchOutlined } from "@ant-design/icons";
import { SearchFilterDropdown } from "shared/components/SearchFilterDropdown";
import { DateFilterDropdown } from "shared/components/DateFilterDropdown";
import { toUSD } from "utils/helpers.currency";
import { renderDate, getCalendarIcon } from "../helpers";
import { ThumbnailCell } from "shared/components/ThumbnailCell";
import { useBeeswaxCampaignsContext } from "../BeeswaxCampaignsProvider";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../constants";

export const useLineItemColumns = () => {
  const navigate = useNavigate();
  const { campaignId } = useParams<BeeswaxCampaignURLParams>();
  const { selectLineItem } = useBeeswaxCampaignsContext();

  const columns: ITableColumnURL<keyof LineItem, LineItem>[] = [
    {
      title: "Line Item Name",
      dataIndex: ["name"],
      key: "name",
      filterDropdown: SearchFilterDropdown,
      filterIcon: <SearchOutlined />,
      width: "20%",
      render: (value: string, record: LineItem) => (
        <ThumbnailCell
          name={value}
          image={record.creatives?.[0]?.thumbnailUrl}
          onClick={() => {
            selectLineItem(record.id);
            navigate(ROUTES.editLineItem(campaignId));
          }}
        />
      ),
    },
    {
      key: "creatives",
      title: "Creatives",
      dataIndex: ["creatives"],
      render: (creatives: Creative[]) => creatives.length,
    },
    {
      key: "type",
      title: "Type",
      dataIndex: ["type"],
    },
    {
      key: "budget",
      title: "Budget",
      dataIndex: ["budget"],
      render: toUSD,
    },
    {
      key: "startDate",
      title: "Start Date",
      dataIndex: ["startDate"],
      render: renderDate,
      filterDropdown: DateFilterDropdown,
      filterIcon: getCalendarIcon,
    },
    {
      key: "endDate",
      title: "End Date",
      dataIndex: ["endDate"],
      render: renderDate,
      filterDropdown: DateFilterDropdown,
      filterIcon: getCalendarIcon,
    },
  ];

  return columns;
};
