import { PlusOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Tooltip } from "antd";
import { Key, useCallback, useMemo, useRef, useState } from "react";
import { getDefaultDuration } from "screens/assetExporter/feedConfigurationV2/assetBatchDrawer/utils";
import { DEFAULT_DURATION } from "screens/assetExporter/feedConfigurationV2/shared/constants";
import { useFeedContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/FeedContext";
import { useCompositionDuration } from "screens/assetExporter/feedConfigurationV2/shared/hooks/useCompositionDuration";
import {
  MAX_VIDEO_DURATION,
  videoCompositionAudioFilesEnabled,
} from "shared/constants/assetExporter";
import {
  CompositionType,
  MediaSubtype,
  TMediaComposition,
} from "shared/types/assetExporter";
import { v4 as uuidv4 } from "uuid";
import { useAssetBatchesContext } from "../../../shared/contexts/AssetBatchesContext";
import styles from "./AddMediaDropdown.module.scss";
import { CAMOption } from "./CAMOption";
import { useVideoCompositionAudios } from "screens/assetExporter/feedConfigurationV2/shared/hooks/useVideoCompositionAudios";
import { CAMMultiSelect } from "./CAMMultiSelect";
import { useMediaPreviewContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/MediaPreviewContext";

export const AddMediaDropdown = () => {
  const camElement = useRef<HTMLDivElement>(null);
  const camAudioElement = useRef<HTMLDivElement>(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { setZoomPercentage, setIsFitToScreen } = useMediaPreviewContext();

  const {
    setOpenFileManager,
    addTemplateBlocked,
    compositions,
    setCompositions,
    feedId,
    setCurrentCompositionId,
    setPreventAutoScroll,
    templatesToUse,
    isTemporalRemovedComposition,
    setAudioFiles,
    audioFiles,
    setIsFetchingAudioFiles,
  } = useAssetBatchesContext();
  const { editingAssetBatch } = useFeedContext();
  const { calculateTotalDuration } = useCompositionDuration();
  const { createNewAudioFile } = useVideoCompositionAudios();

  const handleSelectAssets = useCallback(
    async (assets: WDAsset[]) => {
      setIsFetchingAudioFiles(true);
      await Promise.all(
        assets.map(asset =>
          createNewAudioFile(
            MediaSubtype.CAM,
            asset.hiResURLRaw ?? "",
            asset.name,
            uuidv4(),
            setAudioFiles,
          ),
        ),
      )
        .then(() => setIsFetchingAudioFiles(false))
        .catch(() => {
          setIsFetchingAudioFiles(false);
        });
    },
    [createNewAudioFile, setAudioFiles, setIsFetchingAudioFiles],
  );

  const createNewMediaComposition = useCallback(
    async (subtype: MediaSubtype, url: string, name?: string) => {
      const duration = await getDefaultDuration(
        url,
        (compositions?.length ?? 0) + 1,
      );
      const newMediaComposition: TMediaComposition = {
        compositionId: `composition_${uuidv4()}_${Date.now()}`,
        feedId,
        ...(editingAssetBatch?.assetBatchId && {
          assetBatchId: editingAssetBatch.assetBatchId,
        }),
        type: CompositionType.Media,
        url,
        subtype,
        variables: {},
        template: "",
        duration,
        ...(name && { name }),
        audioEnabled: true,
      };
      setCompositions(oldCompositions => {
        if (oldCompositions.length === 0)
          return [...oldCompositions, newMediaComposition];
        return [
          ...oldCompositions.map(comp => {
            if (comp.duration === 0)
              return { ...comp, duration: DEFAULT_DURATION };
            return comp;
          }),
          newMediaComposition,
        ];
      });
      setPreventAutoScroll(false);
      setCurrentCompositionId(newMediaComposition.compositionId);
      setZoomPercentage(100);
      setIsFitToScreen(false);
    },
    [
      compositions?.length,
      feedId,
      editingAssetBatch?.assetBatchId,
      setZoomPercentage,
      setIsFitToScreen,
      setCompositions,
      setPreventAutoScroll,
      setCurrentCompositionId,
    ],
  );

  const totalDuration = calculateTotalDuration(compositions);

  const options = useMemo(
    () => [
      {
        key: "audioTrack",
        label: "Audio track",
        disabled:
          !videoCompositionAudioFilesEnabled ||
          addTemplateBlocked ||
          audioFiles.length >= 5,
        children: [
          {
            key: "audioFeed",
            element: "From feed or URL",
            value: "audioFeed",
          },
          {
            key: "audioCAM",
            element: (
              <CAMMultiSelect
                ref={camAudioElement}
                onSelectAssets={handleSelectAssets}
                title="Advanced CAM Search"
                fileTypes={["mp3", "wav"]}
                maxSelected={audioFiles.length > 0 ? 5 - audioFiles.length : 5}
                search='(.mp3 OR .wav) AND NOT "._"'
              >
                From CAM
              </CAMMultiSelect>
            ),
            value: "From CAM",
          },
        ],
      },
      {
        key: "frame",
        label: "Frame",
        disabled:
          addTemplateBlocked ||
          compositions.length > 2 ||
          totalDuration >= MAX_VIDEO_DURATION,
        children: [
          {
            key: "template",
            element: "Template",
            value: "template",
          },
          {
            key: "feed",
            element: "Media from feed or URL",
            value: "feed",
          },
          {
            key: "cam",
            element: (
              <CAMOption
                ref={camElement}
                onSelectAsset={(url: string, name: string) => {
                  createNewMediaComposition(MediaSubtype.CAM, url, name);
                }}
              />
            ),
            value: "Media from CAM",
          },
        ],
      },
    ],
    [
      addTemplateBlocked,
      audioFiles.length,
      compositions.length,
      createNewMediaComposition,
      handleSelectAssets,
      totalDuration,
    ],
  );

  const onSelectClick = useCallback(
    (key: Key) => {
      if (key === "cam") return camElement.current?.click();
      if (key === "template") return setOpenFileManager(true);
      if (key === "feed")
        return createNewMediaComposition(MediaSubtype.Feed, "");
      if (key === "audioCAM") return camAudioElement.current?.click();
      if (key === "audioFeed") {
        const id = uuidv4();
        return createNewAudioFile(MediaSubtype.Feed, "", "", id, setAudioFiles);
      }
    },
    [
      setOpenFileManager,
      createNewMediaComposition,
      createNewAudioFile,
      setAudioFiles,
    ],
  );

  const handleMenuClick = (e: any) => {
    onSelectClick(e.key);
    setDropdownVisible(false);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {options.map(({ key, label, children, disabled }) => (
        <Menu.SubMenu
          key={key}
          title={label}
          disabled={disabled}
          style={{ width: "155px" }}
        >
          {children.map(child => (
            <Menu.Item key={child.key}>{child.element}</Menu.Item>
          ))}
        </Menu.SubMenu>
      ))}
    </Menu>
  );

  const tooltipText = useMemo(() => {
    if (compositions.length > 2)
      return "You've hit the maximum clip limit of 3";
    if (totalDuration >= MAX_VIDEO_DURATION)
      return "You’ve hit the maximum total duration of 180s";
    if (
      compositions.length === 1 &&
      templatesToUse.find(templ => templ.id === compositions[0].template)
        ?.type === "html"
    )
      return "Video composition does not support HTML templates";
    if (isTemporalRemovedComposition)
      return "Wait 5 seconds to add a new clip or close the notification";
    if (audioFiles.length >= 5)
      return "You've hit the maximum audio file limit of 5";
    return "You can't add a new template right now";
  }, [
    compositions,
    totalDuration,
    templatesToUse,
    isTemporalRemovedComposition,
    audioFiles.length,
  ]);

  const isTooltipVisible =
    (addTemplateBlocked ||
      compositions.length > 2 ||
      totalDuration >= MAX_VIDEO_DURATION) &&
    dropdownVisible;

  return (
    <Tooltip title={tooltipText} visible={isTooltipVisible}>
      <div className={styles.tooltip}>
        <Dropdown
          overlay={menu}
          visible={dropdownVisible}
          onVisibleChange={setDropdownVisible}
        >
          <Button className={styles.dropdown} data-cy="cta-button-select">
            <PlusOutlined />
            Element
          </Button>
        </Dropdown>
      </div>
    </Tooltip>
  );
};
