import { isFeatureEnabled } from "utils/helpers";
import { figureToSvg, TYPES } from "polotno/utils/figure-to-svg";
import { svgToURL } from "polotno/utils/svg";

export const FABRIC_EDITOR = "v2";
export const POLOTNO_EDITOR = "v3";
export const DESIGN_STUDIO_V2_EDITOR_ENABLED = isFeatureEnabled(
  "ENABLE_DESIGN_STUDIO_V2_EDITOR",
);
export const POLOTNO_API_KEY = process.env.REACT_APP_POLOTNO_EDITOR_APIKEY;

const FIGURE_DEFAULTS = {
  width: 200,
  height: 200,
  fill: "#607D8B",
  stroke: "#0c0c0c",
  strokeWidth: 0,
  url: "",
};

const FIGURES = Object.keys(TYPES).map(subType => ({
  subType,
  ...FIGURE_DEFAULTS,
}));

export const POLOTNO_FIGURES = FIGURES.map(figure => ({
  ...figure,
  url: svgToURL(figureToSvg(figure)),
}));
