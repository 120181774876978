import { ITableColumn } from "./types";
import { DEFAULT_KEY, useDataList } from "./useDataList";

export const useColumn = <T,>(props: ITableColumn<T>): ITableColumn<T> => {
  const [{ filters, sort, config }] = useDataList<T>();
  const { key } = props;
  const sorter = typeof config.columns[key]?.sorterFn === "function";
  const filteredValue = filters[DEFAULT_KEY]?.[key] || null;
  const sortOrder = sort.columnKey === key ? sort.order : undefined;

  return {
    ...props,
    sorter,
    filteredValue,
    sortOrder,
  };
};
