import { Alert, Col } from "antd";
import { useCallback } from "react";
import { orderBy } from "lodash";
import { TComposition, TTemplateComposition } from "shared/types/assetExporter";
import { CloseCircleFilled } from "@ant-design/icons";
import { TextVariableInput } from "../panelVariables/TextVariableInput";
import { useAssetBatchesContext } from "../../shared/contexts/AssetBatchesContext";
import { getValueMappingKey } from "../utils";
import styles from "../panelVariables/PanelVariables.module.scss";
import { AssetBatchesTemplateCompositionProvider } from "../../shared/contexts/AssetBatchesTemplateCompositionContext";
import { isTemplateComposition } from "../../shared/contexts/AssetBatchesContext.utils";

export const PanelTextVariables = () => {
  const {
    variables: allVariables,
    compositions,
    showTextAlert,
  } = useAssetBatchesContext();

  const getSortedVariables = useCallback(
    (composition: TComposition) => {
      const variables = allVariables.find(
        vars => vars.compositionId === composition.compositionId,
      )?.variables;
      return orderBy(variables, variable => variable.variable, ["asc"]).filter(
        variable => variable.type === "text",
      );
    },
    [allVariables],
  );

  const getVariablesWithoutMatch = (composition: TTemplateComposition) => {
    return Object.values(composition.variables).filter(
      mapping =>
        !mapping.value &&
        mapping.inputType === "match_to_column" &&
        mapping.variable.type === "text",
    ).length;
  };

  const firstTemplateComposition = compositions[0];
  if (
    !firstTemplateComposition ||
    !isTemplateComposition(firstTemplateComposition)
  )
    return null;

  return (
    <AssetBatchesTemplateCompositionProvider
      key={firstTemplateComposition.compositionId}
      editingComposition={firstTemplateComposition}
    >
      <Col>
        {showTextAlert &&
          getVariablesWithoutMatch(firstTemplateComposition) > 0 && (
            <Alert
              className={styles.error}
              message={`We couldn’t find ${getVariablesWithoutMatch(
                firstTemplateComposition,
              )} of the columns`}
              description={`Please select them below`}
              type="error"
              showIcon
              icon={
                <CloseCircleFilled
                  style={{ fontSize: 14, paddingTop: 3, marginRight: 8 }}
                />
              }
            />
          )}

        {getSortedVariables(firstTemplateComposition).map((variable, idx) => {
          const mappingKey = getValueMappingKey(variable);

          return (
            <TextVariableInput
              key={`${variable.id}-${variable.variable}-${idx}`}
              variable={variable}
              valueMapping={firstTemplateComposition.variables[mappingKey]}
              mappingKey={mappingKey}
            />
          );
        })}
      </Col>
    </AssetBatchesTemplateCompositionProvider>
  );
};
