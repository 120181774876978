import { MutableRefObject, ReactNode } from "react";
import { CAMSelect } from "shared/components/media";

interface IProps {
  onSelectAssets: (assets: WDAsset[]) => void;
  ref?: MutableRefObject<any>;
  children?: ReactNode;
  title?: string;
  fileTypes?: string[];
  maxSelected?: number;
  search?: string;
}

export const CAMMultiSelect = ({
  ref,
  onSelectAssets,
  children,
  title,
  fileTypes,
  maxSelected,
  search,
}: IProps) => {
  const searchObj: MGSearchObj = {
    query: search,
  };
  return (
    <CAMSelect
      width="95vw"
      layout="grid"
      title={title ?? "Select media from CAM"}
      maxSelected={maxSelected ?? 1}
      fileTypes={fileTypes ?? ["png", "jpeg", "jpg", "mp4"]}
      onClose={async assets => {
        onSelectAssets(assets);
      }}
      search={search ? searchObj : undefined}
    >
      <div ref={ref}>{children ?? "Media from CAM"}</div>
    </CAMSelect>
  );
};
