import { Modal } from "antd";
import ToolbarTable from "shared/components/toolbarTable/ToolbarTable";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ToolbarButton } from "shared/types/adLibrary";
import { useAccountsContext } from "shared/components/salesEnablement/context/AccountsContext";
import { useDeleteAccounts } from "shared/hooks/salesEnablement/useDeleteAccounts";

const AccountToolbar = () => {
  const { search, onSearch, toggleDrawer, selectedAccounts, setEmployees } =
    useAccountsContext();
  const { mutate: deleteAccounts } = useDeleteAccounts({});

  const showDeletePrompt = () => {
    Modal.confirm({
      title: <b>Are you sure you want to delete these accounts?</b>,
      icon: (
        <ExclamationCircleOutlined style={{ color: "var(--primary-color)" }} />
      ),
      okText: "Delete",
      cancelText: "Cancel",
      okButtonProps: { type: "primary", danger: true },
      onOk: () => deleteAccounts(selectedAccounts.map(account => account.id)),
    });
  };

  const toolbarContents: ToolbarButton = {
    New: {
      disabled: false,
      onClick: () => toggleDrawer("CREATE_ACCOUNT"),
      extraInfo: {
        text: "New Account",
        tooltip: "Create new account",
      },
    },
    Edit: {
      disabled: selectedAccounts.length !== 1,
      onClick: () => {
        toggleDrawer("EDIT_ACCOUNT");
        setEmployees(selectedAccounts[0].employees);
      },
    },
    Delete: {
      disabled: !selectedAccounts.length,
      onClick: showDeletePrompt,
    },
  };

  return (
    <ToolbarTable
      searchValue={search}
      toolbarContents={toolbarContents}
      searchPlaceholder="Search"
      titleTooltip="Search accounts by name"
      onSearch={onSearch}
    />
  );
};

export default AccountToolbar;
