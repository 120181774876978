import { Stack, Typography, Slider } from "@mui/material";
import { DownloadFileType } from "screens/designStudio/designStudioV2/editorDrawer/editorDrawerContext/helpers.store";
import { PIXELRATIO_STEP, MIN_PIXELRATIO, MAX_PIXELRATIO } from "./constants";

type QualitySliderProps = {
  dpi: number;
  type: DownloadFileType;
  width: number;
  height: number;
  pixelRatio: number;
  setPixelRatio: (value: number) => void;
};

export const QualitySlider = ({
  pixelRatio,
  setPixelRatio,
  dpi,
  type,
  width,
  height,
}: QualitySliderProps) => {
  const isImageDownload = type === "JPEG" || type === "PNG";
  const isPDFDownload = type === "PDF";
  const isHTMLDownload = type === "HTML";
  const isGIFDownload = type === "GIF";

  if (isHTMLDownload) return null;
  return (
    <Stack>
      <Typography variant="caption">Quality</Typography>
      <Slider
        step={PIXELRATIO_STEP}
        min={MIN_PIXELRATIO}
        max={MAX_PIXELRATIO}
        value={pixelRatio}
        onChange={(_, value) => setPixelRatio(value as number)}
      />
      {isPDFDownload && (
        <Typography variant={"subtitle2"} sx={{ color: "text.secondary" }}>
          DPI: {Math.round(dpi * pixelRatio)}
        </Typography>
      )}
      {(isImageDownload || isGIFDownload) && (
        <Typography variant={"subtitle2"} sx={{ color: "text.secondary" }}>
          {Math.round(width * pixelRatio)} x {Math.round(height * pixelRatio)}{" "}
          px
        </Typography>
      )}
    </Stack>
  );
};
